<template>
  <section>
    <div v-if="object">
      <h1
        v-if="!loading">
        <address-text
          :one-line="true"
          :address="object.address" />
      </h1>
      <h5
        v-if="!loading">
        <span v-if="isAdminModule">{{ object.number }} | </span>
        <span v-else>{{ object.publicId }} | </span>
        <span v-if="object.name && object.name !== ''">{{ object.name }} | </span>
        <span v-if="getObjectRoom(object) > 0">{{ formatRooms(getObjectRoom(object)) }} </span>
        <span>{{ $t('message.onBoarding.buildings.objects.category.' + object.category.toLowerCase()) }} </span>
        <span v-if="getObjectFloor(object) > -10000">{{ formatFloor(getObjectFloor(object)) }}</span>
      </h5>
    </div>
    <div class="row">
      <div class="col">
        <form-wizard
          id="wizard"
          :ref="'wizard'"
          :hide-buttons="loading"
          step-size="xs"
          color="rgb(0, 68, 95)">
          <span slot="title" />
          <template>
            <tab-content :title="$t('message.addTenancy.tenants')">
              <div
                v-if="loading"
                class="text-center">
                <coozzy-spinner />
              </div>
              <add-tenancy-tenant-tab
                v-else
                :owner-id="object ? object.ownerId : 0"
                :tenants="tenantContacts"
                :billing-contact="billingContact"
                :tenant-to-hide="!billingContact ? tenantContacts : [billingContact].concat(tenantContacts)"
                :correspondence-contact="correspondenceContact"
                :guarantor-contacts="guarantorContacts"
                @tenant-changed="tenantChanged"
                @tenant-removed="tenantRemoved"
                @contact-changed="additionalContactChanged"
                @contact-removed="removeAdditionalContact" />
            </tab-content>
            <tab-content
              :title="$t('message.addTenancy.tenancy')">
              <div
                v-if="loading"
                class="text-center">
                <coozzy-spinner />
              </div>
              <add-tenancy-tenancy-tab
                v-else-if="object && isLastTenancy"
                ref="add-tenancy-tenancy-tab"
                :object="object"
                :last-tenancies="lastTenancies"
                :initial-tenancy="tenancy"
                :tenant-contacts="tenantContacts"
                :tenancy-id="tenancyId"
                :billing-contact="billingContact"
                :correspondence-contact="correspondenceContact"
                :guarantor-contacts="guarantorContacts"
                :request-id="requestId"
                @previous-tenancy-updated="previousTenancyUpdated"
                @updated-tenancy="tenancyUpdated" />
            </tab-content>
            <tab-content :title="$t('message.addTenancy.createContract')">
              <div
                v-if="loading"
                class="text-center">
                <coozzy-spinner />
              </div>
              <add-tenancy-contract-tab
                :owner-id="object ? object.ownerId : 0"
                @attach-tenancy="addDocumentToTenancy"
                @selected-contract="contractSelectedAction" />
            </tab-content>
          </template>
          <template
            #footer="props">
            <template v-if="!loading">
              <div class="wizard-footer-left">
                <wizard-button
                  v-if="props.activeTabIndex === 0"
                  :style="props.fillButtonStyle"
                  @click.native="$router.back()">
                  {{ $t('message.generic.cancel') }}
                </wizard-button>
                <wizard-button
                  v-else
                  :style="props.fillButtonStyle"
                  @click.native="backClicked(props)">
                  {{ $t('message.generic.back') }}
                </wizard-button>
              </div>
              <div class="wizard-footer-right">
                <wizard-button
                  v-if="!props.isLastStep"
                  ref="btnNextWizard"
                  :disabled="tenantContacts.length === 0"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="nextStep(props)">
                  <template>
                    {{ $t('message.generic.next') }}
                  </template>
                </wizard-button>
                <wizard-button
                  v-else
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="finishProcess">
                  <template v-if="contractSelected.length > 0">
                    {{ $t('message.addTenancy.createContractAndSave') }}
                  </template>
                  <template v-else>
                    {{ $t('message.addTenancy.createWithoutContract') }}
                  </template>
                </wizard-button>
              </div>
            </template>
          </template>
        </form-wizard>
      </div>
    </div>
    <b-modal
      id="confirmationModal"
      ref="confirmationModal"
      no-close-on-backdrop
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="confirmCancellation">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import ObjectApi from '@/misc/apis/ObjectApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import ContactApi from '@/misc/apis/ContactApi'
import AddTenancyTenantTab from '@/addTenancy/AddTenancyTenantTab'
import AddTenancyTenancyTab from '@/addTenancy/AddTenancyTenancyTab'
import AddTenancyContractTab from '@/addTenancy/AddTenancyContractTab'
import AddressText from '@/framework/components/misc/AddressText'
import { obj } from '@/mixins/object'
import { mapMutations } from 'vuex'
import { onboarding } from '@/mixins/onboarding'
import { formatting } from '@/mixins/formatting'
import { routeChecks } from '@/mixins/routeChecks'
import { saveAs } from 'file-saver'
import MediaApi from '@/misc/apis/MediaApi'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import { user } from '@/mixins/user'
import { helper } from '@/types/helper'

export default {
  name: 'AddTenancyView',
  components: {
    CoozzyButton,
    AddTenancyTenancyTab,
    AddTenancyTenantTab,
    AddTenancyContractTab,
    CoozzySpinner,
    AddressText
  },
  mixins: [formatting, obj, routeChecks, onboarding, user],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  beforeRouteLeave (to, from, next) {
    this.nextToPath = to
    if (!this.confirmCancellationProcess) {
      this.$refs.confirmationModal.show()
    } else {
      next()
    }
  },
  props: {
    objectId: {
      type: String,
      required: true
    },
    requestId: {
      type: String,
      default: ''
    },
    tenancyId: {
      type: String,
      default: null
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.interestedParty.action.addAsTenant') + ' - ' + (this.object ? this.object.name : '')
    }
  },
  data() {
    return {
      loading: false,
      object: null,
      tenancy: helper.emptyTenancy,
      contractSelected: [],
      contracts: [],
      lastTenancies: [],
      tenantContacts: [],
      billingContact: null,
      correspondenceContact: null,
      guarantorContacts: [],
      isLastTenancy: false,
      addDocumentToContact: false,
      title: '',
      description: '',
      tenantAdded: false,
      confirmCancellationProcess: false,
      nextToPath: null
    }
  },
  computed: {
    activeTabIndex() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.activeTabIndex
      }
      return 0
    },
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/'
    },
    prevRouteQuery() {
      return this.prevRoute ? this.prevRoute.query : null
    }
  },
  mounted() {
    this.loading = true
    this.$store.dispatch('onboarding/setOpenedCollapses')
    const promises = [ObjectApi.getObject(this.objectId)]
    if (this.tenancyId) {
      promises.push(ObjectApi.getTenancyById([this.tenancyId]))
    } else if (this.requestId) {
      promises.push(RequestApi.listByIds([this.requestId]))
    }

    const t = this
    Promise.all(promises)
      .then(values => {
        this.object = values[0].object
        if (this.object) {
          this.tenancy.ownerId = this.object.ownerId
          this.tenancy.objectId = this.object.id
        }
        if (!this.tenancyId) {
          const adjustment = this.tenancy.rent.adjustments[0]
          if (adjustment) {
            adjustment.newItem = true
            this.tenancy.rent.adjustments[0] = adjustment
          }
        }
        // get last tenant of object
        ObjectApi.getTenancies([this.object.id])
          .then(response => {
            if (response.tenancies.length > 0) {
              this.lastTenancies = [...response.tenancies.filter(x => x.id !== this.tenancyId).sort(function (a, b) {
                return new Date(t.$options.filters.objectToDateInput(b.startingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.startingAt)).getTime()
              }).slice(0, 2)]
              this.isLastTenancy = true
            } else {
              this.isLastTenancy = true
            }
          })
        // Load tenants from tenancy if needed
        if (this.tenancyId) {
          if (!values[1].tenancy) {
            Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
            this.$router.back()
            return
          }
          this.tenancy = values[1].tenancy
          const tenantContactIds = values[1].tenancy.tenant.contactIds
          const billingContactId = values[1].tenancy.tenant.billingContactId
          const correspondenceContactId = values[1].tenancy.tenant.correspondenceContactId
          const guarantorContactIds = values[1].tenancy.tenant.guarantorContactIds

          let contactIdsToLoad = []
          if (tenantContactIds.length > 0) {
            contactIdsToLoad = contactIdsToLoad.concat(tenantContactIds)
          }
          if (billingContactId) {
            contactIdsToLoad.push(billingContactId)
          }
          if (correspondenceContactId) {
            contactIdsToLoad.push(correspondenceContactId)
          }
          if (guarantorContactIds) {
            contactIdsToLoad = contactIdsToLoad.concat(guarantorContactIds)
          }

          ContactApi.contactResolve(contactIdsToLoad)
            .then(response => {
              const loadedContacts = response.persons.concat(response.companies)

              this.tenantContacts = loadedContacts.filter(c => tenantContactIds.includes(c.id))
              this.billingContact = loadedContacts.find(c => billingContactId === c.id)
              this.correspondenceContact = loadedContacts.find(c => correspondenceContactId === c.id)
              this.guarantorContacts = loadedContacts.filter(c => guarantorContactIds.includes(c.id))
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
              this.$router.back()
            })
            .finally(() => {
              this.loading = false
            })
        } else if (this.requestId) {
          // Load requesters from request if needed
          if (values[1].requests.length === 0) {
            Vue.toasted.show(this.$t('message.loadingErrors.request'), { type: 'error' })
            this.$router.back()
            return
          }
          const requesterContactIds = values[1].requests[0].requesters.map(r => r.contactId)
          ContactApi.contactResolve(requesterContactIds)
            .then(response => {
              this.tenantContacts = response.persons.concat(response.companies).filter(c => requesterContactIds.includes(c.id))
              Vue.set(this, 'billingContact', this.tenantContacts.find(c => c.id === requesterContactIds[0]))
              if (this.tenancy) {
                this.tenancy.tenant.billingContactId = this.billingContact.id
              }
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
              this.$router.back()
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          // We did not get any further info and are done
          this.loading = false
        }
      })
      .catch(e => {
        this.loading = false
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        this.$router.back()
      })
  },
  methods: {
    ...mapMutations('onboarding', ['setObjectOwnerContacts']),
    confirmCancellation() {
      this.confirmCancellationProcess = true
      if (this.nextToPath !== null) {
        this.$router.push({ name: this.nextToPath.name, params: this.nextToPath.params, query: this.nextToPath.query })
      } else {
        this.$router.push({ path: this.prevRoutePath, query: this.prevRouteQuery }, () => {
        })
      }
    },
    nextStep(props) {
      if (props.activeTabIndex === 0) {
        const hasNoAddress = this.tenantContacts.filter(x => !(x.address.zip && x.address.city && x.address.country))
        if (hasNoAddress.length > 0) {
          Vue.toasted.show(this.$t('message.savingErrors.tenantHaveAddress'), { type: 'error' })
        } else if (!this.billingContact) {
          Vue.toasted.show(this.$t('message.savingErrors.hasNoDebtor'), { type: 'error' })
        } else {
          props.nextTab()
          this.scrollToTop()
        }
      } else if (props.activeTabIndex === 1) {
        if (this.$refs['add-tenancy-tenancy-tab'] && !this.$refs['add-tenancy-tenancy-tab'].isInvalid()) {
          props.nextTab()
          this.scrollToTop()
        }
      } else {
        props.nextTab()
        this.scrollToTop()
      }
    },
    previousTenancyUpdated(tenancy) {
      if (this.lastTenancies[0].vacancy === false) {
        this.lastTenancies[0] = tenancy
      } else {
        this.lastTenancies[1] = tenancy
      }
      const btn = this.$refs.btnNextWizard.$el
      btn.click()
    },
    tenantChanged(contact) {
      let updatedContact = false
      this.tenantContacts.forEach((value, index) => {
        if (value.id === contact.id) {
          updatedContact = true
          Vue.set(this.tenantContacts, index, contact)
        }
      })

      if (!updatedContact) {
        this.tenantContacts.push(contact)
      }
      if (this.tenancy) {
        this.tenancy.tenant.contactIds = this.tenantContacts.map(value => value.id)
      }
    },
    tenantRemoved(id) {
      this.tenantContacts = this.tenantContacts.filter(c => c.id !== id)
      if (this.tenancy) {
        this.tenancy.tenant.contactIds = this.tenantContacts.map(value => value.id)
      }
    },
    additionalContactChanged(data) {
      const type = data.type
      const that = this

      if (type === 'BILLING_CONTACT') {
        Vue.set(this, 'billingContact', data.contact)
        // Add tenant (if not existing) the same as billing contact
        let exist = false
        this.tenantContacts.forEach((value) => {
          if (value.id === data.contact.id) {
            exist = true
            this.billingContact = data.contact
          }
        })
        if (!exist && this.tenantAdded === false) {
          this.tenantAdded = true
          this.tenantContacts.push(data.contact)
        }
        if (this.tenancy) {
          this.tenancy.tenant.billingContactId = data.contact.id
        }
      }
      if (type === 'CORRESPONDENCE_CONTACT') {
        Vue.set(this, 'correspondenceContact', data.contact)
        this.correspondenceContact = data.contact
        if (!this.objectOwnerContacts.find(x => x.id === data.contact.id || x.objectID === data.contact.id)) {
          that.setObjectOwnerContacts(this.objectOwnerContacts.concat(data.contact))
        }
        if (this.tenancy) {
          this.tenancy.tenant.correspondenceContactId = data.contact.id
        }
      }
      if (type === 'GUARANTOR_CONTACT') {
        let updatedContact = false
        this.guarantorContacts.forEach((value, index) => {
          if (value.id === data.contact.id) {
            updatedContact = true
            Vue.set(this.guarantorContacts, index, data.contact)
          }
        })

        if (!updatedContact) {
          this.guarantorContacts.push(data.contact)
        }
        if (this.tenancy) {
          this.tenancy.tenant.guarantorContactIds = this.guarantorContacts.map(value => value.id)
        }
      }
    },
    removeAdditionalContact(data) {
      const id = data.contact.id
      const type = data.type

      if (type === 'BILLING_CONTACT') {
        if (this.billingContact.id === id) {
          Vue.set(this, 'billingContact', null)
        }
        if (this.tenancy) {
          this.tenancy.tenant.correspondenceContactId = null
        }
      }
      if (type === 'CORRESPONDENCE_CONTACT') {
        if (this.correspondenceContact.id === id) {
          Vue.set(this, 'correspondenceContact', null)
        }
        if (this.tenancy) {
          this.tenancy.tenant.correspondenceContactId = null
        }
      }
      if (type === 'GUARANTOR_CONTACT') {
        const index = this.guarantorContacts.findIndex(c => c.id === id)
        Vue.delete(this.guarantorContacts, index)
        if (this.tenancy) {
          this.tenancy.tenant.guarantorContactIds = this.guarantorContacts.map(value => value.id)
        }
      }
    },
    tenancyUpdated(tenancy) {
      this.tenancy = tenancy
    },
    contractSelectedAction(ids, contracts) {
      this.contractSelected = ids
      this.contracts = contracts
    },
    addDocumentToTenancy(value) {
      this.addDocumentToContact = value
    },
    finishProcess() {
      this.confirmCancellationProcess = true
      if (this.tenancyId) {
        this.loading = true
        ObjectApi.updateTenancy(this.tenancy)
          .then(() => {
            if (this.contractSelected.length > 0) {
              this.renderContract(this.tenancy.id, 'update')
            } else {
              this.redirectOnFinish(this.tenancy.id)
            }
          })
          .catch(e => {
            this.handleErrorOnFinish(e)
          })
      } else {
        this.loading = true
        ObjectApi.createTenancy(this.tenancy)
          .then(response => {
            // If we have a requestId then we need to reserve this object for the request
            if (this.requestId) {
              RequestApi.reserve(this.requestId, response.tenancy.id)
            }

            if (this.contractSelected.length > 0) {
              this.renderContract(response.tenancy.id, 'create')
            } else {
              this.redirectOnFinish(response.tenancy.id)
            }
          })
          .catch(e => {
            this.handleErrorOnFinish(e)
          })
      }
    },
    renderContract(tenancyId, status) {
      const promises = []
      const listTemplatePdf = []
      for (let index = 0; index < this.contractSelected.length; index++) {
        const signatories = []
        const contract = this.contracts.find(x => x.id === this.contractSelected[index])
        if (contract && !contract.mediaId.includes('.pdf')) {
          if (contract && contract.firstSignature !== '') {
            signatories.push(contract.firstSignature)
          }
          if (contract && contract.secondSignature) {
            if (contract && contract.firstSignature === '') {
              signatories.push('')
            }
            signatories.push(contract.secondSignature)
          }
          promises.push(ObjectApi.RenderTenancyTemplate(this.contractSelected[index], tenancyId, this.addDocumentToContact, this.title, this.description, null, signatories))
        } else if (contract) {
          listTemplatePdf.push(contract.mediaId)
        }
      }
      if (listTemplatePdf.length > 0) {
        MediaApi.getListMedia(listTemplatePdf).then(response => {
          for (const media of response.media) {
            saveAs(media.url, media.filename)
          }
        })
      }
      Promise.all(promises)
        .then(response => {
          const renderId = []
          let downloadName = ''
          response.forEach((element, index) => {
            const contract = this.contracts.find(x => x.id === this.contractSelected[index])
            if (this.tenancy.vacancy === false && this.tenancy.tenant.contactIds.length > 0 && this.tenantContacts) {
              let nameOfTenant = ''
              this.tenancy.tenant.contactIds.forEach((element, index) => {
                if (index > 0) {
                  nameOfTenant = nameOfTenant + '_'
                }
                nameOfTenant = nameOfTenant + this.tenantContacts.find(x => x.id === element).name
              })
              downloadName = contract.title + '_' + nameOfTenant + '_' + this.object.numericId
            } else {
              downloadName = contract.title + '_' + this.object.numericId
            }
            const item = { renderId: element.renderId + '_' + downloadName + '-/-' + contract.enforcePdf, contract: contract }
            renderId.push(item)
          })
          localStorage.setItem('renderId', JSON.stringify(renderId))

          if (this.requestId) {
            RequestApi.partiallySetChecklist([this.requestId], { contractSent: true })
              .then(() => {
                Vue.toasted.show(this.$t('message.successMessages.contractAdded'), { type: 'success' })
                this.redirectOnFinish()
              })
              .catch(e => {
                this.handleErrorOnFinish(e)
              })
          } else {
            this.redirectOnFinish(tenancyId)
          }
        })
        .catch(() => {
          this.loading = false
          Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
          if (status === 'create') {
            ObjectApi.deleteTenancy(tenancyId)
              .then(() => {
              })
              .catch(e => {
                console.log(e)
              })
          }
        })
    },
    handleErrorOnFinish(e) {
      console.log(e)
      this.loading = false
      Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
    },
    redirectOnFinish(elementSelected) {
      setTimeout(() => {
        localStorage.setItem('latestCreatedTenancy', elementSelected)
        Vue.toasted.show(this.$t('message.successMessages.tenantAdded'), { type: 'success' })
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerBuildingDetailsView',
            params: { id: this.object.buildingId },
            query: {
              view: 'objects',
              elementSelected: this.object.id
            }
          }, () => {
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: this.object.buildingId },
            query: {
              view: 'objects',
              elementSelected: this.object.id
            }
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: this.object.buildingId },
            query: {
              view: 'objects',
              elementSelected: this.object.id
            }
          }, () => {
          })
        } else if (this.isMarketingModule) {
          this.$router.push({
            name: 'MarketingObjectDetailsView',
            params: { id: this.objectId },
            query: {
              view: 0,
              elementSelected: this.requestId
            }
          })
        }
      }, 2000)
    },
    backClicked(props) {
      props.prevTab()
      this.scrollToTop()
    },
    scrollToTop() {
      this.$nextTick(() => {
        const domRect = this.$el.querySelector('#wizard')
        domRect.scrollIntoView(true)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
