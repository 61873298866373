<template>
  <section v-if="partner">
    <coozzy-page-title :title="$t('message.navigation.networkPartner')" />
    <h6 class="font-weight-bold pt-1 pb-2">
      {{ $t(`message.networkPartner.subTypes.${partner.subType.toLowerCase()}`) }}
      <coozzy-button
        design="secondary"
        class="float-right"
        @click="backClicked">
        {{ $t('message.generic.back') }}
      </coozzy-button>
    </h6>
    <coozzy-card
      class="card shadow mb-3"
      :title="$t('message.networkPartner.agreements_documents')">
      <section
        class="global-section is-disabled mb-3">
        <coozzy-card class="global-card no-top-border">
          <div
            v-b-toggle.collapse-customer
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.networkPartner.customer') }}
            </h5>
          </div>
          <div class="col-12 pl-0">
            <b-collapse
              id="collapse-customer"
              visible>
              <div class="row">
                <div class="col-12">
                  <h1>
                    {{ partnerName }}
                  </h1>
                </div>
                <div class="col-8">
                  <p v-if="partner.company">
                    {{ partner.company.aboutUs }}
                  </p>
                </div>
              </div>
              <div class="row details-row-bg-1 mt-3 py-2">
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.name') }}
                  </label>
                  <p class="mb-0">
                    {{ partnerName }}
                  </p>
                </div>
                <div
                  v-if="hasStreetAddition"
                  class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.addressAddition') }}
                  </label>
                  <p class="mb-0">
                    {{ address.streetAddition }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.street') }}
                  </label>
                  <p class="mb-0">
                    {{ address.street }} {{ address.streetNumber }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.zip') }}
                  </label>
                  <p class="mb-0">
                    {{ address.zip }}
                  </p>
                </div>
                <div
                  v-if="!hasStreetAddition"
                  class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.city') }}
                  </label>
                  <p class="mb-0">
                    {{ address.city }}
                  </p>
                </div>
              </div>
              <div class="row details-row-bg-2 py-01rem">
                <div
                  v-if="hasStreetAddition"
                  class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.city') }}
                  </label>
                  <p class="mb-0">
                    {{ address.city }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.country') }}
                  </label>
                  <p class="mb-0">
                    {{ translateCountry(address.country) }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.phoneNumber') }}
                  </label>
                  <p
                    v-if="partner.company"
                    class="mb-0">
                    {{ partner.company.phoneNumber }}
                  </p>
                  <p
                    v-if="partner.individual"
                    class="mb-0">
                    {{ partner.individual.phoneNumber }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.email') }}
                  </label>
                  <p>
                    <a
                      v-if="partner.company"
                      class="mb-0"
                      tabindex="0"
                      @click="sendEmailModal(partner.company)">
                      {{ partner.company.email }}
                    </a>
                    <a
                      v-if="partner.individual"
                      class="mb-0"
                      tabindex="0"
                      @click="sendEmailModal(partner.individual)">
                      {{ partner.individual.email }}
                    </a>
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.website') }}
                  </label>
                  <p
                    v-if="partner.company">
                    <template v-if="partner.company.website !== ''">
                      <a
                        tabindex="0"
                        target="_blank"
                        :href="getUrlWebsite(partner.company.website)">
                        {{ partner.company.website }}
                      </a>
                    </template>
                    <template v-else>
                      {{ partner.company.website | displayOptionalValue }}
                    </template>
                  </p>
                  <p
                    v-if="partner.individual">
                    <template v-if="partner.individual.website !== ''">
                      <a
                        tabindex="0"
                        target="_blank"
                        :href="getUrlWebsite(partner.individual.website)">
                        {{ partner.individual.website }}
                      </a>
                    </template>
                    <template v-else>
                      {{ partner.individual.website | displayOptionalValue }}
                    </template>
                  </p>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <coozzy-card
          class="global-card">
          <div
            v-b-toggle.collapse-contactperson
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.generic.contactPerson') }}
            </h5>
          </div>
          <div class="col-12 pl-0">
            <b-collapse
              id="collapse-contactperson">
              <div class="row mt-3 py-2">
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.networkPartner.contactPersonName') }}
                  </label>
                  <p class="mb-0">
                    {{ contactPerson && contactPerson.profile ? `${contactPerson.profile.firstName || ''} ${contactPerson.profile.lastName || ''}` : '-' }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.networkPartner.contactPersonPhone') }}
                  </label>
                  <p class="mb-0">
                    {{ contactPerson && contactPerson.profile && contactPerson.profile.phoneNumber ? contactPerson.profile.phoneNumber : '-' }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.networkPartner.contactPersonEmail') }}
                  </label>
                  <p class="mb-0">
                    <a
                      v-if="contactPerson && contactPerson.email"
                      class="mb-0"
                      tabindex="0"
                      @click="sendEmailModal(contactPerson)">
                      {{ contactPerson.email }}
                    </a>
                    <template v-else>
                      {{ '-' }}
                    </template>
                  </p>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <network-partner-region-card :partner="partner" />
      </section>
    </coozzy-card>
  </section>
</template>

<script>
import UserApi from '../../misc/apis/UserApi'
import Vue from 'vue'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import { mapMutations } from 'vuex'
import { country } from '@/mixins/country'
import NetworkPartnerRegionCard from '@/networkPartner/NetworkPartnerRegionCard'

export default {
  name: 'AssetNetworkPartnerDetailsView',
  components: {
    NetworkPartnerRegionCard,
    CoozzyCard,
    CoozzyButton,
    CoozzyPageTitle
  },
  mixins: [country],
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  metaInfo() {
    return {
      title: `${this.$t('message.navigation.networkPartner')} ${this.partnerName}`
    }
  },
  data() {
    return {
      file: null,
      uploading: false,
      partner: null,
      contactPerson: null,
      isBusy: false,
      about: '',
      fieldsContactTable: [
        {
          key: 'name',
          label: 'Name',
          thClass: 'th-name',
          tdClass: 'align-middle'
        },
        {
          key: 'email',
          label: 'Email',
          thClass: 'th-email',
          tdClass: 'align-middle'
        },
        {
          key: 'telephone',
          label: 'Telephone',
          thClass: 'th-telephone',
          tdClass: 'align-middle'
        }
      ]
    }
  },
  computed: {
    address() {
      if (this.partner && this.partner.company) {
        return this.partner.company.address
      } else if (this.partner && this.partner.individual) {
        return this.partner.individual.address
      }
      return {}
    },
    hasStreetAddition() {
      return !!this.address.streetAddition
    },
    partnerName() {
      if (this.partner && this.partner.company) {
        return this.partner.company.name
      } else if (this.partner && this.partner.individual) {
        return `${this.partner.individual.firstName} ${this.partner.individual.lastName}`
      } else {
        return ''
      }
    }
  },
  mounted: function () {
    UserApi.getAccount(this.id).then(response => {
      this.partner = response.account
      if (this.partner.contactPerson && this.partner.contactPerson.userId) {
        UserApi.getUser(this.partner.contactPerson.userId)
          .then(response => {
            this.contactPerson = response
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.user'), { type: 'error' })
          })
      }
    }).catch(e => {
      console.log(e)
      Vue.toasted.show(this.$t('message.loadingErrors.user'), { type: 'error' })
    })
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply'
    ]),
    backClicked() {
      this.$router.go(-1)
    },
    sendEmailModal(contact) {
      contact.id = contact.objectID
      this.setSendMailContacts([contact])
      this.setSendMailPreselectedContact(contact)
      this.setSendMailReferenceIds([])
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.details-row-bg-1) {
  background-color: $color-table-row-2;
}

:deep(.details-row-bg-2) {
  background-color: $color-table-row-1;
}

.global-section {
  border: 1px solid #dfdfdf;
}

:deep(.global-card) {
  margin-bottom: 0;

  & > .card-body {
    -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
  }

  border-left: 0;
  border-right: 0;
  border-bottom: 0;

  &.no-top-border {
    border-top: 0;
  }
}

:deep(.collapse-title) {
  &.collapsed {
    h5 {
      background-image: url('../../assets/arrow_down.svg');
    }
  }

  h5 {
    background-image: url('../../assets/arrow_up.svg');
    background-repeat: no-repeat;
    font-size: large;
    font-weight: 500 !important;
    height: 1.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    background-position: right;
  }
}
</style>
