import Vue from 'vue'
import RequestApi from '@/marketingBasic/api/RequestApi'

const request = {
  methods: {
    resolveMarketplace(marketplace: string) {
      switch (marketplace) {
        case 'newhome':
          return 'newhome.ch'
        case 'immoscout':
          return 'immoscout24.ch'
        case 'homegate':
          return 'homegate.ch'
        default:
          return '-'
      }
    },
    rejectRequestMail(requestList, selectedEntries, contactList, object, secondary = true, onlySimpleMessage = false) {
      const referenceIdsByContacts = {}
      const contacts = []
      for (let index = 0; index < selectedEntries.length; index++) {
        const request = requestList.find(request => request.id === selectedEntries[index])
        if (request) {
          this.requestIds.push(request.id)

          request.requesters.forEach(req => {
            referenceIdsByContacts[req.contactId] = [request.id]
          })
          request.requesters.forEach(requester => {
            contacts.push(contactList.find(contact => contact.id === requester.contactId))
          })
        }
      }
      const contactsWithEmail = contacts.filter(x => x && x.email !== '')
      this.contactsWithoutEmail = contacts.filter(x => x && x.email === '')
      this.setSendMailContacts(contactsWithEmail)
      this.setSendMailPreselectedContact(contactsWithEmail)
      this.setSendMailContactsWithoutEmail(this.contactsWithoutEmail)
      this.setSendMailEmailToReply(null)
      // this.setSendMailReferenceIds(this.requestIds)
      this.setUseBcc(true)
      if (secondary === false) {
        this.setSendMailSecondaryTitle(false)
      } else {
        this.setSendMailSecondaryTitle(true)
      }
      this.setBccReferenceIds(referenceIdsByContacts)
      this.setRenderWithObjectId(object.id)
      if (onlySimpleMessage === false) {
        this.setAdditionalText(`${this.$t('message.generic.object')}:\n${object.address.street} ${object.address.streetNumber}\n${object.address.zip} ${object.address.city}`)
      }
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    rejectRequests(requestList, selectedEntries) {
      const requestIds = []
      for (let index = 0; index < selectedEntries.length; index++) {
        const request = requestList.find(request => request.id === selectedEntries[index])
        requestIds.push(request.id)
      }

      RequestApi.markRejected(requestIds)
        .then(() => {
          if (this.$refs.requestRejectConfirmationModal) {
            this.$refs.requestRejectConfirmationModal.hide()
          }
          if (this.requestList) {
            this.loadRequests()
          }
          this.requestIds = []
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.mailSend'), { type: 'error' })
        })
    },
    reactivateRequests(requestList, selectedEntries) {
      const requestIds = []
      for (let index = 0; index < selectedEntries.length; index++) {
        const request = requestList.find(request => request.id === selectedEntries[index])
        requestIds.push(request.id)
      }

      RequestApi.reactivate(requestIds)
        .then(() => {
          if (this.requestList) {
            this.loadRequests()
          }
          this.requestIds = []
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.request'), { type: 'error' })
        })
    },
    checkIfRejected(requestList, selectedEntries) {
      let countAlreadyRejected = 0
      for (let index = 0; index < selectedEntries.length; index++) {
        const request = requestList.find(request => request.id === selectedEntries[index])
        this.requestIds.push(request.id)

        if (request.rejected === true) {
          countAlreadyRejected += 1
        }
      }
      if (countAlreadyRejected === selectedEntries.length) {
        return true
      } else {
        return false
      }
    },
    checkIfReserved(requestList, selectedEntries) {
      let countAlreadyReserved = 0
      for (let index = 0; index < selectedEntries.length; index++) {
        const request = requestList.find(request => request.id === selectedEntries[index])
        this.requestIds.push(request.id)

        if (request.tenancyId) {
          countAlreadyReserved += 1
        }
      }
      if (countAlreadyReserved === selectedEntries.length) {
        return true
      } else {
        return false
      }
    }
  }
}
export { request }
