<template>
  <b-dropdown-divider />
</template>

<script>
export default {
  name: 'CoozzyDropdownSpaceDivider'
}
</script>

<style lang="scss" scoped>
</style>
