<template>
  <section>
    <div v-if="object">
      <h1
        v-if="!loading">
        <address-text
          :one-line="true"
          :address="object.address" />
      </h1>
      <h5
        v-if="!loading">
        <span v-if="isAdminModule">{{ object.number }} | </span>
        <span v-else>{{ object.publicId }} | </span>
        <span v-if="getObjectRoom(object) > 0">{{ formatRooms(getObjectRoom(object)) }} </span>
        <span>{{ $t('message.onBoarding.buildings.objects.category.' + object.category.toLowerCase()) }} </span>
        <span v-if="getObjectFloor(object) > -10000">{{ formatFloor(getObjectFloor(object)) }}</span>
      </h5>
    </div>
    <div class="row">
      <div class="col">
        <div
          v-if="loading"
          class="text-center">
          <coozzy-spinner />
        </div>
        <template>
          <div class="row">
            <div class="col">
              <coozzy-card class="readonly-wrapper global-card mb-3 mt-4">
                <object-basic
                  v-if="object"
                  ref="object-basic"
                  :key="`basic-${object.id}`"
                  :object-selected="object"
                  :visible="false"
                  :read-only="true"
                  :from-admin="$route.path.startsWith('/admin') || $route.path.startsWith('/asset') || $route.path.startsWith('/accounting')" />
              </coozzy-card>
              <coozzy-card
                class="readonly-wrapper global-card mb-3">
                <object-pricing
                  v-if="object"
                  ref="object-pricing"
                  :key="`pricing-${object.id}`"
                  :object-selected="object"
                  :expand-by-default="false"
                  :read-only="true" />
              </coozzy-card>
              <coozzy-card class="global-card mb-3">
                <object-tenancy
                  v-if="tenancy"
                  ref="object-tenancy"
                  :tenancy="tenancy"
                  :contacts="contacts"
                  :read-only="false"
                  :expand-by-default="true"
                  :object="object"
                  :enable-tenant-selection="false"
                  :show-delete-button="false"
                  mode="activate"
                  @tenancy-updated="tenancyUpdated" />
              </coozzy-card>
            </div>
          </div>
          <div class="col p-0">
            <div
              class="w-100 float-right mb-4">
              <coozzy-button
                size="normal"
                design="green"
                class="float-right"
                @click="saveClicked">
                {{ $t('message.interestedParty.action.activateTenancy') }}
              </coozzy-button>
              <coozzy-button
                size="normal"
                class="float-left"
                @click="cancelClicked">
                {{ $t('message.generic.cancel') }}
              </coozzy-button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <b-modal
      id="setFutureAddressModal"
      ref="setFutureAddressModal"
      no-close-on-backdrop
      :title="$t('message.onBoarding.interestedParties.setFutureAddressModalTenancy.title')"
      hide-footer>
      <div
        v-if="adressLoading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <div
        v-else
        class="col">
        <p>{{ $t('message.onBoarding.interestedParties.setFutureAddressModalTenancy.body') }}</p>
        <coozzy-button
          size="small"
          design="default"
          @click="hidesetFutureAddressModal()">
          {{ $t('message.generic.selectField.no') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="prop-green"
          @click="setAddress()">
          {{ $t('message.generic.selectField.yes') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import ObjectApi from '@/misc/apis/ObjectApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import ContactApi from '@/misc/apis/ContactApi'
import AddressText from '@/framework/components/misc/AddressText'
import { obj } from '@/mixins/object'
import { request } from '@/mixins/request'
import { formatting } from '@/mixins/formatting'
import ObjectBasic from '@/building/tabs/components/ObjectBasic'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import ObjectTenancy from '@/building/tabs/components/ObjectTenancy'
import { routeChecks } from '@/mixins/routeChecks'
import ObjectPricing from '@/building/tabs/components/ObjectPricing'

export default {
  name: 'ActivateTenancy',
  components: {
    CoozzySpinner,
    AddressText,
    ObjectBasic,
    CoozzyCard,
    ObjectTenancy,
    ObjectPricing
  },
  mixins: [formatting, obj, request, routeChecks],
  props: {
    objectId: {
      type: String,
      required: true
    },
    requestId: {
      type: String,
      default: null
    },
    tenancyId: {
      type: String,
      default: null
    },
    readMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      adressLoading: false,
      object: null,
      contactIds: [],
      contacts: [],
      tenancy: null,
      requestList: null,
      requestIds: [],
      contactsList: [],
      redirectAfterFinish: true
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails'])
  },
  watch: {
    getSentMails: function (newVal) {
      if (newVal.length > 0) {
        this.rejectRequests(this.requestList, this.requestList.filter(x => x.id !== this.requestId).map(x => x.id))
      }
    }
  },
  mounted() {
    this.loading = true
    this.loadRequests()

    const promises = [ObjectApi.getObject(this.objectId)]
    if (this.tenancyId) {
      promises.push(ObjectApi.getTenancyById([this.tenancyId]))
    } else if (this.requestId) {
      promises.push(RequestApi.listByIds([this.requestId]))
    }

    Promise.all(promises)
      .then(values => {
        this.object = values[0].object

        // Load tenants from tenancy if needed
        if (this.tenancyId) {
          if (!values[1].tenancy) {
            Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
            this.$router.back()
            return
          }
          this.tenancy = values[1].tenancy
          this.contactIds = values[1].tenancy.tenant.contactIds
          this.loadContacts()
        } else if (this.requestId) {
          // Load requesters from request if needed
          if (values[1].requests.length === 0) {
            Vue.toasted.show(this.$t('message.loadingErrors.request'), { type: 'error' })
            this.$router.back()
            return
          }
          this.contactIds = values[1].requests[0].requesters.map(r => r.contactId)
          this.loadContacts()
        } else {
          // We did not get any further info and are done
          this.loading = false
        }
        this.loading = false
      })
      .catch(e => {
        this.loading = false
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        this.$router.back()
      })
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSubject',
      'setAdditionalText',
      'setUseBcc',
      'setBccReferenceIds'
    ]),
    loadRequesterContacts() {
      ContactApi.contactResolve(this.request.requesters.map(requester => requester.contactId))
        .then(response => {
          if (response.persons.length > 0) {
            this.typeOfContact = 'person'
            this.contact = response.persons[0]
          } else if (response.companies.length > 0) {
            this.typeOfContact = 'company'
            this.contact = response.companies[0]
          }
          this.contactsList = response.persons
            .concat(response.companies)
            .filter(contact => this.request.requesters.map(requester => requester.contactId).includes(contact.id))
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    unPublish() {
      ObjectApi.unPublishObject([this.objectId])
        .then(() => {
          this.$bvModal.hide('requestDeactivateAdvertModal')
          Vue.toasted.show(this.$t('message.successMessages.unPublishObject'), { type: 'success' })
          if (this.requestList.filter(x => x.id !== this.requestId).length > 0) {
            this.$refs.requestRejectConfirmationModal.show()
          } else {
            this.redirectOnFinish()
          }
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.savingErrors.unPublishObject'), { type: 'error' })
        })
    },
    saveClicked() {
      if (this.$refs['object-tenancy'].isInvalid()) {
        Vue.toasted.show(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.tenancies.tenancy') + this.$t('message.savingErrors.validationError2'))

        console.log('Object tenancy invalid')
        return
      }
      this.tenancy.activated = true
      ObjectApi.updateTenancy(this.tenancy)
        .then(() => {
          ObjectApi.activateTenancy(this.tenancyId)
            .then(tenancy => {
              this.$refs.setFutureAddressModal.show()
              Vue.toasted.show(this.$t('message.successMessages.activateTenancy'), { type: 'success' })
            })
        })
        .catch(e => {
          console.log(e)
        })
    },
    getMarketingStatus(marketingStatus) {
      let status = ''
      if (marketingStatus) {
        const pub = marketingStatus.publications

        let publishedEntry = null
        if (pub && pub.length > 0) {
          publishedEntry = marketingStatus.publications.find(element => element.unPublished <= 0)
        }

        if (marketingStatus.newInMarketing === true) {
          status = 'new'
        } else if (marketingStatus.published || publishedEntry) {
          status = 'online'
        } else if (marketingStatus.status === 'rented') {
          status = 'rented'
        } else {
          status = 'offline'
        }
      }

      return status
    },
    checkReject() {
      this.$bvModal.hide('requestDeactivateAdvertModal')
      this.$bvModal.show('requestRejectConfirmationModal')
    },
    rejectRequestWithoutMail() {
      this.rejectRequests(this.requestList, this.requestList.filter(x => x.id !== this.requestId).map(x => x.id))
    },
    cancelClicked() {
      let routeLink = null
      if (this.isMarketingModule) {
        this.$router.push({ name: 'MarketingObjectDetailsView', params: { id: this.objectId }, query: { view: 0, elementSelected: this.requestId } })
      } else {
        ObjectApi.getBuildingByObjectId(this.objectId)
          .then(response => {
            if (this.readMode) {
              routeLink = {
                name: this.isOwnerModule ? 'OwnerBuildingDetailsView' : this.isAssetModule ? 'AssetBuildingDetailsView' : this.isAccountingModule ? 'AccountingBuildingDetailsView' : 'AdminBuildingDetailsView',
                params: { id: response.building.id },
                query: {
                  view: 'objects',
                  elementSelected: this.objectId
                }
              }
            } else {
              routeLink = {
                name: this.isOwnerModule ? 'OwnerOnboardingView' : this.isAssetModule ? 'AssetOnboardingView' : this.isAccountingModule ? 'AccountingBuildingDetailsView' : 'AdminOnboardingView',
                params: { id: response.building.id },
                query: {
                  view: 'objects',
                  elementSelected: this.objectId
                }
              }
            }
            this.$router.push(routeLink)
          })
      }
    },
    loadRequests() {
      RequestApi.listReceivedByObjectIds([this.objectId], false, [], [])
        .then(response => {
          this.requestList = response.requests
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.request'), { type: 'error' })
        })
    },
    loadContacts() {
      this.loading = true
      ContactApi.contactResolve(this.contactIds)
        .then(response => {
          this.contacts = response.persons.concat(response.companies)
          this.loading = false
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
          this.$router.back()
        })
    },
    tenancyUpdated(tenancy) {
      this.tenancy = tenancy
    },
    redirectOnFinish() {
      if (this.requestId) {
        this.$router.push({
          name: 'MarketingObjectDetailsView',
          params: { id: this.objectId },
          query: {
            view: 0,
            elementSelected: this.requestId,
            isTenancyActivated: true
          }
        })
      } else {
        ObjectApi.getBuildingByObjectId(this.objectId)
          .then(response => {
            if (this.isAssetModule) {
              this.$router.push({ name: 'AssetBuildingDetailsView', params: { id: response.building.id }, query: { view: 'tenants', elementSelected: this.tenancyId } }, () => {})
            } else if (this.isAccountingModule) {
              this.$router.push({ name: 'AccountingBuildingDetailsView', params: { id: response.building.id }, query: { view: 'tenants', elementSelected: this.tenancyId } }, () => {})
            } else {
              this.$router.push({ name: 'AdminBuildingDetailsView', params: { id: response.building.id }, query: { view: 'tenants', elementSelected: this.tenancyId } }, () => {})
            }
          })
      }
    },
    hidesetFutureAddressModal() {
      this.$refs.setFutureAddressModal.hide()
      this.redirectOnFinish()
    },
    setAddress() {
      this.adressLoading = true
      ContactApi.setAddress(this.object.address, this.tenancy.tenant.contactIds, this.tenancy.startingAt ? this.tenancy.startingAt : null)
        .then((res) => {
          this.adressLoading = false
          this.hidesetFutureAddressModal()
        })
        .catch(e => {
          console.log(e)
          this.adressLoading = false
          this.hidesetFutureAddressModal()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.readonly-wrapper {
  .inputs-container {
    overflow-y: scroll;
    height: calc(100vh - 100px);
  }

  .item {
    cursor: pointer;
  }

  :deep(.details-row-bg-1) {
    background-color: $color-table-row-2;
  }

  :deep(.details-row-bg-2) {
    background-color: $color-table-row-1;
  }

  :deep(label) {
    width: 100%;
  }

  :deep(.is-disabled) {
    .sort button {
      display: block !important;
    }

    button:not(.sort):not(.page-link) {
      display: none;
    }

    .multiselect, .custom-checkbox {
      pointer-events: none;

      .multiselect__select, .multiselect__placeholder {
        display: none;
      }
    }

    .filter-inputs .multiselect, .custom-checkbox {
      pointer-events: auto !important;

      .multiselect__select, .multiselect__placeholder {
        display: block !important;
      }
    }

    .basic {
      input {
        opacity: 1;
        border: thin solid #ced4da;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
        margin-bottom: 0;
        pointer-events: initial;
        padding-left: 0.2rem;
        background: none;
      }
    }

    .multi-select-value {
      line-height: 1.5;
    }
  }

  :deep(.collapse-title) {
    &.collapsed {
      h5 {
        background-image: url('../assets/arrow_down.svg');
      }
    }

    h5 {
      background-image: url('../assets/arrow_up.svg');
      background-repeat: no-repeat;
      font-size: large;
      font-weight: 500 !important;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      background-position: right;
    }
  }

  :deep(.global-card) {
    margin-bottom: 0;

    & > .card-body {
      -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    }

    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    &.no-top-border {
      border-top: 0;
    }
  }
}
:deep(.modal-dialog) {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    @media(max-width: 768px) {
      min-height: calc(100vh - 20px);
    }
  }
</style>
