<template>
  <b-card>
    <b-card-title class="mb-0">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title pt-1 mb-2">
                {{ $t('message.generic.search') }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </b-card-title>
    <b-card-body class="p-0">
      <div
        class="row">
        <div class="col-md-9 col-12 col-lg-6">
          <coozzy-form-input
            v-model="searchValue" />
        </div>
        <div class="col-md-3 col-12 col-lg-6">
          <coozzy-button
            class="float-right mt-2 mt-md-0"
            design="green"
            @click="clickSearch()">
            {{ $t('message.header.search') }}
          </coozzy-button>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'CoozzySearchCard',
  inheritAttrs: false,
  data() {
    return {
      searchValue: ''
    }
  },
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  },
  methods: {
    clickSearch() {
      this.$emit('clickSearch', this.searchValue)
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-subtitle {
    color: $color-text-grey-dark !important;
  }
</style>
