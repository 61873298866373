<template>
  <b-modal
    id="create-tenant-report"
    ref="create-tenant-report"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.navigation.reporting.createTenantReport')"
    @close="hide()">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <template>
          <div
            class="row details-row-bg-1 mb-3">
            <div
              class="col-6">
              <coozzy-form-radio
                v-model="periodType"
                name="type"
                :default-model="periodType"
                :select-value="'keyDate'">
                {{ $t('message.navigation.reporting.selectKeyDate') }}
              </coozzy-form-radio>
            </div>
            <div
              class="col-6">
              <coozzy-form-radio
                v-model="periodType"
                name="type"
                :default-model="periodType"
                :select-value="'period'">
                {{ $t('message.navigation.reporting.selectPeriod') }}
              </coozzy-form-radio>
            </div>
          </div>
          <div
            v-if="periodType === 'period'"
            class="row details-row-bg-1 mb-2">
            <div
              class="col-6 date-picker-container">
              <date-picker
                v-model="startDate"
                value-type="format"
                format="MM/YYYY"
                title-format="M"
                :placeholder="$t('message.navigation.reporting.startDate')"
                :multiple="false"
                :clearable="true"
                :editable="true"
                type="month"
                :input-class="($v.startDate.$dirty && $v.startDate.$error) ? 'mx-input is-invalid' : 'mx-input'"
                :lang="getLang" />
            </div>
            <div
              class="col-6 date-picker-container">
              <date-picker
                v-model="endDate"
                value-type="format"
                format="MM/YYYY"
                title-format="M"
                :placeholder="$t('message.navigation.reporting.endDate')"
                :multiple="false"
                :clearable="true"
                :editable="true"
                type="month"
                :input-class="($v.endDate.$dirty && $v.endDate.$error) ? 'mx-input is-invalid' : 'mx-input'"
                :lang="getLang" />
            </div>
          </div>
          <div
            v-if="periodType === 'keyDate'"
            class="row details-row-bg-1 mb-2">
            <div
              class="col-6 date-picker-container">
              <date-picker
                v-model="keyDate"
                value-type="format"
                format="DD/MM/YYYY"
                title-format="M"
                :placeholder="$t('message.navigation.reporting.keyDate')"
                :multiple="false"
                :clearable="true"
                :editable="true"
                type="date"
                :input-class="($v.keyDate.$dirty && $v.keyDate.$error) ? 'mx-input is-invalid' : 'mx-input'"
                :lang="getLang" />
            </div>
          </div>
        </template>
      </div>
      <div
        class="col-sm-12 col-md-12 mb-2">
        <label>{{ $t('message.settings.template.placeholderTemplate.language') }}</label>
        <coozzy-form-select v-model="language">
          <option :value="'PREFERRED_LANGUAGE_ENGLISH'">
            EN
          </option>
          <option :value="'PREFERRED_LANGUAGE_GERMAN'">
            DE
          </option>
          <option :value="'PREFERRED_LANGUAGE_ITALIAN'">
            IT
          </option>
        </coozzy-form-select>
      </div>
      <div
        class="col-sm-12 col-md-12">
        <label>{{ $t('message.generateExcel.sorting.title') }}</label>
        <coozzy-form-select
          v-model="sortingType"
          class="mb-2">
          <option :value="'SORTING_TYPE_OBJECT_ID'">
            {{ $t('message.generateExcel.sorting.byObjectId') }}
          </option>
          <option :value="'SORTING_TYPE_OBJECT_NUMBER'">
            {{ $t('message.generateExcel.sorting.byObjectNumber') }}
          </option>
          <option :value="'SORTING_TYPE_STANDARD'">
            {{ $t('message.generateExcel.sorting.standard') }}
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-sm-12 col-md-12 mt-1">
        <coozzy-form-checkbox
          v-model="buildingBreakPage"
          :initial="buildingBreakPage"
          name="noDebitUntil">
          {{ $t('message.navigation.reporting.buildingBreakingPage') }}
        </coozzy-form-checkbox>
      </div>
      <div class="col-sm-12 col-md-12 mt-1">
        <coozzy-form-checkbox
          v-model="displayPropertySummary"
          :initial="displayPropertySummary"
          name="noDebitUntil">
          {{ $t('message.navigation.reporting.displayPropertySummary') }}
          <coozzy-info-circle-icon
            v-if="isPropertyCondominium"
            v-b-tooltip="{ container: '#createTenantReport', title: $t('message.navigation.reporting.displayPropertySummaryTooltip') }" />
        </coozzy-form-checkbox>
      </div>
      <div class="col-sm-12 col-md-12 mt-1">
        <coozzy-form-checkbox
          v-model="withoutTenantsNames"
          :initial="withoutTenantsNames"
          name="noDebitUntil">
          {{ $t('message.navigation.reporting.withoutTenantsNames') }}
          <coozzy-info-circle-icon
            v-b-tooltip="{ container: '#createTenantReport', title: $t('message.navigation.reporting.withoutTenantsNamesTooltip') }" />
        </coozzy-form-checkbox>
      </div>
      <div class="col mt-3">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="hide()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="createReport">
          {{ $t('message.generic.create') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import ObjectApi from '@/misc/apis/ObjectApi'
import { user } from '@/mixins/user'
import { dateUtils } from '@/mixins/dateUtils'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzyFormRadio from '@/framework/components/form/radio/CoozzyFormRadio.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import DatePicker from 'vue2-datepicker'

export function endDateMoreThanStartDate() {
  if (this.startDate && this.endDate) {
    const startDateArray = this.startDate.split('/')
    const endDateArray = this.endDate.split('/')
    const startDate = new Date(startDateArray[1], startDateArray[0] - 1, 1).getTime()
    const endDate = new Date(endDateArray[1], endDateArray[0], 0).getTime()
    return startDate < endDate
  }
  return false
}
export default {
  name: 'CreateTenantReportModal',
  components: { CoozzyInfoCircleIcon, CoozzyFormSelect, CoozzyFormRadio, CoozzyFormCheckbox, CoozzyButton, DatePicker },
  mixins: [user, dateUtils],
  props: {
    buildingsIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      periodType: 'keyDate',
      startDate: null,
      endDate: null,
      keyDate: null,
      sortingType: 'SORTING_TYPE_STANDARD',
      buildingBreakPage: false,
      displayPropertySummary: false,
      language: '',
      properties: [],
      isPropertyCondominium: false,
      withoutTenantsNames: false
    }
  },
  computed: {
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    }
  },
  watch: {
    sortingType: function() {
      localStorage.setItem('sortingTypeTenantReportCreation', this.sortingType)
    }
  },
  mounted() {
    this.keyDate = this.formatDate(new Date())
    this.language = this.encodeLanguage(this.currentLanguage)
    if (localStorage.getItem('sortingTypeTenantReportCreation')) {
      this.sortingType = localStorage.getItem('sortingTypeTenantReportCreation')
    }
    if (this.buildingsIds.length > 0) {
      ObjectApi.getPropertiesByBuildingIds(this.buildingsIds).then(response => {
        this.properties = response.properties
        this.isPropertyCondominium = response.properties.some(property => {
          return property.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'
        })
      })
    }
  },
  methods: {
    // takes care of adding a leading zero if the month and day values only contain a single digit
    padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    },
    // formats a date as DD/MM/YYYY.
    formatDate(date) {
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear()
      ].join('/')
    },
    hide() {
      this.$refs['create-tenant-report']?.hide()
      this.$emit('hide')
    },
    show() {
      this.$refs['create-tenant-report']?.show()
    },
    async createReport() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let pdfRenderRentalProperties
        let pdfRenderCondominiumProperties
        let errorNoTenancies = false
        Vue.toasted.show(this.$t('message.navigation.reporting.startGenerating'), { type: 'success' })
        // filter buildingIds for rental property
        const buildingsIdsRentalProperties = this.buildingsIds.filter(buildingId => {
          return this.properties.filter(property => property.buildingIds.includes(buildingId) && property.type === 'PROPERTY_TYPE_RENTAL').length > 0
        })
        // filter buildingIds for condominium property
        const buildingsIdsCondominiumProperties = this.buildingsIds.filter(buildingId => {
          return this.properties.filter(property => property.buildingIds.includes(buildingId) && property.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP').length > 0
        })
        if (this.periodType === 'period') {
          const startDateArray = this.startDate.split('/')
          const endDateArray = this.endDate.split('/')
          const startDate = new Date(startDateArray[1], startDateArray[0] - 1, 1)
          const endDate = new Date(endDateArray[1], endDateArray[0], 0)
          this.hide()
          if (buildingsIdsRentalProperties.length > 0) {
            pdfRenderRentalProperties = await ObjectApi.rentalIndexRenderPdf(buildingsIdsRentalProperties, this.language, this.toObject(startDate), this.toObject(endDate), this.sortingType, this.buildingBreakPage, this.displayPropertySummary, this.withoutTenantsNames).catch(err => {
              const error = err.response.data
              console.log('error :', error)
              if (error.code === 2 && error.message === 'There are no tenancies for generating report') {
                errorNoTenancies = true
              }
            })
          }
          if (buildingsIdsCondominiumProperties.length > 0) {
            pdfRenderCondominiumProperties = await ObjectApi.rentalIndexRenderPdf(buildingsIdsCondominiumProperties, this.language, this.toObject(startDate), this.toObject(endDate), this.sortingType, this.buildingBreakPage, this.displayPropertySummary, this.withoutTenantsNames).catch(err => {
              const error = err.response.data
              console.log('error :', error)
              if (error.code === 2 && error.message === 'There are no tenancies for generating report') {
                errorNoTenancies = true
              }
            })
          }
        } else {
          const keyDateArray = this.keyDate.split('/')
          const keyDate = new Date(keyDateArray[2], keyDateArray[1] - 1, keyDateArray[0])
          this.hide()
          if (buildingsIdsRentalProperties.length > 0) {
            pdfRenderRentalProperties = await ObjectApi.rentalIndexRenderPdf(buildingsIdsRentalProperties, this.language, null, this.toObject(keyDate), this.sortingType, this.buildingBreakPage, this.displayPropertySummary, this.withoutTenantsNames).catch(err => {
              const error = err.response.data
              console.log('error :', error)
              if (error.code === 2 && error.message === 'There are no tenancies for generating report') {
                errorNoTenancies = true
              }
            })
          }
          if (buildingsIdsCondominiumProperties.length > 0) {
            pdfRenderCondominiumProperties = await ObjectApi.rentalIndexRenderPdf(buildingsIdsCondominiumProperties, this.language, null, this.toObject(keyDate), this.sortingType, this.buildingBreakPage, this.displayPropertySummary, this.withoutTenantsNames).catch(err => {
              const error = err.response.data
              console.log('error :', error)
              if (error.code === 2 && error.message === 'There are no tenancies for generating report') {
                errorNoTenancies = true
              }
            })
          }
        }
        const names = this.getDocsName()
        // download generated file for rental property
        if (pdfRenderRentalProperties) {
          const binary = window.atob(pdfRenderRentalProperties)
          const len = binary.length
          const buffer = new ArrayBuffer(len)
          const view = new Uint8Array(buffer)
          for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i)
          }
          const blob = new Blob([view], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = names.rental + '.pdf'
          link.click()
        }
        // download generated file for condominium property
        if (pdfRenderCondominiumProperties) {
          const binary = window.atob(pdfRenderCondominiumProperties)
          const len = binary.length
          const buffer = new ArrayBuffer(len)
          const view = new Uint8Array(buffer)
          for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i)
          }
          const blob = new Blob([view], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = names.condominium + '.pdf'
          link.click()
        }
        if (!pdfRenderRentalProperties && !pdfRenderCondominiumProperties) {
          if (errorNoTenancies) {
            Vue.toasted.show('There are no tenancies for generating report', { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.navigation.reporting.errorGenerating'), { type: 'error' })
          }
        }
      }
    },
    getDocsName() {
      const names = { rental: '', condominium: '' }
      switch (this.language) {
        case 'PREFERRED_LANGUAGE_ENGLISH':
          names.rental = 'Tenant report'
          names.condominium = 'Condominium report'
          break
        case 'PREFERRED_LANGUAGE_ITALIAN':
          names.rental = 'Stato locativo'
          names.condominium = 'Stato PPP'
          break
        case 'PREFERRED_LANGUAGE_GERMAN':
          names.rental = 'Mieterspiegel'
          names.condominium = 'Eigentümerspiegel'
          break
        default:
          names.rental = 'Tenant report'
          names.condominium = 'Condominium report'
      }
      return names
    },
    encodeLanguage(language) {
      if (language === 'en') {
        return 'PREFERRED_LANGUAGE_ENGLISH'
      } else if (language === 'it') {
        return 'PREFERRED_LANGUAGE_ITALIAN'
      } else if (language === 'de') {
        return 'PREFERRED_LANGUAGE_GERMAN'
      }
    }
  },
  validations() {
    const validationDevice = {}
    if (this.periodType === 'period') {
      validationDevice.startDate = {
        required
      }
      validationDevice.endDate = {
        required,
        endDateMoreThanStartDate
      }
    }
    if (this.periodType === 'keyDate') {
      validationDevice.keyDate = {
        required
      }
    }
    return validationDevice
  }
}
</script>

<style lang="scss" scoped>
:deep(.date-picker-container) {
  .mx-input {
    display: block;
    width: 100%;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    font-weight: 400;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    height: 30px !important;
    box-shadow: 1px 1px 6px #ccc;
  }
  .mx-input:hover, .mx-input:focus {
    border-color: #ccc !important;
  }
  .mx-datepicker {
    width: 100%;
  }
  .mx-icon-calendar {
    display: none;
  }
}
:deep(.is-invalid) {
  border-color: #dc3545 !important;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
