import networkTS from '@/misc/apis/networkTS'
import axios from 'axios'

export default {
  getZipInfo(zip: string) {
    return networkTS.zipServiceGetInfo(zip).then(response => {
        return response.data
      })
  }
}
