<template>
  <font-awesome-icon
    icon="exclamation-triangle"
    class="fa" />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

library.add(faExclamationTriangle)

export default {
  name: 'CoozzyExclamationTriangleIcon'
}
</script>

<style lang="scss" scoped>
.fa {
  color: $color-yellow;

  &:hover {
    cursor: pointer;
  }
}
</style>
