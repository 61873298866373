// @ts-nocheck
import ObjectApi from '@/misc/apis/ObjectApi'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/de'
import Vue from 'vue'
import { dateUtils } from '@/mixins/dateUtils'

const countryIndex = {
  components: {
    DatePicker
  },
  mixins: [dateUtils],
  data() {
    return {
      baseYearEnum: ['COUNTRY_INDEX_BASE_YEAR_1914', 'COUNTRY_INDEX_BASE_YEAR_1939', 'COUNTRY_INDEX_BASE_YEAR_1966', 'COUNTRY_INDEX_BASE_YEAR_1977', 'COUNTRY_INDEX_BASE_YEAR_1982', 'COUNTRY_INDEX_BASE_YEAR_1993', 'COUNTRY_INDEX_BASE_YEAR_2000', 'COUNTRY_INDEX_BASE_YEAR_2005', 'COUNTRY_INDEX_BASE_YEAR_2010', 'COUNTRY_INDEX_BASE_YEAR_2015', 'COUNTRY_INDEX_BASE_YEAR_2020'].reverse()
    }
  },
  methods: {
    getCountryIndexBaseDateOptions(date) {
      if (!date || date === '0') {
        return null
      }
      const index = this.baseYearEnum.findIndex(r => r.includes(date))
      if (index !== -1) {
        return this.baseYearEnum[index]
      } else {
        return null
      }
    },
    handleBaseYearChange() {
      if (this.countryIndexBaseDate) {
        if (this.dates.countryIndexBaseDate && this.dates.countryIndexBaseDate !== '') {
          this.countryIndexBaseDate = this.dates.countryIndexBaseDate.split('_').pop()
          if (this.dates.countryIndexBaseDate !== '' && this.countryIndexDate) {
            this.getInflationRate()
          }
          if (this.dates.countryIndexBaseDate !== '' && this.countryIndexPossibleDate) {
            this.getPossibleInflationRate()
          }
        }
      } else {
        this.countryIndexInflation = -1
        this.countryIndexInflationPossible = -1
      }
    },
    getFullYearString(year) {
      if (!year || isNaN(year.substring(year.length - 4, year.length))) {
        return '-'
      }
      return this.$t('message.onBoarding.buildings.objects.tenancies.countryIndex.baseYearOptions.' + year)
    },
    objectToDateString(value) {
      if (!value || value.year === -1 || value.year === '-1') {
        return '-'
      }
      const tomonth = value.month
      const toyear = value.year
      return ('0' + tomonth).slice(-2) + '/' + toyear
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d)
    },
    disabledBefore1914(date) {
      const year1914 = new Date('1/1/1914').getTime()

      return date < year1914
    },
    clearCountryIndexDate() {
      this.countryIndexDate = null
      this.dates.countryIndexDate = null
    },
    handleCountryIndexDateChange() {
      if (this.dates.countryIndexDate) {
        const countryIndexDateArrayD = this.dates.countryIndexDate.split('/')
        const countryIndexDateFormated = new Date(countryIndexDateArrayD[0] + '/01/' + countryIndexDateArrayD[1])
        if (this.isValidDate(countryIndexDateFormated) === true && !this.disabledBefore1914(countryIndexDateFormated)) {
          this.countryIndexDate = this.toObject(countryIndexDateFormated)
            if (this.countryIndexBaseDate && this.countryIndexDate) {
              this.getInflationRate()
            }
            if (!this.countryIndexPossibleDateModified) {
              this.dates.countryIndexPossibleDate = this.dates.countryIndexDate
              this.handleCountryIndexPossibleDateChange()
            }
        }
      }
    },
    handleCountryIndexPossibleDateChange() {
      if (this.dates.countryIndexPossibleDate) {
        const countryIndexPossibleDateArrayD = this.dates.countryIndexPossibleDate.split('/')
        const countryIndexPossibleDateFormatted = new Date(countryIndexPossibleDateArrayD[0] + '/01/' + countryIndexPossibleDateArrayD[1])
        if (this.isValidDate(countryIndexPossibleDateFormatted) === true && !this.disabledBefore1914(countryIndexPossibleDateFormatted)) {
          this.countryIndexPossibleDate = this.toObject(countryIndexPossibleDateFormatted)
            if (this.countryIndexBaseDate && this.countryIndexPossibleDate) {
              this.getPossibleInflationRate()
            }
        }
      }
    },
    getPossibleInflationRate(useCostIncreasePossibleDate = false) {
      const month = (useCostIncreasePossibleDate ? this.costIncreasePossibleDate.month : this.countryIndexPossibleDate.month) || 0
      const year = (useCostIncreasePossibleDate ? this.costIncreasePossibleDate.year : this.countryIndexPossibleDate.year) || 0
      let baseYear = this.dates.countryIndexBaseDate
      if (!this.dates.countryIndexBaseDate.includes('COUNTRY_INDEX_BASE_YEAR')) {
        baseYear = `COUNTRY_INDEX_BASE_YEAR_${this.dates.countryIndexBaseDate}`
      }
      if (month !== 0 && year !== 0) {
        ObjectApi.getInflation(baseYear, month, year)
          .then(response => {
            if (useCostIncreasePossibleDate) {
              this.costIncreaseInflationPossible = response.inflation
            } else {
              this.countryIndexInflationPossible = response.inflation
            }
          })
          .catch(e => {
            this.countryIndexInflationPossible = -1
            console.log(e)
            if (e.response?.data?.message) {
              Vue.toasted.show(e.response?.data?.message, { type: 'error' })
            }
          })
      }
    },
    getInflationRate(useCostIncreaseDate = false) {
      const month = (useCostIncreaseDate ? this.costIncreaseDate.month : this.countryIndexDate?.month) || 0
      const year = (useCostIncreaseDate ? this.costIncreaseDate.year : this.countryIndexDate?.year) || 0
      let baseYear = this.dates.countryIndexBaseDate
      if (!this.dates.countryIndexBaseDate.includes('COUNTRY_INDEX_BASE_YEAR')) {
        baseYear = `COUNTRY_INDEX_BASE_YEAR_${this.dates.countryIndexBaseDate}`
      }
      if (month !== 0 && year !== 0) {
        ObjectApi.getInflation(baseYear, month, year)
          .then(response => {
            if (useCostIncreaseDate) {
              this.costIncreaseInflation = response.inflation
            } else {
              this.countryIndexInflation = response.inflation
            }
          })
          .catch(e => {
            this.countryIndexInflation = -1
            console.log(e)
            if (e.response?.data?.message) {
              Vue.toasted.show(e.response?.data?.message, { type: 'error' })
            }
          })
      }
    },
    mountEventListenersCountryIndex(e) {
      let thisVal
      if (e.which !== 8) {
        const lastChar = e.substr(e.length - 1)
        if (isNaN(lastChar) && lastChar !== '/') {
          this.$nextTick(function () {
            thisVal = this.dates.countryIndexDate.slice(0, -1)
            this.dates.countryIndexDate = thisVal
          })
        } else {
          const numChars = this.dates.countryIndexDate.length
          if (numChars < 6) {
            if (numChars === 2) {
              thisVal = this.dates.countryIndexDate
              thisVal += '/'
              this.dates.countryIndexDate = thisVal
            }
            if (numChars === 6) {
              this.handleCountryIndexDateChange()
            }
          } else {
            this.$nextTick(function () {
              thisVal = this.dates.countryIndexDate.slice(0, 7)
              this.dates.countryIndexDate = thisVal
            })
          }
        }
      }
    }
  }
}

export { countryIndex }
