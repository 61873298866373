const countries = require('i18n-iso-countries')

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
countries.registerLocale(require('i18n-iso-countries/langs/it.json'))

const country = {
  methods: {
    translateCountry(iso2Code) {
      return countries.getName(iso2Code, this.$store.getters['internationalization/getCurrentLanguage'], { select: 'official' })
    }
  }
}

export { country }
