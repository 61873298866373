<template>
  <div>
    <div
      v-if="loading"
      class="text-center">
      <coozzy-spinner />
    </div>
    <div v-if="!loading">
      <div
        v-for="object in selectedObjectToProcess"
        :key="object.id">
        <add-vacancy-view
          :id="'add-vacancy-' + object.id"
          :ref="'add-vacancy-' + object.id"
          :object-id="object.id"
          :target="'enterTermination'"
          :details-of-termination="detailsOfTermination.filter(e => e.objectId === object.id)"
          :enter-termination-execute-save="true"
          @validated-vacancy="validatedVacancy"
          @added-vacancy="addedVacancy" />
      </div>
    </div>
  </div>
</template>

<script>

import AddVacancyView from '@/addVacancy/AddVacancyView'

export default {
  name: 'EnterTerminationAddTenancy',
  components: {
    AddVacancyView
  },
  props: {
    selectedObjectToProcess: {
      type: Array,
      required: true
    },
    detailsOfTermination: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addedObjects: [],
      loading: false,
      validateObjects: []
    }
  },
  methods: {
    validateBeforeSaveAddVacancy() {
      this.loading = false
      for (let index = 0; index < this.selectedObjectToProcess.length; index++) {
        if (!this.validateObjects.includes(item => item.id === this.selectedObjectToProcess[index].id)) {
          this.$refs['add-vacancy-' + this.selectedObjectToProcess[index].id][0].validateBeforeSave()
        }
      }
    },
    validatedVacancy(success, value) {
      if (success === false) {
        this.$emit('all-vacancy-added', false)
        this.validateObjects = []
        this.loading = false
      } else {
        if (this.validateObjects.includes(value)) {
          this.validateObjects = this.validateObjects.filter(item => item !== value)
        } else {
          this.validateObjects.push(value)
        }
        if (this.selectedObjectToProcess.length === this.validateObjects.length) {
          this.saveAddVacancy()
        }
      }
    },
    saveAddVacancy() {
      for (let index = 0; index < this.selectedObjectToProcess.length; index++) {
        if (!this.addedObjects.includes(item => item.id === this.selectedObjectToProcess[index].id)) {
          this.$refs['add-vacancy-' + this.selectedObjectToProcess[index].id][0].saveClicked()
        }
      }
    },
    addedVacancy(success, value) {
      if (success === false) {
        this.loading = false
        this.$emit('all-vacancy-added', false)
      } else {
        if (this.addedObjects.includes(value)) {
          this.addedObjects = this.addedObjects.filter(item => item !== value)
        } else {
          this.addedObjects.push(value)
        }
        if (this.selectedObjectToProcess.length === this.addedObjects.length) {
          this.loading = false
          this.$emit('all-vacancy-added', true)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
