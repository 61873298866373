<template>
  <img
    width="18px"
    height="18px"
    src="@/assets/icon/editer.png"
    alt="editerIcon">
</template>

<script>

export default {
  name: 'CoozzyEditIconImg'
}
</script>

<style lang="scss" scoped>
</style>
