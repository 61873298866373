<template>
  <section>
    <div
      v-if="!searchClient"
      class="text-center">
      <coozzy-spinner />
    </div>
    <ais-instant-search
      v-else
      ref="instant-search"
      :search-client="searchClient"
      :index-name="algoliaIndex"
      :routing="routing">
      <app-refresh ref="app-refresh" />
      <ais-stats>
        <template #default="{ nbHits }">
          <coozzy-page-title
            :title="$t('message.navigation.management.allCondominiumOwners')"
            :count="nbHits"
            :count-text="$t('message.onBoarding.buildings.objects.ownerAgreement.condominiumOwner')"
            :clear-filter="checkfilter"
            @clear-filter="clickOnClear" />
        </template>
      </ais-stats>
      <!-- Filter -->
      <div class="row align-items-center">
        <div class="col-12 col-md mb-2 mb-md-0">
          <ais-search-box>
            <template #default="{ currentRefinement }">
              <debounced-algolia-search-box
                ref="debouncedAlgolia"
                :default-value="currentRefinement"
                :delay="300" />
            </template>
          </ais-search-box>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="status"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchStatus', items) }}
              <coozzy-multiselect
                v-model="searchStatus"
                :options="sortedFilterValues(items, customLabelStatus)"
                :multiple="true"
                :placeholder="$t('message.generic.currentStatus')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelStatus"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="obj.address.zip"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchZip', items) }}
              <coozzy-multiselect
                v-model="searchZip"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.generic.zip')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="obj.category"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchCategories', items) }}
              <coozzy-multiselect
                v-model="searchCategories"
                :options="sortedFilterValues(items, customLabelCategory)"
                :multiple="true"
                :placeholder="$t('message.onBoarding.buildings.objects.category.title')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelCategory"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="obj.floor"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchFloor', items) }}
              <coozzy-multiselect
                v-model="searchFloor"
                :options="sortedFloor(items)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.floor')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelFloor"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="refineWithNegativeNumbers(refine, $event.value)"
                @remove="refineWithNegativeNumbers(refine, $event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="obj.rooms"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchRoom', items) }}
              <coozzy-multiselect
                v-model="searchRoom"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.rooms')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div
          v-if="isAdminModule"
          class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="obj.assignees.administration.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchAdminAssignee', items) }}
              <coozzy-assignee-multiselect
                v-model="searchAdminAssignee"
                :options="getAssigneeData(items)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.adminAssignee')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelAssignee"
                :track-by="'value'"
                label="name"
                group-values="employees"
                group-label="employee"
                :group-select="true"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div
          v-if="isAssetModule"
          class="col-12 col-md-2 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="obj.assignees.asset.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchAssetAssignee', items) }}
              <coozzy-assignee-multiselect
                v-model="searchAssetAssignee"
                :options="getAssigneeData(items)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.adminAssignee')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelAssignee"
                :track-by="'value'"
                label="name"
                group-values="employees"
                group-label="employee"
                :group-select="true"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div
          v-if="isOwnerModule"
          class="col-12 col-md-2 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="obj.assignees.owner.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchOwnerAssignee', items) }}
              <coozzy-assignee-multiselect
                v-model="searchOwnerAssignee"
                :options="getAssigneeData(items)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.adminAssignee')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelAssignee"
                :track-by="'value'"
                label="name"
                group-values="employees"
                group-label="employee"
                :group-select="true"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div
          v-if="isAdminModule"
          class="col-12 col-md-2 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="owner.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchAdminOwner', items) }}
              <coozzy-multiselect
                v-model="searchAdminOwner"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.owner')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                label="name"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-2">
          <ais-refinement-list
            attribute="preferredCommunicationChannel"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchPreferredCommunicationChannel', items) }}
              <coozzy-multiselect
                v-model="searchPreferredCommunicationChannel"
                :options="sortedFilterValues(sortOptions(items), customLabel)"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelChannel"
                :placeholder="$t('message.contact.preferredCommunicationChannel')"
                :track-by="'value'"
                label="name"
                :preselect-first="false"
                :taggable="false"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
      </div>
      <!-- eslint-disable vue/attribute-hyphenation -->
      <ais-configure
        :hitsPerPage="nbrPerPage"
        :filters="filterQuery" />
      <!-- eslint-disable vue/attribute-hyphenation -->
      <div
        id="rowConfig"
        class="row mt-2 align-items-center">
        <div class="col-md-2">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator) && (isOwnerModule || isAssetModule)"
              :disabled="selectedEntries.length === 0"
              @click="createTicket('TICKET_TYPE_TASK')">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown
              v-if="!isOwnerModule && !isAssetModule && isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator)"
              design="transparent"
              size="sm"
              class="w-100 ticket-dropdown-bulk-action"
              :class="selectedEntries.length > 0 ? 'enabled' : ''"
              :disabled="true"
              :text="$t('message.ticketOverview.createTicket')"
              dropright
              @click.prevent>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="createTicket('TICKET_TYPE_TASK')">
                {{ $t('message.ticketOverview.createTask') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="showActivity()">
              {{ $t('message.contact.activity.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="$refs['reminder-creation'].show()">
              {{ $t('message.calendar.reminder.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="serialEmail()">
              {{ selectedEntries.length === 1 ? $t('message.generic.oneEmail') : $t('message.generic.serialEmail') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="serialLetter()">
              {{ selectedEntries.length === 1 ? $t('message.generic.oneSerialLetter') : $t('message.generic.serialLetter') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider v-if="isEditor && (isAdminModule || isOwnerModule || isAssetModule)" />
            <div
              v-if="isEditor"
              class="row m-0"
              :class="selectedEntries.length === 0 || notAllStatusNotActivated ? 'coozzyDropdownItemTooltip' : ''">
              <coozzy-dropdown-item
                v-if="isAdminModule || isOwnerModule || isAssetModule"
                :disabled="selectedEntries.length === 0 || notAllStatusNotActivated"
                @click="showActivateCondominiumModal()">
                {{ $t('message.manageBuilding.bulkAction.activateCondominium') }}
              </coozzy-dropdown-item>
              <div
                v-if="isAdminModule || isOwnerModule || isAssetModule"
                class="pl-0">
                <coozzy-info-circle-icon
                  v-if="notAllStatusNotActivated"
                  v-b-tooltip.hover.html="$t('message.deleteErrors.activateCondominiumTooltip')"
                  class="mt-2" />
              </div>
            </div>
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <div
              v-if="isEditor"
              class="row m-0"
              :class="selectedEntries.length === 0 || deletableSelection">
              <coozzy-dropdown-item
                v-if="isEditor"
                class="pr-0 pl-0"
                :disabled="selectedEntries.length === 0 || deletableSelection"
                @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                {{ $t('message.generic.delete') }}
              </coozzy-dropdown-item>
              <div
                v-if="isEditor"
                class="pl-0">
                <coozzy-info-circle-icon
                  v-if="deletableSelection"
                  v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.ownerAgreement.deleteTooltip')"
                  class="mt-2" />
              </div>
            </div>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
          <ais-clear-refinements :included-attributes="['query', 'status', 'obj.address.zip', 'obj.category', 'obj.floor', 'obj.rooms', 'owner.name', 'obj.assignees.administration.name', 'obj.assignees.asset.name', 'obj.assignees.owner.name', 'preferredCommunicationChannel']">
            <template #default="{ canRefine, refine }">
              {{ setCanRefine(canRefine) }}
              <a
                v-if="canRefine"
                ref="clearClick"
                @click="initFilter(refine)" />
            </template>
          </ais-clear-refinements>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
          :class="isCompanyAdmin !== true ? 'offset-md-6' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <ais-state-results>
        <template #default="{ hits, results }">
          {{ getState(results) }}
          <b-table
            ref="tenanciesTable"
            class="mt-2"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            :fields="fields"
            :items="hits"
            @row-clicked="navigateToDetail"
            @row-middle-clicked="onMiddlelicked">
            <!-- Headings -->
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(condominiumOwner)>
              {{ $t('message.onBoarding.buildings.objects.ownerAgreement.condominiumOwner') }}
            </template>
            <template #head(status)>
              {{ $t('message.tableColumnHeadings.status') }}
            </template>
            <template #head(contractNumber)>
              {{ $t('message.tableColumnHeadings.contractNumber') }}
            </template>
            <template #head(address)>
              {{ $t('message.tableColumnHeadings.address') }}
            </template>
            <template #head(type)>
              {{ $t('message.tableColumnHeadings.type') }}
            </template>
            <template #head(floor)>
              {{ $t('message.tableColumnHeadings.floor') }}
            </template>
            <template #head(rooms)>
              {{ $t('message.tableColumnHeadings.rooms') }}
            </template>
            <template #head(livingArea)>
              {{ $t('message.tableColumnHeadings.livingArea') }}
            </template>
            <template #head(valueQuota)>
              <span>{{ $t('message.tableColumnHeadings.valueQuota') }}</span>
            </template>
            <template #head(ownershipStarting)>
              {{ $t('message.tableColumnHeadings.ownershipStarting') }}
            </template>
            <template #head(ownershipEnding)>
              {{ $t('message.tableColumnHeadings.ownershipEnding') }}
            </template>
            <template #head(assignee)>
              {{ $t('message.tableColumnHeadings.assignee') }}
            </template>
            <template #head(roommate)>
              {{ $t('message.tableColumnHeadings.roommate') }}
            </template>
            <template #head(tenant)>
              {{ $t('message.tableColumnHeadings.tenant') }}
            </template>
            <template #head(subCategory)>
              {{ $t('message.tableColumnHeadings.subCategory') }}
            </template>
            <template #head(objects)>
              {{ $t('message.tableColumnHeadings.objects') }}
            </template>

            <!-- Columns -->
            <template #cell(checkbox)="data">
              <!-- data needs to be used in order to work properly -->
              <coozzy-form-checkbox
                :id="'check_' + data.item.objectID"
                @change="(value) => { itemSelected(data.item, value) }" />
            </template>
            <template #cell(contractNumber)="data">
              <div
                class="color-box mr-2" />
              {{ data.item.numericId }}
            </template>
            <template #cell(address)="data">
              <address-text
                v-if="data.item.obj"
                :address="data.item.obj.address" />
            </template>
            <template #cell(status)="data">
              <div
                class="color-box mr-2"
                :class="data.item.status.replace('_offline','').replace('_online','')" />
              {{ $t(`message.onBoarding.buildings.objects.tenancies.status.${data.item.status}`) }}
            </template>
            <template #cell(type)="data">
              <template
                v-if="data.item.obj && data.item.obj.category && data.item.obj.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + data.item.obj.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(floor)="data">
              {{ data.item.obj && data.item.obj.hasOwnProperty('floor') ? formatFloor(data.item.obj.floor) : '-' }}
            </template>
            <template #cell(rooms)="data">
              {{ (data.item.obj && data.item.obj.rooms ? data.item.obj.rooms : '') | displayOptionalValue }}
            </template>
            <template #cell(livingArea)="data">
              {{
                (data.item.obj && data.item.obj && data.item.obj.livingArea ? data.item.obj.livingArea : '') | formatArea
              }}
            </template>
            <template #cell(condominiumOwner)="data">
              <template v-if="data.item.condominiumOwners.length === 0 || data.item.condominiumOwners.map(n => n.name).join(', ') === ''">
                -
              </template>
              <template v-else>
                <router-link
                  v-for="(owner, ownersIndex) in data.item.condominiumOwners"
                  :key="ownersIndex"
                  :to="redirectToContactDetail(owner.contactId)">
                  {{ owner.name }}<br>
                </router-link>
              </template>
            </template>
            <template #cell(tenant)="data">
              <template v-if="data.item.tenants && data.item.tenants.length > 0">
                <router-link
                  v-for="(tenant, indexTenant) in data.item.tenants"
                  :key="tenant.contactId"
                  :to="redirectToContactDetail(tenant.contactId)">
                  {{ tenant.name }}<span v-if="indexTenant < data.item.tenants.length - 1">,</span><br>
                </router-link>
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(valueQuota)="data">
              {{ formatValueQuota(data.item.obj.valueQuota) }}
            </template>
            <template #cell(ownershipStarting)="data">
              {{ data.item.startingAt | objectToDate }}
            </template>
            <template #cell(ownershipEnding)="data">
              {{ data.item.endingAt | objectToDate }}
            </template>
            <template #cell(assignee)="data">
              <template v-if="isAdminModule">
                {{
                  data.item.obj.assignees && data.item.obj.assignees.administration && data.item.obj.assignees.administration.name && data.item.obj.assignees.administration.name !== 'Unassigned Nicht zugeordnet Non assegnato' ? data.item.obj.assignees.administration.name : '-'
                }}
              </template>
              <template v-if="isAssetModule">
                {{
                  data.item.obj.assignees && data.item.obj.assignees.asset && data.item.obj.assignees.asset.name && data.item.obj.assignees.asset.name !== 'Unassigned Nicht zugeordnet Non assegnato' ? data.item.obj.assignees.asset.name : '-'
                }}
              </template>
              <template v-if="isOwnerModule">
                {{
                  data.item.obj.assignees && data.item.obj.assignees.owner && data.item.obj.assignees.owner.name && data.item.obj.assignees.owner.name !== 'Unassigned Nicht zugeordnet Non assegnato' ? data.item.obj.assignees.owner.name : '-'
                }}
              </template>
            </template>
            <template #cell(roommate)="data">
              <template v-if="!data.item.roommates || data.item.roommates.length === 0 || data.item.roommates.map(n => n.name).join(', ') === ''">
                -
              </template>
              <template v-else>
                <router-link
                  v-for="(roommate, roommateIndex) in data.item.roommates"
                  :key="roommateIndex"
                  :to="redirectToContactDetail(roommate.contactId)">
                  {{ roommate.name }}<br>
                </router-link>
              </template>
            </template>
            <template #cell(subCategory)="data">
              <template v-if="data.item.obj?.subCategories?.length > 0">
                <template v-for="(subCat, subCatIndex) in data.item.obj.subCategories">
                  <span
                    v-if="subCat !== 'UNDEFINED_SUB_CATEGORY'"
                    :key="subCatIndex">
                    {{ $t(`message.onBoarding.buildings.objects.subCategory.` + subCat?.toLowerCase()) }}
                  </span>
                  <template v-else>
                    -
                  </template>
                </template>
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(objects)="data">
              <template v-if="data.item.obj?.name">
                {{ data.item.obj.name }}
              </template>
              <template v-else>
                -
              </template>
            </template>
          </b-table>
        </template>
      </ais-state-results>
      <pagination-algolia
        ref="paginator"
        @changed-page="clearCheckbox()" />
    </ais-instant-search>
    <reminder-creation-modal
      ref="reminder-creation"
      :source="'condominium'"
      :references-ids="selectedEntriesIds"
      @new-reminder="clearCheckbox" />
    <create-activity-modal
      ref="activity-creation"
      :key="keyActivity"
      :references-ids="selectedEntriesContactIds"
      :ids="selectedEntriesIds"
      @activity-created="clearCheckbox" />
    <activate-condominium-modal
      v-if="selectedEntriesIds.length > 0"
      ref="activateCondominiumModal"
      :selected-entries-ids="selectedEntriesIds"
      :objects="objectsList"
      :condominiums="condominiums"
      @condo-activated="condominiumActivated" />
    <b-modal
      :id="'confirmationModalcondominium'"
      ref="confirmationModalcondominium"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div
        v-if="loadingDeletion"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col p-0">
          <p>{{ $t('message.onBoarding.deleteModals.bodyCondominiumOwner') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModalcondominium')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteCondominium()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import ObjectApi from '../misc/apis/ObjectApi'
import AddressText from '../framework/components/misc/AddressText'
import { formatting } from '@/mixins/formatting'
import { algolia } from '@/mixins/algolia'
import ActivateCondominiumModal from '@/addTenancy/ActivateCondominiumModal'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import AppRefresh from '../framework/components/RefreshAlgolia'
import DebouncedAlgoliaSearchBox from '../framework/components/DebouncedAlgoliaSearchBox'
import PaginationAlgolia from '../framework/components/PaginationAlgolia'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CoozzyAssigneeMultiselect from '../framework/components/multiselect/CoozzyAssigneeMultiselect'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { user } from '@/mixins/user'
import { tables } from '@/mixins/tables'
import { routeChecks } from '@/mixins/routeChecks'
import { mail } from '@/mixins/mail'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import AssembleListModal from '@/contact/components/AssembleListModal'
import SearchApi from '@/misc/apis/SearchApi'
import { v4 as uuidv4 } from 'uuid'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'

export default {
  name: 'CondominiumOverview',
  components: {
    CoozzyFiltersIcon,
    CoozzyInfoCircleIcon,
    CoozzyDropdownSpaceDivider,
    CoozzyButton,
    CoozzySpinner,
    CoozzyFormCheckbox,
    CoozzyFormSelect,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyMultiselect,
    CoozzyAssigneeMultiselect,
    CoozzyPageTitle,
    DebouncedAlgoliaSearchBox,
    PaginationAlgolia,
    CreateActivityModal,
    ActivateCondominiumModal,
    AppRefresh,
    ReminderCreationModal,
    AddressText,
    AssembleListModal
  },
  mixins: [user, formatting, algolia, tables, routeChecks, mail],
  metaInfo() {
    return {
      title: this.$t('message.navigation.management.allCondominiumOwners')
    }
  },
  data() {
    return {
      algoliaIndex: 'condominium',
      showFirst: false,
      checkfilter: false,
      keyActivity: 0,
      loadingDeletion: false,
      searchCategories: [],
      searchStatus: [],
      searchZip: [],
      searchFloor: [],
      searchRoom: [],
      searchAdminOwner: [],
      selectedEntries: [],
      searchAdminAssignee: [],
      searchAssetAssignee: [],
      searchOwnerAssignee: [],
      searchPreferredCommunicationChannel: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'condominiumOwner',
          label: this.$t('message.tableColumnHeadings.condominiumOwner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle status-td-icon',
          selected: true
        },
        {
          key: 'contractNumber',
          label: this.$t('message.tableColumnHeadings.contractNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'livingArea',
          label: this.$t('message.tableColumnHeadings.livingArea'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'valueQuota',
          label: this.$t('message.tableColumnHeadings.valueQuota'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'ownershipStarting',
          label: this.$t('message.tableColumnHeadings.ownershipStarting'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'ownershipEnding',
          label: this.$t('message.tableColumnHeadings.ownershipEnding'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'roommate',
          label: this.$t('message.tableColumnHeadings.roommate'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'subCategory',
          label: this.$t('message.tableColumnHeadings.subCategory'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        }
      ],
      selectedFields: [],
      condominiums: [],
      objectsList: [],
      paramsAlgolia: {
        query: '',
        params: '',
        index: ''
      },
      nbrTotalOfHits: 0,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.objectID) : this.selectedEntries.map(obj => obj.objectID)
    },
    selectedEntriesContactIds() {
      const listEntries = this.selectAllEntries ? this.allEntries.map(x => x.tenants) : this.selectedEntries.map(x => x.tenants)
      const merged = [].concat.apply([], listEntries)
      return merged.map(x => x.contactId)
    },
    notAllStatusNotActivated() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.length > 0 && listEntries.filter(x => x.status !== 'not_activated').length > 0
    },
    filterQuery() {
      let query = ''

      if (!this.isPlatformEmployee) {
        if (this.isMarketingModule) {
          query += `obj.networkPartners.combinedId:${this.accountId.toString()}-marketing`
        } else if (this.isAdminModule) {
          query += `obj.networkPartners.combinedId:${this.accountId.toString()}-management`
        } else if (this.isAccountingModule) {
          query += `obj.networkPartners.combinedId:${this.accountId.toString()}-accounting`
        } else if (this.isAssetModule) {
          query += `obj.networkPartners.combinedId:${this.accountId.toString()}-asset`
        }
      }

      return query
    },
    deletableSelection() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.some(x => x.status === 'current' || x.status === 'terminated')
    }
  },
  watch: {
    checkfilter: function () {
      this.clearCheckbox()
    },
    nbrPerPage: function () {
      if (this.showFirst) {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.refresh()
          }
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.redirect(this.$route.query.page)
          }
        })
      }
      this.showFirst = true
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    selectedEntries: function (value) {
      if (value?.length > 0) {
        this.condominiums = []
        this.objectsList = []
        value.forEach(element => {
          this.objectsList.push(element.obj)
          this.condominiums.push({ id: element.objectID, objectId: element.obj.id, startingAt: element.startingAt, owner: { contactIds: element.condominiumOwners?.map(x => x.contactId) } })
        })
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        // check if we already call algolia with the same params
        if (this.nbrTotalOfHits !== this.allEntries.length) {
          this.allEntries = await this.getAllAvailableDataByIndex(this.paramsAlgolia)
        }
        this.objectsList = this.allEntries.map(x => x.obj)
        this.condominiums = this.allEntries.map(x => {
          return { id: x.objectID, objectId: x.obj.id, startingAt: x.startingAt, owner: { contactIds: x.condominiumOwners?.map(x => x.contactId) } }
        })
      } else {
        this.objectsList = this.selectedEntries.map(x => x.obj)
        this.condominiums = this.selectedEntries.map(x => {
          return { id: x.objectID, objectId: x.obj.id, startingAt: x.startingAt, owner: { contactIds: x.condominiumOwners?.map(x => x.contactId) } }
        })
      }
    }
  },
  mounted() {
    this.loadInstantSearch()
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'condo_owners_overview') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.key !== 'roommate'))
        let elm = null
        this.dataFields.forEach(element => {
          if (element.key !== 'roommate') {
            elm = { key: element.key, selected: true }
          } else {
            elm = { key: element.key, selected: false }
          }
          this.selectedFields.push(elm)
        })
      }
    }).catch(e => {
      console.log(e)
    }).finally(() => {
      setTimeout(() => {
        this.$refs.debouncedAlgolia?.setFocusInput()
      }, 300)
    })
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSendMailSecondaryTitle',
      'setUseBcc',
      'setSendMailContactsWithoutEmail',
      'setRenderWithObjectId',
      'setSendMailContactsWithObject',
      'setShowAllPlaceholders'
    ]),
    getState(result) {
      this.nbrTotalOfHits = result.nbHits
      const params = this.editAlgoliaParams(result.params)
      this.paramsAlgolia = {
        query: result.query,
        params: params,
        index: result.index,
        attributesToRetrieve: ['objectID', 'tenants', 'obj', 'status', 'condominiumOwners']
      }
      return ''
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.objectsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'condo_owners_overview').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    createTicket(type) {
      const defaultAssignee = this.selectedEntries.every((val, i, arr) => val.owner.id === arr[0].owner.id)
        ? this.selectedEntries[0].owner.id
: ''
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      }
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const condoOwnerIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { condoOwnerIds: condoOwnerIds, source: 'condoOwner' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { condoOwnerIds: condoOwnerIds, source: 'condoOwner' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { condoOwnerIds: condoOwnerIds, source: 'condoOwner' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { condoOwnerIds: condoOwnerIds, source: 'condoOwner' } }, () => {})
      }
    },
    serialLetter() {
      const uniqKey = uuidv4()
      localStorage.setItem(uniqKey, this.selectedEntriesIds)
      this.$nextTick(() => {
        this.$router.push({
          name: 'AdminSerialLetterProcessView',
          query: {
            source: 'condominium',
            uuid: uniqKey,
            previousRoute: this.$route.fullPath
          }
        })
      })
    },
    async serialEmail() {
      this.setShowAllPlaceholders(true)
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      await this.prepareSerialEmail(listEntries)
      this.loading = false
    },
    clickOnClear() {
      this.$refs.clearClick.click()
    },
    setCanRefine(canRefine) {
      this.checkfilter = canRefine
      return ''
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    customLabelFloor(item) {
      return this.formatFloor(item.value) + ' (' + item.count + ')'
    },
    customLabelCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabel(item) {
      return item.value + ' (' + item.count + ')'
    },
    customLabelChannel(item) {
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL') {
        return this.$t('message.contact.preferredCommunicationChannelType.email') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_LETTER') {
        return this.$t('message.contact.preferredCommunicationChannelType.letter') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED') {
        return '- (' + item.count + ')'
      }
      return item.value
    },
    customLabelStatus(item) {
      return this.$t('message.onBoarding.buildings.objects.tenancies.status.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    showActivateCondominiumModal() {
      this.isSameTenant = this.selectedEntries.map(x => x.condominiumOwners[0].contactId).every(
        function(value, _, array) {
          return array[0] === value
        }
      )
      this.$refs.activateCondominiumModal.show()
    },
    openConfirmationModal() {
      this.$refs.confirmationModalcondominium.show()
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    deleteCondominium() {
      this.loadingDeletion = true
      const promises = []
      for (const condominium of this.selectedEntriesIds) {
        promises.push(ObjectApi.deleteCondominium(condominium))
      }
      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            this.$refs['app-refresh'].refresh()
            this.loadingDeletion = false
            this.$bvModal.hide('confirmationModalcondominium')
          }, 2000)
        })
        .catch(e => {
          this.loadingDeletion = false
          this.$bvModal.hide('confirmationModalcondominium')
          console.log(e)
        })
    },
    condominiumActivated() {
      setTimeout(() => {
        this.$refs['app-refresh'].refresh()
        this.selectedEntries = []
        this.handleCheckboxesInsideTable(false)
        if (this.$refs.header_checkbox) {
          this.$refs.header_checkbox.checked = false
        }
      }, 2000)
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.tenanciesTable) {
        const allCheckboxesComponents = this.$refs.tenanciesTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.objectID !== item.objectID)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    initFilter(refine) {
      this.searchZip = []
      this.searchCategories = []
      this.searchStatus = []
      this.searchFloor = []
      this.searchAdminOwner = []
      this.searchRoom = []
      this.searchAdminAssignee = []
      this.searchAssetAssignee = []
      this.searchOwnerAssignee = []
      this.searchPreferredCommunicationChannel = []
      refine()
    },
    navigateToDetail(item) {
      if (this.isOwnerModule) {
        ObjectApi.getBuildingByObjectId(item.obj.id).then(response => {
          this.$router.push({
            name: 'OwnerBuildingDetailsView',
            params: { id: response.building.id },
            query: {
              view: 'condominium',
              elementSelected: item.objectID
            }
          })
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
        })
      } else if (this.isAssetModule) {
        ObjectApi.getBuildingByObjectId(item.obj.id).then(response => {
          this.$router.push({
            name: 'AssetBuildingDetailsView',
            params: { id: response.building.id },
            query: {
              view: 'condominium',
              elementSelected: item.objectID
            }
          })
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
        })
      } else if (this.isAdminModule) {
        ObjectApi.getBuildingByObjectId(item.obj.id).then(response => {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: response.building.id },
            query: {
              view: 'condominium',
              elementSelected: item.objectID
            }
          })
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
        })
      } else if (this.isAccountingModule) {
        ObjectApi.getBuildingByObjectId(item.obj.id).then(response => {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: response.building.id },
            query: {
              view: 'condominium',
              elementSelected: item.objectID
            }
          })
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
        })
      }
    },
    onMiddlelicked(item, index, event) {
      const element = event.srcElement
      if (element.tagName !== 'A') {
        if (this.isOwnerModule) {
          ObjectApi.getBuildingByObjectId(item.obj.id).then(response => {
            const route = this.$router.resolve({
              name: 'OwnerBuildingDetailsView',
              params: { id: response.building.id },
              query: {
                view: 'condominium',
                elementSelected: item.objectID
              }
            })
            window.open(route.href)
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
          })
        } else if (this.isAssetModule) {
          ObjectApi.getBuildingByObjectId(item.obj.id).then(response => {
            const route = this.$router.resolve({
              name: 'AssetBuildingDetailsView',
              params: { id: response.building.id },
              query: {
                view: 'condominium',
                elementSelected: item.objectID
              }
            })
            window.open(route.href)
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
          })
        } else if (this.isAdminModule) {
          ObjectApi.getBuildingByObjectId(item.obj.id).then(response => {
            const route = this.$router.resolve({
              name: 'AdminBuildingDetailsView',
              params: { id: response.building.id },
              query: {
                view: 'condominium',
                elementSelected: item.objectID
              }
            })
            window.open(route.href)
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
          })
        } else if (this.isAccountingModule) {
          ObjectApi.getBuildingByObjectId(item.obj.id).then(response => {
            const route = this.$router.resolve({
              name: 'AccountingBuildingDetailsView',
              params: { id: response.building.id },
              query: {
                view: 'condominium',
                elementSelected: item.objectID
              }
            })
            window.open(route.href)
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
          })
        }
      }
    },
    redirectToContactDetail(id) {
      if (this.isOwnerModule) {
        return { name: 'OwnerContactDetailsView', params: { id: id } }
      } else if (this.isAdminModule) {
        return { name: 'AdminContactDetailsView', params: { id: id } }
      } else if (this.isAssetModule) {
        return { name: 'AssetContactDetailsView', params: { id: id } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingContactDetailsView', params: { id: id } }
      } else {
        return { name: 'MarketingContactDetailsView', params: { id: id } }
      }
    },
    getAssigneeData(items) {
      items.forEach(element => {
        const emp = this.employeeList.find(x => (x.profile.lastName + ' ' + x.profile.firstName) === element.label)
        if (emp) {
          element.active = !emp.blocked
        }
      })
      return [{
        employee: this.$t('message.search.filterAll'),
        employees: items
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
