<template>
  <section class="w-100">
    <div
      v-if="index !== -1"
      :id="`settlement-${target}-${index}`"
      v-b-toggle="`collapse-settlement-${target}-${index}`"
      class="col pt-3 mb-0">
      <h5
        v-if="title === ''"
        class="cursor-pointer hover width-only-content">
        n/a
      </h5>
      <h5
        v-else
        class="cursor-pointer hover width-only-content">
        {{ title }}
      </h5>
    </div>
    <div class="col-12 px-0">
      <b-collapse
        :id="`collapse-settlement-${target}-${index}`"
        ref="collapse"
        :visible="expandByDefault"
        class="col">
        <div class="row details-row-bg-1 py-01rem pr-3">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              ref="description"
              v-model="description"
              :is-read-only="readOnly"
              :check-valide="$v.description.$dirty && $v.description.$error ? 'is-invalid' : ''"
              :placeholder="$t('message.advertisement.description')"
              :name="$t('message.onBoarding.settlementObject.description') + (readOnly ? '' : '*')" />
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-12 col-md-3 mb-2">
            <coozzy-form-checkbox
              :id="`${settlement.id || settlement.internalId}-ownerIsDebtor`"
              :key="`${settlement.id || settlement.internalId}-ownerIsDebtor`"
              v-model="ownerIsDebtor"
              :disabled="isAlreadyActivated"
              :initial="ownerIsDebtor"
              class="align-self-center mt-3">
              {{ $t('message.onBoarding.settlementObject.ownerIsDebtor') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <label>
              {{ $t('message.onBoarding.settlementObject.ownerIsDebtor') }}
            </label>
            <span class="show-data">
              {{ ownerIsDebtor ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
            </span>
          </div>
          <template
            v-if="ownerIsDebtor">
            <div class="col-sm-12 col-md-3">
              <label :class="!readOnly ? 'readOnly-label': ''">
                {{ $t('message.onBoarding.settlementObject.billingContactId') + '*' }}
              </label>
              <span class="d-block">{{ ownerName }}</span>
            </div>
          </template>
          <template v-else>
            <div
              v-if="readOnly"
              class="col-sm-12 col-md-3">
              <label>
                {{ $t('message.onBoarding.settlementObject.billingContactId') }}
              </label>
              <div v-if="billingContactId">
                <router-link
                  :to="redirectToDetailPage(billingContactId.objectID || billingContactId.id)">
                  {{ billingContactId.name }}
                </router-link>
              </div>
              <span
                v-else
                class="d-block">-</span>
            </div>
            <div
              v-else
              class="col-sm-12 col-md-3">
              <ais-instant-search
                v-if="searchClientInternal"
                :search-client="searchClientInternal"
                :routing="routing"
                index-name="contact">
                <!-- eslint-disable vue/attribute-hyphenation -->
                <ais-configure
                  :hitsPerPage="10"
                  :filters="'active:true AND isEmployee:false AND isOnlyInterestedParty:false'" />
                <!-- eslint-disable vue/attribute-hyphenation -->
                <ais-autocomplete>
                  <template slot-scope="{ indices, refine }">
                    <label>{{ $t('message.onBoarding.settlementObject.billingContactId') + '*' }}</label>
                    <coozzy-search-multiselect
                      v-model="billingContactId"
                      :options="indices[0].hits.filter(x => x && x.objectID)"
                      :placeholder="''"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :preselect-first="false"
                      :check-valide="!ownerIsDebtor && $v.billingContactId.$dirty && $v.billingContactId.$error ? 'is-invalid' : ''"
                      :disabled="ownerIsDebtor || (isAlreadyActivated && !debitUntilValidation)"
                      :taggable="false"
                      track-by="objectID"
                      label="name"
                      :with-address="true"
                      @search-change="see(refine, $event)" />
                  </template>
                </ais-autocomplete>
              </ais-instant-search>
            </div>
          </template>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label>
              {{ $t('message.addTenancy.CorrespondenceAddress') }}
            </label>
            <div v-if="correspondenceContactId">
              <router-link
                :to="redirectToDetailPage(correspondenceContactId.objectID)">
                {{ correspondenceContactId.name }}
              </router-link>
            </div>
            <span
              v-else
              class="d-block">-</span>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <ais-instant-search
              v-if="searchClientInternal"
              :search-client="searchClientInternal"
              :routing="routing"
              index-name="contact">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                :filters="'active:true AND isEmployee:false AND isOnlyInterestedParty:false'" />
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <label>{{ $t('message.addTenancy.CorrespondenceAddress') }}</label>
                  <coozzy-search-multiselect
                    v-model="correspondenceContactId"
                    :options="indices[0].hits.filter(x => x && x.objectID)"
                    :placeholder="''"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :taggable="false"
                    track-by="objectID"
                    label="name"
                    :with-address="true"
                    @search-change="see(refine, $event)" />
                </template>
              </ais-autocomplete>
            </ais-instant-search>
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem pr-3">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              ref="numericContractId"
              v-model="numericContractId"
              :disabled="true"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.settlementObject.numericContract')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.billingStart"
              type="date"
              :is-read-only="readOnly"
              :disabled="disableFields || (isAlreadyActivated && !isAccountingModule && !isAdminModule)"
              :filter="'formatDate'"
              :state="$v.billingStart.$dirty && $v.billingStart.$error ? false : null"
              :name="$t('message.onBoarding.settlementObject.billingStart') + (readOnly ? '' : '*')"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.billingEnd"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :disabled="isAlreadyActivated && !isAccountingModule && !isAdminModule"
              :name="$t('message.onBoarding.settlementObject.billingEnd')"
              :state="$v.billingEnd.$dirty && $v.billingEnd.$error ? false : null"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="additionalCostsBillingType">
              {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.title') }} {{ readOnly ? '' : '*' }}
              <coozzy-info-circle-icon
                v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.tooltip')" />
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="additionalCostsBillingType"
              v-model="additionalCostsBillingType"
              :disabled="disableFields || (isAlreadyActivated && !isAccountingModule && !isAdminModule)">
              <option value="UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE') }}
              </option>
              <option value="ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE') }}
              </option>
              <option value="FLATRATE_ADDITIONAL_COSTS_BILLING_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.FLATRATE_ADDITIONAL_COSTS_BILLING_TYPE') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              {{ $t(`message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.${additionalCostsBillingType}`) }}
            </span>
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem pr-3">
          <div class="col-sm-12 col-md-3">
            <label for="settlementObjectStatus">
              {{ $t('message.onBoarding.settlementObject.status') + (readOnly ? '' : '*') }}
              <coozzy-info-circle-icon
                v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.tooltip')" />
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="settlementObjectStatus"
              v-model="settlementObjectStatus"
              :state="$v.settlementObjectStatus.$dirty && $v.settlementObjectStatus.$error ? false : null"
              :disabled="disableFields">
              <option
                v-if="!hideInprogress"
                :value="null">
                -
              </option>
              <option
                v-if="!hideInprogress"
                value="inProgress">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.inProgress') }}
              </option>
              <option
                value="active">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.active') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="settlementObjectStatus === null">
                -
              </span>
              <span v-else-if="settlementObjectStatus === 'inProgress'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.inProgress') }}
              </span>
              <span v-else-if="settlementObjectStatus === 'active'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.active') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-checkbox
              v-model="opted"
              :initial="opted"
              :disabled="disableFields || readOnly || !property.opted"
              class="align-self-center mt-3">
              {{ $t('message.onBoarding.buildings.objects.opted') }}
            </coozzy-form-checkbox>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.debitUntil"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :disabled="true"
              :name="$t('message.onBoarding.settlementObject.debitUntil')"
              @blur="dateToObject()" />
          </div>
        </div>
        <settlement-object-rent-components
          v-for="(adjustment, adjIndex) in rentAdjustments"
          ref="rent-component"
          :key="adjustment.internalId"
          :settlement="settlement"
          :index="adjIndex"
          :property="property"
          :readOnly="readOnly"
          :rentComponentSelected="adjustment"
          :disabled="disableFields && !adjustment.newItem"
          @has-change="hasChanges()"
          @delete-adjustment="deleteAdjustmentClicked" />
        <div class="col-12 px-0 mt-2">
          <hr
            v-if="!readOnly"
            class="mt-0">
        </div>
        <div class="row">
          <div class="col-2">
            <coozzy-button
              v-if="!readOnly"
              class="w-100"
              design="prop-green"
              @click="addRentComponentClicked()">
              {{ $t('message.onBoarding.buildings.objects.tenancies.addNewRent') }}
            </coozzy-button>
          </div>
          <div class="col-2 offset-8">
            <coozzy-button
              v-if="!readOnly && !disableFields && (((isAccountingModule || isAdminModule) && (target !== 'building' || buildingShares.length> 0 )) || (!isAccountingModule && !activated))"
              size="small"
              design="red"
              class="float-right"
              @click="removeClicked">
              {{ $t('message.generic.form.delete') }}
            </coozzy-button>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-modal
      :id="'deleteModal'"
      ref="deleteModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodySettlement') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('deleteModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import { onboarding } from '@/mixins/onboarding'
import { validation } from '@/mixins/validation'
import { contact } from '@/mixins/contact'
import { required } from 'vuelidate/lib/validators'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import { routeChecks } from '@/mixins/routeChecks'
import { algolia } from '@/mixins/algolia'
import UserApi from '@/misc/apis/UserApi'
import { user } from '@/mixins/user'
import { dateUtils } from '@/mixins/dateUtils'
import ContactApi from '@/misc/apis/ContactApi'
import SettlementObjectRentComponents from './SettlementObjectRentComponents.vue'
import Vue from 'vue'

function billingStartMoreThanDebitUntil(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.debitUntil) {
    return true
  }
  if (vm.initialBillingStart && vm.billingStart) {
    const initialBillingStart = new Date(this.$options.filters.objectToDateInput(vm.initialBillingStart)).getTime()
    const billingStart = new Date(this.$options.filters.objectToDateInput(vm.billingStart)).getTime()
    if (initialBillingStart === billingStart) {
      return true
    }
  }
  return new Date(vm.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(vm.debitUntil))
}
function billingEndMoreThanDebitUntil(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.debitUntil) {
    return true
  }
  return new Date(vm.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(vm.debitUntil))
}

export default {
  name: 'SettlementObject',
  components: {
    SettlementObjectRentComponents,
    CoozzyInfoCircleIcon,
    CoozzyFormSelect,
    CoozzyFormCheckbox,
    CoozzyButton,
    CoozzyFormInput,
    CoozzySearchMultiselect
  },
  mixins: [onboarding, validation, contact, routeChecks, user, dateUtils, algolia],
  props: {
    index: {
      type: Number,
      default: -1
    },
    target: {
      type: String,
      default: 'building'
    },
    settlement: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    property: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      ownerName: '',
      dates: {
        billingStart: null,
        billingEnd: null,
        debitUntil: null
      },
      settlementObjectStatus: null,
      hideInprogress: false,
      isAlreadyActivated: false,
      usedContacts: [],
      initialBillingStart: null
    }
  },
  computed: {
    buildingShares() {
      if (this.accountId) {
        const objects = this.objects.flatMap(x => x.id)
        return this.shares.filter(x => x.targetAccountId === this.accountId.toString() && x.resourceIds.some(x => objects.includes(x)) && x.scope === 'ACCOUNTING_SCOPE')
      }
      return []
    },
    activated: {
      get() {
        return this.settlement.activated
      },
      set(value) {
        const newSettlement = this.settlement
        newSettlement.activated = value
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    opted: {
      get() {
        return this.settlement.opted
      },
      set(value) {
        const newSettlement = this.settlement
        newSettlement.opted = value
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    title() {
      let title = ''

      if (this.description) {
        title += ` - ${this.description}`
      }

      if (this.billingContactId) {
        title += `, ${this.billingContactId.name}`
      }

      if (this.billingStart) {
        title += ` : ${this.objectToDate(this.billingStart)}`
      }
      if (this.billingEnd) {
        title += ` - ${this.objectToDate(this.billingEnd)}`
      }
      if (title.startsWith(' - ')) {
        title = title.substr(3)
      }
      if (title.startsWith(', ')) {
        title = title.substr(2)
      }

      return title
    },
    ownerIsDebtor: {
      get() {
        return this.settlement.ownerIsDebtor
      },
      set(value) {
        const newSettlement = this.settlement
        newSettlement.ownerIsDebtor = value
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    description: {
      get() {
        return this.settlement.description
      },
      set(value) {
        const newSettlement = this.settlement
        newSettlement.description = value
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    numericContractId: {
      get() {
        return this.settlement.numericContractId
      },
      set(value) {
        const newSettlement = this.settlement
        newSettlement.numericContractId = value
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    billingContactId: {
      get() {
        return this.usedContacts.find(t => {
          return t.objectID === this.settlement.billingContactId || t.id === this.settlement.billingContactId
        })
      },
      set(value) {
        const newSettlement = this.settlement
        if (value.length > 0) {
          newSettlement.billingContactId = value[value.length - 1].objectID
          this.usedContacts.push(value[value.length - 1])
          this.usedContacts = [...this.removeDuplicatesContact(this.usedContacts)]
        } else {
          newSettlement.billingContactId = ''
        }
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    correspondenceContactId: {
      get() {
        return this.usedContacts.find(t => {
          return t.objectID === this.settlement.correspondenceContactId
        })
      },
      set(value) {
        const newSettlement = this.settlement
        if (value.length > 0) {
          newSettlement.correspondenceContactId = value[value.length - 1].objectID
          this.usedContacts.push(value[value.length - 1])
          this.usedContacts = [...this.removeDuplicatesContact(this.usedContacts)]
        } else {
          newSettlement.correspondenceContactId = ''
        }
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    billingStart: {
      get() {
        return this.settlement.billingStart ? this.settlement.billingStart : null
      },
      set(value) {
        this.setRentComponentBydate(value)
        const newSettlement = this.settlement
        newSettlement.billingStart = value
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    billingEnd: {
      get() {
        return this.settlement.billingEnd ? this.settlement.billingEnd : null
      },
      set(value) {
        const newSettlement = this.settlement
        newSettlement.billingEnd = value
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    debitUntil: {
      get() {
        return this.settlement.debitUntil ? this.settlement.debitUntil : null
      },
      set(value) {
        const newSettlement = this.settlement
        newSettlement.debitUntil = value
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    additionalCostsBillingType: {
      get() {
        return this.settlement.additionalCostsBillingType
      },
      set(value) {
        const newSettlement = this.settlement
        newSettlement.additionalCostsBillingType = value
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    rentAdjustments: {
      get() {
        const sortedRentAdjustments = this.settlement.rentAdjustments
        const current = this
        sortedRentAdjustments.sort(function(a, b) {
          if (a.byDate === null) return -1
          if (b.byDate === null) return 1
          return current.$options.filters.objectToDateInput(a.byDate) < current.$options.filters.objectToDateInput(b.byDate) ? 1 : -1
        })
        return sortedRentAdjustments
      },
      set(value) {
        const newSettlement = this.settlement
        newSettlement.rentAdjustments = value
        this.dispatchSettlementUpdate(newSettlement)
      }
    },
    disableFields() {
      return this.settlement.id && this.debitUntil && this.initialBillingStart && this.dateObjectToTimeStamp(this.initialBillingStart) < this.dateObjectToTimeStamp(this.debitUntil)
    },
    debitUntilValidation() {
      return this.settlement.id && (!this.debitUntil || (this.debitUntil && this.initialBillingStart && this.dateObjectToTimeStamp(this.debitUntil) < this.dateObjectToTimeStamp(this.initialBillingStart)))
    }
  },
  watch: {
    settlement: {
      deep: true,
      handler: function () {
        this.$emit('change-settlement')
      }
    },
    ownerIsDebtor: function (value) {
      if (value) {
        this.billingContactId = ''
      }
    },
    settlementObjectStatus: function (newVal, oldVal) {
      if ((oldVal === 'inProgress' || oldVal === null)) {
        this.settlement.statusEdited = true
      }
      if (newVal === 'active') {
        this.activated = true
      } else if (newVal === 'inProgress') {
        this.activated = false
      }
    }
  },
  mounted() {
    this.loadInstantSearchInternal()
    this.dates.billingStart = this.$options.filters.objectToDateInput(this.billingStart)
    this.dates.billingEnd = this.$options.filters.objectToDateInput(this.billingEnd)
    this.dates.debitUntil = this.$options.filters.objectToDateInput(this.debitUntil)
    this.initialBillingStart = this.billingStart
    if (this.settlement.activated === true) {
      this.settlementObjectStatus = 'active'
      this.hideInprogress = true
      this.isAlreadyActivated = true
    } else if (this.settlement.activated === false) {
      this.settlementObjectStatus = 'inProgress'
    }
    const usedContacts = []
    if (this.settlement.billingContactId !== '') {
      usedContacts.push(this.settlement.billingContactId)
    }
    if (this.settlement.correspondenceContactId !== '') {
      usedContacts.push(this.settlement.correspondenceContactId)
    }
    this.availableTenants(usedContacts)
    this.loadCurrentOwner()
    let i = 0
    this.settlement.rentAdjustments.forEach(x => {
      x.internalId = Date.now() + i
      x.newItem = false
      i = i + 1
    })
  },
  methods: {
    setRentComponentBydate(billingStart) {
      if (this.$refs['rent-component']) {
        this.$refs['rent-component'][0].setBydate(billingStart)
      }
    },
    loadCurrentOwner() {
      UserApi.getAccount(this.property.ownerId)
        .then(response => {
          if (response.account.company) {
            this.ownerName = response.account.company.name
          }
          if (response.account.individual) {
            this.ownerName = response.account.individual.firstName.substr(0, 1) + '. ' + response.account.individual.lastName
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    hasChanges() {
      this.$emit('change-settlement')
      this.dispatchSettlementUpdate(this.settlement)
    },
    deleteAdjustmentClicked(index) {
      this.$delete(this.settlement.rentAdjustments, this.settlement.rentAdjustments.findIndex(adj => adj.internalId === index))
      this.$emit('change-settlement')
      this.dispatchSettlementUpdate(this.settlement)
    },
    addRentComponentClicked() {
      const newAdjustment = {
        additionalValues: [],
        internalId: Date.now(),
        advancePaymentValues: [],
        byDate: null,
        documentIds: [],
        dueDates: [],
        flatRateValues: [],
        newItem: true
      }
      if (this.rentAdjustments.length === 0) {
        newAdjustment.byDate = this.billingStart
      }
      this.rentAdjustments.push(newAdjustment)
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return { name: 'OwnerContactDetailsView', params: { id: contactId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetContactDetailsView', params: { id: contactId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingContactDetailsView', params: { id: contactId } }
        } else {
          return { name: 'AdminContactDetailsView', params: { id: contactId } }
        }
      }
    },
    objectToDate(object) {
      return object && object.year !== -1 && object.year > 0 ? ('0' + object.day).slice(-2) + '.' + ('0' + object.month).slice(-2) + '.' + object.year : ''
    },
    dispatchSettlementUpdate(newSettlement) {
      if (this.target === 'building') {
        this.$store.dispatch('onboarding/updateSettlementObjects', newSettlement)
      }
    },
    dateToObject() {
      this.billingStart = this.toObject(this.dates.billingStart)
      this.billingEnd = this.toObject(this.dates.billingEnd)
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    removeConfirmed() {
      if (this.target === 'building') {
        this.removeSettlementObjects(this.settlement)
      }
      this.$refs.deleteModal.hide()
      this.$emit('removeConfirmed', this.settlement)
    },
    isInvalid() {
      this.$v.$touch()
      let valid = true
      if (this.$v.$invalid) {
        this.scrollToError()
        valid = false
        if (this.$v.billingStart.$dirty && this.$v.billingStart.$error && !this.$v.billingStart.billingStartMoreThanDebitUntil) {
          Vue.toasted.show(this.$t('message.savingErrors.startDateBeforeDebit', { debitUntil: this.$options.filters.objectToDate(this.debitUntil) }), {
            type: 'error'
          })
        }
        if (this.$v.billingEnd.$dirty && this.$v.billingEnd.$error && !this.$v.billingEnd.billingEndMoreThanDebitUntil) {
          Vue.toasted.show(this.$t('message.savingErrors.endDateBeforeDebit', { debitUntil: this.$options.filters.objectToDate(this.debitUntil) }), {
            type: 'error'
          })
        }
      }
      if (this.$refs['rent-component']) {
        this.$refs['rent-component'].forEach(element => {
          if (element.isInvalid()) {
            valid = false
          }
        })
      }
      return !valid
    },
    getContact(contactId) {
      return this.usedContacts.find(contact => contact.id === contactId || contact.objectID === contactId)
    },
    getContactName(contactId) {
      const contact = this.getContact(contactId)
      return contact ? ', ' + contact.name : '-'
    },
    isCollapseOpen() {
      return this.$refs.collapse.show
    },
    see(refine, value) {
      refine(value)
    },
    availableTenants(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          this.usedContacts = this.usedContacts.concat(contacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  validations() {
    const validation = {
      billingStart: {
        required,
        billingStartMoreThanDebitUntil
      },
      billingEnd: {
        billingEndMoreThanDebitUntil
      },
      description: {
        required
      },
      settlementObjectStatus: {
        required
      }
    }
    if (!this.ownerIsDebtor) {
      validation.billingContactId = {
        required
      }
    }
    return validation
  }
}
</script>

<style lang="scss" scoped>
  .readOnly-label{
    font-size: 0.875rem !important;
  }
</style>
