<template>
  <section>
    <coozzy-card v-if="!statistics">
      <div class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
    </coozzy-card>
    <coozzy-statistics-card
      v-else
      class="h-100"
      :title="$t('message.dashboard.allRentalObjects')"
      :statistics="statistics.countOverall"
      :objects="$t('message.managementOverview.objects')">
      <div
        class="row">
        <div class="col-3 padding-y-1" />
        <div class="col padding-y-1">
          <p class="mb-0">
            {{ $t('message.dashboard.admin.vacancy') }}
            <span class="ml-1">
              <coozzy-info-circle-icon
                v-if="['Admin', 'Owner', 'Asset'].includes(moduleRoutePrefix)"
                v-b-tooltip="{container: '#allObjectTable', title: $t('message.dashboard.rentalObjects.' + moduleRoutePrefix + '.vacancy')}" />
            </span>
          </p>
        </div>
        <div class="col-3 padding-y-1">
          <p class="mb-0">
            {{ $t('message.dashboard.admin.notInMarketing') }}
            <span class="ml-1">
              <coozzy-info-circle-icon
                v-if="['Admin', 'Owner', 'Asset'].includes(moduleRoutePrefix)"
                v-b-tooltip="{container: '#allObjectTable', title: $t('message.dashboard.rentalObjects.' + moduleRoutePrefix + '.notInMarketing')}" />
            </span>
          </p>
        </div>
        <div class="col padding-y-1">
          <p class="mb-0">
            {{ $t('message.dashboard.admin.inMarketing') }}
            <span class="ml-1">
              <coozzy-info-circle-icon
                v-if="['Admin', 'Owner', 'Asset'].includes(moduleRoutePrefix)"
                v-b-tooltip="{container: '#allObjectTable', title: $t('message.dashboard.rentalObjects.' + moduleRoutePrefix + '.inMarketing')}" />
            </span>
          </p>
        </div>
        <div class="col padding-y-1">
          <p class="mb-0">
            {{ $t('message.dashboard.admin.published') }}
            <span class="ml-1">
              <coozzy-info-circle-icon
                v-if="['Admin', 'Owner', 'Asset'].includes(moduleRoutePrefix)"
                v-b-tooltip="{container: '#allObjectTable', title: $t('message.dashboard.rentalObjects.' + moduleRoutePrefix + '.published')}" />
            </span>
          </p>
        </div>
      </div>
      <div class="row border-bottom">
        <div class="col-3 padding-y-3 text-left">
          <p class="mb-0">
            {{ $t('message.dashboard.marketing.since12weeks') }}
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-red">{{ statistics.vacantSince12Weeks }}</span>
          </p>
        </div>
        <div class="col-3 padding-y-3">
          <p class="mb-0">
            <span class="value text-red">{{ statistics.notInMarketingSince12Weeks }}</span>
          </p>
        </div>
        <div class="col py-3">
          <p class="mb-0">
            <span class="value text-red">{{ statistics.inMarketingSince12Weeks }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-red">{{ statistics.publishedSince12Weeks }}</span>
          </p>
        </div>
      </div>
      <div class="row border-bottom">
        <div class="col-3 padding-y-3 text-left">
          <p class="mb-0">
            {{ $t('message.dashboard.marketing.since8weeks') }}
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-orange">{{ statistics.vacantSince8Weeks }}</span>
          </p>
        </div>
        <div class="col-3 py-3">
          <p class="mb-0">
            <span class="value text-orange">{{ statistics.notInMarketingSince8Weeks }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-orange">{{ statistics.inMarketingSince8Weeks }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-orange">{{ statistics.publishedSince8Weeks }}</span>
          </p>
        </div>
      </div>
      <div class="row border-bottom">
        <div class="col-3 padding-y-3 text-left">
          <p class="mb-0">
            {{ $t('message.dashboard.marketing.since4weeks') }}
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-green">{{ statistics.vacantSince4Weeks }}</span>
          </p>
        </div>
        <div class="col-3 padding-y-3">
          <p class="mb-0">
            <span class="value text-green">{{ statistics.notInMarketingSince4Weeks }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-green">{{ statistics.inMarketingSince4Weeks }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-green">{{ statistics.publishedSince4Weeks }}</span>
          </p>
        </div>
      </div>
      <div class="row font-weight-bold">
        <div class="col-3 padding-y-3 text-left">
          <p class="mb-0">
            {{ $t('message.dashboard.admin.overall') }}
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-blue">{{ statistics.vacantOverall }}</span>
          </p>
        </div>
        <div class="col-3 padding-y-3">
          <p class="mb-0">
            <span class="value text-blue">{{ statistics.notInMarketingOverall }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-blue">{{ statistics.inMarketingOverall }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-blue">{{ statistics.publishedOverall }}</span>
          </p>
        </div>
      </div>
    </coozzy-statistics-card>
  </section>
</template>
<script>
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyStatisticsCard from '@/framework/components/card/CoozzyStatisticsCard'
import { routeChecks } from '@/mixins/routeChecks'

export default {
  name: 'AllObjectTable',
  components: { CoozzyStatisticsCard, CoozzySpinner, CoozzyCard },
  mixins: [routeChecks],
  props: {
    statistics: {
      type: Object,
      default: null
    }

  }
}
</script>

<style lang="scss" scoped>
.padding-y-1 {
  padding-bottom: 7px !important;
  padding-top: 6px !important;
}
.padding-y-3 {
  padding-bottom: 17px !important;
  padding-top: 17px !important;
}
</style>
