<template>
  <div
    v-if="loading"
    class="text-center">
    <coozzy-spinner />
  </div>
  <section v-else>
    <div class="row">
      <div class="col">
        <form-wizard
          id="wizard"
          :hide-buttons="loading"
          step-size="xs"
          color="rgb(0, 68, 95)">
          <span slot="title" />
          <tab-content :title="$t('message.generateExcel.titleStep1')">
            <select-properties-and-buildings
              :property-ids="storedPropertyIds"
              :building-ids="storedBuildingIds"
              @selected-objects="selectedObjects" />
          </tab-content>
          <tab-content :title="$t('message.generateExcel.titleStep2')">
            <select-fields-and-parameters
              ref="selectFieldsAndParameters"
              @changed-data="changedData" />
          </tab-content>
          <template
            slot="footer"
            slot-scope="props">
            <template v-if="!loading">
              <div class="wizard-footer-left">
                <wizard-button
                  v-if="props.activeTabIndex === 0"
                  :style="props.fillButtonStyle"
                  @click.native="$router.back()">
                  {{ $t('message.generic.cancel') }}
                </wizard-button>
                <wizard-button
                  v-else
                  :style="props.fillButtonStyle"
                  @click.native="backClicked(props)">
                  {{ $t('message.generic.back') }}
                </wizard-button>
              </div>
              <div class="wizard-footer-right">
                <wizard-button
                  v-if="!props.isLastStep"
                  :disabled="!selectedBuildings.length"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="nextStep(props)">
                  {{ $t('message.generic.next') }}
                </wizard-button>
                <wizard-button
                  v-else
                  :disabled="allMandatoryFieldsFilledOut"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="() => generateExcel()">
                  {{ $t('message.generateExcel.createExcel') }}
                </wizard-button>
              </div>
            </template>
          </template>
        </form-wizard>
      </div>
    </div>
  </section>
</template>

<script>
import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import { onboarding } from '@/mixins/onboarding'
import { routeChecks } from '@/mixins/routeChecks'
import SelectPropertiesAndBuildings from '@/generateExcel/SelectPropertiesAndBuildings'
import SelectFieldsAndParameters from '@/generateExcel/SelectFieldsAndParameters'
import ObjectApi from '@/misc/apis/ObjectApi'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'

export default {
  name: 'GenerateExcelView',
  components: {
    SelectPropertiesAndBuildings,
    SelectFieldsAndParameters,
    CoozzySpinner
  },
  mixins: [formatting, obj, onboarding, routeChecks, dateUtils],
  props: {
    propertyIds: {
      type: Array,
      default() {
        return []
      }
    },
    buildingIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: true,
      storedPropertyIds: [],
      storedBuildingIds: [],
      generatedExcelParameters: {},
      selectedBuildings: [],
      checkDisableGenerateButton: 1
    }
  },
  computed: {
    allMandatoryFieldsFilledOut() {
      const p = this.generatedExcelParameters
      if (this.checkDisableGenerateButton &&
        this.selectedBuildings?.length &&
        p &&
        (p.dateFrom || p.targetDateCheckbox) &&
        p.dateTo &&
        p.fields?.length &&
        p.language &&
        p.tenancyType &&
        p.sortingType &&
        p.compositionType) {
        return false
      } else {
        return true
      }
    }
  },
  mounted() {
    this.loading = true
    this.updateStore(this.buildingIds, this.propertyIds)
  },
  methods: {
    updateStore(buildingIds, propertyIds) {
      if (buildingIds.length || propertyIds.length) {
        if (buildingIds.length) {
          localStorage.setItem('propertyIdsForGeneratingExcel', null)
        } else {
          localStorage.setItem('buildingIdsForGeneratingExcel', null)
        }
      }

      if (propertyIds.length) {
        localStorage.setItem('propertyIdsForGeneratingExcel', JSON.stringify(propertyIds))
        this.storedPropertyIds = propertyIds
      } else {
        this.storedPropertyIds = JSON.parse(localStorage.getItem('propertyIdsForGeneratingExcel')) || []
      }
      if (buildingIds.length) {
        localStorage.setItem('buildingIdsForGeneratingExcel', JSON.stringify(buildingIds))
        this.storedBuildingIds = buildingIds
      } else {
        this.storedBuildingIds = JSON.parse(localStorage.getItem('buildingIdsForGeneratingExcel')) || []
      }
      this.loading = false
    },
    nextStep(props) {
      props.nextTab()
      this.scrollToTop()
    },
    backClicked(props) {
      props.prevTab()
      this.scrollToTop()
    },
    scrollToTop() {
      this.$nextTick(() => {
        const domRect = this.$el.querySelector('#wizard')
        domRect.scrollIntoView(true)
      })
    },
    selectedObjects(objects) {
      this.selectedBuildings = objects
    },
    changedData(dateFrom, dateTo, fields, language, tenancyType, targetDateCheckbox, sortingType, compositionType, compositionLinesOrColumns) {
      this.checkDisableGenerateButton += 1
      this.generatedExcelParameters.dateFrom = dateFrom ? this.convertStartDate(dateFrom) : null
      if (this.generatedExcelParameters.dateFrom) {
        this.generatedExcelParameters.dateTo = dateTo ? this.convertEndDate(dateTo) : null
      } else {
        if (dateTo) {
          const dateParts = dateTo.split('/')
          const dateString = dateParts[2] + '/' + dateParts[1] + '/' + dateParts[0]
          this.generatedExcelParameters.dateTo = this.toObject(dateString)
        } else {
          this.generatedExcelParameters.dateTo = null
        }
      }
      this.generatedExcelParameters.fields = fields
      this.generatedExcelParameters.language = language
      this.generatedExcelParameters.tenancyType = tenancyType
      this.generatedExcelParameters.targetDateCheckbox = targetDateCheckbox
      this.generatedExcelParameters.sortingType = sortingType
      this.generatedExcelParameters.compositionType = compositionType
      this.generatedExcelParameters.compositionLinesOrColumns = compositionLinesOrColumns
    },
    convertStartDate(startDateString) {
      const startDateArray = startDateString.split('/')
      const startDate = new Date(startDateArray[1], startDateArray[0] - 1, 1)
      return startDate instanceof Date && isFinite(startDate.getTime())
        ? {
          day: startDate.getDate(),
          month: startDate.getMonth() + 1,
          year: startDate.getFullYear()
        }
        : null
    },
    convertEndDate(endDateString) {
      const endDateArray = endDateString.split('/')
      const endDate = new Date(endDateArray[1], endDateArray[0], 0)
      return endDate instanceof Date && isFinite(endDate.getTime())
        ? {
          day: endDate.getDate(),
          month: endDate.getMonth() + 1,
          year: endDate.getFullYear()
        }
        : null
    },
    async generateExcel() {
      // if (this.$refs['selectFieldsAndParameters'].isInvalid()) {
      //   return
      // }
      this.loading = true
      const p = this.generatedExcelParameters
      const rentalIndexRender = await ObjectApi.rentalIndexRender(this.selectedBuildings, p.dateTo, p.fields, p.language, p.dateFrom, p.tenancyType, p.sortingType, p.compositionType, p.compositionLinesOrColumns)

      const binary = window.atob(rentalIndexRender)
      const len = binary.length
      const buffer = new ArrayBuffer(len)
      const view = new Uint8Array(buffer)
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i)
      }
      const blob = new Blob([view], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Report.xlsx'
      link.click()
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
