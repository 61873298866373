// @ts-nocheck
import network from './network'

export default {
  listNetworkPartner(page = 0, perPage, types, subTypes, companyName, contactPersonName, searchCanton) {
    let url = `/web/v2/user/account/list/${page}?perPage=${perPage}`

    if (Array.isArray(types) && types.length > 0) {
      for (const type of types) {
        url += `&types=${type}`
      }
    }

    if (Array.isArray(subTypes) && subTypes.length > 0) {
      for (const subType of subTypes) {
        url += `&subTypes=${subType}`
      }
    }
    if (companyName) {
      url += `&companyName=${companyName}`
    }
    if (contactPersonName) {
      url += `&contactPersonName=${contactPersonName}`
    }
    for (const marketingState of searchCanton) {
      url += `&states=${marketingState}`
    }

    return network.get(url)
      .then(response => {
        return response.data
      })
  },
  newDocument(documentId, sourceAccountId, targetAccountId) {
    return network.post('/web/v2/user/partner-document/add', {
      documentId: documentId,
      sourceAccountId: sourceAccountId,
      targetAccountId: targetAccountId
    })
      .then(response => {
        return response
      })
  },
  deleteDocument(documentId, sourceAccountId, targetAccountId) {
    return network.delete(`/web/v2/user/partner-document/remove?sourceAccountId=${sourceAccountId}&targetAccountId=${targetAccountId}&documentId=${documentId}`).then(response => {
      return response.data
    })
  },
  getDocuments(sourceAccountId, targetAccountId) {
    return network.get(`/web/v2/user/partner-document/${sourceAccountId}/target/${targetAccountId}`, {
      sourceAccountId: sourceAccountId,
      targetAccountId: targetAccountId
    })
      .then(response => {
        return response
      })
  }

}
