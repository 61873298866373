<template>
  <section>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6 mb-0 table-content">
        <coozzy-card
          class="card-title-dashboard-custom">
          <h5
            class="mb-0 title-card">
            {{ $t('message.dashboard.marketing.upcomingEvents') }}<span
              class="text-blue"> | <b>{{ lastEvents.length }}</b> {{ $t('message.generic.events') }} </span>
          </h5>
          <div class="row  no-entries-table">
            <div
              v-if="lastEvents.length > 0"
              class="col">
              <b-table
                ref="eventTable"
                bordered
                responsive="true"
                stacked="md"
                selectable
                select-mode="single"
                class="m-0"
                :fields="fieldsEvent"
                :items="lastEvents.slice(0, 5)"
                @row-clicked="eventClicked">
                <!-- Headings -->
                <template #head(date)>
                  {{ $t('message.calendar.reminder.date') }}
                </template>
                <template #head(title)>
                  {{ $t('message.calendar.event.title') }}
                </template>
                <template #head(location)>
                  {{ $t('message.calendar.event.location') }}
                </template>

                <!-- Columns -->
                <template
                  #cell(date)="data">
                  <template v-if="!data.item.allDay">
                    {{ formatDateForCalendar(data.item.startDate, data.item.startTime, 'dd.mm.yyyy').substr(0, 16) }}
                  </template>
                  <template v-else>
                    {{ formatDateForCalendar(data.item.startDate, data.item.startTime, 'dd.mm.yyyy').substr(0, 10) }}
                    {{ $t('message.calendar.event.allDay') }}
                  </template>
                </template>
                <template
                  #cell(title)="data">
                  {{ truncateText(data.item.title) }}
                </template>
                <template
                  #cell(location)="data">
                  {{ truncateText(data.item.location) }}
                </template>
              </b-table>
              <div class="text-right mt-2 show-all-button">
                <a
                  class="link"
                  @click="showAllEvents">
                  {{ $t('message.dashboard.marketing.showAll') }}
                </a>
              </div>
            </div>
            <div
              v-else
              class=" col text-center mt-3">
              {{ $t('message.dashboard.marketing.noEntries') }}
            </div>
          </div>
        </coozzy-card>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 mb-0 table-content">
        <coozzy-card
          class="card-title-dashboard-custom">
          <h5
            class="mb-0 title-card">
            {{ $t('message.dashboard.marketing.reminders') }}<span
              class="text-blue"> | <b>{{ reminderFiltred.length }}</b> {{ $t('message.generic.reminders') }} </span>
          </h5>
          <div class="row no-entries-table">
            <div
              v-if="lastReminders.length > 0"
              class="col">
              <b-table
                ref="reminderTable"
                bordered
                responsive="true"
                stacked="md"
                selectable
                select-mode="single"
                class="m-0"
                :class="lastReminders.length === 0 ? 'empty-table' : ''"
                :fields="fieldsReminder"
                :items="lastReminders"
                @row-clicked="showReminderDetails">
                <!-- Headings -->
                <template #head(date)>
                  {{ $t('message.calendar.reminder.date') }}
                </template>
                <template #head(subject)>
                  {{ $t('message.calendar.reminder.subject') }}
                </template>
                <template #head(activity)>
                  {{ $t('message.calendar.reminder.activity.title') }}
                </template>
                <template #head(priority)>
                  {{ $t('message.calendar.reminder.priority.title') }}
                </template>

                <!-- Columns -->
                <template
                  #cell(date)="data">
                  <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                    {{ data.item.date | timestampToDateTime }}
                  </span>
                </template>
                <template
                  #cell(subject)="data">
                  <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                    {{ truncateText(data.item.subject) }}
                  </span>
                </template>
                <template
                  #cell(activity)="data">
                  <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                    {{
                      data.item.activity !== 'UNDEFINED_REMINDER_ACTIVITY' && data.item.activity !== '' ? $t('message.calendar.reminder.activity.' + data.item.activity) : '-'
                    }}
                  </span>
                </template>
                <template
                  #cell(priority)="data">
                  <template
                    v-if="data.item.priority === 'HIGH_REMINDER_PRIORITY'">
                    <img
                      :src="`/img/priority/prio-HIGH.svg`"
                      :alt="$t('message.calendar.reminder.priority.' + data.item.priority)"
                      class="ml-3"
                      height="15px">
                  </template>
                  <template
                    v-if="data.item.priority === 'NORMAL_REMINDER_PRIORITY'">
                    <img
                      :src="`/img/priority/prio-DEFAULT.svg`"
                      :alt="$t('message.calendar.reminder.priority.' + data.item.priority)"
                      class="ml-3"
                      height="15px">
                  </template>
                  <template
                    v-if="data.item.priority === 'LOW_REMINDER_PRIORITY'">
                    <img
                      :src="`/img/priority/prio-LOW.svg`"
                      :alt="$t('message.calendar.reminder.priority.' + data.item.priority)"
                      class="ml-3"
                      height="15px">
                  </template>
                </template>
              </b-table>
              <div class="text-right mt-2 show-all-button">
                <a
                  class="link"
                  @click="showAllReminders">
                  {{ $t('message.dashboard.marketing.showAll') }}
                </a>
              </div>
            </div>
            <div
              v-else
              class="col text-center mt-3">
              {{ $t('message.dashboard.marketing.noEntries') }}
            </div>
          </div>
        </coozzy-card>
      </div>
    </div>
    <div
      v-if="isCompanyAdmin && isEditor"
      class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-0">
        <coozzy-card
          class="card-title-dashboard-custom">
          <h5
            class="mb-0 title-card">
            {{ $t('message.marketingMessages.unreadMessages') }}<span
              class="text-blue"> | <b>{{ numberMessage }}</b></span>
          </h5>
          <div
            class="row"
            @mousemove="onMouseMove($event)">
            <div
              v-if="messageList && messageList !== undefined && messageList !== 'undefined' && messageList.length > 0"
              class="col">
              <b-table
                ref="messageTable"
                bordered
                hover
                responsive="true"
                stacked="md"
                selectable
                select-mode="single"
                :fields="fieldsMessage"
                :items="messageList"
                :tbody-tr-class="rowClass"
                @row-clicked="onRowClicked"
                @row-hovered="onRowHovredMessage"
                @row-unhovered="onRowUnHovredMessage">
                <!-- Busy state -->
                <div
                  slot="table-busy"
                  class="text-center text-danger my-2">
                  <coozzy-spinner />
                </div>

                <!-- Headings -->
                <template #head(icons) />
                <template #head(date)>
                  {{ $t('message.tableColumnHeadings.date') }}
                </template>
                <template #head(from)>
                  {{ $t('message.tableColumnHeadings.from') }}
                </template>
                <template #head(to)>
                  {{ $t('message.tableColumnHeadings.to') }}
                </template>
                <template #head(subject)>
                  {{ $t('message.tableColumnHeadings.subject') }}
                </template>
                <template #head(message)>
                  {{ $t('message.tableColumnHeadings.message') }}
                </template>

                <!-- Data -->
                <template #cell(icons)="data">
                  <coozzy-link-icon
                    v-if="isMailHasReferenceIdTicket(data.item)"
                    :id="'tooltip-target-' + data.item.id"
                    class="icon-link-ticket"
                    @click.native="routeToDetail(isMailHasReferenceIdTicket(data.item))"
                    @click.middle="openMiddleDetail(isMailHasReferenceIdTicket(data.item))" />
                  <b-tooltip
                    v-if="isMailHasReferenceIdTicket(data.item)"
                    :target="'tooltip-target-' + data.item.id"
                    triggers="hover">
                    {{ isMailHasReferenceIdTicket(data.item).key }} {{ isMailHasReferenceIdTicket(data.item).title }}
                  </b-tooltip>
                  <template
                    v-if="hasReminder(data.item.id)">
                    <coozzy-reminder-icon
                      v-if="hasReminder(data.item.id) === 'progress'"
                      class="mr-1 ml-1 reminderIcon"
                      @click.native="forwardToReminder(data.item.id)" />
                    <coozzy-check-mark-icon
                      v-else-if="hasReminder(data.item.id) === 'finish'"
                      v-b-tooltip.hover.html="$t('message.marketingMessages.reminderDone', {date: $options.filters.timestampToDate(getReminder(data.item.id)?.done)})"
                      class="mr-1 ml-1" />
                  </template>
                  <coozzy-reply-icon
                    v-if="data.item.hasReply"
                    class="mr-1 ml-1" />
                  <coozzy-forward-icon
                    v-if="data.item.hasForwarding"
                    class="mr-1 ml-1" />
                  <coozzy-confidential-icon
                    v-if="data.item.confidential"
                    class="mr-1 ml-1" />
                </template>
                <template #cell(date)="data">
                  <span>
                    {{ data.item.timestamp | timestampToDateTime }}</span>
                </template>

                <template #cell(from)="data">
                  <template v-if="getMailConnectedWithContacts(data.item).from.contactId">
                    <router-link
                      :to="getContactDetailRoute(getMailConnectedWithContacts(data.item).from.contactId)">
                      <span>
                        {{ getMailConnectedWithContacts(data.item).from.name }}<br>
                      </span>
                    </router-link>
                    <span>
                      {{ data.item.from.email }}
                    </span>
                  </template>

                  <template v-else>
                    <span>{{ data.item.from.name }}</span><br>
                    <span>{{ data.item.from.email }}</span>
                  </template>
                </template>

                <template #cell(to)="data">
                  <template
                    v-if="data.item.to.length && getMailConnectedWithContacts(data.item).to[0].contactId">
                    <router-link
                      :to="getContactDetailRoute(getMailConnectedWithContacts(data.item).to[0].contactId)">
                      <span>
                        {{ getMailConnectedWithContacts(data.item).to[0].name }}<br>
                      </span>
                    </router-link>
                    <span>{{ data.item.to[0].email }} </span>
                    <span v-if="data.item.to.length > 1">+{{ data.item.to.length - 1 }}</span>
                  </template>
                  <template v-else>
                    <template v-if="data.item.to.length === 0 && data.item.cc.length > 0">
                      <template v-if="data.item.cc.length && getMailConnectedWithContacts(data.item).cc[0].contactId">
                        <router-link :to="getContactDetailRoute(getMailConnectedWithContacts(data.item).cc[0].contactId)">
                          {{ getMailConnectedWithContacts(data.item).cc[0].name }}<br>
                        </router-link>
                        <span>
                          {{ data.item.cc[0].email }}
                        </span>
                        <span v-if="data.item.cc.length > 1">+{{ data.item.cc.length - 1 }}</span>
                      </template>
                    </template>
                    <template v-else>
                      <span>{{ data.item.to.length > 0 ? getMailConnectedWithContacts(data.item).to[0].name : '-' }}</span><br>
                      <span>{{ data.item.to.length > 0 ? data.item.to[0].email : '-' }} </span>
                      <span v-if="data.item.to.length > 1">+{{ data.item.to.length - 1 }}</span>
                    </template>
                  </template>
                </template>

                <template #cell(subject)="data">
                  <p class="fieldToShow">
                    <coozzy-caret-up-icon
                      v-if="mailboxesForCurrentUser.find(obj => obj.outboundEmail === data.item.from.email)"
                      class="d-inline" />
                    <coozzy-caret-down-icon
                      v-else
                      class="d-inline" />
                    {{ data.item.subject | displayOptionalValue }}
                    <coozzy-paper-clip-icon v-if="data.item.attachmentMediaIds.length > 0" />
                  </p>
                </template>
                <template #cell(message)="data">
                  <p class="text-msg mb-0 fieldToShow">
                    {{ data.item.bodyText }}
                  </p>
                </template>
              </b-table>
              <div class="text-right mt-2 show-all-button">
                <a
                  class="link"
                  @click="showAllMessages">
                  {{ $t('message.dashboard.marketing.showAll') }}
                </a>
              </div>
            </div>
            <div
              v-else
              class="col text-center mt-3 mb-3">
              {{ $t('message.dashboard.marketing.noEntries') }}
            </div>
          </div>
        </coozzy-card>
      </div>
    </div>
    <reminder-details-modal
      v-if="selectedReminder !== null"
      ref="detail-reminder-modal"
      :reminder-detail="selectedReminder"
      :available-employees="availableEmployees"
      @create-reminder-click="createReminderClicked"
      @update-reminder="updateReminderDetail" />
    <reminder-creation-modal
      ref="modal-reminder-creation"
      :key="selectedReminder !== null ? 'modal-reminder-creation'+selectedReminder.id : 'modal-reminder-creation'"
      source="dashboard"
      :reminder-detail="selectedReminder"
      :available-employees="availableEmployees"
      :available-contacts="usedContacts"
      @update-reminder="updateReminder" />
    <event-creation-modal
      ref="event-modal-creation"
      :key="keyId + (eventForEdit !== null ? 'event-modal-creation-'+ eventForEdit.id : 'event-modal-creation')"
      :available-employees="availableAttendees"
      :event-detail="eventForEdit"
      :reference-id="$store.getters['user/getAccountId'].toString()"
      :start-date-occurrences-response="computedStartDateOccurrencesResponse"
      :end-date-occurrences-response="computedEndDateOccurrencesResponse"
      :source="'dashbord'"
      @post-creation="postCreation"
      @remove-event="removeEvent" />
    <message-details-modal
      v-if="selectedMail"
      ref="mail-detail-modal"
      :key="selectedMail.id"
      :email="selectedMail"
      :ticket="isMailHasReferenceIdTicket(selectedMail)"
      @reply-mail="replyMail"
      @reply-all-mail="replyAllMail"
      @forward-mail="forwardMail" />
    <div
      v-if="showPopoverMessage"
      id="eventPopover"
      class="eventPopover"
      :style="{ left: page.left + 'px', top: page.top + 'px'}">
      <div
        v-if="selectedPopOverMessage"
        class="row">
        <div class="employees-calendar shadow py-3 px-4 mt-3">
          <div class="row">
            <div class="col-12 mb-1">
              <p><b>{{ selectedPopOverMessage.subject }}</b></p>
              <!-- eslint-disable vue/no-v-html -->
              <span
                style="white-space: pre-line"
                v-html="selectedPopOverMessage.bodyText" />
              <!-- eslint-enable vue/no-v-html -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showPopoverEvent"
      class="eventPopover"
      :style="{ left: page.left + 'px', top: page.top + 'px'}">
      <div
        v-if="selectedPopOverEvent"
        class="row">
        <div class="employees-calendar shadow py-3 px-4 mt-3">
          <div class="employees-calendar shadow py-3 px-4 mt-3">
            <div
              class="row">
              <div class="col-12">
                <h4 class="mb-1 font-weight-bold">
                  {{ $t('message.manageInterestedParties.eventCreation.title') }}: {{ selectedPopOverEvent.originalTitle }}
                </h4>
                <p class="mb-1">
                  {{ $t('message.navigation.calendar.title') }}: {{ selectedPopOverEventOrganizer ? selectedPopOverEventOrganizer.firstName : null }} {{ selectedPopOverEventOrganizer ? selectedPopOverEventOrganizer.lastName : null }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-1">
                <span>
                  {{ $t('message.calendar.event.location') }}: {{ selectedPopOverEvent.location }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="mb-0">
                  {{ $t('message.calendar.event.description') }}:
                </p>
                <p class="mb-0">
                  {{ getNWordsFromString(selectedPopOverEvent.description, 30, true) }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="mt-2 mb-0">
                  {{ $t('message.calendar.event.participants') }}:
                </p>
              </div>
            </div>
            <div
              v-for="attendee in selectedPopOverEvent.attendees"
              :key="attendee.email">
              <div
                class="row">
                <div class="col-10">
                  <span>
                    {{ attendee.displayName !== '' ? attendee.displayName : attendee.email }}
                  </span>
                </div>
                <div class="col-2 p-0">
                  <img
                    v-if="attendee.status === 'ATTENDEE_STATUS_NEEDS_ACTION'"
                    width="15px"
                    height="15px"
                    src="@/assets/icon/hourglass.svg"
                    :alt="'hourglass'">
                  <coozzy-success-icon v-if="attendee.status === 'ATTENDEE_STATUS_ACCEPTED'" />
                  <coozzy-fail-icon v-if="attendee.status === 'ATTENDEE_STATUS_DECLINED'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ReminderDetailsModal from '@/calendar/components/ReminderDetailsModal'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import EventCreationModal from '@/calendar/components/EventCreationModal'
import MessageDetailsModal from '@/contact/components/MessageDetailsModal'
import CalendarApi from '../../misc/apis/CalendarApi'
import MessageApi from '../../misc/apis/MessageApi'
import TicketApi from '../../misc/apis/TicketApi'
import Vue from 'vue'
import CoozzyForwardIcon from '@/framework/components/icons/CoozzyForwardIcon'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CoozzyCaretUpIcon from '@/framework/components/icons/CoozzyCaretUpIcon'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyCaretDownIcon from '@/framework/components/icons/CoozzyCaretDownIcon'
import CoozzyPaperClipIcon from '../../framework/components/icons/CoozzyPaperClipIcon'
import ContactApi from '@/misc/apis/ContactApi'
import { user } from '@/mixins/user'
import { contact } from '@/mixins/contact'
import { routeChecks } from '@/mixins/routeChecks'
import { calendar } from '@/mixins/calendar'
import { mail } from '@/mixins/mail'
import { responsivity } from '@/mixins/responsivity'
import { dateUtils } from '@/mixins/dateUtils'

export default {
  name: 'DashboardTables',
  components: {
    CoozzyCaretDownIcon,
    CoozzySpinner,
    CoozzyCard,
    CoozzyCaretUpIcon,
    ReminderCreationModal,
    EventCreationModal,
    MessageDetailsModal,
    ReminderDetailsModal,
    CoozzyPaperClipIcon,
    CoozzyForwardIcon
  },
  mixins: [routeChecks, user, contact, calendar, mail, responsivity, dateUtils],
  data() {
    return {
      keyId: '',
      listTickets: [],
      eventList: [],
      reminderList: [],
      reminderFiltred: [],
      messageList: [],
      usedContacts: [],
      selectedMail: null,
      remainderForEdit: null,
      eventForEdit: null,
      selectedReminder: null,
      showPopoverMessage: false,
      selectedPopOverMessage: null,
      showPopoverEvent: false,
      selectedPopOverEvent: null,
      isWaiting: null,
      page: {
        left: 0,
        top: 0
      },
      fieldsEvent: [
        {
          key: 'date',
          label: this.$t('message.calendar.reminder.date'),
          tdClass: 'align-middle'
        },
        {
          key: 'title',
          label: this.$t('message.calendar.event.title'),
          tdClass: 'align-middle'
        },
        {
          key: 'location',
          label: this.$t('message.calendar.event.location'),
          tdClass: 'align-middle'
        }
      ],
      fieldsReminder: [
        {
          key: 'date',
          label: this.$t('message.calendar.reminder.date'),
          tdClass: 'align-middle'
        },
        {
          key: 'subject',
          label: this.$t('message.calendar.reminder.subject'),
          tdClass: 'align-middle'
        },
        {
          key: 'activity',
          label: this.$t('message.calendar.reminder.activity.title'),
          tdClass: 'align-middle'
        },
        {
          key: 'priority',
          label: this.$t('message.calendar.reminder.priority.title'),
          tdClass: 'align-middle'
        }
      ],
      fieldsMessage: [
        {
          key: 'icons',
          label: '',
          thClass: 'align-middle adjustWidth',
          tdClass: 'align-middle text-center adjustWidth'
        },
        {
          key: 'date',
          label: this.$t('message.tableColumnHeadings.date'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'from',
          label: this.$t('message.tableColumnHeadings.from'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'to',
          label: this.$t('message.tableColumnHeadings.to'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'subject',
          label: this.$t('message.tableColumnHeadings.subject'),
          thClass: 'align-middle',
          tdClass: 'align-middle fieldToShow'
        },
        {
          key: 'message',
          label: this.$t('message.tableColumnHeadings.message'),
          thClass: 'align-middle message-text',
          tdClass: 'align-middle fieldToShow'
        }
      ]
    }
  },
  computed: {
    ...mapState('message', ['mailsLoadedAt', 'readMails']),
    ...mapGetters('message', ['getMailboxesForUserId']),
    selectedPopOverEventOrganizer() {
      if (this.selectedPopOverEvent) {
        return this.employeeList.find(employee => employee.id === this.selectedPopOverEvent.userId)
      }
      return null
    },
    employeeList() {
      if (this.getEmployees() && this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    mailboxesForCurrentUser() {
      return this.getMailboxesForUserId(this.userId)
    },
    availableAttendees() {
      const array = []
      if (this.employeeList.length !== 0) {
        this.employeeList.forEach(element => {
          const cont = {
            firstName: element.profile.firstName,
            lastName: element.profile.lastName,
            email: element.email,
            id: element.id
          }
          array.push(cont)
        })
      }
      return array
    },
    availableEmployees() {
      const array = []
      if (this.employeeList.length !== 0) {
        this.employeeList.forEach((emp) => {
          if (typeof (emp) !== 'undefined') {
            array.push({
              label: (emp.profile.firstName ? emp.profile.firstName.substr(0, 1) + '. ' : '') + emp.profile.lastName + (emp.deleted ? ` (${this.$t('message.generic.deleted')})` : ''),
              value: emp.id
            })
          }
        })
      }
      return array
    },
    lastEvents() {
      const lastEvents = []
      const eventList = this.eventList.filter(a => {
        const date = a.endDate
        const time = a.endTime
        const dateString = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`
        const eventDate = a.allDay
          ? new Date(`${dateString}T23:59:59Z`)
          : new Date(`${dateString}` +
            `T${time.hours.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}:00Z`)
        return eventDate >= new Date()
      })
      eventList.forEach(element => {
        let endDate = element.endDate
        if (element.allDay) {
          endDate = new Date(this.formatDateForCalendar(element.endDate, element.endTime))
          endDate.setDate(endDate.getDate() - 1)
          endDate = this.toObject(endDate)
        }
        const start = new Date(this.formatDateForCalendar(element.startDate, !element.startTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : element.startTime))
        const end = new Date(this.formatDateForCalendar(endDate, !element.endTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : element.endTime))
        const differenceInDays = this.getDifferenceInDays(start, end)
        if (element.allDay) {
          for (let index = 0; index < differenceInDays; index++) {
            const elm = { ...element }
//             const startDate = new Date(this.formatDateForCalendar(elm.startDate, !elm.startTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : elm.startTime))
//             startDate.setDate(startDate.getDate())
//             const elmStartDate = startDate instanceof Date && isFinite(startDate)
// ? {
//               day: startDate.getDate() + index,
//               month: startDate.getMonth() + 1,
//               year: startDate.getFullYear()
//             }
// : null
//             elm.startDate = elmStartDate
//             elm.start = elmStartDate
            elm.endDate = endDate
            elm.end = this.formatDateForCalendar(endDate, !elm.endTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : elm.endTime)
              lastEvents.push(elm)
            }
        } else {
          lastEvents.push(element)
        }
      })
      return lastEvents
    },
    eventCurrentWeek() {
      const diffFirst = new Date().getDate() - new Date().getDay() + (new Date().getDay() === 0 ? -6 : 1)
      const firstOfWeek = new Date(new Date().setDate(diffFirst))
      const diffLast = new Date().getDate() - (new Date().getDay() - 1) + 6
      const LastOfWeek = new Date(new Date().setDate(diffLast))
      return this.eventList.filter(item => {
        const dateItem = new Date(item.startDate.year + '/' + item.startDate.month + '/' + item.startDate.day)
        return dateItem >= firstOfWeek.setHours(0, 0, 0, 0) && dateItem <= LastOfWeek.setHours(0, 0, 0, 0)
      })
    },
    eventsToday() {
      return this.eventList.filter(item => {
        const dateItem = new Date(item.startDate.year + '/' + item.startDate.month + '/' + item.startDate.day)
        const endDateItem = new Date(item.endDate.year + '/' + item.endDate.month + '/' + item.endDate.day)
        const currentDate = new Date().setHours(0, 0, 0, 0)
        const check = currentDate > dateItem && currentDate < endDateItem
        return ((+dateItem) === currentDate) || check
      })
    },
    lastReminders() {
      return this.reminderFiltred ? this.reminderFiltred.slice(0, 5) : []
    },
    computedStartDateOccurrencesResponse() {
      return this.eventForEdit ? this.eventForEdit.startDate : null
    },
    computedEndDateOccurrencesResponse() {
      return this.eventForEdit ? this.eventForEdit.endDate : null
    }
  },
  watch: {
    readMails: function (newVal) {
      if (newVal && newVal.length > 0) {
        this.countUnreadMails()
        if (this.numberMessage !== '-' && this.numberMessage >= 5) {
          this.getMessageList()
        } else {
          const newEntry = newVal[newVal.length - 1]
          for (let index = 0; index < this.messageList.length; index++) {
            if (this.messageList[index].id === newEntry.mailId) {
              this.$delete(this.messageList, index)
            }
          }
        }
      }
    },
    mailsLoadedAt: function () {
      this.countUnreadMails()
    },
    mailboxesForCurrentUser: function () {
      this.getMessageList()
      this.countUnreadMails()
    },
    messageList: function () {
      this.getReminders()
      this.getTickets()
    }
  },
  mounted() {
    CalendarApi.getEventStat(this.accountId, [this.userId])
      .then(response => {
        this.eventList = response.events
        this.eventList.forEach(element => {
          element.allDay = !element.startTime ||
            !element.endTime ||
            (element.startTime.hours === 0 &&
            element.startTime.minutes === 0 &&
            element.startTime.seconds === 0 &&
            element.endTime.hours === 0 &&
            element.endTime.minutes === 0 &&
            element.endTime.seconds === 0)
        })
        this.$emit('event-current-Week-length', this.eventCurrentWeek)
        this.$emit('event-today-length', this.eventsToday ? this.eventsToday.length : 0)
      }).catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.calendar'), { type: 'error' })
      })
    this.loadEmployees()
    this.loadMailboxesForUserId(this.userId)
    if (this.mailboxesForCurrentUser.length > 0) {
      this.getMessageList()
    }
    this.countUnreadMails()
    this.getReminders()
    this.getTickets()
  },
  methods: {
    ...mapActions('message', ['loadMailboxesForUserId']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setMailsLoadedAt'
    ]),
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    hasReminder(id) {
      let item = this.reminderList.filter(x => x.referenceIds.find(y => y && y.includes(id)))
      if (item.length > 0) {
        item = item.sort((a, b) => (a.date < b.date ? 1 : -1))
        if (item[0].done === '0') {
          return 'progress'
        } else {
          return 'finish'
        }
      }
      return null
    },
    getReminder(id) {
      let item = this.reminderList.filter(x => x.referenceIds.find(y => y && y.includes(id)))
      item = item.sort((a, b) => (a.date < b.date ? 1 : -1))
      if (item.length > 0) {
        return item[0]
      }
      return null
    },
    forwardToReminder(id) {
      this.selectedReminder = this.getReminder(id)
      this.$nextTick(function () {
        this.$refs['detail-reminder-modal']?.show()
      })
    },
    getMessageList() {
      MessageApi.getListMails(
        'FILTER_FOLDER_INBOUND',
        [],
        'APPLIES',
        5,
        this.mailboxesForCurrentUser.map(mailbox => mailbox.id),
        1,
        '',
        'DESC'
      ).then(async response => {
        this.messageList = response.mails
        this.loadContacts(this.messageList.map(mail => mail.referenceIds).flat().filter(id => id.includes('contact_')))
        this.getRemindersByReferenceIds()
        this.setMailsLoadedAt(Date.now())
      }).catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.message'), { type: 'error' })
      })
    },
    loadContacts(ids) {
      ContactApi.contactResolve(ids).then(response => {
        const contacts = this.usedContacts.concat(response.persons.concat(response.companies))
        this.usedContacts = this.removeDuplicatesContact(contacts)
        this.setContacts(this.usedContacts)
      }).catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
      })
    },
    replyMail(email) {
      this.prepareReplyMail(email)
    },
    replyAllMail(email) {
      this.prepareReplyAll(email)
    },
    forwardMail(email) {
      this.prepareForwardMail(email)
    },
    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.readEntries.some(element => parseInt(element.accountId) === parseInt(this.$store.getters['user/getAccountId']))) {
          return ''
        } else {
          return 'unread-mail'
        }
      } else {
        return null
      }
    },
    onRowClicked(item, index, event) {
      if (event.target && event.target.parentElement && event.target.parentElement._prevClass && event.target.parentElement._prevClass.includes('reminderIcon')) {
        return false
      } else {
        this.showDetail(item)
      }
    },
    showDetail(mail) {
      this.selectedMail = mail
      this.$nextTick(function () {
        this.$refs['mail-detail-modal']?.show()
      })
    },
    updateReminder(reminder) {
      for (let index = 0; index < this.reminderFiltred.length; index++) {
        if (this.reminderFiltred[index].id === reminder.id) {
          if (this.reminderFiltred[index].employeeUserId === reminder.employeeUserId) {
            this.reminderFiltred.splice(index, 1, reminder)
          } else {
            this.reminderFiltred.splice(index, 1)
          }
        }
      }
      this.reminderFiltred = this.reminderFiltred.sort(function (a, b) {
        return a.date - b.date
      })
    },
    updateReminderDetail(reminder) {
      for (let index = 0; index < this.reminderFiltred.length; index++) {
        if (this.reminderFiltred[index].id === reminder.id) {
          if (this.reminderFiltred[index].done === reminder.done) {
            this.getReminders()
          }
        }
      }
    },
    showAllEvents() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCalendarOverview', params: { viewCalendar: 'list', dayToFocus: null } })
      } else if (this.isMarketingModule) {
        this.$router.push({ name: 'MarketingCalendarOverview', params: { viewCalendar: 'list', dayToFocus: null } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCalendarOverview', params: { viewCalendar: 'list', dayToFocus: null } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCalendarOverview', params: { viewCalendar: 'list', dayToFocus: null } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCalendarOverview', params: { viewCalendar: 'list', dayToFocus: null } })
      }
    },
    showAllReminders() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerRemindersView' })
      } else if (this.isMarketingModule) {
        this.$router.push({ name: 'MarketingRemindersView' })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminRemindersView' })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetRemindersView' })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingRemindersView' })
      }
    },
    showAllMessages() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerMessagesView' })
      } else if (this.isMarketingModule) {
        this.$router.push({ name: 'MarketingMessagesView' })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminMessagesView' })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetMessagesView' })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingMessagesView' })
      }
    },
    showContactDetail(contactId) {
      if (contactId !== null) {
        this.$router.push({ name: 'OwnerContactDetailsView', params: { id: contactId } })
      }
    },
    showReminderDetails(item) {
      this.selectedReminder = item
      this.$nextTick(function () {
        this.$refs['detail-reminder-modal']?.show()
      })
    },
    createReminderClicked(reminder = null) {
      if (reminder !== null) {
        this.selectedReminder = reminder
      } else {
        this.selectedReminder = null
      }
      this.$nextTick(function () {
        this.$refs['modal-reminder-creation']?.show()
      })
    },
    eventClicked(event = null) {
      this.keyId = Math.random().toString(36).substr(2, 5)
      if (event !== null) {
        this.eventForEdit = event
      } else {
        this.eventForEdit = null
      }
      this.$nextTick(function () {
        this.$refs['event-modal-creation']?.show()
      })
    },
    postCreation(events) {
      events.forEach(event => {
        const indexToEdit = this.eventList.findIndex(x => event.url.includes(x.id) || event.url === x.url)
        if (indexToEdit !== -1) {
          this.$set(this.eventList, indexToEdit, event)
        }
        this.eventList = this.eventList.sort(function (a, b) {
          return a.startDate.year - b.startDate.year || a.startDate.month - b.startDate.month || a.startDate.day - b.startDate.day
        })
      })
    },
    removeEvent(id) {
      for (let index = 0; index < this.eventList.length; index++) {
        if (this.eventList[index].id === id) {
          this.eventList.splice(index, 1)
        }
      }
    },
    truncateText(value) {
      if (value === null || value === '' || value === undefined || value === -1 || value === '-1') {
        return '-'
      } else {
        if (value.length > 30) {
          return value.substring(0, 30) + '...'
        }
        return value
      }
    },
    isMailHasReferenceIdTicket(message) {
      return this.listTickets?.find(ticket => message.referenceIds.includes(ticket.id))
    },
    getTickets() {
      const ticketReferenceIds = this.messageList.map(mail => mail.referenceIds).flat().filter(x => x && x.includes('ticket_'))
      if (ticketReferenceIds.length > 0) {
        TicketApi.getTicketByIds(ticketReferenceIds)
          .then(response => {
            this.listTickets = response.tickets
          })
      }
    },
    getReminders() {
      CalendarApi.getReminders(this.$store.getters['user/getAccountId'], [this.$store.getters['user/getUserId']], null)
        .then(response => {
          this.reminderFiltred = response.reminders.filter(x => x.done === '0')
          const ids = this.reminderFiltred.filter(x => x.contactId !== '').map(x => x.contactId)
          this.loadContacts(ids)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.reminders'), { type: 'error' })
        })
    },
    getRemindersByReferenceIds() {
      CalendarApi.getRemindersByReferenceIds(this.messageList.map(mail => mail.id), this.$store.getters['user/getAccountId'], true)
        .then(response => {
          this.reminderList = response.reminders
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.reminders'), { type: 'error' })
        })
    },
    onRowHovredMessage(item, index, event) {
      this.selectedPopOverMessage = item
    },
    onRowUnHovredMessage(item, index, event) {
      this.selectedPopOverMessage = null
    },
    onMouseMove(e) {
      this.showPopoverMessage = this.hasSomeParentTheClass(e.srcElement, 'fieldToShow')
      if (this.showPopoverMessage && document.getElementById('eventPopover')) {
        const tooltipWidth = document.getElementById('eventPopover').clientWidth
        const tooltipHeight = document.getElementById('eventPopover').clientHeight
        const windowWidth = window.innerWidth
        const windowHeight = document.body.clientHeight
        if ((windowWidth - 100) >= (tooltipWidth + e.pageX)) {
          this.page.left = e.pageX
        } else {
          this.page.left = e.pageX - tooltipWidth
        }
        if ((windowHeight - 100) >= (tooltipHeight + e.pageY)) {
          this.page.top = e.pageY
        } else {
          this.page.top = e.pageY - tooltipHeight
        }
      }
    },
    getMessageText(bodyText) {
      if (bodyText) {
        return this.getTooltipCorrectString(bodyText).trim()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.message-text) {
  width: 330px;
}
:deep(.adjustWidth) {
  max-width: 55px;
  padding-right: 0 !important;
    > div {
    max-width: 50px;
    }
}
p.text-msg {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  line-break: anywhere;
}

.no-entries-table {
  min-height: 253px;
}

.show-all-button {
  position: absolute;
  bottom: 2px;
  right: 30px;
}

.text-gray {
  color: $color-text-grey-light;
}

.card-title-dashboard-custom :deep(.card-title) {
  background-color: rgba(0, 68, 95, .06);
  margin-bottom: 0;
  font-size: 1.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  line-height: 3rem;
}

.card-title-dashboard-custom :deep(.card-body) {
  padding: 0;
}

.card-title-dashboard-custom :deep(table.table) {
  padding-bottom: 2rem;
  margin-bottom: 0;
  box-shadow: none;
}

.card-title-dashboard-custom :deep(thead tr th) {
  border-bottom: 1px solid rgba(0, 68, 95, 0.15);
  background: none;
}

.card-title-dashboard-custom :deep(tbody tr) {
  border-bottom: 1px solid rgba(0, 68, 95, 0.15);
}

.card-title-dashboard-custom :deep(tr td div) {
  overflow-wrap: anywhere;
}

.title-card {
    background-color: $color-bg-light-blue;
    border: none;
    position: relative;
    padding: 0 1.25rem;
    margin-bottom: 0;
    line-height: 3rem;
    border-top-left-radius: 0.2rem !important;
    border-top-right-radius: 0.2rem !important;
}
.eventPopover {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  width: 270px !important;
  .employees-calendar{
    width: 100%;
    border-radius: 25px;
    background: #ededed;
    color: black;
    font-size: 14px;
    max-width: 500px;
    h4 {
      font-size: 20px;
    }
    span {
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 10;
      -webkit-line-clamp: 10; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}
</style>
