<template>
  <section class="container">
    <coozzy-page-title :title="$t('message.registration.title')" />
    <coozzy-card>
      <div class="row">
        <div class="col-12 col-md-3 mb-3">
          <span class="font-weight-bold">{{ $t('message.generic.email') }}</span>
        </div>
        <div class="col-12 col-md-9 mb-3">
          {{ tokenData.email }}
        </div>
        <div class="col-12 col-md-3 mb-3">
          <span class="font-weight-bold">{{ $t('message.generic.name') }}</span>
        </div>
        <div class="col-12 col-md-9 mb-3">
          {{ tokenData.companyName ? tokenData.companyName : `${tokenData.firstName} ${tokenData.lastName}` }}
        </div>
        <div class="col-12 col-md-3 mb-3">
          <span class="font-weight-bold">{{ $t('message.registration.password') }}</span>
        </div>
        <div class="col-12 col-md-9 mb-3">
          <coozzy-form-input
            v-model="password"
            :state="$v.password.$dirty && $v.password.$error ? false : null"
            type="password" />
        </div>
        <div class="col-12 col-md-3 mb-3">
          <span class="font-weight-bold">{{ $t('message.registration.repeatPassword') }}</span>
        </div>
        <div class="col-12 col-md-9 mb-3">
          <coozzy-form-input
            v-model="repeatPassword"
            :state="$v.repeatPassword.$dirty && $v.repeatPassword.$error ? false : null"
            type="password" />
        </div>
        <div class="col-12">
          <coozzy-button
            design="prop-green"
            class="float-right"
            @click="setPasswordClicked">
            {{ $t('message.registration.setPassword') }}
          </coozzy-button>
        </div>
      </div>
    </coozzy-card>
  </section>
</template>

<script>
import Vue from 'vue'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import UserApi from '@/misc/apis/UserApi'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { required, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'RegistrationView',
  components: { CoozzyButton, CoozzyFormInput, CoozzyCard, CoozzyPageTitle },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      tokenResolved: false,
      tokenData: {
        companyName: '',
        email: '',
        gender: '',
        firstName: '',
        lastName: ''
      },
      password: '',
      repeatPassword: ''
    }
  },
  mounted() {
    this.loading = true
    UserApi.resolveToken(this.token)
      .then(response => {
        this.tokenData = response
        this.tokenResolved = true
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.registrationToken'), { type: 'error' })
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    setPasswordClicked() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        UserApi.registerNewUser(this.token, this.password)
          .then(response => {
            this.$router.push({ name: 'Login' })
          })
          .catch(e => {
            if (e.response.data.code === 3) {
              Vue.toasted.show(this.$t('message.savingErrors.weakPassword'), { type: 'error' })
            }
          })
      }
    }
  },
  validations: {
    password: {
      required
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
