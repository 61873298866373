<template>
  <div>Logging out...</div>
</template>

<script>
export default {
  name: 'LogoutView',
  created() {
    this.$auth.logOut()
  }
}
</script>

<style lang="scss" scoped>

</style>
