import Vue from 'vue'
import store from '../store'
import TicketApi from '@/misc/apis/TicketApi'

interface StateType {
  countTickets: Number;
  loading: boolean;
}

interface ContextType {
  commit: Function;
  state: StateType;
}
export default {
  namespaced: true,
  state: {
    countTickets: 0
  },
  getters: {
    getCountTickets(state: StateType) {
      return state.countTickets
    }
  },
  mutations: {
    setCountTickets(state: StateType, value: Number) {
      Vue.set(state, 'countTickets', value)
    },
    setLoading: (state: StateType) => {
      state.loading = true
    }
  },
  actions: {
    setCountTickets: ({ commit }: ContextType, value: Number) => {
      commit('setCountTickets', value)
    },
    loadCountTickets: async ({ commit, state }: ContextType, force: Boolean) => {
      return new Promise((resolve, reject) => {
        let userIds: string[] = []
        if (localStorage.getItem('filterAssigneeTicketOverview')) {
          const splitIds = localStorage.getItem('filterAssigneeTicketOverview')?.split(',')
          userIds = splitIds.map(id => id !== 'unassigned' ? id : '')
        }
        // Setup watcher for accountId if not yet available
        if (!store.getters['user/getUserId']) {
          commit('setLoading')
          store.watch((watchedState, getters) => getters['user/getUserId'], () => {
            TicketApi.countMyUnreadTickets(userIds).then((response) => {
                commit('setCountTickets', response.amount)
                resolve(state.countTickets)
              })
              .catch(e => {
                console.error(e)
                reject(e)
              })
          })
        } else if (force) {
          // If force OR loading is not already triggered and stored tickets count are older than 30 seconds -> Load new data
          commit('setLoading')
          // Load and sort users
          TicketApi.countMyUnreadTickets(userIds).then((response) => {
            commit('setCountTickets', response.amount)
            resolve(state.countTickets)
          })
          .catch(e => {
            console.error(e)
            reject(e)
          })
        } else {
          resolve(state.countTickets)
        }
      })
    }
  }
}
