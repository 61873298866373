<template>
  <div>
    <div class="col-12">
      <h5>{{ $t('message.applicationForm.currentLandlord') }}</h5>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-6">
        <coozzy-form-checkbox
          v-model="hasNoCurrentLandlord"
          :initial="hasNoCurrentLandlord">
          {{ $t('message.applicationForm.noCurrentLandlord') }}
        </coozzy-form-checkbox>
      </div>
    </div>
    <div
      v-if="!hasNoCurrentLandlord"
      class="row">
      <div
        class="col-12"
        :class="individualId ? 'col-md-6' : ''">
        <coozzy-form-input
          v-model="landlordName"
          :name="$t('message.applicationForm.landlordName') + (isCurrentLandLordRequired ? ' *' : '')"
          type="text"
          :state="($v.landlordName && $v.landlordName.$dirty && $v.landlordName.$error) ? false : null" />
      </div>
      <div class="col-12 col-md-6">
        <coozzy-form-input
          v-model="landlordPersonName"
          :name="$t('message.generic.contactPerson') + (isCurrentLandLordRequired ? ' *' : '')"
          type="text"
          :state="($v.landlordPersonName && $v.landlordPersonName.$dirty && $v.landlordPersonName.$error) ? false : null" />
      </div>
      <div class="col-12 col-md-6">
        <coozzy-form-input
          v-model="landlordPhone"
          :name="$t('message.generic.phoneNumber') + (isCurrentLandLordRequired ? ' *' : '')"
          type="text"
          :state="($v.landlordPhone && $v.landlordPhone.$dirty && $v.landlordPhone.$error) ? false : null" />
      </div>
      <div class="col-12 col-md-6">
        <coozzy-form-input
          v-model="landlordEmail"
          :name="$t('message.generic.email') + (isCurrentLandLordRequired ? ' *' : '')"
          type="text"
          :state="($v.landlordEmail && $v.landlordEmail.$dirty && $v.landlordEmail.$error) ? false : null" />
      </div>
      <div class="col-12 col-md-6">
        <coozzy-form-input
          ref="rentedSince"
          v-model="dates.rentedSince"
          :filter="'formatDate'"
          :name="$t('message.applicationForm.rentedSince') + (isCurrentLandLordRequired ? ' *' : '')"
          type="date"
          :state="($v.rentedSince && $v.rentedSince.$dirty && $v.rentedSince.$error) ? false : null"
          @blur="dateToTimestamp()" />
      </div>
      <div class="col-12 col-md-6">
        <!-- <coozzy-form-input
          v-model="previousGrossRent"
          :name="$t('message.applicationForm.previousGrossRent')"
          type="text" /> -->
        <coozzy-form-input-numeric
          v-model="previousGrossRent"
          type="number"
          :filter="'formatPrice'"
          :min="0"
          placeholder="CHF"
          :is-decimal="true"
          :check-valide="$v.previousGrossRent && $v.previousGrossRent.$dirty && $v.previousGrossRent.$error ? 'is-invalid' : ''"
          :name="$t('message.applicationForm.previousGrossRent') + (isCurrentLandLordRequired ? ' *' : '')" />
      </div>
      <div class="col-12 col-md-6">
        <label :for="`cancelledByTenant${individualId}`">{{ $t('message.applicationForm.cancelledByTenant') + (isCurrentLandLordRequired ? ' *' : '') }}</label>
        <coozzy-form-select
          :id="`cancelledByTenant${individualId}`"
          v-model="cancelledByTenant"
          :state="($v.cancelledByTenant && $v.cancelledByTenant.$dirty && $v.cancelledByTenant.$error) ? false : null">
          <option value="UNDEFINED_VALUE">
            -
          </option>
          <option value="APPLIES">
            {{ $t('message.generic.selectField.yes') }}
          </option>
          <option value="DOES_NOT_APPLY">
            {{ $t('message.generic.selectField.no') }}
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-12 col-md-6">
        <coozzy-form-input
          v-model="relocationReason"
          :name="$t('message.applicationForm.relocationReason') + (isCurrentLandLordRequired ? ' *' : '')"
          type="text"
          :state="($v.relocationReason && $v.relocationReason.$dirty && $v.relocationReason.$error) ? false : null" />
      </div>
    </div>
  </div>
</template>

<script>
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { mapGetters, mapMutations } from 'vuex'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { validation } from '@/mixins/validation'
import { required, email, not } from 'vuelidate/lib/validators'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric.vue'

export default {
  name: 'ApplicationFormTenancy',
  components: { CoozzyFormInputNumeric, CoozzyFormSelect, CoozzyFormCheckbox, CoozzyFormInput },
  mixins: [validation],
  props: {
    company: {
      type: Object,
      default: null
    },
    individualId: {
      type: String,
      default: ''
    },
    sending: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dates: {
        rentedSince: null
      }
    }
  },
  computed: {
    cancelledByTenant: {
      get() {
        return this.item.tenancy.cancelledByTenant
      },
      set(value) {
        const i = this.item
        i.tenancy.cancelledByTenant = value
        this.updateItem(i)
      }
    },
    hasNoCurrentLandlord: {
      get() {
        return !this.item.tenancy.hasCurrentLandlord
      },
      set(value) {
        const i = this.item
        i.tenancy.hasCurrentLandlord = !value
        this.updateItem(i)
      }
    },
    ...mapGetters('applicationForm', ['getIndividualByInternalId']),
    item() {
      if (this.company !== null) {
        return this.company
      }
      return this.getIndividualByInternalId(this.individualId)
    },
    isMainIndividual() {
      return this.item.mainIndividual
    },
    landlordEmail: {
      get() {
        return this.item.tenancy.landlordEmail
      },
      set(value) {
        const i = this.item
        i.tenancy.landlordEmail = value
        this.updateItem(i)
      }
    },
    landlordName: {
      get() {
        return this.item.tenancy.landlordName
      },
      set(value) {
        const i = this.item
        i.tenancy.landlordName = value
        this.updateItem(i)
      }
    },
    landlordPersonName: {
      get() {
        return this.item.tenancy.landlordPersonName
      },
      set(value) {
        const i = this.item
        i.tenancy.landlordPersonName = value
        this.updateItem(i)
      }
    },
    landlordPhone: {
      get() {
        return this.item.tenancy.landlordPhone
      },
      set(value) {
        const i = this.item
        i.tenancy.landlordPhone = value
        this.updateItem(i)
      }
    },
    relocationReason: {
      get() {
        return this.item.tenancy.relocationReason
      },
      set(value) {
        const i = this.item
        i.tenancy.relocationReason = value
        this.updateItem(i)
      }
    },
    rentedSince: {
      get() {
        return this.item.tenancy.rentedSince !== '-1' ? this.item.tenancy.rentedSince : null
      },
      set(value) {
        const i = this.item
        i.tenancy.rentedSince = value
        this.updateItem(i)
      }
    },
    previousGrossRent: {
      get() {
        return this.item.tenancy.previousGrossRent !== -1 ? this.item.tenancy.previousGrossRent : null
      },
      set(value) {
        const i = this.item
        i.tenancy.previousGrossRent = value
        this.updateItem(i)
      }
    },
    isCurrentLandLordRequired() {
      return !this.hasNoCurrentLandlord && this.object && ['HOUSE', 'APARTMENT', 'COMMERCIAL', 'GASTRONOMY'].includes(this.object.category)
    }
  },
  mounted() {
    this.dates.rentedSince = this.rentedSince ? `${this.rentedSince.year}-${this.rentedSince.month.toString().padStart(2, '0')}-${this.rentedSince.day.toString().padStart(2, '0')}` : null
  },
  methods: {
    ...mapMutations('applicationForm', ['updateIndividual', 'updateCompany']),
    updateItem(item) {
      if (this.individualId) {
        this.updateIndividual(item)
      } else {
        this.updateCompany(item)
      }
    },
    dateToTimestamp() {
      const date = new Date(this.dates.rentedSince)
      this.rentedSince = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        return true
      }
      return false
    }
  },
  validations() {
    const validationTenancy = {}
    if (this.isCurrentLandLordRequired) {
      validationTenancy.landlordName = {
        required
      }
      validationTenancy.landlordPersonName = {
        required
      }
      validationTenancy.landlordPhone = {
        required
      }
      validationTenancy.landlordEmail = {
        required,
        email
      }
      validationTenancy.rentedSince = {
        required
      }
      validationTenancy.previousGrossRent = {
        required
      }
      validationTenancy.cancelledByTenant = {
        required,
        notUndefined: not((value) => value === 'UNDEFINED_VALUE')
      }
      validationTenancy.relocationReason = {
        required
      }
    }
  return validationTenancy
  }
}
</script>

<style lang="scss" scoped>
.custom-radio {
  display: inline-block;
}
</style>
