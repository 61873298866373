import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/internationalization/languages/de'

Vue.use(VueI18n)

const fallbackLanguage = 'de'
const browserLanguage = 'de'
const loadedLanguages = [browserLanguage]

export const i18n = new VueI18n({
  locale: browserLanguage,
  fallbackLocale: fallbackLanguage,
  messages: { de: messages }
})

const getLanguageModule = function (language) {
  return import(/* webpackChunkName: "lang-[request]" */ '@/internationalization/languages/' + language)
}

getLanguageModule(browserLanguage).then((defaultLanguageModule) => {
  i18n.setLocaleMessage(browserLanguage, defaultLanguageModule.default)
})

function SetI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function LoadLanguageAsync(language) {
  if (i18n.locale !== language) {
    if (!loadedLanguages.includes(language)) {
      return getLanguageModule(language).then(message => {
        i18n.setLocaleMessage(language, message.default)
        loadedLanguages.push(language)
        return SetI18nLanguage(language)
      })
    }
    return Promise.resolve(SetI18nLanguage(language))
  }
  return Promise.resolve(language)
}
