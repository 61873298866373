<template>
  <section class="w-100">
    <div
      :id="`insurance-${index}`"
      v-b-toggle="`collapse-insurance-${index}`"
      class="col pt-3 mb-0">
      <div class="row">
        <div class="col">
          <h5
            v-if="type && type !== 'INSURANCE_TYPE_UNDEFINED'"
            class="cursor-pointer hover width-only-content">
            {{ $t('message.onBoarding.buildings.insurances.type.' + type) }}{{ insuranceCompany ? (': ' + insuranceCompany) : '' }} {{ insuranceDate.startDate ? (' ' + $options.filters.formatDate(insuranceDate.startDate)) : 'n/a' }} {{ insuranceDate.endDate ? ('- ' + $options.filters.formatDate(insuranceDate.endDate)) : '- n/a' }}
          </h5>
          <h5
            v-else
            class="cursor-pointer hover width-only-content">
            {{ $t('message.onBoarding.buildings.insurances.newInsurance') }}
          </h5>
        </div>
        <div
          class="col mr-5">
          <coozzy-dropdown
            v-if="isEditor"
            :text="$t('message.generic.actions')"
            class="w-20 sort float-right"
            size="sm">
            <coozzy-dropdown-item
              @click="readOnly ? goToEditMode() : addDocumentClicked('insurances' + index);$event.stopPropagation()">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
      </div>
    </div>
    <div class="col-12 pl-0">
      <b-collapse
        :id="`collapse-insurance-${index}`"
        :ref="'collapse'"
        :visible="expandByDefault"
        class="pl-3">
        <div class="row details-row-bg-1 py-01rem mt-3">
          <div class="col-sm-12 col-md-3">
            <label for="type">{{ $t('message.onBoarding.buildings.insurances.type.title') }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="type"
              v-model="type">
              <option value="INSURANCE_TYPE_UNDEFINED" />
              <option
                v-for="(typeValue, typeIndex) in availableTypes"
                :key="typeIndex"
                :value="typeValue">
                {{ $t('message.onBoarding.buildings.insurances.type.' + typeValue) }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">
              {{ !type || type === 'INSURANCE_TYPE_UNDEFINED' ? '-' : $t('message.onBoarding.buildings.insurances.type.' + type) }}
            </span>
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label>{{ $t('message.onBoarding.buildings.insurances.benefits.title') }}</label>
            <p
              v-if="getBenefits.length > 0"
              class="mb-0 multi-select-value">
              {{ getBenefits }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="benefits"
              v-model="benefits"
              :options="translatedBenefits"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.insurances.benefits.title') }}
            </coozzy-multiselect>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="insuranceDate.premiumPaidUntil"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.buildings.insurances.premiumPaidUpTo')"
              @blur="dateToTimestamp()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="insuranceCompany"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.buildings.insurances.company')" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="policyNumber"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.buildings.insurances.policyNumber')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="amount"
              type="number"
              :is-read-only="readOnly"
              :filter="'formatPrice'"
              placeholder="CHF"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.insurances.amount')"
              :check-valide="$v.amount.$dirty && $v.amount.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="yearlyCosts"
              type="number"
              :is-read-only="readOnly"
              :filter="'formatPrice'"
              placeholder="CHF"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.insurances.costs')"
              :check-valide="$v.yearlyCosts.$dirty && $v.yearlyCosts.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="excess"
              type="number"
              :is-read-only="readOnly"
              :filter="'formatPrice'"
              placeholder="CHF"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.insurances.excess')"
              :check-valide="$v.excess.$dirty && $v.excess.$error ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="insuranceDate.startDate"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.buildings.insurances.startingAt')"
              :state="$v.insuranceDate.startDate.$dirty && $v.insuranceDate.startDate.$error ? false : null"
              @blur="dateToTimestamp()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="insuranceDate.endDate"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.buildings.insurances.endingAt')"
              @blur="dateToTimestamp()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="insuranceDate.earliestTerminationDate"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.buildings.insurances.noticeDate')"
              :state="$v.insuranceDate.earliestTerminationDate.$dirty && $v.insuranceDate.earliestTerminationDate.$error ? false : null"
              @blur="dateToTimestamp()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="notes"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.buildings.insurances.note')" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-2 mt-3">
            <add-document-modal
              :suffix="'insurances' + index"
              :owner-id="target === 'property' ? insurance.ownerId : building.ownerId"
              @document-created="documentCreated" />
            <coozzy-button
              v-if="!readOnly"
              design="prop-green"
              class="w-100"
              @click="addDocumentClicked('insurances' + index)">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-button>
          </div>
          <div
            v-if="documents.length > 0"
            class="col-12 mt-2">
            <documents-list
              :documents-list="documents"
              :is-read-only="readOnly"
              @version-added="versionAdded"
              @document-deleted="deleteDocument" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <coozzy-button
              v-if="!readOnly"
              size="small"
              design="red"
              class="float-right"
              @click="removeClicked">
              {{ $t('message.onBoarding.buildings.insurances.deleteInsurance') }}
            </coozzy-button>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-modal
      :id="'deleteModal'"
      ref="deleteModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodyInsurance') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('deleteModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import { onboarding } from '../../../mixins/onboarding'
import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import { minValue } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import MediaApi from '@/misc/apis/MediaApi'
import Vue from 'vue'
import DocumentsList from '@/properties/components/DocumentsList.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import { user } from '@/mixins/user'
import { routeChecks } from '@/mixins/routeChecks'

function insuranceStart(value, vm) {
  if (this.insuranceDate.endDate && (this.toTimestamp(value) > this.toTimestamp(this.insuranceDate.endDate))) {
    return false
  }
  return true
}

function insuranceNotice(value, vm) {
  if ((this.insuranceDate.startDate && (this.toTimestamp(value) < this.toTimestamp(this.insuranceDate.startDate))) ||
  (this.insuranceDate.endDate && (this.toTimestamp(value) > this.toTimestamp(this.insuranceDate.endDate)))) {
    return false
  }
  return true
}

export default {
  name: 'BuildingInsurance',
  components: {
    CoozzyDropdown,
    CoozzyDropdownItem,
    DocumentsList,
    CoozzyFormInputNumeric,
    CoozzyMultiselect,
    CoozzyFormSelect,
    CoozzyFormInput,
    CoozzyButton,
    AddDocumentModal
    },
  mixins: [onboarding, validation, user, routeChecks],
  props: {
    index: {
      type: Number,
      default: 0
    },
    insurance: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    target: {
      type: String,
      default: 'building'
    },
    addNewDocument: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      availableTypes: ['INSURANCE_TYPE_FIRE_ELEMENTAL_DAMAGE', 'INSURANCE_TYPE_BUILDING_PROPERTY_LIABILITY', 'INSURANCE_TYPE_LIABILITY', 'INSURANCE_TYPE_BUILDING_PROPERTY_INSURANCE', 'INSURANCE_TYPE_LEGAL_EXPENSES', 'INSURANCE_TYPE_CONSTRUCTION_LIABILITY', 'INSURANCE_TYPE_CANTONAL_BUILDING_INSURANCE', 'INSURANCE_TYPE_ACCIDENT_INSURANCE'],
      availableBenefits: ['INSURANCE_BENEFIT_LIABILITY', 'INSURANCE_BENEFIT_WATER', 'INSURANCE_BENEFIT_LOSS_OF_RENTAL_INCOME', 'INSURANCE_BENEFIT_BURGLARY_ROBBERY', 'INSURANCE_BENEFIT_GLASS_BREAKAGE', 'INSURANCE_BENEFIT_EXTENDED_COVERAGE', 'INSURANCE_BENEFIT_BUILDING_SERVICES_FAILURE', 'INSURANCE_BENEFIT_CONVERSIONS_AND_RENOVATIONS'],
      insuranceDate: {
        startDate: null,
        endDate: null,
        earliestTerminationDate: null,
        premiumPaidUntil: null
      },
      documentList: []
    }
  },
  computed: {
    type: {
      get() {
        return this.insurance.type
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.type = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    benefits: {
      get() {
        return this.insurance.benefits.filter(con => con !== 'INSURANCE_BENEFIT_UNDEFINED').map(conTyp => {
          return {
            label: this.$t('message.onBoarding.buildings.insurances.benefits.' + conTyp),
            value: conTyp
          }
        })
      },
      set(value) {
        const conTypValues = value.map(v => v.value)
        const newInsurance = this.insurance
        newInsurance.benefits = conTypValues
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    notes: {
      get() {
        return this.insurance.notes
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.notes = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    premiumPaidUntil: {
      get() {
        return this.insurance.premiumPaidUntil
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.premiumPaidUntil = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    insuranceCompany: {
      get() {
        return this.insurance.insuranceCompany
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.insuranceCompany = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    policyNumber: {
      get() {
        return this.insurance.policyNumber
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.policyNumber = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    amount: {
      get() {
        return this.insurance.amount !== -1 ? this.insurance.amount : null
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.amount = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    yearlyCosts: {
      get() {
        return this.insurance.yearlyCosts !== -1 ? this.insurance.yearlyCosts : null
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.yearlyCosts = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    excess: {
      get() {
        return this.insurance.excess !== -1 ? this.insurance.excess : null
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.excess = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    startDate: {
      get() {
        return this.insurance.startDate
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.startDate = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    endDate: {
      get() {
        return this.insurance.endDate
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.endDate = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    earliestTerminationDate: {
      get() {
        return this.insurance.earliestTerminationDate
      },
      set(value) {
        const newInsurance = this.insurance
        newInsurance.earliestTerminationDate = value
        this.$store.dispatch('onboarding/updateInsurance', newInsurance)
      }
    },
    documents() {
      if (this.target !== 'property') {
        return this.getFilteredInsuranceDocuments(this.insurance.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
      } else {
        const doc = this.documentList
        return doc.sort((a, b) => (a.name > b.name ? 1 : -1))
      }
    },
    translatedBenefits() {
      const array = []
      this.availableBenefits.forEach(conTyp => {
        array.push({
          label: this.$t('message.onBoarding.buildings.insurances.benefits.' + conTyp),
          value: conTyp
        })
      })
      return array
    },
    getBenefits() {
      const array = []
      this.insurance.benefits.forEach(conTyp => {
        array.push(this.$t('message.onBoarding.buildings.insurances.benefits.' + conTyp))
      })
      return array.join(', ')
    }
  },
  watch: {
    insurance: {
      deep: true,
      handler: function () {
        this.$emit('change-insurance')
      }
    }
  },
  mounted() {
    this.insuranceDate.startDate = this.startDate && this.startDate.year !== -1 && this.startDate.year > 0 ? this.startDate.year + '-' + ('0' + this.startDate.month).slice(-2) + '-' + ('0' + this.startDate.day).slice(-2) : ''
    this.insuranceDate.endDate = this.endDate && this.endDate.year !== -1 && this.endDate.year > 0 ? this.endDate.year + '-' + ('0' + this.endDate.month).slice(-2) + '-' + ('0' + this.endDate.day).slice(-2) : ''
    this.insuranceDate.earliestTerminationDate = this.earliestTerminationDate && this.earliestTerminationDate.year !== -1 && this.earliestTerminationDate.year > 0 ? this.earliestTerminationDate.year + '-' + ('0' + this.earliestTerminationDate.month).slice(-2) + '-' + ('0' + this.earliestTerminationDate.day).slice(-2) : ''
    this.insuranceDate.premiumPaidUntil = this.premiumPaidUntil && this.premiumPaidUntil.year !== -1 && this.premiumPaidUntil.year > 0 ? this.premiumPaidUntil.year + '-' + ('0' + this.premiumPaidUntil.month).slice(-2) + '-' + ('0' + this.premiumPaidUntil.day).slice(-2) : ''
    if (this.target === 'property') {
      MediaApi.listDocuments(this.insurance.documentIds)
        .then(documents => {
          this.documentList = documents.documents
        })
    }
    this.$nextTick(() => {
      if (!this.readOnly && this.addNewDocument) {
        this.addDocumentClicked('insurances' + this.index)
      }
    })
  },
  beforeDestroy() {
    this.$root.$off('bv::collapse::state')
  },
  methods: {
    goToEditMode() {
      let routeLink = null
      localStorage.setItem('insuranceIdNewDocument', this.insurance.id)
      if (this.$route.name?.includes('PropertyDetailsView')) {
          routeLink = {
            name: this.moduleRoutePrefix + 'PropertyEditingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'property'
            }
          }
      } else if (this.$route.name?.includes('BuildingDetailsView')) {
          routeLink = {
            name: this.moduleRoutePrefix + 'OnboardingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'building'
            }
          }
      }
      this.$router.push(routeLink)
    },
    addDocumentClicked(suffix = '') {
      localStorage.removeItem('insuranceIdNewDocument')
      this.$bvModal.show('add-document-modal' + suffix)
    },
    dateToTimestamp() {
      if (this.insuranceDate.startDate && this.insuranceDate.startDate !== '') {
        const startDate = new Date(this.insuranceDate.startDate)
        this.startDate = {
          year: startDate.getFullYear(),
          month: startDate.getMonth() + 1,
          day: startDate.getDate()
        }
      } else {
        this.startDate = null
      }
      if (this.insuranceDate.endDate && this.insuranceDate.endDate !== '') {
        const endDate = new Date(this.insuranceDate.endDate)
        this.endDate = {
          year: endDate.getFullYear(),
          month: endDate.getMonth() + 1,
          day: endDate.getDate()
        }
      } else {
        this.endDate = null
      }
      if (this.insuranceDate.premiumPaidUntil && this.insuranceDate.premiumPaidUntil !== '') {
        const date = new Date(this.insuranceDate.premiumPaidUntil)
        this.premiumPaidUntil = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      } else {
        this.premiumPaidUntil = null
      }
      if (this.insuranceDate.earliestTerminationDate && this.insuranceDate.earliestTerminationDate !== '') {
        const earliestTerminationDate = new Date(this.insuranceDate.earliestTerminationDate)
        this.earliestTerminationDate = {
          year: earliestTerminationDate.getFullYear(),
          month: earliestTerminationDate.getMonth() + 1,
          day: earliestTerminationDate.getDate()
        }
      } else {
        this.earliestTerminationDate = null
      }
    },
    documentCreated(document) {
      // Update insurance in vuex store with new documentId
      const insurance = this.insurance
      insurance.documentIds.push(document.id)
      this.$store.dispatch('onboarding/updateInsurance', insurance)

      // Add whole new document to vuex store to display it
      if (this.target !== 'property') {
        this.insuranceDocuments.push(document)
      } else {
        this.documentList.push(document)
      }
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    removeConfirmed() {
      this.removeInsurance(this.insurance)
      this.$refs.deleteModal.hide()
      this.$emit('removeConfirmed')
    },
    toTimestamp(strDate) {
      return Date.parse(strDate) / 1000
    },
    versionAdded(updatedDocument) {
      this.insuranceDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.insuranceDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
      const index = this.documentList.findIndex(d => d.id === updatedDocument.id)
      if (index !== -1) {
        this.$set(this.documentList, index, updatedDocument)
      }
    },
    deleteDocument(document) {
      this.insurance.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.insurance.documentIds.splice(index, 1)
        }
      }, this)
      if (this.target !== 'property') {
        const indexToDelete = this.insuranceDocuments.findIndex(d => d.id === document.id)
        if (indexToDelete !== -1) {
          this.$delete(this.insuranceDocuments, indexToDelete)
        }
      } else {
        const indexToDelete = this.documentList.findIndex(d => d.id === document.id)
        if (indexToDelete !== -1) {
          this.$delete(this.documentList, indexToDelete)
        }
      }
      this.$store.dispatch('onboarding/updateInsurance', this.insurance)
      this.activateChanged()
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      if (this.$v.insuranceDate.startDate.$dirty && this.$v.insuranceDate.startDate.$error) {
        Vue.toasted.show(this.$t('message.savingErrors.insuranceStartDate'), { type: 'error' })
      }
      if (this.$v.insuranceDate.earliestTerminationDate.$dirty && this.$v.insuranceDate.earliestTerminationDate.$error) {
        Vue.toasted.show(this.$t('message.savingErrors.insuranceEarliestTerminationDate'), { type: 'error' })
      }
      return this.$v.$invalid
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    }
  },
  validations: {
    amount: {
      minValue: minValue(0)
    },
    yearlyCosts: {
      minValue: minValue(0)
    },
    excess: {
      minValue: minValue(0)
    },
    insuranceDate: {
      startDate: {
        insuranceStart
      },
      earliestTerminationDate: {
        insuranceNotice
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
