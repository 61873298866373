<template>
  <section>
    <b-modal
      id="activate-condominium-modal"
      ref="activate-condominium-modal"
      :modal-class="'has-confirmation-modal'"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.buildings.objects.ownerAgreement.activateModal.title')">
      <div>
        <div class="row">
          <div class="col">
            <coozzy-button
              size="small"
              class="mb-0 border"
              design="transparent"
              @click="hide">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              v-if="selectedEntriesIds.length > 0"
              size="small"
              class="float-right mb-0"
              design="green"
              @click="saveClicked">
              {{ $t('message.onBoarding.buildings.objects.ownerAgreement.activateModal.title') }}
            </coozzy-button>
            <coozzy-button
              v-else
              size="small"
              class="float-right mb-0"
              design="prop-green"
              @click="saveClicked">
              {{ $t('message.generic.form.save') }}
            </coozzy-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="setFutureAddressModal"
      ref="setFutureAddressModal"
      no-close-on-backdrop
      :title="$t('message.onBoarding.interestedParties.setFutureAddressModalCondominium.title')"
      hide-footer>
      <div
        class="col">
        <p>{{ $t('message.onBoarding.interestedParties.setFutureAddressModalCondominium.body') }}</p>
        <coozzy-button
          size="small"
          design="default"
          @click="hidesetFutureAddressModal()">
          {{ $t('message.generic.selectField.no') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="prop-green"
          @click="setAddress()">
          {{ $t('message.generic.selectField.yes') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'

export default {
  name: 'ActivateCondominiumModal',
  components: { CoozzyButton },
  props: {
    condominiumId: {
      type: [String, Number],
      default: ''
    },
    ownerId: {
      type: [String, Number],
      default: 0
    },
    selectedEntriesIds: {
      type: Array,
      default() {
        return []
      }
    },
    objects: {
      type: Array,
      default() {
        return []
      }
    },
    condominiums: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    saveClicked() {
      if (this.selectedEntriesIds.length > 0) {
        this.selectedEntriesIds.forEach(condominiumId => {
          ObjectApi.activateCondominium(condominiumId)
            .then(tenancy => {
              this.handleSuccess()
            })
        })
      }
    },
    handleSuccess() {
      this.hide()
      const list = this.condominiums.filter(x => this.selectedEntriesIds.includes(x.id) && ['HOUSE', 'APARTMENT', 'COMMERCIAL', 'GASTRONOMY'].includes(this.getObject(x.objectId)?.category?.toUpperCase()))
      if (list.length > 0) {
        this.$refs.setFutureAddressModal.show()
      } else {
        this.$emit('condo-activated')
      }
      Vue.toasted.show(this.$t('message.successMessages.activateCondominium'), { type: 'success' })
    },
    show() {
      this.$refs['activate-condominium-modal']?.show()
    },
    hide() {
      this.$refs['activate-condominium-modal']?.hide()
    },
    hidesetFutureAddressModal() {
      this.$refs.setFutureAddressModal?.hide()
      this.$emit('condo-activated')
    },
    getObject(objectId) {
      const object = this.objects.find(function (object) {
        return object.id === objectId || object.objectID === objectId
      })

      return object || '-'
    },
    setAddress() {
      this.adressLoading = true
      const list = []
      const filteredList = this.condominiums.filter(x => this.selectedEntriesIds.includes(x.id) && ['HOUSE', 'APARTMENT', 'COMMERCIAL', 'GASTRONOMY'].includes(this.getObject(x.objectId).category?.toUpperCase()))
      const items = filteredList.map(x => x.id)
      if (items.length > 0) {
        items.forEach(element => {
          const item = this.condominiums.find(condo => condo.id === element)
          if (item) {
            const obj = { address: this.getObject(item.objectId).address, condoOwners: item.owner?.contactIds, startingAt: item.startingAt || null }
            list.push(obj)
          }
        })
      }
      if (list.length > 0) {
        const promises = []
        list.forEach(element => {
          promises.push(ContactApi.setAddress(element.address, element.condoOwners, element.startingAt))
        })
        Promise.all(promises)
          .then((res) => {
            this.adressLoading = false
            this.hidesetFutureAddressModal()
          })
          .catch(e => {
            console.log(e)
            this.adressLoading = false
            this.hidesetFutureAddressModal()
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    margin-bottom: 0;
  }
</style>
