import { render, staticRenderFns } from "./AdminMarketingObjectsView.vue?vue&type=template&id=7fb3c20a&scoped=true"
import script from "./AdminMarketingObjectsView.vue?vue&type=script&lang=js"
export * from "./AdminMarketingObjectsView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fb3c20a",
  null
  
)

export default component.exports