// @ts-nocheck
import Vue from 'vue'
import { i18n } from '@/internationalization/i18nSetup'
import SearchApi from '@/misc/apis/SearchApi'

export default {
  namespaced: true,
  state: {
    key: null,
    keyLoading: false,
    unauthorizedKey: null,
    unauthorizedKeyLoading: false
  },
  getters: {
    getKey: (state) => {
      return state.key
    },
    getUnauthorizedKey: (state) => {
      return state.unauthorizedKey
    }
  },
  mutations: {
    setKey: (state, value) => {
      state.key = value
      state.keyLoading = false
    },
    setKeyLoading: (state) => {
      state.keyLoading = true
    },
    setUnauthorizedKey: (state, value) => {
      state.unauthorizedKey = value
      state.unauthorizedKeyLoading = false
    },
    setUnauthorizedKeyLoading: (state) => {
      state.unauthorizedKeyLoading = true
    }
  },
  actions: {
    loadKey: async ({ commit, state }, accountId) => {
      if (!state.keyLoading) {
        try {
          commit('setKeyLoading')
          const response = await SearchApi.getAlgoliaKey(accountId)
          commit('setKey', response.key)
        } catch (e) {
          Vue.toasted.show(i18n.t('message.loadingErrors.generic'), { type: 'error' })
        }
      }
    },
    loadUnauthorizedKey: async ({ commit, state }) => {
      if (!state.unauthorizedKey) {
        try {
          commit('setUnauthorizedKeyLoading')
          const response = await SearchApi.getAlgoliaUnauthorizedKey()
          commit('setUnauthorizedKey', response.key)
        } catch (e) {
          Vue.toasted.show(i18n.t('message.loadingErrors.generic'), { type: 'error' })
        }
      }
    }
  }
}
