<template>
  <div>
    <font-awesome-icon
      icon="check"
      class="fa" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

library.add(faCheckCircle)

export default {
  name: 'CoozzyCheckIcon'
}
</script>

<style lang="scss" scoped>
  .fa {
    color: $color-grey-dark;
  }
</style>
