<template>
  <section>
    <h6
      v-b-toggle="`collapse-settlement-rent-component-${index}`"
      class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
      {{ $t('message.generic.asOf') }} {{ adjustmentsByDate | formatDate }}
    </h6>
    <div class="col-12 p-0">
      <b-collapse
        :id="`collapse-settlement-rent-component-${index}`"
        :ref="`collapse-settlement-rent-component-${index}`"
        :visible="expandByDefault || openedCollapses.includes(`collapse-settlement-rent-component-${index}`)">
        <div
          v-if="rentComponent">
          <div class="row">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="adjustmentsByDate"
                :is-read-only="readOnly"
                :filter="'formatDate'"
                :state="$v.adjustmentsByDate.$dirty && $v.adjustmentsByDate.$error ? false : null"
                :disabled="isFirstAdjustment || disabled"
                type="date"
                :name="$t('message.generic.byDate') + (readOnly ? '' : '*')"
                @blur="dateToObject()" />
            </div>
            <div
              v-if="!readOnly && !isFirstAdjustment"
              class="col-md-3 mt-2">
              <coozzy-button
                design="alert"
                class="align-self-center mt-3"
                @click="openConfirmationModal()">
                {{ $t('message.generic.delete') }}
              </coozzy-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <label
                v-if="advancePaymentLabels.length > 0 || flatRatesLabels.length > 0 || additionalRentLabels.length > 0"
                class="mt-3 mb-1">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentComponents') }}
              </label>
            </div>
          </div>
          <div
            v-for="(j, k) in getModulo(advancePaymentLabels)"
            :key="k">
            <div
              class="row"
              :class="j % 2 ? 'details-row-bg-2' : 'details-row-bg-1'">
              <div
                v-for="(item, i) in advancePaymentLabels.slice(j * 4, j * 4 + 4)"
                :key="'advancePaymentLabels'+i"
                class="col-sm-12 col-md-3">
                <coozzy-form-input-numeric
                  :value="getAdvancePaymentText(item) !== -1 ? getAdvancePaymentText(item) : null"
                  type="number"
                  min="0"
                  :is-read-only="readOnly"
                  :is-decimal="true"
                  :filter="'formatPrice'"
                  :name="$t(`message.propertiesView.rentComponents.advancePaymentRentComponents.${item}`)"
                  :disabled="disabled"
                  @input="advancePaymentTextChanged(item, $event)" />
              </div>
            </div>
          </div>
          <div
            v-for="(y, z) in getModulo(flatRatesLabels)"
            :key="'globalflatRateValues'+z">
            <div
              class="row"
              :class="(y + getModulo(advancePaymentLabels).length) % 2 ? 'details-row-bg-2' : 'details-row-bg-1'">
              <div
                v-for="(item, i) in flatRatesLabels.slice(y * 4, y * 4 + 4)"
                :key="'flatRateValues'+i"
                class="col-sm-12 col-md-3">
                <coozzy-form-input-numeric
                  :value="getFlatRateText(item) !== -1 ? getFlatRateText(item) : null"
                  type="number"
                  min="0"
                  :is-read-only="readOnly"
                  :is-decimal="true"
                  :filter="'formatPrice'"
                  :name="$t(`message.propertiesView.rentComponents.flatRateRentComponents.${item}`)"
                  :disabled="disabled"
                  @input="flatRateTextChanged(item, $event)" />
              </div>
            </div>
          </div>
          <div
            v-for="(y, z) in getModulo(additionalRentLabels)"
            :key="'globaladditionalValues'+z">
            <div
              class="row"
              :class="(y + + getModulo(flatRatesLabels).length + getModulo(advancePaymentLabels).length) % 2 ? 'details-row-bg-2' : 'details-row-bg-1'">
              <div
                v-for="(item, i) in additionalRentLabels.slice(y * 4, y * 4 + 4)"
                :key="'additionalRentLabels'+i"
                class="col-sm-12 col-md-3">
                <coozzy-form-input-numeric
                  :value="getAdditionalRentText(item) !== -1 ? getAdditionalRentText(item) : null"
                  type="number"
                  min="0"
                  :is-read-only="readOnly"
                  :is-decimal="true"
                  :filter="'formatPrice'"
                  :name="$t(`message.propertiesView.rentComponents.additionalRentComponents.${item}`)"
                  :disabled="disabled"
                  @input="additionalRentTextChanged(item, $event)" />
              </div>
            </div>
          </div>
          <div
            class="row"
            :class="(getModulo(flatRatesLabels).length + getModulo(additionalRentLabels).length + getModulo(advancePaymentLabels).length) % 2 ? 'details-row-bg-2' : 'details-row-bg-1'">
            <div
              class="col-6">
              <div
                v-if="readOnly">
                <label for="dueDates">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.dueDates') }}
                </label>
                <p
                  v-if="dueDates.length > 0"
                  class="mb-0 multi-select-value">
                  {{ getDueDateMonths() }}
                </p>
                <p
                  v-else
                  class="mb-0 multi-select-value">
                  -
                </p>
              </div>
              <div
                v-else>
                <coozzy-multiselect
                  id="dueDates"
                  v-model="dueDates"
                  :options="translatedMonths"
                  :check-valide="$v.dueDates.$dirty && $v.dueDates.$error ? 'is-invalid' : ''"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                  :taggable="false"
                  :disabled="disabled"
                  label="label"
                  track-by="label">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.dueDates') }} {{ (readOnly ? '' : '*') }}
                </coozzy-multiselect>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
      <b-modal
        :id="'cost-adjustments-confirmationModal'"
        ref="cost-adjustments-confirmationModal"
        no-close-on-backdrop
        hide-footer
        :title="$t('message.onBoarding.deleteModals.titleCostAdjusment')">
        <template>
          <div class="col">
            <p>{{ $t('message.onBoarding.deleteModals.bodyCostAdjusment') }}</p>
          </div>
          <div class="col">
            <coozzy-button
              size="small"
              class="mb-0 border"
              design="transparent"
              @click="$bvModal.hide('cost-adjustments-confirmationModal')">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              size="small"
              class="float-right mb-0"
              design="green"
              @click="deleteAdjustmentClicked">
              {{ $t('message.generic.delete') }}
            </coozzy-button>
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>

import { onboarding } from '@/mixins/onboarding'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import { validation } from '@/mixins/validation'
import { required, minLength } from 'vuelidate/lib/validators'
import Vue from 'vue'

function moreThanStarting(value, vm) {
  if (!value) {
    return true
  }
  return new Date(value) >= new Date(this.$options.filters.objectToDateInput(this.settlement.billingStart))
}

function notEqualToOtherAdjBydate(value, vm) {
  let flag = true
  this.settlement.rentAdjustments.filter(adj => adj.internalId !== this.rentComponentSelected.internalId).forEach(adj => {
    const isEqual = new Date(value).getTime() === new Date(this.$options.filters.objectToDateInput(adj.byDate)).getTime()
    if (isEqual) {
      flag = false
    }
  })
  return flag
}
function moreThanDebitUntil(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.settlement.debitUntil) {
    return true
  }

  if (vm.initialByDate && vm.rentComponent?.byDate) {
    const initialByDate = new Date(this.$options.filters.objectToDateInput(vm.initialByDate)).getTime()
    const byDate = new Date(this.$options.filters.objectToDateInput(vm.rentComponent.byDate)).getTime()
    if (initialByDate === byDate) {
      return true
    }
  }
  return new Date(vm.$options.filters.objectToDateInput(vm.rentComponent?.byDate)) >= new Date(this.$options.filters.objectToDateInput(vm.settlement.debitUntil))
}

export default {
  name: 'SettlementObjectyRentComponents',
  components: { CoozzyMultiselect },
  mixins: [onboarding, dateUtils, validation],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null
    },
    settlement: {
      type: Object,
      default: null
    },
    rentComponentSelected: {
      type: Object,
      default: null
    },
    property: {
      type: Object,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentProperty: null,
      availableTerminationMonths: ['all', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      adjustmentsByDate: null,
      rentComponent: this.rentComponentSelected ? this.rentComponentSelected : null,
      initialByDate: null
    }
  },
  computed: {
    isFirstAdjustment() {
      return this.settlement.rentAdjustments.length <= 1 || (this.index === 0 && this.settlement.billingStart && new Date(this.$options.filters.objectToDateInput(this.settlement.billingStart)).getTime() / 1000 === new Date(this.adjustmentsByDate).getTime() / 1000)
    },
    additionalRentLabels() {
      return this.currentProperty ? this.currentProperty.additionalRentComponents.map(x => x.rentComponent) : []
    },
    flatRatesLabels() {
      return this.currentProperty ? this.currentProperty.flatRateRentComponents.map(x => x.rentComponent) : []
    },
    advancePaymentLabels() {
      return this.currentProperty ? this.currentProperty.advancePaymentRentComponents.map(x => x.rentComponent) : []
    },
    monthlyRentDueBy: {
      get() {
        return this.adjustment.monthlyRentDueBy
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.monthlyRentDueBy = value
        this.$emit('change-adjustment', newAdjustment)
      }
    },
    dueDates: {
      get() {
        const tempMonths = this.rentComponent.dueDates
        tempMonths.slice().sort(function (a, b) {
          return a - b
        })
        return tempMonths.map(month => {
          return {
            label: this.$t('message.generic.months.' + month),
            value: month
          }
        })
      },
      set(value) {
        for (const month of value) {
          if (month.value === 'all') {
            this.rentComponent.dueDates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            this.$emit('has-change', true)
            return
          }
        }
        this.rentComponent.dueDates = value.map(v => v.value)
        this.$emit('has-change', true)
      }
    },
    translatedMonths() {
      const array = []
      for (const month of this.availableTerminationMonths) {
        array.push({
          label: this.$t('message.generic.months.' + month),
          value: month
        })
      }
      return array
    }
  },
  watch: {
    'rentComponentSelected.byDate': function (newVal, oldVal) {
      if (this.newVal) {
        this.rentComponent.byDate = newVal
        this.$emit('has-change', true)
        this.adjustmentsByDate = this.$options.filters.objectToDateInput(newVal)
      }
    }
  },
  mounted() {
    this.currentProperty = this.property ? this.property : this.properties[0]
    this.adjustmentsByDate = this.$options.filters.objectToDateInput(this.rentComponent.byDate)
    for (let index = 0; index < this.currentProperty.additionalRentComponents.length; index++) {
      this.checkAdditionalRent(this.currentProperty.additionalRentComponents[index].rentComponent)
    }
    for (let index = 0; index < this.currentProperty.advancePaymentRentComponents.length; index++) {
      this.checkAdvancePayment(this.currentProperty.advancePaymentRentComponents[index].rentComponent)
    }
    for (let index = 0; index < this.currentProperty.flatRateRentComponents.length; index++) {
      this.checkFlatRate(this.currentProperty.flatRateRentComponents[index].rentComponent)
    }
    this.initialByDate = this.rentComponent.byDate
  },
  methods: {
    close() {
      if (this.$refs['collapse-settlement-rent-component-' + this.index].show) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-settlement-rent-component-' + this.index)
      }
    },
    getModulo(array) {
      let foo = []
      for (let i = 0; i < array.length / 4; i++) {
        foo.push(i)
      }
      return foo
    },
    setBydate(billingStart) {
      this.rentComponent.byDate = billingStart
      this.adjustmentsByDate = this.$options.filters.objectToDateInput(billingStart)
    },
    getDueDateMonths() {
      const array = []
      for (const month of this.rentComponent.dueDates) {
        array.push(this.$t('message.generic.months.' + month))
      }
      return array.join(', ')
    },
    checkAdvancePayment(e) {
      const i = this.rentComponent.advancePaymentValues.find(x => x.type === e)
      if (!i || i === undefined) {
        this.rentComponent.advancePaymentValues.push({ type: e, value: null })
      }
    },
    checkFlatRate(e) {
      const i = this.rentComponent.flatRateValues.find(x => x.type === e)
      if (!i || i === undefined) {
        this.rentComponent.flatRateValues.push({ type: e, value: null })
      }
    },
    checkAdditionalRent(e) {
      const i = this.rentComponent.additionalValues.find(x => x.type === e)
      if (!i || i === undefined) {
        this.rentComponent.additionalValues.push({ type: e, value: null })
      }
    },
    initCheckAdvancePayment(value) {
      return this.rentComponent.advancePaymentValues.flatMap(x => x.type).includes(value)
    },
    initCheckFlatRate(value) {
      return this.rentComponent.flatRateValues.flatMap(x => x.type).includes(value)
    },
    initCheckAdditionalRent(value) {
      return this.rentComponent.additionalValues.flatMap(x => x.type).includes(value)
    },
    advancePaymentTextChanged(item, val) {
      this.rentComponent.advancePaymentValues.forEach(x => {
        if (x.type === item) {
          x.value = (val === null ? -1 : val)
        }
      })
      this.$emit('has-change', true)
    },
    additionalRentTextChanged(item, val) {
      this.rentComponent.additionalValues.forEach(x => {
        if (x.type === item) {
          x.value = (val === null ? -1 : val)
        }
      })
      this.$emit('has-change', true)
    },
    flatRateTextChanged(item, val) {
      this.rentComponent.flatRateValues.forEach(x => {
        if (x.type === item) {
          x.value = (val === null ? -1 : val)
        }
      })
      this.$emit('has-change', true)
    },
    getAdvancePaymentText(val) {
      const advancePaymentValue = this.rentComponent.advancePaymentValues.find(x => x.type === val)
      if (advancePaymentValue) {
        return advancePaymentValue.value
      }
      return null
    },
    getFlatRateText(val) {
      const flatRateValue = this.rentComponent.flatRateValues.find(x => x.type === val)
      if (flatRateValue) {
        return flatRateValue.value
      }
      return null
    },
    getAdditionalRentText(val) {
      const additionalValue = this.rentComponent.additionalValues.find(x => x.type === val)
      if (additionalValue) {
        return additionalValue.value
      }
      return null
    },
    dateToObject() {
      this.rentComponent.byDate = this.toObject(this.adjustmentsByDate)
    },
    openConfirmationModal() {
      this.$refs['cost-adjustments-confirmationModal'].show()
    },
    deleteAdjustmentClicked() {
      this.$emit('delete-adjustment', this.rentComponent.internalId)
      this.$refs['cost-adjustments-confirmationModal'].hide()
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        if (this.$v.adjustmentsByDate.$dirty && this.$v.adjustmentsByDate.$error && !this.$v.adjustmentsByDate.notEqualToOtherAdjBydate) {
          Vue.toasted.show(this.$t('message.savingErrors.notEqualToOtherAdjBydate'), { type: 'error' })
        }
      }
      return this.$v.$invalid
    }
  },
  validations() {
    return {
      adjustmentsByDate: {
        required,
        moreThanDebitUntil,
        moreThanStarting,
        notEqualToOtherAdjBydate
      },
      dueDates: {
        required,
        minLength: minLength(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.custom-control-input:disabled ~ .custom-control-label) {
  color: #333;
}
</style>
