<template>
  <div>
    <p class="font-weight-bold">
      {{ title }}
    </p>
    <a
      tabindex="0"
      href=""
      @click="redirectToDetailPage(assignee.id || assignee.objectID)">
      {{ assignee.name }}
    </a>
    <p v-if="assignee.employment">
      {{ assignee.employment.companyName }}
    </p>
    <div
      v-if="getPhoneNumbers(assignee.phoneNumbers, 'mobile') !== '-'"
      class="row">
      <div class="col-1">
        <coozzy-mobile-alt-icon />
      </div>
      <div class="col">
        <a :href="'tel:' + getPhoneNumbers(assignee.phoneNumbers, 'mobile')">
          {{ getPhoneNumbers(assignee.phoneNumbers, 'mobile') }}
        </a>
      </div>
    </div>
    <div
      v-if="getPhoneNumbers(assignee.phoneNumbers, 'home') !== '-'"
      class="row">
      <div class="col-1">
        <coozzy-phone-icon />
      </div>
      <div class="col">
        <a :href="'tel:' + getPhoneNumbers(assignee.phoneNumbers, 'home')">
          {{ getPhoneNumbers(assignee.phoneNumbers, 'home') }}
        </a>
      </div>
    </div>
    <div
      v-if="getPhoneNumbers(assignee.phoneNumbers, 'work') !== '-'"
      class="row">
      <div class="col-1">
        <coozzy-headset-icon />
      </div>
      <div class="col">
        <a :href="'tel:' + getPhoneNumbers(assignee.phoneNumbers, 'work')">
          {{ getPhoneNumbers(assignee.phoneNumbers, 'work') }}
        </a>
      </div>
    </div>
    <div
      v-if="assignee.email !== ''"
      class="row">
      <div class="col-1">
        <img
          class="email-icon-img"
          src="@/assets/icon/arroba.svg"
          alt="Placeholder">
      </div>
      <div class="col">
        <template v-if="assignee.email !== ''">
          <a
            tabindex="0"
            href=""
            @click="sendEmailModal()">
            {{ assignee.email }}
          </a>
        </template>
        <template v-else>
          -
        </template>
      </div>
    </div>
    <div
      v-if="assignee.website !== ''"
      class="row">
      <div class="col-1">
        <coozzy-globe-icon />
      </div>
      <div class="col">
        <template v-if="assignee.website !== ''">
          <a
            tabindex="0"
            target="_blank"
            :href="getUrlWebsite(assignee.website)">
            {{ assignee.website }}
          </a>
        </template>
        <template v-else>
          {{ assignee.website | displayOptionalValue }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon'
import CoozzyHeadsetIcon from '@/framework/components/icons/CoozzyHeadsetIcon'
import CoozzyGlobeIcon from '@/framework/components/icons/CoozzyGlobeIcon'
import { mapMutations } from 'vuex'
import { contact } from '@/mixins/contact'

export default {
  name: 'EmployeeDetails',
  components: { CoozzyGlobeIcon, CoozzyHeadsetIcon, CoozzyPhoneIcon, CoozzyMobileAltIcon },
  mixins: [contact],
  props: {
    title: {
      type: String,
      required: true
    },
    assignee: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply'
    ]),
    sendEmailModal() {
      this.setSendMailContacts([this.assignee])
      this.setSendMailPreselectedContact(this.assignee)
      this.setSendMailReferenceIds([])
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
</style>
