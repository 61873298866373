<template>
  <font-awesome-icon
    icon="ban"
    :class="[defaultColor ? 'is-default' : '', 'fa']" />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBan } from '@fortawesome/free-solid-svg-icons'

library.add(faBan)

export default {
  name: 'CoozzyRejectIcon',
  props: {
    defaultColor: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
    .fa {
      color: $color-red;
      &.is-default{
        color: $color-text-grey;
      }
      &:hover {
        cursor: pointer;
      }
    }
</style>
