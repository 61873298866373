import { LoadLanguageAsync } from '@/internationalization/i18nSetup'

export default {
  namespaced: true,
  state: {
    currentLanguage: 'de'
  },
  getters: {
    getCurrentLanguage: (state) => {
      return state.currentLanguage
    }
  },
  mutations: {
    setCurrentLanguage: (state, value) => {
      state.currentLanguage = value
    }
  },
  actions: {
    changeCurrentLanguage: ({ commit }, value) => {
      localStorage.setItem('language', value)
      LoadLanguageAsync(value).then(commit('setCurrentLanguage', value))
    }
  }
}
