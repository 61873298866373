<template>
  <b-dropdown-header
    v-bind="$attrs"
    v-on="mountEventListeners">
    <slot />
  </b-dropdown-header>
</template>

<script>
export default {
  name: 'CoozzyDropdownItemHeader',
  inheritAttrs: false,
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
