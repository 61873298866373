<template>
  <section class="w-100">
    <!-- Loading -->
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-else
      class="row m-0">
      <div
        v-if="galleryImages && galleryImages.length > 0"
        class="image-container rounded-left col-12 col-md-4"
        :style="{ 'background-image': 'url(' + galleryImages[0].url + ')' }"
        @click="openGallery" />
      <div
        v-else
        class="image-container rounded-left col-12 col-md-4"
        :style="{ 'background-image': 'url(/img/image-placeholder.png)' }" />
      <!-- Object data -->
      <div
        class="col-12 col-md-8 bg-grey rounded pt-3 mt-3 mt-md-0"
        :class="requestId ? 'cursor-pointer' : ''"
        @click="requestClick(requestId)">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="row align-items-center">
              <img
                v-if="marketingAccount && marketingAccount.logoUrl !== ''"
                :src="marketingAccount.logoUrl"
                alt="Logo"
                style="max-height: 50px;max-width: 250px;display: inline-block;"
                class="col-lg-5 col-md-12">
              <span class="col-lg-7 col-md-12 font-weight-bold">
                {{ marketingAccount ? marketingAccount.company.name : '' }}
              </span>
            </div>
          </div>
          <div class="col-12 mb-3 font-weight-bold">
            {{ object.title }},
            <address-text
              :address="object.address"
              :one-line="true" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="row">
              <div class="col-6 font-weight-bold">
                {{ $t('message.tableColumnHeadings.netRent') }}
              </div>
              <div class="col-6">
                <template v-if="object.coldRent">
                  {{ object.coldRent | formatPrice('CHF') }}
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col-6 font-weight-bold">
                {{ $t('message.tableColumnHeadings.utilities_HC') }}
              </div>
              <div class="col-6">
                <template v-if="getSomeOfPrice(object.additional, object.heating) !== '-'">
                  {{ getSomeOfPrice(object.additional, object.heating) | formatPrice('CHF') }}
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col-6 font-weight-bold">
                {{ $t('message.tableColumnHeadings.grossRent') }}
              </div>
              <div class="col-6">
                <template v-if="grossRent">
                  {{ grossRent | formatPrice('CHF') }}
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col-6 font-weight-bold">
                {{ $t('message.onBoarding.buildings.objects.rooms') }}
              </div>
              <div class="col-6">
                {{ object.rooms | displayOptionalValue }}
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="row">
              <div class="col-6 font-weight-bold">
                {{ $t('message.onBoarding.buildings.objects.floor') }}
              </div>
              <div class="col-6">
                {{ formatFloor(object.floor) }}
              </div>
            </div>
            <div class="row">
              <div class="col-6 font-weight-bold">
                {{ $t('message.tableColumnHeadings.reference') }}
              </div>
              <div class="col-6">
                {{ object.publicId }}
              </div>
            </div>
            <div class="row">
              <div class="col-6 font-weight-bold">
                {{ $t('message.tableColumnHeadings.availability') }}
              </div>
              <div class="col-6">
                <template v-if="object.availability">
                  <template v-if="object.availability.type === 'IMMEDIATELY'">
                    {{ $t('message.onBoarding.buildings.objects.availability.IMMEDIATELY') }}
                  </template>
                  <template v-else-if="object.availability.type === 'BY_ARRANGEMENT'">
                    {{ $t('message.onBoarding.buildings.objects.availability.BY_ARRANGEMENT') }}
                  </template>
                  <template v-else-if="object.availability.type === 'FROM_DATE'">
                    {{ object.availability.availableFrom | timestampToDate }}
                  </template>
                  <template v-else-if="object.availability.type === 'TEMPORARY_LIVING'">
                    {{ object.availability.availableFrom | timestampToDate }}
                  </template>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </div>
            <div
              v-if="object.category !== 'HOUSE' && object.category !== 'APARTMENT'"
              class="row">
              <div class="col-6 font-weight-bold">
                {{ $t('message.onBoarding.buildings.objects.effectiveArea') }}
              </div>
              <div class="col-6">
                {{ object.effectiveArea | formatArea }}
              </div>
            </div>
            <div
              v-else
              class="row">
              <div class="col-6 font-weight-bold">
                {{ $t('message.onBoarding.buildings.objects.livingSpace') }}
              </div>
              <div class="col-6">
                {{ object.livingArea | formatArea }}
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div
              class="row">
              <template>
                <div class="col-4 offset-md-4">
                  <coozzy-button
                    v-if="accountId && $route.name !== 'UserRequestDetailView'"
                    class="w-100"
                    design="prop-green"
                    @click.stop="requestClick(requestId)">
                    {{ $t('message.generic.form.toCommunication') }}
                  </coozzy-button>
                </div>
              </template>
              <template v-if="showApplyButton && accountId">
                <div class="col-4">
                  <coozzy-button
                    class="w-100"
                    design="prop-green"
                    @click.stop="applyClicked">
                    {{ $t('message.generic.form.apply') }}
                  </coozzy-button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ObjectApi from '../../../misc/apis/ObjectApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import MediaApi from '../../../misc/apis/MediaApi'
import UserApi from '../../../misc/apis/UserApi'
import Vue from 'vue'
import AddressText from '../../../framework/components/misc/AddressText'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { formatting } from '@/mixins/formatting'
import { user } from '@/mixins/user'
import CoozzyButton from '@/framework/components/button/CoozzyButton'

export default {
  name: 'ApplicationFormObjectView',
  components: {
    CoozzyButton,
    CoozzySpinner,
    AddressText
  },
  mixins: [user, formatting],
  props: {
    requestId: {
      type: [String, Number],
      default: ''
    },
    defaultObjectId: {
      type: [String, Number],
      default: ''
    },
    showApplyButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      objectId: '',
      object: null,
      request: null,
      loading: true,
      galleryImages: [],
      marketingAccount: null
    }
  },
  computed: {
    grossRent() {
      const total = (this.object.heating !== -1 ? this.object.heating : 0) +
        (this.object.additional !== -1 ? this.object.additional : 0) +
        (this.object.coldRent !== -1 ? this.object.coldRent : 0)

      return total !== 0 ? total : null
    }
  },
  mounted: function () {
    if (this.requestId) {
      RequestApi.getListSent(this.$store.getters['user/getAccountId'])
        .then(response => {
          this.requestList = response.requests
          this.request = response.requests.find(obj => this.requestId === obj.id)
          this.loadObject(this.request.objectId)
        })
    } else if (this.defaultObjectId) {
      this.loadObject(this.defaultObjectId)
    }
  },
  methods: {
    loadObject(objectId) {
      ObjectApi.getMarketingObject(objectId)
        .then(response => {
          this.object = response
          const promises = []
          for (const media of this.object.images) {
            promises.push(MediaApi.getMediaByID(media.mediaId))
          }
          if (this.object.marketingAccountId !== '-1') {
            UserApi.getAccount(this.object.marketingAccountId).then(response => {
              this.marketingAccount = response.account
            }).catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.user'), { type: 'error' })
            })
          }
          const current = this
          Promise.all(promises)
            .then(values => {
              for (const media of values) {
                this.galleryImages.push({ url: media.media.url, mediaId: media.media.id, order: current.object.images.find(x => x.mediaId === media.media.id).order })
              }
              this.galleryImages = this.galleryImages.sort((a, b) => { return a.order - b.order })
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.image'), { type: 'error' })
            })
            .then(() => {
              this.loading = false
            })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    openGallery() {
      const media = this.galleryImages.map(x => x.url)
      this.$viewerApi({
        images: media,
        options: this.viewerOptions
      })
    },
    getSomeOfPrice(additional, heating) {
      return (additional !== -1 ? additional : 0) + (heating !== -1 ? heating : 0) !== 0 ? (additional !== -1 ? additional : 0) + (heating !== -1 ? heating : 0) : '-'
    },
    requestClick(requestId) {
      if (requestId) {
        this.$router.push({ name: 'UserRequestDetailView', params: { id: requestId } })
      }
    },
    applyClicked() {
      this.$router.push({
        name: 'ApplicationFormView',
        query: {
          requestId: this.requestId
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.image-container {
  background-size: cover;
  cursor: pointer;
  min-height: 200px;
  background-position: center;
}
</style>
