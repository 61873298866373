<template>
  <svg
    :id="name"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="14px"
    height="14px"
    viewBox="0 0 512 512"
    class="nav-icon fa"
    :style="active ? 'filter: invert(30%) sepia(8%) saturate(171%) hue-rotate(161deg) brightness(92%) contrast(89%)' : 'filter: invert(97%) sepia(5%) saturate(507%) hue-rotate(102deg) brightness(117%) contrast(100%);'">
    <path
      d="M501.333,490.667h-10.667V181.333c0-5.888-4.779-10.667-10.667-10.667h-64c-5.888,0-10.667,4.779-10.667,10.667v309.333h-42.667V309.333c0-5.888-4.779-10.667-10.667-10.667h-64c-5.888,0-10.667,4.779-10.667,10.667v181.333h-42.667v-224c0-5.888-4.779-10.667-10.667-10.667h-64c-5.888,0-10.667,4.779-10.667,10.667v224h-42.667V373.333c0-5.888-4.779-10.667-10.667-10.667H32c-5.888,0-10.667,4.779-10.667,10.667v117.333H10.667C4.779,490.667,0,495.445,0,501.333C0,507.221,4.779,512,10.667,512H32h64h64h64h64h64h64h64h21.333c5.888,0,10.667-4.779,10.667-10.667C512,495.445,507.221,490.667,501.333,490.667z" />
    <path
      d="M448,0c-23.531,0-42.667,19.136-42.667,42.667c0,7.893,2.304,15.189,6.037,21.547l-69.824,69.824C335.189,130.304,327.893,128,320,128c-13.376,0-25.195,6.315-33.024,15.979l-52.416-14.955c0-0.341,0.107-0.661,0.107-1.024c0-23.531-19.136-42.667-42.667-42.667S149.333,104.469,149.333,128c0,6.208,1.387,12.053,3.776,17.365l-66.667,53.184C79.915,194.453,72.256,192,64,192c-23.531,0-42.667,19.136-42.667,42.667c0,23.531,19.136,42.667,42.667,42.667c23.531,0,42.667-19.136,42.667-42.667c0-7.488-2.112-14.443-5.525-20.565l65.344-52.117c7.147,5.376,15.915,8.683,25.515,8.683c15.637,0,29.205-8.555,36.629-21.141l49.429,14.101c-0.405,2.304-0.725,4.629-0.725,7.04c0,23.531,19.136,42.667,42.667,42.667c23.531,0,42.667-19.136,42.667-42.667c0-7.893-2.304-15.189-6.037-21.547l69.845-69.845c6.336,3.755,13.632,6.059,21.525,6.059c23.531,0,42.667-19.136,42.667-42.667C490.667,19.136,471.531,0,448,0z" />
  </svg>
</template>

<script>

export default {
  name: 'CoozzyAnalyticsIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
