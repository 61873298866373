import { render, staticRenderFns } from "./CreateDomainModal.vue?vue&type=template&id=545f4278&scoped=true"
import script from "./CreateDomainModal.vue?vue&type=script&lang=js"
export * from "./CreateDomainModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545f4278",
  null
  
)

export default component.exports