import * as Sentry from '@sentry/browser'

const tracking = {
  data() {
    return {
      trackingEnabled: false
    }
  },
  methods: {
    enableTracking() {
      this.trackingEnabled = true
    },
    captureMessage(message: string, extras: Record<string, unknown>) {
      if (this.trackingEnabled) {
        Sentry.setExtras(extras)
        Sentry.captureMessage(message)
      }
    }
  }
}

export { tracking }
