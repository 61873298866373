<template>
  <img
    width="20px"
    height="20px"
    src="@/assets/icon/warning.svg"
    alt="warningIcon">
</template>

<script>

export default {
  name: 'CoozzyWarningIcon'
}
</script>

<style lang="scss" scoped>
</style>
