import store from '@/store/store'

const dateFormatter = new Intl.DateTimeFormat('de-DE', {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
})

export default {
  install(Vue) {
    Vue.filter('formatPrice', function (value, currency?: string, decimal?: boolean) {
      if ((!value || value === -1 || value === '-1' || value === undefined || value === 'undefined') && value !== 0) {
        return '-'
      }
      // Format with 2 decimals if 'decimal' === true or if the value has a decimal value !== 0
      value = Number(value).toFixed(decimal === true ? 2 : ((value % 1) === 0 ? 0 : 2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\'')
      if (currency === 'EUR') {
        return `${value} €`
      } else {
        return `CHF ${value}`
      }
    })
    Vue.filter('formatArea', function (value) {
      if (!value || value === -1 || value === '-1' || value === '-' || value === '') {
        return '-'
      }
      return value.toFixed((value % 1) === 0 ? 0 : 2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'").replace('.', ',') + ' m²'
    })
    Vue.filter('formatMeter', function (value) {
      if (!value || value === -1 || value === '-1') {
        return '-'
      }
      return value.toFixed((value % 1) === 0 ? 0 : 2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'").replace('.', ',') + ' m'
    })
    Vue.filter('formatMeterDecimal', function (value) {
      if (!value || value === -1 || value === '-1') {
        return '-'
      }

      return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'").replace('.', ',') + ' m'
    })
    Vue.filter('formatNumber', function (value) {
      if (!value || value === '') {
        return '-'
      }

      return value.toFixed((value % 1) === 0 ? 0 : 2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'").replace('.', ',')
    })
    Vue.filter('formatVolume', function (value) {
      if (!value || value === -1 || value === '-1') {
        return '-'
      }

      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'").replace('.', ',') + ' m³'
    })
    Vue.filter('formatDate', function (value) {
      if (value === null || value === '-' || !value || value === -1 || value === '-1') {
        return '-'
      }

      const array = value.split('-')
      return array[2] + '.' + array[1] + '.' + array[0]
    })
    Vue.filter('formatPercent', function (value) {
      if (value === null || value === -1 || value === '-1') {
        return '-'
      }

      return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'").replace('.', ',') + '%'
    })
    Vue.filter('objectToDateInput', function (value) {
      if (!value || value === -1 || (value && Object.keys(value).length === 0)) {
        return null
      }
      return value && value.year !== -1 && value.year > 0 ? String(value.year).padStart(4, '0') + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2) : ''
    })
    Vue.filter('objectToDate', function (value) {
      if (!value || value === -1 || (value && Object.keys(value).length === 0)) {
        return '-'
      }

      return value && value.year !== -1 && value.year > 0 ? ('0' + value.day).slice(-2) + '.' + ('0' + value.month).slice(-2) + '.' + value.year : ''
    })
    Vue.filter('objectToTimestamp', function (value) {
      if (!value || value === -1 || (value && Object.keys(value).length === 0)) {
        return '-'
      }
      const str = value && value.year !== -1 && value.year > 0 ? ('0' + value.day).slice(-2) + '.' + ('0' + value.month).slice(-2) + '.' + value.year : ''
      return new Date(str).getTime()
    })
    Vue.filter('timestampToTime', function (value) {
      if (!value) {
        return '-'
      }

      const dt = new Date(value * 1000)
      return dateFormatter.format(dt)
    })
    function convertDateToUTC(date) {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
    }
    Vue.filter('timestampToSmallDate', function (value) {
      if (!value) {
        return '-'
      }
      const date = convertDateToUTC(new Date(value * 1000))
      const now = new Date()
      const dt = new Date(value * 1000)
      const mm = dt.getMonth() + 1
      const dd = dt.getDate()
      const yy = dt.getFullYear()

      // get only short name of the day
      const options: Intl.DateTimeFormatOptions = { weekday: 'short' }
      const todayYear = now.getFullYear()
      return new Intl.DateTimeFormat('de-DE', options).format(date) + ' ' + ('0' + dd).slice(-2) + '.' + ('0' + mm).slice(-2) + (todayYear !== yy ? '.' + yy : '')
    })
    Vue.filter('timestampToTimeOnly', function (value) {
      if (!value) {
        return '-'
      }

      const dt = convertDateToUTC(new Date(value * 1000))
      const hours = dt.getHours()
      const minutes = '0' + dt.getMinutes()
      return hours.toString().padStart(2, '0') + ':' + minutes.substr(-2).toString().padStart(2, '0')
    })
    Vue.filter('timestampToDate', function (value) {
      if (!value || value === -1 || value === '-1') {
        return '-'
      }

      const dt = new Date(value * 1000)
      const mm = dt.getMonth() + 1
      const dd = dt.getDate()
      const yy = dt.getFullYear()
      return ('0' + dd).slice(-2) + '.' + ('0' + mm).slice(-2) + '.' + yy
    })
    Vue.filter('timestampToDateTime', function (value) {
      if (!value || value === -1 || value === '-1') {
        return '-'
      }

      const dt = new Date(value * 1000)
      const mm = dt.getMonth() + 1
      const dd = dt.getDate()
      const yy = dt.getFullYear()
      const hours = dt.getHours()
      const hoursString = (hours < 10) ? '0' + hours : hours
      const minutes = '0' + dt.getMinutes()
      return ('0' + dd).slice(-2) + '.' + ('0' + mm).slice(-2) + '.' + yy + ' ' + hoursString + ':' + minutes.substr(-2)
    })
    Vue.filter('timestampToDateInput', function (value) {
      if ((!value && value !== '0') || value === -1 || value === '-1') {
        return null
      }

      const dt = new Date(value * 1000)
      const todate = dt.getDate()
      const tomonth = dt.getMonth() + 1
      const toyear = dt.getFullYear()
      return toyear + '-' + ('0' + tomonth).slice(-2) + '-' + ('0' + todate).slice(-2)
    })
    Vue.filter('displayOptionalValue', function (value) {
      if (value === null || value === '' || value === undefined || value === -1 || value === '-1') {
        return '-'
      } else {
        return value
      }
    })
    Vue.filter('addressText', function (address) {
      if (!address) {
        return '-'
      }

      const addr = (address.careOf ? address.careOf + '\n' : ' ') +
        (address.street ? address.street + (address.streetNumber ? ' ' + address.streetNumber : ' ') : ' ') +
        (address.mailbox ? ' ' + address.mailbox + '\n ' : ' ') +
        (address.zip ? address.country + '-' : ' ') +
        (address.zip ? address.zip + ' ' : '') +
        (address.city ? address.city : '')

      if (addr) {
        return addr
      } else {
        return '-'
      }
    })
    Vue.filter('mapImagesForCarousel', function (value) {
      return value.map(v => {
        return {
          id: v.id,
          imageSource: v.url
        }
      })
    })
    Vue.filter('getNameFromMessage', function (values) {
      const accountId = store.getters['user/getAccountId']
      let name = ''
      values.forEach(function (element) {
        if (accountId !== element.accountId) {
          name = element.name
          return false
        }
      })
      return name
    })
  }
}
