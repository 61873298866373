const hideNavigation = {
  methods: {
    hideNavigation() {
      return !(this.$route.name &&
        (['Login', 'Callback', 'Registration', 'PostRegistration', 'Logout', 'Help', 'AboutUs', 'ToS', 'PrivacyPolicy', 'Imprint', 'Cookies', 'ApplicationFormLandingPage'].indexOf(this.$route.name) === -1 && this.isAuthenticated))
    }
  }
}

export { hideNavigation }
