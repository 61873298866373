<template>
  <img
    width="20px"
    height="20px"
    src="@/assets/icon/filters.svg"
    alt="filterIcon">
</template>

<script>

export default {
  name: 'CoozzyFiltersIcon'
}
</script>

<style lang="scss" scoped>
  img {
    cursor: pointer;
  }
</style>
