<template>
  <section>
    <div
      v-b-toggle="`collapse-rent-component`"
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ $t('message.propertiesView.rentComponents.title') }}
      </h5>
    </div>
    <div class="col-12 p-0">
      <b-collapse
        id="collapse-rent-component"
        :visible="expandByDefault || openedCollapses.includes('collapse-rent-component')">
        <div
          v-if="property"
          class="row">
          <div
            v-if="property.type === 'PROPERTY_TYPE_RENTAL'"
            class="col-12">
            <div
              v-b-toggle="`collapse-rent-component-advancePayment`"
              class="row mt-3 ml-0">
              <h5 class="mb-2 cursor-pointer hover width-only-content">
                {{ $t('message.propertiesView.rentComponents.advancePaymentRentComponents.advancePayment') }}
              </h5>
            </div>
            <b-collapse
              id="collapse-rent-component-advancePayment"
              :visible="expandByDefault || openedCollapses.includes('collapse-rent-component-advancePayment')">
              <div
                class="row">
                <div class="col-12 mb-4">
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS')"
                      class="col-3 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS', $event)" />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY"
                        :disabled="readOnly"
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY')"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY')"
                      class="col-6 ml-4 mb-2 mt-2 ml-4 mb-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING"
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING')"
                        :disabled="readOnly"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE"
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE')"
                        :disabled="readOnly"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_HEATING"
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING')"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_HEATING"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_HEATING')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_HEATING')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER"
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER')"
                        :disabled="readOnly"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE"
                        :disabled="readOnly"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_TV')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_TV"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_TV"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_TV')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_TV')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_TV')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_TV')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_TV', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS', $event)" />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :disabled="readOnly"
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE"
                        :disabled="readOnly"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT', $event)" />
                    </div>
                  </div>
                  <div
                    class="row">
                    <div
                      class="col-12">
                      <coozzy-form-checkbox
                        :initial="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION')"
                        name="ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION"
                        value="ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION"
                        :disabled="readOnly"
                        @change="checkAdvancePayment($event, 'ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION')">
                        {{
                          $t('message.propertiesView.rentComponents.advancePaymentRentComponents.ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION')
                        }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="initCheckAdvancePayment('ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION')"
                      class="col-6 ml-4 mb-2 mt-2">
                      <coozzy-form-textarea
                        :show-label="false"
                        :is-read-only="readOnly"
                        name="description"
                        :rows="2"
                        :placeholder="$t('message.advertisement.description')"
                        :initial="getAdvancePaymentText('ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION')"
                        @input="advancePaymentTextChanged('ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION', $event)" />
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="col-12">
            <div
              v-b-toggle="`collapse-rent-component-flatRate`"
              class="row mt-3 ml-0">
              <h5 class="mb-2 cursor-pointer hover width-only-content">
                {{ $t('message.propertiesView.rentComponents.flatRateRentComponents.flatRate') }}
              </h5>
            </div>
            <b-collapse
              id="collapse-rent-component-flatRate"
              :visible="expandByDefault || openedCollapses.includes('collapse-rent-component-flatRate')">
              <template
                v-if="property.type === 'PROPERTY_TYPE_RENTAL'">
                <div class="row">
                  <div class="col-12 mb-4">
                    <div class="row">
                      <div class="col-4">
                        <div class="row">
                          <div
                            class="col-12">
                            <coozzy-form-checkbox
                              value="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_1"
                              name="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_1"
                              :disabled="readOnly"
                              :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_1')"
                              @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_1')">
                              {{
                                $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_1')
                              }}
                            </coozzy-form-checkbox>
                          </div>
                          <div
                            v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_1')"
                            class="col-6 ml-4 mb-2 mt-2">
                            <coozzy-form-textarea
                              :show-label="false"
                              :is-read-only="readOnly"
                              name="description"
                              :rows="2"
                              :placeholder="$t('message.advertisement.description')"
                              :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_1')"
                              @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_1', $event)" />
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <div
                            class="col-12">
                            <coozzy-form-checkbox
                              value="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_2"
                              name="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_2"
                              :disabled="readOnly"
                              :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_2')"
                              @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_2')">
                              {{
                                $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_2')
                              }}
                            </coozzy-form-checkbox>
                          </div>
                          <div
                            v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_2')"
                            class="col-6 ml-4 mb-2 mt-2">
                            <coozzy-form-textarea
                              :show-label="false"
                              :is-read-only="readOnly"
                              name="description"
                              :rows="2"
                              :placeholder="$t('message.advertisement.description')"
                              :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_2')"
                              @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_2', $event)" />
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <div
                            class="col-12">
                            <coozzy-form-checkbox
                              value="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_3"
                              name="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_3"
                              :disabled="readOnly"
                              :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_3')"
                              @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_3')">
                              {{
                                $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_3')
                              }}
                            </coozzy-form-checkbox>
                          </div>
                          <div
                            v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_3')"
                            class="col-6 ml-4 mb-2 mt-2">
                            <coozzy-form-textarea
                              :show-label="false"
                              :is-read-only="readOnly"
                              name="description"
                              :rows="2"
                              :placeholder="$t('message.advertisement.description')"
                              :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_3')"
                              @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_3', $event)" />
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <div
                            class="col-12">
                            <coozzy-form-checkbox
                              value="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_1"
                              name="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_1"
                              :disabled="readOnly"
                              :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_1')"
                              @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_1')">
                              {{
                                $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_1')
                              }}
                            </coozzy-form-checkbox>
                          </div>
                          <div
                            v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_1')"
                            class="col-6 ml-4 mb-2 mt-2">
                            <coozzy-form-textarea
                              :show-label="false"
                              :is-read-only="readOnly"
                              name="description"
                              :rows="2"
                              :placeholder="$t('message.advertisement.description')"
                              :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_1')"
                              @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_1', $event)" />
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <div
                            class="col-12">
                            <coozzy-form-checkbox
                              value="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_2"
                              name="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_2"
                              :disabled="readOnly"
                              :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_2')"
                              @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_2')">
                              {{
                                $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_2')
                              }}
                            </coozzy-form-checkbox>
                          </div>
                          <div
                            v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_2')"
                            class="col-6 ml-4 mb-2 mt-2">
                            <coozzy-form-textarea
                              :show-label="false"
                              :is-read-only="readOnly"
                              name="description"
                              :rows="2"
                              :placeholder="$t('message.advertisement.description')"
                              :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_2')"
                              @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_2', $event)" />
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <div
                            class="col-12">
                            <coozzy-form-checkbox
                              value="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_3"
                              name="FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_3"
                              :disabled="readOnly"
                              :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_3')"
                              @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_3')">
                              {{
                                $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_3')
                              }}
                            </coozzy-form-checkbox>
                          </div>
                          <div
                            v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_3')"
                            class="col-6 ml-4 mb-2 mt-2">
                            <coozzy-form-textarea
                              :show-label="false"
                              :is-read-only="readOnly"
                              name="description"
                              :rows="2"
                              :placeholder="$t('message.advertisement.description')"
                              :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_3')"
                              @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_3', $event)" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_DISH_WASHER"
                          name="FLAT_RATE_RENT_COMPONENT_DISH_WASHER"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_DISH_WASHER')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_DISH_WASHER')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_DISH_WASHER')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_DISH_WASHER')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_DISH_WASHER')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_DISH_WASHER', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_MAINTENANCE"
                          name="FLAT_RATE_RENT_COMPONENT_MAINTENANCE"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_MAINTENANCE')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_MAINTENANCE')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_MAINTENANCE')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_MAINTENANCE')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_MAINTENANCE')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_MAINTENANCE', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING"
                          name="FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER"
                          name="FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_HEATING"
                          name="FLAT_RATE_RENT_COMPONENT_HEATING"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_HEATING')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_HEATING')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_HEATING')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_HEATING')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_HEATING')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_HEATING', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_GARBAGE"
                          name="FLAT_RATE_RENT_COMPONENT_GARBAGE"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_GARBAGE')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_GARBAGE')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_GARBAGE')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_GARBAGE')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_GARBAGE')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_GARBAGE', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_ELEVATOR"
                          name="FLAT_RATE_RENT_COMPONENT_ELEVATOR"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ELEVATOR')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_ELEVATOR')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_ELEVATOR')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ELEVATOR')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_ELEVATOR')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_ELEVATOR', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_TV"
                          name="FLAT_RATE_RENT_COMPONENT_TV"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_TV')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_TV')">
                          {{ $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_TV') }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_TV')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_TV')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_TV', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS"
                          name="FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION"
                          name="FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION', $event)" />
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_ELECTRICITY"
                          name="FLAT_RATE_RENT_COMPONENT_ELECTRICITY"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ELECTRICITY')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_ELECTRICITY')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_ELECTRICITY')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ELECTRICITY')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_ELECTRICITY')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_ELECTRICITY', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE"
                          name="FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE"
                          name="FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE"
                          name="FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_FREEZER"
                          name="FLAT_RATE_RENT_COMPONENT_FREEZER"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_FREEZER')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_FREEZER')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_FREEZER')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_FREEZER')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_FREEZER')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_FREEZER', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_WATER"
                          name="FLAT_RATE_RENT_COMPONENT_WATER"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_WATER')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_WATER')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_WATER')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_WATER')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_WATER')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_WATER', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE"
                          name="FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE"
                          name="FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT"
                          name="FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT', $event)" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-6">
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS"
                          name="FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS', $event)" />
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND"
                          name="FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND"
                          :disabled="readOnly"
                          :initial="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND')"
                          @change="checkFlatRate($event, 'FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND')">
                          {{
                            $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckFlatRate('FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getFlatRateText('FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND')"
                          @input="flatRateTextChanged('FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND', $event)" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </b-collapse>
          </div>
          <div class="col-12">
            <div
              v-b-toggle="`collapse-rent-component-additionalRent`"
              class="row mt-3 ml-0">
              <h5 class="mb-2 cursor-pointer hover width-only-content">
                {{ $t('message.propertiesView.rentComponents.additionalRentComponents.title') }}
              </h5>
            </div>
            <b-collapse
              id="collapse-rent-component-additionalRent"
              :visible="expandByDefault || openedCollapses.includes('collapse-rent-component-additionalRent')">
              <template
                v-if="property.type === 'PROPERTY_TYPE_RENTAL'">
                <div class="row">
                  <div class="col-6">
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION"
                          name="ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION"
                          :disabled="readOnly"
                          :initial="initCheckAdditionalRent('ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION')"
                          @change="checkAdditionalRent($event, 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION')">
                          {{
                            $t('message.propertiesView.rentComponents.additionalRentComponents.ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckAdditionalRent('ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getAdditionalRentText('ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION')"
                          @input="additionalRentTextChanged('ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION"
                          name="ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION"
                          :disabled="readOnly"
                          :initial="initCheckAdditionalRent('ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION')"
                          @change="checkAdditionalRent($event, 'ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION')">
                          {{
                            $t('message.propertiesView.rentComponents.additionalRentComponents.ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckAdditionalRent('ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getAdditionalRentText('ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION')"
                          @input="additionalRentTextChanged('ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION', $event)" />
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT"
                          name="ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT"
                          :disabled="readOnly"
                          :initial="initCheckAdditionalRent('ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT')"
                          @change="checkAdditionalRent($event, 'ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT')">
                          {{
                            $t('message.propertiesView.rentComponents.additionalRentComponents.ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckAdditionalRent('ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getAdditionalRentText('ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT')"
                          @input="additionalRentTextChanged('ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT', $event)" />
                      </div>
                    </div>
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="ADDITIONAL_RENT_COMPONENT_AMORTIZATION"
                          name="ADDITIONAL_RENT_COMPONENT_AMORTIZATION"
                          :disabled="readOnly"
                          :initial="initCheckAdditionalRent('ADDITIONAL_RENT_COMPONENT_AMORTIZATION')"
                          @change="checkAdditionalRent($event, 'ADDITIONAL_RENT_COMPONENT_AMORTIZATION')">
                          {{
                            $t('message.propertiesView.rentComponents.additionalRentComponents.ADDITIONAL_RENT_COMPONENT_AMORTIZATION')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckAdditionalRent('ADDITIONAL_RENT_COMPONENT_AMORTIZATION')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getAdditionalRentText('ADDITIONAL_RENT_COMPONENT_AMORTIZATION')"
                          @input="additionalRentTextChanged('ADDITIONAL_RENT_COMPONENT_AMORTIZATION', $event)" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-6">
                    <div
                      class="row">
                      <div
                        class="col-12">
                        <coozzy-form-checkbox
                          value="ADDITIONAL_RENT_COMPONENT_RENT"
                          name="ADDITIONAL_RENT_COMPONENT_RENT"
                          :disabled="readOnly"
                          :initial="initCheckAdditionalRent('ADDITIONAL_RENT_COMPONENT_RENT')"
                          @change="checkAdditionalRent($event, 'ADDITIONAL_RENT_COMPONENT_RENT')">
                          {{
                            $t('message.propertiesView.rentComponents.additionalRentComponents.ADDITIONAL_RENT_COMPONENT_RENT')
                          }}
                        </coozzy-form-checkbox>
                      </div>
                      <div
                        v-if="initCheckAdditionalRent('ADDITIONAL_RENT_COMPONENT_RENT')"
                        class="col-6 ml-4 mb-2 mt-2">
                        <coozzy-form-textarea
                          :show-label="false"
                          :is-read-only="readOnly"
                          name="description"
                          :rows="2"
                          :placeholder="$t('message.advertisement.description')"
                          :initial="getAdditionalRentText('ADDITIONAL_RENT_COMPONENT_RENT')"
                          @input="additionalRentTextChanged('ADDITIONAL_RENT_COMPONENT_RENT', $event)" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </b-collapse>
          </div>
        </div>
      </b-collapse>
    </div>
  </section>
</template>

<script>

import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { onboarding } from '@/mixins/onboarding'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'

export default {
  name: 'PropertyRentComponents',
  components: { CoozzyFormTextarea, CoozzyFormCheckbox },
  mixins: [onboarding],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    propertySelected: {
      type: Object,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      property: this.propertySelected ? this.propertySelected : null
    }
  },
  methods: {
    checkAdvancePayment(checked, rentComponent) {
      const i = this.property.advancePaymentRentComponents.findIndex(x => x.rentComponent === rentComponent)
      if (i !== -1) {
        this.property.advancePaymentRentComponents[i].inactive = !checked
      } else {
        this.property.advancePaymentRentComponents.push({
          rentComponent: rentComponent,
          description: '',
          inactive: !checked
        })
      }
      this.$emit('has-change', true)
    },
    checkFlatRate(checked, rentComponent) {
      const i = this.property.flatRateRentComponents.findIndex(x => x.rentComponent === rentComponent)
      if (i !== -1) {
        this.property.flatRateRentComponents[i].inactive = !checked
      } else {
        this.property.flatRateRentComponents.push({
          rentComponent: rentComponent,
          description: '',
          inactive: !checked
        })
      }
      this.$emit('has-change', true)
    },
    checkAdditionalRent(checked, rentComponent) {
      const i = this.property.additionalRentComponents.findIndex(x => x.rentComponent === rentComponent)
      if (i !== -1) {
        this.property.additionalRentComponents[i].inactive = !checked
      } else {
        this.property.additionalRentComponents.push({
          rentComponent: rentComponent,
          description: '',
          inactive: !checked
        })
      }
      this.$emit('has-change', true)
    },
    initCheckAdvancePayment(value) {
      const advancePaymentRentComponent = this.property.advancePaymentRentComponents.find(x => x.rentComponent === value)
      if (advancePaymentRentComponent) {
        return !advancePaymentRentComponent.inactive
      }
      return false
    },
    initCheckFlatRate(value) {
      // this.property.flatRateRentComponents.flatMap(x => x.rentComponent).includes(value) || (!this.property.id && value === 'FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS')
      const flatRateRentComponent = this.property.flatRateRentComponents.find(x => x.rentComponent === value)
      let inactive = true
      if (flatRateRentComponent) {
        inactive = flatRateRentComponent.inactive
      }
      return !this.property.id && value === 'FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS' ? true : !inactive
    },
    initCheckAdditionalRent(value) {
      const additionalRentComponent = this.property.additionalRentComponents.find(x => x.rentComponent === value)
      if (additionalRentComponent) {
        return !additionalRentComponent.inactive
      }
      return false
    },
    advancePaymentTextChanged(item, val) {
      this.property.advancePaymentRentComponents.forEach(x => {
        if (x.rentComponent === item) {
          x.description = val
        }
      })
      this.$emit('has-change', true)
    },
    additionalRentTextChanged(item, val) {
      this.property.additionalRentComponents.forEach(x => {
        if (x.rentComponent === item) {
          x.description = val
        }
      })
      this.$emit('has-change', true)
    },
    flatRateTextChanged(item, val) {
      this.property.flatRateRentComponents.forEach(x => {
        if (x.rentComponent === item) {
          x.description = val
        }
      })
      this.$emit('has-change', true)
    },
    getAdvancePaymentText(val) {
      const advancePaymentRentComponent = this.property.advancePaymentRentComponents.find(x => x.rentComponent === val)
      if (advancePaymentRentComponent) {
        return advancePaymentRentComponent.description
      }
      return ''
    },
    getFlatRateText(val) {
      const flatRateRentComponent = this.property.flatRateRentComponents.find(x => x.rentComponent === val)
      if (flatRateRentComponent) {
        return flatRateRentComponent.description
      }
      return ''
    },
    getAdditionalRentText(val) {
      const additionalRentComponent = this.property.additionalRentComponents.find(x => x.rentComponent === val)
      if (additionalRentComponent) {
        return additionalRentComponent.description
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.custom-control-input:disabled ~ .custom-control-label) {
  color: #333;
}
</style>
