<template>
  <section>
    <b-modal
      id="fake-send-mail-modal"
      ref="fake-send-mail-modal">
      <div slot="modal-title">
        {{ $t('message.marketingMessages.sendMail') }}
      </div>
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <div
        v-else
        class="row">
        <div class="col-sm-12 col-md-12 mb-3">
          <label>{{ $t('message.marketingMessages.mail.mailbox') }}</label>
          <p>{{ from }}</p>
        </div>
        <div class="col-sm-12 col-md-12 mb-3">
          <label>{{ $t('message.marketingMessages.mail.to') }}</label>
          <p>{{ to }}</p>
        </div>
        <div class="col-sm-12 col-md-12 mb-3">
          <coozzy-form-input
            v-model="subject"
            :name="$t('message.marketingMessages.mail.subject')" />
        </div>
        <div
          class="col-sm-12 col-md-12 mb-3">
          <label for="description">{{ $t('message.marketingMessages.mail.body') }}</label>
          <coozzy-form-textarea
            id="description"
            v-model="bodyText"
            :value-text-area="bodyText"
            :initial="bodyText"
            :show-label="false"
            :text="bodyText"
            :rows="10">
            {{ bodyText }}
          </coozzy-form-textarea>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          design="green"
          class="float-right ml-3"
          :disabled="loading"
          @click="sendMail()">
          {{ $t('message.marketingMessages.sendMail') }}
        </coozzy-button>
        <coozzy-button
          design="default"
          :disabled="loading"
          @click="$bvModal.hide('fake-send-mail-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import MessageApi from '@/misc/apis/MessageApi'
import Vue from 'vue'

export default {
  name: 'FakeSendMailModal',
  components: {
    CoozzyButton,
    CoozzySpinner,
    CoozzyFormInput,
    CoozzyFormTextarea
  },
  props: {
    baseEmail: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      bodyText: '',
      subject: this.baseEmail.subject,
      from: this.$store.getters['user/getCurrentUser'].email.toLowerCase() === this.baseEmail.from.email.toLowerCase() ? this.baseEmail.from.email.toLowerCase() : this.baseEmail.to[0].email.toLowerCase(),
      to: this.$store.getters['user/getCurrentUser'].email.toLowerCase() === this.baseEmail.from.email.toLowerCase() ? this.baseEmail.to[0].email.toLowerCase() : this.baseEmail.from.email.toLowerCase(),
      loading: false
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', () => {
      this.bodyText = '\n\n' + this.baseEmail.bodyText
      this.subject = this.baseEmail.subject
      this.from = this.$store.getters['user/getCurrentUser'].email.toLowerCase() === this.baseEmail.from.email.toLowerCase() ? this.baseEmail.from.email.toLowerCase() : this.baseEmail.to[0].email.toLowerCase()
      this.to = this.$store.getters['user/getCurrentUser'].email.toLowerCase() === this.baseEmail.from.email.toLowerCase() ? this.baseEmail.to[0].email.toLowerCase() : this.baseEmail.from.email.toLowerCase()
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'fake-send-mail-modal') {
        this.bodyText = ''
      }
    })
    this.bodyText = '\n\n' + this.baseEmail.bodyText
  },
  methods: {
    show() {
      this.$refs['fake-send-mail-modal'].show()
    },
    hide() {
      this.bodyText = ''
      this.$emit('hide-mail')
      this.$refs['fake-send-mail-modal'].hide()
    },
    sendMail() {
      let from = {}
      let to = {}
      const mailfrom = this.$store.getters['user/getCurrentUser'].email.toLowerCase() === this.baseEmail.from.email.toLowerCase() ? this.baseEmail.from : this.baseEmail.to
      if (Array.isArray(mailfrom)) {
        from = {
          email: mailfrom[0].email,
          name: mailfrom[0].name
        }
      } else {
        from = { email: mailfrom.email, name: mailfrom.name }
      }
      const mailto = this.$store.getters['user/getCurrentUser'].email.toLowerCase() === this.baseEmail.from.email.toLowerCase() ? this.baseEmail.to : this.baseEmail.from
      if (Array.isArray(mailto)) {
        to = {
          email: mailto[0].email,
          name: mailto[0].name
        }
      } else {
        to = { email: mailto.email, name: mailto.name }
      }
      MessageApi.sendMail(
        this.bodyText,
        from,
        this.subject,
        [to],
        this.baseEmail.referenceIds,
        [],
        this.$store.getters['user/getAccountId'],
        this.baseEmail.id
      ).then(() => {
        Vue.toasted.show(this.$t('message.successMessages.mailSend'), { type: 'success' })
        this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        this.hide()
        this.$emit('send-mail')
      }).catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.savingErrors.mailSend'), { type: 'error' })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.modal-dialog) {
 max-width: 1200px !important;
}
</style>
