<template>
  <coozzy-card :title="$t('message.settings.ticketing.informationTickets')">
    <p>
      {{ $t('message.settings.ticketing.subTitle') }}
    </p>
    <div
      v-if="loading"
      style="height: 30px">
      <coozzy-spinner />
    </div>
    <div v-else>
      <!--   Insurance   -->
      <coozzy-card class="global-card">
        <div
          v-b-toggle="`collapse-ticketing-insurance`"
          class="collapse-title">
          <h5 class="cursor-pointer">
            {{ $t('message.onBoarding.createBuilding.insurance') }}
          </h5>
        </div>
        <b-collapse
          v-if="insurance_expire"
          :id="`collapse-ticketing-insurance`"
          :visible="true">
          <information-ticket-item
            ref="insurance_expire"
            :list-employee="listAllEmployees"
            :schedule="insurance_expire"
            @schedule-updated="scheduleUpdated" />
        </b-collapse>
      </coozzy-card>
      <!--   Mortgage   -->
      <coozzy-card class="global-card">
        <div
          v-b-toggle="`collapse-ticketing-mortgage`"
          class="collapse-title">
          <h5 class="cursor-pointer">
            {{ $t('message.onBoarding.createBuilding.mortgage') }}
          </h5>
        </div>
        <b-collapse
          v-if="mortgage_expire"
          :id="`collapse-ticketing-mortgage`"
          :visible="true">
          <information-ticket-item
            ref="mortgage_expire"
            :list-employee="listAllEmployees"
            :schedule="mortgage_expire"
            @schedule-updated="scheduleUpdated" />
        </b-collapse>
      </coozzy-card>
      <!--   Building strategy   -->
      <coozzy-card class="global-card">
        <div
          v-b-toggle="`collapse-ticketing-building`"
          class="collapse-title">
          <h5 class="cursor-pointer">
            {{ $t('message.generic.building') }}
          </h5>
        </div>
        <b-collapse
          v-if="building_strategy_revise"
          :id="`collapse-ticketing-building`"
          :visible="true">
          <information-ticket-item
            ref="building_strategy_revise"
            :list-employee="listAllEmployees"
            :schedule="building_strategy_revise"
            @schedule-updated="scheduleUpdated" />
        </b-collapse>
      </coozzy-card>
      <!--   Device   -->
      <coozzy-card class="global-card">
        <div
          v-b-toggle="`collapse-ticketing-device`"
          class="collapse-title">
          <h5 class="cursor-pointer">
            {{ $t('message.onBoarding.devices.title') }}
          </h5>
        </div>
        <b-collapse
          v-if="device_warranty_expire && device_maintenance_expire && device_inspection_expire && device_revision_expire"
          :id="`collapse-ticketing-device`"
          :visible="true">
          <information-ticket-item
            ref="device_warranty_expire"
            :list-employee="listAllEmployees"
            :schedule="device_warranty_expire"
            @schedule-updated="scheduleUpdated" />
          <information-ticket-item
            ref="device_maintenance_expire"
            :list-employee="listAllEmployees"
            :schedule="device_maintenance_expire"
            @schedule-updated="scheduleUpdated" />
          <information-ticket-item
            ref="device_inspection_expire"
            :list-employee="listAllEmployees"
            :schedule="device_inspection_expire"
            @schedule-updated="scheduleUpdated" />
          <information-ticket-item
            ref="device_revision_expire"
            :list-employee="listAllEmployees"
            :schedule="device_revision_expire"
            @schedule-updated="scheduleUpdated" />
          <information-ticket-item
            ref="device_maintenance_next"
            :list-employee="listAllEmployees"
            :schedule="device_maintenance_next"
            @schedule-updated="scheduleUpdated" />
          <information-ticket-item
            ref="device_inspection_next"
            :list-employee="listAllEmployees"
            :schedule="device_inspection_next"
            @schedule-updated="scheduleUpdated" />
          <information-ticket-item
            ref="device_revision_next"
            :list-employee="listAllEmployees"
            :schedule="device_revision_next"
            @schedule-updated="scheduleUpdated" />
        </b-collapse>
      </coozzy-card>
      <!--   Tenancies   -->
      <coozzy-card class="global-card">
        <div
          v-b-toggle="`collapse-ticketing-tenancies`"
          class="collapse-title">
          <h5 class="cursor-pointer">
            {{ $t('message.onBoarding.buildings.objects.tenancies.title') }}
          </h5>
        </div>
        <b-collapse
          v-if="tenancy_fixed_term_ends && tenancy_option_expire"
          :id="`collapse-ticketing-tenancies`"
          :visible="true">
          <information-ticket-item
            ref="tenancy_fixed_term_ends"
            :list-employee="listAllEmployees"
            :schedule="tenancy_fixed_term_ends"
            @schedule-updated="scheduleUpdated" />
          <information-ticket-item
            ref="tenancy_option_expire"
            :list-employee="listAllEmployees"
            :schedule="tenancy_option_expire"
            @schedule-updated="scheduleUpdated" />
          <information-ticket-item
            ref="tenancy_deposit_due_date"
            :list-employee="listAllEmployees"
            :schedule="tenancy_deposit_due_date"
            @schedule-updated="scheduleUpdated" />
        </b-collapse>
      </coozzy-card>
    </div>
    <div class="col">
      <coozzy-button
        class="float-right mt-2"
        design="green"
        @click="saveSettings">
        {{ $t('message.generic.form.save') }}
      </coozzy-button>
    </div>
  </coozzy-card>
</template>
<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { user } from '@/mixins/user'
import TicketApi from '@/misc/apis/TicketApi'
import Vue from 'vue'
import UserApi from '@/misc/apis/UserApi'
import { mapActions, mapGetters } from 'vuex'
import InformationTicketItem from '@/settings/components/InformationTicketItem.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'

export default {
  name: 'TicketingSettings',
  components: {
    CoozzyButton,
    InformationTicketItem,
    CoozzySpinner,
    CoozzyCard
  },
  mixins: [user],
  data() {
    return {
      loading: false,
      schedule: {
        accountId: '',
        assigneeUserId: '',
        category: '',
        createAtAmount: 0,
        createAtUnit: 'SCHEDULE_UNIT_UNDEFINED',
        dueDateAmount: 0,
        dueDateUnit: 'SCHEDULE_UNIT_UNDEFINED',
        enabled: false,
        language: 'PREFERRED_LANGUAGE_UNDEFINED',
        useObjectAssignee: false
      },
      insurance_expire: null,
      mortgage_expire: null,
      building_strategy_revise: null,
      device_warranty_expire: null,
      device_maintenance_expire: null,
      device_inspection_expire: null,
      device_revision_expire: null,
      tenancy_fixed_term_ends: null,
      tenancy_option_expire: null,
      tenancy_deposit_due_date: null,
      device_maintenance_next: null,
      device_inspection_next: null,
      device_revision_next: null,
      currentEmployeeList: [],
      listAllEmployees: [],
      ownerAccounts: [],
      listCategories: [
        'insurance_expire',
        'mortgage_expire',
        'building_strategy_revise',
        'device_warranty_expire',
        'device_maintenance_expire',
        'device_inspection_expire',
        'device_revision_expire',
        'device_inspection_next',
        'device_maintenance_next',
        'device_revision_next',
        'tenancy_fixed_term_ends',
        'tenancy_option_expire',
        'tenancy_deposit_due_date'
      ]
    }
  },
  computed: {
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees().filter(x => !x.blocked)
      }
      return []
    }
  },
  mounted: function () {
    this.loading = true
    this.loadEmployees()
    this.getSchedules()
    this.getListEmployees([this.accountId])
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    saveSettings() {
      const insuranceExpireInvalid = this.$refs.insurance_expire && this.$refs.insurance_expire.isInvalid()
      const mortgageExpireInvalid = this.$refs.mortgage_expire && this.$refs.mortgage_expire.isInvalid()
      const buildingStrategyReviseInvalid = this.$refs.building_strategy_revise && this.$refs.building_strategy_revise.isInvalid()
      const deviceWarrantyExpireInvalid = this.$refs.device_warranty_expire && this.$refs.device_warranty_expire.isInvalid()
      const deviceMaintenanceExpireInvalid = this.$refs.device_maintenance_expire && this.$refs.device_maintenance_expire.isInvalid()
      const deviceInspectionExpireInvalid = this.$refs.device_inspection_expire && this.$refs.device_inspection_expire.isInvalid()
      const deviceRevisionExpireInvalid = this.$refs.device_revision_expire && this.$refs.device_revision_expire.isInvalid()
      const deviceMaintenanceNextInvalid = this.$refs.device_maintenance_next && this.$refs.device_maintenance_next.isInvalid()
      const deviceInspectionNextInvalid = this.$refs.device_inspection_next && this.$refs.device_inspection_next.isInvalid()
      const deviceRevisionNextInvalid = this.$refs.device_revision_next && this.$refs.device_revision_next.isInvalid()
      const tenancyFixedTermEndsInvalid = this.$refs.tenancy_fixed_term_ends && this.$refs.tenancy_fixed_term_ends.isInvalid()
      const tenancyOptionExpireInvalid = this.$refs.tenancy_option_expire && this.$refs.tenancy_option_expire.isInvalid()
      const tenancyDepositDueDate = this.$refs.tenancy_deposit_due_date && this.$refs.tenancy_deposit_due_date.isInvalid()

      if (insuranceExpireInvalid || mortgageExpireInvalid || buildingStrategyReviseInvalid || deviceWarrantyExpireInvalid ||
        deviceMaintenanceExpireInvalid || deviceInspectionExpireInvalid || deviceRevisionExpireInvalid || tenancyFixedTermEndsInvalid ||
        tenancyOptionExpireInvalid || tenancyDepositDueDate || deviceMaintenanceNextInvalid || deviceInspectionNextInvalid || deviceRevisionNextInvalid) {
        Vue.toasted.show(this.$tc('message.savingErrors.mandatoryMissing'), { type: 'error' })
      } else {
        this.loading = true
        const schedules = []
        if (this.insurance_expire) {
          schedules.push(this.insurance_expire)
        }
        if (this.mortgage_expire) {
          schedules.push(this.mortgage_expire)
        }
        if (this.building_strategy_revise) {
          schedules.push(this.building_strategy_revise)
        }
        if (this.device_warranty_expire) {
          schedules.push(this.device_warranty_expire)
        }
        if (this.device_maintenance_expire) {
          schedules.push(this.device_maintenance_expire)
        }
        if (this.device_inspection_expire) {
          schedules.push(this.device_inspection_expire)
        }
        if (this.device_revision_expire) {
          schedules.push(this.device_revision_expire)
        }
        if (this.device_maintenance_next) {
          schedules.push(this.device_maintenance_next)
        }
        if (this.device_inspection_next) {
          schedules.push(this.device_inspection_next)
        }
        if (this.device_revision_next) {
          schedules.push(this.device_revision_next)
        }
        if (this.tenancy_fixed_term_ends) {
          schedules.push(this.tenancy_fixed_term_ends)
        }
        if (this.tenancy_option_expire) {
          schedules.push(this.tenancy_option_expire)
        }
        if (this.tenancy_deposit_due_date) {
          schedules.push(this.tenancy_deposit_due_date)
        }
        TicketApi.setSchedules(schedules).then(() => {
          this.loading = false
          this.getSchedules()
        }).catch((error) => {
          this.loading = false
          console.log(error)
        })
      }
    },
    getSchedules() {
      TicketApi.listSchedules(this.accountId).then((response) => {
        if (response.schedules.length > 0) {
          response.schedules.forEach((schedule) => {
            switch (schedule.category) {
              case 'insurance_expire':
                this.insurance_expire = schedule
                break
              case 'mortgage_expire':
                this.mortgage_expire = schedule
                break
              case 'building_strategy_revise':
                this.building_strategy_revise = schedule
                break
              case 'device_warranty_expire':
                this.device_warranty_expire = schedule
                break
              case 'device_maintenance_expire':
                this.device_maintenance_expire = schedule
                break
              case 'device_inspection_expire':
                this.device_inspection_expire = schedule
                break
              case 'device_revision_expire':
                this.device_revision_expire = schedule
                break
              case 'device_maintenance_next':
                this.device_maintenance_next = schedule
                break
              case 'device_inspection_next':
                this.device_inspection_next = schedule
                break
              case 'device_revision_next':
                this.device_revision_next = schedule
                break
              case 'tenancy_fixed_term_ends':
                this.tenancy_fixed_term_ends = schedule
                break
              case 'tenancy_option_expire':
                this.tenancy_option_expire = schedule
                break
              case 'tenancy_deposit_due_date':
                this.tenancy_deposit_due_date = schedule
                break
            }
          })
          this.$nextTick(() => {
            this.loading = false
            this.checkMissingCategories()
          })
        } else {
          this.schedule.accountId = this.accountId
          this.insurance_expire = JSON.parse(JSON.stringify(this.schedule))
          this.insurance_expire.category = 'insurance_expire'

          this.mortgage_expire = JSON.parse(JSON.stringify(this.schedule))
          this.mortgage_expire.category = 'mortgage_expire'

          this.building_strategy_revise = JSON.parse(JSON.stringify(this.schedule))
          this.building_strategy_revise.category = 'building_strategy_revise'

          this.device_warranty_expire = JSON.parse(JSON.stringify(this.schedule))
          this.device_warranty_expire.category = 'device_warranty_expire'

          this.device_maintenance_expire = JSON.parse(JSON.stringify(this.schedule))
          this.device_maintenance_expire.category = 'device_maintenance_expire'

          this.device_inspection_expire = JSON.parse(JSON.stringify(this.schedule))
          this.device_inspection_expire.category = 'device_inspection_expire'

          this.device_revision_expire = JSON.parse(JSON.stringify(this.schedule))
          this.device_revision_expire.category = 'device_revision_expire'

          this.device_maintenance_next = JSON.parse(JSON.stringify(this.schedule))
          this.device_maintenance_next.category = 'device_maintenance_next'

          this.device_inspection_next = JSON.parse(JSON.stringify(this.schedule))
          this.device_inspection_next.category = 'device_inspection_next'

          this.device_revision_next = JSON.parse(JSON.stringify(this.schedule))
          this.device_revision_next.category = 'device_revision_next'

          this.tenancy_fixed_term_ends = JSON.parse(JSON.stringify(this.schedule))
          this.tenancy_fixed_term_ends.category = 'tenancy_fixed_term_ends'

          this.tenancy_option_expire = JSON.parse(JSON.stringify(this.schedule))
          this.tenancy_option_expire.category = 'tenancy_option_expire'

          this.tenancy_deposit_due_date = JSON.parse(JSON.stringify(this.schedule))
          this.tenancy_deposit_due_date.category = 'tenancy_deposit_due_date'

          this.$nextTick(() => {
            this.loading = false
          })
        }
      })
    },
    scheduleUpdated(schedule) {
      if (['unassigned', 'assigneeObject'].includes(schedule.assigneeUserId)) {
        schedule.useObjectAssignee = schedule.assigneeUserId === 'assigneeObject'
        schedule.assigneeUserId = ''
      } else {
        schedule.useObjectAssignee = false
      }
      switch (schedule.category) {
        case 'insurance_expire':
          this.insurance_expire = schedule
          break
        case 'mortgage_expire':
          this.mortgage_expire = schedule
          break
        case 'building_strategy_revise':
          this.building_strategy_revise = schedule
          break
        case 'device_warranty_expire':
          this.device_warranty_expire = schedule
          break
        case 'device_maintenance_expire':
          this.device_maintenance_expire = schedule
          break
        case 'device_inspection_expire':
          this.device_inspection_expire = schedule
          break
        case 'device_revision_expire':
          this.device_revision_expire = schedule
          break
        case 'device_maintenance_next':
          this.device_maintenance_next = schedule
          break
        case 'device_inspection_next':
          this.device_inspection_next = schedule
          break
        case 'device_revision_next':
          this.device_revision_next = schedule
          break
        case 'tenancy_fixed_term_ends':
          this.tenancy_fixed_term_ends = schedule
          break
        case 'tenancy_option_expire':
          this.tenancy_option_expire = schedule
          break
        case 'tenancy_deposit_due_date':
          this.tenancy_deposit_due_date = schedule
          break
      }
    },
    checkMissingCategories() {
      this.listCategories.forEach((category) => {
        if (this[category] === null) {
          this.schedule.accountId = this.accountId
          switch (category) {
            case 'insurance_expire':
              this.insurance_expire = JSON.parse(JSON.stringify(this.schedule))
              this.insurance_expire.category = 'insurance_expire'
              break
            case 'mortgage_expire':
              this.mortgage_expire = JSON.parse(JSON.stringify(this.schedule))
              this.mortgage_expire.category = 'mortgage_expire'
              break
            case 'building_strategy_revise':
              this.building_strategy_revise = JSON.parse(JSON.stringify(this.schedule))
              this.building_strategy_revise.category = 'building_strategy_revise'
              break
            case 'device_warranty_expire':
              this.device_warranty_expire = JSON.parse(JSON.stringify(this.schedule))
              this.device_warranty_expire.category = 'device_warranty_expire'
              break
            case 'device_maintenance_expire':
              this.device_maintenance_expire = JSON.parse(JSON.stringify(this.schedule))
              this.device_maintenance_expire.category = 'device_maintenance_expire'
              break
            case 'device_inspection_expire':
              this.device_inspection_expire = JSON.parse(JSON.stringify(this.schedule))
              this.device_inspection_expire.category = 'device_inspection_expire'
              break
            case 'device_revision_expire':
              this.device_revision_expire = JSON.parse(JSON.stringify(this.schedule))
              this.device_revision_expire.category = 'device_revision_expire'
              break
            case 'device_maintenance_next':
              this.device_maintenance_next = JSON.parse(JSON.stringify(this.schedule))
              this.device_maintenance_next.category = 'device_maintenance_next'
              break
            case 'device_inspection_next':
              this.device_inspection_next = JSON.parse(JSON.stringify(this.schedule))
              this.device_inspection_next.category = 'device_inspection_next'
              break
            case 'device_revision_next':
              this.device_revision_next = JSON.parse(JSON.stringify(this.schedule))
              this.device_revision_next.category = 'device_revision_next'
              break
            case 'tenancy_fixed_term_ends':
              this.tenancy_fixed_term_ends = JSON.parse(JSON.stringify(this.schedule))
              this.tenancy_fixed_term_ends.category = 'tenancy_fixed_term_ends'
              break
            case 'tenancy_option_expire':
              this.tenancy_option_expire = JSON.parse(JSON.stringify(this.schedule))
              this.tenancy_option_expire.category = 'tenancy_option_expire'
              break
            case 'tenancy_deposit_due_date':
              this.tenancy_deposit_due_date = JSON.parse(JSON.stringify(this.schedule))
              this.tenancy_deposit_due_date.category = 'tenancy_deposit_due_date'
              break
          }
        }
      })
    },
    getListEmployees(listIds) {
      TicketApi.listTicketingEnabled(listIds).then(rsp => {
        UserApi.listAllEmployees(rsp.enabledAccountIds).then(response1 => {
          this.currentEmployeeList = response1.employees
          if (this.currentEmployeeList.length > 0) {
            const ids = [...new Set(this.currentEmployeeList.map(x => x.accountId).concat(this.employeeList.map(x => x.accountId)))]
            UserApi.listAccountsByIds(ids)
              .then(accounts => {
                this.ownerAccounts = accounts.accounts
                this.addAvailableEmployees()
              })
          } else {
            this.addAvailableEmployees()
          }
        })
      })
    },
    addAvailableEmployees() {
      const array = []
      if (this.employeeList.length !== 0) {
        this.employeeList.filter(x => (x.blocked === false && x.deleted === false)).forEach((employee) => {
          const accountName = this.ownerAccounts.find(x => x.id === employee.accountId)
          array.push({
            label: (employee.profile.firstName ? employee.profile.firstName + ' ' : '') + employee.profile.lastName,
            value: employee,
            company: (accountName ? ' ' + (accountName.company ? accountName.company?.name : accountName.individual.firstName + ' ' + accountName.individual.lastName) : '')
          })
        })
      }
      if (this.currentEmployeeList.length !== 0) {
        this.currentEmployeeList.filter(x => (x.blocked === false && x.deleted === false)).forEach((employee) => {
          const accountName = this.ownerAccounts.find(x => x.id === employee.accountId)
          array.push({
            label: ((employee.profile.firstName ? employee.profile.firstName + ' ' : '') + employee.profile.lastName),
            value: employee,
            company: (accountName ? ' ' + (accountName.company ? accountName.company?.name : accountName.individual.firstName + ' ' + accountName.individual.lastName) : '')
          })
        })
      }
      const ids = array.map(o => o.value.id)
      const filtered = array.filter(({ value }, index) => !ids.includes(value.id, index + 1))
      const accountUsers = filtered.filter(x => parseInt(x.value.accountId) === this.accountId).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
      const otherUsers = filtered.filter(x => parseInt(x.value.accountId) !== this.accountId).sort((a, b) => (a.company > b.company ? 1 : -1))
      this.listAllEmployees = accountUsers.concat(otherUsers)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
