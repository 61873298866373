<template>
  <b-form-select
    :id="name || $attrs.id"
    :value="value"
    v-bind="$attrs"
    class="costum-select-local"
    size="sm"
    v-on="listeners">
    <slot />
  </b-form-select>
</template>

<script>
export default {
  name: 'CoozzyFormSelect',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: ''
    }
  },
  computed: {
    listeners() {
      return Object.assign({},
        this.$listeners,
        {
          select: (event) => {
            this.$emit('select', event.target.value)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .costum-select-local{
    color: black !important;
  }
</style>
