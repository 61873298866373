<template>
  <!-- Link to issue fix for mounting native elements to components -->
  <!-- It is possible to just mount one native/custom event to components -->
  <!-- https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components -->
  <button
    :class="[size, internalDesign]"
    class="btn"
    v-bind="$attrs"
    :type="type"
    v-on="mountEventListeners">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'CoozzyButton',
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'small',
      validator: (value) => {
        return ['small', 'normal', 'large'].includes(value)
      }
    },
    design: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['default', 'green', 'red', 'blue', 'primary', 'secondary', 'alert', 'transparent', 'light-green', 'grey', 'prop-green', 'tab-selection', 'deactivated'].includes(value)
      }
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    internalDesign() {
      switch (this.design) {
        case 'primary':
        case 'green':
          return 'primary'
        case 'secondary':
        case 'blue':
          return 'secondary'
        case 'alert':
        case 'red':
          return 'alert'
        case 'transparent':
          return 'transparent'
        case 'light-green':
          return 'light-green'
        case 'grey':
          return 'grey'
        case 'prop-green':
          return 'prop-green'
        case 'tab-selection':
          return 'tab-selection'
        case 'deactivated':
          return 'deactivated'
        default:
          return 'default'
      }
    },
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../../node_modules/bootstrap/scss/bootstrap.scss';

  .btn-active {
    color: $color-bg-green-light !important;
  }

  .btn-inactive {
    color: $color-red !important;
  }

  button {
    &.small {
      @extend .btn-sm;
    }

    /* stylelint-disable-next-line block-no-empty */
    &.normal {
      padding: 0.375rem 0.75rem !important;
    }

    &.large {
      @extend .btn-lg;
    }

    &.default {
      @extend .btn-light;
      color: $color-text-grey;
      background-color: #fff;
      border-color: $color-input-border;
    }

    &:active, &:hover:not([disabled]) {
      -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
    }

    &.primary, &.primary:disabled {
      @extend .btn-primary;
      color: #fff;
      background-color: $color-primary;
      border-color: $color-primary;

      &:active, &:hover:not([disabled]) {
        color: #fff;
        background-color: $color-primary-dark !important;
        border-color: $color-primary-dark !important;
      }
    }

    &.alert {
      @extend .btn-secondary;
      background-color: $color-red;
      border-color: $color-red;

      &:hover:not([disabled]) {
        background-color: $color-red-dark;
        border-color: $color-red-dark;
      }
    }

    &.secondary {
      @extend .btn-secondary;
      background-color: $color-bg-white;
      border-color: $color-primary;
      color: $color-primary;

      &:hover:not([disabled]) {
        color: $color-primary;
        background-color: #e6e6e6;
        border-color: $color-primary-dark;
      }
    }

    &.grey {
       @extend .btn-secondary;
      background-color: $color-grey-dark;
      border-color: $color-grey-dark;
    }

    &.transparent {
      @extend .btn-link;
      color: $color-text-grey-dark;
      text-decoration: none !important;
      background-color: transparent;
      box-shadow: none !important;

      &:hover:not([disabled]) {
        color: $color-grey-dark;
      }
    }

    &.light-green {
      @extend .btn-light;
      color: $color-text-grey;
      background-color: #e2f0da;
      border-color: $color-input-border;

      &:hover:not([disabled]), &:active, &:focus {
        color: $color-grey-dark;
        background-color: #e2f0da;
      }
    }

    &.prop-green {
      @extend .btn-light;
      color: $color-bg-white;
      background-color: $color-bg-green-light;
      border-color: $color-bg-green-light;

      &:hover:not([disabled]) {
        color: $color-bg-white;
        background-color: $color-bg-green;
        border-color: $color-bg-green;
      }
      &:not(:disabled):not(.disabled):active, &:focus {
        color: $color-bg-white;
        background-color: $color-bg-green-light;
        border-color: $color-bg-green-light;
      }
    }

    &.tab-selection {
      @extend .btn-light;
      color: $color-primary-dark;
      background-color: $color-bg-light-blue;
      border-color: $color-primary-dark;

      &:hover:not([disabled]) {
      color: $color-primary-dark;
      background-color: $color-bg-light-blue;
      border-color: $color-primary-dark;
      }
    }

    &.deactivated {
      @extend .btn-light;
      color: white;
      background-color: #9ea4a9;
      border-color: #9ea4a9;
      box-shadow: none;

      &:hover:not([disabled]) {
        color: white;
        background-color: #9ea4a9;
        border-color: #9ea4a9;
        box-shadow: none;
      }
    }
  }
</style>
