<template lang="html">
  <b-form-file
    ref="file-input"
    v-model="file"
    :drop-placeholder="$t('message.generic.form.dropFile')"
    :browse-text="$t('message.generic.form.browse')"
    v-bind="$attrs"
    v-on="mountEventListeners">
    <template
      slot="placeholder">
      <span v-if="showName === true">{{ $t('message.generic.form.selectFile') }} </span>
      <span
        v-if="showName === false"
        class="color_dark"><coozzy-paper-clip-icon class="mr-2" />{{ $t('message.generic.form.selectFile') }} </span>
    </template>
    <template
      slot="file-name"
      slot-scope="{names}">
      <section>
        <span v-if="showName === true">{{ names[0] }}</span>
        <span
          v-if="showName === false"
          class="color_light">
          <coozzy-paper-clip-icon class="mr-2" />{{ $t('message.generic.form.selectFile') }}<coozzy-spinner class="spinner_personnalised ml-3" />
        </span>
      </section>
    </template>
  </b-form-file>
</template>

<script>
import CoozzyPaperClipIcon from '@/framework/components/icons/CoozzyPaperClipIcon'
export default {
  name: 'CoozzyFormFileInput',
  components: {
    CoozzyPaperClipIcon
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ''
    },
    showName: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      file: null
    }
  },
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  },
  methods: {
    resetPlaceholder() {
      this.$refs['file-input'].reset()
    }
  }
}
</script>

<style lang="scss" scoped>
  .custom-file-label {
    box-shadow: 1px 1px 6px #ccc;
    font-size: 0.875rem;
    padding-left: 8px!important;
    cursor: pointer;
  }
  .spinner_personnalised {
    width: 1rem !important;
    height: 1rem !important;
    border-width: 0.15em !important;
  }

  .color_dark {
    color: #333;
  }
  .color_light{
    color: #495057;
    font-weight: 300;
  }
</style>
