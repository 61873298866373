<template>
  <img
    width="20px"
    height="20px"
    src="@/assets/icon/reload.svg"
    alt="reactivateIcon">
</template>

<script>

export default {
  name: 'CoozzyReactivateIcon'
}
</script>

<style lang="scss" scoped>
</style>
