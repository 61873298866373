<template>
  <section class="w-100">
    <div class="col-12 col-md-12 card-data p-0">
      <div class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.name') }}
        </p>
        <p class="col-8 col-md-8 mb-0">
          <template v-if="!contact.gender">
            {{ $t('message.generic.company') }}
          </template>
          <template v-else-if="contact.gender === 'UNDEFINED_GENDER'">
            <coozzy-matching-popover
              v-if="isApplicationFormGenderDifferent"
              :value="$t(`message.generic.gender.${applicationFormContact.gender.toLowerCase()}`)"
              @accepted="onApplicationFormGenderAccepted" />
          </template>
          <template v-else>
            <template v-if="contact.gender === 'MALE'">
              {{ $t('message.generic.title.mr') }}
            </template>
            <template v-if="contact.gender === 'FEMALE'">
              {{ $t('message.generic.title.mrs') }}
            </template>
            <template v-if="contact.gender === 'OTHER'">
              {{ $t('message.generic.title.divers') }}
            </template>
          </template>
          <template v-if="contact.title && contact.title !== ''">
            {{ contact.title }}
          </template>
          <br v-if="(isApplicationFormGenderDifferent || contact.gender !== 'UNDEFINED_GENDER') && contact.title !== ''">
          <router-link
            v-if="type !== 'self'"
            class="d-block link"
            :to="redirectToContactPage(contact.id)">
            {{ contact.name }}
            <coozzy-matching-popover
              v-if="isApplicationFormNameDifferent"
              :value="applicationFormName"
              @accepted="onApplicationFormNameAccepted" />
          </router-link>
          <template v-else>
            <p
              class="font-weight-bold p-0 mb-0">
              {{ contact.name }}
            </p>
          </template>
          <span class="text-red">{{ contact.active ? '' : '(' + $t('message.generic.inactive') + ')' }}</span>
        </p>
      </div>
      <div
        v-if="contact.birthName"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.birthName') }}
        </p>
        <p class="col-8 col-md-8 mb-0">
          {{ contact.birthName }}
        </p>
      </div>
      <div class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.address') }}
          <template v-if="contact.addressHistory && contact.addressHistory.length > 1">
            <br>
            <a
              class="link"
              @click="showAddressHistory">
              <small>{{ $t('message.generic.timeline') }}</small>
            </a>
          </template>
        </p>
        <div class="col-8 col-md-8 mb-0">
          <p class="row mb-0">
            <address-text
              :show-short-country="true"
              :show-state="true"
              :address="contact.address" />
            <coozzy-matching-popover
              v-if="isApplicationFormAddressDifferent"
              :value="applicationFormAddress"
              @accepted="onApplicationFormAddressAccepted" />
          </p>
        </div>
      </div>
      <div class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right pt-0">
          {{ $t('message.generic.communication') }}
        </p>
        <div
          class="col-8 col-md-8 mb-0">
          <template>
            <div
              v-if="contact.gender && (isApplicationFormMobileDifferent || getPhoneNumbers('MOBILE') !== '')"
              class="row">
              <div class="col-1">
                <coozzy-mobile-alt-icon />
              </div>
              <div class="col">
                <a
                  class="link"
                  :href="'tel:' + getPhoneNumbers('MOBILE')">
                  {{ getPhoneNumbers('MOBILE') | displayOptionalValue }}
                </a>
                <coozzy-matching-popover
                  v-if="isApplicationFormMobileDifferent"
                  :value="applicationFormContact.mobileNumber"
                  @accepted="onApplicationFormMobileAccepted" />
              </div>
            </div>
            <div
              v-if="getPhoneNumbers('WORK') !== ''"
              class="row">
              <div class="col-1">
                <coozzy-headset-icon />
              </div>
              <div class="col">
                <a
                  class="link"
                  :href="'tel:' + getPhoneNumbers('WORK')">
                  {{ getPhoneNumbers('WORK') | displayOptionalValue }}
                </a>
              </div>
            </div>
            <div
              v-if="!contact.gender && (isApplicationFormMobileDifferent || getPhoneNumbers('MOBILE') !== '')"
              class="row">
              <div class="col-1">
                <coozzy-mobile-alt-icon />
              </div>
              <div class="col">
                <a
                  class="link"
                  :href="'tel:' + getPhoneNumbers('MOBILE')">
                  {{ getPhoneNumbers('MOBILE') | displayOptionalValue }}
                </a>
                <coozzy-matching-popover
                  v-if="isApplicationFormMobileDifferent"
                  :value="applicationFormContact.mobileNumber"
                  @accepted="onApplicationFormMobileAccepted" />
              </div>
            </div>
            <template v-if="isApplicationFormPhoneDifferent || (getHomePhoneNumbers() && getHomePhoneNumbers().length > 0)">
              <div
                v-for="(number, index) in getHomePhoneNumbers()"
                :key="index"
                class="row">
                <div class="col-1">
                  <coozzy-phone-icon />
                </div>
                <div class="col">
                  <span>
                    <a
                      class="link"
                      :href="'tel:' + number">
                      {{ number }}
                    </a>
                    <coozzy-matching-popover
                      v-if="index === 0 && isApplicationFormPhoneDifferent"
                      :value="applicationFormContact.phoneNumber"
                      @accepted="onApplicationFormPhoneAccepted" />
                    <br>
                  </span>
                </div>
              </div>
              <template v-if="getHomePhoneNumbers() && getHomePhoneNumbers().length === 0">
                -
                <coozzy-matching-popover
                  :value="applicationFormContact.phoneNumber"
                  @accepted="onApplicationFormPhoneAccepted" />
              </template>
            </template>
            <div
              v-if="getPhoneNumbers('FAX') !== ''"
              class="row">
              <div class="col-1">
                <coozzy-fax-icon />
              </div>
              <div class="col">
                <a
                  class="link"
                  :href="'tel:' + getPhoneNumbers('FAX')">
                  {{ getPhoneNumbers('FAX') | displayOptionalValue }}
                </a>
              </div>
            </div>
            <div
              v-if="contact.email !== ''"
              class="row">
              <div class="col-1">
                <img
                  class="email-icon-img"
                  src="@/assets/icon/arroba.svg"
                  alt="Placeholder">
              </div>
              <div class="col">
                <template>
                  <a
                    class="link"
                    @click="sendEmailModal()">{{ contact.email }}</a>
                </template>
              </div>
            </div>
            <div
              v-if="contact.workEmail && contact.workEmail.email !== ''"
              class="row">
              <div class="col-1">
                <img
                  class="email-icon-img"
                  src="@/assets/icon/arroba.svg"
                  alt="Placeholder">
              </div>
              <div class="col">
                <template>
                  <a
                    class="link"
                    @click="sendEmailModal()">{{ contact.workEmail.email }}</a>
                </template>
              </div>
            </div>
            <div
              v-if="isApplicationFormWebsiteDifferent || contact.website !== '' && contact.website !== undefined"
              class="row">
              <div class="col-1">
                <coozzy-globe-icon />
              </div>
              <div class="col">
                <template v-if="contact.website !== ''">
                  <a
                    class="link"
                    tabindex="0"
                    target="_blank"
                    :href="getUrlWebsite(contact.website)">
                    {{ contact.website }}
                  </a>
                </template>
                <template v-else>
                  -
                </template>
                <coozzy-matching-popover
                  v-if="isApplicationFormWebsiteDifferent"
                  :value="applicationFormContact.website"
                  @accepted="onApplicationFormWebsiteAccepted" />
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="contact.preferredLanguage && contact.preferredLanguage !== 'PREFERRED_LANGUAGE_UNDEFINED'"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.contact.preferredLanguage') }}
        </p>
        <p
          class="col-8 col-md-8 mb-0">
          <span v-if="contact.preferredLanguage === 'PREFERRED_LANGUAGE_GERMAN'">
            {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_GERMAN') }}
          </span>
          <span v-if="contact.preferredLanguage === 'PREFERRED_LANGUAGE_ENGLISH'">
            {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ENGLISH') }}
          </span>
          <span v-if="contact.preferredLanguage === 'PREFERRED_LANGUAGE_ITALIAN'">
            {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ITALIAN') }}
          </span>
          <span v-if="contact.preferredLanguage === 'PREFERRED_LANGUAGE_FRENCH'">
            {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_FRENCH') }}
          </span>
        </p>
      </div>
      <div
        v-if="isApplicationPreferredCommunicationChannelDifferent || contact.preferredCommunicationChannel && contact.preferredCommunicationChannel !== 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED'"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.contact.preferredCommunicationChannel') }}
        </p>
        <p
          class="col-8 col-md-8 mb-0">
          <span v-if="contact.preferredCommunicationChannel === 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL'">
            {{ $t('message.contact.preferredCommunicationChannelType.email') }}
          </span>
          <span v-if="contact.preferredCommunicationChannel === 'PREFERRED_COMMUNICATION_CHANNEL_LETTER'">
            {{ $t('message.contact.preferredCommunicationChannelType.letter') }}
          </span>
          <coozzy-matching-popover
            v-if="isApplicationPreferredCommunicationChannelDifferent"
            :value="translatePreferredCommunicationChannelFromApplicationForm"
            @accepted="onApplicationFormPreferredCommunicationChannelAccepted" />
        </p>
      </div>
      <div
        v-if="contact.civilStatus && contact.civilStatus !== 'UNDEFINED_CIVIL_STATUS'|| isApplicationCivilStatusDifferent"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.civilStatus') }}
        </p>
        <p class="col-8 col-md-8 mb-0">
          <span v-if="contact.civilStatus === 'CIVIL_STATUS_SINGLE'">
            {{ $t('message.applicationForm.civilStatus.single') }}
          </span>
          <span v-else-if="contact.civilStatus === 'CIVIL_STATUS_MARRIED'">
            {{ $t('message.applicationForm.civilStatus.married') }}
          </span>
          <span v-else-if="contact.civilStatus === 'CIVIL_STATUS_DIVORCED'">
            {{ $t('message.applicationForm.civilStatus.divorced') }}
          </span>
          <span v-else-if="contact.civilStatus === 'CIVIL_STATUS_WIDOWED'">
            {{ $t('message.applicationForm.civilStatus.widowed') }}
          </span>
          <span v-else-if="contact.civilStatus === 'CIVIL_STATUS_SEPARATED'">
            {{ $t('message.applicationForm.civilStatus.separated') }}
          </span>
          <span v-else-if="contact.civilStatus === 'CIVIL_STATUS_REGISTERED_PARTNERSHIP'">
            {{ $t('message.applicationForm.civilStatus.registeredPartnership') }}
          </span>
          <span v-else-if="contact.civilStatus === 'CIVIL_STATUS_DISSOLVED_PARTNERSHIP'">
            {{ $t('message.applicationForm.civilStatus.dissolvedPartnership') }}
          </span>
          <span v-else-if="!contact.civilStatus && contact.civilStatus === 'UNDEFINED_CIVIL_STATUS'">
            -
          </span>
          <coozzy-matching-popover
            v-if="isApplicationCivilStatusDifferent"
            :value="translateCivilStatusFromApplicationForm"
            @accepted="onApplicationFormCivilStatusAccepted" />
        </p>
      </div>
      <div
        v-if="contact.birthplace"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.citizenship') }}
        </p>
        <p class="col-8 col-md-8 mb-0">
          {{ contact.birthplace }}
        </p>
      </div>
      <div
        v-if="conditionNationality"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.nationality.title') }}
        </p>
        <p class="col-8 col-md-8 mb-0 d-flex">
          <coozzy-form-country-citizenship-select
            v-model="contact.nationality"
            :is-read-only="true"
            :initial-value="contact.nationality"
            :without-label="true" />
          <coozzy-matching-popover
            v-if="isApplicationFormNationalityDifferent"
            :value="applicationFormContactNationality"
            @accepted="onApplicationFormNationalityAccepted" />
        </p>
      </div>
      <div
        v-if="(contact.residenceStatus && contact.residenceStatus !== 'UNDEFINED_RESIDENCE_STATUS') || (conditionNationality && applicationFormContact && contact.nationality === applicationFormContact.nationality && contact.nationality !== 'CH')"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.residenceStatus') }}
        </p>
        <p class="col-8 col-md-8 mb-0">
          <span v-if="contact.residenceStatus === 'UNDEFINED_RESIDENCE_STATUS'">
            -
          </span>
          <span v-else-if="contact.residenceStatus === 'RESIDENCE_STATUS_B_STAY'">
            {{ $t('message.generic.residenceStatusB') }}
          </span>
          <span v-else-if="contact.residenceStatus === 'RESIDENCE_STATUS_C_SETTLE'">
            {{ $t('message.generic.residenceStatusC') }}
          </span>
          <span v-else-if="contact.residenceStatus === 'RESIDENCE_STATUS_CI_STAY_EMPLOYMENT'">
            {{ $t('message.generic.residenceStatusCi') }}
          </span>
          <span v-else-if="contact.residenceStatus === 'RESIDENCE_STATUS_G_BORDER_CROSSER'">
            {{ $t('message.generic.residenceStatusG') }}
          </span>
          <span v-else-if="contact.residenceStatus === 'RESIDENCE_STATUS_L_SHORT_STAY'">
            {{ $t('message.generic.residenceStatusL') }}
          </span>
          <span v-else-if="contact.residenceStatus === 'RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER'">
            {{ $t('message.generic.residenceStatusF') }}
          </span>
          <span v-else-if="contact.residenceStatus === 'RESIDENCE_STATUS_N_SEEKING_ASYLUM'">
            {{ $t('message.generic.residenceStatusN') }}
          </span>
          <span v-else-if="contact.residenceStatus === 'RESIDENCE_STATUS_S_PROTECTION_NEEDED'">
            {{ $t('message.generic.residenceStatusS') }}
          </span>
          <coozzy-matching-popover
            v-if="isApplicationFormResidenceStatusDifferent"
            :value="applicationFormContactResidenceStatus"
            @accepted="onApplicationFormResidenceStatusAccepted" />
        </p>
      </div>
      <div
        v-if="(contact.residenceStatus && contact.residenceStatus !== 'UNDEFINED_RESIDENCE_STATUS' && contact.residenceStatusValidUntil) || (conditionNationality && applicationFormContact && contact.nationality === applicationFormContact.nationality && contact.nationality !== 'CH' && applicationFormContact.residenceStatusValidUntil !== null)"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.validUntil') }}
        </p>
        <p class="col-8 col-md-8 mb-0">
          <span v-if="!contact.residenceStatusValidUntil || !contact.residenceStatusValidUntil.day"> - </span>
          <span v-else>{{
            `${contact.residenceStatusValidUntil.day.toString().padStart(2, '0')}.${contact.residenceStatusValidUntil.month.toString().padStart(2, '0')}.${contact.residenceStatusValidUntil.year}`
          }}</span>
          <coozzy-matching-popover
            v-if="isApplicationFormResidenceStatusValidUntilDifferent"
            :value="`${applicationFormContact.residenceStatusValidUntil.day.toString().padStart(2, '0')}.${applicationFormContact.residenceStatusValidUntil.month.toString().padStart(2, '0')}.${applicationFormContact.residenceStatusValidUntil.year}`"
            @accepted="onApplicationFormResidenceStatusValidUntilAccepted" />
        </p>
      </div>
      <div
        v-if="isApplicationFormBirthdayDifferent || contact.dateOfBirth && contact.dateOfBirth.year > 0"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.birthday') }}
        </p>
        <p class="col-8 col-md-8 mb-0">
          <template v-if="contact.dateOfBirth">
            {{
              `${contact.dateOfBirth.day.toString().padStart(2, '0')}.${contact.dateOfBirth.month.toString().padStart(2, '0')}.${contact.dateOfBirth.year}`
            }}
          </template>
          <template v-else>
            -
          </template>
          <coozzy-matching-popover
            v-if="isApplicationFormBirthdayDifferent"
            :value="`${applicationFormContact.dob.day.toString().padStart(2, '0')}.${applicationFormContact.dob.month.toString().padStart(2, '0')}.${applicationFormContact.dob.year}`"
            @accepted="onApplicationFormBirthdayAccepted" />
        </p>
      </div>
      <div
        v-if="contact.dateOfDeath && contact.dateOfDeath.year > 0"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.dateOfDeath') }}
        </p>
        <p class="col-8 col-md-8 mb-0">
          {{
            contact.dateOfDeath.day.toString().padStart(2, '0')
          }}.{{ contact.dateOfDeath.month.toString().padStart(2, '0') }}.{{ contact.dateOfDeath.year }}
        </p>
      </div>
      <div
        v-if="isApplicationFormEmploymentCompanyNameDifferent || isApplicationFormEmploymentCompanyPositionDifferent || contact.employment && (contact.employment.companyName || contact.employment.companyPosition)"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          <template
            v-if="isApplicationFormEmploymentCompanyNameDifferent || isApplicationFormEmploymentCompanyPositionDifferent || contact.employment.companyName && contact.employment.companyPosition">
            {{ $t('message.generic.companyName') }}<br>
            {{ $t('message.generic.jobTitle') }}
          </template>
          <template v-else-if="isApplicationFormEmploymentCompanyNameDifferent || contact.employment.companyName">
            {{ $t('message.generic.companyName') }}
          </template>
          <template v-else>
            {{ $t('message.generic.jobTitle') }}
          </template>
        </p>
        <p class="col-8 col-md-8 mb-0">
          <template
            v-if="isApplicationFormEmploymentCompanyNameDifferent || isApplicationFormEmploymentCompanyPositionDifferent || contact.employment.companyName && contact.employment.companyPosition">
            {{ contact.employment.companyName | displayOptionalValue }}
            <coozzy-matching-popover
              v-if="isApplicationFormEmploymentCompanyNameDifferent"
              :value="applicationFormContact.employment.companyName"
              @accepted="onApplicationFormEmploymentCompanyNameAccepted" />
            <br>
            {{ contact.employment.companyPosition | displayOptionalValue }}
            <coozzy-matching-popover
              v-if="isApplicationFormEmploymentCompanyPositionDifferent"
              :value="applicationFormContact.employment.companyPosition"
              @accepted="onApplicationFormEmploymentCompanyPositionAccepted" />
          </template>
          <template v-else-if="contact.employment.companyName">
            {{ contact.employment.companyName | displayOptionalValue }}
            <coozzy-matching-popover
              v-if="isApplicationFormEmploymentCompanyNameDifferent"
              :value="applicationFormContact.employment.companyName"
              @accepted="onApplicationFormEmploymentCompanyNameAccepted" />
          </template>
          <template v-else>
            {{ contact.employment.companyPosition | displayOptionalValue }}
            <coozzy-matching-popover
              v-if="isApplicationFormEmploymentCompanyPositionDifferent"
              :value="applicationFormContact.employment.companyPosition"
              @accepted="onApplicationFormEmploymentCompanyPositionAccepted" />
          </template>
        </p>
      </div>
      <div
        v-if="contact.ahv"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.ahv') }}
        </p>
        <p class="col-8 col-md-8 mb-0">
          {{ contact.ahv }}
        </p>
      </div>
      <div
        v-if="getListRole"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.roles') }}
        </p>
        <p
          class="col-8 col-md-8 mb-0">
          {{ getListRole }}
        </p>
      </div>
      <div
        v-if="!contact.gender && contact.vatNumber"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.vatNumber') }}
        </p>
        <p
          class="col-8 col-md-8 mb-0">
          {{ contact.vatNumber }}
        </p>
      </div>
      <div
        v-if="isSpOrCraftsmen"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.industries') }}
        </p>
        <p
          class="col-8 col-md-8 mb-0">
          {{ getTranslatedIndustries(contact) }}
        </p>
      </div>
      <div
        v-if="contact.note && contact.note !== ''"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.notes') }}
        </p>
        <p class="col-8 col-md-8 mb-0 white-space-pre-line">
          {{ contact.note }}
        </p>
      </div>
      <div
        v-if="contact.tags && contact.tags.length > 0"
        class="row m-0">
        <p class="col-4 col-md-4 mb-0 text-right">
          {{ $t('message.generic.tags') }}
        </p>
        <p class="col-8 col-md-8 mb-0">
          {{ contact.tags.join(', ') }}
        </p>
      </div>
    </div>
    <address-history-modal
      ref="address-history-modal"
      :contact="contact"
      @edit-address="editAddress"
      @contact-edited="contactEdited" />
  </section>
</template>

<script>

import Vue from 'vue'
import AddressText from '../../framework/components/misc/AddressText'
import CoozzyHeadsetIcon from '../../framework/components/icons/CoozzyHeadsetIcon'
import CoozzyFaxIcon from '../../framework/components/icons/CoozzyFaxIcon'
import { formatting } from '@/mixins/formatting'
import CoozzyGlobeIcon from '@/framework/components/icons/CoozzyGlobeIcon'
import { mapMutations } from 'vuex'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon'
import CoozzyMatchingPopover from '@/framework/components/popover/CoozzyMatchingPopover'
import ContactApi from '@/misc/apis/ContactApi'
import AddressHistoryModal from '@/contact/components/AddressHistoryModal'
import { contact } from '@/mixins/contact'
import CoozzyFormCountryCitizenshipSelect from '@/framework/components/form/select/CoozzyFormCountryCitizenshipSelect'
import { country } from '@/mixins/country'
import { mail } from '@/mixins/mail'

export default {
  name: 'ContactDetailCard',
  components: {
    CoozzyMatchingPopover,
    CoozzyPhoneIcon,
    CoozzyMobileAltIcon,
    AddressText,
    CoozzyFaxIcon,
    CoozzyHeadsetIcon,
    CoozzyGlobeIcon,
    AddressHistoryModal,
    CoozzyFormCountryCitizenshipSelect
  },
  mixins: [formatting, contact, country, mail],
  props: {
    availableAttendees: {
      type: Array,
      default() {
        return []
      }
    },
    contact: {
      type: Object,
      required: true
    },
    mailReferenceIds: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: ''
    },
    applicationFormContact: {
      type: Object,
      default: null
    },
    mustChangeData: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      uniqueId: '',
      emailToContact: null,
      listContacts: []
    }
  },
  computed: {
    isApplicationFormGenderDifferent() {
      return this.applicationFormContact &&
        this.applicationFormContact.mainIndividual !== undefined &&
        this.applicationFormContact.gender?.trim() !== this.contact.gender?.trim()
    },
    isApplicationFormNameDifferent() {
      return this.applicationFormContact &&
        (
          (
            this.applicationFormContact.mainIndividual !== undefined &&
            (
              this.applicationFormContact.firstName?.trim() !== this.contact?.firstName.trim() ||
              this.applicationFormContact.lastName?.trim() !== this.contact?.lastName.trim()
            )
          ) ||
          (
            this.applicationFormContact.mainIndividual === undefined &&
            this.applicationFormContact?.name.trim() !== this.contact.name?.trim()
          )
        )
    },
    applicationFormName() {
      if (this.applicationFormContact.mainIndividual !== undefined) {
        return `${this.applicationFormContact.lastName} ${this.applicationFormContact.firstName}`
      } else {
        return this.applicationFormContact.name
      }
    },
    isApplicationFormAddressDifferent() {
      return this.applicationFormContact &&
        (
          !this.contact.address ||
          (
            this.applicationFormContact.address.street.trim() !== this.contact.address.street.trim() ||
            this.applicationFormContact.address.streetNumber.trim() !== this.contact.address.streetNumber.trim() ||
            this.applicationFormContact.address.zip.trim() !== this.contact.address.zip.trim() ||
            this.applicationFormContact.address.city.trim() !== this.contact.address.city.trim()
          )
        )
    },
    isApplicationPreferredCommunicationChannelDifferent() {
      return this.applicationFormContact &&
        this.applicationFormContact.preferredCommunicationChannel !== undefined &&
        this.applicationFormContact.preferredCommunicationChannel?.trim() !== this.contact.preferredCommunicationChannel.trim()
    },
    applicationFormAddress() {
      return `${this.applicationFormContact.address.street} ${this.applicationFormContact.address.streetNumber}\n` +
        `${this.applicationFormContact.address.zip} ${this.applicationFormContact.address.city}`
    },
    isApplicationFormMobileDifferent() {
      return this.applicationFormContact &&
        this.applicationFormContact.mobileNumber &&
        this.applicationFormContact.mobileNumber?.trim() !== this.getPhoneNumbers('MOBILE')
    },
    isApplicationFormPhoneDifferent() {
      return this.applicationFormContact &&
        this.applicationFormContact.phoneNumber &&
        this.applicationFormContact.phoneNumber?.trim() !== this.getPhoneNumbers('HOME')
    },
    isApplicationFormWebsiteDifferent() {
      return this.applicationFormContact && this.applicationFormContact.mainIndividual === undefined &&
        this.applicationFormContact.website?.trim() !== this.contact.website.trim()
    },
    isApplicationCivilStatusDifferent() {
      return this.applicationFormContact && this.applicationFormContact.mainIndividual !== undefined &&
        this.applicationFormContact.civilStatus?.trim() !== this.contact.civilStatus.trim()
    },
    isApplicationFormBirthdayDifferent() {
      return this.applicationFormContact && this.applicationFormContact.mainIndividual !== undefined &&
        (
          this.contact.dateOfBirth === null ||
          this.applicationFormContact.dob === null ||
          this.applicationFormContact.dob.year !== this.contact.dateOfBirth.year ||
          this.applicationFormContact.dob.month !== this.contact.dateOfBirth.month ||
          this.applicationFormContact.dob.day !== this.contact.dateOfBirth.day
        )
    },
    isApplicationFormEmploymentCompanyNameDifferent() {
      return this.applicationFormContact &&
        this.applicationFormContact.employment &&
        this.applicationFormContact.employment.companyName?.trim() !== this.contact.employment.companyName.trim()
    },
    isApplicationFormEmploymentCompanyPositionDifferent() {
      return this.applicationFormContact &&
        this.applicationFormContact.employment &&
        this.applicationFormContact.employment.companyPosition?.trim() !== this.contact.employment.companyPosition.trim()
    },
    getListRole() {
      if (this.contact !== null && this.contact.types !== undefined) {
        if (this.contact.types && this.contact.types.length === 1 && ['NONE', 'EMPLOYEE'].includes(this.contact.types[0])) {
          return '-'
        } else {
          const array = []
          const contactType = this.contact.types.map(e => e.toUpperCase())
          contactType.forEach(role => {
            if (role !== 'NONE' && role !== 'EMPLOYEE') {
              array.push(this.$t('message.contact.types.' + role))
            }
          })
          return array.join(', ')
        }
      }
      return '-'
    },
    isSpOrCraftsmen() {
      if (this.contact !== null && this.contact.types !== undefined) {
        return (this.contact.types && this.contact.types.filter(c => ['SERVICE_PROVIDER', 'CRAFTSMAN'].includes(c)).length > 0) && this.contact.industries && this.contact.industries[0] !== 'INDUSTRY_UNDEFINED_VALUE'
      } else {
        return false
      }
    },
    translateCivilStatusFromApplicationForm() {
      if (this.applicationFormContact.civilStatus) {
        if (this.applicationFormContact.civilStatus === 'CIVIL_STATUS_SINGLE') {
          return this.$t('message.applicationForm.civilStatus.single')
        }
        if (this.applicationFormContact.civilStatus === 'CIVIL_STATUS_MARRIED') {
          return this.$t('message.applicationForm.civilStatus.married')
        }
        if (this.applicationFormContact.civilStatus === 'CIVIL_STATUS_DIVORCED') {
          return this.$t('message.applicationForm.civilStatus.divorced')
        }
        if (this.applicationFormContact.civilStatus === 'CIVIL_STATUS_WIDOWED') {
          return this.$t('message.applicationForm.civilStatus.widowed')
        }
        if (this.applicationFormContact.civilStatus === 'CIVIL_STATUS_SEPARATED') {
          return this.$t('message.applicationForm.civilStatus.separated')
        }
        if (this.applicationFormContact.civilStatus === 'CIVIL_STATUS_REGISTERED_PARTNERSHIP') {
          return this.$t('message.applicationForm.civilStatus.registeredPartnership')
        }
        if (this.applicationFormContact.civilStatus === 'CIVIL_STATUS_DISSOLVED_PARTNERSHIP') {
          return this.$t('message.applicationForm.civilStatus.dissolvedPartnership')
        }
        if (this.applicationFormContact.civilStatus === 'UNDEFINED_CIVIL_STATUS') {
          return '-'
        }
      }
      return ''
    },
    translatePreferredCommunicationChannelFromApplicationForm() {
      if (this.applicationFormContact && this.applicationFormContact.preferredCommunicationChannel) {
        if (this.applicationFormContact.preferredCommunicationChannel === 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL') {
          return this.$t('message.contact.preferredCommunicationChannelType.email')
        } else if (this.applicationFormContact.preferredCommunicationChannel === 'PREFERRED_COMMUNICATION_CHANNEL_LETTER') {
          return this.$t('message.contact.preferredCommunicationChannelType.letter')
        }
      }
      return ''
    },
    isApplicationFormNationalityDifferent() {
      return this.applicationFormContact &&
        this.applicationFormContact.nationality?.trim() !== this.contact.nationality?.trim()
    },
    applicationFormContactNationality() {
      if (this.applicationFormContact.nationality === 'FOREIGN_CITIZENSHIP') {
        return this.$t('message.generic.nationality.foreignCitizenship')
      }
      return this.translateCountry(this.applicationFormContact.nationality)
    },
    isApplicationFormResidenceStatusDifferent() {
      return this.applicationFormContact &&
        this.applicationFormContact.residenceStatus?.trim() !== this.contact.residenceStatus?.trim()
    },
    applicationFormContactResidenceStatus() {
      switch (this.applicationFormContact.residenceStatus) {
        case 'UNDEFINED_RESIDENCE_STATUS':
          return '-'
        case 'RESIDENCE_STATUS_B_STAY':
          return 'B'
        case 'RESIDENCE_STATUS_C_SETTLE':
          return 'C'
        case 'RESIDENCE_STATUS_CI_STAY_EMPLOYMENT':
          return 'Ci'
        case 'RESIDENCE_STATUS_G_BORDER_CROSSER':
          return 'G'
        case 'RESIDENCE_STATUS_L_SHORT_STAY':
          return 'L'
        case 'RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER':
          return 'F'
        case 'RESIDENCE_STATUS_N_SEEKING_ASYLUM':
          return 'N'
        case 'RESIDENCE_STATUS_S_PROTECTION_NEEDED':
          return 'S'
        default:
          break
      }
      return '-'
    },
    isApplicationFormResidenceStatusValidUntilDifferent() {
      return this.applicationFormContact && this.applicationFormContact.residenceStatusValidUntil !== null &&
        (
          this.contact.residenceStatusValidUntil === null ||
          this.applicationFormContact.residenceStatusValidUntil === null ||
          this.applicationFormContact.residenceStatusValidUntil?.year !== this.contact.residenceStatusValidUntil?.year ||
          this.applicationFormContact.residenceStatusValidUntil?.month !== this.contact.residenceStatusValidUntil?.month ||
          this.applicationFormContact.residenceStatusValidUntil?.day !== this.contact.residenceStatusValidUntil?.day
        )
    },
    conditionNationality() {
      return (this.isApplicationFormNationalityDifferent || this.contact.nationality !== '') && !('contactPersons' in this.contact)
    }
  },
  watch: {
    'contact.id': function () {
      this.loadData()
    },
    applicationFormContact: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && oldVal === null) {
          this.isSomeDataDifferent()
        }
      },
      deep: true
    },
    mustChangeData: function () {
      this.onApplicationFormAllInfoAccepted()
    }
  },
  mounted() {
    if (this.contact) {
      this.loadData()
    }
  },
  methods: {
    editAddress(item) {
      this.$emit('edit-address', item, this.contact)
    },
    contactEdited() {
      this.$emit('contact-edited')
    },
    isSomeDataDifferent() {
      const text = (this.isApplicationFormNameDifferent ? this.applicationFormName + '\n' : '') +
        (this.isApplicationFormGenderDifferent ? this.$t(`message.generic.gender.${this.applicationFormContact.gender.toLowerCase()}`) + '\n' : '') +
        (this.isApplicationFormAddressDifferent ? this.applicationFormAddress + '\n' : '') +
        (this.isApplicationFormNationalityDifferent ? this.applicationFormContactNationality + '\n' : '') +
        (this.isApplicationFormEmploymentCompanyPositionDifferent ? this.applicationFormContact.employment.companyPosition + '\n' : '') +
        (this.isApplicationPreferredCommunicationChannelDifferent ? this.translatePreferredCommunicationChannelFromApplicationForm + '\n' : '') +
                (this.isApplicationFormMobileDifferent ? this.applicationFormContact.mobileNumber + '\n' : '') +
                (this.isApplicationFormPhoneDifferent ? this.applicationFormContact.phoneNumber + '\n' : '') +
                (this.isApplicationFormWebsiteDifferent ? this.applicationFormContact.website + '\n' : '') +
                (this.isApplicationCivilStatusDifferent ? this.translateCivilStatusFromApplicationForm + '\n' : '') +
                (this.isApplicationFormEmploymentCompanyNameDifferent ? this.applicationFormContact.employment.companyName + '\n' : '') +
        (this.isApplicationFormResidenceStatusDifferent ? this.applicationFormContactResidenceStatus + '\n' : '') +
        (this.isApplicationFormResidenceStatusValidUntilDifferent ? `${this.applicationFormContact?.residenceStatusValidUntil?.day.toString().padStart(2, '0')}.${this.applicationFormContact?.residenceStatusValidUntil?.month.toString().padStart(2, '0')}.${this.applicationFormContact?.residenceStatusValidUntil?.year}` : '')
      this.$emit('is-some-data-different', this.isApplicationFormNameDifferent ||
        this.isApplicationFormGenderDifferent ||
        this.isApplicationFormAddressDifferent ||
        this.isApplicationFormNationalityDifferent ||
        this.isApplicationFormEmploymentCompanyPositionDifferent ||
        this.isApplicationPreferredCommunicationChannelDifferent ||
        this.isApplicationFormMobileDifferent ||
        this.isApplicationFormPhoneDifferent ||
        this.isApplicationFormWebsiteDifferent ||
        this.isApplicationCivilStatusDifferent ||
        this.isApplicationFormBirthdayDifferent ||
        this.isApplicationFormEmploymentCompanyNameDifferent ||
        this.isApplicationFormResidenceStatusDifferent ||
        this.isApplicationFormResidenceStatusValidUntilDifferent, text)
    },
    redirectToContactPage(contactId) {
      if (contactId !== null) {
        if (this.isMarketingModule) {
          return {
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isOwnerModule) {
          return {
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          }
        }
      }
    },
    loadData() {
      if (this.contact) {
        this.preparePhoneNumberObject()
        this.prepareDates()
      }
    },
    showAddressHistory() {
      this.$refs['address-history-modal'].show()
    },
    getHomePhoneNumbers() {
      if (!this.contact || !this.contact.phoneNumbers) {
        return []
      }

      return this.contact.phoneNumbers
        .filter(number => number.type === 'HOME' && number.number)
        .map(number => number.number)
    },
    getPhoneNumbers(type) {
      if (this.contact.phoneNumbers && this.contact?.phoneNumbers?.length > 0) {
        if (this.contact.phoneNumbers.find(element => element.type === type)) {
          return this.contact.phoneNumbers.find(element => element.type === type).number
        }
      }
      return ''
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    },
    ...mapMutations('message', ['setSendMailContacts', 'setSendMailPreselectedContact', 'setSendMailReferenceIds', 'setSendMailEmailToReply']),
    sendEmailModal() {
      this.setSendMailContacts([this.contact])
      this.setSendMailPreselectedContact(this.contact)
      this.setSendMailReferenceIds(this.mailReferenceIds)
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    preparePhoneNumberObject() {
      this.contact.phone = {}
      if (this.contact.phoneNumbers) {
        this.contact.phone.home1 = this.contact.phoneNumbers.find(element => element.type === 'HOME') ? this.contact.phoneNumbers.find(element => element.type === 'HOME').number : ''
        this.contact.phone.work = this.contact.phoneNumbers.find(element => element.type === 'WORK') ? this.contact.phoneNumbers.find(element => element.type === 'WORK').number : ''
        this.contact.phone.mobile = this.contact.phoneNumbers.find(element => element.type === 'MOBILE') ? this.contact.phoneNumbers.find(element => element.type === 'MOBILE').number : ''
        this.contact.phone.fax = this.contact.phoneNumbers.find(element => element.type === 'FAX') ? this.contact.phoneNumbers.find(element => element.type === 'FAX').number : ''
      }
    },
    prepareDates() {
      this.contact.dob = this.contact.dateOfBirth ? `${this.contact.dateOfBirth.year}-${this.contact.dateOfBirth.month.toString().padStart(2, '0')}-${this.contact.dateOfBirth.day.toString().padStart(2, '0')}` : ''
      this.contact.dod = this.contact.dateOfDeath ? `${this.contact.dateOfDeath.year}-${this.contact.dateOfDeath.month.toString().padStart(2, '0')}-${this.contact.dateOfDeath.day.toString().padStart(2, '0')}` : ''
    },
    updateContact() {
      ContactApi.editContact(this.contact)
        .then(response => {
          this.$emit('contact-updated', response.person ? response.person : response.company)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.editContact'), { type: 'error' })
        })
    },
    onApplicationFormAllInfoAccepted() {
      this.contact.gender = this.applicationFormContact.gender
      this.contact.preferredCommunicationChannel = this.applicationFormContact.preferredCommunicationChannel
      if (this.applicationFormContact.mainIndividual !== undefined) {
        this.contact.firstName = this.applicationFormContact.firstName
        this.contact.lastName = this.applicationFormContact.lastName
      }
      this.contact.name = this.applicationFormName
      this.contact.address = this.applicationFormContact.address
      if (this.contact.phone) {
        this.contact.phone.mobile = this.applicationFormContact.mobileNumber
        this.contact.phone.home1 = this.applicationFormContact.phoneNumber
      }
      let replacedMobile = false
      let replacedHome = false
      for (const phoneNumber of this.contact.phoneNumbers) {
        if (phoneNumber.type === 'MOBILE') {
          phoneNumber.number = this.applicationFormContact.mobileNumber
          replacedMobile = true
        }
        if (phoneNumber.type === 'HOME') {
          phoneNumber.number = this.applicationFormContact.phoneNumber
          replacedHome = true
        }
      }

      if (!replacedMobile) {
        this.contact.phoneNumbers.push({
          type: 'MOBILE',
          number: this.applicationFormContact.mobileNumber
        })
      }
      if (!replacedHome) {
        this.contact.phoneNumbers.push({
          type: 'HOME',
          number: this.applicationFormContact.phoneNumber
        })
      }
      this.contact.website = this.applicationFormContact.website
      this.contact.civilStatus = this.applicationFormContact.civilStatus
      this.contact.dob = `${this.applicationFormContact.dob.year}-${this.applicationFormContact.dob.month.toString().padStart(2, '0')}-${this.applicationFormContact.dob.day.toString().padStart(2, '0')}`
      this.contact.dateOfBirth = this.applicationFormContact.dob
      this.contact.employment.companyName = this.applicationFormContact.employment.companyName
      this.contact.employment.companyPosition = this.applicationFormContact.employment.companyPosition
      this.contact.nationality = this.applicationFormContact.nationality
      this.contact.residenceStatus = this.applicationFormContact.residenceStatus
      this.contact.residenceStatusValidUntil = this.applicationFormContact.residenceStatusValidUntil
      this.updateContact()
      this.$emit('application-form-applied')
    },
    onApplicationFormGenderAccepted() {
      this.contact.gender = this.applicationFormContact.gender
      this.updateContact()
    },
    onApplicationFormPreferredCommunicationChannelAccepted() {
      this.contact.preferredCommunicationChannel = this.applicationFormContact.preferredCommunicationChannel
      this.updateContact()
    },
    onApplicationFormNameAccepted() {
      if (this.applicationFormContact.mainIndividual !== undefined) {
        this.contact.firstName = this.applicationFormContact.firstName
        this.contact.lastName = this.applicationFormContact.lastName
      }
      this.contact.name = this.applicationFormName

      this.updateContact()
    },
    onApplicationFormAddressAccepted() {
      this.contact.address = this.applicationFormContact.address
      this.updateContact()
    },
    onApplicationFormMobileAccepted() {
      if (this.contact.phone) {
        this.contact.phone.mobile = this.applicationFormContact.mobileNumber
      }
      let replacedMobile = false
      for (const phoneNumber of this.contact.phoneNumbers) {
        if (phoneNumber.type === 'MOBILE') {
          phoneNumber.number = this.applicationFormContact.mobileNumber
          replacedMobile = true
          break
        }
      }

      if (!replacedMobile) {
        this.contact.phoneNumbers.push({
          type: 'MOBILE',
          number: this.applicationFormContact.mobileNumber
        })
      }

      this.updateContact()
    },
    onApplicationFormPhoneAccepted() {
      if (!Object.prototype.hasOwnProperty.call(this.contact, 'phone')) {
        this.contact.phone = {}
      }
      this.contact.phone.home1 = this.contact.phone ? this.applicationFormContact.phoneNumber : null
      let replacedHome = false
      for (const phoneNumber of this.contact.phoneNumbers) {
        if (phoneNumber.type === 'HOME') {
          phoneNumber.number = this.applicationFormContact.phoneNumber
          replacedHome = true
          break
        }
      }

      if (!replacedHome) {
        this.contact.phoneNumbers.push({
          type: 'HOME',
          number: this.applicationFormContact.phoneNumber
        })
      }

      this.updateContact()
    },
    onApplicationFormWebsiteAccepted() {
      this.contact.website = this.applicationFormContact.website
      this.updateContact()
    },
    onApplicationFormCivilStatusAccepted() {
      this.contact.civilStatus = this.applicationFormContact.civilStatus
      this.updateContact()
    },
    onApplicationFormBirthdayAccepted() {
      this.contact.dob = `${this.applicationFormContact.dob.year}-${this.applicationFormContact.dob.month.toString().padStart(2, '0')}-${this.applicationFormContact.dob.day.toString().padStart(2, '0')}`
      this.contact.dateOfBirth = this.applicationFormContact.dob
      this.updateContact()
    },
    onApplicationFormEmploymentCompanyNameAccepted() {
      this.contact.employment.companyName = this.applicationFormContact.employment.companyName
      this.updateContact()
    },
    onApplicationFormEmploymentCompanyPositionAccepted() {
      this.contact.employment.companyPosition = this.applicationFormContact.employment.companyPosition
      this.updateContact()
    },
    onApplicationFormNationalityAccepted() {
      this.contact.nationality = this.applicationFormContact.nationality
      this.updateContact()
    },
    onApplicationFormResidenceStatusAccepted() {
      this.contact.residenceStatus = this.applicationFormContact.residenceStatus
      this.updateContact()
    },
    onApplicationFormResidenceStatusValidUntilAccepted() {
      this.contact.residenceStatusValidUntil = this.applicationFormContact.residenceStatusValidUntil
      this.updateContact()
    }
  }
}
</script>

<style lang="scss" scoped>
.card-data {
  p {
    padding: 0.35rem 0.7rem 0.35rem 0.7rem;
  }

  a:hover {
    text-decoration: none;
  }
  .height-ellipsis {
    height: 28px;
  }
}
:deep .tooltip-inner {
  max-width: 230px;
}
.white-space-pre-line {
  white-space: pre-line;
}
</style>
