<template>
  <section class="w-100">
    <div
      class="col mb-0">
      <h6 class="d-inline-block font-weight-bold mt-3">
        {{ $t('message.onBoarding.devices.maintenance.costs.title') }}
      </h6>
    </div>
    <div class="col-12 pl-0">
      <b-collapse
        :id="`collapse-revision-costs-${target}-${index}`"
        visible
        class="col">
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-sm-12 col-md-3">
            <label for="currency">{{ $t('message.onBoarding.devices.maintenance.costs.currency') }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="currency"
              v-model="currency">
              <option value="CHF">
                CHF
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">{{ currency === '' || currency === null ? '-' : currency }}</span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="amount"
              type="number"
              :is-read-only="readOnly"
              :filter="'formatPrice'"
              :placeholder="currency"
              :is-decimal="true"
              :name="$t('message.onBoarding.devices.maintenance.costs.amount')"
              :check-valide="$v.amount.$dirty && $v.amount.$error ? 'is-invalid' : ''" />
          </div>
        </div>
      </b-collapse>
    </div>
  </section>
</template>

<script>

import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import { minValue, numeric } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'

export default {
  name: 'DeviceRevisionCosts',
  components: { CoozzyFormSelect, CoozzyFormInputNumeric },
  mixins: [validation],
  props: {
    index: {
      type: Number,
      default: 0
    },
    target: {
      type: String,
      default: 'building'
    },
    device: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currency: {
      get() {
        return this.device.revision.costs.currency
      },
      set(value) {
        const device = this.device
        device.revision.costs.currency = value
        this.dispatchDeviceUpdate(device)
      }
    },
    amount: {
      get() {
        return this.device.revision.costs.amount !== -1 ? this.device.revision.costs.amount : null
      },
      set(value) {
        const device = this.device
        device.revision.costs.amount = value
        this.dispatchDeviceUpdate(device)
      }
    }
  },
  methods: {
    dispatchDeviceUpdate(newDevice) {
      if (this.target === 'building') {
        this.$store.dispatch('onboarding/updateBuildingDevice', newDevice)
      } else {
        this.$store.dispatch('onboarding/updateObjectDevice', newDevice)
      }
      this.$emit('device-update', newDevice)
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      return this.$v.$invalid
    }
  },
  validations: {
    amount: {
      minValue: minValue(0),
      numeric
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
