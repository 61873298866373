<template>
  <section>
    <div class="row">
      <div class="col">
        <h1>{{ title }}</h1>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <slot name="SideNavigation" />
      </div>
      <div class="col-md-8">
        <slot name="MainContent" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CoozzyContent',
  props: {
    title: {
      type: String,
      default: '',
      validator: (value) => {
        return typeof value !== 'undefined'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
