<template>
  <coozzy-card
    :title="$t('message.contact.export.title')"
    :sub-title="$t('message.contact.export.subTitle')">
    <coozzy-form-copy-input
      type="email"
      :name="$t('message.contact.export.url')"
      :value="exportUrl"
      @click="copyExportUrlClicked" />
  </coozzy-card>
</template>

<script>

import CoozzyFormCopyInput from '@/framework/components/form/input/CoozzyFormCopyInput.vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'

export default {
  name: 'ContactExportCard',
  components: { CoozzyCard, CoozzyFormCopyInput },
  data() {
    return {
      exportUrl: null
    }
  },
  mounted() {
    this.exportUrl = 'https://staging.propbase.ch/web/v2/contacts/e0063c30-8ddc-457f-af29-b86c72b9cd74'
  },
  methods: {
    copyExportUrlClicked() {
      this.$clipboard(this.exportUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
