<template>
  <coozzy-card :title="$t('message.settings.signature.title')">
    <div
      v-if="loading"
      class="row">
      <div class="col">
        <coozzy-spinner />
      </div>
    </div>
    <template v-else>
      <div
        v-for="index in signatures.length"
        :key="index"
        class="row">
        <div class="col-sm-12 col-md-8 mb-2">
          <coozzy-form-input
            v-model="signatures[index-1].name"
            :name="$tc('message.generic.name')" />
        </div>
        <div class="col-sm-12 col-md-8">
          <editor
            ref="editor-tiny"
            v-model="signatures[index-1].signature"
            :api-key="editorApi"
            :init="{
              toolbar: 'undo redo | bold italic underline forecolor | fontsize | numlist bullist link table | image | code | fullscreen print',
              plugins: 'fullscreen lists code image table link',
              height: '370',
              menubar: '',
              language: currentLanguage,
              elementpath: false,
              automatic_uploads: true,
              images_upload_handler: uploadImage,
              license_key: 'gpl',
              content_style: contentStyle,
              font_formats: fontFormats,
              branding: false,
              remove_trailing_brs: false,
              browser_spellcheck: true,
              table_toolbar: '',
              font_size_formats: fontsizeFormats
            }" />
        </div>
        <div class="col-sm-12 col-md-4">
          <h5>{{ $t('message.settings.availablePlaceholders') }}</h5>
          <div
            v-for="placeholder in availablePlaceholders"
            :key="placeholder">
            {{ placeholder }}
          </div>
        </div>
        <div
          v-if="index > 1"
          class="col-sm-12 col-md-8">
          <coozzy-button
            class="float-right mr-3 mt-3"
            design="red"
            @click="deleteNewSignature(index-1)">
            {{ $t('message.settings.signature.deleteNewSignature') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <coozzy-button
            class="float-right mt-3"
            design="primary"
            @click="saveClicked">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
          <coozzy-button
            class="float-right mr-3 mt-3"
            design="prop-green"
            @click="addNewSignature">
            {{ $t('message.settings.signature.addNewSignature') }}
          </coozzy-button>
        </div>
      </div>
    </template>
  </coozzy-card>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import MessageApi from '../../misc/apis/MessageApi'
import { user } from '@/mixins/user'
import Vue from 'vue'
import { mapActions } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
import MediaApi from '@/misc/apis/MediaApi'
import { media } from '@/mixins/media'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'

export default {
  name: 'SignatureSettings',
  components: {
    CoozzyFormInput,
    CoozzySpinner,
    CoozzyButton,
    CoozzyCard,
    Editor
  },
  mixins: [user, media],
  data: () => {
    return {
      loading: false,
      signatures: [{
        signature: '',
        name: 'Standard'
      }],
      availablePlaceholders: [
        '#user.firstName#',
        '#user.lastName#',
        '#user.phoneNumber#',
        '#user.email#',
        '#user.jobTitle#'
      ]
    }
  },
  computed: {
    editorApi() {
      return 'gpl'
    },
    fontSetting() {
      return this.$store.getters['user/getFontSettings']
    },
    contentStyle() {
      const defaultFontFamily = 'Arial'
      const fontFamily = this.fontSetting && this.fontSetting.family !== '' ? `${this.fontSetting.family}` : defaultFontFamily
      const fontSize = this.fontSetting && this.fontSetting.size !== '' ? this.fontSetting.size + 'pt' : '10pt'
      return `body { color: #495057 !important; font-family: ${fontFamily} !important; font-size: ${fontSize};} p {margin: 0}`
    },
    fontsizeFormats() {
      let sizeOptions = []
      for (let i = 4; i <= 36; i++) {
        sizeOptions.push(`${i}pt`)
      }
      return sizeOptions.join(' ')
    },
    fontFormats() {
      const defaultFont = 'Lato=Lato, sans-serif;'
      const userFont = this.fontSetting && this.fontSetting.family !== '' ? `${this.fontSetting.family}=${this.fontSetting.family}, sans-serif;` : defaultFont
      return `${userFont}`
    }
  },
  watch: {},
  mounted() {
    this.getSignature()
  },
  methods: {
    ...mapActions('message', ['resetSignatures', 'loadSignatureForUserId']),
    updateEditorContentStyle() {
      if (this.$refs['editor-tiny']) {
        this.$refs['editor-tiny'].forEach(editorRef => {
          const editor = editorRef.editor
          if (editor && editor.getBody()) {
            editor.getBody().style.fontFamily = this.fontSetting.family || 'Lato'
            editor.getBody().style.fontSize = (this.fontSetting.size || 10) + 'pt'
          }
        })
      }
    },
    getSignature() {
      MessageApi.getSignatures(this.accountId)
        .then(response => {
          this.signatures = response.signatures
          if (response.signatures.length === 0) {
            this.signatures.push({
              signature: '',
              name: 'Standard'
            })
          }
          this.updateEditorContentStyle()
        })
        .catch(e => {
          if (!e.response || e.response.status !== 404) {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.signature'), { type: 'error' })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    addNewSignature() {
      this.signatures.push({
        signature: '',
        name: ''
      })
    },
    deleteNewSignature(index) {
      if (this.signatures[index].id) {
        MessageApi.deleteSignature(this.signatures[index].id)
          .then(() => {
            Vue.toasted.show(this.$t('message.settings.signature.deletedSuccessfully'), { type: 'success' })
            this.signatures = this.signatures.filter((sign) => sign.id !== this.signatures[index].id)
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.signature'), { type: 'error' })
          })
      } else {
        this.signatures.splice(index, 1)
      }
    },
    async saveClicked() {
      const promises = []
      for (let index = 0; index < this.signatures.length; index++) {
        let signatureBody = await this.$refs['editor-tiny'][index]?.editor?.getContent()
        signatureBody = signatureBody?.replaceAll(' crossorigin="use-credentials"', '')
        promises.push(MessageApi.setSignatureWithId(this.accountId, signatureBody, this.signatures[index].name, this.signatures[index].id))
      }
      this.loading = true
      Promise.all(promises).then(() => {
        this.resetSignatures()
        this.getSignature()
        this.loadSignatureForUserId({
          accountId: this.accountId,
          userId: this.userId
        })
        Vue.toasted.show(this.$t('message.settings.signature.savedSuccessfully'), { type: 'success' })
        this.$nextTick(() => {
          this.loading = false
        })
      })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.signature'), { type: 'error' })
        })
    },
    async uploadImage(blobInfo, success, failure) {
      // const imageSize = blobInfo.blob().size / 1000
      // const maxSize = 10000000
      // if (imageSize > maxSize) {
      //   failure('Image is too large( ' + imageSize + ') ,Maximum image size is:' + maxSize + ' kB')
      //   return
      // } else {
      //   console.log('not too big')
      // }
      if (blobInfo) {
        const formData = new FormData()
        const fileName = this.editFileName(blobInfo.filename())
        formData.append('file', blobInfo.blob(), fileName)
        formData.append('filename', blobInfo.filename())
        formData.append('public', 'true')
        try {
          const response = await MediaApi.uploadMedia(formData)
          return response.url
        } catch (e) {
          console.log(e)
          if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
            Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
          }
          return e
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
