import Vue from 'vue'
import Vuex from 'vuex'
import Internationalization from './modules/internationalization'
import User from './modules/user'
import Employee from './modules/employee'
import Appointment from './modules/appointment'
import Message from './modules/message'
import Ticket from './modules/ticket'
import contract from './modules/contract'
import Media from './modules/media'
import Onboarding from './modules/onboarding'
import Algolia from './modules/algolia'
import Request from './modules/request'
import ApplicationForm from './modules/applicationForm'
import Notification from './modules/notification'
import Reminder from './modules/reminder'
import DeepSign from './modules/deepSign'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [],
  modules: {
    internationalization: Internationalization,
    user: User,
    employee: Employee,
    appointment: Appointment,
    media: Media,
    onboarding: Onboarding,
    message: Message,
    contract: contract,
    algolia: Algolia,
    notification: Notification,
    request: Request,
    applicationForm: ApplicationForm,
    reminder: Reminder,
    deepSign: DeepSign,
    ticket: Ticket
  }
})
