<template>
  <section>
    <coozzy-card class="global-card no-top-border">
      <div class="row mb-2">
        <div class="col pl-0">
          <h5 class="ml-3 pt-1">
            {{ contactsCount }} {{ $t('message.marketingObjects.details.tabs.contacts') }}
          </h5>
          <div
            v-if="isFiltered"
            class="d-inline-block float-right">
            <coozzy-button
              class="sort"
              @click="initFilter()">
              <coozzy-filter-icon /> {{ $t('message.generic.clearFilters') }}
            </coozzy-button>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-2">
          <coozzy-form-input
            v-model="query"
            :placeholder="$t('message.generic.search')"
            type="text" />
        </div>
        <div class="col-12 col-md-2">
          <coozzy-multiselect
            v-model="rolesInBuildingFilter"
            :options="availableRolesInBuilding"
            :multiple="true"
            :custom-label="customRolesInBuildingLabel"
            :placeholder="$t('message.onBoarding.createContactTab.roleInBuilding').toString()" />
        </div>
        <div class="col-12 col-md-2">
          <coozzy-multiselect
            v-model="industriesFilter"
            :options="translatedIndustries"
            :multiple="true"
            :placeholder="$t('message.generic.industries').toString()"
            :custom-label="customLabel"
            track-by="label" />
        </div>
        <div
          v-if="isEditor"
          class="col-12"
          :class="'col-md-6'">
          <coozzy-button
            size="small"
            class="float-right"
            design="prop-green"
            :disabled="disableButtons"
            @click="callAction('addServiceProviders')">
            {{ $t('message.manageBuilding.bulkAction.addServiceProviders') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mr-0 mr-md-2"
            design="prop-green"
            :disabled="disableButtons"
            @click="callAction('addCraftsmen')">
            {{ $t('message.manageBuilding.bulkAction.addCraftsmen') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mr-0 mr-md-2"
            design="prop-green"
            :disabled="disableButtons"
            @click="callAction('addJanitors')">
            {{ $t('message.manageBuilding.bulkAction.addJanitors') }}
          </coozzy-button>
          <coozzy-button
            v-if="property.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"
            size="small"
            class="float-right mr-0 mr-md-2"
            design="prop-green"
            :disabled="disableButtons"
            @click="callAction('addDelegate')">
            {{ $t('message.manageBuilding.bulkAction.addDelegate') }}
          </coozzy-button>
          <coozzy-button
            v-if="property.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"
            size="small"
            class="float-right mr-0 mr-md-2"
            design="prop-green"
            :disabled="disableButtons"
            @click="callAction('addRevisor')">
            {{ $t('message.manageBuilding.bulkAction.addRevisor') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row mb-2 align-items-center">
        <div class="col-12 col-md-2">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="showActivity()">
              {{ $t('message.contact.activity.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="$refs['reminder-creation'].show()">
              {{ $t('message.calendar.reminder.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="openEditNote()">
              {{ $t('message.onBoarding.buildings.editNote') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? $bvModal.show('removeFromBuildingConfirmationModal') : ''">
              {{ $t('message.onBoarding.createContactTab.confirmation.title') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div class="col-12 offset-md-6 col-md-2">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-table
            ref="contactsTable"
            hover
            bordered
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            :busy="loading"
            :fields="fields"
            :items="paginatedContacts"
            class="overflow-auto shadow"
            @row-clicked="contactClicked">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <!-- Headings -->
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(roleInBuilding)>
              {{ $t('message.tableColumnHeadings.roleInBuilding') }}
            </template>
            <template #head(industries)>
              {{ $t('message.generic.industry') }}
            </template>
            <template #head(name)>
              {{ $t('message.generic.name') }}
            </template>
            <template #head(address)>
              {{ $t('message.generic.address') }}
            </template>
            <template #head(communication)>
              {{ $t('message.generic.communication') }}
            </template>
            <template #head(notes)>
              {{ $t('message.generic.notes') }}
            </template>

            <!-- Body -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="'check_' + data.item.selectingID"
                @change="(value) => { contactSelected(data.item, value) }" />
            </template>
            <!-- Role in building -->
            <template #cell(roleInBuilding)="data">
              {{ $t('message.onBoarding.createContactTab.' + data.item.roleInBuilding) }}
            </template>
            <!-- Industries -->
            <template #cell(industries)="data">
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="getTranslatedIndustries(data.item, true)" />
              <!-- eslint-enable vue/no-v-html -->
            </template>
            <!-- Name -->
            <template #cell(name)="data">
              <router-link
                class="link"
                :to="getContactDetailRoute(data.item.id ? data.item.id : data.item.objectID)">
                <span>
                  {{ data.item.name }}
                </span>
              </router-link>
            </template>
            <!-- Address -->
            <template #cell(address)="data">
              <address-text :address="data.item.address" />
            </template>
            <!-- Communication -->
            <template #cell(communication)="data">
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-mobile-alt-icon />
                </div>
                <div class="col">
                  <a
                    class="link"
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'MOBILE')">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') }}
                  </a>
                </div>
              </div>
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'HOME') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-phone-icon />
                </div>
                <div class="col">
                  <a
                    class="link"
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'HOME')">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'HOME') }}
                  </a>
                </div>
              </div>
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'WORK') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-headset-icon />
                </div>
                <div class="col">
                  <a
                    class="link"
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'WORK')">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'WORK') }}
                  </a>
                </div>
              </div>
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'FAX') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-fax-icon />
                </div>
                <div class="col">
                  <a
                    class="link"
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'FAX')">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'FAX') }}
                  </a>
                </div>
              </div>
              <div
                v-if="data.item.email !== ''"
                class="row">
                <div class="col-1">
                  <img
                    class="email-icon-img"
                    src="@/assets/icon/arroba.svg"
                    alt="Placeholder">
                </div>
                <div class="col">
                  <template v-if="data.item.email !== ''">
                    <a
                      class="link"
                      tabindex="0"
                      @click="sendEmailModal(data.item)">
                      {{ data.item.email }}
                    </a>
                  </template>
                  <template v-else>
                    {{ data.item.email | displayOptionalValue }}
                  </template>
                </div>
              </div>
              <div
                v-if="data.item.website !== ''"
                class="row">
                <div class="col-1">
                  <coozzy-globe-icon />
                </div>
                <div class="col">
                  <template v-if="data.item.website !== ''">
                    <a
                      class="link"
                      tabindex="0"
                      target="_blank"
                      :href="getUrlWebsite(data.item.website)">
                      {{ data.item.website }}
                    </a>
                  </template>
                  <template v-else>
                    {{ data.item.website | displayOptionalValue }}
                  </template>
                </div>
              </div>
            </template>
            <!-- Notes -->
            <template #cell(notes)="data">
              <p
                :id="`contact-note-${data.item.id}`"
                class="text-msg">
                {{ getNote(data.item) }}
              </p>
              <b-tooltip
                v-if="getNote(data.item)"
                :target="`contact-note-${data.item.id}`"
                custom-class="tooltip-contact-note"
                triggers="hover">
                <p class="m-0">
                  {{ getNote(data.item) }}
                </p>
              </b-tooltip>
            </template>
          </b-table>
          <b-pagination
            v-if="!loading"
            v-model="currentPage"
            align="center"
            :total-rows="filteredContacts.length"
            :per-page="nbrPerPage" />
        </div>
      </div>

      <b-modal
        id="removeFromBuildingConfirmationModal"
        ref="removeFromBuildingConfirmationModal"
        no-close-on-backdrop
        :title="$t('message.onBoarding.createContactTab.confirmation.title')"
        hide-footer>
        <p>{{ $t('message.onBoarding.createContactTab.confirmation.text') }}</p>
        <div class="col">
          <coozzy-button
            size="small"
            class="border mb-0"
            design="transparent"
            @click="$bvModal.hide('removeFromBuildingConfirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            :disabled="loading"
            @click="deleteItem">
            {{ $t('message.generic.confirm') }}
          </coozzy-button>
        </div>
      </b-modal>
      <b-modal
        id="edit-note-modal"
        ref="edit-note-modal"
        no-close-on-backdrop
        :title="$t('message.onBoarding.buildings.editNote')"
        hide-footer>
        <div class="col-12 mb-3">
          <coozzy-form-textarea
            v-model="note"
            :initial="note"
            :value-text-area="note"
            :text="note"
            :initial-value="note"
            :rows="3" />
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="border mb-0"
            design="transparent"
            @click="$bvModal.hide('edit-note-modal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            :disabled="loading"
            @click="editNote">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </b-modal>
      <object-contacts-bulk-action
        v-if="selectedActions"
        ref="objectContactsBulk"
        :title="selectedActions"
        :owner-ids="property?.ownerId ? [property.ownerId] : []"
        :building-ids="buildingList.filter(y => y.objectIds.length > 0).map(x => x.id)"
        @action-done="contactAdded" />
      <reminder-creation-modal
        ref="reminder-creation"
        :references-ids="selectedEntriesIds"
        :source="'addressbook'"
        @new-reminder="resetSelectedEntries()" />
      <create-activity-modal
        ref="activity-creation"
        :references-ids="selectedEntriesIds"
        :ids="selectedEntriesIds"
        @activity-created="resetSelectedEntries()" />
    </coozzy-card>
  </section>
</template>

<script>
import Vue from 'vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import ObjectContactsBulkAction from '@/owner/ObjectContactsBulkAction'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { tables } from '@/mixins/tables'
import AddressText from '@/framework/components/misc/AddressText'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon'
import CoozzyHeadsetIcon from '@/framework/components/icons/CoozzyHeadsetIcon'
import CoozzyGlobeIcon from '@/framework/components/icons/CoozzyGlobeIcon'
import { contact } from '@/mixins/contact'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import { mapMutations } from 'vuex'
import { algolia } from '@/mixins/algolia'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import CoozzyFaxIcon from '@/framework/components/icons/CoozzyFaxIcon'
import { user } from '@/mixins/user'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon.vue'

export default {
  name: 'ContactsTab',
  components: {
    CoozzyFilterIcon,
    CoozzyCard,
    CoozzyFormTextarea,
    CoozzyFaxIcon,
    CreateActivityModal,
    ReminderCreationModal,
    CoozzyDropdown,
    CoozzyDropdownSpaceDivider,
    CoozzyDropdownItem,
    CoozzyFormSelect,
    CoozzyMultiselect,
    CoozzyFormInput,
    CoozzySpinner,
    CoozzyGlobeIcon,
    CoozzyHeadsetIcon,
    CoozzyPhoneIcon,
    CoozzyMobileAltIcon,
    AddressText,
    CoozzyFormCheckbox,
    CoozzyButton,
    ObjectContactsBulkAction
  },
  mixins: [routeChecks, tables, contact, algolia, user],
  props: {
    property: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      note: '',
      loading: false,
      keyActivity: 0,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'roleInBuilding',
          label: this.$t('message.tableColumnHeadings.roleInBuilding'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'industries',
          label: this.$t('message.generic.industries'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'communication',
          label: this.$t('message.generic.communication'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'notes',
          label: this.$t('message.generic.notes'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      selectedEntries: [],
      query: '',
      rolesInBuildingFilter: [],
      industriesFilter: [],
      availableRolesInBuilding: ['craftsmen', 'janitors', 'serviceProvider', 'delegate', 'revisor'],
      availableIndustries: [],
      currentPage: 1,
      nbrPerPage: 10,
      selectedActions: '',
      type: null,
      idContact: null,
      subtype: null,
      buildingList: [],
      allContacts: [],
      craftsmanContactIds: [],
      janitorContactIds: [],
      serviceProviderContactIds: [],
      delegateContactIds: [],
      revisorContactIds: [],
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    nbrTotalOfHits() {
      return this.filteredContacts.length
    },
    isFiltered() {
      return this.query !== '' || this.rolesInBuildingFilter.length > 0 || this.industriesFilter.length > 0
    },
    translatedIndustries() {
      const contacts = [...this.craftsmanContacts].concat([...this.janitorContacts]).concat([...this.serviceProviderContacts])
      const counts = []
      this.translatedIndustriesList(true).filter(x => {
        return contacts.flatMap(x => x.industries).includes(x.value)
      }).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x.value)
        if (existingCount.length === 0) {
          const sameNetwork = contacts.flatMap(x => x.industries).filter(z => z === x.value)
          if (existingCount.length === 0) {
            counts.push({
              count: sameNetwork.length,
              value: x.value,
              label: x.label
            })
          } else {
            counts.push({
              count: 1,
              value: x.value,
              label: x.label
            })
          }
        }
      })
      return counts
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id || x.objectID) : this.selectedEntries.map(x => x.id || x.objectID)
    },
    filteredContacts() {
      let contacts = []
      if (this.rolesInBuildingFilter.length === 0 || this.rolesInBuildingFilter.includes('craftsmen')) {
        contacts = contacts.concat([...this.craftsmanContacts].map(c => {
          const temp = Object.assign({}, c)
          temp.roleInBuilding = 'craftsmen'
          temp.selectingID = Date.now() + Math.random().toString(36).substr(2, 9)
          return temp
        }))
      }
      if (this.rolesInBuildingFilter.length === 0 || this.rolesInBuildingFilter.includes('janitors')) {
        contacts = contacts.concat([...this.janitorContacts].map(c => {
          const temp = Object.assign({}, c)
          temp.roleInBuilding = 'janitors'
          temp.selectingID = Date.now() + Math.random().toString(36).substr(2, 9)
          return temp
        }))
      }
      if (this.rolesInBuildingFilter.length === 0 || this.rolesInBuildingFilter.includes('serviceProvider')) {
        contacts = contacts.concat([...this.serviceProviderContacts].map(c => {
          const temp = Object.assign({}, c)
          temp.roleInBuilding = 'serviceProvider'
          temp.selectingID = Date.now() + Math.random().toString(36).substr(2, 9)
          return temp
        }))
      }
      if (this.rolesInBuildingFilter.length === 0 || this.rolesInBuildingFilter.includes('delegate')) {
        contacts = contacts.concat([...this.delegateContacts].map(c => {
          const temp = Object.assign({}, c)
          temp.roleInBuilding = 'delegate'
          temp.selectingID = Date.now() + Math.random().toString(36).substr(2, 9)
          return temp
        }))
      }
      if (this.rolesInBuildingFilter.length === 0 || this.rolesInBuildingFilter.includes('revisor')) {
        contacts = contacts.concat([...this.revisorContacts].map(c => {
          const temp = Object.assign({}, c)
          temp.roleInBuilding = 'revisor'
          temp.selectingID = Date.now() + Math.random().toString(36).substr(2, 9)
          return temp
        }))
      }
      if (this.query) {
        const q = this.query.toLowerCase()
        contacts = contacts.filter(c => {
          const searchString = c.name + ' ' +
            c.birthName + ' ' +
            c.email + ' ' +
            c.phoneNumbers.map(phone => phone.number + ' ') +
            (c.address ? `${c.address.street} ${c.address.streetNumber} ${c.address.mailbox} ${c.address.subUnit} ${c.address.careOf} ${c.address.zip} ${c.address.city}` : ' ') +
            (c.contactPersons ? c.contactPersons.map(p => this.getContactPersonSearchString(p)) : ' ') +
            c.website

          return searchString.toLowerCase().includes(q)
        })
      }

      if (this.industriesFilter.length > 0) {
        const industries = this.industriesFilter.map(item => item.value)
        contacts = contacts.filter(c => {
          for (const industry of industries) {
            if (c.industries.includes(industry)) {
              return true
            }
          }
          return false
        })
      }

      return contacts
    },
    paginatedContacts() {
      return this.filteredContacts.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
    },
    contactsCount() {
      return this.filteredContacts !== null ? this.filteredContacts.length : 0
    },
    craftsmanContacts() {
      return [...this.removeDuplicatesContact(this.allContacts.filter(item => this.craftsmanContactIds.includes(item.id || item.objectID)))]
    },
    janitorContacts() {
      return [...this.removeDuplicatesContact(this.allContacts.filter(item => this.janitorContactIds.includes(item.id || item.objectID)))]
    },
    serviceProviderContacts() {
      return [...this.removeDuplicatesContact(this.allContacts.filter(item => this.serviceProviderContactIds.includes(item.id || item.objectID)))]
    },
    delegateContacts() {
      return [...this.removeDuplicatesContact(this.allContacts.filter(item => this.delegateContactIds.includes(item.id || item.objectID)))]
    },
    revisorContacts() {
      return [...this.removeDuplicatesContact(this.allContacts.filter(item => this.revisorContactIds.includes(item.id || item.objectID)))]
    },
    disableButtons() {
      return !this.buildingList.filter(x => x.objectIds.length > 0).length > 0
    }
  },
  watch: {
    paginatedContacts() {
      this.handleCheckboxesInsideTable(false)
    },
    nbrPerPage: function (newVal) {
      localStorage.setItem('contactsTab-nbrPerPage', newVal)
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = this.filteredContacts
      }
    }
  },
  mounted() {
    this.loadInstantSearchInternal()
    this.nbrPerPage = localStorage.getItem('contactsTab-nbrPerPage') ? parseInt(localStorage.getItem('contactsTab-nbrPerPage')) : 10
    this.loadData()
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply'
    ]),
    loadData() {
      ObjectApi.getBuildingsListByIds(this.property.buildingIds)
        .then(async response => {
          let contactIds = []
          this.buildingList = response.buildings
          this.craftsmanContactIds = []
          this.janitorContactIds = []
          this.serviceProviderContactIds = []
          this.delegateContactIds = []
          this.revisorContactIds = []

          this.buildingList.forEach(element => {
            if (element.contacts.length > 0) {
              element.contacts.forEach(contact => {
                switch (contact.type) {
                  case 'BUILDING_CONTACT_TYPE_CRAFTSMAN': {
                    this.craftsmanContactIds.push(contact.id)
                    break
                  }
                  case 'BUILDING_CONTACT_TYPE_JANITOR': {
                    this.janitorContactIds.push(contact.id)
                    break
                  }
                  case 'BUILDING_CONTACT_TYPE_SERVICE_PROVIDER': {
                    this.serviceProviderContactIds.push(contact.id)
                    break
                  }
                  case 'BUILDING_CONTACT_TYPE_DELEGATE': {
                    this.delegateContactIds.push(contact.id)
                    break
                  }
                  case 'BUILDING_CONTACT_TYPE_REVISOR': {
                    this.revisorContactIds.push(contact.id)
                    break
                  }
                }
              })
            }
          })
          this.$nextTick(() => {
            contactIds = this.craftsmanContactIds
              .concat(this.janitorContactIds)
              .concat(this.serviceProviderContactIds)
              .concat(this.delegateContactIds)
              .concat(this.revisorContactIds)
            this.availableTenants(contactIds.filter(x => x !== null && x !== ''))
          })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
        })
    },
    customLabel(item) {
      return item.label + ' (' + item.count + ')'
    },
    async contactAdded() {
      this.loading = true
      await this.loadData()
      this.loading = false
    },
    removeDuplicatesContact(array) {
      return array.reverse().reduce((acc, current) => {
        const currentObjectId = current.objectID ? current.objectID : current.id
        const x = acc.find(item => (item.objectID && item.objectID === currentObjectId) || (item.id && item.id === currentObjectId))
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])
    },
    initFilter() {
      this.query = ''
      this.industriesFilter = []
      this.rolesInBuildingFilter = []
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    deleteItem() {
      this.loading = true
      const buildingContacts = []
      const buildingIds = []
      for (const contactId of this.selectedEntriesIds) {
        this.buildingList.forEach(building => {
          const buildingContactIds = buildingContacts.map(x => x.id)
          if (building.contacts.find(c => c.id === contactId) && !buildingContactIds.includes(contactId)) {
            buildingContacts.push(building.contacts.find(c => c.id === contactId))
          }
          if (building.contacts.find(c => c.id === contactId) && !buildingIds.includes(building.id)) {
            buildingIds.push(building.id)
          }
        })
      }
      ObjectApi.removeBuildingContacts(buildingContacts, buildingIds)
        .then(async () => {
          await this.loadData()
        })
          .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.removeContact').toString(), { type: 'error' })
        })
        .finally(() => {
          this.$bvModal.hide('removeFromBuildingConfirmationModal')
          this.loading = false
        })
    },
    resetSelectedEntries() {
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
    },
    callAction(action) {
      this.selectedActions = action
      this.$nextTick(function () {
        this.$refs.objectContactsBulk.show()
      })
    },
    sendEmailModal(contact) {
      this.setSendMailContacts([contact])
      this.setSendMailPreselectedContact(contact)
      this.setSendMailReferenceIds([])
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.$bvModal.show('modal-send-mail')
      })
    },
    getContactPersonSearchString(person) {
      return person.name + ' ' +
        person.birthName + ' ' +
        person.email + ' ' +
        person.phoneNumbers.map(phone => phone.number + ' ')
    },
    customRolesInBuildingLabel(roleInBuilding) {
      return this.$t(`message.onBoarding.createContactTab.${roleInBuilding}`)
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.contactsTable) {
        const allCheckboxesComponents = this.$refs.contactsTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
      if (this.$refs.header_checkbox && value === false) {
        this.$refs.header_checkbox.checked = false
      }
    },
    contactSelected(contact, checked) {
      if (checked) {
        this.selectedEntries.push(contact)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.selectingID !== contact.selectingID)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    contactClicked(item) {
      const contactId = item.objectID ? item.objectID : item.id
      this.redirectToDetailPage(contactId)
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    },
    openEditNote() {
      if (this.selectedEntries.length === 1) {
          this.buildingList.forEach(building => {
            if (building.contacts.find(c => c.id === this.selectedEntries[0].id)) {
              this.note = building.contacts.find(contact => this.selectedEntries[0].id === contact.id)?.note || this.selectedEntries[0].note
            }
          })
      }
      this.$nextTick(() => {
        this.$refs['edit-note-modal'].show()
      })
    },
    editNote() {
      const buildingContacts = []
      const buildingIds = []
      const contactTypes = ['BUILDING_CONTACT_TYPE_UNDEFINED', 'BUILDING_CONTACT_TYPE_CRAFTSMAN', 'BUILDING_CONTACT_TYPE_JANITOR', 'BUILDING_CONTACT_TYPE_SERVICE_PROVIDER', 'BUILDING_CONTACT_TYPE_DELEGATE', 'BUILDING_CONTACT_TYPE_REVISOR']
      let type = ''
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      for (const contact of listEntries) {
        if (contact.roleInBuilding === 'craftsmen') {
          type = contactTypes[1]
        } else if (contact.roleInBuilding === 'janitors') {
          type = contactTypes[2]
        } else if (contact.roleInBuilding === 'serviceProvider') {
          type = contactTypes[3]
        } else if (contact.roleInBuilding === 'delegate') {
          type = contactTypes[4]
        } else if (contact.roleInBuilding === 'revisor') {
          type = contactTypes[5]
        } else {
          type = contactTypes[0]
        }

        const contactId = contact.id || contact.objectID
        this.buildingList.forEach(building => {
          const buildingContactIds = buildingContacts.map(x => x.id)
          if (building.contacts.find(c => c.id === contactId) && !buildingContactIds.includes(contactId)) {
            buildingContacts.push({
              id: contactId,
              note: this.note,
              type: type
            })
          }
          if (building.contacts.find(c => c.id === contactId) && !buildingIds.includes(building.id)) {
            buildingIds.push(building.id)
          }
        })
      }
      ObjectApi.editContactNote(buildingContacts, buildingIds)
        .then(() => {
          this.resetSelectedEntries()
          this.$refs['edit-note-modal'].hide()
          this.loadData()
          Vue.toasted.show(this.$t('message.successMessages.note').toString(), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.note').toString(), { type: 'error' })
        })
    },
    see(refine, value) {
      refine(value)
    },
    getNote(item) {
      let note = null
      this.buildingList.forEach(building => {
        note = building.contacts.find(contact => contact.id === item.id)?.note || item.note
      })
      return note
    },
    availableTenants(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          this.allContacts = response.persons.concat(response.companies)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
p.text-msg {
  line-height: 1.5em;
  height: 1.5em;
  max-width: 200px;
  overflow: hidden;
  line-break: anywhere;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
