<template>
  <section :class="[readOnly ? 'is-disabled' : '']">
    <coozzy-card
      :class="['global-card', statusObject !== 'rented' ? 'no-top-border' : '']">
      <object-basic
        ref="object-basic"
        :key="`basic-${item.id}`"
        :object-selected="item"
        :read-only="readOnly"
        :expand-by-default="true"
        :external-call="true"
        :has-application-info="true" />
    </coozzy-card>
    <coozzy-card class="global-card">
      <object-advertising
        ref="object-advertising"
        :key="`advertising-${item.id}`"
        :object-selected="item"
        :read-only="readOnly"
        :expand-by-default="false" />
    </coozzy-card>
    <coozzy-card class="global-card">
      <object-possible-deposits
        ref="object-possible-deposits"
        :key="`possible-deposits-${item.id}`"
        :item="item"
        :read-only="readOnly"
        :expand-by-default="false" />
    </coozzy-card>
    <coozzy-card
      v-if="statusObject !== 'rented'"
      class="global-card">
      <building-basic
        v-if="building"
        :read-only="true"
        :expand-by-default="false" />
    </coozzy-card>
    <coozzy-card
      v-if="statusObject !== 'rented'"
      class="global-card">
      <characteristics
        v-if="building"
        ref="characteristics"
        :building="building"
        :title="$t('message.onBoarding.createBuilding.features.marketBase')"
        :read-only="readOnly"
        :expand-by-default="false" />
    </coozzy-card>
    <coozzy-card
      v-if="statusObject !== 'rented'"
      class="global-card">
      <object-features
        ref="object-features"
        :key="`features-${item.id}`"
        :title="$t('message.onBoarding.buildings.objects.features.title.marketBase')"
        :object-selected="item"
        :read-only="readOnly"
        :expand-by-default="false" />
    </coozzy-card>
    <coozzy-card
      class="global-card">
      <building-distances
        v-if="building"
        ref="building-distances"
        :building="building"
        :read-only="readOnly"
        :expand-by-default="false" />
    </coozzy-card>
    <coozzy-card
      v-if="currentTenancy"
      class="global-card">
      <div
        v-b-toggle="'collapse-current-tenancy'"
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.generic.tenancy') }}
        </h5>
      </div>
      <b-collapse
        :id="'collapse-current-tenancy'"
        :visible="false"
        class="row">
        <object-tenancy
          :read-only="true"
          :expand-by-default="false"
          :object="item"
          :tenancy="currentTenancy" />
      </b-collapse>
    </coozzy-card>
    <coozzy-card
      class="global-card">
      <object-assignees
        :object-id="item.id"
        :assignees="item.contacts" />
    </coozzy-card>
  </section>
</template>

<script>
import Vue from 'vue'
import { formatting } from '@/mixins/formatting'
import { onboarding } from '@/mixins/onboarding'
import BuildingBasic from '@/building/tabs/components/BuildingBasic'
import ObjectFeatures from '@/building/tabs/components/ObjectFeatures'
import BuildingDistances from '@/building/tabs/components/BuildingDistances'
import ObjectBasic from '@/building/tabs/components/ObjectBasic'
import Characteristics from '@/building/tabs/components/Characteristics'
import ObjectAdvertising from '@/building/tabs/components/ObjectAdvertising'
import ObjectApi from '@/misc/apis/ObjectApi'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import ObjectTenancy from '@/building/tabs/components/ObjectTenancy'
import ObjectAssignees from '@/building/tabs/components/ObjectAssignees'
import ObjectPossibleDeposits from '@/building/tabs/components/ObjectPossibleDeposits'

export default {
  name: 'MarketingObjectAdvertTab',
  components: {
    ObjectAssignees,
    ObjectTenancy,
    CoozzyCard,
    BuildingBasic,
    Characteristics,
    ObjectFeatures,
    BuildingDistances,
    ObjectBasic,
    ObjectAdvertising,
    ObjectPossibleDeposits
  },
  mixins: [formatting, onboarding],
  props: {
    readOnly: {
      type: Boolean,
      default: true
    },
    objectSelected: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      item: this.objectSelected,
      statusObject: ''
    }
  },
  computed: {
    currentTenancy() {
      const now = Math.trunc(Date.now() / 1000)
      return this.tenancies.find(tenancy => tenancy.objectId === this.item.id && parseInt(tenancy.endingAt) > now)
    }
  },
  mounted() {
    this.getMarketingStatus(this.item.marketingStatus)
    ObjectApi.getBuildingByObjectId(this.item.id).then(response => {
      this.loadBuildingForRequest(response.building.id)
    }).catch(e => {
      console.log(e)
      Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
    })
  },
  methods: {
    getMarketingStatus(marketingStatus) {
      let found
      if (marketingStatus != null && marketingStatus.publications.length > 0) {
        found = marketingStatus.publications.find(element => element.unPublishedByAccountId <= 0)
      }
      if (marketingStatus != null && marketingStatus.newInMarketing === true) {
        this.statusObject = 'new'
      } else if (marketingStatus != null && found) {
        this.statusObject = 'online'
      } else if (marketingStatus != null && marketingStatus.vacantAt > 0) {
        this.statusObject = 'offline'
      } else {
        this.statusObject = 'rented'
      }
    },
    getFilteredObjectDocuments(documentIds, filterType = '', filterName = '') {
      return this.objectDocuments.filter(doc => {
        const cdtObject = documentIds.includes(doc.id)
        const cdtName = doc.name.includes(filterName)
        const cdtType = filterType === '' || doc.type === filterType
        return cdtObject && cdtName && cdtType
      }).sort((a, b) => (a.name > b.name ? 1 : -1))
    }
  }
}
</script>
<style lang="scss" scoped>
.building-image {
  max-height: 200px;
  max-width: 100%;
}

.item {
  cursor: pointer;
}

:deep(.details-row-bg-1) {
  background-color: $color-table-row-2;
}

:deep(.details-row-bg-2) {
  background-color: $color-table-row-1;
}

:deep(label) {
  width: 100%;
}

.item-button {
  margin-top: -6px;

  .nav-link {
    border: 0;
  }
}

:deep(.collapse-title) {
  &.collapsed {
    h5 {
      background-image: url('../assets/arrow_down.svg');
    }
  }

  h5 {
    background-image: url('../assets/arrow_up.svg');
    background-repeat: no-repeat;
    font-size: large;
    font-weight: 500 !important;
    height: 1.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    background-position: right;
  }
}

:deep(.global-card) {
  margin-bottom: 0;

  & > .card-body {
    -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
  }

  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

:deep(.global-card) {
  margin-bottom: 0;

  & > .card-body {
    -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
  }

  border-left: 0;
  border-right: 0;
  border-bottom: 0;

  &.no-top-border {
    border-top: 0;
  }
}
</style>
