export default {
  namespaced: true,
  state: {
    appointments: []
  },
  getters: {
    getAppointments: (state) => {
      return state.appointments
    },
    getAppointmentById: (state) => (id) => {
      return state.appointments.find(appt => appt.appointment.id === id)
    }
  },
  mutations: {
    setAppointments: (state, value) => {
      if (Array.isArray(value)) {
        state.appointments = value
      }
    },
    addAppointment: (state, value) => {
      state.appointments.push(value)
    }
  },
  actions: {
    clearAppointmentList: ({ commit }) => {
      commit('setAppointments', [])
    },
    addAppointment({ commit }, value) {
      commit('addAppointment', value)
    }
  }
}
