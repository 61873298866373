// @ts-nocheck
import networkTS from '@/misc/apis/networkTS'
import network from '../../misc/apis/network'

export default {
  listReceivedByObjectIds(objectIds: string[], newRequest: boolean, checkFilter, marketplaceName, readByReceiver = null) {
    // please check checklist, i think for existing endpoint we use checklist.xxxx
    let newFilter = ''
    if (newRequest !== null) {
      newFilter = `?new=${newRequest}`
    }
    let readByReceiverFilter = ''
    if (readByReceiver !== null) {
      if (newRequest !== null) {
        readByReceiverFilter = '&readByReceiver=' + readByReceiver
      } else {
        readByReceiverFilter = '?readByReceiver=' + readByReceiver
      }
    }
    let checkFilterFilter = ''
    checkFilter.forEach(element => {
      if (newFilter === '' && readByReceiverFilter === '' && checkFilterFilter === '') {
        checkFilterFilter += '?checklist.' + element + '=true'
      } else {
        checkFilterFilter += '&checklist.' + element + '=true'
      }
    })
    let sourceFilterFilter = ''
    marketplaceName.forEach(element => {
      if (newFilter === '' && readByReceiverFilter === '' && checkFilterFilter === '' && sourceFilterFilter === '') {
        sourceFilterFilter += '?marketplaceName=' + element
      } else {
        sourceFilterFilter += '&marketplaceName=' + element
      }
    })
    return network.get(`/web/v2/request/listReceivedByObjectIds/${objectIds}${newFilter}` + readByReceiverFilter + checkFilterFilter + sourceFilterFilter)
      .then(response => {
        return response.data
      })
  },
  newRequest(request) {
    return network.post('/web/v2/request/create', {
      checklist: request.checklist,
      createdByReceiver: request.createdByReceiver,
      favorite: request.favorite,
      initialMessage: request.initialMessage,
      marketplaceName: request.marketplaceName,
      objectId: request.objectId,
      ownerId: request.ownerId,
      requesters: request.requesters,
      source: request.source
    })
      .then(response => {
        return response.data
      })
  },
  addRequesters(requestId, requesters) {
    return network.post(`/web/v2/request/${requestId}/addRequesters`, {
      requesters: requesters
    })
      .then(response => {
        return response.data
      })
  },
  moveRequest(requestId, objectId) {
    return network.post(`/web/v2/request/${requestId}/move`, {
      id: requestId,
      objectId: objectId
    })
      .then(response => {
        return response.data
      })
  },
  setEmployerReference(employerReference, requestId, contactId, generatePdf, language) {
    return network.post(`/web/v2/request/${requestId}/setEmployerReference`, {
      employerReference: employerReference,
      id: requestId,
      contactId: contactId,
      generatePdf: generatePdf,
      language: language
    })
      .then(response => {
        return response.data
      })
  },
  setLandlordReference(landlordReference, requestId, contactId, generatePdf, language) {
    return network.post(`/web/v2/request/${requestId}/setLandlordReference`, {
      landlordReference: landlordReference,
      id: requestId,
      contactId: contactId,
      generatePdf: generatePdf,
      language: language
    })
      .then(response => {
        return response.data
      })
  },
  setChecklist(checklist, ids) {
    return network.post('/web/v2/request/setChecklist', {
      checklist: checklist,
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  deleteRequests(ids) {
    return network.delete(`/web/v2/request/${ids}`).then(response => {
      return response.data
    })
  },
  getMarketingStatistics(accountId, contactId) {
    return network.get(`/web/v2/request/getMarketingStatistics/${accountId}?contactIdAssigneeMarketing=${contactId}`)
      .then(response => {
        return response.data
      })
  },
  listByIds(ids) {
    return network.get(`/web/v2/request/listByIds/${ids}`)
      .then(response => {
        return response.data
      })
  },
  markRejected(ids) {
    return network.post('/web/v2/request/markRejected', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  reactivate(ids) {
    return network.post('/web/v2/request/reactivate', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  reserve(requestId, tenancyId) {
    return network.post(`web/v2/request/${requestId}/reserve`, { tenancyId: tenancyId })
      .then(response => {
        return response.data
      })
  },
  getListSent(accountId) {
    return network.get(`web/v2/request/listSent/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  saveApplication(individuals, company) {
    return network.post('web/v2/request/application/create', {
      individuals: individuals,
      company: company
    })
      .then(response => {
        return response.data
      })
  },
  sendApplicationForm(id, objectId, requestId = undefined, viewingAppointmentDone, desiredStartOfContract = null, lan = 'PREFERRED_LANGUAGE_UNDEFINED') {
    return network.post('web/v2/request/application/send', {
      id: id,
      objectId: objectId,
      requestId: requestId,
      viewingAppointmentDone: viewingAppointmentDone,
      desiredStartOfContract: desiredStartOfContract,
      language: lan
    }).then(response => {
      return response.data
    })
  },
  partiallySetChecklist(ids, checklist) {
    return network.post('web/v2/request/partiallySetChecklist', {
      contractSent: checklist.contractSent,
      contractReceived: checklist.contractReceived,
      applicationFormRequested: checklist.applicationFormRequested,
      viewingAppointmentArranged: checklist.viewingAppointmentArranged,
      referencesObtained: checklist.referencesObtained,
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  getApplicationFormById(id) {
    return network.get(`web/v2/request/application/getById/${id}`)
      .then(response => {
        return response.data
      })
  },
  getApplicationFormByEmail(creatorEmail) {
    return network.get(`web/v2/request/application/getLatest/${creatorEmail}`)
      .then(response => {
        return response.data
      })
  },
  setRequestFavorite(ids) {
    return network.post('/web/v2/request/setFavorite', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  unsetRequestFavorite(ids) {
    return network.post('/web/v2/request/setNotFavorite', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  removeRequesters(id, contactIds) {
    return network.post(`/web/v2/request/${id}/removeRequesters`, {
      contactIds: contactIds
    })
      .then(response => {
        return response.data
      })
  },
  getStatistics(objectIds: string) {
    return networkTS.requestServiceGetStatistics(objectIds)
      .then(response => {
        return response.data
      })
  },
  markRead(ids) {
    return network.post('/web/v2/request/markRead', {
      ids: ids,
      readByReceiver: true
    })
      .then(response => {
        return response.data
      })
  },
  markUnRead(ids) {
    return network.post('/web/v2/request/markUnread', {
      ids: ids,
      unreadByReceiver: true
    })
      .then(response => {
        return response.data
      })
  },
  getApplicationFormSettings(accountId) {
    return network.get(`/web/v2/request/application/settings/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  setApplicationFormSettings(accountId, showContractCreationCostAgreement, contractCreationCost) {
    return network.post(`/web/v2/request/application/settings/${accountId}`, {
      showContractCreationCostAgreement: showContractCreationCostAgreement,
      contractCreationCost: contractCreationCost
    }).then(response => {
      return response.data
    })
  }
}
