<template>
  <div>
    <span class="span-dropdown">
      {{ text }}
    </span>
    <coozzy-button
      v-if="direction === ''"
      class="pl-0 pt-0 sort"
      design="transparent"
      @click="updateSorting('desc')">
      <coozzy-chevron-down-icon />
    </coozzy-button>
    <coozzy-button
      v-else-if="direction === 'desc'"
      class="pl-0 pt-0 sort"
      design="transparent"
      @click="updateSorting('asc')">
      <coozzy-arrow-down-icon />
    </coozzy-button>
    <coozzy-button
      v-else-if="direction === 'asc'"
      class="pl-0 pt-0 sort"
      design="transparent"
      @click="updateSorting('')">
      <coozzy-arrow-up-icon />
    </coozzy-button>
  </div>
</template>

<script>

import CoozzyArrowUpIcon from '@/framework/components/icons/CoozzyArrowUpIcon'

export default {
  name: 'CoozzySortDropdown',
  components: {
    CoozzyArrowUpIcon
  },
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: ''
    },
    sortingField: {
      type: String,
      default: ''
    },
    initialSorting: {
      type: String,
      default: ''
    },
    descOnly: {
      type: Boolean,
      default: false
    },
    ascDescOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      direction: this.initialSorting
    }
  },
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  },
  methods: {
    updateSorting(value) {
      let sortingVal = value
      if (this.ascDescOnly) {
        if (sortingVal === '') {
          sortingVal = 'desc'
        }
      }
      if (sortingVal === 'asc' && this.descOnly) {
        this.direction = ''
      } else {
        this.direction = sortingVal
      }
      this.$emit('updateSorting', sortingVal, this.sortingField)
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.btn-sort-filter) {
    cursor: inherit !important;
    background-color: transparent;
    border-color: transparent;

    &.dropdown-toggle-split {
      cursor: pointer !important;
    }
  }
  :deep(.filter-checkbox) {

    width: 15rem;

    & > div.custom-checkbox {

      display: block;

      & > label.custom-control-label {
        color: $color-text-grey-dark;
        font-weight: normal;
        font-size: 1rem;
      }
    }
  }

  .b-dropdown {
    &.up, &.down {
      .dropdown-toggle-split {
        opacity: 0;
        margin-left: -2rem;
        z-index: 99;
      }
    }
  }
</style>
