<template>
  <section>
    <div
      v-b-toggle="'collapse-object-features'"
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ title }}
      </h5>
    </div>
    <b-collapse
      :id="'collapse-object-features'"
      ref="collapse"
      :visible="expandByDefault || openedCollapses.includes('collapse-object-features')"
      class="row">
      <div class="col">
        <div class="row mt-3">
          <div class="col">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.buildings.objects.features.interiorAccessories') }}
            </h5>
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="kitchens">{{ $t('message.onBoarding.buildings.objects.features.kitchens.title') }}</label>
            <p
              v-if="getKitchen.length > 0"
              class="mb-0 multi-select-value">
              {{ getKitchen }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="kitchens"
              v-model="kitchens"
              :options="translatedKitchen"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.features.kitchens.title') }}
            </coozzy-multiselect>
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="additionalRooms">{{ $t('message.onBoarding.buildings.objects.features.additionalRooms.title') }}</label>
            <p
              v-if="getAdditionalRoom.length > 0"
              class="mb-0 multi-select-value">
              {{ getAdditionalRoom }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="additionalRooms"
              v-model="additionalRooms"
              :options="translatedAdditionalRoom"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.features.additionalRooms.title') }}
            </coozzy-multiselect>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="furnishing">{{ $t('message.onBoarding.buildings.objects.features.furnishing.title') }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="furnishing"
              v-model="furnishing">
              <option value="UNDEFINED_FURNISHING" />
              <option
                v-for="(furnishingValue, furnishingIndex) in availableFurnishing"
                :key="furnishingIndex"
                :value="furnishingValue">
                {{ $t(`message.onBoarding.buildings.objects.features.furnishing.${furnishingValue}`) }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">{{ furnishing === '' || furnishing === null || furnishing === 'UNDEFINED_FURNISHING' ? '-' : $t(`message.onBoarding.buildings.objects.features.furnishing.${furnishing}`) }}</span>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="furnishingType">{{ $t('message.onBoarding.buildings.objects.features.furnishingType.title') }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="furnishingType"
              v-model="furnishingType">
              <option value="UNDEFINED_FURNISHING_TYPE" />
              <option
                v-for="(furnishingTypeValue, furnishingTypeIndex) in availableFurnishingType"
                :key="furnishingTypeIndex"
                :value="furnishingTypeValue">
                {{ $t(`message.onBoarding.buildings.objects.features.furnishingType.${furnishingTypeValue}`) }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">{{ furnishingType === '' || furnishingType === null || furnishingType === 'UNDEFINED_FURNISHING_TYPE' ? '-' : $t(`message.onBoarding.buildings.objects.features.furnishingType.${furnishingType}`) }}</span>
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-12">
            <div class="row mt-3">
              <div class="col">
                <h5 class="cursor-pointer">
                  {{ $t('message.onBoarding.buildings.objects.features.sanitary') }}
                </h5>
              </div>
            </div>
            <div class="row details-row-bg-1 py-01rem">
              <div
                v-if="readOnly"
                class="col-sm-12 col-md-3">
                <label for="sanitaries">{{ $t('message.onBoarding.buildings.objects.features.sanitaries.title') }}</label>
                <p
                  v-if="getSanitary.length > 0"
                  class="mb-0 multi-select-value">
                  {{ getSanitary }}
                </p>
                <p
                  v-else
                  class="mb-0 multi-select-value">
                  -
                </p>
              </div>
              <div
                v-else
                class="col-sm-12 col-md-3">
                <coozzy-multiselect
                  id="sanitaries"
                  v-model="sanitaries"
                  :options="translatedSanitary"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                  :taggable="false"
                  label="label"
                  track-by="label">
                  {{ $t('message.onBoarding.buildings.objects.features.sanitaries.title') }}
                </coozzy-multiselect>
              </div>
              <div class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="bathrooms"
                  type="number"
                  :min="0"
                  :is-read-only="readOnly"
                  :state="$v.bathrooms.$dirty && $v.bathrooms.$error ? false : null"
                  :name="$t('message.onBoarding.buildings.objects.features.bathroomCount')" />
              </div>
              <div class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="showers"
                  type="number"
                  :min="0"
                  :is-read-only="readOnly"
                  :state="$v.showers.$dirty && $v.showers.$error ? false : null"
                  :name="$t('message.onBoarding.buildings.objects.features.showerCount')" />
              </div>
              <div class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="toilets"
                  type="number"
                  :min="0"
                  :is-read-only="readOnly"
                  :state="$v.toilets.$dirty && $v.toilets.$error ? false : null"
                  :name="$t('message.onBoarding.buildings.objects.features.toiletCount')" />
              </div>
              <div class="col-sm-12 col-md-3 mt-2">
                <coozzy-form-input
                  v-model="guestToilets"
                  type="number"
                  :min="0"
                  :is-read-only="readOnly"
                  :state="$v.guestToilets.$dirty && $v.guestToilets.$error ? false : null"
                  :name="$t('message.onBoarding.buildings.objects.features.guestToiletCount')" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.buildings.objects.features.indoorArea') }}
            </h5>
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="floorings">{{ $t('message.onBoarding.buildings.objects.features.floorings.title') }}</label>
            <p
              v-if="getFlooring.length > 0"
              class="mb-0 multi-select-value">
              {{ getFlooring }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="floorings"
              v-model="floorings"
              :options="translatedFlooring"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.features.floorings.title') }}
            </coozzy-multiselect>
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="windows">{{ $t('message.onBoarding.buildings.objects.features.windows.title') }}</label>
            <p
              v-if="getWindows.length > 0"
              class="mb-0 multi-select-value">
              {{ getWindows }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="windows"
              v-model="windows"
              :options="translatedWindows"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.features.windows.title') }}
            </coozzy-multiselect>
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="glazings">{{ $t('message.onBoarding.buildings.objects.features.glazings.title') }}</label>
            <p
              v-if="getGlasing.length > 0"
              class="mb-0 multi-select-value">
              {{ getGlasing }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="glazings"
              v-model="glazings"
              :options="translatedGlasing"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.features.glazings.title') }}
            </coozzy-multiselect>
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="petAllowances">{{ $t('message.onBoarding.buildings.objects.features.pets.title') }}</label>
            <p
              v-if="getPetAllowances.length > 0"
              class="mb-0 multi-select-value">
              {{ getPetAllowances }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="petAllowances"
              v-model="petAllowances"
              :options="translatedPetAllowances"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.features.pets.title') }}
            </coozzy-multiselect>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.buildings.objects.features.outdoorArea') }}
            </h5>
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="extras">{{ $t('message.onBoarding.buildings.objects.features.extras.title') }}</label>
            <p
              v-if="getExtras.length > 0"
              class="mb-0 multi-select-value">
              {{ getExtras }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="extras"
              v-model="extras"
              :options="translatedExtras"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.features.extras.title') }}
            </coozzy-multiselect>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.buildings.objects.features.technologyAndHeating') }}
            </h5>
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="technologies">{{ $t('message.onBoarding.buildings.objects.features.technologies.title') }}</label>
            <p
              v-if="getTechnology.length > 0"
              class="mb-0 multi-select-value">
              {{ getTechnology }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="technologies"
              v-model="technologies"
              :options="translatedTechnology"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.features.technologies.title') }}
            </coozzy-multiselect>
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="heatings">{{ $t('message.onBoarding.buildings.objects.features.heatings.title') }}</label>
            <p
              v-if="getHeating.length > 0"
              class="mb-0 multi-select-value">
              {{ getHeating }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="heatings"
              v-model="heatings"
              :options="translatedHeating"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.features.heatings.title') }}
            </coozzy-multiselect>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="wheelchairAccessible">{{ $t('message.onBoarding.buildings.objects.features.wheelchairAccessible.title') }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="wheelchairAccessible"
              v-model="wheelchairAccessible">
              <option value="UNDEFINED_VALUE">
                -
              </option>
              <option
                v-for="(wheelChairAccessibleValue, wheelChairAccessibleIndex) in availableWheelChairAccessibleOptions"
                :key="wheelChairAccessibleIndex"
                :value="wheelChairAccessibleValue">
                {{ $t(`message.onBoarding.buildings.objects.features.wheelchairAccessible.${wheelChairAccessibleValue}`) }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">{{ wheelchairAccessible === '' || wheelchairAccessible === null || wheelchairAccessible === 'UNDEFINED_VALUE' ? '-' : $t(`message.onBoarding.buildings.objects.features.wheelchairAccessible.${wheelchairAccessible}`) }}</span>
          </div>
        </div>
      </div>
    </b-collapse>
  </section>
</template>

<script>

import { onboarding } from '@/mixins/onboarding'
import { minValue, numeric } from 'vuelidate/lib/validators'
import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import CoozzyMultiselect from '../../../framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import { validation } from '@/mixins/validation'

export default {
  name: 'ObjectFeatures',
  components: { CoozzyFormInput, CoozzyMultiselect, CoozzyFormSelect },
  mixins: [onboarding, validation],
  props: {
    title: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    objectSelected: {
      type: Object,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      item: this.objectSelected,
      availableFurnishing: ['FURNISHED', 'PARTLY_FURNISHED', 'NOT_FURNISHED'],
      availableFurnishingType: ['SIMPLE_FURNISHING', 'STANDARD_FURNISHING', 'UPSCALE_FURNISHING', 'LUXURY_FURNISHING'],
      availableAdditionalRoom: ['ADDITIONAL_ROOM_ATTIC', 'ADDITIONAL_ROOM_BICYCLE_STORAGE', 'ADDITIONAL_ROOM_WASHING_AND_DRYING_ROOM', 'ADDITIONAL_ROOM_BASEMENT', 'ADDITIONAL_ROOM_FITNESS_ROOM', 'ADDITIONAL_ROOM_HOBBY_ROOM', 'ADDITIONAL_ROOM_ARCHIVE', 'ADDITIONAL_ROOM_STORING_PLACE', 'ADDITIONAL_ROOM_WORKROOM', 'ADDITIONAL_ROOM_HALL', 'ADDITIONAL_ROOM_COLD_STORAGE_CELL'],
      availableKitchens: ['NO_KITCHEN', 'TEA_KITCHEN', 'BUILD_IN_KITCHEN', 'KITCHENETTE', 'EAT_IN_KITCHEN', 'SMALL_KITCHEN', 'OPEN_KITCHEN', 'GLASS_CERAMIC_STOVE', 'INDUCTION_STOVE', 'TEPANIAKI', 'STEAMER', 'COMBI_STEAMER', 'DISHWASHER'],
      availableSanitaries: ['SANITARY_BATHROOM_WITH_WINDOW', 'SANITARY_BATHROOM_WITH_SHOWER', 'SANITARY_BATHROOM_WITH_BATHTUB', 'SANITARY_SAUNA', 'SANITARY_SWIMMING_POOL', 'SANITARY_JACUZZI', 'SANITARY_TOWEL_RADIATOR', 'SANITARY_WASHING_MACHINE', 'SANITARY_DRYER', 'SANITARY_CONNECTION_FOR_WASHING_MACHINE'],
      availableFloorings: ['FLOORING_TILES', 'FLOORING_LAMINATE', 'FLOORING_PARQUET', 'FLOORING_STONE', 'FLOORING_DOUBLE_FLOOR', 'FLOORING_WOOD_BOARDS', 'FLOORING_MARBLE', 'FLOORING_PVC', 'FLOORING_CARPET', 'FLOORING_TERRACOTTA', 'FLOORING_ANHYDRIT', 'FLOORING_VINYL', 'FLOORING_OTHERS'],
      availableWindows: ['WINDOW_ALUMINIUM', 'WINDOW_WOOD', 'WINDOW_PLASTIC', 'WINDOW_LAMELLAR_SHUTTER', 'WINDOW_SHUTTER_BLINDS'],
      availableGlazings: ['GLAZING_ACOUSTIC_GLAZING', 'GLAZING_SUN_PROTECTION', 'GLAZING_SIMPLY_GLAZED', 'GLAZING_DOUBLE_GLAZED', 'GLAZING_TRIPLE_GLAZED', 'GLAZING_RESISTANT_GLASS'],
      availableExtras: ['EXTRAS_BALCONY', 'EXTRAS_FRENCH_BALCONY', 'EXTRAS_WINTER_GARDEN', 'EXTRAS_GARDEN', 'EXTRAS_VERANDA', 'EXTRAS_TERRACE', 'EXTRAS_LOGGIA', 'EXTRAS_GARDEN_HOUSE'],
      availableTechnologies: ['TECHNOLOGY_ALARM_SYSTEM', 'TECHNOLOGY_CAMERA', 'TECHNOLOGY_HIGH_VOLTAGE_CURRENT'],
      availableHeatings: ['CENTRAL_HEATING', 'SELF_CONTAINED_CENTRAL_HEATING', 'UNDERFLOOR_HEATING', 'RADIATOR', 'CONTROLLED_RESIDENTIAL_VENTILATION', 'AIR_CONDITIONING', 'STOVE', 'WOOD_BURNING_STOVE', 'STORAGE_HEATER', 'FIREPLACE', 'OPEN_FIREPLACE'],
      availablePetAllowances: ['PETS_ALLOWED', 'PETS_AFTER_CONSULTATION', 'PETS_NOT_ALLOWED', 'PETS_SMALL_ALLOWED', 'PETS_DOGS_ALLOWED', 'PETS_CATS_ALLOWED'],
      availableWheelChairAccessibleOptions: ['APPLIES', 'DOES_NOT_APPLY']
    }
  },
  computed: {
    furnishing: {
      get() {
        return this.item.characteristics.furnishing
      },
      set(value) {
        const object = this.item
        object.characteristics.furnishing = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    furnishingType: {
      get() {
        return this.item.characteristics.furnishingType
      },
      set(value) {
        const object = this.item
        object.characteristics.furnishingType = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    wheelchairAccessible: {
      get() {
        return this.item.characteristics.wheelchairAccessible
      },
      set(value) {
        const object = this.item
        object.characteristics.wheelchairAccessible = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    additionalRooms: {
      get() {
        return this.item.characteristics.additionalRooms.map(additionalRoom => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.features.additionalRooms.' + additionalRoom),
            value: additionalRoom
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.additionalRooms = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    kitchens: {
      get() {
        return this.item.characteristics.kitchens.map(kitchen => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.features.kitchens.' + kitchen),
            value: kitchen
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.kitchens = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    sanitaries: {
      get() {
        return this.item.characteristics.sanitaries.map(sanitary => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.features.sanitaries.' + sanitary),
            value: sanitary
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.sanitaries = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    floorings: {
      get() {
        return this.item.characteristics.floorings.map(flooring => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.features.floorings.' + flooring),
            value: flooring
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.floorings = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    windows: {
      get() {
        return this.item.characteristics.windows.map(windows => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.features.windows.' + windows),
            value: windows
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.windows = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    glazings: {
      get() {
        return this.item.characteristics.glazings.map(glazing => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.features.glazings.' + glazing),
            value: glazing
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.glazings = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    extras: {
      get() {
        return this.item.characteristics.extras.map(extra => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.features.extras.' + extra),
            value: extra
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.extras = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    technologies: {
      get() {
        return this.item.characteristics.technologies.map(technology => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.features.technologies.' + technology),
            value: technology
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.technologies = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    heatings: {
      get() {
        return this.item.characteristics.heatings.map(heating => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.features.heatings.' + heating),
            value: heating
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.heatings = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    bathrooms: {
      get() {
        return this.item.characteristics.bathrooms !== -1 ? this.item.characteristics.bathrooms : null
      },
      set(value) {
        const object = this.item
        object.characteristics.bathrooms = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    showers: {
      get() {
        return this.item.characteristics.showers !== -1 ? this.item.characteristics.showers : null
      },
      set(value) {
        const object = this.item
        object.characteristics.showers = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    toilets: {
      get() {
        return this.item.characteristics.toilets !== -1 ? this.item.characteristics.toilets : null
      },
      set(value) {
        const object = this.item
        object.characteristics.toilets = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    guestToilets: {
      get() {
        return this.item.characteristics.guestToilets !== -1 ? this.item.characteristics.guestToilets : null
      },
      set(value) {
        const object = this.item
        object.characteristics.guestToilets = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    petAllowances: {
      get() {
        return this.item.characteristics.petAllowances.map(petAllowance => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.features.pets.' + petAllowance),
            value: petAllowance
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.petAllowances = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    getAdditionalRoom() {
      const array = []
      this.item.characteristics.additionalRooms.forEach(additionalRoom => {
        array.push(this.$t('message.onBoarding.buildings.objects.features.additionalRooms.' + additionalRoom))
      })
      return array.join(', ')
    },
    translatedAdditionalRoom() {
      const array = []
      this.availableAdditionalRoom.forEach(additionalRoom => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.features.additionalRooms.' + additionalRoom),
          value: additionalRoom
        })
      })
      return array
    },
    getKitchen() {
      const array = []
      this.item.characteristics.kitchens.forEach(kitchen => {
        array.push(this.$t('message.onBoarding.buildings.objects.features.kitchens.' + kitchen))
      })
      return array.join(', ')
    },
    translatedKitchen() {
      const array = []
      this.availableKitchens.forEach(kitchen => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.features.kitchens.' + kitchen),
          value: kitchen
        })
      })
      return array
    },
    getSanitary() {
      const array = []
      this.item.characteristics.sanitaries.forEach(sanitary => {
        array.push(this.$t('message.onBoarding.buildings.objects.features.sanitaries.' + sanitary))
      })
      return array.join(', ')
    },
    translatedSanitary() {
      const array = []
      this.availableSanitaries.forEach(sanitary => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.features.sanitaries.' + sanitary),
          value: sanitary
        })
      })
      return array
    },
    getFlooring() {
      const array = []
      this.item.characteristics.floorings.forEach(flooring => {
        array.push(this.$t('message.onBoarding.buildings.objects.features.floorings.' + flooring))
      })
      return array.join(', ')
    },
    translatedFlooring() {
      const array = []
      this.availableFloorings.forEach(flooring => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.features.floorings.' + flooring),
          value: flooring
        })
      })
      return array
    },
    getWindows() {
      const array = []
      this.item.characteristics.windows.forEach(windows => {
        array.push(this.$t('message.onBoarding.buildings.objects.features.windows.' + windows))
      })
      return array.join(', ')
    },
    getPetAllowances() {
      const array = []
      this.item.characteristics.petAllowances.forEach(windows => {
        array.push(this.$t('message.onBoarding.buildings.objects.features.pets.' + windows))
      })
      return array.join(', ')
    },
    translatedWindows() {
      const array = []
      this.availableWindows.forEach(windows => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.features.windows.' + windows),
          value: windows
        })
      })
      return array
    },
    translatedPetAllowances() {
      const array = []
      this.availablePetAllowances.forEach(pet => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.features.pets.' + pet),
          value: pet
        })
      })
      return array
    },
    getGlasing() {
      const array = []
      this.item.characteristics.glazings.forEach(glasing => {
        array.push(this.$t('message.onBoarding.buildings.objects.features.glazings.' + glasing))
      })
      return array.join(', ')
    },
    translatedGlasing() {
      const array = []
      this.availableGlazings.forEach(glasing => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.features.glazings.' + glasing),
          value: glasing
        })
      })
      return array
    },
    getExtras() {
      const array = []
      this.item.characteristics.extras.forEach(extras => {
        array.push(this.$t('message.onBoarding.buildings.objects.features.extras.' + extras))
      })
      return array.join(', ')
    },
    translatedExtras() {
      const array = []
      this.availableExtras.forEach(extras => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.features.extras.' + extras),
          value: extras
        })
      })
      return array
    },
    getTechnology() {
      const array = []
      this.item.characteristics.technologies.forEach(technology => {
        array.push(this.$t('message.onBoarding.buildings.objects.features.technologies.' + technology))
      })
      return array.join(', ')
    },
    translatedTechnology() {
      const array = []
      this.availableTechnologies.forEach(technology => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.features.technologies.' + technology),
          value: technology
        })
      })
      return array
    },
    getHeating() {
      const array = []
      this.item.characteristics.heatings.forEach(heating => {
        array.push(this.$t('message.onBoarding.buildings.objects.features.heatings.' + heating))
      })
      return array.join(', ')
    },
    translatedHeating() {
      const array = []
      this.availableHeatings.forEach(heating => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.features.heatings.' + heating),
          value: heating
        })
      })
      return array
    }
  },
  methods: {
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      return this.$v.$invalid
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    }
  },
  validations: {
    bathrooms: {
      minValue: minValue(0),
      numeric
    },
    showers: {
      minValue: minValue(0),
      numeric
    },
    toilets: {
      minValue: minValue(0),
      numeric
    },
    guestToilets: {
      minValue: minValue(0),
      numeric
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
