<template>
  <div>
    <div
      v-if="$slots.default"
      class="row">
      <div class="col">
        <label class="mb-0">
          <slot />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <multiselect
          v-bind="$attrs"
          :value="value"
          :options="options"
          :placeholder="placeholder"
          :multiple="multiple"
          :taggable="taggable"
          :select-label="''"
          :close-on-select="closeOnSelect || !multiple"
          :clear-on-select="true"
          :class="checkValide"
          tag-placeholder="Add this as new tag"
          @tag="addTag"
          @open="onOpen"
          v-on="mountEventListeners">
          <span slot="noResult">{{ $t('message.generic.noResult') }}</span>
          <span slot="noOptions">{{ $t('message.generic.ListIsEmpty') }}</span>
          <template
            v-if="emailWithName && props.option.name !== undefined && props.option.email !== undefined"
            slot="option"
            slot-scope="props">
            <div
              class="option__desc">
              <span class="option__title">{{ props.option.name }} - {{ props.option.email }}</span>
            </div>
          </template>
          <template
            v-if="withAddress"
            slot="option"
            slot-scope="props">
            <div
              v-if="withAddress"
              :id="'opt-'+props.option.objectID + randomString"
              :ref="'opt-'+props.option.objectID + randomString"
              class="tyo">
              <b-tooltip
                v-if="['inspector', 'maintenance', 'revision'].includes(from) "
                :disabled="withAddress && !checkIsDisabled(props.option.address)"
                :target="'opt-'+props.option.objectID + randomString"
                :title="$t('message.onBoarding.buildings.objects.tenancies.tenants.address.' + (from === 'inspector' ? 'inspectorAddressRequiredTooltip' : from === 'maintenance' ? 'maintenanceAddressRequiredTooltip' : 'revisorAddressRequiredTooltip'))" />
              <b-tooltip
                v-else
                :disabled="withAddress && !checkIsDisabled(props.option.address)"
                :target="'opt-'+props.option.objectID + randomString"
                :title="$t('message.onBoarding.buildings.objects.tenancies.tenants.address.addressRequeredTooltip')" />
              <div
                v-if="withNumericId"
                class="option__address">
                <span>{{ props.option.numericId }}</span>
              </div>
              <div
                v-if="detailBankname">
                {{ props.option.iid }}<br>
                {{ props.option.bankOrInstitutionName }}<br><span v-if="props.option.domicileAddress">{{ props.option.domicileAddress }} <br></span>{{ props.option.zipCode }} {{ props.option.place }}
              </div>
              <div
                class="option__desc"
                :class="withAddress && checkIsDisabled(props.option.address) ? 'disableOptionHover' : ''">
                <b><span class="option__title">{{ props.option.name }}</span></b>
              </div>
              <div
                v-if="props.option.address"
                class="option__address"
                :class="withAddress && checkIsDisabled(props.option.address) ? 'disableOptionHover' : ''">
                <span>{{ props.option.address | addressText }}</span>
              </div>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoozzySearchMultiselect',
  props: {
    placeholder: {
      type: String,
      default: function () {
        return this.$t('message.generic.select')
      }
    },
    value: {
      type: [Array, String, Object],
      default: function () {
        return []
      }
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    closeOnSelect: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: true
    },
    checkValide: {
      type: String,
      default: ''
    },
    isEmail: {
      type: Boolean,
      default: false
    },
    emailWithName: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    withAddress: {
      type: Boolean,
      default: true
    },
    withNumericId: {
      type: Boolean,
      default: false
    },
    detailBankname: {
      type: Boolean,
      default: false
    },
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchNewItemValidation: true,
      randomString: this.randomizationString()
    }
  },
  computed: {
    mountEventListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: (value) => {
            if (this.multiple) {
              this.$emit('input', value.filter(x => !this.withAddress || !this.checkIsDisabled(x.address)))
            } else {
              this.$emit('input', value)
            }
          }
        }
      )
    }
  },
  methods: {
    addTag(newTag) {
      newTag = newTag.trim()
      if (this.isEmail) {
        // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.searchNewItemValidation = re.test(String(newTag).toLowerCase())
        if (this.searchNewItemValidation) {
          let tag
          if (this.options.find(x => x.email === newTag)) {
            tag = this.options.find(x => x.email === newTag) || {
              email: newTag,
              name: ''
              // you'll need to add other items specific to your objects
            }
          } else {
            tag = {
              email: newTag,
              name: ''
              // you'll need to add other items specific to your objects
            }
            this.options.push(tag)
          }
          this.value.push(tag)
          this.searchNewItemValidation = true
        }
      } else {
        const tag = {
          email: newTag,
          name: newTag
          // you'll need to add other items specific to your objects
        }
        this.options.push(tag)
        if (this.multiple) {
          this.value.push(tag)
          this.$emit('input', this.value)
        } else {
          this.$emit('input', tag)
        }
      }
    },
    onOpen() {
      this.options.forEach(e => {
        if (e && e.isDisabled) {
          if (this.$refs['opt-' + e.value + this.randomString] && this.$refs['opt-' + e.value + this.randomString].parentElement && this.$refs['opt-' + e.value + this.randomString].parentElement.parentElement) {
            if (!this.$refs['opt-' + e.value + this.randomString].parentElement.parentElement.classList.contains('disableOption')) {
              this.$refs['opt-' + e.value + this.randomString].parentElement.parentElement.classList = this.$refs['opt-' + e.value + this.randomString].parentElement.parentElement.classList + ' disableOption'
            }
          }
        }
      })
    },
    randomizationString() {
      const result = []
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 4; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
      }
      return result.join('')
    },
    checkIsDisabled(address) {
      if (address?.zip || address?.city || address?.country) {
        const isAddressComplete = !!((address.zip && address.city && address.country))
        return !isAddressComplete
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.multiselect__tags) {
    border: 1px solid $color-input-border;
    border-radius: 0.2rem;
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 3px 40px 0 8px;
    box-shadow: 1px 1px 6px #ccc;
  }

  :deep(.multiselect__placeholder) {
    padding-top: 0;
    margin-bottom: 5px;
    color: #6d757d;
  }

  :deep(.multiselect__tag) {
    padding: 3px 22px 3px 7px;
    margin-bottom: 1px;
    background-color: $color-primary;
  }

  :deep(.multiselect__input) {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  :deep(.multiselect--disabled) {
    .multiselect__select {
      display: none;
    }
  }

  :deep(.multiselect__select) {
    height: 33px;
  }

  :deep(.multiselect) {
    min-height: 33px;
  }

  :deep(.multiselect) {
    min-height: 30px;
    &.is-invalid .multiselect__tags{
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-repeat: no-repeat;
      background-position: center right calc(0.375em + 0.1875rem);
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  :deep(.multiselect__tag-icon) {
    &:after {
      color: $color-text-white !important;
    }

    &:hover {
      background-color: $color-primary-dark;
    }
  }

  :deep(.multiselect__element) {
    &:hover {
      background-color: $color-primary;
    }
  }

  :deep(.multiselect__option--highlight:after) {
    content: none !important;
  }

  :deep(.multiselect__option--highlight) {
    background-color: $color-primary;
  }

  :deep(.multiselect__option--highlight.multiselect__option--selected) {
    background-color: $color-red;
  }

  .option__address{
    font-size: 14px;
  }
  :deep(.disableOption) {
    color: #6c757d;
    background-color: #e9ecef;

    &:hover {
      color: #6c757d !important;
      background-color: #e9ecef !important;
    }
  }
  .disableOptionHover {
    cursor: default !important;
    color: #6c757d !important;
  }
</style>
