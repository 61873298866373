import { render, staticRenderFns } from "./DeviceRevisionCosts.vue?vue&type=template&id=244310f3&scoped=true"
import script from "./DeviceRevisionCosts.vue?vue&type=script&lang=js"
export * from "./DeviceRevisionCosts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "244310f3",
  null
  
)

export default component.exports