<template>
  <section class="w-100">
    <div
      v-if="index !== -1"
      :id="`room-${target}-${index}`"
      v-b-toggle="`collapse-room-${target}-${index}`"
      class="col pt-3 mb-0">
      <div class="row">
        <div class="col">
          <h5
            class="cursor-pointer hover width-only-content">
            {{ title }}
          </h5>
        </div>
        <div
          class="col mr-5">
          <coozzy-dropdown
            v-if="(isTicketingEnabled && (isEditor || isTicketEditor)) || isEditor"
            :text="$t('message.generic.actions')"
            class="w-20 sort float-right"
            size="sm">
            <coozzy-dropdown-item
              v-if="room.id && isTicketingEnabled && (isEditor || isTicketEditor)"
              @click="createTicket();$event.stopPropagation()">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!isOwnerModule && !isAssetModule && room.id && isTicketingEnabled && (isEditor || isTicketEditor)"
              @click="navigateToCreateOrder()">
              {{ $t('message.ticketOverview.createOrder') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              @click="readOnly ? goToEditMode() : addDocumentClicked('buildingRoom' + index);$event.stopPropagation()">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
      </div>
    </div>
    <div class="col-12 px-0">
      <b-collapse
        :id="`collapse-room-${target}-${index}`"
        ref="collapse"
        :visible="expandByDefault || openedCollapses.includes(`collapse-room-${target}-${index}`)"
        class="col">
        <template>
          <div class="row details-row-bg-2 py-01rem pr-3">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="name"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.room.name')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="length"
                :filter="'formatMeter'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.room.roomLength')"
                placeholder="m"
                type="number"
                @input="calculateVolumeAndArea()" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="width"
                :filter="'formatMeter'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.room.roomWidth')"
                placeholder="m"
                type="number"
                @input="calculateVolumeAndArea()" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="height"
                :filter="'formatMeter'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.room.roomHeight')"
                placeholder="m"
                type="number"
                @input="calculateVolumeAndArea()" />
            </div>
          </div>

          <div class="row details-row-bg-1 py-01rem pr-3">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="area"
                :filter="'formatArea'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.room.area')"
                placeholder="m²"
                type="number" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="volume"
                :filter="'formatVolume'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.room.volume')"
                placeholder="m³"
                type="number" />
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="note">{{ $t('message.onBoarding.room.remark') }}</label>
              <coozzy-form-textarea
                id="note"
                ref="note"
                v-model="note"
                :initial="note"
                :is-read-only="readOnly"
                :show-label="false"
                :value-text-area="note"
                name="note" />
            </div>
          </div>
        </template>
        <div
          class="row">
          <div class="col-12">
            <hr>
          </div>
          <div class="col-12">
            <h6 class="d-inline-block font-weight-bold mt-3">
              {{ $t('message.generic.pictures') }}
            </h6>
          </div>
          <coozzy-spinner v-if="selectedRoomImageMedia === null" />
          <template v-else>
            <div
              v-if="selectedRoomImageMedia.length === 0"
              class="col-sm-12 col-md-3">
              {{ $t('message.generic.noPictures') }}
            </div>
            <draggable
              v-model="selectedRoomImageMedia"
              :disabled="readOnly"
              class="col-sm-12 col-md-12 row">
              <div
                v-for="(image, indexImage) in selectedRoomImageMedia"
                :key="image.media.id"
                class="col-sm-12 col-md-2 mt-3">
                <div
                  class="uploaded-file square">
                  <div
                    :class="['room-image-remove-container', 'cursor-pointer', 'hover', readOnly ? 'd-none' : '']"
                    @click="removeImageClicked(image.media)">
                    <coozzy-close-icon class="room-image-remove bg" />
                    <coozzy-close-icon class="room-image-remove" />
                  </div>
                  <coozzy-thumbnail
                    :key="image.media.id"
                    :original="image.media.url"
                    :size-restriction="false"
                    :src="getMediaUrl(image.media)"
                    class="room-image cursor-pointer"
                    @imageClicked="imageClicked(indexImage)" />
                </div>
              </div>
            </draggable>
            <div
              v-if="!readOnly && selectedRoomImageMedia.length < 20"
              class="col-sm-12 col-md-2 mt-1">
              <coozzy-spinner v-if="imageUploadProcessing" />
              <coozzy-button
                class="col-sm-4 col-lg-12 col-md-12"
                design="prop-green"
                @click="uploadImageClicked">
                {{ $t('message.generic.addPicture') }}
              </coozzy-button>
            </div>

            <coozzy-form-file-input
              v-if="!readOnly"
              ref="imageUpload"
              accept="image/png,image/jpeg,image/jpg"
              class="d-none"
              multiple
              @change="addFile" />
          </template>
        </div>
        <div class="row">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div
          class="row">
          <div class="col-12 col-md-2 mt-3">
            <add-document-modal
              :owner-id="room.ownerId"
              :suffix="'buildingRoom' + index"
              @document-created="documentCreated" />
            <coozzy-button
              class="w-100"
              design="prop-green"
              @click="addDocumentClicked('buildingRoom' + index)">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-button>
          </div>
        </div>
        <div
          class="row pr-3">
          <div
            v-if="documents.length > 0"
            class="col-12 mt-2">
            <h6
              class="font-weight-bold mt-3">
              {{ $t('message.generic.documents') }}
            </h6>
            <documents-list
              :documents-list="documents"
              :is-read-only="readOnly"
              @version-added="versionAdded"
              @document-deleted="deleteDocument" />
          </div>
        </div>
        <div
          class="row pr-3">
          <div class="col-12 mt-3">
            <coozzy-button
              class="float-right"
              design="red"
              size="small"
              @click="removeClicked">
              {{ $t('message.onBoarding.room.deleteRoom') }}
            </coozzy-button>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-modal
      :id="'deleteModal'"
      ref="deleteModal"
      :title="$t('message.onBoarding.deleteModals.confirmation')"
      hide-footer
      no-close-on-backdrop>
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodyRoom') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          class="mb-0 border"
          design="transparent"
          size="small"
          @click="$bvModal.hide('deleteModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import { onboarding } from '@/mixins/onboarding'
import { validationMixin } from 'vuelidate'
import { validation } from '@/mixins/validation'
import MediaApi from '@/misc/apis/MediaApi'
import { toastError } from '@/mixins/toastError'
import draggable from 'vuedraggable'
import { routeChecks } from '@/mixins/routeChecks'
import Vue from 'vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyCloseIcon from '@/framework/components/icons/CoozzyCloseIcon'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import { dateUtils } from '@/mixins/dateUtils'
import { media } from '@/mixins/media'
import DocumentsList from '@/properties/components/DocumentsList.vue'
import { user } from '@/mixins/user'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import CoozzyThumbnail from '@/framework/components/img/CoozzyThumbnail.vue'

export default {
  name: 'Room',
  components: {
    CoozzyThumbnail,
    CoozzyFormTextarea,
    CoozzyFormInput,
    CoozzyDropdownItem,
    CoozzyDropdown,
    DocumentsList,
    CoozzyFormFileInput,
    CoozzyCloseIcon,
    CoozzySpinner,
    CoozzyButton,
    CoozzyFormInputNumeric,
    AddDocumentModal,
    draggable
  },
  mixins: [onboarding, validationMixin, validation, toastError, dateUtils, routeChecks, media, user],
  props: {
    index: {
      type: Number,
      default: -1
    },
    target: {
      type: String,
      default: 'building'
    },
    room: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    externalContactList: {
      type: Array,
      default: () => {
        return []
      }
    },
    addNewDocument: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedRoomImageMedia: null,
      imageUpload: [],
      imageUploadProcessing: false,
      query: '',
      documentList: []
    }
  },
  computed: {
    area: {
      get() {
        return this.room.dimensions.area !== -100000000 ? this.room.dimensions.area : null
      },
      set(value) {
        const newRoom = this.room
        newRoom.dimensions.area = value
        this.dispatchRoomUpdate(newRoom)
      }
    },
    height: {
      get() {
        return this.room.dimensions.height !== -100000000 ? this.room.dimensions.height : null
      },
      set(value) {
        const newRoom = this.room
        newRoom.dimensions.height = value
        this.dispatchRoomUpdate(newRoom)
      }
    },
    length: {
      get() {
        return this.room.dimensions.length !== -100000000 ? this.room.dimensions.length : null
      },
      set(value) {
        const newRoom = this.room
        newRoom.dimensions.length = value
        this.dispatchRoomUpdate(newRoom)
      }
    },
    volume: {
      get() {
        return this.room.dimensions.volume !== -100000000 ? this.room.dimensions.volume : null
      },
      set(value) {
        const newRoom = this.room
        newRoom.dimensions.volume = value
        this.dispatchRoomUpdate(newRoom)
      }
    },
    width: {
      get() {
        return this.room.dimensions.width !== -100000000 ? this.room.dimensions.width : null
      },
      set(value) {
        const newRoom = this.room
        newRoom.dimensions.width = value
        this.dispatchRoomUpdate(newRoom)
      }
    },
    name: {
      get() {
        return this.room.name
      },
      set(value) {
        const newRoom = this.room
        newRoom.name = value
        this.dispatchRoomUpdate(newRoom)
      }
    },
    note: {
      get() {
        return this.room.note || null
      },
      set(value) {
        const newRoom = this.room
        newRoom.note = value
        this.dispatchRoomUpdate(newRoom)
      }
    },
    ownerId: {
      get() {
        return this.room.ownerId || null
      },
      set(value) {
        const newRoom = this.room
        newRoom.ownerId = value
        this.dispatchRoomUpdate(newRoom)
      }
    },
    referenceId: {
      get() {
        return this.room.referenceId || null
      },
      set(value) {
        const newRoom = this.room
        newRoom.referenceId = value
        this.dispatchRoomUpdate(newRoom)
      }
    },
    documents() {
      return this.target === 'building'
        ? this.getFilteredBuildingRoomDocuments(this.room.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
        : this.getFilteredObjectRoomDocuments(this.room.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    title() {
      if (this.room.name && this.room.name !== '') {
        return this.room.name
      } else {
        return '-'
      }
    },
    galleryImages() {
      return this.selectedRoomImageMedia.map(element => element.media.url)
    }
  },
  watch: {
    room: {
      deep: true,
      handler: function (val) {
        val.modified = true
        this.$emit('change-room', val)
      }
    }
  },
  mounted() {
    this.loadRoomImages(this.room)
    this.$nextTick(() => {
      if (!this.readOnly && this.addNewDocument) {
        this.addDocumentClicked('buildingRoom' + this.index)
      }
    })
  },
  methods: {
    async addFile(event) {
      let notImageFiles = []
      const files = await this.getFilesFromEvent(event)
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          const element = files[index]
          if (!['image/png', 'image/jpeg', 'image/jpg'].includes(element.type)) {
            notImageFiles.push(index)
          }
          if (notImageFiles.filter(x => x === index).length === 0) {
            this.imageUpload.push(element)
          }
        }
      }
      if (this.imageUpload.length > 0) {
        await this.sendFile()
      }
      if (notImageFiles.length > 0) {
        Vue.toasted.show(this.$tc('message.savingErrors.fileNotSupported'), { type: 'error' })
      }
    },
    async sendFile() {
      this.imageUploadProcessing = true
      const response = await this.uploadMedia(this.imageUpload)
      if (response.length > 0) {
        // Update vuex store
        const room = this.room
        for (const media of response) {
          this.selectedRoomImageMedia.push({
            media: media,
            mediaId: media.id
          })
          room.imageMediaIds.push(media.id)
        }
        this.dispatchRoomUpdate(room)
        this.imageUpload = []
        this.imageUploadProcessing = false
      }
      this.imageUploadProcessing = false
    },
    goToEditMode() {
      let routeLink = null
      localStorage.setItem('roomIdNewDocument', this.room.id)
      if (this.$route.name?.includes('BuildingDetailsView')) {
        if (this.$route.query.view === 'building') {
          routeLink = {
            name: this.moduleRoutePrefix + 'OnboardingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'building'
            }
          }
        } else if (this.$route.query.view === 'objects') {
          routeLink = {
            name: this.moduleRoutePrefix + 'OnboardingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'objects',
              elementSelected: this.room.referenceId
            }
          }
        }
      }
      this.$router.push(routeLink)
    },
    createTicket() {
      const defaultAssignee = this.room?.ownerId
      const list = [this.room?.id]
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee
          }
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee
          }
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee
          }
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee
          }
        })
      }
    },
    navigateToCreateOrder() {
      const currentRoute = {
        name: this.$router.currentRoute.name,
        params: this.$router.currentRoute.params,
        query: this.$router.currentRoute.query
      }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerCreateOrderView',
          query: {
            roomId: this.room.id,
            source: 'room'
          }
        }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminCreateOrderView',
          query: {
            roomId: this.room.id,
            source: 'room'
          }
        }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetCreateOrderView',
          query: {
            roomId: this.room.id,
            source: 'room'
          }
        }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingCreateOrderView',
          query: {
            roomId: this.room.id,
            source: 'room'
          }
        }, () => {
        })
      }
    },
    calculateVolumeAndArea() {
      if (this.length && this.width && this.height) {
        this.volume = this.length * this.width * this.height
      }
      if (this.length && this.width) {
        this.area = this.length * this.width
      }
    },
    dispatchRoomUpdate(newRoom) {
      if (this.target === 'building') {
        this.$store.dispatch('onboarding/updateBuildingRoom', newRoom)
      } else {
        this.$store.dispatch('onboarding/updateObjectRoom', newRoom)
      }
      this.$emit('room-update', newRoom)
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    removeConfirmed() {
      if (this.target === 'building') {
        this.removeBuildingRoom(this.room)
      } else {
        this.removeObjectRoom(this.room)
      }
      this.$refs.deleteModal.hide()
      this.$emit('removeConfirmed')
    },
    addDocumentClicked(suffix = '') {
      localStorage.removeItem('roomIdNewDocument')
      this.$bvModal.show('add-document-modal' + suffix)
    },
    documentCreated(document) {
      // Update room in vuex store with new documentId
      const room = this.room
      room.documentIds.push(document.id)
      if (this.target === 'building') {
        this.$store.dispatch('onboarding/updateBuildingRoom', room)
      } else {
        this.$store.dispatch('onboarding/updateObjectRoom', room)
      }

      // Add whole new document to vuex store to display it
      if (this.target === 'building') {
        this.buildingRoomDocuments.push(document)
      } else {
        this.objectRoomDocuments.push(document)
      }
    },
    versionAdded(updatedDocument) {
      if (this.target === 'building') {
        this.buildingRoomDocuments.forEach((doc, index) => {
          if (doc.id === updatedDocument.id) {
            this.buildingRoomDocuments.splice(index, 1, updatedDocument)
          }
        }, this)
      } else {
        this.objectRoomDocuments.forEach((doc, index) => {
          if (doc.id === updatedDocument.id) {
            this.objectRoomDocuments.splice(index, 1, updatedDocument)
          }
        }, this)
      }
    },
    deleteDocument(document) {
      this.room.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.room.documentIds.splice(index, 1)
          if (this.target === 'building') {
            const indexToDelete = this.buildingRoomDocuments.findIndex(d => d.id === document.id)
            if (indexToDelete !== -1) {
              this.$delete(this.buildingRoomDocuments, indexToDelete)
            }
          } else {
            const indexToDelete = this.objectRoomDocuments.findIndex(d => d.id === document.id)
            if (indexToDelete !== -1) {
              this.$delete(this.objectRoomDocuments, indexToDelete)
            }
          }
        }
      }, this)
      const newRoom = this.room
      this.dispatchRoomUpdate(newRoom)
      this.activateChanged()
    },
    removeImageClicked(media) {
      this.selectedRoomImageMedia = this.selectedRoomImageMedia.filter(m => {
        return m.media.id !== media.id
      })

      const newRoom = this.room
      newRoom.imageMediaIds = newRoom.imageMediaIds.filter(i => {
        return i !== media.id
      })
      this.dispatchRoomUpdate(newRoom)
    },
    getMediaUrl(media) {
      if (media.thumbnails === undefined || media.thumbnails === null || typeof media.thumbnails === 'undefined') {
        return null
      }
      for (const thumbnail of media.thumbnails) {
        if (thumbnail.type === 1 || thumbnail.type === 'M') {
          return thumbnail.url
        }
      }

      return media.url
    },
    imageClicked(index) {
      const images = [this.galleryImages[index]]
      this.$viewerApi({
        images: images,
        options: this.viewerOptions
      })
    },
    uploadImageClicked() {
      if (!this.readOnly) {
        const btn = this.$refs.imageUpload.$el.children
        btn[0].click()
      }
    },
    async loadRoomImages(room) {
      this.selectedRoomImageMedia = null
      // Load media
      const promises = []
      for (const image of room.imageMediaIds) {
        promises.push(MediaApi.getMediaByID(image))
      }
      const listmedia = await Promise.all(promises)
      for (let index = 0; index < (listmedia.length); index++) {
        listmedia[index].mediaId = room.imageMediaIds[index]
      }
      this.selectedRoomImageMedia = listmedia.filter(med => med.media.mimeType.includes('image'))
    }
  }
}
</script>

<style lang="scss" scoped>
.uploaded-file {
  height: 200px;
}

.room-image-remove-container {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin: 4px 12px;
}

.room-image-remove {
  position: absolute;
  z-index: 3;
  width: 16px;
  height: 16px;

  &.bg {
    left: -2px;
    top: -4px;
    width: 20px;
    height: 24px;
    color: white;
  }
}

.room-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-gray {
  color: $color-text-grey-light;
}

.square {
  position: relative;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
</style>
