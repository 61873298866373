const dateUtils = {
  methods: {
    toObject(stringDate) {
      if (!stringDate) {
        return null
      }

      const convDate = new Date(stringDate)
      return convDate instanceof Date && isFinite(convDate.getTime())
? {
        day: convDate.getDate(),
        month: convDate.getMonth() + 1,
        year: convDate.getFullYear()
      }
: null
    },
    objectToIsoDate(object) {
      return object && object.year !== -1 && object.year > 0 ? object.year + '-' + ('0' + object.month).slice(-2) + '-' + ('0' + object.day).slice(-2) : ''
    },
    dateObjectToTimeStamp(dateObject) {
      return dateObject !== null && dateObject.year !== null ? new Date(dateObject.year + '-' + ('0' + dateObject.month).slice(-2) + '-' + ('0' + dateObject.day).slice(-2)).getTime() : 0
    },
    objectToStringDate(object) {
      return object && object.year !== -1 && object.year > 0 ? ('0' + object.day).slice(-2) + '.' + ('0' + object.month).slice(-2) + '.' + object.year : ''
    },
    getTimeSince(timestampOrDateString, isTimestamp) {
      let d
      if (isTimestamp) {
        d = new Date(timestampOrDateString * 1000)
      } else {
        d = new Date(timestampOrDateString)
      }
      const localtime = new Date().getTime()
      const onehoure = 60 * 60 * 1000
      const oneday = 60 * 60 * 24 * 1000
      const twoday = 60 * 60 * 48 * 1000
      if ((localtime - d.getTime()) < onehoure) {
        const minAgo = Math.floor(((localtime - d) / 1000 / 60))
        if (minAgo <= 0) {
          return this.$t('message.notifications.now')
        } else {
          return this.$t('message.notifications.minutesAgo', { minutes: minAgo })
        }
      } else if (localtime - d.getTime() < oneday) {
        return this.$t('message.notifications.hoursAgo', { hours: Math.floor((localtime - d) / 1000 / 60 / 60) })
      } else if ((localtime - d.getTime()) > oneday && (new Date().getTime() - d) < twoday) {
        return this.$t('message.notifications.yesterday')
      } else {
        return this.$options.filters.timestampToDateTime(d.getTime() / 1000)
      }
    }
  }
}

export { dateUtils }
