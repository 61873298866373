<template>
  <coozzy-card :title="$t('message.settings.domain.title')">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="row">
          <div class="col-12 col-md-3 offset-md-9">
            <coozzy-button
              class="my-2 w-100"
              design="prop-green"
              @click="$refs['add-domain-modal'].show()">
              {{ $t('message.settings.domain.create') }}
            </coozzy-button>
          </div>
          <div class="col-12">
            <b-table
              hover
              bordered
              responsive="true"
              stacked="md"
              class="mb-0 building-tables"
              selectable
              :busy="loading"
              :fields="fields"
              :items="domainList"
              @row-clicked="changeSelectedDomain">
              <!-- Busy state -->
              <div
                slot="table-busy"
                class="text-center text-danger my-2">
                <coozzy-spinner />
              </div>

              <!-- Header -->
              <template #head(domain)>
                {{ $t('message.settings.domain.name') }}
              </template>
              <template #head(validationKey)>
                {{ $t('message.settings.domain.validationKey') }}
              </template>
              <template #head(isValid)>
                {{ $t('message.settings.domain.isValid') }}
              </template>

              <!-- Body -->
              <template #cell(domain)="data">
                {{ data.item.domain }}
              </template>
              <template #cell(validationKey)="data">
                {{ data.item.validationKey }}
              </template>
              <template #cell(isValid)="data">
                <coozzy-check-circle-icon
                  v-if="data.item.isValid"
                  class="green-icon" />
                <coozzy-fail-icon v-else />
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 order-first order-md-last">
        <coozzy-alert variant="secondary">
          <h6 class="font-weight-bold">
            {{ $t('message.settings.domain.info.title') }}
          </h6>
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="$t('message.settings.domain.info.text')" />
          <!-- eslint-enable vue/no-v-html -->
        </coozzy-alert>
      </div>
    </div>
    <create-domain-modal
      ref="add-domain-modal"
      @domain-created="domainCreated" />
    <domain-details-modal
      ref="detail-domain-modal"
      :key="selectedDomain ? selectedDomain.id : null"
      :domain="selectedDomain"
      @refresh-domains="refreshDomains" />
  </coozzy-card>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import DomainDetailsModal from './DomainDetailsModal'
import CreateDomainModal from './CreateDomainModal'
import MessageApi from '../../misc/apis/MessageApi'
import Vue from 'vue'
import CoozzyCheckCircleIcon from '../../framework/components/icons/CoozzyCheckCircleIcon'
import CoozzyFailIcon from '../../framework/components/icons/CoozzyFailIcon'
import CoozzyAlert from '../../framework/components/alert/CoozzyAlert'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyButton from '../../framework/components/button/CoozzyButton'

export default {
  name: 'DomainSettings',
  components: {
    CoozzyButton,
    CoozzySpinner,
    CoozzyAlert,
    CoozzyFailIcon,
    CoozzyCheckCircleIcon,
    CoozzyCard,
    CreateDomainModal,
    DomainDetailsModal
  },
  data() {
    return {
      domainList: [],
      selectedDomain: null,
      loading: false,
      fields: [
        {
          key: 'domain',
          label: this.$t('message.settings.domain.name'),
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'validationKey',
          label: this.$t('message.settings.domain.validationKey'),
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'isValid',
          label: this.$t('message.settings.domain.isValid'),
          thClass: 'table-header text-center',
          tdClass: 'align-middle text-center'
        }
      ]
    }
  },
  mounted() {
    this.getDomailList()
  },
  methods: {
    changeSelectedDomain(item) {
      this.selectedDomain = item
      this.$refs['detail-domain-modal'].show()
    },
    domainCreated(domain) {
      this.selectedDomain = domain.domain
      this.domainList.push(domain.domain)
      this.$refs['detail-domain-modal'].show()
      this.$nextTick(function () {
        this.$refs['detail-domain-modal'].show()
      })
    },
    getDomailList() {
      this.loading = false
      MessageApi.getDomainList(this.$store.getters['user/getAccountId'])
        .then(response => {
          this.domainList = response.domains
          this.loading = false
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.loadingErrors.domain'), { type: 'error' })
        })
    },
    refreshDomains() {
      this.getDomailList()
      this.$emit('domain-deleted')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.green-icon svg) {
  color: $color-navigation-active;
}
</style>
