import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    countReminders: 0
  },
  getters: {
    getCountReminders(state) {
      return state.countReminders
    }
  },
  mutations: {
    setCountReminders(state, value) {
      Vue.set(state, 'countReminders', value)
    }
  },
  actions: {
    setCountReminders: ({ commit }, value) => {
      commit('setCountReminders', value)
    }
  }
}
