<template>
  <span>{{ getText }}</span>
</template>

<script>
export default {
  name: 'CoozzyAppointmentText',
  props: {
    appointment: {
      type: Object,
      default: null
    }
  },
  computed: {
    getText() {
      const filterInstance = this.$options.filters
      const startString = filterInstance.timestampToTime(this.appointment.startTime)
      let endString = filterInstance.timestampToTime(this.appointment.endTime)

      const start = new Date(this.appointment.startTime * 1000)
      const end = new Date(this.appointment.endTime * 1000)

      if (start.getFullYear() === end.getFullYear() &&
        start.getMonth() === end.getMonth() &&
        start.getDate() === end.getDate()) {
        endString = endString.substr(10)
      }

      return `${startString} - ${endString}`
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
