const user = {
  data() {
    return {
      viewerOptions: {
        inline: false,
        button: true,
        navbar: false,
        title: false,
        toolbar: false,
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: false,
        scalable: false,
        transition: false,
        fullscreen: true,
        keyboard: true
      }
    }
  },
  computed: {
    currentLanguage() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    },
    getAccessToken() {
      return this.$store.getters['user/getAccessToken']
    },
    isAuthenticated() {
      return this.$store.getters['user/isAuthenticated']
    },
    fullName() {
      const profile = this.$store.getters['user/getCurrentUser']
      return `${profile['https://coozzy.ch/lastName']} ${profile['https://coozzy.ch/firstName']}`
    },
    userId() {
      return this.$store.getters['user/getUserId']
    },
    accountId() {
      return this.$store.getters['user/getAccountId']
    },
    subType() {
      return this.$store.getters['user/getSubType']
    },
    userEmail() {
      return this.$store.getters['user/getEmail']
    },
    userProfilePicture() {
      const profile = this.$store.getters['user/getCurrentUser']
      return profile.picture || ''
    },
    logoUrl() {
      return this.$store.getters['user/getLogoUrl']
    },
    isCompanyAccount() {
      return this.$store.getters['user/isCompanyAccount']
    },
    isEditor() {
      return this.$store.getters['user/isEditor']
    },
    isJanitor() {
      return this.$store.getters['user/isJanitor']
    },
    isJanitorEditor() {
      return this.$store.getters['user/isJanitorEditor']
    },
    isTicketReader() {
      return this.$store.getters['user/isTicketReader']
    },
    isTicketCreator() {
      return this.$store.getters['user/isTicketCreator']
    },
    isTicketEditor() {
      return this.$store.getters['user/isTicketEditor']
    },
    isTicketingEnabled() {
      return this.$store.getters['user/getIsTicketingEnabled']
    },
    isInvestPlanEnabled() {
      return this.$store.getters['user/getIsInvestPlanEnabled']
    },
    isBriefButlerEnabled() {
      return this.$store.getters['user/getIsBriefButlerEnabled']
    },
    isTicketDeletor() {
      return this.$store.getters['user/isTicketDeletor']
    },
    userWithoutRole() {
      return this.$store.getters['user/userWithoutRole']
    },
    isCompanyAdmin() {
      return this.$store.getters['user/isCompanyAdmin']
    },
    hasDigitalSignatureRole() {
      return this.$store.getters['user/hasDigitalSignatureRole']
    },
    hasFinanceBaseRole() {
      return this.$store.getters['user/hasFinanceBaseRole']
    },
    hasAdminBaseRole() {
      return this.$store.getters['user/hasAdminBaseRole']
    },
    hasMarketbaseRole() {
      return this.$store.getters['user/hasMarketbaseRole']
    },
    hasAssetBaseRole() {
      return this.$store.getters['user/hasAssetBaseRole']
    },
    isPlatformEmployee() {
      return this.$store.getters['user/isPlatformEmployee']
    },
    hasAccessPropBase() {
      localStorage.setItem('hasAccessPropBase', 'true')
      return this.$store.getters['user/getSubType'] && this.$store.getters['user/getSubType'].toUpperCase() === 'OWNER'
    },
    hasAccessMarketBase() {
      localStorage.setItem('hasAccessMarketBase', 'true')
      return (this.$store.getters['user/getSubType'] && this.$store.getters['user/getSubType'].toUpperCase().includes('MARKETING')) && (this.isCompanyAdmin || this.hasMarketbaseRole)
    },
    hasAccessAdminBase() {
      localStorage.setItem('hasAccessAdminBase', 'true')
      return ((this.$store.getters['user/getType'] && this.$store.getters['user/getType'].toUpperCase() === 'PLATFORM_EMPLOYEE') || (this.$store.getters['user/getSubType'] && this.$store.getters['user/getSubType'].toUpperCase().includes('LANDLORD'))) && (this.isCompanyAdmin || this.hasAdminBaseRole)
    },
    hasAccessAccountingBase() {
      localStorage.setItem('hasAccessAccountingBase', 'true')
      return (this.$store.getters['user/getSubType'] && this.$store.getters['user/getSubType'].toUpperCase().includes('ACCOUNTING')) && (this.isCompanyAdmin || this.hasFinanceBaseRole)
    },
    hasAccessAssetBase() {
      localStorage.setItem('hasAccessAssetBase', 'true')
      return (this.$store.getters['user/getSubType'] && this.$store.getters['user/getSubType'].toUpperCase().includes('ASSET')) && (this.isCompanyAdmin || this.hasAssetBaseRole)
    },
    hasAccessUserBase() {
      return this.$store.getters['user/getSubType'] && this.$store.getters['user/getSubType'].toUpperCase() === 'TENANT'
    },
    hasManageTemplatesRole() {
      return this.$store.getters['user/hasManageTemplatesRole']
    },
    hasManageMailsRole() {
      return this.$store.getters['user/hasManageMailsRole']
    },
    hasPublishUpdateMessageRole() {
      return this.$store.getters['user/hasPublishUpdateMessageRole']
    },
    hasGenerateDataSetRole() {
      return this.$store.getters['user/hasGenerateDataSetRole']
    },
    hasDeleteTicketRole() {
      return this.$store.getters['user/hasManageMailsRole']
    }
  }
}

export { user }
