export default {
  namespaced: true,
  state: {
    token: null
  },
  getters: {
    getToken: (state) => {
      return state.token
    }
  },
  mutations: {
    setToken: (state, value) => {
      state.token = value
    }
  },
  actions: {
    setToken({ commit }, value) {
      commit('setToken', value)
    }
  }
}
