<template>
  <div class="d-inline-block">
    <coozzy-exclamation-triangle-icon :id="`matching-triangle-${value}`" />

    <b-popover
      :target="`matching-triangle-${value}`"
      triggers="focus hover"
      placement="auto">
      <template #title>
        {{ $t('message.generic.dataFromApplicationForm') }}
      </template>
      <template #default>
        <div style="white-space: pre-line">
          {{ value }}
        </div>
        <coozzy-button
          design="prop-green"
          size="small"
          class="float-right mb-2"
          @click="acceptClicked">
          {{ $t('message.generic.form.accept') }}
        </coozzy-button>
      </template>
    </b-popover>
  </div>
</template>

<script>
import CoozzyExclamationTriangleIcon from '@/framework/components/icons/CoozzyExclamationTriangle'
import CoozzyButton from '@/framework/components/button/CoozzyButton'

export default {
  name: 'CoozzyMatchingPopover',
  components: { CoozzyButton, CoozzyExclamationTriangleIcon },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    acceptClicked() {
      this.$emit('accepted')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.popover-body) {
  padding-right: 0.5rem !important;
}
</style>
