<template>
  <section>
    <div class="row">
      <div class="col-sm-6 col-md-2 col-lg-2 mb-0 mb-md-3">
        <div class="row">
          <div class="col-sm-12 col-md-12 mb-2 simple">
            <single-value
              v-if="statistics.overallNewInMarketing > 0"
              :name="$t('message.dashboard.marketing.newObjects')"
              :value="statistics.overallNewInMarketing"
              design="blue"
              class="cursor-pointer"
              @click="$router.push({name: 'MarketingObjectsView', query: { 'object_marketing[refinementList][marketingStatus.status][0]': 'new', 'predefined': 'true' }})"
              @click-middle="newObjectsMiddle" />
            <single-value
              v-else
              :name="$t('message.dashboard.marketing.newObjects')"
              :value="statistics.overallNewInMarketing"
              design="blue" />
          </div>
          <div class="col-sm-12 col-md-12 mb-2 simple">
            <single-value
              v-if="statisticsRequest.overallNewRequests > 0"
              :name="$t('message.dashboard.marketing.newRequests')"
              :value="statisticsRequest.overallNewRequests"
              design="deep-orange"
              class="cursor-pointer"
              @click="$router.push({name: 'MarketingObjectsView', query: { 'object_marketing[refinementList][requestStatistics.status][0]': 'new', 'object_marketing[refinementList][requestStatistics.status][1]': 'new_responses', 'predefined': 'true' }})"
              @click-middle="newRequestsMiddle" />
            <single-value
              v-else
              :name="$t('message.dashboard.marketing.newRequests')"
              :value="statisticsRequest.overallNewRequests"
              design="deep-orange" />
          </div>
          <div class="col-sm-12 col-md-12 mb-2 simple">
            <single-value
              v-if="statisticsRequest.overallUnreadRequests > 0"
              :name="$t('message.dashboard.marketing.unreadRequests')"
              :value="statisticsRequest.overallUnreadRequests"
              design="gray"
              class="cursor-pointer"
              @click="$router.push({name: 'MarketingObjectsView', query: { 'object_marketing[refinementList][requestStatistics.status][0]': 'responses', 'object_marketing[refinementList][requestStatistics.status][1]': 'new_responses', 'predefined': 'true' }})"
              @click-middle="unreadRequestsMiddle" />
            <single-value
              v-else
              :name="$t('message.dashboard.marketing.unreadRequests')"
              :value="statisticsRequest.overallUnreadRequests"
              design="gray" />
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2 col-lg-2 mb-3">
        <div class="row">
          <div class="col-12 col-sm-12 mb-2 simple">
            <single-value
              :name="$t('message.dashboard.marketing.eventsToday')"
              :value="countEventsToday"
              design="gray"
              class="cursor-pointer"
              @click="$router.push({name: 'MarketingCalendarOverview', params: { viewCalendar: 'day' }})"
              @click-middle="eventsTodayMiddle" />
          </div>
          <div class="col-12 col-sm-12 mb-2 simple">
            <single-value
              v-if="statisticsRequest.employeeNewRequests > 0"
              :name="$t('message.dashboard.marketing.myNewRequests')"
              :value="statisticsRequest.employeeNewRequests"
              design="blue"
              class="cursor-pointer"
              @click="$router.push({name: 'MarketingObjectsView', query: { 'object_marketing[refinementList][assignees.marketing.name][0]': fullName, 'object_marketing[refinementList][requestStatistics.status][0]': 'new', 'object_marketing[refinementList][requestStatistics.status][1]': 'new_responses', 'predefined': 'true' }})"
              @click-middle="myNewRequestsMiddle" />
            <single-value
              v-else
              :name="$t('message.dashboard.marketing.myNewRequests')"
              :value="statisticsRequest.employeeNewRequests"
              design="blue" />
          </div>
          <div class="col-12 col-sm-12 mb-2 simple">
            <single-value
              v-if="statisticsRequest.employeeUnreadRequests > 0"
              :name="$t('message.dashboard.marketing.myUnreadRequests')"
              :value="statisticsRequest.employeeUnreadRequests"
              design="light-blue"
              class="cursor-pointer"
              @click="$router.push({name: 'MarketingObjectsView', query: { 'object_marketing[refinementList][assignees.marketing.name][0]': fullName, 'object_marketing[refinementList][requestStatistics.status][0]': 'responses', 'object_marketing[refinementList][requestStatistics.status][1]': 'new_responses', 'predefined': 'true' }})"
              @click-middle="myUnreadRequestsMiddle" />
            <single-value
              v-else
              :name="$t('message.dashboard.marketing.myUnreadRequests')"
              :value="statisticsRequest.employeeUnreadRequests"
              design="light-blue" />
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 mb-0 mb-md-3">
        <my-marketing-object-table :statistics="statistics" />
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 mb-0 mb-md-3">
        <all-marketing-object-table :statistics="statistics" />
      </div>
    </div>
    <dashboard-tables
      @event-today-length="getCountEventsToday"
      @event-current-Week-length="getCountEventCurrentWeek" />
  </section>
</template>

<script>
import Vue from 'vue'
import ObjectApi from '../../misc/apis/ObjectApi'
import ContactApi from '../../misc/apis/ContactApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import { chartColors } from '@/mixins/chartColors'
import DashboardTables from '@/dashboard/marketing/DashboardTables'
import MyMarketingObjectTable from './MyMarketingObjectTable'
import AllMarketingObjectTable from './AllMarketingObjectTable'
import SingleValue from '@/dashboard/charts/SingleValue'
import { mapGetters } from 'vuex'
import { user } from '@/mixins/user'
import { calendar } from '@/mixins/calendar'

export default {
  name: 'MarketingDashboardView',
  components: { SingleValue, DashboardTables, MyMarketingObjectTable, AllMarketingObjectTable },
  mixins: [chartColors, user, calendar],
  metaInfo() {
    return {
      title: this.$t('message.navigation.dashboard.title')
    }
  },
  data() {
    return {
      statistics: {
        employeeInMarketing: 0,
        employeeInMarketingFor4Weeks: 0,
        employeeInMarketingFor8Weeks: 0,
        employeeInMarketingFor12Weeks: 0,
        employeeOnline: 0,
        employeeOnlineFor4Weeks: 0,
        employeeOnlineFor8Weeks: 0,
        employeeOnlineFor12Weeks: 0,
        overallNewInMarketing: 0,
        overallInMarketing: 0,
        overallInMarketingFor4Weeks: 0,
        overallInMarketingFor8Weeks: 0,
        overallInMarketingFor12Weeks: 0,
        overallOnline: 0,
        overallOnlineFor4Weeks: 0,
        overallOnlineFor8Weeks: 0,
        overallOnlineFor12Weeks: 0
      },
      statisticsRequest: {
        overallNewRequests: 0,
        employeeNewRequests: 0,
        overallUnreadRequests: 0,
        employeeUnreadRequests: 0
      },
      countEventCurrentWeek: 0,
      countEventsToday: 0
    }
  },
  computed: {
    ...mapGetters('message', ['getMailboxesForUserId'])
  },
  mounted() {
    ContactApi.getContacts(this.accountId)
      .then(response => {
        const contactsList = response.persons.concat(response.companies).concat(response.companies.map(c => c.contactPersons).flat())
        const contactId = contactsList.find(c => c.email && c.email.toLowerCase() === this.$store.getters['user/getCurrentUser'].email.toLowerCase()).id
        ObjectApi.getMarketingStatistics(this.$store.getters['user/getAccountId'], contactId)
          .then(response => {
            this.statistics = response
          })
        RequestApi.getMarketingStatistics(this.$store.getters['user/getAccountId'], contactId)
          .then(response => {
            this.statisticsRequest = response
          })
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
      })
  },
  methods: {
    getCountEventsToday(item) {
      this.countEventsToday = item
    },
    newObjectsMiddle() {
      const routeData = this.$router.resolve({ name: 'MarketingObjectsView', query: { 'object_marketing[refinementList][marketingStatus.status][0]': 'new', predefined: 'true' } })
      window.open(routeData.href, '_blank')
    },
    newRequestsMiddle() {
      const routeData = this.$router.resolve({ name: 'MarketingObjectsView', query: { 'object_marketing[refinementList][requestStatistics.status][0]': 'new', 'object_marketing[refinementList][requestStatistics.status][1]': 'new_responses', predefined: 'true' } })
      window.open(routeData.href, '_blank')
    },
    unreadRequestsMiddle() {
      const routeData = this.$router.resolve({ name: 'MarketingObjectsView', query: { 'object_marketing[refinementList][requestStatistics.status][0]': 'responses', 'object_marketing[refinementList][requestStatistics.status][1]': 'new_responses', predefined: 'true' } })
      window.open(routeData.href, '_blank')
    },
    eventsTodayMiddle() {
      const routeData = this.$router.resolve({ name: 'MarketingCalendarOverview', params: { viewCalendar: 'day' } })
      window.open(routeData.href, '_blank')
    },
    myNewRequestsMiddle() {
      const routeData = this.$router.resolve({ name: 'MarketingObjectsView', query: { 'object_marketing[refinementList][assignees.marketing.name][0]': this.fullName, 'object_marketing[refinementList][requestStatistics.status][0]': 'new', 'object_marketing[refinementList][requestStatistics.status][1]': 'new_responses', predefined: 'true' } })
      window.open(routeData.href, '_blank')
    },
    myUnreadRequestsMiddle() {
      const routeData = this.$router.resolve({ name: 'MarketingObjectsView', query: { 'object_marketing[refinementList][assignees.marketing.name][0]': this.fullName, 'object_marketing[refinementList][requestStatistics.status][0]': 'responses', 'object_marketing[refinementList][requestStatistics.status][1]': 'new_responses', predefined: 'true' } })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
