<template>
  <section class="w-100">
    <div v-if="countReminder !== 0">
      <coozzy-detail-card
        :title="$t('message.calendar.reminder.title') + ' (' + countReminder + ')'">
        <div class="col p-0">
          <b-table
            ref="reminder-table"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            class="m-0"
            :busy="isBusyReminder"
            :fields="fieldsReminder"
            :items="countReminder <= 5 || showAllReminders ? reminderList : reminderList.slice(0,5)"
            @row-clicked="showDetail">
            <!-- Headings -->
            <template #head(date)>
              {{ $t('message.calendar.reminder.date') }}
            </template>
            <template #head(subject)>
              {{ $t('message.calendar.reminder.subject') }}
            </template>
            <template #head(assignee)>
              {{ $t('message.generic.assignee') }}
            </template>

            <!-- Columns -->
            <template
              #cell(date)="data">
              <span :class="[data.item.done !== '0' ? 'text-grey' : '']">
                {{ data.item.date | timestampToDateTime }}
              </span>
            </template>
            <template
              #cell(subject)="data">
              <span :class="[data.item.done !== '0' ? 'text-grey' : '']">
                {{ data.item.subject | displayOptionalValue }}
              </span>
            </template>
            <template
              #cell(assignee)="data">
              <span :class="[data.item.done !== '0' ? 'text-grey' : '']">
                <employee-name-text
                  :user-id="data.item.employeeUserId"
                  :shorten="false" />
              </span>
            </template>
          </b-table>
          <div class="text-center">
            <a
              v-if="countReminder > 5"
              class="link"
              @click="showAllReminders = !showAllReminders">
              <coozzy-angle-double-up-icon v-if="showAllReminders === true" />
              <coozzy-angle-double-down-icon v-else />
            </a>
          </div>
        </div>
      </coozzy-detail-card>
    </div>
    <reminder-details-modal
      v-if="selectedReminder !== null"
      ref="detail-reminder-modal"
      :key="selectedReminder.id"
      :reminder-detail="selectedReminder"
      :contact-id="contact.id"
      :available-contacts="availableContact"
      :available-employees="availableEmployees"
      @update-reminder="editDone"
      @create-reminder-click="createReminderClicked" />
    <reminder-creation-modal
      ref="modal-creation"
      :key="remainderForEdit !== null ? 'modal-creation'+remainderForEdit.id+availableContact.length : 'modal-creation'+availableContact.length"
      :reminder-detail="remainderForEdit"
      :available-contacts="availableContact"
      :contact-id="contact.id"
      :available-employees="availableEmployees"
      @new-reminder="newReminder"
      @update-reminder="updateReminder" />
  </section>
</template>

<script>
import ReminderDetailsModal from '@/calendar/components/ReminderDetailsModal'
import EmployeeNameText from '../../framework/components/misc/EmployeeNameText'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CalendarApi from '../../misc/apis/CalendarApi'
import { mapActions, mapGetters } from 'vuex'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard.vue'
import CoozzyAngleDoubleUpIcon from '@/framework/components/icons/CoozzyAngleDoubleUpIcon.vue'
import CoozzyAngleDoubleDownIcon from '@/framework/components/icons/CoozzyAngleDoubleDownIcon.vue'

export default {
  name: 'ContactReminderCard',
  components: { CoozzyAngleDoubleDownIcon, CoozzyAngleDoubleUpIcon, CoozzyDetailCard, ReminderDetailsModal, ReminderCreationModal, EmployeeNameText },
  props: {
    availableContact: {
      type: Array,
      default() {
        return []
      }
    },
    contact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showAllReminders: false,
      remainderForEdit: null,
      isBusyReminder: false,
      selectedReminder: null,
      reminderList: [],
      fieldsReminder: [
        {
          key: 'date',
          label: this.$t('message.calendar.reminder.date'),
          tdClass: 'align-middle'
        },
        {
          key: 'subject',
          label: this.$t('message.calendar.reminder.subject'),
          tdClass: 'align-middle'
        },
        {
          key: 'assignee',
          label: this.$t('message.generic.assignee'),
          tdClass: 'align-middle'
        }
      ]
    }
  },
  computed: {
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    availableEmployees() {
      const array = []
      if (this.employeeList.length !== 0) {
        this.employeeList.forEach((emp) => {
          if (typeof (emp) !== 'undefined') {
            array.push({
              label: (emp.profile.firstName ? emp.profile.firstName.substr(0, 1) + '. ' : '') + emp.profile.lastName + (emp.deleted ? ` (${this.$t('message.generic.deleted')})` : ''),
              value: emp.id
            })
          }
        })
      }
      return array
    },
    countReminder() {
      return this.reminderList.length
    }
  },
  mounted() {
    this.loadEmployees()
    this.loadReminder()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    createReminderClicked(reminder = null) {
      if (reminder !== null) {
        this.remainderForEdit = reminder
      } else {
        this.remainderForEdit = null
      }
      this.$nextTick(function () {
        this.$refs['modal-creation'].show()
      })
    },
    editDone(reminder) {
      const indexToEdit = this.reminderList.findIndex(x => x.id === reminder.id)
      if (indexToEdit !== -1) {
        this.reminderList[indexToEdit] = reminder
        this.$refs['reminder-table'].refresh()
      }
    },
    loadReminder() {
      const referencesIds = this.contact.contactPersons
        ? [this.contact.id].concat(this.contact.contactPersons.map(d => d.id))
        : [this.contact.id]
      CalendarApi.getRemindersByReferenceIds(referencesIds, this.$store.getters['user/getAccountId'])
        .then(response => {
          this.reminderList = response.reminders
        })
        .catch(e => {
          console.log(e)
          alert(this.$t('message.loadingErrors.reminders'))
        })
    },
    newReminder() {
      this.loadReminder()
      this.selectedReminder = null
    },
    showDetail(item) {
      this.selectedReminder = item
      this.$nextTick(function () {
        this.$refs['detail-reminder-modal'].show()
      })
    },
    toTimestamp(strDate) {
      return Date.parse(strDate) / 1000
    },
    updateReminder() {
      this.loadReminder()
      this.selectedReminder = null
    }
  }
}
</script>

<style lang="scss" scoped>
.text-grey {
  color: $color-text-grey-light;
}
</style>
