<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>{{ $t(`message.export.${platform}`) }} </h4>
        <coozzy-info-circle-icon
          v-if="platform === 'newhome'"
          id="newhomeInfo"
          v-b-tooltip.hover.html="$t('message.export.newhomeInfo')" />
      </div>
      <div class="col">
        <coozzy-button
          :disabled="exportConfig === null || !exportConfig.id"
          class="float-right"
          design="prop-green"
          @click="startExport">
          {{ $t('message.generic.form.start') }}
        </coozzy-button>
      </div>
    </div>
    <coozzy-spinner v-if="loading" />
    <div>
      <div class="row mt-1">
        <div class="col-12 col-sm-6">
          <coozzy-form-input
            v-model="credentials.hostname"
            :name="$t('message.marketingSettings.export.hostname')"
            :state="$v.credentials.hostname.$dirty && $v.credentials.hostname.$error ? false : null"
            type="text"
            :is-read-only="readOnly" />
        </div>
        <div class="col-12 col-sm-6">
          <coozzy-form-input
            v-model="credentials.port"
            :name="$t('message.marketingSettings.export.port')"
            :state="$v.credentials.port.$dirty && $v.credentials.port.$error ? false : null"
            type="text"
            :is-read-only="readOnly" />
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 col-sm-6">
          <coozzy-form-input
            v-model="credentials.username"
            :name="$t('message.marketingSettings.export.username')"
            :state="$v.credentials.username.$dirty && $v.credentials.username.$error ? false : null"
            type="text"
            :is-read-only="readOnly" />
        </div>
        <div
          v-if="isCompanyAdmin"
          class="col-12 col-sm-6">
          <coozzy-form-input
            v-model="credentials.password"
            :name="$t('message.marketingSettings.export.password')"
            :state="$v.credentials.password.$dirty && $v.credentials.password.$error ? false : null"
            type="password" />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <coozzy-button
            v-if="!readOnly"
            :disabled="exportConfig === null || !exportConfig.id"
            class="float-right"
            design="red"
            @click="deleteExportData">
            {{ $t('message.marketingSettings.export.deleteExportData') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 col-sm-6">
          <coozzy-form-input
            v-model="agencyId"
            :name="$t('message.marketingSettings.export.agencyId')"
            type="text"
            :is-read-only="readOnly" />
        </div>
        <div
          v-if="displayPreset"
          class="col-12 col-sm-6">
          <!--suppress XmlInvalidId -->
          <label
            for="preset"
            class="mb-0">{{ $t('message.marketingSettings.export.preset.title') }}</label>
          <coozzy-form-select
            v-model="preset"
            name="preset"
            :is-read-only="readOnly">
            <option value="FULL_PRESET">
              {{ $t('message.marketingSettings.export.preset.allEnabled') }}
            </option>
            <option value="FULL_AGENCY_NO_VISIT_PRESET">
              {{ $t('message.marketingSettings.export.preset.noVisit') }}
            </option>
            <option value="ALL_FIELDS_DISABLED_PRESET">
              {{ $t('message.marketingSettings.export.preset.allDisabled') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <coozzy-button
            v-if="!readOnly"
            design="green"
            class="float-right"
            @click="saveExport">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { minLength, numeric, required } from 'vuelidate/lib/validators'
import ExportApi from '../../misc/apis/ExportApi'
import Vue from 'vue'
import { user } from '@/mixins/user'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'

export default {
  name: 'ExportSettingsConfig',
  components: { CoozzyFormSelect, CoozzyFormInput, CoozzySpinner, CoozzyButton, CoozzyInfoCircleIcon },
  mixins: [user],
  props: {
    platform: {
      type: String,
      default: ''
    },
    exportConfig: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      newExport: !this.exportConfig,
      credentials: {
        hostname: '',
        port: '21',
        username: '',
        password: ''
      },
      agencyId: '',
      displayPreset: false,
      isPasswordChanged: false,
      preset: 'UNDEFINED_PRESET'
    }
  },
  computed: {
    readOnly() {
      return !this.isCompanyAdmin
    }
  },
  watch: {
    'credentials.password': function () {
      this.isPasswordChanged = true
    },
    exportConfig: function () {
      this.showExport()
    }
  },
  mounted() {
    this.showExport()
  },
  methods: {
    showExport() {
      this.credentials = {
        hostname: '',
        port: '21',
        username: '',
        password: ''
      }
      this.agencyId = ''

      if (this.exportConfig) {
        this.loading = true
        this.newExport = false
        this.agencyId = this.exportConfig.agencyId
        this.displayPreset = this.exportConfig.targets.length > 0
        this.preset = this.exportConfig.targets[0] ? this.exportConfig.targets[0].preset || 'FULL_PRESET' : 'FULL_PRESET'

        ExportApi.getCredential(this.exportConfig.credentialsId)
          .then(response => {
            this.credentials = response.credentials
            this.credentials.password = 'cz-dummy-pw'
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.exports'), { type: 'error' })
          })
          .then(() => {
            this.loading = false
            this.isPasswordChanged = false
          })
      } else {
        this.newExport = true
        this.displayPreset = false
      }
    },
    deleteExportData() {
      ExportApi.deleteExport(this.exportConfig.id)
        .then(() => {
          this.credentials = {
            hostname: '',
            port: '21',
            username: '',
            password: ''
          }
          this.agencyId = ''
        })
        .catch(e => {
        })
    },
    saveExport() {
      this.$v.credentials.$touch()
      if (!this.$v.credentials.$invalid) {
        this.loading = true

        if (this.newExport) {
          // Creating new export
          // Creating new credential
          ExportApi.createCredential(
            this.credentials.hostname,
            this.credentials.port,
            this.credentials.username,
            this.credentials.password
          ).then((credentials) => {
            // Creating new export
            ExportApi.createIdxExport(
              this.$store.getters['user/getAccountId'],
              this.platform,
              credentials.credentialsId,
              this.agencyId
            ).then((response) => {
              this.$emit('export-saved', response.export)
              this.$v.$reset()
            }).catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.export'), { type: 'error' })
            }).then(() => {
              this.loading = false
            })
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.export'), { type: 'error' })
          })
          // Creating new export
        } else {
          // Updating credentials
          const credentials = ExportApi.updateCredentials(
            this.credentials.id,
            this.credentials.hostname,
            this.credentials.port,
            this.credentials.username,
            this.credentials.password,
            this.credentials.password !== 'cz-dummy-pw'
          )
          const agency = ExportApi.updateAgencyId(this.exportConfig.id, this.agencyId)
          const preset = ExportApi.updatePreset(this.exportConfig.id, this.preset)

          Promise.all([credentials, agency, preset])
            .then(() => {
              this.$v.$reset()
              this.isPasswordChanged = false
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.export'), { type: 'error' })
            })
            .finally(() => {
              this.loading = false
              this.isPasswordChanged = false
            })
        }
      }
    },
    startExport() {
      ExportApi.startExport(this.exportConfig.id)
        .then(() => {
          Vue.toasted.show(this.$t('message.export.started'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.export.startingFailed'), { type: 'error' })
        })
    }
  },
  validations: {
    credentials: {
      hostname: {
        required,
        minLength: minLength(4)
      },
      port: {
        required,
        numeric
      },
      username: {
        required
      },
      password: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h4 {
  display: inline;
}
</style>
