<template>
  <div
    v-if="mailList.length > 0 || inOutfilter !== 'FILTER_FOLDER_ALL' || hasEmails"
    id="message-container"
    class="col-12 col-md-12">
    <coozzy-form-input
      ref="searchText"
      v-model="searchText"
      class="mb-3"
      :placeholder="$t('message.generic.search')"
      @keyup="searchEmails()" />
    <coozzy-detail-card
      :title="$t('message.marketingMessages.messages') + ' (' + totalMailsCount + ')'"
      :is-sortable="true"
      @onSort="updateSorting">
      <div
        class="col p-0"
        @mousemove="onMouseMove($event)">
        <b-table
          ref="mailsTable"
          bordered
          hover
          responsive="true"
          stacked="md"
          class="m-0"
          selectable
          :fields="fieldsMails"
          :items="countMails <= 5 || showAllMails ? mailList : mailList.slice(0,5)"
          :tbody-tr-class="tableRowClass"
          @row-clicked="showEmail"
          @row-hovered="onRowHovredMessage"
          @row-unhovered="onRowUnHovredMessage">
          <!-- Busy state -->
          <div
            slot="table-busy"
            class="text-center text-danger my-2">
            <coozzy-spinner />
          </div>

          <!-- Headings -->
          <template #head(icons) />
          <template #head(date)>
            {{ $t('message.marketingMessages.mail.date') }}
          </template>
          <template #head(subject)>
            {{ $t('message.marketingMessages.mail.subject') }}
          </template>
          <template #head(email)>
            {{ $t('message.marketingMessages.mail.email') }}
          </template>
          <template #head(from)>
            {{ $t('message.marketingMessages.mail.mailbox') }}
          </template>
          <template #head(receivedOpened)>
            {{ $t('message.tableColumnHeadings.receivedOpened') }}
          </template>

          <!-- Columns -->
          <template
            #cell(icons)="data">
            <coozzy-reply-icon
              v-if="data.item.hasReply"
              class="mr-1 ml-1" />
            <coozzy-forward-icon
              v-if="data.item.hasForwarding"
              class="mr-1 ml-1" />
            <coozzy-confidential-icon
              v-if="data.item.confidential"
              class="mr-1 ml-1" />
          </template>
          <template
            #cell(date)="data">
            <span>{{ data.item.timestamp | timestampToDateTime }}</span>
          </template>
          <template
            #cell(subject)="data">
            <p class="mb-0 fieldToShow">
              <coozzy-caret-up-icon
                v-if="mailboxOutboundEmails.includes(data.item.from.email)"
                class="d-inline" />
              <coozzy-caret-down-icon
                v-else
                class="d-inline" />
              {{ data.item.subject | displayOptionalValue }}
              <coozzy-paper-clip-icon v-if="data.item.attachmentMediaIds.length > 0" />
            </p>
          </template>
          <template #cell(email)="data">
            <span class="fieldToShow">
              <template v-if="getParticipatingEmails(data.item).length > 0 && !getParticipatingEmails(data.item)[0].email.startsWith(('ticket_'))">
                <span v-if="getParticipatingEmails(data.item)[0].name !== ''">
                  {{ getParticipatingEmails(data.item)[0].name }}
                  <br>
                </span>
                <span>
                  {{ getParticipatingEmails(data.item)[0].email }}
                </span>
                <template v-if="getParticipatingEmails(data.item).length > 1">
                  <br>
                  <span>...</span>
                </template>
              </template>
              <template v-else>
                -
              </template>
            </span>
          </template>
          <template #cell(from)="data">
            <span class="fieldToShow">
              <template v-if="data.item.to && data.item.to.length && data.item.to[0].email && data.item.to[0].email.startsWith('req_')">
                <template v-if="resolveMarketplace(marketplace) !== '-'">
                  {{ $t('message.marketingMessages.requestFrom') }} {{ resolveMarketplace(marketplace) }}
                </template>
                <template v-else>
                  {{ $t('message.marketingMessages.requestFromGeneric') }}
                </template>
              </template>
              <template v-else-if="getParticipatingMailbox(data.item)">
                {{ getParticipatingMailbox(data.item).name }}
                <br>
                {{ getParticipatingMailbox(data.item).outboundEmail }}
              </template>
              <template v-else>
                -
              </template>
            </span>
          </template>
          <template #cell(receivedOpened)="data">
            <div
              v-if="mailboxOutboundEmails.includes(data.item.from.email)"
              class="text-center">
              <template v-if="data.item.deliverData.status === 'DELIVER_STATUS_DELIVERED'">
                <coozzy-success-icon />
              </template>
              <template v-else-if="data.item.deliverData.status === 'DELIVER_STATUS_PENDING'">
                <coozzy-pending-icon />
              </template>
              <template v-else>
                <coozzy-fail-icon
                  v-b-tooltip.hover.left
                  :title="data.item.deliverData.errorMessage" />
              </template>
              /
              <template v-if="data.item.engagementData.status === 'ENGAGEMENT_STATUS_OPENED'">
                <coozzy-success-icon />
              </template>
              <template v-else>
                <coozzy-fail-icon />
              </template>
            </div>
            <div
              v-else
              class="text-center">
              -
            </div>
          </template>
        </b-table>
        <div class="text-center">
          <a
            v-if="countMails > 5 || totalPageMailCount > 1"
            class="link"
            @click="showAllMails = !showAllMails">
            <coozzy-angle-double-up-icon v-if="showAllMails === true" />
            <coozzy-angle-double-down-icon v-else />
          </a>
        </div>
      </div>
    </coozzy-detail-card>
    <div
      v-if="showPopoverMessage"
      id="eventPopover"
      class="eventPopover"
      :style="{ left: page.left + 'px', top: page.top + 'px'}">
      <div
        v-if="selectedPopOverMessage"
        class="row">
        <div class="employees-calendar shadow py-3 px-4 mt-3">
          <div class="row">
            <div class="col-12 mb-1">
              <p><b>{{ selectedPopOverMessage.subject }}</b></p>
              <!-- eslint-disable vue/no-v-html -->
              <span
                style="white-space: pre-line"
                v-html="selectedPopOverMessage.bodyText || selectedPopOverMessage.bodyHtml" />
              <!-- eslint-enable vue/no-v-html -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { user } from '@/mixins/user'
import { mail } from '@/mixins/mail'
import CoozzyCaretUpIcon from '@/framework/components/icons/CoozzyCaretUpIcon'
import CoozzyCaretDownIcon from '@/framework/components/icons/CoozzyCaretDownIcon'
import CoozzyForwardIcon from '@/framework/components/icons/CoozzyForwardIcon'
import CoozzyAngleDoubleUpIcon from '@/framework/components/icons/CoozzyAngleDoubleUpIcon'
import CoozzyAngleDoubleDownIcon from '@/framework/components/icons/CoozzyAngleDoubleDownIcon'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyPaperClipIcon from '../framework/components/icons/CoozzyPaperClipIcon'
import { request } from '@/mixins/request'
import { responsivity } from '@/mixins/responsivity'
import CoozzyReplyIcon from '@/framework/components/icons/CoozzyReplyIcon.vue'
import CoozzyConfidentialIcon from '@/framework/components/icons/CoozzyConfidentialIcon.vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'

export default {
  name: 'SmallMessagesTable',
  components: { CoozzyFormInput, CoozzyConfidentialIcon, CoozzyReplyIcon, CoozzySpinner, CoozzyDetailCard, CoozzyAngleDoubleDownIcon, CoozzyAngleDoubleUpIcon, CoozzyCaretDownIcon, CoozzyCaretUpIcon, CoozzyPaperClipIcon, CoozzyForwardIcon },
  mixins: [request, user, mail, responsivity],
  props: {
    mailList: {
      type: Array,
      default() {
        return []
      }
    },
    totalPageMailCount: {
      type: Number,
      default: null
    },
    totalMailsCount: {
      type: Number,
      default: null
    },
    hasEmails: {
      type: Boolean,
      default: false
    },
    contact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showAllMails: false,
      inOutfilter: 'FILTER_FOLDER_ALL',
      isWaiting: null,
      fieldsMails: [
        {
          key: 'icons',
          label: '',
          tdClass: 'align-middle text-center adjustWidth',
          thClass: 'align-middle text-center adjustWidth'
        },
        {
          key: 'date',
          label: this.$t('message.marketingMessages.mail.date'),
          tdClass: 'align-middle dateWidth'
        },
        {
          key: 'subject',
          label: this.$t('message.marketingMessages.mail.subject'),
          tdClass: 'align-middle fieldToShow subjectWidth'
        },
        {
          key: 'email',
          label: this.$t('message.marketingMessages.mail.email'),
          tdClass: 'align-middle emailWidth'
        },
        {
          key: 'from',
          label: this.$t('message.marketingMessages.mail.mailbox'),
          tdClass: 'align-middle fieldToShow fromEmail'
        },
        {
          key: 'receivedOpened',
          label: this.$t('message.tableColumnHeadings.receivedOpened'),
          tdClass: 'align-middle receivedOpenedWidth'
        }
      ],
      showPopoverMessage: false,
      selectedPopOverMessage: null,
      page: {
        left: 0,
        top: 0
      },
      alreadyLoaded: false,
      searchText: ''
    }
  },
  computed: {
    ...mapState('request', ['marketplace']),
    ...mapGetters('message', ['getSentMails', 'getMailboxesForAccountId']),
    mailboxOutboundEmails() {
      return this.getMailboxesForAccountId(this.accountId).map(mailbox => mailbox.outboundEmail)
    },
    mailboxes() {
      return this.getMailboxesForUserId(this.userId)
    },
    countMails() {
      return this.mailList.length
    }
  },
  watch: {
    getSentMails: function (newVal) {
      if (newVal.length > 0) {
        this.loadMailboxesForAccountId(this.accountId)
      }
    },
    showAllMails: function (newVal) {
      if (newVal) {
        this.$emit('search-emails', this.inOutfilter, this.mailboxes.map(x => x.id), true, this.searchText)
      }
    }
  },
  mounted: function () {
    this.loadMailboxesForAccountId(this.accountId)
  },
  methods: {
    ...mapActions('message', ['loadMailboxesForAccountId']),
    getEmail(email) {
      if (email.to.length > 0) {
        const mappedToEmails = email.to.map(to => to.email)
        if (mappedToEmails.includes(this.contact.email) || (this.contact.workEmail && this.contact.workEmail.use && mappedToEmails.includes(this.contact.workEmail.email))) {
          return email.from.email
        }
        if (email.from?.email === this.contact.email || (this.contact.workEmail && this.contact.workEmail.use && email.from?.email === this.contact.workEmail.email)) {
          return email.to.map(to => to.email).join(',')
        }
      }
      return '-'
    },
    searchEmails() {
      this.$emit('search-emails', this.inOutfilter, this.mailboxes.map(x => x.id), this.showAllMails, this.searchText)
    },
    getParticipatingMailbox(mail) {
      for (const mailbox of this.getMailboxesForAccountId(this.accountId)) {
        if (mailbox.outboundEmail === mail.from.email) {
          return mailbox
        }
        if (mail.to.map(to => to.email).join(',').includes(mailbox.outboundEmail) || mail.cc.map(cc => cc.email).join(',').includes(mailbox.outboundEmail) || mail.bcc.map(bcc => bcc.email).join(',').includes(mailbox.outboundEmail)) {
          return mailbox
        }
      }

      return {
        name: 'n/a',
        outboundEmail: ''
      }
    },
    getParticipatingEmails(mail) {
      for (const mailbox of this.getMailboxesForAccountId(this.accountId)) {
        if (mailbox.outboundEmail === mail.from.email) {
          return mail.to
        }
        if (mail.to.map(to => to.email).join(',').includes(mailbox.outboundEmail)) {
          return [mail.from]
        }
      }

      return {
        name: '',
        email: ''
      }
    },
    getMessageText(bodyText) {
      if (bodyText) {
        return this.getNWordsFromString(bodyText, 30).trim()
      }
    },
    updateSorting(data) {
      if (data === 'asc') {
        this.inOutfilter = 'FILTER_FOLDER_OUTBOUND'
      } else if (data === 'desc') {
        this.inOutfilter = 'FILTER_FOLDER_INBOUND'
      } else if (data === 'archived') {
        this.inOutfilter = 'FILTER_FOLDER_ARCHIVED'
      } else {
        this.inOutfilter = 'FILTER_FOLDER_ALL'
      }
      this.showAllMails = false
      this.$emit('search-emails', this.inOutfilter, this.mailboxes.map(x => x.id), this.showAllMails, this.searchText)
    },
    showEmail(item) {
      this.$emit('show-email', item)
    },
    onRowHovredMessage(item) {
      this.selectedPopOverMessage = item
    },
    onRowUnHovredMessage() {
      this.selectedPopOverMessage = null
    },
    onMouseMove(e) {
      this.showPopoverMessage = this.hasSomeParentTheClass(e.srcElement, 'fieldToShow')
      if (this.showPopoverMessage && document.getElementById('eventPopover')) {
        const tooltipWidth = document.getElementById('eventPopover').clientWidth
        const tooltipHeight = document.getElementById('eventPopover').clientHeight
        const windowWidth = window.innerWidth
        const windowHeight = document.body.clientHeight
        if ((windowWidth - 100) >= (tooltipWidth + e.pageX)) {
          this.page.left = e.layerX + 25
        } else {
          this.page.left = e.layerX - tooltipWidth + 25
        }
        if ((windowHeight - 100) >= (tooltipHeight + e.pageY)) {
          this.page.top = e.layerY + 25
        } else {
          this.page.top = e.layerY - tooltipHeight + 25
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.adjustWidth) {
max-width: 55px;
padding-right: 0 !important;
  > div {
  max-width: 50px;
  }
}
.eventPopover {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  width: 270px !important;
  .employees-calendar{
    width: 100%;
    border-radius: 25px;
    background: #ededed;
    color: black;
    font-size: 14px;
    max-width: 500px;
    h4 {
      font-size: 20px;
    }
    span {
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 10;
      -webkit-line-clamp: 10; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}
:deep(.dateWidth) {
  width: 12%;
}
:deep(.subjectWidth) {
  width: 20%;
}
:deep(.emailWidth, .fromEmail) {
  width: 30%;
}
:deep(.receivedOpenedWidth) {
  width: 10%;
}
</style>
