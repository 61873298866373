<template>
  <b-form-radio
    v-model="localModel"
    :value="selectValue"
    v-bind="$attrs"
    class="custom-radio"
    v-on="mountEventListeners">
    <slot />
  </b-form-radio>
</template>

<script>
export default {
  name: 'CoozzyFormRadio',
  inheritAttrs: false,
  props: {
    selectValue: {
      type: [String, Boolean],
      default: ''
    },
    defaultModel: {
      type: [String, Boolean],
      default: '' || false

    }
  },
  data() {
    return {
      localModel: this.defaultModel // create a local copy of defaultModel
    }
  },
  computed: {
    mountEventListeners() {
      const { change, ...listeners } = this.$listeners

      return Object.assign({},
        listeners,
        {
          change: (event) => {
            this.$emit('input', this.selectValue)
          }
        }
      )
    }
  },
  watch: {
    defaultModel(newVal) {
      this.localModel = newVal // update localModel when defaultModel prop changes
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.custom-control-input:checked ~ .custom-control-label::before) {
    color: #fff;
    border-color: $color-primary;
    background-color: $color-primary;
  }
</style>

<style lang="scss" scoped>

  .custom-radio.likeBtn {
    input {
      display: none;
    }
    label:before {
      display: none;
    }
    label {
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      height: 31px;
      width: 100%;
      box-shadow: none !important;
      color: $color-text-grey-dark !important;
      background-color: $color-bg-grey-dark !important;
      border-color: $color-bg-grey-dark !important;
      margin-right: 20px;
      display: inline-block;
    }
    input:checked + label {
      color: $color-text-white !important;
      background-color: $color-primary !important;
      border-color: $color-primary !important;
    }
    input:disabled + label {
      opacity: .65;
    }
  }
</style>
