<template>
  <div>
    <div
      v-for="(object, index) in selectedObjectToProcess"
      :key="object.id">
      <enter-termination-review-object
        ref="enter-termination-details-tenant-items"
        :object="object"
        :can-copy-data="index === 0 && selectedObjectToProcess.length > 1"
        :default-select-contact="listContacts"
        @selected-entries-review="itemSelectedReview"
        @copy-data-from-first-review="copyDataFromFirstReview" />
      <hr v-if="index !== selectedObjectToProcess.length - 1">
    </div>
  </div>
</template>

<script>
import EnterTerminationReviewObject from '@/enterTermination/component/EnterTerminationReviewObject'

export default {
  name: 'EnterTerminationReview',
  components: {
    EnterTerminationReviewObject
  },
  props: {
    selectedObjectToProcess: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedEntriesReview: [],
      listContacts: []
    }
  },
  methods: {
    itemSelectedReview(status, objectId) {
      this.selectedEntriesReview[objectId] = status
      this.$emit('selected-entries-review', this.selectedEntriesReview)
    },
    copyDataFromFirstReview(objectId, contacts) {
      this.listContacts = contacts
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
