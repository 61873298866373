import { render, staticRenderFns } from "./OwnerNetworkPartnerView.vue?vue&type=template&id=fbb4d5ca&scoped=true"
import script from "./OwnerNetworkPartnerView.vue?vue&type=script&lang=js"
export * from "./OwnerNetworkPartnerView.vue?vue&type=script&lang=js"
import style0 from "./OwnerNetworkPartnerView.vue?vue&type=style&index=0&id=fbb4d5ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbb4d5ca",
  null
  
)

export default component.exports