import { render, staticRenderFns } from "./CoozzySettingIcon.vue?vue&type=template&id=673da40b&scoped=true"
import script from "./CoozzySettingIcon.vue?vue&type=script&lang=js"
export * from "./CoozzySettingIcon.vue?vue&type=script&lang=js"
import style0 from "./CoozzySettingIcon.vue?vue&type=style&index=0&id=673da40b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "673da40b",
  null
  
)

export default component.exports