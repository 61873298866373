<template>
  <coozzy-card :title="$t('message.settings.notifications')">
    <div
      v-if="loading"
      style="height: 30px">
      <coozzy-spinner />
    </div>
    <div v-else>
      <div class="col-12 mt-3">
        <b-card-sub-title class="mb-3">
          {{ $t('message.settings.notification.notifiedMethod.label') }}
        </b-card-sub-title>
        <coozzy-form-select
          v-model="notifiedMethod"
          name="notifiedMethod"
          class="w-25">
          <option value="">
            -
          </option>
          <option value="NOTIFICATION_CHANNEL_EMAIL">
            {{ $t('message.settings.notification.notifiedMethod.email') }}
          </option>
          <option value="NOTIFICATION_CHANNEL_PUSH">
            {{ $t('message.settings.notification.notifiedMethod.module') }}
          </option>
          <option value="emailAndModule">
            {{ $t('message.settings.notification.notifiedMethod.emailAndModule') }}
          </option>
        </coozzy-form-select>
      </div>
      <div
        class="col-12 mt-3">
        <b-card-sub-title class="mb-3">
          {{ $t('message.settings.notification.notificationsReceived.label') }}
        </b-card-sub-title>
        <coozzy-form-checkbox
          v-model="ticketNotifications.created"
          :initial="ticketNotifications.created">
          {{ $t('message.settings.notification.notificationsReceived.ticketCreated') }}
        </coozzy-form-checkbox>
        <coozzy-form-checkbox
          v-model="ticketNotifications.deleted"
          :initial="ticketNotifications.deleted">
          {{ $t('message.settings.notification.notificationsReceived.ticketDeleted') }}
        </coozzy-form-checkbox>
        <coozzy-form-checkbox
          v-model="ticketNotifications.assigned"
          :initial="ticketNotifications.assigned">
          {{ $t('message.settings.notification.notificationsReceived.ticketResponsible') }}
        </coozzy-form-checkbox>
        <coozzy-form-checkbox
          v-model="ticketNotifications.statusChanged"
          :initial="ticketNotifications.statusChanged">
          {{ $t('message.settings.notification.notificationsReceived.statusChange') }}
        </coozzy-form-checkbox>
        <coozzy-form-checkbox
          v-model="ticketNotifications.comments"
          :initial="ticketNotifications.comments">
          {{ $t('message.settings.notification.notificationsReceived.newComments') }}
        </coozzy-form-checkbox>
        <coozzy-form-checkbox
          v-model="ticketNotifications.updated"
          :initial="ticketNotifications.updated">
          {{ $t('message.settings.notification.notificationsReceived.newMention') }}
        </coozzy-form-checkbox>
      </div>
      <div class="col">
        <coozzy-button
          class="float-right mt-3"
          design="green"
          @click="saveSettings">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </div>
  </coozzy-card>
</template>
<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import UserApi from '../../misc/apis/UserApi'
import Vue from 'vue'
import store from '../../store/store'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyButton from '@/framework/components/button/CoozzyButton'

export default {
  name: 'NotificationsSettings',
  components: {
    CoozzyButton,
    CoozzySpinner,
    CoozzyFormSelect,
    CoozzyFormCheckbox,
    CoozzyCard
  },
  data() {
    return {
      notifiedMethod: '',
      loading: false,
      ticketNotifications: {
        assigned: false,
        created: false,
        comments: false,
        statusChanged: false,
        updated: false,
        deleted: false
      }
    }
  },
  mounted: function () {
    this.loading = true
    UserApi.getNotificationSettings(store.getters['user/getUserId'])
      .then(response => {
        if (response.settings.channels.includes('NOTIFICATION_CHANNEL_EMAIL') && response.settings.channels.includes('NOTIFICATION_CHANNEL_PUSH')) {
          this.notifiedMethod = 'emailAndModule'
        } else {
          this.notifiedMethod = response.settings.channels.length > 0 ? response.settings.channels[0] : ''
        }
        this.ticketNotifications = response.settings.ticketNotifications
        this.loading = false
      })
      .catch(e => {
        console.log(e)
        this.loading = false
        Vue.toasted.show(this.$t('message.loadingErrors.accountSettings'), { type: 'error' })
      })
  },
  methods: {
    saveSettings() {
      this.loading = true
      const notificationMethod = []
      if (this.notifiedMethod === 'emailAndModule') {
        notificationMethod.push('NOTIFICATION_CHANNEL_EMAIL')
        notificationMethod.push('NOTIFICATION_CHANNEL_PUSH')
      } else {
        notificationMethod.push(this.notifiedMethod)
      }
      UserApi.postNotificationSettings(notificationMethod, this.ticketNotifications, store.getters['user/getUserId'])
        .then(() => {
          this.loading = false
          Vue.toasted.show(this.$t('message.successMessages.notificationUpdated'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.savingErrors.accountSettings'), { type: 'error' })
        })
    }
  }
}
</script>
