<template>
  <coozzy-card
    :class="fullHeight ? 'global-card h-100 test' : 'global-card'"
    v-bind="$attrs"
    v-on="mountEventListeners">
    <h6 class="title-card bg-prop-blue">
      {{ title }}
      <a
        v-if="linkActivity"
        tabindex="0"
        @click="$emit('goToActivities')"
        @click.middle="$emit('goToActivitiesMiddle')">
        <img
          height="15px"
          class="ml-3 mb-1"
          :src="`/img/ticket/new-window.svg`"
          alt="type">
        {{ $t('message.ticketOverview.activities.title') }}
      </a>
      <coozzy-button
        v-if="isDataDifferente"
        size="small"
        class="float-right mb-2 for-triangle-icon">
        <coozzy-matching-popover
          v-if="isDataDifferente"
          :value="textUpdated"
          @accepted="isAcceptable" />
      </coozzy-button>
      <template
        v-if="isSortable">
        <div class="pl-1 d-inline float-right">
          <a
            class="shadow-none ml-3 font-weight-normal d-inline"
            :class="sort === '' ? 'active' : ''"
            @click="updateSorting('')">
            {{ $t('message.marketingMessages.folderFilter.all') }}
          </a>
          <a
            class="shadow-none ml-3 font-weight-normal d-inline"
            :class="sort === 'desc' ? 'active' : ''"
            @click="updateSorting('desc')">
            {{ $t('message.marketingMessages.folderFilter.inbound') }}
          </a>
          <a
            class="shadow-none ml-3 font-weight-normal d-inline"
            :class="sort === 'asc' ? 'active' : ''"
            @click="updateSorting('asc')">
            {{ $t('message.marketingMessages.folderFilter.outbound') }}
          </a>
          <a
            class="shadow-none mx-3 font-weight-normal d-inline"
            :class="sort === 'archived' ? 'active' : ''"
            @click="updateSorting('archived')">
            {{ $t('message.marketingMessages.folderFilter.archive') }}
          </a>
        </div>
      </template>
      <template
        v-if="isAdjustable">
        <div class="pl-1 d-inline float-right">
          <a
            class="shadow-none mx-3 font-weight-normal d-inline"
            :class="sort === '' ? 'active' : ''"
            @click="adjustFields">
            <coozzy-filters-icon />
          </a>
        </div>
      </template>
      <template
        v-if="downloadAll">
        <div class="pl-1 d-inline float-right">
          <span
            class="link"
            @click="downloadAllDocuments">{{ $t('message.marketingMessages.mail.downloadAll') }} <coozzy-file-download-icon /></span>
        </div>
      </template>
      <coozzy-button
        v-if="isEditable"
        design="transparent"
        class="float-right shadow-none"
        @click="editModal">
        <coozzy-edit-icon />
      </coozzy-button>
      <coozzy-button
        v-if="isLinked"
        design="transparent"
        class="float-right shadow-none"
        @click="linkedModal">
        <coozzy-unlink-icon />
      </coozzy-button>
      <coozzy-button
        v-if="isDeleted"
        design="transparent"
        class="float-right shadow-none"
        @click="deleteItem">
        <coozzy-delete-icon />
      </coozzy-button>
    </h6>
    <div v-if="isNormal">
      <slot />
    </div>
    <div
      v-else
      class="d-flex interne">
      <slot />
    </div>
  </coozzy-card>
</template>

<script>
import CoozzyUnlinkIcon from '@/framework/components/icons/CoozzyUnlinkIcon'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon.vue'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'
import CoozzyMatchingPopover from '@/framework/components/popover/CoozzyMatchingPopover.vue'

export default {
  name: 'CoozzyDetailCard',
  components: { CoozzyMatchingPopover, CoozzyFiltersIcon, CoozzyFileDownloadIcon, CoozzyEditIcon, CoozzyButton, CoozzyCard, CoozzyUnlinkIcon, CoozzyDeleteIcon },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: ''
    },
    linkActivity: {
      type: Boolean,
      default: false
    },
    isDataDifferente: {
      type: Boolean,
      default: false
    },
    textUpdated: {
      type: String,
      default: ''
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isLinked: {
      type: Boolean,
      default: false
    },
    isDeleted: {
      type: Boolean,
      default: false
    },
    isSortable: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    isNormal: {
      type: Boolean,
      default: false
    },
    isAdjustable: {
      type: Boolean,
      default: false
    },
    downloadAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sort: ''
    }
  },
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  },
  methods: {
    editModal() {
      this.$emit('onEdit')
    },
    isAcceptable() {
      this.$emit('onAccept')
    },
    linkedModal() {
      this.$emit('onUnlink')
    },
    deleteItem() {
      this.$emit('onDelete')
    },
    updateSorting(data) {
      this.sort = data
      this.$emit('onSort', this.sort)
    },
    adjustFields() {
      this.$emit('adjust-fields')
    },
    downloadAllDocuments() {
      this.$emit('download-all-documents')
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.card-body) {
    padding: 0 !important;
    box-shadow: 1px 1px 6px #ccc;
    border-radius: 0.2rem !important;
  }

  .interne {
    width: 100%;

    .card {
      margin-bottom: 0 !important;
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
    }

    .card-body {
      padding: 1.25rem !important;
    }
  }

  .title-card {
    font-weight: bold !important;
    padding: 0 0.4rem;
    margin-bottom: 0;
    line-height: 2rem;
    border-bottom: 1px solid $color-table-border;
  }
  .sortTitleDetailCard{
    display: inline;
  }
  .active{
    font-weight: bold !important;
  }
  .for-triangle-icon {
    position: absolute;
    background-color: initial !important;
    border: 0;
    box-shadow: none;
    &:hover {
      box-shadow: none !important;
    }
  }
</style>
