<template>
  <section>
    <div class="body-of-child">
      <div class="row about-us-Page">
        <div class="w-100">
          <div class="section white-bg">
            <div class="my-5">
              <div class="sub-section part-top">
                <div class="content-wrap">
                  <div class="header-index-block mb-4">
                    <h1 class="mb-4">
                      {{ $t('message.staticPages.aboutUs.title') }}
                    </h1>
                    <h4><b>{{ $t('message.staticPages.aboutUs.partone.text1') }}</b></h4>
                    <h4 class="mb-4">
                      {{ $t('message.staticPages.aboutUs.partone.text2') }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section bg-green">
            <div>
              <div class="sub-section">
                <div class="content-wrap">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 col-sm-4 col-lg-4">
                      <div class="card img-onhover">
                        <img
                          class="icon basic"
                          src="@/assets/aboutus/rocket_small_icon.svg"
                          alt="">
                        <img
                          class="icon hover"
                          src="@/assets/aboutus/rocket_small_icon_hover.svg"
                          alt="">
                        <p class="date">
                          {{ $t('message.staticPages.aboutUs.parttwo.number1') }}
                        </p>
                        <h3 class="title">
                          {{ $t('message.staticPages.aboutUs.parttwo.subtitle1') }}
                        </h3>
                        <p class="text">
                          {{ $t('message.staticPages.aboutUs.parttwo.text1') }}
                        </p>
                        <span class="border" />
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 col-sm-4 col-lg-4">
                      <div class="card img-onhover">
                        <img
                          class="icon basic"
                          src="@/assets/aboutus/Mitarbeiter_icons.svg"
                          alt="">
                        <img
                          class="icon hover"
                          src="@/assets/aboutus/Mitarbeiter_icons_hover.svg"
                          alt="">
                        <p class="date">
                          {{ $t('message.staticPages.aboutUs.parttwo.number2') }}
                        </p>
                        <h3 class="title">
                          {{ $t('message.staticPages.aboutUs.parttwo.subtitle2') }}
                        </h3>
                        <p class="text">
                          {{ $t('message.staticPages.aboutUs.parttwo.text2') }}
                        </p>
                        <span class="border" />
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 col-sm-4 col-lg-4">
                      <div class="card img-onhover">
                        <img
                          class="icon basic"
                          src="@/assets/aboutus/coffee_icon.svg"
                          alt="">
                        <img
                          class="icon hover"
                          src="@/assets/aboutus/coffee_icon_hover.svg"
                          alt="">
                        <p class="date">
                          {{ $t('message.staticPages.aboutUs.parttwo.number3') }}
                        </p>
                        <h3 class="title">
                          {{ $t('message.staticPages.aboutUs.parttwo.subtitle3') }}
                        </h3>
                        <p class="text">
                          {{ $t('message.staticPages.aboutUs.parttwo.text3') }}
                        </p>
                        <span class="border" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div>
              <div class="sub-section">
                <div class="content-wrap">
                  <div class="header-index-block mb-2">
                    <h1 class="mt-3">
                      {{ $t('message.staticPages.aboutUs.partthree.title') }}
                    </h1>
                  </div>
                  <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mt-4">
                    <div class="row block">
                      <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6">
                        <h3 class="title-first">
                          {{ $t('message.staticPages.aboutUs.partthree.subtitle') }}
                        </h3>
                        <!-- eslint-disable vue/no-v-html -->
                        <p
                          class="text-first"
                          v-html="$t('message.staticPages.aboutUs.partthree.text1')" />
                        <!-- eslint-enable vue/no-v-html -->
                      </div>
                      <div
                        class="col-md-6 col-xs-12 col-sm-6 col-lg-6 work-together"
                        :style="{backgroundImage: 'url('+require('@/assets/aboutus/work_together.jpg')}" />
                    </div>
                    <div class="row block mt-4">
                      <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                        <div class="text-center second">
                          <img
                            class="logo"
                            src="@/assets/aboutus/icon.png">
                          <h3>{{ $t('message.staticPages.aboutUs.partfour.title') }}</h3>
                          <!-- eslint-disable vue/no-v-html -->
                          <p
                            class="text-second"
                            v-html="$t('message.staticPages.aboutUs.partfour.text')" />
                          <!-- eslint-enable vue/no-v-html -->
                        </div>
                      </div>
                    </div>
                    <div class="row block mt-4">
                      <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                        <div class="text-center third row">
                          <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 right-side">
                            <img src="@/assets/aboutus/Element14.svg">
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 left-side">
                            <!-- eslint-disable vue/no-v-html -->
                            <p
                              class=""
                              v-html="$t('message.staticPages.aboutUs.partfive.text')" />
                            <!-- eslint-enable vue/no-v-html -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row block mt-4">
                      <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                        <div class="fourth row">
                          <div class="col-md-8 col-xs-12 col-sm-8 col-lg-8 right-side">
                            <!-- eslint-disable vue/no-v-html -->
                            <h3
                              class="mt-3"
                              v-html="$t('message.staticPages.aboutUs.partsix.title')" />
                            <!-- eslint-enable vue/no-v-html -->
                            <!-- eslint-disable vue/no-v-html -->
                            <h4
                              class="mb-3"
                              v-html="$t('message.staticPages.aboutUs.partsix.text')" />
                            <!-- eslint-enable vue/no-v-html -->
                          </div>
                          <div class="col-md-4 col-xs-12 col-sm-4 col-lg-4 left-side">
                            <a
                              href="#"
                              class="btn contact-btn w-100"
                              data-toggle="modal"
                              data-target="#contactsupport">{{ $t('message.staticPages.aboutUs.contact') }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <coozzy-footer />
  </section>
</template>

<script>

import { language } from '../mixins/language'

export default {
  name: 'AboutUsView',
  mixins: [language]
}
</script>

<style lang="scss" scoped>
  .sub-title {
    color: $color-text-grey-dark;
    font-weight: bold;
    font-size: 16px;
  }
  .img-onhover {
    cursor: pointer;
    .hover {
      display: none;
    }
    &:hover,&.hover {
      .basic {
        display: none;
      }
      .hover {
        display: inline;
      }
    }
  }
  .about-us-Page {
    .section * {
      outline: none;
    }
    .section p {
      margin: 0;
    }

    .bg-gray {
      background: $color-text-grey-light;
    }

    .bg-green {
      background: $color-primary-dark !important;
    }
    .card {
      height: 200px;
      width: 200px;
      padding: 19px;
      margin: 0 auto;
      color: $color-text-white;
      background: none;
      -webkit-transition: all 0.35s linear;
      transition: all 0.35s linear;
      text-align: center;
      border: 0;
      .date {
        font-size: 28px;
      }
      p {
        &.title {
          color: inherit;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          padding: 5px;
          margin: 0;
        }
      }
      h3 {
        &.title {
          color: inherit;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          padding: 5px;
          margin: 0;
        }
      }
      .text {
        color: inherit;
        font-size: 10px;
      }
      .icon {
        width: 30px;
        height: 30px;
        margin: 0 auto;
      }
      .border {
        display: block;
        margin: 10px auto !important;
        height: 2px;
        width: 30%;
        background: $color-text-white;
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
      }
      &:hover {
        color: #677273;
        background: $color-text-white;
        .border {
          background: #000;
        }
        .date {
          color: $color-primary;
        }
      }
    }
    .section {
      width: 100%;
      padding: 15px 5px;
      margin: 0;
      min-height: 150px;
      .sub-section {
        &.part-top {
          .header-index-block {
            .h1 {
              color: #1e1e1e !important;
              font-weight: normal !important;
            }
            h4 {
              line-height: 29px;
              margin-top: 25px;
              font-size: 23px;
              font-weight: 300;
              color: $color-text-grey;
            }
          }
        }
      }
      .block {
        background-color: $color-text-white;
        .work-together {
          background-position: center center;
          background-size: cover;
          min-height: 28em;
        }
        .title-first {
          padding: 30px 25px 0 60px;
          line-height: 29px;
          font-weight: 300;
          font-size: 23px;
          color: $color-text-grey;
        }
        .text-first {
          padding: 30px 60px;
          line-height: 24px;
          color: $color-text-grey;
          font-family: 'Lato', sans-serif !important;
          font-size: 16px;
          font-weight: 300;
        }
        .second {
          padding: 25px;
          .logo {
            max-width: 50px;
          }
          h3 {
            line-height: 29px;
            font-weight: 300;
            font-size: 23px;
            color: $color-text-grey;
          }
          .text-second {
            padding: 5px 120px;
            line-height: 24px;
            color: $color-text-grey;
            font-family: 'Lato', sans-serif !important;
            font-size: 16px;
            font-weight: 300;
          }
        }
        .third {
          padding: 35px 15px;
          .rightside {
            border-right: 1px solid $color-red-dark;
            img {
              max-height: 250px;
            }
          }
          .leftside {
            p {
              padding: 80px 35px 35px;
              line-height: 24px;
              color: $color-text-grey;
              font-family: 'Lato', sans-serif !important;
              font-size: 16px;
              font-weight: 300;
            }
            i {
              position: absolute;
              left: 25px;
              top: 50px;
            }
          }
        }
        .fourth {
          padding: 5px 15px 15px;
          .right-side {
            h4 {
              line-height: 24px;
              font-weight: normal;
              font-size: 20px;
            }
          }
          .left-side {
            a {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .contact-btn {
      margin: 60px 0 25px;
      padding: 10px 30px;
      color: $color-text-white;
      background: $color-primary-dark !important;
      border: none !important;
      border-radius: 5px;
      font-size: 16px;
    }
  }
  .white-bg {
    background-color: $color-text-white;
  }
</style>
