const language = {
  props: {
    language: {
      type: String,
      default: ''
    }
  },
  mounted: function () {
    if (['de', 'en', 'fr', 'it'].includes(this.language)) {
      this.$store.dispatch('internationalization/changeCurrentLanguage', this.language).then()
    }
  },
  methods: {
    getLocale() {
      switch (this.$store.getters['internationalization/getCurrentLanguage']) {
        case 'de':
          return 'de-DE'
        case 'it':
          return 'it-IT'
        default:
          return 'en-EN'
      }
    }
  }
}

export { language }
