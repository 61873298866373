<template>
  <section>
    <div class="row">
      <div
        v-if="item === null || loading"
        class="col-12 text-center">
        <coozzy-spinner class="m-3" />
      </div>
      <div
        v-else
        class="col-12">
        <h1>
          <address-text
            :one-line="true"
            :address="item.address" />
        </h1>
        <h5
          v-if="!loading">
          <span>{{ item.number }} | </span>
          <span v-if="item.name && item.name !== ''">{{ item.name }} | </span>
          <span v-if="getObjectRoom(item) > 0">{{ formatRooms(getObjectRoom(item)) }} </span>
          <span>{{ $t('message.onBoarding.buildings.objects.category.' + item.category.toLowerCase()) }} </span>
          <span v-if="getObjectFloor(item) > -10000">{{ formatFloor(getObjectFloor(item)) }}</span>
        </h5>
        <div
          v-if="loadingMark"
          class="col-12 text-center">
          <coozzy-spinner class="m-3" />
        </div>
        <div v-else>
          <coozzy-card class="global-card">
            <object-basic
              ref="object-basic"
              :key="`basic-${item.id}`"
              :object-selected="item"
              :read-only="true"
              :from-admin="true" />
          </coozzy-card>
          <coozzy-card
            v-if="lastestTenant.length > 0"
            class="readonly-wrapper global-card mb-3">
            <div
              id="Tenant"
              v-b-toggle.coozzy-card-tenancy
              class="collapse-title">
              <h5 class="cursor-pointer">
                <template>
                  {{ $t('message.onBoarding.buildings.objects.tenancies.title') }}
                </template>
              </h5>
            </div>
            <div class="col-12 p-0">
              <b-collapse
                id="coozzy-card-tenancy"
                :visible="true">
                <template v-for="lastTenancy of lastestTenant">
                  <object-tenancy
                    v-if="lastTenancy.vacancy === false"
                    :key="'ten-'+lastTenancy.id"
                    :ref="`object-last-tenancy-${lastTenancy.id}`"
                    :index="lastTenancy.id"
                    :tenancy="lastTenancy"
                    :read-only="true"
                    :expand-by-default="false"
                    :object="object"
                    :enable-tenant-selection="false"
                    :show-delete-button="false"
                    :hide-actions="true"
                    mode="full" />
                  <object-vacancy
                    v-else
                    :key="'vac-'+lastTenancy.id"
                    :ref="`object-last-vacancy-${lastTenancy.id}`"
                    :index="lastTenancy.id"
                    :tenancy="lastTenancy"
                    :expand-by-default="false"
                    :enable-tenant-selection="false"
                    :read-only="true"
                    :show-delete-button="false"
                    mode="adding" />
                </template>
              </b-collapse>
            </div>
          </coozzy-card>
          <coozzy-card
            v-if="!editMarketingInfoMode"
            class="global-card mb-3">
            <object-pricing
              v-if="item"
              ref="object-pricing"
              :object-selected="item"
              :expand-by-default="false"
              :read-only="false"
              @update-object-pricing="updateObjectPricing" />
          </coozzy-card>
          <coozzy-card
            v-if="!editMarketingInfoMode"
            class="global-card">
            <object-advertising
              ref="object-advertising"
              :object-selected="item"
              :read-only="false"
              :expand-by-default="false"
              @update-object-advertising="updateObjectAdvertising" />
          </coozzy-card>
          <coozzy-card class="global-card">
            <template v-if="!updateMarketingInfoEdit && editMarketingInfoMode">
              <div>
                <h6 class="d-inline-block alert-heading font-weight-bold mb-0">
                  {{ $t('message.marketingObjects.startMarketing') }}:
                </h6>
                {{ startMarketingAt | timestampToDate }}
                <div class="d-inline-flex float-right">
                  <coozzy-button
                    class="mr-3 mb-3"
                    @click.native="updateMarketingInfoEdit = !updateMarketingInfoEdit">
                    <coozzy-edit-icon class="too-left" />
                    <span
                      class="ml-1">
                      {{ $t('message.marketingObjects.editNote') }}
                    </span>
                  </coozzy-button>
                </div>
              </div>
              <h6 class="alert-heading font-weight-bold mb-0 mt-2">
                {{ $t('message.marketingObjects.marketingNote') }}:
              </h6>
              <div
                v-if="marketingNote.length < 60 ">
                <!-- eslint-disable vue/no-v-html -->
                <span
                  style="white-space: pre;"
                  v-html="marketingNote" />
                  <!-- eslint-enable vue/no-v-html -->
              </div>
              <div
                v-else-if="!marketingNoteVisibility">
                <!-- eslint-disable vue/no-v-html -->
                <span
                  style="white-space: pre;"
                  v-html="marketingNote.substring(0, 20)" />...
                <!-- eslint-enable vue/no-v-html -->
                <b-link
                  @click="showMoreShowLess">
                  {{ $t('message.generic.showMore') }}
                </b-link>
              </div>
              <div
                v-else>
                <b-collapse
                  id="collapse-1"
                  v-model="marketingNoteVisibility">
                  <!-- eslint-disable vue/no-v-html -->
                  <span
                    style="white-space: pre;"
                    v-html="marketingNote" />
                  <!-- eslint-enable vue/no-v-html -->
                </b-collapse>
                <b-link
                  @click="showMoreShowLess">
                  {{ $t('message.generic.showLess') }}
                </b-link>
              </div>
            </template>
            <template v-else>
              <div class="row py-2">
                <div class="col-sm-12 col-md-4">
                  <coozzy-form-input
                    v-model="startMarketing"
                    type="date"
                    :filter="'formatDate'"
                    :name="$t('message.marketingObjects.startMarketing')"
                    @blur="dateToTimestamp()" />
                </div>
                <div class="col-sm-12 col-md-8">
                  <coozzy-form-textarea
                    ref="description"
                    v-model="marketingNote"
                    :initial="marketingNote"
                    :value-text-area="marketingNote"
                    name="marketingNote"
                    :rows="5"
                    :placeholder="$t('message.marketingObjects.marketingNote')">
                    {{ $t('message.marketingObjects.marketingNote') }}
                  </coozzy-form-textarea>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-3 offset-9">
                  <coozzy-button
                    v-if="!editMarketingInfoMode"
                    size="small"
                    class="float-right mb-0"
                    design="green"
                    @click="markForMarketing()">
                    {{ $t('message.marketingObjects.markForMarketing') }}
                  </coozzy-button>
                  <coozzy-button
                    v-else
                    size="small"
                    class="float-right mb-0"
                    design="green"
                    @click="updateMarketingInfo()">
                    {{ $t('message.generic.form.save') }}
                  </coozzy-button>
                </div>
              </div>
            </template>
          </coozzy-card>
        </div>
      </div>
    </div><b-modal
      id="confirmationModal"
      ref="confirmationModal"
      no-close-on-backdrop
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="confirmCancellation">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ObjectApi from '@/misc/apis/ObjectApi'
import ObjectBasic from '@/building/tabs/components/ObjectBasic'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import AddressText from '../framework/components/misc/AddressText'
import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import Vue from 'vue'
import ObjectPricing from '@/building/tabs/components/ObjectPricing'
import ObjectAdvertising from '@/building/tabs/components/ObjectAdvertising'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { onboarding } from '../mixins/onboarding'
import ShareApi from '@/misc/apis/ShareApi'
import UserApi from '@/misc/apis/UserApi'
import { routeChecks } from '@/mixins/routeChecks'
import ObjectVacancy from '@/building/tabs/components/ObjectVacancy'
import ObjectTenancy from '@/building/tabs/components/ObjectTenancy'

export default {
  name: 'MarkForMarketingView',
  components: {
    CoozzyButton,
    CoozzyFormTextarea,
    CoozzyFormInput,
    CoozzyCard,
    ObjectBasic,
    AddressText,
    CoozzySpinner,
    ObjectPricing,
    ObjectAdvertising,
    ObjectVacancy,
    ObjectTenancy
  },
  mixins: [onboarding, obj, formatting, routeChecks],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  beforeRouteLeave (to, from, next) {
    this.nextToPath = to
    if (!this.confirmCancellationProcess) {
      this.$refs.confirmationModal.show()
    } else {
      next()
    }
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    },
    editMarketingInfoMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      updateMarketingInfoEdit: false,
      marketingAccountName: 'n/a',
      loading: false,
      loadingMark: false,
      deposit: null,
      description: '',
      documents: [],
      images: [],
      parking: [],
      publishAddress: false,
      title: '',
      videos: [],
      virtualTourUrl: '',
      item: null,
      availableFrom: '',
      availableTo: '',
      availability: {
        availableFrom: null,
        availableTo: null,
        type: 'IMMEDIATELY'
      },
      marketingNote: '',
      pricing: {
        coldRent: -1,
        additional: -1,
        heating: -1,
        sellingPrice: -1,
        sellingPriceM2: -1,
        unit: 'ON_REQUEST'
      },
      startMarketing: '',
      startMarketingAt: '',
      c: true,
      marketingNoteVisibility: false,
      lastTenancies: [],
      confirmCancellationProcess: false,
      nextToPath: null
    }
  },
  computed: {
    lastestTenant() {
      return this.lastTenancies.filter(x => x.id !== this.tenancyId).sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
    },
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/'
    },
    prevRouteQuery() {
      return this.prevRoute ? this.prevRoute.query : null
    }
  },
  mounted() {
    this.loadObject()
    this.loadMarketingAccountName()
  },
  methods: {
    confirmCancellation() {
      this.confirmCancellationProcess = true
      if (this.nextToPath !== null) {
        this.$router.push({ name: this.nextToPath.name, params: this.nextToPath.params, query: this.nextToPath.query }, () => {
        })
      } else {
        this.$router.push({ path: this.prevRoutePath, query: this.prevRouteQuery }, () => {
        })
      }
    },
    showMoreShowLess() {
      this.marketingNoteVisibility = !this.marketingNoteVisibility
    },
    loadObject() {
      ObjectApi.getObject(this.id)
        .then(response => {
          if (!this.building) {
            ObjectApi.getBuildingByObjectId(this.id)
              .then(response => {
                this.loadOnboarding(response.building.id)
              })
          }
          this.item = response.object
          // get last tenant of object
          const current = this
          ObjectApi.getTenancies([this.item.id])
            .then(response => {
              if (response.tenancies.length > 0) {
                this.lastTenancies = [...response.tenancies.sort(function (a, b) {
                  return new Date(current.$options.filters.objectToDateInput(b.startingAt)).getTime() - new Date(current.$options.filters.objectToDateInput(a.startingAt)).getTime()
                }).slice(0, 2)]
              }
            })
          this.startMarketingAt = this.item.marketingStatus.startMarketingAt
          this.startMarketing = this.$options.filters.timestampToDateInput(this.startMarketingAt)
          this.marketingNote = this.item.marketingStatus.marketingNote
          this.availability = this.item.availability
          this.deposit = this.item.deposit
          this.description = this.item.description
          this.documents = this.item.documents
          this.images = this.item.images
          this.parking = this.item.parking
          this.pricing = this.item.pricing
          this.publishAddress = this.item.publishAddress
          this.title = this.item.title
          this.videos = this.item.videos
          this.virtualTourUrl = this.item.virtualTourUrl
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    loadMarketingAccountName() {
      // Find marketing share for object
      ShareApi.listByResource([this.id])
        .then(({ shares }) => {
          if (shares) {
            for (const share of shares) {
              if (share.scope === 'MARKETING_SCOPE') {
                // Load marketing account to set name
                UserApi.getAccount(share.targetAccountId)
                  .then(({ account }) => {
                    if (account) {
                      this.marketingAccountName = account.company
                        ? account.company.name
                        : (account.individual ? `${account.individual.firstName.substr(0, 1)}. ${account.individual.lastName}` : 'n/a')
                    }
                  })
              }
            }
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    dateToTimestamp() {
      this.startMarketingAt = this.toTimestamp(this.startMarketing)
    },
    getDatesFromTimestamps: function () {
      this.startMarketing = this.$options.filters.timestampToDateInput(this.startMarketingAt)
    },
    toTimestamp(strDate) {
      return Date.parse(strDate) / 1000
    },
    updateObjectPricing(item) {
      this.pricing = item.pricing
    },
    updateObjectAdvertising(item) {
      this.pricing = item.pricing
      this.availability = item.availability
      this.deposit = item.deposit
      this.description = item.description
      this.documents = item.documents
      this.images = item.images
      this.parking = item.parking
      this.publishAddress = item.publishAddress
      this.title = item.title
      this.videos = item.videos
      this.virtualTourUrl = item.virtualTourUrl
    },
    markForMarketing() {
      this.confirmCancellationProcess = true
      this.loadingMark = true
      ObjectApi.markForMarketing(
        this.id,
        this.availability,
        this.marketingNote,
        this.pricing,
        this.startMarketingAt,
        this.deposit,
        this.title,
        this.description,
        this.documents,
        this.images,
        this.parking,
        this.publishAddress,
        this.videos,
        this.virtualTourUrl
      ).then(() => {
        setTimeout(() => {
          this.loadingMark = false
          Vue.toasted.show(this.$t('message.successMessages.markedForMarketing', { name: this.marketingAccountName }), { type: 'success' })
          if (this.from === 'acountingBuilding') {
            this.$router.push({
              name: 'AccountingBuildingDetailsView',
              params: { id: this.item.buildingId },
              query: {
                view: 'objects',
                elementSelected: this.id
              }
            })
          } else if (this.from === 'adminBuilding') {
            this.$router.push({
              name: 'AdminBuildingDetailsView',
              params: { id: this.item.buildingId },
              query: {
                view: 'objects',
                elementSelected: this.id
              }
            })
          } else if (this.from === 'adminOpenRental') {
            this.$router.push({
              name: 'AdminMarketingObjectsView'
            })
          } else if (this.from === 'accountingOpenRental') {
            this.$router.push({
              name: 'AccountingMarketingObjectsView'
            })
          }
        }, 2000)
      }).catch(e => {
        console.log(e)
        this.loadingMark = false
        Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
      })
    },
    updateMarketingInfo() {
      this.confirmCancellationProcess = true
      ObjectApi.updateMarketingInfo(this.id, this.marketingNote, this.startMarketingAt)
        .then(response => {
          this.updateMarketingInfoEdit = false
          Vue.toasted.show(this.$t('message.successMessages.marketingNoteAdded'), { type: 'success' })
          if (this.isAccountingModule) {
            this.$router.push({
              name: 'AccountingMarketingObjectsView'
            })
          } else {
            this.$router.push({
              name: 'AdminMarketingObjectsView'
            })
          }
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

:deep(.collapse-title) {
  &.collapsed {
    h5 {
      background-image: url('../assets/arrow_down.svg');
    }
  }

  h5 {
    background-image: url('../assets/arrow_up.svg');
    background-repeat: no-repeat;
    font-size: large;
    font-weight: 500 !important;
    height: 1.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    background-position: right;
  }
}

.item {
  cursor: pointer;
}

:deep(.details-row-bg-1) {
  background-color: $color-table-row-2;
}

:deep(.details-row-bg-2) {
  background-color: $color-table-row-1;
}

:deep(label) {
  width: 100%;
}

.nav-button {

  button:not(.sort):not(.page-link) {
    display: none;
  }

  .multiselect, .custom-checkbox {
    pointer-events: none;

    .multiselect__select, .multiselect__placeholder {
      display: none;
    }
  }

  .basic {
    input {
      opacity: 1;
      border: thin solid #ced4da;
      -webkit-box-shadow: inherit;
      box-shadow: inherit;
      margin-bottom: 0;
      pointer-events: initial;
      padding-left: 0.2rem;
      background: none;
    }
  }

  .multi-select-value {
    line-height: 1.5;
  }
}
</style>
