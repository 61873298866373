import MapsApi from '@/misc/apis/MapsApi'
import { CommonaddressAddress, ZipZipEntry } from '@/types/api'
type zipInfo = {
  cities: ZipZipEntry[],
  state: string
}

const address = {
  methods: {
    getStringAddress(address: CommonaddressAddress) {
      const addr = (address.careOf ? address.careOf + '\n' : '') +
        (address.street
? address.street + (address.streetNumber ? ' ' + address.streetNumber : '') +
          (', ')
: '') +
        (address.zip ? address.zip + ' ' : '') +
        (address.city ? address.city : '') +
        (address.state ? ', ' + address.state : '') +
        ' ' + this.translatedCountry(address.country)
      return addr
    },
    translatedCountry(country: string) {
      switch (country) {
        case 'CH':
        case 'CHE':
          return this.$t('message.generic.countries.switzerland')
        case 'DEU':
          return this.$t('message.generic.countries.germany')
        case 'AUT':
          return this.$t('message.generic.countries.austria')
        case 'LIE':
          return this.$t('message.generic.countries.liechtenstein')
        default:
          return ''
      }
    },
    async getZipInfo(zip: string) {
      let zipData: zipInfo = null
      const data = await MapsApi.getZipInfo(zip)
      const list = data.entries
      if (list.length > 0) {
        zipData = { cities: list, state: list[0].state }
      }
      return zipData
    }
  }
}

export { address }
