<template>
  <div>
    <label
      v-if="!withoutLabel"
      for="country">
      {{ $t('message.generic.country') }}<template v-if="required && !isReadOnly">*</template>
    </label>
    <country-select
      v-if="!isReadOnly"
      id="country"
      v-model="country"
      v-bind="$attrs"
      :country="country"
      placeholder="-"
      top-country="CH"
      :class-name="'custom-select custom-select-sm' + (state === false ? ' is-invalid' : '')"
      v-on="$listeners" />
    <span v-else>
      {{ translateCountry(country) }}
    </span>
  </div>
</template>

<script>

import { country } from '@/mixins/country'

export default {
  name: 'CoozzyFormCountrySelect',
  mixins: [country],
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: true
    },
    initialValue: {
      type: String,
      default: 'CH'
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    },
    withoutLabel: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      country: this.initialValue || 'CH'
    }
  },
  watch: {
    initialValue: function (newVal) {
      if (newVal !== this.country) {
        this.country = this.initialValue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
