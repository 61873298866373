<template>
  <div class="row">
    <div class="col-12">
      <div class="row mb-3 mx-0 buttons-collocation">
        <coozzy-button
          :class="billingContact ? 'd-none' : ''"
          class="col-3 ml-2 mb-2"
          design="prop-green"
          @click="addClicked('BILLING_CONTACT')">
          <template>
            {{ $t('message.addTenancy.selectDebtor') }}
          </template>
        </coozzy-button>
        <coozzy-button
          :class="!billingContact ? 'd-none' : ''"
          class="col-3 ml-2 mb-2"
          design="prop-green"
          @click="addClicked('TENANT')">
          <template v-if="tenants.length === 0">
            {{ $t('message.addTenancy.selectTenant') }}
          </template>
          <template v-else>
            {{ $t('message.addTenancy.addAnotherTenant') }}
          </template>
        </coozzy-button>
        <coozzy-button
          :class="[correspondenceContact ? 'd-none' : '']"
          class="col-3 ml-2 mb-2"
          design="prop-green"
          @click="addClicked('CORRESPONDENCE_CONTACT')">
          <template>
            {{ $t('message.addTenancy.selectCorrespondenceAddress') }}
          </template>
        </coozzy-button>
        <coozzy-button
          class="col-3 ml-2 mb-2"
          design="prop-green"
          @click="addClicked('GUARANTOR_CONTACT')">
          <template v-if="guarantorContacts.length === 0">
            {{ $t('message.addTenancy.selectGuarantor') }}
          </template>
          <template v-else>
            {{ $t('message.addTenancy.addAnotherGuarantor') }}
          </template>
        </coozzy-button>
      </div>
    </div>
    <div
      v-if="!billingContact"
      class="col-12 mt-3">
      <coozzy-alert>
        {{ $t('message.addTenancy.noDebtor') }}
      </coozzy-alert>
    </div>    <div
      v-if="billingContact && tenants.length === 0"
      class="col-12 mt-3">
      <coozzy-alert>
        {{ $t('message.addTenancy.noTenants') }}
      </coozzy-alert>
    </div>
    <div
      v-if="billingContact"
      class="col-12 col-md-6 col-sm-12">
      <coozzy-detail-card
        :title="$t('message.addTenancy.debtor')"
        :is-editable="true"
        :is-linked="true"
        @onEdit="editContactClicked(billingContact, 'BILLING_CONTACT')"
        @onUnlink="openUnlinkModal(billingContact, 'BILLING_CONTACT')">
        <contact-detail-card
          :ref="'contact-detail-card-' + billingContact.id"
          :contact="billingContact" />
      </coozzy-detail-card>
    </div>
    <div
      v-for="contact in tenants"
      :key="contact.id"
      class="col-12 col-md-6 col-sm-12">
      <coozzy-detail-card
        :title="$t('message.contact.types.TENANT')"
        :is-editable="true"
        :is-linked="true"
        @onEdit="editContactClicked(contact, 'TENANT')"
        @onUnlink="openUnlinkModal(contact, 'TENANT')">
        <contact-detail-card
          :ref="'contact-detail-card-' + contact.id"
          :contact="contact" />
      </coozzy-detail-card>
    </div>
    <div class="col-6 col-md-6 col-sm-12">
      <div
        v-if="correspondenceContact"
        class="col-12 p-0">
        <coozzy-detail-card
          :title="$t('message.addTenancy.CorrespondenceAddress')"
          :is-editable="true"
          :is-linked="true"
          @onEdit="editContactClicked(correspondenceContact, 'CORRESPONDENCE_CONTACT')"
          @onUnlink="openUnlinkModal(correspondenceContact, 'CORRESPONDENCE_CONTACT')">
          <contact-detail-card
            :ref="'contact-detail-card-' + correspondenceContact.id"
            :contact="correspondenceContact" />
        </coozzy-detail-card>
      </div>
    </div>
    <div class="col-6 col-md-6 col-sm-12">
      <div
        v-for="contact in guarantorContacts"
        :key="contact.id"
        class="col-12 p-0">
        <coozzy-detail-card
          :title="$t('message.addTenancy.guarantor')"
          :is-editable="true"
          :is-linked="true"
          @onEdit="editContactClicked(contact, 'GUARANTOR_CONTACT')"
          @onUnlink="openUnlinkModal(contact, 'GUARANTOR_CONTACT')">
          <contact-detail-card
            :ref="'contact-detail-card-' + contact.id"
            :contact="contact" />
        </coozzy-detail-card>
      </div>
    </div>
    <create-contact-modal
      modal-id="create-contact-modal-tenant-tab"
      :for-owner-id="parseInt(ownerId)"
      :show-search="true"
      :show-request-sources="false"
      :show-roles-input="true"
      :set-interested-party-role="false"
      :set-tenant-role="true"
      :show-add-another="true"
      :contact-to-edit="contactToEdit"
      :enable-delete="false"
      :show-numeric-id="true"
      @contact-created="contactCreated"
      @contact-updated="contactUpdated" />
    <create-contact-modal
      modal-id="create-contact-modal-partner-tab"
      :for-owner-id="parseInt(ownerId)"
      :show-search="true"
      :show-request-sources="false"
      :show-roles-input="true"
      :set-interested-party-role="false"
      :set-tenant-role="false"
      :show-add-another="false"
      :contact-to-hide="tenantToHide"
      :contact-to-edit="contactToEdit"
      :enable-delete="false"
      :show-numeric-id="true"
      @contact-created="contactCreated"
      @contact-updated="contactUpdated" />
    <b-modal
      :id="'confirmationModalLinkContact'"
      ref="confirmationModalLinkContact"
      no-close-on-backdrop
      hide-footer
      :title="typeOfContact === 'BILLING_CONTACT' ? $t('message.addTenancy.removeDebtorTitle') : typeOfContact === 'CORRESPONDENCE_CONTACT' ? $t('message.addTenancy.removeCorrespondenceTitle') : typeOfContact === 'GUARANTOR_CONTACT' ? $t('message.addTenancy.removeGuarantorTitle') : $t('message.addTenancy.removeContactTitle')">
      <div>
        <p v-if="typeOfContact === 'BILLING_CONTACT'">
          {{ $t('message.addTenancy.removeDebtorBody') }}
        </p>
        <p v-else-if="typeOfContact === 'CORRESPONDENCE_CONTACT'">
          {{ $t('message.addTenancy.removeCorrespondenceBody') }}
        </p>
        <p v-else-if="typeOfContact === 'GUARANTOR_CONTACT'">
          {{ $t('message.addTenancy.removeGuarantorBody') }}
        </p>
        <p v-else>
          {{ $t('message.addTenancy.removeContactBody') }}
        </p>
      </div>
      <div>
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModalLinkContact')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="contactRemoved()">
          {{ $t('message.generic.confirm') }}
        </coozzy-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard'
import ContactDetailCard from '@/contact/components/ContactDetailCard'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyAlert from '@/framework/components/alert/CoozzyAlert'
import CreateContactModal from '@/contact/components/CreateContactModal'

export default {
  name: 'AddTenancyTenantTab',
  components: {
    CreateContactModal,
    CoozzyAlert,
    CoozzyButton,
    ContactDetailCard,
    CoozzyDetailCard
  },
  props: {
    ownerId: {
      type: [String, Number],
      required: true
    },
    tenants: {
      type: Array,
      default: () => {
        return []
      }
    },
    billingContact: {
      type: Object,
      default: null
    },
    guarantorContacts: {
      type: Array,
      default: () => {
        return []
      }
    },
    correspondenceContact: {
      type: Object,
      default: null
    },
    tenantToHide: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      contactToUnlink: null,
      contactToEdit: null,
      typeOfContact: ''
    }
  },
  methods: {
    addClicked(type) {
      this.typeOfContact = type
      this.contactToEdit = null
      if (type === 'CORRESPONDENCE_CONTACT' || type === 'TENANT') {
        this.$bvModal.show('create-contact-modal-tenant-tab')
      } else {
        this.$bvModal.show('create-contact-modal-partner-tab')
      }
    },
    editContactClicked(contact, type) {
      this.typeOfContact = type
      this.contactToEdit = contact
      if (type === 'CORRESPONDENCE_CONTACT' || type === 'TENANT') {
        this.$bvModal.show('create-contact-modal-tenant-tab')
      } else {
        this.$bvModal.show('create-contact-modal-partner-tab')
      }
    },
    contactCreated(data) {
      if (this.typeOfContact === 'TENANT') {
        this.$emit('tenant-changed', data.contact)
      } else {
        this.$emit('contact-changed', { contact: data.contact, type: this.typeOfContact })
      }
    },
    contactUpdated(data) {
      if (data?.contact && data.contact?.lastName && data.contact?.firstName) {
        data.contact.name = data.contact.lastName + ' ' + data.contact.firstName
      }
      if (this.typeOfContact === 'TENANT') {
        this.$emit('tenant-changed', data.contact)
        if (this.billingContact && this.billingContact.id === data.contact.id) {
          this.$emit('contact-changed', { contact: data.contact, type: 'BILLING_CONTACT' })
        }
      } else {
        this.$emit('contact-changed', { contact: data.contact, type: this.typeOfContact })
        if (this.tenants.find(x => x.id === data.contact.id)) {
          this.$emit('tenant-changed', data.contact)
        }
      }
    },
    openUnlinkModal(element, type) {
      this.typeOfContact = type
      this.contactToUnlink = element
      this.$refs.confirmationModalLinkContact.show()
    },
    contactRemoved() {
      if (this.typeOfContact === 'TENANT') {
        this.$emit('tenant-removed', this.contactToUnlink.id)
      } else {
        this.$emit('contact-removed', { contact: this.contactToUnlink, type: this.typeOfContact })
      }
      this.$refs.confirmationModalLinkContact.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-max-width-300px button {
  max-width: 300px;
}

.buttons-collocation {
  display: flex;
}

@media (min-width: 768px) {
  .buttons-collocation {
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .buttons-collocation {
    flex-direction: column;
    align-items: center;
  }
  .buttons-collocation button {
    margin-bottom: 0.5rem;
  }
}

</style>
