// @ts-nocheck
import networkTS from './networkTS'
import network from './network'

export default {
  newTicket(ticket) {
    return network.post('/web/ticket/create', {
      type: ticket.type,
      status: ticket.status,
      title: ticket.title,
      description: ticket.description,
      mediaIds: ticket.mediaIds,
      priority: ticket.priority,
      dueDate: ticket.dueDate,
      startDate: ticket.startDate,
      references: ticket.references,
      assignee: ticket.assignee,
      watcher: ticket.watcher,
      ownerId: ticket.ownerId,
      onSiteContact: ticket.onSiteContact,
      onSiteContacts: ticket.onSiteContacts,
      correspondences: ticket.correspondences,
      links: ticket.links,
      implementationLead: ticket.links,
      externalTicketWatchers: ticket.externalTicketWatchers,
      key: ticket.key
    })
      .then(response => {
        return response.data
      })
  },
  updateTicket(ticket, updateMask = null) {
    return network.post(`/web/ticket/${ticket.id}/update`, {
      update: ticket,
      updateMask: updateMask
    })
      .then(response => {
        return response.data
      })
  },
  ticketServiceIsContactInUse(contactId: string) {
    return networkTS.ticketServiceIsContactInUse(contactId)
      .then(response => {
        return response.data
      })
  },
  countMyUnreadTickets(userIds = []) {
    if (userIds.length === 0) {
      return network.get('/web/ticket/countMyUnreadTickets')
        .then(response => {
          return response.data
        })
    } else {
      let query = ''
      userIds.forEach((userId) => {
        query += `userIds=${userId}&`
      })
      query = query.slice(0, -1)
      return network.get(`/web/ticket/countMyUnreadTickets?${query}`)
        .then(response => {
          return response.data
        })
    }
  },
  markRead(ids: string[]) {
    return networkTS.ticketServiceMarkRead2({
      ids: ids
    }).then(response => {
        return response.data
      })
  },
  markUnread(ids: string[]) {
    return networkTS.ticketServiceMarkUnread2({
      ids: ids
    }).then(response => {
        return response.data
      })
  },
  changedStatusPublicTicket(id, email, name, status) {
    return network.post(`/web/ticket/${id}/changeStatus`, {
      email: email,
      id: id,
      name: name,
      status: status
    })
      .then(response => {
        return response.data
      })
  },
  ListTicketByQuery(query) {
    return network.get(`web/ticket/listByQuery?query=${query}`)
      .then(response => {
        return response.data
      })
  },
  setNotificationMailbox(accountId, mailboxId) {
    return network.post(`/web/ticket/setNotificationMailbox/${accountId}`, {
      mailboxId: mailboxId
      })
      .then(response => {
        return response.data
      })
  },
  getNotificationMailbox(accountId) {
    return network.get(`/web/ticket/getNotificationMailbox/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  countTickets(referenceIds) {
    return network.post('/web/ticket/countTickets', {
      referenceIds: referenceIds
    })
      .then(response => {
        return response.data
      })
  },
  // expected params:{ query, closedOnly, page, itemsPerPage, priorities, types, ownerIds, creatorUserIds, assigneeUserIds, involvedContactIds, referenceIds, overdueOnly, watchingOnly}
  listMyTickets(params = {}) {
    if (this.cancelToken) {
      this.cancelToken.abort()
    }

    this.cancelToken = new AbortController()
    let query = `closedOnly=${params.closedOnly}`
    query += `&includeClosed=${params.includeClosed}`
    if (params.query !== '') {
      query += '&query=' + params.query
    }
    if (params.priorities.length > 0 && params.priorities[0] !== '') {
      query += '&priorities=' + params.priorities
    }
    if (params.types.length > 0 && params.types[0] !== '') {
      query += '&types=' + params.types
    }
    if (params.statuses.length > 0 && params.statuses[0] !== '') {
      params.statuses.forEach((status) => {
        query += '&statuses=' + status
      })
    }
    if (params.ownerIds.length > 0 && params.ownerIds[0] !== '') {
      params.ownerIds.forEach((ownerId) => {
        query += '&ownerIds=' + ownerId
      })
    }
    if (params.creatorUserIds.length > 0 && params.creatorUserIds[0] !== '') {
      params.creatorUserIds.forEach((creatorUserId) => {
        query += '&creatorUserIds=' + creatorUserId
      })
    }
    if (params.assigneeUserIds.length > 0) {
      params.assigneeUserIds.forEach((assigneeUserId) => {
        if (assigneeUserId !== 'unassigned') {
          query += '&assigneeUserIds=' + assigneeUserId
        } else {
          query += '&assigneeUserIds='
        }
      })
    }
    if (params.involvedContactIds.length > 0 && params.involvedContactIds[0] !== '') {
      params.involvedContactIds.forEach((correspondenceContactId) => {
        query += '&involvedContactIds=' + correspondenceContactId
      })
    }
    if (params.referenceIds.length > 0 && params.referenceIds[0] !== '') {
      params.referenceIds.forEach((referenceId) => {
        query += '&referenceIds=' + referenceId
      })
    }
    if (params.overdueOnly) {
      query += '&overdueOnly=' + params.overdueOnly
    }
    if (params.watchingOnly) {
      query += '&watchingOnly=' + params.watchingOnly
    }
    if (params.newEvent) {
      query += '&unreadByAssigneeOnly=' + params.newEvent
    }
    if (params.page) {
      query += '&page=' + params.page
    }
    if (params.itemsPerPage && params.itemsPerPage === 'all') {
      query += '&itemsPerPage=10000'
    } else if (params.itemsPerPage) {
      query += '&itemsPerPage=' + params.itemsPerPage
    }
    if ('directReferenceIdsOnly' in params) {
      query += '&directReferenceIdsOnly=' + params.directReferenceIdsOnly
    }
    return network.get(`web/ticket/listMyTickets?${query}`, {
      signal: this.cancelToken.signal
    })
      .then(response => {
        return response.data
      })
  },
  // getInvolvedPersons(involvedPersons) {
  //   const listInvolvedPersons = []
  //   for (let i = 0; i < involvedPersons.length; i += 1) {
  //     const person =
  //       {
  //         userReference: involvedPersons[i].userReference,
  //         accountId: involvedPersons[i].accountId,
  //         name: involvedPersons[i].name,
  //         firstName: involvedPersons[i].firstName,
  //         lastName: involvedPersons[i].lastName
  //       }
  //     listInvolvedPersons.push(person)
  //   }
  //   return listInvolvedPersons
  // },
  // getTickets(accountId) {
  //   return network.get(`web/ticket/?accountId=${accountId}`)
  //     .then(response => {
  //       return response.data
  //     })
  // },
  // getTicketById(ticketId) {
  //   return network.get(`web/ticket/${ticketId}`)
  //     .then(response => {
  //       return response.data
  //     })
  // },
  getTicketByKey(ticketKey) {
    return network.get(`web/ticket/getByKey/${ticketKey}`)
      .then(response => {
        return response.data
      })
  },
  deleteTicket(ticketId) {
    return network.delete(`/web/ticket/${ticketId}/delete`)
      .then(response => {
        return response.data
      })
  },
  getTicketByIds(ids) {
    return network.post('web/ticket/listByIds', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  // setStatus(ticketId, status) {
  //   return network.post(`/web/ticket/${ticketId}/status`, {
  //     status: status
  //   })
  //     .then(response => {
  //       return response.data
  //     })
  // },
  // setData(ticketId, title, description) {
  //   return network.post(`/web/ticket/${ticketId}`, {
  //     title: title,
  //     description: description
  //   })
  //     .then(response => {
  //       return response.data
  //     })
  // },
  setAssignee(ticketId, assignee) {
    return network.post(`/web/ticket/${ticketId}/assignee`, {
      userReference: assignee.id,
      accountId: assignee.accountId
    })
      .then(response => {
        return response.data
      })
  },
  // newNote(ticketId, userId, accountId, text, mediaIds) {
  //   return network.post(`/web/ticket/${ticketId}/notes`, {
  //     ticketId: ticketId,
  //     userId: userId,
  //     accountId: accountId,
  //     text: text,
  //     mediaIds: mediaIds
  //   })
  //     .then(response => {
  //       return response.data
  //     })
  // },
  // getNotes(ticketId) {
  //   return network.get(`/web/ticket/${ticketId}/notes`)
  //     .then(response => {
  //       return response.data
  //     })
  // },
  // getActivities(ticketId) {
  //   return network.get(`/web/ticket/${ticketId}/activities`)
  //     .then(response => {
  //       return response.data
  //     })
  // },
  // addInvolvedPerson(ticketId, involvedPerson) {
  //   return network.post(`/web/ticket/${ticketId}/persons`, {
  //     userReference: involvedPerson.userReference,
  //     accountId: involvedPerson.accountId
  //   })
  //     .then(response => {
  //       return response.data
  //     })
  // },
  // removeInvolvedPerson(ticketId, userReference, accountId) {
  //   return network.delete(`/web/ticket/${ticketId}/persons`, {
  //     data: {
  //       userReference: userReference,
  //       accountId: accountId
  //     }
  //   })
  //     .then(response => {
  //       return response.data
  //     })
  // },
  hasTicketingEnabled(accountId) {
    return network.get(`/web/ticket/hasTicketingEnabled?accountId=${accountId}`)
      .then(response => {
        return response.data
      })
  },
  enableTicketing(accountId, enable) {
    return network.post('/web/ticket/enableTicketing', {
      accountId: accountId,
      enable: enable
    }).then(response => {
      return response.data
    })
  },
  listTicketingEnabled(accountIds) {
    return network.post('/web/ticket/listTicketingEnabled', {
      accountIds: accountIds
    }).then(response => {
      return response.data
    })
  },
  getByPublicId(publicId) {
    return network.get(`/web/ticket/getByPublicId/${publicId}`)
      .then(response => {
        return response.data
      })
  },
  getTicketHistory(ticketId, publicTicketId) {
    return network.get(`/web/ticket/history/${ticketId}?publicTicketId=${publicTicketId}`)
      .then(response => {
        return response.data
      })
  },
  reserveKeys(accountId, amount) {
    return network.post('/web/ticket/reserveKeys', {
      accountId: accountId,
      amount: amount
    }).then(response => {
      return response.data
    })
  },
  listByReferenceIds(referenceIds) {
    return network.post('/web/ticket/listByReferenceIds', {
      referenceIds: referenceIds
    }).then(response => {
      return response.data
    })
  },
  getTicketStatistics(userId) {
    return network.get(`/web/ticket/getTicketStatistics/${userId}`)
      .then(response => {
        return response.data
      })
  },
  setSchedules(schedules) {
    return networkTS.ticketServiceSetSchedules({ schedules: schedules })
      .then(response => {
        return response.data
      })
  },
  listSchedules(accountId, categories: []) {
    return networkTS.ticketServiceListSchedules({ accountId, categories })
      .then(response => {
        return response.data
      })
  }
}
