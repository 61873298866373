<template>
  <section>
    <b-modal
      :id="'modal-send-mail-type'"
      ref="modal-send-mail-type"
      no-close-on-backdrop
      hide-footer>
      <template #modal-header>
        <h5 class="modal-title">
          {{ $t('message.marketingMessages.sendMailType.title') }}
        </h5>
        <div class="d-flex">
          <b-button
            class="close ml-2"
            size="sm"
            @click="hide()">
            x
          </b-button>
        </div>
      </template>
      <div class="col py-3">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="callShowSendMailModalFunction(true)">
          {{ $t('message.marketingMessages.sendMailType.single') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="callShowSendMailModalFunction(false)">
          {{ $t('message.marketingMessages.sendMailType.multiple') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { user } from '@/mixins/user'
import { media } from '@/mixins/media'
import { contact } from '@/mixins/contact'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MessageApi from '@/misc/apis/MessageApi'
import { routeChecks } from '@/mixins/routeChecks'
import { request } from '@/mixins/request'
import { mail } from '@/mixins/mail'
import { algolia } from '@/mixins/algolia'

export default {
  name: 'ModalSendMailType',
  components: {
    CoozzyButton
  },
  mixins: [algolia, routeChecks, user, mail, media, contact, request],
  props: {
    contact: {
      type: Object,
      default() {
        return null
      }
    },
    email: {
      type: Object,
      default() {
        return null
      }
    },
    ticket: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    hide() {
      this.$refs['modal-send-mail-type'].hide()
    },
    callShowSendMailModalFunction(toAllTo) {
      this.hide()
      this.callShowSendMailModal(toAllTo)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.more-btn button) {
  border-radius: 0.2rem;
  color: #515151;
  font-size: .875rem;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ced4da;
}

:deep(.modal-header) {
  padding-bottom: 0;
}

:deep(.modal-body) {
  padding-top: 0;
}

p {
  white-space: pre-wrap;
}

.media-block {
  max-width: 33%;
  margin-right: 0.5%;
}

:deep(.modal-dialog) {
  max-width: 1200px !important;
}

.close-img {
  width: 15px;
}

.section-to-print {
  opacity: 1;
  background-color: #FFF;
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  min-height: 200px;
  top: 0;
  left: 0;
  padding: 15px;
  margin: 0;
  z-index: 1000;
}

.section-to-print-bg {
  background-color: transparent !important;
}

:deep(.ticket-dropdown-bulk-action button) {
  border: 0;
}

.linkTicket {
  cursor: pointer;
  color: #007bff !important;
}
</style>
