<template>
  <div class="root">
    <div class="row">
      <div class="col">
        <label
          v-if="name"
          :for="name">
          {{ name }}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <datepicker
          v-model="dateModel"
          :type="type"
          :lang="lang"
          :class="['form-control form-control-sm', checkValide]"
          v-bind="$attrs"
          v-on="mountEventListeners" />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue2-datepicker'

export default {
  name: 'CoozzyDatePicker',
  components: {
    Datepicker
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: Date,
      default: new Date()
    },
    type: {
      type: String,
      default: 'datetime'
    },
    checkValide: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dateModel: this.value
    }
  },
  computed: {
    mountEventListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: (value) => {
            this.$emit('input', value)
          }
        }
      )
    },
    lang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    }
  }
}
</script>

<style lang="scss" scoped>
  .root {
    input {
      padding: 0.2em 8px;
    }
  }

  .mx-datepicker {
    width: 100%;
    padding: 0;
    :deep(.mx-input) {
      border: none;
      height: 29px;
    }
  }
</style>
