export default {
  install(Vue, options) {
    const requireComponent = require.context(
      '@/framework/layout',
      true,
      /Coozzy[A-Z]\w+\.vue$/
    )

    requireComponent.keys().forEach(fileName => {
      const componentConfig = requireComponent(fileName)
      const componentName = componentConfig.default.name

      Vue.component(
        componentName,
        componentConfig.default || componentConfig
      )
    })
  }
}
