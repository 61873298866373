import DeepSignApi from '@/misc/apis/DeepSignApi'
import { user } from '@/mixins/user'

const deepSign = {
  mixins: { user },
  data() {
    return {
      countOpenSignRequests: '-'
    }
  },
  computed: {
    deepSignEnabled() {
      return this.$store.getters['deepSign/getDeepSignStatus']
    }
  },
  mounted() {
    this.getCountOpenSignRequests()
  },
  methods: {
    getCountOpenSignRequests() {
      DeepSignApi.countOpenSignRequests(this.accountId).then((response) => {
        this.countOpenSignRequests = response.amount
      })
    }
  }
}
export { deepSign }
