<template>
  <section :class="readOnly ? '' : 'no-alternate-backgrounds'">
    <div
      v-b-toggle.coozzy-card-possible-deposits
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ $t('message.onBoarding.createObjectView.possibleDeposits') }}
      </h5>
    </div>
    <div class="col-12 pl-0">
      <b-collapse
        :id="'coozzy-card-possible-deposits'"
        ref="collapse"
        :visible="expandByDefault || openedCollapses.includes('coozzy-card-possible-deposits')"
        class="row">
        <div class="col">
          <div class="row details-row-bg-1 mt-3">
            <div
              v-if="!readOnly"
              class="d-flex col-3">
              <coozzy-form-checkbox
                id="newdepositNeeded"
                :key="'depositNeeded'"
                v-model="depositNeeded"
                :initial="depositNeeded"
                class="align-self-center mt-3">
                {{ $t('message.onBoarding.buildings.objects.deposit.depositNeeded') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-else
              class="col-sm-12 col-md-3">
              <label for="depositNeeded">{{ $t('message.onBoarding.buildings.objects.deposit.depositNeeded')
              }}</label>
              <span
                class="show-data">{{ depositNeeded === null || depositNeeded === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
            </div>
            <div
              v-if="isDepositNeeded"
              class="col-3">
              <coozzy-form-input-numeric
                v-model="amount"
                type="number"
                :is-read-only="readOnly"
                :filter="'formatPrice'"
                min="0"
                placeholder="CHF"
                :is-decimal="true"
                :name="$t('message.onBoarding.buildings.objects.deposit.amount')" />
            </div>
          </div>
          <template v-if="isDepositNeeded">
            <div
              class="row details-row-bg-2">
              <div
                v-if="!readOnly"
                class="d-flex col-3">
                <coozzy-form-checkbox
                  id="DEPOSIT_TYPE_CASH_PAYMENT"
                  :key="'DEPOSIT_TYPE_CASH_PAYMENT'"
                  v-model="cashPaymentAccepted"
                  :initial="cashPaymentAccepted"
                  class="align-self-center mt-3">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_CASH_PAYMENT') }}
                </coozzy-form-checkbox>
              </div>
              <div
                v-else-if="cashPaymentAccepted"
                class="col-sm-12 col-md-3">
                <label for="DEPOSIT_TYPE_CASH_PAYMENT">{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_CASH_PAYMENT')
                }}</label>
                <span
                  class="show-data">{{ cashPaymentAccepted === null || cashPaymentAccepted === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
              </div>
            </div>
            <div
              class="row details-row-bg-1">
              <div
                v-if="!readOnly"
                class="d-flex col-3">
                <coozzy-form-checkbox
                  id="DEPOSIT_TYPE_INSURANCE_GUARANTEE"
                  :key="'DEPOSIT_TYPE_INSURANCE_GUARANTEE'"
                  v-model="insuranceAccepted"
                  :initial="insuranceAccepted"
                  class="align-self-center mt-3">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_INSURANCE_GUARANTEE') }}
                </coozzy-form-checkbox>
              </div>
              <div
                v-else-if="insuranceAccepted"
                class="col-sm-12 col-md-3">
                <label for="DEPOSIT_TYPE_INSURANCE_GUARANTEE">{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_INSURANCE_GUARANTEE')
                }}</label>
                <span
                  class="show-data">{{ insuranceAccepted === null || insuranceAccepted === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
              </div>
              <template
                v-if="isDepositNeeded && isInsuranceAccepted">
                <div
                  v-if="!readOnly"
                  class="col-sm-12 col-md-3">
                  <label>{{ $t('message.onBoarding.buildings.objects.deposit.depositInsurance.title') }}</label>
                  <coozzy-search-multiselect
                    v-model="insuranceNameValue"
                    :options="insuranceNameList"
                    :multiple="true"
                    :placeholder="''"
                    :tag-placeholder="'Add this as new tag'"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :taggable="true"
                    :is-email="false"
                    :with-address="false"
                    :email-with-name="false"
                    label="email"
                    track-by="name"
                    :is-read-only="readOnly" />
                </div>
                <div
                  v-else-if="insuranceAccepted"
                  class="col-sm-12 col-md-3">
                  <label>{{ $t('message.onBoarding.buildings.objects.deposit.depositInsurance.title') }}</label>
                  <span class="d-block">{{ item.deposit.insuranceNames.length > 0 ? getInsuranceNames(item.deposit.insuranceNames) : '-' }}</span>
                </div>
              </template>
            </div>
            <div
              class="row details-row-bg-2">
              <div
                v-if="!readOnly"
                class="d-flex col-3">
                <coozzy-form-checkbox
                  id="DEPOSIT_TYPE_DEPOT"
                  :key="'DEPOSIT_TYPE_DEPOT'"
                  v-model="bankDepotAccepted"
                  :initial="bankDepotAccepted"
                  class="align-self-center mt-3">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_DEPOT') }}
                </coozzy-form-checkbox>
              </div>
              <div
                v-else-if="bankDepotAccepted"
                class="col-sm-12 col-md-3">
                <label for="DEPOSIT_TYPE_DEPOT">{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_DEPOT')
                }}</label>
                <span
                  class="show-data">{{ bankDepotAccepted === null || bankDepotAccepted === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
              </div>
              <template
                v-if="isDepositNeeded && isBankDepotAccepted">
                <div
                  v-if="!readOnly"
                  class="col-sm-12 col-md-3">
                  <label for="bankName">{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.bankName') }}</label>
                  <ais-instant-search
                    v-if="searchClient"
                    :search-client="searchClient"
                    :routing="routing"
                    index-name="banks">
                    <!-- eslint-disable vue/attribute-hyphenation -->
                    <ais-configure
                      :hitsPerPage="10"
                      filters="countryCode:CH OR countryCode:DE OR countryCode:AT OR countryCode:LI" />
                    <!-- eslint-enable vue/attribute-hyphenation -->
                    <ais-autocomplete>
                      <template slot-scope="{ indices, refine }">
                        <coozzy-search-multiselect
                          id="bankDepotNames-multiselect"
                          v-model="bankDepotNamesValue"
                          :options="indices[0].hits"
                          :multiple="true"
                          :placeholder="''"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :preselect-first="false"
                          :taggable="false"
                          :custom-label="nameBank"
                          :internal-search="false"
                          :track-by="'objectID'"
                          :with-address="true"
                          :detail-bankname="true"
                          @search-change="see(refine, $event)" />
                      </template>
                    </ais-autocomplete>
                  </ais-instant-search>
                </div>
                <div
                  v-else-if="bankDepotAccepted"
                  class="col-sm-12 col-md-3">
                  <label for="bankName">{{
                    $t('message.onBoarding.buildings.objects.tenancies.deposit.bankName') }}</label>
                  {{ bankDepotNames }}
                </div>
              </template>
            </div>
            <div
              class="row details-row-bg-1">
              <div
                v-if="!readOnly"
                class="d-flex col-3">
                <coozzy-form-checkbox
                  id="DEPOSIT_TYPE_COOPERATIVE_SHARE"
                  :key="'DEPOSIT_TYPE_COOPERATIVE_SHARE'"
                  v-model="cooperativeShareAccepted"
                  :initial="cooperativeShareAccepted"
                  class="align-self-center mt-3">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_COOPERATIVE_SHARE') }}
                </coozzy-form-checkbox>
              </div>
              <div
                v-else-if="cooperativeShareAccepted"
                class="col-sm-12 col-md-3">
                <label for="DEPOSIT_TYPE_COOPERATIVE_SHARE">{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_COOPERATIVE_SHARE')
                }}</label>
                <span
                  class="show-data">{{ cooperativeShareAccepted === null || cooperativeShareAccepted === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
              </div>
            </div>
            <div
              class="row details-row-bg-2">
              <div
                v-if="!readOnly"
                class="d-flex col-3">
                <coozzy-form-checkbox
                  id="DEPOSIT_TYPE_BANK_GUARANTEE"
                  :key="'DEPOSIT_TYPE_BANK_GUARANTEE'"
                  v-model="bankGuaranteeAccepted"
                  :initial="bankGuaranteeAccepted"
                  class="align-self-center mt-3">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_BANK_GUARANTEE') }}
                </coozzy-form-checkbox>
              </div>
              <div
                v-else-if="bankGuaranteeAccepted"
                class="col-sm-12 col-md-3">
                <label for="DEPOSIT_TYPE_BANK_GUARANTEE">{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_BANK_GUARANTEE')
                }}</label>
                <span
                  class="show-data">{{ bankGuaranteeAccepted === null || bankGuaranteeAccepted === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
              </div>
              <template
                v-if="isDepositNeeded && isBankGuaranteeAccepted"
                class="row details-row-bg-2">
                <div
                  v-if="!readOnly"
                  class="col-sm-12 col-md-3">
                  <label for="bankName">{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.bankName') }}</label>
                  <ais-instant-search
                    v-if="searchClient"
                    :search-client="searchClient"
                    :routing="routing"
                    index-name="banks">
                    <!-- eslint-disable vue/attribute-hyphenation -->
                    <ais-configure
                      :hitsPerPage="10"
                      filters="countryCode:CH OR countryCode:DE OR countryCode:AT OR countryCode:LI" />
                    <!-- eslint-enable vue/attribute-hyphenation -->
                    <ais-autocomplete>
                      <template slot-scope="{ indices, refine }">
                        <coozzy-search-multiselect
                          id="bankDepotNames-multiselect"
                          v-model="bankGuaranteeValue"
                          :options="indices[0].hits"
                          :multiple="true"
                          :placeholder="''"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :preselect-first="false"
                          :taggable="false"
                          :custom-label="nameBank"
                          :internal-search="false"
                          :track-by="'objectID'"
                          :with-address="true"
                          :detail-bankname="true"
                          @search-change="see(refine, $event)" />
                      </template>
                    </ais-autocomplete>
                  </ais-instant-search>
                </div>
                <div
                  v-else-if="bankGuaranteeAccepted"
                  class="col-sm-12 col-md-3">
                  <label for="bankName">{{
                    $t('message.onBoarding.buildings.objects.tenancies.deposit.bankName') }}</label>
                  {{ bankGuaranteeNames }}
                </div>
              </template>
            </div>
            <div
              class="row details-row-bg-1">
              <!-- <div
                v-if="!readOnly"
                class="d-flex col-3">
                <coozzy-form-checkbox
                  id="DEPOSIT_TYPE_COLLECTIVE_INSURANCE"
                  :key="'DEPOSIT_TYPE_COLLECTIVE_INSURANCE'"
                  v-model="collectiveInsuranceAccepted"
                  :initial="collectiveInsuranceAccepted"
                  class="align-self-center mt-3">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_COLLECTIVE_INSURANCE') }}
                </coozzy-form-checkbox>
              </div>
              <div
                v-else-if="collectiveInsuranceAccepted"
                class="col-sm-12 col-md-3">
                <label for="DEPOSIT_TYPE_COLLECTIVE_INSURANCE">{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_COLLECTIVE_INSURANCE')
                }}</label>
                <span
                  class="show-data">{{ collectiveInsuranceAccepted === null || collectiveInsuranceAccepted === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
              </div> -->
              <!-- <template v-if="isDepositNeeded && isCollectiveInsuranceAccepted">
                <div
                  v-if="!readOnly"
                  class="col-sm-12 col-md-3">
                  <label>{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.insuranceNameCollective') }}*</label>
                  <coozzy-search-multiselect
                    v-model="collectiveInsuranceNamesValue"
                    :options="collectiveInsuranceNamesList"
                    :multiple="true"
                    :placeholder="''"
                    :tag-placeholder="'Add this as new tag'"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :taggable="true"
                    :is-email="false"
                    :with-address="false"
                    :email-with-name="false"
                    label="name"
                    track-by="name"
                    :is-read-only="readOnly" />
                </div>
                <div
                  v-else-if="collectiveInsuranceAccepted"
                  class="col-sm-12 col-md-3">
                  <label>{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.insuranceNameCollective') }}</label>
                  <span class="d-block">{{ item.deposit.collectiveInsuranceNames.length > 0 ? item.deposit.collectiveInsuranceNames.toString() : '-' }}</span>
                </div>
              </template> -->
            </div>
          </template>
        </div>
      </b-collapse>
    </div>
  </section>
</template>

<script>

import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import { onboarding } from '@/mixins/onboarding'
import { algolia } from '@/mixins/algolia'
import { formatting } from '@/mixins/formatting'
import { validation } from '@/mixins/validation'
import CoozzyFormCheckbox from '../../../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'

export default {
  name: 'ObjectPossibleDeposits',
  components: {
    CoozzyFormInputNumeric,
    CoozzyFormCheckbox,
    CoozzySearchMultiselect
  },
  mixins: [onboarding, formatting, algolia, validation],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      banksData: false,
      isLoaded: false,
      isDepositNeeded: false,
      collectiveInsuranceNamesValue: [],
      bankDepotNamesValue: [],
      bankGuaranteeValue: [],
      insuranceNameValue: [],
      isCollectiveInsuranceAccepted: false,
      isBankDepotAccepted: false,
      isBankGuaranteeAccepted: false,
      isCashPaymentAccepted: false,
      isCooperativeShareAccepted: false,
      isInsuranceAccepted: false,
      collectiveInsuranceNamesChanged: false
    }
  },
  computed: {
    insuranceNameList() {
      return [
        {
          email: this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.AXA'),
          name: 'AXA'
        },
        {
          email: this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.FIRST_CAUTION'),
          name: 'FIRST_CAUTION'
        },
        {
          email: this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.GO_CAUTION'),
          name: 'GO_CAUTION'
        },
        {
          email: this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.HELVETIA'),
          name: 'HELVETIA'
        },
        {
          email: this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.MOBILAR'),
          name: 'MOBILAR'
        },
        {
          email: this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.SMART_CAUTION'),
          name: 'SMART_CAUTION'
        },
        {
          email: this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.SWISS_CAUTION'),
          name: 'SWISS_CAUTION'
        },
        {
          email: this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.ZUERICH'),
          name: 'ZUERICH'
        }]
    },
    // collectiveInsuranceNamesList() {
    //   const arr = [{ email: 'AXA', name: 'AXA' }]
    //   if (this.item.deposit?.collectiveInsuranceNames?.length > 0) {
    //     this.item.deposit?.collectiveInsuranceNames.forEach(element => {
    //       if (this.collectiveInsuranceAccepted && !arr.find(x => x.name === element)) {
    //         arr.push({
    //           name: element,
    //           email: element
    //         })
    //       }
    //     })
    //   }
    //   return arr
    // },
    insuranceNames: {
      get() {
        return this.item.deposit && this.item.deposit.insuranceNames ? this.item.deposit.insuranceNames : null
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.insuranceNames = value
        this.updateObject(object)
      }
    },
    depositNeeded: {
      get() {
        return this.item.deposit && this.item.deposit.depositNeeded ? this.item.deposit.depositNeeded : false
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.depositNeeded = value
        this.isDepositNeeded = value
        if (object.deposit.depositNeeded === false) {
          object.deposit.depositInsurance = 'UNDEFINED_DEPOSIT_INSURANCE'
          object.deposit.amount = -1
        }
        this.updateObject(object)
      }
    },
    amount: {
      get() {
        return this.item.deposit && this.item.deposit.amount !== undefined && this.item.deposit.amount !== -1 ? this.item.deposit.amount : null
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.amount = value
        this.updateObject(object)
      }
    },
    collectiveInsuranceAccepted: {
      get() {
        return this.item.deposit && this.item.deposit.collectiveInsuranceAccepted ? this.item.deposit.collectiveInsuranceAccepted : false
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.collectiveInsuranceAccepted = value
        this.isCollectiveInsuranceAccepted = value
        this.updateObject(object)
      }
    },
    collectiveInsuranceNames: {
      get() {
        return this.item.deposit && this.item.deposit.collectiveInsuranceNames ? this.item.deposit.collectiveInsuranceNames : []
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.collectiveInsuranceNames = value
        this.updateObject(object)
      }
    },
    bankDepotAccepted: {
      get() {
        return this.item.deposit && this.item.deposit.bankDepotAccepted ? this.item.deposit.bankDepotAccepted : false
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.bankDepotAccepted = value
        this.isBankDepotAccepted = value
        this.updateObject(object)
      }
    },
    bankDepotNames: {
      get() {
        return this.item.deposit && this.item.deposit.bankDepotNames ? [...new Set(this.item.deposit.bankDepotNames)].toString() : []
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.bankDepotNames = value
        this.updateObject(object)
      }
    },
    bankDepotIds: {
      get() {
        return this.item.deposit && this.item.deposit.bankDepotIds ? this.item.deposit.bankDepotIds : []
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.bankDepotIds = value
        this.updateObject(object)
      }
    },
    bankGuaranteeAccepted: {
      get() {
        return this.item.deposit && this.item.deposit.bankGuaranteeAccepted ? this.item.deposit.bankGuaranteeAccepted : false
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.bankGuaranteeAccepted = value
        this.isBankGuaranteeAccepted = value
        this.updateObject(object)
      }
    },
    bankGuaranteeIds: {
      get() {
        return this.item.deposit && this.item.deposit.bankGuaranteeIds ? this.item.deposit.bankGuaranteeIds : []
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.bankGuaranteeIds = value
        this.updateObject(object)
      }
    },
    bankGuaranteeNames: {
      get() {
        return this.item.deposit && this.item.deposit.bankGuaranteeNames ? [...new Set(this.item.deposit.bankGuaranteeNames)].toString() : []
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.bankGuaranteeNames = value
        this.updateObject(object)
      }
    },
    cashPaymentAccepted: {
      get() {
        return this.item.deposit && this.item.deposit.cashPaymentAccepted ? this.item.deposit.cashPaymentAccepted : false
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.cashPaymentAccepted = value
        this.isCashPaymentAccepted = value
        this.updateObject(object)
      }
    },
    cooperativeShareAccepted: {
      get() {
        return this.item.deposit && this.item.deposit.cooperativeShareAccepted ? this.item.deposit.cooperativeShareAccepted : false
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.cooperativeShareAccepted = value
        this.isCooperativeShareAccepted = value
        this.updateObject(object)
        this.$emit('update-object-possible-deposits', object)
      }
    },
    insuranceAccepted: {
      get() {
        return this.item.deposit && this.item.deposit.insuranceAccepted ? this.item.deposit.insuranceAccepted : false
      },
      set(value) {
        const object = this.item
        if (!object.deposit) {
          object.deposit = {}
        }
        object.deposit.insuranceAccepted = value
        this.isInsuranceAccepted = value
        this.updateObject(object)
        this.$emit('update-object-possible-deposits', object)
      }
    }
  },
  watch: {
    insuranceNameValue: function (newVal) {
      if (newVal.length > 0) {
        this.insuranceNames = newVal.map(x => x.name)
      } else {
        this.insuranceNames = []
      }
    },
    collectiveInsuranceNamesValue: function (newVal) {
      if (this.collectiveInsuranceNamesChanged) {
        if (newVal.length > 0) {
          this.collectiveInsuranceNames = newVal.map(x => x.name)
        } else {
          this.collectiveInsuranceNames = []
        }
      }
    },
    bankDepotNamesValue: function (newVal) {
      if (!this.banksData) {
        if (newVal.length > 0) {
          this.bankDepotNames = newVal.map(x => x.bankOrInstitutionName)
          this.bankDepotIds = newVal.map(x => x.iid.toString())
        } else {
          this.bankDepotNames = []
          this.bankDepotIds = []
        }
      }
    },
    bankGuaranteeValue: function (newVal) {
      if (!this.banksData) {
        if (newVal.length > 0) {
          this.bankGuaranteeNames = newVal.map(x => x.bankOrInstitutionName)
          this.bankGuaranteeIds = newVal.map(x => x.iid.toString())
        } else {
          this.bankGuaranteeNames = []
          this.bankGuaranteeIds = []
        }
      }
    },
    searchClient: function (newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        const bankIds = (this.item.deposit?.bankDepotIds || [])
          .concat(this.item.deposit?.bankGuaranteeIds || [])
        if (bankIds.length) {
          this.availableBankname(bankIds.filter(x => x !== null && x !== ''))
        }
      }
    }
  },
  mounted() {
    this.loadInstantSearch(true)
    this.isDepositNeeded = this.item.deposit?.depositNeeded ? this.item.deposit?.depositNeeded : false
    this.isCollectiveInsuranceAccepted = this.item.deposit?.collectiveInsuranceAccepted ? this.item.deposit?.collectiveInsuranceAccepted : false
    this.isBankDepotAccepted = this.item.deposit?.bankDepotAccepted ? this.item.deposit?.bankDepotAccepted : false
    this.isBankGuaranteeAccepted = this.item.deposit?.bankGuaranteeAccepted ? this.item.deposit?.bankGuaranteeAccepted : false
    this.isCashPaymentAccepted = this.item.deposit?.cashPaymentAccepted ? this.item.deposit?.cashPaymentAccepted : false
    this.isCooperativeShareAccepted = this.item.deposit?.cooperativeShareAccepted ? this.item.deposit?.cooperativeShareAccepted : false
    this.isInsuranceAccepted = this.item.deposit?.insuranceAccepted ? this.item.deposit?.insuranceAccepted : false
    this.collectiveInsuranceNamesValue = []
    this.item.deposit?.collectiveInsuranceNames.forEach(element => {
      this.collectiveInsuranceNamesValue.push({ name: element, email: element })
    })
    this.item.deposit?.insuranceNames.forEach(element => {
      this.insuranceNameValue.push({ name: element, email: this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.' + element) })
    })
    const bankIds = this.item.deposit?.bankDepotIds
      .concat(this.item.deposit.bankGuaranteeIds) || ''
    this.$nextTick(function () {
      if (bankIds !== '') {
        this.availableBankname(bankIds.filter(x => x !== null && x !== ''))
      }
      this.collectiveInsuranceNamesChanged = true
      this.isLoaded = true
    })
  },
  methods: {
    nameBank(item) {
      return item.bankOrInstitutionName
    },
    getInsuranceNames(insuranceNames) {
      let text = ''
      for (let index = 0; index < insuranceNames.length; index++) {
        const element = insuranceNames[index]
        if (index > 0) {
          text += ', '
        }
        text += this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.' + element)
      }
      return text
    },
    updateObject(object) {
      if (this.isLoaded) {
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    see(refine, value) {
      refine(value)
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    },
    availableBankname(search) {
      if (this.searchClient && search.length > 0) {
        const index = this.searchClient.initIndex('banks')
        let filter = ''
        for (let index = 0; index < search.length; index++) {
          const element = search[index]
          if (index > 0) {
            filter += ' OR '
          }
          filter += `iid:${element}`
        }
        index
          .search('', {
            filters: filter
          })
          .then(({ hits }) => {
            this.banksData = true
            this.bankDepotNamesValue = hits.filter(x => this.bankDepotIds.includes(x.iid.toString())).length > 0 ? hits.filter(x => this.bankDepotIds.includes(x.iid.toString())).filter(x => x.iidType === 'HEADQUARTERS') : []
            this.bankGuaranteeValue = hits.filter(x => this.bankGuaranteeIds.includes(x.iid.toString())).length > 0 ? hits.filter(x => this.bankGuaranteeIds.includes(x.iid.toString())).filter(x => x.iidType === 'HEADQUARTERS') : []
            setTimeout(() => {
              this.banksData = false
            }, 1000)
            return hits[0]
          })
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
