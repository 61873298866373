<template>
  <section>
    <div
      v-if="loading"
      class="text-center">
      <coozzy-spinner />
    </div>
    <div v-else-if="action === 'accept'">
      {{ $t('message.successMessages.eventAccepted') }}
    </div>
    <div v-else>
      {{ $t('message.successMessages.eventDeclined') }}
    </div>
  </section>
</template>

<script>
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CalendarApi from '@/misc/apis/CalendarApi'
import Vue from 'vue'

export default {
  name: 'EventExternalReplyView',
  components: { CoozzySpinner },
  props: {
    action: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    const userLang = navigator.language || navigator.userLanguage
    this.$store.dispatch('internationalization/changeCurrentLanguage', userLang.substring(0, 2)).then()
    this.loading = true
    CalendarApi.setExternalAttendeesStatus(
      this.action === 'accept' ? 'ATTENDEE_STATUS_ACCEPTED' : 'ATTENDEE_STATUS_DECLINED',
      this.token
    ).then(() => {
      this.loading = false
    }).catch(e => {
      console.log(e)
      if (this.action === 'accept') {
        Vue.toasted.show(this.$t('message.savingErrors.eventAccepted'), { type: 'error' })
      } else {
        Vue.toasted.show(this.$t('message.savingErrors.eventDeclined'), { type: 'error' })
      }
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
