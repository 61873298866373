<template>
  <section>
    <div>
      <h1
        v-if="!loading">
        {{ $t('message.processHandover.title') }}
      </h1>
    </div>
    <div class="row">
      <div class="col-12">
        <coozzy-page-title
          :title="$t('message.onBoarding.buildings.title') + ': ' + buildingName"
          :sub-title="objectAddress" />
      </div>
      <div class="col">
        <form-wizard
          id="wizard"
          :hide-buttons="loading"
          step-size="xs"
          color="rgb(0, 68, 95)"
          @on-change="handleTabChange">
          <span slot="title" />
          <tab-content :title="$t('message.processHandover.participants')">
            <div
              v-if="loading"
              class="text-center">
              <coozzy-spinner />
            </div>
            <handover-other-tenancies
              v-else-if="!loading && objectsWithSameTenants.length > 0"
              :objects-with-same-tenants="availableObjects"
              :same-tenancies-by-object="sameTenanciesByObject"
              :object-id="objectId"
              @selected-object="selectedObject" />
            <div class="row">
              <div class="col-3">
                <label>{{ $t('message.tableColumnHeadings.adminAssignee') }} </label>
                <coozzy-form-select
                  v-model="handoverObject.assigneeContactId"
                  name="assigneeMarketing"
                  class="select-entries float-right">
                  <option :value="null">
                    -
                  </option>
                  <option
                    v-for="(employee, index) in availableEmployeeContacts"
                    :key="index + employee.id"
                    :value="employee.id">
                    {{ employee.firstName }} {{ employee.lastName }}
                  </option>
                </coozzy-form-select>
              </div>
              <div class="col-3">
                <label>{{ $t('message.calendar.event.start') }}*</label>
                <coozzy-form-input
                  id="startDate"
                  ref="startDate"
                  v-model="scheduledDate"
                  :state="$v.scheduledDate.$dirty && $v.scheduledDate.$error ? false : null"
                  type="date" />
              </div>
              <div
                class="col-3">
                <label>{{ $t('message.calendar.event.time') }}*</label>
                <coozzy-form-select
                  id="startTime"
                  v-model="scheduledTime"
                  :state="$v.scheduledTime.$dirty && $v.scheduledTime.$error ? false : null">
                  <option
                    v-for="t in timeList"
                    :key="'start' + t"
                    :value="t">
                    {{ t }}
                  </option>
                </coozzy-form-select>
              </div>
              <template
                v-if="!getLoading && objectsWithSameTenantsComputed.length > 0">
                <template
                  v-for="(obj, index) in objectsWithSameTenantsComputed">
                  <div
                    :key="'obj-' + index"
                    class="col-12">
                    <hr class="mt-3 mb-3">
                    <div
                      id="titleObject"
                      :ref="`titleObject`"
                      v-b-toggle="`titleObject-${obj.id}`"
                      class="collapse-title">
                      <h5 class="cursor-pointer">
                        {{ obj.numericId + ' - ' + obj.name }}
                      </h5>
                    </div>
                    <b-collapse
                      :id="`titleObject-${obj.id}`"
                      :key="`object-${obj.id}`"
                      :ref="`collapse-${obj.id}`"
                      :visible="true">
                      <div class="row">
                        <div
                          class="col-6 col-md-6 mt-3">
                          <handover-tenancy-move
                            :ref="'tenancies-contact-card'"
                            :key="filterSameTenanciesMovingInBySelectedObject.length"
                            :objects="[obj]"
                            :from-handover="'moveIn'"
                            :available-contact="detailsOfTenants"
                            :move-tenancies="!tenancyAfterVacancy ? filterSameTenanciesMovingInBySelectedObject : currentTenancySigned ? filterSameTenanciesMovingInBySelectedObjectAndNoSigned : []"
                            :type="'TENANT'"
                            @contact-updated="contactUpdated" />
                        </div>
                        <div
                          class="col-6 col-md-6 mt-3">
                          <handover-tenancy-move
                            :key="filterSameTenanciesMovingOutBySelectedObject.length"
                            :ref="'tenancies-contact-card'"
                            :from-handover="'moveOut'"
                            :objects="[obj]"
                            :available-contact="detailsOfTenants"
                            :move-tenancies="filterSameTenanciesMovingOutBySelectedObject"
                            :type="'TENANT'"
                            @contact-updated="contactUpdated" />
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </template>
              </template>
            </div>
          </tab-content>
          <template
            v-for="(item, index) in selectedObjectToProcess">
            <tab-content
              :key="index"
              :title="getTabTitle('rooms', item)">
              <div
                v-if="loading"
                class="text-center">
                <coozzy-spinner />
              </div>
              <div
                v-else-if="item"
                class="row">
                <div class="col-md-12">
                  <h3>{{ item.name }}</h3>
                </div>
                <handover-rooms-step
                  :key="item.id + '-' + rooms.length + '-' + preSelectedRooms.length"
                  :object="item"
                  :self-rooms="rooms.filter(x => x.referenceId === item.id).filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)"
                  :pre-selected-rooms="preSelectedRooms.find(x => x.object === item.id).rooms.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)"
                  @selected-rooms="storeSelectedRooms"
                  @deleted-rooms="storeDeletedRooms" />
              </div>
            </tab-content>
            <tab-content
              :key="index"
              :title="getTabTitle('devices', item)">
              <div
                v-if="loading"
                class="text-center">
                <coozzy-spinner />
              </div>
              <handover-devices-step
                v-if="preSelectedRooms.find(x => x.object === item.id)"
                :key="item.id + '-' + preSelectedRooms.length"
                :object="item"
                :devices="listDevices.filter(x => x.referenceId === item.id)"
                :pre-selected-rooms="preSelectedRooms.find(x => x.object === item.id).rooms.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)"
                @selected-rooms="storeSelectedRooms"
                @deleted-devices="storeDeletedDevices"
                @devices-without-rooms="storeDevicesWithoutRooms" />
            </tab-content>
            <tab-content
              :key="index"
              :title="getTabTitle('keys', item)">
              <div
                v-if="loading"
                class="text-center">
                <coozzy-spinner />
              </div>
              <handover-keys-step
                v-else-if="preSelectedRooms.find(x => x.object === item.id) && activeTab % 3 === 0 && activeTab !== 0"
                :key="item.id + '-' + preSelectedRooms.length"
                :object="item"
                :self-keys="devicesWithoutRooms.filter(x => x.referenceId === item.id)"
                :pre-selected-rooms="preSelectedRooms.find(x => x.object === item.id).rooms.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)"
                @selected-rooms="storeSelectedRooms"
                @deleted-keys="storeDeletedDevices"
                @update-keys="storeDevicesWithoutRooms" />
            </tab-content>
          </template>
          <template
            slot="footer"
            slot-scope="props">
            <template v-if="!loading">
              <div class="wizard-footer-left">
                <wizard-button
                  v-if="props.activeTabIndex === 0"
                  :style="props.fillButtonStyle"
                  @click.native="$router.back()">
                  {{ $t('message.generic.cancel') }}
                </wizard-button>
                <wizard-button
                  v-else
                  :style="props.fillButtonStyle"
                  @click.native="backClicked(props)">
                  {{ $t('message.generic.back') }}
                </wizard-button>
              </div>
              <div class="wizard-footer-right">
                <wizard-button
                  v-if="!props.isLastStep && props.activeTabIndex === 0"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="nextStep(props)">
                  {{ $t('message.generic.next') }}
                </wizard-button>
                <wizard-button
                  v-else-if="props.isLastStep"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  :disabled="disabledSave"
                  @click.native="nextStep(props)">
                  <template>
                    {{ $t('message.generic.form.save') }}
                  </template>
                </wizard-button>
                <wizard-button
                  v-else
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="nextStep(props)">
                  {{ $t('message.enterTermination.saveAndNext') }}
                </wizard-button>
              </div>
            </template>
          </template>
        </form-wizard>
      </div>
    </div>
    <b-modal
      id="confirmationModal"
      ref="confirmationModal"
      no-close-on-backdrop
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="confirmCancellation">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import RoomApi from '@/misc/apis/RoomApi'
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import HandoverTenancyMove from '@/handover/component/HandoverTenancyMove'
import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import { onboarding } from '@/mixins/onboarding'
import { routeChecks } from '@/mixins/routeChecks'
import HandoverOtherTenancies from '@/handover/HandoverOtherTenancies'
import HandoverDevicesStep from '@/handover/component/HandoverDevicesStep.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import { user } from '@/mixins/user'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle.vue'
import HandoverRoomsStep from '@/handover/component/HandoverRoomsStep.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import { mapGetters } from 'vuex'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import { required } from 'vuelidate/lib/validators'
import HandoverKeysStep from '@/handover/component/HandoverKeysStep.vue'
import { emptyHandoverProtocol } from '@/types/helper'

export default {
  name: 'ProcessHandoverView',
  components: {
    CoozzyFormInput,
    CoozzyFormSelect,
    HandoverRoomsStep,
    CoozzyPageTitle,
    CoozzyButton,
    CoozzySpinner,
    HandoverOtherTenancies,
    HandoverTenancyMove,
    HandoverDevicesStep,
    HandoverKeysStep
  },
  mixins: [formatting, obj, onboarding, routeChecks, user],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  beforeRouteLeave (to, from, next) {
    this.nextToPath = to
    if (!this.confirmCancellationProcess) {
      this.$refs.confirmationModal.show()
    } else {
      next()
    }
  },
  props: {
    objectId: {
      type: String,
      required: true
    },
    tenancyId: {
      type: [String, Number],
      default: ''
    },
    buildingId: {
      type: [String, Number],
      default: ''
    },
    tenancyAfterVacancy: {
      type: Object,
      default: null
    },
    currentTenancySigned: {
      type: Object,
      default: null
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.processHandover.title')
    }
  },
  data() {
    return {
      activeTab: 0,
      devicesWithoutRooms: [],
      allTenancies: [],
      activeTabIndex: 0,
      listDevices: [],
      availableObjects: [],
      deletedRooms: [],
      deletedDevices: [],
      scheduledDate: null,
      scheduledTime: null,
      handoverObject: emptyHandoverProtocol,
      disabledSave: false,
      anyContract: false,
      addDocumentToContact: false,
      contactIds: [],
      detailsOfTenants: [],
      lastTenancy: null,
      loading: false,
      object: null,
      rooms: [],
      objectsWithSameTenants: [],
      sameTenanciesMovingOut: [],
      sameTenanciesMovingIn: [],
      selectedEntriesContract: [],
      selectedEntriesReview: [],
      selectedObjectToProcess: [],
      preventChangesInSelectedObjectToProcess: false,
      tenancy: null,
      visibleModal: false,
      confirmationReviewContacts: false,
      confirmCancellationProcess: false,
      nextToPath: null,
      selectedRooms: [],
      building: null,
      listObjects: [],
      timeList: [],
      contact: null
    }
  },
  computed: {
    filterSameTenanciesMovingInBySelectedObject() {
      // filter the sameTenanciesMovingIn by the selected objects
      return this.sameTenanciesMovingIn.filter(r => this.selectedObjectToProcess.map(x => x.id).includes(r.objectId))
    },
    filterSameTenanciesMovingInBySelectedObjectAndNoSigned() {
      // filter the sameTenanciesMovingIn by the selected objects and get only next tenancy (currentTenancySigned)
      const s = (this.sameTenanciesMovingIn.concat(this.sameTenanciesMovingOut)).filter(r => r?.id === this.currentTenancySigned?.id)
      const array = this.filterSameTenanciesMovingInBySelectedObject.concat(s)
      // remove duplicated object on array
      return array.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
    },
    filterSameTenanciesMovingOutBySelectedObject() {
      // filter the sameTenanciesMovingOut by the selected objects
      return this.sameTenanciesMovingOut.filter(r => this.selectedObjectToProcess.map(x => x.id).includes(r.objectId))
    },
    availableEmployees() {
      return this.getEmployees().filter(e => !e.blocked && !e.deleted)
    },
    availableEmployeeContacts() {
      return this.availableEmployees
        .map(e => this.employeeContacts.find(c => c.email === e.email))
        .filter(e => !!e)
    },
    preSelectedRooms() {
      return this.selectedRooms
    },
    objectAddress() {
      if (this.object) {
        return this.$t('message.onBoarding.buildings.address') + ': ' + this.object.address.street + ' ' + this.object.address.streetNumber + ', ' + this.object.address.zip + ' ' + this.object.address.city
      } else {
        return '-'
      }
    },
    sameTenanciesByObject() {
      return this.sameTenanciesMovingIn.concat(this.sameTenanciesMovingOut).concat(this.allTenancies).filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
    },
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/'
    },
    prevRouteQuery() {
      return this.prevRoute ? this.prevRoute.query : null
    },
    buildingName() {
      if (this.building) {
        return this.building.name
      } else {
        return '-'
      }
    },
    objectsWithSameTenantsComputed() {
      return this.objectsWithSameTenants.filter(x => this.selectedObjectToProcess.map(x => x.id).includes(x.id))
    },
    getLoading() {
      return this.loading
    }
  },
  watch: {
    availableEmployeeContacts: function (newVal, oldVal) {
      if (newVal.length > 0 && JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.handoverObject.assigneeContactId = this.availableEmployeeContacts.find(e => e.email === this.userEmail)?.id
      }
    }
  },
  mounted() {
    this.getListTimes()
    this.loading = true
    const t = this
    ObjectApi.getObject(this.objectId).then(values => {
      this.object = values.object
      this.objectsWithSameTenants.push(this.object)
      this.availableObjects.push(this.object)
      this.loadBuilding(this.object.buildingId)
      this.loadRooms()
      ObjectApi.getTenancies([this.object.id]).then(response => {
        if (response.tenancies.length > 0) {
          this.allTenancies = this.allTenancies.concat(response.tenancies)
          if (response.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT_TERMINATED')) {
            this.lastTenancy = response.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT_TERMINATED')
          } else {
            this.lastTenancy = response.tenancies.sort(function (a, b) {
              return new Date(t.$options.filters.objectToDateInput(b.endingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.endingAt)).getTime()
            })[0]
          }
          const promises = []
          if (this.lastTenancy && this.lastTenancy.tenant.contactIds) {
            promises.push(ObjectApi.getTenanciesByTenant(this.lastTenancy.ownerId, this.lastTenancy.tenant.contactIds[0]).then((resp) => {
              let allReceivedTenancies = resp.tenancies
              this.allTenancies = this.allTenancies.concat(allReceivedTenancies)
              allReceivedTenancies = allReceivedTenancies.concat(response.tenancies)
              // remove duplicates
              allReceivedTenancies = allReceivedTenancies.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
              // get all contactIds from all tenancies and remove duplicated
              const listContactIds = allReceivedTenancies.map(x => x.tenant.contactIds).flat().filter((v, i, a) => a.findIndex(t => (t === v)) === i)
              // get all contactIds from all tenancies without duplicates
              const uniqueContactIds = [...new Set(listContactIds)]
              ContactApi.contactResolve(uniqueContactIds).then((response) => {
                this.detailsOfTenants = response.persons.concat(response.companies)
              })
              const date = new Date()
              date.setDate(date.getDate() - 1)
              date.setUTCHours(23, 59, 59, 999)
              const objectTodayOrFuture = allReceivedTenancies.filter(x => new Date(t.$options.filters.objectToDateInput(x.startingAt)).getTime() > new Date().getTime() || new Date(t.$options.filters.objectToDateInput(x.endingAt)).getTime() > date.getTime())
              for (let index = 0; index < allReceivedTenancies.length; index++) {
                // Check if today is closer to startingAt than to endingAt of allReceivedTenancies[index]
                const today = new Date()
                const tenancy = allReceivedTenancies[index]
                const startingAt = new Date(t.$options.filters.objectToDateInput(tenancy.startingAt))
                const endingAt = new Date(t.$options.filters.objectToDateInput(tenancy.endingAt))

                const diffToStart = Math.abs(today - startingAt)
                const diffToEnd = Math.abs(today - endingAt)

                if (diffToStart > diffToEnd && allReceivedTenancies[index].endingAt && new Date(t.$options.filters.objectToDateInput(allReceivedTenancies[index].endingAt)).getTime() > date.getTime()) {
                  this.sameTenanciesMovingOut.push(allReceivedTenancies[index])
                } else if (allReceivedTenancies[index].startingAt && ((new Date(t.$options.filters.objectToDateInput(allReceivedTenancies[index].startingAt)).getTime() >= date.getTime() || (new Date(t.$options.filters.objectToDateInput(allReceivedTenancies[index].startingAt)).getTime() > date.getTime() && !allReceivedTenancies[index].vacancy)))) {
                  this.sameTenanciesMovingIn.push(allReceivedTenancies[index])
                }
              }
              // remove duplicate from sameTenanciesMovingOut and sameTenanciesMovingIn
              this.sameTenanciesMovingOut = this.sameTenanciesMovingOut.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
              this.sameTenanciesMovingIn = this.sameTenanciesMovingIn.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
              ObjectApi.listObjectsByIds((this.sameTenanciesMovingIn.concat(this.sameTenanciesMovingOut)).map(x => x.objectId)).then((response) => {
                const allReceivedObjects = response.objects
                for (let index = 0; index < allReceivedObjects.length; index++) {
                  if (this.objectsWithSameTenants.map(x => x.id).includes(allReceivedObjects[index].id)) {
                    continue
                  }
                  let listObjectId = []
                  this.objectsWithSameTenants.push(allReceivedObjects[index])
                  if (objectTodayOrFuture.map(x => x.objectId).includes(allReceivedObjects[index].id)) {
                    this.availableObjects.push(allReceivedObjects[index])
                    listObjectId.push(allReceivedObjects[index].id)
                  }
                  if (listObjectId.length > 0) {
                    promises.push(ObjectApi.getTenancies(listObjectId).then((response) => {
                      if (response.tenancies.length > 0) {
                        this.allTenancies = this.allTenancies.concat(response.tenancies)
                        const otherTenancies = response.tenancies
                        for (let index = 0; index < otherTenancies.length; index++) {
                          if (otherTenancies[index].endingAt && new Date(t.$options.filters.objectToDateInput(otherTenancies[index].endingAt)).getTime() >= date.getTime()) {
                            this.sameTenanciesMovingOut.push(otherTenancies[index])
                          } else if (otherTenancies[index].startingAt && ((new Date(t.$options.filters.objectToDateInput(otherTenancies[index].startingAt)).getTime() >= date.getTime()) || (new Date(t.$options.filters.objectToDateInput(otherTenancies[index].startingAt)).getTime() > date.getTime() && !otherTenancies[index].vacancy))) {
                            this.sameTenanciesMovingIn.push(otherTenancies[index])
                          }
                        }
                        const listContactIds = otherTenancies.map(x => x.tenant.contactIds).flat().filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                        const contactIds = [...new Set(listContactIds)]
                        ContactApi.contactResolve(contactIds).then((response) => {
                          const list = this.detailsOfTenants.concat(response.persons.concat(response.companies))
                        //   check list and remove duplicates
                          this.detailsOfTenants = list.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
                        })
                      }
                    }))
                  }
                }
                this.loading = false
                this.loadRooms()
                this.loadDevices()
              })
            }))
          }
        }
        this.loading = false
      })
    }).catch(e => {
      this.loading = false
      console.log(e)
      Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
    })
      .finally(() => {
        this.loading = false
        this.loadRooms()
      })
  },
  methods: {
    ...mapGetters('employee', ['getEmployees']),
    contactUpdated(contact) {
      // change contact on detailsOfTenants by id
      const index = this.detailsOfTenants.findIndex(x => x.id === contact.id)
      if (index !== -1) {
        this.$set(this.detailsOfTenants, index, contact)
      } else {
        this.detailsOfTenants.push(contact)
      }
    },
    getListTimes() {
      let hours, minutes
      const list = []
      for (let i = 360; i <= 1425; i += 15) {
        hours = Math.floor(i / 60)
        minutes = i % 60
        if (minutes < 10) {
          minutes = '0' + minutes // adding leading zero
        }
        if (hours < 10) {
          hours = '0' + hours // adding leading zero
        }
        if (hours === 24) {
          hours = '00'
        }
        list.push(hours + ':' + minutes)
      }
      this.timeList = list
    },
    loadDevices() {
      ObjectApi.getDevicesByReferenceIds(this.availableObjects.map(x => x.id))
        .then(response => {
          this.listDevices = response.devices
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    loadRooms() {
      RoomApi.getRoomsByReferenceIds(this.objectsWithSameTenants.map(x => x.id))
        .then(response => {
          this.rooms = this.rooms.concat(response.rooms)
          this.loading = false
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    storeSelectedRooms(rooms, object, from) {
      if (rooms.length > 0 && (this.activeTab === 0 || (this.activeTab % 3 === 2 && from === 'device') || (this.activeTab % 3 === 1 && from === 'rooms'))) {
        if (this.selectedRooms.find((room) => room.object === object.id)) {
          // update room of selected room by object id
          this.$set(this.selectedRooms, this.selectedRooms.findIndex((room) => room.object === object.id), { rooms: rooms, object: object.id })
        } else {
          this.selectedRooms.push({ rooms: rooms, object: object.id })
        }
      }
    },
    storeDeletedRooms(rooms, object) {
      const item = this.deletedRooms.find((room) => room.object === object.id)
      if (item) {
        this.deletedRooms.find((room) => room.object === object.id).rooms.concat(rooms)
      } else {
        this.deletedRooms.push({ rooms: rooms, object: object.id })
      }
    },
    storeDeletedDevices(devices, object) {
      const item = this.deletedDevices.find((device) => device.object === object.id)
      if (item) {
        this.deletedDevices.find((device) => device.object === object.id).devices.concat(devices)
      } else {
        this.deletedDevices.push({ devices: devices, object: object.id })
      }
    },
    loadBuilding(buildingId) {
      ObjectApi.getBuilding(buildingId)
        .then(response => {
          this.building = response.building
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    confirmCancellation() {
      this.confirmCancellationProcess = true
      if (this.nextToPath !== null) {
        this.$router.push({ name: this.nextToPath.name, params: this.nextToPath.params, query: this.nextToPath.query }, () => {
        })
      } else {
        this.$router.push({ path: this.prevRoutePath, query: this.prevRouteQuery }, () => {
        })
      }
    },
    handleTabChange(prevIndex, nextIndex) {
      this.activeTab = nextIndex
      this.preventChangesInSelectedObjectToProcess = nextIndex !== 0
    },
    selectedObject(object) {
      this.selectedObjectToProcess = object
      this.selectedRooms = []
      this.selectedObjectToProcess.forEach(element => {
        this.selectedRooms.push({ rooms: this.rooms.filter(r => r.referenceId === element.id), object: element.id })
      })
    },
    storeDevicesWithoutRooms(devices, object) {
      this.devicesWithoutRooms = JSON.parse(JSON.stringify(this.devicesWithoutRooms.filter(x => x.referenceId !== object.id)))
      this.devicesWithoutRooms = this.devicesWithoutRooms.concat(devices)
      // remove duplicated devices
      this.devicesWithoutRooms = this.devicesWithoutRooms.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
    },

    async nextStep(props) {
      this.activeTabIndex = props.activeTabIndex + 1
      if (props.activeTabIndex === 0) {
        this.$v.$touch()
        if (this.scheduledTime === null || this.scheduledDate === null) {
          return
        }
        this.handoverObject.scheduledTime = {
          hours: parseInt(this.scheduledTime.substr(0, 2)),
          minutes: parseInt(this.scheduledTime.substr(3, 2))
        }
        this.handoverObject.scheduledDate = {
          year: parseInt(this.scheduledDate.substr(0, 4)),
          month: parseInt(this.scheduledDate.substr(5, 2)),
          day: parseInt(this.scheduledDate.substr(8, 2))
        }
        props.nextTab()
        this.scrollToTop()
      } else {
        await this.saveStep(props)
        this.$nextTick(() => {
          if (props.isLastStep) {
            this.disabledSave = true
            this.confirmCancellationProcess = true
          }
          props.nextTab()
          this.scrollToTop()
        })
      }
    },
    async saveStep(props) {
      if (props.activeTabIndex % 3 === 1) {
        if (this.selectedRooms.length > 0) {
          const indexOfSteps = Math.floor((props.activeTabIndex - 1) / 3)
          const roomToUpdate = this.selectedRooms[indexOfSteps].rooms.filter(room => room.edited)
          let roomToDelete = this.deletedRooms.find(r => r.object === this.selectedRooms[indexOfSteps].object) || []
          if (roomToDelete && roomToDelete.rooms?.length > 0) {
            roomToDelete = roomToDelete.rooms.map(r => r)
            // remove the rooms that are not started with room_
            roomToDelete = roomToDelete.filter(r => typeof r === 'string' && r.startsWith('room_'))
          }
          const roomToAdd = this.selectedRooms[indexOfSteps].rooms.filter(room => typeof room.id !== 'string' || !room?.id?.startsWith('room_'))
          const promises = []
          if (roomToUpdate.length > 0) {
            promises.push(RoomApi.updateRooms(roomToUpdate))
          }
          if (roomToAdd.length > 0) {
            promises.push(RoomApi.createRooms(roomToAdd))
          }
          if (roomToDelete.length > 0) {
            promises.push(RoomApi.deleteRooms(roomToDelete))
          }
          await Promise.all(promises)
            .then(responses => {
              const currentObject = this.selectedRooms[indexOfSteps].object
              const deletedRoomsIds = this.deletedRooms.find(r => r.object === currentObject)?.rooms?.map(r => r) || []
              this.rooms = this.rooms.filter(d => !d.internalId && !deletedRoomsIds.some(r => r === d.id))
              if (responses.length > 0) {
                // update every room from selectedRoom with the new from response
                this.selectedRooms[indexOfSteps].rooms.forEach((room, indexRoom) => {
                  if (room.edited) {
                    this.selectedRooms[indexOfSteps].rooms[indexRoom] = responses[0].rooms?.find(r => r.id === room.id) ? responses[0].rooms?.find(r => r.id === room.id) : responses[1].rooms?.find(r => r.id === room.id)
                  }
                })
                this.selectedRooms[indexOfSteps].rooms = this.selectedRooms[indexOfSteps].rooms.filter(r => typeof r.id === 'string' && r.id.startsWith('room_'))
                responses.forEach((response) => {
                  if (response?.rooms) {
                    this.selectedRooms[indexOfSteps].rooms = this.selectedRooms[indexOfSteps].rooms.concat(response.rooms)
                    this.rooms = this.rooms.concat(response.rooms)
                  }
                })
                // remove delete rooms from selectedrooms
                this.selectedRooms[indexOfSteps].rooms = this.selectedRooms[indexOfSteps].rooms.filter(r => !roomToDelete.includes(r.id))
                this.$nextTick(() => {
                  this.deletedRooms = []
                })
              }
            }).catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.onboarding'), { type: 'error' })
            })
        }
      } else if (props.activeTabIndex % 3 === 2) {
        const indexOfStep = Math.floor((props.activeTabIndex - 2) / 3)
        const currentObject = this.selectedRooms[indexOfStep].object
        // create the new devices and update the edited devices
        const devicesToAdd = []
        const devicesToUpdate = []
        let deviceToDelete = this.deletedDevices.find(r => r.object === currentObject) || []
        if (deviceToDelete && deviceToDelete.devices?.length > 0) {
          deviceToDelete = deviceToDelete.devices.map(r => r)
          deviceToDelete = deviceToDelete.filter(r => typeof r === 'string' && r.startsWith('device_'))
        }
        for (let index = 0; index < this.selectedRooms[indexOfStep].rooms.length; index++) {
          if (this.selectedRooms[indexOfStep].rooms[index].devices) {
            this.selectedRooms[indexOfStep].rooms[index].devices.forEach((device) => {
              device.roomId = this.selectedRooms[indexOfStep].rooms[index].id
              device.referenceId = this.selectedRooms[indexOfStep].rooms[index].referenceId
              if (device.internalId || !device.id.startsWith('device_')) {
                devicesToAdd.push(JSON.parse(JSON.stringify(device)))
              } else if (device.edited && device.id) {
                devicesToUpdate.push(JSON.parse(JSON.stringify(device)))
              }
            })
          }
        }
        for (let index = 0; index < this.devicesWithoutRooms.filter(x => x.referenceId === currentObject).length; index++) {
          if (this.devicesWithoutRooms.filter(x => x.referenceId === currentObject)[index].internalId || !this.devicesWithoutRooms.filter(x => x.referenceId === currentObject)[index].id.startsWith('device_')) {
            devicesToAdd.push(this.devicesWithoutRooms.filter(x => x.referenceId === currentObject)[index])
          } else if (this.devicesWithoutRooms.filter(x => x.referenceId === currentObject)[index].edited) {
            devicesToUpdate.push(this.devicesWithoutRooms.filter(x => x.referenceId === currentObject)[index])
          }
        }
        const promises = []
        if (devicesToAdd.length > 0) {
          devicesToAdd.forEach((device) => {
            promises.push(ObjectApi.createDevice(device))
          })
        }
        if (devicesToUpdate.length > 0) {
          devicesToUpdate.forEach((device) => {
            promises.push(ObjectApi.updateDevice(device))
          })
        }
        if (deviceToDelete.length > 0) {
          deviceToDelete.forEach((device) => {
            promises.push(ObjectApi.deleteDevice(device))
          })
        }
        await Promise.all(promises)
          .then(responses => {
            const deletedDevicesIds = this.deletedDevices.find(r => r.object === currentObject)?.devices?.map(r => r) || []
            this.listDevices = this.listDevices.filter(d => !d.internalId && !deletedDevicesIds.some(r => r === d.id))
            if (responses.length > 0) {
              const unassignedDevices = []
              responses.map(item => item.device).forEach((device) => {
                if (device) {
                  this.listDevices = this.listDevices.filter(d => d.id !== device.id)
                  this.listDevices.push(device)
                  if (device?.roomId === '') {
                    unassignedDevices.push(device)
                  }
                }
              })
              if (unassignedDevices.length > 0) {
                this.devicesWithoutRooms = JSON.parse(JSON.stringify(this.devicesWithoutRooms.filter(x => !x.internalId)))
                unassignedDevices.forEach((device) => {
                  if (!this.devicesWithoutRooms.find(x => x.id === device.id)) {
                    this.devicesWithoutRooms.push(device)
                  } else {
                    const index = this.devicesWithoutRooms.findIndex(x => x.id === device.id)
                    if (index !== -1) {
                      this.$set(this.devicesWithoutRooms, index, device)
                    }
                  }
                })
              }
              for (let index = 0; index < this.selectedRooms[indexOfStep].rooms.length; index++) {
                if (this.selectedRooms[indexOfStep].rooms[index].devices) {
                  // remove the device with internalId
                  this.selectedRooms[indexOfStep].rooms[index].devices = this.selectedRooms[indexOfStep].rooms[index].devices.filter(d => !d.internalId)
                  // add the new devices for the room by roomId
                  responses.map(item => item.device).forEach((device) => {
                    if (device?.roomId === this.selectedRooms[indexOfStep].rooms[index].id) {
                      device.value = device.type === '' || device.type === null || device.type === 'DEVICE_TYPE_UNDEFINED' ? '-' : this.$t('message.onBoarding.devices.typeValues.' + device.type)
                      const deviceIndex = this.selectedRooms[indexOfStep].rooms[index].devices.findIndex(d => d.id === device.id)
                      if (deviceIndex !== -1) {
                        this.$set(this.selectedRooms[indexOfStep].rooms[index].devices, deviceIndex, device)
                      } else {
                        this.selectedRooms[indexOfStep].rooms[index].devices.push(device)
                      }
                    }
                  })
                  // this.selectedRooms[(props.activeTabIndex / 2) - 1].rooms[index].devices = this.selectedRooms[(props.activeTabIndex / 2) - 1].rooms[index].devices.concat(responses.map(item => item.device).filter(r => r.roomId === this.selectedRooms[(props.activeTabIndex / 2) - 1].rooms[index].id))
                }
              }
              this.$nextTick(() => {
                this.deletedDevices = []
              })
            } else {
              this.$nextTick(() => {
                this.deletedDevices = []
              })
            }
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.onboarding'), { type: 'error' })
          })
          .finally(() => {
            // set the newest value of selectedRooms
            this.selectedRooms = JSON.parse(JSON.stringify(this.selectedRooms))
          })
      } else if (props.activeTabIndex % 3 === 0 && props.activeTabIndex !== 0) {
        const indexOfStep = Math.floor((props.activeTabIndex - 3) / 3)
        const currentObject = this.selectedRooms[indexOfStep].object
        // create the new devices and update the edited devices
        const devicesToAdd = []
        const devicesToUpdate = []
        let deviceToDelete = this.deletedDevices.find(r => r.object === currentObject) || []
        if (deviceToDelete && deviceToDelete.devices?.length > 0) {
          deviceToDelete = deviceToDelete.devices.map(r => r)
          deviceToDelete = deviceToDelete.filter(r => typeof r === 'string' && r.startsWith('device_'))
        }
        for (let index = 0; index < this.devicesWithoutRooms.filter(x => x.referenceId === currentObject).length; index++) {
          if (!this.devicesWithoutRooms.filter(x => x.referenceId === currentObject)[index].id.startsWith('device_')) {
            devicesToAdd.push(this.devicesWithoutRooms.filter(x => x.referenceId === currentObject)[index])
          } else if (this.devicesWithoutRooms.filter(x => x.referenceId === currentObject)[index].edited) {
            devicesToUpdate.push(this.devicesWithoutRooms.filter(x => x.referenceId === currentObject)[index])
          }
        }
        const promises = []
        if (devicesToAdd.length > 0) {
          devicesToAdd.forEach((device) => {
            promises.push(ObjectApi.createDevice(device))
          })
        }
        if (devicesToUpdate.length > 0) {
          devicesToUpdate.forEach((device) => {
            promises.push(ObjectApi.updateDevice(device))
          })
        }
        if (deviceToDelete.length > 0) {
          deviceToDelete.forEach((device) => {
            promises.push(ObjectApi.deleteDevice(device))
          })
        }
        await Promise.all(promises)
          .then(responses => {
            if (responses.length > 0) {
              this.devicesWithoutRooms = JSON.parse(JSON.stringify(this.devicesWithoutRooms.filter(x => !x.internalId)))
              responses.forEach((response) => {
                if (response?.device) {
                  this.devicesWithoutRooms.push(response.device)
                }
              })
              this.$nextTick(() => {
                this.devicesWithoutRooms = JSON.parse(JSON.stringify(this.devicesWithoutRooms.filter(x => x.id.startsWith('device_'))))
                this.deletedDevices = []
                if (props.isLastStep) {
                  this.confirmCancellationProcess = true
                  this.saveHandoverProcess()
                }
              })
            } else {
              this.$nextTick(() => {
                this.deletedDevices = []
                if (props.isLastStep) {
                  this.confirmCancellationProcess = true
                  this.saveHandoverProcess()
                }
              })
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.onboarding'), { type: 'error' })
          })
      }
    },
    async backClicked(props) {
      if (!props.isLastStep) {
        await this.saveStep(props)
      }
      this.$nextTick(() => {
        props.prevTab()
        this.scrollToTop()
      })
    },
    scrollToTop() {
      this.$nextTick(() => {
        const domRect = this.$el.querySelector('#wizard')
        domRect.scrollIntoView(true)
      })
    },
    saveHandoverProcess() {
      const promises = []
      this.selectedObjectToProcess.forEach((object) => {
        let handoverItem = {
          rooms: [],
          participants: [],
          movingOutTenancyReferences: [],
          movingInTenancyReferences: [],
          devicesWithoutRoom: []
        }
        handoverItem.assigneeContactId = this.handoverObject.assigneeContactId
        handoverItem.note = this.handoverObject.note
        handoverItem.scheduledDate = this.handoverObject.scheduledDate
        handoverItem.scheduledTime = this.handoverObject.scheduledTime
        handoverItem.devicesWithoutRoom = this.devicesWithoutRooms.length > 0 ? this.devicesWithoutRooms.filter(r => r.referenceId === object.id && r.id.startsWith('device_')) : this.listDevices.filter(r => r.referenceId === object.id && (r.roomId === null || r.roomId === '') && r.id.startsWith('device_'))
        handoverItem.devicesWithoutRoom.forEach(device => {
          device.installationDate = device.installation
          device.keyData = device.lockingKey
          device.keyData.amount = device.amount
          device.keyData.lockingPlanNumber = device.lockingPlanNumber
          device.keyData.keyNumber = device.serialNumber
        })
        const selectedRoom = this.selectedRooms.find(r => r.object === object.id)
        selectedRoom.rooms.filter(x => x.devices).forEach((room) => {
          if (room.devices) {
            room.devices.forEach((device, index) => {
              room.devices[index].installationDate = device.installation
              room.devices[index].keyData = device.lockingKey
              room.devices[index].keyData.amount = device.amount
              room.devices[index].keyData.lockingPlanNumber = device.lockingPlanNumber
              room.devices[index].keyData.keyNumber = device.serialNumber
            })
          }
          handoverItem.rooms.push({ id: room.id, devices: room.devices, name: room.name, note: room.note || '' })
        })
        if (!this.currentTenancySigned) {
          this.sameTenanciesMovingOut.find(r => r.objectId === object.id)?.tenant.contactIds.forEach(contactId => {
            handoverItem.participants.push({ type: 'PARTICIPANT_TYPE_TENANT_MOVE_OUT', contactId: contactId })
          })
        }
        if (!this.tenancyAfterVacancy || this.currentTenancySigned) {
          this.sameTenanciesMovingIn.find(r => r.objectId === object.id)?.tenant.contactIds.forEach(contactId => {
            handoverItem.participants.push({ type: 'PARTICIPANT_TYPE_TENANT_MOVE_IN', contactId: contactId })
          })
        }
        if (this.handoverObject.assigneeContactId !== null) {
          handoverItem.participants.push({ type: 'PARTICIPANT_TYPE_ADMINISTRATOR', contactId: this.handoverObject.assigneeContactId })
        }
        this.sameTenanciesMovingOut.filter(r => r.objectId === object.id).forEach((tenancy) => {
          handoverItem.movingOutTenancyReferences.push({ tenancyId: tenancy.id, objectId: tenancy.objectId })
        })
        this.sameTenanciesMovingIn.filter(r => r.objectId === object.id).forEach((tenancy) => {
          handoverItem.movingInTenancyReferences.push({ tenancyId: tenancy.id, objectId: tenancy.objectId })
        })
        handoverItem.ownerId = this.lastTenancy.ownerId
        promises.push(ObjectApi.saveHandoverProcess(handoverItem))
      })
      if (promises.length > 0) {
        this.confirmCancellationProcess = true
        Promise.all(promises).then(() => {
          this.confirmCancellationProcess = true
          Vue.toasted.show(this.$t('message.successMessages.handoverProtocol'), { type: 'success' })
          if (this.prevRoutePath !== '/' && this.prevRouteQuery) {
            this.$router.push({ path: this.prevRoutePath, query: this.prevRouteQuery }, () => {
            })
          } else {
            this.$router.push({
              name: this.isOwnerModule ? 'OwnerBuildingDetailsView' : this.isAssetModule ? 'AssetBuildingDetailsView' : this.isAccountingModule ? 'AccountingBuildingDetailsView' : 'AdminBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'objects',
                elementSelected: this.objectId
              }
            })
          }
          this.disabledSave = false
        })
        .catch(e => {
          this.disabledSave = false
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.onboarding'), { type: 'error' })
        })
      }
    },
    addDocumentToTenancy(value) {
      this.addDocumentToContact = value
    },
    contractSelectedAction(value, contracts, objectId) {
      const item = {}
      item.value = value
      item.contracts = contracts
      item.objectId = objectId
      const index = this.selectedEntriesContract.findIndex(c => c.objectId === objectId)
      if (index !== -1) {
        this.selectedEntriesContract[index] = item
      } else {
        this.selectedEntriesContract.push(item)
      }
      this.isAnyContract()
    },
    isAnyContract() {
      this.anyContract = false
      this.selectedEntriesContract.forEach((entry) => {
        if (entry.value.length > 0) {
          this.anyContract = true
        }
      })
    },
    getTabTitle(type, item) {
      const tabTitle = this.$t(`message.processHandover.${type}.title`)
      if (this.selectedObjectToProcess.length > 1) {
        return `${tabTitle} | ${this.$t('message.onBoarding.buildings.objects.category.' + item.category.toLowerCase())} | ${item.numericId}`
      }
      return tabTitle
    }
  },
  validations: {
    scheduledDate: {
      required
    },
    scheduledTime: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
