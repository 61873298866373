import { render, staticRenderFns } from "./AdminRentalObjectOverview.vue?vue&type=template&id=6282e8d4&scoped=true"
import script from "./AdminRentalObjectOverview.vue?vue&type=script&lang=js"
export * from "./AdminRentalObjectOverview.vue?vue&type=script&lang=js"
import style0 from "./AdminRentalObjectOverview.vue?vue&type=style&index=0&id=6282e8d4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6282e8d4",
  null
  
)

export default component.exports