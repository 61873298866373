<template>
  <div
    v-if="isBusy"
    class="row">
    <!-- Busy -->
    <div
      class="col text-center">
      <coozzy-spinner />
    </div>
  </div>
  <section v-else>
    <application-form-company-basic
      ref="basic"
      :company="company"
      :sending="sending" />
    <hr>
    <application-form-company-contact-person
      ref="employment"
      :company="company"
      :sending="sending" />
    <hr>
    <application-form-tenancy
      ref="tenancy"
      :company="company"
      :sending="sending"
      :object="object" />
  </section>
</template>

<script>
import ApplicationFormCompanyBasic from '@/marketingBasic/components/applicationForm/ApplicationFormCompanyBasic'
import ApplicationFormCompanyContactPerson from '@/marketingBasic/components/applicationForm/ApplicationFormCompanyContactPerson'
import ApplicationFormTenancy from '@/marketingBasic/components/applicationForm/ApplicationFormTenancy'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'

export default {
  name: 'ApplicationFormCompany',
  components: {
    ApplicationFormTenancy,
    ApplicationFormCompanyBasic,
    ApplicationFormCompanyContactPerson,
    CoozzySpinner
  },
  props: {
    company: {
      type: Object,
      default: null
    },
    sending: {
      type: Boolean,
      default: false
    },
    isBusy: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
      default: null
    }
  },
  methods: {
    isInvalid() {
      let valide = true
      if (this.$refs.basic && this.$refs.basic.isInvalid()) {
        valide = false
      }
      if (this.$refs.employment && this.$refs.employment.isInvalid()) {
        valide = false
      }
      return !valide
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
