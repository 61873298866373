<template>
  <b-list-group
    v-bind="$attrs"
    v-on="mountEventListeners">
    <slot />
  </b-list-group>
</template>

<script>
export default {
  name: 'CoozzyListGroup',
  inheritAttrs: false,
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
