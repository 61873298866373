<template>
  <coozzy-card>
    <div class="row mb-2">
      <div class="col-10">
        <h4 class="card-title">
          {{ $t('message.navigation.settings.employees') }}
        </h4>
      </div>
      <div
        class="col-12 col-md-2 my-auto">
        <coozzy-button
          v-if="isCompanyAdmin && !isOwnerModule"
          class="float-md-right w-100"
          design="prop-green"
          @click="$refs['invite-employee-modal'].show()">
          {{ $t('message.employeesSettings.addEmployee') }}
        </coozzy-button>
        <invite-employee-modal
          ref="invite-employee-modal"
          @reload-employees="updateEmployees" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-2 offset-md-10">
        <coozzy-form-select
          v-model="nbrPerPage"
          name="numberEntries"
          class="select-entries float-right">
          <option value="5">
            5 {{ $t('message.generic.entries') }}
          </option>
          <option value="10">
            10 {{ $t('message.generic.entries') }}
          </option>
          <option value="15">
            15 {{ $t('message.generic.entries') }}
          </option>
          <option value="20">
            20 {{ $t('message.generic.entries') }}
          </option>
          <option value="all">
            {{ $t('message.manageInterestedParties.filter.allEntries') }}
          </option>
        </coozzy-form-select>
      </div>
    </div>
    <b-table
      ref="employeesTable"
      bordered
      hover
      responsive="true"
      stacked="md"
      selectable
      select-mode="single"
      no-select-on-click
      :busy="isBusy"
      :fields="fields"
      :items="employeeList"
      @row-clicked="onRowClicked">
      <div
        slot="table-busy"
        class="text-center text-danger my-2">
        <coozzy-spinner />
      </div>

      <!-- Headings -->
      <template #head(avatar)>
        {{ $t('message.generic.avatar') }}
      </template>
      <template #head(firstName)>
        {{ $t('message.generic.firstName') }}
      </template>
      <template #head(lastName)>
        {{ $t('message.generic.lastName') }}
      </template>
      <template #head(email)>
        {{ $t('message.generic.email') }}
      </template>
      <template #head(permissions)>
        {{ $t('message.generic.permissions') }}
      </template>
      <template #head(phoneNumber)>
        {{ $t('message.generic.phoneNumber') }}
      </template>
      <template #head(color)>
        {{ $t('message.generic.color') }}
      </template>
      <template #head(status)>
        {{ $t('message.generic.status') }}
      </template>

      <!-- Data cells -->
      <template
        #cell(avatar)="data">
        <img
          :class="['align-self-center', 'avatar-image', 'cursor-pointer']"
          :src="[data.item.profile.pictureUrl === '' ? '/avatar.png' : data.item.profile.pictureUrl]"
          alt="avatar">
      </template>
      <template
        #cell(firstName)="data">
        {{ data.item.profile.firstName | displayOptionalValue }}
      </template>
      <template
        #cell(lastName)="data">
        {{ data.item.profile.lastName | displayOptionalValue }}
      </template>
      <template
        #cell(email)="data">
        {{ data.item.email | displayOptionalValue }}
      </template>
      <template
        #cell(permissions)="data">
        {{ getPermission (data.item.roles) }}
      </template>
      <template
        #cell(phoneNumber)="data">
        {{ data.item.profile.phoneNumber | displayOptionalValue }}
      </template>
      <template
        #cell(color)="data">
        <div
          class="color-box"
          :style="{ background: getColor(data.item.profile.color) }" />
      </template>
      <template
        #cell(status)="data">
        <coozzy-button
          v-if="data.item.blocked"
          class="btn-inactive"
          @click="unBlockUser(data.item.id)">
          {{ $t('message.generic.inactive') }}
        </coozzy-button>
        <coozzy-button
          v-else
          class="btn-active"
          @click="blockUser(data.item.id)">
          {{ $t('message.generic.active') }}
        </coozzy-button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      align="center"
      class="mt-3 mb-3"
      :total-rows="employeeCount"
      :per-page="nbrPerPage" />
    <edit-employee-modal
      ref="edit-employee-modal"
      :key="selectedEmployee ? 'edit-employee-modal'+selectedEmployee.id : 'edit-employee-modal'"
      :user-to-edit="selectedEmployee"
      :allow-email-change="false"
      @endEdit="updateEmployees()" />
  </coozzy-card>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import { user } from '@/mixins/user'
// import EmployeeApi from '@/misc/apis/EmployeeApi'
import EditEmployeeModal from './EditEmployeeModal'
import InviteEmployeeModal from './InviteEmployeeModal'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import Vue from 'vue'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import UserApi from '../../misc/apis/UserApi'
import { routeChecks } from '@/mixins/routeChecks'
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'EmployeeSettings',
  components: {
    CoozzySpinner,
    CoozzyFormSelect,
    CoozzyButton,
    CoozzyCard,
    EditEmployeeModal,
    InviteEmployeeModal
  },
  mixins: [user, routeChecks],
  data() {
    return {
      isBusy: false,
      nbrPerPage: 5,
      currentPage: 1,
      employees: [],
      keyData: 1,
      selectedEmployee: null,
      fields: [
        {
          key: 'avatar',
          label: this.$t('message.generic.avatar'),
          tdClass: 'align-middle'
        },
        {
          key: 'firstName',
          label: this.$t('message.generic.firstName'),
          tdClass: 'align-middle'
        },
        {
          key: 'lastName',
          label: this.$t('message.generic.lastName'),
          tdClass: 'align-middle'
        },
        {
          key: 'email',
          label: this.$t('message.generic.email'),
          tdClass: 'align-middle'
        },
        {
          key: 'permissions',
          label: this.$t('message.generic.permissions'),
          tdClass: 'align-middle'
        },
        {
          key: 'phoneNumber',
          label: this.$t('message.generic.phoneNumber'),
          tdClass: 'align-middle'
        },
        {
          key: 'color',
          label: this.$t('message.generic.color'),
          thClass: 'text-center',
          tdClass: 'text-center align-middle'
        }
      ]
    }
  },
  computed: {
    employeeList() {
      const employees = this.$store.getters['employee/getEmployees'].filter(
        emp => !emp.deleted
      ).sort(function(a, b) {
        if (a.blocked === b.blocked) return 0
        if (!a.blocked) return -1
        if (a.blocked) return 1
        return 0
      })
      let list = []
      if (this.nbrPerPage === 'all') {
        list = employees
      } else {
        list = employees.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
      }
      return list
    },
    employeeCount() {
      return this.$store.getters['employee/getEmployees'].filter(emp => !emp.deleted).length
    }
  },
  mounted() {
    this.loadEmployees({
      loadInactive: true
    })
    if (this.isCompanyAdmin) {
      this.fields.push({
        key: 'status',
        label: this.$t('message.generic.status'),
        tdClass: 'align-middle status-cell cursor-pointer'
      })
    }
  },
  methods: {
    ...mapMutations('employee', ['updateEmployee']),
    ...mapActions('employee', ['loadEmployees']),
    getPermission(roles) {
      if (roles.length === 0 || (roles.length === 1 && roles[0] === 'company_default')) {
        return this.$t('message.contact.types.USER')
      } else if (roles.filter(r => r === 'company_admin').length > 0) {
        return this.$t('message.employeesSettings.roles.admin')
      } else {
        return this.$t('message.contact.types.USER')
      }
    },
    blockUser(userId) {
      UserApi.blockUser([userId])
        .then(() => {
          for (let index = 0; index < this.employeeList.length; index++) {
            if (this.employeeList[index].id === userId) {
              this.employeeList[index].blocked = true
              this.updateEmployee(this.employeeList[index])
            }
          }
        })
        .catch(e => {
          if (e.response.status === 400 && e.response.data.code === 9) {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.employeeOnlyAdmin'), { type: 'error' })
          } else {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.employee'), { type: 'error' })
          }
        })
    },
    unBlockUser(userId) {
      UserApi.unBlockUser([userId])
        .then(() => {
          for (let index = 0; index < this.employeeList.length; index++) {
            if (this.employeeList[index].id === userId) {
              this.employeeList[index].blocked = false
              this.updateEmployee(this.employeeList[index])
            }
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.employee'), { type: 'error' })
        })
    },
    onRowClicked(employee) {
      if (this.userId === employee.id || this.isCompanyAdmin) {
        this.selectedEmployee = null
        if (employee) {
          this.selectedEmployee = employee
          this.$nextTick(function () {
            this.$refs['edit-employee-modal'].show()
          })
        }
      }
    },
    componentToHex(c) {
      const hex = c.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    },
    rgbToHex(r, g, b) {
      return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b)
    },
    getColor(color) {
      if (color) {
        let colorCss = this.rgbToHex(color.red, color.green, color.blue)
        colorCss += color.alpha !== null ? (color.alpha * 255).toString(16).substring(0, 2) : ''
        return colorCss
      } else {
        return '-'
      }
    },
    updateEmployees() {
      this.loadEmployees(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.color-box {
  float: none;
  height: 20px;
  width: 20px;
  margin-left: auto;
  margin-right: auto;
}
.avatar-image{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>
