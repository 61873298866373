<template>
  <coozzy-card :title="$t('message.marketingSettings.applicationForm.title')">
    <b-card-sub-title>
      {{ $t('message.marketingSettings.applicationForm.subTitle') }}
    </b-card-sub-title>
    <div
      v-if="loadingSettings || loadingAccount"
      class="row">
      <div class="col text-center">
        <coozzy-spinner />
      </div>
    </div>
    <template v-else>
      <div class="row">
        <div class="col-12 mt-3">
          <coozzy-form-checkbox
            v-model="showContractCreationCostAgreement"
            :initial="showContractCreationCostAgreement"
            :disabled="readOnly">
            {{ $t('message.marketingSettings.applicationForm.contractCreationCost1') }}
            <coozzy-form-input-numeric
              v-model="contractCreationCost"
              :check-valide="$v.contractCreationCost && $v.contractCreationCost.$dirty && $v.contractCreationCost.$error ? 'is-invalid' : ''"
              class="cost-input"
              :is-read-only="readOnly" />
            {{ $t('message.marketingSettings.applicationForm.contractCreationCost2') }}
          </coozzy-form-checkbox>
        </div>
        <div class="col-12 mt-3">
          <coozzy-form-checkbox
            :disabled="true"
            :initial="true">
            {{ $t('message.marketingSettings.applicationForm.obtainInfoAgreement', { name: name }) }}
          </coozzy-form-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <coozzy-button
            v-if="isCompanyAdmin"
            design="primary"
            class="float-right"
            @click="saveClicked">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </div>
    </template>
  </coozzy-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import RequestApi from '@/marketingBasic/api/RequestApi'
import { user } from '@/mixins/user'
import Vue from 'vue'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import UserApi from '@/misc/apis/UserApi'

export default {
  name: 'ApplicationFormSettings',
  components: {
    CoozzySpinner,
    CoozzyButton,
    CoozzyFormInputNumeric,
    CoozzyFormCheckbox,
    CoozzyCard
  },
  mixins: [user],
  data() {
    return {
      loadingSettings: false,
      loadingAccount: false,
      showContractCreationCostAgreement: false,
      contractCreationCost: null,
      account: null
    }
  },
  computed: {
    name() {
      if (this.account && this.account.company) {
        return this.account.company.name
      } else if (this.account && this.account.individual) {
        return this.account.individual.firstName + ' ' + this.account.individual.lastName
      }

      return ''
    },
    readOnly() {
      return !this.isCompanyAdmin
    }
  },
  mounted() {
    this.loadingSettings = true
    this.loadingAccount = true
    UserApi.getAccount(this.accountId)
      .then(response => {
        this.account = response.account
      })
      .catch(() => {
        Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
      })
      .finally(() => {
        this.loadingAccount = false
      })
    RequestApi.getApplicationFormSettings(this.accountId)
      .then(response => {
        this.showContractCreationCostAgreement = response.settings.showContractCreationCostAgreement
        if (response.settings.contractCreationCost !== -1) {
          this.contractCreationCost = response.settings.contractCreationCost
        }
      })
      .catch(e => {
        if (!e.response || e.response.status !== 404) {
          Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
        }
      })
      .finally(() => {
        this.loadingSettings = false
      })
  },
  methods: {
    saveClicked() {
      if (this.$v) {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
      }

      this.loadingSettings = true
      RequestApi.setApplicationFormSettings(
        this.accountId,
        this.showContractCreationCostAgreement,
        this.contractCreationCost === null ? -1 : this.contractCreationCost
      ).then(() => {
        Vue.toasted.show(this.$t('message.successMessages.applicationFormSettings'), { type: 'success' })
      }).catch(() => {
        Vue.toasted.show(this.$t('message.savingErrors.savingApplicationForm'), { type: 'error' })
      }).finally(() => {
        this.loadingSettings = false
      })
    }
  },
  validations() {
    if (this.showContractCreationCostAgreement) {
      return {
        contractCreationCost: {
          required
        }
      }
    }

    return {}
  }
}
</script>

<style lang="scss" scoped>
.cost-input {
  display: inline-block;
  width: 100px;
  :deep(input) {
    height: auto;
  }
}
</style>
