<template>
  <b-modal
    id="transferPropertiesToAccountingModal"
    ref="transferPropertiesToAccountingModal"
    no-close-on-backdrop
    :title="$t('message.propertiesView.transferPropertiesAction')"
    hide-footer>
    <p>{{ $t('message.propertiesView.transferPropertiesConfirmationBody') }}</p>
    <div
      v-if="loading"
      class="col text-center">
      <coozzy-spinner />
    </div>
    <div
      v-else
      class="col pl-0">
      <coozzy-button
        size="small"
        design="default"
        @click="$bvModal.hide('transferPropertiesToAccountingModal')">
        {{ $t('message.generic.cancel') }}
      </coozzy-button>
      <coozzy-button
        size="small"
        class="float-right mb-0"
        design="prop-green"
        @click="confirmed">
        {{ $t('message.generic.confirm') }}
      </coozzy-button>
    </div>
  </b-modal>
</template>

<script>
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import ObjectApi from '@/misc/apis/ObjectApi'
import Vue from 'vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'

export default {
  name: 'TransferToAccountingModal',
  components: {
    CoozzySpinner,
    CoozzyButton
  },
  props: {
    ids: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'transferPropertiesToAccountingModal') {
        this.loading = false
      }
    })
  },
  methods: {
    confirmed() {
      this.loading = true
      ObjectApi.transferPropertiesToAccounting(this.ids)
        .then(() => {
          this.$emit('transferred')
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.property'), { type: 'error' })
          this.loading = false
          this.hide()
        })
    },
    hide() {
      this.$bvModal.hide('transferPropertiesToAccountingModal')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
