import authService from '@/auth/auth0/service'

const loginEventName = 'authStateChanged'

export default {
  install(Vue) {
    Vue.prototype.$auth = authService

    Vue.mixin({
      created() {
        if (this.handleLoginEvent) {
          authService.addListener(loginEventName, this.handleLoginEvent)
        }
      },

      destroyed() {
        if (this.handleLoginEvent) {
          authService.removeListener(loginEventName, this.handleLoginEvent)
        }
      }
    })
  }
}
