<template>
  <div
    v-if="isBusy"
    class="row">
    <!-- Busy -->
    <div
      class="col text-center">
      <coozzy-spinner />
    </div>
  </div>
  <section
    v-else>
    <application-form-individual-basic
      ref="basic"
      :individual-id="individualId"
      :sending="sending"
      :changed-address="changedAddress"
      @address-changed="addressChanged" />
    <hr>
    <application-form-employment
      ref="employment"
      :individual-id="individualId"
      :sending="sending"
      :object="object" />
    <hr>
    <application-form-tenancy
      v-if="!individual.tenancy.sameAsMainIndividual"
      ref="tenancy"
      :individual-id="individualId"
      :sending="sending"
      :object="object" />
  </section>
</template>

<script>
import ApplicationFormIndividualBasic from '@/marketingBasic/components/applicationForm/ApplicationFormIndividualBasic'
import ApplicationFormEmployment from '@/marketingBasic/components/applicationForm/ApplicationFormEmployment'
import ApplicationFormTenancy from '@/marketingBasic/components/applicationForm/ApplicationFormTenancy'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { mapGetters } from 'vuex'

export default {
  name: 'ApplicationFormIndividual',
  components: {
    ApplicationFormTenancy,
    ApplicationFormEmployment,
    ApplicationFormIndividualBasic,
    CoozzySpinner
  },
  props: {
    individualId: {
      type: String,
      required: true
    },
    sending: {
      type: Boolean,
      default: false
    },
    isBusy: {
      type: Boolean,
      default: false
    },
    changedAddress: {
      type: String,
      default: ''
    },
    object: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('applicationForm', ['getIndividualByInternalId']),
    individual() {
      return this.getIndividualByInternalId(this.individualId)
    }
  },
  methods: {
    ...mapGetters('applicationForm', ['getMainIndividual']),
    addressChanged(val) {
      this.$emit('address-changed', val)
    },
    isInvalid() {
      let valid = true
      if (this.$refs.basic && this.$refs.basic.isInvalid()) {
        valid = false
      }
      if (this.$refs.employment && this.$refs.employment.isInvalid()) {
        valid = false
      }
      if (this.$refs.tenancy && this.$refs.tenancy.isInvalid()) {
        valid = false
      }
      return !valid
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
