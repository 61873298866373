<template>
  <div class="advertisement-section">
    <div class="row">
      <div class="col">
        <img :src="realEstateInformation.previewImageHref">

        <div class="row">
          <div class="col image-counter-icon-section">
            <!-- Here goes the icon -->
            <span>IC</span>
            <!-- Here goes the image count -->
            <span>4</span>
          </div>
          <div class="col">
            <!-- Here goes the icon -->
            <span>ICON</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h4>{{ realEstateInformation.title }}</h4>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h5>{{ realEstateInformation.postalCode }} {{ realEstateInformation.city }}</h5>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            {{ realEstateInformation.livingArea }}
          </div>
        </div>

        <div class="row">
          <div class="col">
            Wohnfläche
          </div>
        </div>
      </div>

      <div class="col">
        <div class="row">
          <div class="col">
            {{ realEstateInformation.roomCount }}
          </div>
        </div>

        <div class="row">
          <div class="col">
            Zimmer
          </div>
        </div>
      </div>

      <div class="col align-self-end">
        <div class="row">
          <div class="col">
            {{ realEstateInformation.rent | formatPrice }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoozzyAdvertisementPreviewCard',
  props: {
    realEstateInformation: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
  .image-counter-icon-section {
    width: 40px;
  }

  img {
    max-width: 100%;
  }

  .advertisement-section {
    width: 300px;
    background-color: #d6d6d6;
  }
</style>
