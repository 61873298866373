<template>
  <img
    width="20px"
    height="20px"
    src="@/assets/icon/filter.svg"
    alt="filterIcon">
</template>

<script>

export default {
  name: 'CoozzyFilterIcon'
}
</script>

<style lang="scss" scoped>
</style>
