<template>
  <section>
    <coozzy-card :title="$t('message.settings.exportTemplate.title')">
      <!-- eslint-enable vue/no-v-html -->
      <div class="row mt-2 justify-content-between">
        <div class="col-12 col-md-2 mb-2">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
              {{ $t('message.generic.delete') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div
          v-if="isFiltered"
          class="col-12 offset-md-4 col-md-2">
          <coozzy-button
            class="w-100 sort"
            @click="initFilter()">
            <coozzy-filter-icon /> {{ $t('message.generic.clearFilters') }}
          </coozzy-button>
        </div>
        <div class="col-12 col-md-2 mb-2">
          <coozzy-button
            class="w-100"
            design="prop-green"
            @click="createTemplateClicked()">
            {{ $t('message.settings.exportTemplate.createTemplate') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row align-items-center mb-2">
        <div
          class="col-12 col-md-3 mb-2 mb-md-0">
          <coozzy-form-input
            v-model="searchText"
            :placeholder="$t('message.generic.search')" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-table
            id="table-contract"
            ref="table-contract"
            hover
            bordered
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            class="mb-4"
            :busy="isBusy || renderContractLoading"
            :fields="fields"
            :items="contractTemplateFiltered"
            @row-clicked="onRowClicked">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <!-- Headings -->
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(name)>
              {{ $t('message.tableColumnHeadings.template') }}
            </template>
            <template #head(language)>
              {{ $t('message.language.title') }}
            </template>
            <template #head(tenancyType)>
              {{ $t('message.settings.exportTemplate.tenancyType.title') }}
            </template>

            <!-- Body -->
            <template
              #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="'check_' + data.item.id"
                :key="data.item.id"
                :initial="selectedEntries.findIndex(x => x === data.item.id) !== -1"
                @change="(value) => { itemSelected(data.item.id, value) }" />
            </template>
            <template #cell(name)="data">
              {{ data.item.name | displayOptionalValue }}
            </template>
            <template #cell(language)="data">
              {{ $t('message.contact.preferredLanguageValue.' + data.item.language) }}
            </template>
            <template #cell(tenancyType)="data">
              {{ $t('message.settings.exportTemplate.tenancyType.' + data.item.tenancyType) }}
            </template>
          </b-table>
        </div>
      </div>
      <b-modal
        :id="'confirmationModal'"
        ref="confirmationModal"
        no-close-on-backdrop
        hide-footer
        :title="$t('message.settings.template.confirmationContract.title')">
        <div
          v-if="loadingDeletion"
          class="row">
          <div class="col text-center">
            <coozzy-spinner class="m-3" />
          </div>
        </div>
        <template v-else>
          <div class="col p-0">
            <p>{{ $t('message.settings.template.confirmationContract.body') }}</p>
          </div>
          <div class="col p-0">
            <coozzy-button
              size="small"
              class="mb-0 border"
              design="transparent"
              @click="$bvModal.hide('confirmationModal')">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              size="small"
              class="float-right mb-0"
              design="green"
              @click="deleteTemplateContract()">
              {{ $t('message.generic.delete') }}
            </coozzy-button>
          </div>
        </template>
      </b-modal>
      <template-export-modal
        ref="modal-template-export-creation"
        :key="templateForEdit !== null ? 'modal-template-export-creation' + templateForEdit.id : 'modal-template-export-creation'"
        :template-edit="templateForEdit"
        @on-creation="loadContracts()" />
    </coozzy-card>
  </section>
</template>

<script>
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import Vue from 'vue'
import TemplateExportModal from './TemplateExportModal'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { user } from '@/mixins/user'
import { tables } from '@/mixins/tables'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'

export default {
  name: 'ExportTemplateSettings',
  components: {
    CoozzyFormInput,
    CoozzyFormCheckbox,
    CoozzySpinner,
    CoozzyButton,
    CoozzyDropdownItem,
    CoozzyDropdown,
    TemplateExportModal
  },
  mixins: [user, tables, routeChecks],
  props: {
    source: {
      type: String,
      default: ''
    },
    renderContractLoading: {
      type: Boolean,
      default: false
    },
    byOwnersIds: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      isBusy: false,
      selectedEntries: [],
      contractTemplate: [],
      listMedia: [],
      ownerAccounts: [],
      contactList: [],
      searchCategories: [],
      loadingDeletion: false,
      searchOwner: [],
      searchText: '',
      templateForEdit: null,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'name',
          label: this.$t('message.tableColumnHeadings.title'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'language',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'tenancyType',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ]
    }
  },
  computed: {
    isFiltered() {
      return this.searchText !== '' || this.searchCategories.length > 0 || this.searchOwner.length > 0
    },
    selectedEntriesText() {
      return this.$t('message.generic.selectedEntries') + ':  ' + this.selectedEntries.length
    },
    categoryFilter() {
      const counts = []
      const current = this
      current.contractTemplate.map(contact => contact.category).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0) {
          const sameNetwork = current.contractTemplate.map(contact => contact.category).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    // availableOwner() {
    //   const counts = []
    //   const current = this
    //   current.contractTemplate.map(contact => contact.ownerId).forEach(function(x) {
    //     const existingCount = counts.filter(e => e.value === x)
    //     if (existingCount.length === 0) {
    //       const sameNetwork = current.contractTemplate.map(contact => contact.ownerId).filter(z => z === x)
    //       if (sameNetwork.length > 1) {
    //         counts.push({
    //           count: sameNetwork.length,
    //           value: x
    //         })
    //       } else {
    //         counts.push({
    //           count: 1,
    //           value: x
    //         })
    //       }
    //     }
    //   })
    //   return counts
    // },
    contractTemplateFiltered() {
      let contractTemplate = null
      contractTemplate = this.contractTemplate
      return contractTemplate.filter(item => {
        const cdtSearch = item.name.toLowerCase().includes(this.searchText.toLowerCase())
        return cdtSearch
      }).sort((a, b) => {
        // Then by title
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }

        return 0
      })
    }
  },
  watch: {
    contractTemplateFiltered: function (newVal) {
      this.selectedEntries.forEach(element => {
        if (newVal.findIndex(x => x.id === element) === -1) {
          this.clearCheckbox()
        }
      })
    }
  },
  mounted() {
    ContactApi.getContacts(this.accountId)
      .then(response => {
        const allContacts = response.persons.concat(response.companies)
        this.contactList = allContacts.filter(con => con.types.includes('EMPLOYEE'))
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
      })
    this.loadContracts()
  },
  methods: {
    onRowClicked(item, index, event) {
      if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
        this.itemSelected(item.id, !document.getElementById('check_' + item.id).checked)
        document.getElementById('check_' + item.id).checked = !document.getElementById('check_' + item.id).checked
      } else {
        this.createTemplateClicked(item)
      }
    },
    createTemplateClicked(template = null) {
      if (template !== null) {
        this.templateForEdit = template
      } else {
        this.templateForEdit = null
      }
      this.$nextTick(function () {
        this.$refs['modal-template-export-creation'].show()
      })
    },
    initFilter() {
      this.searchText = ''
      this.searchCategories = []
      this.searchOwner = []
    },
    getOwnerName(ownerId) {
      if (ownerId !== this.ownerId) {
        const account = this.ownerAccounts.find(account => account.id === ownerId)

        if (!account) {
          return ''
        }

        if (account.company) {
          return account.company.name
        }
        if (account.individual) {
          return account.individual.firstName.substr(0, 1) + '. ' + account.individual.lastName
        }
      } else {
        return this.fullName
      }
    },
    customLabelCategory(item) {
      return this.$t('message.settings.template.contractTemplateCategories.' + item.value) + ' (' + item.count + ')'
    },
    customLabelOwner(item) {
      return this.getOwnerName(item.value) + ' (' + item.count + ')'
    },
    getMediaUrl(id) {
      if (this.listMedia.length > 0) {
        const media = this.listMedia.find(x => x.media.id === id)
        if (media) {
          return this.listMedia.find(x => x.media.id === id).media.url
        }
      }
      return ''
    },
    loadContracts() {
      this.isBusy = true
      this.contractTemplate = []
      ObjectApi.rentalIndexTemplatesList(this.accountId)
        .then(response => {
          this.contractTemplate = response.templates
          this.$emit('contracts-loaded', this.contractTemplate)
          this.isBusy = false
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.template'), { type: 'error' })
        })
    },
    openConfirmationModal() {
      this.$refs.confirmationModal.show()
    },
    itemSelected(id, checked) {
      if (checked && !this.selectedEntries.includes(id)) {
        this.selectedEntries.push(id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== id)
      }
      this.$emit('selected-contract', this.selectedEntries, this.contractTemplate)
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs['table-contract'].$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
    },
    deleteTemplateContract() {
      this.loadingDeletion = true
      const promises = []
      console.log(this.selectedEntries)
      this.selectedEntries.forEach(element => {
        promises.push(ObjectApi.rentalIndexTemplateDelete(element))
      })
      Promise.all(promises)
        .then(() => {
          this.loadingDeletion = false
          this.selectedEntries = []
          this.handleCheckboxesInsideTable(false)
          this.$refs.header_checkbox.checked = false
          this.$refs.confirmationModal.hide()
          this.loadContracts()
        })
        .catch(e => {
          this.loadingDeletion = false
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.template'), { type: 'error' })
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
