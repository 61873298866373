import networkTS from '@/misc/apis/networkTS'
import { InvestPlanCreateBulkEntry, ObjectinvestPlanUpdateBulkEntry } from '@/types/api'

export default {
  async hasInvestPlanEnabled(accountId: string) {
    return networkTS.investPlanServiceHasEnabled(accountId)
      .then((response) => {
        return response.data
      })
  },
  async enableInvestPlan(accountId: string, enable: boolean) {
    return networkTS.investPlanServiceEnable(accountId, { enable: enable })
      .then((response) => {
        return response.data
      })
  },
  async createComponentBulk(entries: InvestPlanCreateBulkEntry[]) {
    return networkTS.investPlanServiceCreateBulk({ entries: entries })
      .then(response => {
        return response.data
      })
  },
  async deleteComponent(id: string) {
    return networkTS.investPlanServiceDelete(id)
      .then(response => {
        return response.data
      })
  },
  async listComponentsByReferenceIds(ids: string[]) {
    return networkTS.investPlanServiceListByReferenceIds({ ids: ids })
      .then(response => {
        return response.data
      })
  },
  async updateComponentBulk(entries: ObjectinvestPlanUpdateBulkEntry[]) {
    return networkTS.investPlanServiceUpdateBulk({ entries: entries })
      .then(response => {
        return response.data
      })
  }
}
