// @ts-nocheck
const tables = {
  methods: {
    handleCheckbox(allCheckboxesComponents, value) {
      allCheckboxesComponents.forEach(component => {
        for (const node of component.$el.childNodes[0].childNodes[0].childNodes[0].childNodes) {
          if (node.getAttribute('type') === 'checkbox') {
            if (node.checked !== value) {
              node.checked = value
              const event = new Event('change')
              node.dispatchEvent(event)
            }
          }
        }
      })
    },
    // in the case of TicketOverviewData table the checkbox is in the second column
    handleCheckboxInSecondNode(allCheckboxesComponents, value) {
      allCheckboxesComponents.forEach(component => {
        for (const node of component.$el.childNodes[1].childNodes[0].childNodes[0].childNodes) {
          if (node.getAttribute('type') === 'checkbox') {
            if (node.checked !== value) {
              node.checked = value
              const event = new Event('change')
              node.dispatchEvent(event)
            }
          }
        }
      })
    },
    sortedFilterValues(items, labelFunction, field = 'label') {
      if (items) {
        items.sort(function (a, b) {
          if (a.active && !b.active) { return -1 }
          if (!a.active && b.active) { return 1 }
          if (labelFunction(a).toLowerCase() < labelFunction(b).toLowerCase()) {
            return -1
          }
          if (labelFunction(a).toLowerCase() > labelFunction(b).toLowerCase()) {
            return 1
          }
          return 0
        })
        const fromIndex = items.findIndex(x => x && x[field] !== undefined && ((x[field].toLowerCase().includes('undefined') || x[field].toLowerCase().includes('unassigned nicht zugeordnet non assegnato') || x[field] === '')))
        if (fromIndex !== -1) {
          const element = items.splice(fromIndex, 1)[0]
          items.splice(0, 0, element)
        }
        return items
      }
      return 0
    },
    sortedFloor(items) {
      if (items) {
        const posFloor = []
        const negFloor = []
        const floorZero = []
        for (const item of items) {
          if (parseInt(item.label) > 0) {
            posFloor.push(item)
          }
          if (parseInt(item.label) < 0) {
            negFloor.push(item)
          }
          if (parseInt(item.label) === 0) {
            floorZero.push(item)
          }
        }
        posFloor.sort(function(a, b) {
          return parseInt(a.label) - parseInt(b.label)
        })
        negFloor.sort(function(a, b) {
          return parseInt(b.label) - parseInt(a.label)
        })
        const newList = floorZero.concat(posFloor).concat(negFloor)
        return newList
      }
      return 0
    },
    sortedGroupedFilterValues(items, labelFunction, group, field = 'label') {
      // sort by active first and then by label
      items[0][group].sort(function(a, b) {
        if (a.active === false && b.active !== false) {
          return 1
        }
        if (b.active === false && a.active !== false) {
          return -1
        }
        if (labelFunction(a).toLowerCase() < labelFunction(b).toLowerCase()) { return -1 }
        if (labelFunction(a).toLowerCase() > labelFunction(b).toLowerCase()) { return 1 }
        return 0
      })
      const fromIndex = items[0][group].findIndex(x => x && x[field] && (x[field].toLowerCase().includes('undefined') || x[field].toLowerCase().includes('unassigned nicht zugeordnet non assegnato') || x[field] === ''))
      if (fromIndex !== -1) {
        const element = items[0][group].splice(fromIndex, 1)[0]
        items[0][group].splice(0, 0, element)
      }
      return items
    },
    sortOptions(item) {
      return item.sort(function(a, b) {
        if (a.value === 'Unassigned Nicht zugeordnet Non assegnato') return -1
        if (b.value === 'Unassigned Nicht zugeordnet Non assegnato') return -1
        return 0
      })
    },
    getFields(dataFields, setting, hasCheckBox = true, hasRedDot = false) {
      let elm = null
      const fields = []
      if (hasRedDot) {
        const redDot = {
          key: 'newEvent',
            label: '',
          thClass: 'th-newEvent',
          tdClass: 'align-middle border-right-0 newEvent-cell'
        }
        fields.push(redDot)
      }
      if (hasCheckBox) {
        const checkBox = {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
        fields.push(checkBox)
      }
      const selectedFields = []
      setting.columns.forEach(element => {
        if (element) {
          const item = dataFields.filter(x => x && x.key === element)
          if (item && item.length > 0) {
            fields.push(item[0])
            elm = { key: item[0].key, selected: true }
            selectedFields.push(elm)
          }
        }
      })
      dataFields.forEach(element => {
        if (element && !setting.columns.includes(element.key)) {
          elm = { key: element.key, selected: false }
          selectedFields.push(elm)
        }
      })
      return { fields: fields, selectedFields: selectedFields }
    }
  }
}

export { tables }
