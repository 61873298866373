// @ts-nocheck
import network from './network'
import networkTS from '@/misc/apis/networkTS'

export default {
  shareUnset(scopes, sourceAccountId, resourceIds) {
    return network.post('web/share/unset', {
      scopes: scopes,
      sourceAccountId: sourceAccountId,
      resourceIds: resourceIds
    }).then(response => {
      return response.data
    })
  },
  shareSet(scope, sourceAccountId, resourceIds, targetAccountId) {
    return network.post('web/share/set', {
      scope: scope,
      sourceAccountId: sourceAccountId,
      targetAccountId: targetAccountId,
      resourceIds: resourceIds
    }).then(response => {
      return response.data
    })
  },
  buildingShared(sourceAccountId, targetAccountIds, resourceIdPrefix) {
    return network.get(`web/share/listByAccountIds/${sourceAccountId}/target/${targetAccountIds}?${resourceIdPrefix}=`)
      .then(response => {
        return response.data
      })
  },
  listOwners(targetAccountId) {
    return network.get(`web/share/listOwners/${targetAccountId}`)
      .then(response => {
        return response.data
      })
  },
  listByResource(resourceIds) {
    return network.post('web/share/listByResource', { resourceIds: resourceIds })
      .then(response => {
        return response.data
      })
  },
  listByTargetAccountId(accountId) {
    return network.get(`/web/share/listByTargetAccountId/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  listPartners(sourceAccountId) {
    return network.get(`/web/share/listPartners/${sourceAccountId}`)
      .then(response => {
        return response.data
      })
  },
  listPartnersOfAsset(sourceAccountId) {
    return network.get(`/web/share/listPartnersOfAsset/${sourceAccountId}`)
      .then(response => {
        return response.data
      })
  },
  listSubResourceByResourceIds(resourceIds: string[], subResourceIdPrefixes: string[]) {
    return networkTS.shareServiceListSubResourcesByResourceIds({
      resourceIds: resourceIds,
      subResourceIdPrefixes: subResourceIdPrefixes
    }).then(response => {
      return response.data
    })
  },
  listAllowedAccountIds(entityIds) {
    return network.post('web/share/listAllowedAccountIds', {
      entityIds: entityIds
    }).then(response => {
      return response.data
    })
  }
}
