import { ObjecttenancyRentAdjustment } from '@/types/api'
type Tax = { start: string | null, end: string | null, value: string }

const referenceInterestRate = {
  data() {
    return {
      tax: [
        { start: null, end: '31-12-1994', value: '0' },
        { start: '01-01-1995', end: '31-12-1998', value: '6.5' },
        { start: '01-01-1999', end: '31-12-2000', value: '7.5' },
        { start: '01-01-2001', end: '31-12-2010', value: '7.6' },
        { start: '01-01-2011', end: '31-12-2017', value: '8.0' },
        { start: '01-01-2018', end: '31-12-2023', value: '7.7' },
        { start: '01-01-2024', end: null, value: '8.1' }
      ]
    }
  },
  methods: {
    calculateRentWithTax(adjustedRentValues: ObjecttenancyRentAdjustment, type: string) {
        const coldRent = adjustedRentValues.coldRent
        const byDate = new Date(this.$options.filters.objectToDateInput(adjustedRentValues.byDate)).getTime() / 1000
        let taxNetRent = 0
        let grossRentInclTax = 0
        this.tax.forEach((element: Tax) => {
          const start = element.start ? new Date(element.start).getTime() / 1000 : null
          const end = element.end ? new Date(element.end).getTime() / 1000 : null
          if ((!start || byDate >= start) && (!end || byDate <= end)) {
            // calculate taxNetRent
            const taxValue = Number(element.value)
            taxNetRent = coldRent !== null ? (Math.round(((coldRent * taxValue) / 100) * 20) / 20) : 0
            const taxHeatingAdditionalCosts = this.getAdditionalCostsFromAdjustment(adjustedRentValues) !== null ? (Math.round(((this.getAdditionalCostsFromAdjustment(adjustedRentValues) * taxValue) / 100) * 20) / 20) : 0
            grossRentInclTax = (this.getGrossRentFromAdjustment(adjustedRentValues) + taxNetRent + taxHeatingAdditionalCosts)
          }
        })
        let total = 0
        if (type === 'netRent') {
          total = adjustedRentValues.coldRentPerMonth + ((taxNetRent * adjustedRentValues.dueDates.length) / 12)
        }
        if (type === 'grossRent' && grossRentInclTax !== 0) {
          total = (grossRentInclTax * adjustedRentValues.dueDates.length) / 12
        }
      return total
    },
    getGrossRentFromAdjustment(adjustment: ObjecttenancyRentAdjustment) {
      let result = 0
      adjustment.additionalValues.forEach(element => {
        if (element.value > 0 && element.type === 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION') {
          result -= element.value
        } else if (element.value > 0 || (element.value < -1 && element.type === 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION')) {
          result += element.value
        }
      })
      return (adjustment.coldRent !== -1 ? adjustment.coldRent : 0) + this.getAdditionalCostsFromAdjustment(adjustment) + result
    },
    getAdditionalCostsFromAdjustment(adjustment: ObjecttenancyRentAdjustment) {
      let result = 0
      adjustment.advancePaymentValues.forEach(element => {
        if (element.value > 0) {
          result += element.value
        }
      })
      adjustment.flatRateValues.forEach(element => {
        if (element.value > 0 || element.value < -1) {
          result += element.value
        }
      })
      return result
    },
    getReferenceInterestRateByTimestamp(timestamp: number) {
      // Smaller than 10.09.2008
      if (timestamp < 1221004800) {
        return null
      } else if (timestamp <= 1243900800) { // 10.09.2008 - 02.06.2009
        return 3.5
      } else if (timestamp <= 1251763200) { // 03.06.2009 - 01.09.2009
        return 3.25
      } else if (timestamp <= 1291161600) { // 02.09.2009 - 01.12.2010
        return 3
      } else if (timestamp <= 1322697600) { // 02.12.2010 - 01.12.2011
        return 2.75
      } else if (timestamp <= 1338508800) { // 02.12.2011 - 01.06.2012
        return 2.5
      } else if (timestamp <= 1378080000) { // 02.06.2012 - 02.09.2013
        return 2.25
      } else if (timestamp <= 1433116800) { // 03.09.2013 - 01.06.2015
        return 2
      } else if (timestamp <= 1496275200) { // 02.06.2015 - 01.06.2017
        return 1.75
      } else if (timestamp <= 1583107200) { // 02.06.2017 - 02.03.2020
        return 1.5
      } else if (timestamp <= 1685663999) { // 03.03.2020 - 01.06.2023
        return 1.25
      } else if (timestamp <= 1701388800) { // 02.06.2023 - 01.12.2023
        return 1.5
      } else {
        return 1.75
      }
    }
  }
}

export { referenceInterestRate }
