<template>
  <section class="w-100">
    <div
      v-if="index !== -1 && renderDevices"
      :id="`device-${target}-${index}`"
      v-b-toggle="`collapse-device-${target}-${index}`"
      :class="index === -1 && !device.internalId ? '' : 'pt-3'"
      class="col mb-0">
      <div class="row">
        <div class="col d-flex">
          <h5
            :class="[uninstallation !== null ? 'text-gray' : '']"
            class="cursor-pointer hover width-only-content">
            {{ title }}
            <span v-if="roomId && getRoomName(roomId) !== '-'">({{ getRoomName(roomId) }})</span>
          </h5>
          <a
            class="lh-1 link-activity "
            tabindex="0"
            @click="openActivities(device)"
            @click.middle="openActivitiesMiddle(device)">
            <img
              :src="`/img/ticket/new-window.svg`"
              alt="type"
              class="ml-3 mb-1"
              height="15px">
            {{ $t('message.ticketOverview.activities.title') }}
          </a>
        </div>
        <div
          v-if="!fromHandover"
          class="col mr-5">
          <coozzy-dropdown
            v-if="(isTicketingEnabled && (isEditor || isTicketEditor)) || isEditor"
            :text="$t('message.generic.actions')"
            class="w-20 sort float-right"
            size="sm">
            <coozzy-dropdown
              v-if="ownerId != null && isTicketingEnabled && (isEditor || isTicketEditor)"
              :disabled="true"
              :text="$t('message.ticketOverview.createTicket')"
              class="w-100 ticket-dropdown-bulk-action enabled"
              design="transparent"
              dropleft
              size="sm"
              @click.prevent>
              <coozzy-dropdown-item
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="!isOwnerModule && !isAssetModule"
                @click="createTicket('TICKET_TYPE_TASK')">
                {{ $t('message.ticketOverview.createTask') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
            <coozzy-dropdown-item
              v-if="isEditor"
              @click="readOnly ? goToEditMode() : addDocumentClicked('buildingDevice' + index);$event.stopPropagation()">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
      </div>
    </div>
    <div
      v-if="index === -1 && !fromHandover && !device.internalId"
      class="row h-0">
      <div
        class="offset-6 col-6 action-object">
        <coozzy-dropdown
          v-if="(isTicketingEnabled && (isEditor || isTicketEditor)) || isEditor"
          :text="$t('message.generic.actions')"
          class="w-20 sort float-right mr-5"
          size="sm">
          <coozzy-dropdown
            v-if="!device.internalId && isTicketingEnabled && (isEditor || isTicketEditor)"
            :disabled="true"
            :text="$t('message.ticketOverview.createTicket')"
            class="w-100 ticket-dropdown-bulk-action enabled"
            design="transparent"
            dropleft
            size="sm"
            @click.prevent>
            <coozzy-dropdown-item
              @click="navigateToCreateOrder()">
              {{ $t('message.ticketOverview.createOrder') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!isOwnerModule && !isAssetModule"
              @click="createTicket('TICKET_TYPE_TASK')">
              {{ $t('message.ticketOverview.createTask') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
          <coozzy-dropdown-item
            v-if="isEditor"
            @click="readOnly ? goToEditMode() : addDocumentClicked('buildingDevice' + index);$event.stopPropagation()">
            {{ $t('message.manageDocuments.addDocument') }}
          </coozzy-dropdown-item>
        </coozzy-dropdown>
      </div>
    </div>
    <div class="col-12 px-0">
      <b-collapse
        :id="`collapse-device-${target}-${index}`"
        ref="collapse"
        :visible="expandByDefault || openedCollapses.includes(`collapse-device-${target}-${index}`)"
        class="col">
        <template v-if="renderDevices">
          <div class="row details-row-bg-1 py-01rem pr-3">
            <div
              v-if="target !== 'property' && !fromKeyStep && !hideRoomSelection"
              class="col-sm-12 col-md-3">
              <label for="room">{{ $t('message.onBoarding.devices.room') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="room"
                v-model="roomId"
                class="select-entries float-right"
                name="room">
                <option value="">
                  -
                </option>
                <option
                  v-for="(item, indexOption) in roomsOption"
                  :key="indexOption"
                  :value="item.id">
                  {{ item.name }}
                </option>
              </coozzy-form-select>
              <div
                v-else>
                <p
                  class="show-data">
                  {{ getRoomName(roomId) }}
                </p>
              </div>
            </div>
          </div>
          <div class="row details-row-bg-2 py-01rem pr-3">
            <div class="col-sm-12 col-md-3">
              <label>{{ $t('message.onBoarding.devices.type') }}</label>
              <vue-autosuggest
                v-if="!readOnly"
                v-model="query"
                :get-suggestion-value="s => s.item.typeText"
                :input-props="typeSuggestInputProps"
                :should-render-suggestions="(size, loading) => size >= 0 && !loading && !closed"
                :suggestions="filteredSuggestions"
                @blur="setClosed(true)"
                @focus="setClosed(false)"
                @selected="typeSuggestSelected"
                @item-changed="typeSuggestSelected">
                <template #default="{suggestion}">
                  {{ suggestion.item.text }}
                </template>
              </vue-autosuggest>
              <span
                v-else
                class="show-data">
                {{
                  type === '' || type === null || type === 'DEVICE_TYPE_UNDEFINED' ? '-' : $t('message.onBoarding.devices.typeValues.' + type)
                }}
              </span>
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="translatedFacilityPlant"
                :disabled="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.facilityPlant.title')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="translatedSystem"
                :disabled="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.system')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="bkpH"
                :disabled="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.bkpH')" />
            </div>
          </div>
          <div
            v-if="['DEVICE_TYPE_LOCKING_KEY', 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER'].includes(type)"
            class="row details-row-bg-1 py-01rem pr-3">
            <div class="col-12">
              <div class="row">
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="description"
                    :is-read-only="readOnly"
                    :name="$tc('message.onBoarding.devices.lockingKey.description')" />
                </div>
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="serialNumber"
                    :is-read-only="readOnly"
                    :name="$tc('message.onBoarding.devices.serialNumber')" />
                </div>
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="lockingPlanNumber"
                    :is-read-only="readOnly"
                    :name="$tc('message.onBoarding.devices.lockingKey.lockingPlanNumber')" />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input-numeric
                    v-model="amount"
                    :is-decimal="false"
                    :is-integer="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.amount')"
                    type="number" />
                </div>
                <div
                  class="col-sm-12 col-md-3 align-content-center">
                  <coozzy-form-checkbox
                    :id="'combinedKeysNote' + device.id || device.internalId"
                    :key="'combinedKeysNote' + device.id || device.internalId"
                    v-model="combinedKeys"
                    :disabled="readOnly"
                    :initial="combinedKeys"
                    class="mt-3">
                    {{ $t('message.onBoarding.devices.lockingKey.combinedKeys') }}
                  </coozzy-form-checkbox>
                </div>
                <div
                  v-if="combinedKeys"
                  class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="combinedKeysNote"
                    :is-read-only="readOnly"
                    :name="$tc('message.onBoarding.devices.lockingKey.combinedKeysNote')" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="row details-row-bg-1 py-01rem pr-3">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="manufacturer"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.manufacturer')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="brand"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.brand')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="model"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.model')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="serialNumber"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.serialNumber')" />
            </div>
          </div>

          <div class="row details-row-bg-2 py-01rem pr-3">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.installation"
                :filter="'formatDate'"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.installation')"
                type="date"
                @blur="dateToObject()" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.uninstallation"
                :filter="'formatDate'"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.uninstallation')"
                :state="(dates.uninstallation && $v.uninstallation.$dirty && $v.uninstallation.$error) ? false : null"
                type="date"
                @blur="dateToObject()" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.warrantyEnd"
                :filter="'formatDate'"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.warrantyExpiration')"
                type="date"
                @blur="dateToObject()" />
            </div>
          </div>
          <div class="row details-row-bg-1 py-01rem pr-3">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="acquisitionCosts"
                :check-valide="$v.acquisitionCosts.$dirty && $v.acquisitionCosts.$error ? 'is-invalid' : ''"
                :filter="'formatPrice'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.acquisitionCosts')"
                min="0"
                placeholder="CHF"
                type="number" />
            </div>
            <div class="col-sm-12 col-md-3">
              <div class="row">
                <div class="col-md-6">
                  <coozzy-form-input-numeric
                    v-model="cataloguePrice"
                    :check-valide="$v.cataloguePrice.$dirty && $v.cataloguePrice.$error ? 'is-invalid' : ''"
                    :filter="'formatPrice'"
                    :is-decimal="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.cataloguePrice')"
                    min="0"
                    placeholder="CHF"
                    type="number" />
                </div>
                <div
                  v-if="!readOnly"
                  class="col-md-6">
                  <label for="unit">{{ $t('message.onBoarding.devices.unit') }}</label>
                  <coozzy-form-select
                    id="unit"
                    v-model="unit"
                    class="select-entries float-right"
                    name="unit">
                    <option
                      v-for="(item, indexOption) in unitOptions"
                      :key="indexOption"
                      :value="item">
                      {{ $t('message.onBoarding.devices.unitOptions.' + item) }}
                    </option>
                  </coozzy-form-select>
                </div>
                <div
                  v-else
                  class="col-md-6">
                  <label>{{ $t('message.onBoarding.devices.unit') }}</label>
                  <p
                    class="show-data">
                    {{ $t('message.onBoarding.devices.unitOptions.' + unit) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="row">
                <div
                  v-if="!['DEVICE_TYPE_LOCKING_KEY', 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER'].includes(type)"
                  class="col-md-3">
                  <coozzy-form-input-numeric
                    v-model="amount"
                    :is-decimal="false"
                    :is-integer="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.amount')"
                    type="number" />
                </div>
                <div class="col-md-3">
                  <coozzy-form-input-numeric
                    v-model="acquisitionCostSum"
                    :disabled="true"
                    :filter="'formatPrice'"
                    :is-decimal="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.acquisitionCostSum')"
                    type="number" />
                </div>
                <!-- <div class="col-md-6">
                  <coozzy-form-input-numeric
                    v-model="guidePriceSum"
                    :is-read-only="readOnly"
                    :disabled="true"
                    :filter="'formatPrice'"
                    type="number"
                    :is-decimal="true"
                    :name="$t('message.onBoarding.devices.guidePriceSum')" />
                </div> -->
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="lifeSpan"
                    :is-read-only="readOnly"
                    :min="0"
                    :name="$t('message.onBoarding.devices.lifeSpan')"
                    type="number"
                    @blur="onBlurLifeSpan()" />
                </div>
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="calculatedReplacementSet"
                    :disabled="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.calculatedReplacement')" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!fromHandover"
            class="row details-row-bg-2 py-01rem pr-3">
            <div
              v-if="!readOnly"
              class="col-sm-12 col-md-3">
              <label for="condition">{{ $t('message.onBoarding.devices.condition') }}</label>
              <coozzy-form-select
                id="condition"
                v-model="condition"
                class="select-entries float-right"
                name="condition">
                <option
                  v-for="(item, indexOption) in conditionOptions"
                  :key="indexOption"
                  :value="item">
                  {{ $t('message.onBoarding.devices.conditionOptions.' + item) }}
                </option>
              </coozzy-form-select>
            </div>
            <div
              v-else
              class="col-sm-12 col-md-3">
              <label for="condition">{{ $t('message.onBoarding.devices.condition') }}</label>
              <p>
                {{ $t('message.onBoarding.devices.conditionOptions.' + condition) }}
              </p>
            </div>
            <div
              v-if="condition === 'DEVICE_CONDITION_REPAIR_NEEDED' || condition === 'DEVICE_CONDITION_REPLACEMENT_NEEDED'"
              class="col-3">
              <div class="row">
                <div
                  v-if="!readOnly"
                  class="d-flex col-sm-12 col-md-6 mt-1">
                  <coozzy-form-checkbox
                    :id="device.id || device.internalId + 'measure'"
                    :key="device.id || device.internalId + 'measure'"
                    v-model="measure"
                    :initial="measure === 'DEVICE_MEASURE_IMMEDIATELY'"
                    class="align-self-center mt-3">
                    {{ $t('message.onBoarding.devices.measures') }}
                  </coozzy-form-checkbox>
                </div>
                <div
                  v-else
                  class="col-sm-12 col-md-6">
                  <label>
                    {{ $t('message.onBoarding.devices.measures') }}
                  </label>
                  <span class="show-data">
                    {{
                      measure === 'DEVICE_MEASURE_IMMEDIATELY' ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no')
                    }}
                  </span>
                </div>
                <div class="col-sm-12 col-md-6">
                  <coozzy-form-input
                    v-model="dates.measureUntil"
                    :filter="'formatDate'"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.measureUntil') + (readOnly ? '' : '*')"
                    :state="$v.measureUntil.$dirty && $v.measureUntil.$error ? false : null"
                    type="date"
                    @blur="dateToObjectMeasureUntil()" />
                </div>
              </div>
            </div>
            <div
              :class="condition === 'DEVICE_CONDITION_REPAIR_NEEDED' || condition === 'DEVICE_CONDITION_REPLACEMENT_NEEDED' ? 'col-9' : 'col-12'">
              <coozzy-form-textarea
                v-model="note"
                :check-valide="(condition === 'DEVICE_CONDITION_REPAIR_NEEDED' || condition === 'DEVICE_CONDITION_REPLACEMENT_NEEDED') && $v.note.$dirty && $v.note.$error ? 'is-invalid' : ''"
                :initial="note"
                :is-read-only="readOnly">
                {{ $t('message.onBoarding.devices.note') }} {{
                  (condition === 'DEVICE_CONDITION_REPAIR_NEEDED' || condition === 'DEVICE_CONDITION_REPLACEMENT_NEEDED') && !readOnly ? '*' : ''
                }}
              </coozzy-form-textarea>
            </div>
          </div>
          <div
            v-if="!fromHandover"
            class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>
        </template>
        <div
          v-if="!fromHandover"
          v-show="renderDevices"
          class="row">
          <div class="col-12 pl-0">
            <device-inspection
              ref="device-inspection"
              :contacts-list="contactList"
              :device="device"
              :index="index"
              :read-only="readOnly"
              :target="target"
              @device-update="deviceUpdated" />
          </div>
        </div>
        <div
          v-if="!fromHandover"
          class="row">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div
          v-if="!fromHandover"
          v-show="renderDevices"
          class="row">
          <div class="col-12 pl-0">
            <device-maintenance
              ref="device-maintenance"
              :contacts-list="contactList"
              :device="device"
              :index="index"
              :read-only="readOnly"
              :target="target"
              @device-update="deviceUpdated" />
          </div>
        </div>
        <div
          v-if="!fromHandover"
          class="row">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div
          v-if="!fromHandover"
          v-show="renderDevices"
          class="row">
          <div class="col-12 pl-0">
            <device-revision
              ref="device-revision"
              :contacts-list="contactList"
              :device="device"
              :index="index"
              :read-only="readOnly"
              :target="target"
              @device-update="deviceUpdated" />
          </div>
        </div>
        <div
          v-if="renderDevices && !fromHandover"
          class="row">
          <div class="col-12">
            <hr>
          </div>
          <div class="col-12">
            <h6
              v-b-toggle="`collapse-device-${index}-picture`"
              class="d-inline-block font-weight-bold mt-3 width-only-content">
              {{ $t('message.generic.pictures') }}
            </h6>
          </div>
          <b-collapse
            v-if="renderDevices"
            :id="`collapse-device-${index}-picture`"
            :visible="openedCollapses.includes(`collapse-device-${index}-picture`)"
            class="w-100">
            <coozzy-spinner v-if="selectedDeviceImageMedia === null" />
            <template v-else>
              <div
                v-if="selectedDeviceImageMedia.length === 0"
                class="col-sm-12 col-md-3">
                <h6>{{ $t('message.generic.noPictures') }}</h6>
              </div>
              <draggable
                v-model="selectedDeviceImageMedia"
                :disabled="readOnly"
                class="col-sm-12 col-md-12 row"
                @end="endMoving">
                <div
                  v-for="(image, indexImage) in selectedDeviceImageMedia"
                  :key="image.media.id"
                  class="col-sm-12 col-md-2 mt-3">
                  <div
                    class="uploaded-file square">
                    <div
                      :class="['device-image-remove-container', 'cursor-pointer', 'hover', readOnly ? 'd-none' : '']"
                      @click="removeImageClicked(image.media)">
                      <coozzy-close-icon class="device-image-remove bg" />
                      <coozzy-close-icon class="device-image-remove" />
                    </div>
                    <coozzy-thumbnail
                      :key="image.media.id"
                      v-viewer="Object.assign(viewerOptions, {url: 'original'})"
                      :original="image.media.url"
                      :size-restriction="false"
                      :src="getMediaUrl(image.media)"
                      class="device-image cursor-pointer thumbnail-viewer"
                      @imageClicked="imageClicked(indexImage)" />
                  </div>
                </div>
              </draggable>
              <div
                v-if="!readOnly && selectedDeviceImageMedia.length < 20"
                class="col-sm-12 col-md-2 mt-1">
                <coozzy-spinner v-if="imageUploadProcessing" />
                <coozzy-button
                  class="col-sm-4 col-lg-12 col-md-12"
                  design="prop-green"
                  @click="uploadImageClicked">
                  {{ $t('message.generic.addPicture') }}
                </coozzy-button>
              </div>

              <coozzy-form-file-input
                v-if="!readOnly"
                ref="imageUpload"
                accept="image/png,image/jpeg,image/jpg"
                class="d-none"
                multiple
                @change="addFile" />
            </template>
          </b-collapse>
        </div>
        <div
          v-if="!fromHandover"
          class="row">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div
          v-if="!fromHandover"
          class="row">
          <div
            v-if="renderDevices"
            class="col-12">
            <h6
              v-b-toggle="`collapse-device-${index}-document`"
              class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
              {{ $t('message.generic.documents') }}
            </h6>
          </div>
        </div>
        <b-collapse
          v-if="renderDevices && !fromHandover"
          :id="`collapse-device-${index}-document`"
          :visible="openedCollapses.includes(`collapse-device-${index}-document`)"
          class="w-100">
          <div
            class="row">
            <div class="col-12 col-md-2">
              <add-document-modal
                :owner-id="device.ownerId"
                :suffix="'buildingDevice' + index"
                @document-created="documentCreated" />
              <coozzy-button
                v-if="!readOnly"
                class="w-100 mt-2"
                design="prop-green"
                @click="addDocumentClicked('buildingDevice' + index)">
                {{ $t('message.manageDocuments.addDocument') }}
              </coozzy-button>
            </div>
          </div>
          <div
            class="row pr-3">
            <div
              class="col-12">
              <documents-list
                :documents-list="documents"
                :is-read-only="readOnly"
                @version-added="versionAdded"
                @document-deleted="deleteDocument" />
            </div>
          </div>
        </b-collapse>
        <div
          v-if="renderDevices && !readOnly && !fromHandover"
          class="row pr-3">
          <div class="col-12 mt-3">
            <coozzy-button
              v-if="!readOnly"
              class="float-right"
              design="red"
              size="small"
              @click="removeClicked">
              {{ $t('message.onBoarding.devices.deleteDevice') }}
            </coozzy-button>
            <coozzy-button
              v-if="duplicateButton && !readOnly"
              class="float-right mr-2"
              design="green"
              size="small"
              @click="duplicateClicked">
              {{ $t('message.generic.duplicate') }}
            </coozzy-button>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-modal
      v-if="renderDevices"
      :id="'deleteModal'"
      ref="deleteModal"
      :title="$t('message.onBoarding.deleteModals.confirmation')"
      hide-footer
      no-close-on-backdrop>
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodyDevice') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          class="mb-0 border"
          design="transparent"
          size="small"
          @click="$bvModal.hide('deleteModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import DeviceMaintenance from './DeviceMaintenance'
import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import { onboarding } from '@/mixins/onboarding'
import DeviceInspection from './DeviceInspection'
import { decimal, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { validation } from '@/mixins/validation'
import { VueAutosuggest } from 'vue-autosuggest'
import MediaApi from '@/misc/apis/MediaApi'
import RoomApi from '@/misc/apis/RoomApi'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import { toastError } from '@/mixins/toastError'
import DeviceRevision from './DeviceRevision'
import draggable from 'vuedraggable'
import { routeChecks } from '@/mixins/routeChecks'
import Vue from 'vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyCloseIcon from '@/framework/components/icons/CoozzyCloseIcon'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import { dateUtils } from '@/mixins/dateUtils'
import { user } from '@/mixins/user'
import { media } from '@/mixins/media'
import { deviceUtils } from '@/mixins/deviceUtils'
import DocumentsList from '@/properties/components/DocumentsList.vue'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzyThumbnail from '@/framework/components/img/CoozzyThumbnail.vue'

function moreThanInstallation(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.installation) {
    return true
  }

  return new Date(this.$options.filters.objectToDateInput(value)).getTime() >= new Date(this.$options.filters.objectToDateInput(vm.installation)).getTime()
}

export default {
  name: 'Device',
  components: {
    CoozzyThumbnail,
    CoozzyFormCheckbox,
    CoozzyDropdownItem,
    CoozzyDropdown,
    DocumentsList,
    CoozzyFormFileInput,
    CoozzyCloseIcon,
    CoozzySpinner,
    CoozzyFormSelect,
    CoozzyFormTextarea,
    DeviceInspection,
    CoozzyButton,
    CoozzyFormInput,
    DeviceMaintenance,
    CoozzyFormInputNumeric,
    VueAutosuggest,
    AddDocumentModal,
    DeviceRevision,
    draggable
  },
  mixins: [onboarding, validationMixin, validation, toastError, dateUtils, routeChecks, user, media, deviceUtils],
  props: {
    index: {
      type: Number,
      default: -1
    },
    fromHandover: {
      type: Boolean,
      default: false
    },
    fromKeyStep: {
      type: Boolean,
      default: false
    },
    handoverRooms: {
      type: Array,
      default: () => {
        return []
      }
    },
    ownerId: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: 'building'
    },
    device: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    fromLocal: {
      type: Boolean,
      default: false
    },
    renderDevices: {
      type: Boolean,
      default: true
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    externalContactList: {
      type: Array,
      default: () => {
        return []
      }
    },
    duplicateButton: {
      type: Boolean,
      default: true
    },
    addNewDocument: {
      type: Boolean,
      default: false
    },
    propertyId: {
      type: String,
      default: 'building'
    },
    hideRoomSelection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      calculatedReplacement: '',
      selectedDeviceImageMedia: null,
      imageUpload: [],
      imageUploadProcessing: false,
      query: '',
      documentList: [],
      dates: {
        installation: null,
        uninstallation: null,
        warrantyEnd: null,
        measureUntil: null
      },
      typeSuggestFilteredOptions: [],
      typeSuggestInputProps: {
        id: 'autosuggest__input',
        placeholder: this.$t('message.onBoarding.devices.searchType'),
        class: 'custom-select'
      },
      listRooms: [],
      closed: false,
      viewer: null
    }
  },
  computed: {
    calculatedReplacementSet: {
      get() {
        return this.calculatedReplacement
      },
      set(value) {
        this.calculatedReplacement = value
      }
    },
    roomsOption() {
      if (this.fromHandover) {
        return this.rooms
      } else if (this.target !== 'building') {
        return this.getFilteredObjectRooms(this.device.referenceId)
      } else {
        return this.rooms.filter(x => x.referenceId === this.device.referenceId)
      }
    },
    contactList() {
      const contacts = this.contacts && this.contacts.length > 0 ? this.contacts : this.externalContactList
      return contacts.filter(c => c.types && !c.types.includes('EMPLOYEE'))
    },
    roomId: {
      get() {
        return this.device.roomId !== '' ? this.device.roomId : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.roomId = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    type: {
      get() {
        return this.device.type !== 'DEVICE_TYPE_UNDEFINED' ? this.device.type : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.type = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    system: {
      get() {
        return this.device.system !== 'DEVICE_SYSTEM_UNDEFINED' ? this.device.system : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.system = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    manufacturer: {
      get() {
        return this.device.manufacturer
      },
      set(value) {
        const newDevice = this.device
        newDevice.manufacturer = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    brand: {
      get() {
        return this.device.brand
      },
      set(value) {
        const newDevice = this.device
        newDevice.brand = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    model: {
      get() {
        return this.device.model
      },
      set(value) {
        const newDevice = this.device
        newDevice.model = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    serialNumber: {
      get() {
        return this.device.serialNumber
      },
      set(value) {
        const newDevice = this.device
        newDevice.serialNumber = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    installation: {
      get() {
        return this.device.installation || null
      },
      set(value) {
        const newDevice = this.device
        newDevice.installation = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    uninstallation: {
      get() {
        return this.device.uninstallation || null
      },
      set(value) {
        const newDevice = this.device
        newDevice.uninstallation = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    warrantyEnd: {
      get() {
        return this.device.warrantyEnd || null
      },
      set(value) {
        const newDevice = this.device
        newDevice.warrantyEnd = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    measureUntil: {
      get() {
        return this.device.measureUntil || null
      },
      set(value) {
        const newDevice = this.device
        newDevice.measureUntil = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    measure: {
      get() {
        return this.device.measure !== 'DEVICE_MEASURE_UNDEFINED' ? this.device.measure : null
      },
      set(value) {
        const newDevice = this.device
        if (value === true) {
          newDevice.measure = 'DEVICE_MEASURE_IMMEDIATELY'
        } else {
          newDevice.measure = 'DEVICE_MEASURE_UNDEFINED'
        }
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    facilityPlant: {
      get() {
        return this.device.facilityPlant !== 'DEVICE_FACILITY_PLANT_UNDEFINED' ? this.device.facilityPlant : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.facilityPlant = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    bkpH: {
      get() {
        return this.device.bkpH
      },
      set(value) {
        const newDevice = this.device
        newDevice.bkpH = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    acquisitionCosts: {
      get() {
        return this.device.acquisitionCosts !== -1 ? this.device.acquisitionCosts : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.acquisitionCosts = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    cataloguePrice: {
      get() {
        return this.device.cataloguePrice !== -1 ? this.device.cataloguePrice : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.cataloguePrice = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    guidePriceSum() {
      return (this.amount !== null ? this.amount : 0) *
        (this.cataloguePrice !== null ? this.cataloguePrice : 0)
    },
    acquisitionCostSum() {
      return (this.amount !== null ? this.amount : 0) *
        (this.acquisitionCosts !== null ? this.acquisitionCosts : 0)
    },
    unit: {
      get() {
        return this.device.unit
      },
      set(value) {
        const newDevice = this.device
        newDevice.unit = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    note: {
      get() {
        return this.device.note
      },
      set(value) {
        const newDevice = this.device
        newDevice.note = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    amount: {
      get() {
        return this.device.amount !== -1 ? this.device.amount : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.amount = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    lifeSpan: {
      get() {
        return this.device.lifeSpan !== -1 ? this.device.lifeSpan : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.lifeSpan = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    description: {
      get() {
        return this.device.lockingKey.description !== '' ? this.device.lockingKey.description : ''
      },
      set(value) {
        const newDevice = this.device
        newDevice.lockingKey.description = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    lockingPlanNumber: {
      get() {
        return this.device.lockingKey.lockingPlanNumber !== '' ? this.device.lockingKey.lockingPlanNumber : ''
      },
      set(value) {
        const newDevice = this.device
        newDevice.lockingKey.lockingPlanNumber = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    combinedKeys: {
      get() {
        return this.device.lockingKey.combinedKeys
      },
      set(value) {
        const newDevice = this.device
        newDevice.lockingKey.combinedKeys = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    combinedKeysNote: {
      get() {
        return this.device.lockingKey.combinedKeysNote
      },
      set(value) {
        const newDevice = this.device
        newDevice.lockingKey.combinedKeysNote = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    condition: {
      get() {
        return this.device.condition
      },
      set(value) {
        const newDevice = this.device
        newDevice.condition = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    documents() {
      if (this.target !== 'property' && this.fromLocal === false) {
        return this.target === 'building'
          ? this.getFilteredBuildingDeviceDocuments(this.device.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
          : this.getFilteredObjectDeviceDocuments(this.device.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
      } else {
        const doc = this.documentList
        return doc.sort((a, b) => (a.name > b.name ? 1 : -1))
      }
    },
    filteredSuggestions() {
      if (this.fromKeyStep) {
        return [{
          data: this.availableSuggestions.filter(f => f.type === 'DEVICE_TYPE_LOCKING_KEY' || f.type === 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER').filter(suggestion => {
            return suggestion.text.toLowerCase().indexOf(this.query.toLowerCase()) > -1
          }).sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
        }]
      } else {
        return [{
          data: this.availableSuggestions.filter(suggestion => {
            return suggestion.text.toLowerCase().indexOf(this.query.toLowerCase()) > -1
          }).sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
        }]
      }
    },
    translatedFacilityPlant() {
      if (this.facilityPlant) {
        return this.$t('message.onBoarding.devices.facilityPlant.' + this.facilityPlant)
      }
      return null
    },
    translatedSystem() {
      if (this.system) {
        return this.$t('message.onBoarding.devices.systemValues.' + this.system)
      }
      return null
    },
    translatedType() {
      return this.$t('message.onBoarding.devices.typeValues.' + this.type)
    },
    title() {
      if (this.type && this.type !== 'DEVICE_TYPE_UNDEFINED') {
        return this.translatedType + (this.uninstallation !== null ? ' - ' + this.$t('message.onBoarding.devices.filter.uninstalled') : '')
      } else {
        return this.$t('message.onBoarding.devices.newDevice')
      }
    },
    rooms() {
      if (this.fromLocal === true || this.fromHandover === true) {
        return this.listRooms
      } else {
        return this.buildingRooms
      }
    }
  },
  watch: {
    currentLanguage: function () {
      if (this.type) {
        this.query = this.translatedType
      }
    },
    measure: function (newValue) {
      const dateUntil = new Date()
      if (newValue) {
        dateUntil.setDate(dateUntil.getDate() + 14)
        this.measureUntil = this.toObject(dateUntil)
        this.dates.measureUntil = this.$options.filters.objectToDateInput(this.measureUntil)
      }
    },
    renderDevices: {
      deep: true,
      handler: function (value) {
        if (value === true && this.selectedDeviceImageMedia === null) {
          this.loadDeviceImages(this.device)
        }
      }
    },
    // device: {
    //   deep: true,
    //   handler: function (newValue, oldValue) {
    //     if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
    //       newValue.modified = true
    //       this.$emit('change-device', newValue)
    //     }
    //   }
    // },
    installation: function (newValue) {
      if (this.lifeSpan !== null && newValue !== null) {
        this.fillOutCalculatedReplacement()
      }
    }
  },
  mounted() {
    document.body.onmousedown = function (e) {
      if (e.button === 1) {
        e.preventDefault()
        return false
      }
    }
    this.dates.installation = this.$options.filters.objectToDateInput(this.installation)
    this.dates.uninstallation = this.$options.filters.objectToDateInput(this.uninstallation)
    this.dates.warrantyEnd = this.$options.filters.objectToDateInput(this.warrantyEnd)
    if (this.lifeSpan !== null && this.installation !== null) {
      this.fillOutCalculatedReplacement()
    }
    if (this.measureUntil) {
      this.dates.measureUntil = this.$options.filters.objectToDateInput(this.measureUntil)
    }
    if (this.type) {
      this.query = this.translatedType
    }
    if (this.target === 'property' || this.fromLocal === true) {
      MediaApi.listDocuments(this.device.documentIds)
        .then(documents => {
          this.documentList = documents.documents
        })
    }
    if (this.fromLocal === true) {
      RoomApi.getRoomsByReferenceIds([this.device.referenceId])
        .then(response => {
          this.listRooms = response.rooms
        })
    }
    if (this.fromHandover) {
      this.listRooms = this.handoverRooms
    }
    if (this.renderDevices) {
      this.loadDeviceImages(this.device)
    }
    if (!this.readOnly && this.addNewDocument) {
      this.addDocumentClicked('buildingDevice' + this.index)
    }
    this.$nextTick(() => {
      const element = this.$el.querySelector('.thumbnail-viewer')
      this.viewer = element?.$viewer
    })
  },
  beforeDestroy() {
    this.$root.$off('bv::collapse::state')
  },
  methods: {
    async addFile(event) {
      let files = await this.getFilesFromEvent(event)
      let notImageFiles = []
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          const element = files[index]
          if (!['image/png', 'image/jpeg', 'image/jpg'].includes(element.type)) {
            notImageFiles.push(index)
          }
          if (notImageFiles.filter(x => x === index).length === 0) {
            this.imageUpload.push(element)
          }
        }
      }

      if (this.imageUpload.length > 0) {
        await this.sendFile()
      }
      if (notImageFiles.length > 0) {
        Vue.toasted.show(this.$tc('message.savingErrors.fileNotSupported'), { type: 'error' })
      }
    },
    async sendFile() {
      this.imageUploadProcessing = true
      const response = await this.uploadMedia(this.imageUpload)
      if (response.length > 0) {
        // Update vuex store
        const device = this.device
        for (const media of response) {
          this.selectedDeviceImageMedia.push({
            media: media,
            mediaId: media.id
          })
          device.images.push({
            mediaId: media.id,
            order: this.selectedDeviceImageMedia.length - 1
          })
        }
        this.dispatchDeviceUpdate(device)
        this.imageUpload = []
        this.imageUploadProcessing = false
      }
      this.$nextTick(() => {
        this.imageUploadProcessing = false
      })
    },
    goToEditMode() {
      let routeLink = null
      localStorage.setItem('deviceIdNewDocument', this.device.id)
      if (this.$route.name?.includes('PropertyDetailsView')) {
        if (JSON.stringify(this.$route.query) === '{}' || this.$route.query?.view === 'property') {
          routeLink = {
            name: this.moduleRoutePrefix + 'PropertyEditingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'property',
              elementSelected: this.elementSelected
            }
          }
        } else if (this.$route.query?.view === 'devices') {
          routeLink = {
            name: this.moduleRoutePrefix + 'PropertyEditingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'devices',
              elementSelected: this.device.id
            }
          }
        }
      } else if (this.$route.name?.includes('BuildingDetailsView')) {
        if (this.$route.query.view === 'building') {
          routeLink = {
            name: this.moduleRoutePrefix + 'OnboardingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'building'
            }
          }
        } else if (this.$route.query.view === 'objects') {
          routeLink = {
            name: this.moduleRoutePrefix + 'OnboardingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'objects',
              elementSelected: this.device.referenceId
            }
          }
        } else if (this.$route.query.view === 'devices') {
          routeLink = {
            name: this.moduleRoutePrefix + 'OnboardingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'devices',
              elementSelected: this.device.id
            }
          }
        }
      }
      this.$router.push(routeLink)
    },
    openActivities(device) {
      if (device !== null && device !== undefined) {
        if (this.target !== 'property') {
          if (this.isOwnerModule) {
            this.$router.replace({
              name: 'OwnerBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAdminModule) {
            this.$router.replace({
              name: 'AdminBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAssetModule) {
            this.$router.replace({
              name: 'AssetBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAccountingModule) {
            this.$router.replace({
              name: 'AccountingBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          }
        } else if (device.referenceId.startsWith('prop')) {
          this.$emit('selected-device-for-history', device)
          // if (this.isOwnerModule) {
          //   this.$router.replace({
          //     name: 'OwnerPropertyDetailsView',
          //     params: { id: device.referenceId },
          //     query: {
          //       view: 'history',
          //       elementSelected: device.id
          //     }
          //   })
          // } else if (this.isAdminModule) {
          //   this.$router.replace({
          //     name: 'AdminPropertyDetailsView',
          //     params: { id: device.referenceId },
          //     query: {
          //       view: 'history',
          //       elementSelected: device.id
          //     }
          //   })
          // } else if (this.isAssetModule) {
          //   this.$router.replace({
          //     name: 'AssetPropertyDetailsView',
          //     params: { id: device.referenceId },
          //     query: {
          //       view: 'history',
          //       elementSelected: device.id
          //     }
          //   })
          // } else if (this.isAccountingModule) {
          //   this.$router.replace({
          //     name: 'AccountingPropertyDetailsView',
          //     params: { id: device.referenceId },
          //     query: {
          //       view: 'history',
          //       elementSelected: device.id
          //     }
          //   })
          // }
        }
      }
    },
    openActivitiesMiddle(device) {
      let routeData = ''
      if (device !== null && device !== undefined) {
        if (this.target !== 'property') {
          if (this.isOwnerModule) {
            routeData = this.$router.resolve({
              name: 'OwnerBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAdminModule) {
            routeData = this.$router.resolve({
              name: 'AdminBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAssetModule) {
            routeData = this.$router.resolve({
              name: 'AssetBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAccountingModule) {
            routeData = this.$router.resolve({
              name: 'AccountingBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          }
        } else if (device.referenceId.startsWith('prop')) {
          this.$emit('selected-device-for-history', device)
          if (this.isOwnerModule) {
            routeData = this.$router.resolve({
              name: 'OwnerPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAdminModule) {
            routeData = this.$router.resolve({
              name: 'AdminPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAssetModule) {
            routeData = this.$router.resolve({
              name: 'AssetPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAccountingModule) {
            routeData = this.$router.resolve({
              name: 'AccountingPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          }
        }
      }
      window.open(routeData.href, '_blank')
    },
    createTicket(type) {
      const defaultAssignee = this.ownerId
      const list = [this.device.id]
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee
          }
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee,
            ticketType: type
          }
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee
          }
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee,
            ticketType: type
          }
        })
      }
    },
    getRoomName(roomId) {
      const room = this.rooms.concat(this.objectRooms).find(x => x.id === roomId)
      return room ? room.name : '-'
    },
    onBlurLifeSpan() {
      if (this.lifeSpan !== null && this.installation !== null) {
        this.fillOutCalculatedReplacement()
      }
    },
    fillOutCalculatedReplacement() {
      let date = new Date(this.dates.installation)
      this.calculatedReplacement = date.getFullYear() + parseInt(this.lifeSpan)
    },
    typeSuggestSelected(option) {
      if (option) {
        this.facilityPlant = option.item.facilityPlant
        this.system = option.item.system
        this.type = option.item.type
        this.bkpH = option.item.bkp
        this.lifeSpan = option.item.lifeSpan
        this.cataloguePrice = option.item.cataloguePrice
        this.unit = option.item.unit
      }
    },
    setClosed(value) {
      setTimeout(() => {
        this.closed = value
      }, 200)
    },
    deviceUpdated(newDevice) {
      this.$emit('device-update', newDevice)
    },
    dispatchDeviceUpdate(newDevice) {
      if (this.target === 'building') {
        this.$store.dispatch('onboarding/updateBuildingDevice', newDevice)
      } else if (this.fromHandover === false) {
        this.$store.dispatch('onboarding/updateObjectDevice', newDevice)
      }
      this.$emit('device-update', newDevice)
    },
    dateToObject() {
      this.installation = this.toObject(this.dates.installation)
      this.uninstallation = this.toObject(this.dates.uninstallation)
      this.warrantyEnd = this.toObject(this.dates.warrantyEnd)
    },
    dateToObjectMeasureUntil() {
      this.measureUntil = this.toObject(this.dates.measureUntil)
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    removeConfirmed() {
      if (this.target === 'building') {
        this.removeBuildingDevice(this.device)
      } else {
        this.removeObjectDevice(this.device)
      }
      this.$refs.deleteModal.hide()
      this.$emit('removeConfirmed')
    },
    duplicateClicked() {
      if (this.target === 'property' && this.fromLocal === false) {
        this.$emit('duplicated')
      } else if (this.target === 'building' || this.fromLocal === true) {
        this.duplicateBuildingDevice(this.device)
      } else if (this.target === 'object') {
        this.duplicateObjectDevice(this.device)
      }
      this.$emit('duplicateDevice', this.device)
    },
    addDocumentClicked(suffix = '') {
      localStorage.removeItem('deviceIdNewDocument')
      this.$bvModal.show('add-document-modal' + suffix)
    },
    documentCreated(document) {
      // Update device in vuex store with new documentId
      const device = this.device
      device.documentIds.push(document.id)
      if (this.target === 'building' && this.fromLocal === false) {
        this.$store.dispatch('onboarding/updateBuildingDevice', device)
      } else {
        this.$store.dispatch('onboarding/updateObjectDevice', device)
      }
      this.dispatchDeviceUpdate(device)

      // Add whole new document to vuex store to display it
      if (this.target === 'building' && this.fromLocal === false) {
        this.buildingDeviceDocuments.push(document)
      } else if (this.target === 'property' || this.fromLocal === true) {
        this.documentList.push(document)
      } else {
        this.objectDeviceDocuments.push(document)
      }
    },
    versionAdded(updatedDocument) {
      if (this.target === 'building') {
        this.buildingDeviceDocuments.forEach((doc, index) => {
          if (doc.id === updatedDocument.id) {
            this.buildingDeviceDocuments.splice(index, 1, updatedDocument)
          }
        }, this)
      } else if (this.target === 'property') {
        this.documentList.forEach((doc, index) => {
          if (doc.id === updatedDocument.id) {
            this.documentList.splice(index, 1, updatedDocument)
          }
        }, this)
      } else {
        this.objectDeviceDocuments.forEach((doc, index) => {
          if (doc.id === updatedDocument.id) {
            this.objectDeviceDocuments.splice(index, 1, updatedDocument)
          }
        }, this)
      }
    },
    deleteDocument(document) {
      this.device.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.device.documentIds.splice(index, 1)
          if (this.target === 'building' && this.fromLocal === false) {
            const indexToDelete = this.buildingDeviceDocuments.findIndex(d => d.id === document.id)
            if (indexToDelete !== -1) {
              this.$delete(this.buildingDeviceDocuments, indexToDelete)
            }
          } else if (this.target === 'property' || this.fromLocal === true) {
            const indexToDelete = this.documentList.findIndex(d => d.id === document.id)
            if (indexToDelete !== -1) {
              this.$delete(this.documentList, indexToDelete)
            }
            const device = this.device
            if (this.target === 'building') {
              this.$store.dispatch('onboarding/updateBuildingDevice', device)
            } else {
              this.$store.dispatch('onboarding/updateObjectDevice', device)
            }
          } else {
            const indexToDelete = this.objectDeviceDocuments.findIndex(d => d.id === document.id)
            if (indexToDelete !== -1) {
              this.$delete(this.objectDeviceDocuments, indexToDelete)
            }
          }
        }
      }, this)
      this.$store.dispatch('onboarding/updateObjectDevice', this.device)
      this.activateChanged()
    },
    isInvalid() {
      this.$v.$touch()
      let listErrors = []
      if (this.$v.$invalid) {
        if (this.$v.uninstallation.$dirty && this.$v.uninstallation.$error) {
          listErrors.push(this.$t('message.savingErrors.uninstallation'))
        }
      }

      const inspectionInvalid = this.$refs['device-maintenance'].isInvalid()
      const maintenanceInvalid = this.$refs['device-maintenance'].isInvalid()
      const revisionInvalid = this.$refs['device-revision'].isInvalid()
      if (inspectionInvalid[0] || maintenanceInvalid[0] || revisionInvalid[0] || this.$v.$invalid) {
        listErrors = listErrors.concat(this.$refs['device-maintenance'].isInvalid()[1].concat(this.$refs['device-inspection'].isInvalid()[1]))
        if (listErrors.length > 0) {
          this.showManyToast(listErrors)
        }
        this.scrollToError()
        return true
      } else {
        return false
      }
    },
    isCollapseOpen() {
      return this.$refs.collapse.show
    },
    endMoving() {
      for (let index = 0; index < this.selectedDeviceImageMedia.length; index++) {
        const indexElement = this.item?.images?.findIndex(image => image.mediaId === this.selectedDeviceImageMedia[index].mediaId)
        if (this.item && this.item.images && this.item.images[indexElement]) {
          this.item.images[indexElement].order = index + 1
        }
      }
    },
    removeImageClicked(media) {
      this.selectedDeviceImageMedia = this.selectedDeviceImageMedia.filter(m => {
        return m.media.id !== media.id
      })

      const device = this.device
      device.images = device.images.filter(i => {
        return i.mediaId !== media.id
      })
      for (const [index, image] of device.images.entries()) {
        image.order = index
      }
      this.dispatchDeviceUpdate(device)
    },
    getMediaUrl(media) {
      if (media.thumbnails === undefined || media.thumbnails === null || typeof media.thumbnails === 'undefined') {
        return null
      }
      for (const thumbnail of media.thumbnails) {
        if (thumbnail.type === 1 || thumbnail.type === 'M') {
          return thumbnail.url
        }
      }

      return media.url
    },
    imageClicked() {
      this.viewer?.show()
    },
    uploadImageClicked() {
      if (!this.readOnly) {
        const btn = this.$refs.imageUpload.$el.children
        btn[0].click()
      }
    },
    async loadDeviceImages(device) {
      this.selectedDeviceImageMedia = null
      // Load media
      const promises = []
      for (const image of device.images) {
        promises.push(MediaApi.getMediaByID(image.mediaId))
      }
      const listmedia = await Promise.all(promises)
      for (let index = 0; index < (listmedia.length); index++) {
        listmedia[index].mediaId = device.images[index].mediaId
        listmedia[index].order = device.images[index].order
      }
      this.selectedDeviceImageMedia = listmedia.filter(med => med.media.mimeType.includes('image')).sort(function (a, b) {
        return a.order - b.order
      })
    },
    navigateToCreateOrder() {
      const currentRoute = {
        name: this.$router.currentRoute.name,
        params: this.$router.currentRoute.params,
        query: this.$router.currentRoute.query
      }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const buildingId = this.building ? this.building.id : this.device.referenceId
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerCreateOrderView',
          query: {
            buildingIds: buildingId,
            deviceIds: this.device.id,
            source: 'device'
          }
        }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminCreateOrderView',
          query: {
            buildingIds: buildingId,
            deviceIds: this.device.id,
            source: 'device'
          }
        }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingCreateOrderView',
          query: {
            buildingIds: buildingId,
            deviceIds: this.device.id,
            source: 'device'
          }
        }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetCreateOrderView',
          query: {
            buildingIds: buildingId,
            deviceIds: this.device.id,
            source: 'device'
          }
        }, () => {
        })
      }
    }
  },
  validations() {
    const validationDevice = {
      acquisitionCosts: {
        decimal
      },
      cataloguePrice: {
        decimal
      },
      uninstallation: {
        moreThanInstallation
      }
    }
    if (this.condition === 'DEVICE_CONDITION_REPAIR_NEEDED' || this.condition === 'DEVICE_CONDITION_REPLACEMENT_NEEDED') {
      validationDevice.measureUntil = {
        required
      }
      validationDevice.note = {
        required
      }
    }
    return validationDevice
  }
}
</script>

<style lang="scss" scoped>
.uploaded-file {
  height: 200px;
}

.device-image-remove-container {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin: 4px 12px;
}

.device-image-remove {
  position: absolute;
  z-index: 3;
  width: 16px;
  height: 16px;

  &.bg {
    left: -2px;
    top: -4px;
    width: 20px;
    height: 24px;
    color: white;
  }
}

.device-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-gray {
  color: $color-text-grey-light;
}

.square {
  position: relative;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.action-object {
  top: -2rem;
  right: 0;
}

.h-0 {
  height: 10px;
}
</style>
