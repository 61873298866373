<template>
  <section>
    <coozzy-page-title :title="$t('message.applicationForm.title')" />
    <div
      v-if="loading"
      class="text-center">
      <coozzy-spinner />
    </div>
    <template v-else>
      <coozzy-card
        v-if="!!requestId">
        <application-form-object-view
          :request-id="requestId"
          :default-object-id="objectId"
          :show-apply-button="false" />
      </coozzy-card>
      <application-form
        :sending="!!requestId"
        :request-id="requestId"
        :object-id="objectId" />
    </template>
  </section>
</template>
<script>
import ApplicationForm from '@/marketingBasic/components/applicationForm/ApplicationForm'
import RequestApi from '@/marketingBasic/api/RequestApi'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import ApplicationFormObjectView from '@/marketingBasic/components/applicationForm/ApplicationFormObjectView'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'

export default {
  name: 'ApplicationFormView',
  components: {
    CoozzyPageTitle,
    ApplicationFormObjectView,
    CoozzySpinner,
    ApplicationForm
  },
  props: {
    requestId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      objectId: ''
    }
  },
  mounted() {
    if (this.requestId) {
      this.loading = true
      RequestApi.listByIds([this.requestId])
        .then(response => {
          if (response.requests.length === 1) {
            this.objectId = response.requests[0].objectId
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
