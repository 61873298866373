<template>
  <font-awesome-icon
    :icon="{ prefix: 'fab', iconName: 'linkedin' }" />
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

library.add(faLinkedin)

export default {
  name: 'CoozzyLinkedinIcon'
}
</script>

<style lang="scss" scoped>
  .fa {
    color: $color-yellow;
  }
</style>
