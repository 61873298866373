<template>
  <b-input-group>
    <b-form-input
      :id="name"
      :value="value"
      :placeholder="placeholder === null || placeholder === '' ? $t('message.search.placeholder'): placeholder"
      type="text"
      size="sm"
      @keydown.enter.native="clickListener ? clickListener : () => {}"
      v-on="mountEventListeners" />

    <b-input-group-append>
      <coozzy-button
        class="btn-search input-group-end"
        @click="clickListener ? clickListener : () => {}">
        <coozzy-search-icon />
      </coozzy-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzySearchIcon from '@/framework/components/icons/CoozzySearchIcon'
export default {
  name: 'CoozzyFormSearchInput',
  components: { CoozzySearchIcon, CoozzyButton },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    clickListener() {
      return this.$listeners.click
    },
    mountEventListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: (value) => {
            this.$emit('input', value)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-search {
    background-color: #fff !important;
    box-shadow: none;
    font-size: inherit;

    &:hover {
      background-color: $color-grey !important;
    }
  }

  .input-group-end {
    border: 1px solid $color-input-border !important;
  }
</style>
