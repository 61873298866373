import { render, staticRenderFns } from "./NetworkPartnerRegionCard.vue?vue&type=template&id=12ef6fd1&scoped=true"
import script from "./NetworkPartnerRegionCard.vue?vue&type=script&lang=js"
export * from "./NetworkPartnerRegionCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ef6fd1",
  null
  
)

export default component.exports