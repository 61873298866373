<template>
  <section>
    <coozzy-statistics-card class="card-title-dashboard-custom">
      <h5
        class="mb-0 title-card">
        {{ $t('message.dashboard.marketing.allObjects') }}<span
          class="text-blue"> | <b>{{ statistics.overallInMarketing }}</b></span>
      </h5>
      <div
        class="row">
        <div class="col-3 padding-y-1" />
        <div class="col padding-y-2">
          <p class="mb-0">
            {{ $t('message.dashboard.marketing.inMarketing') }}
          </p>
        </div>
        <div class="col padding-y-2">
          <p class="mb-0">
            {{ $t('message.dashboard.marketing.online') }}
          </p>
        </div>
      </div>
      <div class="row border-bottom">
        <div class="col-3 padding-y-3 text-left">
          <p class="mb-0">
            {{ $t('message.dashboard.marketing.since12weeks') }}
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-red">{{ statistics.overallInMarketingFor12Weeks }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-red">{{ statistics.overallOnlineFor12Weeks }}</span>
          </p>
        </div>
      </div>
      <div class="row border-bottom">
        <div class="col-3 padding-y-3 text-left">
          <p class="mb-0">
            {{ $t('message.dashboard.marketing.since8weeks') }}
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-orange">{{ statistics.overallInMarketingFor8Weeks }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-orange">{{ statistics.overallOnlineFor8Weeks }}</span>
          </p>
        </div>
      </div>
      <div class="row border-bottom">
        <div class="col-3 padding-y-3 text-left">
          <p class="mb-0">
            {{ $t('message.dashboard.marketing.since4weeks') }}
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-green">{{ statistics.overallInMarketingFor4Weeks }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-green">{{ statistics.overallOnlineFor4Weeks }}</span>
          </p>
        </div>
      </div>
      <div class="row font-weight-bold">
        <div class="col-3 padding-y-3 text-left">
          <p class="mb-0">
            {{ $t('message.dashboard.admin.overall') }}
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-blue">{{ statistics.overallInMarketing }}</span>
          </p>
        </div>
        <div class="col padding-y-3">
          <p class="mb-0">
            <span class="value text-blue">{{ statistics.overallOnline }}</span>
          </p>
        </div>
      </div>
    </coozzy-statistics-card>
  </section>
</template>
<script>
import CoozzyStatisticsCard from '@/framework/components/card/CoozzyStatisticsCard'

export default {
  name: 'AllMarketingObjectTable',
  components: { CoozzyStatisticsCard },
  props: {
    statistics: {
      type: Object,
      default: null
    }

  }
}
</script>

<style lang="scss" scoped>
.padding-y-1 {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}
.padding-y-2 {
  padding-bottom: 11px !important;
  padding-top: 11px !important;
}
.padding-y-3 {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
}
.title-card {
  margin: 0 -1.25rem;
  text-align: left;
  background-color: $color-bg-light-blue;
  border: none;
  position: relative;
  padding: 0 1.25rem;
  margin-bottom: 0;
  line-height: 3rem;
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}
</style>
