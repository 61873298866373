<template>
  <section>
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-else>
      <!-- Heading -->
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <h1>{{ $t('message.propertiesView.createProperty') }}</h1>
        </div>
        <div class="col-auto">
          <coozzy-button
            class="my-2 w-100"
            design="blue"
            @click="cancelClicked">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
        </div>
      </div>
      <coozzy-card
        :title="$t('message.propertiesView.property')">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <coozzy-form-input
              v-model="property.name"
              class="mb-2"
              :limit="30"
              :state="$v.property.name.$dirty && $v.property.name.$error ? false : null"
              :name="$t('message.propertiesView.name') + '*'" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="numericId"
              :disabled="true"
              :name="$t('message.propertiesView.number')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="development">{{ $t('message.propertiesView.development.title') }}</label>
            <coozzy-form-select
              v-model="property.development"
              name="development">
              <option value="PROPERTY_DEVELOPMENT_UNDEFINED" />
              <option value="PROPERTY_DEVELOPMENT_FULL">
                {{ $t('message.propertiesView.development.PROPERTY_DEVELOPMENT_FULL') }}
              </option>
              <option value="PROPERTY_DEVELOPMENT_PARTIAL">
                {{ $t('message.propertiesView.development.PROPERTY_DEVELOPMENT_PARTIAL') }}
              </option>
              <option value="PROPERTY_DEVELOPMENT_UNDEVELOPED">
                {{ $t('message.propertiesView.development.PROPERTY_DEVELOPMENT_UNDEVELOPED') }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-country-select
              v-model="property.address.country"
              :state="$v.property.address.country.$dirty && $v.property.address.country.$error ? false : null"
              :initial-value="property.address.country" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="property.address.zip"
              :limit="zipLength"
              :state="$v.property.address.zip.$dirty && $v.property.address.zip.$error ? false : null"
              class="mb-2"
              :name="$t('message.generic.zip') + '*'"
              @input="getCityFromZip" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-if="!isCitiesSuggested"
              v-model="property.address.city"
              :state="$v.property.address.city.$dirty && $v.property.address.city.$error ? false : null"
              class="mb-2"
              :name="$t('message.generic.city') + '*'" />
            <template v-else>
              <label for="city">{{ $t('message.generic.city') }}*</label>
              <coozzy-form-select
                id="city"
                :state="$v.property.address.city.$dirty && $v.property.address.city.$error ? false : null"
                @change="cityChanged">
                <option
                  v-for="(item, typeIndex) in citiesValue"
                  :key="typeIndex"
                  :value="item">
                  {{ item.city }}
                </option>
              </coozzy-form-select>
            </template>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-region-select
              v-model="property.address.state"
              :initial-value="property.address.state"
              :state="$v.property.address.state.$dirty && $v.property.address.state.$error ? false : null"
              :country="property.address.country" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-3">
            <label for="type">{{ $t('message.propertiesView.propertyType.title') }}*</label>
            <coozzy-form-select
              id="type"
              v-model="property.type"
              :state="$v.property.type.$dirty && $v.property.type.$error ? false : null">
              <option
                value="">
                -
              </option>
              <option
                v-for="(type, typeIndex) in typeValue"
                :key="typeIndex"
                :value="type">
                {{ $t(`message.propertiesView.propertyType.${type}`) }}
              </option>
            </coozzy-form-select>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="note">{{ $t('message.generic.note') }}</label>
            <coozzy-form-textarea
              ref="note"
              v-model="property.note"
              class="textAreaHeight"
              :initial="property.note"
              :value-text-area="property.note"
              :rows="1"
              :show-label="false"
              name="note" />
          </div>
          <div
            class="col-sm-12 col-md-3 mt-2">
            <coozzy-form-checkbox
              id="opted"
              v-model="property.opted"
              :initial="property.opted"
              class="align-self-center mt-3">
              {{ $t('message.onBoarding.buildings.objects.opted') }}
            </coozzy-form-checkbox>
          </div>
          <div
            class="col-sm-12 col-md-3">
            <label for="optedPortionKey">{{ $t('message.propertiesView.optedPortionKey.title') }}{{ (property.opted ? '*' : '') }}</label>
            <coozzy-form-select
              id="optedPortionKey"
              v-model="property.optedPortionKey"
              :disabled="!property.opted"
              :state="property.opted && $v.property.optedPortionKey.$dirty && $v.property.optedPortionKey.$error ? false : null">
              <option value="">
                -
              </option>
              <option
                v-for="(optedPortionKey, index) in optedPortionKeyValue"
                :key="index"
                :value="optedPortionKey">
                {{ $t(`message.propertiesView.optedPortionKey.${optedPortionKey}`) }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
      </coozzy-card>
      <coozzy-card
        v-for="(building, index) in property.buildings"
        :key="index">
        <div
          :id="`building-${index}`"
          v-b-toggle="`collapse-building-${index}`"
          class="col pl-0 mb-0">
          <h5 class="cursor-pointer hover mb-0">
            {{ $t('message.manageBuilding.building') }}:
            <address-text
              :address="building ? building.address : null"
              :show-country="false"
              :one-line="true" />
            <span
              v-for="(object, indexObj) in building.objects"
              :key="indexObj">
              <template
                v-if="object.amount !== 0 && object.category !== ''">
                , {{ object.amount }}
                {{ $t(`message.onBoarding.buildings.objects.category.${object.category.toLowerCase()}`) }}
              </template>
            </span>
          </h5>
        </div>
        <b-collapse
          :id="`collapse-building-${index}`"
          visible>
          <div class="row details-row-bg-1 py-01rem">
            <div class="col-12 col-sm-3">
              <coozzy-form-input
                v-model="building.name"
                class="mb-2"
                :state="$v.property.buildings.$each[index].name.$dirty && $v.property.buildings.$each[index].name.$error ? false : null"
                :name="$t('message.onBoarding.buildings.name') + '*'" />
            </div>
            <div class="col-12 col-sm-3">
              <coozzy-form-input
                v-model="building.address.street"
                :state="$v.property.buildings.$each[index].address.street.$dirty && $v.property.buildings.$each[index].address.street.$error ? false : null"
                :name="$t('message.generic.street') + '*'" />
            </div>
            <div class="col-12 col-sm-3">
              <coozzy-form-input
                v-model="building.address.streetNumber"
                :name="$t('message.generic.streetNumber')" />
            </div>
            <div class="col-12 col-sm-3">
              <coozzy-form-input
                v-model="building.egid"
                class="mb-2"
                :name="$t('message.onBoarding.buildings.egid')" />
            </div>
          </div>
          <div class="row details-row-bg-1 py-01rem">
            <div class="col-3">
              <h5 class="mb-0">
                {{ $t('message.navigation.objects') }}
              </h5>
            </div>
          </div>
          <div
            v-for="(object, indexObj) in building.objects"
            :key="indexObj"
            class="row details-row-bg-1 py-01rem">
            <div class="col-3">
              <label for="category">{{ $t('message.onBoarding.buildings.objects.category.title') }}{{ '*' }}</label>
              <coozzy-form-select
                id="category"
                v-model="object.category"
                :state="$v.property.buildings.$each[index].objects.$each[indexObj].category.$dirty && $v.property.buildings.$each[index].objects.$each[indexObj].category.$error ? false : null">
                <option value="UNDEFINED_CATEGORY" />
                <option
                  v-for="(categoryValue, categoryIndex) in availableCategories"
                  :key="categoryIndex"
                  :value="categoryValue">
                  {{ $t(`message.onBoarding.buildings.objects.category.${categoryValue.toLowerCase()}`) }}
                </option>
              </coozzy-form-select>
            </div>
            <div class="col-3">
              <coozzy-form-input
                v-model="object.amount"
                type="number"
                min="0"
                :is-integer="true"
                :state="$v.property.buildings.$each[index].objects.$each[indexObj].amount.$dirty && $v.property.buildings.$each[index].objects.$each[indexObj].amount.$error ? false : null"
                :name="$t('message.propertiesView.objectCount') + '*'" />
            </div>
            <div
              v-if="building.objects.length > 1"
              class="col-auto">
              <coozzy-button
                class="w-100 mt-4"
                design="red"
                @click="removeObject(indexObj, index)">
                {{ $t('message.advertisementManagementOverview.deletion.confirmation.title') }}
              </coozzy-button>
            </div>
          </div>
          <div
            class="row">
            <div class="col-auto">
              <coozzy-button
                class="w-100 mt-4"
                design="prop-green"
                @click="addObject(index)">
                {{ $t('message.onBoarding.buildings.objects.add') }}
              </coozzy-button>
            </div>
          </div>
          <div
            class="row details-row-bg-1 py-01rem float-right">
            <div class="col-auto">
              <coozzy-button
                class="w-100 mt-4"
                design="red"
                @click="removeBuilding(index)">
                {{ $t('message.manageBuilding.deleteConfirmation.title') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </coozzy-card>
      <div class="row justify-content-between align-items-center">
        <div class="col-2">
          <coozzy-button
            class="my-2 w-100"
            design="prop-green"
            @click="addBuilding">
            {{ $t('message.propertiesView.addBuilding') }}
          </coozzy-button>
        </div>
        <div class="col-2">
          <coozzy-button
            class="my-2 w-100"
            design="prop-green"
            @click="saveProperty">
            {{ $t('message.propertiesView.createProperty') }}
          </coozzy-button>
        </div>
      </div>
    </div>
    <b-modal
      id="confirmationModal"
      ref="confirmationModal"
      no-close-on-backdrop
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="confirmCancellation">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import * as Sentry from '@sentry/browser'
import AddressText from '../framework/components/misc/AddressText'
import ObjectApi from '../misc/apis/ObjectApi'
import { decimal, minValue, required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import CoozzyFormSelect from '../framework/components/form/select/CoozzyFormSelect'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import CoozzyCard from '../framework/components/card/CoozzyCard'
import CoozzyFormInput from '../framework/components/form/input/CoozzyFormInput'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import { user } from '@/mixins/user'
import { isZipValid, validation } from '@/mixins/validation'
import CoozzyFormCountrySelect from '@/framework/components/form/select/CoozzyFormCountrySelect'
import CoozzyFormRegionSelect from '@/framework/components/form/select/CoozzyFormRegionSelect'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { address } from '@/mixins/address'

function isCategoryUndefined(value, vm) {
  return value !== 'UNDEFINED_CATEGORY'
}
export default {
  name: 'OwnerCreatePropertyView',
  components: {
    CoozzyFormCheckbox,
    CoozzyFormRegionSelect,
    CoozzyFormCountrySelect,
    CoozzySpinner,
    CoozzyFormInput,
    CoozzyCard,
    CoozzyButton,
    CoozzyFormSelect,
    AddressText
  },
  mixins: [user, validation, address],
  metaInfo() {
    return {
      title: this.$t('message.propertiesView.createProperty')
    }
  },
  data() {
    return {
      isCitiesSuggested: false,
      citiesValue: [],
      loading: false,
      hasChanged: false,
      availableCategories: ['APARTMENT', 'HOUSE', 'COMMERCIAL', 'GASTRONOMY', 'PARKING_SPACE', 'GARDEN', 'SECONDARY_ROOM', 'PLOT', 'AGRICULTURE'],
      property: {
        buildingIds: [],
        documentIds: [],
        imageMediaId: '',
        address: {
          city: '',
          country: 'CH',
          mailbox: '',
          state: '',
          street: '',
          streetAddition: '',
          streetNumber: '',
          subUnit: '',
          zip: ''
        },
        name: '',
        number: null,
        landRegisterNumber: '',
        plotArea: -1,
        development: 'PROPERTY_DEVELOPMENT_UNDEFINED',
        constructionZone: '',
        buildings: [],
        type: '',
        opted: false,
        optedPortionKey: '',
        note: ''
      },
      optedPortionKeyValue: ['OPTED_PORTION_KEY_M2', 'OPTED_PORTION_KEY_M3', 'OPTED_PORTION_KEY_VALUE_RATIO', 'OPTED_PORTION_KEY_TV', 'OPTED_PORTION_KEY_ELEVATOR', 'OPTED_PORTION_KEY_LOCATION_COMPENSATION'],
      typeValue: ['PROPERTY_TYPE_RENTAL', 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP', 'PROPERTY_TYPE_COOPERATIVE'],
      isAddressInvalid: false,
      currentBuilding: null
    }
  },
  computed: {
    numericId() {
      return this.$t('message.generic.generatedAfterSave')
    },
    zipLength() {
      const country = this.property.address.country
      if (country === 'CH') {
        return 4
      } else if (country === 'DE') {
        return 5
      } else {
        return 15
      }
    }
  },
  watch: {
    property: {
      deep: true,
      handler: function (newValue) {
        if (!newValue.opted) {
          newValue.optedPortionKey = ''
        }
        this.hasChanged = true
      }
    },
    'property.address.city': function (val) {
      for (let index = 0; index < this.property.buildings.length; index++) {
        this.property.buildings[index].address.city = val
      }
    },
    'property.address.zip': function (val) {
      for (let index = 0; index < this.property.buildings.length; index++) {
        this.property.buildings[index].address.zip = val
      }
    }
  },
  methods: {
    cityChanged(event) {
      this.property.address.city = event.city
      this.property.address.state = event.state
    },
    async getCityFromZip() {
      if (this.property.address.zip.length > 3 && this.property.address.country === 'CH') {
        const zipData = await this.getZipInfo(this.property.address.zip)
        if (zipData) {
          this.property.address.state = zipData.state
          if (zipData.cities.length > 1) {
            this.isCitiesSuggested = true
            this.citiesValue = zipData.cities
          } else {
            this.isCitiesSuggested = false
            this.$nextTick(() => {
              this.property.address.city = zipData.cities[0].city
            })
          }
        }
      }
    },
    addBuilding() {
      this.$v.property.$touch()
      const building = {
        name: '',
        acquisitionDate: null,
        address: {
          country: this.property.address.country,
          state: this.property.address.state,
          city: this.property.address.city,
          zip: this.property.address.zip,
          street: '',
          streetNumber: '',
          streetAddition: '',
          subUnit: ''
        },
        egid: '',
        objects: [{
          category: ''
        }]
      }
      if (!this.$v.property.$invalid) {
        this.property.buildings.push(building)
      } else {
        this.scrollToError()
      }
    },
    addObject(buildingIndex) {
      const object = {
        category: ''
      }
      this.property.buildings[buildingIndex].objects.push(object)
    },
    cancelClicked() {
      if (this.hasChanged === true) {
        this.$refs.confirmationModal.show()
      } else {
        this.confirmCancellation()
      }
    },
    confirmCancellation() {
      this.$router.push({ name: 'OwnerPropertyView' })
    },
    removeBuilding(buildingIndex) {
      this.property.buildings.splice(buildingIndex, 1)
    },
    removeObject(indexObj, buildingIndex) {
      this.property.buildings[buildingIndex].objects.splice(indexObj, 1)
    },
    async saveProperty() {
      this.$v.property.$touch()
      if (!this.$v.property.$invalid && !this.isAddressInvalid) {
        this.loading = true

        if (this.property.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
          this.property.flatRateRentComponents = [{ rentComponent: 'FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS', description: '' }, { rentComponent: 'FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND', description: '' }]
        }
        try {
          // Create property
          const propertyResponse = await ObjectApi.createProperties(this.accountId, this.property)
          this.property.id = propertyResponse.property.id

          // Create all buildings
          const buildingPromises = []
          this.property.buildings.forEach((building) => {
            buildingPromises.push(ObjectApi.createBuilding(this.accountId, building.address, building.name, building.egid, building.acquisitionDate, building.note))
          })

          const buildingResponses = await Promise.all(buildingPromises)

          // Update property with buildingIds
          this.property.buildingIds = buildingResponses.map(building => building.building.id)
          await ObjectApi.updateProperty(this.property)

          // Create all objects for each local building
          const promises = []
          for (let i = 0; i < this.property.buildings.length; i++) {
            const buildingResponse = buildingResponses[i].building
            const localBuilding = this.property.buildings[i]

            for (const object of localBuilding.objects) {
              promises.push(
                ObjectApi.createBulkPropertyObject({
                  amount: object.amount,
                  buildingId: buildingResponse.id,
                  address: localBuilding.address,
                  category: object.category,
                  ownerId: this.accountId,
                  type: 'RENT'
                })
              )
            }
          }

          await Promise.all(promises)

          setTimeout(() => {
            this.loading = false
            Vue.toasted.show(this.$t('message.propertiesView.successMessageCreation'), { type: 'success' })
            this.$router.push({
              name: 'OwnerPropertyDetailsView',
              params: { id: this.property.id }
            })
          }, 2500)
        } catch (e) {
          console.log(e)
          this.loading = false
          if (e.response?.data?.error === 'No coordinates found for given address') {
            Vue.toasted.show(this.$t('message.savingErrors.buildingCreation'), { type: 'error' })
          } else {
            Sentry.captureException(e)
            Vue.toasted.show(this.$t('message.savingErrors.property'), { type: 'error' })
          }
          if (this.property.plotArea === '-1' || this.property.plotArea === -1) {
            this.property.plotArea = ''
          }
          ObjectApi.deleteProperty([this.property.id])
            .then(() => {
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.deleteErrors.property'), { type: 'error' })
            })
        }
      } else {
        this.scrollToError()
      }
    }
  },
  validations() {
    const validationProperty = {
      property: {
        name: {
          required
        },
        plotArea: {
          minValue: minValue(-1),
          decimal
        },
        type: {
          required
        },
        address: {
          country: {
            required
          },
          zip: {
            required,
            isZipValid: isZipValid(this.property.address)
          },
          city: {
            required
          },
          state: {
            required
          }
        },
        buildings: {
          $each: {
            name: {
              required
            },
            address: {
              street: {
                required
              }
            },
            objects: {
              $each: {
                category: {
                  required,
                  isCategoryUndefined
                },
                amount: {
                  required
                }
              }
            }
          }
        }
      }
    }
    if (this.property.opted) {
      validationProperty.property.optedPortionKey = {
        required
      }
    }
    return validationProperty
  }
}
</script>
<style  lang="scss" scoped>
:deep(.textAreaHeight textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}
</style>
