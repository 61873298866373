import store from '../store/store'
import UserApi from '../misc/apis/UserApi'
import { connect } from 'getstream'

const notification = {
  data() {
    return {
      streamClient: null
    }
  },
  methods: {
    async loadGetStreamio() {
      if (!store.getters['notification/getToken']) {
        await UserApi.getNotificationToken(store.getters['user/getUserId'])
          .then(response => {
            store.dispatch('notification/setToken', response.token).then().catch(e => console.log(e))
            this.streamClient = connect(process.env.VUE_APP_GET_STREAM_APP_KEY, response.token, process.env.VUE_APP_GET_STREAM_APP_ID, { location: 'eu-west', timeout: 15000 })
          })
          .catch(e => {
            console.log(e)
          })
      } else {
        this.streamClient = connect(process.env.VUE_APP_GET_STREAM_APP_KEY, store.getters['notification/getToken'], process.env.VUE_APP_GET_STREAM_APP_ID, { location: 'eu-west', timeout: 15000 })
      }
    }
  }
}

export { notification }
