import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import { i18n } from './internationalization/i18nSetup'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import CoozzyComponents from './misc/loader/global/frameworkComponents'
import CoozzyFilter from './misc/loader/global/filter'
import CoozzyLayout from './misc/loader/global/frameworkLayout'

import AuthPlugin from './misc/plugins/auth0'

import FormWizard from 'vue-form-wizard'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as VueGoogleMaps from 'vue2-google-maps'
import Clipboard from 'v-clipboard'
import Vuelidate from 'vuelidate'
import VueTextareaAutosize from 'vue-textarea-autosize'
import * as Sentry from '@sentry/vue'
import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues
import Toasted from 'vue-toasted'
import InstantSearch from 'vue-instantsearch'
import VueCountryRegionSelect from '@coozzy/vue-country-region-select'
import browserDetect from 'vue-browser-detect-plugin'
import VueMeta from 'vue-meta'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VTooltip from 'v-tooltip'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

Vue.use(VTooltip)
Vue.use(VueViewer)

Vue.use(BootstrapVue)
Vue.use(CoozzyLayout)
Vue.use(CoozzyComponents)
Vue.use(CoozzyFilter)
Vue.use(AuthPlugin)
Vue.use(Clipboard)
Vue.use(Vuelidate)
Vue.use(VueTextareaAutosize)
Vue.use(FormWizard)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places'
  }
})
Vue.use(Toasted, {
  position: 'top-center',
  duration: 5000,
  singleton: true
})
Vue.use(InstantSearch)
Vue.use(VueCountryRegionSelect)
Vue.use(browserDetect)
Vue.use(VueMeta)
Vue.use(VueDOMPurifyHTML, {
  hooks: {
    afterSanitizeAttributes: (node) => {
      // set all elements owning href to target=_blank
      if ('href' in node) {
        node.setAttribute('target', '_blank')
      }
    }
  }
})

Vue.component('v-select', vSelect)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('multiselect', Multiselect)
Vue.component('GmapCluster', GmapCluster)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://9f61a7cd865d4b60a7705211c8fd9aa9@o251494.ingest.sentry.io/1437729',
    release: process.env.VUE_APP_VERSION || undefined,
    attachProps: true,
    tracesSampleRate: 0.1
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
