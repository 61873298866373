<template>
  <img
    class="briefButler-icon"
    src="@/assets/icon/BriefButlerIcon.png"
    alt="filterIcon">
</template>

<script>

export default {
  name: 'CoozzyBriefButlerIcon'
}
</script>

<style lang="scss" scoped>
.briefButler-icon {
  height: auto;
  width: 1.09375rem;
  margin: 0 0.5rem 0 0;
}
</style>
