<template>
  <coozzy-card>
    <h5
      class="mb-0 title-card">
      {{ title }}<span
        v-if="statistics"
        class="text-blue"> | <b>{{ statistics }}</b> {{ objects }} </span>
    </h5>
    <div class="col body text-center bottom_radius">
      <slot />
    </div>
  </coozzy-card>
</template>

<script>

import CoozzyCard from '@/framework/components/card/CoozzyCard'

export default {
  name: 'CoozzyStatisticsCard',
  components: { CoozzyCard },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: ''
    },
    statistics: {
      type: [String, Number],
      default: ''
    },
    objects: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.title-card {
  background-color: $color-bg-light-blue;
  border: none;
  position: relative;
  padding: 0 1.25rem;
  margin-bottom: 0;
  line-height: 3rem;
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}

:deep(.card-body) {
  padding: 0 !important;
}

.bottom_radius {
  border-bottom-left-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
  padding: 0 1.25rem;
}

:deep(p) {
  color: $color-text-grey !important;
}
</style>
