<template>
  <section>
    <ais-pagination>
      <div
        slot-scope="{
          currentRefinement,
          nbPages,
          pages,
          isFirstPage,
          isLastPage,
          refine,
          createURL
        }"
        class="ais-Pagination mb-3">
        {{ defaultRefining(refine) }}
        <ul
          class="ais-Pagination-list">
          <button
            ref="btnClick"
            :href="createURL(0)"
            class="d-none"
            @click="refine(0)">
            click
          </button>
          <button
            ref="btnClickInit"
            :href="createURL(localPage)"
            class="d-none"
            @click="refine(localPage)">
            click init
          </button>
          <li
            :class="['ais-Pagination-item', 'ais-Pagination-item--firstPage', isFirstPage ? 'ais-Pagination-item--disabled' : '']">
            <a
              :ref="'firstPage'"
              :href="createURL(0)"
              class="ais-Pagination-link"
              @click="changedPage()"
              @click.prevent="refine(0)">
              ‹‹
            </a>
          </li>
          <li
            :class="['ais-Pagination-item', 'ais-Pagination-item--firstPage', isFirstPage ? 'ais-Pagination-item--disabled' : '']">
            <a
              :href="createURL(currentRefinement - 1)"
              class="ais-Pagination-link"
              @click="changedPage()"
              @click.prevent="refine(currentRefinement - 1)">
              ‹
            </a>
          </li>
          <li
            v-for="page in pages"
            :key="page"
            :class="['ais-Pagination-item', page === currentRefinement ? 'ais-Pagination-item--selected' : '']">
            <a
              :href="createURL(page)"
              class="ais-Pagination-link"
              @click="changedPage()"
              @click.prevent="refine(page)">
              {{ page + 1 }}
            </a>
          </li>
          <li
            :class="['ais-Pagination-item', 'ais-Pagination-item--nextPage', isLastPage ? 'ais-Pagination-item--disabled' : '']">
            <a
              :href="createURL(currentRefinement + 1)"
              class="ais-Pagination-link"
              @click="changedPage()"
              @click.prevent="refine(currentRefinement + 1)">
              ›
            </a>
          </li>
          <li
            :class="['ais-Pagination-item', 'ais-Pagination-item--lastPage', isLastPage ? 'ais-Pagination-item--disabled' : '']">
            <a
              :href="createURL(nbPages)"
              class="ais-Pagination-link"
              @click="changedPage()"
              @click.prevent="refine(nbPages)">
              ››
            </a>
          </li>
        </ul>
      </div>
    </ais-pagination>
  </section>
</template>

<script>

import { connectSearchBox } from 'instantsearch.js/es/connectors'
import { createWidgetMixin } from 'vue-instantsearch'

export default {
  name: 'PaginationAlgolia',
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  data() {
    return {
      localPage: 0
    }
  },
  watch: {
    currentRefinement: function () {
      alert()
    }
  },
  methods: {
    refresh() {
      setTimeout(() => {
        if (this.$refs.btnClick) {
          this.$refs.btnClick.click()
        }
        this.$emit('clicked-done')
      }, 1500)
    },
    redirect(page) {
      if (!isNaN(page) && !Number.isNaN(page)) {
        this.localPage = parseInt(page) - 1
      }
      setTimeout(() => {
        if (this.$refs.btnBlickInit) {
          this.$refs.btnClickInit.click()
        }
      }, 1500)
    },
    defaultRefining(refine) {
      if (localStorage.getItem('algolia-pagination-mounted')) {
        localStorage.removeItem('algolia-pagination-mounted')
        if (this.$route.query) {
          const query = this.$route.query
          const key = Object.keys(query).find(key => key.includes('[page]'))
          if (key) {
            const page = query[key]
            if (page && parseInt(page) > 1) {
              setTimeout(() => {
                refine(parseInt(page) - 1)
              }, 400)
            }
          }
        }
      }
    },
    changedPage() {
      this.$emit('changed-page')
    }
  }
}
</script>
