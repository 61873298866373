<template>
  <section>
    <div class="floating-wrapper">
      <coozzy-button
        v-if="!onboardingLoading"
        :disabled="!hasChanged || dataProcessing"
        class="floating-button"
        design="transparent"
        @click="saveData">
        <coozzy-save-icon />
      </coozzy-button>
    </div>
    <div class="row">
      <div
        v-if="building === null || onboardingLoading"
        class="col-12 text-center">
        <coozzy-spinner class="m-3" />
      </div>
      <div
        v-else
        class="col-12">
        <coozzy-page-title
          :sub-title="$t('message.propertiesView.tabs.property') +': ' + propertyName + ' | ' + $t('message.onBoarding.buildings.address') + ': ' + building.address.street + ' ' + building.address.streetNumber + ', ' +building.address.zip + ' ' + building.address.city"
          :title="$t('message.onBoarding.buildings.title') + ': ' + building.name" />
        <!-- Action buttons on mobile -->
        <div class="row d-md-none">
          <div class="col-12 mb-2">
            <router-link
              :to="toPropertyClicked()">
              <coozzy-button
                class="w-100"
                design="secondary">
                {{ $t('message.onBoardingTabs.toProperty') }}
              </coozzy-button>
            </router-link>
          </div>
          <div class="col-12 mb-2">
            <coozzy-button
              :disabled="!hasChanged || dataProcessing"
              class="w-100"
              design="primary"
              @click="saveData">
              {{ $t('message.generic.form.save') }}
            </coozzy-button>
          </div>
          <div class="col-12 mb-2">
            <coozzy-button
              class="w-100"
              design="secondary"
              @click="showConfirm">
              {{ $t('message.generic.readView') }}
            </coozzy-button>
          </div>
        </div>
        <b-tabs
          v-model="tabIndex"
          class="mb-3">
          <b-tab
            class="p-0"
            lazy>
            <template #title>
              <router-link
                :to="getBuildingRoute('building')">
                <span>{{ $t('message.onBoardingTabs.building') }}</span>
              </router-link>
            </template>
            <create-building-tab ref="create-building-view" />
          </b-tab>
          <b-tab
            class="p-0"
            lazy>
            <template #title>
              <router-link
                :to="getBuildingRoute('objects')">
                <span>{{ $t('message.onBoardingTabs.objects') }}</span>
              </router-link>
            </template>
            <create-objects-tab
              :key="elementSelected"
              ref="create-objects-view"
              :element-selected="elementSelected"
              :object-filters="objectFilters"
              @selected-object="updateElementSelected"
              @change-filter="updateWithFilter" />
          </b-tab>
          <b-tab
            v-if="propertyType === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"
            class="p-0"
            lazy>
            <template #title>
              <router-link
                :to="getBuildingRoute('condominium')">
                <span>{{ $t('message.onBoardingTabs.condominium') }}</span>
              </router-link>
            </template>
            <create-condominium-tab
              ref="create-condominium-tab"
              :condominium-filters="condominiumFilters"
              :element-selected="elementSelected"
              @selected-condominium="updateElementSelected"
              @change-filter="updateWithFilter" />
          </b-tab>
          <b-tab
            v-else
            class="p-0"
            lazy>
            <template #title>
              <router-link
                :to="getBuildingRoute('tenants')">
                <span>{{ $t('message.onBoardingTabs.tenants') }}</span>
              </router-link>
            </template>
            <create-tenants-tab
              ref="create-tenants-tab"
              :element-selected="elementSelected"
              :tenant-filters="tenantFilters"
              @selected-tenancy="updateElementSelected"
              @change-filter="updateWithFilter" />
          </b-tab>
          <b-tab
            class="p-0"
            lazy>
            <template #title>
              <router-link
                :to="getBuildingRoute('devices')">
                <span>{{ $t('message.onBoardingTabs.devices') }}</span>
              </router-link>
            </template>
            <create-devices-tab
              :key="elementSelected"
              ref="create-devices-tab"
              :add-new-item-device="addNewItemDevice"
              :device-filters="deviceFilters"
              :element-selected="$route.query.elementSelected ? $route.query.elementSelected : elementSelected"
              :property-id="propertyId"
              @selected-device="updateElementSelected"
              @change-filter="updateWithFilter" />
          </b-tab>
          <b-tab
            v-if="isInvestPlanEnabled"
            lazy
            class="p-0">
            <template #title>
              <router-link
                :to="getBuildingRoute('investmentPlanning') !== undefined ? getBuildingRoute('investmentPlanning') : ''">
                <span>{{ $t('message.onBoarding.investmentPlanning.title') }}</span>
              </router-link>
            </template>
            <building-invest-plan-tab
              ref="investPlanTab"
              :add-new-component="addNewComponent"
              @building-value-changed="buildingValueChanged" />
          </b-tab>
          <b-tab
            class="p-0"
            lazy>
            <template #title>
              <router-link
                :to="getBuildingRoute('contacts')">
                <span>{{ $t('message.onBoardingTabs.contacts') }}</span>
              </router-link>
            </template>
            <create-contacts-tab />
          </b-tab>
          <b-tab
            class="p-0">
            <template #title>
              <router-link
                class="tab_title_color"
                :to="getBuildingRoute('history')">
                <span>{{ $t('message.onBoardingTabs.history') }}</span>
                <span
                  v-if="hasNotification"
                  class="small-dot" />
              </router-link>
            </template>
            <history-tab
              ref="activitiesTab"
              :building="building"
              :device-list="buildingDevices.concat(objectDevices)"
              :history-filters="historyFilters"
              :route-name="$route.name"
              :element-selected="elementSelected"
              @has-notification="updateHasNotification"
              @change-filter="updateWithFilter" />
          </b-tab>
          <b-tab
            v-if="isPortalLinkEnabled"
            class="p-0">
            <template #title>
              <router-link
                :to="getBuildingRoute('portalLinks') !== undefined ? getBuildingRoute('portalLinks') : ''">
                <span>{{ $t('message.portalTab.title') }}</span>
              </router-link>
            </template>
            <building-portal-links-tab
              ref="portalLinksTab" />
          </b-tab>
          <template #tabs-end>
            <b-nav-item
              class="d-none d-md-block item-button">
              <coozzy-button
                :disabled="!hasChanged || dataProcessing"
                class="nav-button nav-button-center"
                design="primary"
                @click="saveData">
                {{ $t('message.generic.form.save') }}
              </coozzy-button>
            </b-nav-item>
            <b-nav-item
              class="d-none d-md-block item-button"
              href="#">
              <coozzy-button
                class="nav-button nav-button-right"
                design="secondary"
                @click="showConfirm">
                {{ $t('message.generic.readView') }}
              </coozzy-button>
            </b-nav-item>
            <b-nav-item
              class="d-none d-md-block item-button"
              href="#">
              <router-link
                :to="toPropertyClicked()">
                <coozzy-button
                  class="nav-button nav-button-right adaptToPropertyButton"
                  design="secondary">
                  {{ $t('message.onBoardingTabs.toProperty') }}
                </coozzy-button>
              </router-link>
            </b-nav-item>
          </template>
        </b-tabs>
      </div>
    </div>
    <b-modal
      :id="'modal-saveAfterInactive'"
      :ref="`modal-saveAfterInactive`"
      :title="$t('message.onBoarding.buildings.objects.inactivePopup.title')"
      hide-footer
      no-close-on-backdrop>
      <div class="col">
        <p>{{ $t('message.onBoarding.buildings.objects.inactivePopup.text', { objectId: objectActivated }) }}</p>
      </div>
      <div class="col">
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="$bvModal.hide('modal-saveAfterInactive')">
          {{ $t('message.onBoarding.buildings.objects.inactivePopup.ok') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      ref="modal-confirmation"
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer
      no-close-on-backdrop>
      <div class="row mb-2">
        <div class="col">
          <p class="mb-3">
            {{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}
          </p>
          <coozzy-button
            design="green"
            size="normal"
            @click="discardChanges">
            {{ $t('message.generic.discardChanges') }}
          </coozzy-button>
          <coozzy-button
            :disabled="dataProcessing"
            class="float-right"
            design="green"
            size="normal"
            @click="saveData">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
          <coozzy-button
            class="float-right mr-3 border"
            design="transparent"
            size="normal"
            @click="closeModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CreateBuildingTab from '../tabs/CreateBuildingTab'
import CreateObjectsTab from '../tabs/CreateObjectsTab'
import CreateTenantsTab from '../tabs/CreateTenantsTab'
import CreateCondominiumTab from '../tabs/CreateCondominiumTab'
import CreateDevicesTab from '../tabs/CreateDevicesTab'
import { user } from '@/mixins/user'
import { onboarding } from '@/mixins/onboarding'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CreateContactsTab from '../tabs/CreateContactsTab'
import CoozzySaveIcon from '@/framework/components/icons/CoozzySaveIcon'
import HistoryTab from '@/property/tabs/HistoryTab.vue'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import BuildingInvestPlanTab from '@/building/tabs/components/BuildingInvestPlanTab.vue'
import BuildingPortalLinksTab from '@/building/tabs/BuildingPortalLinksTab.vue'

export default {
  name: 'OnboardingView',
  components: {
    BuildingPortalLinksTab,
    BuildingInvestPlanTab,
    CoozzyPageTitle,
    CoozzySaveIcon,
    CoozzySpinner,
    CoozzyButton,
    CreateBuildingTab,
    CreateObjectsTab,
    CreateDevicesTab,
    CreateTenantsTab,
    CreateCondominiumTab,
    CreateContactsTab,
    HistoryTab
  },
  mixins: [user, onboarding, routeChecks],
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('onboarding/setOpenedCollapses')
    this.nextPath = next
    if (this.hasChanged) {
      this.$refs['modal-confirmation'].show()
    } else {
      this.nextPath()
    }
  },
  props: {
    elementSelected: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: null
    },
    view: {
      type: String,
      default: 'building'
    },
    objectFilters: {
      type: String,
      default: ''
    },
    deviceFilters: {
      type: String,
      default: ''
    },
    historyFilters: {
      type: String,
      default: ''
    },
    tenantFilters: {
      type: String,
      default: ''
    },
    condominiumFilters: {
      type: String,
      default: ''
    },
    addNewItemDevice: {
      type: Boolean,
      default: false
    },
    addNewComponent: {
      type: Boolean,
      default: false
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.onBoarding.buildings.title') + ' ' + this.buildingName
    }
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      nextPath: null,
      objectActivated: null,
      dataProcessing: false,
      hasNotification: false
    }
  },
  computed: {
    ...mapState('onboarding', ['errors']),
    buildingName() {
      return this.building && this.building.name ? this.building.name : ''
    },
    propertyName() {
      if (this.properties[0] === null || typeof this.properties[0] === 'undefined') {
        return '-'
      }
      return this.properties[0].name
    },
    propertyId() {
      return this.properties[0] ? this.properties[0].id : null
    },
    propertyType() {
      return this.properties.length > 0 ? this.properties[0].type : ''
    },
    isPortalLinkEnabled() {
      return this.$store.getters['onboarding/getPortalLinkEnabled']
    }
  },
  watch: {
    errors: function (newVal) {
      if (newVal.length > 0) {
        // Go to correct tab
        if (this.errors[0].entityType === 'building') {
          this.tabIndex = 0
        } else {
          this.tabIndex = 1
        }
      }
    },
    view: function () {
      if (this.isInvestPlanEnabled !== null) {
        this.openSelectedTabs()
      }
    },
    isInvestPlanEnabled: function () {
      this.openSelectedTabs()
    },
    tabIndex: function (newVal) {
      let newView = 'building'
      const propertyType = this.propertyType === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'
      if (newVal === 0) {
        newView = 'building'
      } else if (newVal === 1) {
        newView = 'objects'
      } else if (newVal === 2) {
        if (!propertyType) {
          newView = 'condominium'
        } else {
          newView = 'tenants'
        }
      } else if (newVal === 3) {
        newView = 'devices'
      } else if (newVal === 4 && this.isInvestPlanEnabled) {
        newView = 'investmentPlanning'
      } else if ((newVal === 5 && this.isInvestPlanEnabled) || (newVal === 4 && !this.isInvestPlanEnabled)) {
        newView = 'contacts'
      } else if ((newVal === 6 && this.isInvestPlanEnabled) || (newVal === 5 && !this.isInvestPlanEnabled)) {
        newView = 'history'
      } else if ((newVal === 7 && this.isInvestPlanEnabled) || (newVal === 6 && !this.isInvestPlanEnabled)) {
        newView = 'portalLinks'
      }
      if (this.view !== newView) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerOnboardingView',
            params: { id: this.id },
            query: {
              view: newView,
              elementSelected: this.elementSelected,
              from: this.from,
              objectFilters: this.objectFilters,
              tenantFilters: this.tenantFilters,
              condominiumFilters: this.condominiumFilters,
              deviceFilters: this.deviceFilters,
              historyFilters: this.historyFilters
            }
          }, () => {
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminOnboardingView',
            params: { id: this.id },
            query: {
              view: newView,
              elementSelected: this.elementSelected,
              from: this.from,
              objectFilters: this.objectFilters,
              tenantFilters: this.tenantFilters,
              condominiumFilters: this.condominiumFilters,
              deviceFilters: this.deviceFilters,
              historyFilters: this.historyFilters
            }
          }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetOnboardingView',
            params: { id: this.id },
            query: {
              view: newView,
              elementSelected: this.elementSelected,
              from: this.from,
              objectFilters: this.objectFilters,
              tenantFilters: this.tenantFilters,
              condominiumFilters: this.condominiumFilters,
              deviceFilters: this.deviceFilters,
              historyFilters: this.historyFilters
            }
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingOnboardingView',
            params: { id: this.id },
            query: {
              view: newView,
              elementSelected: this.elementSelected,
              from: this.from,
              objectFilters: this.objectFilters,
              tenantFilters: this.tenantFilters,
              condominiumFilters: this.condominiumFilters,
              deviceFilters: this.deviceFilters,
              historyFilters: this.historyFilters
            }
          }, () => {
          })
        }
      }
    },
    onboardingLoading: function () {
      this.$store.dispatch('onboarding/setLoadingFinish', true)
      this.$nextTick(() => {
        let scrollpos = localStorage.getItem('scrollpos')
        if (scrollpos) {
          window.scrollTo(0, parseInt(scrollpos))
        }
      })
    }
  },
  created() {
    this.$store.dispatch('onboarding/setLoadingFinish', false)
  },
  mounted() {
    localStorage.removeItem('toReadView')
    localStorage.removeItem('saveAfterInactive')
    this.loadOnboarding(this.id)
    this.openSelectedTabs()
    window.addEventListener('beforeunload', (event) => {
      if (this.hasChanged) {
        event.returnValue = this.$t('message.generic.discardChanges')
      }
    })
  },
  beforeDestroy: function () {
    localStorage.removeItem('buildingObject-nbrPerPage')
  },
  methods: {
    buildingValueChanged(value) {
      this.building.investmentPlanning = value
    },
    updateHasNotification(value) {
      this.hasNotification = value
    },
    toPropertyClicked() {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerPropertyEditingView',
          params: { id: this.properties[0].id }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminPropertyEditingView',
          params: { id: this.properties[0].id }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetPropertyEditingView',
          params: { id: this.properties[0].id }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingPropertyEditingView',
          params: { id: this.properties[0].id }
        }
      }
    },
    addNewDevice() {
      this.buildings[0].devices.push({ internalId: Date.now() })
      this.$nextTick(() => {
        const indexDevice = this.buildings[0].devices.length - 1
        const domRect = this.$el.querySelector('#device-' + indexDevice)
        domRect.scrollIntoView(true)
        this.$el.querySelector('#device-' + indexDevice + ' input').focus()
      })
    },
    autocompletePlaceChanged(data) {
      this.updateWithAddressComponents(data.address_components)
    },
    closeModal() {
      if (this.$refs['modal-confirmation']) {
        this.$refs['modal-confirmation'].hide()
      }
    },
    // countDownChanged(dismissCountDown) {
    //   this.updateDismissCountDown(dismissCountDown)
    // },
    discardChanges() {
      this.closeModal()
      this.resetChanged()
      if (this.nextPath !== null) {
        this.nextPath()
      } else if (localStorage.getItem('toReadView')) {
        this.redirectToReadView()
      } else {
        this.$router.go(-1)
      }
    },
    openSelectedTabs() {
      if (this.view === 'building') {
        this.tabIndex = 0
      } else if (this.view === 'objects') {
        this.tabIndex = 1
      } else if (this.view === 'tenants' || this.view === 'condominium') {
        this.tabIndex = 2
      } else if (this.view === 'devices') {
        this.tabIndex = 3
      } else if (this.view === 'investmentPlanning' && this.isInvestPlanEnabled) {
        this.tabIndex = 4
      } else if (this.view === 'contacts') {
        this.tabIndex = this.isInvestPlanEnabled ? 5 : 4
      } else if (this.view === 'history') {
        this.tabIndex = this.isInvestPlanEnabled ? 6 : 5
      } else if (this.view === 'portalLinks') {
        this.tabIndex = this.isInvestPlanEnabled ? 7 : 6
      }
    },
    removeDevice(index) {
      this.$delete(this.buildings[0].devices, index)
    },
    async saveData() {
      // dataProcessing is used to disable the save button to avoid multiple clicks
      this.dataProcessing = true
      // check if all tabs are valid
      const isBuildingViewValid = !(this.$refs['create-building-view'] && this.$refs['create-building-view'].isInvalid())
      const isObjectsViewValid = !(this.$refs['create-objects-view'] && this.$refs['create-objects-view'].isInvalid())
      const isTenantsViewValid = !(this.$refs['create-tenants-tab'] && this.$refs['create-tenants-tab'].isInvalid())
      const isCondominiumsViewValid = !(this.$refs['create-condominium-tab'] && this.$refs['create-condominium-tab'].isInvalid())
      const isDevicesViewValid = !(this.$refs['create-devices-tab'] && this.$refs['create-devices-tab'].isInvalid())
      const isPortalLinksViewValid = !(this.$refs.portalLinksTab && this.$refs.portalLinksTab.isInvalid())
      console.log('Validation state: ' + JSON.stringify({
        isBuildingViewValid: isBuildingViewValid,
        isObjectViewValid: isObjectsViewValid,
        isTenantViewValid: isTenantsViewValid,
        isCondominiumsViewValid: isCondominiumsViewValid,
        isDevicesViewValid: isDevicesViewValid,
        isPortalLinksViewValid: isPortalLinksViewValid
      }))
      // I think create-objects-view has multiple child levels it take some time to verify validation
      this.$nextTick(async () => {
        if (isBuildingViewValid && isObjectsViewValid && isTenantsViewValid && isDevicesViewValid && isPortalLinksViewValid) {
          await this.$store.dispatch('onboarding/setOpenedCollapses')
          this.closeModal()
          localStorage.setItem('scrollpos', window.scrollY)
          // const result = await this.saveOnboarding()
          // Abort if saving failed
          // if (result === false) {
          // We use a nextTick + timeout to be absolutely after expanding the necessary collapses
          // if (this.$refs['create-building-view']) {
          //   this.$refs['create-building-view'].isInvalid()
          // }
          // if (this.$refs['create-objects-view']) {
          //   this.$refs['create-objects-view'].isInvalid()
          // }
          // if (this.propertyType !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP' && this.$refs['create-tenants-tab']) {
          //   this.$refs['create-tenants-tab'].isInvalid()
          // }
          // if (this.propertyType === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP' && this.$refs['create-condominium-tab']) {
          //   this.$refs['create-condominium-tab'].isInvalid()
          // }
          // if (this.$refs['create-devices-tab']) {
          //   this.$refs['create-devices-tab'].isInvalid()
          // }
          this.dataProcessing = false
          await this.$store.dispatch('onboarding/setLoading', true)
          const result = await this.saveOnboarding()
          if (result === false) {
            return false
          }
          // return
          // }
          if (localStorage.getItem('toReadView')) {
            localStorage.removeItem('toReadView')
            this.redirectToReadView()
          } else if (localStorage.getItem('saveAfterInactive')) {
            this.objectActivated = localStorage.getItem('saveAfterInactive')
            this.$refs['modal-saveAfterInactive'].show()
            localStorage.removeItem('saveAfterInactive')
          } else if (localStorage.getItem('newDeviceId') !== null && this.view === 'devices') {
            const query = {
              view: this.view,
              elementSelected: localStorage.getItem('newDeviceId'),
              objectFilters: this.objectFilters,
              tenantFilters: this.tenantFilters,
              condominiumFilters: this.condominiumFilters,
              deviceFilters: this.deviceFilters,
              historyFilters: this.historyFilters
            }
            if (this.isOwnerModule) {
              this.$router.push({
                name: 'OwnerOnboardingView',
                params: { id: this.id },
                query: query
              })
            } else if (this.isAdminModule) {
              this.$router.push({
                name: 'AdminOnboardingView',
                params: { id: this.id },
                query: query
              })
            } else if (this.isAssetModule) {
              this.$router.push({
                name: 'AssetOnboardingView',
                params: { id: this.id },
                query: query
              })
            } else if (this.isAccountingModule) {
              this.$router.push({
                name: 'AccountingOnboardingView',
                params: { id: this.id },
                query: query
              })
            }
            localStorage.removeItem('newDeviceId')
          } else if (this.nextPath !== null) {
            this.nextPath()
          }
        } else {
          this.dataProcessing = false
          await this.$store.dispatch('onboarding/setLoading', false)
          if (!isBuildingViewValid) {
            this.tabIndex = 0
          } else if (!isObjectsViewValid) {
            this.tabIndex = 1
          } else if (!isTenantsViewValid) {
            this.tabIndex = 2
          }
        }
      })
    },
    showConfirm() {
      if (this.hasChanged) {
        localStorage.setItem('toReadView', true)
        this.$refs['modal-confirmation'].show()
      } else {
        this.redirectToReadView()
      }
    },
    redirectToReadView() {
      localStorage.setItem('scrollpos', window.scrollY)
      const selectedElement = this.$route.query.elementSelected || this.elementSelected
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerBuildingDetailsView',
          params: { id: this.id },
          query: {
            view: this.view,
            elementSelected: selectedElement,
            from: this.from,
            objectFilters: this.objectFilters,
            tenantFilters: this.tenantFilters,
            condominiumFilters: this.condominiumFilters,
            deviceFilters: this.deviceFilters,
            historyFilters: this.historyFilters
          }
        }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminBuildingDetailsView',
          params: { id: this.id },
          query: {
            view: this.view,
            elementSelected: selectedElement,
            from: this.from,
            objectFilters: this.objectFilters,
            tenantFilters: this.tenantFilters,
            condominiumFilters: this.condominiumFilters,
            deviceFilters: this.deviceFilters,
            historyFilters: this.historyFilters
          }
        }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetBuildingDetailsView',
          params: { id: this.id },
          query: {
            view: this.view,
            elementSelected: selectedElement,
            from: this.from,
            objectFilters: this.objectFilters,
            tenantFilters: this.tenantFilters,
            condominiumFilters: this.condominiumFilters,
            deviceFilters: this.deviceFilters,
            historyFilters: this.historyFilters
          }
        }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingBuildingDetailsView',
          params: { id: this.id },
          query: {
            view: this.view,
            elementSelected: selectedElement,
            from: this.from,
            objectFilters: this.objectFilters,
            tenantFilters: this.tenantFilters,
            condominiumFilters: this.condominiumFilters,
            deviceFilters: this.deviceFilters,
            historyFilters: this.historyFilters
          }
        }, () => {
        })
      }
    },
    updateDevice(item) {
      this.$set(this.buildings[0].devices, item.index, item.device)
    },
    updateElementSelected(item) {
      if (item && item.id && this.elementSelected !== item.id) {
        const query = {
          view: this.view,
          elementSelected: item.id,
          objectFilters: this.objectFilters,
          tenantFilters: this.tenantFilters,
          condominiumFilters: this.condominiumFilters,
          deviceFilters: this.deviceFilters,
          historyFilters: this.historyFilters
        }
        if (this.isOwnerModule) {
          // this.$router.push({ name: 'OwnerOnboardingView', params: { id: this.id }, query: query })
          this.$route.query.elementSelected = item.id
          const route = this.$router.resolve({
            name: 'OwnerOnboardingView',
            params: { id: this.id },
            query: query
          })
          history.replaceState(
            {},
            null,
            `${route.href}`
          )
        } else if (this.isAdminModule) {
          // this.$router.push({ nam/**/e: 'AdminOnboardingView', params: { id: this.id }, query: query })
          this.$route.query.elementSelected = item.id
          const route = this.$router.resolve({
            name: 'AdminOnboardingView',
            params: { id: this.id },
            query: query
          })
          history.replaceState(
            {},
            null,
            `${route.href}`
          )
        } else if (this.isAssetModule) {
          // this.$router.push({ name: 'AssetOnboardingView', params: { id: this.id }, query: query })
          this.$route.query.elementSelected = item.id
          const route = this.$router.resolve({
            name: 'AssetOnboardingView',
            params: { id: this.id },
            query: query
          })
          history.replaceState(
            {},
            null,
            `${route.href}`
          )
        } else if (this.isAccountingModule) {
          // this.$router.push({ name: 'AccountingOnboardingView', params: { id: this.id }, query: query })
          this.$route.query.elementSelected = item.id
          const route = this.$router.resolve({
            name: 'AccountingOnboardingView',
            params: { id: this.id },
            query: query
          })
          history.replaceState(
            {},
            null,
            `${route.href}`
          )
        }
      }
    },
    updateWithFilter(newVal, field) {
      const query = {
        view: this.view,
        elementSelected: this.elementSelected,
        objectFilters: this.objectFilters,
        tenantFilters: this.tenantFilters,
        condominiumFilters: this.condominiumFilters,
        deviceFilters: this.deviceFilters,
        historyFilters: this.historyFilters,
        addNewItemDevice: this.addNewItemDevice,
        addNewComponent: this.addNewComponent
      }
      const initQuery = JSON.stringify(query)
      query[field] = newVal
      if (initQuery !== JSON.stringify(query)) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerOnboardingView',
            params: { id: this.id },
            query: query
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetOnboardingView',
            params: { id: this.id },
            query: query
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingOnboardingView',
            params: { id: this.id },
            query: query
          })
        } else {
          this.$router.push({
            name: 'AdminOnboardingView',
            params: { id: this.id },
            query: query
          })
        }
      }
    },
    updateWithAddressComponents(addressComponents) {
      const streetNo = addressComponents.find((component) => component.types.find((type) => type === 'street_number'))
      const street = addressComponents.find((component) => component.types.find((type) => type === 'route'))
      const city = addressComponents.find((component) => component.types.find((type) => type === 'locality'))
      const zip = addressComponents.find((component) => component.types.find((type) => type === 'postal_code'))
      const state = addressComponents.find((component) => component.types.find((type) => type === 'administrative_area_level_1'))
      const country = addressComponents.find((component) => component.types.find((type) => type === 'country'))

      if (country && country.short_name === 'CH') {
        if (streetNo) {
          this.buildings[0].address.streetNo = streetNo.long_name
        }
        if (street) {
          this.buildings[0].address.street = street.long_name
        }
        if (city) {
          this.buildings[0].address.city = city.long_name
        }
        if (zip) {
          this.buildings[0].address.zip = zip.long_name
        }
        if (state) {
          this.buildings[0].address.state = state.short_name
        }
      } else {
        // TODO Alert
      }
    },
    getBuildingRoute(view) {
      const selectedElement = this.$route.query?.elementSelected !== '' ? this.$route.query.elementSelected : this.elementSelected
      if (this.isOwnerModule) {
        return {
          name: 'OwnerOnboardingView',
          params: { id: this.id },
          query: {
            view: view,
            elementSelected: selectedElement
          }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminOnboardingView',
          params: { id: this.id },
          query: {
            view: view,
            elementSelected: selectedElement
          }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetOnboardingView',
          params: { id: this.id },
          query: {
            view: view,
            elementSelected: selectedElement
          }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingOnboardingView',
          params: { id: this.id },
          query: {
            view: view,
            elementSelected: selectedElement
          }
        }
      }
    }
  },
  validations: {
    buildings: {
      required,
      $each: {
        address: {
          street: {
            required
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.floating-wrapper {
  right: 5vw;
  bottom: 4vh;
  z-index: 999;
  position: fixed;

  .floating-button {
    border-radius: 100px !important;
    display: flex;
    align-items: center;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.20), 0 4px 4px rgba(0, 0, 0, 0.15);
    z-index: 2;
    justify-content: center;
    background-color: $color-primary-dark;
    padding: 20px !important;
    cursor: pointer;

    svg {
      color: white;
      font-size: 15px;
    }
  }
}

.inputs-container {
  overflow-y: scroll;
  height: calc(100vh - 100px);
}

.item {
  cursor: pointer;
}

:deep(.collapse-title) {
  &.collapsed {
    h5 {
      background-image: url('../../assets/arrow_down.svg');
    }
  }

  h5 {
    background-image: url('../../assets/arrow_up.svg');
    background-repeat: no-repeat;
    font-size: large;
    font-weight: 500 !important;
    height: 1.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    background-position: right;
  }
}

:deep(.global-card) {
  margin-bottom: 0;

  & > .card-body {
    -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
  }

  border-left: 0;
  border-right: 0;
  border-bottom: 0;

  &.no-top-border {
    border-top: 0;
  }
}

:deep(.tabs .nav-tabs .nav-link a.router-link-active), :deep(.tabs .nav-tabs .nav-link a) {
  color: inherit !important;
  text-decoration: none !important;
}
.adaptToPropertyButton {
  // check css on staging before change it
  top: 40px;
  max-height: 30px;
}
</style>
