<template>
  <section>
    <div>
      <!-- Heading -->
      <coozzy-page-title
        :title="$t('message.navigation.closedTickets')"
        :count="ticketLength"
        :count-text="$t('message.ticketOverview.title')"
        :action-text="isEditor ? $t('message.ticketOverview.createTicket') : ''"
        :clear-filter="checkfilter"
        @click="createTicket"
        @clear-filter="clickOnClear" />
      <ticket-overview-data
        ref="ticket-overview-data"
        :default-text="defaultText"
        :default-type="defaultType"
        :default-creator="defaultCreator"
        :default-assigne="defaultAssigne"
        :default-priority="defaultPriority"
        :default-overdue="defaultOverdue"
        :default-watched="defaultWatched"
        :default-empty-assigne="defaultEmptyAssignee"
        :reference-ids="referenceIds"
        :closed-ticket="true"
        @is-filtered="setCheckfilter"
        @ticket-length="getTicketLength" />
    </div>
  </section>
</template>

<script>
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import TicketOverviewData from './TicketOverviewData'
import { user } from '@/mixins/user'

export default {
  name: 'ClosedTicketOverview',
  components: {
    CoozzyPageTitle,
    TicketOverviewData
  },
  mixins: [user],
  metaInfo() {
    return {
      title: this.$t('message.navigation.tickets')
    }
  },
  props: {
    defaultText: {
      type: String,
      default: ''
    },
    defaultType: {
      type: String,
      default: ''
    },
    defaultCreator: {
      type: String,
      default: ''
    },
    defaultAssigne: {
      type: String,
      default: ''
    },
    defaultPriority: {
      type: String,
      default: ''
    },
    defaultOverdue: {
      type: String,
      default: ''
    },
    defaultWatched: {
      type: String,
      default: ''
    },
    referenceIds: {
      type: String,
      default: ''
    },
    defaultEmptyAssignee: {
      type: [Boolean, String],
      default: false
    }
  },
  data() {
    return {
      checkfilter: false,
      ticketLength: 0,
      isColumnView: true
    }
  },
  methods: {
    createTicket() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation' }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation' }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation' }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation' }, () => {
        })
      } else {
        this.$router.push({ name: 'MarketingTicketCreation' }, () => {
        })
      }
    },
    getTicketLength(value) {
      this.ticketLength = value
    },
    setCheckfilter(value) {
      this.checkfilter = value
    },
    clickOnClear() {
      this.$refs['ticket-overview-data'].initFilter()
    }
  }
}
</script>
