<template>
  <svg
    :id="name"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="12px"
    height="12px"
    viewBox="0 0 489.4 489.4"
    style="enable-background:new 0 0 489.4 489.4;"
    :style="active ? 'filter: invert(30%) sepia(8%) saturate(171%) hue-rotate(161deg) brightness(92%) contrast(89%)' : 'filter: invert(70%) sepia(0%) saturate(50%) hue-rotate(102deg) brightness(100%) contrast(100%);'"
    xml:space="preserve">
    <g>
      <g>
        <path
          d="M99,147v51.1h-3.4c-21.4,0-38.8,17.4-38.8,38.8v213.7c0,21.4,17.4,38.8,38.8,38.8h298.2c21.4,0,38.8-17.4,38.8-38.8V236.8
          c0-21.4-17.4-38.8-38.8-38.8h-1v-51.1C392.8,65.9,326.9,0,245.9,0C164.9,0.1,99,66,99,147z M267.7,353.2c-3,2.2-3.8,4.3-3.8,7.8
          c0.1,15.7,0.1,31.3,0.1,47l0,0c0.3,6.5-3,12.9-8.8,15.8c-13.7,7-27.4-2.8-27.4-15.8v-0.1c0-15.7,0-31.4,0.1-47.1
          c0-3.2-0.7-5.3-3.5-7.4c-14.2-10.5-18.9-28.4-11.8-44.1c6.9-15.3,23.8-24.3,39.7-21.1c17.7,3.6,30,17.8,30.2,35.5
          C282.5,336,277.6,346,267.7,353.2z M163.3,147c0-45.6,37.1-82.6,82.6-82.6c45.6,0,82.6,37.1,82.6,82.6v51.1H163.3V147z" />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
</template>

<script>

export default {
  name: 'CoozzyPadlockIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    classCss: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
