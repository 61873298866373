<template>
  <section>
    <div class="background-section">
      <div class="cover-photo">
        <h2>{{ $t('message.help.title') }}</h2>
      </div>
    </div>
    <div class="main-section">
      <div class="content-wrap row">
        <div class="left-part">
          <h2>{{ $t('message.help.leftTitle') }}</h2>
          <ul>
            <li
              v-for="cat in categoryList"
              :key="cat.id"
              :class="cat.id === selectedCategory ? 'active' : ''"
              @click="selectedCategory = cat.id">
              {{ cat[categoryKey] }}
            </li>
          </ul>
        </div>
        <div class="body-part">
          <div class="main-container">
            <h2>{{ categoryList[selectedCategory-1][categoryKey] }}</h2>
            <div class="collpase-container">
              <div
                v-for="(faq, index) in faqList"
                :key="index">
                <b-button
                  v-b-toggle="'collapse-' + faq.id"
                  variant="primary">
                  {{ faq[questionKey] }}
                  <coozzy-angle-right-icon />
                </b-button>
                <b-collapse
                  :id="'collapse-'+faq.id"
                  :visible="index === 0"
                  accordion="help"
                  class="mt-2 mb-2 collapse-text">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="faq[answerKey]" />
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="right-part">
          <h2>{{ $t('message.help.rightTitle') }}</h2>
          <div class="faq-form">
            <coozzy-form-input
              v-model="searchTerm"
              :placeholder="$t('message.help.placeholder')"
              class="search-faq-block mb-3" />
            <coozzy-search-icon />
          </div>
          <ul>
            <li
              v-for="result in searchResult"
              :key="result.id"
              @click="selectFaq(result)">
              {{ result[questionKey] }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <coozzy-footer />
  </section>
</template>

<script>
import faqJson from '../assets/data/faq.json'
import categoryJson from '../assets/data/faq-category.json'
import { language } from '../mixins/language'

export default {
  name: 'Help',
  mixins: [language],
  data() {
    return {
      searchTerm: '',
      showCollapse: false,
      defaultList: faqJson,
      categoryList: categoryJson,
      selectedCategory: 1
    }
  },
  computed: {
    answerKey() {
      let language = this.$store.getters['internationalization/getCurrentLanguage']
      if (language === 'de') {
        return 'german_answer'
      } else if (language === 'fr') {
        return 'french_answer'
      } else if (language === 'en') {
        return 'english_answer'
      } else {
        return 'italian_answer'
      }
    },
    categoryKey() {
      let language = this.$store.getters['internationalization/getCurrentLanguage']
      if (language === 'de') {
        return 'german_category'
      } else if (language === 'fr') {
        return 'french_category'
      } else if (language === 'en') {
        return 'english_category'
      } else {
        return 'italian_category'
      }
    },
    faqList() {
      return this.defaultList.filter(faq => faq.category_id === this.selectedCategory)
    },
    questionKey() {
      let language = this.$store.getters['internationalization/getCurrentLanguage']
      if (language === 'de') {
        return 'german_question'
      } else if (language === 'fr') {
        return 'french_question'
      } else if (language === 'en') {
        return 'english_question'
      } else {
        return 'italian_question'
      }
    },
    searchResult() {
      if (this.searchTerm === '') {
        let array1 = [1, 2, 22]
        return this.defaultList.filter((faq) => {
          return array1.indexOf(faq.id) !== -1
        })
      } else {
        return this.defaultList.filter((faq) => {
          return faq[this.questionKey].toLowerCase().includes(this.searchTerm.toLowerCase())
        }).slice(0, 4)
      }
    }
  },
  methods: {
    selectFaq(faq) {
      this.selectedCategory = faq.category_id
      this.$nextTick(function () {
        this.$root.$emit('bv::toggle::collapse', 'collapse-' + faq.id)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.faq-form) {
    background: $color-bg-green;
    padding: 15px 0;
    position: relative;

    .search-faq-block {
      width: 88%;
      background: white;
      margin-bottom: 0 !important;
      border: 1px solid rgba(178, 180, 181, 0.36);
      border-radius: 20px;
      margin-left: 10%;

      > .row:first-child {
        display: none;
      }

      input {
        padding-left: 15px;
        border: none;
        background: transparent;
        font-size: 13px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    svg {
      color: #dddddd;
      position: absolute;
      right: calc(2% + 6px);
      top: 25px;
    }
  }

  .background-section {
    .cover-photo {
      background: url('../assets/faq-background.jpg');
      background-position: center center;
      background-size: cover;
      padding-top: 13em;
      min-height: 30em;
      width: 100%;
      position: relative;

      h2 {
        font-weight: 300;
        color: white;
        font-size: 36px;
        padding: 10px 50px;
        margin-left: 20%;
        margin-bottom: 0;
      }
    }
  }

  .main-container {
    .collpase-container {
      margin-right: 70px;

      > div {
        padding-left: 100px;
        border: 1px solid $color-bg-grey;
        border-top: 0 !important;

        &:first-child {
          border-top: 1px solid $color-bg-grey !important;
        }

        button {
          width: 100%;
          text-align: left;
          border: none;
          padding: 15px 0;
          background: white;
          font-size: 14px;
          color: $color-text-grey-dark;
          font-weight: bold;

          &:focus, &:hover, &:active {
            border: none;
            background: white;
            box-shadow: none !important;
            color: $color-text-grey-dark;
          }

          svg {
            font-size: 20px;
            float: right;
            margin-right: 15px;
            transform: rotate(90deg);
          }

          &.collapsed svg {
            transform: rotate(0deg);
          }
        }

        .collapse-text {
          color: $color-text-grey;
          font-size: 13px;

          :deep(a) {
            color: $color-primary;
          }
        }
      }
    }
  }

  .main-section {
    .right-part {
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
      background: #fff;
      float: left;
      width: 296px;

      h2 {
        color: black;
        padding: 14px 35px;
        font-size: 15px;
        font-weight: bold;
      }

      ul {
        padding-left: 0;

        li {
          cursor: pointer;
          margin-bottom: 10px;
          font-size: 12px;
          padding-left: 35px;
          position: relative;
          list-style: none;
          line-height: 25px;
          color: #9d9d9d;
        }
      }
    }

    .left-part {
      overflow: auto;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
      background: #fff;
      width: 296px;
      float: left;

      h2 {
        color: black;
        padding: 14px 35px;
        font-size: 15px;
        font-weight: bold;
      }

      ul {
        padding-left: 0;

        li {
          padding-left: 35px;
          position: relative;
          list-style: none;
          margin: 0;
          line-height: 45px;
          border-bottom: 1px solid #dededf;
          cursor: pointer;
          color: #9d9d9d;

          &.active, &:hover {
            background-color: #ebebeb;
            color: #585858;
          }
        }
      }
    }

    .body-part {
      background: #f9f9f9;
      width: calc(100% - 592px);
      padding: 0 40px;
      float: left;

      .main-container {
        min-height: 500px;
        background-color: white;

        h2 {
          padding: 35px 100px;
          color: #44b48e;
          margin-top: 0;
          font-size: 24px;
        }
      }
    }
  }
</style>
