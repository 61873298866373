<template>
  <section>
    <div
      v-if="account && ['ACCOUNTING', 'LANDLORD', 'MARKETING', 'LANDLORD_MARKETING', 'ASSET_MANAGEMENT', 'LANDLORD_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING', 'ASSET_LANDLORD', 'ASSET_MARKETING', 'ASSET_ACCOUNTING', 'ASSET_LANDLORD_ACCOUNTING', 'ASSET_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING_ACCOUNTING'].includes(account.subType)"
      class="row">
      <div class="col">
        <coozzy-card
          :title="$t('message.navigation.settings.editRegion')">
          <div
            v-if="loading"
            class="row">
            <div class="col">
              <coozzy-spinner />
            </div>
          </div>
          <div
            v-else
            class="row">
            <div
              v-if="['ACCOUNTING', 'LANDLORD_MARKETING_ACCOUNTING', 'ASSET_ACCOUNTING', 'ASSET_LANDLORD_ACCOUNTING', 'ASSET_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING_ACCOUNTING'].includes(account.subType)"
              class="col-sm-12 col-md-6 mb-2">
              <coozzy-multiselect
                id="accountingCanton"
                v-model="accountingCanton"
                :options="translatedAccountingCantons"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                label="label"
                track-by="value"
                :taggable="false">
                FINANCEbase
              </coozzy-multiselect>
            </div>
            <div
              v-if="['Landlord', 'LANDLORD_MARKETING', 'LANDLORD_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING', 'ASSET_LANDLORD', 'ASSET_LANDLORD_ACCOUNTING', 'ASSET_LANDLORD_MARKETING_ACCOUNTING'].includes(account.subType)"
              class="col-sm-12 col-md-6 mb-2">
              <coozzy-multiselect
                id="managementCanton"
                v-model="managementCanton"
                :options="translatedManagementCantons"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                label="label"
                track-by="value"
                :taggable="false">
                ADMINbase
              </coozzy-multiselect>
            </div>
            <div
              v-if="['MARKETING', 'LANDLORD_MARKETING','LANDLORD_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING', 'ASSET_MARKETING', 'ASSET_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING_ACCOUNTING'].includes(account.subType)"
              class="col-sm-12 col-md-6 mb-2">
              <coozzy-multiselect
                id="marketingCanton"
                v-model="marketingCanton"
                :options="translatedMarketingCantons"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                label="label"
                track-by="value"
                :taggable="false">
                MARKETbase
              </coozzy-multiselect>
            </div>
            <div
              v-if="['ASSET_MANAGEMENT', 'ASSET_LANDLORD_MARKETING', 'ASSET_LANDLORD', 'ASSET_MARKETING', 'ASSET_ACCOUNTING', 'ASSET_LANDLORD_ACCOUNTING', 'ASSET_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING_ACCOUNTING'].includes(account.subType)"
              class="col-sm-12 col-md-6 mb-2">
              <coozzy-multiselect
                id="managementStates"
                v-model="assetCanton"
                :options="translatedAssetCantons"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                label="label"
                track-by="value"
                :taggable="false">
                ASSETbase
              </coozzy-multiselect>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <coozzy-button
                class="float-right"
                design="green"
                @click="saveRegion">
                {{ $t('message.generic.form.save') }}
              </coozzy-button>
            </div>
          </div>
        </coozzy-card>
      </div>
    </div>
  </section>
</template>

<script>
import UserApi from '../../misc/apis/UserApi'
import Vue from 'vue'
import CoozzyMultiselect from '../../framework/components/multiselect/CoozzyMultiselect'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import { routeChecks } from '@/mixins/routeChecks'

export default {
  name: 'EditRegionSettings',
  components: { CoozzyButton, CoozzyCard, CoozzySpinner, CoozzyMultiselect },
  mixins: { routeChecks },
  data() {
    return {
      managementStates: [],
      marketingStates: [],
      assetStates: [],
      accountingStates: [],
      account: null,
      loading: true,
      cantons: ['AR', 'AG', 'AI', 'BE', 'BL', 'BS', 'OW', 'FR', 'GE', 'GL', 'GR', 'JU', 'LU', 'NE', 'NW', 'SG', 'SH', 'SO', 'SZ', 'TG', 'TI', 'UR', 'VD', 'VS', 'ZG', 'ZH']
    }
  },
  computed: {
    translatedManagementCantons() {
      const array = []
      if (!this.managementCanton.find(x => x.value === 'all')) {
        for (const canton of this.cantons) {
          array.push({
            label: canton,
            value: canton
          })
        }
      }
      array.unshift({
        label: this.$t('message.networkPartner.allCantons'),
        value: 'all'
      })
      return array
    },
    translatedMarketingCantons() {
      const array = []
      if (!this.marketingCanton.find(x => x.value === 'all')) {
        for (const canton of this.cantons) {
          array.push({
            label: canton,
            value: canton
          })
        }
      }
      array.unshift({
        label: this.$t('message.networkPartner.allCantons'),
        value: 'all'
      })
      return array
    },
    translatedAccountingCantons() {
      const array = []
      if (!this.accountingCanton.find(x => x.value === 'all')) {
        for (const canton of this.cantons) {
          array.push({
            label: canton,
            value: canton
          })
        }
      }
      array.unshift({
        label: this.$t('message.networkPartner.allCantons'),
        value: 'all'
      })
      return array
    },
    translatedAssetCantons() {
      const array = []
      if (!this.assetCanton.find(x => x.value === 'all')) {
        for (const canton of this.cantons) {
          array.push({
            label: canton,
            value: canton
          })
        }
      }
      array.unshift({
        label: this.$t('message.networkPartner.allCantons'),
        value: 'all'
      })
      return array
    },
    accountingCanton: {
      get() {
        const array = []
        if (this.areEqual(this.cantons, this.accountingStates)) {
          array.unshift({
            label: this.$t('message.networkPartner.allCantons'),
            value: 'all'
          })
        } else {
          for (const canton of this.accountingStates) {
            array.push({
              label: canton,
              value: canton
            })
          }
        }
        return array
      },
      set(value) {
        this.accountingStates = []
        for (const canton of value) {
          if (canton.value === 'all') {
            this.accountingStates = this.cantons
            return
          } else {
            this.accountingStates.push(canton.value)
          }
        }
      }
    },
    marketingCanton: {
      get() {
        const array = []
        if (this.areEqual(this.cantons, this.marketingStates)) {
          array.unshift({
            label: this.$t('message.networkPartner.allCantons'),
            value: 'all'
          })
        } else {
          for (const canton of this.marketingStates) {
            array.push({
              label: canton,
              value: canton
            })
          }
        }
        return array
      },
      set(value) {
        this.marketingStates = []
        for (const canton of value) {
          if (canton.value === 'all') {
            this.marketingStates = this.cantons
            return
          } else {
            this.marketingStates.push(canton.value)
          }
        }
      }
    },
    managementCanton: {
      get() {
        const array = []
        if (this.areEqual(this.cantons, this.managementStates)) {
          array.unshift({
            label: this.$t('message.networkPartner.allCantons'),
            value: 'all'
          })
        } else {
          for (const canton of this.managementStates) {
            array.push({
              label: canton,
              value: canton
            })
          }
        }
        return array
      },
      set(value) {
        this.managementStates = []
        for (const canton of value) {
          if (canton.value === 'all') {
            this.managementStates = this.cantons
            return
          } else {
            this.managementStates.push(canton.value)
          }
        }
      }
    },
    assetCanton: {
      get() {
        const array = []
        if (this.areEqual(this.cantons, this.assetStates)) {
          array.unshift({
            label: this.$t('message.networkPartner.allCantons'),
            value: 'all'
          })
        } else {
          for (const canton of this.assetStates) {
            array.push({
              label: canton,
              value: canton
            })
          }
        }
        return array
      },
      set(value) {
        this.assetStates = []
        for (const canton of value) {
          if (canton.value === 'all') {
            this.assetStates = this.cantons
            return
          } else {
            this.assetStates.push(canton.value)
          }
        }
      }
    }
  },
  mounted: function () {
    UserApi.getAccount(this.$store.getters['user/getAccountId'])
      .then(response => {
        this.loading = false
        this.account = response.account
        this.marketingStates = response.account.marketingStates.map(x => x.substr(3))
        this.managementStates = response.account.managementStates.map(x => x.substr(3))
        this.accountingStates = response.account.accountingStates.map(x => x.substr(3))
        this.assetStates = response.account.assetStates.map(x => x.substr(3))
      })
      .catch(e => {
        console.log(e)
        this.loading = false
        Vue.toasted.show(this.$t('message.loadingErrors.user'), { type: 'error' })
      })
  },
  methods: {
    areEqual(array1, array2) {
      if (array1.length === array2.length) {
        return array1.every((element, index) => {
          return element === array2[index]
        })
      }
      return false
    },
    saveRegion() {
      this.loading = true
      const managementStates = this.managementStates.map(x => 'CH_' + x)
      const marketingStates = this.marketingStates.map(x => 'CH_' + x)
      const assetStates = this.assetStates.map(x => 'CH_' + x)
      const accountingStates = this.accountingStates.map(x => 'CH_' + x)

      UserApi.updateStates(this.$store.getters['user/getAccountId'], managementStates, marketingStates, accountingStates, assetStates)
        .then(() => {
          this.loading = false
          Vue.toasted.show(this.$t('message.successMessages.regionSetting'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.savingErrors.user'), { type: 'error' })
        })
    }
  }
}
</script>
