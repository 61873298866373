const colors = [
  '#2196f3',
  '#ffc107',
  '#4caf50',
  '#673ab7',
  '#ff9800',
  '#009688',
  '#3f51b5',
  '#ff5722',
  '#00bcd4',
  '#cddc39',
  '#9c27b0',
  '#ffeb3b',
  '#9e9e9e',
  '#03a9f4',
  '#8bc34a',
  '#607d8b',
  '#e91e63',
  '#cddc39',
  '#f44336'
]

const blueColors = [
  '#004561',
  '#004561cc',
  '#00456199',
  '#00456166',
  '#00456133',
  '#1976d2',
  '#1565c0',
  '#0d47a1',
  '#81d4fa',
  '#4fc3f7',
  '#29b6f6',
  '#03a9f4',
  '#039be5',
  '#0288d1',
  '#0277bd',
  '#01579b'
]

const greenColors = [
  '#EE7203',
  '#ed7002cc',
  '#ed700299',
  '#ed700266',
  '#ed700233',
  '#afb42b',
  '#9e9d24',
  '#827717',
  '#c5e1a5',
  '#aed581',
  '#9ccc65',
  '#8bc34a',
  '#7cb342',
  '#689f38',
  '#558b2f',
  '#33691e'
]

const greyColors = [
  '#b0bec5',
  '#90a4ae',
  '#78909c',
  '#607d8b',
  '#546e7a',
  '#455a64',
  '#37474f',
  '#263238',
  '#eeeeee',
  '#e0e0e0',
  '#bdbdbd',
  '#9e9e9e',
  '#757575',
  '#616161',
  '#424242',
  '#212121'
]

const getRandomColor = function () {
  return '#' + (Math.random() * 0xFFFFFF << 0).toString(16)
}

const chartColors = {
  methods: {
    getChartColors(amount) {
      return colors.slice(0, amount)
    },
    getBlueColor(number) {
      return blueColors[number]
    },
    getBlueColors(amount) {
      const colors = blueColors
      if (blueColors.length < amount) {
        for (let i = 0; (amount - blueColors.length) > 0; i++) {
          colors.push(getRandomColor())
        }
      }

      return colors.slice(0, amount)
    },
    getGreenColor(number) {
      return greenColors[number]
    },
    getGreenColors(amount) {
      const colors = greenColors
      if (greenColors.length < amount) {
        for (let i = 0; (amount - greenColors.length) > 0; i++) {
          colors.push(getRandomColor())
        }
      }

      return colors.slice(0, amount)
    },
    getGreyColors(amount) {
      const colors = greyColors
      if (greyColors.length < amount) {
        for (let i = 0; (amount - greyColors.length) > 0; i++) {
          colors.push(getRandomColor())
        }
      }

      return colors.slice(0, amount)
    },
    getAmber() {
      return '#ffc107'
    },
    getBlue() {
      return '#2196f3'
    },
    getGreen() {
      return '#4caf50'
    },
    getGrey() {
      return '#9e9e9e'
    },
    getLime() {
      return '#cddc39'
    }
  }
}

export { chartColors }
