<template>
  <form
    v-bind="$attrs"
    @submit.prevent
    v-on="mountEventListeners">
    <slot />
  </form>
</template>

<script>
export default {
  name: 'CoozzyForm',
  inheritAttrs: false,
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
