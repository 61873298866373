<template>
  <coozzy-card>
    <div class="row">
      <div class="col-12">
        <h5 class="mb-3">
          {{ $t('message.onBoarding.buildings.objects.deposit.amount') }}: {{ $options.filters.formatPrice(object.deposit.amount, 'CHF') }}
        </h5>
      </div>
      <div class="col-12">
        <p>
          <label>{{ $t('message.onBoarding.buildings.objects.deposit.accountOffers', { accountName: marketingAccount?.company ? marketingAccount.company.name : marketingAccount?.individual ? marketingAccount.individual.name : '' }) }}</label>
        </p>
        {{ $t('message.onBoarding.buildings.objects.deposit.pleaseSelectOption') }}
      </div>
      <div
        v-if="!object"
        class="col-12 text-center">
        <coozzy-spinner />
      </div>
      <template v-else>
        <div class="col">
          <!-- <div
            class="row details-row-bg-2 mb-2 mt-3">
            <div
              class="col-auto d-flex justify-content-center align-items-center">
              <coozzy-form-radio
                v-model="type"
                name="type"
                :default-model="type"
                :select-value="'PREFERRED_DEPOSIT_TYPE_UNDEFINED'">
                {{ $t('message.contact.types.NONE') }}
              </coozzy-form-radio>
            </div>
          </div> -->
          <div
            v-if="object.deposit.cashPaymentAccepted"
            class="row details-row-bg-2 mb-2">
            <div
              class="col-auto d-flex justify-content-center align-items-center">
              <coozzy-form-radio
                v-model="type"
                name="type"
                :default-model="type"
                :select-value="'PREFERRED_DEPOSIT_TYPE_CASH_PAYMENT'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_CASH_PAYMENT') }}
              </coozzy-form-radio>
            </div>
          </div>
          <div
            v-if="object.deposit.insuranceAccepted"
            class="row details-row-bg-1 mb-2">
            <div
              class="col-auto d-flex justify-content-center align-items-center">
              <coozzy-form-radio
                v-model="type"
                name="type"
                :default-model="type"
                :select-value="'PREFERRED_DEPOSIT_TYPE_INSURANCE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_INSURANCE_GUARANTEE') }}
              </coozzy-form-radio>
            </div>
            <template
              v-if="object.deposit.insuranceNames.length > 0">
              <div
                class="col-12 pl-5">
                <template
                  v-for="(item, index) in object.deposit.insuranceNames">
                  <coozzy-form-radio
                    :key="index"
                    v-model="insuranceNames"
                    :default-model="insuranceNames"
                    name="banknames"
                    :select-value="item">
                    {{ $t('message.onBoarding.buildings.objects.deposit.depositInsurance.' + item) }}
                  </coozzy-form-radio>
                </template>
              </div>
            </template>
            <!-- <template v-if="object.deposit.insuranceAccepted && type === 'PREFERRED_DEPOSIT_TYPE_INSURANCE'">
              <template v-if="object.deposit.insuranceNames?.find(x => x === 'FIRST_CAUTION')">
                <div class="col">
                  <coozzy-form-input-numeric
                    v-model="premiumThisYear"
                    type="number"
                    :filter="'formatPrice'"
                    :is-decimal="true"
                    :name="$t('message.applicationForm.premiumForThisYear')" />
                </div>
                <div class="col">
                  <coozzy-form-input-numeric
                    v-model="premiumPerYear"
                    type="number"
                    :filter="'formatPrice'"
                    :is-decimal="true"
                    :name="$t('message.applicationForm.premiumPerYear')" />
                </div>
              </template>
            </template> -->
          </div>
          <div
            v-if="object.deposit.bankDepotAccepted"
            class="row details-row-bg-2 mb-2">
            <div
              class="col-auto d-flex justify-content-center align-items-center">
              <coozzy-form-radio
                v-model="type"
                name="type"
                :default-model="type"
                :select-value="'PREFERRED_DEPOSIT_TYPE_DEPOT'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_DEPOT') }}
              </coozzy-form-radio>
            </div>
            <template
              v-if="object.deposit.bankDepotNames.length > 0">
              <div
                class="col-12 pl-5">
                <template
                  v-for="(item, index) in object.deposit.bankDepotNames.filter((v, i, a) => a.findIndex(t => (t.iid === v.iid)) === i)">
                  <coozzy-form-radio
                    :key="index"
                    v-model="bankDepotNames"
                    :default-model="bankDepotNames"
                    name="banknames"
                    :select-value="item">
                    {{ item }}
                  </coozzy-form-radio>
                </template>
              </div>
            </template>
          </div>
          <div
            v-if="object.deposit.cooperativeShareAccepted"
            class="row details-row-bg-1 mb-2">
            <div
              class="col-auto d-flex justify-content-center align-items-center">
              <coozzy-form-radio
                v-model="type"
                name="type"
                :default-model="type"
                :select-value="'PREFERRED_DEPOSIT_TYPE_COOPERATIVE_SHARE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_COOPERATIVE_SHARE') }}
              </coozzy-form-radio>
            </div>
          </div>
          <div
            v-if="object.deposit.bankGuaranteeAccepted"
            class="row details-row-bg-2 mb-2">
            <div
              class="col-auto d-flex justify-content-center align-items-center">
              <coozzy-form-radio
                v-model="type"
                name="type"
                :default-model="type"
                :select-value="'PREFERRED_DEPOSIT_TYPE_BANK_GUARANTEE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_BANK_GUARANTEE') }}
              </coozzy-form-radio>
            </div>
            <template
              v-if="object.deposit.bankGuaranteeNames.length > 0">
              <div
                class="col-12 pl-5">
                <template
                  v-for="(item, index) in object.deposit.bankGuaranteeNames.filter((v, i, a) => a.findIndex(t => (t.iid === v.iid)) === i)">
                  <coozzy-form-radio
                    :key="index"
                    v-model="bankGuaranteeNames"
                    name="banknames"
                    :default-model="bankGuaranteeNames"
                    :select-value="item">
                    {{ item }}
                  </coozzy-form-radio>
                </template>
              </div>
            </template>
          </div>
          <!-- <div
            v-if="object.deposit.collectiveInsuranceAccepted"
            class="row details-row-bg-2 mb-2">
            <div
              class="col-auto d-flex justify-content-center align-items-center">
              <coozzy-form-radio
                v-model="type"
                name="type"
                :default-model="type"
                :select-value="'PREFERRED_DEPOSIT_TYPE_COLLECTIVE_INSURANCE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_COLLECTIVE_INSURANCE') }}
              </coozzy-form-radio>
            </div>
            <template
              v-if="object.deposit.collectiveInsuranceNames.length > 0">
              <div
                class="col-12 pl-5">
                <template
                  v-for="(item, index) in object.deposit.collectiveInsuranceNames">
                  <coozzy-form-radio
                    :key="index"
                    v-model="collectiveInsuranceNames"
                    name="banknames"
                    :default-model="collectiveInsuranceNames"
                    :select-value="item">
                    {{ item }}
                  </coozzy-form-radio>
                </template>
              </div>
            </template>
          </div> -->
        </div>
      </template>
    </div>
  </coozzy-card>
</template>

<script>
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { mapMutations } from 'vuex'

export default {
  name: 'ApplicationFormPreferredDeposit',
  components: {
    CoozzySpinner,
    CoozzyCard
  },
  props: {
    object: {
      type: Object,
      default: null
    },
    individual: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    marketingAccount: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
    }
  },
  computed: {
    type: {
      get() {
        if (this.company) {
          return this.company?.preferredDeposit.type === 'PREFERRED_DEPOSIT_TYPE_UNDEFINED' ? null : this.company?.preferredDeposit.type
        } else {
          return this.individual?.preferredDeposit.type === 'PREFERRED_DEPOSIT_TYPE_UNDEFINED' ? null : this.individual?.preferredDeposit.type
        }
      },
      set(value) {
        if (this.company) {
          const ind = this.company
          ind.preferredDeposit.type = value
          this.updateCompany(ind)
        } else {
          const ind = this.individual
          ind.preferredDeposit.type = value
          this.updateIndividual(ind)
        }
      }
    },
    insuranceNames: {
      get() {
        return this.individual?.preferredDeposit.insuranceNames.toString() || this.company?.preferredDeposit.insuranceNames.toString()
      },
      set(value) {
        if (this.company) {
          const ind = this.company
          if (value) {
            ind.preferredDeposit.insuranceNames = [value]
          } else {
            ind.preferredDeposit.insuranceNames = []
          }
          this.updateCompany(ind)
        } else {
          const ind = this.individual
          if (value) {
            ind.preferredDeposit.insuranceNames = [value]
          } else {
            ind.preferredDeposit.insuranceNames = []
          }
          this.updateIndividual(ind)
        }
      }
    },
    bankDepotNames: {
      get() {
        return this.individual?.preferredDeposit?.bankDepotNames?.toString() || this.company?.preferredDeposit?.bankDepotNames?.toString()
      },
      set(value) {
        if (this.company) {
          const ind = this.company
          if (value) {
            ind.preferredDeposit.bankDepotNames = [value]
            if (this.object.deposit.bankDepotNames.findIndex(x => x === value) !== -1) {
              ind.preferredDeposit.bankDepotIds = [this.object.deposit.bankDepotIds[this.object.deposit.bankDepotNames.findIndex(x => x === value)]]
            }
          } else {
            ind.preferredDeposit.bankDepotNames = []
            ind.preferredDeposit.bankDepotIds = []
          }
          this.updateCompany(ind)
        } else {
          const ind = this.individual
          if (value) {
            ind.preferredDeposit.bankDepotNames = [value]
            if (this.object.deposit.bankDepotNames.findIndex(x => x === value) !== -1) {
              ind.preferredDeposit.bankDepotIds = [this.object.deposit.bankDepotIds[this.object.deposit.bankDepotNames.findIndex(x => x === value)]]
            }
          } else {
            ind.preferredDeposit.bankDepotNames = []
            ind.preferredDeposit.bankDepotIds = []
          }
          this.updateIndividual(ind)
        }
      }
    },
    bankGuaranteeNames: {
      get() {
        return this.individual?.preferredDeposit.bankGuaranteeNames.toString() || this.company?.preferredDeposit.bankGuaranteeNames.toString()
      },
      set(value) {
        if (this.company) {
          const ind = this.company
          ind.preferredDeposit.bankGuaranteeNames = [value]
          if (this.object.deposit.bankGuaranteeNames.findIndex(x => x === value) !== -1) {
            ind.preferredDeposit.bankGuaranteeIds = [this.object.deposit.bankGuaranteeIds[this.object.deposit.bankGuaranteeNames.findIndex(x => x === value)]]
          }
          this.updateCompany(ind)
        } else {
          const ind = this.individual
          if (value) {
            ind.preferredDeposit.bankGuaranteeNames = [value]
            if (this.object.deposit.bankGuaranteeNames.findIndex(x => x === value) !== -1) {
              ind.preferredDeposit.bankGuaranteeIds = [this.object.deposit.bankGuaranteeIds[this.object.deposit.bankGuaranteeNames.findIndex(x => x === value)]]
            }
          } else {
            ind.preferredDeposit.bankGuaranteeNames = []
            ind.preferredDeposit.bankGuaranteeIds = []
          }
          this.updateIndividual(ind)
        }
      }
    },
    collectiveInsuranceNames: {
      get() {
        return this.individual?.preferredDeposit.collectiveInsuranceNames.toString() || this.company?.preferredDeposit.collectiveInsuranceNames.toString()
      },
      set(value) {
        if (this.company) {
          const ind = this.company
          if (value) {
            ind.preferredDeposit.collectiveInsuranceNames = [value]
          } else {
            ind.preferredDeposit.collectiveInsuranceNames = []
          }
          this.updateCompany(ind)
        } else {
          const ind = this.individual
          if (value) {
            ind.preferredDeposit.collectiveInsuranceNames = [value]
          } else {
            ind.preferredDeposit.collectiveInsuranceNames = []
          }
          this.updateIndividual(ind)
        }
      }
    },
    premiumPerYear: {
      get() {
        if (this.individual) {
          return this.individual?.preferredDeposit.premiumPerYear !== -1 ? this.individual?.preferredDeposit.premiumPerYear : null
        } else {
          return this.company?.preferredDeposit.premiumPerYear !== -1 ? this.company?.preferredDeposit.premiumPerYear : null
        }
      },
      set(value) {
        if (this.company) {
          const ind = this.company
          ind.preferredDeposit.premiumPerYear = value
          this.updateCompany(ind)
        } else {
          const ind = this.individual
          ind.preferredDeposit.premiumPerYear = value
          this.updateIndividual(ind)
        }
      }
    },
    premiumThisYear: {
      get() {
        if (this.individual) {
          return this.individual?.preferredDeposit.premiumThisYear !== -1 ? this.individual?.preferredDeposit.premiumThisYear : null
        } else {
          return this.company?.preferredDeposit.premiumThisYear !== -1 ? this.company?.preferredDeposit.premiumThisYear : null
        }
      },
      set(value) {
        if (this.company) {
          const ind = this.company
          ind.preferredDeposit.premiumThisYear = value
          this.updateCompany(ind)
        } else {
          const ind = this.individual
          ind.preferredDeposit.premiumThisYear = value
          this.updateIndividual(ind)
        }
      }
    }
  },
  watch: {
    type: function (newVal) {
      if (this.company) {
        this.company.preferredDeposit.collectiveInsuranceNames = []
        this.company.preferredDeposit.bankDepotNames = []
        this.company.preferredDeposit.bankGuaranteeNames = []
        this.company.preferredDeposit.insuranceNames = []
      } else {
        this.individual.preferredDeposit.collectiveInsuranceNames = []
        this.individual.preferredDeposit.bankDepotNames = []
        this.individual.preferredDeposit.bankGuaranteeNames = []
        this.individual.preferredDeposit.insuranceNames = []
      }
      if (newVal !== 'PREFERRED_DEPOSIT_TYPE_INSURANCE') {
        this.premiumPerYear = -1
        this.premiumThisYear = -1
      }
    },
    collectiveInsuranceNames: function (newVal) {
      this.type = 'PREFERRED_DEPOSIT_TYPE_COLLECTIVE_INSURANCE'
    },
    bankGuaranteeNames: function (newVal) {
      this.type = 'PREFERRED_DEPOSIT_TYPE_BANK_GUARANTEE'
    },
    bankDepotNames: function (newVal) {
      this.type = 'PREFERRED_DEPOSIT_TYPE_DEPOT'
    },
    insuranceNames: function (newVal) {
      this.type = 'PREFERRED_DEPOSIT_TYPE_INSURANCE'
    }
  },
  mounted() {
  },
  methods: {
    ...mapMutations('applicationForm', ['updateIndividual', 'updateCompany']),
    getInsuranceNames(insuranceNames) {
      let text = ''
      for (let index = 0; index < insuranceNames.length; index++) {
        const element = insuranceNames[index]
        if (index > 0) {
          text += ', '
        }
        text += this.$t('message.onBoarding.buildings.objects.deposit.depositInsurance.' + element)
      }
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.custom-checkbox > label) {
  font-weight: normal;
}
</style>
