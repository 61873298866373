<template>
  <section>
    <div>
      <!-- Heading -->
      <div class="row justify-content-between align-items-center">
        <div
          v-if="item"
          class="col-auto">
          <title-marketing-object-view
            :show-marketing-info-box="false"
            :item="item" />
        </div>
        <div
          v-else
          class="col-auto">
          <h1>
            {{ $t('message.managementOverview.objects') }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 marketing-edit-advert">
          <form-wizard
            id="wizard"
            ref="wizard"
            step-size="xs"
            color="rgb(0, 68, 95)"
            @on-complete="publishData">
            <span slot="title" />
            <tab-content
              :title="$t('message.generic.basics')"
              :before-change="validateTabOne">
              <div
                v-if="loading"
                class="col-12 text-center">
                <coozzy-spinner class="m-3" />
              </div>
              <div v-else>
                <coozzy-card
                  class="global-card mb-3">
                  <h5 class="cursor-pointer">
                    {{ $t('message.manageBuilding.building') }}
                  </h5>
                  <object-basic
                    v-if="item"
                    ref="object-basics"
                    :key="`basic-${item.id}`"
                    :object-selected="item"
                    :expand-by-default="true"
                    :edit-advert-call="true"
                    @edit-building="editBuildingFields" />
                </coozzy-card>
                <coozzy-card
                  class="global-card mb-3 readonly-wrapper">
                  <object-pricing
                    v-if="item"
                    ref="object-pricing"
                    :key="`pricing-${item.id}`"
                    :object-selected="item"
                    :expand-by-default="false"
                    :read-only="true" />
                </coozzy-card>
                <coozzy-card
                  class="global-card mb-3 readonly-wrapper">
                  <div
                    id="Tenant"
                    v-b-toggle.coozzy-card-tenancy
                    class="collapse-title">
                    <h5 class="cursor-pointer">
                      {{ $t('message.onBoarding.buildings.objects.tenancies.title') }} ({{ currentTenancies.length }})
                    </h5>
                  </div>
                  <div class="col-12 p-0">
                    <b-collapse
                      id="coozzy-card-tenancy"
                      :visible="false">
                      <div
                        v-if="currentTenancies.length === 0"
                        class="row mt-3">
                        <div class="col-12">
                          <h6>
                            {{ $t('message.nonExistent.tenancies') }}
                          </h6>
                        </div>
                      </div>
                      <template
                        v-for="lastTenancy of currentTenancies"
                        v-else>
                        <object-tenancy
                          v-if="!lastTenancy.vacancy"
                          :key="'ten-'+lastTenancy.id"
                          ref="object-tenancy"
                          :tenancy="lastTenancy"
                          :object="item"
                          :read-only="true"
                          :expand-by-default="false"
                          :hide-actions="true" />
                        <object-vacancy
                          v-else
                          :key="'ten-'+lastTenancy.id"
                          ref="object-vacancy"
                          :tenancy="lastTenancy"
                          :object="item"
                          :read-only="true"
                          :expand-by-default="false" />
                      </template>
                    </b-collapse>
                  </div>
                </coozzy-card>
                <coozzy-card class="global-card mb-3">
                  <object-advertising
                    v-if="item"
                    ref="object-advertising"
                    :key="`advertising-${item.id}`"
                    :object-selected="item"
                    :property="property"
                    :expand-by-default="true"
                    @update-object-advertising="updateObjectAdvertising" />
                </coozzy-card>
                <coozzy-card class="global-card mb-3">
                  <object-possible-deposits
                    v-if="item"
                    ref="object-possible-deposits"
                    :key="`possible-deposits-${item.id}`"
                    :item="item"
                    :expand-by-default="true" />
                </coozzy-card>
                <coozzy-card class="global-card mb-3">
                  <template v-if="item">
                    <h5 class="cursor-pointer">
                      {{ $t('message.export.creditTrust.title') }}
                    </h5>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="$t('message.export.creditTrust.description')" />
                    <coozzy-form-checkbox
                      id="acceptsCreditTrust"
                      :key="'acceptsCreditTrust'"
                      v-model="acceptsCreditTrust"
                      :initial="acceptsCreditTrust"
                      class="align-self-center mt-3">
                      {{ $t('message.export.creditTrust.acceptCertificate') }}
                    </coozzy-form-checkbox>
                  </template>
                </coozzy-card>
                <coozzy-card class="global-card mb-3">
                  <building-distances
                    v-if="building"
                    ref="building-distances"
                    :building="building"
                    :expand-by-default="true"
                    @value-updated="buildingDistanceUpdated" />
                </coozzy-card>
              </div>
            </tab-content>
            <tab-content
              :title="$t('message.onBoarding.createBuilding.features.adminBase')"
              :before-change="validateTabTwo">
              <div
                v-if="loading"
                class="col-12 text-center">
                <coozzy-spinner class="m-3" />
              </div>
              <div v-else>
                <coozzy-card
                  class="global-card mb-3">
                  <characteristics
                    v-if="building"
                    ref="characteristics"
                    :building="building"
                    :title="$t('message.onBoarding.createBuilding.features.marketBase')"
                    :expand-by-default="true"
                    @value-updated="buildingCharacteristicsUpdated" />
                </coozzy-card>
                <coozzy-card
                  class="global-card mb-3">
                  <object-features
                    v-if="item"
                    ref="object-features"
                    :key="`features-${item.id}`"
                    :title="$t('message.onBoarding.buildings.objects.features.title.marketBase')"
                    :object-selected="item"
                    :expand-by-default="true" />
                </coozzy-card>
              </div>
            </tab-content>
            <tab-content
              :title="$t('message.marketingObjects.editAdvert.tabs.communication')"
              :before-change="validateTabThree">
              <div
                v-if="loading"
                class="col-12 text-center">
                <coozzy-spinner class="m-3" />
              </div>
              <marketing-edit-advert-communication-tab
                v-else
                ref="editAdvertCommunicationTab"
                :object="item"
                @default-answer-updated="defaultAnswerUpdated"
                @viewing-appointment-updated="viewingAppointmentUpdated"
                @viewing-contact-updated="viewingContactUpdated"
                @assignee-marketing-updated="assigneeMarketingUpdated"
                @empty-default-answer="emptyDefaultAnswer" />
            </tab-content>
            <tab-content
              :title="$t('message.marketingObjects.editAdvert.tabs.export')"
              :before-change="validateTabFour">
              <div
                v-if="loading"
                class="col-12 text-center">
                <coozzy-spinner class="m-3" />
              </div>
              <coozzy-card
                v-if="!loading"
                class="global-card mb-3">
                <h5 class="cursor-pointer">
                  {{ $t('message.export.title') }}
                </h5>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="$t('message.export.advertPlatformInfo')" />
                <export-platforms
                  v-if="item"
                  ref="export-platforms"
                  :show-save-button="false"
                  :advert-ids="[item.id]"
                  :source="'editAdvert'"
                  @platforms-saved="publishData"
                  @platforms-changed="platformsChanged" />
                <div class="row">
                  <div class="col-2">
                    <label class="mb-0 mt-3">{{ $t('message.advertisement.onlineUntil') }}</label>
                    <coozzy-form-select
                      id="onlineUntil"
                      v-model="onlineUntil">
                      <option value="">
                        -
                      </option>
                      <option value="date">
                        {{ $t('message.tableColumnHeadings.date') }}
                      </option>
                      <option value="amount">
                        {{ $t('message.advertisement.amountOfRequests') }}
                      </option>
                    </coozzy-form-select>
                  </div>
                  <div
                    v-if="onlineUntil === 'date'"
                    class="col-2 mt-3">
                    <coozzy-form-input
                      id="onlineUntil"
                      ref="onlineUntil"
                      v-model="unPublishAt"
                      type="date"
                      :state="$v.unPublishAt && $v.unPublishAt.$dirty && $v.unPublishAt.$error ? false : null"
                      :name="$t('message.tableColumnHeadings.date')" />
                  </div>
                  <div
                    v-if="onlineUntil === 'date'"
                    class="col-2">
                    <label
                      for="time"
                      class="mb-0 mt-3">
                      {{ $t('message.advertisement.time') }}
                    </label>
                    <coozzy-form-select
                      id="timeUntil"
                      v-model="time.end">
                      <option value="09:30">
                        09:30
                      </option>
                      <option value="12:30">
                        12:30
                      </option>
                      <option value="17:30">
                        17:30
                      </option>
                    </coozzy-form-select>
                  </div>
                  <div
                    v-if="onlineUntil === 'amount'"
                    class="col-2">
                    <label
                      for="time"
                      class="mb-0 mt-3"
                      style="opacity: 0;">
                      date
                    </label>
                    <coozzy-form-input-numeric
                      v-model="unPublishAtAmountOfRequests"
                      type="number"
                      :is-integer="true"
                      :is-integer-format="true"
                      min="0"
                      :check-valide="$v.unPublishAtAmountOfRequests.$dirty && $v.unPublishAtAmountOfRequests.$error ? 'is-invalid' : ''" />
                  </div>
                </div>
                <label
                  for="language"
                  class="mb-0 mt-3">
                  {{ $t('message.advertisement.labelLanguage') }}
                </label>
                <coozzy-form-select
                  v-if="item"
                  id="language"
                  v-model="marketingLanguage"
                  class="w-50">
                  <option value="PREFERRED_LANGUAGE_GERMAN">
                    {{ $t('message.language.german') }}
                  </option>
                  <option value="PREFERRED_LANGUAGE_ENGLISH">
                    {{ $t('message.language.english') }}
                  </option>
                  <option value="PREFERRED_LANGUAGE_ITALIAN">
                    {{ $t('message.language.italian') }}
                  </option>
                </coozzy-form-select>
              </coozzy-card>
            </tab-content>
            <template
              slot="footer"
              slot-scope="props">
              <div
                v-if="!loading"
                class="wizard-footer-left">
                <wizard-button
                  v-if="props.activeTabIndex === 0"
                  :style="props.fillButtonStyle"
                  @click.native="clickBack()">
                  <!-- clickBack() -->
                  {{ $t('message.marketingObjects.editAdvert.cancel') }}
                </wizard-button>
                <wizard-button
                  v-else
                  :style="props.fillButtonStyle"
                  @click.native="backClicked(props)">
                  {{ $t('message.marketingObjects.editAdvert.back') }}
                </wizard-button>
              </div>
              <div
                v-if="!loading"
                class="wizard-footer-right">
                <wizard-button
                  v-if="!props.isLastStep"
                  id="publish-btn"
                  class="wizard-footer-right"
                  :style="!props.isLastStep || canBePublished ? props.fillButtonStyle : 'color:white;border-color: grey !important;background: grey !important;cursor: default;'"
                  @click.native="nextClicked(props, canBePublished)">
                  {{ $t('message.marketingObjects.editAdvert.next') }}
                </wizard-button>
                <wizard-button
                  v-if="props.isLastStep"
                  id="publish-btn"
                  class="wizard-footer-right mr-2"
                  :class="isRented ? 'cursor-normal' : ''"
                  :style="props.isLastStep && !canBePublished ? 'color:white;border-color: grey !important;background: grey !important;cursor: default;' : props.fillButtonStyle"
                  :disabled="isRented"
                  @click.native="canBePublished ? save(props.activeTabIndex, true) : null">
                  {{ $t('message.marketingObjects.editAdvert.publish') }}
                </wizard-button>
                <wizard-button
                  id="save-btn"
                  class="wizard-footer-right mr-2"
                  :style="props.fillButtonStyle"
                  @click.native="save(props.activeTabIndex, false)">
                  {{ $t('message.marketingObjects.editAdvert.saveDraft') }}
                </wizard-button>
                <wizard-button
                  v-if="props.isLastStep"
                  id="expose-btn"
                  class="wizard-footer-right mr-2"
                  :style="props.fillButtonStyle"
                  @click.native="$bvModal.show('openExposeModal')">
                  {{ $t('message.advertisement.action.saveAndOpenExpose') }}
                </wizard-button>
                <b-tooltip
                  v-if="!canBePublished && props.isLastStep"
                  target="publish-btn"
                  triggers="hover">
                  {{ $t('message.marketingObjects.impossibleToPublish') }}
                </b-tooltip>
              </div>
            </template>
          </form-wizard>
        </div>
      </div>
    </div>
    <b-modal
      ref="modal-confirmation"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')">
      <div class="row mb-2">
        <div class="col">
          <p class="mb-3">
            {{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}
          </p>
          <coozzy-button
            size="normal"
            design="green"
            @click="discardChanges">
            {{ $t('message.generic.discardChanges') }}
          </coozzy-button>
          <coozzy-button
            size="normal"
            class="float-right mr-3 border"
            design="transparent"
            @click="closeModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      :id="'openExposeModal'"
      ref="openExposeModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.advertisement.action.openExpose')">
      <template>
        <div class="col-12">
          <p>{{ $t('message.advertisement.action.titleModal') }}</p>
        </div>
        <div class="col-12 mt-3 mb-3">
          <coozzy-form-select
            v-model="language">
            <option
              v-for="option in optionsLanguages"
              :key="option.key"
              :value="option.key">
              {{ option.value }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('openExposeModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="openAdvertExpose">
            {{ $t('message.generic.confirm') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import ObjectFeatures from '@/building/tabs/components/ObjectFeatures'
import ObjectBasic from '@/building/tabs/components/ObjectBasic'
import ObjectPricing from '@/building/tabs/components/ObjectPricing'
import ObjectTenancy from '@/building/tabs/components/ObjectTenancy'
import ObjectVacancy from '@/building/tabs/components/ObjectVacancy'
import ObjectAdvertising from '@/building/tabs/components/ObjectAdvertising'
import ObjectPossibleDeposits from '@/building/tabs/components/ObjectPossibleDeposits'
import Characteristics from '@/building/tabs/components/Characteristics'
import BuildingDistances from '@/building/tabs/components/BuildingDistances'
import TitleMarketingObjectView from '@/marketing/TitleMarketingObjectView'
import { user } from '@/mixins/user'
import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import ObjectApi from '../misc/apis/ObjectApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import CoozzyCard from '../framework/components/card/CoozzyCard'
import ExportPlatforms from '@/properties/components/ExportPlatforms'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { algolia } from '@/mixins/algolia'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import MarketingEditAdvertCommunicationTab from '@/marketing/MarketingEditAdvertCommunicationTab'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric.vue'

function moreThanOverall(value, vm) {
  return value > vm.overall
}
function moreThanToday(value, vm) {
  return new Date(value + ' ' + vm.time.end + ':00') >= new Date()
}

export default {
  name: 'MarketingEditAdvertView',
  components: {
    CoozzyFormCheckbox,
    MarketingEditAdvertCommunicationTab,
    TitleMarketingObjectView,
    CoozzyButton,
    CoozzyFormSelect,
    ExportPlatforms,
    CoozzyCard,
    CoozzySpinner,
    Characteristics,
    ObjectFeatures,
    BuildingDistances,
    ObjectAdvertising,
    ObjectPossibleDeposits,
    ObjectBasic,
    ObjectPricing,
    ObjectTenancy,
    ObjectVacancy,
    CoozzyFormInputNumeric
  },
  mixins: [formatting, user, obj, algolia],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      building: null,
      item: null,
      itemChanged: false,
      currentTenancies: [],
      hasPlatforms: false,
      loading: false,
      language: 'DE',
      defaultAnswerNone: false,
      isRented: false,
      property: null,
      acceptsCreditTrust: null,
      unPublishAt: null,
      unPublishAtAmountOfRequests: null,
      time: {
        end: '17:30'
      },
      onlineUntil: '',
      overall: 0
    }
  },
  computed: {
    onlineUntilValue() {
      return this.onlineUntil
    },
    optionsLanguages() {
      return [
        {
          key: 'EN',
          value: this.$t('message.language.english')
        },
        {
          key: 'DE',
          value: this.$t('message.language.german')
        },
        {
          key: 'IT',
          value: this.$t('message.language.italian')
        }]
    },
    getUserLang() {
      return this.$store.getters['user/getCurrentUser']['https://coozzy.ch/lang'].toUpperCase()
    },
    canBePublished() {
      return !!(this.item && this.item.title !== '' && this.item.description !== '' && this.item.category !== 'UNDEFINED_CATEGORY' &&
        this.item.pricing.currency !== '' && (this.item.pricing.coldRent !== '' || this.item.pricing.unit !== 'ON_REQUEST') && this.hasPlatforms)
    },
    applicationformLink() {
      return `${process.env.VUE_APP_BASE_API_URL}/object/${this.id}`
    },
    contactIdAssigneeMarketing: {
      get() {
        return this.item && this.item.contacts ? this.item.contacts.contactIdAssigneeMarketing : null
      },
      set(value) {
        if (this.item.contacts) {
          this.item.contacts.contactIdAssigneeMarketing = value
        } else {
          this.item.contacts = {
            contactIdAssigneeMarketing: value
          }
        }
      }
    },
    contactIdViewingAppointment: {
      get() {
        return this.item?.contacts?.contactIdViewingAppointment
      },
      set(newVal) {
        if (this.item.contacts) {
          this.item.contacts.contactIdViewingAppointment = newVal
        } else {
          this.item.contacts = {
            contactIdViewingAppointment: newVal
          }
        }
      }
    },
    statusTitle() {
      const status = this.calculateMarketingStatus(this.item.marketingStatus)
      return status.label
    },
    statusColor() {
      const status = this.calculateMarketingStatus(this.item.marketingStatus)
      return status.color
    },
    statusDate() {
      const status = this.calculateMarketingStatus(this.item.marketingStatus)
      return status.date
    },
    contactIdsJanitor: function () {
      return this.item && this.item.contacts ? this.item.contacts.contactIdsJanitor || [] : []
    },
    contactIdsServiceProvider: function () {
      return this.item && this.item.contacts ? this.item.contacts.contactIdsServiceProvider || [] : []
    },
    marketingLanguage: {
      get() {
        let lang = ''
        if (this.currentLanguage === 'de') {
          lang = 'PREFERRED_LANGUAGE_GERMAN'
        } else if (this.currentLanguage === 'en') {
          lang = 'PREFERRED_LANGUAGE_ENGLISH'
        } else if (this.currentLanguage === 'it') {
          lang = 'PREFERRED_LANGUAGE_ITALIAN'
        }
        return this.item.marketingLanguage !== 'PREFERRED_LANGUAGE_UNDEFINED' ? this.item.marketingLanguage : lang
      },
      set(value) {
        const object = this.item
        object.marketingLanguage = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    checkIfObjectOnLine() {
      // check if object is online
      if (this.item?.marketingStatus) {
         const pub = this.item?.marketingStatus.publications
        if (pub && pub.length > 0 && pub[pub.length - 1].unPublished) {
          if (parseInt(pub[pub.length - 1]?.unPublished) * 1000 > new Date().getTime() &&
            parseInt(pub[pub.length - 1]?.published) * 1000 <= new Date().getTime()) {
            // The object is currently published (online)
            return true
          } else {
            // The object is offline or scheduled to be published in the future
           return false
          }
        }
      }
      return false
    }
  },
  watch: {
    item: {
      deep: true,
      handler: function () {
        this.itemChanged = true
      }
    },
    onlineUntil: function (newVal) {
      if (newVal === 'date') {
        this.unPublishAtAmountOfRequests = null
      } else if (newVal === 'amount') {
        this.time.end = '17:30'
        this.unPublishAt = null
      } else {
        this.unPublishAtAmountOfRequests = null
        this.time.end = '17:30'
        this.unPublishAt = null
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.language = this.getUserLang || 'DE'
    })
    this.loadInstantSearchInternal()
    Promise.all([
      ObjectApi.getBuildingByObjectId(this.id),
      ObjectApi.getObject(this.id),
      ObjectApi.getTenancies([this.id]),
      RequestApi.getStatistics([this.id])
    ])
      .then(responses => {
        this.building = responses[0].building
        ObjectApi.getPropertiesByBuildingIds([this.building.id])
          .then(responseProp => {
            this.property = responseProp.properties[0]
          })
        this.item = responses[1].object
        this.acceptsCreditTrust = responses[1].object.acceptsCreditTrust
        const startOfDayTimestamp = this.getStartOfDayTimestamp()
        this.currentTenancies = responses[2].tenancies
          .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
          .filter(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))
        this.overall = responses[3].statistics[0].overall
        this.loading = false
        this.$refs.wizard.activateAll()

        if (this.item.marketingLanguage === 'PREFERRED_LANGUAGE_UNDEFINED') {
          let lang = ''
          if (this.currentLanguage === 'de') {
            lang = 'PREFERRED_LANGUAGE_GERMAN'
          } else if (this.currentLanguage === 'en') {
            lang = 'PREFERRED_LANGUAGE_ENGLISH'
          } else if (this.currentLanguage === 'it') {
            lang = 'PREFERRED_LANGUAGE_ITALIAN'
          }
          this.item.marketingLanguage = lang
        }
        if (this.item?.marketingStatus?.publications.length > 0) {
          if (this.item?.marketingStatus?.publications[this.item.marketingStatus.publications.length - 1]?.unPublishAtAmountOfRequests !== null) {
            this.onlineUntil = 'amount'
            this.unPublishAtAmountOfRequests = this.item?.marketingStatus?.publications[this.item.marketingStatus.publications.length - 1]?.unPublishAtAmountOfRequests
          } else if (this.item?.marketingStatus?.publications[this.item.marketingStatus.publications.length - 1]?.unPublished !== null && this.item?.marketingStatus?.publications[this.item.marketingStatus.publications.length - 1]?.unPublished !== '0') {
            const timeEnd = new Date(this.item?.marketingStatus?.publications[this.item.marketingStatus.publications.length - 1]?.unPublished * 1000)
            const hoursEnd = timeEnd.getHours().toString().padStart(2, '0')
            const minutesEnd = timeEnd.getMinutes().toString().padStart(2, '0')
            this.time.end = hoursEnd + ':' + minutesEnd
            this.onlineUntil = 'date'
            this.unPublishAt = this.$options.filters.timestampToDateInput(this.item?.marketingStatus?.publications?.[this.item.marketingStatus.publications.length - 1]?.unPublished)
          } else {
            this.onlineUntil = ''
          }
        }
        this.loadCurrentStatus()
        if (localStorage.getItem('oldCurrentStep') === '3') {
          this.$refs.wizard.changeTab(0, 3)
          localStorage.removeItem('oldCurrentStep')
        }
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
      })
  },
  methods: {
    ...mapGetters('employee', ['getEmployees']),
    ...mapMutations('onboarding', ['setObjectOwnerContacts']),
    updateObjectAdvertising(object) {
      this.item = object
    },
    loadCurrentStatus() {
      if (this.item.marketingStatus && this.item.marketingStatus.clearFromMarketingAt > 0) {
        this.$nextTick(function () {
          this.isRented = true
        })
      }
    },
    async openAdvertExpose() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        this.$bvModal.hide('openExposeModal')
        // save object and building before generate pdf
        await Promise.all([
          this.saveBuilding(),
          this.saveObject()
        ])
        const blob = new Blob([await ObjectApi.getMarketingExpose(this.item.id, this.language)], { type: 'application/pdf' })
        const blobURL = URL.createObjectURL(blob)
        window.open(blobURL)
        this.loading = false
        await this.$router.push({
          name: 'MarketingObjectDetailsView',
          params: { id: this.id },
          query: { view: 1 }
        })
      }
    },
    editBuildingFields(data) {
      if (data.field === 'constructionYear') {
        this.building.constructionYear = data.value
      } else if (data.field === 'constructionTypes') {
        this.building.constructionTypes = data.value.map(x => x.value)
      } else if (data.field === 'renovationYear') {
        this.building.renovationYear = data.value
      }
    },
    buildingCharacteristicsUpdated(payload) {
      Vue.set(this.building.characteristics, payload.field, payload.value)
    },
    buildingDistanceUpdated(payload) {
      Vue.set(this.building.distances, payload.field, payload.value)
    },
    defaultAnswerUpdated(defaultAnswer) {
      Vue.set(this.item, 'defaultAnswer', defaultAnswer)
    },
    viewingAppointmentUpdated(viewingAppointment) {
      Vue.set(this.item, 'viewingAppointment', viewingAppointment)
    },
    viewingContactUpdated(contactId) {
      if (this.item.contacts) {
        this.item.contacts.contactIdViewingAppointment = contactId
      } else {
        this.item.contacts = {
          contactIdViewingAppointment: contactId
        }
      }
    },
    assigneeMarketingUpdated(contactId) {
      if (this.item.contacts) {
        this.item.contacts.contactIdAssigneeMarketing = contactId
      } else {
        this.item.contacts = {
          contactIdAssigneeMarketing: contactId
        }
      }
    },
    emptyDefaultAnswer(empty) {
      this.defaultAnswerNone = empty
    },
    platformsChanged(platforms) {
      this.hasPlatforms = !!(platforms.newhome || platforms.immoscout || platforms.homegate || platforms.casaone || platforms.flatfox)
    },
    clickBack() {
      if (this.itemChanged) {
        this.$refs['modal-confirmation'].show()
      } else {
        this.$router.go(-1)
      }
    },
    closeModal() {
      this.$refs['modal-confirmation'].hide()
    },
    discardChanges() {
      this.$router.go(-1)
    },
    async publishData() {
      await ObjectApi.publishObject([this.id], new Date(this.unPublishAt + 'T' + this.time.end).getTime() / 1000, this.unPublishAtAmountOfRequests)
        .then(() => {
          this.$router.push({
            name: 'MarketingObjectDetailsView',
            params: { id: this.id },
            query: { view: 1 }
          })
          Vue.toasted.show(this.$t('message.successMessages.publishObject'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.publishObject'), { type: 'error' })
        })
    },
    async saveData() {
      if (this.$refs['create-building-view'].isInvalid() && this.$refs['create-objects-view'].isInvalid()) {
        this.closeModal()
        await this.saveOnboarding()
        if (this.nextPath !== null) {
          this.nextPath()
        }
      }
    },
    async validateTabOne() {
      return !this.$refs['object-advertising'].isInvalid() && !this.$refs['object-basics'].isInvalid() && !this.$refs['building-distances'].isInvalid()
    },
    async validateTabTwo() {
      return !this.$refs['object-features'].isInvalid()
    },
    async validateTabThree() {
      return !this.$refs?.editAdvertCommunicationTab?.isInvalid()
    },
    async validateTabFour() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        if (this.$v.unPublishAtAmountOfRequests?.$error) {
          Vue.toasted.show(this.$t('message.savingErrors.alreadyReachedAmountRequest'), { type: 'error' })
        }
        if (this.$v.unPublishAt?.$error) {
          Vue.toasted.show(this.$t('message.savingErrors.unPublishAtCantBePast'), { type: 'error' })
        }
        return false
      }
      return true
    },
    nextClicked(props, canBePublished) {
      if (!props.isLastStep || canBePublished) {
        props.nextTab()
        this.scrollToTop()
      }
    },
    backClicked(props) {
      props.prevTab()
      this.scrollToTop()
    },
    scrollToTop() {
      this.$nextTick(() => {
        const domRect = this.$el.querySelector('#wizard')
        domRect.scrollIntoView(true)
      })
    },
    async save(activeTabIndex, publish = false) {
      let noErrorFlag = true
      if (activeTabIndex === 0) {
        noErrorFlag = await this.validateTabOne()
      } else if (activeTabIndex === 1) {
        noErrorFlag = await this.validateTabTwo()
      } else if (activeTabIndex === 2) {
        noErrorFlag = await this.validateTabThree()
      } else if (activeTabIndex === 3) {
        noErrorFlag = await this.validateTabFour()
      }
      if (noErrorFlag) {
        this.loading = true

        if (this.canBePublished) {
          this.$refs['export-platforms']?.savePlatforms()
        }

        await Promise.all([
          this.saveBuilding(),
          this.saveObject()
        ])

        if (publish) {
          await this.publishData(publish)
          Vue.toasted.show(this.$t('message.successMessages.publishObject'), { type: 'success' })
        } else {
          Vue.toasted.show(this.$t('message.successMessages.changeSaved'), { type: 'success' })
        }
        await this.$router.push({
          name: 'MarketingObjectDetailsView',
          params: { id: this.id },
          query: { view: 1 }
        })
      }
    },
    async saveBuilding() {
      await ObjectApi.updateBuilding(
        this.building.id,
        this.building.name,
        this.building.objectIds,
        this.building.egid,
        this.building.number,
        this.building.imageMediaId,
        this.building.realEstateNumber,
        this.building.characteristics,
        this.building.constructionYear,
        this.building.renovationYear,
        this.building.buildingZone,
        this.building.plotIds,
        this.building.plotArea,
        this.building.buildingArea,
        this.building.volume,
        this.building.floors,
        this.building.locationQuality,
        this.building.constructionTypes,
        this.building.sia,
        this.building.distances,
        this.building.energySources,
        this.building.documentIds,
        this.building.acquisitionDate,
        this.building.address,
        this.building.note,
        this.building.contractAmendments,
        this.building.rating,
        this.building.buildingStrategy
      )
    },
    async saveObject() {
      if (this.item?.viewingAppointment && this.item.viewingAppointment.contactType !== 'VIEWING_APPOINTMENT_CONTACT_TYPE_FREE_TEXT') {
        const newViewingAppointment = this.item.viewingAppointment
        newViewingAppointment.firstName = ''
        newViewingAppointment.lastName = ''
        newViewingAppointment.phone = ''
        newViewingAppointment.email = ''
        Vue.set(this.item, 'viewingAppointment', newViewingAppointment)
      }
      Vue.set(this.item, 'acceptsCreditTrust', this.acceptsCreditTrust)
      await ObjectApi.updateObject(this.item)
    }
  },
  validations() {
    let validations = {}
    if (this.onlineUntilValue === 'amount') {
      validations = {
      ...validations,
        unPublishAtAmountOfRequests: {
          required,
          moreThanOverall
        }
      }
    }
    if (this.onlineUntilValue === 'date') {
      validations = {
      ...validations,
        unPublishAt: {
          required,
          moreThanToday
        }
      }
    }
    return validations
  }
}
</script>

<style lang="scss" scoped>
.media-block {
  max-width: 25%;
  margin-right: 0.5%;

  .uploaded-file {
    background-position: center center;
    background-size: cover;
    min-height: 7em;
  }

  .file-block {
    min-height: 7em;
    background: $color-bg-grey-dark;

    a {
      color: $color-text-grey;
      overflow-wrap: break-word;
    }
  }
}

:deep(.global-card) {
  margin-bottom: 0;

  & > .card-body {
    -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
  }

  &.no-top-border {
    border-top: 0;
  }
}
:deep(.marketing-edit-advert h5.cursor-pointer) {
  font-size: large;
  font-weight: 500 !important;
}

:deep(.vue-form-wizard .wizard-icon-circle .wizard-icon) {
  font-style: initial;
}

:deep(label) {
  width: 100%;
}

.readonly-wrapper {

  .inputs-container {
    overflow-y: scroll;
    height: calc(100vh - 100px);
  }

  .item {
    cursor: pointer;
  }

  :deep(.details-row-bg-1) {
    background-color: $color-table-row-2;
  }

  :deep(.details-row-bg-2) {
    background-color: $color-table-row-1;
  }

  :deep(label) {
    width: 100%;
  }

  :deep(.is-disabled) {
    .sort button {
      display: block !important;
    }

    button:not(.sort):not(.page-link) {
      display: none;
    }

    .multiselect, .custom-checkbox {
      pointer-events: none;

      .multiselect__select, .multiselect__placeholder {
        display: none;
      }
    }

    .filter-inputs .multiselect, .custom-checkbox {
      pointer-events: auto !important;

      .multiselect__select, .multiselect__placeholder {
        display: block !important;
      }
    }

    .basic {
      input {
        opacity: 1;
        border: thin solid #ced4da;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
        margin-bottom: 0;
        pointer-events: initial;
        padding-left: 0.2rem;
        background: none;
      }
    }

    .multi-select-value {
      line-height: 1.5;
    }
  }

  :deep(.collapse-title) {
    &.collapsed {
      h5 {
        background-image: url('../assets/arrow_down.svg');
      }
    }

    h5 {
      background-image: url('../assets/arrow_up.svg');
      background-repeat: no-repeat;
      font-size: large;
      font-weight: 500 !important;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      background-position: right;
    }
  }

  :deep(.global-card) {
    margin-bottom: 0;

    & > .card-body {
      -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    }

    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    &.no-top-border {
      border-top: 0;
    }
  }

}
</style>
