<template>
  <section>
    <div class="row">
      <div class="col">
        <coozzy-page-title
          :title="$t('message.navigation.settings.title') " />
        <edit-password-settings />
      </div>
    </div>
  </section>
</template>
<script>
import EditPasswordSettings from '../settings/components/EditPasswordSettings'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'

export default {
  name: 'UserSettings',
  components: {
    CoozzyPageTitle,
    EditPasswordSettings
  }
}
</script>
