<template>
  <section>
    <div class="body-of-child">
      <div>
        <div class="row m-0">
          <div class="my-5">
            <div>
              <h1 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titleteen') }}
              </h1>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.textteen')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <coozzy-footer />
  </section>
</template>

<script>

import { language } from '../mixins/language'

export default {
  name: 'CookiesView',
  mixins: [language]
}
</script>

<style lang="scss" scoped>
  .body-of-child {
    background-color: $color-text-white;
    min-height: 90vh;
    padding-bottom: 226px;
  }
  hr {
    border: 1px solid $color-primary-dark;
    width: 30px;
  }
  p a {
    color: $color-primary-dark;
  }
  h4 {
    line-height: 29px;
    font-weight: 300;
    font-size: 23px;
    color: $color-text-grey;
  }
  :deep(footer) {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
</style>
