<template>
  <section>
    <b-modal
      v-if="newContact"
      id="add-contact-person-modal"
      ref="add-contact-person-modal"
      :modal-class="'has-confirmation-modal'"
      no-close-on-backdrop
      :title="selectedContact != null ? $t('message.contact.editContact') : $t('message.contact.newContact')"
      @hidden="hide()">
      <div class="row">
        <div class="col-6 mb-3">
          <coozzy-form-input
            v-model="newContact.email"
            :disabled="!newContact.active"
            :state="$v.newContact.email.$dirty && $v.newContact.email.$error ? false : null"
            :name="$t('message.generic.email')" />
        </div>
        <div
          class="col-6 mb-3">
          <coozzy-multiselect
            v-model="notificationPreferences"
            :disabled="!newContact.active"
            :options="translatedNotificationPreferencesList"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('message.contact.notificationPreferences.title')"
            :preselect-first="false"
            :taggable="false"
            label="label"
            track-by="label">
            {{ $t('message.contact.notificationPreferences.title') }}
          </coozzy-multiselect>
        </div>
      </div>
      <div class="row">
        <!--suppress XmlInvalidId -->
        <div class="col-6 col-md-6 mb-2">
          <label
            for="preferredLanguage"
            class="mb-0">
            {{ $t('message.contact.preferredLanguage') }}
          </label>
          <coozzy-form-select
            id="preferredLanguage"
            v-model="newContact.preferredLanguage"
            :disabled="!newContact.active">
            <option value="PREFERRED_LANGUAGE_GERMAN">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_GERMAN') }}
            </option>
            <option value="PREFERRED_LANGUAGE_ENGLISH">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ENGLISH') }}
            </option>
            <option value="PREFERRED_LANGUAGE_ITALIAN">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ITALIAN') }}
            </option>
            <option value="PREFERRED_LANGUAGE_FRENCH">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_FRENCH') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-6 col-md-6 mb-3">
          <coozzy-form-input
            v-model="newContact.title"
            :disabled="!newContact.active"
            :name="$t('message.generic.contactTitle')"
            :limit="fiftyLength"
            @input="checkLenght('title')" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <!--suppress XmlInvalidId -->
          <label
            for="title"
            class="mb-0">
            {{ $t('message.generic.title.title') }}
          </label>
          <coozzy-form-select
            id="title"
            v-model="newContact.gender"
            :disabled="!newContact.active">
            <option value="UNDEFINED_GENDER" />
            <option value="MALE">
              {{ $t('message.generic.title.mr') }}
            </option>
            <option value="FEMALE">
              {{ $t('message.generic.title.mrs') }}
            </option>
            <option value="OTHER">
              {{ $t('message.generic.title.divers') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <coozzy-form-input
            v-model="newContact.firstName"
            :disabled="!newContact.active"
            :state="$v.newContact.firstName && $v.newContact.firstName.$dirty && $v.newContact.firstName.$error ? false : null"
            :name="$t('message.generic.firstName') +'*'" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <coozzy-form-input
            v-model="newContact.lastName"
            :disabled="!newContact.active"
            :state="$v.newContact.lastName && $v.newContact.lastName.$dirty && $v.newContact.lastName.$error ? false : null"
            :name="$t('message.generic.lastName') +'*'" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <coozzy-form-input
            v-model="newContact.birthName"
            :disabled="!newContact.active"
            :name="$t('message.generic.birthName')" />
        </div>
      </div>
      <!-- Start row: Address 1 -->
      <div
        v-if="newContact.address"
        class="row">
        <div class="col-12">
          <coozzy-form-input
            v-model="newContact.address.careOf"
            :disabled="!newContact.active"
            class="mb-2"
            :name="$t('message.generic.addressAddition')" />
        </div>
      </div>
      <!-- End row: Address 1 -->
      <!-- Start row: Address 2 -->
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <coozzy-form-input
            v-model="newContact.address.street"
            :disabled="!newContact.active"
            class="mb-2"
            :name="$t('message.generic.street')" />
        </div>
        <div class="col-sm-12 col-md-4">
          <coozzy-form-input
            v-model="newContact.address.streetNumber"
            :disabled="!newContact.active"
            class="mb-2"
            :name="$t('message.generic.no')" />
        </div>
        <div class="col-sm-12 col-md-4">
          <coozzy-form-input
            v-model="newContact.address.mailbox"
            :disabled="!newContact.active"
            class="mb-2"
            :name="$t('message.generic.postOfficeBox')"
            :placeholder="$t('message.generic.postOfficeBoxPlaceholder')" />
        </div>
      </div>
      <!-- End row: Address 2 -->
      <!-- Start row: Address 3 -->
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <coozzy-form-input
            v-model="newContact.address.zip"
            :disabled="!newContact.active"
            :type="(newContact.address.country === 'CH' || newContact.address.country === 'DE') ? 'number': 'text'"
            :limit="zipLength"
            :name="$t('message.generic.zip')"
            @input="getCityFromZip" />
        </div>
        <div class="col-sm-12 col-md-6">
          <coozzy-form-input
            v-if="!isCitiesSuggested"
            v-model="newContact.address.city"
            :disabled="!newContact.active"
            class="mb-2"
            :name="$t('message.generic.city')" />
          <template v-else>
            <label for="city">{{ $t('message.generic.city') }}*</label>
            <coozzy-form-select
              id="city"
              @change="cityChanged">
              <option
                v-for="(item, typeIndex) in citiesValue"
                :key="typeIndex"
                :value="item">
                {{ item.city }}
              </option>
            </coozzy-form-select>
          </template>
        </div>
      </div>
      <!-- End row: Address 3 -->
      <!-- Start row: Address 4 -->
      <div class="row">
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-country-select
            v-model="newContact.address.country"
            :disabled="!newContact.active"
            :initial-value="newContact.address.country"
            :required="false" />
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-region-select
            v-model="newContact.address.state"
            :disabled="!newContact.active"
            :initial-value="newContact.address.state"
            :country="newContact.address.country"
            :required="false" />
        </div>
      </div>
      <!-- End row: Address 4 -->
      <div class="row">
        <div class="col-sm-12 col-md-6 mb-3">
          <coozzy-form-input
            v-model="newContact.phone.work"
            :disabled="!newContact.active"
            type="tel"
            :name="$t('message.generic.phoneNumber')" />
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <coozzy-form-input
            v-model="newContact.phone.mobile"
            :disabled="!newContact.active"
            type="tel"
            :name="$t('message.generic.phone.mobile')" />
        </div>
      </div>
      <div class="row">
        <div class="col col-md-6 mb-3">
          <coozzy-form-input
            v-model="newContact.dob"
            :disabled="!newContact.active"
            type="date"
            :name="$t('message.generic.birthday')" />
        </div>
        <div
          v-if="selectedContact != null"
          class="col col-md-6 mb-3">
          <coozzy-form-input
            v-model="newContact.dod"
            :disabled="!newContact.active"
            type="date"
            :name="$t('message.generic.dateOfDeath')" />
        </div>
      </div>
      <div class="row">
        <div
          v-if="contactType === 'person'"
          class="col-6 mb-3">
          <coozzy-form-input
            v-model="newContact.companyName"
            :disabled="!newContact.active"
            :name="$t('message.generic.companyName')" />
        </div>
        <div
          class="col-6 mb-3">
          <coozzy-form-input
            v-model="newContact.companyPosition"
            :disabled="!newContact.active"
            :name="$t('message.generic.jobTitle')" />
        </div>
        <div
          v-if="contactType === 'company'"
          class="col-6 mb-2">
          <coozzy-form-input
            v-model="newContact.salutationName"
            :disabled="!newContact.active"
            :name="$t('message.generic.salutation')"
            :placeholder="salutationPlaceholder"
            :tooltip-text="salutationTooltip" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 mb-3">
          <label> {{ $t('message.generic.notes') }} </label>
          <coozzy-form-textarea
            v-model="newContact.note"
            :value-text-area="newContact.note"
            :disabled="!newContact.active"
            :initial="newContact.note"
            :resizable="false"
            :show-label="false"
            :rows="3">
            {{ newContact.note }}
          </coozzy-form-textarea>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          size="normal"
          design="green"
          class="float-right"
          :disabled="!newContact.active || loading"
          @click="saveClicked">
          {{ selectedContact ? $t('message.generic.form.save') : $t('message.generic.add') }}
        </coozzy-button>
        <coozzy-button
          size="normal"
          class="float-left"
          @click="hide()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          v-if="selectedContact !== null && newContact.active && companyContact && companyContact.active"
          v-b-modal="'confirmationModal'+selectedContact.id"
          design="red"
          size="normal"
          class="float-left ml-1"
          :disabled="loading">
          {{ $t('message.generic.inactivate') }}
        </coozzy-button>
        <coozzy-button
          v-if="selectedContact !== null && !newContact.active && companyContact &&companyContact.active"
          v-b-modal="'confirmationModal'+selectedContact.id"
          design="red"
          size="normal"
          class="float-left ml-1"
          :disabled="loading">
          {{ $t('message.generic.activate') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      v-if="selectedContact !== null && !loading"
      :id="'confirmationModal'+selectedContact.id"
      :ref="'confirmationModal'+selectedContact.id"
      no-close-on-backdrop
      hide-footer
      :title="selectedContact.active ? $t('message.employeesSettings.deactivateContactConfirmation') : $t('message.employeesSettings.activateContactConfirmation')">
      <div class="col p-0">
        <p v-if="selectedContact.active">
          {{ $t('message.employeesSettings.deactivateContactText') }}
        </p>
        <p v-else>
          {{ $t('message.employeesSettings.activeUserText') }}
        </p>
      </div>
      <div class="col p-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModal'+selectedContact.id)">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          v-if="newContact.active"
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="loading"
          @click="deactivateContact()">
          {{ $t('message.generic.inactivate') }}
        </coozzy-button>
        <coozzy-button
          v-else
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="loading"
          @click="activateContact()">
          {{ $t('message.generic.activate') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { email, required } from 'vuelidate/lib/validators'
import ContactApi from '../../misc/apis/ContactApi'
import Vue from 'vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyFormCountrySelect from '@/framework/components/form/select/CoozzyFormCountrySelect'
import CoozzyFormRegionSelect from '@/framework/components/form/select/CoozzyFormRegionSelect'
import { contact } from '@/mixins/contact'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import { address } from '@/mixins/address'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect.vue'

export default {
  name: 'ContactPersonCreation',
  components: { CoozzyMultiselect, CoozzyButton, CoozzyFormTextarea, CoozzyFormSelect, CoozzyFormInput, CoozzyFormCountrySelect, CoozzyFormRegionSelect },
  mixins: [contact, address],
  props: {
    selectedContact: {
      type: Object,
      default: null
    },
    companyContact: {
      type: Object,
      default: null
    },
    contactType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      salutationPlaceholder: this.renderSalutationPlaceholder('private'),
      salutationTooltip: this.renderSalutationTooltip('private'),
      loading: false,
      isCitiesSuggested: false,
      citiesValue: [],
      newContact: {
        contactType: 'private',
        active: true,
        gender: 'UNDEFINED_GENDER',
        title: '',
        firstName: '',
        lastName: '',
        birthName: '',
        name: '',
        email: '',
        phone: {
          home1: '',
          home2: '',
          work: '',
          mobile: '',
          fax: '',
          phoneNumber: ''
        },
        companyName: '',
        companyPosition: '',
        dob: '',
        dod: '',
        website: '',
        salutationName: '',
        type: [],
        address: {
          city: '',
          state: '',
          street: '',
          streetNumber: '',
          zip: '',
          streetNo: '',
          country: 'CH'
        },
        preferredLanguage: 'PREFERRED_LANGUAGE_UNDEFINED',
        includeInSerialEmails: false,
        portalAccess: false
      },
      fiftyLength: 50,
      notificationPreferences: [],
      includeInSerialEmails: false,
      portalAccess: false
    }
  },
  computed: {
    zipLength() {
      const country = this.newContact.address.country
      if (country === 'CH') {
        return 4
      } else if (country === 'DE') {
        return 5
      } else {
        return 15
      }
    },
    translatedNotificationPreferencesList() {
      const array = []
      let listNotificationPreferences = ['includeInSerialEmail', 'portalAccess']
      listNotificationPreferences.forEach(item => {
        array.push({
          label: this.$t('message.contact.notificationPreferences.' + item),
          value: item
        })
      })
      return array
    }
  },
  watch: {
    notificationPreferences: {
      handler(value) {
        this.includeInSerialEmails = !!value.find(item => item.value === 'includeInSerialEmail')
        this.portalAccess = !!value.find(item => item.value === 'portalAccess')
        this.$nextTick(() => {
          this.newContact.includeInSerialEmails = this.includeInSerialEmails
          this.newContact.portalAccess = this.portalAccess
        })
      }
    },
    newContact: {
      handler() {
        this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
      },
      deep: true
    },
    'newContact.preferredLanguage': {
      handler() {
        this.renderSalutationName()
      },
      deep: true
    },
    'newContact.gender': {
      handler() {
        this.renderSalutationName()
      },
      deep: true
    },
    'newContact.lastName': {
      handler() {
        this.renderSalutationName()
      },
      deep: true
    },
    'newContact.firstName': {
      handler() {
        this.renderSalutationName()
      },
      deep: true
    },
    contactType: {
      handler() {
        this.renderSalutationName()
      }
    }
  },
  mounted: function () {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'add-contact-person-modal' && this.selectedContact === null) {
        this.emptyNewContact()
        this.$nextTick(function () {
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        })
      }
    })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'add-contact-person-modal') {
        if (this.selectedContact !== null) {
          this.copyContactByDefault()
          this.includeInSerialEmails = this.selectedContact.includeInSerialEmails
          this.portalAccess = this.selectedContact.portalAccess
          this.setValuesNotificationPreferences()
        } else {
          this.emptyNewContact()
          this.$nextTick(function () {
            this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
          })
        }
      }
    })
  },
  methods: {
    setValuesNotificationPreferences() {
      if (this.includeInSerialEmails) {
        this.notificationPreferences.push({
          label: this.$t('message.contact.notificationPreferences.includeInSerialEmail'),
          value: 'includeInSerialEmail'
        })
      }
      if (this.portalAccess) {
        this.notificationPreferences.push({
          label: this.$t('message.contact.notificationPreferences.portalAccess'),
          value: 'portalAccess'
        })
      }
    },
    checkLenght(field) {
      if (field === 'title' && this.newContact.title.length > this.fiftyLength) {
        this.$nextTick(() => {
          this.newContact.title = this.newContact.title.substring(0, this.fiftyLength)
        })
      }
    },
    cityChanged(event) {
      this.newContact.address.city = event.city
      this.newContact.address.state = event.state
    },
    renderSalutationName() {
      let salutationPrefix = ''
      let salutationFinal = ''
      if ((this.newContact.lastName && this.newContact.lastName !== '') || (this.newContact.gender && this.newContact.gender !== 'UNDEFINED_GENDER')) {
        if (this.newContact.gender === 'MALE') {
          salutationPrefix = this.$t('message.contact.salutation.prefixIndividualMale')
          salutationFinal = salutationPrefix + ' ' + this.newContact.lastName
        } else if (this.newContact.gender === 'FEMALE') {
          salutationPrefix = this.$t('message.contact.salutation.prefixIndividualFemale')
          salutationFinal = salutationPrefix + ' ' + this.newContact.lastName
        } else {
          salutationPrefix = this.$t('message.contact.salutation.prefixIndividualOther')
          salutationFinal = salutationPrefix + ' ' + (this.newContact.firstName !== '' ? this.newContact.firstName + ' ' : '') + this.newContact.lastName
        }
      }
      const addExampleStringToPlaceholder = !(this.newContact.lastName || (this.newContact.gender && this.newContact.gender !== 'UNDEFINED_GENDER'))
      this.salutationTooltip = this.renderSalutationTooltip('private', salutationFinal)
      this.salutationPlaceholder = this.renderSalutationPlaceholder('private', salutationFinal, addExampleStringToPlaceholder)
    },
    async getCityFromZip() {
      if (this.newContact.address.zip.length > 3 && this.newContact.address.country === 'CH') {
        const zipData = await this.getZipInfo(this.newContact.address.zip)
          if (zipData) {
            this.newContact.address.state = zipData.state
            if (zipData.cities.length > 1) {
              this.isCitiesSuggested = true
              this.citiesValue = zipData.cities
            } else {
              this.isCitiesSuggested = false
              this.$nextTick(() => {
                this.newContact.address.city = zipData.cities[0].city
              })
            }
        }
      }
    },
    saveClicked() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        if (this.selectedContact === null) {
          this.newContactPerson()
        } else {
          this.editContactPerson()
        }
      }
    },
    newContactPerson() {
      const companyId = 'objectID' in this.companyContact ? this.companyContact.objectID : this.companyContact.id
      ContactApi.addContactPerson(this.newContact, companyId)
        .then(response => {
          this.loading = false
          this.$v.$reset()
          this.emptyNewContact()
          this.$emit('new-contact-person', response.contactPerson)
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
          this.hide()
        })
        .catch(e => {
          console.log(e)
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
          this.loading = false
          Vue.toasted.show(this.$t('message.savingErrors.addContact'), { type: 'error' })
        })
    },
    editContactPerson() {
      const companyId = 'objectID' in this.companyContact ? this.companyContact.objectID : this.companyContact.id
      ContactApi.editContactPerson(this.newContact, companyId, this.selectedContact.id)
        .then(response => {
          this.loading = false
          this.$emit('edit-contact-person', response.contactPerson)
          this.$v.$reset()
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
          this.hide()
        })
        .catch(e => {
          console.log(e)
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
          this.loading = false
          Vue.toasted.show(this.$t('message.savingErrors.addContact'), { type: 'error' })
        })
    },
    show() {
      this.$refs['add-contact-person-modal'].show()
    },
    hide() {
      if (this.$refs['add-contact-person-modal']) {
        this.$refs['add-contact-person-modal'].hide()
      }
    },
    emptyNewContact() {
      this.newContact.active = true
      this.newContact.dob = ''
      this.newContact.dod = ''
      this.newContact.email = ''
      this.newContact.lastName = ''
      this.newContact.birthName = ''
      this.newContact.firstName = ''
      this.newContact.title = ''
      this.newContact.gender = 'UNDEFINED_GENDER'
      this.newContact.name = ''
      this.newContact.phone.work = ''
      this.newContact.phone.mobile = ''
      this.newContact.companyName = ''
      this.newContact.companyPosition = ''
      this.newContact.note = ''
      this.newContact.preferredLanguage = 'PREFERRED_LANGUAGE_UNDEFINED'
      this.newContact.address = {
        city: '',
        state: '',
        street: '',
        streetNumber: '',
        zip: '',
        streetNo: '',
        country: 'CH'
      }
    },
    copyContactByDefault() {
      this.newContact.active = this.selectedContact.active
      this.newContact.dob = this.selectedContact.dateOfBirth ? this.selectedContact.dateOfBirth.year + '-' + ('0' + this.selectedContact.dateOfBirth.month).slice(-2) + '-' + ('0' + this.selectedContact.dateOfBirth.day).slice(-2) : ''
      this.newContact.dod = this.selectedContact.dateOfDeath ? this.selectedContact.dateOfDeath.year + '-' + ('0' + this.selectedContact.dateOfDeath.month).slice(-2) + '-' + ('0' + this.selectedContact.dateOfDeath.day).slice(-2) : ''
      this.newContact.firstName = this.selectedContact.firstName
      this.newContact.title = this.selectedContact.title
      this.newContact.note = this.selectedContact.note
      this.newContact.lastName = this.selectedContact.lastName
      this.newContact.birthName = this.selectedContact.birthName
      this.newContact.name = this.selectedContact.name
      this.newContact.id = this.selectedContact.id
      this.newContact.preferredLanguage = this.selectedContact.preferredLanguage
      this.newContact.salutationName = this.selectedContact.salutationName
      if (this.selectedContact.phoneNumbers) {
        this.newContact.phone.work = this.selectedContact.phoneNumbers.find(element => element.type === 'WORK') ? this.selectedContact.phoneNumbers.find(element => element.type === 'WORK').number : ''
        this.newContact.phone.mobile = this.selectedContact.phoneNumbers.find(element => element.type === 'MOBILE') ? this.selectedContact.phoneNumbers.find(element => element.type === 'MOBILE').number : ''
      }
      this.newContact.email = this.selectedContact.email
      this.newContact.gender = this.selectedContact.gender
      this.newContact.companyName = this.selectedContact.companyName
      this.newContact.companyPosition = this.selectedContact.companyPosition || this.selectedContact.employment?.companyPosition
      const address = {
        city: '',
        state: '',
        street: '',
        streetNumber: '',
        zip: '',
        streetNo: '',
        country: 'CH'
      }
      this.newContact.address = 'address' in this.selectedContact ? this.selectedContact.address : address
      this.$nextTick(function () {
        this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      })
    },
    removeContactPerson() {
      this.loading = true
      ContactApi.deleteContactPerson(this.companyContact.id, [this.selectedContact.id])
        .then(() => {
          this.$refs['confirmationModal' + this.selectedContact.id].hide()
          this.hide()
          this.$emit('delete-contact-person', this.selectedContact.id)
          this.loading = false
          Vue.toasted.show(this.$t('message.successMessages.contactDeletion'), { type: 'success' })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.contact'), { type: 'error' })
        })
    },
    deactivateContact() {
      this.loading = true
      ContactApi.deactivateContact([this.selectedContact.id])
        .then(() => {
          setTimeout(() => {
            this.hide()
            this.loading = false
            this.newContact.active = false
            this.$emit('edit-contact-person', this.newContact)
            Vue.toasted.show(this.$t('message.successMessages.contactInactivated'), { type: 'success' })
          }, 2000)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.contactInactivated'), { type: 'error' })
          this.$refs['confirmationModal' + this.selectedContact.id].hide()
          this.hide()
          this.loading = false
        })
    },
    activateContact() {
      this.loading = true
      ContactApi.activateContact([this.selectedContact.id])
        .then(() => {
          setTimeout(() => {
            this.hide()
            this.loading = false
            this.newContact.active = true
            this.$emit('edit-contact-person', this.newContact)
            Vue.toasted.show(this.$t('message.successMessages.contactActivated'), { type: 'success' })
          }, 2000)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.contactActivated'), { type: 'error' })
          this.$refs['confirmationModal' + this.selectedContact.id].hide()
          this.hide()
          this.loading = false
        })
    }
  },
  validations() {
    return {
      newContact: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        email: {
          email
        }
      }
    }
  }
}
</script>
