<template>
  <div class="row">
    <div class="col">
      <coozzy-card class="readonly-wrapper global-card mb-3">
        <object-basic
          :key="`basic-${object.id}`"
          ref="object-basic"
          :from-admin="$route.path.startsWith('/admin') || $route.path.startsWith('/accounting')"
          :object-selected="object"
          :read-only="true"
          :visible="false" />
      </coozzy-card>
      <coozzy-card
        class="readonly-wrapper global-card mb-3">
        <object-pricing
          :key="`pricing-${object.id}`"
          ref="object-pricing"
          :expand-by-default="false"
          :object-selected="object"
          :read-only="true" />
      </coozzy-card>
      <coozzy-card
        v-if="lastestTenant.length > 0"
        class="readonly-wrapper global-card mb-3">
        <div
          id="Tenant"
          v-b-toggle.coozzy-card-tenancy
          class="collapse-title">
          <h5 class="cursor-pointer">
            <template>
              {{ $t('message.onBoarding.buildings.objects.tenancies.title') }}
            </template>
          </h5>
        </div>
        <div class="col-12 p-0">
          <b-collapse
            id="coozzy-card-tenancy"
            :visible="true">
            <template v-for="lastTenancy of lastestTenant">
              <object-tenancy
                v-if="lastTenancy.vacancy === false"
                :key="'ten-'+lastTenancy.id"
                :ref="`object-last-tenancy-${lastTenancy.id}`"
                :enable-tenant-selection="false"
                :expand-by-default="false"
                :hide-actions="true"
                :index="lastTenancy.id"
                :object="object"
                :read-only="true"
                :show-delete-button="false"
                :tenancy="lastTenancy"
                mode="adding" />
              <object-vacancy
                v-else
                :key="'vac-'+lastTenancy.id"
                :ref="`object-last-vacancy-${lastTenancy.id}`"
                :enable-tenant-selection="false"
                :expand-by-default="false"
                :index="lastTenancy.id"
                :read-only="true"
                :show-delete-button="false"
                :tenancy="lastTenancy"
                mode="adding" />
            </template>
          </b-collapse>
        </div>
      </coozzy-card>
      <coozzy-card class="global-card mb-3">
        <object-tenancy
          v-if="tenancySet"
          ref="object-tenancy"
          :billing-contact="billingContact"
          :correspondence-contact="correspondenceContact"
          :enable-tenant-selection="false"
          :expand-by-default="true"
          :guarantor-contacts="guarantorContacts"
          :tenant-contacts="tenantContacts"
          :is-inprogress="true"
          :object="object"
          :read-only="false"
          :request-id="requestId"
          :show-delete-button="false"
          :tenancy="tenancy"
          :tenant-not-editable="true"
          :hide-actions="fromMassRentalProcess"
          :mode="fromMassRentalProcess ? 'full' : 'adding'"
          :from-mass-rental-process="fromMassRentalProcess"
          @tenancy-updated="tenancyUpdated" />
      </coozzy-card>
    </div>
    <b-modal
      :id="'editDates'"
      ref="editDates"
      :title="$t('message.onBoarding.buildings.objects.tenancies.modal.attention')"
      hide-footer>
      <div class="row m-1">
        <div class="col-12 p-0">
          <p>{{ $t('message.onBoarding.buildings.objects.tenancies.modal.message', { endDate: getEndDate() }) }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            class="mb-0 border"
            design="transparent"
            size="small"
            @click="actionModal(0)">
            {{ $t('message.onBoarding.buildings.objects.tenancies.modal.no') }}
          </coozzy-button>
          <coozzy-button
            class="float-right mb-0"
            design="green"
            size="small"
            @click="actionModal(1)">
            {{ $t('message.onBoarding.buildings.objects.tenancies.modal.yes') }}
          </coozzy-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ObjectBasic from '@/building/tabs/components/ObjectBasic'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import ObjectTenancy from '@/building/tabs/components/ObjectTenancy'
import ContactApi from '@/misc/apis/ContactApi'
import { mapMutations } from 'vuex'
import ObjectPricing from '@/building/tabs/components/ObjectPricing'
import ObjectApi from '@/misc/apis/ObjectApi'
import Vue from 'vue'
import { validation } from '@/mixins/validation'
import { onboarding } from '@/mixins/onboarding'
import ObjectVacancy from '@/building/tabs/components/ObjectVacancy'
import { dateUtils } from '@/mixins/dateUtils'
import * as Sentry from '@sentry/vue'

export default {
  name: 'AddTenancyTenancyTab',
  components: {
    ObjectTenancy,
    CoozzyCard,
    CoozzyButton,
    ObjectBasic,
    ObjectPricing,
    ObjectVacancy
  },
  mixins: [validation, onboarding, dateUtils],
  props: {
    object: {
      type: Object,
      required: true
    },
    initialTenancy: {
      type: Object,
      default: null
    },
    tenancyId: {
      type: String,
      default: ''
    },
    lastTenancies: {
      type: Array,
      default: () => {
        return []
      }
    },
    tenantContacts: {
      type: Array,
      default: () => {
        return []
      }
    },
    billingContact: {
      type: Object,
      default: null
    },
    correspondenceContact: {
      type: Object,
      default: null
    },
    guarantorContacts: {
      type: Array,
      default: () => {
        return []
      }
    },
    requestId: {
      type: String,
      default: ''
    },
    listTenancies: {
      type: Array,
      default: () => {
        return []
      }
    },
    fromMassRentalProcess: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      property: null,
      loadingProperty: false,
      tenancy: this.initialTenancy
        ? this.initialTenancy
        : {
          internalId: Date.now(),
          ownerId: this.object.ownerId,
          objectId: this.object.id,
          vacancy: false,
          contract: {
            activationDate: null,
            creationDate: null,
            number: '',
            type: 'UNDEFINED_CONTRACT_TYPE',
            rentType: '',
            typeOfUse: ''
          },
          subTenants: [],
          roommates: [],
          opted: false,
          taxable: null,
          tenant: {
            billingContactId: this.billingContact ? this.billingContact.id : null,
            guarantorContactIds: this.guarantorContacts.map(c => c.id),
            correspondenceContactId: this.correspondenceContact ? this.correspondenceContact.id : null,
            contactIds: this.tenantContacts.map(c => c.id),
            industryName: '',
            numberOfPersons: -1,
            type: 'UNDEFINED_TENANT_TYPE'
          },
          deposit: {
            type: 'DEPOSIT_TYPE_NO_DEPOSIT',
            address: {
              country: 'CH',
              state: '',
              zip: '',
              city: '',
              street: '',
              streetNumber: ''
            },
            amount: -1,
            dueDate: null,
            paidAt: null,
            paidOutAt: null,
            paidAmount: -1,
            clearingNumber: -1,
            bic: '',
            bankAccount: null,
            bankName: null,
            paidOutAmount: -1,
            note: '',
            validFrom: null,
            validUntil: null
          },
          indexData: {
            additionalCostsAdjustmentAllowed: false,
            belowInitialRentAllowed: false,
            maximumRate: null,
            referenceMonthForIndex: null,
            rentAdjustmentAdditionalBenefit: false,
            rentAdjustmentByMonths: null,
            rentAdjustmentByPoints: null,
            rentAdjustmentMonths: [],
            contractPercentImputedAtIndex: 100,
            rentAdjustmentNotification: {
              unit: 'RENT_ADJUSTMENT_NOTIFICATION_MONTHS',
              value: null
            }
          },
          renewalOptions: [],
          earlyRightOfTerminationOptions: [],
          rent: {
            advancePaymentValues: [],
            flatRateValues: [],
            additionalValues: [],
            dueDates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            additionalCosts: -1,
            coldRent: -1,
            coldRentM2PerYear: -1,
            heatingCosts: -1,
            additionalCostsBillingType: 'ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE',
            monthlyRentDueBy: 1,
            countryIndex: {
              baseDate: null,
              date: null,
              inflation: -1
            },
            referenceInterestRate: {
              date: null,
              rate: -10000,
              ratePossible: -10000
            },
            costIncreaseType: 'COST_INCREASE_TYPE_FLAT_RATE',
            costIncreaseDate: null,
            costIncreaseValue: -1,
            adjustments: [
              {
                calculationBasis: '',
                byDate: null,
                newItem: true,
                dueDates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                additionalCosts: -1,
                coldRent: -1,
                coldRentM2PerYear: -1,
                coldRentPerMonth: -1,
                heatingCosts: -1,
                additionalCostsBillingType: 'UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE',
                countryIndex: {
                  baseDate: null,
                  date: null,
                  inflation: -1,
                  possibleDate: null,
                  possibleInflation: -1
                },
                referenceInterestRate: {
                  date: null,
                  rate: -10000,
                  ratePossible: -10000
                },
                costIncreaseType: 'COST_INCREASE_TYPE_FLAT_RATE',
                costIncreaseDate: null,
                costIncreaseValue: -1,
                monthlyRentDueBy: 1,
                inArrears: false,
                advancePaymentValues: [],
                flatRateValues: [],
                additionalValues: [],
                retentionOfRents: [],
                documentIds: []
              }
            ]
          },
          terminationTenant: {
            earliestDate: null,
            possibleMonths: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
            periodInMonths: -1
          },
          terminationLandlord: {
            earliestDate: null,
            possibleMonths: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
            periodInMonths: -1
          },
          startingAt: null,
          endingAt: null,
          documentIds: [],
          terminatedBy: 'TERMINATED_BY_UNDEFINED',
          terminationType: 'TERMINATION_TYPE_UNDEFINED',
          terminationReason: [],
          liabilityEndsAt: null,
          nextPossibleMoveInAt: null,
          terminationCreatedAt: null,
          terminatedOn: null,
          terminationJustification: '',
          movedOutAt: null
        },
      tenancySet: false
    }
  },
  computed: {
    lastestTenant() {
      return this.lastTenancies.filter(x => x.id !== this.tenancyId).sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
    }
  },
  watch: {
    tenantContacts: function (newVal) {
      this.tenancy.tenant.contactIds = newVal.map(c => c.id)
    },
    listTenancies: function (newVal) {
      if (this.fromMassRentalProcess) {
        const tenancy = newVal.find(x => x.objectId === this.initialTenancy.objectId) || null
        if (tenancy) {
          this.tenancy = tenancy
        }
      }
    }
  },
  mounted() {
    this.tenancy.tenant.contactIds = this.tenantContacts.map(c => c.id)
    if (!this.fromMassRentalProcess) {
      this.loadOnboarding(this.object.buildingId)
    }
    this.loadingProperty = true
    ObjectApi.getPropertiesByBuildingIds([this.object.buildingId])
      .then(response => {
        this.property = response.properties[0]
        this.loadingProperty = false
        if (this.tenancyId === '' || this.tenancyId === null) {
          this.setDefaultValuesFromPreviousTenancy(this.lastestTenant[0])
        } else {
          this.tenancySet = true
        }
      })
    ContactApi.getContacts(this.object.ownerId)
      .then(response => {
        let contacts = response.persons.concat(response.companies).concat(this.objectOwnerContacts)
        // remove duplicates
        contacts = [...new Map(contacts.map(item => [item.id, item])).values()]
        this.setObjectOwnerContacts(contacts)
      })
    this.$emit('updated-tenancy', this.tenancy)
  },
  methods: {
    ...mapMutations('onboarding', ['setObjectOwnerContacts']),
    isSameDate(date1, date2) {
      if (date1 === null || date2 === null) {
        return !date1 && !date2
      } else {
        return date1.day === date2.day && date1.month === date2.month && date1.year === date2.year
      }
    },
    getEndDate() {
      let val = ''
      let date = null
      if (this.lastTenancies.length > 1) {
        if (this.lastTenancies[0].vacancy === false) {
          date = this.lastTenancies[0].endingAt
        } else {
          date = this.lastTenancies[1].endingAt
        }
      } else if (this.lastTenancies.length === 1) {
        date = this.lastTenancies[0].endingAt
      }
      val = this.$options.filters.objectToDate(date)
      return val
    },
    actionModal(src) {
      if (src === 0) {
        this.$refs.editDates.hide()
        this.$refs['object-tenancy'].errorStartAt = true
        setTimeout(() => {
          this.$refs['object-tenancy'].errorStartAt = false
        }, 3000)
      } else if (src === 1) {
        this.editPreviousTenancy()
      }
    },
    editPreviousTenancy() {
      let lastTenancy = null
      if (this.lastTenancies[0].vacancy === false) {
        lastTenancy = this.lastTenancies[0]
      } else {
        lastTenancy = this.lastTenancies[1]
      }
      const item1 = lastTenancy.endingAt
      const item2 = this.initialTenancy.startingAt
      lastTenancy.liabilityEndsAt = item1
      lastTenancy.endingAt = item2
      const date = new Date(this.$options.filters.objectToDateInput(item2)).getTime() - 86400000
      lastTenancy.endingAt = this.toObject(date)
      ObjectApi.updateTenancy(lastTenancy)
        .then((response) => {
          this.$emit('previous-tenancy-updated', response.tenancy)
          this.$refs.editDates.hide()
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          this.$refs.editDates.hide()
          Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
        })
    },
    tenancyUpdated(tenancy) {
      this.tenancy = tenancy
      this.$emit('updated-tenancy', tenancy)
    },
    isInvalid() {
      let isDateInvalid = false
      const tenancyArray = [this.initialTenancy]
      if (this.lastTenancies.length > 0) {
        this.lastTenancies.forEach(element => {
          if (element.vacancy === false) {
            tenancyArray.push(element)
          }
        })
      }
      if (this.tenancy.endingAt === null) {
        const startingAt = new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt))
        const afterwardsTenancies = this.lastTenancies.filter(c => c.objectId === this.tenancy.objectId && ((startingAt <= new Date(this.$options.filters.objectToDateInput(c.startingAt)) && !this.tenancy.isNewItem) || (startingAt < new Date(this.$options.filters.objectToDateInput(c.startingAt)) && this.tenancy.isNewItem)))
        const sameDateTenancies = this.tenancies.filter(c => this.tenancy.id !== c.id && c.objectId === this.tenancy.objectId && ((this.isSameDate(this.tenancy.startingAt, c.startingAt) && !this.tenancy.isNewItem) || (this.isSameDate(this.tenancy.startingAt, c.startingAt) && this.tenancy.isNewItem)) && (this.activated || (c.activated && new Date(this.$options.filters.objectToDateInput(this.object.debitUntil)) > startingAt)))
        if (sameDateTenancies.length > 0) {
          Vue.toasted.show(this.$t('message.savingErrors.sameDateTenanciesError'), { type: 'error' })
          isDateInvalid = true
        }
        if (afterwardsTenancies.length > 0) {
          if (afterwardsTenancies.filter(x => x.activated && !x.vacancy).length > 0) {
            Vue.toasted.show(this.$t('message.savingErrors.vacancyEndAtCanNotBeDeleted'), { type: 'error' })
            isDateInvalid = true
          }
        }
      }

      let isTenancyInvalid = true
      if (this.$refs['object-tenancy']) {
        isTenancyInvalid = this.$refs['object-tenancy']?.isInvalid()
      } else {
        Sentry.captureMessage('tenancy component undefined on isInvalid check', 'error')
      }

      const tenancies = tenancyArray.map(x => ({
        start: x.startingAt,
        end: x.endingAt
      }))
      const isOverlap = this.overlap(tenancies)
      if (isOverlap.overlap) {
        const startDateMoreThanDebitUntil = (!this.object?.debitUntil || new Date(this.$options.filters.objectToDateInput(this.object?.debitUntil)) < new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt)))
        if (this.$refs['object-tenancy']?.onlyErrorStartingAt === true && startDateMoreThanDebitUntil) {
          Sentry.captureMessage('show edit dates', 'debug')
          this.$refs.editDates.show()
        } else {
          Vue.toasted.show(this.$t('message.savingErrors.tenancyStartingOverLap'), { type: 'error' })
        }
        isDateInvalid = true
      }
      Sentry.captureMessage('add tenancy isTenancyInvalid=' + isTenancyInvalid + ' with isDateInvalid=' + isDateInvalid, 'debug')
      return isTenancyInvalid || isDateInvalid
    },
    // loadUpdatedTenantContacts() {
    //   const that = this
    //   ContactApi.getContacts(this.object.ownerId)
    //     .then(response => {
    //       that.setObjectOwnerContacts(response.persons.concat(response.companies))
    //     })
    //   return this.$refs['object-tenancy'].loadUpdatedTenantContacts()
    // },
    setDefaultValuesFromPreviousTenancy(previousTenant) {
      const newTenancy = JSON.parse(JSON.stringify(this.tenancy))
      newTenancy.contract.creationDate = this.toObject(new Date())
      newTenancy.rent.adjustments[0].advancePaymentValues = []
      newTenancy.rent.adjustments[0].flatRateValues = []
      newTenancy.rent.adjustments[0].additionalValues = []
      if (this.requestId !== '') {
        newTenancy.deposit.amount = this.object?.deposit?.amount
        newTenancy.rent.adjustments[0].coldRent = this.object.pricing.coldRent
        if (this.object?.pricing?.advancePaymentValues.length > 0) {
          for (let i = 0; i < this.object?.pricing.advancePaymentValues.length; i++) {
            if (this.property.advancePaymentRentComponents.flatMap(x => x.rentComponent).includes(this.object?.pricing.advancePaymentValues[i].type)) {
              newTenancy.rent.adjustments[0].advancePaymentValues.push(this.object?.pricing.advancePaymentValues[i])
            }
          }
        }
        if (this.object?.pricing?.flatRateValues.length > 0) {
          for (let i = 0; i < this.object?.pricing.flatRateValues.length; i++) {
            if (this.property.flatRateRentComponents.flatMap(x => x.rentComponent).includes(this.object?.pricing.flatRateValues[i].type)) {
              newTenancy.rent.adjustments[0].flatRateValues.push(this.object?.pricing.flatRateValues[i])
            }
          }
        }
        if (this.object?.pricing?.additionalValues.length > 0) {
          for (let i = 0; i < this.object?.pricing.additionalValues.length; i++) {
            if (this.property.additionalRentComponents.flatMap(x => x.rentComponent).includes(this.object?.pricing.additionalValues[i].type)) {
              newTenancy.rent.adjustments[0].additionalValues.push(this.object?.pricing.additionalValues[i])
            }
          }
        }
      }
      if (previousTenant) {
        if (previousTenant.rent.adjustments.length > 0) {
          const previousAdjustment = JSON.parse(JSON.stringify(previousTenant.rent.adjustments.sort(function (a, b) {
            return b.byDate - a.byDate
          })[0]))
          if (this.requestId === '') {
            this.property.advancePaymentRentComponents.forEach(element => {
              if (element.inactive === false) {
                const item = previousAdjustment.advancePaymentValues.find(e => e.type === element.rentComponent)
                newTenancy.rent.adjustments[0].advancePaymentValues.push({
                  type: element.rentComponent,
                  value: item ? item.value : null
                })
              }
            })
            this.property.flatRateRentComponents.forEach(element => {
              if (element.inactive === false) {
                const item = previousAdjustment.flatRateValues.find(e => e.type === element.rentComponent)
                newTenancy.rent.adjustments[0].flatRateValues.push({
                  type: element.rentComponent,
                  value: item ? item.value : null
                })
              }
            })
            this.property.additionalRentComponents.forEach(element => {
              if (element.inactive === false) {
                const item = previousAdjustment.additionalValues.find(e => e.type === element.rentComponent)
                newTenancy.rent.adjustments[0].additionalValues.push({
                  type: element.rentComponent,
                  value: item ? item.value : null
                })
              }
            })
          }
          newTenancy.rent.adjustments[0].dueDates = previousAdjustment.dueDates
          if (newTenancy.rent.adjustments[0].calculationBasis === 'TENANCY_CALCULATION_BASIS_M2_YEAR') {
            newTenancy.rent.adjustments[0].coldRentM2PerYear = previousAdjustment.coldRentM2PerYear
          }
          if (this.requestId !== '' && this.object.pricing.coldRent > 0 && this.object.pricing.unit !== 'ON_REQUEST') {
            newTenancy.rent.adjustments[0].coldRent = this.object.pricing.coldRent
            newTenancy.rent.adjustments[0].coldRentPerMonth = (this.object.pricing.coldRent * (previousAdjustment.dueDates.length)) / 12
            newTenancy.rent.adjustments[0].coldRentM2PerYear = this.calculateColdRentM2PerYear(previousAdjustment.dueDates, this.object.pricing.coldRent) || 0
            if (this.object.pricing.unit === 'M2_MONTH' || this.object.pricing.unit === 'M2_YEAR') {
              newTenancy.rent.adjustments[0].calculationBasis = 'TENANCY_CALCULATION_BASIS_M2_YEAR'
            } else if (this.object.pricing.unit === 'FIXED') {
              newTenancy.rent.adjustments[0].calculationBasis = 'TENANCY_CALCULATION_BASIS_DEFAULT'
            }
          } else {
            newTenancy.rent.adjustments[0].coldRent = previousAdjustment.coldRent
            newTenancy.rent.adjustments[0].coldRentPerMonth = previousAdjustment.coldRentPerMonth
            newTenancy.rent.adjustments[0].calculationBasis = previousAdjustment.calculationBasis
          }
          newTenancy.rent.adjustments[0].additionalCostsBillingType = previousAdjustment.additionalCostsBillingType
          newTenancy.rent.adjustments[0].countryIndex.baseDate = previousAdjustment.countryIndex.baseDate
          if (previousTenant.vacancy === true && this.lastestTenant.length > 1 && this.lastestTenant[1].terminationType === 'TERMINATION_TYPE_OFF_TERM') {
            const lastAdjustement = this.lastestTenant[1].rent.adjustments.sort(function (a, b) {
              return b.byDate - a.byDate
            })[0]
            const lastTenant = this.lastestTenant[1]
            if (this.requestId === '') {
              newTenancy.rent.adjustments[0].coldRentPerMonth = lastAdjustement.coldRentPerMonth
              newTenancy.rent.adjustments[0].coldRent = lastAdjustement.coldRent
              newTenancy.rent.adjustments[0].coldRentM2PerYear = lastAdjustement.coldRentM2PerYear
              newTenancy.rent.adjustments[0].calculationBasis = lastAdjustement.calculationBasis
              newTenancy.rent.adjustments[0].additionalCostsBillingType = lastAdjustement.additionalCostsBillingType
            }
            newTenancy.rent.adjustments[0].retentionOfRents = lastAdjustement.retentionOfRents

            newTenancy.rent.adjustments[0].referenceInterestRate.rate = lastAdjustement.referenceInterestRate.rate
            newTenancy.rent.adjustments[0].referenceInterestRate.date = lastAdjustement.referenceInterestRate.date
            newTenancy.rent.adjustments[0].referenceInterestRate.ratePossible = lastAdjustement.referenceInterestRate.ratePossible
            newTenancy.rent.adjustments[0].countryIndex.date = lastAdjustement.countryIndex.date
            newTenancy.rent.adjustments[0].countryIndex.baseDate = lastAdjustement.countryIndex.baseDate
            newTenancy.rent.adjustments[0].costIncreaseType = lastAdjustement.costIncreaseType
            newTenancy.rent.adjustments[0].costIncreaseDate = lastAdjustement.costIncreaseDate
            newTenancy.rent.adjustments[0].costIncreaseValue = lastAdjustement.costIncreaseValue
            newTenancy.rent.adjustments[0].dueDates = lastAdjustement.dueDates
            newTenancy.rent.adjustments[0].countryIndex.inflation = lastAdjustement.countryIndex.inflation
            newTenancy.terminationLandlord = lastTenant.terminationLandlord
            newTenancy.terminationTenant = lastTenant.terminationTenant
          }
          if (previousTenant.terminationType === 'TERMINATION_TYPE_OFF_TERM') {
            if (this.requestId === '') {
              newTenancy.rent.adjustments[0].coldRentPerMonth = previousAdjustment.coldRentPerMonth
              newTenancy.rent.adjustments[0].coldRent = previousAdjustment.coldRent
              newTenancy.rent.adjustments[0].coldRentM2PerYear = previousAdjustment.coldRentM2PerYear
              newTenancy.rent.adjustments[0].calculationBasis = previousAdjustment.calculationBasis
              newTenancy.rent.adjustments[0].additionalCostsBillingType = previousAdjustment.additionalCostsBillingType
            }
            newTenancy.rent.adjustments[0].retentionOfRents = previousAdjustment.retentionOfRents

            newTenancy.rent.adjustments[0].referenceInterestRate.rate = previousAdjustment.referenceInterestRate.rate
            newTenancy.rent.adjustments[0].referenceInterestRate.date = previousAdjustment.referenceInterestRate.date
            newTenancy.rent.adjustments[0].referenceInterestRate.ratePossible = previousAdjustment.referenceInterestRate.ratePossible
            newTenancy.rent.adjustments[0].countryIndex.date = previousAdjustment.countryIndex.date
            newTenancy.rent.adjustments[0].countryIndex.baseDate = previousAdjustment.countryIndex.baseDate
            newTenancy.rent.adjustments[0].costIncreaseType = previousAdjustment.costIncreaseType
            newTenancy.rent.adjustments[0].costIncreaseDate = previousAdjustment.costIncreaseDate
            newTenancy.rent.adjustments[0].costIncreaseValue = previousAdjustment.costIncreaseValue
            newTenancy.rent.adjustments[0].dueDates = previousAdjustment.dueDates
            newTenancy.rent.adjustments[0].countryIndex.inflation = previousAdjustment.countryIndex.inflation
            newTenancy.terminationLandlord = previousTenant.terminationLandlord
            newTenancy.terminationTenant = previousTenant.terminationTenant
          }
          if (previousTenant.vacancy === true && this.lastestTenant.length > 1) {
            newTenancy.terminationLandlord = JSON.parse(JSON.stringify(this.lastestTenant[1].terminationLandlord))
            newTenancy.terminationLandlord.earliestDate = null
            newTenancy.terminationTenant = JSON.parse(JSON.stringify(this.lastestTenant[1].terminationTenant))
            newTenancy.terminationTenant.earliestDate = null
          } else {
            newTenancy.terminationLandlord = JSON.parse(JSON.stringify(previousTenant.terminationLandlord))
            newTenancy.terminationLandlord.earliestDate = null
            newTenancy.terminationTenant = JSON.parse(JSON.stringify(previousTenant.terminationTenant))
            newTenancy.terminationTenant.earliestDate = null
          }
        }
        newTenancy.rent.additionalCostsBillingType = previousTenant.rent.additionalCostsBillingType
        let tempDate = new Date(this.$options.filters.objectToDateInput(previousTenant.endingAt))
        tempDate = tempDate.setDate(tempDate.getDate() + 1)
        const startingDate = this.toObject(tempDate)
        if (!previousTenant.vacancy) {
          newTenancy.startingAt = { ...newTenancy.startingAt, ...startingDate }
        }
        if (this.$refs['object-vacancy-' + this.object.id]) {
          this.$refs['object-vacancy-' + this.object.id].getDatesFromObjects()
        }
        newTenancy.rent.adjustments[0].byDate = { ...newTenancy.rent.adjustments[0].byDate, ...startingDate }
        if (previousTenant.contract.rentType) {
          newTenancy.contract.rentType = previousTenant.contract.rentType
        }
        if (previousTenant.contract.typeOfUse) {
          newTenancy.contract.typeOfUse = previousTenant.contract.typeOfUse
          if (previousTenant.contract.typeOfUse === 'TYPE_OF_USE_PARKING_EXTERNAL') {
            newTenancy.taxable = !this.property.opted
          }
        }
      }
      const tenancy = JSON.parse(JSON.stringify(newTenancy))
      this.tenancySet = true
      this.tenancy = tenancy
    },
    calculateColdRentM2PerYear(dueDates, coldRent) {
      const duedate = dueDates.length > 0 ? dueDates.length : 1
      let la = 1
      let fa = 1
      if (this.object && (this.object.category === 'HOUSE' || this.object.category === 'APARTMENT')) {
        la = this.object.characteristics &&
        this.object.characteristics.areas &&
        this.object.characteristics.areas.livingArea !== -1
          ? this.object.characteristics.areas.livingArea
          : 1
        return Math.round(duedate * coldRent / la)
      } else {
        fa = this.object && this.object.characteristics &&
        this.object.characteristics.areas &&
        this.object.characteristics.areas.effectiveArea !== -1
          ? this.object.characteristics.areas.effectiveArea
          : 1
        return Math.round(duedate * coldRent / fa)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.readonly-wrapper {
  .inputs-container {
    overflow-y: scroll;
    height: calc(100vh - 100px);
  }

  .item {
    cursor: pointer;
  }

  :deep(.details-row-bg-1) {
    background-color: $color-table-row-2;
  }

  :deep(.details-row-bg-2) {
    background-color: $color-table-row-1;
  }

  :deep(label) {
    width: 100%;
  }

  :deep(.is-disabled) {
    .sort button {
      display: block !important;
    }

    button:not(.sort):not(.page-link) {
      display: none;
    }

    .multiselect, .custom-checkbox {
      pointer-events: none;

      .multiselect__select, .multiselect__placeholder {
        display: none;
      }
    }

    .filter-inputs .multiselect, .custom-checkbox {
      pointer-events: auto !important;

      .multiselect__select, .multiselect__placeholder {
        display: block !important;
      }
    }

    .basic {
      input {
        opacity: 1;
        border: thin solid #ced4da;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
        margin-bottom: 0;
        pointer-events: initial;
        padding-left: 0.2rem;
        background: none;
      }
    }

    .multi-select-value {
      line-height: 1.5;
    }
  }

  :deep(.collapse-title) {
    &.collapsed {
      h5 {
        background-image: url('../assets/arrow_down.svg');
      }
    }

    h5 {
      background-image: url('../assets/arrow_up.svg');
      background-repeat: no-repeat;
      font-size: large;
      font-weight: 500 !important;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      background-position: right;
    }
  }

  :deep(.global-card) {
    margin-bottom: 0;

    & > .card-body {
      -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    }

    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    &.no-top-border {
      border-top: 0;
    }
  }
}

</style>
