<template>
  <section class="w-100">
    <div class="row m-0">
      <div
        class="col-sm-12 col-md-3">
        <label for="type">{{ $t('message.onBoarding.buildings.objects.tenancies.retentionOfRent.type.title') }}</label>
        <coozzy-form-select
          v-if="!readOnly"
          id="type"
          v-model="type"
          :disabled="disabledField">
          <option
            v-for="(item, indexItem) in actualTypes"
            :key="indexItem"
            :value="item">
            {{ $t(`message.onBoarding.buildings.objects.tenancies.retentionOfRent.type.${item}`) }}
          </option>
        </coozzy-form-select>
        <span
          v-else
          class="show-data">
          {{ type === '' || type === null ? '-' : $t(`message.onBoarding.buildings.objects.tenancies.retentionOfRent.type.${type}`) }}
        </span>
      </div>
      <div
        class="col-sm-12 col-md-3">
        <label for="inPercent">{{ $t('message.onBoarding.buildings.objects.tenancies.retentionOfRent.inPercent.title') }}</label>
        <coozzy-form-select
          v-if="!readOnly"
          id="inPercent"
          v-model="inPercent"
          :disabled="disabledField">
          <option :value="true">
            {{ $t(`message.onBoarding.buildings.objects.tenancies.retentionOfRent.inPercent.true`) }}
          </option>
          <option :value="false">
            {{ $t(`message.onBoarding.buildings.objects.tenancies.retentionOfRent.inPercent.false`) }}
          </option>
        </coozzy-form-select>
        <span
          v-else
          class="show-data">
          {{ inPercent === '' || inPercent === null ? '-' : $t(`message.onBoarding.buildings.objects.tenancies.retentionOfRent.inPercent.${inPercent}`) }}
        </span>
      </div>
      <div class="col-sm-12 col-md-3">
        <coozzy-form-input-numeric
          v-model="actual"
          type="number"
          :allow-negative="true"
          :is-read-only="readOnly"
          :is-decimal="true"
          :is-only-decimal="true"
          :disabled="disabledField"
          :filter="'formatNumber'"
          :name="$t('message.onBoarding.buildings.objects.tenancies.retentionOfRent.effective')" />
      </div>
      <div class="col-sm-12 col-md-3">
        <coozzy-form-input-numeric
          v-model="possible"
          type="number"
          :allow-negative="true"
          :is-read-only="readOnly"
          :is-decimal="true"
          :is-only-decimal="true"
          :disabled="disabledField"
          :filter="'formatNumber'"
          :name="$t('message.onBoarding.buildings.objects.tenancies.retentionOfRent.possible')" />
      </div>
      <div class="col-sm-12 col-md-3">
        <coozzy-form-input
          v-model="remark"
          :is-read-only="readOnly"
          :disabled="disabledField"
          :name="$t('message.onBoarding.buildings.objects.tenancies.retentionOfRent.remark')" />
      </div>
      <div class="col-sm-12 col-md-3">
        <div
          v-if="!readOnly && !disabledField"
          class="col-md-1">
          <label
            for="delete"
            class="text-white">delete</label>
          <coozzy-button
            design="alert"
            class="align-self-center"
            @click="openConfirmationModal()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </div>
    </div>
    <b-modal
      :id="'retention-confirmationModal'"
      ref="retention-confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.titleRetention')">
      <template>
        <div class="col">
          <p>{{ $t('message.onBoarding.deleteModals.bodyRetention') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('retention-confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteRetentionClicked()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>
<script>
import { routeChecks } from '@/mixins/routeChecks'
import { onboarding } from '@/mixins/onboarding'
import { validation } from '@/mixins/validation'

export default {
  name: 'AdjustmentRetention',
  mixins: [routeChecks, onboarding, validation],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    retention: {
      type: Object,
      default: null
    },
    tenancy: {
      type: Object,
      default: null
    },
    adjustment: {
      type: Object,
      default: null
    },
    disabledField: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dates: {
        startingAt: null,
        endingAt: null
      },
      types: ['RETENTION_OF_RENT_INSUFFICIENT_GROSS_RENT',
        'RETENTION_OF_RENT_INSUFFICIENT_NET_RETURN',
        'RETENTION_OF_RENT_VALUE_ENHANCING_INVESTMENTS',
        'RETENTION_OF_RENT_ADJUSTMENT_NEIGHBORHOOD']
    }
  },
  computed: {
    actualTypes() {
      const selectedTypes = this.adjustment.retentionOfRents.map(z => z.type)
      return this.types.filter(x => !selectedTypes.includes(x) || x === this.type)
    },
    actual: {
      get() {
        return this.retention.actual
      },
      set(value) {
        const newRetention = this.retention
        newRetention.actual = value
        console.log({ value, newRetention })
        this.$emit('change-retention', newRetention)
      }
    },
    remark: {
      get() {
        return this.retention.remark
      },
      set(value) {
        const newRetention = this.retention
        newRetention.remark = value
        this.$emit('change-retention', newRetention)
      }
    },
    possible: {
      get() {
        return this.retention.possible
      },
      set(value) {
        const newRetention = this.retention
        newRetention.possible = value
        this.$emit('change-retention', newRetention)
      }
    },
    type: {
      get() {
        return this.retention.type
      },
      set(value) {
        const newRetention = this.retention
        newRetention.type = value
        this.$emit('change-retention', newRetention)
      }
    },
    inPercent: {
      get() {
        return this.retention.inPercent
      },
      set(value) {
        const newRetention = this.retention
        newRetention.inPercent = value
        this.$emit('change-retention', newRetention)
      }
    }
  },
  mounted() {
    if (this.type === '') {
      this.type = this.actualTypes.length > 0 ? this.actualTypes[0] : ''
    }
  },
  methods: {
    openConfirmationModal() {
      this.$refs['retention-confirmationModal'].show()
    },
    deleteRetentionClicked() {
      this.$emit('delete-retention')
      this.$refs['retention-confirmationModal'].hide()
    },
    isInvalid() {
      let valid = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        valid = false
      }
      return !valid
    }
  },
  validations: {
  }
}
</script>
