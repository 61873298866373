<template>
  <section>
    <div class="body-of-child">
      <div>
        <div class="row m-0">
          <div class="my-5">
            <div>
              <h1>{{ $t('message.staticPages.termAndPrivacy.title') }}</h1>
              <hr align="left">
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titleone') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.onetext')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titletwo') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12  text">
                <!-- eslint-disable vue/no-v-html -->
                <p
                  class="sub-title"
                  v-html="$t('message.staticPages.termAndPrivacy.titletwobody.atitle')" />
                <p v-html="$t('message.staticPages.termAndPrivacy.titletwobody.atext')" />
                <p
                  class="sub-title"
                  v-html="$t('message.staticPages.termAndPrivacy.titletwobody.btitle')" />
                <p v-html="$t('message.staticPages.termAndPrivacy.titletwobody.btext')" />
                <p
                  class="sub-title"
                  v-html="$t('message.staticPages.termAndPrivacy.titletwobody.ctitle')" />
                <p v-html="$t('message.staticPages.termAndPrivacy.titletwobody.ctext')" />
                <p
                  class="sub-title"
                  v-html="$t('message.staticPages.termAndPrivacy.titletwobody.dtitle')" /><p v-html="$t('message.staticPages.termAndPrivacy.titletwobody.dtext')" />
                <p
                  class="sub-title"
                  v-html="$t('message.staticPages.termAndPrivacy.titletwobody.etitle')" />
                <p v-html="$t('message.staticPages.termAndPrivacy.titletwobody.etext')" />
                <p
                  class="sub-title"
                  v-html="$t('message.staticPages.termAndPrivacy.titletwobody.ftitle')" />
                <p v-html="$t('message.staticPages.termAndPrivacy.titletwobody.ftext')" />
                <p
                  class="sub-title"
                  v-html="$t('message.staticPages.termAndPrivacy.titletwobody.gtitle')" />
                <p v-html="$t('message.staticPages.termAndPrivacy.titletwobody.gtext')" />
                <p
                  class="sub-title"
                  v-html="$t('message.staticPages.termAndPrivacy.titletwobody.htitle')" />
                <p v-html="$t('message.staticPages.termAndPrivacy.titletwobody.htext')" />
                <p
                  class="sub-title"
                  v-html="$t('message.staticPages.termAndPrivacy.titletwobody.ititle')" />
                <p v-html="$t('message.staticPages.termAndPrivacy.titletwobody.jtitle')" />
                <p
                  class="sub-title"
                  v-html="$t('message.staticPages.termAndPrivacy.titletwobody.jtitle')" />
                <p v-html="$t('message.staticPages.termAndPrivacy.titletwobody.jtext')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titlethree') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12  text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.textthree')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titlefour') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12  text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.textfour')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titlefive') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.textfive')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titlesix') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.textsix')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titleseven') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.textseven')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titleeight') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.texteight')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titlenine') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.textnine')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                10. {{ $t('message.staticPages.termAndPrivacy.titleteen') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.textteen')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titleeleven') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.texteleven')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.termAndPrivacy.titletwelve') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.termAndPrivacy.texttwelve')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text marginbottom15 margintop35">
                <p>
                  <!-- eslint-disable vue/no-v-html -->
                  <b v-html="$t('message.staticPages.termAndPrivacy.date')" />
                  <!-- eslint-enable vue/no-v-html -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <coozzy-footer />
  </section>
</template>

<script>

import { language } from '../mixins/language'

export default {
  name: 'PrivacyPolicyView',
  mixins: [language]
}
</script>

<style lang="scss" scoped>
  .body-of-child {
    background-color: $color-text-white;
  }
  hr {
    border: 1px solid $color-primary-dark;
    width: 30px;
  }
  p a {
    color: $color-primary-dark;
  }
  h4 {
    line-height: 29px;
    font-weight: 300;
    font-size: 23px;
    color: $color-text-grey;
  }
</style>
