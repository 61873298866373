<template>
  <section>
    <coozzy-page-title :title="$t('message.navigation.realEstate')" />
    <div class="row">
      <div class="col-sm-12 col-md-4 mb-3">
        <ais-instant-search
          v-if="searchClient"
          :search-client="searchClient"
          :routing="routing"
          index-name="building">
          <!-- eslint-disable vue/attribute-hyphenation -->
          <ais-configure
            :hitsPerPage="10"
            :filters="filterQuery" />
          <!-- eslint-enable vue/attribute-hyphenation -->
          <ais-autocomplete>
            <template slot-scope="{ indices, refine }">
              <vue-autosuggest
                v-model="buildingTypedSearch"
                :suggestions="indicesToSuggestions(indices)"
                :input-props="{
                  placeholder: $t('message.generic.search'),
                  class: 'custom-select',
                  id: 'autosuggest__input',
                }"
                @input="onBuildingInputChange(refine, buildingTypedSearch)"
                @selected="onBuildingSelected">
                <template slot-scope="{ suggestion }">
                  {{ suggestion.item.name }} - {{ suggestion.item.address.street }} {{
                    suggestion.item.address.streetNumber }}, {{ suggestion.item.address.zip }} {{
                    suggestion.item.address.city }}
                </template>
              </vue-autosuggest>
            </template>
          </ais-autocomplete>
        </ais-instant-search>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-4 mb-3 pr-0">
        <div
          class="marker-sidebar mr-3">
          <b-tabs
            v-model="tabIndex"
            class="h-100">
            <!-- Building base data -->
            <b-tab
              class="h-100"
              lazy>
              <template #title>
                <router-link
                  :to="getBuildingRoute('base')">
                  <span>{{ $t('message.manageBuilding.mapsView.menu.baseData') }}</span>
                </router-link>
              </template>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.generic.name') }}</span>
                </div>
                <div class="col-8">
                  <span class="font-weight-bold float-right">
                    {{ (selectedBuilding !== null ? selectedBuilding.name : '') |displayOptionalValue }}
                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.onBoarding.buildings.address') }}</span>
                </div>
                <div class="col-8">
                  <span class="font-weight-bold float-right">
                    <address-text
                      :address="selectedBuilding ? selectedBuilding.address : null"
                      :show-country="false"
                      :pull-right="true" />
                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.manageBuilding.floors') }}</span>
                </div>
                <div class="col-8">
                  <span class="font-weight-bold float-right">
                    {{ (selectedBuilding !== null ? selectedBuilding.floors : '') |displayOptionalValue }}
                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.onBoarding.buildings.objects.titleObjects') }}</span>
                </div>
                <div class="col-8">
                  <span class="data-building">
                    {{ (selectedBuilding !== null ? selectedBuilding.objectIds.length : '-') }}
                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.manageBuilding.buildingArea') }}</span>
                </div>
                <div class="col-8">
                  <span class="data-building">
                    {{ (selectedBuilding !== null && selectedBuilding.buildingArea !== '' && selectedBuilding.buildingArea !== -1 ? selectedBuilding.buildingArea : '-') }}

                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.onBoarding.buildings.buildingZone') }}</span>
                </div>
                <div class="col-8">
                  <span class="data-building">
                    {{ getBuildingZone }}
                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.onBoarding.buildings.landRegisterNo') }}</span>
                </div>
                <div class="col-8">
                  <span class="data-building">
                    {{ getPlotsNumber }}
                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.advertisement.plotArea') }}</span>
                </div>
                <div class="col-8">
                  <span class="data-building">
                    {{ parseFloat(getBuildingArea )| formatArea }}
                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span
                    class="label-building">{{ $t('message.tableColumnHeadings.constructionYear' ) }}</span>
                </div>
                <div class="col-8">
                  <span class="data-building">
                    {{ (selectedBuilding !== null && selectedBuilding.constructionYear !== 0 ? selectedBuilding.constructionYear : '') | displayOptionalValue }}
                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.onBoarding.buildings.renovationYear') }}</span>
                </div>
                <div class="col-8">
                  <span class="data-building">
                    {{ (selectedBuilding !== null && selectedBuilding.renovationYear !== 0 ? selectedBuilding.renovationYear : '') | displayOptionalValue }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <coozzy-button
                    v-if="selectedBuilding"
                    size="normal"
                    design="green"
                    class="float-right mt-3 col-4"
                    @click="navigateToBuilding"
                    @click.middle="navigateToBuildingMiddle">
                    {{ $t('message.manageBuilding.mapsView.showBuilding') }}
                  </coozzy-button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <coozzy-button
                    v-if="selectedBuilding"
                    size="normal"
                    design="prop-green"
                    class="float-right mt-3 col-4"
                    @click="navigateToGoogleMaps">
                    {{ $t('message.manageBuilding.mapsView.direction') }}
                  </coozzy-button>
                </div>
              </div>
            </b-tab>
            <!-- Object list -->
            <b-tab
              lazy>
              <template #title>
                <router-link
                  :to="getBuildingRoute('objects')">
                  <span>{{ $t('message.manageBuilding.mapsView.menu.objects') }}</span>
                </router-link>
              </template>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.generic.name') }}</span>
                </div>
                <div class="col-8">
                  <span class="font-weight-bold float-right">
                    {{ (selectedBuilding !== null ? selectedBuilding.name : '') |displayOptionalValue }}
                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.generic.address') }} </span>
                </div>
                <div class="col-8">
                  <span class="font-weight-bold float-right">
                    <address-text
                      :address="selectedBuilding ? selectedBuilding.address : null"
                      :show-country="false"
                      :pull-right="true" />
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 p-0">
                  <b-table
                    hover
                    bordered
                    class="mb-0 building-tables"
                    :busy="loadingObjects"
                    :fields="objectFields"
                    :items="paginatedList">
                    <!-- Busy state -->
                    <div
                      slot="table-busy"
                      class="text-center text-danger my-2">
                      <coozzy-spinner />
                    </div>

                    <!-- Header -->
                    <template
                      #head(objects)>
                      <span>{{ $t('message.tableColumnHeadings.objects') }}</span>
                    </template>
                    <template
                      #head(rooms)>
                      <span>{{ $t('message.tableColumnHeadings.rooms') }}</span>
                    </template>
                    <template
                      #head(surface)>
                      <span>{{ $t('message.tableColumnHeadings.mapArea') }}</span>
                    </template>
                    <template
                      #head(tenants)>
                      <span>{{ $t('message.tableColumnHeadings.tenant') }}</span>
                    </template>

                    <!-- Body -->
                    <template
                      #cell(objects)="data">
                      {{ data.item.name | displayOptionalValue }}
                    </template>
                    <template
                      #cell(rooms)="data">
                      {{ data.item.characteristics.rooms | displayOptionalValue }}
                    </template>
                    <template
                      #cell(surface)="data">
                      {{ (data.item.characteristics && data.item.characteristics.areas && data.item.characteristics.areas.livingArea > -1 ? parseFloat(data.item.characteristics.areas.livingArea) : data.item.characteristics.areas.effectiveArea > -1 ? parseFloat(data.item.characteristics.areas.effectiveArea) : '') | formatArea }}
                    </template>
                    <template #cell(tenants)="data">
                      {{ getTenantsName(data.item.id) }}
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    align="center"
                    class="mt-3 mb-3"
                    :total-rows="selectedObjects.length"
                    :per-page="nbrPerPage" />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <coozzy-button
                    v-if="selectedBuilding"
                    size="normal"
                    design="green"
                    class="float-right mt-3"
                    @click="navigateToObjects"
                    @click.middle="navigateToObjectsMiddle">
                    {{ $t('message.manageBuilding.mapsView.showObjects') }}
                  </coozzy-button>
                </div>
              </div>
            </b-tab>
            <!-- Network partner list -->
            <b-tab
              lazy>
              <template #title>
                <router-link
                  :to="getBuildingRoute('partner')">
                  <span>{{ $t('message.tableColumnHeadings.partner') }}</span>
                </router-link>
              </template>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.generic.name') }}</span>
                </div>
                <div class="col-8">
                  <span class="font-weight-bold float-right">
                    {{ (selectedBuilding !== null ? selectedBuilding.name : '') | displayOptionalValue }}
                  </span>
                </div>
              </div>
              <div class="row data-row-building">
                <div class="col-4">
                  <span class="label-building">{{ $t('message.generic.address') }} </span>
                </div>
                <div class="col-8">
                  <span class="font-weight-bold float-right">
                    <address-text
                      :address="selectedBuilding ? selectedBuilding.address : null"
                      :show-country="false"
                      :pull-right="true" />
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 p-0">
                  <b-table
                    hover
                    bordered
                    :busy="loadingPartners"
                    class="mb-0 building-tables"
                    :fields="partnerFields"
                    :items="buildingContact">
                    <!-- Busy state -->
                    <div
                      slot="table-busy"
                      class="text-center text-danger my-2">
                      <coozzy-spinner />
                    </div>
                    <!-- Header -->
                    <template
                      #head(companyName)>
                      <span>{{ $t('message.generic.companyName') }}</span>
                    </template>

                    <!-- Body -->
                    <template
                      #cell(companyName)="data">
                      <template
                        v-if="data.item.id && parseInt(data.item.id) !== accountId">
                        <span
                          v-if="data.item.subType === 'OWNER'">
                          <img
                            height="20px"
                            src="/img/propbase-icon.png"
                            alt="prop-base">
                        </span>
                        <template v-for="(scop, index) in showpartnerIcon(data.item.id)">
                          <span
                            v-if="scop === 'MANAGEMENT_SCOPE'"
                            :key="index">
                            <img
                              height="20px"
                              src="/img/adminbase-icon.png"
                              alt="prop-base">
                          </span>
                          <span
                            v-if="scop === 'ACCOUNTING_SCOPE'"
                            :key="index">
                            <img
                              height="20px"
                              src="/img/financebase-icon.png"
                              alt="finance-base">
                          </span>
                          <span
                            v-if="scop === 'ASSET_SCOPE'"
                            :key="index">
                            <img
                              height="20px"
                              src="/img/assetbase-icon.png"
                              alt="prop-base">
                          </span>
                          <span
                            v-if="scop === 'MARKETING_SCOPE'"
                            :key="index">
                            <img
                              height="20px"
                              src="/img/marketbase-icon.png"
                              alt="prop-base">
                          </span>
                        </template>
                        {{ data.item.company !== null ? data.item.company.name : '-' }}
                      </template>
                      <template
                        v-else-if="data.item.id && parseInt(data.item.id) === accountId">
                        <span
                          v-if="data.item.scope === 'MANAGEMENT_SCOPE'">
                          <img
                            height="20px"
                            src="/img/adminbase-icon.png"
                            alt="prop-base">
                        </span>
                        <span
                          v-if="data.item.scope === 'ACCOUNTING_SCOPE'">
                          <img
                            height="20px"
                            src="/img/financebase-icon.png"
                            alt="finance-base">
                        </span>
                        <span
                          v-if="data.item.scope === 'ASSET_SCOPE'">
                          <img
                            height="20px"
                            src="/img/assetbase-icon.png"
                            alt="prop-base">
                        </span>
                        <span
                          v-if="data.item.scope === 'MARKETING_SCOPE'">
                          <img
                            height="20px"
                            src="/img/marketbase-icon.png"
                            alt="prop-base">
                        </span>
                        {{ data.item.company !== null ? data.item.company.name : '-' }}
                      </template>
                    </template>
                  </b-table>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="col-sm-8 col-md-8 mb-3 pl-0">
        <gmap-map
          v-show="!loading && map"
          ref="gmap"
          :center="map.center"
          :zoom="map.zoom"
          :options="map.options"
          style="width:100%; height: calc(100vh - 210px);"
          @bounds_changed="loadAppearingObjects">
          <GmapCluster
            :zoom-on-click="true"
            :average-center="true"
            :max-zoom="14"
            :animation="2">
            <gmap-marker
              v-for="(m, index) in markers"
              :key="index"
              ref="marker"
              :label="m.type === 'multiple' ? {'text': '' + m.buildingList.length, 'color': '#ff5f5b', 'fontSize' : '20px'} : ''"
              :position="m.position"
              :animation="2"
              :shape="{type: 'circle'}"
              :icon="{ url: m.type === 'multiple' ? '../../pin3.png' : currentMarker !== index ? '../../pin.png' : '../../pin2.png'}"
              @click="toggleInfoWindow(m.building, index, m.type,m)">
              <gmap-info-window
                v-if="m.type === 'multiple'"
                class="test-info"
                :disable-auto-pan="false"
                :opened="currentMarker === index">
                <img
                  v-for="(b, index2) in m.buildingList"
                  :key="b.id"
                  :src="selectedBuilding === null || selectedBuilding.id !== b.id ? '../../pin.png' : '../../pin2.png'"
                  class="cursor-pointer"
                  :class="index2 === 0 ? 'mr-3' : ''"
                  alt="Marker"
                  @click="toggleInfoWindow(b, index, 'single',m)">
              </gmap-info-window>
            </gmap-marker>
          </GmapCluster>
        </gmap-map>
      </div>
    </div>
  </section>
</template>

<script>
import AddressText from '../framework/components/misc/AddressText'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import ObjectApi from '../misc/apis/ObjectApi'
import UserApi from '../misc/apis/UserApi'
import ShareApi from '../misc/apis/ShareApi'
import ContactApi from '@/misc/apis/ContactApi'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import { VueAutosuggest } from 'vue-autosuggest'
import Vue from 'vue'
import { algolia } from '@/mixins/algolia'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import { user } from '@/mixins/user'
import { routeChecks } from '@/mixins/routeChecks'
import { address } from '@/mixins/address'

export default {
  name: 'BuildingsMapView',
  components: { CoozzyPageTitle, VueAutosuggest, CoozzySpinner, CoozzyButton, AddressText },
  mixins: [algolia, user, routeChecks, user, address],
  metaInfo() {
    return {
      title: this.$t('message.navigation.realEstate')
    }
  },
  props: {
    view: {
      type: String,
      default: 'base'
    },
    elementSelected: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tabIndex: 0,
      nbrPerPage: 5,
      currentPage: 1,
      loading: false,
      currentTenancyList: [],
      loadingObjects: false,
      loadingPartners: false,
      currentMarker: -1,
      selectedObjects: [],
      contactsList: [],
      suggestedBuildings: [],
      buildingListPlot: [],
      buildingTypedSearch: '',
      BuildingInputProps: {
        id: 'autosuggest__input',
        placeholder: 'Gebäude suchen',
        class: 'custom-select'
      },
      map: {
        center: { lat: 46.7, lng: 8.5 },
        zoom: 8,
        options: {
          tilt: 0,
          mapTypeControl: true,
          streetViewControl: true,
          rotateControl: false,
          mapTypeId: 'terrain'
        }
      },
      timeoutId: '',
      newObject: {
        ewid: '',
        address: {
          country: 'CH',
          state: '',
          city: '',
          zip: '',
          street: '',
          streetNumber: '',
          streetAddition: '',
          subUnit: ''
        }
      },
      newMarker: null,
      popoverShow: false,
      markers: [],
      selectedBuilding: null,
      selectedBuildingOwner: null,
      selectedPartners: [],
      tenanciesList: null,
      partnerFields: [
        {
          key: 'companyName',
          label: 'Company Name',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      objectFields: [
        {
          key: 'objects',
          label: 'Name',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'rooms',
          label: 'Rooms',
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'surface',
          label: 'Surface',
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'tenants',
          label: 'Tenant',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      accountsShares: null,
      addressToOpen: null,
      company: {
        name: '',
        email: '',
        phoneNumber: '',
        website: '',
        address: {
          country: 'CH',
          state: '',
          zip: '',
          city: '',
          street: '',
          streetNumber: ''
        }
      },
      localPartner: []
    }
  },
  computed: {
    paginatedList () {
      return this.selectedObjects.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
    },
    filterQuery() {
      let query = ''
      if (this.isAdminModule) {
        query += `networkPartners.combinedId:${this.accountId.toString()}-management`
      } else if (this.isAssetModule) {
        query += `networkPartners.combinedId:${this.accountId.toString()}-asset`
      } else if (this.isAccountingModule) {
        query += `networkPartners.combinedId:${this.accountId.toString()}-accounting`
      }
      return query
    },
    getBuildingArea() {
      if (this.selectedBuilding) {
        if (this.selectedBuilding.objectID) {
          return this.selectedBuilding.plotArea
        } else {
          if (this.buildingListPlot.length > 0) {
            let plotArea = 0
            this.buildingListPlot.forEach(element => {
              if (this.selectedBuilding && this.selectedBuilding.plotIds.includes(element.id)) {
                if (element.plotArea && element.plotArea !== '') {
                  plotArea = plotArea + element.plotArea
                }
              }
            })
            return plotArea
          }
        }
      }
      return '-'
    },
    getBuildingZone() {
      let constructionZone = ''
      if (this.selectedBuilding) {
        if (this.selectedBuilding.objectID) {
          return this.selectedBuilding.buildingZone
        } else {
          if (this.buildingListPlot.length > 0) {
            this.buildingListPlot.forEach((element, index) => {
              if (this.selectedBuilding.plotIds.includes(element.id)) {
                if (element.constructionZone && element.constructionZone !== '') {
                  if (index === 0) {
                    constructionZone = element.constructionZone
                  } else {
                    constructionZone = constructionZone + ', ' + element.constructionZone
                  }
                }
              }
            })
            return constructionZone
          }
        }
      }
      return '-'
    },
    getPlotsNumber() {
      let landRegisterNumber = ''
      if (this.selectedBuilding) {
        if (this.selectedBuilding.objectID) {
          return this.selectedBuilding.landRegisterNumber
        } else {
          if (this.buildingListPlot.length > 0) {
            this.buildingListPlot.forEach(element => {
              if (element) {
                if (element.landRegisterNumber && element.landRegisterNumber !== '') {
                  landRegisterNumber = landRegisterNumber + ', ' + element.landRegisterNumber
                }
              }
            })
            return landRegisterNumber.slice(2)
          }
        }
      }
      return landRegisterNumber
    },
    buildingContact() {
      const current = this
      if (current.selectedPartners && this.selectedBuildingOwner && this.selectedBuildingOwner.id !== this.accountId.toString()) {
        const list = [this.selectedBuildingOwner].concat(current.selectedPartners.map(x => {
          if (current.accountsShares && current.accountsShares.find(z => z.targetAccountId === x.id) && current.accountsShares.find(z => z.targetAccountId === x.id).scope) {
            x.scope = current.accountsShares.find(z => z.targetAccountId === x.id).scope
          } else {
            x.scope = 'UNDEFINED_SCOPE'
          }
          return x
        }).filter(x => x.id !== this.accountId.toString()).sort((a, b) => {
          const nameA = a.scope === 'ASSET_SCOPE' ? 1 : a.scope === 'MANAGEMENT_SCOPE' ? 2 : a.scope === 'MARKETING_SCOPE' ? 3 : 4
          const nameB = b.scope === 'ASSET_SCOPE' ? 1 : b.scope === 'MANAGEMENT_SCOPE' ? 2 : b.scope === 'MARKETING_SCOPE' ? 3 : 4
          return nameA > nameB ? 1 : -1
        }))
        return list.length > 0 ? list.concat(this.localPartner) : []
      } else {
        const list = this.selectedPartners.map(x => {
          if (current.accountsShares && current.accountsShares.find(z => z.targetAccountId === x.id) && current.accountsShares.find(z => z.targetAccountId === x.id).scope) {
            x.scope = current.accountsShares.find(z => z.targetAccountId === x.id).scope
          } else {
            x.scope = 'UNDEFINED_SCOPE'
          }
          return x
        }).filter(x => x.id !== this.accountId.toString()).sort((a, b) => {
          const nameA = a.scope === 'ASSET_SCOPE' ? 1 : a.scope === 'MANAGEMENT_SCOPE' ? 2 : a.scope === 'MARKETING_SCOPE' ? 3 : 4
          const nameB = b.scope === 'ASSET_SCOPE' ? 1 : b.scope === 'MANAGEMENT_SCOPE' ? 2 : b.scope === 'MARKETING_SCOPE' ? 3 : 4
          return nameA > nameB ? 1 : -1
        })
        return list.length > 0 ? list.concat(this.localPartner) : []
      }
    }
  },
  watch: {
    tabIndex: function (newVal) {
      let newView = 'base'
      if (newVal === 0) {
        newView = 'base'
      } else if (newVal === 1) {
        newView = 'objects'
      } else if (newVal === 2) {
        newView = 'partner'
      }
      if (this.view !== newView) {
        if (this.isOwnerModule) {
          this.$router.push({ name: 'OwnerBuildingsMapView', query: { view: newView, elementSelected: this.selectedBuilding?.objectID } }, () => {})
        } else if (this.isAdminModule) {
          this.$router.push({ name: 'AdminBuildingsMapView', query: { view: newView, elementSelected: this.selectedBuilding?.objectID } }, () => {})
        } else if (this.isAssetModule) {
          this.$router.push({ name: 'AssetBuildingsMapView', query: { view: newView, elementSelected: this.selectedBuilding?.objectID } }, () => {})
        } else if (this.isAccountingModule) {
          this.$router.push({ name: 'AccountingBuildingsMapView', query: { view: newView, elementSelected: this.selectedBuilding?.objectID } }, () => {})
        }
      }
    }
  },
  mounted() {
    this.loading = true
    this.loadInstantSearch()
    ContactApi.getContacts(this.$store.getters['user/getAccountId'])
      .then(response => {
        this.contactsList = response.persons.concat(response.companies)
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
      })
    UserApi.getAccount(this.accountId)
      .then(values => {
        if (values.account.company) {
          this.company = values.account.company
          const account = values.account
          if (this.hasAccessAdminBase) {
            const obj = JSON.parse(JSON.stringify(account))
            obj.scope = 'MANAGEMENT_SCOPE'
            this.localPartner.push(obj)
          }
          if (this.hasAccessAssetBase) {
            const obj = JSON.parse(JSON.stringify(account))
            obj.scope = 'ASSET_SCOPE'
            this.localPartner.push(obj)
          }
          if (this.hasAccessAccountingBase) {
            const obj = JSON.parse(JSON.stringify(account))
            obj.scope = 'ACCOUNTING_SCOPE'
            this.localPartner.push(obj)
          }
          if (this.hasAccessMarketBase) {
            const obj = JSON.parse(JSON.stringify(account))
            obj.scope = 'MARKETING_SCOPE'
            this.localPartner.push(obj)
          }
          if (this.elementSelected) {
            this.loadCurrentBuilding(this.elementSelected)
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
    this.$nextTick(() => {
      this.loading = false
      this.openSelectedTabs()
    })
  },
  methods: {
    loadCurrentBuilding(objectID) {
      if (this.searchClient && objectID !== '') {
        const index = this.searchClient.initIndex('building')
        const filter = `objectID:${objectID}`
        index
          .search('', {
            filters: filter
          })
          .then(({ hits }) => {
            this.selectedBuilding = hits[0]
          })
      }
    },
    openSelectedTabs() {
      if (this.view === 'base') {
        this.tabIndex = 0
      } else if (this.view === 'objects') {
        this.tabIndex = 1
      } else if (this.view === 'partner') {
        this.tabIndex = 2
      }
    },
    getBuildingRoute(view) {
      if (this.isOwnerModule) {
        return { name: 'OwnerBuildingsMapView', query: { view: view, elementSelected: this.selectedBuilding?.objectID } }
      } else if (this.isAdminModule) {
        return { name: 'AdminBuildingsMapView', query: { view: view, elementSelected: this.selectedBuilding?.objectID } }
      } else if (this.isAssetModule) {
        return { name: 'AssetBuildingsMapView', query: { view: view, elementSelected: this.selectedBuilding?.objectID } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingBuildingsMapView', query: { view: view, elementSelected: this.selectedBuilding?.objectID } }
      }
    },
    showpartnerIcon(accountid) {
      return this.accountsShares ? this.accountsShares.filter(x => x.targetAccountId === accountid).map(x => x.scope) : []
    },
    loadAppearingObjects() {
      const current = this
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        if (this.$refs.gmap) {
          current.$refs.gmap.$mapPromise.then(() => {
            // eslint-disable-next-line no-undef
            let scope = 'UNDEFINED_SCOPE'
            if (this.isAdminModule) {
              scope = 'MANAGEMENT_SCOPE'
            } else if (this.isAssetModule) {
              scope = 'ASSET_SCOPE'
            } else if (this.isAccountingModule) {
              scope = 'ACCOUNTING_SCOPE'
            }
            ObjectApi.listByCoordinates(
              this.$refs.gmap.$mapObject.getBounds().getNorthEast().lng(),
              this.$refs.gmap.$mapObject.getBounds().getSouthWest().lng(),
              this.$refs.gmap.$mapObject.getBounds().getNorthEast().lat(),
              this.$refs.gmap.$mapObject.getBounds().getSouthWest().lat(),
              scope)
              .then(response => {
                for (const b of response.buildings) {
                  if (b.address.geo) {
                    // check if geo position is already pushed to marker array
                    const geoExist = current.markers.find(building =>
                      building.position.lat === b?.address?.geo?.latitude &&
                      building.position.lng === b?.address?.geo?.longitude
                    )
                    if (!geoExist) {
                      // building list with same geo position
                      const sameGeoList = response.buildings.filter(building =>
                        building.address.geo.latitude === b.address.geo.latitude &&
                        building.address.geo.longitude === b.address.geo.longitude
                      )
                      let type = 'single'
                      let buildingList = []
                      if (sameGeoList.length > 1) {
                        type = 'multiple'
                        buildingList = sameGeoList
                      }
                      current.markers.push({
                        building: b,
                        type: type,
                        buildingList: buildingList,
                        position: {
                          lat: b?.address?.geo?.latitude,
                          lng: b?.address?.geo?.longitude
                        }
                      })
                    }
                  }
                }
                if (current.$refs.gmap) {
                  current.$refs.gmap.$mapPromise.then(() => {
                    // eslint-disable-next-line no-undef
                    const bounds = new google.maps.LatLngBounds()
                    for (const m of current.markers) {
                      bounds.extend(m.position)
                    }
                  })
                }
              })
              .catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
              })
          })
        }
      }, 500)
    },
    getTenantsName(objectId) {
      const tenancy = this.currentTenancyList.find(object => object.object === objectId)
      if (tenancy && tenancy.vacancy === false) {
        const tenantNames = []
        for (const contactId of tenancy.contacts) {
          const contact = this.contactsList.find(contact => contact.id === contactId)
          if (contact) {
            tenantNames.push(contact.name)
          }
        }
        return tenantNames.length !== 0 ? tenantNames.join(', ') : '-'
      } else if (tenancy && tenancy.vacancy === true) {
        return this.$t('message.onBoarding.buildings.objects.status.vacant')
      } else {
        return '-'
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.building.name + ' - ' + suggestion.item.building.address.street + ' ' + suggestion.item.building.address.streetNumber + ', ' + suggestion.item.building.address.zip + ' ' + suggestion.item.building.address.city
    },
    onBuildingInputChange(refine, text) {
      refine(text)
    },
    onBuildingSelected(option) {
      if (option && option.item) {
        this.map.zoom = 15
        this.map.center = {
          lat: option.item.address.geo.latitude,
          lng: option.item.address.geo.longitude
        }
        this.toggleInfoWindow(option.item, -1)
      }
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits.filter(hit => {
          return hit.id !== ''
        })
      }))
    },
    getObjectList() {
      const current = this
      const buildingObjectIds = this.selectedBuilding.objectIds
      let sharedObjects = []
      let listObjects = []
      ObjectApi.listObjectsByIds(buildingObjectIds)
        .then(response => {
          this.loadingObjects = false
          this.currentTenancies(response.objects)
          listObjects = response.objects
          sharedObjects = response.objects.map(obj => obj.id)
          if (sharedObjects.length > 0) {
            ShareApi.listByResource(sharedObjects)
              .then(response => {
                this.accountsShares = response.shares
                const tempList = []
                listObjects.forEach(obj => {
                  this.accountsShares.forEach(item => {
                    if ((parseInt(item.sourceAccountId) === this.accountId || parseInt(item.targetAccountId) === this.accountId) && item.resourceIds.includes(obj.id)) {
                      const found = tempList.find(x => x.id === obj.id)
                      if (!found) {
                        tempList.push(obj)
                      }
                    }
                  })
                })
                this.$nextTick(() => {
                  this.selectedObjects = tempList
                })
                const promises = []
                response.shares.forEach((share) => {
                  promises.push(UserApi.getAccount(share.targetAccountId))
                })
                Promise.all(promises)
                  .then(response => {
                    const selectedPartners = response
                    const uniquePartners = this.getUniqueArray(selectedPartners.map(x => x.account), ['id'])
                    current.selectedPartners = uniquePartners
                    this.loadingPartners = false
                  })
                  .catch(e => {
                    this.loadingPartners = false
                    console.log(e)
                  })
              })
              .catch(e => {
                this.loadingPartners = false
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.networkPartners'), { type: 'error' })
              })
          } else {
            this.loadingPartners = false
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    currentTenancies(objects) {
      if (objects.length > 0) {
        ObjectApi.listCurrentByObjectIds(objects.map(obj => obj.id)).then(response => {
          let contactIds = []
          for (let index = 0; index < response.tenancies.length; index++) {
            const objectContact = {
              object: response.tenancies[index].objectId,
              contacts: response.tenancies[index].tenant.contactIds,
              rent: response.tenancies[index].rent,
              vacancy: response.tenancies[index].vacancy
            }
            this.currentTenancyList.push(objectContact)
            contactIds = contactIds.concat(response.tenancies[index].tenant.contactIds)
            ContactApi.contactResolve(contactIds)
              .then(response => {
                this.contactsList = this.contactsList.concat(response.persons.concat(response.companies))
              })
          }
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
        })
      }
    },
    navigateToBuilding() {
      const buildingId = this.selectedBuilding.objectID ? this.selectedBuilding.objectID : this.selectedBuilding.id
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerBuildingDetailsView', params: { id: buildingId }, query: { view: 'building' } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetBuildingDetailsView', params: { id: buildingId }, query: { view: 'building' } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingBuildingDetailsView', params: { id: buildingId }, query: { view: 'building' } })
      } else {
        this.$router.push({ name: 'AdminBuildingDetailsView', params: { id: buildingId }, query: { view: 'building' } })
      }
    },
    navigateToBuildingMiddle() {
      const buildingId = this.selectedBuilding.objectID ? this.selectedBuilding.objectID : this.selectedBuilding.id
      let routeData = null
      if (this.isOwnerModule) {
        routeData = this.$router.resolve({ name: 'OwnerBuildingDetailsView', params: { id: buildingId }, query: { view: 'building' } })
      } else if (this.isAssetModule) {
        routeData = this.$router.resolve({ name: 'AssetBuildingDetailsView', params: { id: buildingId }, query: { view: 'building' } })
      } else if (this.isAccountingModule) {
        routeData = this.$router.resolve({ name: 'AccountingBuildingDetailsView', params: { id: buildingId }, query: { view: 'building' } })
      } else {
        routeData = this.$router.resolve({ name: 'AdminBuildingDetailsView', params: { id: buildingId }, query: { view: 'building' } })
      }
      window.open(routeData.href, '_blank')
    },
    getUniqueArray(arr = [], compareProps = []) {
      const modifiedArray = []
      if (compareProps.length === 0 && arr.length > 0) { compareProps.push(...Object.keys(arr[0])) }
      arr.forEach(item => {
        if (modifiedArray.length === 0) {
          modifiedArray.push(item)
        } else {
          if (!modifiedArray.some(item2 =>
            compareProps.every(eachProps => item2[eachProps] === item[eachProps])
          )) { modifiedArray.push(item) }
        }
      }); return modifiedArray
    },
    toggleInfoWindow(building, index, type = 'single', m = null) {
      if (building) {
        const buildingOwnerId = building.ownerId ? building.ownerId : building.owner.id
        UserApi.getAccount(buildingOwnerId).then(res => {
          this.selectedBuildingOwner = res.account
        })
        ObjectApi.listPlotbyIds(building.plotIds).then(response => {
          this.buildingListPlot = response.plots
        })
      }
      if (this.currentMarker === index && type === 'multiple') {
        this.currentMarker = -1
      } else {
        if (m) this.addressToOpen = this.getStringAddress(m.building.address)
        this.currentMarker = index
        if (type === 'single') {
          this.loadingObjects = true
          this.loadingPartners = true
          this.selectedBuilding = building
          this.selectedObjects = []
          this.getObjectList()
        }
      }
    },
    getContactName(contactId) {
      const contact = this.contactsList.find(function (contact) {
        return contact.id === contactId
      })
      return contact ? ', ' + contact.name : '-'
    },
    navigateToObjects() {
      let routeName = ''
      if (this.isOwnerModule) {
        routeName = 'OwnerBuildingDetailsView'
      } else if (this.isAssetModule) {
        routeName = 'AssetBuildingDetailsView'
      } else if (this.isAccountingModule) {
        routeName = 'AccountingBuildingDetailsView'
      } else {
        routeName = 'AdminBuildingDetailsView'
      }
      const buildingId = this.selectedBuilding.objectID ? this.selectedBuilding.objectID : this.selectedBuilding.id
      this.$router.push({
        name: routeName,
        params: { id: buildingId },
        query: { view: 'objects' }
      })
    },
    navigateToObjectsMiddle() {
      let routeData = null
      const buildingId = this.selectedBuilding.objectID ? this.selectedBuilding.objectID : this.selectedBuilding.id
      if (this.isOwnerModule) {
        routeData = this.$router.resolve({ name: 'OwnerBuildingDetailsView', params: { id: buildingId }, query: { view: 'objects' } })
      } else if (this.isAssetModule) {
        routeData = this.$router.resolve({ name: 'AssetBuildingDetailsView', params: { id: buildingId }, query: { view: 'objects' } })
      } else if (this.isAccountingModule) {
        routeData = this.$router.resolve({ name: 'AccountingBuildingDetailsView', params: { id: buildingId }, query: { view: 'objects' } })
      } else {
        routeData = this.$router.resolve({ name: 'AdminBuildingDetailsView', params: { id: buildingId }, query: { view: 'objects' } })
      }
      console.log('route :', routeData)
      window.open(routeData.href, '_blank')
    },
    checkAddress() {
      if (this.newObject.address.street) {
        // Search if we have a building with same zip, street and street number
        const filter = this.markers.filter(function (element) {
          return element.building.address.street.toLowerCase().includes(this.newObject.address.street.toLowerCase()) &&
            element.building.address.streetNumber === this.newObject.address.streetNumber &&
            element.building.address.zip === this.newObject.address.zip
        }, this)

        this.map.center = null
        if (filter.length > 0) {
          this.map.zoom = 15
          this.toggleInfoWindow(filter[0], -1)
          this.map.center = this.newMarker
        } else {
          const marker = {
            building: null,
            position: {
              lat: this.newMarker?.lat,
              lng: this.newMarker?.lng
            }
          }
          this.markers.push(marker)
          this.map.zoom = 15
          this.map.center = this.newMarker
          this.popoverShow = true
        }
      }
    },
    autocompletePlaceChanged(data) {
      this.newMarker = {
        lat: data?.geometry?.viewport?.pa?.g,
        lng: data?.geometry?.viewport?.ka?.g
      }
      this.updateWithAddressComponents(data.address_components)
    },
    updateWithAddressComponents(addressComponents) {
      const streetNumber = addressComponents.find((component) => component.types.find((type) => type === 'street_number'))
      const street = addressComponents.find((component) => component.types.find((type) => type === 'route'))
      const city = addressComponents.find((component) => component.types.find((type) => type === 'locality'))
      const zip = addressComponents.find((component) => component.types.find((type) => type === 'postal_code'))
      const state = addressComponents.find((component) => component.types.find((type) => type === 'administrative_area_level_1'))
      const country = addressComponents.find((component) => component.types.find((type) => type === 'country'))

      if (country && country.short_name === 'CH') {
        if (streetNumber) {
          this.newObject.address.streetNumber = streetNumber.long_name
        }
        if (street) {
          this.newObject.address.street = street.long_name
        }
        if (city) {
          this.newObject.address.city = city.long_name
        }
        if (zip) {
          this.newObject.address.zip = zip.long_name
        }
        if (state) {
          this.newObject.address.state = state.short_name
        }
      } else {
        // TODO Alert
      }
      this.checkAddress()
    },
    navigateToGoogleMaps() {
      if (this.addressToOpen) {
        let url
        if (this.company.address.state || this.company.address.zip || this.company.address.city) {
          url = `https://www.google.com/maps/dir/${this.getStringAddress(this.company.address)}/${this.addressToOpen}`
        } else {
          url = `https://www.google.com/maps/search/${this.addressToOpen}`
        }
        window.open(url, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

:deep(.building-tables) {
    width: calc(100%);

    .table-header {
      padding: 0.75rem 0.25rem;
    }
  }

  .existing-building {
    padding-top: 15px;
    padding-left: 15px;
    width: 300px;
  }

  .marker-sidebar {
    height: 100%;
  }

  .confirmation-modal {
    padding-left: 15px;
    padding-top: 10px;
  }

  :deep(.tab-content) {
    height: calc(100% - 42px);
  }

  .data-row-building {
    padding: 10px 0;
    border-bottom: 1px solid #c8ced3;

    .data-building {
      color: $color-primary;
      font-weight: bold;
      float: right;
    }
  }
  :deep(.tabs .nav-tabs .nav-link a.router-link-active), :deep(.tabs .nav-tabs .nav-link a) {
    color: inherit !important;
    text-decoration: none !important;
  }
</style>
