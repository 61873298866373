<template>
  <b-modal
    id="contract-modal"
    ref="contract-modal"
    :title="templateToEdit ? $t('message.settings.template.editContractTemplate') : $t('message.settings.template.createContractTemplate')"
    hide-footer
    no-close-on-backdrop
    @close="hide">
    <div
      v-if="loading"
      class="row">
      <div
        class="text-center col">
        <coozzy-spinner />
      </div>
    </div>
    <div
      v-else
      :class="drag ? 'drag-zone' : ''"
      class="row"
      @dragover="drag = true"
      @drop.prevent="addFile"
      @dragleave.prevent>
      <div
        class="dragover-mask"
        @dragleave="drag=false" />
      <div
        v-if="!hasAccessPropBase"
        class="col-sm-12 col-md-12">
        <label>{{ $t('message.settings.template.createTemplateFor') + '*' }}</label>
        <coozzy-form-select
          v-model="contract.ownerId"
          :state="$v.contract.ownerId.$dirty && $v.contract.ownerId.$error ? false : null"
          class="mb-2">
          <option :value="accountId">
            {{ $t('message.settings.template.ownTemplate') }}
          </option>
          <option
            v-for="item in ownerAccounts.filter(a => parseInt(a.id) !== parseInt(accountId))"
            :key="item.id"
            :value="item.id">
            <template v-if="item.company">
              {{ item.company.name }}
            </template>
            <template v-else-if="item.individual">
              {{ item.individual.firstName.substr(0, 1) }}. {{ item.individual.lastName }}
            </template>
            <template v-else>
              n/a
            </template>
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-sm-12 col-md-12">
        <coozzy-form-input
          v-model="contract.title"
          :limit="255"
          :name="$t('message.tableColumnHeadings.title') + '*'"
          :state="$v.contract.title.$dirty && $v.contract.title.$error ? false : null"
          class="mb-2" />
      </div>
      <div
        class="col-sm-12 col-md-12">
        <label>{{ $t('message.settings.template.placeholderTemplate.category') }}*</label>
        <coozzy-form-select
          v-model="contract.category"
          :state="$v.contract.category.$dirty && $v.contract.category.$error ? false : null"
          class="mb-2">
          <option
            v-for="(cat, index) in translatedCategories"
            :key="index"
            :value="cat.value">
            {{ cat.label }}
          </option>
        </coozzy-form-select>
      </div>
      <div
        class="col-sm-12 col-md-6">
        <label>{{ $t('message.settings.template.placeholderTemplate.language') }}</label>
        <coozzy-form-select
          v-model="contract.language"
          class="mb-2">
          <option :value="'EN'">
            EN
          </option>
          <option :value="'DE'">
            DE
          </option>
          <option :value="'IT'">
            IT
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-sm-12 col-md-6">
        <coozzy-form-checkbox
          v-model="contract.enforcePdf"
          :class="'mt-4 pt-1'"
          :initial="contract.enforcePdf"
          name="enforcePdf">
          {{ $t('message.settings.template.placeholderTemplate.enforcePdf') }}
        </coozzy-form-checkbox>
      </div>
      <div class="col-sm-12 col-md-12">
        <label for="file">{{ $t('message.generic.attachments') }}*</label>
        <coozzy-form-file-input
          id="file"
          ref="file-input"
          :state="isValidemediaUpload"
          accept=".docx"
          @change="addFile" />
      </div>
      <div class="col mt-3">
        <coozzy-button
          class="mb-0 border"
          design="transparent"
          size="small"
          @click="hide">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          :disabled="loading"
          class="float-right mb-0"
          design="green"
          size="small"
          @click="onSaveClicked">
          {{ templateToEdit ? $t('message.generic.form.save') : $t('message.generic.create') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ObjectApi from '../../misc/apis/ObjectApi'
import MediaApi from '../../misc/apis/MediaApi'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import CoozzyFormFileInput from '../../framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { user } from '@/mixins/user'
import { media } from '@/mixins/media'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'

export default {
  name: 'TemplateContractModal',
  components: {
    CoozzyFormCheckbox,
    CoozzyFormSelect,
    CoozzySpinner,
    CoozzyFormFileInput,
    CoozzyButton,
    CoozzyFormInput
  },
  mixins: [user, media],
  props: {
    ownerAccounts: {
      type: Array,
      default() {
        return []
      }
    },
    templateToEdit: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      loading: false,
      file: null,
      drag: false,
      contract: {
        title: '',
        mediaId: '',
        ownerId: this.hasAccessPropBase ? this.accountId : '',
        category: 'TEMPLATE_CATEGORY_GENERAL',
        creatorName: this.fullName,
        enforcePdf: false,
        language: this.getUserLang,
        firstSignature: '',
        secondSignature: '',
        id: ''
      },
      mediaUpload: null,
      isValidemediaUpload: null
    }
  },
  fullName() {
    const profile = this.$store.getters['user/getCurrentUser']
    if (profile) {
      return `${profile['https://coozzy.ch/firstName'].substr(0, 1)}. ${profile['https://coozzy.ch/lastName']}`
    } else {
      return ''
    }
  },
  computed: {
    getUserLang() {
      return this.$store.getters['user/getCurrentUser']['https://coozzy.ch/lang'].toUpperCase()
    },
    translatedCategories() {
      const translatedCategories = ['TEMPLATE_CATEGORY_GENERAL', 'TEMPLATE_CATEGORY_ORDERS', 'TEMPLATE_CATEGORY_TERMINATION', 'TEMPLATE_CATEGORY_CONTRACTS', 'TEMPLATE_CATEGORY_OFFER', 'TEMPLATE_CATEGORY_LETTER']
      const array = []
      translatedCategories.forEach(cat => {
        const text = this.$t('message.settings.template.contractTemplateCategories.' + cat)
        array.push({
          label: text,
          value: cat
        })
      })
      array.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
        }
        return 0
      })
      return array
    }
  },
  watch: {
    file: {
      handler: function (val) {
        if (val !== null) {
          this.drag = false
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'contract-modal') {
        if (this.templateToEdit) {
          const creator = this.ownerAccounts.find(x => x.id === this.templateToEdit.ownerId)
          this.contract = {
            title: this.templateToEdit.title,
            mediaId: this.templateToEdit.mediaId,
            ownerId: creator.id,
            category: this.templateToEdit.category,
            creatorName: this.templateToEdit.creatorName,
            enforcePdf: this.templateToEdit.enforcePdf,
            language: this.templateToEdit.language,
            id: this.templateToEdit.id,
            firstSignature: this.templateToEdit.firstSignature,
            secondSignature: this.templateToEdit.secondSignature
          }
        } else {
          this.contract = {
            title: '',
            mediaId: '',
            ownerId: this.hasAccessPropBase ? this.accountId : '',
            category: 'TEMPLATE_CATEGORY_GENERAL',
            creatorName: this.fullName,
            enforcePdf: false,
            language: this.getUserLang
          }
        }
      }
      document.addEventListener('drop', function (e) {
        e.preventDefault()
      })
      document.addEventListener('dragover', function (e) {
        e.preventDefault()
      })
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'contract-modal') {
        this.$emit('hide-modal')
      }
    })
  },
  methods: {
    onSaveClicked() {
      this.isValidemediaUpload = this.mediaUpload !== null ? null : this.templateToEdit !== null ? null : false
      this.$v.contract.$touch()
      if (!this.$v.contract.$invalid && this.isValidemediaUpload === null) {
        this.loading = true
        this.sendFile()
      }
    },
    async addFile(event) {
      const files = await this.getFilesFromEvent(event)
      if (files.length > 0) {
        this.file = files[0]
        this.isValidemediaUpload = null
        this.mediaUpload = files[0]
        this.updateUploadedFileName(this, files[0].name)
      } else {
        this.drag = false
        this.isValidemediaUpload = false
        if (this.$refs['file-input']) {
          this.$refs['file-input'].resetPlaceholder()
        }
      }
    },
    async sendFile() {
      if (this.templateToEdit && this.mediaUpload === null) {
        this.createContractTemplate()
      } else {
        this.uploadProcessing = true
        const selectedFile = this.mediaUpload ? [this.mediaUpload] : []
        const response = await this.uploadMedia(selectedFile)
        if (response.length > 0) {
          const media = response[0]
          this.contract.mediaId = media.id
          this.createContractTemplate()
        } else {
          this.loading = false
        }
        this.uploadProcessing = false
      }
    },
    createContractTemplate() {
      if (this.templateToEdit) {
        ObjectApi.updateContractTemplate(
          this.contract
        ).then(() => {
          this.$v.$reset()
          this.hide()
          Vue.toasted.show(this.$tc('message.settings.template.documentSavedSuccessfully'), { type: 'success' })
          this.$emit('on-creation')
        }).catch(e => {
          this.loading = false
          console.log(e)
          Vue.toasted.show(this.$tc('message.savingErrors.template'), { type: 'error' })
        }).finally(() => {
          this.loading = false
        })
      } else {
        ObjectApi.createContractTemplate(
          this.contract,
          this.contract.ownerId
        ).then(() => {
          this.$v.$reset()
          this.hide()
          Vue.toasted.show(this.$tc('message.settings.template.documentSavedSuccessfully'), { type: 'success' })
          this.$emit('on-creation')
        }).catch(e => {
          this.loading = false
          console.log(e)
          Vue.toasted.show(this.$tc('message.savingErrors.template'), { type: 'error' })
        }).finally(() => {
          this.loading = false
        })
      }
    },
    show() {
      this.$refs['contract-modal'].show()
    },
    hide() {
      this.contract = {
        ownerId: this.hasAccessPropBase ? this.accountId : '',
        title: '',
        mediaId: ''
      }
      this.$refs['contract-modal'].hide()
    }
  },
  validations: {
    contract: {
      title: {
        required
      },
      category: {
        required
      },
      ownerId: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0;
}

.drag-zone .dragover-mask {
  display: block !important;
  transition: z-index 0s step-end;
  z-index: 2500;;
}

.dragover-mask {
  display: none;
  background: rgba(255, 255, 255, 0.3);
  border: 3px dashed $color-primary;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  margin: 2px;
  position: absolute;
  right: 0;
  top: 0;
  transition: z-index .2s step-end;
  z-index: -1;
}

.media-block {
  max-width: 33%;
  margin-right: 0.5%;

  .uploaded-file {
    background-position: center center;
    background-size: cover;
    min-height: 7em;
  }

  .file-block {
    min-height: 7em;
    background: $color-bg-grey-dark;

    a {
      color: $color-text-grey;
      overflow-wrap: break-word;
    }
  }
}

</style>
