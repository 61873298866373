<template>
  <div>
    <span
      v-b-tooltip="getDateTimeFormatted(ticket.createdAt)"
      class="small mb-0">
      {{ $t('message.ticketOverview.ticket.created') }}: {{ getTimeSince(ticket.createdAt, true) }}
      <br>
    </span>
    <span
      v-b-tooltip.bottom="getDateTimeFormatted(ticket.updatedAt)"
      class="small">
      {{ $t('message.ticketOverview.ticket.updated') }}: {{ getTimeSince(ticket.updatedAt, true) }}
    </span>
  </div>
</template>

<script>
import { dateUtils } from '@/mixins/dateUtils'

export default {
  name: 'TicketDates',
  mixins: [dateUtils],
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },
  methods: {
    getDateTimeFormatted(timestampInSeconds) {
      return this.$options.filters.timestampToDateTime(timestampInSeconds)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
