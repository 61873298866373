<template>
  <coozzy-list-item
    button
    :active="selectedPlatform === platform"
    @click="clicked()">
    <coozzy-pending-icon
      v-if="run !== null && run.status === 'started'"
      v-b-tooltip.hover
      :title="$t('message.export.pending')" />
    <coozzy-success-icon
      v-if="run !== null && run.status === 'finished'"
      v-b-tooltip.hover
      :title="$t('message.export.successful')" />
    <coozzy-fail-icon
      v-if="run !== null && run.status === 'failed'"
      v-b-tooltip.hover
      :title="$t('message.export.failed')" />
    <slot />
  </coozzy-list-item>
</template>

<script>
export default {
  name: 'ExportSettingsPlatformItem',
  props: {
    selectedPlatform: {
      type: String,
      default: ''
    },
    platform: {
      type: String,
      default: ''
    },
    run: {
      type: Object,
      default: null
    }
  },
  methods: {
    clicked() {
      this.$emit('selected', this.platform)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
