<template>
  <div>
    <label :for="name">
      {{ name }}
    </label>
    <b-input-group>
      <b-form-input
        :id="name"
        ref="parentInput"
        type="text"
        size="sm"
        :value="value"
        :readonly="true"
        @focus.native="$event.target.select()"
        v-on="mountEventListeners" />

      <b-input-group-append>
        <coozzy-popover
          :show.sync="isPopover"
          :disabled.sync="isPopover"
          triggers="manual"
          target="popover-target">
          {{ $t('message.generic.copy') }}
        </coozzy-popover>
        <coozzy-button
          id="popover-target"
          ref="popover"
          v-clipboard="value"
          class="btn-copy input-group-end"
          @click.stop.prevent="clickListener">
          <coozzy-copy-icon />
        </coozzy-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>

export default {
  name: 'CoozzyFormCopyInput',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isPopover: false
    }
  },
  computed: {
    mountEventListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: (value) => {
            this.$emit('input', value)
          }
        }
      )
    }
  },
  methods: {
    clickListener() {
      this.$refs.parentInput.focus()
      const current = this
      current.isPopover = true
      document.execCommand('copy')
      this.$clipboard(this.value)
      this.$nextTick(function () { this.isPopover = false })
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-copy {
    background-color: $color-primary !important;
    box-shadow: none;
    font-size: inherit;

    &:hover {
      background-color: $color-primary-dark !important;
    }
  }

  .input-group-end {
    border: 1px solid $color-input-border !important;
  }
</style>
