<template>
  <coozzy-card :title="$t('message.settings.template.titleLetter')">
    <div>
      <div class="row mt-2">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
              {{ $t('message.generic.delete') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div class="col-12 col-md-2 offset-md-8">
          <coozzy-button
            class="w-100 mb-2"
            design="prop-green"
            @click="createTemplateClicked(null)">
            {{ $t('message.settings.template.createLetterTemplate') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-table
            ref="letterTemplateTable"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            :busy="isBusy"
            :fields="fields"
            :items="sortedTemplate"
            @row-clicked="onRowClicked">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <!-- Headings -->
            <template #head(checkbox)="">
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(name)>
              {{ $t('message.tableColumnHeadings.template') }}
            </template>
            <template #head(subject)>
              {{ $t('message.tableColumnHeadings.subject') }}
            </template>
            <template #head(message)>
              {{ $t('message.tableColumnHeadings.letter') }}
            </template>

            <!-- Data -->
            <template #cell(checkbox)="data">
              <!-- data needs to be used in order to work properly -->
              <coozzy-form-checkbox
                :id="'checkItem_' + data.item.id"
                @change="(value) => { itemSelected(data.item.id, value) }" />
            </template>
            <template #cell(name)="data">
              <p class="mb-0">
                {{ data.item.name | displayOptionalValue }}
              </p>
            </template>
            <template #cell(subject)="data">
              <p class="mb-0">
                {{ data.item.subject | displayOptionalValue }}
              </p>
            </template>
            <template #cell(message)="data">
              <p class="mb-0">
                {{ data.item.messageWithoutHtml | displayOptionalValue }}
              </p>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.settings.template.confirmation.title')">
      <div class="col p-0">
        <p>{{ $t('message.settings.template.confirmation.text') }}</p>
      </div>
      <div class="col p-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledDeleteBtn"
          @click="deleteTemplate()">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
    <template-modal
      ref="modal-creation"
      :key="templateForEdit !== null ? 'modal-creation'+templateForEdit.id : 'modal-creation'"
      :template-edit="templateForEdit"
      source="letterTemplate"
      @on-close="templateForEdit = null"
      @change-success="resetTemplateList(accountId)" />
  </coozzy-card>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import TemplateModal from './TemplateModal'
import MessageApi from '../../misc/apis/MessageApi'
import CoozzyDropdown from '../../framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '../../framework/components/dropdown/CoozzyDropdownItem'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyFormCheckbox from '../../framework/components/form/checkbox/CoozzyFormCheckbox'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { user } from '@/mixins/user'
import { tables } from '@/mixins/tables'

export default {
  name: 'LetterTemplateSettings',
  components: {
    CoozzyFormCheckbox,
    CoozzySpinner,
    CoozzyButton,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyCard,
    TemplateModal
  },
  mixins: [user, tables],
  data() {
    return {
      isBusy: false,
      disabledDeleteBtn: false,
      selectedEntries: [],
      templateForEdit: null,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'name',
          label: this.$t('message.tableColumnHeadings.template'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'subject',
          label: this.$t('message.tableColumnHeadings.subject'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'message',
          label: this.$t('message.tableColumnHeadings.message'),
          thClass: 'th-message align-middle',
          tdClass: 'td-message align-middle'
        }
      ]
    }
  },
  computed: {
    ...mapState('message', ['templates']),
    selectedEntriesText() {
      return this.$t('message.generic.selectedEntries') + ':  ' + this.selectedEntries.length
    },
    sortedTemplate() {
      return this.templates.filter(x => x.type === 'TEMPLATE_TYPE_LETTER').slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    }
  },
  mounted: function () {
    this.loadTemplateList(this.accountId)
      .finally(() => {
        this.isBusy = false
      })
  },
  methods: {
    ...mapActions('message', ['loadTemplateList', 'resetTemplateList']),
    onRowClicked(item, index, event) {
      if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
        this.itemSelected(item.id, !document.getElementById('checkItem_' + item.id).checked)
        document.getElementById('checkItem_' + item.id).checked = !document.getElementById('checkItem_' + item.id).checked
      } else {
        this.createTemplateClicked(item, index, event)
      }
    },
    createTemplateClicked(template = null) {
      if (template !== null) {
        this.templateForEdit = template
      } else {
        this.templateForEdit = null
      }
      this.$nextTick(function () {
        this.$refs['modal-creation'].show()
      })
    },
    openConfirmationModal() {
      this.$refs.confirmationModal.show()
    },
    deleteTemplate() {
      this.disabledDeleteBtn = true
      MessageApi.deleteTemplate(this.selectedEntries)
        .then(() => {
          this.$refs.confirmationModal.hide()
          this.resetTemplateList(this.accountId)
          this.disabledDeleteBtn = false
          this.selectedEntries = []
          this.$refs.header_checkbox.checked = false
          this.handleCheckboxesInsideTable(false)
          Vue.toasted.show(this.$t('message.settings.template.templateDeletedSuccessfully'), { type: 'success' })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.template'), { type: 'error' })
        })
    },
    itemSelected(id, checked) {
      if (checked && !this.selectedEntries.includes(id)) {
        this.selectedEntries.push(id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== id)
      }
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs.letterTemplateTable.$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.td-message), .th-message {
    width: 34vw;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 34vw;
    }
  }
</style>
