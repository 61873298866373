<template>
  <section>
    <div
      v-if="isEditor || isJanitorEditor"
      class="floating-wrapper">
      <coozzy-button
        class="floating-button"
        design="transparent"
        @click="editClicked">
        <coozzy-edit-icon class="too-left" />
      </coozzy-button>
    </div>
    <div
      v-if="property !== null && property !== undefined"
      class="row">
      <div
        v-if="loading"
        class="col-12 text-center">
        <coozzy-spinner class="m-3" />
      </div>
      <div
        v-else
        class="col-12">
        <div class="row">
          <div class="col-12">
            <coozzy-page-title :title="$t('message.propertiesView.tabs.property') +': ' + propertyName" />
          </div>
        </div>

        <div class="row d-block d-md-none">
          <!-- Action buttons on mobile -->
          <div
            v-if="isEditor || isJanitorEditor"
            class="col-12">
            <coozzy-button
              design="primary"
              class="w-100 mb-2"
              @click="editClicked">
              {{ $t('message.generic.edit') }}
            </coozzy-button>
          </div>
          <div class="col-12">
            <coozzy-button
              design="secondary"
              class="w-100 mb-2"
              @click="backClicked">
              {{ $t('message.generic.back') }}
            </coozzy-button>
          </div>
        </div>

        <div class="row">
          <div class="col-12 custumeTabs">
            <b-tabs
              v-model="tabIndex"
              no-key-nav
              class="mb-3">
              <b-tab
                :title="$t('message.propertiesView.tabs.property')"
                class="p-0">
                <template #title>
                  <router-link
                    :to="getPropertyRoute('property')">
                    <span>{{ $t('message.propertiesView.tabs.property') }}</span>
                  </router-link>
                </template>
                <property-tab
                  :property-selected="property"
                  :read-only="true"
                  :expand-property-mortgage-id="expandPropertyMortgageId"
                  :expand-property-insurance-id="expandPropertyInsuranceId"
                  @selected-device-for-history="selectedDeviceForHistory" />
              </b-tab>
              <b-tab
                v-if="!isJanitor"
                class="p-0"
                :title="$t('message.propertiesView.tabs.landRegister')">
                <template #title>
                  <router-link
                    :to="getPropertyRoute('landRegister')">
                    <span>{{ $t('message.propertiesView.tabs.landRegister') }}</span>
                  </router-link>
                </template>
                <land-register-tab
                  :property="property"
                  :read-only="true"
                  :element-selected="elementSelected"
                  @selected-land-register="selectedLandRegister" />
              </b-tab>
              <b-tab
                class="p-0"
                :title="$t('message.propertiesView.tabs.buildings')">
                <template #title>
                  <router-link
                    :to="getPropertyRoute('buildings')">
                    <span>{{ $t('message.propertiesView.tabs.buildings') }}</span>
                  </router-link>
                </template>
                <buildings-tab
                  :property-selected="property"
                  :read-only="true" />
              </b-tab>
              <b-tab
                class="p-0"
                :title="$t('message.onBoarding.createBuilding.device')">
                <template #title>
                  <router-link
                    :to="getPropertyRoute('devices')">
                    <span>{{ $t('message.onBoarding.createBuilding.device') }}</span>
                  </router-link>
                </template>
                <devices-tab
                  ref="devicesTab"
                  :property="property"
                  :element-selected="elementSelected"
                  :add-new-item="addNewItem"
                  :read-only="true"
                  @selected-device="selectedDevice"
                  @selected-device-for-history="selectedDeviceForHistory"
                  @all-devices="deviceList" />
              </b-tab>
              <b-tab
                v-if="isInvestPlanEnabled"
                class="p-0"
                :title="$t('message.onBoarding.investmentPlanning.title')">
                <template #title>
                  <router-link
                    :to="getPropertyRoute('investmentPlanning')">
                    <span>{{ $t('message.onBoarding.investmentPlanning.title') }}</span>
                  </router-link>
                </template>
                <invest-plan-tab
                  :read-only="true"
                  :property="property" />
              </b-tab>
              <b-tab
                class="p-0"
                :title="$t('message.onBoarding.createBuilding.device')">
                <template #title>
                  <router-link
                    :to="getPropertyRoute('contacts')">
                    <span>{{ $t('message.onBoardingTabs.contacts') }}</span>
                  </router-link>
                </template>
                <contacts-tab
                  ref="contactsTab"
                  :property="property" />
              </b-tab>
              <b-tab
                class="p-0"
                :title="$t('message.onBoardingTabs.history')">
                <template #title>
                  <router-link
                    class="tab_title_color"
                    :to="getPropertyRoute('history')">
                    <span>{{ $t('message.onBoardingTabs.history') }}</span>
                    <span
                      v-if="hasNotification"
                      class="small-dot" />
                  </router-link>
                </template>
                <history-tab
                  ref="historyTab"
                  :property="property"
                  :device-list="arrDeviceList"
                  :element-selected="elementSelected"
                  :route-name="$route.name"
                  :is-active="isHistoryTabActive"
                  @has-notification="updateHasNotification" />
              </b-tab>
              <b-tab
                v-if="isPortalLinkEnabled"
                class="p-0"
                :title="$t('message.portalTab.title')">
                <template #title>
                  <router-link
                    :to="getPropertyRoute('portalLinks')">
                    <span>{{ $t('message.portalTab.title') }}</span>
                  </router-link>
                </template>
                <portal-links-tab
                  ref="portalLinksTab"
                  :read-only="true"
                  :property="property" />
              </b-tab>
              <template #tabs-end>
                <b-nav-item
                  v-if="isEditor || isJanitorEditor"
                  href="#"
                  class="d-none d-md-block item-button">
                  <coozzy-button
                    design="primary"
                    class="nav-button nav-button-center"
                    @click="editClicked">
                    {{ $t('message.generic.edit') }}
                  </coozzy-button>
                </b-nav-item>
                <b-nav-item
                  href="#"
                  class="d-none d-md-block item-button">
                  <coozzy-button
                    design="secondary"
                    class="nav-button nav-button-right"
                    @click="backClicked">
                    {{ $t('message.generic.back') }}
                  </coozzy-button>
                </b-nav-item>
              </template>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ObjectApi from '@/misc/apis/ObjectApi'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import BuildingsTab from './tabs/BuildingsTab'
import LandRegisterTab from './tabs/LandRegisterTab'
import PropertyTab from './tabs/PropertyTab'
import DevicesTab from './tabs/DevicesTab'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import Vue from 'vue'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import { user } from '@/mixins/user'
import { routeChecks } from '@/mixins/routeChecks'
import HistoryTab from '@/property/tabs/HistoryTab.vue'
import ContactsTab from '@/property/tabs/ContactsTab.vue'
import InvestPlanTab from '@/property/tabs/InvestPlanTab.vue'
import PortalLinksTab from '@/property/tabs/PortalLinksTab.vue'
import PortalApi from '@/misc/apis/PortalApi'

export default {
  name: 'PropertyDetailsView',
  components: {
    PortalLinksTab,
    InvestPlanTab,
    ContactsTab,
    CoozzyPageTitle,
    CoozzyButton,
    BuildingsTab,
    LandRegisterTab,
    PropertyTab,
    CoozzyEditIcon,
    CoozzySpinner,
    HistoryTab,
    DevicesTab
  },
  mixins: [user, routeChecks],
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name && from.query.view === 'history' && from.query.view !== to.query.view) {
      this.openSelectedTabs(to.query.view || 'property')
    }
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('onboarding/setOpenedCollapses')
    next()
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    view: {
      type: String,
      default: 'property'
    },
    elementSelected: {
      type: String,
      default: ''
    },
    addNewItem: {
      type: Boolean,
      default: false
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.propertiesView.tabs.property') + ' ' + this.propertyName
    }
  },
  data() {
    return {
      arrDeviceList: [],
      tabIndex: 0,
      property: null,
      hasNotification: false,
      expandPropertyMortgageId: '',
      expandPropertyInsuranceId: '',
      isPortalLinkEnabled: false,
      loading: false
    }
  },
  computed: {
    propertyName() {
      return this.property && this.property.name ? this.property.name : ''
    },
    isHistoryTabActive() {
      return this.$route.query.view === 'history'
    }
  },
  watch: {
    tabIndex: function (newVal) {
      if (newVal === 3) {
        this.$refs.devicesTab?.setFocus()
      }
      let viewName = ''
      switch (newVal) {
        case 0:
          viewName = 'property'
          break
        case 1:
          viewName = 'landRegister'
          break
        case 2:
          viewName = 'buildings'
          break
        case 3:
          viewName = 'devices'
          break
        case 4:
          viewName = this.isInvestPlanEnabled ? 'investmentPlanning' : 'contacts'
          break
        case 5:
          viewName = this.isInvestPlanEnabled ? 'contacts' : 'history'
          break
        case 6:
          viewName = this.isInvestPlanEnabled ? 'history' : 'portalLinks'
          break
        case 7:
          viewName = 'portalLinks'
          break
        default:
          viewName = 'property'
          break
      }
      if (this.$route && (!this.$route.query || this.$route.query.view !== viewName)) {
        this.$router.replace({
          name: this.$route.name,
          params: { id: this.id },
          query: { view: viewName }
        })
      }
    },
    isInvestPlanEnabled: function () {
      this.openSelectedTabs()
    }
  },
  mounted() {
    this.loading = true
    ObjectApi.getPropertyListByIds([this.id])
      .then(response => {
        this.property = response.properties[0]
        PortalApi.getPortalStatus(this.property.ownerId)
          .then(response => {
            this.loading = false
            this.isPortalLinkEnabled = response.enabled
          })
          .catch(e => {
            this.loading = false
          })
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
      })

    if (this.isInvestPlanEnabled !== null) {
      this.openSelectedTabs()
    }

    // Scroll to mortgage collapse in property tab
    if (localStorage.getItem('expandPropertyMortgageId')) {
      this.expandPropertyMortgageId = localStorage.getItem('expandPropertyMortgageId')
      localStorage.removeItem('expandPropertyMortgageId')
    }
    // Scroll to insurance collapse in property tab
    if (localStorage.getItem('expandPropertyInsuranceId')) {
      this.expandPropertyInsuranceId = localStorage.getItem('expandPropertyInsuranceId')
      localStorage.removeItem('expandPropertyInsuranceId')
    }
  },
  methods: {
    updateHasNotification(value) {
      this.hasNotification = value
    },
    deviceList(devices) {
      this.arrDeviceList = devices
    },
    selectedDevice(device) {
      this.updateElementSelected(device)
    },
    selectedLandRegister(plot) {
      this.updateElementSelected(plot)
    },
    selectedDeviceForHistory(his) {
      this.updateElementSelected(his, 'history')
    },
    getPropertyRoute(view) {
      if (this.isOwnerModule) {
        return { name: 'OwnerPropertyDetailsView', params: { id: this.id }, query: { view: view } }
      } else if (this.isAdminModule) {
        return { name: 'AdminPropertyDetailsView', params: { id: this.id }, query: { view: view } }
      } else if (this.isAssetModule) {
        return { name: 'AssetPropertyDetailsView', params: { id: this.id }, query: { view: view } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingPropertyDetailsView', params: { id: this.id }, query: { view: view } }
      }
    },
    updateElementSelected(item, view = '') {
      if (item && item.id && (this.elementSelected !== item.id || (view !== '' && this.elementSelected === item.id && this.$route.query.view !== view))) {
        this.$router.replace({
          name: this.$route.name,
          params: { id: this.id },
          query: {
            view: view || this.$route.query.view,
            elementSelected: item.id
          }
        })
        if (view === 'history') {
          this.tabIndex = this.isInvestPlanEnabled ? 6 : 5
        }
      }
    },
    backClicked() {
      this.$router.go(-1)
    },
    editClicked() {
      localStorage.setItem('scrollpos', window.scrollY)
      if (this.$parent.$route.name === 'AdminPropertyDetailsView') {
        this.$router.push({
          name: 'AdminPropertyEditingView',
          params: { id: this.id },
          query: {
            view: this.view,
            elementSelected: this.elementSelected
          }
        })
      } else if (this.$parent.$route.name === 'AssetPropertyDetailsView') {
        this.$router.push({
          name: 'AssetPropertyEditingView',
          params: { id: this.id },
          query: {
            view: this.view,
            elementSelected: this.elementSelected
          }
        })
      } else if (this.$parent.$route.name === 'AccountingPropertyDetailsView') {
        this.$router.push({
          name: 'AccountingPropertyEditingView',
          params: { id: this.id },
          query: {
            view: this.view,
            elementSelected: this.elementSelected
          }
        })
      } else {
        this.$router.push({
          name: 'OwnerPropertyEditingView',
          params: { id: this.id },
          query: {
            view: this.view,
            elementSelected: this.elementSelected
          }
        })
      }
    },
    openSelectedTabs(routeView = '') {
      const view = routeView !== '' ? routeView : this.view
      if (view === 'property') {
        this.tabIndex = 0
      } else if (view === 'landRegister') {
        this.tabIndex = 1
      } else if (view === 'buildings') {
        this.tabIndex = 2
      } else if (view === 'devices') {
        this.tabIndex = 3
      } else if (view === 'investmentPlanning' && this.isInvestPlanEnabled) {
        this.tabIndex = 4
      } else if (view === 'contacts') {
        this.tabIndex = this.isInvestPlanEnabled ? 5 : 4
      } else if (view === 'history') {
        this.tabIndex = this.isInvestPlanEnabled ? 6 : 5
      } else if (view === 'portalLinks') {
        this.tabIndex = this.isInvestPlanEnabled ? 7 : 6
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.item {
  cursor: pointer;
}

:deep(.details-row-bg-1) {
  background-color: $color-table-row-2;
}

:deep(.details-row-bg-2) {
  background-color: $color-table-row-1;
}

:deep(label) {
  width: 100%;
}

:deep(.is-disabled) {
  .sort button{
    display: block !important;
  }

  .multiselect, .custom-checkbox {
    pointer-events: none;

    .multiselect__select, .multiselect__placeholder {
      display: none;
    }
  }

  .filter-inputs .multiselect, .custom-checkbox {
    pointer-events: auto !important;

    .multiselect__select, .multiselect__placeholder {
      display: block !important;
    }
  }

  .basic {
    input {
      opacity: 1;
      border: thin solid #ced4da;
      -webkit-box-shadow: inherit;
      box-shadow: inherit;
      margin-bottom: 0;
      pointer-events: initial;
      padding-left: 0.2rem;
      background: none;
    }
  }

  .multi-select-value {
    line-height: 1.5;
  }
}

:deep(.collapse-title) {
  &.collapsed {
    h5 {
      background-image: url('../assets/arrow_down.svg');
    }
  }

  h5 {
    background-image: url('../assets/arrow_up.svg');
    background-repeat: no-repeat;
    font-size: large;
    font-weight: 500 !important;
    height: 1.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    background-position: right;
  }
}

:deep(.global-card) {
  margin-bottom: 0;

  & > .card-body {
    -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
  }

  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

:deep(.global-card) {
  margin-bottom: 0;

  & > .card-body {
    -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
  }

  border-left: 0;
  border-right: 0;
  border-bottom: 0;

  &.no-top-border {
    border-top: 0;
  }
}
.floating-wrapper{
  right: 5vw;
  bottom: 4vh;
  z-index: 999;
  position: fixed;
  .floating-button{
    border-radius: 100px !important;
    display: flex;
    align-items: center;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.20), 0 4px 4px rgba(0, 0, 0, 0.15);
    z-index: 2;
    justify-content: center;
    background-color: $color-primary-dark;
    padding: 20px !important;
    cursor: pointer;
    svg{
      color: white;
      font-size: 15px;
    }
  }
}
:deep(.tabs .nav-tabs .nav-link a.router-link-active), :deep(.tabs .nav-tabs .nav-link a) {
    color: inherit !important;
    text-decoration: none !important;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation]) {
    margin-right: 0.2rem;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item > a.nav-link) {
    margin-right: 0;
    padding: 0;
    box-shadow: none;
    border: 0;
    z-index: -1;
    height: 26.5px;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link.active > a) {
    font-weight: bold;
    background-color: #fff;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link:hover > a) {
    font-weight: bold;
    cursor: pointer;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link > a) {
    color: rgb(0, 68, 95) !important;
    border-color: #c8ced3 !important;
    border: 1px solid;
    border-radius: 0 !important;
    border-bottom: 0 !important;
    margin-right: 0;
    padding: 0.55rem 1.5rem;
    font-weight: 300;
    -webkit-box-shadow: inset 0 16px 20px -15px rgb(238 238 238), 3px 0 2px -2px #ccc;
    box-shadow: inset 0 16px 20px -15px rgb(238 238 238), 3px 0 2px -2px #ccc;
    cursor: default;
    height: 36.25px;
  }
  :deep(.card.shadow) {
    box-shadow: none !important;
  }
</style>
