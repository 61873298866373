// @ts-nocheck
import network from './network'
import networkTS from './networkTS'

export default {
  getAccountingStatistics(accountId) {
    return network.get(`/web/objects/accounting/getStatistics/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  getLogs(accountId, itemsPerPagepage, page) {
    return network.get(`web/objects/accounting/getLogs/${accountId}?page=${page}&itemsPerPage=${itemsPerPagepage}`)
      .then(response => {
        return response.data
      })
  },
  listAllContractErrors() {
    return network.get('web/objects/accounting/listAllContractErrors')
      .then(response => {
        return response.data
      })
  },
  hasBeenAbacusSynchronized(propertyIds) {
    return network.post('/web/objects/accounting/isEnabled', {
      propertyIds: propertyIds
    })
      .then(response => {
        return response.data
      })
  },
  changeContractRelation(ownerId, numericId, contractRelationNumber) {
      return network.post('/web/objects/accounting/changeContractRelation', {
          ownerId: ownerId,
          numericId: numericId,
          contractRelationNumber: contractRelationNumber
      }).then(response => {
        return response.data
      })
  },
  saveHandoverProcess(handoverProtocol) {
    return networkTS.handoverProtocolServiceCreate(handoverProtocol)
      .then(response => {
        return response.data
      })
  },
  skipNextEvent(accountId) {
    return network.post('/web/objects/accounting/skipNextEvent', {
      accountId: accountId
    })
      .then(response => {
        return response.data
      })
  },
  getObjectAccountingStatistics(accountId) {
    return network.get(`/web/objects/getAccountingStatistics/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  createBuilding(ownerId, address, name = '', egid = '', acquisitionDate = null, note = '') {
    return network.post('/web/objects/building/create', {
      ownerId: ownerId,
      address: address,
      name: name,
      number: -1,
      buildingArea: -1,
      renovationYear: -1,
      constructionYear: -1,
      plotArea: -1,
      floors: -1,
      volume: -1,
      egid: egid,
      acquisitionDate: acquisitionDate,
      note: note
    }).then(response => {
      return response ? response.data : null
    })
  },
  getBuilding(buildingId) {
    return network.get(`/web/objects/building/get/${buildingId}`)
      .then(response => {
        return response.data
      })
  },
  getPropertiesByBuildingIds(buildingIds) {
    return network.get(`/web/v2/objects/property/listByBuildingIds/${buildingIds}`)
      .then(response => {
        return response.data
      })
  },
  updateBuilding(
    buildingId, name, objectIds, egid, number, imageMediaId, realEstateNumber, characteristics, constructionYear, renovationYear,
    buildingZone, plotIds, plotArea, buildingArea, volume, floors,
    locationQuality, constructionTypes, sia, distances, energySources, documentIds, acquisitionDate = null, address, note, contractAmendments, rating, buildingStrategy) {
    return network.post(`/web/objects/building/${buildingId}/update`, {
      name: name,
      objectIds: objectIds,
      egid: egid,
      number: number,
      imageMediaId: imageMediaId,
      realEstateNumber: realEstateNumber,
      characteristics: characteristics,
      constructionTypes: constructionTypes,
      constructionYear: constructionYear,
      renovationYear: renovationYear,
      buildingZone: buildingZone,
      plotIds: plotIds,
      plotArea: plotArea,
      buildingArea: buildingArea,
      volume: volume,
      floors: floors,
      locationQuality: locationQuality,
      sia: sia,
      distances: distances,
      energySources: energySources,
      documentIds: documentIds,
      acquisitionDate: acquisitionDate,
      address: address,
      note: note,
      contractAmendments: contractAmendments,
      rating: rating,
      buildingStrategy: buildingStrategy
    }).then(response => {
      return response.data
    })
  },
  listBuildingByContactIds(contactIds) {
    return network.post('/web/objects/building/listByContactIds', { contactIds: contactIds })
      .then(response => {
        return response.data
      })
  },
  editContactNote(buildingContacts, ids) {
    return network.post('/web/objects/building/editContactNote', { buildingContacts: buildingContacts, ids: ids })
      .then(response => {
        return response.data
      })
  },
  addBuildingContacts(buildingContacts, ids) {
    return network.post('/web/objects/building/addBuildingContacts', { buildingContacts: buildingContacts, ids: ids })
        .then(response => {
          return response.data
        })
  },
  removeBuildingContacts(buildingContacts, ids) {
    return network.post('/web/objects/building/removeBuildingContacts', { buildingContacts: buildingContacts, ids: ids })
        .then(response => {
          return response.data
        })
  },
  moveBuilding(buildingId: string, newPropertyId: string) {
    return network.post(`/web/objects/building/${buildingId}/move`, { newPropertyId })
        .then(response => {
          return response.data
        })
  },
  moveObjects(newBuildingId: string, ids: string[]) {
    return network.post('/web/objects/moveObjects', { newBuildingId, ids })
        .then(response => {
          return response.data
        })
  },
  getTenanciesByIds(ids: string[]) {
    return networkTS.tenancyServiceListByIds({ ids: ids })
      .then(response => {
        return response.data
      })
  },
  getTenancies(objectIds) {
    return network.post('/web/objects/tenancy/list', { objectIds: objectIds })
      .then(response => {
        return response.data
      })
  },
  listCurrentCondominiumsByObjectIds(objectIds: string[]) {
    return network.post('/web/objects/condominium/listCurrentByObjectIds', { objectIds: objectIds })
        .then(response => {
          return response.data
        })
  },
  getCondominiumOwnership(objectIds) {
    return network.post('/web/objects/condominium/listByObjectIds', { objectIds: objectIds })
      .then(response => {
        return response.data
      })
  },
  getCondominiumByIds(condominiumIds) {
    return network.post('/web/objects/condominium/listByIds', { ids: condominiumIds })
      .then(response => {
        return response.data
      })
  },
  getCondominiumByOwnerContact(ownerContactId) {
    return network.get(`/web/objects/condominium/listByOwnerContact/${ownerContactId}`)
      .then(response => {
        return response.data
      })
  },
  getCondominiumByBillingContactId(billingContactId) {
    return network.get(`/web/objects/condominium/listByBillingContactId/${billingContactId}`)
      .then(response => {
        return response.data
      })
  },
  publishUpdatedCondominiumEvents(ownerIds) {
    return network.post('/web/objects/condominium/publishUpdatedEvents', {
      ownerIds: ownerIds
    })
      .then(response => {
        return response.data
      })
  },
  getTenanciesByTenant(
    ownerId, tenantContactId) {
    const url = `/web/objects/tenancy/listByTenant/${ownerId}/tenant/${tenantContactId}`
    return network.get(url)
      .then(response => {
        return response.data
      })
  },
  getTenanciesByGuarantor(
    guarantorContactId) {
    const url = `/web/objects/tenancy/listByGuarantor/${guarantorContactId}`
    return network.get(url)
      .then(response => {
        return response.data
      })
  },
  listCurrentByObjectIds(objectIds) {
    return network.post('/web/objects/tenancy/listCurrentByObjectIds', { objectIds: objectIds })
      .then(response => {
        return response.data
      })
  },
  publishUpdatedTenancyEvents(ownerIds) {
    return network.post('/web/objects/tenancy/publishUpdatedEvents', {
      ownerIds: ownerIds
    })
      .then(response => {
        return response.data
      })
  },
  createCondominiumOwnership(condominium) {
    return network.post('/web/objects/condominium/create', {
      activated: condominium.activated,
      ownerId: condominium.ownerId,
      objectId: condominium.objectId,
      finalInvoiceCreated: condominium.finalInvoiceCreated,
      closed: condominium.closed,
      contract: condominium.contract,
      tenants: condominium.tenants,
      status: condominium.status,
      secondaryResidence: condominium.secondaryResidence,
      startingAt: condominium.startingAt,
      endingAt: condominium.endingAt,
      documentIds: condominium.documentIds,
      owner: condominium.owner,
      rent: condominium.rent,
      taxable: condominium.taxable,
      opted: condominium.opted,
      flatRateValues: condominium.flatRateValues
    }).then(response => {
      return response.data
    })
  },
  updateBulkCondominiumOwnership(condominiums) {
    const array = []
    condominiums.forEach(condominium => {
      const dataSent = {
        id: condominium.id,
        activated: condominium.activated,
        ownerId: condominium.ownerId,
        objectId: condominium.objectId,
        finalInvoiceCreated: condominium.finalInvoiceCreated,
        closed: condominium.closed,
        contract: condominium.contract,
        tenants: condominium.tenants,
        status: condominium.status,
        secondaryResidence: condominium.secondaryResidence,
        startingAt: condominium.startingAt,
        endingAt: condominium.endingAt,
        documentIds: condominium.documentIds,
        owner: condominium.owner,
        rent: condominium.rent,
        taxable: condominium.taxable,
        opted: condominium.opted,
        flatRateValues: condominium.flatRateValues
      }
      array.push(dataSent)
    })
    return network.post('/web/objects/condominium/updateBulk', { entries: array }).then(response => {
      return response.data
    })
  },
  deactivateCondominium(numericId, ownerId: null) {
    let data = {}
    if (ownerId) {
      data = {
        ownerId: ownerId
      }
    }
    return network.post(`/web/objects/condominium/${numericId}/deactivateByNumericId`, data)
      .then(response => {
        return response.data
      })
  },
  deleteCondominium(condominiumId) {
    return network.delete(`/web/objects/condominium/${condominiumId}`).then(response => {
      return response.data
    })
  },
  deleteCondominiumRentAdjustments(byDate, objectIds) {
    const dataSent = {
      byDate: byDate,
      objectIds: objectIds
    }
    return network.post('/web/objects/condominium/deleteRentAdjustments', dataSent).then(response => {
      return response.data
    })
  },
  activateCondominium(id, documentIds = []) {
    return network.post(`/web/objects/condominium/${id}/activate`, {
      id: id,
      documentIds: documentIds
    })
      .then(response => {
        return response.data
      })
  },
  createTenancy(tenancy) {
    if (tenancy.subTenants) {
      for (let index = 0; index < tenancy.subTenants.length; index++) {
        if (tenancy.subTenants[index].endingAt === null) {
          tenancy.subTenants[index].endingAt = tenancy.endingAt
        }
      }
    }
    for (const adjustment of tenancy.rent.adjustments) {
      if ((typeof adjustment?.countryIndex?.baseDate === 'string' || adjustment?.countryIndex?.baseDate instanceof String) && adjustment.countryIndex.baseDate.length > 4) {
        adjustment.countryIndex.baseDate = adjustment.countryIndex.baseDate.slice(-4)
      }
    }
    const dataSent = {
      ownerId: tenancy.ownerId,
      vacancy: tenancy.vacancy,
      vacancyReason: tenancy.vacancyReason,
      contract: tenancy.contract,
      tenant: tenancy.tenant,
      renewalOptions: tenancy.renewalOptions,
      earlyRightOfTerminationOptions: tenancy.earlyRightOfTerminationOptions,
      subTenants: tenancy.subTenants,
      roommates: tenancy.roommates,
      contractAmendments: tenancy.contractAmendments,
      objectId: tenancy.objectId,
      deposit: tenancy.deposit,
      rent: tenancy.rent,
      indexData: tenancy.indexData,
      terminationTenant: tenancy.terminationTenant,
      terminationLandlord: tenancy.terminationLandlord,
      startingAt: tenancy.startingAt,
      endingAt: tenancy.endingAt,
      liabilityEndsAt: tenancy.liabilityEndsAt,
      documentIds: tenancy.documentIds,
      activated: tenancy.activated,
      closed: tenancy.closed,
      opted: tenancy.opted,
      taxable: tenancy.taxable,
      note: tenancy.note,
      familyFlat: tenancy.familyFlat,
      finalInvoiceCreated: tenancy.finalInvoiceCreated,
      secondaryResidence: tenancy.secondaryResidence,
      movedOutAt: tenancy.movedOutAt,
      terminatedOn: tenancy.terminatedOn,
      nextPossibleMoveInAt: tenancy.nextPossibleMoveInAt,
      terminationCreatedAt: tenancy.terminationCreatedAt,
      terminationJustification: tenancy.terminationJustification,
      terminatedBy: tenancy.terminatedBy,
      terminationType: tenancy.terminationType,
      terminationReason: tenancy.terminationReason,
      unjustifiedUse: tenancy.unjustifiedUse,
      terminationSentByTenantAt: tenancy.terminationSentByTenantAt
    }
    return network.post('/web/objects/tenancy/create', dataSent).then(response => {
      return response.data
    })
  },
  updateTenancy(tenancy) {
    for (let index = 0; index < tenancy.subTenants.length; index++) {
      if (tenancy.subTenants[index].endingAt === null) {
        tenancy.subTenants[index].endingAt = tenancy.endingAt
      }
    }
    for (const adjustment of tenancy.rent.adjustments) {
      if ((typeof adjustment?.countryIndex?.baseDate === 'string' || adjustment?.countryIndex?.baseDate instanceof String) && adjustment.countryIndex.baseDate.length > 4) {
        adjustment.countryIndex.baseDate = adjustment.countryIndex.baseDate.slice(-4)
      }
    }
    const dataSent = {
      vacancy: tenancy.vacancy,
      vacancyReason: tenancy.vacancyReason,
      contract: tenancy.contract,
      tenant: tenancy.tenant,
      renewalOptions: tenancy.renewalOptions,
      earlyRightOfTerminationOptions: tenancy.earlyRightOfTerminationOptions,
      objectId: tenancy.objectId,
      deposit: tenancy.deposit,
      rent: tenancy.rent,
      indexData: tenancy.indexData,
      subTenants: tenancy.subTenants,
      roommates: tenancy.roommates,
      contractAmendments: tenancy.contractAmendments,
      terminationTenant: tenancy.terminationTenant,
      terminationLandlord: tenancy.terminationLandlord,
      startingAt: tenancy.startingAt,
      endingAt: tenancy.endingAt,
      liabilityEndsAt: tenancy.liabilityEndsAt,
      documentIds: tenancy.documentIds,
      activated: tenancy.activated,
      closed: tenancy.closed,
      opted: tenancy.opted,
      taxable: tenancy.taxable,
      note: tenancy.note,
      familyFlat: tenancy.familyFlat,
      finalInvoiceCreated: tenancy.finalInvoiceCreated,
      secondaryResidence: tenancy.secondaryResidence,
      movedOutAt: tenancy.movedOutAt,
      terminatedOn: tenancy.terminatedOn,
      nextPossibleMoveInAt: tenancy.nextPossibleMoveInAt,
      terminationCreatedAt: tenancy.terminationCreatedAt,
      terminationReason: tenancy.terminationReason,
      terminationJustification: tenancy.terminationJustification,
      unjustifiedUse: tenancy.unjustifiedUse,
      terminationSentByTenantAt: tenancy.terminationSentByTenantAt,
      terminatedBy: undefined,
      terminationType: undefined,
      returningAt: undefined
    }
    if (tenancy.terminatedBy) {
      dataSent.terminatedBy = tenancy.terminatedBy
    }
    if (tenancy.terminationType) {
      dataSent.terminationType = tenancy.terminationType
    }
    if (tenancy.returningAt) {
      dataSent.returningAt = tenancy.returningAt
    }
    return network.post(`/web/objects/tenancy/${tenancy.id}/update`, dataSent).then(response => {
      return response.data
    })
  },
  updateBulkTenancies(tenancies) {
    const array = []
    tenancies.forEach(tenancy => {
      for (let index = 0; index < tenancy.subTenants.length; index++) {
        if (tenancy.subTenants[index].endingAt === null) {
          tenancy.subTenants[index].endingAt = tenancy.endingAt
        }
      }
      for (const adjustment of tenancy.rent.adjustments) {
        if ((typeof adjustment?.countryIndex?.baseDate === 'string' || adjustment?.countryIndex?.baseDate instanceof String) && adjustment.countryIndex.baseDate.length > 4) {
          adjustment.countryIndex.baseDate = adjustment.countryIndex.baseDate.slice(-4)
        }
      }
      const dataSent = {
        id: tenancy.id,
        vacancy: tenancy.vacancy,
        vacancyReason: tenancy.vacancyReason,
        contract: tenancy.contract,
        tenant: tenancy.tenant,
        renewalOptions: tenancy.renewalOptions,
        earlyRightOfTerminationOptions: tenancy.earlyRightOfTerminationOptions,
        objectId: tenancy.objectId,
        deposit: tenancy.deposit,
        rent: tenancy.rent,
        indexData: tenancy.indexData,
        subTenants: tenancy.subTenants,
        roommates: tenancy.roommates,
        contractAmendments: tenancy.contractAmendments,
        terminationTenant: tenancy.terminationTenant,
        terminationLandlord: tenancy.terminationLandlord,
        startingAt: tenancy.startingAt,
        endingAt: tenancy.endingAt,
        liabilityEndsAt: tenancy.liabilityEndsAt,
        documentIds: tenancy.documentIds,
        activated: tenancy.activated,
        closed: tenancy.closed,
        opted: tenancy.opted,
        taxable: tenancy.taxable,
        note: tenancy.note,
        familyFlat: tenancy.familyFlat,
        finalInvoiceCreated: tenancy.finalInvoiceCreated,
        secondaryResidence: tenancy.secondaryResidence,
        movedOutAt: tenancy.movedOutAt,
        terminatedOn: tenancy.terminatedOn,
        nextPossibleMoveInAt: tenancy.nextPossibleMoveInAt,
        terminationCreatedAt: tenancy.terminationCreatedAt,
        terminationJustification: tenancy.terminationJustification,
        terminationReason: tenancy.terminationReason,
        unjustifiedUse: tenancy.unjustifiedUse,
        terminationSentByTenantAt: tenancy.terminationSentByTenantAt,
        terminatedBy: undefined,
        terminationType: undefined,
        returningAt: undefined
      }
      if (tenancy.terminatedBy) {
        dataSent.terminatedBy = tenancy.terminatedBy
      }
      if (tenancy.terminationType) {
        dataSent.terminationType = tenancy.terminationType
      }
      if (tenancy.returningAt) {
        dataSent.returningAt = tenancy.returningAt
      }
      array.push(dataSent)
    })
    return network.post('/web/objects/tenancy/updateBulk', { entries: array }).then(response => {
      return response.data
    })
  },
  deleteTenancy(tenancyId) {
    return network.delete(`/web/objects/tenancy/${tenancyId}`).then(response => {
      return response.data
    })
  },
  getTenancyById(tenancyId) {
    return network.get(`/web/objects/tenancy/get/${tenancyId}`)
      .then(response => {
        return response.data
      })
  },
  hasActivatedTenancy(objectIds) {
    return network.post('/web/objects/tenancy/hasActivatedTenancy', { objectIds: objectIds })
      .then(response => {
        return response.data
      })
  },
  setTermination(tenancyId, termination) {
    return network.post(`/web/objects/tenancy/${tenancyId}/setTermination`, {
      terminatedOn: termination.terminatedOn,
      terminateAt: termination.terminateAt,
      liabilityEndsAt: termination.liabilityEndsAt,
      nextPossibleMoveInAt: termination.nextPossibleMoveInAt,
      movedOutAt: termination.movedOutAt,
      terminationCreatedAt: termination.terminationCreatedAt,
      terminatedBy: termination.terminatedBy,
      terminationReason: termination.terminationReason,
      terminationType: termination.terminationType,
      terminationJustification: termination.terminationJustification,
      documentIds: termination.documentIds,
      terminationSentByTenantAt: termination.terminationSentByTenantAt
    })
      .then(response => {
        return response.data
      })
  },
  deleteTenancyRentAdjustments(byDate, objectIds) {
    const dataSent = {
      byDate: byDate,
      objectIds: objectIds
    }
    return network.post('/web/objects/tenancy/deleteRentAdjustments', dataSent).then(response => {
      return response.data
    })
  },
  renderDevice(id, assigneeContactId, contactId, devices, executeFrom, executeUntil, orderDescription, onSiteContactPersonId, randomContactData, attachAutomatically, signatories = [], enforcePdf = false, orderNumber = '') {
    return network.post('/web/v2/objects/contract/template/renderDevice', {
      id: id,
      assigneeContactId: assigneeContactId,
      contactId: contactId,
      devices: devices,
      executeFrom: executeFrom,
      executeUntil: executeUntil,
      orderDescription: orderDescription,
      onSiteContactPersonId: onSiteContactPersonId,
      randomContactData: randomContactData,
      attachAutomatically: attachAutomatically,
      signatories: signatories,
      enforcePdf: enforcePdf,
      orderNumber: orderNumber
    })
      .then(response => {
        return response.data
      })
  },
  getDevicesByReferenceIds(referenceIds) {
    return network.post('/web/objects/device/listByReferenceIds', { referenceIds: referenceIds })
      .then(response => {
        return response.data
      })
  },
  listDevicesByIds(ids) {
    return network.post('/web/objects/device/listByIds', { ids: ids })
      .then(response => {
        return response.data
      })
  },
  getMarketingStatistics(accountId, contactId) {
    return network.get(`/web/objects/getMarketingStatistics/${accountId}?contactIdAssigneeMarketing=${contactId}`)
      .then(response => {
        return response.data
      })
  },
  createSettlement(settlement) {
    return network.post('/web/objects/settlement-object/create', {
      activated: settlement.activated,
      additionalCostsBillingType: settlement.additionalCostsBillingType,
      billingContactId: settlement.billingContactId,
      billingStart: settlement.billingStart,
      billingEnd: settlement.billingEnd,
      description: settlement.description,
      ownerId: settlement.ownerId,
      ownerIsDebtor: settlement.ownerIsDebtor,
      referenceId: settlement.referenceId,
      rentAdjustments: settlement.rentAdjustments,
      correspondenceContactId: settlement.correspondenceContactId,
      opted: settlement.opted
    }).then(response => {
      return response.data
    })
  },
  updateSettlement(settlement) {
    return network.post(`/web/objects/settlement-object/${settlement.id}/update`, {
      activated: settlement.activated,
      billingStart: settlement.billingStart,
      billingEnd: settlement.billingEnd,
      billingContactId: settlement.billingContactId,
      description: settlement.description,
      additionalCostsBillingType: settlement.additionalCostsBillingType,
      ownerIsDebtor: settlement.ownerIsDebtor,
      rentAdjustments: settlement.rentAdjustments,
      correspondenceContactId: settlement.correspondenceContactId,
      opted: settlement.opted
    }).then(response => {
      return response.data
    })
  },
  getSettlementByReferenceId(referenceId) {
    return network.get(`/web/objects/settlement-object/listByReferenceId/${referenceId}`)
      .then(response => {
        return response.data
      })
  },
  deleteSettlement(id) {
    return network.delete(`/web/objects/settlement-object/${id}/delete`)
      .then(response => {
        return response.data
      })
  },
  createDevice(device) {
    for (const item in device.maintenance) {
      if (device.maintenance[item] === '-1') {
        delete device.maintenance[item]
      }
    }
    for (const item in device.inspection) {
      if (device.inspection[item] === '-1') {
        delete device.inspection[item]
      }
    }
    for (const item in device.revision) {
      if (device.revision[item] === '-1') {
        delete device.revision[item]
      }
    }
    return network.post('/web/objects/device/create', {
      ownerId: device.ownerId,
      referenceId: device.referenceId,
      type: device.type,
      system: device.system,
      measure: device.measure,
      measureUntil: device.measureUntil,
      facilityPlant: device.facilityPlant,
      manufacturer: device.manufacturer,
      brand: device.brand,
      model: device.model,
      serialNumber: device.serialNumber,
      bkpH: device.bkpH,
      installation: device.installation,
      uninstallation: device.uninstallation,
      warrantyEnd: device.warrantyEnd,
      acquisitionCosts: device.acquisitionCosts,
      cataloguePrice: device.cataloguePrice,
      documentIds: device.documentIds,
      maintenance: device.maintenance,
      inspection: device.inspection,
      revision: device.revision,
      note: device.note,
      amount: device.amount,
      lifeSpan: device.lifeSpan,
      lockingKey: device.lockingKey,
      condition: device.condition,
      unit: device.unit,
      images: device.images,
      roomId: device.roomId
    }).then(response => {
      localStorage.setItem('newDeviceId', response.data.device.id)
      return response.data
    })
  },
  updateDevice(device) {
    return network.post(`/web/objects/device/${device.id}/update`, {
      referenceId: device.referenceId,
      type: device.type,
      system: device.system,
      measure: device.measure,
      measureUntil: device.measureUntil,
      facilityPlant: device.facilityPlant,
      manufacturer: device.manufacturer,
      brand: device.brand,
      model: device.model,
      serialNumber: device.serialNumber,
      bkpH: device.bkpH,
      installation: device.installation,
      uninstallation: device.uninstallation,
      warrantyEnd: device.warrantyEnd,
      acquisitionCosts: device.acquisitionCosts,
      cataloguePrice: device.cataloguePrice,
      documentIds: device.documentIds,
      maintenance: device.maintenance,
      inspection: device.inspection,
      revision: device.revision,
      note: device.note,
      amount: device.amount,
      lifeSpan: device.lifeSpan,
      lockingKey: device.lockingKey,
      condition: device.condition,
      unit: device.unit,
      images: device.images,
      roomId: device.roomId
    }).then(response => {
      return response.data
    })
  },
  deleteDevice(deviceId) {
    return network.delete(`/web/objects/device/${deviceId}`).then(response => {
      return response.data
    })
  },
  getInsurancesByReferenceId(referenceId) {
    return network.get(`/web/objects/insurance/listByReferenceIds/${referenceId}`)
      .then(response => {
        return response.data
      })
  },
  createInsurance(insurance) {
    return network.post('/web/objects/insurance/create', {
      ownerId: insurance.ownerId,
      referenceId: insurance.referenceId,
      type: insurance.type ? insurance.type : 'INSURANCE_TYPE_UNDEFINED',
      notes: insurance.notes,
      benefits: insurance.benefits ? insurance.benefits : 'INSURANCE_BENEFIT_UNDEFINED',
      premiumPaidUntil: insurance.premiumPaidUntil,
      insuranceCompany: insurance.insuranceCompany,
      policyNumber: insurance.policyNumber,
      amount: insurance.amount,
      yearlyCosts: insurance.yearlyCosts,
      excess: insurance.excess,
      startDate: insurance.startDate,
      endDate: insurance.endDate,
      earliestTerminationDate: insurance.earliestTerminationDate,
      documentIds: insurance.documentIds
    }).then(response => {
      return response.data
    })
  },
  updateInsurance(insurance) {
    return network.post(`/web/objects/insurance/${insurance.id}/update`, {
      referenceId: insurance.referenceId,
      type: insurance.type ? insurance.type : 'INSURANCE_TYPE_UNDEFINED',
      notes: insurance.notes,
      benefits: insurance.benefits ? insurance.benefits : 'INSURANCE_BENEFIT_UNDEFINED',
      premiumPaidUntil: insurance.premiumPaidUntil,
      insuranceCompany: insurance.insuranceCompany,
      policyNumber: insurance.policyNumber,
      amount: insurance.amount,
      yearlyCosts: insurance.yearlyCosts,
      excess: insurance.excess,
      startDate: insurance.startDate,
      endDate: insurance.endDate,
      earliestTerminationDate: insurance.earliestTerminationDate,
      documentIds: insurance.documentIds
    }).then(response => {
      return response.data
    })
  },
  deleteInsurance(insuranceId) {
    return network.delete(`/web/objects/insurance/${insuranceId}`).then(response => {
      return response.data
    })
  },
  createMortgage(mortgage) {
    return network.post('/web/objects/mortgage/create', {
      amortisationAmount: mortgage.amortisationAmount,
      ownerId: mortgage.ownerId,
      referenceId: mortgage.referenceId,
      documentIds: mortgage.documentIds,
      amortizations: mortgage.amortizations,
      amortizationFrom: mortgage.amortizationFrom,
      amount: mortgage.amount,
      annualAmortisation: mortgage.annualAmortisation,
      bankName: mortgage.bankName,
      bankNumber: mortgage.bankNumber,
      bic: mortgage.bic,
      designation: mortgage.designation,
      dueDateMonthsAmortization: mortgage.dueDateMonthsAmortization,
      dueDateMonthsInterestRate: mortgage.dueDateMonthsInterestRate,
      expiresAt: mortgage.expiresAt,
      interestRate: mortgage.interestRate,
      debitAccount: mortgage.debitAccount,
      mortgageDesignation: mortgage.mortgageDesignation,
      enableAmortizations: mortgage.enableAmortizations,
      mortgageeAddress: mortgage.mortgageeAddress,
      number: mortgage.number,
      terminableOn: mortgage.terminableOn,
      validFrom: mortgage.validFrom
    }).then(response => {
      return response.data
    })
  },
  updateMortgage(mortgage) {
    return network.post(`/web/objects/mortgage/${mortgage.id}/update`, {
      amortisationAmount: mortgage.amortisationAmount,
      ownerId: mortgage.ownerId,
      referenceId: mortgage.referenceId,
      documentIds: mortgage.documentIds,
      amortizations: mortgage.amortizations,
      amortizationFrom: mortgage.amortizationFrom,
      amount: mortgage.amount,
      annualAmortisation: mortgage.annualAmortisation,
      bankName: mortgage.bankName,
      bankNumber: mortgage.bankNumber,
      bic: mortgage.bic,
      designation: mortgage.designation,
      dueDateMonthsAmortization: mortgage.dueDateMonthsAmortization,
      dueDateMonthsInterestRate: mortgage.dueDateMonthsInterestRate,
      expiresAt: mortgage.expiresAt,
      interestRate: mortgage.interestRate,
      debitAccount: mortgage.debitAccount,
      enableAmortizations: mortgage.enableAmortizations,
      mortgageDesignation: mortgage.mortgageDesignation,
      mortgageeAddress: mortgage.mortgageeAddress,
      number: mortgage.number,
      terminableOn: mortgage.terminableOn,
      validFrom: mortgage.validFrom
    }).then(response => {
      return response.data
    })
  },
  deleteMortgage(mortgageIds) {
    return network.delete(`/web/objects/mortgage/${mortgageIds}`).then(response => {
      return response.data
    })
  },
  getMortgagesByReferenceIds(referenceIds) {
    return network.post('/web/objects/mortgage/listByReferenceIds', { referenceIds: referenceIds })
      .then(response => {
        return response.data
      })
  },
  createEnergySource(energySource) {
    return network.post('/web/objects/energy-source/create', {
      ownerId: energySource.ownerId,
      referenceId: energySource.referenceId,
      type: energySource.type,
      kind: energySource.kind,
      unit: energySource.unit,
      costDistribution: energySource.costDistribution,
      costDistribution2: energySource.costDistribution2,
      consumptionKwhPerYear: energySource.consumptionKwhPerYear,
      co2PerYear: energySource.co2PerYear,
      consumption: energySource.consumption,
      documentIds: energySource.documentIds,
      tankReadings: energySource.tankReadings,
      energyReferenceArea: energySource.energyReferenceArea,
      heatDistribution: energySource.heatDistribution,
      orders: energySource.orders,
      tankSize: energySource.tankSize,
      photovoltaic: energySource.photovoltaic,
      selfConsumptionCommunity: energySource.selfConsumptionCommunity
    }).then(response => {
      return response.data
    })
  },
  updateEnergySource(energySource) {
    return network.post(`/web/objects/energy-source/${energySource.id}/update`, {
      ownerId: energySource.ownerId,
      referenceId: energySource.referenceId,
      type: energySource.type,
      kind: energySource.kind,
      unit: energySource.unit,
      costDistribution: energySource.costDistribution,
      costDistribution2: energySource.costDistribution2,
      consumptionKwhPerYear: energySource.consumptionKwhPerYear,
      co2PerYear: energySource.co2PerYear,
      consumption: energySource.consumption,
      documentIds: energySource.documentIds,
      tankReadings: energySource.tankReadings,
      tankSize: energySource.tankSize,
      energyReferenceArea: energySource.energyReferenceArea,
      heatDistribution: energySource.heatDistribution,
      orders: energySource.orders,
      photovoltaic: energySource.photovoltaic,
      selfConsumptionCommunity: energySource.selfConsumptionCommunity
    }).then(response => {
      return response.data
    })
  },
  getEnergySourcesByReferenceIds (referenceIds) {
    return network.post('/web/objects/energy-source/listByReferenceIds', { referenceIds: referenceIds })
      .then(response => {
        return response.data
      })
  },
  deleteEnergySources(energySourceIds) {
    return network.delete(`/web/objects/energy-source/${energySourceIds}`).then(response => {
      return response.data
    })
  },
  createObject(ownerId, buildingId, address, category) {
    return network.post('/web/objects/create', {
      ownerId: ownerId,
      buildingId: buildingId,
      address: address,
      type: 'RENT',
      publishAddress: true,
      category: category,
      subCategories: ['UNDEFINED_SUB_CATEGORY'],
      utilizations: ['UNDEFINED_UTILIZATION'],
      development: 'UNDEFINED_DEVELOPMENT',
      deposit: {
        amount: -1,
        depositInsurance: 'UNDEFINED_DEPOSIT_INSURANCE',
        depositNeeded: false
      },
      characteristics: {
        areas: {
          livingArea: -1,
          netLivingArea: -1,
          plotArea: -1,
          effectiveArea: -1
        },
        volume: -1,
        firstTimeOccupancy: -1,
        numberOfFloors: -1,
        rooms: -1,
        bathrooms: -1,
        showers: -1,
        toilets: -1,
        guestToilets: -1,
        separateToilets: -1,
        ceilingHeight: -1,
        hallHeight: -1,
        floor: -10000,
        floorLoad: -1,
        grossPremium: -1,
        yearBuilt: -1,
        yearLastRenovation: -1,
        tags: [],
        wheelchairAccessible: 'UNDEFINED_VALUE'
      }
    }).then(response => {
      return response.data
    })
  },
  getObject(id) {
    return networkTS.objectServiceGet(id).then(response => {
      return response.data
    })
  },
  getMarketingObject(id) {
    return network.get(`/web/objects/getMarketing/${id}`).then(response => {
      return response.data
    })
  },
  createBulkPropertyObject(bulk) {
    return network.post(
      '/web/objects/createBulk',
      {
        address: bulk.address,
        amount: bulk.amount,
        buildingId: bulk.buildingId,
        administrationType: bulk.administrationType,
        category: bulk.category,
        ownerId: bulk.ownerId,
        type: bulk.type
      },
      {
        timeout: 40000
      }).then(response => {
      return response.data
    })
  },
  getTenancyListByContactId(contactId, billingContact = false, tenants = false, correspondence = false, guarantors = false, subTenants = false) {
    return networkTS.tenancyServiceListByContactId({ contactId: contactId, billingContact: billingContact, tenants: tenants, correspondence: correspondence, guarantors: guarantors, subTenants: subTenants })
      .then(response => {
        return response.data
      })
  },
  getCondominiumListById(contactId, billingContact = false, owners = false, correspondence = false, roommates = false, tenants = false) {
    return networkTS.condominiumServiceListByContactId({ contactId: contactId, billingContact: billingContact, owners: owners, correspondence: correspondence, roommates: roommates, tenants: tenants })
      .then(response => {
        return response.data
      })
  },
  listObjects(
    accountId, page, perPage, address = null, categories = [], zipFrom = null, zipTo = null, tenantContactIds = [], networkPartnerIds = [],
    sortFloor = null, sortRentPrice = null) {
    let url = `/web/objects/list/${accountId}?page=${page}&itemsPerPage=${perPage}`

    if (address) {
      url += `&address=${address}`
    }
    for (const category of categories) {
      url += `&categories=${category}`
    }
    if (zipFrom) {
      url += `&zipFrom=${zipFrom}`
    }
    if (zipTo) {
      url += `&zipTo=${zipTo}`
    }
    for (const contactIds of tenantContactIds) {
      url += `&tenantContactIds=${contactIds}`
    }
    for (const partnerId of networkPartnerIds) {
      url += `&networkPartnerIds=${partnerId}`
    }
    if (sortFloor) {
      url += `&sortFloor=${sortFloor.toUpperCase()}`
    }
    if (sortRentPrice) {
      url += `&sortRentPrice=${sortRentPrice.toUpperCase()}`
    }

    return network.get(url)
      .then(response => {
        return response.data
      })
  },
  listObjectsByIds(objectIds) {
    return network.post('/web/objects/listByIds', { ids: objectIds })
      .then(response => {
        return response.data
      })
  },
  listFreeByDate(buildingIds, date) {
    return networkTS.objectServiceListFreeByDate({ buildingIds: buildingIds, date: date })
      .then(response => {
        return response.data
      })
  },
  listObjectsByBuildingId(buildingId) {
    return network.post('/web/objects/listByBuildingIds', { buildingIds: [buildingId] })
      .then(response => {
        return response.data
      })
  },
  getPropertyCount(accountId, scope = 'UNDEFINED_SCOPE') {
    return network.get(`/web/v2/objects/property/count/${accountId}?scope=${scope}`)
      .then(response => {
        return response.data
      })
  },
  getBuildingCount(accountId, scope = 'UNDEFINED_SCOPE') {
    return network.get(`/web/objects/building/count/${accountId}?scope=${scope}`)
      .then(response => {
        return response.data
      })
  },
  getAdministrationStatistics(accountId) {
    return network.get(`/web/objects/getAdministrationStatistics/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  getAssetStatistics(accountId) {
    return network.get(`/web/objects/getAssetStatistics/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  getOwnerStatistics(accountId) {
    return network.get(`/web/objects/getOwnerStatistics/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  getBuildingByObjectId(objectId) {
    return network.get(`/web/objects/building/getByObjectId/${objectId}`)
      .then(response => {
        return response.data
      })
  },
  getBuildingByObjectIds(objectIds: string[]) {
    return networkTS.buildingServiceListByObjectIds({ objectIds: objectIds })
      .then(response => {
        return response.data
      })
  },
  updateObject(object) {
    return network.post(`/web/objects/${object.id}/update`, {
      ewid: object.ewid,
      awn: object.awn,
      name: object.name,
      number: object.number,
      type: object.type,
      marketingLanguage: object.marketingLanguage,
      publishAddress: object.publishAddress,
      administrationType: object.administrationType,
      category: object.category,
      subCategories: object.subCategories,
      utilizations: object.utilizations,
      development: object.development,
      deposit: object.deposit,
      images: object.images,
      inactiveBy: object.inactiveBy,
      parking: object.parking,
      renovations: object.renovations,
      videos: object.videos,
      documents: object.documents,
      note: object.note,
      characteristics: object.characteristics,
      pricing: object.pricing,
      title: object.title,
      description: object.description,
      vacant: object.vacant,
      published: object.published,
      needsMatching: object.needsMatching,
      condominium: object.condominium,
      positions: object.positions,
      virtualTourUrl: object.virtualTourUrl,
      contacts: object.contacts,
      defaultAnswer: object.defaultAnswer,
      availability: object.availability,
      viewingAppointment: object.viewingAppointment,
      energySources: object.energySources,
      assignedSideRooms: object.assignedSideRooms,
      sharedUse: object.sharedUse,
      specialFeatures: object.specialFeatures,
      unusualAgreement: object.unusualAgreement,
      valueQuota: object.valueQuota,
      marketingStatus: object.marketingStatus,
      tags: object.tags,
      otoId: object.otoId,
      acceptsCreditTrust: object.acceptsCreditTrust
    }).then(response => {
      return response.data
    })
  },
  deleteObject(objectId) {
    return network.delete(`/web/objects/${objectId}`).then(response => {
      return response.data
    })
  },
  deleteProperty(propertyIds) {
    return network.delete(`/web/v2/objects/property/${propertyIds}`).then(response => {
      return response.data
    })
  },
  deleteBuilding(buildingId) {
    return network.delete(`/web/objects/building/${buildingId}`).then(response => {
      return response.data
    })
  },
  createProperties(accountId, property) {
    if (!property.plotArea || property.plotArea === '') {
      property.plotArea = -1
    }
    return network.post('/web/v2/objects/property/create', {
      address: property.address,
      buildingIds: property.buildingIds,
      constructionZone: property.constructionZone,
      development: property.development,
      documentIds: property.documentIds,
      egrid: property.egrid,
      imageMediaId: property.imageMediaId,
      landRegisterNumber: property.landRegisterNumber,
      name: property.name,
      ownerId: accountId,
      plotArea: property.plotArea,
      opted: property.opted,
      optedPortionKey: property.optedPortionKey === '' || property.optedPortionKey === null ? 'OPTED_PORTION_KEY_UNDEFINED' : property.optedPortionKey,
      type: property.type === '' || property.type === null ? 'PROPERTY_TYPE_UNDEFINED' : property.type
    }).then(response => {
      return response.data
    })
  },
  createPlots(plots) {
    return network.post('/web/objects/plot/create', { plotCreations: plots }
    ).then(response => {
      return response.data
    })
  },
  updatePlots(plots) {
    return network.post('/web/objects/plot/updatePlots', { plotUpdates: plots }
    ).then(response => {
      return response.data
    })
  },
  deletePlots(ids) {
    return network.delete(`/web/objects/plot/${ids}`).then(response => {
      return response.data
    })
  },
  listByPropertyId(propertyId) {
    return network.get(`/web/objects/plot/listByPropertyId/${propertyId}`)
      .then(response => {
        return response.data
      })
  },
  listPlotbyIds(plotIds) {
    return network.get(`/web/objects/plot/listByIds/${plotIds}`)
      .then(response => {
        return response.data
      })
  },
  getPropertyListByIds(ids) {
    return network.get(`/web/v2/objects/property/listByIds/${ids}`)
      .then(response => {
        return response.data
      })
  },
  getAllowedPropertyTypes(accountId) {
    return network.get(`/web/v2/objects/property/getAllowedPropertyTypes/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  getBuildingsListByIds(ids) {
    return network.post('/web/objects/building/listByIds', { ids: ids })
      .then(response => {
        return response.data
      })
  },
  updateProperty(property) {
    return network.post(`/web/v2/objects/property/${property.id}/update`, {
      buildingIds: property.buildingIds,
      address: property.address,
      constructionZone: property.constructionZone,
      development: property.development,
      documentIds: property.documentIds,
      egrid: property.egrid,
      imageMediaId: property.imageMediaId,
      landRegisterNumber: property.landRegisterNumber,
      name: property.name,
      plotArea: property.plotArea === null || property.plotArea === '' ? -1 : property.plotArea,
      advancePaymentRentComponents: property.advancePaymentRentComponents,
      flatRateRentComponents: property.flatRateRentComponents,
      additionalRentComponents: property.additionalRentComponents,
      opted: property.opted,
      optedPortionKey: property.optedPortionKey === '' || property.optedPortionKey === null ? 'OPTED_PORTION_KEY_UNDEFINED' : property.optedPortionKey,
      type: property.type === '' || property.type === null ? 'PROPERTY_TYPE_UNDEFINED' : property.type,
      note: property.note,
      bankInfo: property.bankinfo,
      propertyValue: property.propertyValue
    }).then(response => {
      return response.data
    })
  },
  transferPropertiesToAccounting(ids) {
    return network.post('/web/v2/objects/property/transferToAccounting', {
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  listByCoordinates(southWestLat, southWestLng, northEastLat, northEastLng, scope) {
    const url = `/web/objects/building/listByCoordinates?southWestLat=${southWestLat}&southWestLng=${southWestLng}&northEastLat=${northEastLat}&northEastLng=${northEastLng}&scope=${scope}`
    return network.get(url)
      .then(response => {
        return response.data
      })
  },
  publishObject(ids, unPublishAt = null, unPublishAtAmountOfRequests = null) {
    return network.post('/web/objects/publish', {
      ids: ids,
      unPublishAt: unPublishAt,
      unPublishAtAmountOfRequests: unPublishAtAmountOfRequests
    }).then(response => {
      return response.data
    })
  },
  unPublishObject(ids) {
    return network.post('/web/objects/unpublish', {
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  setMarketingAssignee(contactIdAssigneeMarketing, ids) {
    return network.post('/web/objects/setMarketingAssignee', {
      contactIdAssigneeMarketing: contactIdAssigneeMarketing,
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  setViewingAppointmentContact(contactIdViewingAppointment, ids) {
    return network.post('/web/objects/setViewingAppointmentContact', {
      contactIdViewingAppointment: contactIdViewingAppointment,
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  setAdminAssignee(contactIdAssigneeAdministration, ids) {
    return network.post('/web/objects/setAdminAssignee', {
      contactIdAssigneeAdministration: contactIdAssigneeAdministration,
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  setAccountingAssignee(contactIdAssigneeAccounting, ids) {
    return network.post('/web/objects/setAccountingAssignee', {
      contactIdAssigneeAccounting: contactIdAssigneeAccounting,
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  setAssetAssignee(contactIdAssigneeAsset, ids) {
    return network.post('/web/objects/setAssetAssignee', {
      contactIdAssigneeAsset: contactIdAssigneeAsset,
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  setOwnerAssignee(contactIdAssigneeOwner, ids) {
    return network.post('/web/objects/setOwnerAssignee', {
      contactIdAssigneeOwner: contactIdAssigneeOwner,
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  marketingMarkSeen(id) {
    return network.post(`/web/objects/marketingMarkSeen/${id}`)
      .then(response => {
        return response.data
      })
  },
  markForMarketing(id, availability, marketingNote, pricing, startMarketingAt, deposit, title, description, documents, images, parking, publishAddress, videos, virtualTourUrl) {
    const requestData = {
      availability: availability,
      id: id,
      marketingNote: marketingNote,
      pricing: pricing,
      deposit: deposit,
      description: description,
      documents: documents,
      images: images,
      parking: parking,
      publishAddress: publishAddress,
      title: title,
      videos: videos,
      virtualTourUrl: virtualTourUrl,
      startMarketingAt: undefined
    }
    if (startMarketingAt !== '') {
      requestData.startMarketingAt = startMarketingAt
    }
    return network.post(`/web/objects/markForMarketing/${id}`, requestData)
      .then(response => {
        return response.data
      })
  },
  getApplicationLink(id) {
    return network.get(`/web/objects/${id}/getApplicationLink`)
      .then(response => {
        return response.data
      })
  },
  getApplicationInfo(objectId, language = 'DE') {
    return network.get(`/web/objects/${objectId}/getApplicationInfo?language=${language}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf'
      }
    })
      .then(response => {
        return response.data
      })
  },
  getMarketingExpose(objectId, language) {
    return network.get(`/web/objects/${objectId}/getMarketingExpose?language=${language}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf'
      }
    })
      .then(response => {
        return response.data
      })
  },
  getTemplateContract(ownerIds) {
    return network.get(`/web/v2/objects/contract/template/list/${ownerIds.join(',')}`)
      .then(response => {
        return response.data
      })
  },
  deleteContractTemplate(ids) {
    return network.delete(`/web/v2/objects/contract/template/delete/${ids}`).then(response => {
      return response.data
    })
  },
  createContractTemplate(contract, ownerId) {
    return network.post('/web/v2/objects/contract/template/create', {
      mediaId: contract.mediaId,
      ownerId: contract.ownerId !== '' ? contract.ownerId : ownerId,
      title: contract.title,
      creatorName: contract.creatorName,
      category: contract.category,
      enforcePdf: contract.enforcePdf,
      language: contract.language
    })
      .then(response => {
        return response.data
      })
  },
  updateContractTemplate(contract) {
    return networkTS.contractServiceUpdateTemplateMixin2(contract.id, {
      title: contract.title,
      mediaId: contract.mediaId,
      category: contract.category,
      creatorName: contract.creatorName,
      enforcePdf: contract.enforcePdf,
      language: contract.language,
      ownerId: contract.ownerId
      }).then(response => {
          return response.data
        })
  },
  RenderTenancyTemplate(contractId, tenancyId, attachAutomatically = false, title, description, sendingProfile = '', signatories = [], useGuarantorAddress = false, enforcePdf = false) {
    return networkTS.contractServiceRenderTenancyTemplate({
      attachAutomatically: attachAutomatically,
      id: contractId,
      description: description,
      sendingProfile: sendingProfile,
      signatories: signatories,
      tenancyId: tenancyId,
      title: title,
      useGuarantorAddress: useGuarantorAddress,
      enforcePdf: enforcePdf
      }).then(response => {
          return response.data
        })
  },
  renderTenanciesTemplate(contractId, tenancyIds, title, description, signatories = [], sendingProfile = '', attachAutomatically = false, order: 'RENDER_ORDER_DEFAULT', enforcePdf = false) {
    return networkTS.contractServiceRenderTenanciesTemplateMixin2({
      attachAutomatically: attachAutomatically,
      id: contractId,
      description: description,
      order: order,
      sendingProfile: sendingProfile,
      signatories: signatories,
      tenancyIds: tenancyIds,
      title: title,
      enforcePdf: enforcePdf
    }).then(response => {
          return response.data
        })
  },
  getRenderStatus(renderId) {
    return network.get(`web/v2/objects/contract/template/getRenderStatus/${renderId}`)
      .then(response => {
        return response.data
      })
  },
  listRenderStatus(renderIds) {
    return network.post('web/v2/objects/contract/template/listRenderStatus', {
      renderIds: renderIds
    })
      .then(response => {
        return response.data
      })
  },
  getRenderFile(renderId) {
    return network.get(`web/v2/objects/contract/template/getRenderFile/${renderId}`)
      .then(response => {
        return response.data
      })
  },
  getRenderFiles(renderIds) {
    return network.post('web/v2/objects/contracts/template/getRenderFiles', {
      renderIds: renderIds
    },
    {
      timeout: 300000
    })
      .then(response => {
        return response.data
      })
  },
  deleteRenderFile(renderId) {
    return network.delete(`/web/v2/objects/contract/template/deleteRenderFile/${renderId}`).then(response => {
      return response.data
    })
  },
  deleteRenderFiles(renderIds) {
    return network.post('/web/v2/objects/contract/template/deleteRenderFiles', {
      renderIds: renderIds
    }).then(response => {
      return response.data
    })
  },
  renderContractCondominium(contractId, condominiumId, attachAutomatically = false, title, description, includeRoommates, sendingProfile, signatories = [], enforcePdf = false) {
    return networkTS.contractServiceRenderCondominiumTemplateMixin2({
      attachAutomatically: attachAutomatically,
      condominiumId: condominiumId,
      id: contractId,
      description: description,
      includeRoommates: includeRoommates,
      sendingProfile: sendingProfile,
      signatories: signatories,
      title: title,
      enforcePdf: enforcePdf
    }).then(response => {
          return response.data
        })
  },
  renderContractCondominiums(contractId, condominiumIds, title, description, includeRoommates, sendingProfile = '', signatories = [], attachAutomatically = false, order: 'RENDER_ORDER_DEFAULT', enforcePdf = false) {
    return networkTS.contractServiceRenderCondominiumsTemplateMixin2({
      attachAutomatically: attachAutomatically,
      condominiumIds: condominiumIds,
      id: contractId,
      description: description,
      includeRoommates: includeRoommates,
      order: order,
      sendingProfile: sendingProfile,
      signatories: signatories,
      title: title,
      enforcePdf: enforcePdf
    }).then(response => {
          return response.data
        })
  },
  renderContractContact(contractId, contactId, attachAutomatically = false, title, description, sendingProfile = '', signatories = [], enforcePdf = false) {
    return networkTS.contractServiceRenderContactTemplateMixin2({
      attachAutomatically: attachAutomatically,
      contactId: contactId,
      id: contractId,
      description: description,
      sendingProfile: sendingProfile,
      signatories: signatories,
      title: title,
      enforcePdf: enforcePdf
    }).then(response => {
          return response.data
        })
  },
  renderContractContacts(contractId, contactIds, title, description, sendingProfile = '', signatories = [], attachAutomatically = false, order: 'RENDER_ORDER_DEFAULT', enforcePdf = false) {
    return networkTS.contractServiceRenderContactsTemplateMixin2({
      attachAutomatically: attachAutomatically,
      contactIds: contactIds,
      id: contractId,
      description: description,
      order: order,
      sendingProfile: sendingProfile,
      signatories: signatories,
      title: title,
      enforcePdf: enforcePdf
    }).then(response => {
          return response.data
        })
  },
  removeServiceProviders(ids, contactIdsServiceProvider) {
    return network.post('/web/objects/removeServiceProviders', {
      contactIdsServiceProvider: contactIdsServiceProvider,
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  removeJanitors(ids, contactIdsJanitor) {
    return network.post('/web/objects/removeJanitors', {
      contactIdsJanitor: contactIdsJanitor,
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  publishUpdatedObjectEvents(ownerIds) {
    return network.post('/web/objects/publishUpdatedEvents', {
      ownerIds: ownerIds
    })
      .then(response => {
        return response.data
      })
  },
  removeCraftsmen(ids, contactIdsCraftsman) {
    return network.post('/web/objects/removeCraftsmen', {
      contactIdsCraftsman: contactIdsCraftsman,
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  addServiceProviders(ids, contactIdsServiceProvider) {
    return network.post('/web/objects/addServiceProviders', {
      contactIdsServiceProvider: contactIdsServiceProvider,
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  addJanitors(ids, contactIdsJanitor) {
    return network.post('/web/objects/addJanitors', {
      contactIdsJanitor: contactIdsJanitor,
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  addCraftsmen(ids, contactIdsCraftsman) {
    return network.post('/web/objects/addCraftsmen', {
      contactIdsCraftsman: contactIdsCraftsman,
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  getObjectTypes(accountId: string) {
    return networkTS.objectServiceGetObjectTypes(accountId)
      .then(response => {
        return response.data
      })
  },
  setDefaultAnswer(id, defaultAnswer) {
    return network.post(`/web/objects/${id}/setDefaultAnswer`, {
      defaultAnswer: defaultAnswer,
      id: id
    })
      .then(response => {
        return response.data
      })
  },
  setViewingAppointment(id, viewingAppointment) {
    return network.post(`/web/objects/${id}/setViewingAppointment`, {
      viewingAppointment: viewingAppointment,
      id: id
    })
      .then(response => {
        return response.data
      })
  },
  addTenancyDocument(id, documentIds) {
    return network.post(`/web/objects/tenancy/${id}/addDocument`, {
      documentIds: documentIds,
      id: id
    })
      .then(response => {
        return response.data
      })
  },
  activateTenancy(id) {
    return network.post(`/web/objects/tenancy/${id}/activate`, {
      id: id
    })
      .then(response => {
        return response.data
      })
  },
  closeTenancy(ids) {
    return network.post('/web/objects/tenancy/close', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  deactivateTenancy(numericId, ownerId: null) {
    let data = {}
    if (ownerId) {
      data = {
        ownerId: ownerId
      }
    }
    return network.post(`/web/objects/tenancy/${numericId}/deactivateByNumericId`, data)
      .then(response => {
        return response.data
      })
  },
  removeFromMarketing(ids) {
    return network.post('/web/objects/removeFromMarketing', { ids: ids })
      .then(response => {
        return response.data
      })
  },
  readCountryIndex(fileUrl) {
    return network.post('/web/objects/country-index/read', { fileUrl: fileUrl }, { timeout: 40000 })
      .then(response => {
        return response.data
      })
  },
  getInflation(COUNTRY_INDEX, month, year) {
    const url = `/web/objects/country-index/getInflation?baseYear=${COUNTRY_INDEX}&month=${month}&year=${year}`
    return network.get(url)
      .then(response => {
        return response.data
      })
  },
  updateMarketingInfo(id, marketingNote, startMarketingAt) {
    return network.post(`/web/objects/updateMarketingInfo/${id}`, {
      id: id,
      marketingNote: marketingNote,
      startMarketingAt: startMarketingAt
    })
      .then(response => {
        return response.data
      })
  },
  generateDemoDataSet(accountId, dataSet) {
    return network.post(
      '/web/objects/demo-data/generate',
      {
        accountId: accountId,
        dataSet: dataSet
      },
      { timeout: 300000 }
    ).then(response => {
      return response.data
    })
  },
  getTotalValueQuotaForBuilding(buildingId) {
    const url = `web/objects/getTotalValueQuotaForBuilding/${buildingId}`
    return network.get(url)
      .then(response => {
        return response.data
      })
  },
  sellProperty(propertyId, targetOwnerId, keepNetworkPartners) {
    return network.post('/web/v2/objects/property/sell', {
      id: propertyId,
      targetOwnerId,
      keepNetworkPartners
    }).then(response => {
      return response.data
    })
  },
  getUsedTags(accountId) {
    return network.get(`/web/objects/getUsedTags/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  loadFahrlaenderConfig(accountId) {
    return network.get(`/web/objects/fahrlaender/${accountId}/loadConfiguration`)
      .then(response => {
        return response.data
      })
  },
  saveFahrlaenderConfig(accountId, apiUser, apiPassword, features) {
    return network.post(`/web/objects/fahrlaender/${accountId}/saveConfiguration`, {
      apiUser,
      apiPassword,
      features
    })
      .then(response => {
        return response.data
      })
  },
  startFahrlaenderMicroLocationRating(accountId) {
    return network.post(`/web/objects/fahrlaender/${accountId}/updateMicroLocationRating`)
      .then(response => {
        return response.data
      })
  },
  startFahrlaenderMarketRent(accountId) {
    return network.post(`/web/objects/fahrlaender/${accountId}/updateMarketRent`)
      .then(response => {
        return response.data
      })
  },
  rentalIndexTemplateCreate(fields, language, name, ownerId, tenancyType, sortingType, compositionType, compositionLinesOrColumns) {
    return network.post('/web/objects/rental-index/create', {
      fields: fields,
      language: language,
      name: name,
      ownerId: ownerId,
      tenancyType: tenancyType,
      sortingType: sortingType,
      compositionType: compositionType,
      compositionLinesOrColumns: compositionLinesOrColumns
    })
      .then(response => {
        return response.data
      })
  },
  rentalIndexTemplatesList(accountId) {
    return network.get(`/web/objects/rental-index/list?accountId=${accountId}`)
      .then(response => {
        return response.data
      })
  },
  rentalIndexTemplateUpdate(fields, id, language, name, tenancyType, sortingType, compositionType, compositionLinesOrColumns) {
    return network.post(`/web/objects/rental-index/${id}/update`, {
      fields: fields,
      language: language,
      name: name,
      tenancyType: tenancyType,
      sortingType: sortingType,
      compositionType: compositionType,
      compositionLinesOrColumns: compositionLinesOrColumns
    })
      .then(response => {
        return response.data
      })
  },
  rentalIndexTemplateDelete(templateId) {
    return network.delete(`/web/objects/rental-index/${templateId}`)
      .then(response => {
        return response.data
      })
  },
  rentalIndexRender(buildingIds, endDate, fields, language, startDate, tenancyType, sortingType, compositionType, compositionLinesOrColumns) {
    return network.post('/web/objects/rental-index/render', {
      buildingIds: buildingIds,
      endDate: endDate,
      fields: fields,
      language: language,
      startDate: startDate,
      tenancyType: tenancyType,
      sortingType: sortingType,
      compositionType: compositionType,
      compositionLinesOrColumns: compositionLinesOrColumns
    },
    {
      timeout: 600000
    })
      .then(response => {
        return response.data.file
      })
  },
  rentalIndexRenderPdf(buildingIds, language, startDate, endDate, sortingType, buildingBreakPage, displayPropertySummary, withoutTenantsNames) {
    return network.post('/web/objects/rental-index/renderPdf', {
      buildingIds: buildingIds,
      language: language,
      startDate: startDate,
      endDate: endDate,
      sortingType: sortingType,
      buildingBreakPage: buildingBreakPage,
      displayPropertySummary: displayPropertySummary,
      withoutTenantsNames: withoutTenantsNames
    },
    {
      timeout: 600000
    })
      .then(response => {
        return response.data.file
      })
  },
  propertyListFilterOptions() {
    return network.get('/web/v2/objects/property/listFilterOptions')
      .then(response => {
        return response.data
      })
  },
  buildingListFilterOptions() {
    return network.get('/web/objects/building/listFilterOptions')
      .then(response => {
        return response.data
      })
  },
  objectListFilterOptions() {
    return network.get('/web/objects/listFilterOptions')
      .then(response => {
        return response.data
      })
  },
  listIdsInMarketing(marketerAccountId) {
    return network.get('/web/objects/listIdsInMarketing/' + marketerAccountId)
      .then(response => {
        return response.data
      })
  },
  getMortgagesByIds(ids) {
    return networkTS.mortgageServiceListByIds({ ids: ids })
      .then(response => {
        return response.data
      })
  },
  getInsurancesByIds(ids) {
    return networkTS.insuranceServiceListByIds(ids)
      .then(response => {
        return response.data
      })
  },
  accountingGetEnabled(accountId) {
    return networkTS.accountingServiceGetEnabled(accountId)
      .then(response => {
        return response.data
      })
  },
  accountingSetEnabled(data) {
    return networkTS.accountingServiceSetEnabled(data)
      .then(response => {
        return response.data
      })
  }
}
