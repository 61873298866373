<template>
  <section>
    <div
      v-b-toggle="'collapse-sia-areas'"
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ $t('message.onBoarding.buildings.objects.areas.title') }}
      </h5>
    </div>
    <b-collapse
      :id="'collapse-sia-areas'"
      :ref="'collapse'"
      class="row"
      :visible="expandByDefault || openedCollapses.includes('collapse-sia-areas')">
      <div class="col">
        <!-- Flächen -->
        <div class="row details-row-bg-1 py-01rem mt-3">
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaGf"
              type="number"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.objects.areas.siaGf')"
              :check-valide="$v.siaNgf.$dirty && $v.siaNgf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaNgf"
              type="number"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.objects.areas.siaNgf')"
              :check-valide="$v.siaNgf.$dirty && $v.siaNgf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaKf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaKf')"
              :check-valide="$v.siaKf.$dirty && $v.siaKf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaNf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaNf')"
              :check-valide="$v.siaNf.$dirty && $v.siaNf.$error ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaVf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaVf')"
              :check-valide="$v.siaVf.$dirty && $v.siaVf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaFf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaFf')"
              :check-valide="$v.siaFf.$dirty && $v.siaFf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaKft"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaKft')"
              :check-valide="$v.siaKft.$dirty && $v.siaKft.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaKfn"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaKfn')"
              :check-valide="$v.siaKfn.$dirty && $v.siaKfn.$error ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaNnf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaNnf')"
              :check-valide="$v.siaNnf.$dirty && $v.siaNnf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaHnf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaHnf')"
              :check-valide="$v.siaHnf.$dirty && $v.siaHnf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaHnf1"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaHnf1')"
              :check-valide="$v.siaHnf1.$dirty && $v.siaHnf1.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaHnf2"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaHnf2')"
              :check-valide="$v.siaHnf2.$dirty && $v.siaHnf2.$error ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaHnf3"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaHnf3')"
              :check-valide="$v.siaHnf3.$dirty && $v.siaHnf3.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaHnf4"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaHnf4')"
              :check-valide="$v.siaHnf4.$dirty && $v.siaHnf4.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaHnf5"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaHnf5')"
              :check-valide="$v.siaHnf5.$dirty && $v.siaHnf5.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaHnf6"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaHnf6')"
              :check-valide="$v.siaHnf6.$dirty && $v.siaHnf6.$error ? 'is-invalid' : ''" />
          </div>
        </div>
        <!-- Aussenflächen -->
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaAgf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaAgf')"
              :check-valide="$v.siaAgf.$dirty && $v.siaAgf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaAngf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaAngf')"
              :check-valide="$v.siaAngf.$dirty && $v.siaAngf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaAkf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaAkf')"
              :check-valide="$v.siaAkf.$dirty && $v.siaAkf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaAnf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaAnf')"
              :check-valide="$v.siaAnf.$dirty && $v.siaAnf.$error ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaAvf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaAvf')"
              :check-valide="$v.siaAvf.$dirty && $v.siaAvf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaAff"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaAff')"
              :check-valide="$v.siaAff.$dirty && $v.siaAff.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaAkft"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaAkft')"
              :check-valide="$v.siaAkft.$dirty && $v.siaAkft.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaAkfn"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaAkfn')"
              :check-valide="$v.siaAkfn.$dirty && $v.siaAkfn.$error ? 'is-invalid' : ''" />
          </div>
        </div>
        <!-- Grundstücksfläche -->
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaGsf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaGsf')"
              :check-valide="$v.siaGsf.$dirty && $v.siaGsf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaUf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaUf')"
              :check-valide="$v.siaUf.$dirty && $v.siaUf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaGgf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaGgf')"
              :check-valide="$v.siaGgf.$dirty && $v.siaGgf.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaBuf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaBuf')"
              :check-valide="$v.siaBuf.$dirty && $v.siaBuf.$error ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-6 col-md-3">
            <coozzy-form-input-numeric
              v-model="siaUuf"
              type="number"
              min="0"
              placeholder="m²"
              :is-decimal="true"
              :is-read-only="readOnly"
              :filter="'formatArea'"
              :name="$t('message.onBoarding.buildings.objects.areas.siaUuf')"
              :check-valide="$v.siaUuf.$dirty && $v.siaUuf.$error ? 'is-invalid' : ''" />
          </div>
        </div>
      </div>
    </b-collapse>
  </section>
</template>

<script>

import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import { onboarding } from '../../../mixins/onboarding'
import { minValue } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'

export default {
  name: 'SiaAreas',
  components: { CoozzyFormInputNumeric },
  mixins: [onboarding, validation],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    expandByDefault: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    siaAff: {
      get() {
        return this.building.sia.siaAff !== -1 ? this.building.sia.siaAff : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaAff', value: value })
      }
    },
    siaAgf: {
      get() {
        return this.building.sia.siaAgf !== -1 ? this.building.sia.siaAgf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaAgf', value: value })
      }
    },
    siaAkf: {
      get() {
        return this.building.sia.siaAkf !== -1 ? this.building.sia.siaAkf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaAkf', value: value })
      }
    },
    siaAkfn: {
      get() {
        return this.building.sia.siaAkfn !== -1 ? this.building.sia.siaAkfn : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaAkfn', value: value })
      }
    },
    siaAkft: {
      get() {
        return this.building.sia.siaAkft !== -1 ? this.building.sia.siaAkft : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaAkft', value: value })
      }
    },
    siaAnf: {
      get() {
        return this.building.sia.siaAnf !== -1 ? this.building.sia.siaAnf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaAnf', value: value })
      }
    },
    siaAngf: {
      get() {
        return this.building.sia.siaAngf !== -1 ? this.building.sia.siaAngf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaAngf', value: value })
      }
    },
    siaAvf: {
      get() {
        return this.building.sia.siaAvf !== -1 ? this.building.sia.siaAvf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaAvf', value: value })
      }
    },
    siaBuf: {
      get() {
        return this.building.sia.siaBuf !== -1 ? this.building.sia.siaBuf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaBuf', value: value })
      }
    },
    siaFf: {
      get() {
        return this.building.sia.siaFf !== -1 ? this.building.sia.siaFf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaFf', value: value })
      }
    },
    siaGf: {
      get() {
        return this.building.sia.siaGf !== -1 ? this.building.sia.siaGf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaGf', value: value })
      }
    },
    siaGgf: {
      get() {
        return this.building.sia.siaGgf !== -1 ? this.building.sia.siaGgf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaGgf', value: value })
      }
    },
    siaGsf: {
      get() {
        return this.building.sia.siaGsf !== -1 ? this.building.sia.siaGsf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaGsf', value: value })
      }
    },
    siaHnf: {
      get() {
        return this.building.sia.siaHnf !== -1 ? this.building.sia.siaHnf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaHnf', value: value })
      }
    },
    siaKf: {
      get() {
        return this.building.sia.siaKf !== -1 ? this.building.sia.siaKf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaKf', value: value })
      }
    },
    siaKfn: {
      get() {
        return this.building.sia.siaKfn !== -1 ? this.building.sia.siaKfn : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaKfn', value: value })
      }
    },
    siaKft: {
      get() {
        return this.building.sia.siaKft !== -1 ? this.building.sia.siaKft : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaKft', value: value })
      }
    },
    siaNf: {
      get() {
        return this.building.sia.siaNf !== -1 ? this.building.sia.siaNf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaNf', value: value })
      }
    },
    siaNgf: {
      get() {
        return this.building.sia.siaNgf !== -1 ? this.building.sia.siaNgf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaNgf', value: value })
      }
    },
    siaNnf: {
      get() {
        return this.building.sia.siaNnf !== -1 ? this.building.sia.siaNnf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaNnf', value: value })
      }
    },
    siaHnf1: {
      get() {
        return this.building.sia.siaHnf1 !== -1 ? this.building.sia.siaHnf1 : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaHnf1', value: value })
      }
    },
    siaHnf2: {
      get() {
        return this.building.sia.siaHnf2 !== -1 ? this.building.sia.siaHnf2 : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaHnf2', value: value })
      }
    },
    siaHnf3: {
      get() {
        return this.building.sia.siaHnf3 !== -1 ? this.building.sia.siaHnf3 : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaHnf3', value: value })
      }
    },
    siaHnf4: {
      get() {
        return this.building.sia.siaHnf4 !== -1 ? this.building.sia.siaHnf4 : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaHnf4', value: value })
      }
    },
    siaHnf5: {
      get() {
        return this.building.sia.siaHnf5 !== -1 ? this.building.sia.siaHnf5 : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaHnf5', value: value })
      }
    },
    siaHnf6: {
      get() {
        return this.building.sia.siaHnf6 !== -1 ? this.building.sia.siaHnf6 : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaHnf6', value: value })
      }
    },
    siaUf: {
      get() {
        return this.building.sia.siaUf !== -1 ? this.building.sia.siaUf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaUf', value: value })
      }
    },
    siaUuf: {
      get() {
        return this.building.sia.siaUuf !== -1 ? this.building.sia.siaUuf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaUuf', value: value })
      }
    },
    siaVf: {
      get() {
        return this.building.sia.siaVf !== -1 ? this.building.sia.siaVf : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateSia', { field: 'siaVf', value: value })
      }
    }
  },
  methods: {
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      return this.$v.$invalid
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    }
  },
  validations: {
    siaGf: {
      minValue: minValue(0)
    },
    siaNgf: {
      minValue: minValue(0)
    },
    siaKf: {
      minValue: minValue(0)
    },
    siaNf: {
      minValue: minValue(0)
    },
    siaVf: {
      minValue: minValue(0)
    },
    siaFf: {
      minValue: minValue(0)
    },
    siaKft: {
      minValue: minValue(0)
    },
    siaKfn: {
      minValue: minValue(0)
    },
    siaNnf: {
      minValue: minValue(0)
    },
    siaHnf: {
      minValue: minValue(0)
    },
    siaHnf1: {
      minValue: minValue(0)
    },
    siaHnf2: {
      minValue: minValue(0)
    },
    siaHnf3: {
      minValue: minValue(0)
    },
    siaHnf4: {
      minValue: minValue(0)
    },
    siaHnf5: {
      minValue: minValue(0)
    },
    siaHnf6: {
      minValue: minValue(0)
    },
    siaAgf: {
      minValue: minValue(0)
    },
    siaAngf: {
      minValue: minValue(0)
    },
    siaAkf: {
      minValue: minValue(0)
    },
    siaAnf: {
      minValue: minValue(0)
    },
    siaAvf: {
      minValue: minValue(0)
    },
    siaAff: {
      minValue: minValue(0)
    },
    siaAkft: {
      minValue: minValue(0)
    },
    siaAkfn: {
      minValue: minValue(0)
    },
    siaGsf: {
      minValue: minValue(0)
    },
    siaUf: {
      minValue: minValue(0)
    },
    siaGgf: {
      minValue: minValue(0)
    },
    siaBuf: {
      minValue: minValue(0)
    },
    siaUuf: {
      minValue: minValue(0)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
