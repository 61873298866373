import Vue from 'vue'
import store from '../store'
import EmployeeApi from '@/misc/apis/EmployeeApi'

function sortEmployees(employees) {
  return employees.sort(function (a, b) {
    if (a.deleted) {
      return 1
    } else if (b.deleted) {
      return -1
    } else if (a.profile && a.profile.lastName && b.profile && b.profile.lastName) {
      return a.profile.lastName.localeCompare(b.profile.lastName)
    } else {
      return 1
    }
  })
}

export default {
  namespaced: true,
  state: {
    loading: false,
    loadedAt: 0,
    employees: []
  },
  getters: {
    getEmployees: (state) => {
      return state.employees
    },
    getAdminEmployees: (state) => {
      return state.employees.filter(e => e.roles.includes('company_admin') && !e.deleted)
    },
    getNormalEmployees: (state) => {
      return state.employees.filter(e => e.roles.includes('company_default') &&
        !e.roles.includes('company_admin') &&
        !e.deleted)
    }
  },
  mutations: {
    setEmployees: (state, employees) => {
      Vue.set(state, 'employees', employees)
      state.loadedAt = Date.now()
      state.loading = false
    },
    updateEmployee: (state, employee) => {
      const index = state.employees
        .findIndex(e => e.id === employee.id)
      Vue.set(state.employees, index, employee)
      state.loadedAt = Date.now()
      state.loading = false
    },
    setLoading: (state) => {
      state.loading = true
    },
    resetEmployees: (state) => {
      state.loading = false
      state.loadedAt = 0
      Vue.set(state, 'employees', [])
    }
  },
  actions: {
    loadEmployees: async ({ commit, state }, force = false) => {
      return new Promise((resolve, reject) => {
        // Setup watcher for accountId if not yet available
        if (!store.getters['user/getAccountId']) {
          commit('setLoading')

          store.watch((watchedState, getters) => getters['user/getAccountId'], value => {
            EmployeeApi.getEmployees(value)
              .then(value => {
                const data = value.employees
                const employees = sortEmployees(data)
                commit('setEmployees', employees)
                resolve(employees)
              })
              .catch(e => {
                console.error(e)
                reject(e)
              })
          })
        } else if (force || (state.loading === false && (state.loadedAt === 0 || (Date.now() - state.loadedAt) > 600000))) {
          // If force OR loading is not already triggered and stored employees are older than 10 minutes -> Load new data
          commit('setLoading')

          // Load and sort users
          EmployeeApi.getEmployees(store.getters['user/getAccountId'])
            .then(value => {
              const data = value.employees
              const employees = sortEmployees(data)
              commit('setEmployees', employees)
              resolve(employees)
            })
            .catch(e => {
              console.error(e)
              reject(e)
            })
        } else {
          resolve(state.employees)
        }
      })
    },
    resetEmployees({ commit }) {
      commit('resetEmployees')
    }
  }
}
