const routeChecks = {
  computed: {
    moduleRoutePrefix() {
      if (this.isOwnerModule) {
        return 'Owner'
      } else if (this.isAdminModule) {
        return 'Admin'
      } else if (this.isMarketingModule) {
        return 'Marketing'
      } else if (this.isUserModule) {
        return 'User'
      } else if (this.isAssetModule) {
        return 'Asset'
      } else if (this.isAccountingModule) {
        return 'Accounting'
      } else {
        return ''
      }
    },
    isOwnerModule() {
      return this.$route.path.startsWith('/owner')
    },
    isAdminModule() {
      return this.$route.path.startsWith('/admin')
    },
    isMarketingModule() {
      return this.$route.path.startsWith('/marketing')
    },
    isUserModule() {
      return this.$route.path.startsWith('/user')
    },
    isAssetModule() {
      return this.$route.path.startsWith('/asset')
    },
    isAccountingModule() {
      return this.$route.path.startsWith('/accounting')
    },
    isPropBaseOnboarding() {
      return this.$route.name === 'OwnerOnboardingView'
    },
    isAdminBaseOnboarding() {
      return this.$route.name === 'AdminOnboardingView'
    },
    isAssetBaseOnboarding() {
      return this.$route.name === 'AssetOnboardingView'
    },
    isAccountingOnboarding() {
      return this.$route.name === 'AccountingOnboardingView'
    },
    isOnboarding() {
      return this.isPropBaseOnboarding || this.isAdminBaseOnboarding || this.isAssetBaseOnboarding || this.isAccountingOnboarding
    },
    isPropBaseBuildingDetails() {
      return this.$route.name === 'OwnerBuildingDetailsView'
    },
    isAdminBaseBuildingDetails() {
      return this.$route.name === 'AdminBuildingDetailsView'
    },
    isAssetBaseBuildingDetails() {
      return this.$route.name === 'AssetBuildingDetailsView'
    },
    isAccountingBaseBuildingDetails() {
      return this.$route.name === 'AccountingBuildingDetailsView'
    },
    isBuildingDetails() {
      return this.isPropBaseBuildingDetails || this.isAdminBaseBuildingDetails || this.isAssetBaseBuildingDetails || this.isAccountingBaseBuildingDetails
    }
  }
}

export { routeChecks }
