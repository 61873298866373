<template>
  <div class="container-fluid">
    <div
      v-if="ticketDeleted === true"
      class="row">
      <h1 class="col-12 mt-3 mb-3 pl-0 text-center text-lg">
        {{ $t('message.loadingErrors.ticketDeleted') }}
      </h1>
    </div>
    <ticket-details-view
      v-else-if="ticket"
      :public-ticket="ticket"
      :email="email" />
  </div>
</template>

<script>
import TicketApi from '@/misc/apis/TicketApi'
import Vue from 'vue'
import TicketDetailsView from './TicketDetailsView'

export default {
  name: 'TicketPublicView',
  components: {
    TicketDetailsView
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ticket: null,
      ticketDeleted: false
    }
  },
  mounted() {
    if (this.id !== '') {
        TicketApi.getByPublicId(this.id)
          .then(response => {
            const ticket = response.ticket
            if (!localStorage.getItem('isCoozzyUserAuthenticated') || localStorage.getItem('isCoozzyUserAuthenticated') === 'false') {
            this.ticket = ticket
            } else if (localStorage.getItem('isCoozzyUserAuthenticated') === 'true') {
              if (localStorage.getItem('hasAccessAdminBase')) {
                this.$router.push({
                  name: 'AdminTicketDetailsView',
                  params: { id: ticket.key }
                }, () => {
                })
              } else if (localStorage.getItem('hasAccessPropBase')) {
                this.$router.push({
                  name: 'OwnerTicketDetailsView',
                  params: { id: ticket.key }
                }, () => {
                })
              } else if (localStorage.getItem('hasAccessAssetBase')) {
                this.$router.push({
                  name: 'AssetTicketDetailsView',
                  params: { id: ticket.key }
                }, () => {
                })
              } else if (localStorage.getItem('hasAccessAccountingBase')) {
                this.$router.push({
                  name: 'AccountingTicketDetailsView',
                  params: { id: ticket.key }
                }, () => {
                })
              } else if (localStorage.getItem('hasAccessMarketBase')) {
                this.$router.push({
                  name: 'MarketingTicketDetailsView',
                  params: { id: ticket.key }
                }, () => {
                })
              }
            }
          })
          .catch(e => {
            const error = e.response.data
            if (error.code === 5 && error.message === 'ticket not found') {
              this.ticketDeleted = true
            } else {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.ticket'), { type: 'error' })
            }
          })
    }
  }
}
</script>
<style lang="scss" scoped>
.text-lg {
  font-size: 2.1875rem;
}
</style>
