export default {
  namespaced: true,
  state: {
    deepSignEnabled: false
  },
  getters: {
    getDeepSignStatus: (state) => {
      return state.deepSignEnabled
    }
  },
  mutations: {
    setDeepSignStatus: (state, value) => {
      state.deepSignEnabled = value
    }
  },
  actions: {
    setDeepSignStatus: ({ commit }, value) => {
      commit('setDeepSignStatus', value)
    }
  }
}
