<template>
  <section>
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-else>
      <!-- Heading -->
      <div class="row details-row-bg-1 py-01rem">
        <div class="col-sm-6 col-md-3">
          <coozzy-form-input
            v-model="landRegisterNumber"
            :is-read-only="readOnly"
            :name="$t('message.propertiesView.landRegisterNumber')" />
        </div>
        <div class="col-sm-6 col-md-3">
          <coozzy-form-input
            v-model="egrid"
            class="mb-2"
            :is-read-only="readOnly"
            :filter="'formatNumber'"
            :name="$t('message.propertiesView.egrid')" />
        </div>
        <div class="col-sm-6 col-md-3">
          <coozzy-form-input-numeric
            v-model="plotArea"
            type="number"
            :is-decimal="true"
            :is-read-only="readOnly"
            placeholder="m²"
            :filter="'formatArea'"
            :name="$t('message.advertisement.plotArea')" />
        </div>
        <div class="col-sm-6 col-md-3">
          <coozzy-form-input
            v-model="constructionZone"
            class="mb-2"
            :is-read-only="readOnly"
            :name="$t('message.propertiesView.constructionZone')" />
        </div>
      </div>
      <div class="row details-row-bg-2 py-01rem">
        <div class="col-sm-6 col-md-3">
          <coozzy-form-input
            v-model="plotType"
            :is-read-only="readOnly"
            :name="$t('message.propertiesView.createLandRegister.plotType')" />
        </div>
        <div class="col-sm-6 col-md-3">
          <coozzy-form-input-numeric
            v-model="cadastralValue"
            type="number"
            :is-read-only="readOnly"
            :filter="'formatPrice'"
            placeholder="CHF"
            :is-decimal="true"
            :name="$t('message.propertiesView.createLandRegister.cadastralValue')" />
        </div>
        <div class="col-sm-6 col-md-3">
          <coozzy-form-input-numeric
            v-model="purchasePrice"
            type="number"
            :is-read-only="readOnly"
            :filter="'formatPrice'"
            placeholder="CHF"
            :is-decimal="true"
            :name="$t('message.propertiesView.createLandRegister.plotPurchasePrice')" />
        </div>
        <div class="col-sm-6 col-md-3">
          <coozzy-form-input
            v-model="utilizationZone"
            :is-read-only="readOnly"
            :name="$t('message.propertiesView.createLandRegister.utilizationZone')" />
        </div>
      </div>
      <div class="row details-row-bg-1 py-01rem">
        <div class="col-sm-6 col-md-3">
          <coozzy-form-input
            v-model="noiseSensitivity"
            :is-read-only="readOnly"
            :name="$t('message.propertiesView.createLandRegister.noiseSensitivity')" />
        </div>
        <div
          v-if="readOnly"
          class="col-sm-6 col-md-3">
          <label>{{ $t('message.generic.building') }}</label>
          <p
            class="mb-0 multi-select-value">
            {{ buildings.map(a => a.name).toString() }}
          </p>
        </div>
        <div
          v-else
          class="col-sm-6 col-md-3">
          <label>{{ $t('message.generic.building') }}</label>
          <coozzy-multiselect
            v-model="buildings"
            :options="buildingList"
            :multiple="true"
            :placeholder="$t('message.generic.building')"
            :custom-label="customLabel"
            track-by="id" />
        </div>
        <div class="col-sm-6 col-md-3">
          <coozzy-form-input
            v-model="formOfOwnership"
            :is-read-only="readOnly"
            :name="$t('message.propertiesView.createLandRegister.formOfOwnership')" />
        </div>
      </div>
      <div class="row details-row-bg-2 py-01rem">
        <div class="col-sm-12 col-md-12">
          <label for="note">{{ $t('message.propertiesView.createLandRegister.remark') }}</label>
          <coozzy-form-textarea
            id="note"
            ref="note"
            v-model="note"
            :is-read-only="readOnly"
            :initial="note"
            :value-text-area="note"
            :show-label="false"
            name="note" />
        </div>
      </div>
      <div class="row details-row-bg-1 py-01rem">
        <div class="col-sm-12 col-md-12">
          <label for="easements">{{ $t('message.propertiesView.createLandRegister.easement') }}</label>
          <coozzy-form-textarea
            id="easements"
            ref="easements"
            v-model="easements"
            :initial="easements"
            :is-read-only="readOnly"
            :value-text-area="easements"
            :show-label="false"
            name="easements" />
        </div>
      </div>
      <div class="row details-row-bg-2 py-01rem">
        <div class="col-sm-12 col-md-12">
          <label for="priorityNotices">{{ $t('message.propertiesView.createLandRegister.priorityNotices') }}</label>
          <coozzy-form-textarea
            id="priorityNotices"
            ref="priorityNotices"
            v-model="priorityNotices"
            :initial="priorityNotices"
            :is-read-only="readOnly"
            :value-text-area="priorityNotices"
            :show-label="false"
            name="priorityNotices" />
        </div>
      </div>
      <div class="row details-row-bg-1 py-01rem">
        <div class="col-sm-12 col-md-12">
          <label for="realEstateRights">{{ $t('message.propertiesView.createLandRegister.realEstateRights') }}</label>
          <coozzy-form-textarea
            id="realEstateRights"
            ref="realEstateRights"
            v-model="realEstateRights"
            :initial="realEstateRights"
            :is-read-only="readOnly"
            :value-text-area="realEstateRights"
            :show-label="false"
            name="realEstateRights" />
        </div>
      </div>
      <div class="row details-row-bg-2 py-01rem">
        <div class="col-sm-12 col-md-12">
          <label for="inventories">{{ $t('message.propertiesView.createLandRegister.inventories') }}</label>
          <coozzy-form-textarea
            id="inventories"
            ref="inventories"
            v-model="inventories"
            :is-read-only="readOnly"
            :initial="inventories"
            :value-text-area="inventories"
            :show-label="false"
            name="inventories" />
        </div>
      </div>
      <div class="row details-row-bg-1 py-01rem">
        <div class="col-sm-12 col-md-12">
          <label for="loads">{{ $t('message.propertiesView.createLandRegister.load') }}</label>
          <coozzy-form-textarea
            id="loads"
            ref="loads"
            v-model="loads"
            :initial="loads"
            :is-read-only="readOnly"
            :value-text-area="loads"
            :show-label="false"
            name="loads" />
        </div>
      </div>
      <div class="col-12 p-0 mt-3">
        <h5
          v-b-toggle.collapse-property-document
          class="cursor-pointer">
          {{ $t('message.propertiesView.createLandRegister.landRegisterDocuments') }} ({{ landRegisterDocuments.length }})
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-property-document"
          :visible="true">
          <div
            v-if="filteredLandRegisterDocuments.length > 0"
            class="row mt-2">
            <div class="col-12">
              <documents-list
                :documents-list="filteredLandRegisterDocuments"
                :is-read-only="readOnly"
                @version-added="versionAdded"
                @document-deleted="deleteDocument" />
            </div>
          </div>
          <div
            v-if="!readOnly"
            class="row">
            <add-document-modal
              :suffix="'land-register'"
              :owner-id="plot.ownerId"
              @document-created="documentCreated" />
            <div class="col-2 mt-1">
              <coozzy-button
                class="w-100"
                design="prop-green"
                @click="addDocumentClicked('land-register')">
                {{ $t('message.manageDocuments.addDocument') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        v-if="!readOnly"
        class="col-12 p-0 mt-3">
        <div
          class="row pr-3">
          <div class="col-12 mt-3">
            <coozzy-button
              size="small"
              design="red"
              class="float-right"
              @click="removeClicked">
              {{ $t('message.propertiesView.createLandRegister.delete') }}
            </coozzy-button>
          </div>
        </div>
        <b-modal
          :id="'deleteModal'"
          ref="deleteModal"
          no-close-on-backdrop
          hide-footer
          :title="$t('message.onBoarding.deleteModals.confirmation')">
          <div class="col">
            <p>{{ $t('message.onBoarding.deleteModals.bodyPlot') }}</p>
          </div>
          <div class="col">
            <coozzy-button
              size="small"
              class="mb-0 border"
              design="transparent"
              @click="$bvModal.hide('deleteModal')">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              size="small"
              class="float-right mb-0"
              design="green"
              @click="removeConfirmed">
              {{ $t('message.generic.delete') }}
            </coozzy-button>
          </div>
        </b-modal>
      </div>
    </div>
  </section>
</template>

<script>
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import CoozzyFormInputNumeric from '../framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import CoozzyFormInput from '../framework/components/form/input/CoozzyFormInput'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import { user } from '@/mixins/user'
import MediaApi from '../misc/apis/MediaApi'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import DocumentsList from '@/properties/components/DocumentsList.vue'
// import ObjectApi from '@/misc/apis/ObjectApi'
// import Vue from 'vue'

export default {
  name: 'LandRegister',
  components: {
    DocumentsList,
    CoozzyFormTextarea,
    CoozzySpinner,
    CoozzyFormInput,
    CoozzyButton,
    AddDocumentModal,
    CoozzyMultiselect,
    CoozzyFormInputNumeric
  },
  mixins: [user],
  props: {
    property: {
      type: Object,
      default: null
    },
    landRegister: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    buildingList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: false,
      loaded: false,
      hasChanged: false,
      landRegisterDocuments: [],
      buildings: [],
      availableTypes: [
        'SITUATION_PLAN',
        'PURCHASE_CONTRACT',
        'CONSTRUCTION_LAW_AGREEMENT',
        'EASEMENT_AGREEMENT',
        'CADASTRAL_ESTIMATE',
        'OTHER',
        'LAND_REGISTER'
      ],
      documentName: '',
      documentType: '',
      plot: this.landRegister
? this.landRegister
: {
        documentIds: [],
        landRegisterNumber: '',
        plotArea: null,
        egrid: '',
        constructionZone: '',
        note: '',
        cadastralValue: null,
        easements: '',
        inventories: '',
        formOfOwnership: '',
        loads: '',
        noiseSensitivity: '',
        plotType: '',
        priorityNotices: '',
        propertyId: this.id,
        purchasePrice: null,
        realEstateRights: '',
        utilizationZone: ''
      }
    }
  },
  computed: {
    filteredLandRegisterDocuments() {
      return this.landRegisterDocuments.filter(doc => {
        const cdtName = doc.name.includes(this.documentName)
        const cdtType = this.documentType === '' || doc.type === this.documentType
        return cdtName && cdtType
      }).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    translatedAvailableTypes() {
      const array = this.availableTypes
      array.sort((a, b) => {
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) < this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return -1
        }
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) > this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return 1
        }
        return 0
      })
      return array
    },
    egrid: {
      get() {
        return this.plot.egrid !== -1 ? this.plot.egrid : null
      },
      set(value) {
        this.plot.egrid = value
      }
    },
    landRegisterNumber: {
      get() {
        return this.plot.landRegisterNumber !== -1 ? this.plot.landRegisterNumber : null
      },
      set(value) {
        this.plot.landRegisterNumber = value
      }
    },
    plotArea: {
      get() {
        return this.plot.plotArea !== -1 ? this.plot.plotArea : null
      },
      set(value) {
        this.plot.plotArea = (!value || value === '' ? -1 : value)
      }
    },
    constructionZone: {
      get() {
        return this.plot.constructionZone
      },
      set(value) {
        this.plot.constructionZone = value
      }
    },
    note: {
      get() {
        return this.plot.note
      },
      set(value) {
        this.plot.note = value
      }
    },
    cadastralValue: {
      get() {
        return this.plot.cadastralValue !== -1 ? this.plot.cadastralValue : null
      },
      set(value) {
        this.plot.cadastralValue = value
      }
    },
    easements: {
      get() {
        return this.plot.easements
      },
      set(value) {
        this.plot.easements = value
      }
    },
    formOfOwnership: {
      get() {
        return this.plot.formOfOwnership
      },
      set(value) {
        this.plot.formOfOwnership = value
      }
    },
    inventories: {
      get() {
        return this.plot.inventories
      },
      set(value) {
        this.plot.inventories = value
      }
    },
    loads: {
      get() {
        return this.plot.loads
      },
      set(value) {
        this.plot.loads = value
      }
    },
    noiseSensitivity: {
      get() {
        return this.plot.noiseSensitivity
      },
      set(value) {
        this.plot.noiseSensitivity = value
      }
    },
    plotType: {
      get() {
        return this.plot.plotType
      },
      set(value) {
        this.plot.plotType = value
      }
    },
    priorityNotices: {
      get() {
        return this.plot.priorityNotices
      },
      set(value) {
        this.plot.priorityNotices = value
      }
    },
    purchasePrice: {
      get() {
        return this.plot.purchasePrice !== -1 ? this.plot.purchasePrice : null
      },
      set(value) {
        this.plot.purchasePrice = value
      }
    },
    realEstateRights: {
      get() {
        return this.plot.realEstateRights
      },
      set(value) {
        this.plot.realEstateRights = value
      }
    },
    utilizationZone: {
      get() {
        return this.plot.utilizationZone
      },
      set(value) {
        this.plot.utilizationZone = value
      }
    }
  },
  watch: {
    landRegister: {
      deep: true,
      handler: function (val) {
        if (this.loaded) {
          val.modified = true
          this.$emit('change-plots', val)
        }
      }
    },
    buildings: function (val) {
      if (this.loaded) {
        this.landRegister.modified = true
        this.$emit('change-buildings', { buildings: val.map(x => x.id), landregister: this.landRegister })
      }
    }
  },
  mounted() {
    if (this.plot.documentIds.length > 0) {
      MediaApi.listDocuments(this.plot.documentIds)
        .then(response => {
          this.landRegisterDocuments = response.documents
        })
    }
    this.buildings = this.buildingList.filter(x => x.plotIds.includes(this.landRegister.id))
    this.$nextTick(function () {
      this.loaded = true
    })
  },
  methods: {
    customLabel(item) {
      return item.name
    },
    addDocumentClicked(suffix = '') {
      this.$bvModal.show('add-document-modal' + suffix)
    },
    documentCreated(document) {
      this.plot.documentIds.push(document.id)
      this.landRegisterDocuments.push(document)
    },
    versionAdded(updatedDocument) {
      this.landRegisterDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.landRegisterDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    deleteDocument(document) {
      this.$emit('has-change', true)
      this.plot.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.plot.documentIds.splice(index, 1)
        }
      }, this)

      const index = this.landRegisterDocuments.findIndex(doc => doc.id === document.id)
      if (index >= 0) {
        this.$delete(this.landRegisterDocuments, index)
      }
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    removeConfirmed() {
      this.$refs.deleteModal.hide()
      this.$emit('remove-confirmed', this.plot.id || this.plot.internalId)
    }
  }
}
</script>
