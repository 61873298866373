<template>
  <coozzy-card
    :id="ticket.id"
    class="card-clickable cursor-pointer"
    @mouseup="mouseDown">
    <div class="row">
      <div class="col">
        <h5>
          <img
            v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.type.' + ticket.type)}"
            height="20px"
            class="mr-1 mb-1"
            :src="`/img/ticket/${ticket.type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
            alt="type">{{ ticket.title }}
        </h5>
      </div>
      <div class="col-auto">
        <h6 class="float-right">
          #{{ ticket.key }}
          <img
            v-if="ticket.readStatus && !ticket.readStatus.assignee"
            height="20px"
            class="ml-3 mb-1"
            :src="`/img/ticket/notification-bell.svg`"
            alt="type">
        </h6>
      </div>
    </div>
    <div class="row">
      <slot />
      <div
        v-if="ticket.onSiteContacts.length > 0"
        class="col-12 d-flex">
        <div class="mr-1">
          {{ $t('message.ticketOverview.ticket.contactPersonOnSite') }}:
        </div>
        <div
          v-for="(onSiteContact, j) in ticket.onSiteContacts"
          :key="'onSiteContact_' + j"
          :class="'mb-1'">
          <p
            class="m-0">
            <a
              href="#"
              class="external-link"
              @click="redirectToContactDetail(onSiteContact.contactId, $event)"
              @click.middle="redirectToContactDetailMiddle(onSiteContact.contactId, $event)">
              {{ onSiteContact?.companyName !== '' ? onSiteContact?.companyName : onSiteContact?.name }}
            </a>
          </p>
        </div>
      </div>
      <div
        v-if="ticket.type === 'TICKET_TYPE_ORDER'"
        class="col-12 d-flex mb-2">
        <div class="mr-1">
          {{ $t('message.ticketOverview.ticket.operator') }}:
        </div>
        <div>
          <span
            v-if="ticket.correspondences.length === 0"
            class="mr-1">
            -
          </span>
          <template
            v-else>
            <span
              v-for="(cor, corIndex) in ticket.correspondences"
              :key="cor.contactId">
              <p
                class="m-0">
                <a
                  href="#"
                  class="external-link"
                  @click="redirectToContactDetail(cor.contactId, $event)"
                  @click.middle="redirectToContactDetailMiddle(cor.contactId, $event)">
                  {{ corIndex > 0 ? ', ' + cor?.companyName !== '' ? cor?.companyName : cor?.name : '' + cor?.companyName !== '' ? cor?.companyName : cor?.name }}
                </a>
              </p>
            </span>
          </template>
        </div>
      </div>
      <div class="col-12 d-flex">
        <div class="mr-1">
          {{ $t('message.ticketOverview.ticket.assignee') }}:
        </div>
        <span
          v-if="!ticket.assignee"
          class="mr-1">
          -
        </span>
        <span
          v-else
          class="mb-1">
          <img
            alt=""
            height="15"
            class="image-user"
            :src="`${ticket.assignee.profilePictureUrl}`"> {{ ticket.assignee.name }} - <b>{{ ticket.assignee.accountName }}</b>
        </span>
      </div>
      <div
        v-if="ticket.dueDate"
        :class="mail && ticket.createdAt >= 1709638200 ? 'col-12' : 'col'">
        {{ $t('message.ticketOverview.ticket.dueDate') }}: {{ ticket.dueDate | objectToDate }}
      </div>
      <div
        v-if="mail && ticket.createdAt >= 1709638200"
        class="col">
        {{ $t('message.tableColumnHeadings.receivedOpened') }}:
        <template v-if="mail.deliverData.status === 'DELIVER_STATUS_DELIVERED'">
          <coozzy-success-icon />
        </template>
        <template v-else-if="mail.deliverData.status === 'DELIVER_STATUS_PENDING'">
          <coozzy-pending-icon />
        </template>
        <template v-else>
          <coozzy-fail-icon
            v-b-tooltip.hover
            :title="mail.deliverData.errorMessage" />
        </template>
        /
        <template v-if="mail.engagementData.status === 'ENGAGEMENT_STATUS_OPENED'">
          <coozzy-success-icon />
        </template>
        <template v-else>
          <coozzy-fail-icon />
        </template>
      </div>
      <div class="col-auto">
        <span
          class="mb-1">
          <img
            alt=""
            :src="`/img/priority/prio-${ticket.priority}.svg`"
            height="15px">
        </span>
      </div>
    </div>
  </coozzy-card>
</template>

<script>

// import EmployeeNameText from '../../../framework/components/misc/EmployeeNameText'

import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyPendingIcon from '@/framework/components/icons/CoozzyPendingIcon.vue'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon.vue'

export default {
  name: 'TicketCard',
  components: { CoozzyFailIcon, CoozzySuccessIcon, CoozzyPendingIcon, CoozzyCard },
  // components: { EmployeeNameText },
  mixins: [routeChecks],
  props: {
    ticket: {
      type: Object,
      default: null
    },
    mail: {
      type: Object,
      default: null
    }
  },
  computed: {
    typeText() {
      switch (this.ticket.type) {
        default:
        case 'UNDEFINED_TYPE':
          return this.$t('message.ticketOverview.type.undefined')
        case 'DAMAGE':
          return this.$t('message.ticketOverview.type.damage')
      }
    }
  },
  methods: {
    mouseDown(e) {
      if (e.target.className === 'external-link') {
        e.preventDefault()
      } else {
        if (e.which === 2) {
          this.$emit('click-middle', this.ticket, e)
        } else if (e.which === 1) {
          this.$emit('click', this.ticket, e)
        }
      }
    },
    redirectToContactDetail(contactId, event) {
      if (event.target.className.includes('external-link')) {
        event.preventDefault()
      }
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return this.$router.push({
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAssetModule) {
          return this.$router.push({
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAccountingModule) {
          return this.$router.push({
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isMarketingModule) {
          return this.$router.push({
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          })
        } else {
          return this.$router.push({
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          })
        }
      }
    },
    redirectToContactDetailMiddle(contactId, event) {
      if (event.target.className.includes('external-link')) {
        event.preventDefault()
      }
      if (contactId !== null) {
        let route = null
        if (this.isOwnerModule) {
          route = this.$router.resolve({
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAssetModule) {
          route = this.$router.resolve({
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAccountingModule) {
          route = this.$router.resolve({
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isMarketingModule) {
          route = this.$router.resolve({
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          })
        } else {
          route = this.$router.resolve({
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          })
        }
        window.open(route.href)
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.card-clickable:hover {
  background-color: $color-bg-grey-light;
}

.ticket-key {
  font-size: 0.75rem;
}

.priority-img {
  height: 16px;
  width: 16px;
}

.avatar-img {
  height: 24px;
  border-radius: 50%;
}
.image-user {
  border-radius: 50%;
}
</style>
