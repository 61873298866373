<template>
  <section>
    <div class="row">
      <div class="col-sm-6 col-md-2 col-lg-2 mb-0 mb-md-3">
        <div class="row">
          <div class="col-sm-12 col-md-12 mb-2 simple">
            <single-value
              :name="$t('message.dashboard.admin.vacancyRate')"
              :value="vacancyRate"
              :info-tooltip-text="$t('message.dashboard.admin.vacancyRateTooltip')"
              class="card-color cursor-pointer"
              design="blue"
              @click="$router.push({name: 'OwnerRentalObjectOverview', query: {'object[refinementList][status][0]': 'vacant', 'object[refinementList][status][1]': 'undefined', 'object[refinementList][status][2]': 'not_activated', 'predefined': 'true'}})"
              @click-middle="vacancyRateMiddle" />
          </div>
          <div class="col-sm-12 col-md-12 mb-2 simple">
            <single-value
              :name="$t('message.dashboard.admin.properties')"
              :value="countProperties"
              class="card-color cursor-pointer"
              design="deep-orange"
              @click="$router.push({name: 'OwnerPropertyView'})"
              @click-middle="propertiesMiddle" />
          </div>
          <div class="col-sm-12 col-md-12 mb-2 simple">
            <single-value
              :name="$t('message.dashboard.admin.buildings')"
              :value="countBuildings"
              class="card-color cursor-pointer"
              design="gray"
              @click="$router.push({name: 'OwnerBuildingsView'})"
              @click-middle="buildingsMiddle" />
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2 col-lg-2 mb-3">
        <div class="row">
          <div class="col-12 col-sm-12 mb-2 simple">
            <single-value
              :name="$t('message.dashboard.admin.newMessages')"
              :value="numberMessage"
              class="card-color cursor-pointer"
              design="gray"
              @click="$router.push({name: 'OwnerMessagesView'})"
              @click-middle="newMessagesMiddle" />
          </div>
          <div class="col-12 col-sm-12 mb-2 simple">
            <single-value
              :name="$t('message.dashboard.marketing.eventsToday')"
              :value="countEventsToday"
              class="card-color cursor-pointer"
              design="blue"
              @click="$router.push({name: 'OwnerCalendarOverview', params: { viewCalendar: 'day' }})"
              @click-middle="eventsTodayMiddle" />
          </div>
          <div class="col-12 col-sm-12 mb-2 simple">
            <single-value
              :name="$t('message.dashboard.marketing.eventsThisWeek')"
              :value="countEventCurrentWeek"
              class="card-color cursor-pointer"
              design="light-blue"
              @click="$router.push({name: 'OwnerCalendarOverview', params: { viewCalendar: 'week' }})"
              @click-middle="eventsThisWeekMiddle" />
          </div>
        </div>
      </div>
      <div class="d-none d-md-block col-sm-12 col-md-8 col-lg-8 mb-0 mb-md-3">
        <all-object-table :statistics="statistics" />
      </div>
    </div>
    <dashboard-tables
      @event-today-length="getCountEventsToday"
      @event-current-Week-length="getCountEventCurrentWeek" />
  </section>
</template>

<script>
import ObjectApi from '@/misc/apis/ObjectApi'
import { user } from '@/mixins/user'
import { mail } from '@/mixins/mail'
import { chartColors } from '@/mixins/chartColors'
import AllObjectTable from '../admin/AllObjectTable'
import DashboardTables from '@/dashboard/marketing/DashboardTables'
import { mapActions, mapGetters } from 'vuex'
import SingleValue from '@/dashboard/charts/SingleValue'
import { calendar } from '@/mixins/calendar'

export default {
  name: 'OwnerDashboardOverviewView',
  components: { SingleValue, DashboardTables, AllObjectTable },
  mixins: [chartColors, user, calendar, mail],
  metaInfo() {
    return {
      title: this.$t('message.navigation.dashboard.title')
    }
  },
  data() {
    return {
      countEventCurrentWeek: 0,
      countEventsToday: 0,
      countProperties: 0,
      countBuildings: 0,
      getNumberInterval: null,
      statistics: null
    }
  },
  computed: {
    ...mapGetters('message', ['getMailboxesForUserId']),
    mailboxesForCurrentUser() {
      return this.getMailboxesForUserId(this.userId)
    },
    vacancyRate() {
      if (this.statistics) {
        return this.statistics.vacancyRate.toFixed(2) + ' %'
      } else {
        return 'n/a'
      }
    }
  },
  watch: {
    mailboxesForCurrentUser: function () {
      this.countUnreadMails(true)
    }
  },
  mounted() {
    ObjectApi.getPropertyCount(this.$store.getters['user/getAccountId'])
      .then(response => {
        this.countProperties = response.count
      })
    ObjectApi.getBuildingCount(this.$store.getters['user/getAccountId'])
      .then(response => {
        this.countBuildings = response.count
      })
    ObjectApi.getOwnerStatistics(this.$store.getters['user/getAccountId'])
      .then(response => {
        this.statistics = response
      })
    this.countUnreadMails()
  },
  methods: {
    ...mapActions('message', ['loadMailboxesForUserId']),
    getCountEventsToday(item) {
      this.countEventsToday = item
    },
    vacancyRateMiddle() {
      const routeData = this.$router.resolve({ name: 'OwnerRentalObjectOverview', query: { 'object[refinementList][status][0]': 'vacant', 'object[refinementList][status][1]': 'undefined', 'object[refinementList][status][2]': 'not_activated', predefined: 'true' } })
      window.open(routeData.href, '_blank')
    },
    propertiesMiddle() {
      const routeData = this.$router.resolve({ name: 'OwnerPropertyView' })
      window.open(routeData.href, '_blank')
    },
    buildingsMiddle() {
      const routeData = this.$router.resolve({ name: 'OwnerBuildingsView' })
      window.open(routeData.href, '_blank')
    },
    newMessagesMiddle() {
      const routeData = this.$router.resolve({ name: 'OwnerMessagesView' })
      window.open(routeData.href, '_blank')
    },
    eventsTodayMiddle() {
      const routeData = this.$router.resolve({ name: 'OwnerCalendarOverview', params: { viewCalendar: 'day' } })
      window.open(routeData.href, '_blank')
    },
    eventsThisWeekMiddle() {
      const routeData = this.$router.resolve({ name: 'OwnerCalendarOverview', params: { viewCalendar: 'week' } })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
