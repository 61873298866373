<template>
  <section>
    <coozzy-card :title="$t('message.settings.template.titleContract')">
      <template-table :source="'setting'" />
    </coozzy-card>
  </section>
</template>

<script>
import TemplateTable from './TemplateTable'
import CoozzyCard from '@/framework/components/card/CoozzyCard'

export default {
  name: 'ContractTemplateSettings',
  components: {
    TemplateTable,
    CoozzyCard
  },
  data() {
    return {
      isBusy: false,
      selectedEntries: [],
      contractTemplate: [],
      contractTemplateFiltred: [],
      listMedia: [],
      ownerAccounts: [],
      contactList: [],
      searchCategories: [],
      loadingDeletion: false,
      searchOwner: [],
      searchText: '',
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'title',
          label: this.$t('message.tableColumnHeadings.title'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'category',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'creator',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'download',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ]
    }
  },
  computed: {
    selectedEntriesText() {
      return this.$t('message.generic.selectedEntries') + ':  ' + this.selectedEntries.length
    },
    categoryFilter() {
      const counts = []
      const current = this
      current.contractTemplate.map(contact => contact.category).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0) {
          const sameNetwork = current.contractTemplate.map(contact => contact.category).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    availableOwner() {
      const counts = []
      const current = this
      current.contractTemplate.map(contact => contact.ownerId).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0) {
          const sameNetwork = current.contractTemplate.map(contact => contact.ownerId).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
