<template>
  <section>
    <b-modal
      :id="'add-address-modal'"
      :modal-class="'has-confirmation-modal'"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.contact.actions.addAddress')">
      <div
        v-if="loading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <div v-else>
        <div class="row">
          <div
            v-if="contact !== null || contactPersons.length > 1"
            class="col-sm-12 col-md-6 mb-2">
            <label>{{ $t('message.calendar.reminder.contact') }}</label>
            <coozzy-multiselect
              id="tenants"
              v-model="contactId"
              :options="contactToList"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="name"
              track-by="id" />
          </div>
          <div class="col-sm-12 col-md-6 mb-2">
            <coozzy-form-input
              id="validFrom"
              ref="validFrom"
              v-model="validFrom"
              type="date"
              :disabled="itemToEdit && itemToEdit?.index === 0"
              :state="$v.validFrom.$dirty && $v.validFrom.$error ? false : null"
              :name="$t('message.contact.address.validFrom') + '*'"
              @blur="dateToObject()" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <coozzy-form-input
              v-model="address.careOf"
              class="mb-2"
              :name="$t('message.generic.addressAddition')" />
          </div>
          <div class="col-sm-12 col-md-4">
            <coozzy-form-input
              v-model="address.street"
              class="mb-2"
              :name="$t('message.generic.street')" />
          </div>
          <div class="col-sm-12 col-md-4">
            <coozzy-form-input
              v-model="address.streetNumber"
              :name="$t('message.generic.no')" />
          </div>
          <div class="col-sm-12 col-md-4">
            <coozzy-form-input
              v-model="address.mailbox"
              :name="$t('message.generic.postOfficeBox')"
              :placeholder="$t('message.generic.postOfficeBoxPlaceholder')" />
          </div>
          <div class="col-sm-12 col-md-6">
            <coozzy-form-input
              v-model="address.zip"
              :state="$v.address.zip && $v.address.zip.$dirty && $v.address.zip.$error ? false : null"
              :name="$t('message.generic.zip') + ('*')"
              @input="getCityFromZip" />
          </div>
          <div class="col-sm-12 col-md-6">
            <coozzy-form-input
              v-if="!isCitiesSuggested"
              v-model="address.city"
              :state="$v.address.city && $v.address.city.$dirty && $v.address.city.$error ? false : null"
              class="mb-2"
              :name="$t('message.generic.city') + ('*')" />
            <template v-else>
              <label for="city">{{ $t('message.generic.city') }}*</label>
              <coozzy-form-select
                id="city"
                :state="$v.address.city && $v.address.city.$dirty && $v.address.city.$error ? false : null"
                @change="cityChanged">
                <option
                  v-for="(item, typeIndex) in citiesValue"
                  :key="typeIndex"
                  :value="item">
                  {{ item.city }}
                </option>
              </coozzy-form-select>
            </template>
          </div>
          <div class="col-sm-6 col-md-6 mb-1">
            <coozzy-form-country-select
              v-model="address.country"
              :state="$v.address.country.$dirty && $v.address.country.$error ? false : null"
              :initial-value="address.country"
              :required="true" />
          </div>
          <div class="col-sm-6 col-md-6 mb-1">
            <coozzy-form-region-select
              v-model="address.state"
              :initial-value="address.state"
              :state="$v.address.state.$dirty && $v.address.state.$error ? false : null"
              :country="address.country"
              :required="true" />
          </div>
        </div>

        <div
          class="w-100 float-right mt-3">
          <coozzy-button
            size="normal"
            design="green"
            class="float-right ml-3"
            :disabled="loading"
            @click="contact && listContacts.length === 0 ? saveClicked() : $bvModal.show('changeAddressconfirmationModal')">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
          <coozzy-button
            size="normal"
            design="default"
            :disabled="loading"
            @click="$bvModal.hide('add-address-modal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      :id="'changeAddressconfirmationModal'"
      ref="changeAddressconfirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.contact.addressChange.title')">
      <div class="col">
        <p>{{ $t('message.contact.addressChange.text') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('changeAddressconfirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="saveClicked()">
          {{ $t('message.generic.confirm') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
// import Vue from 'vue'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import ContactApi from '../../misc/apis/ContactApi'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyFormCountrySelect from '@/framework/components/form/select/CoozzyFormCountrySelect.vue'
import CoozzyFormRegionSelect from '@/framework/components/form/select/CoozzyFormRegionSelect.vue'
import { address } from '@/mixins/address'

export default {
  name: 'AddAddressModal',
  components: { CoozzyFormRegionSelect, CoozzyFormCountrySelect, CoozzyFormSelect, CoozzySpinner, CoozzyButton, CoozzyFormInput, CoozzyMultiselect },
  mixins: [address],
  props: {
    contactPersons: {
      type: Array,
      default() {
        return []
      }
    },
    contact: {
      type: Object,
      default() {
        return null
      }
    },
    listContacts: {
      type: Array,
      default() {
        return []
      }
    },
    itemToEdit: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      contactId: [],
      validFrom: '',
      loading: false,
      isCitiesSuggested: false,
      citiesValue: [],
      address: {
        city: '',
        state: '',
        street: '',
        streetNumber: '',
        zip: '',
        streetNo: '',
        country: 'CH',
        careOf: '',
        mailbox: ''
      },
      validFromObject: null,
      oldValidFrom: null
    }
  },
  computed: {
    isTenant() {
      return this.contactId.filter(cont => cont.types.includes('TENANT')).length > 0
    },
    contactToList() {
      if (this.contact) {
        return [this.contact].concat(this.contactPersons)
      } else if (this.contactPersons.length > 1) {
        return this.contactPersons
      }
      return []
    }
  },
  watch: {
    validFrom: {
      handler: function (val) {
        if (val !== '') {
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    },
    itemToEdit: {
      handler: function (val) {
        this.oldValidFrom = val ? val.validFrom : null
        if (val && val.address && val.validFrom) {
          this.address = val.address
          this.validFrom = this.$options.filters.objectToDateInput(val.validFrom)
          this.validFromObject = val.validFrom
        }
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'add-address-modal') {
        this.$v.$reset()
        this.validFrom = ''
        this.contactId = []
        this.address = {
          city: '',
          state: '',
          street: '',
          streetNumber: '',
          zip: '',
          streetNo: '',
          country: 'CH',
          careOf: '',
          mailbox: ''
        }
        this.$emit('clear-modal')
      }
    })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'add-address-modal') {
        if (this.contact && !this.contactId.includes(this.contact)) {
          this.contactId.push(this.contact)
        }
        if (!this.contact && this.listContacts.length > 0) {
          this.contactId = this.contactId.concat(this.listContacts)
        }
      }
    })
  },
  methods: {
    dateToObject() {
      const validFrom = new Date(this.validFrom)
      this.validFromObject = validFrom instanceof Date && isFinite(validFrom)
? {
        day: validFrom.getDate(),
        month: validFrom.getMonth() + 1,
        year: validFrom.getFullYear()
      }
: null
    },
    cityChanged(event) {
      this.address.city = event.city
      this.address.state = event.state
    },
    async getCityFromZip() {
      if (this.address.zip.length > 3 && this.address.country === 'CH') {
        const zipData = await this.getZipInfo(this.address.zip)
        if (zipData) {
          this.address.state = zipData.state
          if (zipData.cities.length > 1) {
            this.isCitiesSuggested = true
            this.citiesValue = zipData.cities
          } else {
            this.isCitiesSuggested = false
            this.$nextTick(() => {
              this.address.city = zipData.cities[0].city
            })
          }
        }
      }
    },
    saveClicked() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        const ids = this.itemToEdit && this.itemToEdit?.userId ? [this.itemToEdit?.userId] : this.contactId.map(x => x.id || x.objectID)
        if (this.oldValidFrom) {
          ContactApi.updateAddressHistory(this.address, ids, this.oldValidFrom, this.validFromObject)
            .then(() => {
              this.$emit('address-added')
              this.$bvModal.hide('changeAddressconfirmationModal')
            })
            .catch(e => {
              console.log(e)
            })
            .finally(() => {
              this.loading = false
              this.$bvModal.hide('add-address-modal')
            })
        } else {
          ContactApi.setAddress(this.address, ids, this.validFromObject)
            .then(() => {
              this.$emit('address-added')
              this.$bvModal.hide('changeAddressconfirmationModal')
            })
            .catch(e => {
              console.log(e)
            })
            .finally(() => {
              this.loading = false
              this.$bvModal.hide('add-address-modal')
            })
        }
      }
    }
  },
  validations() {
    let contact = {}
    contact = {
      validFrom: {
        required
      }
    }
    contact.address = {
      state: {
        required
      },
      zip: {
        required
      },
      city: {
        required
      },
      country: {
        required
      }

    }
    return contact
  }
}
</script>

<style lang="scss" scoped>
  label {
    margin-bottom: 0;
  }
</style>
