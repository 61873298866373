<template>
  <b-modal
    :id="'address-history-modal'"
    :ref="'address-history-modal'"
    :modal-class="'has-confirmation-modal'"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.contact.address.adressHistory')">
    <div>
      <div class="row">
        <div class="col">
          <b-table
            hover
            bordered
            class="mb-0 mt-2"
            :fields="fields"
            :items="addressHistory">
            <template
              #head(validfrom)>
              <span>{{ $t('message.contact.address.validFrom') }}</span>
            </template>
            <template
              #head(addressAddition)>
              <span>{{ $t('message.generic.addressAddition') }}</span>
            </template>
            <template
              #head(street)>
              <span>{{ $t('message.generic.street') }}</span>
            </template>
            <template
              #head(streetNumber)>
              <span>{{ $t('message.generic.no') }}</span>
            </template>
            <template
              #head(mailbox)>
              <span>{{ $t('message.generic.postOfficeBox') }}</span>
            </template>
            <template
              #head(zip)>
              <span>{{ $t('message.generic.zip') }}</span>
            </template>
            <template
              #head(city)>
              <span>{{ $t('message.generic.location') }}</span>
            </template>
            <template
              #head(country)>
              <span>{{ $t('message.generic.country') }}</span>
            </template>
            <template
              #head(state)>
              <span>{{ $t('message.generic.canton') }}</span>
            </template>
            <template
              #cell(validfrom)="data">
              <span v-if="data.item && data.item.validFrom">{{ data.item.validFrom | objectToDate }}</span>
            </template>
            <template
              #cell(addressAddition)="data">
              <span v-if="data.item && data.item.address.careOf">{{ data.item.address.careOf }}</span>
            </template>
            <template
              #cell(street)="data">
              <span v-if="data.item && data.item.address.street">{{ data.item.address.street }}</span>
            </template>
            <template
              #cell(streetNumber)="data">
              <span v-if="data.item && data.item.address.streetNumber">{{ data.item.address.streetNumber }} </span>
            </template>
            <template
              #cell(mailbox)="data">
              <span v-if="data.item && data.item.address.mailbox">{{ data.item.address.mailbox }}</span>
            </template>
            <template
              #cell(zip)="data">
              <span v-if="data.item && data.item.address.zip">{{ data.item.address.zip }}</span>
            </template>
            <template
              #cell(city)="data">
              <span v-if="data.item && data.item.address.city">{{ data.item.address.city }}</span>
            </template>
            <template
              #cell(country)="data">
              <span v-if="data.item && data.item.address.country">{{ data.item.address.country }}</span>
            </template>
            <template
              #cell(state)="data">
              <div
                class="d-flex">
                <span v-if="data.item && data.item.address.state">{{ data.item.address.state }}</span>
                <span
                  class="ml-2 d-flex">
                  <coozzy-button
                    design="transparent"
                    class="p-0 mb-2"
                    @click="editAddress(data.item)">
                    <coozzy-edit-icon />
                  </coozzy-button>
                  <coozzy-button
                    v-if="data.item.index !== 0"
                    design="transparent"
                    class="p-0 ml-1 mb-2"
                    @click="deleteAddress(data.item)">
                    <coozzy-delete-icon />
                  </coozzy-button>
                </span>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import ContactApi from '@/misc/apis/ContactApi'

export default {
  name: 'AddressHistoryModal',
  components: { CoozzyDeleteIcon, CoozzyEditIcon, CoozzyButton },
  mixins: [dateUtils],
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      contactId: [],
      validFrom: '',
      loading: false,
      addressHistory: [],
      fields: [
        {
          key: 'validfrom',
          label: 'validfrom',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'addressAddition',
          label: 'addressAddition',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'street',
          label: 'street',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'streetNumber',
          label: 'streetNumber',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'mailbox',
          label: 'mailbox',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'zip',
          label: 'zip',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'city',
          label: 'city',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'country',
          label: 'country',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'state',
          label: 'state',
          thClass: 'table-header',
          tdClass: 'align-items-center align-middle'
        }
      ]
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (event) => {
      if (event.componentId === 'address-history-modal') {
        this.validFrom = ''
        this.addressHistory = []
      }
    })
    this.$root.$on('bv::modal::show', (event) => {
      if (event.componentId === 'address-history-modal') {
        if (this.contact && this.contact.addressHistory) {
          const lengthAddress = this.contact.addressHistory.length - 1
          this.addressHistory = this.contact.addressHistory.sort(function(a, b) {
            return new Date(b.validFrom.year + '-' + ('0' + b.validFrom.month).slice(-2) + '-' + ('0' + b.validFrom.day).slice(-2)) - new Date(a.validFrom.year + '-' + ('0' + a.validFrom.month).slice(-2) + '-' + ('0' + a.validFrom.day).slice(-2))
          }).map((item, index) => {
            return {
              validFrom: item.validFrom,
              address: item.address,
              index: lengthAddress - index
            }
          })
        }
      }
    })
  },
  methods: {
    show() {
      this.$refs['address-history-modal']?.show()
    },
    editAddress(item) {
      this.$emit('edit-address', item, this.contact)
    },
    deleteAddress(item) {
      ContactApi.deleteAddress(this.contact.id, item.validFrom)
        .then(() => {
          this.$emit('contact-edited')
        })
        .catch(error => {
          console.log('error', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    margin-bottom: 0;
  }
</style>
