<template>
  <section>
    <div
      v-b-toggle="`collapse-object-assignees-${objectId}`"
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ $t('message.generic.assignees') }}
      </h5>
    </div>
    <b-collapse
      :id="`collapse-object-assignees-${objectId}`"
      :visible="openedCollapses.includes(`collapse-object-assignees-${objectId}`)"
      class="row mt-3 details-row-bg-1">
      <div
        v-if="loading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <div
        v-else-if="!ownerAssignee && !assetAssignee && !administrationAssignee && !marketingAssignee && !accountingAssignee"
        class="col-12 bg-light">
        <h6>
          {{ $t('message.advertisementManagementOverview.involvedContact.noAssignees') }}
        </h6>
      </div>
      <template v-else>
        <employee-details
          v-if="ownerAssignee"
          class="col-12 col-md-3"
          :title="$t('message.advertisementManagementOverview.involvedContact.assigneeOwner')"
          :assignee="ownerAssignee" />
        <employee-details
          v-if="assetAssignee"
          class="col-12 col-md-3"
          :title="$t('message.advertisementManagementOverview.involvedContact.assigneeAsset')"
          :assignee="assetAssignee" />
        <employee-details
          v-if="administrationAssignee"
          class="col-12 col-md-3"
          :title="$t('message.advertisementManagementOverview.involvedContact.assigneeManagement')"
          :assignee="administrationAssignee" />
        <employee-details
          v-if="accountingAssignee"
          class="col-12 col-md-3"
          :title="$t('message.advertisementManagementOverview.involvedContact.assigneeAccounting')"
          :assignee="accountingAssignee" />
        <div
          v-if="ownerAssignee && assetAssignee && administrationAssignee && accountingAssignee && marketingAssignee"
          class="col-12">
          <hr>
        </div>
        <employee-details
          v-if="marketingAssignee"
          class="col-12 col-md-3"
          :title="$t('message.advertisementManagementOverview.involvedContact.assigneeMarketing')"
          :assignee="marketingAssignee" />
      </template>
    </b-collapse>
  </section>
</template>

<script>
import EmployeeDetails from '@/contact/components/EmployeeDetails'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import ContactApi from '@/misc/apis/ContactApi'
import { onboarding } from '@/mixins/onboarding'
import { mapMutations } from 'vuex'

export default {
  name: 'ObjectAssignees',
  components: {
    CoozzySpinner,
    EmployeeDetails
  },
  mixins: [onboarding],
  props: {
    objectId: {
      type: String,
      required: true
    },
    assignees: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    usedContacts() {
      return this.objectOwnerContacts.concat(this.contacts).concat(this.employeeContacts).concat(this.assigneeContacts)
    },
    ownerAssignee() {
      const tt = this.usedContacts.filter(c => (c.objectID && c.objectID === this.assignees.contactIdAssigneeOwner) || (c.id && c.id === this.assignees.contactIdAssigneeOwner))
      return tt && tt.length > 0 && tt.filter(x => Object.prototype.hasOwnProperty.call(x, 'id')).length > 0 ? tt.filter(x => Object.prototype.hasOwnProperty.call(x, 'id'))[0] : tt && tt.length > 0 ? tt[0] : null
    },
    assetAssignee() {
      const tt = this.usedContacts.filter(c => c.objectID === this.assignees.contactIdAssigneeAsset || c.id === this.assignees.contactIdAssigneeAsset)
      return tt && tt.length > 0 && tt.filter(x => Object.prototype.hasOwnProperty.call(x, 'id')).length > 0 ? tt.filter(x => Object.prototype.hasOwnProperty.call(x, 'id'))[0] : tt && tt.length > 0 ? tt[0] : null
    },
    administrationAssignee() {
      const tt = this.usedContacts.filter(c => c.objectID === this.assignees.contactIdAssigneeAdministration || c.id === this.assignees.contactIdAssigneeAdministration)
      return tt && tt.length > 0 && tt.filter(x => Object.prototype.hasOwnProperty.call(x, 'id')).length > 0 ? tt.filter(x => Object.prototype.hasOwnProperty.call(x, 'id'))[0] : tt && tt.length > 0 ? tt[0] : null
    },
    marketingAssignee() {
      const tt = this.usedContacts.filter(c => c.objectID === this.assignees.contactIdAssigneeMarketing || c.id === this.assignees.contactIdAssigneeMarketing)
      return tt && tt.length > 0 && tt.filter(x => Object.prototype.hasOwnProperty.call(x, 'id')).length > 0 ? tt.filter(x => Object.prototype.hasOwnProperty.call(x, 'id'))[0] : tt && tt.length > 0 ? tt[0] : null
    },
    accountingAssignee() {
      const tt = this.usedContacts.filter(c => c.objectID === this.assignees.contactIdAssigneeAccounting || c.id === this.assignees.contactIdAssigneeAccounting)
      return tt && tt.length > 0 && tt.filter(x => Object.prototype.hasOwnProperty.call(x, 'id')).length > 0 ? tt.filter(x => Object.prototype.hasOwnProperty.call(x, 'id'))[0] : tt && tt.length > 0 ? tt[0] : null
    }
  },
  mounted() {
    const contactIds = []
    contactIds.push(this.assignees.contactIdAssigneeOwner)
    contactIds.push(this.assignees.contactIdAssigneeAsset)
    contactIds.push(this.assignees.contactIdAssigneeAdministration)
    contactIds.push(this.assignees.contactIdAssigneeMarketing)
    contactIds.push(this.assignees.contactIdAssigneeAccounting)
    this.availableContact([...new Set(contactIds.filter(x => x !== null && x !== ''))])
  },
  methods: {
    ...mapMutations('onboarding', ['setObjectOwnerContacts']),
    availableContact(search) {
      search.filter(item => !this.usedContacts.map(x => x.id ? x.id : x.objectID).includes(item))
      if (search.length > 0) {
        ContactApi.contactResolve(search)
          .then(response => {
            const contacts = response.persons.concat(response.companies)
            const usedContacts = this.objectOwnerContacts.concat(contacts)
            this.setObjectOwnerContacts(usedContacts)
          })
          .catch(e => {
            console.log(e)
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
