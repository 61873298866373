<template>
  <section>
    <div class="row">
      <div class="col">
        <h1>
          {{ $t('message.navigation.contact.title') }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <contact-export-card />
      </div>
    </div>
  </section>
</template>

<script>
import ContactExportCard from '@/contact/components/ContactExportCard.vue'

export default {
  name: 'ContactSettingsView',
  components: { ContactExportCard }
}
</script>

<style lang="scss" scoped>
</style>
