<template>
  <section>
    <div>
      <coozzy-page-title
        :title="$t('message.userRequests.title')"
        :count="requestList.length"
        :count-text="$t('message.generic.requests')" />
      <coozzy-card
        v-for="request in requestList"
        :key="request.id">
        <application-form-object-view
          :request-id="request.id" />
      </coozzy-card>
    </div>
  </section>
</template>
<script>
import ApplicationFormObjectView from '@/marketingBasic/components/applicationForm/ApplicationFormObjectView'
import RequestApi from '@/marketingBasic/api/RequestApi'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import { user } from '@/mixins/user'
import Vue from 'vue'

export default {
  name: 'UserRequests',
  components: {
    CoozzyCard,
    CoozzyPageTitle,
    ApplicationFormObjectView
  },
  mixins: [user],
  data() {
    return {
      requestList: []
    }
  },
  mounted() {
    RequestApi.getListSent(this.accountId)
      .then(response => {
        this.requestList = response.requests
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.request'), { type: 'error' })
      })
  }
}
</script>
