<template>
  <section>
    <b-modal
      :id="'modal-detail-mail-' + mailId"
      ref="modal-detail-mail"
      no-close-on-backdrop>
      <template #modal-header>
        <h5 class="modal-title">
          {{ $t('message.marketingMessages.detailEmail') }}
        </h5>
        <div class="d-flex">
          <a
            v-if="ticket"
            class="d-inline linkTicket mr-3 align-self-center"
            @click="routeToDetail(ticket)"
            @click.middle="openMiddleDetail(ticket)">
            <span
              :id="'tooltip-title-' + ticket.key">
              <img
                height="20px"
                class="mr-1"
                :src="`/img/ticket/${ticket.type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                alt="type">{{ $t('message.ticketOverview.ticket.type.' + ticket.type) }} {{ ticket.key }}
            </span>
            <b-tooltip
              :target="'tooltip-title-' + ticket.key"
              triggers="hover">
              {{ ticket.title }}
            </b-tooltip>
          </a>
          <coozzy-form-checkbox
            v-model="confidentialEmail"
            :disabled="!canChangeMailConfidential"
            :initial="confidentialEmail"
            class="mr-4 pr-0 align-self-center"
            name="confidentialEmail"
            @change="(value) => { markAsConfidential(value) }">
            {{ $t('message.settings.confidentialEmails.confidential') }}
          </coozzy-form-checkbox>
          <b-button
            class="close ml-2"
            size="sm"
            @click="hide()">
            x
          </b-button>
        </div>
      </template>
      <div
        v-if="loading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <message-detail-content
        v-show="!loading"
        ref="messageDetailContent"
        :email="email"
        :contact="contact"
        :ticket="ticket"
        :source="'modal'"
        @print-message="printMessage"
        @reply-mail="reply"
        @reply-all-mail="replyAll"
        @forward-as-attachement="forwardAsAttachement"
        @forward-mail="forwardMail"
        @delete-mail="deleteMail"
        @archive-mail="archiveMail"
        @restore-mail="restoreMail"
        @refresh-list="refreshEmailList"
        @refresh-list-after-close="refreshListAfterClose"
        @refresh-reminders="refreshReminders"
        @hide="hide"
        @loading-message="changeLoading" />
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          design="default"
          @click="hide()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { user } from '@/mixins/user'
import { media } from '@/mixins/media'
import { contact } from '@/mixins/contact'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MessageApi from '@/misc/apis/MessageApi'
import { routeChecks } from '@/mixins/routeChecks'
import { request } from '@/mixins/request'
import { mail } from '@/mixins/mail'
import { algolia } from '@/mixins/algolia'
import MessageDetailContent from './MessageDetailContent.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'

export default {
  name: 'MessageDetailsModal',
  components: {
    CoozzySpinner,
    CoozzyFormCheckbox,
    MessageDetailContent,
    CoozzyButton
  },
  mixins: [algolia, routeChecks, user, mail, media, contact, request],
  props: {
    contact: {
      type: Object,
      default() {
        return null
      }
    },
    email: {
      type: Object,
      default() {
        return null
      }
    },
    ticket: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      mediasList: [],
      printAllow: false,
      loading: true,
      emailForForward: false,
      confidentialEmail: false,
      documentDirection: '',
      ticketType: '',
      mustRefresh: false,
      scrollPosition: 0
    }
  },
  computed: {
    ...mapState('request', ['marketplace']),
    ...mapState('message', ['confidentialMailDomain']),
    ...mapGetters('message', ['getMailboxesForUserId']),
    mailboxesForCurrentUser() {
      return this.getMailboxesForUserId(this.userId)
    },
    canChangeMailConfidential() {
      const outboundEmails = this.mailboxesForCurrentUser.filter(mailbox => mailbox.mainUserId === this.userId).map(mailbox => mailbox.outboundEmail)
      const emailFrom = this.email.from ? [this.email.from.email] : []
      const emailTo = this.email.to ? this.email.to.flatMap(x => x.email) : []
      const emailBcc = (this.email.bcc && this.email.bcc[0]) ? this.email.bcc[0].email : ''
      const emailCc = this.email.cc ? this.email.cc.flatMap(x => x.email) : []
      const recipientsMails = emailFrom.concat(emailTo).concat(emailCc).concat(emailBcc)
      return recipientsMails.some(x => outboundEmails.includes(x))
    },
    mailId() {
      return this.email.id || this.email.objectID
    }
  },
  watch: {
  },
  mounted() {
    this.confidentialEmail = this.email.confidential
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-detail-mail-' + this.mailId) {
        // Save the current scroll position
        this.scrollPosition = window.pageYOffset

        if (!this.email?.readEntries?.some(entry => entry.accountId === this.accountId) || this.getUnreadMail().find(entry => entry.accountId === this.accountId && entry.mailId === this.mailId)) {
          const outboundEmails = this.mailboxesForCurrentUser
            .filter(mailbox => mailbox.mainUserId === this.userId)
            .map(mailbox => mailbox.outboundEmail)
          const emailTo = this.email.to ? this.email.to.flatMap(x => x.email) : []
          const emailBcc = (this.email.bcc && this.email.bcc[0]) ? this.email.bcc[0].email : ''
          const emailCc = this.email.cc ? this.email.cc.flatMap(x => x.email) : []
          if (outboundEmails.includes(emailBcc) || emailCc.some(x => outboundEmails.includes(x)) || emailTo.some(x => x.startsWith('req_') || outboundEmails.includes(x))) {
            MessageApi.mailMarkRead([this.mailId], this.accountId).then(() => {
              this.addReadMail({
                mailId: this.mailId,
                accountId: this.accountId
              })
            })
          }
        }
        this.loadConfidentialMailDomain(this.accountId)
      }
    })
    this.$root.$on('bv::modal::hide', (_, modalId) => {
      this.$emit('modal-hidden')
    })
  },
  methods: {
    ...mapGetters('message', ['getUnreadMail']),
    ...mapMutations('message', ['addReadMail', 'setChangingStatusMails']),
    ...mapActions('message', ['loadMailboxesForUserId', 'loadConfidentialMailDomain']),
    show() {
      this.$refs['modal-detail-mail'].show()
    },
    changeLoading(value) {
      setTimeout(() => {
        this.loading = value
      }, 400)
    },
    hide() {
      this.loading = true
      this.$nextTick(() => {
        if (this.mustRefresh) {
          this.$emit('refresh-list')
        }
        this.$refs['modal-detail-mail']?.hide()
      })
    },
    refreshListAfterClose() {
      this.mustRefresh = true
    },
    reply(email) {
      this.$emit('reply-mail', email)
    },
    replyAll(email) {
      this.$emit('reply-all-mail', email)
    },
    forwardMail(email) {
      this.$emit('forward-mail', email)
    },
    deleteMail(id) {
      this.$emit('delete-mail', id)
    },
    archiveMail(email) {
      this.$emit('archive-mail', email)
    },
    restoreMail(email) {
      this.$emit('restore-mail', email)
    },
    forwardAsAttachement(mediasList, email) {
      this.$emit('forward-as-attachement', mediasList, email)
    },
    refreshReminders() {
      this.$emit('refresh-reminders')
    },
    refreshEmailList(id) {
      this.$emit('refresh-list', id)
    },
    linkToContact(contactId) {
      this.$emit('link-to-contact', contactId)
    },
    linkToTicket(ticketId) {
      this.$emit('link-to-ticket', ticketId)
    },
    linkToRequest(requestId) {
      this.$emit('link-to-request', requestId)
    },
    printMessage(newVal) {
      this.$emit('print-message', newVal)
    },
    markAsConfidential(value) {
      this.$refs.messageDetailContent.markAsConfidential(value)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.more-btn button) {
  border-radius: 0.2rem;
  color: #515151;
  font-size: .875rem;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ced4da;
}

:deep(.modal-header) {
  padding-bottom: 0;
}

:deep(.modal-body) {
  padding-top: 0;
}

p {
  white-space: pre-wrap;
}

.media-block {
  max-width: 33%;
  margin-right: 0.5%;
}

:deep(.modal-dialog) {
  max-width: 1200px !important;
}

.close-img {
  width: 15px;
}

.section-to-print {
  opacity: 1;
  background-color: #FFF;
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  min-height: 200px;
  top: 0;
  left: 0;
  padding: 15px;
  margin: 0;
  z-index: 1000;
}

.section-to-print-bg {
  background-color: transparent !important;
}

:deep(.ticket-dropdown-bulk-action button) {
  border: 0;
}

.linkTicket {
  cursor: pointer;
  color: #007bff !important;
}
</style>
