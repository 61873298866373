import { render, staticRenderFns } from "./CoozzyPaperClipIcon.vue?vue&type=template&id=0cec3621&scoped=true"
import script from "./CoozzyPaperClipIcon.vue?vue&type=script&lang=js"
export * from "./CoozzyPaperClipIcon.vue?vue&type=script&lang=js"
import style0 from "./CoozzyPaperClipIcon.vue?vue&type=style&index=0&id=0cec3621&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cec3621",
  null
  
)

export default component.exports