<template>
  <section>
    <b-modal
      id="listContactsWithoutEmailModal"
      ref="listContactsWithoutEmailModal"
      no-close-on-backdrop
      :title="$t('message.tableColumnHeadings.information')"
      hide-footer
      @close="closeModal">
      <h5 v-if="$route.name.includes('MarketingObjectDetailsView')">
        {{ $t('message.onBoarding.interestedParties.modal.requestWithoutEmail') }}
      </h5>
      <h5 v-else>
        {{ $t('message.onBoarding.interestedParties.modal.contactWithoutEmail', {source: getSource()}) }}
      </h5>
      <div class="col pl-0">
        <div
          v-for="contact in contacts"
          :key="contact.id"
          class="mb-2">
          <span class="ml-2 mr-2">-</span>
          <a
            tabindex="0"
            target="_blank"
            @click="redirectToDetailPageMiddle(contact.id)"
            @click.middle="redirectToDetailPageMiddle(contact.id)">
            {{ getContactName(contact.id) }}
          </a>
          <span
            v-if="hasJoinTenant(contact.id).length > 0">
            {{ '(' + (['tenants', 'objects'].includes(source) ? $t('message.onBoarding.interestedParties.listContactsWithoutEmailModal.hasJoinTenant') : $t('message.onBoarding.interestedParties.listContactsWithoutEmailModal.hasJoinCondo')) + ':' }}
            <span
              v-for="( tenantId, index) in hasJoinTenant(contact.id)"
              :key="index">
              <span v-if="index !== 0">
                ,
              </span>
              <a
                tabindex="0"
                target="_blank"
                @click="redirectToDetailPageMiddle(tenantId)"
                @click.middle="redirectToDetailPageMiddle(tenantId)">
                {{ getContactName(tenantId) }}
              </a>
              <span v-if="index === hasJoinTenant(contact.id).length -1">
                )
              </span>
            </span>
          </span>
        </div>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { routeChecks } from '@/mixins/routeChecks'
import ContactApi from '@/misc/apis/ContactApi'
import Vue from 'vue'
import * as Sentry from '@sentry/vue'

export default {
  name: 'ListContactWithoutEmailModal',
  components: {
  },
  mixins: [routeChecks],
  props: {
    contacts: {
      type: Array,
      default: () => {
        return []
      }
    },
    allContacts: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      source: 'contacts',
      contactIds: [],
      contactList: this.allContacts || []
    }
  },
  computed: {
    listRequest() {
      return this.$store.getters['request/getRequests'] || []
    },
    selectedEntries() {
      return this.$store.getters['message/getSelectedEntries'] || []
    }
  },
  mounted: function () {
    this.getSource()
    this.selectedEntries.forEach(element => {
      if (element.tenancy && element.tenancy.tenants?.length > 0) {
        this.contactIds = this.contactIds.concat(element.tenancy.tenants.map(x => x.contactId))
      }
      if (element.tenants?.length > 0) {
        this.contactIds = this.contactIds.concat(element.tenants.map(x => x.contactId))
      }
      if (element.tenant && element.tenant.contactIds?.length > 0) {
        this.contactIds = this.contactIds.concat(element.tenant.contactIds)
      }
      if (element.condominium && element.condominium.owner?.length > 0) {
        this.contactIds = this.contactIds.concat(element.condominium.owner.map(x => x.contactId))
      }
      if (element.condominiumOwners?.length > 0) {
        this.contactIds = this.contactIds.concat(element.condominiumOwners.map(x => x.contactId))
      }
      if (element.owner && element.owner.contactIds?.length > 0) {
        this.contactIds = this.contactIds.concat(element.owner.contactIds)
      }
      if (element.tenants && element.tenants.tenant?.contactIds?.length > 0) {
        this.contactIds = this.contactIds.concat(element.tenants.tenant?.contactIds)
      }
      if (element.condominium && element.condominium.owner?.contactIds?.length > 0) {
        this.contactIds = this.contactIds.concat(element.condominium.owner?.contactIds)
      }
    })
    this.loadContacts()
  },
  methods: {
    getContactName(contactId) {
      if ((!this.contactList || this.contactList?.length === 0) && (!this.contacts || this.contacts?.length === 0)) {
        Sentry.setExtra('contactList', JSON.stringify(this.contactList))
        Sentry.setExtra('contacts.length', this.contacts.length)
        Sentry.captureMessage('Contacts names couldn\'t be processed')
        return '-'
      }
      const listContacts = []
      if (this.contactList?.length > 0) {
        listContacts.push(...this.contactList)
      }
      if (this.contacts?.length > 0) {
        listContacts.push(...this.contacts)
      }
      const contact = listContacts.find(function (contact) {
        return contact.id === contactId
      })
      if (!contact) {
        Sentry.setExtra('contactId', contactId)
        Sentry.captureMessage('Contacts names couldn\'t be processed.')
      }
      return contact ? contact.name : '-'
    },
    redirectToDetailPageMiddle(contactId) {
      if (contactId !== null) {
        let route = ''
        if (this.isOwnerModule) {
          route = this.$router.resolve({
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAssetModule) {
          route = this.$router.resolve({
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAccountingModule) {
          route = this.$router.resolve({
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isMarketingModule) {
          route = this.$router.resolve({
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          })
        } else {
          route = this.$router.resolve({
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          })
        }
        window.open(route.href)
      }
    },
    closeModal() {
      this.$bvModal.hide('listContactsWithoutEmailModal')
      this.$store.dispatch('request/setRequests', [])
      this.contactsWithoutEmail = []
    },
    getSource() {
      // values possible for source = ['tenants', 'condoOwners', 'contacts', 'requests', 'objects']
      if (this.$route.name.includes('BuildingDetailsView') && this.$route.query.view === 'objects') {
        if (this.selectedEntries.length > 0) {
          if (this.selectedEntries[0].condominiumOwnership === true) {
            this.source = 'condominium'
            return this.$t('message.onBoarding.interestedParties.modal.condoOwners')
          } else {
            this.source = 'objects'
            return this.$t('message.onBoarding.interestedParties.modal.tenants')
          }
        }
      } else if (this.$route.name.includes('TenanciesView') || this.$route.name.includes('RentalObjectOverview') || (this.$route.name.includes('BuildingDetailsView') && this.$route.query.view === 'tenants')) {
        this.source = 'tenants'
        return this.$t('message.onBoarding.interestedParties.modal.tenants')
      } else if (this.$route.name.includes('CondominiumOverview') || this.$route.name.includes('CondominiumObjectOverview') || (this.$route.name.includes('BuildingDetailsView') && this.$route.query.view === 'condominium')) {
        this.source = 'condoOwners'
        return this.$t('message.onBoarding.interestedParties.modal.condoOwners')
      } else if (this.$route.name.includes('ContactOverview')) {
        this.source = 'contacts'
        return this.$t('message.onBoarding.interestedParties.modal.contacts')
      } else if (this.$route.name.includes('MarketingObjectDetailsView')) {
        this.source = 'requests'
        return this.$t('message.onBoarding.interestedParties.modal.requests')
      } else {
        return this.$t('message.onBoarding.interestedParties.modal.contacts')
      }
    },
    hasJoinTenant(id) {
      let hasTenant = []
      if (this.allContacts?.length > 0 || this.contacts?.length > 0 || this.contactList?.length > 0) {
        const allContacts = this.allContacts && this.allContacts.length > 0 ? this.allContacts : []
        const contacts = this.contacts && this.contacts.length > 0 ? this.contacts : []
        const contactList = this.contactList && this.contactList.length > 0 ? this.contactList : []
        const globalsContacts = [...new Set(allContacts.concat(contacts).concat(contactList))]
        if (this.source === 'tenants') {
          this.selectedEntries.forEach(element => {
            if (element.id?.startsWith('obj') || element.objectID?.startsWith('obj')) {
              if (element.tenancy && element.tenancy.tenants?.length > 0) {
                const tenants = element.tenancy.tenants.map(x => x.contactId)
                const contacts = globalsContacts.filter(contact => contact && tenants.includes(contact.id) && contact.email !== '')
                if (tenants.includes(id) && contacts.length > 0) {
                  hasTenant = contacts.map(x => x.id)
                }
              }
            }
            if (element.id?.startsWith('tenancy') || element.objectID?.startsWith('tenancy')) {
              if (element.tenants?.length > 0) {
                const tenants = element.tenants.map(x => x.contactId)
                const contacts = globalsContacts.filter(contact => contact && tenants.includes(contact.id) && contact.email !== '')
                if (tenants.includes(id) && contacts.length > 0) {
                  hasTenant = contacts.map(x => x.id)
                }
              }
              if (element.tenant && element.tenant.contactIds?.length > 0) {
                const tenants = element.tenant.contactIds
                const contacts = globalsContacts.filter(contact => contact && tenants.includes(contact.id) && contact.email !== '')
                if (tenants.includes(id) && contacts.length > 0) {
                  hasTenant = contacts.map(x => x.id)
                }
              }
            }
          })
        } else if (this.source === 'condoOwners') {
          this.selectedEntries.forEach(element => {
            if (element.id?.startsWith('obj') || element.objectID?.startsWith('obj')) {
              if (element.condominium && element.condominium.owner?.length > 0) {
                const condoOwners = element.condominium.owner.map(x => x.contactId).concat(element.condominium.roommates?.map(x => x.contactId))
                const contacts = globalsContacts.filter(contact => contact && condoOwners.includes(contact.id) && contact.email !== '')
                if (condoOwners.includes(id) && contacts.length > 0) {
                  hasTenant = contacts.map(x => x.id)
                }
              }
            }
            if (element.id?.startsWith('condominium') || element.objectID?.startsWith('condominium')) {
              if (element.condominiumOwners?.length > 0) {
                const condoOwners = element.condominiumOwners.map(x => x.contactId).concat(element.roommates?.map(x => x.contactId))
                const contacts = globalsContacts.filter(contact => contact && condoOwners.includes(contact.id) && contact.email !== '')
                if (condoOwners.includes(id) && contacts.length > 0) {
                  hasTenant = contacts.map(x => x.id)
                }
              }
              if (element.owner && element.owner.contactIds?.length > 0) {
                const condoOwners = element.owner.contactIds.concat(element.owner.roommateContactIds)
                const contacts = globalsContacts.filter(contact => contact && condoOwners.includes(contact.id) && contact.email !== '')
                if (condoOwners.includes(id) && contacts.length > 0) {
                  hasTenant = contacts.map(x => x.id)
                }
              }
            }
          })
        } else if (this.source === 'objects') {
          this.selectedEntries.forEach(element => {
            if (element.id?.startsWith('obj') || element.objectID?.startsWith('obj')) {
              if (element.tenants && element.tenants.tenant?.contactIds?.length > 0) {
                const tenants = element.tenants.tenant?.contactIds
                const contacts = globalsContacts.filter(contact => contact && tenants.includes(contact.id) && contact.email !== '')
                if (tenants.includes(id) && contacts.length > 0) {
                  hasTenant = contacts.map(x => x.id)
                }
              }
            }
          })
        } else if (this.source === 'condominium') {
          this.selectedEntries.forEach(element => {
            if (element.id?.startsWith('obj') || element.objectID?.startsWith('obj')) {
              if (element.condominium && element.condominium.owner?.contactIds?.length > 0) {
                const condoOwners = element.condominium.owner?.contactIds.concat(element.condominium.owner?.roommateContactIds)
                const contacts = globalsContacts.filter(contact => contact && condoOwners.includes(contact.id) && contact.email !== '')
                if (condoOwners.includes(id) && contacts.length > 0) {
                  hasTenant = contacts.map(x => x.id)
                }
              }
            }
          })
        }
      }
      return hasTenant?.length > 0 ? [...new Set(hasTenant)].filter(x => x !== id) : []
    },
    loadContacts() {
      this.loading = true
      ContactApi.contactResolve(this.contactIds)
        .then(response => {
          this.contactList = this.contactList.concat(response.persons.concat(response.companies)).concat(this.allContacts).concat(this.contacts)
          this.loading = false
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
