<template>
  <div>
    <label for="region">
      {{ label }}<template v-if="required && !isReadOnly">*</template>
    </label>
    <region-select
      v-if="!isReadOnly"
      id="region"
      v-model="region"
      v-bind="$attrs"
      :country="countryValue || 'CH'"
      :region="region || ''"
      placeholder="-"
      :class-name="'custom-select custom-select-sm' + (state === false ? ' is-invalid' : '')"
      v-on="$listeners" />
    <span v-else>
      {{ region }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'CoozzyFormRegionSelect',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: true
    },
    country: {
      type: String,
      required: true
    },
    initialValue: {
      type: String,
      default: ''
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    }
  },
  data: function () {
    return {
      region: this.initialValue || '',
      countryValue: this.country
    }
  },
  computed: {
    label() {
      return (this.countryValue || 'CH') === 'CH' ? this.$t('message.generic.canton') : this.$t('message.generic.state')
    }
  },
  watch: {
    country: function (newVal) {
      if (newVal !== this.countryValue) {
        this.countryValue = this.country
        this.region = this.initialValue || ''
      }
    },
    initialValue: function (newVal) {
      if (newVal !== this.region) {
        this.countryValue = this.country
        this.region = this.initialValue || ''
      }
    }
  },
  mounted() {
    this.region = this.initialValue
  }
}
</script>

<style lang="scss" scoped>
</style>
