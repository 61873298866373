<template>
  <router-link
    class="nav-item nav-dropdown open"
    :to="routeObject">
    <div
      class="nav-link nav-dropdown-toggle"
      :class="[active ? 'active' : '']">
      <template v-if="icon === 'ticket'">
        <coozzy-ticket-icon
          :name="name"
          :active="active" />
      </template>
      <template v-else-if="icon === 'briefButler'">
        <coozzy-brief-butler-icon />
      </template>
      <template v-else-if="icon === 'deepSign'">
        <coozzy-deep-sign-icon
          :name="name"
          :active="!active"
          class="nav-icon" />
      </template>
      <template v-else-if="icon !== 'analytics'">
        <font-awesome-icon
          :id="name"
          :icon="icon"
          class="nav-icon fa" />
      </template>
      <template v-else>
        <coozzy-analytics-icon
          :name="name"
          :active="active" />
      </template>
      <span class="link-name">{{ name }} </span>
      <coozzy-badge
        v-if="badge !== ''"
        class="p-1"
        :design="badge === 0 || badge === '0' || badge === '-' ? 'white' : 'white-red'">
        {{ badge }}
      </coozzy-badge>
      <span
        v-if="badge !== '' && badge > 0"
        class="badge-dot" />
    </div>
    <b-tooltip
      fallback-placement="clockwise"
      :target="name"
      :custom-class="getTooltipClass()"
      placement="right">
      {{ name }}
    </b-tooltip>
    <ul class="nav-dropdown-items">
      <slot />
    </ul>
  </router-link>
</template>

<script>
import { logout } from '@/mixins/logout'
import CoozzyBadge from '../../badge/CoozzyBadge'
import CoozzyAnalyticsIcon from '@/framework/components/icons/CoozzyAnalyticsIcon'
import CoozzyTicketIcon from '@/framework/components/icons/CoozzyTicketIcon'
import CoozzyBriefButlerIcon from '@/framework/components/icons/CoozzyBriefButlerIcon'
import CoozzyDeepSignIcon from '@/framework/components/icons/CoozzyDeepSignIcon.vue'

export default {
  name: 'CoozzySidebarNavDropdown',
  components: {
    CoozzyDeepSignIcon,
    CoozzyAnalyticsIcon,
    CoozzyTicketIcon,
    CoozzyBadge,
    CoozzyBriefButlerIcon
  },
  mixins: [logout],
  props: {
    name: {
      type: String,
      default: ''
    },
    routeName: {
      type: String,
      default: ''
    },
    routeParam: {
      type: Object,
      default: null
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    badge: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    routeObject() {
      if (this.routeName) {
        if (this.routeParam) {
          return {
            name: this.routeName,
            params: this.routeParam
          }
        }
        return { name: this.routeName }
      } else {
        return this.url
      }
    }
  },
  methods: {
    getTooltipClass() {
      switch (this.name) {
        case this.$t('message.navigation.networkPartner'):
          return 'righter-tooltip-network'
        case this.$t('message.generic.submitApplicationForm'):
          return 'righter-tooltip-applicationForm'
        case this.$t('message.navigation.dashboard.title'):
          return 'righter-tooltip2'
        case this.$t('message.navigation.realEstate'):
          return 'righter-tooltip3'
        case this.$t('message.navigation.tickets'):
          return 'righter-tooltip4'
        case this.$t('message.navigation.reminder'):
          return 'righter-tooltip5'
        case this.$t('message.navigation.calendar.title'):
          return 'righter-tooltip6'
        case this.$t('message.navigation.directory'):
          return 'righter-tooltip7'
        case this.$t('message.navigation.settings.title'):
          return 'righter-tooltip8'
        default:
          return 'righter-tooltip'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a:hover {
  text-decoration: none !important;
}

:deep(.badge) {
  margin-right: 0 !important;
}

.icon-container {
  margin-top: -9px;
  display: inline-block;
  vertical-align: middle;
}

.nav-dropdown {
  background: transparent !important;

  ::before {
    display: none !important;
  }

  .nav-link {
    cursor: pointer;
    color: #fff;
    background: transparent !important;
    font-size: 16px;
    line-height: 24px;

    .nav-icon {
      color: inherit !important;
    }

    .icon {
      width: 20px;
      margin-right: 5px;

      &.icon-grey {
        display: none;
      }

      &.icon-white {
        display: inline;
      }
    }

    &.active {
      .icon-grey {
        display: inline;
      }

      .icon-white {
        display: none !important;
      }

      background-color: #fff !important;
      color: $color-grey-dark !important;
    }
  }
}

@media (min-width: 992px) {
  .sidebar-fixed {
    .link-name {
      display: none !important;
    }

    .badge-dot {
      display: inline-block !important;
    }
  }
  .sidebar-fixed.sidebar-lg-show {
    .link-name {
      display: inline !important;
    }

    .badge-dot {
      display: none !important;
    }
  }
}

@media (max-width: 991px) {
  .sidebar-fixed:not(.sidebar-show) {
    .link-name {
      display: none !important;
    }

    .badge-dot {
      display: inline !important;
    }
  }
  .sidebar-fixed.sidebar-lg-show {
    .link-name {
      display: inline;
    }

    .badge-dot {
      display: none !important;
    }
  }
}

.sidebar .nav-dropdown.open > .nav-dropdown-items {
  max-width: 200px;
}

.badge-dot {
  display: none;
  position: absolute;
  top: 8px;
  left: 33px;
  padding: 4px;
  border-radius: 50%;
  background: red;
  color: white;
}
.nav-dropdown-toggle {
  max-width: 200px;
}
</style>
