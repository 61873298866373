<template>
  <b-form-checkbox
    v-model="checked"
    :value="true"
    :unchecked-value="false"
    class="custom-checkbox"
    v-bind="$attrs"
    v-on="mountEventListeners">
    <slot />
  </b-form-checkbox>
</template>

<script>
export default {
  name: 'CoozzyFormCheckbox',
  inheritAttrs: false,
  props: {
    initial: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: this.initial
    }
  },
  computed: {
    // This peace of beauty here mounts all event listeners from the component to the checkbox-element
    // Additionally this gives the support for using the v-model attribute

    mountEventListeners() {
      return Object.assign({},
        this.$listeners,
        {
          change: () => {
            this.$emit('change', this.checked)
          }
        }
      )
    }
  },
  watch: {
    initial: function () {
      this.checked = this.initial
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-control-label::before {
  position: absolute;
  top: 0.125rem;
  left: -1.625rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid $color-input-border;
  background-color: #fff;
}

.custom-checkbox {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 -2rem 0 0;

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $color-input-border;
    background-color: white;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23515151' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !important;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #e9ecef !important;
  }
  :deep(.btn-secondary) {
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    height: 31px;
    width: 100%;
    box-shadow: none !important;
    color: $color-text-grey-dark !important;
    background-color: $color-bg-grey-dark !important;
    border-color: $color-bg-grey-dark !important;
    &.active {
      color: $color-text-white !important;
      background-color: $color-primary !important;
      border-color: $color-primary !important;
    }
  }
}
</style>
