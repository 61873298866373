<template>
  <b-modal
    v-bind="$attrs"
    no-close-on-backdrop
    v-on="mountEventListeners">
    <slot />
    <slot
      slot="modal-header"
      name="modal-header" />
    <slot
      slot="modal-title"
      name="modal-title" />
    <slot
      slot="modal-header-close"
      name="modal-header-close" />
    <slot
      slot="modal-footer"
      name="modal-footer" />
    <slot
      slot="modal-ok"
      name="modal-ok" />
    <slot
      slot="modal-cancel"
      name="modal-cancel" />
  </b-modal>
</template>

<script>
export default {
  name: 'CoozzyModal',
  inheritAttrs: false,
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style type="scss" scoped>
</style>
