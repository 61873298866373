<template>
  <div
    class="p-2 pt-2 pb-2 mb-2">
    <template
      v-for="(item, index) in listEvent">
      <div
        :key="`event_${index}`">
        <div
          v-if="item.historyType === 'HISTORY_TYPE_TICKET_CREATED'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-if="ticket.creator.userId === 'system'"
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.ticketCreatedBySystem', {datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <p
            v-else
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.ticketCreated', {user: ticket.creator.name, datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_MAIL_LINKED'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.ticketLinkedEmail', {user: item.editor.name, datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <p class="adjustOpacity">
            {{ item.mailLinked.subject }} ({{ $options.filters.timestampToDateTime(item.mailLinked.timestamp) }})
          </p>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_PRIORITY'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.priorityChanged', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.priority"
            class="row m-0">
            <div class="oldVersion alignItemsCenter mr-3">
              <img
                :alt="$t('message.ticketOverview.ticket.priority.' + item.priority.old)"
                :src="`/img/priority/prio-${item.priority.old}.svg`"
                height="15px">
              <span class="adjustOpacity ml-1">{{ $t('message.ticketOverview.ticket.priority.' + item.priority.old) }}</span>
            </div>
            <div class="alignItemsCenter">
              <coozzy-arrow-right-icon
                :name="item.timestamp" />
            </div>
            <div class="newVersion alignItemsCenter ml-3">
              <img
                :alt="$t('message.ticketOverview.ticket.priority.' + item.priority.new)"
                :src="`/img/priority/prio-${item.priority.new}.svg`"
                height="15px">
              <span class="adjustOpacity ml-1">{{ $t('message.ticketOverview.ticket.priority.' + item.priority.new) }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_ADDED_LINKS'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.ticketLinked', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.linksAdded?.links?.length > 0"
            class="row m-0">
            <div class="">
              <template
                v-for="(link, i) in item.linksAdded.links">
                <div
                  v-if="listLinks.find(x => x.id === link.ticketId)"
                  :key="`link_${i}`"
                  class="adjustOpacity d-block">
                  <img
                    v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.type.' + listLinks.find(x => x.id === link.ticketId)?.type)}"
                    height="20px"
                    class="mr-1"
                    :src="`/img/ticket/${listLinks.find(x => x.id === link.ticketId).type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                    alt="type">
                  {{ listLinks.find(x => x.id === link.ticketId)?.key }} - {{ listLinks.find(x => x.id === link.ticketId)?.title }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_REMOVED_LINKS'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.ticketLinkRemoved', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.linksRemoved?.links?.length > 0"
            class="row m-0">
            <div class="">
              <template
                v-for="(link, i) in item.linksRemoved.links">
                <div
                  v-if="listLinks.find(x => x.id === link.ticketId)"
                  :key="`link_${i}`"
                  class="adjustOpacity d-block">
                  <img
                    v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.type.' + listLinks.find(x => x.id === link.ticketId)?.type)}"
                    height="20px"
                    class="mr-1"
                    :src="`/img/ticket/${listLinks.find(x => x.id === link.ticketId).type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                    alt="type">
                  {{ listLinks.find(x => x.id === link.ticketId)?.key }} - {{ listLinks.find(x => x.id === link.ticketId)?.title }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_TITLE'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.titleUpdated', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.title"
            class="row m-0">
            <div class="oldVersion alignItemsCenter mr-3">
              <span class="adjustOpacity wrap ml-1">{{ item.title.old }}</span>
            </div>
            <div class="alignItemsCenter">
              <coozzy-arrow-right-icon
                :name="item.timestamp" />
            </div>
            <div class="newVersion wrap alignItemsCenter ml-3">
              <span class="adjustOpacity ml-1">{{ item.title.new }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_DESCRIPTION'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.descriptionUpdated', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.title"
            class="row m-0">
            <div class="oldVersion alignItemsCenter mr-3">
              <span class="adjustOpacity wrap ml-1">{{ removeHtmlTags(item.title.old) }}</span>
            </div>
            <div class="alignItemsCenter">
              <coozzy-arrow-right-icon
                :name="item.timestamp" />
            </div>
            <div class="newVersion alignItemsCenter ml-3">
              <span class="adjustOpacity wrap ml-1">{{ removeHtmlTags(item.title.new) }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_ADDED_FILES'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.fileUploaded', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_REMOVED_FILES'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.fileDeleted', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_ASSIGNEE'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.assigneeChanged', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.assignee"
            class="row m-0">
            <div class="oldVersion alignItemsCenter mr-3">
              <img
                class="avatar"
                :alt="item.assignee.old.name"
                :src="item.assignee.old.profilePictureUrl !== '' ? item.assignee.old.profilePictureUrl : '/avatar.png'">
              <span class="adjustOpacity ml-1">{{ item.assignee.old.name }}</span>
            </div>
            <div class="alignItemsCenter">
              <coozzy-arrow-right-icon
                :name="item.timestamp" />
            </div>
            <div class="newVersion alignItemsCenter ml-3">
              <img
                class="avatar"
                :alt="item.assignee.new.name"
                :src="item.assignee.new.profilePictureUrl !== '' ? item.assignee.new.profilePictureUrl : '/avatar.png'">
              <span class="adjustOpacity ml-1">{{ item.assignee.new.name }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_CONTACT_PERSON_ON_SITE'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.onSiteContactChanged', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.onSitePerson"
            class="row m-0">
            <div class="oldVersion alignItemsCenter mr-3">
              <span class="adjustOpacity ml-1">{{ item.onSitePerson.old.name }}</span>
            </div>
            <div class="alignItemsCenter">
              <coozzy-arrow-right-icon
                :name="item.timestamp" />
            </div>
            <div class="newVersion alignItemsCenter ml-3">
              <span class="adjustOpacity ml-1">{{ item.onSitePerson.new.name }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_CORRESPONDENCES'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.operatorChanged', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_DUE_DATE'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.dueDateChanged', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.dueDate"
            class="row m-0">
            <div class="oldVersion alignItemsCenter mr-3">
              <span class="adjustOpacity ml-1">{{ item.dueDate.old | objectToDate }}</span>
            </div>
            <div class="alignItemsCenter">
              <coozzy-arrow-right-icon
                :name="item.timestamp" />
            </div>
            <div class="newVersion alignItemsCenter ml-3">
              <span class="adjustOpacity ml-1">{{ item.dueDate.new | objectToDate }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_EXECUTED_FROM'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.executeFromChanged', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.executeFrom"
            class="row m-0">
            <div class="oldVersion alignItemsCenter mr-3">
              <span class="adjustOpacity ml-1">{{ item.executeFrom.old | objectToDate }}</span>
            </div>
            <div class="alignItemsCenter">
              <coozzy-arrow-right-icon
                :name="item.timestamp" />
            </div>
            <div class="newVersion alignItemsCenter ml-3">
              <span class="adjustOpacity ml-1">{{ item.executeFrom.new | objectToDate }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_STATUS'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.statusChanged', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.status"
            class="row m-0">
            <div class="col-12 col-md-auto oldVersion alignItemsCenter">
              <div
                class="btn btn_status px-3 py-1"
                :class="`status-design ${(item.status.oldStatus.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                {{ $t('message.ticketOverview.status.' + item.status.oldStatus) }}
              </div>
            </div>
            <div class="col-12 col-md-auto px-0 px-md-3 alignItemsCenter">
              <coozzy-arrow-right-icon
                :name="item.timestamp" />
            </div>
            <div class="col-12 col-md-auto newVersion alignItemsCenter">
              <div
                class="btn btn_status px-3 py-1"
                :class="`status-design ${(item.status.newStatus.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                {{ $t('message.ticketOverview.status.' + item.status.newStatus) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_NEW_COMMENT' && item.commentNew"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-if="item.commentNew.internal"
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.commentInternalAdded', { user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000) })" />
          <p
            v-else
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.commentAdded', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.commentNew"
            class="row m-0">
            <div class="alignItemsCenter">
              <span class="adjustOpacity">{{ item.commentNew.comment }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_EDITED_COMMENT' && item.commentEdited"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-if="item.commentEdited.internal"
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.commentInternalEdited', { user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000) })" />
          <p
            v-else
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.commentEdited', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.commentEdited"
            class="row m-0">
            <div class="oldVersion alignItemsCenter mr-3">
              <span class="adjustOpacity ml-1">{{ item.commentEdited.old }}</span>
            </div>
            <div class="alignItemsCenter">
              <coozzy-arrow-right-icon
                :name="item.timestamp" />
            </div>
            <div class="newVersion alignItemsCenter ml-3">
              <span class="adjustOpacity ml-1">{{ item.commentEdited.new }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_DELETED_COMMENT' && item.commentDeleted"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.commentDeleted', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.commentDeleted"
            class="row m-0">
            <div class="alignItemsCenter">
              <span class="adjustOpacity">{{ item.commentDeleted.comment }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_ADDED_CONNECTIONS'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.connectionAdded', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.connectionAdded && item.connectionAdded.references.length > 0"
            class="row m-0">
            <div
              v-for="(ref, index1) in item.connectionAdded.references"
              :key="index1"
              class="col-12 d-flex align-items-center adjustOpacity p-0">
              <div
                v-if="foundRefs(ref.id)"
                class="">
                <span v-if="foundRefs(ref.id).property">
                  <span>{{ foundRefs(ref.id).property.name }}</span>
                </span>
                <span v-if="foundRefs(ref.id).building">
                  <span v-if="foundRefs(ref.id).property"> > </span>
                  <span>{{ foundRefs(ref.id).building.name }}</span>
                </span>
                <span v-if="foundRefs(ref.id).object">
                  <span v-if="foundRefs(ref.id).building"> > </span>
                  <span>{{ foundRefs(ref.id).object.name != '' ? foundRefs(ref.id).object.name : foundRefs(ref.id).object.number !== '' ? foundRefs(ref.id).object.number : foundRefs(ref.id).object.numericId }}</span>
                </span>
                <span v-if="foundRefs(ref.id).device">
                  <span v-if="foundRefs(ref.id).object || foundRefs(ref.id).building || foundRefs(ref.id).property"> > </span>
                  <span>
                    <template v-if="foundRefs(ref.id).device.type && foundRefs(ref.id).device.type !== 'DEVICE_TYPE_UNDEFINED'">
                      {{ $t('message.onBoarding.devices.typeValues.' + foundRefs(ref.id).device.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + foundRefs(ref.id).device.type).slice(0,50)+ '...' : $t('message.onBoarding.devices.typeValues.' + foundRefs(ref.id).device.type) }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </span>
                </span>
                <span v-if="foundRefs(ref.id).object && foundRefs(ref.id).tenancy">
                  <span v-if="foundRefs(ref.id).object"> > </span>
                  <span>
                    <template v-if="foundRefs(ref.id).tenancy.tenants">
                      <span
                        v-for="(contact, j) in foundRefs(ref.id).tenancy.tenants"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                    <template v-else-if="foundRefs(ref.id).tenancy.tenant && foundRefs(ref.id).listContact && foundRefs(ref.id).tenancy.tenant.contactIds.length > 0">
                      <span
                        v-for="(contact, j) in foundRefs(ref.id).tenancy.tenant.contactIds"
                        :key="j">
                        <template v-if="foundRefs(ref.id).listContact.find(x => x.id === contact)">
                          {{ foundRefs(ref.id).listContact.find(x => x.id === contact).name }} <span v-if="j < foundRefs(ref.id).tenancy.tenant.contactIds.length - 1">-</span>
                        </template>
                      </span>
                    </template>
                    <template v-else>
                      <span
                        v-for="(contact, j) in foundRefs(ref.id).listContact"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                  </span>
                </span>
                <span v-if="foundRefs(ref.id).object && foundRefs(ref.id).condominium">
                  <span v-if="foundRefs(ref.id).object"> > </span>
                  <span>
                    <template v-if="foundRefs(ref.id).condominium.condominiumOwners">
                      <span
                        v-for="(contact, j) in foundRefs(ref.id).condominium.condominiumOwners"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                    <template v-else>
                      <span
                        v-for="(contact, j) in foundRefs(ref.id).listContact"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                  </span>
                </span>
                <span v-if="foundRefs(ref.id).contact">
                  <span>{{ foundRefs(ref.id).contact.name }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_REMOVED_CONNECTIONS'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.connectionRemoved', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="item.connectionRemoved && item.connectionRemoved.references.length > 0"
            class="row m-0">
            <div
              v-for="(ref, index2) in item.connectionRemoved.references"
              :key="index2"
              class="col-12 d-flex align-items-center adjustOpacity p-0">
              <div
                v-if="foundRefs(ref.id)"
                class="">
                <span v-if="foundRefs(ref.id).property">
                  <span>{{ foundRefs(ref.id).property.name }}</span>
                </span>
                <span v-if="foundRefs(ref.id).building">
                  <span v-if="foundRefs(ref.id).property"> > </span>
                  <span>{{ foundRefs(ref.id).building.name }}</span>
                </span>
                <span v-if="foundRefs(ref.id).object">
                  <span v-if="foundRefs(ref.id).building"> > </span>
                  <span>{{ foundRefs(ref.id).object.name != '' ? foundRefs(ref.id).object.name : foundRefs(ref.id).object.number !== '' ? foundRefs(ref.id).object.number : foundRefs(ref.id).object.numericId }}</span>
                </span>
                <span v-if="foundRefs(ref.id).device">
                  <span v-if="foundRefs(ref.id).object || foundRefs(ref.id).building || foundRefs(ref.id).property"> > </span>
                  <span>
                    <template v-if="foundRefs(ref.id).device.type && foundRefs(ref.id).device.type !== 'DEVICE_TYPE_UNDEFINED'">
                      {{ $t('message.onBoarding.devices.typeValues.' + foundRefs(ref.id).device.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + foundRefs(ref.id).device.type).slice(0,50)+ '...' : $t('message.onBoarding.devices.typeValues.' + foundRefs(ref.id).device.type) }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </span>
                </span>
                <span v-if="foundRefs(ref.id).object && foundRefs(ref.id).tenancy">
                  <span v-if="foundRefs(ref.id).object"> > </span>
                  <span>
                    <template v-if="foundRefs(ref.id).tenancy.tenants">
                      <span
                        v-for="(contact, j) in foundRefs(ref.id).tenancy.tenants"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                    <template v-else-if="foundRefs(ref.id).tenancy.tenant && foundRefs(ref.id).listContact && foundRefs(ref.id).tenancy.tenant.contactIds.length > 0">
                      <span
                        v-for="(contact, j) in foundRefs(ref.id).tenancy.tenant.contactIds"
                        :key="j">
                        <template v-if="foundRefs(ref.id).listContact.find(x => x.id === contact)">
                          {{ foundRefs(ref.id).listContact.find(x => x.id === contact).name }} <span v-if="j < foundRefs(ref.id).tenancy.tenant.contactIds.length - 1">-</span>
                        </template>
                      </span>
                    </template>
                    <template v-else>
                      <span
                        v-for="(contact, j) in foundRefs(ref.id).listContact"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                  </span>
                </span>
                <span v-if="foundRefs(ref.id).object && foundRefs(ref.id).condominium">
                  <span v-if="foundRefs(ref.id).object"> > </span>
                  <span>
                    <template v-if="foundRefs(ref.id).condominium.condominiumOwners">
                      <span
                        v-for="(contact, j) in foundRefs(ref.id).condominium.condominiumOwners"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                    <template v-else>
                      <span
                        v-for="(contact, j) in foundRefs(ref.id).listContact"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                  </span>
                </span>
                <span v-if="foundRefs(ref.id).contact">
                  <span>{{ foundRefs(ref.id).contact.name }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.historyType === 'HISTORY_TYPE_TYPE'"
          class="cases">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="mb-1"
            v-html="$t('message.ticketOverview.ticket.history.ticketTypeChanged', {user: getUser(item), datetime: $options.filters.timestampToDateTime(item.timestamp / 1000)})" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import Vue from 'vue'
import { user } from '@/mixins/user'
import CoozzyArrowRightIcon from '@/framework/components/icons/CoozzyArrowRightIcon'

export default {
  name: 'TicketHistory',
  components: {
    CoozzyArrowRightIcon
  },
  mixins: [user],
  props: {
    listEvent: {
      type: Array,
      default: () => {
        return []
      }
    },
    ticket: {
      type: Object,
      default: null
    },
    listLinks: {
      type: Array,
      default: () => {
        return []
      }
    },
    listReferences: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {

  },
  watch: {
  },
  mounted() {
  },
  methods: {
    removeHtmlTags(text) {
      return text.replace(/<br>/g, '\n')
    },
    getUser(item) {
      if (item && item.editor && item.editor.name) {
        if (item.editor.name !== '') {
          return item.editor.name
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    },
    foundRefs(id) {
      let item = null
      this.listReferences.forEach(element => {
        if (id.startsWith('obj')) {
          if (element.object && element.object.id === id && !element.tenancy && !element.condominium && !element.device) {
            item = element
          }
        }
        if (id.startsWith('building')) {
          if (element.building && element.building.id === id && !element.object && !element.device) {
            item = element
          }
        }
        if (id.startsWith('condominium')) {
          if (element.condominium && element.condominium.id === id) {
            item = element
          }
        }
        if (id.startsWith('prop')) {
          if (element.property && element.property.id === id && !element.building && !element.device) {
            item = element
          }
        }
        if (id.startsWith('contact')) {
          if (element.contact && element.contact.id === id) {
            item = element
          }
        }
        if (id.startsWith('device')) {
          if (element.device && element.device.id === id) {
            item = element
          }
        }
        if (id.startsWith('tenancy')) {
          if (element.tenancy && element.tenancy.id === id) {
            item = element
          }
        }
      })
      return item
    }
  }
}
</script>

<style lang="scss" scoped>
.userHistory {
  font-weight: bold;
}
.oldVersion, .newVersion {
  max-width: calc(50% - 25px);
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.adjustOpacity {
  opacity: 0.7;
}
.alignItemsCenter {
  display: flex;
  align-items: center;
}
.cases {
  margin-bottom: 1rem;
}
.btn_status {
  cursor: default !important;
  min-width: 130px !important;
}
.wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
