<template>
  <section>
    <div class="body-of-child">
      <div>
        <div class="row m-0">
          <div class="my-5">
            <div class="mt-5">
              <h1>{{ $t('message.staticPages.tos.title') }}</h1>
              <p class="sub-title">
                {{ $t('message.staticPages.tos.subtitle') }}
              </p>
              <hr align="left">
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titleone') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.onetext')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titletwo') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.texttwo')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titlethree') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p
                  class="sub-title mt-4 mb-3"
                  v-html="$t('message.staticPages.tos.titlethreebody.atitle')" />
                <p v-html="$t('message.staticPages.tos.titlethreebody.atext')" />
                <p
                  class=" sub-title mt-4 mb-3"
                  v-html="$t('message.staticPages.tos.titlethreebody.btitle')" />
                <p v-html="$t('message.staticPages.tos.titlethreebody.btext')" />
                <p
                  class=" sub-title mt-4 mb-3"
                  v-html="$t('message.staticPages.tos.titlethreebody.ctitle')" />
                <p v-html="$t('message.staticPages.tos.titlethreebody.ctext')" />
                <p
                  class=" sub-title mt-4 mb-3"
                  v-html="$t('message.staticPages.tos.titlethreebody.dtitle')" />
                <p v-html="$t('message.staticPages.tos.titlethreebody.dtext')" />
                <p
                  class=" sub-title mt-4 mb-3"
                  v-html="$t('message.staticPages.tos.titlethreebody.etitle')" />
                <p v-html="$t('message.staticPages.tos.titlethreebody.etext')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titlefour') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12  text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.textfour')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titlefive') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.textfive')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titlesix') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.textsix')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titleseven') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.textseven')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titleeight') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.texteight')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titlenine') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.textnine')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titleteen') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.textteen')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titleeleven') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.texteleven')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div>
              <h4 class="mt-5">
                {{ $t('message.staticPages.tos.titletwelve') }}
              </h4>
              <hr align="left">
            </div>
            <div class="row">
              <div class="col-md-12 text">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('message.staticPages.tos.texttwelve')" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text margintop35">
                <p>
                  <!-- eslint-disable vue/no-v-html -->
                  <b v-html="$t('message.staticPages.tos.date')" />
                  <!-- eslint-enable vue/no-v-html -->
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text marginbottom15 margintop35">
                <p>
                  <!-- eslint-disable vue/no-v-html -->
                  <b v-html="$t('message.staticPages.tos.signature')" />
                  <!-- eslint-enable vue/no-v-html -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <coozzy-footer />
  </section>
</template>

<script>

import { language } from '../mixins/language'

export default {
  name: 'ToSView',
  mixins: [language]
}
</script>

<style lang="scss" scoped>
    .body-of-child {
      background-color: $color-text-white;
    }
    :deep(header) {
      border-bottom: 1px solid $color-bg-grey;
    }
    hr {
      border: 1px solid $color-primary-dark;
      width: 30px;
    }
    p a {
      color: $color-primary-dark;
    }
    h4 {
      line-height: 29px;
      font-weight: 300;
      font-size: 23px;
      color: $color-text-grey;
    }
    .sub-title {
      font-weight: bold;
      color: $color-text-grey-dark;
      font-size: 16px;
    }
</style>
