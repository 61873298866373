<template>
  <section class="w-100">
    <div
      :id="`energySource-${index}`"
      v-b-toggle="`collapse-energySource-object-${index}`"
      class="col pt-3 mb-0">
      <h5
        v-if="!energySourceType || energySourceType === 'ENERGY_SOURCE_TYPE_UNDEFINED'"
        class="cursor-pointer hover width-only-content ">
        {{ $t('message.onBoarding.buildings.energySource.newEnergySource') }}
      </h5>
      <h5
        v-else
        class="cursor-pointer hover width-only-content ">
        {{ ($t('message.onBoarding.buildings.energySource.types.' + energySourceType)) }}
      </h5>
    </div>
    <div class="col-12 px-0">
      <b-collapse
        :id="`collapse-energySource-object-${index}`"
        ref="collapse"
        :visible="expandByDefault"
        class="col">
        <div class="row details-row-bg-1">
          <div class="col-sm-6 col-md-3 mt-3">
            <label for="type">{{ $t('message.onBoarding.energySource.title') }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="type"
              v-model="energySourceKind">
              <option value="ENERGY_SOURCE_KIND_UNDEFINED" />
              <option
                v-for="availableType of availableEnergySourceKinds"
                :key="availableType"
                :value="availableType">
                {{ $t('message.onBoarding.energySource.kind.' + availableType) }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">{{ energySourceKind === '' || energySourceKind === null || energySourceKind === 'ENERGY_SOURCE_KIND_UNDEFINED' ? '-' : $t('message.onBoarding.energySource.kind.' + energySourceKind) }}</span>
          </div>
        </div>
        <template v-if="energySourceKind === 'ENERGY_SOURCE_KIND_HEATER' || energySourceKind === 'ENERGY_SOURCE_KIND_HOT_WATER' || energySourceKind === 'ENERGY_SOURCE_KIND_HEATER_AND_HOT_WATER'">
          <div class="row details-row-bg-2">
            <div class="col-sm-6 col-md-3 mt-3">
              <label for="type">{{ $t('message.onBoarding.buildings.energySource.type') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="type"
                v-model="energySourceType">
                <option value="ENERGY_SOURCE_TYPE_UNDEFINED" />
                <option
                  v-for="availableType of availableEnergySourceTypes"
                  :key="availableType"
                  :value="availableType">
                  {{ $t('message.onBoarding.buildings.energySource.types.' + availableType) }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ energySourceType === '' || energySourceType === null || energySourceType ===
                  'ENERGY_SOURCE_TYPE_UNDEFINED' ? '-' : $t('message.onBoarding.buildings.energySource.types.' + energySourceType)
                }}</span>
            </div>
            <div class="col-sm-6 col-md-3 mt-3">
              <label for="type">{{ $t('message.onBoarding.energySource.heatDistribution.title') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="type"
                v-model="heatDistribution">
                <option value="ENERGY_SOURCE_TYPE_UNDEFINED" />
                <option
                  v-for="heat of availableHeatDistribution"
                  :key="heat"
                  :value="heat">
                  {{ $t('message.onBoarding.energySource.heatDistribution.' + heat) }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ heatDistribution === '' || heatDistribution === null || heatDistribution ===
                  'ENERGY_SOURCE_TYPE_UNDEFINED' ? '-' : $t('message.onBoarding.energySource.heatDistribution.' + heatDistribution)
                }}</span>
            </div>
            <div class="col-sm-6 col-md-3 mt-3">
              <label for="type">{{ energySourceKind === 'ENERGY_SOURCE_KIND_HEATER' || energySourceKind === 'ENERGY_SOURCE_KIND_HEATER_AND_HOT_WATER' ?
                $t('message.onBoarding.energySource.costDistributionHeater') :
                $t('message.onBoarding.energySource.costDistributionHotWater') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="type"
                v-model="costDistribution">
                <option value="COST_DISTRIBUTION_UNDEFINED" />
                <option
                  v-for="heat of availableCostDistribution"
                  :key="heat"
                  :value="heat">
                  {{ $t('message.onBoarding.energySource.costDistribution.' + heat) }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ costDistribution === '' || costDistribution === null || costDistribution ===
                  'COST_DISTRIBUTION_UNDEFINED' ? '-' : $t('message.onBoarding.energySource.costDistribution.' + costDistribution)
                }}</span>
            </div>
            <div
              v-if="energySourceKind === 'ENERGY_SOURCE_KIND_HEATER_AND_HOT_WATER'"
              class="col-sm-6 col-md-3 mt-3">
              <label for="type">{{ $t('message.onBoarding.energySource.costDistributionHotWater') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="type"
                v-model="costDistribution2">
                <option value="COST_DISTRIBUTION_UNDEFINED" />
                <option
                  v-for="heat of availableCostDistribution"
                  :key="heat"
                  :value="heat">
                  {{ $t('message.onBoarding.energySource.costDistribution.' + heat) }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ costDistribution2 === '' || costDistribution2 === null || costDistribution2 ===
                  'COST_DISTRIBUTION_UNDEFINED' ? '-' : $t('message.onBoarding.energySource.costDistribution.' + costDistribution2)
                }}</span>
            </div>
          </div>
          <div class="row details-row-bg-1">
            <div
              class="col-sm-6 col-md-3 mt-3">
              <coozzy-form-input-numeric
                v-model="energyReferenceArea"
                type="number"
                min="0"
                :is-read-only="readOnly"
                :is-decimal="true"
                :is-formated="true"
                :filter="'formatArea'"
                :name="$t('message.onBoarding.energySource.energyReferenceArea')" />
            </div>
            <div class="col-6 col-md-3 mt-3">
              <div
                v-if="!readOnly"
                class="d-flex col-sm-12 pl-0 mt-2">
                <coozzy-form-checkbox
                  :id="(energySource.id || energySource.internalId) + 'photovoltaic'"
                  :key="(energySource.id || energySource.internalId) +'photovoltaic'"
                  v-model="photovoltaic"
                  :initial="photovoltaic"
                  class="align-self-center mt-3">
                  {{ $t('message.onBoarding.energySource.photovoltaic') }}
                </coozzy-form-checkbox>
              </div>
              <div
                v-else
                class="col-sm-12 pl-0">
                <label>
                  {{ $t('message.onBoarding.energySource.photovoltaic') }}
                </label>
                <span class="show-data">
                  {{ photovoltaic ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
                </span>
              </div>
            </div>
            <div class="col-6 col-md-3 mt-3">
              <div
                v-if="!readOnly"
                class="d-flex col-sm-12 pl-0 mt-2">
                <coozzy-form-checkbox
                  :id="(energySource.id || energySource.internalId) + 'selfConsumptionCommunity'"
                  :key="(energySource.id || energySource.internalId) + 'selfConsumptionCommunity'"
                  v-model="selfConsumptionCommunity"
                  :initial="selfConsumptionCommunity"
                  class="align-self-center mt-3">
                  {{ $t('message.onBoarding.energySource.selfConsumption') }}
                </coozzy-form-checkbox>
              </div>
              <div
                v-else
                class="col-sm-12 pl-0">
                <label>
                  {{ $t('message.onBoarding.energySource.selfConsumption') }}
                </label>
                <span class="show-data">
                  {{ selfConsumptionCommunity ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
                </span>
              </div>
            </div>
          </div>
          <div class="row details-row-bg-2">
            <div
              class="col-sm-6 col-md-3 mt-3">
              <coozzy-form-input-numeric
                v-model="consumption"
                type="number"
                :is-decimal="true"
                :is-formated="true"
                :is-read-only="readOnly"
                :filter="'formatNumber'"
                :name="$t('message.onBoarding.energySource.consumption')" />
            </div>
            <div
              class="col-sm-6 col-md-3 mt-3">
              <label
                for="unit"
                class="mb-0">
                {{ $t('message.onBoarding.buildings.energySource.unit') }}
              </label>
              <coozzy-form-select
                v-if="!readOnly"
                id="unit"
                v-model="unit">
                <option value="ENERGY_SOURCE_UNIT_UNDEFINED" />
                <option
                  v-for="unitValue of unitList"
                  :key="unitValue"
                  :value="unitValue">
                  {{ $t('message.onBoarding.buildings.energySource.units.' + unitValue) }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">
                {{ !unit || unit === 'ENERGY_SOURCE_UNIT_UNDEFINED' ? '-' : $t('message.onBoarding.buildings.energySource.units.' + unit) }}
              </span>
            </div>
            <div
              class="col-sm-6 col-md-3 mt-3">
              <coozzy-form-input-numeric
                v-model="co2PerYear"
                type="number"
                :is-read-only="readOnly"
                :filter="'formatNumber'"
                :is-decimal="true"
                :is-formated="true"
                :name="$t('message.onBoarding.energySource.co2PerYear')" />
            </div>
            <div
              v-if="unit !== 'ENERGY_SOURCE_UNIT_KWH'"
              class="col-sm-6 col-md-3 mt-3">
              <coozzy-form-input-numeric
                v-model="consumptionKwhPerYear"
                type="number"
                :is-read-only="readOnly"
                :filter="'formatNumber'"
                :is-decimal="true"
                :is-formated="true"
                :name="$t('message.onBoarding.energySource.consumptionKwhPerYear')" />
            </div>
          </div>
          <div
            class="row">
            <div
              v-if="'ENERGY_SOURCE_GAS' === energySourceType || 'ENERGY_SOURCE_OIL' === energySourceType || energySourceType === 'ENERGY_SOURCE_WOOD_GENERIC' || energySourceType === 'ENERGY_SOURCE_WOOD' || energySourceType === 'ENERGY_SOURCE_WOOD_PELLETS' || energySourceType === 'ENERGY_SOURCE_WOOD_CHIPS'"
              class="col-sm-6 col-md-3 mt-3">
              <coozzy-form-input-numeric
                v-model="tankSize"
                type="number"
                min="0"
                :is-read-only="readOnly"
                :is-decimal="true"
                :is-formated="true"
                :filter="'formatNumber'"
                :name="$t('message.onBoarding.buildings.energySource.tankSize')" />
            </div>
          </div>
          <div
            class="row">
            <div class="col">
              <h6
                v-b-toggle="`collapse-energy-source-tankReadings`"
                class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
                {{ $t('message.onBoarding.buildings.energySource.tankReadings') }}
              </h6>
            </div>
          </div>
          <b-collapse
            :id="`collapse-energy-source-tankReadings`"
            :visible="true">
            <div class="row">
              <template
                v-for="(tankReading, adjIndex) in tankReadings">
                <div
                  :id="'tankReadingData' + adjIndex"
                  :key="'tankReadingData' + adjIndex"
                  class="col-md-12">
                  <div
                    class="row py-01rem"
                    :class="adjIndex % 2 || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'">
                    <div class="col-sm-12 col-md-3">
                      <coozzy-form-input
                        :id="dates.tankReadingsByDate[adjIndex]"
                        v-model="dates.tankReadingsByDate[adjIndex]"
                        :is-read-only="readOnly"
                        :filter="'formatDate'"
                        type="date"
                        :name="$t('message.calendar.reminder.date')"
                        @blur="dateTankToObject(adjIndex)" />
                    </div>
                    <div class="col-sm-12 col-md-3">
                      <coozzy-form-input-numeric
                        v-model="tankReading.value"
                        type="number"
                        :is-read-only="readOnly"
                        :is-decimal="true"
                        :is-formated="true"
                        :filter="'formatNumber'"
                        :forced-placeholder="unit !== 'ENERGY_SOURCE_UNIT_UNDEFINED' ? $t('message.onBoarding.buildings.energySource.units.' + unit) : ''"
                        :name="$t('message.onBoarding.buildings.energySource.reading')"
                        :check-valide="$v.tankReadings.$each[adjIndex].value.$dirty && $v.tankReadings.$each[adjIndex].value.$error ? 'is-invalid' : ''" />
                    </div>
                    <div
                      v-if="!readOnly"
                      class="col-sm-12 col-md-3">
                      <coozzy-button
                        design="alert"
                        class="align-self-center mt-4"
                        @click="openConfirmationModal(adjIndex)">
                        {{ $t('message.generic.delete') }}
                      </coozzy-button>
                    </div>
                  </div>
                </div>
              </template>
              <div class="col-md-2 col-2 mt-2">
                <coozzy-button
                  v-if="!readOnly"
                  design="prop-green"
                  class="w-100 mb-3"
                  @click="addTankReadingsClicked">
                  {{ $t('message.onBoarding.buildings.energySource.addReading') }}
                </coozzy-button>
              </div>
            </div>
          </b-collapse>
          <div
            class="row">
            <div class="col">
              <h6
                v-b-toggle="`collapse-energy-source-order`"
                class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
                {{ $t('message.onBoarding.buildings.energySource.order') }}
              </h6>
            </div>
          </div>
          <b-collapse
            :id="`collapse-energy-source-order`"
            :visible="true">
            <div class="row">
              <template
                v-for="(order, adjIndex) in orders">
                <div
                  :id="'orderData' + adjIndex"
                  :key="'orderData' + adjIndex"
                  class="col-md-12">
                  <div
                    class="row py-01rem"
                    :class="adjIndex % 2 || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'">
                    <div class="col-sm-12 col-md-3">
                      <coozzy-form-input
                        v-model="dates.orderByDate[adjIndex]"
                        :is-read-only="readOnly"
                        :filter="'formatDate'"
                        type="date"
                        :name="$t('message.calendar.reminder.date')"
                        @blur="dateOrderToObject(adjIndex)" />
                    </div>
                    <div class="col-sm-12 col-md-3">
                      <coozzy-form-input-numeric
                        v-model="order.quantity"
                        type="number"
                        :filter="'formatNumber'"
                        :is-decimal="true"
                        :is-formated="true"
                        :forced-placeholder="unit !== 'ENERGY_SOURCE_UNIT_UNDEFINED' ? $t('message.onBoarding.buildings.energySource.units.' + unit) : ''"
                        :is-read-only="readOnly"
                        :name="$t('message.onBoarding.buildings.energySource.quantity')" />
                    </div>
                    <div
                      v-if="!readOnly"
                      class="col-sm-12 col-md-3">
                      <coozzy-button
                        design="alert"
                        class="align-self-center mt-4"
                        @click="openConfirmationOrderModal(adjIndex)">
                        {{ $t('message.generic.delete') }}
                      </coozzy-button>
                    </div>
                  </div>
                </div>
              </template>
              <div class="col-md-2 col-2 mt-2">
                <coozzy-button
                  v-if="!readOnly"
                  design="prop-green"
                  class="w-100"
                  @click="addOrderClicked">
                  {{ $t('message.onBoarding.buildings.energySource.addOrder') }}
                </coozzy-button>
              </div>
            </div>
          </b-collapse>
        </template>
        <div class="row">
          <div class="col-12 mt-3">
            <coozzy-button
              size="small"
              design="red"
              class="float-right"
              @click="removeClicked">
              {{ $t('message.onBoarding.buildings.energySource.deleteEnergySource') }}
            </coozzy-button>
          </div>
        </div>
        <b-modal
          :id="'tankReading-confirmationModal'"
          ref="tankReading-confirmationModal"
          no-close-on-backdrop
          hide-footer
          :title="$t('message.onBoarding.deleteModals.confirmation')">
          <template>
            <div class="col">
              <p>{{ $t('message.onBoarding.deleteModals.bodyTankReadings') }}</p>
            </div>
            <div class="col">
              <coozzy-button
                size="small"
                class="mb-0 border"
                design="transparent"
                @click="$bvModal.hide('tankReading-confirmationModal')">
                {{ $t('message.generic.cancel') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="float-right mb-0"
                design="green"
                @click="deleteTankReadingClicked(adjIndexToDelete)">
                {{ $t('message.generic.delete') }}
              </coozzy-button>
            </div>
          </template>
        </b-modal>
        <b-modal
          :id="'order-confirmationModal'"
          ref="order-confirmationModal"
          no-close-on-backdrop
          hide-footer
          :title="$t('message.onBoarding.deleteModals.confirmation')">
          <template>
            <div class="col">
              <p>{{ $t('message.onBoarding.deleteModals.bodyEnergySourceOrder') }}</p>
            </div>
            <div class="col">
              <coozzy-button
                size="small"
                class="mb-0 border"
                design="transparent"
                @click="$bvModal.hide('order-confirmationModal')">
                {{ $t('message.generic.cancel') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="float-right mb-0"
                design="green"
                @click="deleteOrderClicked(adjOrderIndexToDelete)">
                {{ $t('message.generic.delete') }}
              </coozzy-button>
            </div>
          </template>
        </b-modal>
      </b-collapse>
    </div>

    <b-modal
      :id="'deleteModal'"
      ref="deleteModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodyEnergySource') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('deleteModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import { onboarding } from '../../../mixins/onboarding'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import { minValue } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'
import MediaApi from '@/misc/apis/MediaApi'
import { tracking } from '@/mixins/tracking'

export default {
  name: 'EnergySource',
  components: { CoozzyButton, CoozzyFormSelect },
  mixins: [onboarding, validation, tracking],
  props: {
    index: {
      type: Number,
      default: 0
    },
    energySource: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    target: {
      type: String,
      default: 'building'
    }
  },
  data() {
    return {
      dates: {
        tankReadingsByDate: [],
        orderByDate: []
      },
      datesChanged: false,
      adjIndexToDelete: null,
      adjOrderIndexToDelete: null,
      availableEnergySourceKinds: ['ENERGY_SOURCE_KIND_HEATER', 'ENERGY_SOURCE_KIND_HOT_WATER', 'ENERGY_SOURCE_KIND_HEATER_AND_HOT_WATER'],
      availableEnergySourceTypes: ['ENERGY_SOURCE_NO', 'ENERGY_SOURCE_AIR', 'ENERGY_SOURCE_GEOTHERMAL_ENERGY', 'ENERGY_SOURCE_GEOTHERMAL_PROBE', 'ENERGY_SOURCE_EARTH_TUBE', 'ENERGY_SOURCE_WATER', 'ENERGY_SOURCE_GAS', 'ENERGY_SOURCE_OIL', 'ENERGY_SOURCE_WOOD_GENERIC', 'ENERGY_SOURCE_WOOD', 'ENERGY_SOURCE_WOOD_PELLETS', 'ENERGY_SOURCE_WOOD_CHIPS', 'ENERGY_SOURCE_WASTE_HEAT', 'ENERGY_SOURCE_ELECTRICITY', 'ENERGY_SOURCE_SUN', 'ENERGY_SOURCE_DISTRICT_HEATING_GENERIC', 'ENERGY_SOURCE_DISTRICT_HEATING_HIGH', 'ENERGY_SOURCE_DISTRICT_HEATING_LOW', 'ENERGY_SOURCE_HEAT_PUMP', 'ENERGY_SOURCE_OTHER'],
      availableHeatDistribution: ['HEAT_DISTRIBUTION_RADIATORS', 'HEAT_DISTRIBUTION_FLOOR_HEATING', 'HEAT_DISTRIBUTION_CEILING_HEATING'],
      availableCostDistribution: ['COST_DISTRIBUTION_NONE', 'COST_DISTRIBUTION_CONSUMPTION', 'COST_DISTRIBUTION_FIXED_KEY'],
      unitList: [
        'ENERGY_SOURCE_UNIT_L',
        'ENERGY_SOURCE_UNIT_KG',
        'ENERGY_SOURCE_UNIT_M3',
        'ENERGY_SOURCE_UNIT_KWH'
      ],
      documents: []
    }
  },
  computed: {
    tankReadings() {
      const t = this
      const array = this.energySource.tankReadings.filter(x => !x.isNew).sort(function (a, b) {
        const vb = new Date(t.$options.filters.objectToDateInput(b.date)).getTime()
        const va = new Date(t.$options.filters.objectToDateInput(a.date)).getTime()

        return va > vb ? -1 : 1
      }).concat(this.energySource.tankReadings.filter(x => x.isNew))
      return array
    },
    orders() {
      const t = this
      const array = this.energySource.orders.filter(x => !x.isNew).sort(function (a, b) {
        const vb = new Date(t.$options.filters.objectToDateInput(b.date)).getTime()
        const va = new Date(t.$options.filters.objectToDateInput(a.date)).getTime()

        return va > vb ? -1 : 1
      }).concat(this.energySource.orders.filter(x => x.isNew))
      return array
    },
    currentEnergySourceDocuments() {
      const doc = this.documents
      return doc.sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    energySourceKind: {
      get() {
        return this.energySource.kind ? this.energySource.kind : 'ENERGY_SOURCE_KIND_UNDEFINED'
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.kind = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    energySourceType: {
      get() {
        return this.energySource.type ? this.energySource.type : 'ENERGY_SOURCE_TYPE_UNDEFINED'
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.type = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    heatDistribution: {
      get() {
        return this.energySource.heatDistribution && this.energySource.heatDistribution !== 'HEAT_DISTRIBUTION_UNDEFINED' ? this.energySource.heatDistribution : null
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.heatDistribution = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    costDistribution: {
      get() {
        return this.energySource.costDistribution !== -1 ? this.energySource.costDistribution : null
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.costDistribution = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    costDistribution2: {
      get() {
        return this.energySource.costDistribution2 !== -1 ? this.energySource.costDistribution2 : null
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.costDistribution2 = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    consumptionKwhPerYear: {
      get() {
        return this.energySource.consumptionKwhPerYear !== -1 ? this.energySource.consumptionKwhPerYear : null
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.consumptionKwhPerYear = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    tankSize: {
      get() {
        return this.energySource.tankSize !== -1 ? this.energySource.tankSize : null
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.tankSize = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    co2PerYear: {
      get() {
        return this.energySource.co2PerYear !== -1 ? this.energySource.co2PerYear : null
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.co2PerYear = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    consumption: {
      get() {
        return this.energySource.consumption !== -1 ? this.energySource.consumption : null
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.consumption = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    energyReferenceArea: {
      get() {
        return this.energySource.energyReferenceArea !== -1 ? this.energySource.energyReferenceArea : null
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.energyReferenceArea = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    photovoltaic: {
      get() {
        return this.energySource.photovoltaic
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.photovoltaic = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    selfConsumptionCommunity: {
      get() {
        return this.energySource.selfConsumptionCommunity
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.selfConsumptionCommunity = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    },
    unit: {
      get() {
        return this.energySource.unit ? this.energySource.unit : 'ENERGY_SOURCE_UNIT_UNDEFINED'
      },
      set(value) {
        const newEnergySource = this.energySource
        newEnergySource.unit = value
        this.dispatchEnergySourceUpdate(newEnergySource)
      }
    }
  },
  watch: {
    'energySource.tankReadings': {
      deep: true,
      handler: function (val) {
        if (!this.datesChanged) {
          this.dispatchEnergySourceUpdate(this.energySource)
        }
      }
    },
    'energySource.orders': {
      deep: true,
      handler: function (val) {
        if (!this.datesChanged) {
          this.dispatchEnergySourceUpdate(this.energySource)
        }
      }
    },
    consumption: function () {
      this.callCalculation()
    },
    unit: function () {
      this.callCalculation()
    },
    energySourceType: function (newVal, oldVal) {
      if (newVal === 'ENERGY_SOURCE_GAS') {
        this.unit = 'ENERGY_SOURCE_UNIT_M3'
      } else if (newVal === 'ENERGY_SOURCE_ELECTRICITY' || newVal === 'ENERGY_SOURCE_SUN') {
        this.unit = 'ENERGY_SOURCE_UNIT_KWH'
      }
      this.callCalculation()
    },
    tankReadings: function (newVal, oldVal) {
      this.dates.tankReadingsByDate = []
      newVal.forEach((tankReading, i) => {
        this.dates.tankReadingsByDate.push(this.$options.filters.objectToDateInput(tankReading.date))
      })
    },
    orders: function (newVal, oldVal) {
      this.dates.orderByDate = []
      newVal.forEach((order, i) => {
        this.dates.orderByDate.push(this.$options.filters.objectToDateInput(order.date))
      })
    }
  },
  mounted() {
    this.loadDocuments()
    this.datesChanged = true
    const t = this
    this.energySource.tankReadings = this.energySource.tankReadings.sort(function (a, b) {
      const vb = new Date(t.$options.filters.objectToDateInput(b.date)).getTime()
      const va = new Date(t.$options.filters.objectToDateInput(a.date)).getTime()

      return va > vb ? -1 : 1
    })
    this.energySource.tankReadings.forEach((tankReading, i) => {
      this.dates.tankReadingsByDate.push(this.$options.filters.objectToDateInput(tankReading.date))
    })
    this.energySource.orders = this.energySource.orders.sort(function (a, b) {
      const vb = new Date(t.$options.filters.objectToDateInput(b.date)).getTime()
      const va = new Date(t.$options.filters.objectToDateInput(a.date)).getTime()

      return va > vb ? -1 : 1
    })
    this.energySource.orders.forEach((order, i) => {
      this.dates.orderByDate.push(this.$options.filters.objectToDateInput(order.date))
    })
    setTimeout(() => {
      this.datesChanged = false
    }, 100)
  },
  beforeDestroy() {
    this.$root.$off('bv::collapse::state')
  },
  methods: {
    callCalculation() {
      if (this.calculationCO2kgPeryear() !== 0) {
        this.co2PerYear = this.consumption * this.calculationCO2kgPeryear()
      }
      if (this.calculationConsumptionkWHPeryear() !== 0) {
        this.consumptionKwhPerYear = this.consumption * this.calculationConsumptionkWHPeryear()
      }
    },
    dispatchEnergySourceUpdate(newEnergySource) {
      if (this.target === 'building') {
        this.$store.dispatch('onboarding/updateBuildingEnergySource', newEnergySource)
      } else {
        this.$store.dispatch('onboarding/updateObjectEnergySource', newEnergySource)
      }
    },
    loadDocuments() {
      let documentIds = []
      for (const doc of this.energySource.documentIds) {
        documentIds = documentIds.concat(doc)
      }
      if (documentIds.length > 0) {
        MediaApi.listDocuments(documentIds)
          .then(response => {
            this.documents = response.documents.reverse()
          })
      }
    },
    addDocumentClicked(suffix = '') {
      this.$bvModal.show('add-document-modal' + suffix)
    },
    documentCreated(document) {
      const energySource = this.energySource
      energySource.documentIds.push(document.id)
      this.documents.push(document)
      this.$emit('change-energy-source')
    },
    tankReadingsDocumentCreated(document, sufix) {
      // Update energySource in vuex store with new documentId
      const index = parseInt(sufix.replace('tankReading_', ''))
      const energySource = this.energySource
      energySource.tankReadings[index].documentIds.push(document.id)
      this.$emit('change-energy-source')

      this.tankReadingDocuments.push(document)
    },

    versionAdded(updatedDocument) {
      this.documents.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.documents.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    deleteDocument(document) {
      this.energySource.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.energySource.documentIds.splice(index, 1)
          this.documents.splice(index, 1)
          this.$emit('energy-source-document-deleted', index)
        }
      }, this)
      this.activateChanged()
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    removeConfirmed() {
      this.$refs.deleteModal.hide()
      if (this.target === 'building') {
        this.removeBuildingEnergySource(this.energySource)
      } else {
        this.removeObjectEnergySource(this.energySource)
      }
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        this.captureMessage('validation error in energy source', { data: this.$v })
      }
      return this.$v.$invalid
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    },
    addTankReadingsClicked() {
      this.dates.tankReadingsByDate.push(null)
      this.energySource.tankReadings.push({
        date: null,
        value: null,
        documentIds: [],
        isNew: true
      })
    },
    openConfirmationModal(adjIndexToDelete) {
      this.adjIndexToDelete = adjIndexToDelete
      this.$refs['tankReading-confirmationModal'].show()
    },
    deleteTankReadingClicked(index) {
      this.$delete(this.dates.tankReadingsByDate, index)
      this.$delete(this.energySource.tankReadings, index)
      this.adjIndexToDelete = null
      this.$refs['tankReading-confirmationModal'].hide()
    },
    addOrderClicked() {
      this.dates.orderByDate.push(null)
      this.energySource.orders.push({
        date: null,
        quantity: null,
        documentIds: [],
        isNew: true
      })
    },
    openConfirmationOrderModal(adjIndexToDelete) {
      this.adjOrderIndexToDelete = adjIndexToDelete
      this.$refs['order-confirmationModal'].show()
    },
    deleteOrderClicked(index) {
      this.$delete(this.dates.orderByDate, index)
      this.$delete(this.energySource.orders, index)
      this.adjOrderIndexToDelete = null
      this.$refs['order-confirmationModal'].hide()
    },
    dateTankToObject(index) {
      const tankDate = new Date(this.dates.tankReadingsByDate[index])
      this.tankReadings[index].date = tankDate instanceof Date && isFinite(tankDate)
? {
        day: tankDate.getDate(),
        month: tankDate.getMonth() + 1,
        year: tankDate.getFullYear()
      }
: null
    },
    dateOrderToObject(index) {
      console.log('dateOrderToObject', index)
      const orderDate = new Date(this.dates.orderByDate[index])
      this.orders[index].date = orderDate instanceof Date && isFinite(orderDate)
? {
        day: orderDate.getDate(),
        month: orderDate.getMonth() + 1,
        year: orderDate.getFullYear()
      }
: null
    },
    calculationConsumptionkWHPeryear() {
      if (this.energySourceType === 'ENERGY_SOURCE_GAS' && this.unit === 'ENERGY_SOURCE_UNIT_M3') {
        return 11.4
      } else if (this.energySourceType === 'ENERGY_SOURCE_OIL' && this.unit === 'ENERGY_SOURCE_UNIT_L') {
        return 10.4
      } else if (this.energySourceType === 'ENERGY_SOURCE_OIL' && this.unit === 'ENERGY_SOURCE_UNIT_KG') {
        return 12.4
      } else if ((this.energySourceType === 'ENERGY_SOURCE_WOOD_GENERIC' || this.energySourceType === 'ENERGY_SOURCE_WOOD' || this.energySourceType === 'ENERGY_SOURCE_WOOD_PELLETS' || this.energySourceType === 'ENERGY_SOURCE_WOOD_CHIPS') && this.unit === 'ENERGY_SOURCE_UNIT_KG') {
        return 5.6
      } else if ((this.energySourceType === 'ENERGY_SOURCE_WOOD_GENERIC' || this.energySourceType === 'ENERGY_SOURCE_WOOD' || this.energySourceType === 'ENERGY_SOURCE_WOOD_PELLETS' || this.energySourceType === 'ENERGY_SOURCE_WOOD_CHIPS') && this.unit === 'ENERGY_SOURCE_UNIT_M3') {
        return 3703
      }
      return 0
    },
    calculationCO2kgPeryear() {
      if (this.energySourceType === 'ENERGY_SOURCE_GAS' && this.unit === 'ENERGY_SOURCE_UNIT_M3') {
        return 2.6
      } else if (this.energySourceType === 'ENERGY_SOURCE_OIL' && this.unit === 'ENERGY_SOURCE_UNIT_L') {
        return 3.1
      } else if (this.energySourceType === 'ENERGY_SOURCE_OIL' && this.unit === 'ENERGY_SOURCE_UNIT_KG') {
        return 3.7
      } else if ((this.energySourceType === 'ENERGY_SOURCE_WOOD_GENERIC' || this.energySourceType === 'ENERGY_SOURCE_WOOD' || this.energySourceType === 'ENERGY_SOURCE_WOOD_PELLETS' || this.energySourceType === 'ENERGY_SOURCE_WOOD_CHIPS') && this.unit === 'ENERGY_SOURCE_UNIT_KG') {
        return 0.5
      } else if ((this.energySourceType === 'ENERGY_SOURCE_WOOD_GENERIC' || this.energySourceType === 'ENERGY_SOURCE_WOOD' || this.energySourceType === 'ENERGY_SOURCE_WOOD_PELLETS' || this.energySourceType === 'ENERGY_SOURCE_WOOD_CHIPS') && this.unit === 'ENERGY_SOURCE_UNIT_M3') {
        return 100
      } else if (this.energySourceType === 'ENERGY_SOURCE_ELECTRICITY' && this.unit === 'ENERGY_SOURCE_UNIT_KWH') {
        return 0.15
      }
      return 0
    }
  },
  validations: {
    orderQuantity: {
      minValue: minValue(0)
    },
    tankReadings: {
      $each: {
        value: {
          minValue: minValue(0)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
