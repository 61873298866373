// @ts-nocheck
import Vue from 'vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import JSZip from 'jszip'
import { mapActions } from 'vuex'
import { saveAs } from 'file-saver'

const contract = {
  data() {
    return {
      blobs: [],
      renderId: '',
      isOnLoad: false,
      numberBriefButler: '-'
    }
  },
  computed: {
    getNumberBriefButler() {
      return this.numberBriefButler
    }
  },
  methods: {
    ...mapActions('contract', ['loadBriefButlerCounts']),
    setNumberBriefButler(numberBriefButler) {
      this.numberBriefButler = numberBriefButler
    },
    async countBriefButler() {
      this.numberBriefButler = await this.loadBriefButlerCounts()
    },
    getRenderStatus() {
      const contracts = localStorage && localStorage.getItem('renderId') ? JSON.parse(localStorage.getItem('renderId')) : null
      if (contracts && !this.isOnLoad) {
        this.isOnLoad = true
        const renderId = contracts.map(x => x.renderId)
        const renderIdsFormated = renderId.map(x => x.split('_')[0])
        const renderIdsWithData = renderId
        let allEntries = renderIdsWithData
        ObjectApi.listRenderStatus(renderIdsFormated)
          .then((response: any) => {
            const stillInProgress = response.status.filter(function(v) {
              return v.finished === false && v.failed === false
            })
            if (stillInProgress.length === 0) {
              const isFinished = response.status.every(function (v) {
                return v.finished !== false || v.failed !== false
              })
              if (isFinished) {
                const onlyFinished = response.status.filter(x => x.finished)
                const onlyFailed = response.status.filter(x => x.failed)
                if (onlyFailed.length > 0) {
                  localStorage.removeItem('renderId')
                  this.isOnLoad = false
                  onlyFailed.forEach(item => {
                    const contract = contracts.find(x => x.renderId.includes(item.renderId))?.contract
                    if (contract) {
                      Vue.toasted.show(this.$t('message.savingErrors.contractFailed', { templateName: contract.title, error: item.errorMessage }), { type: 'error' })
                    }
                  })
                } else if (onlyFinished.length > 9) {
                  ObjectApi.getRenderFiles(onlyFinished.map(x => x.renderId))
                    .then((element: any) => {
                      const binary = window.atob(element.contract)
                      const len = binary.length
                      const buffer = new ArrayBuffer(len)
                      const view = new Uint8Array(buffer)
                      for (let i = 0; i < len; i++) {
                        view[i] = binary.charCodeAt(i)
                      }
                      const link = document.createElement('a')
                      link.href = window.URL.createObjectURL(new Blob([view]))
                      link.download = 'Dokumente.zip'
                      link.click()

                      localStorage.removeItem('renderId')
                    })
                    .catch((error: any) => {
                      console.error(error)
                      localStorage.removeItem('renderId')
                      Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
                    })
                    .finally(() => {
                      this.isOnLoad = false
                    })
                } else {
                  renderIdsFormated.forEach(e => {
                    // const e = onlyFinished[0]
                    const renderContractName = (renderIdsWithData.find(w => w.startsWith(e)).substring(renderIdsWithData.find(w => w.startsWith(e)).indexOf('_') + 1)).split('-/-')[0]
                    const renderContractEnforcePdf = (renderIdsWithData.find(w => w.startsWith(e)).substring(renderIdsWithData.find(w => w.startsWith(e)).indexOf('_') + 1)).split('-/-')[1]
                    ObjectApi.getRenderFile(e)
                      .then((element: any) => {
                      // decode base64 string
                        const binary = window.atob(element.contract)
                        const len = binary.length
                        const buffer = new ArrayBuffer(len)
                        const view = new Uint8Array(buffer)
                        for (let i = 0; i < len; i++) {
                          view[i] = binary.charCodeAt(i)
                        }
                        let blob = ''
                        // create the blob object with content-type "application/vnd.openxmlformats-officedocument.wordprocessing"
                        if (renderContractEnforcePdf === 'true') {
                          blob = new Blob([view], { type: 'application/pdf' })
                        } else {
                          blob = new Blob([view], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
                        }
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = renderContractName + (renderContractEnforcePdf === 'true' ? '.pdf' : '.docx')
                        link.click()

                        allEntries = allEntries.filter(b => !b.startsWith(e))
                        if (allEntries.length > 0) {
                          localStorage.setItem('renderId', allEntries.toString())
                        } else {
                          this.isOnLoad = false
                          localStorage.removeItem('renderId')
                        }
                      })
                      .catch((error: any) => {
                        this.isOnLoad = false
                        console.error(error)
                        localStorage.removeItem('renderId')
                        Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
                      })
                  })
                }
              }
            } else {
              this.isOnLoad = false
            }
          })
          .catch((error: any) => {
            this.isOnLoad = false
            console.error(error)
            localStorage.removeItem('renderId')
            Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
          })
      }
    },
    pack(blobs) {
      const zip = new JSZip()
      const fileName = this.contractTemplateSelected.length === 1 && this.contractTemplate.filter(x => x.id === this.contractTemplateSelected[0]).length ? this.contractTemplate.filter(x => x.id === this.contractTemplateSelected[0])[0].title : 'Dokumente'
      const folder = zip.folder(fileName)
      const alreadyAddedNames = []
      blobs.forEach((blob) => {
        if (alreadyAddedNames.includes(blob.name)) {
          let index = 2
          let newName = blob.name
          do {
            newName = blob.name + '_' + index.toString()
            index++
          } while (alreadyAddedNames.includes(newName))

          folder.file(newName, blob)
          alreadyAddedNames.push(newName)
        } else {
          folder.file(blob.name, blob)
          alreadyAddedNames.push(blob.name)
        }
      })
      zip.generateAsync({ type: 'blob' }).then(function(content) {
        // see FileSaver.js
        saveAs(content, fileName + '.zip')
      })
    }
  }
}

export { contract }
