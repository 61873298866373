<template>
  <font-awesome-icon
    icon="globe"
    class="fa" />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

library.add(faGlobe)

export default {
  name: 'CoozzyGlobeIcon'
}
</script>

<style lang="scss" scoped>
  .fa {
    color: $color-text-grey;
  }
</style>
