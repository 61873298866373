const links = {
  computed: {
    marketplaceLink() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/search')
    },
    blogLink() {
      return 'https://blog.coozzy.ch'
    },
    howItWorksLink() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/sofunktionierts')
    },
    pricingLink() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/advertise')
    },
    searchLink() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/search')
    },
    cityZurichLinkToRent() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/cities/zurich/rent')
    },
    cityGenevaLinkToRent() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/cities/genf/rent')
    },
    cityBaselLinkToRent() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/cities/basel/rent')
    },
    cityBernLinkToRent() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/cities/bern/rent')
    },
    cityLausanneLinkToRent() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/cities/lausanne/rent')
    },
    cityZurichLinkTobuy() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/cities/zurich/sale')
    },
    cityGenevaLinkTobuy() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/cities/genf/sale')
    },
    cityBaselLinkTobuy() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/cities/basel/sale')
    },
    cityBernLinkTobuy() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/cities/bern/sale')
    },
    cityLausanneLinkTobuy() {
      return process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/cities/lausanne/sale')
    },
    facebookLink() {
      return 'https://www.facebook.com/coozzy.home'
    },
    instagramLink() {
      return 'https://www.instagram.com/coozzy.home/'
    },
    pinterestLink() {
      return 'https://www.pinterest.com/coozzy/'
    },
    twitterLink() {
      return 'https://twitter.com/coozzy_home?lang=de'
    },
    linkedinLink() {
      return 'https://ch.linkedin.com/company/coozzy-ag'
    },
    youtubeLink() {
      return 'https://www.youtube.com/channel/UC_EIzz6ec4RmimYygNb0cLw/featured'
    }
  }
}

export { links }
