<template>
  <div>
    <div
      v-for="(object, index) in selectedObjectToProcess"
      :key="object.id">
      <enter-termination-details-tenant
        ref="enter-termination-details-tenant-items"
        :object="object"
        :array-details-of-tenants="detailsOfTenants"
        :can-copy-data="index === 0 && selectedObjectToProcess.length > 1"
        class="mb-1"
        :termination-data="selectedObjectToProcess.length <= 1 ? null : index !== 0 && allowChanges ? terminationData : null"
        @tenancy-details-updated="tenancyDetailsUpdated"
        @copy-data-from-first-tenant="copyDataFromFirstTenant"
        @stop-changes="stopChanges" />
      <hr v-if="index !== selectedObjectToProcess.length - 1">
    </div>
  </div>
</template>

<script>
import EnterTerminationDetailsTenant from '@/enterTermination/component/EnterTerminationDetailsTenant'

export default {
  name: 'EnterTerminationDetails',
  components: {
    EnterTerminationDetailsTenant
  },
  props: {
    selectedObjectToProcess: {
      type: Array,
      required: true
    },
    arrayDetailsOfTenants: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      detailsOfTenants: this.arrayDetailsOfTenants.length > 0 ? this.arrayDetailsOfTenants : [],
      terminationData: null,
      allowChanges: false
    }
  },
  methods: {
    tenancyDetailsUpdated(selected) {
      const index = this.detailsOfTenants.filter(x => x).map(function(e) { return e.id || e.objectID }).indexOf(selected.id)
      if (index === -1) {
        this.detailsOfTenants.push(selected)
      } else {
        this.detailsOfTenants[index] = selected
      }
      this.$emit('tenancy-details-updated', this.detailsOfTenants)
    },
    copyDataFromFirstTenant(tenancy) {
      this.allowChanges = true
      this.terminationData = tenancy
    },
    stopChanges() {
      this.allowChanges = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
