<template>
  <div>
    <li class="list-item">
      {{ item }}
    </li>
  </div>
</template>

<script>
import { ElementMixin } from 'vue-slicksort'
export default {
  name: 'CoozzySortableItem',
  mixins: [ElementMixin],
  props: {
    item: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 400;
}
</style>
