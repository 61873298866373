<template>
  <a
    v-if="disabled"
    :class="classList"
    tabindex="-1"
    @click.stop.prevent>
    <img
      :id="name"
      :src="'/menu_icon/arrow_right_grey.svg'"
      class="icon-grey icon"
      alt="">
    <img
      :id="name"
      v-b-tooltip.hover.right="name"
      :src="'/menu_icon/arrow_right_white.svg'"
      class="icon-white icon"
      alt="">
    <span :class="child ? 'ml-4' : ''">{{ name }}</span>
    <b-tooltip
      :target="name"
      custom-class="tooltip-nav"
      placement="right">{{ name }}
    </b-tooltip>
    <b-badge
      v-if="badge && badge.text"
      :variant="badge.variant">{{ badge.text }}
    </b-badge>
  </a>
  <a
    v-else-if="isExternalLink"
    :href="url"
    target="_blank"
    :class="classList">
    <img
      :id="name"
      :src="'/menu_icon/arrow_right_grey.svg'"
      class="icon-grey icon"
      alt="">
    <img
      :id="name"
      v-b-tooltip.hover.right="name"
      :src="'/menu_icon/arrow_right_white.svg'"
      class="icon-white icon"
      alt="">
    <span>{{ name }}</span>
    <b-tooltip
      :target="name"
      custom-class="tooltip-nav"
      placement="right">{{ name }}
    </b-tooltip>
    <b-badge
      v-if="badge && badge.text"
      :variant="badge.variant">{{ badge.text }}
    </b-badge>
  </a>
  <router-link
    v-else
    :to="routeObject"
    :class="classList"
    :exact="checkRouteExact()"
    @click.native="beforeRouteChange">
    <img
      :id="name"
      :src="'/menu_icon/arrow_right_grey.svg'"
      class="icon-grey icon"
      alt="">
    <img
      :id="name"
      v-b-tooltip.hover.right="name"
      :src="'/menu_icon/arrow_right_white.svg'"
      class="icon-white icon"
      alt="">
    <span class="link-name">{{ name }}</span>
    <b-tooltip
      :target="name === $t('message.navigation.directory') ? name+randomId : name"
      :custom-class="getTooltipClass()"
      placement="right">
      {{ name }}
    </b-tooltip>
    <coozzy-badge
      v-if="badge !== ''"
      class="p-1"
      :design="badge === 0 || badge === '0' || badge === '-' ? 'white' : 'white-red'">
      {{ badge }}
    </coozzy-badge>
    <span
      v-if="badge !== '' && badge > 0"
      class="badge-dot" />
    <!--    <b-badge-->
    <!--      v-if="badge && badge.text"-->
    <!--      :variant="badge.variant">-->
    <!--      {{ badge.text }}-->
    <!--    </b-badge>-->
  </router-link>
</template>

<script>
import CoozzyBadge from '@/framework/components/badge/CoozzyBadge.vue'

export default {
  name: 'CoozzySidebarNavLink',
  components: { CoozzyBadge },
  props: {
    child: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    routeName: {
      type: String,
      default: ''
    },
    routeParams: {
      type: Object,
      default: () => {
      }
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    badge: {
      type: [String, Number],
      default: ''
    },
    variant: {
      type: String,
      default: ''
    },
    classes: {
      type: [String, Array, Object],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classList() {
      return [
        'nav-link',
        this.linkVariant,
        ...this.itemClasses
      ]
    },
    routeObject() {
      return { name: this.routeName, params: this.routeParams }
    },
    linkVariant() {
      return this.variant ? `nav-link-${this.variant}` : ''
    },
    itemClasses() {
      return this.getClassArray(this.classes)
    },
    isExternalLink() {
      return !this.routeName
    },
    randomId() {
      return Math.random().toString(36).substr(2, 9)
    }
  },
  methods: {
    getClassArray(classes) {
      return !classes ? [] : typeof classes === 'string' || classes instanceof String ? classes.split(' ') : Array.isArray(classes) ? classes : Object.keys(classes).filter(i => classes[i])
    },
    beforeRouteChange() {
      this.$parent.$parent.$parent.$parent.$parent.$parent?.$refs['top-header']?.$refs['mobile-toggler'].toggle()
    },
    getTooltipClass() {
      switch (this.name) {
        case this.$route.path.startsWith('/owner') && this.$t('message.navigation.reporting.management'):
          return 'righter-link-tooltip4'
        case !this.$route.path.startsWith('/owner') && this.$t('message.navigation.reporting.management'):
          return 'righter-link-tooltip'
        case this.$t('message.navigation.management.properties'):
          return 'righter-link-tooltip2'
        case this.$t('message.navigation.management.buildings'):
          return 'righter-link-tooltip2'
        case this.$t('message.navigation.management.rentalObjects'):
          return 'righter-link-tooltip3'
        case this.$t('message.navigation.management.allCondominiumObjects'):
          return 'righter-link-tooltip3'
        case this.$t('message.navigation.management.tenants'):
          return 'righter-link-tooltip3'
        case this.$t('message.navigation.management.allCondominiumOwners'):
          return 'righter-link-tooltip3'
        default:
          return 'righter-link-tooltip'
      }
    },
    checkRouteExact() {
      // we have a problem on Real estates because it has the same path with All buildings
      // that's why we need to check if the route is Real estates
      // if it is, we need to set attribute exact to true
      return this.$route.name.includes('BuildingsMapView')
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-link {
    color: rgba(255, 255, 255, .6) !important;
    font-size: 14px;
    background: transparent;
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    .nav-icon {
      color: inherit !important;
    }

    .icon {
      width: 20px;
      margin-right: 5px;

      &.icon-grey {
        display: inline;
      }

      &.icon-white {
        display: none;
      }
    }

    &.router-link-exact-active, &.router-link-active {
      color: rgb(255, 255, 255) !important;
      font-weight: bold;
      padding-top: 4px;
      padding-bottom: 4px;

      .icon-grey {
        display: none !important;
      }

      .icon-white {
        display: inline !important;
      }
    }
  }

  @media (min-width: 992px) {
    .sidebar-fixed {
      .link-name {
        display: none !important;
      }
      .badge-dot {
        display: inline-block !important;
      }
    }
    .sidebar-fixed.sidebar-lg-show {
      .link-name {
        display: inline !important;
      }
      .badge-dot {
        display: none !important;
      }
    }
  }

  @media (max-width: 991px) {
    .sidebar-fixed:not(.sidebar-show) {
      .link-name {
        display: none !important;
      }

      .badge-dot {
        display: inline !important;
      }
    }
    .sidebar-fixed.sidebar-lg-show {
      .link-name {
        display: inline;
      }

      .badge-dot {
        display: none !important;
      }
    }
  }
  .badge-dot {
    display: none;
    position: absolute;
    top: 8px;
    left: 33px;
    padding: 4px;
    border-radius: 50%;
    background: red;
    color: white;
  }
</style>
