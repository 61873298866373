<template>
  <svg
    :id="name"
    xmlns="http://www.w3.org/2000/svg"
    height="16px"
    viewBox="0 0 24 24"
    width="16px"><g
      id="Layer_81"
      data-name="Layer 81"><path d="m10.71 13.29a1 1 0 0 1 0 1.42l-5.3 5.29h2.59a1 1 0 0 1 0 2h-5a1 1 0 0 1 -1-1v-5a1 1 0 0 1 2 0v2.59l5.29-5.3a1 1 0 0 1 1.42 0zm10.29-11.29h-5a1 1 0 0 0 0 2h2.59l-5.3 5.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l5.29-5.3v2.59a1 1 0 0 0 2 0v-5a1 1 0 0 0 -1-1z" /></g></svg>
</template>

<script>

export default {
  name: 'CoozzyFullScreenIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
  color: $color-text-grey;
}
</style>
