<template>
  <section>
    <div
      v-b-toggle="'collapse-characteristics'"
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ title }}
      </h5>
    </div>
    <b-collapse
      :id="'collapse-characteristics'"
      :visible="expandByDefault || openedCollapses.includes('collapse-characteristics')"
      class="row">
      <div class="col">
        <!-- Flächen -->
        <div class="row details-row-bg-1 py-2 mt-3">
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="fiberGlass"
              :initial="fiberGlass"
              name="fiberGlass">
              {{ $t('message.onBoarding.buildings.objects.characteristics.fiberGlass') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="fiberGlass">{{ $t('message.onBoarding.buildings.objects.characteristics.fiberGlass')
            }}</label>
            <span
              class="show-data">{{ fiberGlass === null || fiberGlass === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="satellite"
              :initial="satellite"
              name="satellite">
              {{ $t('message.onBoarding.buildings.objects.characteristics.satellite') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="satellite">{{ $t('message.onBoarding.buildings.objects.characteristics.satellite')
            }}</label>
            <span
              class="show-data">{{ satellite === null || satellite === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="park"
              :initial="park"
              name="park">
              {{ $t('message.onBoarding.buildings.objects.characteristics.park') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="park">{{ $t('message.onBoarding.buildings.objects.characteristics.park')
            }}</label>
            <span
              class="show-data">{{ park === null || park === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="elevator"
              :initial="elevator"
              name="elevator">
              {{ $t('message.onBoarding.buildings.objects.characteristics.elevator') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="elevator">{{ $t('message.onBoarding.buildings.objects.characteristics.elevator')
            }}</label>
            <span
              class="show-data">{{ elevator === null || elevator === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
        </div>
        <div class="row details-row-bg-2 py-3">
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="broadbandInternet"
              :initial="broadbandInternet"
              name="broadbandInternet">
              {{ $t('message.onBoarding.buildings.objects.characteristics.broadbandInternet') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="elevator">{{ $t('message.onBoarding.buildings.objects.characteristics.broadbandInternet')
            }}</label>
            <span
              class="show-data">{{ broadbandInternet === null || broadbandInternet === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="intercom"
              :initial="intercom"
              name="intercom">
              {{ $t('message.onBoarding.buildings.objects.characteristics.intercom') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="intercom">{{ $t('message.onBoarding.buildings.objects.characteristics.intercom')
            }}</label>
            <span
              class="show-data">{{ intercom === null || intercom === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="communityGarden"
              :initial="communityGarden"
              name="communityGarden">
              {{ $t('message.onBoarding.buildings.objects.characteristics.communityGarden') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="communityGarden">{{ $t('message.onBoarding.buildings.objects.characteristics.communityGarden')
            }}</label>
            <span
              class="show-data">{{ communityGarden === null || communityGarden === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="childFriendly"
              :initial="childFriendly"
              name="childFriendly">
              {{ $t('message.onBoarding.buildings.objects.characteristics.childFriendly') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="childFriendly">{{ $t('message.onBoarding.buildings.objects.characteristics.childFriendly')
            }}</label>
            <span
              class="show-data">{{ childFriendly === null || childFriendly === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
        </div>
        <div class="row details-row-bg-1 py-2">
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="isdn"
              :initial="isdn"
              name="isdn">
              {{ $t('message.onBoarding.buildings.objects.characteristics.isdn') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="isdn">{{ $t('message.onBoarding.buildings.objects.characteristics.isdn')
            }}</label>
            <span
              class="show-data">{{ isdn === null || isdn === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="chargingStation"
              :initial="chargingStation"
              name="chargingStation">
              {{ $t('message.onBoarding.buildings.objects.characteristics.chargingStation') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="chargingStation">{{ $t('message.onBoarding.buildings.objects.characteristics.chargingStation')
            }}</label>
            <span
              class="show-data">{{ chargingStation === null || chargingStation === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="playground"
              :initial="playground"
              name="playground">
              {{ $t('message.onBoarding.buildings.objects.characteristics.playground') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="playground">{{ $t('message.onBoarding.buildings.objects.characteristics.playground')
            }}</label>
            <span
              class="show-data">{{ playground === null || playground === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="conciergeService"
              :initial="conciergeService"
              name="conciergeService">
              {{ $t('message.onBoarding.buildings.objects.characteristics.conciergeService') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="conciergeService">{{ $t('message.onBoarding.buildings.objects.characteristics.conciergeService')
            }}</label>
            <span
              class="show-data">{{ conciergeService === null || conciergeService === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
        </div>
        <div class="row details-row-bg-2 py-3">
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="cableTv"
              :initial="cableTv"
              name="cableTv">
              {{ $t('message.onBoarding.buildings.objects.characteristics.cableTv') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="cableTv">{{ $t('message.onBoarding.buildings.objects.characteristics.cableTv')
            }}</label>
            <span
              class="show-data">{{ cableTv === null || cableTv === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
        </div>
      </div>
    </b-collapse>
  </section>
</template>

<script>
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'

export default {
  name: 'Characteristics',
  components: { CoozzyFormCheckbox },
  props: {
    building: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    expandByDefault: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    openedCollapses() {
      return this.$store.getters['onboarding/getOpenedCollapses']
    },
    broadbandInternet: {
      get() {
        return this.building.characteristics.broadbandInternet
      },
      set(value) {
        this.$emit('value-updated', { field: 'broadbandInternet', value: value })
      }
    },
    cableTv: {
      get() {
        return this.building.characteristics.cableTv
      },
      set(value) {
        this.$emit('value-updated', { field: 'cableTv', value: value })
      }
    },
    chargingStation: {
      get() {
        return this.building.characteristics.chargingStation
      },
      set(value) {
        this.$emit('value-updated', { field: 'chargingStation', value: value })
      }
    },
    childFriendly: {
      get() {
        return this.building.characteristics.childFriendly
      },
      set(value) {
        this.$emit('value-updated', { field: 'childFriendly', value: value })
      }
    },
    communityGarden: {
      get() {
        return this.building.characteristics.communityGarden
      },
      set(value) {
        this.$emit('value-updated', { field: 'communityGarden', value: value })
      }
    },
    conciergeService: {
      get() {
        return this.building.characteristics.conciergeService
      },
      set(value) {
        this.$emit('value-updated', { field: 'conciergeService', value: value })
      }
    },
    elevator: {
      get() {
        return this.building.characteristics.elevator
      },
      set(value) {
        this.$emit('value-updated', { field: 'elevator', value: value })
      }
    },
    fiberGlass: {
      get() {
        return this.building.characteristics.fiberGlass
      },
      set(value) {
        this.$emit('value-updated', { field: 'fiberGlass', value: value })
      }
    },
    intercom: {
      get() {
        return this.building.characteristics.intercom
      },
      set(value) {
        this.$emit('value-updated', { field: 'intercom', value: value })
      }
    },
    isdn: {
      get() {
        return this.building.characteristics.isdn
      },
      set(value) {
        this.$emit('value-updated', { field: 'isdn', value: value })
      }
    },
    park: {
      get() {
        return this.building.characteristics.park
      },
      set(value) {
        this.$emit('value-updated', { field: 'park', value: value })
      }
    },
    playground: {
      get() {
        return this.building.characteristics.playground
      },
      set(value) {
        this.$emit('value-updated', { field: 'playground', value: value })
      }
    },
    satellite: {
      get() {
        return this.building.characteristics.satellite
      },
      set(value) {
        this.$emit('value-updated', { field: 'satellite', value: value })
      }
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
