<template>
  <div
    id="bodyMessage"
    class="row"
    :class="printAllow ? 'section-to-print': ''">
    <div class="col">
      <div class="row">
        <!-- Date on responsive -->
        <div class="d-md-none col-12 mb-1 row">
          <a
            v-if="ticket"
            class="col-12 mr-0 linkTicket"
            @click="routeToDetail(ticket)"
            @click.middle="openMiddleDetail(ticket)">
            <img
              height="20px"
              class="mr-1 mb-1"
              :src="`/img/ticket/${ticket.type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
              alt="type">{{ $t('message.ticketOverview.ticket.type.' + ticket.type) }} {{ ticket.key }} {{ ticket.title }}
          </a>
          <label class="col-2">{{ $t('message.marketingMessages.mail.date') }}:</label>
          <span class="col-10">{{ getWeekday(email.timestamp) }}, {{ email.timestamp | timestampToDateTime }}</span>
        </div>
        <!-- FROM -->
        <div
          v-if="email.from"
          class="col-12 mb-1 row">
          <label class="col-2">{{ $t('message.marketingMessages.mail.from') }}:</label>
          <span class="col-10 col-md-6">
            <span v-if="getMailConnectedWithContacts(email).from.contactId">
              <router-link
                :to="getContactDetailRoute(getMailConnectedWithContacts(email).from.contactId)">
                {{ getMailConnectedWithContacts(email).from.name }} &lt;{{ email.from.email }}&gt;
              </router-link>
            </span>
            <template v-else>
              {{ email.from.name }}
              <template v-if="email.from.name">
                &lt;{{ email.from.email }}&gt;
              </template>
              <template v-else>
                {{ email.from.email }}
              </template>
            </template>
          </span>
          <!-- Date on desktop -->
          <div class="d-none d-md-inline-block col-md-4 mb-1 block-ticket-date">
            <div class="text-right">
              <div class="col-12 block-date">
                <span class="float-right">
                  {{ getWeekday(email.timestamp) }}, {{ email.timestamp | timestampToDateTime }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- TO -->
        <div class="col-12 mb-1 row">
          <label
            v-if="email.to && email.to.length && email.to[0].email && !email.to[0].email.startsWith('req_')"
            class="col-2">
            {{ $t('message.marketingMessages.mail.to') }}:
          </label>
          <span class="col-10">
            <span
              v-for="(to, toIndex) in getMailConnectedWithContacts(email).to"
              :key="toIndex">
              <router-link
                v-if="to.contactId"
                :key="to.contactId"
                :to="getContactDetailRoute(to.contactId)">
                {{ to.name }}
                <template v-if="to.name">
                  &lt;{{ to.email }}&gt;<template v-if="toIndex < email.to.length -1">,</template>
                </template>
                <template v-else>
                  {{ to.email }}<template v-if="toIndex < email.to.length -1">,</template>
                </template>
              </router-link>
              <span v-else>
                <template v-if="to.email && to.email.startsWith('req_')">
                  {{ $t('message.marketingMessages.requestFrom') }} {{ resolveMarketplace(marketplace) }}
                </template>
                <template v-else>
                  {{ to.name }}
                  <template v-if="to.name">
                    &lt;{{ to.email }}&gt;<template v-if="toIndex < email.to.length -1">,</template>
                  </template>
                  <template v-else>
                    {{ to.email }}<template v-if="toIndex < email.to.length -1">,</template>
                  </template>
                </template>
              </span>
            </span>
          </span>
        </div>
        <!-- CC -->
        <div
          v-if="email.cc.length > 0"
          class="col-12 mb-1 row">
          <label class="col-2">
            CC
          </label>
          <span class="col-10">
            <span
              v-for="(cc, ccIndex) in getMailConnectedWithContacts(email).cc"
              :key="ccIndex">
              <router-link
                v-if="cc.contactId"
                :key="cc.contactId"
                :to="getContactDetailRoute(cc.contactId)">
                {{ cc.name }}
                <template v-if="cc.name">
                  &lt;{{ cc.email }}&gt;<template v-if="ccIndex < email.cc.length -1">,</template>
                </template>
                <template v-else>
                  {{ cc.email }}<template v-if="ccIndex < email.cc.length -1">,</template>
                </template>
              </router-link>
              <span v-else>
                {{ cc.name }}
                <template v-if="cc.name">
                  &lt;{{ cc.email }}&gt;<template v-if="ccIndex < email.cc.length -1">,</template>
                </template>
                <template v-else>
                  {{ cc.email }}<template v-if="ccIndex < email.cc.length -1">,</template>
                </template>
              </span>
            </span>
          </span>
        </div>
        <!-- BCC -->
        <div
          v-if="email.bcc.length > 0"
          class="col-12 mb-1 row">
          <label
            class="col-2">
            BCC
          </label>
          <span class="col-10">
            <span
              v-for="(bcc, bccIndex) in getMailConnectedWithContacts(email).bcc"
              :key="bccIndex">
              <router-link
                v-if="bcc.contactId"
                :key="bcc.contactId"
                :to="getContactDetailRoute(bcc.contactId)">
                {{ bcc.name }}
                <template v-if="bcc.name">
                  &lt;{{ bcc.email }}&gt;<template v-if="bccIndex < email.bcc.length -1">,</template>
                </template>
                <template v-else>
                  {{ bcc.email }}<template v-if="bccIndex < email.bcc.length -1">,</template>
                </template>
              </router-link>
              <span v-else>
                {{ bcc.name }}
                <template v-if="bcc.name">
                  &lt;{{ bcc.email }}&gt;<template v-if="bccIndex < email.bcc.length -1">,</template>
                </template>
                <template v-else>
                  {{ bcc.email }}<template v-if="bccIndex < email.bcc.length -1">,</template>
                </template>
              </span>
            </span>
          </span>
        </div>
        <!-- Subject -->
        <div class="col-12 row">
          <label class="col-2">{{ $t('message.marketingMessages.mail.subject') }}:</label>
          <span class="col-10"> {{ email.subject | displayOptionalValue }} </span>
          <hr>
        </div>
      </div>
      <div class="row">
        <!-- Attachments -->
        <div
          v-if="mediasList.length > 0"
          class="col-12 mb-3">
          <a
            class="link"
            @click="downloadAllMedia">
            {{ $t('message.marketingMessages.mail.downloadAll') }}
          </a>
          <div
            class="row mb-2 mx-0">
            <template
              v-for="media in mediasList">
              <div
                v-if="['image/jpeg', 'image/png', 'image/gif'].indexOf(media.media ? media.media.mimeType : media.mimeType) !== -1"
                :key="media.media ? media.media.id : media.id"
                class="media-block col-auto px-0">
                <a
                  :href="media.media ? media.media.url : media.url"
                  target="_blank"
                  :download="media.media ? media.media.filename : media.filename">
                  <img
                    :src="getUrlOfThumbnailS(media.media ? media.media : media)"
                    class="img-thumbnail"
                    alt="Attachment"
                    @dragstart="handleDragStart(media.media ? media.media.id : media.id)">
                </a>
              </div>
            </template>
          </div>
          <div
            class="row mb-2 mx-0">
            <template
              v-for="media in mediasList">
              <div
                v-if="!(['image/jpeg', 'image/png', 'image/gif'].indexOf(media.media ? media.media.mimeType : media.mimeType) !== -1)"
                :key="media.media ? media.media.id : media.id"
                class="col-3 px-0">
                <div
                  class="border mr-1">
                  <a
                    class="cardDocs"
                    target="_blank"
                    :draggable="true"
                    @dragstart="handleDragStart(media.media ? media.media.id : media.id)">
                    <div
                      class="row m-0 d-flex card-file"
                      style="display: inline;"
                      @click="downloadFiles(media, $event)">
                      <div
                        class="col-2 icon d-flex align-items-center mr-auto"
                        style="display: inherit; margin-right:15px;">
                        <coozzy-file-download-icon style="max-width:15px;" />
                      </div>
                      <div
                        class="col-10 pl-0 pr-1"
                        style="display: inline;">
                        <p
                          class="m-0 docName d-inherit"
                          style="display: inline;">{{ media.media ? media.media.filename : media.filename || 'unknown_filename' }}</p>
                        <p
                          class="m-0 docSize d-inherit"
                          style="display: none;">{{ Math.max(Math.floor((media.size) / 1024), 1) + ' KB' }}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- Message body -->
        <div class="col-12 adjust-img-size">
          <div v-if="!email.missingHtml && email.bodyHtml && ((email.to.length && !email.to[0].email.startsWith('req_')) || !email.bodyText)">
            <div
              ref="bodyHtml"
              v-dompurify-html="encapsulateStyles(email.bodyHtml)"
              class="printMessageBodyEmailContent" />
          </div>
          <div
            v-else
            style="white-space: pre-wrap;">
            <!-- eslint-disable vue/no-v-html -->
            <p
              class="mt-3"
              v-html="bodyTextWithLinks(email.bodyText)" />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </div>
    </div>
    <event-creation-modal
      v-if="event"
      :key="event ? (event.id + '_' + dynamicId) : ''"
      ref="modal-creation"
      :can-be-edited="false"
      source="email"
      :event-detail="event"
      :user-contact="userContact"
      :available-employees="availableEmployees"
      @hide-modal="hideModalCreateEvent()"
      @attendees-changed="attendeesChanged" />
  </div>
</template>

<script>

import linkifyStr from 'linkify-string'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import CalendarApi from '@/misc/apis/CalendarApi'
import { contact } from '@/mixins/contact'
import { mail } from '@/mixins/mail'
import { media } from '@/mixins/media'
import { request } from '@/mixins/request'
import { mapActions, mapGetters, mapState } from 'vuex'
import EventCreationModal from '@/calendar/components/EventCreationModal.vue'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon.vue'
import ContactApi from '@/misc/apis/ContactApi'
import { dateUtils } from '@/mixins/dateUtils'

export default {
  name: 'PrintMessage',
  components: { CoozzyFileDownloadIcon, EventCreationModal },
  mixins: [mail, contact, media, request, dateUtils],
  props: {
    printAllow: {
      type: Boolean,
      default: false
    },
    email: {
      type: Object,
      default() {
        return null
      }
    },
    ticket: {
      type: Object,
      default() {
        return null
      }
    },
    mediasList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      event: null,
      dynamicId: '',
      userContact: null
    }
  },
  computed: {
    ...mapState('request', ['marketplace']),
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees().filter(e => !e.deleted && !e.blocked)
      }
      return []
    },
    availableEmployees() {
      const array = []
      if (this.employeeList.length !== 0) {
        const empList = this.employeeList.filter(x => (x.blocked === false && x.deleted === false))
        empList.forEach(element => {
          const cont = {
            firstName: element.profile.firstName,
            lastName: element.profile.lastName,
            email: element.email,
            id: element.id
          }
          array.push(cont)
        })
      }
      return array
    }
  },
  watch: {
    mediasList: {
      handler() {
        this.createInlineImages()
      },
      deep: true
    },
    email: {
      handler(newVal) {
        console.log('325', newVal)
      },
      deep: true
    }
  },
  mounted() {
    this.loadEmployees()
    this.loadUserContact()
    this.createInlineImages()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    handleDragStart(itemId) {
      // Set the dragged item's ID in the data transfer object
      event.dataTransfer.setData('itemId', itemId)
    },
    loadUserContact() {
      ContactApi.resolveByMail(this.accountId, [this.userEmail])
        .then(response => {
          if (response.persons.length > 0) {
            this.userContact = response.persons[0]
          } else if (response.companies.length > 0) {
            this.userContact = response.companies[0]
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    encapsulateStyles(htmlContent) {
      // Create a DOM parser
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlContent, 'text/html')

      // Find all style elements
      const styleElements = doc.getElementsByTagName('style')

      // Iterate over each style element
      for (let i = 0; i < styleElements.length; i++) {
        const styleElement = styleElements[i]

        // Encapsulate the CSS rules within the unique class
        styleElement.textContent = `.printMessageBodyEmailContent { ${styleElement.textContent} }`
      }
      this.$nextTick(() => {
        this.addEventListenerToImages()
      })
      // Return the modified HTML content
      return doc.documentElement.outerHTML
    },
    addEventListenerToImages() {
      const doc = this.$refs.bodyHtml
      const images = doc.querySelectorAll('img')

      // Loop through each image
      images.forEach(img => {
        const src = img.src

        // Find the portion between the last '/' and '?'
        const match = src.match(/\/([^/]+)\?/)

        if (match && match[1]) {
          const extractedString = match[1]
          const current = this
          // Add dragstart event listener to the image
          img.addEventListener('dragstart', function() {
            current.handleDragStart(extractedString) // Pass the extracted string to the handler
          })
        }
      })
    },
    hideModalCreateEvent() {
      this.event = null
    },
    getWeekday(date) {
      const day = new Date(date * 1000)
      let locale = 'en-EN'
      switch (this.$store.getters['internationalization/getCurrentLanguage']) {
        case 'de':
          locale = 'de-DE'
          break
        case 'it':
          locale = 'it-IT'
          break
      }
      return day.toLocaleDateString(locale, { weekday: 'long' })
    },
    bodyTextWithLinks(bodyText) {
      return linkifyStr(bodyText, {
        target: '_blank'
      })
    },
    downloadAllMedia() {
      if (this.mediasList.length <= 10) {
        this.mediasList.forEach(media => {
          const url = media.media ? media.media.url : media.url
          const fileName = media.media ? media.media.filename : media.filename
          saveAs(url, fileName)
        })
      } else {
        const zip = new JSZip()
        const remoteZips = this.mediasList.map(async (media) => {
          const url = media.media ? media.media.url : media.url
          const fileName = media.media ? media.media.filename : media.filename

          const response = await fetch(url)
          const data = await response.blob()

          zip.file(fileName, data)
          return data
        })
        Promise.all(remoteZips).then(() => {
          zip.generateAsync({ type: 'blob' }).then(function(content) {
            saveAs(content, 'DownloadAll.zip')
          })
        })
      }
    },
    async downloadFiles(media, e) {
      const mimeType = media.media ? media.media.mimeType : media.mimeType
      if (mimeType !== 'text/calendar') {
        e.preventDefault()
        const url = media.media ? media.media.url : media.url
        const fileName = media.media ? media.media.filename : media.filename
        saveAs(url, fileName)
      } else if (mimeType === 'text/calendar') {
        if (this.event === null) {
          try {
            const response = await CalendarApi.readEventFromIcal(media.id)
            this.dynamicId = Math.random().toString(36).substr(2, 9)
            const updatedEvent = await this.getUpdatedVersionOfEvent(response.event)
            if (updatedEvent) {
              this.event = updatedEvent
            } else {
              this.event = response.event
            }
            this.$nextTick(() => {
              this.$refs['modal-creation']?.show()
            })
          } catch (error) {
            console.error(error)
          }
        } else {
          this.$refs['modal-creation']?.show()
        }
      }
    },
    getUpdatedVersionOfEvent(event) {
      let startDate = new Date(this.$options.filters.objectToDateInput(event.startDate))
      startDate = startDate.setDate(startDate.getDate() - 1)
      let endDate = new Date(this.$options.filters.objectToDateInput(event.endDate))
      endDate = endDate.setDate(endDate.getDate() + 1)
      return CalendarApi.getEvents(this.accountId, [], [this.userId], this.toObject(startDate), this.toObject(endDate))
        .then(response => {
          const updatedEvent = response.events.find(e => e.id === event.id)
          if (updatedEvent) {
            return updatedEvent
          }
          return null
        })
    },
    async attendeesChanged() {
      const updatedEvent = await this.getUpdatedVersionOfEvent(this.event)
      if (updatedEvent) {
        this.event = updatedEvent
      }
    },
    createInlineImages() {
      // Parse the HTML string
      const mediasList = this.mediasList
      this.email.bodyHtml = this.email.bodyHtml.replace(/<img([^>]*?)data-filename="([^"]*?)"(.*?)>/gi, function(match, pre, dataFilename, post) {
        const matchingImage = mediasList.find(image => image.filename === dataFilename)
        if (matchingImage) {
          // Check if there is already an src attribute
          if (/src="[^"]*"/.test(match)) {
            // Replace existing src attribute
            return match.replace(/src="[^"]*"/, `src="${matchingImage.url}"`)
          } else {
            // Add src attribute
            return `<img${pre}data-filename="${dataFilename}" src="${matchingImage.url}"${post}>`
          }
        } else {
          // No matching image found, return the tag unchanged
          return match
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(th) {
    vertical-align: initial;
  }
  .linkTicket {
    cursor: pointer;
    color: #007bff !important;
  }
  .block-date {
    position: absolute;
    right: 0;
    margin-top: 8px;
  }
  .block-ticket-date {
    position: absolute;
    right: 0;
    top: -8px;
  }
  :deep(.adjust-img-size) {
    isolation: isolate;
  }
  :deep(.adjust-img-size img) {
    max-width: 100%;
  }
</style>
