<template>
  <section>
    <div>
      <coozzy-page-title
        :title="$t('message.navigation.networkPartner')"
        :count="objectCount"
        :count-text="$t('message.navigation.networkPartner')"
        :clear-filter="checkfilter"
        @clear-filter="clickOnClear" />
      <!-- Filter -->
      <div class="row mb-2">
        <div class="col-12 col-md mb-2 mb-md-0">
          <coozzy-form-input
            v-model="searchCompany"
            :placeholder="$t('message.generic.companyName')"
            @keyup="clickSearch()" />
        </div>
        <div class="col-12 col-md-3 mb-2 mb-md-0">
          <coozzy-form-input
            v-model="searchContactPerson"
            :placeholder="$t('message.networkPartner.contactPersonName')"
            @keyup="clickSearch()" />
        </div>
        <div class="col-12 col-md-3 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchCanton"
            :options="availableStates"
            :multiple="true"
            :placeholder="$t('message.networkPartner.canton')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelCategory"
            :preselect-first="false"
            :taggable="false" />
        </div>
        <div class="col-12 col-md-3 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchType"
            :options="availableType"
            :placeholder="$t('message.networkPartner.service')"
            :multiple="false"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelType"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-3 offset-md-9">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-table
            ref="objectsTable"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            :busy="isBusy"
            :fields="fields"
            :items="filtredNetworkPartnerList"
            @row-clicked="onRowClicked"
            @row-middle-clicked="onMiddlelicked">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <!-- Headings -->
            <template #head(name)>
              {{ $t('message.generic.name') }}
            </template>
            <template #head(address)>
              {{ $t('message.generic.address') }}
            </template>
            <template #head(contactPerson)>
              {{ $t('message.generic.contactPerson') }}
            </template>
            <template #head(contactPersonPhone)>
              {{ $t('message.networkPartner.contactPersonPhone') }}
            </template>
            <template #head(contactPersonEmail)>
              {{ $t('message.networkPartner.contactPersonEmail') }}
            </template>
            <template #head(managementStates)>
              {{ $t('message.networkPartner.managementCanton') }}
            </template>
            <template #head(marketingStates)>
              {{ $t('message.networkPartner.marketingCanton') }}
            </template>
            <template #head(assetStates)>
              {{ $t('message.networkPartner.assetCanton') }}
            </template>
            <template #head(accountingStates)>
              {{ $t('message.networkPartner.accountingCanton') }}
            </template>

            <!-- Data cells -->
            <template
              #cell(active)="data">
              <coozzy-success-icon
                v-if="(isActivePartner(data.item.id) && currentnetworkList.includes(data.item.id)) || data.item.active"
                class="green-icon" />
            </template>
            <!-- name column -->
            <template
              #cell(name)="data">
              <template v-if="data.item.company !== null">
                {{ data.item.company.name }}
              </template>
              <template v-else-if="data.item.individual !== null">
                {{ data.item.individual.firstName.substr(0, 1) }}. {{ data.item.individual.lastName }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template
              #cell(address)="data">
              <template v-if="data.item.type === 'COMPANY'">
                <address-text :address="data.item.company.address" />
              </template>
              <template v-else-if="data.item.type === 'INDIVIDUAL'">
                <address-text :address="data.item.individual.address" />
              </template>
            </template>
            <!-- contactPerson name  -->
            <template
              #cell(contactPerson)="data">
              <template v-if="data.item.contactPerson !== null ">
                {{ data.item.contactPerson.name }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <!-- contactPerson Phone -->
            <template
              #cell(contactPersonPhone)="data">
              <template v-if="data.item.company !== null && data.item.company.phoneNumber !== ''">
                {{ data.item.company.phoneNumber }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <!-- contactPerson Email -->
            <template
              #cell(contactPersonEmail)="data">
              <template v-if="data.item.company !== null && data.item.company.email !== ''">
                {{ data.item.company.email }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <!-- marketingStates -->
            <template #cell(marketingStates)="data">
              <div>
                {{ data.item.marketingStates.map(state => state.substring(3)).sort().join(', ') | displayOptionalValue
                }}
              </div>
            </template>
            <!-- managementStates -->
            <template #cell(managementStates)="data">
              <div>
                {{ data.item.managementStates.map(state => state.substring(3)).sort().join(', ') | displayOptionalValue
                }}
              </div>
            </template>
            <!-- assetStates -->
            <template #cell(assetStates)="data">
              <div>
                {{ data.item.assetStates.map(state => state.substring(3)).sort().join(', ') | displayOptionalValue
                }}
              </div>
            </template>
            <!-- financeStates -->
            <template #cell(accountingStates)="data">
              <div>
                {{ data.item.accountingStates.map(state => state.substring(3)).sort().join(', ') | displayOptionalValue
                }}
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            align="center"
            class="mb-3"
            :total-rows="objectCount"
            :per-page="nbrPerPage" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NetworkPartnerAPI from '../../misc/apis/NetworkPartnerApi'
import ShareApi from '../../misc/apis/ShareApi'
import AddressText from '../../framework/components/misc/AddressText'
import CoozzySuccessIcon from '../../framework/components/icons/CoozzySuccessIcon'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyMultiselect from '../../framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import Vue from 'vue'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'

export default {
  name: 'OwnerNetworkPartnerView',
  components: {
    CoozzyPageTitle,
    CoozzySpinner,
    CoozzyFormSelect,
    CoozzyMultiselect,
    CoozzyFormInput,
    CoozzySuccessIcon,
    AddressText
  },
  props: {
    defaultCanton: {
      type: String,
      default: ''
    },
    defaultType: {
      type: String,
      default: ''
    },
    defaultCompany: {
      type: String,
      default: ''
    },
    defaultContactPerson: {
      type: String,
      default: ''
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.networkPartner')
    }
  },
  data() {
    return {
      searchCanton: [],
      searchCompany: '',
      searchContactPerson: '',
      searchType: [],
      currentnetworkList: [],
      availableMarketingStates: ['ZH', 'BE', 'LU', 'UR', 'SZ', 'OW', 'NW', 'GL', 'ZG', 'FR', 'SO', 'BS', 'BL', 'SH', 'AR', 'AI', 'SG', 'GR', 'AG', 'TG', 'TI', 'VD', 'VS', 'NE', 'GE', 'JU'],
      contactList: [],
      isBusy: true,
      networkPartnerList: [],
      nbrPerPage: 10,
      currentPage: 1,
      objectCount: 0,
      filterList: [],
      allActivePartners: [],
      fields: [
        {
          key: 'active',
          label: '',
          thClass: 'th-active',
          tdClass: 'align-middle border-right-0'
        },
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'address',
          label: this.$t('message.generic.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'contactPerson',
          label: this.$t('message.generic.contactPerson'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'contactPersonPhone',
          label: this.$t('message.networkPartner.contactPersonPhone'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'contactPersonEmail',
          label: this.$t('message.networkPartner.contactPersonEmail'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'assetStates',
          label: this.$t('message.networkPartner.assetCanton'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'accountingStates',
          label: this.$t('message.networkPartner.accountingCanton'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'managementStates',
          label: this.$t('message.networkPartner.managementCanton'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'marketingStates',
          label: this.$t('message.networkPartner.marketingCanton'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ]
    }
  },
  computed: {
    filtredNetworkPartnerList() {
      let list = this.networkPartnerList
      list.forEach(element => {
        element.active = this.currentnetworkList.includes(element.id)
        element.name = element.company !== null ? element.company.name : element.individual !== null ? element.individual.firstName.substr(0, 1) + '.' + element.individual.lastName : '-'
      })
      let globalList = []
      globalList = globalList.concat(list.filter(x => x.active === true).sort(function (a, b) {
        return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0
      }))
      globalList = globalList.concat(list.filter(x => x.active === false).sort(function (a, b) {
        return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0
      }))
      const current = this
      list = globalList.sort((x, y) => current.isActivePartner(y.id) - current.isActivePartner(x.id))

      if (this.nbrPerPage !== 'all') {
        list = list.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
      }
      return list
    },
    availableStates() {
      return this.availableMarketingStates.slice(0).sort()
    },
    availableType() {
      const types = []
      const current = this
      types.push({ value: 'MARKETING', label: current.$t('message.networkPartner.subTypes.marketing') })
      types.push({ value: 'LANDLORD', label: current.$t('message.networkPartner.subTypes.landlord') })
      types.push({ value: 'LANDLORD_MARKETING', label: current.$t('message.networkPartner.subTypes.landlord_marketing') })
      types.push({ value: 'ACCOUNTING', label: current.$t('message.networkPartner.subTypes.accounting') })
      types.push({ value: 'LANDLORD_ACCOUNTING', label: current.$t('message.networkPartner.subTypes.landlord_accounting') })
      types.push({ value: 'MARKETING_ACCOUNTING', label: current.$t('message.networkPartner.subTypes.marketing_accounting') })
      types.push({ value: 'LANDLORD_MARKETING_ACCOUNTING', label: current.$t('message.networkPartner.subTypes.landlord_marketing_accounting') })
      types.push({ value: 'ASSET_MANAGEMENT', label: current.$t('message.networkPartner.subTypes.asset_management') })
      return types
    },
    checkfilter() {
      return this.searchCanton.length > 0 || this.searchCompany !== '' || this.searchContactPerson !== '' || this.searchType.length > 0
    }
  },
  watch: {
    searchCanton: function () {
      this.clickSearch()
      this.updateWithFilter()
    },
    searchType: function () {
      this.clickSearch()
      this.updateWithFilter()
    },
    searchCompany: function () {
      this.updateWithFilter()
    },
    searchContactPerson: function () {
      this.updateWithFilter()
    }
  },
  mounted: function () {
    this.filterNetworkPartners()
    this.isBusy = true
    ShareApi.listPartners(this.$store.getters['user/getAccountId'])
      .then(response => {
        this.currentnetworkList = response.targetAccountIds
        this.filterNetworkPartners()
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
      })
    ShareApi.listByTargetAccountId(this.$store.getters['user/getAccountId'])
      .then(response => {
        const promises = []
        response.shares.forEach((share) => {
          promises.push(ShareApi.listPartners(share.sourceAccountId))
        })
        Promise.all(promises)
          .then(response => {
            let allAcrivePartners = []
            for (let i = 0; i < response.map(x => x.targetAccountIds).length; i++) {
              allAcrivePartners = allAcrivePartners.concat(response.map(x => x.targetAccountIds)[i])
            }
            const unique = [...new Set(allAcrivePartners)]
            this.allActivePartners = unique
          })
          .catch(e => {
            console.log(e)
          })
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.owners'), { type: 'error' })
      })
    this.$nextTick(function () {
      this.searchType = this.availableType.filter(x => this.defaultType.includes(x))
      this.searchCanton = this.availableStates.filter(x => this.defaultCanton.includes(x))
      this.searchCompany = this.defaultCompany
      this.searchContactPerson = this.defaultContactPerson
    })
  },
  methods: {
    isActivePartner(id) {
      const isActivePartner = this.allActivePartners.find(x => x === id)
      if (isActivePartner) {
        return true
      } else {
        return false
      }
    },
    clickOnClear() {
      this.searchCompany = ''
      this.searchContactPerson = ''
      this.searchType = []
      this.searchCanton = []
    },
    onRowClicked(item, index, event) {
      this.partnerClicked(item, index, event)
    },
    clickSearch() {
      this.currentPage = 1
      this.filterNetworkPartners()
    },
    customLabelCategory(item) {
      return item
    },
    customLabelType(item) {
      return item.label
    },
    allFilters() {
      const query = {}
      if (this.searchCanton.length > 0) {
        query.defaultCanton = this.searchCanton.map(x => x).join(',')
      }
      if (this.searchType.length > 0) {
        query.defaultType = this.searchType.map(x => x.value).join(',')
      }
      if (this.searchCompany !== '') {
        query.defaultCompany = this.searchCompany
      }
      if (this.searchContactPerson !== '') {
        query.defaultContactPerson = this.searchContactPerson
      }
      return query
    },
    updateWithFilter() {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.allFilters())) {
        this.$router.replace({
          name: 'OwnerNetworkPartnerView',
          query: this.allFilters()
        })
      }
    },
    filterNetworkPartners(page = 0) {
      this.isBusy = true
      let searchType = []
      if (this.searchType.flatMap(x => x.value).some(element => element.indexOf('ASSET_') === -1)) {
        searchType = this.searchType.flatMap(x => x.value)
      } else {
        searchType = this.searchType.flatMap(x => x.value).concat(['ASSET_LANDLORD',
          'ASSET_MARKETING',
          'ASSET_ACCOUNTING',
          'ASSET_LANDLORD_MARKETING',
          'ASSET_LANDLORD_ACCOUNTING',
          'ASSET_MARKETING_ACCOUNTING',
          'ASSET_LANDLORD_MARKETING_ACCOUNTING'])
      }
      NetworkPartnerAPI.listNetworkPartner(
        page,
        this.nbrPerPage,
        [],
        this.searchType.length > 0
? searchType
: [
          'LANDLORD',
          'LANDLORD_MARKETING',
          'MARKETING',
          'ACCOUNTING',
          'LANDLORD_ACCOUNTING',
          'MARKETING_ACCOUNTING',
          'LANDLORD_MARKETING_ACCOUNTING',
          'ASSET_MANAGEMENT',
          'ASSET_LANDLORD',
          'ASSET_MARKETING',
          'ASSET_ACCOUNTING',
          'ASSET_LANDLORD_MARKETING',
          'ASSET_LANDLORD_ACCOUNTING',
          'ASSET_MARKETING_ACCOUNTING',
          'ASSET_LANDLORD_MARKETING_ACCOUNTING'],
        this.searchCompany,
        this.searchContactPerson,
        this.searchCanton.map(x => 'CH_' + x)
      ).then(response => {
        this.objectCount = response.totalCount
        this.networkPartnerList = response.accounts
        this.isBusy = false
      }).catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
      })
    },
    getOnlineText(status, timestamp) {
      if (status === 'published') {
        const published = new Date(timestamp * 1000)
        const now = new Date()
        return published.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }) +
          '</br>' + Math.ceil((now.getTime() - published.getTime()) / (1000 * 3600 * 24)) + ' ' +
          this.$t('message.navigation.calendar.days')
      } else {
        return this.$t(`message.advertisement.status.${status}`)
      }
    },
    openSetClientModal(item) {
      this.$refs.setClientModal.newElementId = item.involvedContacts && item.involvedContacts.client ? item.involvedContacts.client.userReference : null
      this.$refs.setClientModal.setModalShow = true
    },
    openSetEmployeeModal(item) {
      this.$refs.setEmployeeModal.newElementId = item.involvedContacts && item.involvedContacts.assigneeMarketing ? item.involvedContacts.assigneeMarketing.userReference : null
      this.$refs.setEmployeeModal.setModalShow = true
    },
    partnerClicked(partner) {
      this.$router.push({ name: 'OwnerNetworkPartnerDetailsView', params: { id: partner.id } })
    },
    onMiddlelicked(partner) {
      const route = this.$router.resolve({ name: 'OwnerNetworkPartnerDetailsView', params: { id: partner.id } })
      window.open(route.href)
    }
  }
}
</script>

<style lang="scss" scoped>
  .green-icon {
    text-align: center;
    margin: 0 auto;
    height: 1rem;
    width: 1rem;

    :deep(svg) {
      color: $color-bg-green-light;
      height: 1rem;
      width: 1rem;
    }
  }
</style>
