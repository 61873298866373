// @ts-nocheck
import network from './network'

export default {
  createRooms(roomCreations) {
    return network.post('web/objects/room/create', {
      roomCreations: roomCreations
    }).then(response => {
      return response.data
    })
  },
  listById(ids) {
    return network.post('web/objects/room/listByIds', { ids: ids }).then(response => {
      return response.data
    })
  },
  updateRooms(roomUpdates) {
    for (let index = 0; index < roomUpdates.length; index++) {
      roomUpdates[index].dimensions = {
        area: roomUpdates[index].dimensions.area !== null ? roomUpdates[index].dimensions.area : 0,
        length: roomUpdates[index].dimensions.length !== null ? roomUpdates[index].dimensions.length : 0,
        width: roomUpdates[index].dimensions.width !== null ? roomUpdates[index].dimensions.width : 0,
        height: roomUpdates[index].dimensions.height !== null ? roomUpdates[index].dimensions.height : 0,
        volume: roomUpdates[index].dimensions.volume !== null ? roomUpdates[index].dimensions.volume : 0
      }
    }
    return network.post('web/objects/room/updateRooms', {
      roomUpdates: roomUpdates
    }).then(response => {
      return response.data
    })
  },
  deleteRooms(roomsIds) {
    return network.delete(`web/objects/room/${roomsIds}`).then(response => {
      return response.data
    })
  },
  getRoomsByReferenceIds(referenceIds) {
    return network.post('web/objects/room/listByReferenceIds', { referenceIds: referenceIds })
      .then(response => {
        return response.data
      })
  }
}
