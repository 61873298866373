import { render, staticRenderFns } from "./OwnerDashboardOverviewView.vue?vue&type=template&id=6d03236e&scoped=true"
import script from "./OwnerDashboardOverviewView.vue?vue&type=script&lang=js"
export * from "./OwnerDashboardOverviewView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d03236e",
  null
  
)

export default component.exports