<template>
  <section>
    <div
      v-if="!searchClient"
      class="text-center">
      <coozzy-spinner />
    </div>
    <ais-instant-search
      v-else
      ref="instant-search"
      :search-client="searchClient"
      :index-name="algoliaIndex"
      :routing="routing">
      <app-refresh
        ref="app-refresh"
        @refresh-done="refreshDone" />
      <ais-stats>
        <template #default="{ nbHits }">
          <coozzy-page-title
            :title="$t('message.managementOverview.buildings')"
            :count="nbHits"
            :count-text="$t('message.managementOverview.buildings')"
            :clear-filter="checkfilter"
            @clear-filter="clickOnClear" />
        </template>
      </ais-stats>
      <!-- Filter -->
      <div class="row">
        <div class="col-12 col-md mb-2 mb-md-0">
          <ais-search-box>
            <template #default="{ currentRefinement }">
              <debounced-algolia-search-box
                ref="debouncedAlgolia"
                :default-value="currentRefinement"
                :delay="300" />
            </template>
          </ais-search-box>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="propertyType"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchType', items) }}
              <coozzy-multiselect
                v-model="searchType"
                :options="sortedFilterValues(items, customLabelType)"
                :multiple="true"
                :placeholder="$t('message.manageBuilding.buildingType')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelType"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="buildingStrategy.type"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchBuildingStrategy', items) }}
              <coozzy-multiselect
                v-model="searchBuildingStrategy"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.onBoarding.buildings.buildingStrategy.type')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelBuildingStrategy"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="address.zip"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchZip', items) }}
              <coozzy-multiselect
                v-model="searchZip"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.generic.zip')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="address.city"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchCity', items) }}
              <coozzy-multiselect
                v-model="searchCity"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.generic.city')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="d-none d-md-block col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="objectCount"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchObjectCount', items) }}
              <coozzy-multiselect
                v-model="searchObjectCount"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.managementGroup.objectCount')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="owner.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchOwner', items) }}
              <coozzy-multiselect
                v-model="searchOwner"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.owner')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-md-1">
          <ais-refinement-list
            attribute="networkPartnerAsset.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchAsset', items) }}
              <coozzy-multiselect
                v-model="searchAsset"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.asset')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-md-1">
          <ais-refinement-list
            attribute="janitors.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchJanitor', items) }}
              <coozzy-multiselect
                v-model="searchJanitor"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.onBoarding.createContactTab.janitors')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-md-1">
          <ais-refinement-list
            attribute="craftsmen.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchCraftsman', items) }}
              <coozzy-multiselect
                v-model="searchCraftsman"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.onBoarding.createContactTab.craftsmen').toString()"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-md-1">
          <ais-refinement-list
            attribute="serviceProviders.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchServiceProvider', items) }}
              <coozzy-multiselect
                v-model="searchServiceProvider"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.onBoarding.createContactTab.serviceProvider')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
      </div>
      <!-- eslint-disable vue/attribute-hyphenation -->
      <ais-configure
        :hitsPerPage="nbrPerPage"
        :filters="filterQuery" />
      <!-- eslint-enable vue/attribute-hyphenation -->
      <div
        id="rowBuildingsTable"
        class="row mt-2 align-items-center">
        <div class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown
              v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator)"
              design="transparent"
              size="sm"
              class="w-100 ticket-dropdown-bulk-action"
              :class="selectedEntries.length > 0 ? 'enabled' : ''"
              :disabled="true"
              :text="$t('message.ticketOverview.createTicket')"
              dropright
              @click.prevent>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="createTicket('TICKET_TYPE_TASK')">
                {{ $t('message.ticketOverview.createTask') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="showActivity()">
              {{ $t('message.contact.activity.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="$refs['reminder-creation'].show()">
              {{ $t('message.calendar.reminder.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0 || !isSelectedBuildingHasObjects"
              @click="callAction('addJanitors')">
              {{ $t('message.manageBuilding.bulkAction.addJanitors') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0 || !isSelectedBuildingHasObjects"
              @click="callAction('addCraftsmen')">
              {{ $t('message.manageBuilding.bulkAction.addCraftsmen') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0 || !isSelectedBuildingHasObjects"
              @click="callAction('addServiceProviders')">
              {{ $t('message.manageBuilding.bulkAction.addServiceProviders') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider
              v-if="!isJanitor" />
            <coozzy-dropdown-item
              v-if="!isJanitor"
              :disabled="selectedEntries.length === 0"
              @click="redirectToExcelReport">
              {{ $t('message.generateExcel.title') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!isJanitor"
              :disabled="selectedEntries.length === 0"
              @click="showCreateTenantReportModal">
              {{ $t('message.navigation.reporting.createTenantReport') }}
            </coozzy-dropdown-item>
            <!--            <coozzy-dropdown-space-divider v-if="isEditor" />-->
            <!--            <coozzy-dropdown-item-->
            <!--              :disabled="selectedEntries.length === 0 || isSelectedBuildingsTypeCondominium"-->
            <!--              @click="createMassRental">-->
            <!--              {{ $t('message.massRental.title') }}-->
            <!--            </coozzy-dropdown-item>-->
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <div
              v-if="isEditor"
              class="row m-0"
              :class="selectedEntries.length === 0 || isSelectedBuildingAlreadyTransferred || isSelectedBuildingHasActivatedTenancy ? 'coozzyDropdownItemTooltip' : ''">
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0 || isSelectedBuildingAlreadyTransferred || isSelectedBuildingHasActivatedTenancy"
                @click="$bvModal.show('confirmationModalDeleteBuilding')">
                {{ $t('message.manageBuilding.bulkAction.removeBuilding') }}
              </coozzy-dropdown-item>
              <div class="pl-0">
                <coozzy-info-circle-icon
                  v-if="isSelectedBuildingAlreadyTransferred || isSelectedBuildingHasActivatedTenancy"
                  v-b-tooltip.hover.html="$t('message.deleteErrors.deleteBuildingSynchronizedWithAccountingOrHasActiveTenancyTooltip')"
                  class="mt-2" />
              </div>
            </div>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
          <ais-clear-refinements :included-attributes="['query', 'address.zip', 'address.city', 'objectCount', 'owner.name', 'networkPartnerAsset.name', 'propertyType', 'buildingStrategy.type']">
            <template #default="{ canRefine, refine }">
              {{ setCanRefine(canRefine) }}
              <a
                v-if="canRefine"
                ref="clearClick"
                @click="initFilter(refine)" />
            </template>
          </ais-clear-refinements>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
          :class="!isCompanyAdmin ? 'offset-md-6' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <ais-state-results>
        <template #default="{ hits, results }">
          {{ getHits(hits) }}
          {{ getState(results) }}
          <b-table
            id="buildingsTable"
            ref="buildingsTable"
            class="mt-2"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            :fields="fields"
            :items="hits"
            @row-clicked="onRowClicked"
            @row-middle-clicked="onMiddlelicked">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>
            <!-- Headings -->
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(thumbnail)>
              {{ $t('message.generic.thumbnail') }}
            </template>
            <template #head(buildingsName)>
              {{ $t('message.tableColumnHeadings.buildingsName') }}
            </template>
            <template #head(buildingId)>
              <span>{{ $t('message.tableColumnHeadings.buildingId') }}</span>
            </template>
            <template #head(buildingStrategy)>
              <span>{{ $t('message.tableColumnHeadings.buildingStrategy' ) }}</span>
            </template>
            <template #head(buildingStrategyReviewAt)>
              <span>{{ $t('message.tableColumnHeadings.buildingStrategyReviewAt') }}</span>
            </template>
            <template #head(address)>
              {{ $t('message.tableColumnHeadings.address') }}
            </template>
            <template #head(objectsCount)>
              {{ $t('message.tableColumnHeadings.objectsCount') }}
            </template>
            <template #head(grossTargetRentYear)>
              <span>{{ $t('message.tableColumnHeadings.grossTargetRentYear') }}</span>
            </template>
            <template #head(grossActualRentYear)>
              <span>{{ $t('message.tableColumnHeadings.grossActualRentYear') }}</span>
            </template>
            <template #head(netTargetRentYear)>
              <span>{{ $t('message.tableColumnHeadings.netTargetRentYear') }}</span>
            </template>
            <template #head(netActualRentYear)>
              <span>{{ $t('message.tableColumnHeadings.netActualRentYear') }}</span>
            </template>
            <template
              #head(owner)>
              <span>{{ $t('message.tableColumnHeadings.owner') }}</span>
            </template>
            <template #head(netOverallRentPerYear)>
              {{ $t('message.tableColumnHeadings.netOverallRentPerYear') }}
            </template>
            <template #head(grossOverallRentPerYear)>
              {{ $t('message.tableColumnHeadings.grossOverallRentPerYear') }}
            </template>
            <!-- Columns -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="'check_' + data.item.objectID"
                @change="(value) => { itemSelected(data.item, value) }" />
            </template>
            <template #cell(thumbnail)="data">
              <div
                v-if="data.item.imageUrl"
                class="d-flex justify-content-center">
                <coozzy-thumbnail
                  class="thumbnail"
                  :src="data.item.thumbnailSUrl"
                  :original="data.item.imageUrl" />
              </div>
              <div
                v-else
                class="d-flex justify-content-center">
                <coozzy-thumbnail
                  class="thumbnail"
                  src="/img/image-placeholder.png"
                  alt="Placeholder" />
              </div>
            </template>
            <template #cell(buildingsName)="data">
              {{ data.item.name }}
            </template>
            <template #cell(buildingId)="data">
              {{ data.item.numericId | displayOptionalValue }}
            </template>
            <template #cell(buildingStrategy)="data">
              <template v-if="!data.item.buildingStrategy || data.item.buildingStrategy.type === 'BUILDING_STRATEGY_TYPE_UNDEFINED'">
                -
              </template>
              <template v-else>
                {{ $t('message.onBoarding.buildings.buildingStrategy.' + data.item.buildingStrategy.type ) }}
              </template>
            </template>
            <template #cell(buildingStrategyReviewAt)="data">
              {{ data.item.buildingStrategy.reviewAt | objectToDate }}
            </template>
            <template #cell(address)="data">
              <address-text :address="data.item.address" />
            </template>
            <template #cell(objectsCount)="data">
              {{ data.item.objectCount | displayOptionalValue }}
            </template>
            <template #cell(owner)="data">
              <span
                v-b-tooltip="{container: '#buildingsTable', title: $t('message.generic.accountId') + data.item.owner.id}"
                class="d-block">
                <img
                  v-if="data.item.owner.name"
                  height="20px"
                  src="/img/propbase-icon.png"
                  alt="prop-base">
                {{ data.item.owner.name | displayOptionalValue }}
              </span>
            </template>
            <template #cell(grossTargetRentYear)="data">
              {{ data.item.actualGross | formatPrice }}
            </template>
            <template #cell(grossActualRentYear)="data">
              {{ data.item.grossOverallRentPerYear | formatPrice }}
            </template>
            <template #cell(netTargetRentYear)="data">
              {{ data.item.actualNet | formatPrice }}
            </template>
            <template #cell(netActualRentYear)="data">
              {{ data.item.netOverallRentPerYear | formatPrice }}
            </template>
            <template #cell(netOverallRentPerYear)="data">
              {{ data.item.targetNet | formatPrice }}
              <coozzy-info-circle-icon
                v-if="targetRentIncomplete(data.item.objectID)"
                v-b-tooltip="{container: '#buildingsTable', title: $t('message.manageBuilding.noExactValue')}" />
            </template>
            <template #cell(grossOverallRentPerYear)="data">
              {{ data.item.targetGross | formatPrice }}
              <coozzy-info-circle-icon
                v-if="targetRentIncomplete(data.item.objectID)"
                v-b-tooltip="{container: '#buildingsTable', title: $t('message.manageBuilding.noExactValue')}" />
            </template>
          </b-table>
        </template>
      </ais-state-results>
      <pagination-algolia
        ref="paginator"
        @changed-page="clearCheckbox()" />
    </ais-instant-search>
    <object-contacts-bulk-action
      v-if="selectedActions && selectedOwnerIds.length > 0"
      ref="objectContactsBulk"
      :title="selectedActions"
      :selected-object-ids="selectedObjectIds"
      :owner-ids="selectedOwnerIds"
      :building-ids="selectedEntriesIds"
      @action-done="clearArrays()" />
    <reminder-creation-modal
      ref="reminder-creation"
      :source="'building'"
      :references-ids="selectedEntriesIds"
      @new-reminder="clearCheckbox" />
    <b-modal
      :id="'confirmationModalDeleteBuilding'"
      ref="confirmationModalDeleteBuilding"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.manageBuilding.deleteConfirmation.title')">
      <div
        v-if="loadingDeletion"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col p-0">
          <p>{{ $t('message.manageBuilding.deleteConfirmation.text') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModalDeleteBuilding')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteBuilding()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <create-activity-modal
      ref="activity-creation"
      :key="keyActivity"
      :ids="selectedEntriesIds"
      @activity-created="clearCheckbox" />
    <create-tenant-report-modal
      v-if="showCreateTenantReport"
      ref="createTenantReportModal"
      :buildings-ids="selectedEntriesIds"
      @hide="hideCreateTenantReportModal" />
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import AddressText from '../framework/components/misc/AddressText'
import CoozzyThumbnail from '../framework/components/img/CoozzyThumbnail'
import CoozzyFormCheckbox from '../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyInfoCircleIcon from '../framework/components/icons/CoozzyInfoCircleIcon'
import CoozzyDropdown from '../framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '../framework/components/dropdown/CoozzyDropdownItem'
import CoozzyFormSelect from '../framework/components/form/select/CoozzyFormSelect'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import CoozzyDropdownSpaceDivider from '../framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzyPageTitle from '../framework/layout/CoozzyPageTitle'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import AppRefresh from '../framework/components/RefreshAlgolia'
import DebouncedAlgoliaSearchBox from '../framework/components/DebouncedAlgoliaSearchBox'
import { algolia } from '@/mixins/algolia'
import { tables } from '@/mixins/tables'
import ObjectContactsBulkAction from '@/owner/ObjectContactsBulkAction'
import PaginationAlgolia from '../framework/components/PaginationAlgolia'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import { user } from '@/mixins/user'
import ObjectApi from '@/misc/apis/ObjectApi'
import Vue from 'vue'
import ReportingApi from '@/misc/apis/ReportingApi'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CreateTenantReportModal from '@/reporting/CreateTenantReportModal.vue'
import { routeChecks } from '@/mixins/routeChecks'
import SearchApi from '@/misc/apis/SearchApi'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'
import AssembleListModal from '@/contact/components/AssembleListModal.vue'

export default {
  name: 'AdminBuildingsView',
  components: {
    AssembleListModal,
    CoozzyFiltersIcon,
    CoozzyButton,
    CoozzyMultiselect,
    DebouncedAlgoliaSearchBox,
    PaginationAlgolia,
    AppRefresh,
    ReminderCreationModal,
    CreateActivityModal,
    CoozzyPageTitle,
    CoozzyDropdownSpaceDivider,
    CoozzySpinner,
    CoozzyFormSelect,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyFormCheckbox,
    CoozzyThumbnail,
    AddressText,
    CoozzyInfoCircleIcon,
    ObjectContactsBulkAction,
    CreateTenantReportModal
  },
  mixins: [algolia, tables, user, routeChecks],
  metaInfo() {
    return {
      title: this.$t('message.navigation.management.buildings')
    }
  },
  data() {
    return {
      algoliaIndex: 'building',
      showFirst: false,
      galleryImages: [],
      galleryIndex: null,
      searchZip: [],
      keyActivity: 0,
      searchType: [],
      selectionsHaveMultipleOwners: false,
      searchBuildingStrategy: [],
      searchObjectCount: [],
      searchCity: [],
      searchOwner: [],
      searchAsset: [],
      searchJanitor: [],
      searchCraftsman: [],
      searchServiceProvider: [],
      selectedEntries: [],
      currentPage: 1,
      selectedOwnerIds: [],
      selectedObjectIds: [],
      isMultiple: false,
      selectedActions: '',
      checkfilter: false,
      loadingDeletion: false,
      appRefreshAction: '',
      buildingIds: [],
      buildingRentReports: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'thumbnail',
          label: this.$t('message.tableColumnHeadings.thumbnail'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'buildingsName',
          label: this.$t('message.tableColumnHeadings.buildingsName'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'buildingId',
          label: this.$t('message.tableColumnHeadings.buildingId'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center border-left-0',
          selected: true
        },
        {
          key: 'buildingStrategy',
          label: this.$t('message.tableColumnHeadings.buildingStrategy'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center border-left-0',
          selected: true
        },
        {
          key: 'buildingStrategyReviewAt',
          label: this.$t('message.tableColumnHeadings.buildingStrategyReviewAt'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center border-left-0',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.generic.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objectsCount',
          label: this.$t('message.tableColumnHeadings.objectsCount'),
          tdClass: 'text-center align-middle text-center',
          thClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'grossTargetRentYear',
          label: this.$t('message.tableColumnHeadings.grossTargetRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'grossActualRentYear',
          label: this.$t('message.tableColumnHeadings.grossActualRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'grossOverallRentPerYear',
          label: this.$t('message.tableColumnHeadings.grossOverallRentPerYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'netTargetRentYear',
          label: this.$t('message.tableColumnHeadings.netTargetRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'netActualRentYear',
          label: this.$t('message.tableColumnHeadings.netActualRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'netOverallRentPerYear',
          label: this.$t('message.tableColumnHeadings.netOverallRentPerYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'owner',
          label: this.$t('message.tableColumnHeadings.owner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        }
      ],
      selectedFields: [],
      showCreateTenantReport: false,
      paramsAlgolia: {
        query: '',
        params: '',
        index: ''
      },
      nbrTotalOfHits: 0,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.objectID) : this.selectedEntries.map(obj => obj.objectID)
    },
    isSelectedBuildingAlreadyTransferred() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(entry => entry.transferToAccounting === true).length > 0
    },
    isSelectedBuildingsTypeCondominium() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(entry => entry.propertyType && entry.propertyType === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP').length > 0
    },
    isSelectedBuildingHasActivatedTenancy() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(entry => entry.hasActivatedTenancy === true).length > 0
    },
    isSelectedBuildingHasObjects() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(entry => entry.objectIds && entry.objectIds.length > 0).length > 0
    },
    filterQuery() {
      let query = ''

      if (!this.isPlatformEmployee) {
        query += `networkPartners.combinedId:${this.accountId.toString()}-management`
      }

      return query
    }
  },
  watch: {
    checkfilter: function () {
      this.clearCheckbox()
    },
    nbrPerPage: function () {
      if (this.showFirst) {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.refresh()
          }
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.redirect(this.$route.query.page)
          }
        })
      }
      this.showFirst = true
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    buildingIds: function (oldVal, newVal) {
      if (!oldVal.some(x => newVal.includes(x))) {
        this.getRentBuildings()
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        // check if we already call algolia with the same params
        if (this.nbrTotalOfHits !== this.allEntries.length) {
          this.allEntries = await this.getAllAvailableDataByIndex(this.paramsAlgolia)
        }
        this.$nextTick(async () => {
          this.selectedOwnerIds = this.allEntries.map(x => x.owner.id)
          this.selectedObjectIds = this.allEntries.flatMap(x => x.objectIds)
        })
      } else {
        this.selectedOwnerIds = this.selectedEntries.map(x => x.owner.id)
        this.selectedObjectIds = this.selectedEntries.flatMap(x => x.objectIds)
      }
    }
  },
  mounted: function () {
    this.loadInstantSearch()
    if (this.isJanitor) {
      this.dataFields = this.dataFields.filter(x => x.key !== 'buildingStrategy' && x.key !== 'buildingStrategyReviewAt' && x.key !== 'grossTargetRentYear' && x.key !== 'grossActualRentYear' && x.key !== 'netTargetRentYear' && x.key !== 'netActualRentYear')
    }
    this.dataFields = this.dataFields.filter(x => x.selected).concat(this.dataFields.filter(x => !x.selected))
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'building_overview') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    })
    setTimeout(() => {
      this.$refs.debouncedAlgolia?.setFocusInput()
    }, 300)
  },
  methods: {
    createMassRental() {
      const buildingIds = this.selectedEntriesIds.join()
      this.$router.push({ name: 'AdminCreateMassRentalView', query: { source: 'building', buildingIds: buildingIds } })
    },
    getState(result) {
      this.nbrTotalOfHits = result.nbHits
      const params = this.editAlgoliaParams(result.params)
      this.paramsAlgolia = {
        query: result.query,
        params: params,
        index: result.index,
        attributesToRetrieve: ['objectID', 'owner', 'objectIds', 'hasActivatedTenancy', 'transferToAccounting']
      }
      return ''
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.PropertiesViewTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'building_overview').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    getHits(hits) {
      this.buildingIds = hits.map(x => x.objectID)
    },
    hideCreateTenantReportModal() {
      this.showCreateTenantReport = false
      this.clearCheckbox()
    },
    showCreateTenantReportModal() {
      this.showCreateTenantReport = true
      this.$nextTick(() => {
        this.$refs.createTenantReportModal?.show()
      })
    },
    targetRentIncomplete(buildingId) {
      if (this.buildingRentReports !== null) {
        const report = this.buildingRentReports.find(report => report.referenceId === buildingId)
        if (report) {
          return report.targetRentIncomplete
        }
      }
      return false
    },
    getRentBuildings() {
      ReportingApi.getBuildingRentReports(this.buildingIds)
        .then(response => {
          this.buildingRentReports = response.reports
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.rentReports'), { type: 'error' })
        })
    },
    createTicket(type) {
      const defaultAssignee = this.selectedEntries.every(obj => obj.networkPartnerAsset) && this.selectedEntries.every((val, i, arr) => val.networkPartnerAsset.accountId === arr[0].networkPartnerAsset.accountId)
        ? this.selectedEntries[0].networkPartnerAsset.accountId
: this.selectedEntries.every(obj => obj.networkPartnerAdministration) && this.selectedEntries.every((val, i, arr) => val.networkPartnerAdministration.accountId === arr[0].networkPartnerAdministration.accountId)
          ? this.selectedEntries[0].networkPartnerAdministration.accountId
: ''
      this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const buildingIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { buildingIds: buildingIds, source: 'building' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { buildingIds: buildingIds, source: 'building' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { buildingIds: buildingIds, source: 'building' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { buildingIds: buildingIds, source: 'building' } }, () => {})
      }
    },
    clickOnClear() {
      this.$refs.clearClick.click()
    },
    setCanRefine(canRefine) {
      this.checkfilter = canRefine
      return ''
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    clearArrays() {
      this.clearCheckbox()
      this.selectedActions = ''
      this.selectedOwnerIds = []
      this.selectedObjectIds = []
    },
    checkMultiple() {
      for (let index = 0; index < this.selectedOwnerIds.length - 1; index++) {
        if (this.selectedOwnerIds[index] !== this.selectedOwnerIds[index + 1]) {
          return true
        }
      }
      return false
    },
    onRowClicked(item, index, event) {
      if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
        this.itemSelected(item, !document.getElementById('check_' + item.objectID).checked)
        document.getElementById('check_' + item.objectID).checked = !document.getElementById('check_' + item.objectID).checked
      } else {
        this.buildingClicked(item, index, event)
      }
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    buildingClicked(building, index, event) {
      const element = event.srcElement
      if (element.tagName === 'IMG' && element.classList.contains('thumbnail')) {
        if (building.imageUrl && building.imageUrl !== '') {
          this.galleryImages = [
            building.imageUrl
          ]
          this.$nextTick(function () {
            this.$viewerApi({
              images: this.galleryImages,
              options: this.viewerOptions
            })
          })
        }
      } else {
        this.$router.push({
          name: 'AdminBuildingDetailsView',
          params: { id: building.objectID },
          query: { view: 'building' }
        })
      }
    },
    onMiddlelicked(building) {
      const route = this.$router.resolve({
        name: 'AdminBuildingDetailsView',
        params: { id: building.objectID }
      })
      window.open(route.href)
    },
    callAction(action) {
      this.selectedActions = action
      this.$nextTick(function () {
        this.$refs.objectContactsBulk.show()
      })
    },
    clickSearch() {
      this.currentPage = 1
    },
    initFilter(refine) {
      this.searchOwner = []
      this.searchAsset = []
      this.searchObjectCount = []
      this.searchBuildingStrategy = []
      this.searchZip = []
      this.searchCity = []
      this.searchType = []
      this.searchCraftsman = []
      this.searchJanitor = []
      this.searchServiceProvider = []
      refine('')
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.buildingsTable) {
        const allCheckboxesComponents = this.$refs.buildingsTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    itemSelected(element, checked) {
      if (checked && !this.selectedEntries.includes(element)) {
        this.selectedEntries.push(element)
        this.selectedOwnerIds.push(element.owner.id)
        this.selectedObjectIds = this.selectedObjectIds.concat(element.objectIds)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.objectID !== element.objectID)
        const index = this.selectedOwnerIds.findIndex(x => x === element.owner.id)
        this.selectedOwnerIds.splice(index, 1)
        this.selectedObjectIds = this.selectedObjectIds.filter(function (obj) {
          return !this.has(obj)
        }, new Set(element.objectIds.map(obj => obj)))
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    customLabel(item) {
      return item.value + ' (' + item.count + ')'
    },
    customLabelType(item) {
      return this.$t(`message.propertiesView.propertyType.${item.value}`) + ' (' + item.count + ')'
    },
    customLabelBuildingStrategy(item) {
      return this.$t(`message.onBoarding.buildings.buildingStrategy.${item.value}`) + ' (' + item.count + ')'
    },
    deleteBuilding() {
      this.loadingDeletion = true
      const promises = []
      for (const id of this.selectedEntriesIds) {
        promises.push(ObjectApi.deleteBuilding(id))
      }
      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            this.appRefreshAction = 'delete'
            this.$refs['app-refresh'].refresh()
          }, 2000)
        })
        .catch(e => {
          this.loadingDeletion = false
          this.$bvModal.hide('confirmationModalDeleteBuilding')
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.building'), { type: 'error' })
        })
    },
    refreshDone() {
      this.clearCheckbox()
      if (this.appRefreshAction === 'delete') {
        this.$bvModal.hide('confirmationModalDeleteBuilding')
        this.loadingDeletion = false
        Vue.toasted.show(this.$t('message.successMessages.buildingDeletion'), { type: 'success' })
      }
    },
    redirectToExcelReport() {
      let source = ''
      if (this.$route.fullPath.includes('view=objects')) {
        source = 'objects'
      } else if (this.$route.fullPath.includes('view=buildings')) {
        source = 'buildings'
      } else {
        source = 'properties'
      }
      localStorage.setItem('generateExcel-page', source)
      this.$router.push({ name: 'AdminGenerateExcel', params: { buildingIds: this.selectedEntriesIds } })
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  max-width: 100px;
}

:deep(th button) {
  padding-right: 0 !important;
}

:deep(.span-dropdown) {
  padding: 0 !important;
}
</style>
