import { render, staticRenderFns } from "./InterestedPartiesTab.vue?vue&type=template&id=674a59f6&scoped=true"
import script from "./InterestedPartiesTab.vue?vue&type=script&lang=js"
export * from "./InterestedPartiesTab.vue?vue&type=script&lang=js"
import style0 from "./InterestedPartiesTab.vue?vue&type=style&index=0&id=674a59f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "674a59f6",
  null
  
)

export default component.exports