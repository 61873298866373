
const share = {
  methods: {
    regroupObjectsByOwner(list) {
      let listByOwner = []
      list.forEach(element => {
        let owner = listByOwner.find(x => x.owner === element.owner.id)
        const elementId = element.objectID ? element.objectID : element.id
        if (owner) {
          owner.objects.push(elementId)
        } else {
          listByOwner.push({ owner: element.owner.id, objects: [elementId] })
        }
      })
      return listByOwner
    }
  }
}

export { share }
