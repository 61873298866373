// @ts-nocheck
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    applicationFormType: '',
    individuals: [],
    company: null,
    viewingDone: false,
    desiredStartOfContract: null
  },
  getters: {
    getIndividualByInternalId: (state) => (internalId) => {
      return state.individuals.find(individual => individual.internalId === internalId)
    },
    getMainIndividual: (state) => () => {
      return state.individuals[0]
    },
    getViewingDone: (state) => () => {
      return state.viewingDone
    },
    getDesiredStartOfContract: (state) => () => {
      return state.desiredStartOfContract
    },
    getPreferredLanguage: (state) => {
      return state.individuals[0]?.preferredLanguage || state.company?.preferredLanguage || 'PREFERRED_LANGUAGE_UNDEFINED'
    }
  },
  mutations: {
    setApplicationFormType(state, type) {
      if (!['individual', 'company'].includes(type)) {
        console.error('Wrong application form type')
        throw new Error('Wrong application form type')
      }

      state.applicationFormType = type
    },
    addIndividual(state, individual) {
      state.individuals.push(individual)
    },
    setViewingDone(state, viewingDone) {
      state.viewingDone = viewingDone
    },
    setDesiredStartOfContract(state, desiredStartOfContract) {
      state.desiredStartOfContract = desiredStartOfContract
    },
    updateIndividual(state, individual) {
      const index = state.individuals.findIndex(i => i.internalId === individual.internalId)
      if (index !== -1) {
        Vue.set(state.individuals, index, individual)
      }
    },
    removeIndividual(state, individual) {
      const individuals = state.individuals.filter(i => i.internalId !== individual.internalId)
      Vue.set(state, 'individuals', individuals)
    },
    updateCompany(state, company) {
      Vue.set(state, 'company', company)
    },
    addCompany(state, company) {
      Vue.set(state, 'company', company)
    },
    removeCompany(state) {
      Vue.set(state, 'company', null)
    },
    addIndividuals(state, individuals) {
      Vue.set(state, 'individuals', individuals)
    },
    clearIndividuals(state) {
      Vue.set(state, 'individuals', [])
    }
  },
  actions: {
    setApplicationFormType({ commit, dispatch }, type) {
      if (!['individual', 'company'].includes(type)) {
        console.error('Wrong application form type')
        throw new Error('Wrong application form type')
      }

      commit('setApplicationFormType', type)
      if (type === 'individual') {
        commit('removeCompany')
        dispatch('addDefaultIndividual', true)
      } else {
        commit('clearIndividuals')
        const company = {
          address: {
            country: 'CH',
            state: null
          },
          contactPerson: {},
          debtEnforcementRegisterMediaIds: [],
          founded: null,
          idCardMediaIds: [],
          insurance: {
            hasLiabilityInsurance: 'UNDEFINED_VALUE'
          },
          legal: {
            debtCertificateLastFiveYears: 'UNDEFINED_VALUE',
            foreclosureLastTwoYears: 'UNDEFINED_VALUE'
          },
          liquidated: {},
          mediaIds: [],
          name: '',
          purpose: '',
          spaceUtilization: '',
          tenancy: {
            cancelledByTenant: 'UNDEFINED_VALUE',
            hasCurrentLandlord: true,
            landlordEmail: '',
            landlordName: '',
            landlordPersonName: '',
            landlordPhone: '',
            relationToMainIndividual: '',
            relocationReason: '',
            rentedSince: null,
            previousGrossRent: -1,
            sameAsMainIndividual: false
          },
          preferredDeposit: {
            type: 'PREFERRED_DEPOSIT_TYPE_UNDEFINED',
            premiumThisYear: -1,
            premiumPerYear: -1,
            bankDepotIds: [],
            bankDepotNames: [],
            bankGuaranteeNames: [],
            bankGuaranteeIds: [],
            collectiveInsuranceNames: [],
            insuranceNames: []
          },
          tradeRegisterEntry: 'UNDEFINED_VALUE',
          vatLiable: 'UNDEFINED_VALUE',
          website: '',
          preferredLanguage: 'PREFERRED_LANGUAGE_UNDEFINED'
        }
        commit('addCompany', company)
      }
    },
    addDefaultIndividual({ commit }, main = false) {
      if (typeof main !== 'boolean') {
        main = false
      }

      commit('addIndividual', {
        internalId: Math.random().toString(36).substr(0, 5),
        mainIndividual: main,
        address: {
          country: 'CH'
        },
        household: {
          pets: 'UNDEFINED_VALUE',
          instruments: 'UNDEFINED_VALUE',
          smokers: 'UNDEFINED_VALUE'
        },
        employment: {},
        tenancy: {
          cancelledByTenant: 'UNDEFINED_VALUE',
          hasCurrentLandlord: true,
          landlordEmail: '',
          landlordName: '',
          landlordPersonName: '',
          landlordPhone: '',
          relationToMainIndividual: '',
          relocationReason: '',
          rentedSince: null,
          previousGrossRent: -1,
          sameAsMainIndividual: false
        },
        insurance: {
          hasLiabilityInsurance: 'UNDEFINED_VALUE'
        },
        legal: {},
        preferredDeposit: {
          type: 'PREFERRED_DEPOSIT_TYPE_UNDEFINED',
          premiumThisYear: -1,
          premiumPerYear: -1,
          bankDepotIds: [],
          bankDepotNames: [],
          bankGuaranteeNames: [],
          bankGuaranteeIds: [],
          collectiveInsuranceNames: [],
          insuranceNames: []
        },
        preferredLanguage: 'PREFERRED_LANGUAGE_UNDEFINED',
        preferredCommunicationChannel: 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED'
      })
    },
    loadApplicationForm({ commit }, application) {
      const individuals = application.individuals
      const company = application.company
      const pref = {
        type: 'PREFERRED_DEPOSIT_TYPE_UNDEFINED',
        premiumThisYear: -1,
        premiumPerYear: -1,
        bankDepotIds: [],
        bankDepotNames: [],
        bankGuaranteeNames: [],
        bankGuaranteeIds: [],
        collectiveInsuranceNames: [],
        insuranceNames: []
      }
      if (individuals.length > 0) {
        individuals.forEach(element => {
          element.internalId = Math.random().toString(36).substring(7)
          if (element.preferredDeposit === null) {
            element.preferredDeposit = pref
          }
        })
        commit('addIndividuals', individuals)
      } else {
        commit('addCompany', company)
      }

      if (individuals.length > 0) {
        commit('setApplicationFormType', 'individual')
      } else if (company) {
        commit('setApplicationFormType', 'company')
      }
    }
  }
}
