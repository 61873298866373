import networkTS from '@/misc/apis/networkTS'
import { PortalAddLinkEntry, PortalLink } from '@/types/api'

export default {
  async getPortalStatus(accountId: string) {
    return networkTS.portalServiceHasPortalEnabled(accountId).then(response => {
      return response.data
    })
  },
  async updatePortalStatus(accountIds: string[], enable: boolean) {
    return networkTS.portalServiceEnablePortal({ accountIds: accountIds, enable: enable }).then(response => {
      return response.data
    })
  },
  async addLinks(links: PortalAddLinkEntry[]) {
    return networkTS.portalServiceAddLinks({ links: links }).then(response => {
      return response.data
    })
  },
  async updateLinks(links: PortalLink[]) {
    return networkTS.portalServiceUpdateLinks({ links: links }).then(response => {
      return response.data
    })
  },
  async removeLinks(ids: string[]) {
    return networkTS.portalServiceRemoveLinks(ids).then(response => {
      return response.data
    })
  },
  async listLinksByReferenceIds(referenceIds: string[]) {
    return networkTS.portalServiceListLinksByReferenceIds({ referenceIds: referenceIds }).then(response => {
      return response.data
    })
  }
}
