<template>
  <section class="w-100">
    <div
      :id="`mortgage-${index}`"
      v-b-toggle="`collapse-mortgage-${index}`"
      class="col pt-3 mb-0">
      <div class="row">
        <div class="col">
          <h5 class="cursor-pointer hover width-only-content ">
            {{ title }}
          </h5>
        </div>
        <div
          class="col mr-5">
          <coozzy-dropdown
            v-if="isEditor"
            :text="$t('message.generic.actions')"
            class="w-20 sort float-right"
            size="sm">
            <coozzy-dropdown-item
              @click="readOnly ? goToEditMode() : addDocumentClicked('mortgage' + index);$event.stopPropagation()">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
      </div>
    </div>
    <div class="col-12">
      <b-collapse
        :id="`collapse-mortgage-${index}`"
        ref="collapse"
        :visible="expandByDefault">
        <div class="row details-row-bg-1 py-01rem">
          <div
            v-if="!readOnly"
            class="col-sm-12 col-md-3">
            <label for="autosuggest">{{
              $t('message.onBoarding.mortgage.bankName')
            }}*</label>
            <ais-instant-search
              v-if="searchClient"
              :search-client="searchClient"
              :routing="routing"
              index-name="banks">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                filters="countryCode:CH OR countryCode:DE OR countryCode:AT OR countryCode:LI" />
              <!-- eslint-enable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <vue-autosuggest
                    ref="autosuggest"
                    v-model="bankName"
                    :suggestions="indicesToSuggestions(indices)"
                    :get-suggestion-value="s => s.item.bankOrInstitutionName"
                    :input-props="{id:'autosuggest__input', class : 'form-control form-control-sm', placeholder: $t('message.generic.search'),}"
                    :class="$v.bankName.$dirty && $v.bankName.$error ? 'is-invalid' : ''"
                    @selected="selectHandler"
                    @item-changed="selectHandler"
                    @input="refine">
                    <template slot-scope="{ suggestion }">
                      <span class="my-suggestion-item">{{
                        suggestion.item.newIid ? suggestion.item.newIid : suggestion.item.iid
                      }}-{{ suggestion.item.bankOrInstitutionName }}-{{
                        suggestion.item.domicileAddress
                      }}-{{ suggestion.item.zipCode }}-{{ suggestion.item.place }}</span>
                    </template>
                  </vue-autosuggest>
                </template>
              </ais-autocomplete>
            </ais-instant-search>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <label for="autosuggest">{{
              $t('message.onBoarding.mortgage.bankName')
            }}</label>
            {{ bankName }}
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="street"
              :is-read-only="readOnly"
              :disabled="true"
              :name="$t('message.generic.street')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="zip"
              :is-read-only="readOnly"
              :disabled="true"
              :name="$t('message.generic.zip')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="city"
              :is-read-only="readOnly"
              :disabled="true"
              :name="$t('message.generic.city')" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-12 col-md-3">
            <label for="country">{{ $t('message.generic.country') }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="country"
              v-model="country"
              :disabled="true">
              <option value="CH">
                CH
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">
              {{ country }}
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="bankNumber"
              :is-integer="true"
              :disabled="true"
              :is-integer-format="true"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.mortgage.bankNumber')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="bic"
              :is-read-only="readOnly"
              :disabled="true"
              :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.bic')" />
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="number"
              :is-read-only="readOnly"
              :state="$v.number.$dirty && $v.number.$error ? false : null"
              :name="$t('message.onBoarding.mortgage.number') + (readOnly ? '' : '*')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="mortgageDesignation">{{ $t('message.onBoarding.mortgage.mortgageDesignation.title') }} {{ !readOnly ? '*' : '' }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="mortgageDesignation"
              v-model="mortgageDesignation"
              :state="$v.mortgageDesignation.$dirty && $v.mortgageDesignation.$error ? false : null">
              <option value="MORTGAGE_DESIGNATION_UNDEFINED" />
              <option
                v-for="(desi, idx) in availableDesignation"
                :key="idx"
                :value="desi">
                {{ $t('message.onBoarding.mortgage.mortgageDesignation.' + desi) }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">
              {{ !mortgageDesignation || mortgageDesignation === 'MORTGAGE_DESIGNATION_UNDEFINED' ? '-' : $t('message.onBoarding.mortgage.mortgageDesignation.' + mortgageDesignation) }}
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="amount"
              type="number"
              :is-read-only="readOnly"
              :filter="'formatPrice'"
              placeholder="CHF"
              :is-decimal="true"
              :name="$t('message.onBoarding.mortgage.amount') + (readOnly ? '' : '*')"
              :check-valide="$v.amount.$dirty && $v.amount.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="openAmount"
              type="number"
              :is-read-only="readOnly"
              :disabled="true"
              :filter="'formatPrice'"
              placeholder="CHF"
              :is-decimal="true"
              :name="$t('message.onBoarding.mortgage.openAmount')" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="interestRate"
              :is-read-only="readOnly"
              :is-decimal="true"
              :check-valide="$v.interestRate.$dirty && $v.interestRate.$error ? 'is-invalid' : ''"
              :name="$t('message.onBoarding.mortgage.interestRate') + (readOnly ? '' : '*')" />
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="type">{{ $t('message.onBoarding.mortgage.dueDateInterestRate') }}</label>
            <p
              v-if="getDueDateMonthsInterestRate.length > 0"
              class="mb-0 multi-select-value">
              {{ getDueDateMonthsInterestRate }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="dueDateInterestRate"
              v-model="dueDateMonthsInterestRate"
              :options="translatedDueDate"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              :check-valide="$v.dueDateMonthsInterestRate.$dirty && $v.dueDateMonthsInterestRate.$error ? 'is-invalid' : ''"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.mortgage.dueDateInterestRate') + (readOnly ? '' : '*') }}
            </coozzy-multiselect>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="currentMonthlyInterestCharge"
              type="number"
              :is-read-only="readOnly"
              :disabled="true"
              :filter="'formatPrice'"
              placeholder="CHF"
              :is-decimal="true"
              :name="$t('message.onBoarding.mortgage.currentMonthlyInterestCharge')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="debitAccount"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.mortgage.debitAccount')" />
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="mortgageDate.validFrom"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.mortgage.validFrom') + (readOnly ? '' : '*')"
              :state="$v.mortgageDate.validFrom.$dirty && $v.mortgageDate.validFrom.$error ? false : null"
              @blur="dateToObject()"
              @keyup="limitToDateLength()" />
          </div>
          <div
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="mortgageDate.expiresAt"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.mortgage.expirationDate')"
              @blur="dateToObject()"
              @keyup="limitToDateLength()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="type">{{ $t('message.onBoarding.mortgage.terminableOn.title') }} {{ !readOnly ? '*' : '' }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="type"
              v-model="terminableOn"
              :state="$v.terminableOn.$dirty && $v.terminableOn.$error ? false : null">
              <option value="MORTGAGE_TERMINABLE_ON_UNDEFINED" />
              <option
                v-for="(term, idx) in availableTerminable"
                :key="idx"
                :value="term">
                {{ $t('message.onBoarding.mortgage.terminableOn.' + term) }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">
              {{ !terminableOn || terminableOn === 'MORTGAGE_TERMINABLE_ON_UNDEFINED' ? '-' : $t('message.onBoarding.mortgage.terminableOn.' + terminableOn) }}
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="designation"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.mortgage.designation')" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div
            v-if="!readOnly"
            class="col-sm-12 col-md-3">
            <coozzy-form-checkbox
              v-model="enableAmortizations"
              :initial="enableAmortizations"
              name="enableAmortizations"
              class="mt-4">
              {{ $t('message.onBoarding.mortgage.amortization') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <label>
              {{ $t('message.onBoarding.mortgage.amortization') }}
            </label>
            <span class="show-data">
              {{ enableAmortizations ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
            </span>
          </div>
        </div>
        <div
          v-if="enableAmortizations"
          class="row details-row-bg-2 py-02rem">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="mortgageDate.amortizationFrom"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.mortgage.amortizationFrom')"
              @blur="dateToObject()"
              @keyup="limitToDateLength()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="amortisationAmount"
              type="number"
              :is-read-only="readOnly"
              :filter="'formatPrice'"
              placeholder="CHF"
              :is-decimal="true"
              :check-valide="$v.amortisationAmount.$dirty && $v.amortisationAmount.$error ? 'is-invalid' : ''"
              :name="$t('message.onBoarding.mortgage.AmortisationAmountDueDate') + (readOnly ? '' : '*')" />
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="type">{{ $t('message.onBoarding.mortgage.dueDate') }}</label>
            <p
              v-if="getDueDateMonthsAmortization.length > 0"
              class="mb-0 multi-select-value">
              {{ getDueDateMonthsAmortization }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="AmortisationAmountDueDate"
              v-model="dueDateMonthsAmortization"
              :options="translatedDueDate"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              :check-valide="$v.dueDateMonthsAmortization.$dirty && $v.dueDateMonthsAmortization.$error ? 'is-invalid' : ''"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.mortgage.dueDate') }} {{ readOnly ? '' : '*' }}
            </coozzy-multiselect>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="paidAmount"
              type="number"
              :is-read-only="readOnly"
              :filter="'formatPrice'"
              placeholder="CHF"
              :disabled="true"
              :is-decimal="true"
              :name="$t('message.onBoarding.mortgage.paidAmount')" />
          </div>
        </div>
        <div
          v-if="enableAmortizations"
          class="row details-row-bg-2 py-01rem">
          <template
            v-for="(amortization, adjIndex) in amortizations">
            <div
              :key="'amortizationData' + adjIndex"
              class="col-md-12">
              <div class="row details-row-bg-1 py-01rem">
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="dates.amortizationsByDate[adjIndex]"
                    :is-read-only="readOnly"
                    :filter="'formatDate'"
                    type="date"
                    :state="$v.amortizations.$each[adjIndex].byDate.$dirty && $v.amortizations.$each[adjIndex].byDate.$error ? false : null"
                    :name="$t('message.generic.byDate') + (readOnly ? '' : '*')"
                    @blur="dateToObjectAvoidWatcher()" />
                </div>
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input-numeric
                    :value="amortization.amount !== -1 ? amortization.amount : null"
                    type="number"
                    :is-read-only="readOnly"
                    :filter="'formatPrice'"
                    min="0"
                    placeholder="CHF"
                    :is-decimal="true"
                    :name="$t('message.onBoarding.mortgage.amortization') + (readOnly ? '' : '*')"
                    :check-valide="$v.amortizations.$each[adjIndex].amount.$dirty && $v.amortizations.$each[adjIndex].amount.$error ? 'is-invalid' : ''"
                    @input="amortizationValueChanged(adjIndex, 'amount', $event)" />
                </div>
                <div
                  v-if="!readOnly"
                  class="col-md-1 mt-2">
                  <coozzy-button
                    design="alert"
                    class="align-self-center mt-3"
                    @click="openConfirmationModal(adjIndex)">
                    {{ $t('message.generic.delete') }}
                  </coozzy-button>
                </div>
              </div>
            </div>
          </template>
          <div class="col-md-12 col-12 mt-3">
            <div class="row">
              <div class="col-md-2 col-12">
                <coozzy-button
                  v-if="!readOnly"
                  design="prop-green"
                  class="w-100 mb-3"
                  @click="addAmortizationClicked">
                  {{ $t('message.onBoarding.mortgage.addPayment') }}
                </coozzy-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-12 col-md-12">
            <add-document-modal
              :suffix="'mortgage' + index"
              :owner-id="mortgage.ownerId"
              @document-created="documentCreated" />
          </div>
          <div class="col-md-12 col-12">
            <div class="row">
              <div class="col-md-2 col-12">
                <coozzy-button
                  v-if="!readOnly"
                  design="prop-green"
                  class="w-100"
                  @click="addDocumentClicked('mortgage' + index)">
                  {{ $t('message.manageDocuments.addDocument') }}
                </coozzy-button>
              </div>
            </div>
          </div>
          <div
            v-if="documents.length > 0"
            class="col-12 mt-2">
            <documents-list
              :documents-list="documents"
              :is-read-only="readOnly"
              @version-added="versionAdded"
              @document-deleted="deleteDocument" />
          </div>
        </div>
        <b-modal
          :id="'amortization-confirmationModal'"
          ref="amortization-confirmationModal"
          no-close-on-backdrop
          hide-footer
          :title="$t('message.onBoarding.deleteModals.titleAmortization')">
          <template>
            <div class="col">
              <p>{{ $t('message.onBoarding.deleteModals.bodyAmortization') }}</p>
            </div>
            <div class="col">
              <coozzy-button
                size="small"
                class="mb-0 border"
                design="transparent"
                @click="$bvModal.hide('amortization-confirmationModal')">
                {{ $t('message.generic.cancel') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="float-right mb-0"
                design="green"
                @click="deleteAmortizationClicked(adjIndexToDelete)">
                {{ $t('message.generic.delete') }}
              </coozzy-button>
            </div>
          </template>
        </b-modal>

        <div class="row">
          <div class="col-12 mt-3">
            <coozzy-button
              v-if="!readOnly"
              size="small"
              design="red"
              class="float-right"
              @click="removeClicked">
              {{ $t('message.onBoarding.mortgage.deleteMortgage') }}
            </coozzy-button>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-modal
      :id="'deleteModal'"
      ref="deleteModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodyMortgage') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('deleteModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric'
import { required } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import MediaApi from '@/misc/apis/MediaApi'
import { algolia } from '@/mixins/algolia'
import { VueAutosuggest } from 'vue-autosuggest'
import DocumentsList from '@/properties/components/DocumentsList.vue'
import { user } from '@/mixins/user'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import { routeChecks } from '@/mixins/routeChecks'

export default {
  name: 'Mortgage',
  components: {
    CoozzyDropdown,
    CoozzyDropdownItem,
  DocumentsList,
  VueAutosuggest,
  CoozzyMultiselect,
  CoozzyFormCheckbox,
  CoozzyFormInputNumeric,
  CoozzyFormSelect,
  CoozzyFormInput,
  CoozzyButton,
  AddDocumentModal
  },
  mixins: [validation, algolia, user, routeChecks],
  props: {
    index: {
      type: Number,
      default: 0
    },
    mortgage: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    addNewDocument: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dates: {
        amortizationsByDate: []
      },
      availableDueDate: ['all', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      availableTerminable: [
        'MORTGAGE_TERMINABLE_ON_1_MONTH',
        'MORTGAGE_TERMINABLE_ON_2_MONTHS',
        'MORTGAGE_TERMINABLE_ON_3_MONTHS',
        'MORTGAGE_TERMINABLE_ON_6_MONTHS',
        'MORTGAGE_TERMINABLE_ON_12_MONTHS'
      ],
      availableDesignation: [
        'MORTGAGE_DESIGNATION_FIXED_1_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_2_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_3_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_4_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_5_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_6_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_7_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_8_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_9_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_10_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_11_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_12_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_13_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_14_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_15_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_16_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_17_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_18_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_19_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_20_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_21_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_22_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_23_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_24_YEAR',
        'MORTGAGE_DESIGNATION_FIXED_25_YEAR',
        'MORTGAGE_DESIGNATION_VARIABLE',
        'MORTGAGE_DESIGNATION_LIBOR',
        'MORTGAGE_DESIGNATION_SARON'],
      mortgageDate: {
        validFrom: null,
        expiresAt: null,
        amortizationFrom: null
      },
      adjIndexToDelete: null,
      mortgageDocuments: [],
      mountedComplete: false
    }
  },
  computed: {
    title() {
      let title = ''

      if (this.bankName) {
        title += ` - ${this.bankName}`
      }

      if (this.mortgageDesignation && this.mortgageDesignation !== 'MORTGAGE_DESIGNATION_UNDEFINED') {
        title += ` - ${this.$t('message.onBoarding.mortgage.mortgageDesignation.' + this.mortgageDesignation)}`
      }

      if (this.number) {
        title += ` - ${this.number}`
      }

      if (this.amount && this.amount !== -1) {
        title += ` - ${this.$options.filters.formatPrice(this.amount, 'CHF')}`
      }

      if (this.interestRate) {
        title += ` - ${this.interestRate}%`
      }

      if (title === '') {
        title += this.$t('message.onBoarding.mortgage.newMortgage')
      }

      if (this.mortgageDate.validFrom) {
        title += ` - ${this.$options.filters.formatDate(this.mortgageDate.validFrom)}`
      } else {
        title += ' - n/a'
      }

      if (this.mortgageDate.expiresAt) {
        title += ` - ${this.$options.filters.formatDate(this.mortgageDate.expiresAt)}`
      } else {
        title += ' - n/a'
      }

      if (title.startsWith(' - ')) {
        title = title.substr(3)
      }

      return title
    },
    amortizations() {
      return this.mortgage.amortizations
    },
    bankName: {
      get() {
        return this.mortgage.bankName
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.bankName = value
      }
    },
    bic: {
      get() {
        return this.mortgage.bic
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.bic = value
      }
    },
    street: {
      get() {
        return this.mortgage.mortgageeAddress.street
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.mortgageeAddress.street = value
      }
    },
    zip: {
      get() {
        return this.mortgage.mortgageeAddress.zip
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.mortgageeAddress.zip = value
      }
    },
    city: {
      get() {
        return this.mortgage.mortgageeAddress.city
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.mortgageeAddress.city = value
      }
    },
    country: {
      get() {
        return this.mortgage.mortgageeAddress.country
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.mortgageeAddress.country = value
      }
    },
    bankNumber: {
      get() {
        return this.mortgage.bankNumber && this.mortgage.bankNumber !== '' ? parseInt(this.mortgage.bankNumber) : null
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.bankNumber = value.toString()
      }
    },
    number: {
      get() {
        return this.mortgage.number
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.number = value.toString()
      }
    },
    mortgageDesignation: {
      get() {
        return this.mortgage.mortgageDesignation
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.mortgageDesignation = value
      }
    },
    amount: {
      get() {
        return this.mortgage.amount !== -1 ? this.mortgage.amount : null
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.amount = value
      }
    },
    openAmount: {
      get() {
        const amount = this.mortgage.amount !== -1 ? this.mortgage.amount : 0
        const paidAmount = this.paidAmount
        return amount - paidAmount
      }
    },
    interestRate: {
      get() {
        return this.mortgage.interestRate !== -1 && this.mortgage.interestRate !== '' ? this.mortgage.interestRate : null
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.interestRate = value
      }
    },
    enableAmortizations: {
      get() {
        return this.mortgage.enableAmortizations
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.enableAmortizations = value
      }
    },
    paidAmount: {
      get() {
        let amoutAmortization = 0
        this.mortgage.amortizations.forEach(element => {
          amoutAmortization = amoutAmortization + element.amount
        })
        return amoutAmortization
      }
    },
    amortisationAmount: {
      get() {
        return this.mortgage.amortisationAmount
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.amortisationAmount = value
      }
    },
    validFrom: {
      get() {
        return this.mortgage.validFrom !== '-1' ? this.mortgage.validFrom : null
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.validFrom = value
      }
    },
    expiresAt: {
      get() {
        return this.mortgage.expiresAt !== '-1' ? this.mortgage.expiresAt : null
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.expiresAt = value
      }
    },
    amortizationFrom: {
      get() {
        return this.mortgage.amortizationFrom !== '-1' ? this.mortgage.amortizationFrom : null
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.amortizationFrom = value
      }
    },
    dueDateMonthsInterestRate: {
      get() {
        const tempMonths = this.mortgage.dueDateMonthsInterestRate
        tempMonths.sort(function (a, b) {
          return a - b
        })
        return tempMonths.map(month => {
          return {
            label: this.$t('message.generic.months.' + month),
            value: month
          }
        })
      },
      set(value) {
        const newMortgage = this.mortgage
        for (const month of value) {
          if (month.value === 'all') {
            newMortgage.dueDateMonthsInterestRate = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            return
          }
        }
        newMortgage.dueDateMonthsInterestRate = value.map(x => x.value)
      }
    },
    debitAccount: {
      get() {
        return this.mortgage.debitAccount
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.debitAccount = value
      }
    },
    dueDateMonthsAmortization: {
      get() {
        const tempMonths = this.mortgage.dueDateMonthsAmortization
        tempMonths.sort(function (a, b) {
          return a - b
        })
        return tempMonths.map(month => {
          return {
            label: this.$t('message.generic.months.' + month),
            value: month
          }
        })
      },
      set(value) {
        const newMortgage = this.mortgage
        for (const month of value) {
          if (month.value === 'all') {
            newMortgage.dueDateMonthsAmortization = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            return
          }
        }
        newMortgage.dueDateMonthsAmortization = value.map(x => x.value)
      }
    },
    terminableOn: {
      get() {
        return this.mortgage.terminableOn
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.terminableOn = value
      }
    },
    designation: {
      get() {
        return this.mortgage.designation
      },
      set(value) {
        const newMortgage = this.mortgage
        newMortgage.designation = value
      }
    },
    documents() {
      return this.getFilteredMortgageDocuments(this.mortgage.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    getDueDateMonthsInterestRate() {
      const array = []
      this.mortgage.dueDateMonthsInterestRate.forEach(idx => {
        array.push(this.$t('message.generic.months.' + idx))
      })
      return array.join(', ')
    },
    getDueDateMonthsAmortization() {
      const array = []
      this.mortgage.dueDateMonthsAmortization.forEach(idx => {
        array.push(this.$t('message.generic.months.' + idx))
      })
      return array.join(', ')
    },
    translatedDueDate() {
      const array = []
      for (const month of this.availableDueDate) {
        array.push({
          label: this.$t('message.generic.months.' + month),
          value: month
        })
      }
      return array
    },
    currentMonthlyInterestCharge: {
      get() {
        const openAmount = this.openAmount
        const interestRate = this.interestRate
        return (openAmount * interestRate) / (100 * 12)
      }
    }
  },
  watch: {
    amortisationAmount: function () {
      this.calculatePayments()
    },
    amortizationFrom: function () {
      this.calculatePayments()
    },
    expiresAt: function () {
      this.calculatePayments()
    },
    getDueDateMonthsAmortization: function () {
      this.calculatePayments()
    }
  },
  mounted() {
    this.loadInstantSearch(true)
    this.mortgageDate.validFrom = this.validFrom && this.validFrom.year !== -1 && this.validFrom.year > 0 ? this.validFrom.year + '-' + ('0' + this.validFrom.month).slice(-2) + '-' + ('0' + this.validFrom.day).slice(-2) : ''
    this.mortgageDate.expiresAt = this.expiresAt && this.expiresAt.year !== -1 && this.expiresAt.year > 0 ? this.expiresAt.year + '-' + ('0' + this.expiresAt.month).slice(-2) + '-' + ('0' + this.expiresAt.day).slice(-2) : ''
    this.mortgageDate.amortizationFrom = this.amortizationFrom && this.amortizationFrom.year !== -1 && this.amortizationFrom.year > 0 ? this.amortizationFrom.year + '-' + ('0' + this.amortizationFrom.month).slice(-2) + '-' + ('0' + this.amortizationFrom.day).slice(-2) : ''
    this.dates.amortizationsByDate = this.amortizations.map(adj => {
      if (adj && adj.byDate) {
        return adj.byDate.year + '-' + ('0' + adj.byDate.month).slice(-2) + '-' + ('0' + adj.byDate.day).slice(-2)
      } else {
        return null
      }
    })
    if (this.mortgage.documentIds.length > 0) {
      MediaApi.listDocuments(this.mortgage.documentIds)
        .then(response => {
          this.mortgageDocuments = response.documents
        })
    }
    this.$nextTick(() => {
      this.mountedComplete = true
      if (!this.readOnly && this.addNewDocument) {
        this.addDocumentClicked('mortgage' + this.index)
      }
    })
  },
  methods: {
    goToEditMode() {
      localStorage.setItem('mortgagePropertyIdNewDocument', this.mortgage.id)
      this.$router.push({
        name: this.moduleRoutePrefix + 'PropertyEditingView',
        params: { id: this.$route.params.id },
        query: {
          view: 'property'
        }
      })
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    selectHandler(selected) {
      if (selected && selected.item) {
        this.bankNumber = selected.item.newIid ? selected.item.newIid : selected.item.iid
        this.bic = selected.item.bic ? selected.item.bic : ''
        this.bankName = null
        this.$nextTick(function () {
          this.bankName = selected.item.bankOrInstitutionName
        })
        this.zip = selected.item.zipCode
        this.city = selected.item.place
        this.country = selected.item.countryCode
        this.street = selected.item.domicileAddress
      }
    },
    getFilteredMortgageDocuments(documentIds) {
      return this.mortgageDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    addDocumentClicked(suffix = '') {
      localStorage.removeItem('mortgagePropertyIdNewDocument')
      this.$bvModal.show('add-document-modal' + suffix)
    },
    dateToObject() {
      const validFrom = new Date(this.mortgageDate.validFrom)
      this.validFrom = validFrom instanceof Date && isFinite(validFrom)
? {
        day: validFrom.getDate(),
        month: validFrom.getMonth() + 1,
        year: validFrom.getFullYear()
      }
: null
      const expiresAt = new Date(this.mortgageDate.expiresAt)
      this.expiresAt = expiresAt instanceof Date && isFinite(expiresAt)
? {
        day: expiresAt.getDate(),
        month: expiresAt.getMonth() + 1,
        year: expiresAt.getFullYear()
      }
: null
      const amortizationFrom = new Date(this.mortgageDate.amortizationFrom)
      this.amortizationFrom = amortizationFrom instanceof Date && isFinite(amortizationFrom)
? {
        day: amortizationFrom.getDate(),
        month: amortizationFrom.getMonth() + 1,
        year: amortizationFrom.getFullYear()
      }
: null
    },
    dateToObjectAvoidWatcher() {
      this.dates.amortizationsByDate.forEach((date, i) => {
        const amortizationsByDate = new Date(date)
        this.amortizations[i].byDate = amortizationsByDate instanceof Date && isFinite(amortizationsByDate)
? {
          day: amortizationsByDate.getDate(),
          month: amortizationsByDate.getMonth() + 1,
          year: amortizationsByDate.getFullYear()
        }
: null
      })
    },
    documentCreated(document) {
      const mortgage = this.mortgage
      mortgage.documentIds.push(document.id)

      // Add whole new document to vuex store to display it
      this.mortgageDocuments.push(document)
      this.$emit('update-mortgage', this.mortgage.internalId || this.mortgage.id)
    },
    addAmortizationClicked() {
      this.dates.amortizationsByDate.unshift(null)
      this.mortgage.amortizations.unshift({
        byDate: null,
        amount: null
      })
    },
    amortizationValueChanged(index, field, val) {
      this.amortizations[index][field] = val
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    removeConfirmed() {
      this.$emit('remove-mortgage', this.mortgage.internalId || this.mortgage.id)
    },
    toTimestamp(strDate) {
      return Date.parse(strDate) / 1000
    },
    versionAdded(updatedDocument) {
      this.mortgageDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.mortgageDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    openConfirmationModal(adjIndexToDelete) {
      this.adjIndexToDelete = adjIndexToDelete
      this.$refs['amortization-confirmationModal'].show()
    },
    deleteAmortizationClicked(index) {
      this.$delete(this.dates.amortizationsByDate, index)
      this.$delete(this.mortgage.amortizations, index)
      this.adjIndexToDelete = null
      this.$refs['amortization-confirmationModal'].hide()
    },
    deleteDocument(document) {
      this.mortgage.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.mortgage.documentIds.splice(index, 1)
          this.$emit('update-mortgage', this.mortgage.internalId || this.mortgage.id)
        }
      }, this)
    },
    limitToDateLength() {
      if (this.mortgageDate.validFrom) {
        const arrayValidFrom = this.mortgageDate.validFrom.split('-')
        this.mortgageDate.validFrom = arrayValidFrom[0].substring(0, 4) + '-' + arrayValidFrom[1] + '-' + arrayValidFrom[2]
      }
      if (this.mortgageDate.expiresAt) {
        const arrayInactiveFrom = this.mortgageDate.expiresAt.split('-')
        this.mortgageDate.expiresAt = arrayInactiveFrom[0].substring(0, 4) + '-' + arrayInactiveFrom[1] + '-' + arrayInactiveFrom[2]
      }
      if (this.mortgageDate.amortizationFrom) {
        const arrayInactiveFrom = this.mortgageDate.amortizationFrom.split('-')
        this.mortgageDate.amortizationFrom = arrayInactiveFrom[0].substring(0, 4) + '-' + arrayInactiveFrom[1] + '-' + arrayInactiveFrom[2]
      }
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      return this.$v.$invalid
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    },
    calculatePayments() {
      this.$nextTick(function () {
        if (!this.mortgage.amortizationFrom || !this.mountedComplete) {
          return
        }
        let expiresAt
        if (!this.mortgage.expiresAt) {
          expiresAt = new Date()
        } else {
          expiresAt = this.objectDateToDateJs(this.mortgage.expiresAt)
        }

        this.mortgage.amortizations = []
        this.dates.amortizationsByDate = []

        let months = []
        if (this.mortgage.dueDateMonthsInterestRate.length && this.mortgage.dueDateMonthsInterestRate[0] === 'all') {
          months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        } else {
          months = this.mortgage.dueDateMonthsAmortization.map(function (item) {
            return item
          })
        }

        const differenceYears = expiresAt.getFullYear() - this.objectDateToDateJs(this.mortgage.amortizationFrom).getFullYear()
        const years = []
        years.push(this.objectDateToDateJs(this.mortgage.amortizationFrom).getFullYear())
        for (let i = 1; i <= differenceYears; i++) {
          years.push(this.objectDateToDateJs(this.mortgage.amortizationFrom).getFullYear() + i)
        }

        if (years.length > 150) {
          return
        }

        for (const year of years) {
          for (const month of months) {
            const nextDueDate = new Date(year, month - 1, 1)
            if (nextDueDate.getTime() >= this.objectDateToDateJs(this.mortgage.amortizationFrom).getTime() && nextDueDate.getTime() <= expiresAt.getTime()) {
              this.dates.amortizationsByDate.push(nextDueDate.getFullYear() + '-' + ((nextDueDate.getMonth() > 8) ? nextDueDate.getMonth() + 1 : '0' + (nextDueDate.getMonth() + 1)) + '-' + ((nextDueDate.getDate() > 9) ? nextDueDate.getDate() : ('0' + nextDueDate.getDate())))
              this.mortgage.amortizations.push({
                byDate: nextDueDate instanceof Date && isFinite(nextDueDate)
? {
                  day: nextDueDate.getDate(),
                  month: nextDueDate.getMonth() + 1,
                  year: nextDueDate.getFullYear()
                }
: null,
                amount: this.mortgage.amortisationAmount
              })
            } else {
              continue
            }
          }
        }
      })
    },
    objectDateToDateJs(date) {
      if (!date) {
        return null
      }
      return new Date(date.year, date.month - 1, date.day)
    }
  },
  validations() {
    const validationMortgage = {
      bankName: {
        required
      },
      number: {
        required
      },
      amount: {
        required
      },
      mortgageDesignation: {
        required
      },
      interestRate: {
        required
      },
      mortgageDate: {
        validFrom: {
          required
        }
      },
      dueDateMonthsInterestRate: {
        required
      },
      terminableOn: {
        required
      },
      amortizations: {
        $each: {
          byDate: {
            required
          },
          amount: {
            required
          }
        }
      }
    }
    if (this.enableAmortizations) {
      validationMortgage.dueDateMonthsAmortization = {
        required
      }
      validationMortgage.amortisationAmount = {
        required
      }
    }
    return validationMortgage
  }
}
</script>

<style lang="scss" scoped>

</style>
