<template>
  <section class="w-100">
    <div
      :id="`tenancy-${index}`"
      v-b-toggle="`collapse-roommate-${index}`"
      class="pt-3 mb-0">
      <div class="row">
        <div class="col">
          <h5 class="cursor-pointer hover width-only-content">
            <span>
              {{ getRoommateName }}
            </span>
          </h5>
        </div>
      </div>
    </div>
    <b-collapse
      :id="`collapse-roommate-${index}`"
      :visible="expandByDefault && !readOnly">
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="firstName"
            :is-read-only="readOnly"
            :disabled="disabledField"
            class="mb-2"
            :name="$t('message.generic.firstName')" />
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="lastName"
            :is-read-only="readOnly"
            :disabled="disabledField"
            class="mb-2"
            :name="$t('message.generic.lastName')" />
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.birthday"
            :is-read-only="readOnly"
            :disabled="disabledField"
            :filter="'formatDate'"
            type="date"
            :name="$t('message.generic.birthday')"
            @blur="dateToObject()" />
        </div>
        <div
          v-if="!readOnly && !disabledField"
          class="col-md-1">
          <label
            for="delete"
            class="text-white d-block">delete</label>
          <coozzy-button
            design="alert"
            class="align-self-center"
            @click="openConfirmationModal()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </div>
    </b-collapse>
    <b-modal
      :id="'roommate-confirmationModal'"
      ref="roommate-confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.titleRoommate')">
      <template>
        <div class="col">
          <p>{{ $t('message.onBoarding.deleteModals.bodyRoommate') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('roommate-confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteRoommateClicked()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>
<script>
import { validation } from '@/mixins/validation'
import { dateUtils } from '@/mixins/dateUtils'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TenancyRoommate',
  mixins: [validation, dateUtils],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    roommate: {
      type: Object,
      default: null
    },
    tenancy: {
      type: Object,
      default: null
    },
    disabledField: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dates: {
        birthday: null
      }
    }
  },
  computed: {
    getRoommateName() {
      let names = ''
      names = (this.firstName ? this.firstName : '') + ' ' + (this.lastName ? this.lastName : '') + (this.birthday ? ', ' + this.$options.filters.objectToDate(this.birthday) : '')
      return names
    },
    firstName: {
      get() {
        return this.roommate.firstName !== '' ? this.roommate.firstName : null
      },
      set(value) {
        const newRoommate = this.roommate
        newRoommate.firstName = value
        this.$emit('change-roommate', newRoommate)
      }
    },
    lastName: {
      get() {
        return this.roommate.lastName !== '' ? this.roommate.lastName : null
      },
      set(value) {
        const newRoommate = this.roommate
        newRoommate.lastName = value
        this.$emit('change-roommate', newRoommate)
      }
    },
    birthday: {
      get() {
        return this.roommate.birthday !== -1 ? this.roommate.birthday : null
      },
      set(value) {
        const newRoommate = this.roommate
        newRoommate.birthday = value
        this.$emit('change-roommate', newRoommate)
      }
    }
  },
  mounted() {
    this.getDatesFromObjects()
  },
  methods: {
    dateToObject() {
      this.birthday = this.toObject(this.dates.birthday)
    },
    openConfirmationModal() {
      this.$refs['roommate-confirmationModal'].show()
    },
    getDatesFromObjects: function () {
      this.dates.birthday = this.$options.filters.objectToDateInput(this.birthday)
    },
    deleteRoommateClicked() {
      this.$emit('delete-roommate')
      this.$refs['roommate-confirmationModal'].hide()
    },
    isInvalid() {
      let valid = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        valid = false
      }
      return !valid
    }
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    }
  }
}
</script>
