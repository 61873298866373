/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** @default "UNKNOWN" */
export enum HealthCheckResponseServingStatus {
  UNKNOWN = "UNKNOWN",
  SERVING = "SERVING",
  NOT_SERVING = "NOT_SERVING",
}

export type CertifactionEnableDocumentSignatureResponse = object;

export interface CommoncertifactionDownloadFileResponse {
  /** @format byte */
  pdf: string;
}

export interface CommoncertifactionHasDocumentSignatureEnabledResponse {
  enabled: boolean;
}

/** @default "LEGAL_WEIGHT_STANDARD" */
export enum CommoncertifactionLegalWeight {
  LEGAL_WEIGHT_STANDARD = "LEGAL_WEIGHT_STANDARD",
  LEGAL_WEIGHT_PROFESSIONAL = "LEGAL_WEIGHT_PROFESSIONAL",
  LEGAL_WEIGHT_QUALIFIED = "LEGAL_WEIGHT_QUALIFIED",
}

export interface CommoncertifactionListSignRequestsResponse {
  signRequests: CommoncertifactionSignRequest[];
}

export interface CommoncertifactionSignDocumentResponse {
  signRequest: CommoncertifactionSignRequest;
}

export interface CommoncertifactionSignRequest {
  /** @format int64 */
  accountId: string;
  attachToReferenceIds: string[];
  cancelled: boolean;
  completed: boolean;
  /** @format int64 */
  created: string;
  id: string;
  mediaIds: string[];
  name: string;
  signers: CommoncertifactionSigner[];
}

export interface CommoncertifactionSigner {
  cancelled: boolean;
  contactId: string;
  legalWeight: CommoncertifactionLegalWeight;
  note: string;
  /** @format int32 */
  order: number;
  signed: boolean;
}

export interface CommoncertifactionSignerEntry {
  contactId: string;
  legalWeight: CommoncertifactionLegalWeight;
  note: string;
  /** @format int32 */
  order: number;
}

export interface CommondeepsignDownloadFileResponse {
  /** @format byte */
  pdf: string;
}

export interface CommondeepsignHasDocumentSignatureEnabledResponse {
  enabled: boolean;
}

/** @default "LEGAL_WEIGHT_STANDARD" */
export enum CommondeepsignLegalWeight {
  LEGAL_WEIGHT_STANDARD = "LEGAL_WEIGHT_STANDARD",
  LEGAL_WEIGHT_PROFESSIONAL = "LEGAL_WEIGHT_PROFESSIONAL",
  LEGAL_WEIGHT_QUALIFIED = "LEGAL_WEIGHT_QUALIFIED",
}

export interface CommondeepsignListSignRequestsResponse {
  signRequests: CommondeepsignSignRequest[];
}

export interface CommondeepsignSignDocumentRequest {
  /** @format int64 */
  accountId: string;
  attachToReferenceIds: string[];
  documentType: MediadocumentType;
  legalWeight: CommondeepsignLegalWeight;
  mediaIds: string[];
  name: string;
  note: string;
  signers: CommondeepsignSignerEntry[];
}

export interface CommondeepsignSignDocumentResponse {
  signRequest: CommondeepsignSignRequest;
}

export interface CommondeepsignSignRequest {
  attachToReferenceIds: string[];
  cancelled: boolean;
  cancelledMessage: string;
  completed: boolean;
  /** @format int64 */
  created: string;
  documentType: MediadocumentType;
  id: string;
  issuer: DeepsignIssuer;
  legalWeight: CommondeepsignLegalWeight;
  mediaIds: string[];
  name: string;
  note: string;
  signedMediaId: string;
  signers: CommondeepsignSigner[];
}

export interface CommondeepsignSigner {
  contactId: string;
  /** @format int32 */
  order: number;
  signed: boolean;
}

export interface CommondeepsignSignerEntry {
  contactId: string;
  /** @format int32 */
  order: number;
}

export interface CommonnoteCreateRequest {
  accountInternal: boolean;
  mediaIds: string[];
  mentions: NoteMention[];
  note: string;
  /** @format int64 */
  ownerId: string;
  referenceIds: string[];
}

export interface CommonnoteCreateResponse {
  note: NoteNote;
}

export interface CommonnoteCreator {
  /** @format int64 */
  creatorId: string;
  email: string;
  name: string;
  profilePictureUrl: string;
  userId: string;
}

export type CommonnoteDeleteResponse = object;

export interface CommonnoteListByReferenceIdsRequest {
  referenceIds: string[];
}

export interface CommonnoteListByReferenceIdsResponse {
  notes: NoteNote[];
}

export interface CommonnoteUpdateResponse {
  note: NoteNote;
}

export interface CredittrustGetCertificateResponse {
  /** @format byte */
  certificate: string;
}

export interface CredittrustIdentification {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfBirth: TypeDate;
  firstName: string;
  lastName: string;
}

export interface CredittrustOrderCertificateRequest {
  affiliateOrderId: string;
  city: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfBirth: TypeDate;
  email: string;
  firstName: string;
  houseNumber: string;
  lastName: string;
  locale: string;
  oldCity: string;
  oldHouseNumber: string;
  /** @format int32 */
  oldPostalCode: number;
  oldStreet: string;
  /** @format int32 */
  postalCode: number;
  referrerUrl: string;
  street: string;
  subAffiliate: string;
}

export interface CredittrustOrderCertificateResponse {
  certificateIssuable: boolean;
  crifApproval: boolean;
  crifMatch: boolean;
  intrumApproval: boolean;
  intrumMatch: boolean;
  orderId: string;
}

export interface CredittrustVerification {
  fileHash: string;
  issuerName: string;
  issuerVerified: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  registeredAt: TypeDate;
}

export interface CredittrustVerifyCertificateRequest {
  certificate: string;
}

export interface CredittrustVerifyCertificateResponse {
  identification: CredittrustIdentification;
  verification: CredittrustVerification;
}

export type DeepsignCancelSignRequestResponse = object;

export interface DeepsignCountOpenSignRequestsResponse {
  /** @format int32 */
  amount: number;
}

export interface DeepsignGetPartnerAccessUrlResponse {
  url: string;
}

export interface DeepsignIssuer {
  /** @format int64 */
  accountId: string;
  userId: string;
}

export interface DeepsignListSignRequestsByAttachToReferenceIdsRequest {
  referenceIds: string[];
}

export interface DeepsignListSignRequestsByAttachToReferenceIdsResponse {
  signRequests: CommondeepsignSignRequest[];
}

export interface GooglerpcStatus {
  /** @format int32 */
  code: number;
  details: ProtobufAny[];
  message: string;
}

/** @default "PREFERRED_LANGUAGE_UNDEFINED" */
export enum LanguagePreferredLanguage {
  PREFERRED_LANGUAGE_UNDEFINED = "PREFERRED_LANGUAGE_UNDEFINED",
  PREFERRED_LANGUAGE_GERMAN = "PREFERRED_LANGUAGE_GERMAN",
  PREFERRED_LANGUAGE_ENGLISH = "PREFERRED_LANGUAGE_ENGLISH",
  PREFERRED_LANGUAGE_ITALIAN = "PREFERRED_LANGUAGE_ITALIAN",
  PREFERRED_LANGUAGE_FRENCH = "PREFERRED_LANGUAGE_FRENCH",
}

/** @default "OTHER" */
export enum MediadocumentType {
  OTHER = "OTHER",
  APPLICATION_FORM = "APPLICATION_FORM",
  HANDOVER_PROTOCOL = "HANDOVER_PROTOCOL",
  CONTRACT = "CONTRACT",
  BUILDING_PLAN = "BUILDING_PLAN",
  INVOICE = "INVOICE",
  HOUSE_REGULATION = "HOUSE_REGULATION",
  INSTRUCTION_MANUAL = "INSTRUCTION_MANUAL",
  CREDIT_CHECK = "CREDIT_CHECK",
  OWNERS_MEETING = "OWNERS_MEETING",
  PAY_IN_SLIP = "PAY_IN_SLIP",
  ENERGY_CERTIFICATE = "ENERGY_CERTIFICATE",
  WARRANTY_CERTIFICATE = "WARRANTY_CERTIFICATE",
  BANK_STATEMENT = "BANK_STATEMENT",
  ADDITIONAL_COSTS_BILL = "ADDITIONAL_COSTS_BILL",
  SEPA_DIRECT_DEBIT_MANDATE = "SEPA_DIRECT_DEBIT_MANDATE",
  AGREEMENT = "AGREEMENT",
  DEPOSIT_CONFIRMATION = "DEPOSIT_CONFIRMATION",
  BUILDING_INSURANCE_POLICY = "BUILDING_INSURANCE_POLICY",
  KEY_LIST = "KEY_LIST",
  TERMINATION_DOC_TYPE = "TERMINATION_DOC_TYPE",
  RENT_ADJUSTMENT_DOC_TYPE = "RENT_ADJUSTMENT_DOC_TYPE",
  REFERENCES_DOC_TYPE = "REFERENCES_DOC_TYPE",
  MAINTENANCE_CONTRACT_DOC_TYPE = "MAINTENANCE_CONTRACT_DOC_TYPE",
  REGULATIONS = "REGULATIONS",
  REGULATIONS_FOR_USE_AND_MANAGEMENT = "REGULATIONS_FOR_USE_AND_MANAGEMENT",
  ANNUAL_FINANCIAL_STATEMENT = "ANNUAL_FINANCIAL_STATEMENT",
  PURCHASE_CONTRACT_AND_LAND_REGISTER = "PURCHASE_CONTRACT_AND_LAND_REGISTER",
  CONDOMINIUM_OWNERSHIP = "CONDOMINIUM_OWNERSHIP",
  RENEWAL_FOND = "RENEWAL_FOND",
  MORTGAGE = "MORTGAGE",
  ORDER = "ORDER",
  OFFER = "OFFER",
  IDENTITY = "IDENTITY",
  EXTRACT_FROM_DEBT_COLLECTION_REGISTER = "EXTRACT_FROM_DEBT_COLLECTION_REGISTER",
  BANK_ACCOUNT_DETAILS = "BANK_ACCOUNT_DETAILS",
  PURCHASE_CONTRACT = "PURCHASE_CONTRACT",
  LAND_REGISTER = "LAND_REGISTER",
  SITUATION_PLAN = "SITUATION_PLAN",
  CONSTRUCTION_LAW_AGREEMENT = "CONSTRUCTION_LAW_AGREEMENT",
  EASEMENT_AGREEMENT = "EASEMENT_AGREEMENT",
  CADASTRAL_ESTIMATE = "CADASTRAL_ESTIMATE",
  LETTER = "LETTER",
  TRADE_REGISTER_EXCERPT = "TRADE_REGISTER_EXCERPT",
  PAYMENT_REMINDER = "PAYMENT_REMINDER",
  PROTOCOL = "PROTOCOL",
  COURT_DOC_TYPE = "COURT_DOC_TYPE",
  HONORARIUM = "HONORARIUM",
  BUDGET = "BUDGET",
}

export interface NoteCreateFromMailRequest {
  accountInternal: boolean;
  mailId: string;
  /** @format int64 */
  ownerId: string;
  referenceIds: string[];
}

export interface NoteCreateFromMailResponse {
  note: NoteNote;
}

export interface NoteCreateUnregisteredRequest {
  email: string;
  mediaIds: string[];
  name: string;
  note: string;
  referenceIds: string[];
}

export interface NoteCreateUnregisteredResponse {
  note: NoteNote;
}

export interface NoteMention {
  /** @format int64 */
  accountId: string;
  userId: string;
}

export interface NoteNote {
  accountInternal: boolean;
  /** @format int64 */
  createdAt: string;
  creator: CommonnoteCreator;
  historyEntries: NoteNoteHistoryEntry[];
  id: string;
  mediaIds: string[];
  mentions: NoteMention[];
  note: string;
  /** @format int64 */
  ownerId: string;
  referenceIds: string[];
  /** @format int64 */
  updatedAt: string;
}

export interface NoteNoteHistoryEntry {
  /** @format int64 */
  editedAt: string;
  previousMediaIds: string[];
  previousNote: string;
}

export interface PolicyGetPolicyDataResponse {
  bindings: string;
}

export interface ProtobufAny {
  "@type": string;
  [key: string]: any;
}

/**
 * Represents a whole or partial calendar date, such as a birthday. The time of
 * day and time zone are either specified elsewhere or are insignificant. The
 * date is relative to the Gregorian Calendar. This can represent one of the
 * following:
 * * A full date, with non-zero year, month, and day values
 * * A month and day value, with a zero year, such as an anniversary
 * * A year on its own, with zero month and day values
 * * A year and month value, with a zero day, such as a credit card expiration
 * date
 *
 * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
 * `google.protobuf.Timestamp`.
 */
export interface TypeDate {
  /**
   * Day of a month. Must be from 1 to 31 and valid for the year and month, or 0
   * to specify a year by itself or a year and month where the day isn't
   * significant.
   * @format int32
   */
  day: number;
  /**
   * Month of a year. Must be from 1 to 12, or 0 to specify a year without a
   * month and day.
   * @format int32
   */
  month: number;
  /**
   * Year of the date. Must be from 1 to 9999, or 0 to specify a date without
   * a year.
   * @format int32
   */
  year: number;
}

export interface V1HealthCheckResponse {
  status: HealthCheckResponseServingStatus;
}

export interface ZipGetInfoResponse {
  entries: ZipZipEntry[];
}

export interface ZipZipEntry {
  city: string;
  state: string;
}

export interface Activityv2Activity {
  contactIds: string[];
  documentIds: string[];
  employeeContactId: string;
  id: string;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  referenceIds: string[];
  subject: string;
  text: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  timestamp: string;
}

export interface Activityv2ListByContactIdsRequest {
  contactIds: string[];
}

export interface Activityv2ListByContactIdsResponse {
  activities: Activityv2Activity[];
}

export interface Activityv2ListByReferenceIdsRequest {
  referenceIds: string[];
}

export interface Activityv2ListByReferenceIdsResponse {
  activities: Activityv2Activity[];
}

export interface Activityv3Activity {
  creator: Activityv3Creator;
  employeeContactIds: string[];
  hasEndTime: boolean;
  hasStartTime: boolean;
  id: string;
  mediaIds: string[];
  /** @format int64 */
  ownerId: string;
  references: V3ActivityReference[];
  source: V3Source;
  subject: string;
  text: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  timestampEnd: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  timestampStart: string;
}

export interface Activityv3CreateRequest {
  employeeContactIds: string[];
  hasEndTime: boolean;
  hasStartTime: boolean;
  mediaIds: string[];
  /** @format int64 */
  ownerId: string;
  references: V3ActivityReference[];
  source: V3Source;
  subject: string;
  text: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  timestampEnd: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  timestampStart: string;
}

export interface Activityv3CreateResponse {
  activity: Activityv3Activity;
}

export interface Activityv3Creator {
  /** @format int64 */
  accountId: string;
  accountLogoUrl: string;
  accountName: string;
  name: string;
  profilePictureUrl: string;
  userId: string;
}

export type Activityv3DeleteResponse = object;

export interface Activityv3GetResponse {
  activity: Activityv3Activity;
}

export interface Activityv3ListByReferenceIdsRequest {
  referenceIds: string[];
}

export interface Activityv3ListByReferenceIdsResponse {
  activities: Activityv3Activity[];
}

export type Activityv3UpdateResponse = object;

export interface AddressGeo {
  /** @format float */
  elevation?: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface CommonaddressAddress {
  careOf: string;
  city: string;
  country: string;
  geo: AddressGeo;
  mailbox: string;
  state: string;
  street: string;
  streetAddition: string;
  streetNumber: string;
  subUnit: string;
  zip: string;
}

export interface ContactCreateContactResponse {
  contact: ContactcontactContact;
}

export type ContactDeleteContactResponse = object;

export interface ContactExportCSVResponse {
  csv: string;
}

export interface ContactGetSyncTokenResponse {
  token: string;
}

export interface ContactGetVCardsResponse {
  vCards: string;
}

export interface ContactImportVCardsResponse {
  contacts: ContactcontactContact[];
}

export interface ContactListClientsResponse {
  contacts: ContactcontactContact[];
}

export interface ContactListContactsResponse {
  contacts: ContactcontactContact[];
}

export interface ContactUpdateContactResponse {
  contact: ContactcontactContact;
}

export interface Contactactivityv2CreateRequest {
  contactIds: string[];
  documentIds: string[];
  employeeContactId: string;
  referenceIds: string[];
  subject: string;
  text: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  timestamp: string;
}

export interface Contactactivityv2CreateResponse {
  activity: Activityv2Activity;
}

export type Contactactivityv2DeleteResponse = object;

export interface Contactactivityv2GetResponse {
  activity: Activityv2Activity;
}

export type Contactactivityv2UpdateResponse = object;

export interface ContactcommonContactReference {
  /** @format int64 */
  accountId: string;
  name: string;
  userReference: string;
}

export interface ContactcontactAddress {
  city: string;
  /** country name, in the language defined by Contact.language */
  country: string;
  state: string;
  street: string;
  zip: string;
}

export interface ContactcontactContact {
  /**
   * Optional account ID, e.g. 2
   * @format int64
   */
  accountId: string;
  address: ContactcontactAddress;
  companyName: string;
  companyPosition: string;
  deleted: boolean;
  /**
   * UNIX timestamp in seconds of date of birth
   * @format int64
   */
  dob: string;
  email: string;
  firstName: string;
  gender: ContactcontactGender;
  /** Unique ID of a contact */
  id: string;
  /** IETF BCP 47 language tag */
  language: string;
  lastName: string;
  /** Full name: firstName + lastName. Can also be set if these two are not set */
  name: string;
  /**
   * the account to which this contact belongs
   * @format int64
   */
  ownerAccountId: string;
  /** Stores main phone number */
  phoneNumber: string;
  /** Stores all phone numbers, including the main phone number */
  phoneNumbers: ContactcontactPhoneNumber[];
  /** Place of birth */
  pob: string;
  type: ContactcontactType[];
  /** User ID, e.g. auth0|1 or foo@example.com */
  userReference: string;
}

/** @default "UNDEFINED_GENDER" */
export enum ContactcontactGender {
  UNDEFINED_GENDER = "UNDEFINED_GENDER",
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface ContactcontactPhoneNumber {
  number: string;
  type: ContactcontactPhoneNumberType;
}

/** @default "PHONE_NUMBER_TYPE_OTHER" */
export enum ContactcontactPhoneNumberType {
  PHONE_NUMBER_TYPE_OTHER = "PHONE_NUMBER_TYPE_OTHER",
  HOME = "HOME",
  WORK = "WORK",
  MOBILE = "MOBILE",
  FAX = "FAX",
}

export interface ContactcontactResolveByMailResponse {
  contacts: ContactcontactContact[];
}

export interface ContactcontactResolveResponse {
  contacts: ContactcontactContact[];
}

/** @default "NONE" */
export enum ContactcontactType {
  NONE = "NONE",
  LANDLORD = "LANDLORD",
  CLIENT = "CLIENT",
  TENANT = "TENANT",
  EMPLOYEE = "EMPLOYEE",
  CARETAKER = "CARETAKER",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  INTERESTED_PARTY = "INTERESTED_PARTY",
  BANK = "BANK",
  INSURANCE = "INSURANCE",
  REAL_ESTATE_MANAGER = "REAL_ESTATE_MANAGER",
}

/** @default "UNDEFINED_CIVIL_STATUS" */
export enum Contactcontactv2CivilStatus {
  UNDEFINED_CIVIL_STATUS = "UNDEFINED_CIVIL_STATUS",
  CIVIL_STATUS_SINGLE = "CIVIL_STATUS_SINGLE",
  CIVIL_STATUS_MARRIED = "CIVIL_STATUS_MARRIED",
  CIVIL_STATUS_DIVORCED = "CIVIL_STATUS_DIVORCED",
  CIVIL_STATUS_WIDOWED = "CIVIL_STATUS_WIDOWED",
  CIVIL_STATUS_SEPARATED = "CIVIL_STATUS_SEPARATED",
  CIVIL_STATUS_REGISTERED_PARTNERSHIP = "CIVIL_STATUS_REGISTERED_PARTNERSHIP",
  CIVIL_STATUS_DISSOLVED_PARTNERSHIP = "CIVIL_STATUS_DISSOLVED_PARTNERSHIP",
}

export interface Contactcontactv2Company {
  active: boolean;
  address: CommonaddressAddress;
  addressHistory: V2AddressEntry[];
  condominiumIds: string[];
  contactPersons: Contactcontactv2ContactPerson[];
  documentIds: string[];
  /** official contact email of the company, not the email of the responsible person (e.g. info@company.com) */
  email: string;
  iban: string;
  id: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  inactiveAt: TypeDate;
  includeInSerialEmails: boolean;
  industries: IndustryIndustry[];
  name: string;
  note: string;
  /** @format int64 */
  numericId: string;
  /** @format int64 */
  ownerId: string;
  phoneNumbers: Contactv2PhoneNumber[];
  portalAccess: boolean;
  preferredCommunicationChannel: V2PreferredCommunicationChannel;
  preferredLanguage: LanguagePreferredLanguage;
  relations: V2Relation[];
  salutationName: string;
  tags: string[];
  tenancyIds: string[];
  types: Contactcontactv2Type[];
  /**
   * UNIX timestamp in seconds when company was modified the last time
   * @format int64
   */
  updatedAt: string;
  vatNumber: string;
  website: string;
}

export interface Contactcontactv2ContactPerson {
  active: boolean;
  address: CommonaddressAddress;
  birthName: string;
  birthplace: string;
  civilStatus: Contactcontactv2CivilStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfBirth: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfDeath: TypeDate;
  email: string;
  employment: Contactcontactv2Employment;
  firstName: string;
  gender: Contactcontactv2Gender;
  id: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  inactiveAt: TypeDate;
  includeInSerialEmails: boolean;
  lastName: string;
  /**
   * name is the full name of the person. It can be the only field set if no firstName+lastName separation is possible.
   * If 'firstName' and 'lastName' are set and 'name' is not, name is set to: firstName + ' ' + lastName
   */
  name: string;
  nationality: string;
  note: string;
  /** @format int64 */
  numericId: string;
  phoneNumbers: Contactv2PhoneNumber[];
  portalAccess: boolean;
  preferredLanguage: LanguagePreferredLanguage;
  /**
   * ResidenceStatus provides an enumeration for the different swiss residence status a person can have.
   *
   *  - RESIDENCE_STATUS_B_STAY: B - Aufenthaltsbewilligung
   *  - RESIDENCE_STATUS_C_SETTLE: C - Niederlassungsbewilligung
   *  - RESIDENCE_STATUS_CI_STAY_EMPLOYMENT: Ci - Aufenthaltsbewilligung mit Erwerbstätigkeit
   *  - RESIDENCE_STATUS_G_BORDER_CROSSER: G - Grenzgängerbewilligung
   *  - RESIDENCE_STATUS_L_SHORT_STAY: L -  Kurzaufenthaltsbewilligung
   *  - RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER: F - Vorläufig aufgenommene Ausländer
   *  - RESIDENCE_STATUS_N_SEEKING_ASYLUM: N - Asylsuchender
   *  - RESIDENCE_STATUS_S_PROTECTION_NEEDED: S - Schutzbedürftige
   */
  residenceStatus: Contactcontactv2ResidenceStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  residenceStatusValidUntil: TypeDate;
  salutationName: string;
  title: string;
}

export type Contactcontactv2DeleteResponse = object;

export interface Contactcontactv2Employment {
  companyName: string;
  companyPosition: string;
}

/** @default "UNDEFINED_GENDER" */
export enum Contactcontactv2Gender {
  UNDEFINED_GENDER = "UNDEFINED_GENDER",
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface Contactcontactv2ListEmployeesResponse {
  persons: V2Person[];
}

export interface Contactcontactv2ListResponse {
  companies: Contactcontactv2Company[];
  persons: V2Person[];
}

/**
 * ResidenceStatus provides an enumeration for the different swiss residence status a person can have.
 *
 *  - RESIDENCE_STATUS_B_STAY: B - Aufenthaltsbewilligung
 *  - RESIDENCE_STATUS_C_SETTLE: C - Niederlassungsbewilligung
 *  - RESIDENCE_STATUS_CI_STAY_EMPLOYMENT: Ci - Aufenthaltsbewilligung mit Erwerbstätigkeit
 *  - RESIDENCE_STATUS_G_BORDER_CROSSER: G - Grenzgängerbewilligung
 *  - RESIDENCE_STATUS_L_SHORT_STAY: L -  Kurzaufenthaltsbewilligung
 *  - RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER: F - Vorläufig aufgenommene Ausländer
 *  - RESIDENCE_STATUS_N_SEEKING_ASYLUM: N - Asylsuchender
 *  - RESIDENCE_STATUS_S_PROTECTION_NEEDED: S - Schutzbedürftige
 * @default "UNDEFINED_RESIDENCE_STATUS"
 */
export enum Contactcontactv2ResidenceStatus {
  UNDEFINED_RESIDENCE_STATUS = "UNDEFINED_RESIDENCE_STATUS",
  RESIDENCE_STATUS_B_STAY = "RESIDENCE_STATUS_B_STAY",
  RESIDENCE_STATUS_C_SETTLE = "RESIDENCE_STATUS_C_SETTLE",
  RESIDENCE_STATUS_CI_STAY_EMPLOYMENT = "RESIDENCE_STATUS_CI_STAY_EMPLOYMENT",
  RESIDENCE_STATUS_G_BORDER_CROSSER = "RESIDENCE_STATUS_G_BORDER_CROSSER",
  RESIDENCE_STATUS_L_SHORT_STAY = "RESIDENCE_STATUS_L_SHORT_STAY",
  RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER = "RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER",
  RESIDENCE_STATUS_N_SEEKING_ASYLUM = "RESIDENCE_STATUS_N_SEEKING_ASYLUM",
  RESIDENCE_STATUS_S_PROTECTION_NEEDED = "RESIDENCE_STATUS_S_PROTECTION_NEEDED",
}

/**
 * Type defines the general contact-type for individuals, not companies.
 * A contact can have multiple types.
 * @default "NONE"
 */
export enum Contactcontactv2Type {
  NONE = "NONE",
  LANDLORD = "LANDLORD",
  CLIENT = "CLIENT",
  TENANT = "TENANT",
  EMPLOYEE = "EMPLOYEE",
  CARETAKER = "CARETAKER",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  INTERESTED_PARTY = "INTERESTED_PARTY",
  BANK = "BANK",
  INSURANCE = "INSURANCE",
  REAL_ESTATE_MANAGER = "REAL_ESTATE_MANAGER",
  CRAFTSMAN = "CRAFTSMAN",
  AUTHORITY = "AUTHORITY",
  ACCOUNTANT = "ACCOUNTANT",
  MARKETER = "MARKETER",
  CONDOMINIUM_OWNER = "CONDOMINIUM_OWNER",
  SUB_TENANT = "SUB_TENANT",
  REPRESENTATION = "REPRESENTATION",
  COOPERATIVE = "COOPERATIVE",
  SALE = "SALE",
  EXTERNAL_DEBTOR = "EXTERNAL_DEBTOR",
  GUARANTOR = "GUARANTOR",
  REVISOR = "REVISOR",
  DELEGATE = "DELEGATE",
}

export interface Contactcontactv2UpdateCompanyResponse {
  company: Contactcontactv2Company;
}

export interface Contactcontactv2UpdateContactPersonResponse {
  contactPerson: Contactcontactv2ContactPerson;
}

/** No response, CsvDecoded event is emitted */
export type ContactcsvDecodeResponse = object;

export interface ContactcsvEncodeResponse {
  csv: string;
}

export interface Contactv2ActivateRequest {
  ids: string[];
}

export type Contactv2ActivateResponse = object;

export type Contactv2AddDocumentsResponse = object;

export interface Contactv2DeactivateRequest {
  ids: string[];
}

export type Contactv2DeactivateResponse = object;

export interface Contactv2GetUsedTagsResponse {
  tags: string[];
}

/** PhoneNumber stores phoneNumbers */
export interface Contactv2PhoneNumber {
  number: string;
  type: Contactv2PhoneNumberType;
}

/** @default "PHONE_NUMBER_TYPE_OTHER" */
export enum Contactv2PhoneNumberType {
  PHONE_NUMBER_TYPE_OTHER = "PHONE_NUMBER_TYPE_OTHER",
  HOME = "HOME",
  WORK = "WORK",
  MOBILE = "MOBILE",
  FAX = "FAX",
}

export interface Contactv2PublishUpdatedEventsRequest {
  ownerIds: string[];
}

export type Contactv2PublishUpdatedEventsResponse = object;

export interface Contactv2ResolveByMailResponse {
  companies: Contactcontactv2Company[];
  persons: V2Person[];
}

export interface Contactv2ResolveRequest {
  contactIds: string[];
}

export interface Contactv2ResolveResponse {
  companies: Contactcontactv2Company[];
  persons: V2Person[];
}

export interface ContactvcardDecodeResponse {
  contacts: ContactcontactContact[];
}

export interface ContactvcardEncodeResponse {
  vcards: string;
}

/** @default "INDUSTRY_UNDEFINED_VALUE" */
export enum IndustryIndustry {
  INDUSTRY_UNDEFINED_VALUE = "INDUSTRY_UNDEFINED_VALUE",
  INDUSTRY_LIVING_FURNISHING_HOUSEHOLD_APPLIANCES = "INDUSTRY_LIVING_FURNISHING_HOUSEHOLD_APPLIANCES",
  INDUSTRY_LIVING_FURNISHING_MAINTENANCE = "INDUSTRY_LIVING_FURNISHING_MAINTENANCE",
  INDUSTRY_LIVING_FURNISHING_REAL_ESTATE = "INDUSTRY_LIVING_FURNISHING_REAL_ESTATE",
  INDUSTRY_LIVING_FURNISHING_INTERIOR_DESIGN = "INDUSTRY_LIVING_FURNISHING_INTERIOR_DESIGN",
  INDUSTRY_LIVING_FURNISHING_INTERIOR_DECORATION = "INDUSTRY_LIVING_FURNISHING_INTERIOR_DECORATION",
  INDUSTRY_LIVING_FURNISHING_KITCHEN_FACILITIES = "INDUSTRY_LIVING_FURNISHING_KITCHEN_FACILITIES",
  INDUSTRY_LIVING_FURNISHING_REAL_ESTATE_TRADING = "INDUSTRY_LIVING_FURNISHING_REAL_ESTATE_TRADING",
  INDUSTRY_LIVING_FURNISHING_REAL_ESTATE_MANAGEMENT = "INDUSTRY_LIVING_FURNISHING_REAL_ESTATE_MANAGEMENT",
  INDUSTRY_LIVING_FURNISHING_FURNITURE = "INDUSTRY_LIVING_FURNISHING_FURNITURE",
  INDUSTRY_LIVING_FURNISHING_CLEANING = "INDUSTRY_LIVING_FURNISHING_CLEANING",
  INDUSTRY_LIVING_FURNISHING_KEY_SERVICE = "INDUSTRY_LIVING_FURNISHING_KEY_SERVICE",
  INDUSTRY_LIVING_FURNISHING_BLINDS_AND_SHUTTERS = "INDUSTRY_LIVING_FURNISHING_BLINDS_AND_SHUTTERS",
  INDUSTRY_LIVING_FURNISHING_CARPETS = "INDUSTRY_LIVING_FURNISHING_CARPETS",
  INDUSTRY_LIVING_FURNISHING_MOVING = "INDUSTRY_LIVING_FURNISHING_MOVING",
  INDUSTRY_LIVING_FURNISHING_CURTAINS = "INDUSTRY_LIVING_FURNISHING_CURTAINS",
  INDUSTRY_LIVING_FURNISHING_OTHER = "INDUSTRY_LIVING_FURNISHING_OTHER",
  INDUSTRY_BUILD_RENOVATE_ARCHITECTURE = "INDUSTRY_BUILD_RENOVATE_ARCHITECTURE",
  INDUSTRY_BUILD_RENOVATE_CONSTRUCTION = "INDUSTRY_BUILD_RENOVATE_CONSTRUCTION",
  INDUSTRY_BUILD_RENOVATE_ROOFING = "INDUSTRY_BUILD_RENOVATE_ROOFING",
  INDUSTRY_BUILD_RENOVATE_FLOORING_WALL_COVERINGS = "INDUSTRY_BUILD_RENOVATE_FLOORING_WALL_COVERINGS",
  INDUSTRY_BUILD_RENOVATE_PLASTERER = "INDUSTRY_BUILD_RENOVATE_PLASTERER",
  INDUSTRY_BUILD_RENOVATE_HEATING = "INDUSTRY_BUILD_RENOVATE_HEATING",
  INDUSTRY_BUILD_RENOVATE_WOOD_CONSTRUCTION = "INDUSTRY_BUILD_RENOVATE_WOOD_CONSTRUCTION",
  INDUSTRY_BUILD_RENOVATE_ENGINEERING = "INDUSTRY_BUILD_RENOVATE_ENGINEERING",
  INDUSTRY_BUILD_RENOVATE_PAINTER = "INDUSTRY_BUILD_RENOVATE_PAINTER",
  INDUSTRY_BUILD_RENOVATE_METAL_CONSTRUCTION = "INDUSTRY_BUILD_RENOVATE_METAL_CONSTRUCTION",
  INDUSTRY_BUILD_RENOVATE_SANITARY = "INDUSTRY_BUILD_RENOVATE_SANITARY",
  INDUSTRY_BUILD_RENOVATE_LOCKSMITH = "INDUSTRY_BUILD_RENOVATE_LOCKSMITH",
  INDUSTRY_BUILD_RENOVATE_JOINERY = "INDUSTRY_BUILD_RENOVATE_JOINERY",
  INDUSTRY_BUILD_RENOVATE_PLUMBING = "INDUSTRY_BUILD_RENOVATE_PLUMBING",
  INDUSTRY_BUILD_RENOVATE_CARPENTRY = "INDUSTRY_BUILD_RENOVATE_CARPENTRY",
  INDUSTRY_BUILD_RENOVATE_ELECTRICIAN = "INDUSTRY_BUILD_RENOVATE_ELECTRICIAN",
  INDUSTRY_BUILD_RENOVATE_CRAFTSMAN = "INDUSTRY_BUILD_RENOVATE_CRAFTSMAN",
  INDUSTRY_BUILD_RENOVATE_OTHER = "INDUSTRY_BUILD_RENOVATE_OTHER",
  INDUSTRY_FINANCIAL_LAW_ADVOCACY = "INDUSTRY_FINANCIAL_LAW_ADVOCACY",
  INDUSTRY_FINANCIAL_LAW_BANK = "INDUSTRY_FINANCIAL_LAW_BANK",
  INDUSTRY_FINANCIAL_LAW_ACCOUNTING = "INDUSTRY_FINANCIAL_LAW_ACCOUNTING",
  INDUSTRY_FINANCIAL_LAW_FINANCIAL_CONSULTING = "INDUSTRY_FINANCIAL_LAW_FINANCIAL_CONSULTING",
  INDUSTRY_FINANCIAL_LAW_NOTARY = "INDUSTRY_FINANCIAL_LAW_NOTARY",
  INDUSTRY_FINANCIAL_LAW_LAWYER = "INDUSTRY_FINANCIAL_LAW_LAWYER",
  INDUSTRY_FINANCIAL_LAW_TAX_CONSULTING = "INDUSTRY_FINANCIAL_LAW_TAX_CONSULTING",
  INDUSTRY_FINANCIAL_LAW_TRUSTEESHIP = "INDUSTRY_FINANCIAL_LAW_TRUSTEESHIP",
  INDUSTRY_FINANCIAL_LAW_ASSET_MANAGEMENT = "INDUSTRY_FINANCIAL_LAW_ASSET_MANAGEMENT",
  INDUSTRY_FINANCIAL_LAW_INSURANCE = "INDUSTRY_FINANCIAL_LAW_INSURANCE",
  INDUSTRY_FINANCIAL_LAW_OTHER = "INDUSTRY_FINANCIAL_LAW_OTHER",
  INDUSTRY_GARDEN_PLANTS_FLORIST = "INDUSTRY_GARDEN_PLANTS_FLORIST",
  INDUSTRY_GARDEN_PLANTS_HORTICULTURE = "INDUSTRY_GARDEN_PLANTS_HORTICULTURE",
  INDUSTRY_GARDEN_PLANTS_GARDEN_MAINTENANCE = "INDUSTRY_GARDEN_PLANTS_GARDEN_MAINTENANCE",
  INDUSTRY_GARDEN_PLANTS_NURSERY = "INDUSTRY_GARDEN_PLANTS_NURSERY",
  INDUSTRY_GARDEN_PLANTS_DUMPSTER_SERVICE = "INDUSTRY_GARDEN_PLANTS_DUMPSTER_SERVICE",
  INDUSTRY_GARDEN_PLANTS_OTHER = "INDUSTRY_GARDEN_PLANTS_OTHER",
  INDUSTRY_OTHER_METER_READING = "INDUSTRY_OTHER_METER_READING",
  INDUSTRY_OTHER_FACILITY_MANAGEMENT = "INDUSTRY_OTHER_FACILITY_MANAGEMENT",
  INDUSTRY_OTHER_MAINTENANCE_INSTALLATION = "INDUSTRY_OTHER_MAINTENANCE_INSTALLATION",
  INDUSTRY_OTHER_BUILDING_SERVICES_ENGINEERING = "INDUSTRY_OTHER_BUILDING_SERVICES_ENGINEERING",
  INDUSTRY_OTHER_KEY_SERVICE = "INDUSTRY_OTHER_KEY_SERVICE",
  INDUSTRY_OTHER_CLEANING = "INDUSTRY_OTHER_CLEANING",
  INDUSTRY_OTHER_WHITE_GOODS = "INDUSTRY_OTHER_WHITE_GOODS",
  INDUSTRY_OTHER_OTHER = "INDUSTRY_OTHER_OTHER",
  INDUSTRY_OTHER_FIRE_PROTECTION = "INDUSTRY_OTHER_FIRE_PROTECTION",
  INDUSTRY_BUILD_RENOVATE_FACADES = "INDUSTRY_BUILD_RENOVATE_FACADES",
  INDUSTRY_OTHER_ELECTRICITY_PLANT = "INDUSTRY_OTHER_ELECTRICITY_PLANT",
  INDUSTRY_OTHER_WATERWORKS = "INDUSTRY_OTHER_WATERWORKS",
  INDUSTRY_OTHER_SWEEPINGS = "INDUSTRY_OTHER_SWEEPINGS",
  INDUSTRY_OTHER_ANTENNA_SYSTEM = "INDUSTRY_OTHER_ANTENNA_SYSTEM",
  INDUSTRY_OTHER_PEST_CONTROL = "INDUSTRY_OTHER_PEST_CONTROL",
  INDUSTRY_FINANCIAL_LAW_PUBLIC_SECTOR = "INDUSTRY_FINANCIAL_LAW_PUBLIC_SECTOR",
  INDUSTRY_BUILD_RENOVATE_DISTRICT_HEATING = "INDUSTRY_BUILD_RENOVATE_DISTRICT_HEATING",
  INDUSTRY_BUILD_RENOVATE_AIR_CONDITIONING = "INDUSTRY_BUILD_RENOVATE_AIR_CONDITIONING",
  INDUSTRY_BUILD_RENOVATE_GARAGE_GATES = "INDUSTRY_BUILD_RENOVATE_GARAGE_GATES",
  INDUSTRY_BUILD_RENOVATE_MOLD_CONTROL = "INDUSTRY_BUILD_RENOVATE_MOLD_CONTROL",
  INDUSTRY_OTHER_MARKINGS = "INDUSTRY_OTHER_MARKINGS",
  INDUSTRY_BUILD_RENOVATE_JOINTS = "INDUSTRY_BUILD_RENOVATE_JOINTS",
  INDUSTRY_OTHER_OIL = "INDUSTRY_OTHER_OIL",
  INDUSTRY_BUILD_RENOVATE_LIFT_SERVICE = "INDUSTRY_BUILD_RENOVATE_LIFT_SERVICE",
  INDUSTRY_OTHER_WATER_TREATMENT = "INDUSTRY_OTHER_WATER_TREATMENT",
  INDUSTRY_OTHER_ADVERTISING = "INDUSTRY_OTHER_ADVERTISING",
  INDUSTRY_OTHER_GROCERIES = "INDUSTRY_OTHER_GROCERIES",
  INDUSTRY_OTHER_CAR_DEALERSHIP = "INDUSTRY_OTHER_CAR_DEALERSHIP",
  INDUSTRY_OTHER_STATIONERY_SHOP = "INDUSTRY_OTHER_STATIONERY_SHOP",
  INDUSTRY_BUILD_GLAZIER = "INDUSTRY_BUILD_GLAZIER",
  INDUSTRY_BUILD_SCAFFOLDER = "INDUSTRY_BUILD_SCAFFOLDER",
  INDUSTRY_OTHER_CHIMNEY_SWEEPER = "INDUSTRY_OTHER_CHIMNEY_SWEEPER",
  INDUSTRY_OTHER_ENGRAVING = "INDUSTRY_OTHER_ENGRAVING",
  INDUSTRY_BUILD_MASONRY = "INDUSTRY_BUILD_MASONRY",
}

export interface V2AddContactPersonResponse {
  contactPerson: Contactcontactv2ContactPerson;
}

export interface V2AdditionalEmail {
  email: string;
  use: boolean;
}

export interface V2AddressEntry {
  address: CommonaddressAddress;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  validFrom: TypeDate;
}

export interface V2CheckDuplicatesRequest {
  emails: string[];
  firstName: string;
  isCompany: boolean;
  /** Either company name if isCompany=true, otherwise lastName of person. */
  name: string;
  /** @format int64 */
  ownerId: string;
  phoneNumbers: string[];
}

export interface V2CheckDuplicatesResponse {
  companies: Contactcontactv2Company[];
  persons: V2Person[];
}

export interface V2CopyContactsRequest {
  /** @format int64 */
  sourceAccountId: string;
  /** @format int64 */
  targetTargetId: string;
}

export type V2CopyContactsResponse = object;

export interface V2CreateCompanyRequest {
  address: CommonaddressAddress;
  addressHistory: V2AddressEntry[];
  documentIds: string[];
  /** official contact email of the company, not the email of the responsible person (e.g. info@company.com) */
  email: string;
  iban: string;
  includeInSerialEmails: boolean;
  industries: IndustryIndustry[];
  name: string;
  note: string;
  /** @format int64 */
  numericId: string;
  /** @format int64 */
  ownerId: string;
  phoneNumbers: Contactv2PhoneNumber[];
  portalAccess: boolean;
  preferredCommunicationChannel: V2PreferredCommunicationChannel;
  preferredLanguage: LanguagePreferredLanguage;
  salutationName: string;
  tags: string[];
  types: Contactcontactv2Type[];
  vatNumber: string;
  website: string;
}

export interface V2CreateCompanyResponse {
  company: Contactcontactv2Company;
}

export interface V2CreatePersonRequest {
  address: CommonaddressAddress;
  addressHistory: V2AddressEntry[];
  ahv: string;
  birthName: string;
  birthplace: string;
  civilStatus: Contactcontactv2CivilStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfBirth: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfDeath: TypeDate;
  documentIds: string[];
  email: string;
  employment: Contactcontactv2Employment;
  firstName: string;
  gender: Contactcontactv2Gender;
  iban: string;
  industries: IndustryIndustry[];
  lastName: string;
  /**
   * name is the full name of the person. It can be the only field set if no firstName+lastName separation is possible.
   * If 'firstName' and 'lastName' are set and 'name' is not, name is set to: firstName + ' ' + lastName
   */
  name: string;
  nationality: string;
  note: string;
  /** @format int64 */
  numericId: string;
  /** @format int64 */
  ownerId: string;
  phoneNumbers: Contactv2PhoneNumber[];
  preferredCommunicationChannel: V2PreferredCommunicationChannel;
  preferredLanguage: LanguagePreferredLanguage;
  /**
   * ResidenceStatus provides an enumeration for the different swiss residence status a person can have.
   *
   *  - RESIDENCE_STATUS_B_STAY: B - Aufenthaltsbewilligung
   *  - RESIDENCE_STATUS_C_SETTLE: C - Niederlassungsbewilligung
   *  - RESIDENCE_STATUS_CI_STAY_EMPLOYMENT: Ci - Aufenthaltsbewilligung mit Erwerbstätigkeit
   *  - RESIDENCE_STATUS_G_BORDER_CROSSER: G - Grenzgängerbewilligung
   *  - RESIDENCE_STATUS_L_SHORT_STAY: L -  Kurzaufenthaltsbewilligung
   *  - RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER: F - Vorläufig aufgenommene Ausländer
   *  - RESIDENCE_STATUS_N_SEEKING_ASYLUM: N - Asylsuchender
   *  - RESIDENCE_STATUS_S_PROTECTION_NEEDED: S - Schutzbedürftige
   */
  residenceStatus: Contactcontactv2ResidenceStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  residenceStatusValidUntil: TypeDate;
  salutationName: string;
  tags: string[];
  title: string;
  types: Contactcontactv2Type[];
  website: string;
  workEmail: V2AdditionalEmail;
}

export interface V2CreatePersonResponse {
  person: V2Person;
}

export interface V2DeleteAddressResponse {
  companies: Contactcontactv2Company[];
  persons: V2Person[];
}

export type V2DeleteContactPersonsResponse = object;

export interface V2GetOwnerContactResponse {
  company: Contactcontactv2Company;
  person: V2Person;
}

export interface V2LinkCompaniesRequest {
  relations: V2Relation[];
}

export type V2LinkCompaniesResponse = object;

export interface V2LinkPersonsRequest {
  relations: V2Relation[];
}

export type V2LinkPersonsResponse = object;

export interface V2Person {
  active: boolean;
  address: CommonaddressAddress;
  addressHistory: V2AddressEntry[];
  ahv: string;
  birthName: string;
  birthplace: string;
  civilStatus: Contactcontactv2CivilStatus;
  condominiumIds: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfBirth: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfDeath: TypeDate;
  documentIds: string[];
  email: string;
  employment: Contactcontactv2Employment;
  firstName: string;
  gender: Contactcontactv2Gender;
  iban: string;
  id: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  inactiveAt: TypeDate;
  industries: IndustryIndustry[];
  lastName: string;
  /**
   * name is the full name of the person. It can be the only field set if no firstName+lastName separation is possible.
   * If 'firstName' and 'lastName' are set and 'name' is not, name is set to: firstName + ' ' + lastName
   */
  name: string;
  nationality: string;
  note: string;
  /** @format int64 */
  numericId: string;
  /** @format int64 */
  ownerId: string;
  phoneNumbers: Contactv2PhoneNumber[];
  preferredCommunicationChannel: V2PreferredCommunicationChannel;
  preferredLanguage: LanguagePreferredLanguage;
  relations: V2Relation[];
  /**
   * ResidenceStatus provides an enumeration for the different swiss residence status a person can have.
   *
   *  - RESIDENCE_STATUS_B_STAY: B - Aufenthaltsbewilligung
   *  - RESIDENCE_STATUS_C_SETTLE: C - Niederlassungsbewilligung
   *  - RESIDENCE_STATUS_CI_STAY_EMPLOYMENT: Ci - Aufenthaltsbewilligung mit Erwerbstätigkeit
   *  - RESIDENCE_STATUS_G_BORDER_CROSSER: G - Grenzgängerbewilligung
   *  - RESIDENCE_STATUS_L_SHORT_STAY: L -  Kurzaufenthaltsbewilligung
   *  - RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER: F - Vorläufig aufgenommene Ausländer
   *  - RESIDENCE_STATUS_N_SEEKING_ASYLUM: N - Asylsuchender
   *  - RESIDENCE_STATUS_S_PROTECTION_NEEDED: S - Schutzbedürftige
   */
  residenceStatus: Contactcontactv2ResidenceStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  residenceStatusValidUntil: TypeDate;
  salutationName: string;
  tags: string[];
  tenancyIds: string[];
  title: string;
  types: Contactcontactv2Type[];
  /**
   * UNIX timestamp in seconds when person was modified the last time
   * @format int64
   */
  updatedAt: string;
  website: string;
  workEmail: V2AdditionalEmail;
}

/** @default "PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED" */
export enum V2PreferredCommunicationChannel {
  PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED = "PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED",
  PREFERRED_COMMUNICATION_CHANNEL_EMAIL = "PREFERRED_COMMUNICATION_CHANNEL_EMAIL",
  PREFERRED_COMMUNICATION_CHANNEL_LETTER = "PREFERRED_COMMUNICATION_CHANNEL_LETTER",
}

export interface V2Relation {
  incomingContactId: string;
  outgoingContactId: string;
}

export type V2RemoveDocumentsResponse = object;

export interface V2ResolveByCondominiumIdsResponse {
  companies: Contactcontactv2Company[];
  persons: V2Person[];
}

export interface V2ResolveByTenancyIdsResponse {
  companies: Contactcontactv2Company[];
  persons: V2Person[];
}

export interface V2ResolveEmployeesRequest {
  emails: string[];
  /** @format int64 */
  ownerId: string;
}

export interface V2ResolveEmployeesResponse {
  persons: V2Person[];
}

export interface V2SetAddressRequest {
  address: CommonaddressAddress;
  ids: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  validFrom: TypeDate;
}

export interface V2SetAddressResponse {
  companies: Contactcontactv2Company[];
  persons: V2Person[];
}

export interface V2UnLinkCompaniesRequest {
  relations: V2Relation[];
}

export type V2UnLinkCompaniesResponse = object;

export interface V2UnLinkPersonsRequest {
  relations: V2Relation[];
}

export type V2UnLinkPersonsResponse = object;

export interface V2UpdateAddressHistoryRequest {
  address: CommonaddressAddress;
  ids: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  oldValidFrom: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  validFrom: TypeDate;
}

export interface V2UpdateAddressHistoryResponse {
  companies: Contactcontactv2Company[];
  persons: V2Person[];
}

export type V2UpdateGenericResponse = object;

export interface V2UpdatePersonResponse {
  person: V2Person;
}

export interface V3ActivityReference {
  id: string;
  relatedIds: string[];
}

/** @default "ACTIVITY_SOURCE_UNDEFINED" */
export enum V3Source {
  ACTIVITY_SOURCE_UNDEFINED = "ACTIVITY_SOURCE_UNDEFINED",
  ACTIVITY_SOURCE_PHONE = "ACTIVITY_SOURCE_PHONE",
  ACTIVITY_SOURCE_MAIL = "ACTIVITY_SOURCE_MAIL",
  ACTIVITY_SOURCE_PERSONAL = "ACTIVITY_SOURCE_PERSONAL",
}

export interface CredentialsCreateCredentialsRequest {
  /** FTP hostname */
  hostname: string;
  /** FTP password (stored AES encrypted) */
  password: string;
  /**
   * FTP port
   * @format int64
   */
  port: string;
  /** FTP username (stored AES encrypted) */
  username: string;
}

export interface CredentialsCreateCredentialsResponse {
  credentialsId: string;
}

export interface CredentialsCredentials {
  /** FTP hostname */
  hostname: string;
  /** xid */
  id: string;
  /** FTP password (stored AES encrypted) */
  password: string;
  /**
   * FTP port
   * @format int64
   */
  port: string;
  /** FTP username (stored AES encrypted) */
  username: string;
}

export interface CredentialsFindDecryptedCredentialsResponse {
  credentials: CredentialsCredentials;
}

export interface CredentialsUpdateCredentialsRequest {
  /** FTP hostname */
  hostname: string;
  /** xid */
  id: string;
  /** FTP password (stored AES encrypted) */
  password: string;
  /**
   * FTP port
   * @format int64
   */
  port: string;
  /** If true password is updated, otherwise not */
  updatePassword: boolean;
  /** FTP username (stored AES encrypted) */
  username: string;
}

export interface CredentialsUpdateCredentialsResponse {
  credentials: CredentialsCredentials;
}

export interface ExportListAdvertPlatformsResponse {
  platforms: string[];
}

export interface ExportexportCreateExportResponse {
  export: ExportexportExport;
}

export type ExportexportDeleteExportResponse = object;

export interface ExportexportExport {
  /**
   * coozzy account ID (BIGINT)
   * @format int64
   */
  accountId: string;
  /**
   * The agencyID used when encoding the IDX file. If empty, the advert's ownerID will be used
   * AgencyID is used to identify the advert by the target platform (newhome for example).
   */
  agencyId: string;
  /** xid */
  credentialsId: string;
  /** xid */
  id: string;
  /** valid platform string (see 'export/export/export/service.go#L218' for all valid platforms) */
  platform: string;
  /** all targets to be exported */
  targets: ExportexportTarget[];
  /** idx, swissrets */
  type: string;
}

export interface ExportexportFindExportResponse {
  export: ExportexportExport;
}

export interface ExportexportListExportsResponse {
  exports: ExportexportExport[];
}

export interface ExportexportListPresetsResponse {
  presets: string[];
}

export interface ExportexportRemoveAllTargetsResponse {
  export: ExportexportExport;
}

export type ExportexportSendExportUploadCommandResponse = object;

export type ExportexportSetAgencyIdResponse = object;

export interface ExportexportSetPresetResponse {
  export: ExportexportExport;
}

export interface ExportexportStartExportResponse {
  exportRunId: string;
}

export interface ExportexportTarget {
  /**
   * coozzy advert id
   * @format int64
   */
  advertId: string;
  /** xid */
  exportId: string;
  /** xid */
  id: string;
  /**
   * The preset-key which defines which values are exported. The key needs to be understood by the consuming
   * service (e.g. idx, swissrets). The export service does not have any knowledge of a concrete preset.
   */
  preset: string;
}

export interface ExportexportTargetStatus {
  /**
   * Advertisement ID
   * @format int64
   */
  advertId: string;
  /** Target ID */
  id: string;
  /** Free text message */
  message: string;
  /** Status of the single target */
  status: ExportexportTargetStatusStatus;
}

/** @default "PENDING" */
export enum ExportexportTargetStatusStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export interface ExportexportUpdateExportResponse {
  export: ExportexportExport;
}

export interface ExportexportUpdatePlatformsResponse {
  exports: ExportexportExport[];
}

export interface ExporthistoryExportRun {
  /** @format int64 */
  accountId: string;
  events: ExporthistoryExportRunEvent[];
  exportId: string;
  platform: string;
  runId: string;
  /** @format int64 */
  started: string;
  status: string;
  targets: ExportexportTarget[];
}

export interface ExporthistoryExportRunEvent {
  data: Record<string, string>;
  /** @format int64 */
  date: string;
  errorType: string;
  message: string;
  path: string;
  targets: ExportexportTargetStatus[];
  type: string;
}

export interface ExporthistoryGetLatestResponse {
  comparis: ExporthistoryExportRun;
  homegate: ExporthistoryExportRun;
  immoscout: ExporthistoryExportRun;
  immowelt: ExporthistoryExportRun;
  newhome: ExporthistoryExportRun;
}

export interface ExporthistoryStatisticsCountStatus {
  /** @format int32 */
  failed: number;
  /** @format int32 */
  finished: number;
  /** @format int32 */
  started: number;
}

export interface ExporthistoryStatisticsCounts {
  /** @format int32 */
  overall: number;
  platforms: ExporthistoryStatisticsPlatforms;
  status: ExporthistoryStatisticsCountStatus;
  /** @format int32 */
  today: number;
}

export interface ExporthistoryStatisticsPlatforms {
  /** @format int32 */
  comparis: number;
  /** @format int32 */
  homegate: number;
  /** @format int32 */
  immoscout: number;
  /** @format int32 */
  immowelt: number;
  /** @format int32 */
  newhome: number;
}

export interface ExporthistoryStatisticsResponse {
  counts: ExporthistoryStatisticsCounts;
}

export interface Exporthistoryv2GetLatestResponse {
  casaone: Historyv2ExportRun;
  comparis: Historyv2ExportRun;
  homegate: Historyv2ExportRun;
  immoscout: Historyv2ExportRun;
  immowelt: Historyv2ExportRun;
  newhome: Historyv2ExportRun;
}

export type ExportidxExportIdxResponse = object;

/** ExportService ----------------------- */
export interface Exportv2CreateExportRequest {
  /** @format int64 */
  accountId: string;
  agencyId: string;
  credentialsId: string;
  customImageUrl: string;
  customName: string;
  objects: string[];
  platform: V2Platform;
  type: V2ExportType;
}

export interface Exportv2CreateExportResponse {
  export: Exportv2Export;
}

export type Exportv2DeleteExportResponse = object;

export interface Exportv2Export {
  /**
   * coozzy account ID (BIGINT)
   * @format int64
   */
  accountId: string;
  /**
   * The agencyID used when encoding the IDX file. If empty, the objects ownerID will be used
   * AgencyID is used to by the target platform to identify the object.
   */
  agencyId: string;
  /** xid */
  credentialsId: string;
  customImageUrl: string;
  customName: string;
  /** xid */
  id: string;
  /** valid platform string (see 'export/export/export/service.go#L218' for all valid platforms) */
  platform: V2Platform;
  /** all targets to be exported */
  targets: Exportv2Target[];
  /** idx, swissrets */
  type: V2ExportType;
}

export interface Exportv2FindExportResponse {
  export: Exportv2Export;
}

export interface Exportv2ListExportsResponse {
  exports: Exportv2Export[];
}

export interface Exportv2ListPresetsResponse {
  presets: V2Preset[];
}

export interface Exportv2RemoveAllTargetsResponse {
  export: Exportv2Export;
}

export interface Exportv2SendExportUploadCommandRequest {
  /** credentials to connect to the FTP server */
  credentials: CredentialsCredentials;
  /** the export which triggered this command */
  exportId: string;
  /** the export run this upload belongs to */
  exportRunId: string;
  /** the target platform key */
  platform: V2Platform;
  /** data source path: upload everything inside this folder */
  sourcePath: string;
}

export type Exportv2SendExportUploadCommandResponse = object;

export interface Exportv2SetAgencyIdRequest {
  AgencyId: string;
  ExportId: string;
}

export type Exportv2SetAgencyIdResponse = object;

export interface Exportv2SetPresetRequest {
  exportId: string;
  preset: V2Preset;
}

export type Exportv2SetPresetResponse = object;

export interface Exportv2StartExportResponse {
  exportRunId: string;
}

export interface Exportv2Target {
  /** xid */
  exportId: string;
  /** xid */
  id: string;
  /** propbase objectId */
  objectId: string;
  /**
   * The preset-key which defines which values are exported. The key needs to be understood by the consuming
   * service (e.g. idx, swissrets). The export service does not have any knowledge of a concrete preset.
   */
  preset: V2Preset;
}

export interface Exportv2TargetStatus {
  /** Target ID */
  id: string;
  /** Free text message */
  message: string;
  /** Object ID */
  objectId: string;
  /** Status of the single target */
  status: Exportv2TargetStatusStatus;
}

/** @default "PENDING" */
export enum Exportv2TargetStatusStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export interface Exportv2UpdateExportRequest {
  /** @format int64 */
  accountId: string;
  credentialsId: string;
  customImageUrl: string;
  customName: string;
  id: string;
  platform: V2Platform;
  targets: string[];
  type: V2ExportType;
}

export interface Exportv2UpdateExportResponse {
  export: Exportv2Export;
}

export interface Exportv2UpdatePlatformsRequest {
  /** @format int64 */
  accountId: string;
  objectId: string;
  platforms: V2Platform[];
}

export interface Exportv2UpdatePlatformsResponse {
  exports: Exportv2Export[];
}

export interface Historyv2ExportRun {
  /** @format int64 */
  accountId: string;
  events: Historyv2ExportRunEvent[];
  exportId: string;
  platform: string;
  runId: string;
  /** @format int64 */
  started: string;
  status: string;
  targets: Exportv2Target[];
}

export interface Historyv2ExportRunEvent {
  data: Record<string, string>;
  /** @format int64 */
  date: string;
  errorType: string;
  message: string;
  path: string;
  targets: Exportv2TargetStatus[];
  type: string;
}

export interface Historyv2StatisticsCountStatus {
  /** @format int32 */
  failed: number;
  /** @format int32 */
  finished: number;
  /** @format int32 */
  started: number;
}

export interface Historyv2StatisticsCounts {
  /** @format int32 */
  overall: number;
  platforms: Historyv2StatisticsPlatforms;
  status: Historyv2StatisticsCountStatus;
  /** @format int32 */
  today: number;
}

export interface Historyv2StatisticsPlatforms {
  /** @format int32 */
  casaone: number;
  /** @format int32 */
  comparis: number;
  /** @format int32 */
  homegate: number;
  /** @format int32 */
  immoscout: number;
  /** @format int32 */
  immowelt: number;
  /** @format int32 */
  newhome: number;
}

export interface Historyv2StatisticsResponse {
  counts: Historyv2StatisticsCounts;
}

export type Idxv2ExportIdxResponse = object;

export interface SchedulerSchedule {
  evening: boolean;
  exportId: string;
  morning: boolean;
  noon: boolean;
}

/** @default "UNDEFINED_EXPORT_TYPE" */
export enum V2ExportType {
  UNDEFINED_EXPORT_TYPE = "UNDEFINED_EXPORT_TYPE",
  IDX_EXPORT_TYPE = "IDX_EXPORT_TYPE",
  SWISSRETS_EXPORT_TYPE = "SWISSRETS_EXPORT_TYPE",
}

export interface V2ListObjectPlatformsResponse {
  platforms: V2Platform[];
}

/** @default "UNDEFINED_PLATFORM" */
export enum V2Platform {
  UNDEFINED_PLATFORM = "UNDEFINED_PLATFORM",
  PROPBASE_PLATFORM = "PROPBASE_PLATFORM",
  HOMEGATE_PLATFORM = "HOMEGATE_PLATFORM",
  COMPARIS_PLATFORM = "COMPARIS_PLATFORM",
  NEWHOME_PLATFORM = "NEWHOME_PLATFORM",
  IMMOSCOUT_PLATFORM = "IMMOSCOUT_PLATFORM",
  IMMOWELT_PLATFORM = "IMMOWELT_PLATFORM",
  CASAONE_PLATFORM = "CASAONE_PLATFORM",
}

/**
 * - ALL_FIELDS_DISABLED_PRESET: includes NO values
 *  - FULL_AGENCY_NO_VISIT_PRESET: includes ALL agency values but NO visit values
 *  - FULL_PRESET: includes ALL availbale idx fields (if set)
 * @default "UNDEFINED_PRESET"
 */
export enum V2Preset {
  UNDEFINED_PRESET = "UNDEFINED_PRESET",
  ALL_FIELDS_DISABLED_PRESET = "ALL_FIELDS_DISABLED_PRESET",
  FULL_AGENCY_NO_VISIT_PRESET = "FULL_AGENCY_NO_VISIT_PRESET",
  FULL_PRESET = "FULL_PRESET",
}

export interface DocumentMergeAllDocumentsRequest {
  media: DocumentmergingMedium[];
  mergeMediumName: string;
}

export interface DocumentMergeAllDocumentsResponse {
  /** @format byte */
  media: string;
}

export interface DocumentSaveRequest {
  mediaId: string;
  name: string;
  /** @format int64 */
  ownerId: string;
  type: MediadocumentType;
}

export interface DocumentVersion {
  mediaId: string;
  userId: string;
  /** @format int32 */
  version: number;
}

export interface DocumentmergingMedium {
  id: string;
  mediumName: string;
}

export interface MediadocumentDocument {
  id: string;
  name: string;
  /** @format int64 */
  ownerId: string;
  type: MediadocumentType;
  /** @format int32 */
  version: number;
  versions: DocumentVersion[];
}

export interface MediadocumentListRequest {
  documentIds: string[];
}

export interface MediadocumentListResponse {
  documents: MediadocumentDocument[];
}

export type MediadocumentUpdateResponse = object;

export interface PdfGenerateFromHtmlRequest {
  html: string;
}

export interface PdfGenerateFromHtmlResponse {
  /** @format byte */
  pdf: string;
}

export type StoreDeleteMediaResponse = object;

export interface StoreGetMediaResponse {
  media: StoreMedia;
}

export interface StoreListMediaRequest {
  ids: string[];
}

export interface StoreListMediaResponse {
  media: StoreMedia[];
}

export interface StoreMedia {
  /** The filename (original or given one) */
  filename: string;
  /** Media ID */
  id: string;
  /** The MIME type of the media: https://en.wikipedia.org/wiki/Media_type */
  mimeType: string;
  /**
   * Account ID of the user who uploaded the media
   * @format int64
   */
  ownerId: string;
  /**
   * The size of the media in bytes
   * @format int32
   */
  size: number;
  /** A list of available thumbnails */
  thumbnails: StoreThumbnail[];
  /**
   * UNIX timestamp in seconds when the media was uploaded
   * @format int64
   */
  uploadedAt: string;
  /** Absolute URL to get the media from. */
  url: string;
  /**
   * UNIX timestamp in seconds until which this URL is valid. Is set to 0 if no expiration.
   * @format int64
   */
  validUntil: string;
}

export interface StoreThumbnail {
  type: StoreThumbnailType;
  url: string;
}

/**
 * - S: Maximum width/height: 100px
 *  - M: Maximum width/height: 400px
 * @default "S"
 */
export enum StoreThumbnailType {
  S = "S",
  M = "M",
}

/** @default "UNDEFINED_VALUE" */
export enum ApplicableApplicable {
  UNDEFINED_VALUE = "UNDEFINED_VALUE",
  APPLIES = "APPLIES",
  DOES_NOT_APPLY = "DOES_NOT_APPLY",
}

export interface Mailv2Participant {
  email: string;
  name: string;
}

export interface Messagemailv2MarkReadRequest {
  /** @format int64 */
  accountId: string;
  ids: string[];
}

export type Messagemailv2MarkReadResponse = object;

export interface Messagemailv2MarkUnreadRequest {
  /** @format int64 */
  accountId: string;
  ids: string[];
}

export type Messagemailv2MarkUnreadResponse = object;

export interface Messagesignaturev2GetResponse {
  signature: Signaturev2Signature;
}

export interface Messagesignaturev2RenderResponse {
  /** Signature with replaced placeholders */
  signature: string;
}

export interface Messagetemplatev2CreateRequest {
  attachmentMediaIds: string[];
  language: string;
  message: string;
  name: string;
  /** @format int64 */
  ownerId: string;
  subject: string;
  type: V2TemplateType;
}

export interface Messagetemplatev2CreateResponse {
  template: V2Template;
}

export type Messagetemplatev2DeleteResponse = object;

export interface Messagetemplatev2ListResponse {
  templates: V2Template[];
}

export interface Messagetemplatev2RenderResponse {
  attachmentMediaIds: string[];
  subject: string;
  text: string;
}

export type Messagetemplatev2UpdateResponse = object;

export type Signaturev2SetResponse = object;

export interface Signaturev2Signature {
  /** @format int64 */
  accountId: string;
  id: string;
  name: string;
  signature: string;
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortSortOrder {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * Represents a time of day. The date and time zone are either not significant
 * or are specified elsewhere. An API may choose to allow leap seconds. Related
 * types are [google.type.Date][google.type.Date] and
 * `google.protobuf.Timestamp`.
 */
export interface TypeTimeOfDay {
  /**
   * Hours of day in 24 hour format. Should be from 0 to 23. An API may choose
   * to allow the value "24:00:00" for scenarios like business closing time.
   * @format int32
   */
  hours: number;
  /**
   * Minutes of hour of day. Must be from 0 to 59.
   * @format int32
   */
  minutes: number;
  /**
   * Fractions of seconds in nanoseconds. Must be from 0 to 999,999,999.
   * @format int32
   */
  nanos: number;
  /**
   * Seconds of minutes of the time. Must normally be from 0 to 59. An API may
   * allow the value 60 if it allows leap-seconds.
   * @format int32
   */
  seconds: number;
}

export interface V2ActivateMailboxesRequest {
  ids: string[];
}

export type V2ActivateMailboxesResponse = object;

export interface V2AddConfidentialEmailsRequest {
  /** @format int64 */
  accountId: string;
  emails: string[];
}

export type V2AddConfidentialEmailsResponse = object;

export interface V2ArchiveRequest {
  ids: string[];
}

export type V2ArchiveResponse = object;

export interface V2AutomaticReply {
  bodyHtml: string;
  bodyText: string;
  enabled: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endDate: TypeDate;
  /**
   * Represents a time of day. The date and time zone are either not significant
   * or are specified elsewhere. An API may choose to allow leap seconds. Related
   * types are [google.type.Date][google.type.Date] and
   * `google.protobuf.Timestamp`.
   */
  endTime: TypeTimeOfDay;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startDate: TypeDate;
  /**
   * Represents a time of day. The date and time zone are either not significant
   * or are specified elsewhere. An API may choose to allow leap seconds. Related
   * types are [google.type.Date][google.type.Date] and
   * `google.protobuf.Timestamp`.
   */
  startTime: TypeTimeOfDay;
  subject: string;
}

export interface V2BulkRenderTextEntry {
  buildingId: string;
  contactId: string;
  objectId: string;
  propertyId: string;
}

export interface V2BulkRenderTextRequest {
  entries: V2BulkRenderTextEntry[];
  text: string;
  title: string;
}

export interface V2BulkRenderTextResponse {
  entries: V2BulkRenderedTextEntries[];
}

export interface V2BulkRenderedTextEntries {
  contactId: string;
  text: string;
  title: string;
}

export interface V2BulkSendMailEntry {
  attachmentMediaIds: string[];
  bcc: Mailv2Participant[];
  bodyHtml: string;
  bodyText: string;
  cc: Mailv2Participant[];
  confidential: boolean;
  from: Mailv2Participant;
  /** @format int64 */
  ownerId: string;
  referenceIds: string[];
  /** Optional: If set to id of mail the user replies to -> support for threaded mails */
  replyToMailId: string;
  subject: string;
  to: Mailv2Participant[];
}

export interface V2BulkSendMailRequest {
  entries: V2BulkSendMailEntry[];
}

export interface V2BulkSendMailResponse {
  mail: V2Mail[];
}

export interface V2CountDraftsResponse {
  /** @format int32 */
  amount: number;
}

export interface V2CountUnreadMailsResponse {
  /** @format int32 */
  count: number;
}

export interface V2CreateDomainRequest {
  domain: string;
  /** @format int64 */
  ownerId: string;
}

export interface V2CreateDomainResponse {
  domain: V2Domain;
}

export interface V2CreateMailboxRequest {
  allowedUserIds: string[];
  /** Can be empty if no specific user is using this mailbox */
  mainUserId: string;
  name: string;
  outboundEmail: string;
  /** @format int64 */
  ownerId: string;
  signatureId: string;
}

export interface V2CreateMailboxResponse {
  mailbox: V2Mailbox;
}

export interface V2DeactivateMailboxesRequest {
  ids: string[];
}

export type V2DeactivateMailboxesResponse = object;

export type V2DeleteDomainResponse = object;

export type V2DeleteDraftsResponse = object;

export type V2DeleteMailsResponse = object;

export type V2DeleteSignatureResponse = object;

/** @default "DELIVER_STATUS_UNDEFINED" */
export enum V2DeliverStatus {
  DELIVER_STATUS_UNDEFINED = "DELIVER_STATUS_UNDEFINED",
  DELIVER_STATUS_PENDING = "DELIVER_STATUS_PENDING",
  DELIVER_STATUS_DELIVERED = "DELIVER_STATUS_DELIVERED",
  DELIVER_STATUS_ERROR = "DELIVER_STATUS_ERROR",
}

export interface V2DeliverabilityData {
  /** @format int64 */
  changedAt: string;
  errorMessage: string;
  status: V2DeliverStatus;
}

export interface V2Domain {
  domain: string;
  id: string;
  isValid: boolean;
  /** @format int64 */
  ownerId: string;
  validationKey: string;
}

export type V2EditMailboxResponse = object;

export interface V2EngagementData {
  /** @format int64 */
  changedAt: string;
  status: V2EngagementStatus;
}

/** @default "ENGAGEMENT_STATUS_UNDEFINED" */
export enum V2EngagementStatus {
  ENGAGEMENT_STATUS_UNDEFINED = "ENGAGEMENT_STATUS_UNDEFINED",
  ENGAGEMENT_STATUS_OPENED = "ENGAGEMENT_STATUS_OPENED",
  ENGAGEMENT_STATUS_CLICKED = "ENGAGEMENT_STATUS_CLICKED",
}

/** @default "FILTER_FOLDER_ALL" */
export enum V2FilterFolder {
  FILTER_FOLDER_ALL = "FILTER_FOLDER_ALL",
  FILTER_FOLDER_INBOUND = "FILTER_FOLDER_INBOUND",
  FILTER_FOLDER_OUTBOUND = "FILTER_FOLDER_OUTBOUND",
  FILTER_FOLDER_ARCHIVED = "FILTER_FOLDER_ARCHIVED",
  FILTER_FOLDER_TRASH = "FILTER_FOLDER_TRASH",
  FILTER_FOLDER_SPAM = "FILTER_FOLDER_SPAM",
}

export interface V2ForwardRequest {
  attachmentMediaIds: string[];
  bcc: Mailv2Participant[];
  bodyHtml: string;
  bodyText: string;
  cc: Mailv2Participant[];
  confidential: boolean;
  forwardingMailId: string;
  from: Mailv2Participant;
  /** @format int64 */
  ownerId: string;
  referenceIds: string[];
  subject: string;
  to: Mailv2Participant[];
}

export interface V2ForwardResponse {
  mail: V2Mail;
}

export interface V2GetDeliveryAndEngagementDataEntry {
  bcc: Mailv2Participant[];
  cc: Mailv2Participant[];
  deliverData: V2DeliverabilityData;
  engagementData: V2EngagementData;
  from: Mailv2Participant;
  id: string;
  referenceId: string[];
  /**
   * UNIX timestamp in seconds when mail was sent/received
   * @format int64
   */
  timestamp: string;
  to: Mailv2Participant[];
}

export interface V2GetDeliveryAndEngagementDataRequest {
  referenceIds: string[];
}

export interface V2GetDeliveryAndEngagementDataResponse {
  entries: V2GetDeliveryAndEngagementDataEntry[];
}

export interface V2GetMailboxResponse {
  mailbox: V2Mailbox;
}

export interface V2GetRequestAddressResponse {
  email: string;
}

export interface V2GetTicketAddressResponse {
  email: string;
}

export interface V2HasDomainDedicatedIpResponse {
  hasDedicated: boolean;
}

export interface V2ListByAccountIdResponse {
  signatures: Signaturev2Signature[];
}

export interface V2ListConfidentialEmailsResponse {
  emails: string[];
}

export interface V2ListDomainsResponse {
  domains: V2Domain[];
}

export interface V2ListDraftsResponse {
  drafts: V2MailDraft[];
}

export interface V2ListLatestMailsForReferenceIdsRequest {
  referenceIds: string[];
}

export interface V2ListLatestMailsForReferenceIdsResponse {
  mails: V2Mail[];
}

export interface V2ListMailIdsRequest {
  filterFolder: V2FilterFolder;
  /** All mails with provided entity prefixes in referenceIds are excluded from results */
  filterReferenceIdPrefixes: string[];
  filterUnread: ApplicableApplicable;
  mailboxIds: string[];
  query: string;
  sortReceivedAt: SortSortOrder;
}

export interface V2ListMailIdsResponse {
  ids: string[];
}

export interface V2ListMailboxesByAccountIdResponse {
  mailboxes: V2Mailbox[];
}

export interface V2ListMailboxesByUserIdResponse {
  mailboxes: V2Mailbox[];
}

export interface V2ListMailsByIdsRequest {
  mailIds: string[];
}

export interface V2ListMailsByIdsResponse {
  mails: V2Mail[];
}

export interface V2ListMailsByReferenceIdsRequest {
  filterFolder: V2FilterFolder;
  /** @format int32 */
  itemsPerPage: number;
  /** Optional filter that only needs to be set if filter folder is inbound/outbound */
  mailboxIds?: string[];
  /** @format int32 */
  page: number;
  query: string;
  referenceIds: string[];
}

export interface V2ListMailsByReferenceIdsResponse {
  mails: V2Mail[];
  /** @format int32 */
  totalMailsCount: number;
  /** @format int32 */
  totalPageCount: number;
}

export interface V2ListMailsRequest {
  filterFolder: V2FilterFolder;
  /**
   * All mails with provided entity prefixes in referenceIds are excluded from results
   * All mails with provided entity prefixes in referenceIds are excluded from results
   */
  filterReferenceIdPrefixes: string[];
  filterUnread: ApplicableApplicable;
  /** @format int32 */
  itemsPerPage: number;
  mailboxIds: string[];
  /** @format int32 */
  page: number;
  query: string;
  /** DEPRECATED. It's not supported anymore and sorting will not be applied */
  sortFromEmail: SortSortOrder;
  sortReceivedAt: SortSortOrder;
}

export interface V2ListMailsResponse {
  mails: V2Mail[];
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPageCount: number;
}

export interface V2Mail {
  archived: boolean;
  attachmentMediaIds: string[];
  bcc: Mailv2Participant[];
  bodyHtml: string;
  bodyText: string;
  cc: Mailv2Participant[];
  confidential: boolean;
  deliverData: V2DeliverabilityData;
  engagementData: V2EngagementData;
  from: Mailv2Participant;
  hasForwarding: boolean;
  hasReply: boolean;
  id: string;
  inTrash: boolean;
  inbound: boolean;
  mailType: V2MailType;
  mailboxId: string;
  maliciousAttachmentsMissing: boolean;
  missingHtml: boolean;
  /** @format int64 */
  ownerId: string;
  readEntries: V2Read[];
  referenceIds: string[];
  replyTo: string;
  /** If the mail was sent by our platform or was received by external sender */
  sentByPlatform: boolean;
  spam: boolean;
  subject: string;
  /**
   * UNIX timestamp in seconds when mail was sent/received
   * @format int64
   */
  timestamp: string;
  to: Mailv2Participant[];
}

export interface V2MailDraft {
  attachmentMediaIds: string[];
  bcc: Mailv2Participant[];
  bodyHtml: string;
  bodyText: string;
  cc: Mailv2Participant[];
  id: string;
  mailboxId: string;
  ownerId: string;
  referenceIds: string[];
  subject: string;
  /**
   * UNIX timestamp in seconds when draft was saved the last time
   * @format int64
   */
  timestamp: string;
  to: Mailv2Participant[];
  userId: string;
}

/** @default "MAIL_TYPE_NORMAL" */
export enum V2MailType {
  MAIL_TYPE_NORMAL = "MAIL_TYPE_NORMAL",
  MAIL_TYPE_IMIP = "MAIL_TYPE_IMIP",
  MAIL_TYPE_TICKET_NOTIFICATION = "MAIL_TYPE_TICKET_NOTIFICATION",
}

export interface V2Mailbox {
  active: boolean;
  allowedUserIds: string[];
  automaticReply: V2AutomaticReply;
  confidential: boolean;
  id: string;
  inboundEmail: string;
  /** Can be empty if no specific user is using this mailbox */
  mainUserId: string;
  name: string;
  outboundEmail: string;
  /** @format int64 */
  ownerId: string;
  signatureId: string;
}

export interface V2MarkAsNoSpamRequest {
  ids: string[];
}

export type V2MarkAsNoSpamResponse = object;

export interface V2MarkAsSpamRequest {
  ids: string[];
}

export type V2MarkAsSpamResponse = object;

export interface V2MoveToTrashRequest {
  ids: string[];
}

export type V2MoveToTrashResponse = object;

export interface V2Read {
  /** @format int64 */
  accountId: string;
  /**
   * UNIX timestamp in seconds when marked as read
   * @format int64
   */
  timestamp: string;
}

export interface V2RemoveConfidentialEmailsRequest {
  /** @format int64 */
  accountId: string;
  emails: string[];
}

export type V2RemoveConfidentialEmailsResponse = object;

export interface V2RenderByIdResponse {
  /** Signature with replaced placeholders */
  signature: string;
}

export interface V2RenderTextRequest {
  contactId: string;
  objectId: string;
  text: string;
  title: string;
}

export interface V2RenderTextResponse {
  text: string;
  title: string;
}

export interface V2RestoreFromTrashRequest {
  ids: string[];
}

export type V2RestoreFromTrashResponse = object;

export interface V2SaveDraftRequest {
  attachmentMediaIds: string[];
  bcc: Mailv2Participant[];
  bodyHtml: string;
  bodyText: string;
  cc: Mailv2Participant[];
  /** Optional parameter to save new drafts and update existing ones */
  id?: string;
  mailboxId: string;
  referenceIds: string[];
  subject: string;
  /**
   * UNIX timestamp in seconds when draft was saved the last time
   * @format int64
   */
  timestamp: string;
  to: Mailv2Participant[];
}

export interface V2SaveDraftResponse {
  draft: V2MailDraft;
}

export interface V2SendMailRequest {
  attachmentMediaIds: string[];
  bcc: Mailv2Participant[];
  bodyHtml: string;
  bodyText: string;
  cc: Mailv2Participant[];
  confidential: boolean;
  from: Mailv2Participant;
  /** Optional */
  mailType?: V2MailType;
  /** @format int64 */
  ownerId: string;
  referenceIds: string[];
  /** Optional: If set to id of mail the user replies to -> support for threaded mails */
  replyToMailId?: string;
  /**
   * Optional: for scheduled mails
   * @format int64
   */
  sendAt?: string;
  subject: string;
  to: Mailv2Participant[];
}

export interface V2SendMailResponse {
  mail: V2Mail;
}

export interface V2SetAutomaticReplyRequest {
  automaticReply: V2AutomaticReply;
  ids: string[];
}

export interface V2SetAutomaticReplyResponse {
  mailboxes: V2Mailbox[];
}

export interface V2SetConfidentialRequest {
  confidential: boolean;
  ids: string[];
}

export type V2SetConfidentialResponse = object;

export interface V2SetMailboxesConfidentialRequest {
  confidential: boolean;
  mailboxIds: string[];
}

export interface V2SetMailboxesConfidentialResponse {
  mailboxes: V2Mailbox[];
}

export type V2SetReferenceIdsResponse = object;

export interface V2SetSignatureRequest {
  /** @format int64 */
  accountId: string;
  /** OPTIONAL. If not provided a new signature is created */
  id: string;
  name: string;
  signature: string;
}

export interface V2SetSignatureResponse {
  signature: Signaturev2Signature;
}

export interface V2Template {
  attachmentMediaIds: string[];
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  createdAt: string;
  id: string;
  language: string;
  message: string;
  name: string;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  subject: string;
  type: V2TemplateType;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  updatedAt: string;
}

/** @default "TEMPLATE_TYPE_EMAIL" */
export enum V2TemplateType {
  TEMPLATE_TYPE_EMAIL = "TEMPLATE_TYPE_EMAIL",
  TEMPLATE_TYPE_LETTER = "TEMPLATE_TYPE_LETTER",
  TEMPLATE_TYPE_TICKET = "TEMPLATE_TYPE_TICKET",
  TEMPLATE_TYPE_DEEP_SIGN = "TEMPLATE_TYPE_DEEP_SIGN",
}

export interface V2UnarchiveRequest {
  ids: string[];
}

export type V2UnarchiveResponse = object;

export interface V2ValidateDomainResponse {
  isValid: boolean;
}

export interface AccountingChangeContractRelationRequest {
  /** @format int64 */
  contractRelationNumber: string;
  /** @format int64 */
  numericId: string;
  /** @format int64 */
  ownerId: string;
}

export type AccountingChangeContractRelationResponse = object;

export interface AccountingContractErrorInfo {
  /** @format int64 */
  debtorId: string;
  errorMessages: string[];
  /**
   * UNIX timestamp when sync was attempted for the last time
   * @format int64
   */
  lastTimeSynced: string;
  /** @format int64 */
  numericId: string;
  /** @format int64 */
  ownerId: string;
}

export interface AccountingGetEnabledResponse {
  /** @format int64 */
  accountId: string;
  disableBackSync: boolean;
  disableSync: boolean;
  enabled: boolean;
  /** @format int64 */
  targetAccountId: string;
  /** @format int64 */
  targetEnterpriseId: string;
  /** @format int64 */
  targetMainOwnerId: string;
  /** @format int64 */
  targetMandantId: string;
  /** @format int64 */
  targetProviderId: string;
  /** @format int64 */
  targetSystemId: string;
}

export interface AccountingGetLogsResponse {
  logEntries: AccountingLogEntry[];
  /** @format int32 */
  totalLogsCount: number;
  /** @format int32 */
  totalPageCount: number;
}

export interface AccountingIsEnabled {
  enabled: boolean;
  propertyId: string;
}

export interface AccountingIsEnabledRequest {
  propertyIds: string[];
}

export interface AccountingIsEnabledResponse {
  isEnabled: AccountingIsEnabled[];
}

export interface AccountingListAllContractErrorsResponse {
  errors: AccountingContractErrorInfo[];
}

export interface AccountingLogEntry {
  entityId: string;
  /** @format int64 */
  entityNumericId: string;
  errorMessage: string;
  success: boolean;
  /** @format int64 */
  timestamp: string;
  triggeredBy: AccountingTriggeredBy;
}

export type AccountingSetContractSyncResponse = object;

export interface AccountingSetEnabledRequest {
  /** @format int64 */
  accountId: string;
  disableBackSync: boolean;
  disableSync: boolean;
  enabled: boolean;
  /** @format int64 */
  targetAccountId: string;
  /** @format int64 */
  targetEnterpriseId: string;
  /** @format int64 */
  targetMainOwnerId: string;
  /** @format int64 */
  targetMandantId: string;
  /** @format int64 */
  targetProviderId: string;
  /** @format int64 */
  targetSystemId: string;
}

export type AccountingSetEnabledResponse = object;

export type AccountingSetPropertySyncResponse = object;

export interface AccountingSkipNextEventRequest {
  /** @format int64 */
  accountId: string;
}

export type AccountingSkipNextEventResponse = object;

export interface AccountingStatistics {
  contractErrors: AccountingContractErrorInfo[];
  enabled: boolean;
  invalidPayloadContractNumericIds: string[];
  /** @format int32 */
  invalidPayloadContracts: number;
  /**
   * UNIX timestamp when it was synced the last time
   * @format int64
   */
  lastContractSync: string;
  sourceAccountIds: string[];
  /** @format int32 */
  successfulContractSync: number;
  /** @format int64 */
  targetAccountId: string;
}

export type AccountingTriggerDebitUntilSyncResponse = object;

export type AccountingTriggerDocumentSyncResponse = object;

export type AccountingTriggerRentAdjustmentSyncResponse = object;

export interface AccountingTriggeredBy {
  name: string;
  userId: string;
}

export type BriefbutlerAcknowledgeErrorsResponse = object;

export interface BriefbutlerBriefbutlerProfile {
  briefbutlerId: string;
  description: string;
}

export interface BriefbutlerErrorEntryBriefbutler {
  entityId: string;
  errorMessage: string;
  id: string;
  mediaId: string;
  renderId: string;
  /** @format int64 */
  timestamp: string;
}

export interface BriefbutlerListErrorsByAccountIdResponse {
  errors: BriefbutlerErrorEntryBriefbutler[];
}

export interface BriefbutlerSendRawDocumentRequest {
  briefbutlerId: string;
  mediaId: string;
}

export type BriefbutlerSendRawDocumentResponse = object;

export interface BuildingAddBuildingContactsRequest {
  buildingContacts: BuildingBuildingContact[];
  ids: string[];
}

export type BuildingAddBuildingContactsResponse = object;

export interface BuildingBuilding {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  acquisitionDate: TypeDate;
  address: CommonaddressAddress;
  /** @format float */
  buildingArea: number;
  buildingStrategy: BuildingBuildingStrategy;
  /** @format int64 */
  buildingValue: string;
  buildingZone: string;
  characteristics: ObjectbuildingCharacteristics;
  constructionTypes: BuildingConstructionType[];
  /** @format int32 */
  constructionYear: number;
  contacts: BuildingBuildingContact[];
  contractAmendments: string;
  distances: BuildingDistances;
  documentIds: string[];
  egid: string;
  energySources: ObjectbuildingEnergySource[];
  /** @format int32 */
  floors: number;
  id: string;
  imageMediaId: string;
  landRegisterNumber: string;
  locationQuality: ObjectbuildingLocationQuality;
  name: string;
  note: string;
  /** @format int32 */
  number: number;
  /** @format int64 */
  numericId: string;
  objectIds: string[];
  onboardingFinished: boolean;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  /** @format float */
  plotArea: number;
  plotIds: string[];
  rating: BuildingRating;
  realEstateNumber: string;
  /** @format int32 */
  renovationYear: number;
  sia: BuildingSia;
  /** @format float */
  volume: number;
}

export interface BuildingBuildingContact {
  id: string;
  note: string;
  type: BuildingBuildingContactType;
}

/** @default "BUILDING_CONTACT_TYPE_UNDEFINED" */
export enum BuildingBuildingContactType {
  BUILDING_CONTACT_TYPE_UNDEFINED = "BUILDING_CONTACT_TYPE_UNDEFINED",
  BUILDING_CONTACT_TYPE_CRAFTSMAN = "BUILDING_CONTACT_TYPE_CRAFTSMAN",
  BUILDING_CONTACT_TYPE_JANITOR = "BUILDING_CONTACT_TYPE_JANITOR",
  BUILDING_CONTACT_TYPE_SERVICE_PROVIDER = "BUILDING_CONTACT_TYPE_SERVICE_PROVIDER",
  BUILDING_CONTACT_TYPE_DELEGATE = "BUILDING_CONTACT_TYPE_DELEGATE",
  BUILDING_CONTACT_TYPE_REVISOR = "BUILDING_CONTACT_TYPE_REVISOR",
}

export interface BuildingBuildingFilterOption {
  id: string;
  name: string;
  objectIds: string[];
  /** @format int64 */
  ownerId: string;
}

export interface BuildingBuildingStrategy {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  reviewAt: TypeDate;
  type: BuildingBuildingStrategyType;
}

/** @default "BUILDING_STRATEGY_TYPE_UNDEFINED" */
export enum BuildingBuildingStrategyType {
  BUILDING_STRATEGY_TYPE_UNDEFINED = "BUILDING_STRATEGY_TYPE_UNDEFINED",
  BUILDING_STRATEGY_TYPE_NO_RENOVATION = "BUILDING_STRATEGY_TYPE_NO_RENOVATION",
  BUILDING_STRATEGY_TYPE_MAINTAIN = "BUILDING_STRATEGY_TYPE_MAINTAIN",
  BUILDING_STRATEGY_TYPE_REPAIR = "BUILDING_STRATEGY_TYPE_REPAIR",
  BUILDING_STRATEGY_TYPE_REPLACEMENT = "BUILDING_STRATEGY_TYPE_REPLACEMENT",
  BUILDING_STRATEGY_TYPE_SALE = "BUILDING_STRATEGY_TYPE_SALE",
}

/** @default "UNDEFINED_CONSTRUCTION_TYPE" */
export enum BuildingConstructionType {
  UNDEFINED_CONSTRUCTION_TYPE = "UNDEFINED_CONSTRUCTION_TYPE",
  OLD_BUILDING = "OLD_BUILDING",
  NEW_BUILDING = "NEW_BUILDING",
  HIGH_RISE_BUILDING = "HIGH_RISE_BUILDING",
  MIDDLE_TERRACED_HOUSE = "MIDDLE_TERRACED_HOUSE",
  RAISED_GROUND_FLOOR = "RAISED_GROUND_FLOOR",
  CORNER_BUILDING = "CORNER_BUILDING",
  MINERGIE_CONSTRUCTION = "MINERGIE_CONSTRUCTION",
  MINERGIE_CERTIFIED = "MINERGIE_CERTIFIED",
  LISTED_BUILDING = "LISTED_BUILDING",
}

export interface BuildingDistances {
  /** @format float */
  kindergarten: number;
  /** @format float */
  motorway: number;
  /** @format float */
  primarySchool: number;
  /** @format float */
  publicTransport: number;
  /** @format float */
  shop: number;
  /** @format float */
  upperSchool: number;
}

export interface BuildingEditBuildingContactsRequest {
  buildingContacts: BuildingBuildingContact[];
  ids: string[];
}

export type BuildingEditBuildingContactsResponse = object;

export interface BuildingGetByObjectIdResponse {
  building: BuildingBuilding;
}

export interface BuildingListAllResponse {
  buildings: BuildingBuilding[];
}

export interface BuildingListByCoordinatesResponse {
  buildings: BuildingBuilding[];
}

export type BuildingMoveBuildingResponse = object;

export interface BuildingRating {
  /** @format float */
  microLocationRating: number;
  /** @format float */
  microLocationRatingProposal: number;
}

export interface BuildingRemoveBuildingContactsRequest {
  buildingContacts: BuildingBuildingContact[];
  ids: string[];
}

export type BuildingRemoveBuildingContactsResponse = object;

/** https://swissrets.ch/SIA-areas.html */
export interface BuildingSia {
  /** @format float */
  siaAff: number;
  /** @format float */
  siaAgf: number;
  /** @format float */
  siaAkf: number;
  /** @format float */
  siaAkfn: number;
  /** @format float */
  siaAkft: number;
  /** @format float */
  siaAnf: number;
  /** @format float */
  siaAngf: number;
  /** @format float */
  siaAvf: number;
  /** @format float */
  siaBuf: number;
  /** @format float */
  siaFf: number;
  /** @format float */
  siaGf: number;
  /** @format float */
  siaGgf: number;
  /** @format float */
  siaGsf: number;
  /** @format float */
  siaHnf: number;
  /** @format float */
  siaHnf1: number;
  /** @format float */
  siaHnf2: number;
  /** @format float */
  siaHnf3: number;
  /** @format float */
  siaHnf4: number;
  /** @format float */
  siaHnf5: number;
  /** @format float */
  siaHnf6: number;
  /** @format float */
  siaKf: number;
  /** @format float */
  siaKfn: number;
  /** @format float */
  siaKft: number;
  /** @format float */
  siaNf: number;
  /** @format float */
  siaNgf: number;
  /** @format float */
  siaNnf: number;
  /** @format float */
  siaUf: number;
  /** @format float */
  siaUuf: number;
  /** @format float */
  siaVf: number;
}

export interface BuildingUpdateMicroLocationRatingProposalResponse {
  building: BuildingBuilding;
}

export interface CondominiumCondominium {
  activated: boolean;
  closed: boolean;
  contract: ObjectcondominiumContract;
  documentIds: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endingAt: TypeDate;
  finalInvoiceCreated: boolean;
  /** Deprecated, use rent message */
  flatRateValues: ObjectcondominiumFlatRateValues[];
  id: string;
  isLatest: boolean;
  /** @format int64 */
  numericId: string;
  objectId: string;
  opted: boolean;
  owner: CondominiumOwner;
  /** @format int64 */
  ownerId: string;
  rent: ObjectcondominiumRent;
  secondaryResidence: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startingAt: TypeDate;
  status: CondominiumCondominiumStatus;
  taxable: boolean;
  tenants: ObjectcondominiumTenant[];
  /**
   * UNIX timestamp in seconds when condominium was modified the last time
   * @format int64
   */
  updatedAt: string;
}

/** @default "CONDOMINIUM_STATUS_NOT_ACTIVATED" */
export enum CondominiumCondominiumStatus {
  CONDOMINIUM_STATUS_NOT_ACTIVATED = "CONDOMINIUM_STATUS_NOT_ACTIVATED",
  CONDOMINIUM_STATUS_CURRENT = "CONDOMINIUM_STATUS_CURRENT",
  CONDOMINIUM_STATUS_CURRENT_TERMINATED = "CONDOMINIUM_STATUS_CURRENT_TERMINATED",
  CONDOMINIUM_STATUS_TERMINATED = "CONDOMINIUM_STATUS_TERMINATED",
  CONDOMINIUM_STATUS_CLOSED = "CONDOMINIUM_STATUS_CLOSED",
  CONDOMINIUM_STATUS_FUTURE = "CONDOMINIUM_STATUS_FUTURE",
}

export interface CondominiumHasActivatedCondominiumEntry {
  hasActivatedCondominium: boolean;
  objectId: string;
}

export interface CondominiumHasActivatedCondominiumRequest {
  objectIds: string[];
}

export interface CondominiumHasActivatedCondominiumResponse {
  entries: CondominiumHasActivatedCondominiumEntry[];
}

export interface CondominiumHasCondominiumWithContactIdsResponse {
  existing: boolean;
}

export interface CondominiumListByOwnerContactResponse {
  condominiums: CondominiumCondominium[];
}

export interface CondominiumListByTenantContactsResponse {
  condominiums: CondominiumCondominium[];
}

export interface CondominiumOwner {
  billingContactId: string;
  contactIds: string[];
  correspondenceContactId: string;
  /** @format int32 */
  numberOfPersons: number;
  roommateContactIds: string[];
}

export interface ContractRenderTemplateRequest {
  attachAutomatically: boolean;
  description: string;
  id: string;
  signatories: string[];
  /** The ID of the tenancy for which a new contract should be rendered */
  tenancyId: string;
  title: string;
}

export interface ContractRenderTemplateResponse {
  /** @format byte */
  contract: string;
}

export interface Contractv2ContactData {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface Contractv2ContractTemplate {
  category: Contractv2TemplateCategory;
  /** @format int64 */
  createdAt: string;
  creatorName: string;
  enforcePdf: boolean;
  id: string;
  language: V2Language;
  mediaId: string;
  /** @format int64 */
  ownerId: string;
  title: string;
  /** @format int64 */
  updatedAt: string;
}

export interface Contractv2CreateTemplateRequest {
  category: Contractv2TemplateCategory;
  creatorName: string;
  enforcePdf: boolean;
  language: V2Language;
  mediaId: string;
  /** @format int64 */
  ownerId: string;
  title: string;
}

export interface Contractv2CreateTemplateResponse {
  template: Contractv2ContractTemplate;
}

export type Contractv2DeleteTemplateResponse = object;

export interface Contractv2DeviceData {
  id: string;
  note: string;
}

export interface Contractv2ListTemplatesResponse {
  templates: Contractv2ContractTemplate[];
}

export interface Contractv2RenderCondominiumTemplateRequest {
  attachAutomatically: boolean;
  condominiumId: string;
  description: string;
  documentName: string;
  enforcePdf: boolean;
  id: string;
  includeRoommates: boolean;
  sendingProfile: string;
  signatories: string[];
  title: string;
}

export interface Contractv2RenderCondominiumTemplateResponse {
  renderId: string;
}

export interface Contractv2RenderCondominiumsTemplateRequest {
  attachAutomatically: boolean;
  condominiumIds: string[];
  description: string;
  documentName: string;
  enforcePdf: boolean;
  id: string;
  includeRoommates: boolean;
  order: V2RenderOrder;
  sendingProfile: string;
  signatories: string[];
  title: string;
}

export interface Contractv2RenderCondominiumsTemplateResponse {
  renderId: string;
}

export interface Contractv2RenderContactTemplateRequest {
  attachAutomatically: boolean;
  contactId: string;
  description: string;
  documentName: string;
  enforcePdf: boolean;
  id: string;
  sendingProfile: string;
  signatories: string[];
  title: string;
}

export interface Contractv2RenderContactTemplateResponse {
  renderId: string;
}

export interface Contractv2RenderContactsTemplateRequest {
  attachAutomatically: boolean;
  contactIds: string[];
  description: string;
  documentName: string;
  enforcePdf: boolean;
  id: string;
  order: V2RenderOrder;
  sendingProfile: string;
  signatories: string[];
  title: string;
}

export interface Contractv2RenderContactsTemplateResponse {
  renderId: string;
}

export interface Contractv2RenderDeviceTemplateRequest {
  assigneeContactId: string;
  attachAutomatically: boolean;
  contactId: string;
  description: string;
  devices: Contractv2DeviceData[];
  documentName: string;
  enforcePdf: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  executeFrom: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  executeUntil: TypeDate;
  id: string;
  /** Either provide this or randomContactData, but not both! */
  onSiteContactPersonId: string;
  orderDescription: string;
  orderNumber: string;
  /** Either provide this or onSiteContactPersonId, but not both! */
  randomContactData: Contractv2ContactData;
  signatories: string[];
  title: string;
}

export interface Contractv2RenderDeviceTemplateResponse {
  renderId: string;
}

export interface Contractv2RenderTenanciesTemplateRequest {
  attachAutomatically: boolean;
  description: string;
  documentName: string;
  enforcePdf: boolean;
  id: string;
  order: V2RenderOrder;
  sendingProfile: string;
  signatories: string[];
  tenancyIds: string[];
  title: string;
}

export interface Contractv2RenderTenanciesTemplateResponse {
  renderId: string;
}

/** @default "TEMPLATE_CATEGORY_UNDEFINED" */
export enum Contractv2TemplateCategory {
  TEMPLATE_CATEGORY_UNDEFINED = "TEMPLATE_CATEGORY_UNDEFINED",
  TEMPLATE_CATEGORY_GENERAL = "TEMPLATE_CATEGORY_GENERAL",
  TEMPLATE_CATEGORY_ORDERS = "TEMPLATE_CATEGORY_ORDERS",
  TEMPLATE_CATEGORY_TERMINATION = "TEMPLATE_CATEGORY_TERMINATION",
  TEMPLATE_CATEGORY_CONTRACTS = "TEMPLATE_CATEGORY_CONTRACTS",
  TEMPLATE_CATEGORY_PAYMENTS = "TEMPLATE_CATEGORY_PAYMENTS",
  TEMPLATE_CATEGORY_OFFER = "TEMPLATE_CATEGORY_OFFER",
  TEMPLATE_CATEGORY_LETTER = "TEMPLATE_CATEGORY_LETTER",
}

export type Contractv2UpdateTemplateResponse = object;

/** @default "COST_ABSORPTION_UNDEFINED" */
export enum CostCostAbsorption {
  COST_ABSORPTION_UNDEFINED = "COST_ABSORPTION_UNDEFINED",
  COST_ABSORPTION_OWNER = "COST_ABSORPTION_OWNER",
  COST_ABSORPTION_TENANT = "COST_ABSORPTION_TENANT",
}

/** @default "COST_SHARE_UNIT_UNDEFINED" */
export enum CostCostShareUnit {
  COST_SHARE_UNIT_UNDEFINED = "COST_SHARE_UNIT_UNDEFINED",
  COST_SHARE_UNIT_PERCENT = "COST_SHARE_UNIT_PERCENT",
  COST_SHARE_UNIT_FIXED = "COST_SHARE_UNIT_FIXED",
}

/** @default "COUNTRY_INDEX_BASE_YEAR_1914" */
export enum CountryIndexCountryIndexBaseYear {
  COUNTRYINDEXBASEYEAR1914 = "COUNTRY_INDEX_BASE_YEAR_1914",
  COUNTRYINDEXBASEYEAR1939 = "COUNTRY_INDEX_BASE_YEAR_1939",
  COUNTRYINDEXBASEYEAR1966 = "COUNTRY_INDEX_BASE_YEAR_1966",
  COUNTRYINDEXBASEYEAR1977 = "COUNTRY_INDEX_BASE_YEAR_1977",
  COUNTRYINDEXBASEYEAR1982 = "COUNTRY_INDEX_BASE_YEAR_1982",
  COUNTRYINDEXBASEYEAR1993 = "COUNTRY_INDEX_BASE_YEAR_1993",
  COUNTRYINDEXBASEYEAR2000 = "COUNTRY_INDEX_BASE_YEAR_2000",
  COUNTRYINDEXBASEYEAR2005 = "COUNTRY_INDEX_BASE_YEAR_2005",
  COUNTRYINDEXBASEYEAR2010 = "COUNTRY_INDEX_BASE_YEAR_2010",
  COUNTRYINDEXBASEYEAR2015 = "COUNTRY_INDEX_BASE_YEAR_2015",
  COUNTRYINDEXBASEYEAR2020 = "COUNTRY_INDEX_BASE_YEAR_2020",
}

export interface CountryIndexGetInflationResponse {
  /** @format float */
  inflation: number;
}

export interface CountryIndexReadRequest {
  fileUrl: string;
}

export type CountryIndexReadResponse = object;

/** @default "DEMO_DATA_SET_1" */
export enum DemoDataDemoDataSet {
  DEMODATASET1 = "DEMO_DATA_SET_1",
  DEMODATASET2 = "DEMO_DATA_SET_2",
  DEMODATASET3 = "DEMO_DATA_SET_3",
}

export interface DemoDataGenerateRequest {
  /** @format int64 */
  accountId: string;
  dataSet: DemoDataDemoDataSet;
}

export type DemoDataGenerateResponse = object;

/** @default "UNDEFINED_INTERVAL" */
export enum DeviceCostsInterval {
  UNDEFINED_INTERVAL = "UNDEFINED_INTERVAL",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
  MONTHLY = "MONTHLY",
  TRIANNUAL = "TRIANNUAL",
  HALF_YEARLY = "HALF_YEARLY",
  BI_MONTHLY = "BI_MONTHLY",
  TWO_YEARLY = "TWO_YEARLY",
  THREE_YEARLY = "THREE_YEARLY",
  FOUR_YEARLY = "FOUR_YEARLY",
  FIVE_YEARLY = "FIVE_YEARLY",
  TEN_YEARLY = "TEN_YEARLY",
  TWENTY_YEARLY = "TWENTY_YEARLY",
  EVERY6YEARS = "EVERY_6_YEARS",
  EVERY8YEARS = "EVERY_8_YEARS",
  EVERY15YEARS = "EVERY_15_YEARS",
}

export interface DeviceDefaultDataEntry {
  bkpH: string;
  /** @format float */
  cataloguePrice: number;
  facilityPlant: DeviceDeviceFacilityPlant;
  /** @format int32 */
  lifeSpan: number;
  system: DeviceDeviceSystem;
  type: DeviceDeviceType;
  unit: ObjectdeviceDeviceUnit;
}

/**
 * - DEVICE_CONDITION_REPAIR_NEEDED: Deprecated. Will be moved to action value
 *  - DEVICE_CONDITION_REPLACEMENT_NEEDED: Deprecated. Will be moved to action value
 * @default "DEVICE_CONDITION_UNDEFINED"
 */
export enum DeviceDeviceCondition {
  DEVICE_CONDITION_UNDEFINED = "DEVICE_CONDITION_UNDEFINED",
  DEVICE_CONDITION_NORMAL_WEAR = "DEVICE_CONDITION_NORMAL_WEAR",
  DEVICE_CONDITION_REPAIR_NEEDED = "DEVICE_CONDITION_REPAIR_NEEDED",
  DEVICE_CONDITION_REPLACEMENT_NEEDED = "DEVICE_CONDITION_REPLACEMENT_NEEDED",
  DEVICE_CONDITION_PERFECT = "DEVICE_CONDITION_PERFECT",
  DEVICE_CONDITION_DEFICIENCY = "DEVICE_CONDITION_DEFICIENCY",
}

/** @default "DEVICE_FACILITY_PLANT_UNDEFINED" */
export enum DeviceDeviceFacilityPlant {
  DEVICE_FACILITY_PLANT_UNDEFINED = "DEVICE_FACILITY_PLANT_UNDEFINED",
  DEVICEFACILITYSHELLCONSTRUCTION1 = "DEVICE_FACILITY_SHELL_CONSTRUCTION1",
  DEVICEFACILITYSHELLCONSTRUCTION2 = "DEVICE_FACILITY_SHELL_CONSTRUCTION2",
  DEVICE_FACILITY_PLANT_ELECTRICITY = "DEVICE_FACILITY_PLANT_ELECTRICITY",
  DEVICE_FACILITY_PLANT_AIR_CONDITIONING = "DEVICE_FACILITY_PLANT_AIR_CONDITIONING",
  DEVICE_FACILITY_PLANT_SANITARY = "DEVICE_FACILITY_PLANT_SANITARY",
  DEVICE_FACILITY_PLANT_TRANSPORT_WAREHOUSE = "DEVICE_FACILITY_PLANT_TRANSPORT_WAREHOUSE",
  DEVICEFACILITYPLANTINTERIOR1 = "DEVICE_FACILITY_PLANT_INTERIOR1",
  DEVICEFACILITYPLANTINTERIOR2 = "DEVICE_FACILITY_PLANT_INTERIOR2",
  DEVICE_FACILITY_PLANT_GARDEN = "DEVICE_FACILITY_PLANT_GARDEN",
  DEVICE_FACILITY_PLANT_DEVICE = "DEVICE_FACILITY_PLANT_DEVICE",
}

/** @default "DEVICE_MEASURE_UNDEFINED" */
export enum DeviceDeviceMeasure {
  DEVICE_MEASURE_UNDEFINED = "DEVICE_MEASURE_UNDEFINED",
  DEVICE_MEASURE_IMPLEMENTATION_PERIOD = "DEVICE_MEASURE_IMPLEMENTATION_PERIOD",
  DEVICE_MEASURE_IMMEDIATELY = "DEVICE_MEASURE_IMMEDIATELY",
}

/** @default "DEVICE_SYSTEM_UNDEFINED" */
export enum DeviceDeviceSystem {
  DEVICE_SYSTEM_UNDEFINED = "DEVICE_SYSTEM_UNDEFINED",
  DEVICE_SYSTEM_MASTER_BUILDER_WORK = "DEVICE_SYSTEM_MASTER_BUILDER_WORK",
  DEVICE_SYSTEM_WINDOW_DOOR = "DEVICE_SYSTEM_WINDOW_DOOR",
  DEVICE_SYSTEM_ROOFING = "DEVICE_SYSTEM_ROOFING",
  DEVICE_SYSTEM_SEALING_INSULATION = "DEVICE_SYSTEM_SEALING_INSULATION",
  DEVICE_SYSTEM_FACADE_PLASTER = "DEVICE_SYSTEM_FACADE_PLASTER",
  DEVICE_SYSTEM_OUTER_FINISHES = "DEVICE_SYSTEM_OUTER_FINISHES",
  DEVICE_SYSTEM_HEAVY_CURRENT_INSTALLATION = "DEVICE_SYSTEM_HEAVY_CURRENT_INSTALLATION",
  DEVICE_SYSTEM_LIGHTS = "DEVICE_SYSTEM_LIGHTS",
  DEVICE_SYSTEM_TELEPHONE = "DEVICE_SYSTEM_TELEPHONE",
  DEVICE_SYSTEM_LOW_CURRENT_INSTALLATION = "DEVICE_SYSTEM_LOW_CURRENT_INSTALLATION",
  DEVICE_SYSTEM_OTHER_ELECTRICITY = "DEVICE_SYSTEM_OTHER_ELECTRICITY",
  DEVICE_SYSTEM_HEATING_TRANSITION_POSITION = "DEVICE_SYSTEM_HEATING_TRANSITION_POSITION",
  DEVICE_SYSTEM_ENERGY_SUPPLY = "DEVICE_SYSTEM_ENERGY_SUPPLY",
  DEVICE_SYSTEM_HEAT_GENERATION = "DEVICE_SYSTEM_HEAT_GENERATION",
  DEVICE_SYSTEM_HEAT_DISTRIBUTION = "DEVICE_SYSTEM_HEAT_DISTRIBUTION",
  DEVICE_SYSTEM_VENTILATION = "DEVICE_SYSTEM_VENTILATION",
  DEVICE_SYSTEM_AIR_CONDITIONING = "DEVICE_SYSTEM_AIR_CONDITIONING",
  DEVICE_SYSTEM_COOLING = "DEVICE_SYSTEM_COOLING",
  DEVICE_SYSTEM_SPECIAL_FACILITY = "DEVICE_SYSTEM_SPECIAL_FACILITY",
  DEVICE_SYSTEM_INSULATION = "DEVICE_SYSTEM_INSULATION",
  DEVICE_SYSTEM_OTHER_HEATING = "DEVICE_SYSTEM_OTHER_HEATING",
  DEVICE_SYSTEM_SANITARY = "DEVICE_SYSTEM_SANITARY",
  DEVICE_SYSTEM_SPECIAL_SANITARY = "DEVICE_SYSTEM_SPECIAL_SANITARY",
  DEVICE_SYSTEM_SANITARY_SUPPLY_DISPOSAL = "DEVICE_SYSTEM_SANITARY_SUPPLY_DISPOSAL",
  DEVICE_SYSTEM_SANITARY_PIPING = "DEVICE_SYSTEM_SANITARY_PIPING",
  DEVICE_SYSTEM_SANITARY_INSULATION = "DEVICE_SYSTEM_SANITARY_INSULATION",
  DEVICE_SYSTEM_SANITARY_EQUIPMENT = "DEVICE_SYSTEM_SANITARY_EQUIPMENT",
  DEVICE_SYSTEM_ELECTRIC_PNEUMATIC_BOARD = "DEVICE_SYSTEM_ELECTRIC_PNEUMATIC_BOARD",
  DEVICE_SYSTEM_KITCHEN_EQUIPMENT = "DEVICE_SYSTEM_KITCHEN_EQUIPMENT",
  DEVICE_SYSTEM_OTHER_SANITARY = "DEVICE_SYSTEM_OTHER_SANITARY",
  DEVICE_SYSTEM_ELEVATOR = "DEVICE_SYSTEM_ELEVATOR",
  DEVICE_SYSTEM_MOVING_STAIRCASE = "DEVICE_SYSTEM_MOVING_STAIRCASE",
  DEVICE_SYSTEM_FACADE_CLEANING = "DEVICE_SYSTEM_FACADE_CLEANING",
  DEVICE_SYSTEM_GOODS_CONVEYING = "DEVICE_SYSTEM_GOODS_CONVEYING",
  DEVICE_SYSTEM_LIFTING = "DEVICE_SYSTEM_LIFTING",
  DEVICE_SYSTEM_PARKING = "DEVICE_SYSTEM_PARKING",
  DEVICE_SYSTEM_TRANSPORT_OTHER = "DEVICE_SYSTEM_TRANSPORT_OTHER",
  DEVICE_SYSTEM_PLASTERER = "DEVICE_SYSTEM_PLASTERER",
  DEVICE_SYSTEM_METAL = "DEVICE_SYSTEM_METAL",
  DEVICE_SYSTEM_CARPENTER = "DEVICE_SYSTEM_CARPENTER",
  DEVICE_SYSTEM_WINDOW = "DEVICE_SYSTEM_WINDOW",
  DEVICE_SYSTEM_LOCKING = "DEVICE_SYSTEM_LOCKING",
  DEVICE_SYSTEM_INTERIOR_FINISHES = "DEVICE_SYSTEM_INTERIOR_FINISHES",
  DEVICE_SYSTEM_ELEMENT_WALL = "DEVICE_SYSTEM_ELEMENT_WALL",
  DEVICE_SYSTEM_CHROME_STEEL_FACILITY = "DEVICE_SYSTEM_CHROME_STEEL_FACILITY",
  DEVICESYSTEMOTHERINTERIOR1 = "DEVICE_SYSTEM_OTHER_INTERIOR1",
  DEVICE_SYSTEM_FLOORING = "DEVICE_SYSTEM_FLOORING",
  DEVICE_SYSTEM_WALL_COVERING = "DEVICE_SYSTEM_WALL_COVERING",
  DEVICE_SYSTEM_CEILING = "DEVICE_SYSTEM_CEILING",
  DEVICE_SYSTEM_INTERIOR_SURFACE_TREATMENTS = "DEVICE_SYSTEM_INTERIOR_SURFACE_TREATMENTS",
  DEVICESYSTEMOTHERINTERIOR2 = "DEVICE_SYSTEM_OTHER_INTERIOR2",
  DEVICE_SYSTEM_OTHER_TRANSPORT = "DEVICE_SYSTEM_OTHER_TRANSPORT",
  DEVICE_SYSTEM_GARDEN_TRANSITION_POSITION = "DEVICE_SYSTEM_GARDEN_TRANSITION_POSITION",
  DEVICE_SYSTEM_GARDENING = "DEVICE_SYSTEM_GARDENING",
  DEVICE_SYSTEM_FENCING = "DEVICE_SYSTEM_FENCING",
  DEVICE_SYSTEM_SUPERSTRUCTURE = "DEVICE_SYSTEM_SUPERSTRUCTURE",
  DEVICE_SYSTEM_PLAYGROUND = "DEVICE_SYSTEM_PLAYGROUND",
  DEVICE_SYSTEM_GARDEN_OTHER = "DEVICE_SYSTEM_GARDEN_OTHER",
  DEVICE_SYSTEM_DEVICE_TRANSITION_POSITION = "DEVICE_SYSTEM_DEVICE_TRANSITION_POSITION",
}

/**
 * - DEVICE_TYPE_CHROME_STEEP_FINISH: Not used
 * @default "DEVICE_TYPE_UNDEFINED"
 */
export enum DeviceDeviceType {
  DEVICE_TYPE_UNDEFINED = "DEVICE_TYPE_UNDEFINED",
  DEVICE_TYPE_MASTER_BUILDER_WORK_DOWN_PIPES = "DEVICE_TYPE_MASTER_BUILDER_WORK_DOWN_PIPES",
  DEVICE_TYPE_MASTER_BUILDER_WORK_BASIC_LINE = "DEVICE_TYPE_MASTER_BUILDER_WORK_BASIC_LINE",
  DEVICE_TYPE_MASTER_BUILDER_WORK_DRAINAGE_LINE = "DEVICE_TYPE_MASTER_BUILDER_WORK_DRAINAGE_LINE",
  DEVICE_TYPE_MASTER_BUILDER_WORK_PUMP_CHAMBER = "DEVICE_TYPE_MASTER_BUILDER_WORK_PUMP_CHAMBER",
  DEVICE_TYPE_MASTER_BUILDER_WORK_TERRACE_DRAIN = "DEVICE_TYPE_MASTER_BUILDER_WORK_TERRACE_DRAIN",
  DEVICE_TYPE_WINDOW_DOOR_AUTOMATIC_SLIDING_DOOR = "DEVICE_TYPE_WINDOW_DOOR_AUTOMATIC_SLIDING_DOOR",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR",
  DEVICE_TYPE_WINDOW_DOOR_FIRE_GATE = "DEVICE_TYPE_WINDOW_DOOR_FIRE_GATE",
  DEVICE_TYPE_WINDOW_DOOR_ROOF_WINDOW = "DEVICE_TYPE_WINDOW_DOOR_ROOF_WINDOW",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW = "DEVICE_TYPE_WINDOW_DOOR_WINDOW",
  DEVICE_TYPE_WINDOW_DOOR_GATE_DRIVE = "DEVICE_TYPE_WINDOW_DOOR_GATE_DRIVE",
  DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR = "DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR",
  DEVICE_TYPE_WINDOW_DOOR_DOOR_AUTOMATION = "DEVICE_TYPE_WINDOW_DOOR_DOOR_AUTOMATION",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_SOLID_WOOD = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_SOLID_WOOD",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_WOOD_MATERIAL = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_WOOD_MATERIAL",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_METAL = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_METAL",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_DOUBLE_GLAZING = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_DOUBLE_GLAZING",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_PLASTIC = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_PLASTIC",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_METAL = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_METAL",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_METAL = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_METAL",
  DEVICE_TYPE_ROOFING_PENT_ROOF = "DEVICE_TYPE_ROOFING_PENT_ROOF",
  DEVICE_TYPE_ROOFING_SADDLE_ROOF = "DEVICE_TYPE_ROOFING_SADDLE_ROOF",
  DEVICE_TYPE_ROOFING_TERRACE = "DEVICE_TYPE_ROOFING_TERRACE",
  DEVICE_TYPE_ROOFING_HIPPED_ROOF = "DEVICE_TYPE_ROOFING_HIPPED_ROOF",
  DEVICE_TYPE_ROOFING_TENT_ROOF = "DEVICE_TYPE_ROOFING_TENT_ROOF",
  DEVICE_TYPE_ROOFING_FLAT_ROOF = "DEVICE_TYPE_ROOFING_FLAT_ROOF",
  DEVICE_TYPE_ROOFING_SHEARING_ROOF = "DEVICE_TYPE_ROOFING_SHEARING_ROOF",
  DEVICE_TYPE_ROOFING_FRONT_PANEL = "DEVICE_TYPE_ROOFING_FRONT_PANEL",
  DEVICE_TYPE_SEALING_INSULATION_FACADE = "DEVICE_TYPE_SEALING_INSULATION_FACADE",
  DEVICE_TYPE_SEALING_INSULATION_INTERIOR = "DEVICE_TYPE_SEALING_INSULATION_INTERIOR",
  DEVICE_TYPE_SEALING_INSULATION_FACADE_WOOD = "DEVICE_TYPE_SEALING_INSULATION_FACADE_WOOD",
  DEVICE_TYPE_SEALING_INSULATION_FACADE_PLATES = "DEVICE_TYPE_SEALING_INSULATION_FACADE_PLATES",
  DEVICE_TYPE_SEALING_INSULATION_FACADE_ETERNIT = "DEVICE_TYPE_SEALING_INSULATION_FACADE_ETERNIT",
  DEVICE_TYPE_FACADE_PLASTER_PAINTING = "DEVICE_TYPE_FACADE_PLASTER_PAINTING",
  DEVICE_TYPE_FACADE_PLASTER = "DEVICE_TYPE_FACADE_PLASTER",
  DEVICE_TYPE_OUTER_FINISHES_FOLDING_SHUTTERS = "DEVICE_TYPE_OUTER_FINISHES_FOLDING_SHUTTERS",
  DEVICE_TYPE_OUTER_FINISHES_SHUTTERS = "DEVICE_TYPE_OUTER_FINISHES_SHUTTERS",
  DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS = "DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS",
  DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS_VOLANT = "DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS_VOLANT",
  DEVICE_TYPE_OUTER_FINISHES_GLARE_PROTECTION = "DEVICE_TYPE_OUTER_FINISHES_GLARE_PROTECTION",
  DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_WOOD = "DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_WOOD",
  DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_METAL = "DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_METAL",
  DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS = "DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS",
  DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_OUTSIDE_ALUMINIUM = "DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_OUTSIDE_ALUMINIUM",
  DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_INSIDE = "DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_INSIDE",
  DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS = "DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS",
  DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_WOOD = "DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_WOOD",
  DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_METAL = "DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_METAL",
  DEVICE_TYPE_OUTER_FINISHES = "DEVICE_TYPE_OUTER_FINISHES",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_GLASS = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_GLASS",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_WOOD = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_WOOD",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_METAL = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_METAL",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LIGHTING = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LIGHTING",
  DEVICETYPEHEAVYCURRENTINSTALLATIONCO2 = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CO2",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SINA = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SINA",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SMOKE_VENT = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SMOKE_VENT",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PROOF_OF_SAFETY_SINA = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PROOF_OF_SAFETY_SINA",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHGEAR_COMBINATION = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHGEAR_COMBINATION",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PHOTOVOLTAIC = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PHOTOVOLTAIC",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LOW_VOLTAGE_SYSTEM = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LOW_VOLTAGE_SYSTEM",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CABLE = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CABLE",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCH = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCH",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHBOARD = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHBOARD",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SOCKET = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SOCKET",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_UPS = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_UPS",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_INFRASTRUCTURE_ELECTRIC_CHARGING = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_INFRASTRUCTURE_ELECTRIC_CHARGING",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_ELECTRIC_CHARGING_STATION = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_ELECTRIC_CHARGING_STATION",
  DEVICE_TYPE_LIGHTS_ILLUMINANT = "DEVICE_TYPE_LIGHTS_ILLUMINANT",
  DEVICE_TYPE_LIGHTS = "DEVICE_TYPE_LIGHTS",
  DEVICE_TYPE_LIGHTS_CEILING = "DEVICE_TYPE_LIGHTS_CEILING",
  DEVICE_TYPE_LIGHTS_CABINET = "DEVICE_TYPE_LIGHTS_CABINET",
  DEVICE_TYPE_LIGHTS_SPOTS = "DEVICE_TYPE_LIGHTS_SPOTS",
  DEVICE_TYPE_LIGHTS_MIRROR_CABINET = "DEVICE_TYPE_LIGHTS_MIRROR_CABINET",
  DEVICE_TYPE_TELEPHONE_CENTER = "DEVICE_TYPE_TELEPHONE_CENTER",
  DEVICE_TYPE_TELEPHONE_DISTRIBUTOR = "DEVICE_TYPE_TELEPHONE_DISTRIBUTOR",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM_VIDEO = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM_VIDEO",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_BELL = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_BELL",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_DOOR_OPENER = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_DOOR_OPENER",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_FIBERGLASS = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_FIBERGLASS",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_EMERGENCY_PHONE_LIFT = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_EMERGENCY_PHONE_LIFT",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTRUSION_DETECTION = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTRUSION_DETECTION",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_ISDN = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_ISDN",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_CABLE = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_CABLE",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCHBOARD = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCHBOARD",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SOCKET = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SOCKET",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_ANTENNA = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_ANTENNA",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_CABLE = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_CABLE",
  DEVICE_TYPE_OTHER_ELECTRICITY_BATTERY = "DEVICE_TYPE_OTHER_ELECTRICITY_BATTERY",
  DEVICE_TYPE_OTHER_ELECTRICITY_OTHER = "DEVICE_TYPE_OTHER_ELECTRICITY_OTHER",
  DEVICE_TYPE_OTHER_ELECTRICITY_LIGHTNING_PROTECTION = "DEVICE_TYPE_OTHER_ELECTRICITY_LIGHTNING_PROTECTION",
  DEVICE_TYPE_OTHER_ELECTRICITY = "DEVICE_TYPE_OTHER_ELECTRICITY",
  DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET = "DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_ROOF_VENTILATION = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_ROOF_VENTILATION",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_FILTER_REPLACEMENT = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_FILTER_REPLACEMENT",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_AIR_CONDITIONING = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_AIR_CONDITIONING",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_VENTILATION = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_VENTILATION",
  DEVICE_TYPE_ENERGY_SUPPLY_SUPPLY = "DEVICE_TYPE_ENERGY_SUPPLY_SUPPLY",
  DEVICE_TYPE_ENERGY_SUPPLY_LEAKAGE_WARNING = "DEVICE_TYPE_ENERGY_SUPPLY_LEAKAGE_WARNING",
  DEVICE_TYPE_ENERGY_SUPPLY_TANK = "DEVICE_TYPE_ENERGY_SUPPLY_TANK",
  DEVICE_TYPE_ENERGY_SUPPLY_STORAGE = "DEVICE_TYPE_ENERGY_SUPPLY_STORAGE",
  DEVICE_TYPE_ENERGY_SUPPLY_GAS_LINE = "DEVICE_TYPE_ENERGY_SUPPLY_GAS_LINE",
  DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_INSIDE = "DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_INSIDE",
  DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_EARTH_LOADED = "DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_EARTH_LOADED",
  DEVICE_TYPE_HEAT_GENERATION = "DEVICE_TYPE_HEAT_GENERATION",
  DEVICE_TYPE_HEAT_GENERATION_BOILER = "DEVICE_TYPE_HEAT_GENERATION_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_BURNER = "DEVICE_TYPE_HEAT_GENERATION_BURNER",
  DEVICE_TYPE_HEAT_GENERATION_EARTH_PROBE = "DEVICE_TYPE_HEAT_GENERATION_EARTH_PROBE",
  DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STORAGE_STOVE = "DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STORAGE_STOVE",
  DEVICE_TYPE_HEAT_GENERATION_EXPANSION_DEVICE = "DEVICE_TYPE_HEAT_GENERATION_EXPANSION_DEVICE",
  DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING = "DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING",
  DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING_SUBSTATION = "DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING_SUBSTATION",
  DEVICE_TYPE_HEAT_GENERATION_HEATING_BOILER = "DEVICE_TYPE_HEAT_GENERATION_HEATING_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_THERMAL_SOLAR_SYSTEM = "DEVICE_TYPE_HEAT_GENERATION_THERMAL_SOLAR_SYSTEM",
  DEVICE_TYPE_HEAT_GENERATION_HEAT_EXCHANGER = "DEVICE_TYPE_HEAT_GENERATION_HEAT_EXCHANGER",
  DEVICE_TYPE_HEAT_GENERATION_HEAT_PUMP = "DEVICE_TYPE_HEAT_GENERATION_HEAT_PUMP",
  DEVICE_TYPE_HEAT_GENERATION_FIREPLACE = "DEVICE_TYPE_HEAT_GENERATION_FIREPLACE",
  DEVICE_TYPE_HEAT_GENERATION_HEAT_ACCUMULATOR = "DEVICE_TYPE_HEAT_GENERATION_HEAT_ACCUMULATOR",
  DEVICE_TYPE_HEAT_GENERATION_SINGLE_WATER_BOILER = "DEVICE_TYPE_HEAT_GENERATION_SINGLE_WATER_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_COMBIBOILER_HEATING = "DEVICE_TYPE_HEAT_GENERATION_COMBIBOILER_HEATING",
  DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_BOILER = "DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_BOILER_GAS_APPLIANCES = "DEVICE_TYPE_HEAT_GENERATION_BOILER_GAS_APPLIANCES",
  DEVICE_TYPE_HEAT_GENERATION_COMBINED_BOILER = "DEVICE_TYPE_HEAT_GENERATION_COMBINED_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_SOLAR_PANELS = "DEVICE_TYPE_HEAT_GENERATION_SOLAR_PANELS",
  DEVICE_TYPE_HEAT_GENERATION_RADIATORS = "DEVICE_TYPE_HEAT_GENERATION_RADIATORS",
  DEVICE_TYPE_HEAT_GENERATION_RADIATORS_HEATWALLS = "DEVICE_TYPE_HEAT_GENERATION_RADIATORS_HEATWALLS",
  DEVICE_TYPE_HEAT_GENERATION_TOWEL_RADIATORS = "DEVICE_TYPE_HEAT_GENERATION_TOWEL_RADIATORS",
  DEVICE_TYPE_HEAT_GENERATION_FLOOR_HEATING = "DEVICE_TYPE_HEAT_GENERATION_FLOOR_HEATING",
  DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT = "DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT",
  DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STOVE = "DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STOVE",
  DEVICE_TYPE_HEAT_GENERATION_ROOF_HEATING = "DEVICE_TYPE_HEAT_GENERATION_ROOF_HEATING",
  DEVICE_TYPE_HEAT_DISTRIBUTION = "DEVICE_TYPE_HEAT_DISTRIBUTION",
  DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_METER = "DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_METER",
  DEVICE_TYPE_HEAT_DISTRIBUTION_CIRCULATING_PUMP = "DEVICE_TYPE_HEAT_DISTRIBUTION_CIRCULATING_PUMP",
  DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_QUANTITY_FLOW_METERS = "DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_QUANTITY_FLOW_METERS",
  DEVICE_TYPE_HEAT_DISTRIBUTION_HEATING_COST_ALLOCATOR = "DEVICE_TYPE_HEAT_DISTRIBUTION_HEATING_COST_ALLOCATOR",
  DEVICE_TYPE_VENTILATION_VENTILATION_CONTROL_CENTER = "DEVICE_TYPE_VENTILATION_VENTILATION_CONTROL_CENTER",
  DEVICE_TYPE_VENTILATION_VENTILATION = "DEVICE_TYPE_VENTILATION_VENTILATION",
  DEVICE_TYPE_VENTILATION_SUPPLY_AIR_FAN = "DEVICE_TYPE_VENTILATION_SUPPLY_AIR_FAN",
  DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN = "DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN",
  DEVICE_TYPE_VENTILATION_SMOKE_EXTRACTION_SYSTEM = "DEVICE_TYPE_VENTILATION_SMOKE_EXTRACTION_SYSTEM",
  DEVICE_TYPE_VENTILATION = "DEVICE_TYPE_VENTILATION",
  DEVICE_TYPE_VENTILATION_CONTROLLED_HOME = "DEVICE_TYPE_VENTILATION_CONTROLLED_HOME",
  DEVICE_TYPE_VENTILATION_CHANNELS = "DEVICE_TYPE_VENTILATION_CHANNELS",
  DEVICE_TYPE_AIR_CONDITIONING_RECOOLING = "DEVICE_TYPE_AIR_CONDITIONING_RECOOLING",
  DEVICE_TYPE_AIR_CONDITIONING = "DEVICE_TYPE_AIR_CONDITIONING",
  DEVICE_TYPE_AIR_CONDITIONING_INDIVIDUAL_ROOMS = "DEVICE_TYPE_AIR_CONDITIONING_INDIVIDUAL_ROOMS",
  DEVICE_TYPE_COOLING_CHILLERS = "DEVICE_TYPE_COOLING_CHILLERS",
  DEVICE_TYPE_COOLING_COLD_RESERVOIR = "DEVICE_TYPE_COOLING_COLD_RESERVOIR",
  DEVICE_TYPE_COOLING_COLD_WATER_TANK = "DEVICE_TYPE_COOLING_COLD_WATER_TANK",
  DEVICE_TYPE_COOLING_COLD_STORAGE_CELL = "DEVICE_TYPE_COOLING_COLD_STORAGE_CELL",
  DEVICE_TYPE_COOLING = "DEVICE_TYPE_COOLING",
  DEVICE_TYPE_SPECIAL_FACILITY = "DEVICE_TYPE_SPECIAL_FACILITY",
  DEVICE_TYPE_INSULATION = "DEVICE_TYPE_INSULATION",
  DEVICE_TYPE_INSULATION_CHIMNEY = "DEVICE_TYPE_INSULATION_CHIMNEY",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_BUILDING_MANAGEMENT_SYSTEM = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_BUILDING_MANAGEMENT_SYSTEM",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_CONTROL_SYSTEM = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_CONTROL_SYSTEM",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_VALVES = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_VALVES",
  DEVICE_TYPE_SANITARY_BATHTUB = "DEVICE_TYPE_SANITARY_BATHTUB",
  DEVICE_TYPE_SANITARY_RENOVATION = "DEVICE_TYPE_SANITARY_RENOVATION",
  DEVICE_TYPE_SANITARY_CONTROL_FITTINGS = "DEVICE_TYPE_SANITARY_CONTROL_FITTINGS",
  DEVICE_TYPE_SANITARY_WASHBASIN = "DEVICE_TYPE_SANITARY_WASHBASIN",
  DEVICE_TYPE_SANITARY_WASHBASIN_CERAMIC = "DEVICE_TYPE_SANITARY_WASHBASIN_CERAMIC",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET = "DEVICE_TYPE_SANITARY_MIRROR_CABINET",
  DEVICE_TYPE_SANITARY_BIDET = "DEVICE_TYPE_SANITARY_BIDET",
  DEVICE_TYPE_SANITARY_SHOWER = "DEVICE_TYPE_SANITARY_SHOWER",
  DEVICE_TYPE_SANITARY_LAVABO = "DEVICE_TYPE_SANITARY_LAVABO",
  DEVICE_TYPE_SANITARY_URINAL = "DEVICE_TYPE_SANITARY_URINAL",
  DEVICE_TYPE_SANITARY_FLUSH_TANK = "DEVICE_TYPE_SANITARY_FLUSH_TANK",
  DEVICE_TYPE_SANITARY_TOILET = "DEVICE_TYPE_SANITARY_TOILET",
  DEVICE_TYPE_SANITARY_TOILET_CERAMIC = "DEVICE_TYPE_SANITARY_TOILET_CERAMIC",
  DEVICE_TYPE_SANITARY_TOILET_CLOSOMAT = "DEVICE_TYPE_SANITARY_TOILET_CLOSOMAT",
  DEVICE_TYPE_SANITARY_TOILET_COVER = "DEVICE_TYPE_SANITARY_TOILET_COVER",
  DEVICE_TYPE_SANITARY_TOILET_COVER_SOFT_CLOSE = "DEVICE_TYPE_SANITARY_TOILET_COVER_SOFT_CLOSE",
  DEVICE_TYPE_SANITARY_BATHTUB_ACRYLIC = "DEVICE_TYPE_SANITARY_BATHTUB_ACRYLIC",
  DEVICE_TYPE_SANITARY_BATHTUB_STEEL = "DEVICE_TYPE_SANITARY_BATHTUB_STEEL",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET_PLASTIC = "DEVICE_TYPE_SANITARY_MIRROR_CABINET_PLASTIC",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET_WOODEN = "DEVICE_TYPE_SANITARY_MIRROR_CABINET_WOODEN",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET_METAL = "DEVICE_TYPE_SANITARY_MIRROR_CABINET_METAL",
  DEVICE_TYPE_SANITARY_FLUSH_TANK_FLUSH_MOUNTED = "DEVICE_TYPE_SANITARY_FLUSH_TANK_FLUSH_MOUNTED",
  DEVICE_TYPE_SANITARY_FLUSH_TANK_PLASTIC = "DEVICE_TYPE_SANITARY_FLUSH_TANK_PLASTIC",
  DEVICE_TYPE_SANITARY_FLUSH_TANK_CERAMIC = "DEVICE_TYPE_SANITARY_FLUSH_TANK_CERAMIC",
  DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL = "DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL",
  DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CERAMIC = "DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CERAMIC",
  DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CLOSOMAT = "DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CLOSOMAT",
  DEVICE_TYPE_SANITARY_SHOWER_CABIN = "DEVICE_TYPE_SANITARY_SHOWER_CABIN",
  DEVICE_TYPE_SANITARY_SHOWER_CABIN_PLASTIC = "DEVICE_TYPE_SANITARY_SHOWER_CABIN_PLASTIC",
  DEVICE_TYPE_SANITARY_SHOWER_CABIN_GLASWALLS = "DEVICE_TYPE_SANITARY_SHOWER_CABIN_GLASWALLS",
  DEVICE_TYPE_SPECIAL_SANITARY_WATER_SOFTENER = "DEVICE_TYPE_SPECIAL_SANITARY_WATER_SOFTENER",
  DEVICE_TYPE_SPECIAL_SANITARY_LIMESCALE_PROTECTION = "DEVICE_TYPE_SPECIAL_SANITARY_LIMESCALE_PROTECTION",
  DEVICE_TYPE_SPECIAL_SANITARY_DEHUMIDIFIER = "DEVICE_TYPE_SPECIAL_SANITARY_DEHUMIDIFIER",
  DEVICE_TYPE_SPECIAL_SANITARY_WASHING_MACHINE = "DEVICE_TYPE_SPECIAL_SANITARY_WASHING_MACHINE",
  DEVICE_TYPE_SPECIAL_SANITARY_COMBI_WASHER_DRYER = "DEVICE_TYPE_SPECIAL_SANITARY_COMBI_WASHER_DRYER",
  DEVICE_TYPE_SPECIAL_SANITARY_WASHING_ELECTRICITY_METER = "DEVICE_TYPE_SPECIAL_SANITARY_WASHING_ELECTRICITY_METER",
  DEVICE_TYPE_SPECIAL_SANITARY_TUMBLER = "DEVICE_TYPE_SPECIAL_SANITARY_TUMBLER",
  DEVICE_TYPE_SPECIAL_SANITARY_VACUUM_DEGASSING_DEVICE = "DEVICE_TYPE_SPECIAL_SANITARY_VACUUM_DEGASSING_DEVICE",
  DEVICE_TYPE_SPECIAL_SANITARY = "DEVICE_TYPE_SPECIAL_SANITARY",
  DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP = "DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SUPPLY = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SUPPLY",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_DISPOSAL = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_DISPOSAL",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_TREATMENT = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_TREATMENT",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_PUMP = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_PUMP",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_DISTRIBUTION_BATTERY = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_DISTRIBUTION_BATTERY",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_TANK = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_TANK",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_PIPING_COLD_WATER = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_PIPING_COLD_WATER",
  DEVICE_TYPE_SANITARY_PIPING_RAIN_WATER = "DEVICE_TYPE_SANITARY_PIPING_RAIN_WATER",
  DEVICE_TYPE_SANITARY_PIPING_WARM_WATER = "DEVICE_TYPE_SANITARY_PIPING_WARM_WATER",
  DEVICE_TYPE_SANITARY_PIPING = "DEVICE_TYPE_SANITARY_PIPING",
  DEVICE_TYPE_SANITARY_INSULATION = "DEVICE_TYPE_SANITARY_INSULATION",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_ONE_DOOR = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_ONE_DOOR",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_TWO_DOOR = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_TWO_DOOR",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR",
  DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE = "DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE",
  DEVICE_TYPE_SANITARY_EQUIPMENT_SHOWER_CABIN = "DEVICE_TYPE_SANITARY_EQUIPMENT_SHOWER_CABIN",
  DEVICE_TYPE_SANITARY_EQUIPMENT = "DEVICE_TYPE_SANITARY_EQUIPMENT",
  DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE = "DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE",
  DEVICE_TYPE_SANITARY_SHOWER_ROD = "DEVICE_TYPE_SANITARY_SHOWER_ROD",
  DEVICE_TYPE_SANITARY_SHOWER_ROD_CHROME_STEEL = "DEVICE_TYPE_SANITARY_SHOWER_ROD_CHROME_STEEL",
  DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOLDER = "DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOLDER",
  DEVICE_TYPE_ELECTRIC_PNEUMATIC_BOARD = "DEVICE_TYPE_ELECTRIC_PNEUMATIC_BOARD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR = "DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES = "DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES_COMPARTMENT = "DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES_COMPARTMENT",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_SEPARATING_SYSTEM = "DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_SEPARATING_SYSTEM",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_HOLDER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_HOLDER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_BLECH = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_BLECH",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRID = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRID",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_THERMOMETER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_THERMOMETER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRILL = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRILL",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_INDUCTION_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_INDUCTION_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_GLASS_CERAMIC_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_GLASS_CERAMIC_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_HOT_PLATES_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_HOT_PLATES_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_GAS_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_GAS_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_MINOR_KITCHEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_MINOR_KITCHEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FURNITURE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FURNITURE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_MICROWAVE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_MICROWAVE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STOVE_WITH_OVEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STOVE_WITH_OVEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_CARBON_FILTER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_CARBON_FILTER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_ALUMINIUM_FILTER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_ALUMINIUM_FILTER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRYER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRYER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COMBINATION_FURNITURE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COMBINATION_FURNITURE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_SEALING = "DEVICE_TYPE_KITCHEN_EQUIPMENT_SEALING",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_VENTILATION_GRILLE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_VENTILATION_GRILLE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT = "DEVICE_TYPE_KITCHEN_EQUIPMENT",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_CHROME_STEEL = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_CHROME_STEEL",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GRANITE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GRANITE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GLASS = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GLASS",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_SYNTHETIC_RESIN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_SYNTHETIC_RESIN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_WOODEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_WOODEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET = "DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_HIGH_GLOSS = "DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_HIGH_GLOSS",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_WOOD = "DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_WOOD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET = "DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_HIGH_GLOSS = "DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_HIGH_GLOSS",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_WOOD = "DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_WOOD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_CUTLERY = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_CUTLERY",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_BOTTLE_HOLDER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_BOTTLE_HOLDER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_SLIPS_MAT = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_SLIPS_MAT",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_CHROME_STEEL = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_CHROME_STEEL",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_PLASTIC = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_PLASTIC",
  DEVICE_TYPE_OTHER_SANITARY = "DEVICE_TYPE_OTHER_SANITARY",
  DEVICE_TYPE_ELEVATOR_HANDICAP_LIFT = "DEVICE_TYPE_ELEVATOR_HANDICAP_LIFT",
  DEVICE_TYPE_ELEVATOR_BED_LIFT = "DEVICE_TYPE_ELEVATOR_BED_LIFT",
  DEVICE_TYPE_ELEVATOR = "DEVICE_TYPE_ELEVATOR",
  DEVICE_TYPE_ELEVATOR_MERCHANDISE_LIFT = "DEVICE_TYPE_ELEVATOR_MERCHANDISE_LIFT",
  DEVICE_TYPE_MOVING_STAIRCASE = "DEVICE_TYPE_MOVING_STAIRCASE",
  DEVICE_TYPE_FACADE_CLEANING = "DEVICE_TYPE_FACADE_CLEANING",
  DEVICE_TYPE_GOODS_CONVEYING_OTHER = "DEVICE_TYPE_GOODS_CONVEYING_OTHER",
  DEVICE_TYPE_GOODS_CONVEYING_PNEUMATIC_TUBE = "DEVICE_TYPE_GOODS_CONVEYING_PNEUMATIC_TUBE",
  DEVICE_TYPE_GOODS_CONVEYING_FILE_LIFT = "DEVICE_TYPE_GOODS_CONVEYING_FILE_LIFT",
  DEVICE_TYPE_GOODS_CONVEYING = "DEVICE_TYPE_GOODS_CONVEYING",
  DEVICE_TYPE_LIFTING = "DEVICE_TYPE_LIFTING",
  DEVICE_TYPE_PARKING = "DEVICE_TYPE_PARKING",
  DEVICE_TYPE_TRANSPORT_OTHER = "DEVICE_TYPE_TRANSPORT_OTHER",
  DEVICE_TYPE_PLASTERER = "DEVICE_TYPE_PLASTERER",
  DEVICE_TYPE_METAL = "DEVICE_TYPE_METAL",
  DEVICE_TYPE_METAL_CAR_SHELTER = "DEVICE_TYPE_METAL_CAR_SHELTER",
  DEVICE_TYPE_METAL_MAILBOX = "DEVICE_TYPE_METAL_MAILBOX",
  DEVICE_TYPE_METAL_BALCONY_ENLARGEMENT = "DEVICE_TYPE_METAL_BALCONY_ENLARGEMENT",
  DEVICE_TYPE_CARPENTER_SCREED_EXTENSION_LADDER = "DEVICE_TYPE_CARPENTER_SCREED_EXTENSION_LADDER",
  DEVICE_TYPE_CARPENTER_CELLAR_DOOR = "DEVICE_TYPE_CARPENTER_CELLAR_DOOR",
  DEVICE_TYPE_CARPENTER_APARTMENT_DOOR = "DEVICE_TYPE_CARPENTER_APARTMENT_DOOR",
  DEVICE_TYPE_CARPENTER_DOOR = "DEVICE_TYPE_CARPENTER_DOOR",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME = "DEVICE_TYPE_CARPENTER_DOOR_FRAME",
  DEVICE_TYPE_CARPENTER_LOCKS = "DEVICE_TYPE_CARPENTER_LOCKS",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS",
  DEVICE_TYPE_CARPENTER_SLIDING_DOOR = "DEVICE_TYPE_CARPENTER_SLIDING_DOOR",
  DEVICE_TYPE_CARPENTER = "DEVICE_TYPE_CARPENTER",
  DEVICE_TYPE_CARPENTER_DOOR_WOODEN = "DEVICE_TYPE_CARPENTER_DOOR_WOODEN",
  DEVICE_TYPE_CARPENTER_DOOR_WOOD_MATERIAL = "DEVICE_TYPE_CARPENTER_DOOR_WOOD_MATERIAL",
  DEVICE_TYPE_CARPENTER_DOOR_METAL = "DEVICE_TYPE_CARPENTER_DOOR_METAL",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_WOOD = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_WOOD",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL_STONE = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL_STONE",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL",
  DEVICE_TYPE_CARPENTER_LOCKS_APARTMENT_DOOR = "DEVICE_TYPE_CARPENTER_LOCKS_APARTMENT_DOOR",
  DEVICE_TYPE_CARPENTER_LOCKS_ROOM_DOOR = "DEVICE_TYPE_CARPENTER_LOCKS_ROOM_DOOR",
  DEVICE_TYPE_CARPENTER_LOCKS_KITT_JOINT = "DEVICE_TYPE_CARPENTER_LOCKS_KITT_JOINT",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_CHANNELBOARDS = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_CHANNELBOARDS",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_WOODEN = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_WOODEN",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_HIGH_GLOSS = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_HIGH_GLOSS",
  DEVICE_TYPE_CARPENTER_SLIDING_DOOR_WOODEN_MATERIAL = "DEVICE_TYPE_CARPENTER_SLIDING_DOOR_WOODEN_MATERIAL",
  DEVICE_TYPE_CARPENTER_SLIDING_DOOR_ROLLS = "DEVICE_TYPE_CARPENTER_SLIDING_DOOR_ROLLS",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_COLOR_OIL = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_COLOR_OIL",
  DEVICE_TYPE_CARPENTER_GLASS_INSERT_DOOR = "DEVICE_TYPE_CARPENTER_GLASS_INSERT_DOOR",
  DEVICE_TYPE_CARPENTER_FITTINGS_FOR_DOOR = "DEVICE_TYPE_CARPENTER_FITTINGS_FOR_DOOR",
  DEVICE_TYPE_CARPENTER_RUBBER_SEALS_DOOR = "DEVICE_TYPE_CARPENTER_RUBBER_SEALS_DOOR",
  DEVICE_TYPE_CARPENTER_SHELF = "DEVICE_TYPE_CARPENTER_SHELF",
  DEVICE_TYPE_CARPENTER_SHELF_WOOD = "DEVICE_TYPE_CARPENTER_SHELF_WOOD",
  DEVICE_TYPE_CARPENTER_SHELF_METAL = "DEVICE_TYPE_CARPENTER_SHELF_METAL",
  DEVICE_TYPE_CARPENTER_CLOSET = "DEVICE_TYPE_CARPENTER_CLOSET",
  DEVICE_TYPE_CARPENTER_CLOSET_WOOD = "DEVICE_TYPE_CARPENTER_CLOSET_WOOD",
  DEVICE_TYPE_CARPENTER_CLOSET_METAL = "DEVICE_TYPE_CARPENTER_CLOSET_METAL",
  DEVICE_TYPE_WINDOW_DOUBLE_GLAZING = "DEVICE_TYPE_WINDOW_DOUBLE_GLAZING",
  DEVICE_TYPE_WINDOW_INSULATING_GLAZING = "DEVICE_TYPE_WINDOW_INSULATING_GLAZING",
  DEVICE_TYPE_WINDOW_FITTINGS = "DEVICE_TYPE_WINDOW_FITTINGS",
  DEVICE_TYPE_WINDOW_WINDOW_FRAME = "DEVICE_TYPE_WINDOW_WINDOW_FRAME",
  DEVICE_TYPE_WINDOW_SPECIAL_GLAZING = "DEVICE_TYPE_WINDOW_SPECIAL_GLAZING",
  DEVICE_TYPE_WINDOW_WINDOW_SILL = "DEVICE_TYPE_WINDOW_WINDOW_SILL",
  DEVICE_TYPE_WINDOW = "DEVICE_TYPE_WINDOW",
  DEVICE_TYPE_WINDOW_DOUBLE = "DEVICE_TYPE_WINDOW_DOUBLE",
  DEVICE_TYPE_WINDOW_TRIPLE = "DEVICE_TYPE_WINDOW_TRIPLE",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD = "DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD_PAINTED = "DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD_PAINTED",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_TILES = "DEVICE_TYPE_WINDOW_WINDOW_SILL_TILES",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_STONE = "DEVICE_TYPE_WINDOW_WINDOW_SILL_STONE",
  DEVICE_TYPE_LOCKING_LOCK = "DEVICE_TYPE_LOCKING_LOCK",
  DEVICE_TYPE_LOCKING_KEY = "DEVICE_TYPE_LOCKING_KEY",
  DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER = "DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER",
  DEVICE_TYPE_LOCKING = "DEVICE_TYPE_LOCKING",
  DEVICE_TYPE_INTERIOR_FINISHES = "DEVICE_TYPE_INTERIOR_FINISHES",
  DEVICE_TYPE_ELEMENT_WALL = "DEVICE_TYPE_ELEMENT_WALL",
  DEVICE_TYPE_CHROME_STEEP_FINISH = "DEVICE_TYPE_CHROME_STEEP_FINISH",
  DEVICETYPEOTHERINTERIOR1 = "DEVICE_TYPE_OTHER_INTERIOR1",
  DEVICE_TYPE_FLOORING_COVERING = "DEVICE_TYPE_FLOORING_COVERING",
  DEVICE_TYPE_FLOORING_BALL_YARN = "DEVICE_TYPE_FLOORING_BALL_YARN",
  DEVICE_TYPE_FLOORING_LAMINATE = "DEVICE_TYPE_FLOORING_LAMINATE",
  DEVICE_TYPE_FLOORING_LINOLEUM = "DEVICE_TYPE_FLOORING_LINOLEUM",
  DEVICE_TYPE_FLOORING_VINYL = "DEVICE_TYPE_FLOORING_VINYL",
  DEVICE_TYPE_FLOORING_DIRT_TRAP_CARPET = "DEVICE_TYPE_FLOORING_DIRT_TRAP_CARPET",
  DEVICE_TYPE_FLOORING_PARQUET = "DEVICE_TYPE_FLOORING_PARQUET",
  DEVICE_TYPE_FLOORING_PARQUET_SEALING = "DEVICE_TYPE_FLOORING_PARQUET_SEALING",
  DEVICE_TYPE_FLOORING_FLOOR_UNDERLAY = "DEVICE_TYPE_FLOORING_FLOOR_UNDERLAY",
  DEVICE_TYPE_FLOORING_INSTALLATION_FLOOR = "DEVICE_TYPE_FLOORING_INSTALLATION_FLOOR",
  DEVICE_TYPE_FLOORING_TILES = "DEVICE_TYPE_FLOORING_TILES",
  DEVICE_TYPE_FLOORING_CARPET = "DEVICE_TYPE_FLOORING_CARPET",
  DEVICE_TYPE_FLOORING_SKIRTING = "DEVICE_TYPE_FLOORING_SKIRTING",
  DEVICE_TYPE_FLOORING_JOINT = "DEVICE_TYPE_FLOORING_JOINT",
  DEVICE_TYPE_FLOORING = "DEVICE_TYPE_FLOORING",
  DEVICE_TYPE_FLOORING_PVC = "DEVICE_TYPE_FLOORING_PVC",
  DEVICE_TYPE_FLOORING_RUBBER = "DEVICE_TYPE_FLOORING_RUBBER",
  DEVICE_TYPE_FLOORING_CORK = "DEVICE_TYPE_FLOORING_CORK",
  DEVICE_TYPE_FLOORING_LAMINATE_MEDIUM_QUALITY = "DEVICE_TYPE_FLOORING_LAMINATE_MEDIUM_QUALITY",
  DEVICE_TYPE_FLOORING_LAMINATE_HIGH_QUALITY = "DEVICE_TYPE_FLOORING_LAMINATE_HIGH_QUALITY",
  DEVICE_TYPE_FLOORING_TILES_SOUND_PLATES = "DEVICE_TYPE_FLOORING_TILES_SOUND_PLATES",
  DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_SOFT = "DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_SOFT",
  DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_HARD = "DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_HARD",
  DEVICE_TYPE_FLOORING_TILES_CERAMIC = "DEVICE_TYPE_FLOORING_TILES_CERAMIC",
  DEVICE_TYPE_FLOORING_TILES_STONEWARE = "DEVICE_TYPE_FLOORING_TILES_STONEWARE",
  DEVICE_TYPE_FLOORING_TILES_ARTIFICIAL_STONE = "DEVICE_TYPE_FLOORING_TILES_ARTIFICIAL_STONE",
  DEVICE_TYPE_FLOORING_PARQUET_ADHESIVE = "DEVICE_TYPE_FLOORING_PARQUET_ADHESIVE",
  DEVICE_TYPE_FLOORING_PARQUET_HARDWOOD = "DEVICE_TYPE_FLOORING_PARQUET_HARDWOOD",
  DEVICE_TYPE_FLOORING_PARQUET_SOFTWOOD = "DEVICE_TYPE_FLOORING_PARQUET_SOFTWOOD",
  DEVICE_TYPE_FLOORING_PARQUET_FOURNIER = "DEVICE_TYPE_FLOORING_PARQUET_FOURNIER",
  DEVICE_TYPE_FLOORING_CARPET_NATURAL_FIBER = "DEVICE_TYPE_FLOORING_CARPET_NATURAL_FIBER",
  DEVICE_TYPE_FLOORING_CARPET_BALL_YARN = "DEVICE_TYPE_FLOORING_CARPET_BALL_YARN",
  DEVICE_TYPE_FLOORING_CARPET_NEEDLE_FELT = "DEVICE_TYPE_FLOORING_CARPET_NEEDLE_FELT",
  DEVICE_TYPE_FLOORING_CARPET_MEDIUM_QUALITY = "DEVICE_TYPE_FLOORING_CARPET_MEDIUM_QUALITY",
  DEVICE_TYPE_FLOORING_SKIRTING_PLASTIC = "DEVICE_TYPE_FLOORING_SKIRTING_PLASTIC",
  DEVICE_TYPE_FLOORING_SKIRTING_WOOD = "DEVICE_TYPE_FLOORING_SKIRTING_WOOD",
  DEVICE_TYPE_FLOORING_RAW = "DEVICE_TYPE_FLOORING_RAW",
  DEVICE_TYPE_FLOORING_COATED = "DEVICE_TYPE_FLOORING_COATED",
  DEVICE_TYPE_WALL_COVERING_WORK = "DEVICE_TYPE_WALL_COVERING_WORK",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER = "DEVICE_TYPE_WALL_COVERING_WALLPAPER",
  DEVICE_TYPE_WALL_COVERING_PLASTER = "DEVICE_TYPE_WALL_COVERING_PLASTER",
  DEVICE_TYPE_WALL_COVERING_ABRASION = "DEVICE_TYPE_WALL_COVERING_ABRASION",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER",
  DEVICE_TYPE_WALL_COVERING = "DEVICE_TYPE_WALL_COVERING",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER_MEDIUM_QUALITY = "DEVICE_TYPE_WALL_COVERING_WALLPAPER_MEDIUM_QUALITY",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER_GOOD_QUALITY = "DEVICE_TYPE_WALL_COVERING_WALLPAPER_GOOD_QUALITY",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER_GLASS_FIBER = "DEVICE_TYPE_WALL_COVERING_WALLPAPER_GLASS_FIBER",
  DEVICE_TYPE_WALL_COVERING_PLASTER_GLUE_COLOR = "DEVICE_TYPE_WALL_COVERING_PLASTER_GLUE_COLOR",
  DEVICE_TYPE_WALL_COVERING_PLASTER_ACRYL = "DEVICE_TYPE_WALL_COVERING_PLASTER_ACRYL",
  DEVICE_TYPE_WALL_COVERING_PLASTER_MINERALS = "DEVICE_TYPE_WALL_COVERING_PLASTER_MINERALS",
  DEVICE_TYPE_WALL_COVERING_PLASTER_PLASTIC = "DEVICE_TYPE_WALL_COVERING_PLASTER_PLASTIC",
  DEVICE_TYPE_WALL_COVERING_PLASTER_SILICATE_COATING = "DEVICE_TYPE_WALL_COVERING_PLASTER_SILICATE_COATING",
  DEVICE_TYPE_WALL_COVERING_PLASTER_DISPERSION_PAINT = "DEVICE_TYPE_WALL_COVERING_PLASTER_DISPERSION_PAINT",
  DEVICE_TYPE_WALL_COVERING_ABRASION_PLASTIC = "DEVICE_TYPE_WALL_COVERING_ABRASION_PLASTIC",
  DEVICE_TYPE_WALL_COVERING_ABRASION_ROUGH_PUTT = "DEVICE_TYPE_WALL_COVERING_ABRASION_ROUGH_PUTT",
  DEVICE_TYPE_WALL_COVERING_ABRASION_WHITE_PLASTER = "DEVICE_TYPE_WALL_COVERING_ABRASION_WHITE_PLASTER",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_WALL_ROUGH = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_WALL_ROUGH",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_LASED = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_LASED",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_PAINTED_OPAQUE = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_PAINTED_OPAQUE",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_GLAZE = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_GLAZE",
  DEVICE_TYPE_WALL_COVERING_CERAMIC = "DEVICE_TYPE_WALL_COVERING_CERAMIC",
  DEVICE_TYPE_WALL_COVERING_STONEWORK = "DEVICE_TYPE_WALL_COVERING_STONEWORK",
  DEVICE_TYPE_WALL_COVERING_STONEWARE = "DEVICE_TYPE_WALL_COVERING_STONEWARE",
  DEVICE_TYPE_WALL_COVERING_TILES = "DEVICE_TYPE_WALL_COVERING_TILES",
  DEVICE_TYPE_CEILING_WORK = "DEVICE_TYPE_CEILING_WORK",
  DEVICE_TYPE_CEILING_WALLPAPER = "DEVICE_TYPE_CEILING_WALLPAPER",
  DEVICE_TYPE_CEILING_PLASTER = "DEVICE_TYPE_CEILING_PLASTER",
  DEVICE_TYPE_CEILING_ABRASION = "DEVICE_TYPE_CEILING_ABRASION",
  DEVICE_TYPE_CEILING_WOOD_WORKER = "DEVICE_TYPE_CEILING_WOOD_WORKER",
  DEVICE_TYPE_CEILING = "DEVICE_TYPE_CEILING",
  DEVICE_TYPE_CEILING_REGULAR = "DEVICE_TYPE_CEILING_REGULAR",
  DEVICE_TYPE_CEILING_REGULAR_METAL = "DEVICE_TYPE_CEILING_REGULAR_METAL",
  DEVICE_TYPE_CEILING_REGULAR_WOOD = "DEVICE_TYPE_CEILING_REGULAR_WOOD",
  DEVICE_TYPE_CEILING_COVERING_ABRASION_PLASTIC = "DEVICE_TYPE_CEILING_COVERING_ABRASION_PLASTIC",
  DEVICE_TYPE_CEILING_COVERING_ABRASION_ROUGH_PUTT = "DEVICE_TYPE_CEILING_COVERING_ABRASION_ROUGH_PUTT",
  DEVICE_TYPE_CEILING_COVERING_ABRASION_WHITE_PLASTER = "DEVICE_TYPE_CEILING_COVERING_ABRASION_WHITE_PLASTER",
  DEVICE_TYPE_INTERIOR_SURFACE_TREATMENTS_PAINTING = "DEVICE_TYPE_INTERIOR_SURFACE_TREATMENTS_PAINTING",
  DEVICETYPEOTHERINTERIOR2SHUTTER = "DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER",
  DEVICETYPEOTHERINTERIOR2BLADE = "DEVICE_TYPE_OTHER_INTERIOR2_BLADE",
  DEVICETYPEOTHERINTERIOR2BLIND = "DEVICE_TYPE_OTHER_INTERIOR2_BLIND",
  DEVICETYPEOTHERINTERIOR2LETTERBOX = "DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX",
  DEVICETYPEOTHERINTERIOR2 = "DEVICE_TYPE_OTHER_INTERIOR2",
  DEVICETYPEOTHERINTERIOR2SETS = "DEVICE_TYPE_OTHER_INTERIOR2_SETS",
  DEVICETYPEOTHERINTERIOR2SETSSOAPHOLDER = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_SOAP_HOLDER",
  DEVICETYPEOTHERINTERIOR2SETSTOOTHGLASS = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOOTH_GLASS",
  DEVICETYPEOTHERINTERIOR2SETSGLASSSHELF = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_GLASS_SHELF",
  DEVICETYPEOTHERINTERIOR2SETSTOWELBARS = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOWEL_BARS",
  DEVICETYPEOTHERINTERIOR2SETSHOLDINGRACK = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_HOLDING_RACK",
  DEVICETYPEOTHERINTERIOR2SETSCURTAINROD = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_CURTAIN_ROD",
  DEVICETYPEOTHERINTERIOR2SETSPACKAGE = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PACKAGE",
  DEVICETYPEOTHERINTERIOR2SETSWASHBASINBOWL = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_WASHBASIN_BOWL",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDER = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDERCHROMESTEEL = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_CHROME_STEEL",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDERPLASTIC = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_PLASTIC",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDERHOOK = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_HOOK",
  DEVICETYPEOTHERINTERIOR2SETSPAPERHOLDER = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER",
  DEVICETYPEOTHERINTERIOR2SETSPAPERHOLDERCHROMESTEEL = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER_CHROME_STEEL",
  DEVICETYPEOTHERINTERIOR2SETSPAPERHOLDERPLASTIC = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER_PLASTIC",
  DEVICETYPEOTHERINTERIOR2ARMATURE = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE",
  DEVICETYPEOTHERINTERIOR2ARMATURESINGLELEVER = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_SINGLE_LEVER",
  DEVICETYPEOTHERINTERIOR2DOUBLELEVER = "DEVICE_TYPE_OTHER_INTERIOR2_DOUBLE_LEVER",
  DEVICETYPEOTHERINTERIOR2ARMATURESWIVEL = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_SWIVEL",
  DEVICETYPEOTHERINTERIOR2ARMATUREPULLOUTSPRAY = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_PULL_OUT_SPRAY",
  DEVICETYPEOTHERINTERIOR2ARMATURETHERMALMIXER = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_THERMAL_MIXER",
  DEVICETYPEOTHERINTERIOR2OPERATINGGARNITURE = "DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE",
  DEVICETYPEOTHERINTERIOR2CRANK = "DEVICE_TYPE_OTHER_INTERIOR2_CRANK",
  DEVICETYPEOTHERINTERIOR2BELT = "DEVICE_TYPE_OTHER_INTERIOR2_BELT",
  DEVICETYPEOTHERINTERIOR2ELECTRIC = "DEVICE_TYPE_OTHER_INTERIOR2_ELECTRIC",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_GREEN = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_GREEN",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_BLACK = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_BLACK",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_METAL = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_METAL",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_OUTDOOR_PARKING = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_OUTDOOR_PARKING",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_PLANTING = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_PLANTING",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_CONTAINER_SITE = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_CONTAINER_SITE",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_BIKE_SHELTER = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_BIKE_SHELTER",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_ACCESS_PATH = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_ACCESS_PATH",
  DEVICE_TYPE_GARDENING_FENCE = "DEVICE_TYPE_GARDENING_FENCE",
  DEVICE_TYPE_GARDENING_WALLS = "DEVICE_TYPE_GARDENING_WALLS",
  DEVICE_TYPE_GARDENING_CEMENT_BOARD = "DEVICE_TYPE_GARDENING_CEMENT_BOARD",
  DEVICE_TYPE_GARDENING_ACCESS_PATH = "DEVICE_TYPE_GARDENING_ACCESS_PATH",
  DEVICE_TYPE_GARDENING_LAWN = "DEVICE_TYPE_GARDENING_LAWN",
  DEVICE_TYPE_GARDENING = "DEVICE_TYPE_GARDENING",
  DEVICE_TYPE_FENCING = "DEVICE_TYPE_FENCING",
  DEVICE_TYPE_SUPERSTRUCTURE_TERRANCE = "DEVICE_TYPE_SUPERSTRUCTURE_TERRANCE",
  DEVICE_TYPE_SUPERSTRUCTURE_BIKE_STAND = "DEVICE_TYPE_SUPERSTRUCTURE_BIKE_STAND",
  DEVICE_TYPE_SUPERSTRUCTURE = "DEVICE_TYPE_SUPERSTRUCTURE",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_SAFETY_GLASS = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_SAFETY_GLASS",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_METAL = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_METAL",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CHROME_STEEL = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CHROME_STEEL",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CONCRETE = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CONCRETE",
  DEVICE_TYPE_PLAYGROUND = "DEVICE_TYPE_PLAYGROUND",
  DEVICE_TYPE_PLAYGROUND_DEVICE = "DEVICE_TYPE_PLAYGROUND_DEVICE",
  DEVICE_TYPE_GARDEN_OTHER = "DEVICE_TYPE_GARDEN_OTHER",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_FIRE_EXTINGUISHER = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_FIRE_EXTINGUISHER",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_LAWN_MOWER = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_LAWN_MOWER",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_SECURITY_CAMERA = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_SECURITY_CAMERA",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_INDOOR_BIKE_SHELTER = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_INDOOR_BIKE_SHELTER",
}

export interface DeviceGetDefaultDataResponse {
  data: DeviceDefaultDataEntry[];
}

export interface DeviceInspection {
  contact: ContactcommonContactReference;
  contract: string;
  costs: ObjectdeviceCosts;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  lastOfficialControl: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  nextOfficialControl: TypeDate;
  note: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  subscriptionEnd: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  subscriptionStart: TypeDate;
}

export interface DeviceLockingKey {
  combinedKeys: boolean;
  combinedKeysNote: string;
  description: string;
  lockingPlanNumber: string;
}

export interface DeviceMaintenance {
  contact: ContactcommonContactReference;
  contract: string;
  costs: ObjectdeviceCosts;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  lastMaintenance: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  nextMaintenance: TypeDate;
  note: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  subscriptionEnd: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  subscriptionStart: TypeDate;
}

export interface DeviceRevision {
  contact: ContactcommonContactReference;
  contract: string;
  costs: ObjectdeviceCosts;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  lastOfficialRevision: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  nextOfficialRevision: TypeDate;
  note: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  subscriptionEnd: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  subscriptionStart: TypeDate;
}

export type DeviceUpsertHandoverDeviceDataResponse = object;

/** @default "COST_DISTRIBUTION_UNDEFINED" */
export enum EnergySourceCostDistribution {
  COST_DISTRIBUTION_UNDEFINED = "COST_DISTRIBUTION_UNDEFINED",
  COST_DISTRIBUTION_NONE = "COST_DISTRIBUTION_NONE",
  COST_DISTRIBUTION_CONSUMPTION = "COST_DISTRIBUTION_CONSUMPTION",
  COST_DISTRIBUTION_FIXED_KEY = "COST_DISTRIBUTION_FIXED_KEY",
}

/** @default "ENERGY_SOURCE_KIND_UNDEFINED" */
export enum EnergySourceEnergySourceKind {
  ENERGY_SOURCE_KIND_UNDEFINED = "ENERGY_SOURCE_KIND_UNDEFINED",
  ENERGY_SOURCE_KIND_HEATER = "ENERGY_SOURCE_KIND_HEATER",
  ENERGY_SOURCE_KIND_HOT_WATER = "ENERGY_SOURCE_KIND_HOT_WATER",
  ENERGY_SOURCE_KIND_HEATER_AND_HOT_WATER = "ENERGY_SOURCE_KIND_HEATER_AND_HOT_WATER",
}

/** @default "HEAT_DISTRIBUTION_UNDEFINED" */
export enum EnergySourceHeatDistribution {
  HEAT_DISTRIBUTION_UNDEFINED = "HEAT_DISTRIBUTION_UNDEFINED",
  HEAT_DISTRIBUTION_RADIATORS = "HEAT_DISTRIBUTION_RADIATORS",
  HEAT_DISTRIBUTION_FLOOR_HEATING = "HEAT_DISTRIBUTION_FLOOR_HEATING",
  HEAT_DISTRIBUTION_CEILING_HEATING = "HEAT_DISTRIBUTION_CEILING_HEATING",
}

export interface EnergySourceOrder {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  date: TypeDate;
  documentIds: string[];
  /** @format float */
  quantity: number;
}

export interface FahrlaenderConfiguration {
  /** @format int64 */
  accountId: string;
  apiPassword: string;
  apiUser: string;
  features: FahrlaenderFahrlaenderFeatures[];
}

/** @default "FAHRLAENDER_FEATURES_UNDEFINED" */
export enum FahrlaenderFahrlaenderFeatures {
  FAHRLAENDER_FEATURES_UNDEFINED = "FAHRLAENDER_FEATURES_UNDEFINED",
  FAHRLAENDER_FEATURES_MICRO_LOCATION = "FAHRLAENDER_FEATURES_MICRO_LOCATION",
  FAHRLAENDER_FEATURES_MARKET_RENT = "FAHRLAENDER_FEATURES_MARKET_RENT",
}

export interface FahrlaenderLoadConfigurationResponse {
  configuration: FahrlaenderConfiguration;
}

export type FahrlaenderSaveConfigurationResponse = object;

export interface FahrlaenderUpdateMarketRentResponse {
  /** @format int32 */
  commandsPublished: number;
}

export interface FahrlaenderUpdateMicroLocationRatingsResponse {
  /** @format int32 */
  commandsPublished: number;
}

export interface GroupGroup {
  buildingIds: string[];
  id: string;
  name: string;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
}

export interface GroupRemoveBuildingResponse {
  group: GroupGroup;
}

/** @default "ACTION_UNDEFINED" */
export enum HandoverProtocolAction {
  ACTION_UNDEFINED = "ACTION_UNDEFINED",
  ACTION_REPAIR = "ACTION_REPAIR",
  ACTION_REPLACE = "ACTION_REPLACE",
  ACTION_DEPRECIATION = "ACTION_DEPRECIATION",
}

/** @default "ATTACH_TO_HANDOVER_ONLY" */
export enum HandoverProtocolAttachTo {
  ATTACH_TO_HANDOVER_ONLY = "ATTACH_TO_HANDOVER_ONLY",
  ATTACH_TO_DEVICE_ONLY = "ATTACH_TO_DEVICE_ONLY",
  ATTACH_TO_HANDOVER_AND_DEVICE = "ATTACH_TO_HANDOVER_AND_DEVICE",
}

/** @default "CLEANLINESS_ROOM_UNDEFINED" */
export enum HandoverProtocolCleanlinessRoom {
  CLEANLINESS_ROOM_UNDEFINED = "CLEANLINESS_ROOM_UNDEFINED",
  CLEANLINESS_ROOM_PERFECT = "CLEANLINESS_ROOM_PERFECT",
  CLEANLINESS_ROOM_SLIGHTLY_SOILED = "CLEANLINESS_ROOM_SLIGHTLY_SOILED",
  CLEANLINESS_ROOM_HEAVILY_SOILED = "CLEANLINESS_ROOM_HEAVILY_SOILED",
}

/** @default "CLEANLINESS_ROOM_ACTION_UNDEFINED" */
export enum HandoverProtocolCleanlinessRoomAction {
  CLEANLINESS_ROOM_ACTION_UNDEFINED = "CLEANLINESS_ROOM_ACTION_UNDEFINED",
  CLEANLINESS_ROOM_ACTION_TENANT = "CLEANLINESS_ROOM_ACTION_TENANT",
  CLEANLINESS_ROOM_ACTION_SERVICE_PROVIDER = "CLEANLINESS_ROOM_ACTION_SERVICE_PROVIDER",
}

export interface HandoverProtocolGetPdfPreviewResponse {
  /** @format byte */
  pdf: string;
}

export interface HandoverProtocolHandoverProtocol {
  assigneeContactId: string;
  creator: ObjecthandoverProtocolCreator;
  devicesWithoutRoom: ObjecthandoverProtocolDevice[];
  finished: boolean;
  id: string;
  movingInTenancyReferences: HandoverProtocolTenancyReference[];
  movingOutTenancyReferences: HandoverProtocolTenancyReference[];
  note: string;
  /** @format int64 */
  ownerId: string;
  participants: ObjecthandoverProtocolParticipant[];
  protocolMediaId: string;
  rooms: ObjecthandoverProtocolRoom[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  scheduledDate: TypeDate;
  /**
   * Represents a time of day. The date and time zone are either not significant
   * or are specified elsewhere. An API may choose to allow leap seconds. Related
   * types are [google.type.Date][google.type.Date] and
   * `google.protobuf.Timestamp`.
   */
  scheduledTime: TypeTimeOfDay;
}

export interface HandoverProtocolKeyData {
  /** @format int32 */
  amount: number;
  combinedKeys: boolean;
  combinedKeysDescription: string;
  description: string;
  keyNumber: string;
  lockingPlanNumber: string;
  /** @format int32 */
  returnedAmount: number;
}

export interface HandoverProtocolListMyProtocolsResponse {
  protocols: HandoverProtocolHandoverProtocol[];
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalProtocols: number;
}

export interface HandoverProtocolListOtherProtocolsResponse {
  protocols: HandoverProtocolHandoverProtocol[];
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalProtocols: number;
}

export interface HandoverProtocolObjectData {
  address: CommonaddressAddress;
  name: string;
  /** @format int64 */
  numericId: string;
}

/** @default "PARTICIPANT_TYPE_UNDEFINED" */
export enum HandoverProtocolParticipantType {
  PARTICIPANT_TYPE_UNDEFINED = "PARTICIPANT_TYPE_UNDEFINED",
  PARTICIPANT_TYPE_ADMINISTRATOR = "PARTICIPANT_TYPE_ADMINISTRATOR",
  PARTICIPANT_TYPE_TENANT_MOVE_OUT = "PARTICIPANT_TYPE_TENANT_MOVE_OUT",
  PARTICIPANT_TYPE_TENANT_MOVE_IN = "PARTICIPANT_TYPE_TENANT_MOVE_IN",
  PARTICIPANT_TYPE_THIRD_PARTY_MOVE_OUT = "PARTICIPANT_TYPE_THIRD_PARTY_MOVE_OUT",
  PARTICIPANT_TYPE_THIRD_PARTY_MOVE_IN = "PARTICIPANT_TYPE_THIRD_PARTY_MOVE_IN",
}

export interface HandoverProtocolSaveDataDevice {
  action: HandoverProtocolAction;
  condition: ObjecthandoverProtocolCondition;
  costAbsorption: CostCostAbsorption;
  costShareUnit: CostCostShareUnit;
  /** @format double */
  costShareValue: number;
  deleted: boolean;
  /** Leave empty for new devices. */
  id: string;
  images: ObjecthandoverProtocolImage[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  installationDate: TypeDate;
  keyData: HandoverProtocolSaveDataKeyData;
  note: string;
  type: DeviceDeviceType;
}

export interface HandoverProtocolSaveDataKeyData {
  /** @format int32 */
  amount: number;
  combinedKeys: boolean;
  combinedKeysDescription: string;
  description: string;
  keyNumber: string;
  lockingPlanNumber: string;
  /** @format int32 */
  returnedAmount: number;
}

export interface HandoverProtocolSaveDataParticipant {
  address: CommonaddressAddress;
  attending: boolean;
  /** Must only be set if new person should be contact person of company otherwise leave empty. */
  companyContactId: string;
  /** Leave empty for new contacts. Use mock_<number> for new companies to reference them for new contact persons. */
  contactId: string;
  email: string;
  /** Use only if person/contact person. */
  firstName: string;
  /** Use only if person/contact person. */
  lastName: string;
  /** Use only if company */
  name: string;
  phoneNumbers: Contactv2PhoneNumber[];
  powerOfAttorneyMediaIds: string[];
  type: HandoverProtocolParticipantType;
}

export interface HandoverProtocolSaveDataResponse {
  protocol: HandoverProtocolHandoverProtocol;
}

export interface HandoverProtocolSaveDataRoom {
  cleanliness: HandoverProtocolCleanlinessRoom;
  cleanlinessAction: HandoverProtocolCleanlinessRoomAction;
  /** @format double */
  cleanlinessCost: number;
  cleanlinessCostUnit: CostCostShareUnit;
  deleted: boolean;
  devices: HandoverProtocolSaveDataDevice[];
  /** Leave empty for new rooms. */
  id: string;
  name: string;
  note: string;
}

export interface HandoverProtocolSignResponse {
  protocol: HandoverProtocolHandoverProtocol;
}

export interface HandoverProtocolTenancyData {
  contactIds: string[];
  /** Date when tenancy ends. */
  endingAt: TypeDate;
  /** Date when tenancy starts. */
  startingAt: TypeDate;
}

export interface HandoverProtocolTenancyReference {
  objectData: HandoverProtocolObjectData;
  objectId: string;
  tenancyData: HandoverProtocolTenancyData;
  tenancyId: string;
}

export interface HandoverProtocolWriteParticipant {
  attending: boolean;
  contactId: string;
  powerOfAttorneyMediaIds: string[];
  signed: boolean;
  type: HandoverProtocolParticipantType;
}

export interface HandoverProtocolWriteTenancyReference {
  objectId: string;
  tenancyId: string;
}

export interface InsuranceInsurance {
  /** @format float */
  amount: number;
  benefits: InsuranceInsuranceBenefit[];
  documentIds: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  earliestTerminationDate: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endDate: TypeDate;
  /** @format float */
  excess: number;
  id: string;
  insuranceCompany: string;
  notes: string;
  /** @format int64 */
  numericId: string;
  /**
   * Account ID of owner (who owns the stuff in real life)
   * @format int64
   */
  ownerId: string;
  policyNumber: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  premiumPaidUntil: TypeDate;
  /** Some opaque ID where this entity is attached to (e.g. propertyId or buildingId) */
  referenceId: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startDate: TypeDate;
  type: InsuranceInsuranceType;
  /** @format float */
  yearlyCosts: number;
}

/** @default "INSURANCE_BENEFIT_UNDEFINED" */
export enum InsuranceInsuranceBenefit {
  INSURANCE_BENEFIT_UNDEFINED = "INSURANCE_BENEFIT_UNDEFINED",
  INSURANCE_BENEFIT_LIABILITY = "INSURANCE_BENEFIT_LIABILITY",
  INSURANCE_BENEFIT_WATER = "INSURANCE_BENEFIT_WATER",
  INSURANCE_BENEFIT_LOSS_OF_RENTAL_INCOME = "INSURANCE_BENEFIT_LOSS_OF_RENTAL_INCOME",
  INSURANCE_BENEFIT_BURGLARY_ROBBERY = "INSURANCE_BENEFIT_BURGLARY_ROBBERY",
  INSURANCE_BENEFIT_GLASS_BREAKAGE = "INSURANCE_BENEFIT_GLASS_BREAKAGE",
  INSURANCE_BENEFIT_EXTENDED_COVERAGE = "INSURANCE_BENEFIT_EXTENDED_COVERAGE",
  INSURANCE_BENEFIT_BUILDING_SERVICES_FAILURE = "INSURANCE_BENEFIT_BUILDING_SERVICES_FAILURE",
  INSURANCE_BENEFIT_CONVERSIONS_AND_RENOVATIONS = "INSURANCE_BENEFIT_CONVERSIONS_AND_RENOVATIONS",
  INSURANCE_BENEFIT_NATURAL_HAZARDS = "INSURANCE_BENEFIT_NATURAL_HAZARDS",
}

/** @default "INSURANCE_TYPE_UNDEFINED" */
export enum InsuranceInsuranceType {
  INSURANCE_TYPE_UNDEFINED = "INSURANCE_TYPE_UNDEFINED",
  INSURANCE_TYPE_FIRE_ELEMENTAL_DAMAGE = "INSURANCE_TYPE_FIRE_ELEMENTAL_DAMAGE",
  INSURANCE_TYPE_BUILDING_PROPERTY_LIABILITY = "INSURANCE_TYPE_BUILDING_PROPERTY_LIABILITY",
  INSURANCE_TYPE_LIABILITY = "INSURANCE_TYPE_LIABILITY",
  INSURANCE_TYPE_BUILDING_PROPERTY_INSURANCE = "INSURANCE_TYPE_BUILDING_PROPERTY_INSURANCE",
  INSURANCE_TYPE_LEGAL_EXPENSES = "INSURANCE_TYPE_LEGAL_EXPENSES",
  INSURANCE_TYPE_CONSTRUCTION_LIABILITY = "INSURANCE_TYPE_CONSTRUCTION_LIABILITY",
  INSURANCE_TYPE_CANTONAL_BUILDING_INSURANCE = "INSURANCE_TYPE_CANTONAL_BUILDING_INSURANCE",
  INSURANCE_TYPE_ACCIDENT_INSURANCE = "INSURANCE_TYPE_ACCIDENT_INSURANCE",
  INSURANCE_TYPE_ART_IN_ARCHITECTURE = "INSURANCE_TYPE_ART_IN_ARCHITECTURE",
}

export interface InvestPlanComponent {
  bkp: string;
  /** @format float */
  costs: number;
  id: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  installationDate: TypeDate;
  /** @format int32 */
  lifeSpan: number;
  name: string;
  note: string;
  /** @format int64 */
  ownerId: string;
  /** @format float */
  percentage: number;
  referenceId: string;
}

export interface InvestPlanCreateBulkEntry {
  bkp: string;
  /** @format float */
  costs: number;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  installationDate: TypeDate;
  /** @format int32 */
  lifeSpan: number;
  name: string;
  note: string;
  /** @format int64 */
  ownerId: string;
  /** @format float */
  percentage: number;
  referenceId: string;
}

export interface MortgageAmortization {
  /** @format double */
  amount: number;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  byDate: TypeDate;
}

export interface MortgageMortgage {
  /** @format double */
  amortisationAmount: number;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  amortizationFrom: TypeDate;
  amortizations: MortgageAmortization[];
  /** @format double */
  amount: number;
  bankCountry: string;
  bankName: string;
  bankNumber: string;
  bic: string;
  debitAccount: string;
  designation: string;
  documentIds: string[];
  dueDateMonthsAmortization: number[];
  dueDateMonthsInterestRate: number[];
  enableAmortizations: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  expiresAt: TypeDate;
  id: string;
  /** @format float */
  interestRate: number;
  mortgageDesignation: MortgageMortgageDesignation;
  mortgageeAddress: CommonaddressAddress;
  number: string;
  /** @format int64 */
  numericId: string;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  /** Could be property, building or object ID */
  referenceId: string;
  terminableOn: MortgageMortgageTerminableOn;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  validFrom: TypeDate;
}

/** @default "MORTGAGE_DESIGNATION_UNDEFINED" */
export enum MortgageMortgageDesignation {
  MORTGAGE_DESIGNATION_UNDEFINED = "MORTGAGE_DESIGNATION_UNDEFINED",
  MORTGAGEDESIGNATIONFIXED1YEAR = "MORTGAGE_DESIGNATION_FIXED_1_YEAR",
  MORTGAGEDESIGNATIONFIXED2YEAR = "MORTGAGE_DESIGNATION_FIXED_2_YEAR",
  MORTGAGEDESIGNATIONFIXED3YEAR = "MORTGAGE_DESIGNATION_FIXED_3_YEAR",
  MORTGAGEDESIGNATIONFIXED4YEAR = "MORTGAGE_DESIGNATION_FIXED_4_YEAR",
  MORTGAGEDESIGNATIONFIXED5YEAR = "MORTGAGE_DESIGNATION_FIXED_5_YEAR",
  MORTGAGEDESIGNATIONFIXED6YEAR = "MORTGAGE_DESIGNATION_FIXED_6_YEAR",
  MORTGAGEDESIGNATIONFIXED7YEAR = "MORTGAGE_DESIGNATION_FIXED_7_YEAR",
  MORTGAGEDESIGNATIONFIXED8YEAR = "MORTGAGE_DESIGNATION_FIXED_8_YEAR",
  MORTGAGEDESIGNATIONFIXED9YEAR = "MORTGAGE_DESIGNATION_FIXED_9_YEAR",
  MORTGAGEDESIGNATIONFIXED10YEAR = "MORTGAGE_DESIGNATION_FIXED_10_YEAR",
  MORTGAGEDESIGNATIONFIXED11YEAR = "MORTGAGE_DESIGNATION_FIXED_11_YEAR",
  MORTGAGEDESIGNATIONFIXED12YEAR = "MORTGAGE_DESIGNATION_FIXED_12_YEAR",
  MORTGAGEDESIGNATIONFIXED13YEAR = "MORTGAGE_DESIGNATION_FIXED_13_YEAR",
  MORTGAGEDESIGNATIONFIXED14YEAR = "MORTGAGE_DESIGNATION_FIXED_14_YEAR",
  MORTGAGEDESIGNATIONFIXED15YEAR = "MORTGAGE_DESIGNATION_FIXED_15_YEAR",
  MORTGAGEDESIGNATIONFIXED16YEAR = "MORTGAGE_DESIGNATION_FIXED_16_YEAR",
  MORTGAGEDESIGNATIONFIXED17YEAR = "MORTGAGE_DESIGNATION_FIXED_17_YEAR",
  MORTGAGEDESIGNATIONFIXED18YEAR = "MORTGAGE_DESIGNATION_FIXED_18_YEAR",
  MORTGAGEDESIGNATIONFIXED19YEAR = "MORTGAGE_DESIGNATION_FIXED_19_YEAR",
  MORTGAGEDESIGNATIONFIXED20YEAR = "MORTGAGE_DESIGNATION_FIXED_20_YEAR",
  MORTGAGEDESIGNATIONFIXED21YEAR = "MORTGAGE_DESIGNATION_FIXED_21_YEAR",
  MORTGAGEDESIGNATIONFIXED22YEAR = "MORTGAGE_DESIGNATION_FIXED_22_YEAR",
  MORTGAGEDESIGNATIONFIXED23YEAR = "MORTGAGE_DESIGNATION_FIXED_23_YEAR",
  MORTGAGEDESIGNATIONFIXED24YEAR = "MORTGAGE_DESIGNATION_FIXED_24_YEAR",
  MORTGAGEDESIGNATIONFIXED25YEAR = "MORTGAGE_DESIGNATION_FIXED_25_YEAR",
  MORTGAGE_DESIGNATION_VARIABLE = "MORTGAGE_DESIGNATION_VARIABLE",
  MORTGAGE_DESIGNATION_LIBOR = "MORTGAGE_DESIGNATION_LIBOR",
  MORTGAGE_DESIGNATION_SARON = "MORTGAGE_DESIGNATION_SARON",
}

/** @default "MORTGAGE_TERMINABLE_ON_UNDEFINED" */
export enum MortgageMortgageTerminableOn {
  MORTGAGE_TERMINABLE_ON_UNDEFINED = "MORTGAGE_TERMINABLE_ON_UNDEFINED",
  MORTGAGETERMINABLEON1MONTH = "MORTGAGE_TERMINABLE_ON_1_MONTH",
  MORTGAGETERMINABLEON2MONTHS = "MORTGAGE_TERMINABLE_ON_2_MONTHS",
  MORTGAGETERMINABLEON3MONTHS = "MORTGAGE_TERMINABLE_ON_3_MONTHS",
  MORTGAGETERMINABLEON6MONTHS = "MORTGAGE_TERMINABLE_ON_6_MONTHS",
  MORTGAGETERMINABLEON12MONTHS = "MORTGAGE_TERMINABLE_ON_12_MONTHS",
}

export interface ObjectAddCraftsmenRequest {
  contactIdsCraftsman: string[];
  ids: string[];
}

export type ObjectAddCraftsmenResponse = object;

export interface ObjectAddJanitorsRequest {
  contactIdsJanitor: string[];
  ids: string[];
}

export type ObjectAddJanitorsResponse = object;

export interface ObjectAddServiceProvidersRequest {
  contactIdsServiceProvider: string[];
  ids: string[];
}

export type ObjectAddServiceProvidersResponse = object;

/** @default "ADDITIONAL_ROOM_ATTIC" */
export enum ObjectAdditionalRooms {
  ADDITIONAL_ROOM_ATTIC = "ADDITIONAL_ROOM_ATTIC",
  ADDITIONAL_ROOM_BICYCLE_STORAGE = "ADDITIONAL_ROOM_BICYCLE_STORAGE",
  ADDITIONAL_ROOM_WASHING_AND_DRYING_ROOM = "ADDITIONAL_ROOM_WASHING_AND_DRYING_ROOM",
  ADDITIONAL_ROOM_BASEMENT = "ADDITIONAL_ROOM_BASEMENT",
  ADDITIONAL_ROOM_FITNESS_ROOM = "ADDITIONAL_ROOM_FITNESS_ROOM",
  ADDITIONAL_ROOM_HOBBY_ROOM = "ADDITIONAL_ROOM_HOBBY_ROOM",
  ADDITIONAL_ROOM_ARCHIVE = "ADDITIONAL_ROOM_ARCHIVE",
  ADDITIONAL_ROOM_STORING_PLACE = "ADDITIONAL_ROOM_STORING_PLACE",
  ADDITIONAL_ROOM_WORKROOM = "ADDITIONAL_ROOM_WORKROOM",
  ADDITIONAL_ROOM_HALL = "ADDITIONAL_ROOM_HALL",
  ADDITIONAL_ROOM_COLD_STORAGE_CELL = "ADDITIONAL_ROOM_COLD_STORAGE_CELL",
}

/** @default "UNDEFINED_ADMINISTRATION_TYPE" */
export enum ObjectAdministrationType {
  UNDEFINED_ADMINISTRATION_TYPE = "UNDEFINED_ADMINISTRATION_TYPE",
  LETTING = "LETTING",
  INITIAL_LETTING = "INITIAL_LETTING",
  PARTIAL_LETTING = "PARTIAL_LETTING",
  CONDOMINIUM_OWNERSHIP = "CONDOMINIUM_OWNERSHIP",
  OWNERS_CORPORATION = "OWNERS_CORPORATION",
}

export interface ObjectAreas {
  /** @format float */
  effectiveArea: number;
  /** @format float */
  livingArea: number;
  /** @format float */
  netLivingArea: number;
  /** @format float */
  plotArea: number;
}

export interface ObjectAvailability {
  /**
   * Only used if availability is FROM_DATE or TEMPORARY_LIVING
   * @format int64
   */
  availableFrom: string;
  /**
   * Only used if availability is TEMPORARY_LIVING
   * @format int64
   */
  availableTo: string;
  type: ObjectAvailabilityType;
}

/** @default "IMMEDIATELY" */
export enum ObjectAvailabilityType {
  IMMEDIATELY = "IMMEDIATELY",
  BY_ARRANGEMENT = "BY_ARRANGEMENT",
  FROM_DATE = "FROM_DATE",
  TEMPORARY_LIVING = "TEMPORARY_LIVING",
}

/** @default "UNDEFINED_CATEGORY" */
export enum ObjectCategory {
  UNDEFINED_CATEGORY = "UNDEFINED_CATEGORY",
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  COMMERCIAL = "COMMERCIAL",
  GASTRONOMY = "GASTRONOMY",
  GARDEN = "GARDEN",
  PARKING_SPACE = "PARKING_SPACE",
  SECONDARY_ROOM = "SECONDARY_ROOM",
  PLOT = "PLOT",
  AGRICULTURE = "AGRICULTURE",
}

export interface ObjectContacts {
  contactIdAssigneeAccounting: string;
  contactIdAssigneeAdministration: string;
  contactIdAssigneeAsset: string;
  contactIdAssigneeMarketing: string;
  contactIdAssigneeOwner: string;
  contactIdViewingAppointment: string;
  contactIdsCraftsman: string[];
  contactIdsJanitor: string[];
  contactIdsServiceProvider: string[];
}

export interface ObjectDefaultAnswer {
  attachmentMediaIds: string[];
  includeApplicationFormLink: boolean;
  includeViewingAppointmentContact: boolean;
  mailboxId: string;
  subject: string;
  templateId: string;
  text: string;
}

/** @default "UNDEFINED_DEPOSIT_INSURANCE" */
export enum ObjectDepositInsurance {
  UNDEFINED_DEPOSIT_INSURANCE = "UNDEFINED_DEPOSIT_INSURANCE",
  NO_DEPOSIT_INSURANCE = "NO_DEPOSIT_INSURANCE",
  ALL_DEPOSIT_INSURANCES = "ALL_DEPOSIT_INSURANCES",
  AXA = "AXA",
  ADUNOKAUTION = "ADUNOKAUTION",
  FIRST_CAUTION = "FIRST_CAUTION",
  GO_CAUTION = "GO_CAUTION",
  HELVETIA = "HELVETIA",
  MOBILAR = "MOBILAR",
  SMART_CAUTION = "SMART_CAUTION",
  SWISS_CAUTION = "SWISS_CAUTION",
  ZUERICH = "ZUERICH",
}

/** @default "EXTRAS_BALCONY" */
export enum ObjectExtras {
  EXTRAS_BALCONY = "EXTRAS_BALCONY",
  EXTRAS_FRENCH_BALCONY = "EXTRAS_FRENCH_BALCONY",
  EXTRAS_WINTER_GARDEN = "EXTRAS_WINTER_GARDEN",
  EXTRAS_GARDEN = "EXTRAS_GARDEN",
  EXTRAS_VERANDA = "EXTRAS_VERANDA",
  EXTRAS_TERRACE = "EXTRAS_TERRACE",
  EXTRAS_LOGGIA = "EXTRAS_LOGGIA",
  EXTRAS_GARDEN_HOUSE = "EXTRAS_GARDEN_HOUSE",
}

/** @default "FLOORING_TILES" */
export enum ObjectFlooring {
  FLOORING_TILES = "FLOORING_TILES",
  FLOORING_LAMINATE = "FLOORING_LAMINATE",
  FLOORING_PARQUET = "FLOORING_PARQUET",
  FLOORING_STONE = "FLOORING_STONE",
  FLOORING_DOUBLE_FLOOR = "FLOORING_DOUBLE_FLOOR",
  FLOORING_WOOD_BOARDS = "FLOORING_WOOD_BOARDS",
  FLOORING_MARBLE = "FLOORING_MARBLE",
  FLOORING_PVC = "FLOORING_PVC",
  FLOORING_CARPET = "FLOORING_CARPET",
  FLOORING_TERRACOTTA = "FLOORING_TERRACOTTA",
  FLOORING_ANHYDRIT = "FLOORING_ANHYDRIT",
  FLOORING_VINYL = "FLOORING_VINYL",
  FLOORING_OTHERS = "FLOORING_OTHERS",
}

/** @default "UNDEFINED_FURNISHING" */
export enum ObjectFurnishing {
  UNDEFINED_FURNISHING = "UNDEFINED_FURNISHING",
  FURNISHED = "FURNISHED",
  PARTLY_FURNISHED = "PARTLY_FURNISHED",
  NOT_FURNISHED = "NOT_FURNISHED",
}

/** @default "UNDEFINED_FURNISHING_TYPE" */
export enum ObjectFurnishingType {
  UNDEFINED_FURNISHING_TYPE = "UNDEFINED_FURNISHING_TYPE",
  SIMPLE_FURNISHING = "SIMPLE_FURNISHING",
  STANDARD_FURNISHING = "STANDARD_FURNISHING",
  UPSCALE_FURNISHING = "UPSCALE_FURNISHING",
  LUXURY_FURNISHING = "LUXURY_FURNISHING",
}

export interface ObjectGetAccountingStatisticsResponse {
  /** @format int32 */
  countOverall: number;
  /** @format int32 */
  inMarketingOverall: number;
  /** @format int32 */
  inMarketingSince12Weeks: number;
  /** @format int32 */
  inMarketingSince4Weeks: number;
  /** @format int32 */
  inMarketingSince8Weeks: number;
  /** @format int32 */
  notInMarketingOverall: number;
  /** @format int32 */
  notInMarketingSince12Weeks: number;
  /** @format int32 */
  notInMarketingSince4Weeks: number;
  /** @format int32 */
  notInMarketingSince8Weeks: number;
  /** @format int32 */
  publishedOverall: number;
  /** @format int32 */
  publishedSince12Weeks: number;
  /** @format int32 */
  publishedSince4Weeks: number;
  /** @format int32 */
  publishedSince8Weeks: number;
  /** @format float */
  vacancyRate: number;
  /** @format int32 */
  vacantOverall: number;
  /** @format int32 */
  vacantSince12Weeks: number;
  /** @format int32 */
  vacantSince4Weeks: number;
  /** @format int32 */
  vacantSince8Weeks: number;
}

export interface ObjectGetAdministrationStatisticsResponse {
  /** @format int32 */
  countOverall: number;
  /** @format int32 */
  inMarketingOverall: number;
  /** @format int32 */
  inMarketingSince12Weeks: number;
  /** @format int32 */
  inMarketingSince4Weeks: number;
  /** @format int32 */
  inMarketingSince8Weeks: number;
  /** @format int32 */
  notInMarketingOverall: number;
  /** @format int32 */
  notInMarketingSince12Weeks: number;
  /** @format int32 */
  notInMarketingSince4Weeks: number;
  /** @format int32 */
  notInMarketingSince8Weeks: number;
  /** @format int32 */
  publishedOverall: number;
  /** @format int32 */
  publishedSince12Weeks: number;
  /** @format int32 */
  publishedSince4Weeks: number;
  /** @format int32 */
  publishedSince8Weeks: number;
  /** @format float */
  vacancyRate: number;
  /** @format int32 */
  vacantOverall: number;
  /** @format int32 */
  vacantSince12Weeks: number;
  /** @format int32 */
  vacantSince4Weeks: number;
  /** @format int32 */
  vacantSince8Weeks: number;
}

export interface ObjectGetApplicationInfoResponse {
  /** @format byte */
  pdf: string;
}

export interface ObjectGetApplicationLinkResponse {
  link: string;
}

export interface ObjectGetAssetStatisticsResponse {
  /** @format int32 */
  countOverall: number;
  /** @format int32 */
  inMarketingOverall: number;
  /** @format int32 */
  inMarketingSince12Weeks: number;
  /** @format int32 */
  inMarketingSince4Weeks: number;
  /** @format int32 */
  inMarketingSince8Weeks: number;
  /** @format int32 */
  notInMarketingOverall: number;
  /** @format int32 */
  notInMarketingSince12Weeks: number;
  /** @format int32 */
  notInMarketingSince4Weeks: number;
  /** @format int32 */
  notInMarketingSince8Weeks: number;
  /** @format int32 */
  publishedOverall: number;
  /** @format int32 */
  publishedSince12Weeks: number;
  /** @format int32 */
  publishedSince4Weeks: number;
  /** @format int32 */
  publishedSince8Weeks: number;
  /** @format float */
  vacancyRate: number;
  /** @format int32 */
  vacantOverall: number;
  /** @format int32 */
  vacantSince12Weeks: number;
  /** @format int32 */
  vacantSince4Weeks: number;
  /** @format int32 */
  vacantSince8Weeks: number;
}

export interface ObjectGetDefaultAnswerResponse {
  defaultAnswer: ObjectDefaultAnswer;
}

export interface ObjectGetMarketingExposeResponse {
  /** @format byte */
  pdf: string;
}

export interface ObjectGetMarketingResponse {
  acceptsCreditTrust: boolean;
  /** @format float */
  additional: number;
  address: CommonaddressAddress;
  availability: ObjectAvailability;
  category: ObjectCategory;
  /** @format float */
  coldRent: number;
  currency: string;
  /** @format float */
  decimalFloor: number;
  deposit: ObjectobjectDeposit;
  description: string;
  /** @format float */
  effectiveArea: number;
  /** @format int32 */
  floor: number;
  /** @format float */
  heating: number;
  id: string;
  images: ObjectobjectImage[];
  /** @format float */
  livingArea: number;
  /** @format int64 */
  marketingAccountId: string;
  marketingAssignee: ObjectMarketingAssignee;
  marketingLanguage: LanguagePreferredLanguage;
  /** @format int64 */
  ownerId: string;
  parking: ObjectParking[];
  publicId: string;
  publishAddress: boolean;
  /** @format float */
  rooms: number;
  subCategories: ObjectSubCategory[];
  title: string;
  unit: ObjectUnit;
}

export interface ObjectGetObjectTypesResponse {
  hasCondominiumObjects: boolean;
  hasRentalObjects: boolean;
}

export interface ObjectGetOwnerStatisticsResponse {
  /** @format int32 */
  countOverall: number;
  /** @format int32 */
  inMarketingOverall: number;
  /** @format int32 */
  inMarketingSince12Weeks: number;
  /** @format int32 */
  inMarketingSince4Weeks: number;
  /** @format int32 */
  inMarketingSince8Weeks: number;
  /** @format int32 */
  notInMarketingOverall: number;
  /** @format int32 */
  notInMarketingSince12Weeks: number;
  /** @format int32 */
  notInMarketingSince4Weeks: number;
  /** @format int32 */
  notInMarketingSince8Weeks: number;
  /** @format int32 */
  publishedOverall: number;
  /** @format int32 */
  publishedSince12Weeks: number;
  /** @format int32 */
  publishedSince4Weeks: number;
  /** @format int32 */
  publishedSince8Weeks: number;
  /** @format float */
  vacancyRate: number;
  /** @format int32 */
  vacantOverall: number;
  /** @format int32 */
  vacantSince12Weeks: number;
  /** @format int32 */
  vacantSince4Weeks: number;
  /** @format int32 */
  vacantSince8Weeks: number;
}

export interface ObjectGetTotalValueQuotaForBuildingResponse {
  /** @format float */
  totalValueQuota: number;
}

export interface ObjectGetViewingAppointmentResponse {
  viewingAppointment: ObjectViewingAppointment;
}

/** @default "GLAZING_ACOUSTIC_GLAZING" */
export enum ObjectGlazing {
  GLAZING_ACOUSTIC_GLAZING = "GLAZING_ACOUSTIC_GLAZING",
  GLAZING_SUN_PROTECTION = "GLAZING_SUN_PROTECTION",
  GLAZING_SIMPLY_GLAZED = "GLAZING_SIMPLY_GLAZED",
  GLAZING_DOUBLE_GLAZED = "GLAZING_DOUBLE_GLAZED",
  GLAZING_TRIPLE_GLAZED = "GLAZING_TRIPLE_GLAZED",
  GLAZING_RESISTANT_GLASS = "GLAZING_RESISTANT_GLASS",
}

export interface ObjectHaveSamePropertyResponse {
  sameProperty: boolean;
}

/** @default "CENTRAL_HEATING" */
export enum ObjectHeating {
  CENTRAL_HEATING = "CENTRAL_HEATING",
  SELF_CONTAINED_CENTRAL_HEATING = "SELF_CONTAINED_CENTRAL_HEATING",
  UNDERFLOOR_HEATING = "UNDERFLOOR_HEATING",
  RADIATOR = "RADIATOR",
  CONTROLLED_RESIDENTIAL_VENTILATION = "CONTROLLED_RESIDENTIAL_VENTILATION",
  AIR_CONDITIONING = "AIR_CONDITIONING",
  STOVE = "STOVE",
  WOOD_BURNING_STOVE = "WOOD_BURNING_STOVE",
  STORAGE_HEATER = "STORAGE_HEATER",
  FIREPLACE = "FIREPLACE",
  OPEN_FIREPLACE = "OPEN_FIREPLACE",
}

export interface ObjectIsActiveResponse {
  active: boolean;
}

/** @default "NO_KITCHEN" */
export enum ObjectKitchen {
  NO_KITCHEN = "NO_KITCHEN",
  TEA_KITCHEN = "TEA_KITCHEN",
  BUILD_IN_KITCHEN = "BUILD_IN_KITCHEN",
  KITCHENETTE = "KITCHENETTE",
  EAT_IN_KITCHEN = "EAT_IN_KITCHEN",
  SMALL_KITCHEN = "SMALL_KITCHEN",
  OPEN_KITCHEN = "OPEN_KITCHEN",
  GLASS_CERAMIC_STOVE = "GLASS_CERAMIC_STOVE",
  INDUCTION_STOVE = "INDUCTION_STOVE",
  TEPANIAKI = "TEPANIAKI",
  STEAMER = "STEAMER",
  COMBI_STEAMER = "COMBI_STEAMER",
  DISHWASHER = "DISHWASHER",
}

export interface ObjectListFreeByDateRequest {
  buildingIds: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  date: TypeDate;
}

export interface ObjectListFreeByDateResponse {
  objects: ObjectObject[];
}

export interface ObjectListIdsByEmployeeResponse {
  ids: string[];
}

export interface ObjectListIdsInMarketingResponse {
  ids: string[];
}

export interface ObjectListMarketingResponse {
  objects: ObjectObject[];
  /** @format int32 */
  totalObjectCount: number;
  /** @format int32 */
  totalPageCount: number;
}

export interface ObjectListPublishedResponse {
  objects: ObjectObject[];
}

export type ObjectMarkForMarketingResponse = object;

export interface ObjectMarketingAssignee {
  email: string;
  name: string;
  phone: string;
}

export type ObjectMarketingMarkSeenResponse = object;

export interface ObjectMarketingStatus {
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  clearFromMarketingAt: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  markedAsSeenAt: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  markedForMarketingAt: string;
  marketingNote: string;
  newInMarketing: boolean;
  publications: ObjectPublication[];
  /**
   * UNIX timestamp in seconds when tenancy was marked as ending
   * @format int64
   */
  setVacantAt: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  startMarketingAt: string;
  /**
   * Holds info when tenancy for this object is ending. -1 = undetermined end of tenancy
   * @format int64
   */
  vacantAt: string;
}

/** @default "NO_MARKETING_STATUS_FILTER" */
export enum ObjectMarketingStatusFilter {
  NO_MARKETING_STATUS_FILTER = "NO_MARKETING_STATUS_FILTER",
  NEW = "NEW",
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  RENTED = "RENTED",
}

export interface ObjectMoveObjectsRequest {
  ids: string[];
  newBuildingId: string;
}

export type ObjectMoveObjectsResponse = object;

export interface ObjectObject {
  acceptsCreditTrust: boolean;
  address: CommonaddressAddress;
  administrationType: ObjectAdministrationType;
  assignedSideRooms: string;
  availability: ObjectAvailability;
  /** @format int64 */
  awn: string;
  buildingId: string;
  category: ObjectCategory;
  characteristics: ObjectobjectCharacteristics;
  condominiumOwnership: boolean;
  contacts: ObjectContacts;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  debitUntil: TypeDate;
  defaultAnswer: ObjectDefaultAnswer;
  deposit: ObjectobjectDeposit;
  description: string;
  development: ObjectobjectDevelopment;
  documents: ObjectobjectDocument[];
  energySources: ObjectobjectEnergySource[];
  ewid: string;
  id: string;
  images: ObjectobjectImage[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  inactiveBy: TypeDate;
  marketingLanguage: LanguagePreferredLanguage;
  marketingStatus: ObjectMarketingStatus;
  name: string;
  needsMatching: boolean;
  note: string;
  number: string;
  /** @format int64 */
  numericId: string;
  onboardingFinished: boolean;
  otoId: string;
  /** @format int64 */
  ownerId: string;
  parking: ObjectParking[];
  /** Do only use first array element. Others are ignored */
  positions: ObjectPosition[];
  pricing: ObjectPricing;
  /** A unique number consisting of "<ownerId>.<x>" while x = number (counting up) that is unique for the ownerId */
  publicId: string;
  publishAddress: boolean;
  renovations: ObjectRenovation[];
  sharedUse: string;
  specialFeatures: string;
  subCategories: ObjectSubCategory[];
  tags: string[];
  title: string;
  type: ObjectobjectType;
  unusualAgreement: string;
  /**
   * UNIX timestamp in seconds when object was modified the last time
   * @format int64
   */
  updatedAt: string;
  utilizations: ObjectUtilization[];
  /** @format float */
  valueQuota: number;
  videos: ObjectVideo[];
  viewingAppointment: ObjectViewingAppointment;
  virtualTourUrl: string;
}

export interface ObjectObjectFilterOption {
  id: string;
  name: string;
  /** @format int64 */
  ownerId: string;
}

export interface ObjectParking {
  /** @format float */
  costs: number;
  parkingType: ObjectParkingType;
}

/** @default "UNDEFINED_PARKING_TYPE" */
export enum ObjectParkingType {
  UNDEFINED_PARKING_TYPE = "UNDEFINED_PARKING_TYPE",
  PARKING_TYPE_PARKING_SPACE = "PARKING_TYPE_PARKING_SPACE",
  PARKING_TYPE_CARPORT = "PARKING_TYPE_CARPORT",
  PARKING_TYPE_DOUBLE_CARPORT = "PARKING_TYPE_DOUBLE_CARPORT",
  PARKING_TYPE_GARAGE = "PARKING_TYPE_GARAGE",
  PARKING_TYPE_DOUBLE_GARAGE = "PARKING_TYPE_DOUBLE_GARAGE",
  PARKING_TYPE_DUPLEX = "PARKING_TYPE_DUPLEX",
  PARKING_TYPE_CAR_PARK = "PARKING_TYPE_CAR_PARK",
  PARKING_TYPE_UNDERGROUND_GARAGE = "PARKING_TYPE_UNDERGROUND_GARAGE",
  PARKING_TYPE_ELECTRIC = "PARKING_TYPE_ELECTRIC",
}

/** @default "UNDEFINED_PAYMENT_INTERVAL" */
export enum ObjectPaymentInterval {
  UNDEFINED_PAYMENT_INTERVAL = "UNDEFINED_PAYMENT_INTERVAL",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  HALF_YEARLY = "HALF_YEARLY",
  YEARLY = "YEARLY",
}

/** @default "PETS_ALLOWED" */
export enum ObjectPets {
  PETS_ALLOWED = "PETS_ALLOWED",
  PETS_NOT_ALLOWED = "PETS_NOT_ALLOWED",
  PETS_SMALL_ALLOWED = "PETS_SMALL_ALLOWED",
  PETS_DOGS_ALLOWED = "PETS_DOGS_ALLOWED",
  PETS_CATS_ALLOWED = "PETS_CATS_ALLOWED",
  PETS_AFTER_CONSULTATION = "PETS_AFTER_CONSULTATION",
}

/** @default "UNDEFINED_LOCATION" */
export enum ObjectPosition {
  UNDEFINED_LOCATION = "UNDEFINED_LOCATION",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  MIDDLE = "MIDDLE",
  MIDDLE_LEFT = "MIDDLE_LEFT",
  MIDDLE_RIGHT = "MIDDLE_RIGHT",
  NORTH = "NORTH",
  SOUTH = "SOUTH",
  WEST = "WEST",
  EAST = "EAST",
  NORTH_WEST = "NORTH_WEST",
  NORTH_EAST = "NORTH_EAST",
  SOUTH_WEST = "SOUTH_WEST",
  SOUTH_EAST = "SOUTH_EAST",
  MIDDLE_FRONT = "MIDDLE_FRONT",
  MIDDLE_LEFT_LEFT = "MIDDLE_LEFT_LEFT",
  MIDDLE_RIGHT_RIGHT = "MIDDLE_RIGHT_RIGHT",
}

export interface ObjectPricing {
  /** @format float */
  additional: number;
  additionalValues: ObjectobjectAdditionalValues[];
  advancePaymentValues: ObjectobjectAdvancePaymentValue[];
  /** @format float */
  coldRent: number;
  /** @format float */
  commonDistrictRent: number;
  /** E.g. CHF */
  currency: string;
  flatRateValues: ObjectobjectFlatRateValues[];
  /** @format float */
  heating: number;
  /** @format float */
  marketGrossRent: number;
  /** @format float */
  marketHeatingAndAdditional: number;
  /** @format float */
  marketNetRent: number;
  /** @format float */
  marketRent: number;
  paymentInterval: ObjectPaymentInterval;
  /** @format float */
  rentM2PerYear: number;
  /** @format float */
  rentReserves: number;
  /** @format float */
  sellingPrice: number;
  /** @format float */
  sellingPriceM2: number;
  /** @format float */
  targetAdditional: number;
  /** @format float */
  targetColdRent: number;
  /** @format float */
  targetHeating: number;
  /** @format float */
  targetMarketGrossRent: number;
  /** @format float */
  targetMarketHeatingAndAdditional: number;
  /** @format float */
  targetMarketNetRent: number;
  /** @format float */
  underMarketGrossRent: number;
  /** @format float */
  underMarketHeatingAndAdditional: number;
  /** @format float */
  underMarketNetRent: number;
  unit: ObjectUnit;
  /** @format float */
  upperMarketGrossRent: number;
  /** @format float */
  upperMarketHeatingAndAdditional: number;
  /** @format float */
  upperMarketNetRent: number;
}

export interface ObjectPublication {
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  published: string;
  /** @format int64 */
  publishedByAccountId: string;
  publishedByUserId: string;
  /** @format int32 */
  unPublishAtAmountOfRequests: number;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  unPublished: string;
  /** @format int64 */
  unPublishedByAccountId: string;
  unPublishedByUserId: string;
}

export interface ObjectPublishRequest {
  ids: string[];
  /**
   * Optional timestamp in seconds if user wants to schedule unPublish
   * @format int64
   */
  unPublishAt?: string;
  /**
   * Optional parameter if user wants to automatically unPublish
   * @format int32
   */
  unPublishAtAmountOfRequests?: number;
}

export type ObjectPublishResponse = object;

export interface ObjectRemoveCraftsmenRequest {
  contactIdsCraftsman: string[];
  ids: string[];
}

export type ObjectRemoveCraftsmenResponse = object;

export interface ObjectRemoveFromMarketingRequest {
  ids: string[];
}

export type ObjectRemoveFromMarketingResponse = object;

export interface ObjectRemoveJanitorsRequest {
  contactIdsJanitor: string[];
  ids: string[];
}

export type ObjectRemoveJanitorsResponse = object;

export interface ObjectRemoveServiceProvidersRequest {
  contactIdsServiceProvider: string[];
  ids: string[];
}

export type ObjectRemoveServiceProvidersResponse = object;

export interface ObjectRenovation {
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  end: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  start: string;
  text: string;
}

/** @default "SANITARY_BATHROOM_WITH_WINDOW" */
export enum ObjectSanitary {
  SANITARY_BATHROOM_WITH_WINDOW = "SANITARY_BATHROOM_WITH_WINDOW",
  SANITARY_BATHROOM_WITH_SHOWER = "SANITARY_BATHROOM_WITH_SHOWER",
  SANITARY_BATHROOM_WITH_BATHTUB = "SANITARY_BATHROOM_WITH_BATHTUB",
  SANITARY_SAUNA = "SANITARY_SAUNA",
  SANITARY_SWIMMING_POOL = "SANITARY_SWIMMING_POOL",
  SANITARY_JACUZZI = "SANITARY_JACUZZI",
  SANITARY_TOWEL_RADIATOR = "SANITARY_TOWEL_RADIATOR",
  SANITARY_WASHING_MACHINE = "SANITARY_WASHING_MACHINE",
  SANITARY_DRYER = "SANITARY_DRYER",
  SANITARY_CONNECTION_FOR_WASHING_MACHINE = "SANITARY_CONNECTION_FOR_WASHING_MACHINE",
}

export interface ObjectSetAccountingAssigneeRequest {
  contactIdAssigneeAccounting: string;
  ids: string[];
}

export type ObjectSetAccountingAssigneeResponse = object;

export interface ObjectSetAdminAssigneeRequest {
  contactIdAssigneeAdministration: string;
  ids: string[];
}

export type ObjectSetAdminAssigneeResponse = object;

export interface ObjectSetAssetAssigneeRequest {
  contactIdAssigneeAsset: string;
  ids: string[];
}

export type ObjectSetAssetAssigneeResponse = object;

export interface ObjectSetContactsRequest {
  contacts: ObjectContacts;
  ids: string[];
}

export type ObjectSetContactsResponse = object;

export type ObjectSetDefaultAnswerResponse = object;

export interface ObjectSetMarketingAssigneeRequest {
  contactIdAssigneeMarketing: string;
  ids: string[];
}

export type ObjectSetMarketingAssigneeResponse = object;

export interface ObjectSetOwnerAssigneeRequest {
  contactIdAssigneeOwner: string;
  ids: string[];
}

export type ObjectSetOwnerAssigneeResponse = object;

export interface ObjectSetViewingAppointmentContactRequest {
  contactIdViewingAppointment: string;
  ids: string[];
}

export type ObjectSetViewingAppointmentContactResponse = object;

export type ObjectSetViewingAppointmentResponse = object;

/**
 * - ATELIER: SwissRETS fields (can also contain IDX fields)
 *  - AGRICULTURAL_INSTALLATION: IDX fields
 * @default "UNDEFINED_SUB_CATEGORY"
 */
export enum ObjectSubCategory {
  UNDEFINED_SUB_CATEGORY = "UNDEFINED_SUB_CATEGORY",
  ATELIER = "ATELIER",
  ATTIC_FLAT = "ATTIC_FLAT",
  BAR = "BAR",
  CAFE = "CAFE",
  CAR_REPAIR_SHOP = "CAR_REPAIR_SHOP",
  CASTLE = "CASTLE",
  CHALET = "CHALET",
  EARTH_SHELTERED_DWELLING = "EARTH_SHELTERED_DWELLING",
  DETACHED_HOUSE = "DETACHED_HOUSE",
  DOCTORS_OFFICE = "DOCTORS_OFFICE",
  DUPLEX_HOUSE = "DUPLEX_HOUSE",
  FARM_HOUSE = "FARM_HOUSE",
  FURNISHED_FLAT = "FURNISHED_FLAT",
  GRANNY_FLAT = "GRANNY_FLAT",
  HOBBY_ROOM = "HOBBY_ROOM",
  HOTEL = "HOTEL",
  LOFT = "LOFT",
  MAISONETTE = "MAISONETTE",
  MULTIPLEX_HOUSE = "MULTIPLEX_HOUSE",
  OFFICE = "OFFICE",
  ONE_ROOM_FLAT = "ONE_ROOM_FLAT",
  RESTAURANT = "RESTAURANT",
  RETAIL_LOCATION = "RETAIL_LOCATION",
  ROOF_FLAT = "ROOF_FLAT",
  ROW_HOUSE = "ROW_HOUSE",
  RUSTICO = "RUSTICO",
  SINGLE_ROOM = "SINGLE_ROOM",
  STOECKLI = "STOECKLI",
  STEPPED_FLAT = "STEPPED_FLAT",
  STEPPED_HOUSE = "STEPPED_HOUSE",
  VILLA = "VILLA",
  WORKSHOP = "WORKSHOP",
  AGRICULTURAL_INSTALLATION = "AGRICULTURAL_INSTALLATION",
  MOUNTAIN_FARM = "MOUNTAIN_FARM",
  FARM = "FARM",
  DUPLEX = "DUPLEX",
  STUDIO = "STUDIO",
  TERRACE_FLAT = "TERRACE_FLAT",
  BACHELOR_FLAT = "BACHELOR_FLAT",
  ATTIC = "ATTIC",
  ALOTTMENT_GARDEN = "ALOTTMENT_GARDEN",
  CLUB = "CLUB",
  CASINO = "CASINO",
  MOVIE = "MOVIE",
  SQUASH = "SQUASH",
  INDOOR_TENNIS = "INDOOR_TENNIS",
  TENNIS = "TENNIS",
  SPORTS_HALL = "SPORTS_HALL",
  CAMP_GROUND = "CAMP_GROUND",
  OUTDOOR_SWIMMING_POOL = "OUTDOOR_SWIMMING_POOL",
  INDOOR_SWIMMING_POOL = "INDOOR_SWIMMING_POOL",
  GOLF_COURSE = "GOLF_COURSE",
  MOTEL = "MOTEL",
  PUB = "PUB",
  SINGLE_HOUSE = "SINGLE_HOUSE",
  BIFAMILIAR_HOUSE = "BIFAMILIAR_HOUSE",
  TERRANCE_HOUSE = "TERRANCE_HOUSE",
  MULTIPLE_DWELLING = "MULTIPLE_DWELLING",
  CAVE_HOUSE = "CAVE_HOUSE",
  SHOP = "SHOP",
  ADVERTISING_AREA = "ADVERTISING_AREA",
  COMMERCIAL_SUB_CATEGORY = "COMMERCIAL_SUB_CATEGORY",
  STORAGE_ROOM = "STORAGE_ROOM",
  PRACTICE = "PRACTICE",
  KIOSK = "KIOSK",
  GARDENING = "GARDENING",
  FUEL_STATION = "FUEL_STATION",
  CHEESE_FACTORY = "CHEESE_FACTORY",
  BUTCHER = "BUTCHER",
  BAKERY = "BAKERY",
  HAIRDRESSER = "HAIRDRESSER",
  SHOPPING_CENTER = "SHOPPING_CENTER",
  FACTORY = "FACTORY",
  INDUSTRIAL_OBJECT = "INDUSTRIAL_OBJECT",
  ARCADE = "ARCADE",
  LIVING_COMMERCIAL_BUILDING = "LIVING_COMMERCIAL_BUILDING",
  LIBRARY = "LIBRARY",
  HOSPITAL = "HOSPITAL",
  LABORATORY = "LABORATORY",
  MINI_GOLD_COURSE = "MINI_GOLD_COURSE",
  NURSING_HOME = "NURSING_HOME",
  RIDING_HALL = "RIDING_HALL",
  SANATORIUM = "SANATORIUM",
  PARTY_ROOM = "PARTY_ROOM",
  SAUNA = "SAUNA",
  SOLARIUM = "SOLARIUM",
  CARPENTRY_SHOP = "CARPENTRY_SHOP",
  OLD_AGE_HOME = "OLD_AGE_HOME",
  DEPARTMENT_STORE = "DEPARTMENT_STORE",
  HOME = "HOME",
  DISPLAY_WINDOW = "DISPLAY_WINDOW",
  PARKING_GARAGE = "PARKING_GARAGE",
  PARKING_SURFACE = "PARKING_SURFACE",
  OPEN_SLOT = "OPEN_SLOT",
  COVERED_SLOT = "COVERED_SLOT",
  SINGLE_GARAGE = "SINGLE_GARAGE",
  DOUBLE_GARAGE = "DOUBLE_GARAGE",
  UNDERGROUND_SLOT = "UNDERGROUND_SLOT",
  BOAT_DRY_DOCK = "BOAT_DRY_DOCK",
  BOAT_LANDING_STAGE = "BOAT_LANDING_STAGE",
  COVERED_PARKING_PLACE_BIKE = "COVERED_PARKING_PLACE_BIKE",
  OUTDOOR_PARKING_PLACE_BIKE = "OUTDOOR_PARKING_PLACE_BIKE",
  HORSE_BOX = "HORSE_BOX",
  BOAT_MOORING = "BOAT_MOORING",
  BUILDING_LAND = "BUILDING_LAND",
  AGRICULTURAL_LAND = "AGRICULTURAL_LAND",
  COMMERCIAL_LAND = "COMMERCIAL_LAND",
  INDUSTRIAL_LAND = "INDUSTRIAL_LAND",
  CELLAR_COMPARTMENT = "CELLAR_COMPARTMENT",
  ATTIC_COMPARTMENT = "ATTIC_COMPARTMENT",
  OTHER = "OTHER",
  VISITOR_PARKING_LOT = "VISITOR_PARKING_LOT",
  CARETAKER_ROOM = "CARETAKER_ROOM",
  FITNESS_CENTER = "FITNESS_CENTER",
  ARCHIVE_ROOM = "ARCHIVE_ROOM",
  ANTENNA_SYSTEM = "ANTENNA_SYSTEM",
  TRAINING_ROOM = "TRAINING_ROOM",
  TOILET_FACILITY = "TOILET_FACILITY",
  BICYCLE_PARKING = "BICYCLE_PARKING",
  HANDICAPPED_INDOOR_PARKING = "HANDICAPPED_INDOOR_PARKING",
  HANDICAPPED_OUTDOOR_PARKING = "HANDICAPPED_OUTDOOR_PARKING",
  DOUBLE_OUTDOOR_PARKING = "DOUBLE_OUTDOOR_PARKING",
  DOUBLE_INDOOR_PARKING = "DOUBLE_INDOOR_PARKING",
  JANITOR_ROOM = "JANITOR_ROOM",
  LAUNDRY_ROOM = "LAUNDRY_ROOM",
  CUSTOMER_PARKING = "CUSTOMER_PARKING",
  RETIREMENT_APARTMENT = "RETIREMENT_APARTMENT",
  STAFF_ROOM = "STAFF_ROOM",
  SERVER_ROOM = "SERVER_ROOM",
  MEETING_ROOM = "MEETING_ROOM",
  BUILDING_SERVICES = "BUILDING_SERVICES",
  COMMERCIAL_STORAGE_ROOM = "COMMERCIAL_STORAGE_ROOM",
  ELECTRIC_PARKING_SPACE = "ELECTRIC_PARKING_SPACE",
}

/** @default "TECHNOLOGY_ALARM_SYSTEM" */
export enum ObjectTechnology {
  TECHNOLOGY_ALARM_SYSTEM = "TECHNOLOGY_ALARM_SYSTEM",
  TECHNOLOGY_CAMERA = "TECHNOLOGY_CAMERA",
  TECHNOLOGY_HIGH_VOLTAGE_CURRENT = "TECHNOLOGY_HIGH_VOLTAGE_CURRENT",
}

export interface ObjectUnPublishRequest {
  ids: string[];
}

export type ObjectUnPublishResponse = object;

/** @default "ON_REQUEST" */
export enum ObjectUnit {
  ON_REQUEST = "ON_REQUEST",
  FIXED = "FIXED",
  M2YEAR = "M2_YEAR",
  M2MONTH = "M2_MONTH",
}

export type ObjectUpdateMarketingInfoResponse = object;

export interface ObjectUpdatePricingResponse {
  pricing: ObjectPricing;
}

/** @default "UNDEFINED_UTILIZATION" */
export enum ObjectUtilization {
  UNDEFINED_UTILIZATION = "UNDEFINED_UTILIZATION",
  AGRICULTURAL = "AGRICULTURAL",
  COMMERCIAL_UTILIZATION = "COMMERCIAL_UTILIZATION",
  CONSTRUCTION = "CONSTRUCTION",
  GASTRONOMY_UTILIZATION = "GASTRONOMY_UTILIZATION",
  INDUSTRIAL = "INDUSTRIAL",
  INVESTMENT = "INVESTMENT",
  PARKING = "PARKING",
  RESIDENTIAL = "RESIDENTIAL",
  STORAGE = "STORAGE",
  VACATION = "VACATION",
}

export interface ObjectVideo {
  mediaId: string;
}

export interface ObjectViewingAppointment {
  contactType: ObjectViewingAppointmentContactType;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  publishContact: boolean;
  remark: string;
}

/** @default "UNDEFINED_VIEWING_APPOINTMENT_CONTACT_TYPE" */
export enum ObjectViewingAppointmentContactType {
  UNDEFINED_VIEWING_APPOINTMENT_CONTACT_TYPE = "UNDEFINED_VIEWING_APPOINTMENT_CONTACT_TYPE",
  VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE = "VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE",
  VIEWING_APPOINTMENT_CONTACT_TYPE_EMPLOYEE = "VIEWING_APPOINTMENT_CONTACT_TYPE_EMPLOYEE",
  VIEWING_APPOINTMENT_CONTACT_TYPE_TENANT = "VIEWING_APPOINTMENT_CONTACT_TYPE_TENANT",
  VIEWING_APPOINTMENT_CONTACT_TYPE_CARETAKER = "VIEWING_APPOINTMENT_CONTACT_TYPE_CARETAKER",
  VIEWING_APPOINTMENT_CONTACT_TYPE_SERVICE_PROVIDER = "VIEWING_APPOINTMENT_CONTACT_TYPE_SERVICE_PROVIDER",
  VIEWING_APPOINTMENT_CONTACT_TYPE_FREE_TEXT = "VIEWING_APPOINTMENT_CONTACT_TYPE_FREE_TEXT",
}

/** @default "WINDOW_ALUMINIUM" */
export enum ObjectWindow {
  WINDOW_ALUMINIUM = "WINDOW_ALUMINIUM",
  WINDOW_WOOD = "WINDOW_WOOD",
  WINDOW_PLASTIC = "WINDOW_PLASTIC",
  WINDOW_LAMELLAR_SHUTTER = "WINDOW_LAMELLAR_SHUTTER",
  WINDOW_SHUTTER_BLINDS = "WINDOW_SHUTTER_BLINDS",
}

export interface ObjectaccountingGetStatisticsResponse {
  statistics: AccountingStatistics;
}

export interface ObjectbriefbutlerLoadSettingsResponse {
  enabled: boolean;
  profiles: BriefbutlerBriefbutlerProfile[];
}

export interface ObjectbriefbutlerSaveSettingsRequest {
  /** @format int64 */
  accountId: string;
  enabled: boolean;
  profiles: BriefbutlerBriefbutlerProfile[];
}

export type ObjectbriefbutlerSaveSettingsResponse = object;

export interface ObjectbuildingCharacteristics {
  broadbandInternet: boolean;
  cableTv: boolean;
  /** Power supply for e-cars */
  chargingStation: boolean;
  childFriendly: boolean;
  communityGarden: boolean;
  conciergeService: boolean;
  elevator: boolean;
  fiberGlass: boolean;
  intercom: boolean;
  isdn: boolean;
  park: boolean;
  playground: boolean;
  satellite: boolean;
}

export interface ObjectbuildingCountResponse {
  /** @format int32 */
  count: number;
}

export interface ObjectbuildingCreateRequest {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  acquisitionDate: TypeDate;
  address: CommonaddressAddress;
  /** @format float */
  buildingArea: number;
  buildingStrategy: BuildingBuildingStrategy;
  /** @format int64 */
  buildingValue: string;
  buildingZone: string;
  characteristics: ObjectbuildingCharacteristics;
  constructionTypes: BuildingConstructionType[];
  /** @format int32 */
  constructionYear: number;
  contacts: BuildingBuildingContact[];
  contractAmendments: string;
  distances: BuildingDistances;
  documentIds: string[];
  egid: string;
  energySources: ObjectbuildingEnergySource[];
  /** @format int32 */
  floors: number;
  imageMediaId: string;
  landRegisterNumber: string;
  locationQuality: ObjectbuildingLocationQuality;
  name: string;
  note: string;
  /** @format int32 */
  number: number;
  /** @format int64 */
  numericId: string;
  objectIds: string[];
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  /** @format float */
  plotArea: number;
  plotIds: string[];
  rating: BuildingRating;
  realEstateNumber: string;
  /** @format int32 */
  renovationYear: number;
  sia: BuildingSia;
  /** @format float */
  volume: number;
}

export interface ObjectbuildingCreateResponse {
  building: BuildingBuilding;
}

export type ObjectbuildingDeleteResponse = object;

export interface ObjectbuildingEnergySource {
  documentIds: string[];
  hasStorage: boolean;
  /** @format int64 */
  orderDate: string;
  /** @format float */
  orderQuantity: number;
  /**
   * DO NOT USE! Deprecated and replaced with tankReadings.
   * @format float
   */
  tankContent: number;
  tankReadings: ObjectbuildingTankReading[];
  /** @format float */
  tankSize: number;
  type: ObjectbuildingEnergySourceType;
  unit: ObjectbuildingEnergySourceUnit;
}

/** @default "UNDEFINED_ENERGY_SOURCE_TYPE" */
export enum ObjectbuildingEnergySourceType {
  UNDEFINED_ENERGY_SOURCE_TYPE = "UNDEFINED_ENERGY_SOURCE_TYPE",
  ENERGY_SOURCE_NO = "ENERGY_SOURCE_NO",
  ENERGY_SOURCE_AIR = "ENERGY_SOURCE_AIR",
  ENERGY_SOURCE_GEOTHERMAL_ENERGY = "ENERGY_SOURCE_GEOTHERMAL_ENERGY",
  ENERGY_SOURCE_GEOTHERMAL_PROBE = "ENERGY_SOURCE_GEOTHERMAL_PROBE",
  ENERGY_SOURCE_EARTH_TUBE = "ENERGY_SOURCE_EARTH_TUBE",
  ENERGY_SOURCE_WATER = "ENERGY_SOURCE_WATER",
  ENERGY_SOURCE_GAS = "ENERGY_SOURCE_GAS",
  ENERGY_SOURCE_OIL = "ENERGY_SOURCE_OIL",
  ENERGY_SOURCE_WOOD_GENERIC = "ENERGY_SOURCE_WOOD_GENERIC",
  ENERGY_SOURCE_WOOD = "ENERGY_SOURCE_WOOD",
  ENERGY_SOURCE_WOOD_PELLETS = "ENERGY_SOURCE_WOOD_PELLETS",
  ENERGY_SOURCE_WOOD_CHIPS = "ENERGY_SOURCE_WOOD_CHIPS",
  ENERGY_SOURCE_WASTE_HEAT = "ENERGY_SOURCE_WASTE_HEAT",
  ENERGY_SOURCE_ELECTRICITY = "ENERGY_SOURCE_ELECTRICITY",
  ENERGY_SOURCE_SUN = "ENERGY_SOURCE_SUN",
  ENERGY_SOURCE_DISTRICT_HEATING_GENERIC = "ENERGY_SOURCE_DISTRICT_HEATING_GENERIC",
  ENERGY_SOURCE_DISTRICT_HEATING_HIGH = "ENERGY_SOURCE_DISTRICT_HEATING_HIGH",
  ENERGY_SOURCE_DISTRICT_HEATING_LOW = "ENERGY_SOURCE_DISTRICT_HEATING_LOW",
  ENERGY_SOURCE_HEAT_PUMP = "ENERGY_SOURCE_HEAT_PUMP",
  ENERGY_SOURCE_OTHER = "ENERGY_SOURCE_OTHER",
}

/** @default "ENERGY_SOURCE_UNIT_UNDEFINED" */
export enum ObjectbuildingEnergySourceUnit {
  ENERGY_SOURCE_UNIT_UNDEFINED = "ENERGY_SOURCE_UNIT_UNDEFINED",
  ENERGY_SOURCE_UNIT_L = "ENERGY_SOURCE_UNIT_L",
  ENERGY_SOURCE_UNIT_KG = "ENERGY_SOURCE_UNIT_KG",
  ENERGYSOURCEUNITM3 = "ENERGY_SOURCE_UNIT_M3",
  ENERGY_SOURCE_UNIT_STERE = "ENERGY_SOURCE_UNIT_STERE",
}

export type ObjectbuildingFinishOnboardingResponse = object;

export interface ObjectbuildingGetResponse {
  building: BuildingBuilding;
}

export interface ObjectbuildingListByContactIdsRequest {
  contactIds: string[];
}

export interface ObjectbuildingListByContactIdsResponse {
  buildings: BuildingBuilding[];
}

export interface ObjectbuildingListByIdsRequest {
  ids: string[];
}

export interface ObjectbuildingListByIdsResponse {
  buildings: BuildingBuilding[];
}

export interface ObjectbuildingListByObjectIdsRequest {
  objectIds: string[];
}

export interface ObjectbuildingListByObjectIdsResponse {
  buildings: BuildingBuilding[];
}

export interface ObjectbuildingListFilterOptionsResponse {
  options: BuildingBuildingFilterOption[];
}

export interface ObjectbuildingListResponse {
  buildings: BuildingBuilding[];
  /** @format int32 */
  totalObjectCount: number;
  /** @format int32 */
  totalPageCount: number;
}

/**
 * - VERY_BAD_QUALITY: sehr schlecht
 *  - BAD_QUALITY: schlecht
 *  - MODERATE_QUALITY: mässig
 *  - AVERAGE_QUALITY: durchschnittlich
 *  - GOOD_QUALITY: gut
 *  - BETTER_QUALITY: gut bis sehr gut
 *  - VERY_GOOD_QUALITY: sehr gut bis ausgezeichnet
 *  - TOP_QUALITY: ausgezeichnet
 *  - EXCELLENT_QUALITY: allerbeste
 * @default "UNDEFINED_LOCATION_QUALITY"
 */
export enum ObjectbuildingLocationQuality {
  UNDEFINED_LOCATION_QUALITY = "UNDEFINED_LOCATION_QUALITY",
  VERY_BAD_QUALITY = "VERY_BAD_QUALITY",
  BAD_QUALITY = "BAD_QUALITY",
  MODERATE_QUALITY = "MODERATE_QUALITY",
  AVERAGE_QUALITY = "AVERAGE_QUALITY",
  GOOD_QUALITY = "GOOD_QUALITY",
  BETTER_QUALITY = "BETTER_QUALITY",
  VERY_GOOD_QUALITY = "VERY_GOOD_QUALITY",
  TOP_QUALITY = "TOP_QUALITY",
  EXCELLENT_QUALITY = "EXCELLENT_QUALITY",
}

export interface ObjectbuildingTankReading {
  /** @format int64 */
  date: string;
  documentIds: string[];
  /** @format float */
  value: number;
}

export interface ObjectbuildingUpdateResponse {
  building: BuildingBuilding;
}

export interface ObjectcondominiumActivateResponse {
  condominium: CondominiumCondominium;
}

export interface ObjectcondominiumAddDocumentsByNumericIdResponse {
  condominium: CondominiumCondominium;
}

export interface ObjectcondominiumAddDocumentsResponse {
  condominium: CondominiumCondominium;
}

export interface ObjectcondominiumAdditionalValues {
  type: PropertyAdditionalRentComponents;
  /** @format float */
  value: number;
}

export interface ObjectcondominiumContract {
  amendments: string;
  intendedUse: string;
  licensePlate: string;
  typeOfUse: ObjectcondominiumTypeOfUse;
}

export interface ObjectcondominiumCreateRequest {
  activated: boolean;
  closed: boolean;
  contract: ObjectcondominiumContract;
  documentIds: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endingAt: TypeDate;
  finalInvoiceCreated: boolean;
  /** Deprecated, use rent message */
  flatRateValues: ObjectcondominiumFlatRateValues[];
  /** @format int64 */
  numericId: string;
  objectId: string;
  opted: boolean;
  owner: CondominiumOwner;
  /** @format int64 */
  ownerId: string;
  rent: ObjectcondominiumRent;
  secondaryResidence: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startingAt: TypeDate;
  taxable: boolean;
  tenants: ObjectcondominiumTenant[];
}

export interface ObjectcondominiumCreateResponse {
  condominium: CondominiumCondominium;
}

export interface ObjectcondominiumDeactivateByNumericIdResponse {
  condominium: CondominiumCondominium;
}

export interface ObjectcondominiumDeactivateResponse {
  condominium: CondominiumCondominium;
}

export interface ObjectcondominiumDeleteRentAdjustmentsRequest {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  byDate: TypeDate;
  objectIds: string[];
}

export type ObjectcondominiumDeleteRentAdjustmentsResponse = object;

export type ObjectcondominiumDeleteResponse = object;

export interface ObjectcondominiumFlatRateValues {
  type: PropertyFlatRateRentComponents;
  /** @format float */
  value: number;
}

export interface ObjectcondominiumGetResponse {
  condominium: CondominiumCondominium;
}

export interface ObjectcondominiumListByBillingContactIdResponse {
  condominiums: CondominiumCondominium[];
}

export interface ObjectcondominiumListByContactIdResponse {
  condominiums: CondominiumCondominium[];
}

export interface ObjectcondominiumListByIdsRequest {
  ids: string[];
}

export interface ObjectcondominiumListByIdsResponse {
  condominiums: CondominiumCondominium[];
}

export interface ObjectcondominiumListByObjectIdsRequest {
  objectIds: string[];
}

export interface ObjectcondominiumListByObjectIdsResponse {
  condominiums: CondominiumCondominium[];
}

export interface ObjectcondominiumListByOwnerResponse {
  condominiums: CondominiumCondominium[];
}

export interface ObjectcondominiumListCurrentByObjectIdsRequest {
  objectIds: string[];
}

export interface ObjectcondominiumListCurrentByObjectIdsResponse {
  condominiums: CondominiumCondominium[];
}

export interface ObjectcondominiumPublishUpdatedEventsRequest {
  ownerIds: string[];
}

export type ObjectcondominiumPublishUpdatedEventsResponse = object;

export interface ObjectcondominiumRent {
  adjustments: ObjectcondominiumRentAdjustment[];
}

export interface ObjectcondominiumRentAdjustment {
  additionalValues: ObjectcondominiumAdditionalValues[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  byDate: TypeDate;
  documentIds: string[];
  dueDates: number[];
  flatRateValues: ObjectcondominiumFlatRateValues[];
}

export interface ObjectcondominiumTenant {
  contactIds: string[];
  correspondenceContactId: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endingAt: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startingAt: TypeDate;
}

/** @default "TYPE_OF_USE_UNDEFINED" */
export enum ObjectcondominiumTypeOfUse {
  TYPE_OF_USE_UNDEFINED = "TYPE_OF_USE_UNDEFINED",
  TYPE_OF_USE_VACATION = "TYPE_OF_USE_VACATION",
  TYPE_OF_USE_COMMERCIAL = "TYPE_OF_USE_COMMERCIAL",
  TYPE_OF_USE_PARKING = "TYPE_OF_USE_PARKING",
  TYPE_OF_USE_PARKING_EXTERNAL = "TYPE_OF_USE_PARKING_EXTERNAL",
  TYPE_OF_USE_AGRICULTURAL = "TYPE_OF_USE_AGRICULTURAL",
  TYPE_OF_USE_CONSTRUCTION = "TYPE_OF_USE_CONSTRUCTION",
  TYPE_OF_USE_GASTRONOMY = "TYPE_OF_USE_GASTRONOMY",
  TYPE_OF_USE_INDUSTRIAL = "TYPE_OF_USE_INDUSTRIAL",
  TYPE_OF_USE_INVESTMENT = "TYPE_OF_USE_INVESTMENT",
  TYPE_OF_USE_STORAGE = "TYPE_OF_USE_STORAGE",
  TYPE_OF_USE_RESIDENTIAL = "TYPE_OF_USE_RESIDENTIAL",
  TYPE_OF_USE_SECONDARY_ROOM = "TYPE_OF_USE_SECONDARY_ROOM",
  TYPE_OF_USE_OFFICE = "TYPE_OF_USE_OFFICE",
  TYPE_OF_USE_STORE = "TYPE_OF_USE_STORE",
  TYPE_OF_USE_ADVERTISING_AREA = "TYPE_OF_USE_ADVERTISING_AREA",
}

export interface ObjectcondominiumUpdateBulkEntry {
  activated: boolean;
  closed: boolean;
  contract: ObjectcondominiumContract;
  documentIds: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endingAt: TypeDate;
  finalInvoiceCreated: boolean;
  flatRateValues: ObjectcondominiumFlatRateValues[];
  id: string;
  opted: boolean;
  owner: CondominiumOwner;
  rent: ObjectcondominiumRent;
  secondaryResidence: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startingAt: TypeDate;
  taxable: boolean;
  tenants: ObjectcondominiumTenant[];
}

export interface ObjectcondominiumUpdateBulkRequest {
  entries: ObjectcondominiumUpdateBulkEntry[];
}

export interface ObjectcondominiumUpdateBulkResponse {
  condominiums: CondominiumCondominium[];
}

export interface ObjectcondominiumUpdateResponse {
  condominium: CondominiumCondominium;
}

export interface ObjectcontractContactData {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface ObjectcontractContractTemplate {
  category: ObjectcontractTemplateCategory;
  creatorName: string;
  id: string;
  mediaId: string;
  /** @format int64 */
  ownerId: string;
  title: string;
}

export interface ObjectcontractCreateTemplateRequest {
  category: ObjectcontractTemplateCategory;
  creatorName: string;
  mediaId: string;
  /** @format int64 */
  ownerId: string;
  title: string;
}

export interface ObjectcontractCreateTemplateResponse {
  template: ObjectcontractContractTemplate;
}

export type ObjectcontractDeleteTemplateResponse = object;

export interface ObjectcontractDeviceData {
  id: string;
  note: string;
}

export interface ObjectcontractListTemplatesResponse {
  templates: ObjectcontractContractTemplate[];
}

export interface ObjectcontractRenderCondominiumTemplateRequest {
  attachAutomatically: boolean;
  /** The ID of the condominium for which a new contract should be rendered */
  condominiumId: string;
  description: string;
  id: string;
  title: string;
}

export interface ObjectcontractRenderCondominiumTemplateResponse {
  /** @format byte */
  contract: string;
}

export interface ObjectcontractRenderCondominiumsTemplateRequest {
  condominiumIds: string[];
  description: string;
  id: string;
  title: string;
}

export interface ObjectcontractRenderCondominiumsTemplateResponse {
  /** @format byte */
  contract: string;
}

export interface ObjectcontractRenderContactTemplateRequest {
  attachAutomatically: boolean;
  contactId: string;
  description: string;
  id: string;
  title: string;
}

export interface ObjectcontractRenderContactTemplateResponse {
  /** @format byte */
  contract: string;
}

export interface ObjectcontractRenderContactsTemplateRequest {
  contactIds: string[];
  description: string;
  id: string;
  title: string;
}

export interface ObjectcontractRenderContactsTemplateResponse {
  /** @format byte */
  contract: string;
}

export interface ObjectcontractRenderDeviceTemplateRequest {
  assigneeContactId: string;
  contactId: string;
  description: string;
  devices: ObjectcontractDeviceData[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  executeFrom: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  executeUntil: TypeDate;
  id: string;
  /** Either provide this or randomContactData, but not both! */
  onSiteContactPersonId: string;
  orderDescription: string;
  /** Either provide this or onSiteContactPersonId, but not both! */
  randomContactData: ObjectcontractContactData;
  title: string;
}

export interface ObjectcontractRenderDeviceTemplateResponse {
  /** @format byte */
  contract: string;
}

export interface ObjectcontractRenderTenanciesTemplateRequest {
  description: string;
  id: string;
  tenancyIds: string[];
  title: string;
}

export interface ObjectcontractRenderTenanciesTemplateResponse {
  /** @format byte */
  contract: string;
}

/** @default "TEMPLATE_CATEGORY_UNDEFINED" */
export enum ObjectcontractTemplateCategory {
  TEMPLATE_CATEGORY_UNDEFINED = "TEMPLATE_CATEGORY_UNDEFINED",
  TEMPLATE_CATEGORY_GENERAL = "TEMPLATE_CATEGORY_GENERAL",
  TEMPLATE_CATEGORY_ORDERS = "TEMPLATE_CATEGORY_ORDERS",
  TEMPLATE_CATEGORY_TERMINATION = "TEMPLATE_CATEGORY_TERMINATION",
  TEMPLATE_CATEGORY_CONTRACTS = "TEMPLATE_CATEGORY_CONTRACTS",
  TEMPLATE_CATEGORY_PAYMENTS = "TEMPLATE_CATEGORY_PAYMENTS",
  TEMPLATE_CATEGORY_OFFER = "TEMPLATE_CATEGORY_OFFER",
  TEMPLATE_CATEGORY_LETTER = "TEMPLATE_CATEGORY_LETTER",
}

export type ObjectcontractUpdateTemplateResponse = object;

export interface ObjectdeviceAddDocumentsResponse {
  device: ObjectdeviceDevice;
}

export interface ObjectdeviceCosts {
  /** @format float */
  amount: number;
  currency: string;
  interval: DeviceCostsInterval;
}

export interface ObjectdeviceCreateRequest {
  /** @format float */
  acquisitionCosts: number;
  /** @format int32 */
  amount: number;
  /** Construction costs plan for heating (German: Baukostenplan Heizung) */
  bkpH: string;
  brand: string;
  /** @format float */
  cataloguePrice: number;
  condition: DeviceDeviceCondition;
  conditionNote: string;
  documentIds: string[];
  facilityPlant: DeviceDeviceFacilityPlant;
  images: ObjectdeviceImage[];
  inspection: DeviceInspection;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  installation: TypeDate;
  /** @format int32 */
  lifeSpan: number;
  lockingKey: DeviceLockingKey;
  maintenance: DeviceMaintenance;
  manufacturer: string;
  measure: DeviceDeviceMeasure;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  measureUntil: TypeDate;
  model: string;
  note: string;
  /** @format int64 */
  ownerId: string;
  referenceId: string;
  revision: DeviceRevision;
  roomId: string;
  serialNumber: string;
  system: DeviceDeviceSystem;
  type: DeviceDeviceType;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  uninstallation: TypeDate;
  unit: ObjectdeviceDeviceUnit;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  warrantyEnd: TypeDate;
}

export interface ObjectdeviceCreateResponse {
  device: ObjectdeviceDevice;
}

export type ObjectdeviceDeleteResponse = object;

export interface ObjectdeviceDevice {
  /** @format float */
  acquisitionCosts: number;
  /** @format int32 */
  amount: number;
  /** Construction costs plan for heating (German: Baukostenplan Heizung) */
  bkpH: string;
  brand: string;
  /** @format float */
  cataloguePrice: number;
  condition: DeviceDeviceCondition;
  conditionNote: string;
  documentIds: string[];
  facilityPlant: DeviceDeviceFacilityPlant;
  id: string;
  images: ObjectdeviceImage[];
  inspection: DeviceInspection;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  installation: TypeDate;
  /** @format int32 */
  lifeSpan: number;
  lockingKey: DeviceLockingKey;
  maintenance: DeviceMaintenance;
  manufacturer: string;
  measure: DeviceDeviceMeasure;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  measureUntil: TypeDate;
  model: string;
  note: string;
  /** @format int64 */
  numericId: string;
  /** @format int64 */
  ownerId: string;
  referenceId: string;
  revision: DeviceRevision;
  roomId: string;
  serialNumber: string;
  system: DeviceDeviceSystem;
  type: DeviceDeviceType;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  uninstallation: TypeDate;
  unit: ObjectdeviceDeviceUnit;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  warrantyEnd: TypeDate;
}

/** @default "DEVICE_UNIT_UNDEFINED" */
export enum ObjectdeviceDeviceUnit {
  DEVICE_UNIT_UNDEFINED = "DEVICE_UNIT_UNDEFINED",
  DEVICE_UNIT_L = "DEVICE_UNIT_L",
  DEVICE_UNIT_KG = "DEVICE_UNIT_KG",
  DEVICEUNITM2 = "DEVICE_UNIT_M2",
  DEVICEUNITM3 = "DEVICE_UNIT_M3",
  DEVICE_UNIT_PCS = "DEVICE_UNIT_PCS",
  DEVICE_UNIT_M = "DEVICE_UNIT_M",
  DEVICE_UNIT_FLAT_RATE = "DEVICE_UNIT_FLAT_RATE",
}

export interface ObjectdeviceImage {
  mediaId: string;
  /** @format int32 */
  order: number;
}

export interface ObjectdeviceListByIdsRequest {
  ids: string[];
}

export interface ObjectdeviceListByIdsResponse {
  devices: ObjectdeviceDevice[];
}

export interface ObjectdeviceListByReferenceIdsRequest {
  referenceIds: string[];
}

export interface ObjectdeviceListByReferenceIdsResponse {
  devices: ObjectdeviceDevice[];
}

export interface ObjectdeviceUpdateResponse {
  device: ObjectdeviceDevice;
}

export interface ObjectenergySourceCreateRequest {
  /** @format float */
  co2PerYear: number;
  /** @format float */
  consumption: number;
  /** @format float */
  consumptionKwhPerYear: number;
  costDistribution: EnergySourceCostDistribution;
  costDistribution2: EnergySourceCostDistribution;
  documentIds: string[];
  /** @format float */
  energyReferenceArea: number;
  heatDistribution: EnergySourceHeatDistribution;
  kind: EnergySourceEnergySourceKind;
  orders: EnergySourceOrder[];
  /** @format int64 */
  ownerId: string;
  photovoltaic: boolean;
  referenceId: string;
  selfConsumptionCommunity: boolean;
  tankReadings: ObjectenergySourceTankReading[];
  /** @format float */
  tankSize: number;
  type: ObjectenergySourceEnergySourceType;
  unit: ObjectenergySourceEnergySourceUnit;
}

export interface ObjectenergySourceCreateResponse {
  energySource: ObjectenergySourceEnergySource;
}

export type ObjectenergySourceDeleteResponse = object;

export interface ObjectenergySourceEnergySource {
  /** @format float */
  co2PerYear: number;
  /** @format float */
  consumption: number;
  /** @format float */
  consumptionKwhPerYear: number;
  costDistribution: EnergySourceCostDistribution;
  costDistribution2: EnergySourceCostDistribution;
  documentIds: string[];
  /** @format float */
  energyReferenceArea: number;
  heatDistribution: EnergySourceHeatDistribution;
  id: string;
  kind: EnergySourceEnergySourceKind;
  orders: EnergySourceOrder[];
  /** @format int64 */
  ownerId: string;
  photovoltaic: boolean;
  referenceId: string;
  selfConsumptionCommunity: boolean;
  tankReadings: ObjectenergySourceTankReading[];
  /** @format float */
  tankSize: number;
  type: ObjectenergySourceEnergySourceType;
  unit: ObjectenergySourceEnergySourceUnit;
}

/** @default "ENERGY_SOURCE_TYPE_UNDEFINED" */
export enum ObjectenergySourceEnergySourceType {
  ENERGY_SOURCE_TYPE_UNDEFINED = "ENERGY_SOURCE_TYPE_UNDEFINED",
  ENERGY_SOURCE_NO = "ENERGY_SOURCE_NO",
  ENERGY_SOURCE_AIR = "ENERGY_SOURCE_AIR",
  ENERGY_SOURCE_GEOTHERMAL_ENERGY = "ENERGY_SOURCE_GEOTHERMAL_ENERGY",
  ENERGY_SOURCE_GEOTHERMAL_PROBE = "ENERGY_SOURCE_GEOTHERMAL_PROBE",
  ENERGY_SOURCE_EARTH_TUBE = "ENERGY_SOURCE_EARTH_TUBE",
  ENERGY_SOURCE_WATER = "ENERGY_SOURCE_WATER",
  ENERGY_SOURCE_GAS = "ENERGY_SOURCE_GAS",
  ENERGY_SOURCE_OIL = "ENERGY_SOURCE_OIL",
  ENERGY_SOURCE_WOOD_GENERIC = "ENERGY_SOURCE_WOOD_GENERIC",
  ENERGY_SOURCE_WOOD = "ENERGY_SOURCE_WOOD",
  ENERGY_SOURCE_WOOD_PELLETS = "ENERGY_SOURCE_WOOD_PELLETS",
  ENERGY_SOURCE_WOOD_CHIPS = "ENERGY_SOURCE_WOOD_CHIPS",
  ENERGY_SOURCE_WASTE_HEAT = "ENERGY_SOURCE_WASTE_HEAT",
  ENERGY_SOURCE_ELECTRICITY = "ENERGY_SOURCE_ELECTRICITY",
  ENERGY_SOURCE_SUN = "ENERGY_SOURCE_SUN",
  ENERGY_SOURCE_DISTRICT_HEATING_GENERIC = "ENERGY_SOURCE_DISTRICT_HEATING_GENERIC",
  ENERGY_SOURCE_DISTRICT_HEATING_HIGH = "ENERGY_SOURCE_DISTRICT_HEATING_HIGH",
  ENERGY_SOURCE_DISTRICT_HEATING_LOW = "ENERGY_SOURCE_DISTRICT_HEATING_LOW",
  ENERGY_SOURCE_HEAT_PUMP = "ENERGY_SOURCE_HEAT_PUMP",
  ENERGY_SOURCE_OTHER = "ENERGY_SOURCE_OTHER",
}

/** @default "ENERGY_SOURCE_UNIT_UNDEFINED" */
export enum ObjectenergySourceEnergySourceUnit {
  ENERGY_SOURCE_UNIT_UNDEFINED = "ENERGY_SOURCE_UNIT_UNDEFINED",
  ENERGY_SOURCE_UNIT_L = "ENERGY_SOURCE_UNIT_L",
  ENERGY_SOURCE_UNIT_KG = "ENERGY_SOURCE_UNIT_KG",
  ENERGYSOURCEUNITM3 = "ENERGY_SOURCE_UNIT_M3",
  ENERGY_SOURCE_UNIT_KWH = "ENERGY_SOURCE_UNIT_KWH",
}

export interface ObjectenergySourceListByIdsRequest {
  ids: string[];
}

export interface ObjectenergySourceListByIdsResponse {
  energySources: ObjectenergySourceEnergySource[];
}

export interface ObjectenergySourceListByReferenceIdsRequest {
  referenceIds: string[];
}

export interface ObjectenergySourceListByReferenceIdsResponse {
  energySources: ObjectenergySourceEnergySource[];
}

export interface ObjectenergySourceTankReading {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  date: TypeDate;
  documentIds: string[];
  /** @format float */
  value: number;
}

export interface ObjectenergySourceUpdateResponse {
  energySource: ObjectenergySourceEnergySource;
}

export interface ObjectgroupAddBuildingResponse {
  group: GroupGroup;
}

export interface ObjectgroupCreateRequest {
  buildingIds: string[];
  name: string;
  /** @format int64 */
  ownerId: string;
}

export interface ObjectgroupCreateResponse {
  group: GroupGroup;
}

export type ObjectgroupDeleteResponse = object;

export interface ObjectgroupGetResponse {
  group: GroupGroup;
}

export interface ObjectgroupListResponse {
  groups: GroupGroup[];
}

export interface ObjectgroupUpdateResponse {
  group: GroupGroup;
}

/** @default "CONDITION_UNDEFINED" */
export enum ObjecthandoverProtocolCondition {
  CONDITION_UNDEFINED = "CONDITION_UNDEFINED",
  CONDITION_PERFECT = "CONDITION_PERFECT",
  CONDITION_NORMAL_WEAR = "CONDITION_NORMAL_WEAR",
  CONDITION_DEFICIENCY = "CONDITION_DEFICIENCY",
}

export interface ObjecthandoverProtocolContactData {
  address: CommonaddressAddress;
  email: string;
  /** Only set on persons/contact persons */
  firstName: string;
  /** Only set on persons/contact persons */
  lastName: string;
  /** Only set on companies */
  name: string;
  phoneNumbers: Contactv2PhoneNumber[];
}

export interface ObjecthandoverProtocolCreateRequest {
  assigneeContactId: string;
  devicesWithoutRoom: ObjecthandoverProtocolDevice[];
  movingInTenancyReferences: HandoverProtocolWriteTenancyReference[];
  movingOutTenancyReferences: HandoverProtocolWriteTenancyReference[];
  note: string;
  /** @format int64 */
  ownerId: string;
  participants: HandoverProtocolWriteParticipant[];
  rooms: ObjecthandoverProtocolRoom[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  scheduledDate: TypeDate;
  /**
   * Represents a time of day. The date and time zone are either not significant
   * or are specified elsewhere. An API may choose to allow leap seconds. Related
   * types are [google.type.Date][google.type.Date] and
   * `google.protobuf.Timestamp`.
   */
  scheduledTime: TypeTimeOfDay;
}

export interface ObjecthandoverProtocolCreateResponse {
  protocol: HandoverProtocolHandoverProtocol;
}

export interface ObjecthandoverProtocolCreator {
  /** @format int64 */
  accountId: string;
  userId: string;
}

export type ObjecthandoverProtocolDeleteResponse = object;

export interface ObjecthandoverProtocolDevice {
  action: HandoverProtocolAction;
  condition: ObjecthandoverProtocolCondition;
  costAbsorption: CostCostAbsorption;
  costShareUnit: CostCostShareUnit;
  /** @format double */
  costShareValue: number;
  deviceData: ObjecthandoverProtocolDeviceData;
  id: string;
  images: ObjecthandoverProtocolImage[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  installationDate: TypeDate;
  keyData: HandoverProtocolKeyData;
  note: string;
  type: DeviceDeviceType;
}

export interface ObjecthandoverProtocolDeviceData {
  /** @format float */
  acquisitionCosts: number;
  /** @format int32 */
  amount: number;
  /** @format float */
  cataloguePrice: number;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  installationDate: TypeDate;
  /** @format int32 */
  lifeSpan: number;
  unit: ObjecthandoverProtocolDeviceUnit;
}

/** @default "DEVICE_UNIT_UNDEFINED" */
export enum ObjecthandoverProtocolDeviceUnit {
  DEVICE_UNIT_UNDEFINED = "DEVICE_UNIT_UNDEFINED",
  DEVICE_UNIT_L = "DEVICE_UNIT_L",
  DEVICE_UNIT_KG = "DEVICE_UNIT_KG",
  DEVICEUNITM2 = "DEVICE_UNIT_M2",
  DEVICEUNITM3 = "DEVICE_UNIT_M3",
  DEVICE_UNIT_PCS = "DEVICE_UNIT_PCS",
  DEVICE_UNIT_M = "DEVICE_UNIT_M",
  DEVICE_UNIT_FLAT_RATE = "DEVICE_UNIT_FLAT_RATE",
}

export type ObjecthandoverProtocolEnableResponse = object;

export interface ObjecthandoverProtocolHasEnabledResponse {
  enabled: boolean;
}

export interface ObjecthandoverProtocolImage {
  attachTo: HandoverProtocolAttachTo;
  mediaId: string;
}

export interface ObjecthandoverProtocolListByIdsRequest {
  ids: string[];
}

export interface ObjecthandoverProtocolListByIdsResponse {
  protocols: HandoverProtocolHandoverProtocol[];
}

export interface ObjecthandoverProtocolListByObjectIdsRequest {
  objectIds: string[];
}

export interface ObjecthandoverProtocolListByObjectIdsResponse {
  protocols: HandoverProtocolHandoverProtocol[];
}

export interface ObjecthandoverProtocolParticipant {
  attending: boolean;
  contactData: ObjecthandoverProtocolContactData;
  contactId: string;
  powerOfAttorneyMediaIds: string[];
  signed: boolean;
  type: HandoverProtocolParticipantType;
}

export interface ObjecthandoverProtocolRoom {
  cleanliness: HandoverProtocolCleanlinessRoom;
  cleanlinessAction: HandoverProtocolCleanlinessRoomAction;
  /** @format double */
  cleanlinessCost: number;
  cleanlinessCostUnit: CostCostShareUnit;
  devices: ObjecthandoverProtocolDevice[];
  id: string;
  name: string;
  note: string;
}

export interface ObjecthandoverProtocolSignature {
  contactId: string;
  hasSigned: boolean;
  /** @format byte */
  signature: string;
  type: HandoverProtocolParticipantType;
}

export interface ObjecthandoverProtocolUpdateResponse {
  protocol: HandoverProtocolHandoverProtocol;
}

export interface ObjectinsuranceCreateRequest {
  /** @format float */
  amount: number;
  benefits: InsuranceInsuranceBenefit[];
  documentIds: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  earliestTerminationDate: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endDate: TypeDate;
  /** @format float */
  excess: number;
  insuranceCompany: string;
  notes: string;
  /**
   * Account ID of owner (who owns the stuff in real life)
   * @format int64
   */
  ownerId: string;
  policyNumber: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  premiumPaidUntil: TypeDate;
  /** Some opaque ID where this entity is attached to (e.g. propertyId or buildingId) */
  referenceId: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startDate: TypeDate;
  type: InsuranceInsuranceType;
  /** @format float */
  yearlyCosts: number;
}

export interface ObjectinsuranceCreateResponse {
  insurance: InsuranceInsurance;
}

export type ObjectinsuranceDeleteResponse = object;

export interface ObjectinsuranceListByIdsResponse {
  insurances: InsuranceInsurance[];
}

export interface ObjectinsuranceListByReferenceIdsResponse {
  insurances: InsuranceInsurance[];
}

export interface ObjectinsuranceUpdateResponse {
  insurance: InsuranceInsurance;
}

export interface ObjectinvestPlanCreateBulkRequest {
  entries: InvestPlanCreateBulkEntry[];
}

export interface ObjectinvestPlanCreateBulkResponse {
  components: InvestPlanComponent[];
}

export type ObjectinvestPlanDeleteResponse = object;

export type ObjectinvestPlanEnableResponse = object;

export interface ObjectinvestPlanHasEnabledResponse {
  enabled: boolean;
}

export interface ObjectinvestPlanListByReferenceIdsRequest {
  ids: string[];
}

export interface ObjectinvestPlanListByReferenceIdsResponse {
  components: InvestPlanComponent[];
}

export interface ObjectinvestPlanUpdateBulkEntry {
  bkp: string;
  /** @format float */
  costs: number;
  id: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  installationDate: TypeDate;
  /** @format int32 */
  lifeSpan: number;
  name: string;
  note: string;
  /** @format float */
  percentage: number;
}

export interface ObjectinvestPlanUpdateBulkRequest {
  entries: ObjectinvestPlanUpdateBulkEntry[];
}

export interface ObjectinvestPlanUpdateBulkResponse {
  components: InvestPlanComponent[];
}

export type ObjectmortgageAddDocumentsResponse = object;

export interface ObjectmortgageCreateRequest {
  /** @format double */
  amortisationAmount: number;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  amortizationFrom: TypeDate;
  amortizations: MortgageAmortization[];
  /** @format double */
  amount: number;
  bankCountry: string;
  bankName: string;
  bankNumber: string;
  bic: string;
  debitAccount: string;
  designation: string;
  documentIds: string[];
  dueDateMonthsAmortization: number[];
  dueDateMonthsInterestRate: number[];
  enableAmortizations: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  expiresAt: TypeDate;
  /** @format float */
  interestRate: number;
  mortgageDesignation: MortgageMortgageDesignation;
  mortgageeAddress: CommonaddressAddress;
  number: string;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  /** Could be property, building or object ID */
  referenceId: string;
  terminableOn: MortgageMortgageTerminableOn;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  validFrom: TypeDate;
}

export interface ObjectmortgageCreateResponse {
  mortgage: MortgageMortgage;
}

export type ObjectmortgageDeleteResponse = object;

export interface ObjectmortgageListByIdsRequest {
  ids: string[];
}

export interface ObjectmortgageListByIdsResponse {
  mortgages: MortgageMortgage[];
}

export interface ObjectmortgageListByReferenceIdsRequest {
  referenceIds: string[];
}

export interface ObjectmortgageListByReferenceIdsResponse {
  mortgages: MortgageMortgage[];
}

export interface ObjectmortgageUpdateResponse {
  mortgage: MortgageMortgage;
}

export interface ObjectobjectAdditionalValues {
  type: PropertyAdditionalRentComponents;
  /** @format float */
  value: number;
}

export interface ObjectobjectAdvancePaymentValue {
  type: PropertyAdvancePaymentRentComponents;
  /** @format float */
  value: number;
}

export interface ObjectobjectCharacteristics {
  additionalRooms: ObjectAdditionalRooms[];
  areas: ObjectAreas;
  /** @format int32 */
  bathrooms: number;
  /**
   * In meter
   * @format float
   */
  ceilingHeight: number;
  condition: ObjectobjectCondition;
  /** @format float */
  decimalFloor: number;
  extras: ObjectExtras[];
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  firstTimeOccupancy: string;
  flatSharingCommunity: ApplicableApplicable;
  /**
   * Ground floor = 0
   * @format int32
   */
  floor: number;
  /**
   * Maximum floor load in kg/m³
   * @format float
   */
  floorLoad: number;
  floorings: ObjectFlooring[];
  /** Widely used to control the amount of foreigners buying holiday flats in popular regions */
  foreignQuota: ApplicableApplicable;
  furnishing: ObjectFurnishing;
  furnishingType: ObjectFurnishingType;
  /** A small building for storing garden tools, bicycles, ... */
  gardenShed: ApplicableApplicable;
  glazings: ObjectGlazing[];
  /**
   * Total premium of an insurance contract before brokerage or discounts have been deducted, in percent.
   * @format int32
   */
  grossPremium: number;
  /** @format int32 */
  guestToilets: number;
  /**
   * In meter
   * @format float
   */
  hallHeight: number;
  heatings: ObjectHeating[];
  kitchens: ObjectKitchen[];
  locationQualities: ObjectobjectLocationQuality[];
  /** @format float */
  numberOfFloors: number;
  /** optiert */
  opted: ApplicableApplicable;
  petAllowances: ObjectPets[];
  pets: ApplicableApplicable;
  photovoltaic: ApplicableApplicable;
  /** Planned for the future */
  planned: ApplicableApplicable;
  /** @format float */
  rooms: number;
  sanitaries: ObjectSanitary[];
  secondaryResidenceAllowed: ApplicableApplicable;
  /** @format int32 */
  separateToilets: number;
  /** Intentionally missing interior finish in order to allow customization */
  shellConstruction: ApplicableApplicable;
  /** @format int32 */
  showers: number;
  smoking: ApplicableApplicable;
  technologies: ObjectTechnology[];
  thermalSolarCollector: ApplicableApplicable;
  /** @format int32 */
  toilets: number;
  /** Mainly used for parking slots */
  underRoof: ApplicableApplicable;
  /** @format float */
  volume: number;
  wheelchairAccessible: ApplicableApplicable;
  windows: ObjectWindow[];
  /** @format int32 */
  yearBuilt: number;
  /** @format int32 */
  yearLastRenovation: number;
}

/** @default "UNDEFINED_CONDITION" */
export enum ObjectobjectCondition {
  UNDEFINED_CONDITION = "UNDEFINED_CONDITION",
  FIRST_OCCUPANCY = "FIRST_OCCUPANCY",
  FIRST_OCCUPANCY_AFTER_RESTORATION = "FIRST_OCCUPANCY_AFTER_RESTORATION",
  EXCELLENT = "EXCELLENT",
  DILAPIDATED = "DILAPIDATED",
  LIVEABLE = "LIVEABLE",
  WELL_KEPT = "WELL_KEPT",
  GOOD = "GOOD",
  MODERNIZED = "MODERNIZED",
  LIKE_NEW = "LIKE_NEW",
  PASSABLE = "PASSABLE",
  PARTLY_RENOVATED = "PARTLY_RENOVATED",
  PARTLY_NEED_OF_RENOVATION = "PARTLY_NEED_OF_RENOVATION",
  PARTLY_RESTORATED = "PARTLY_RESTORATED",
  PARTLY_NEED_OF_RESTORATION = "PARTLY_NEED_OF_RESTORATION",
  FULLY_RENOVATED = "FULLY_RENOVATED",
  FULLY_RESTORATED = "FULLY_RESTORATED",
}

export interface ObjectobjectCountResponse {
  /** @format int32 */
  count: number;
}

export interface ObjectobjectCreateBulkRequest {
  address: CommonaddressAddress;
  administrationType: ObjectAdministrationType;
  /** @format int32 */
  amount: number;
  buildingId: string;
  category: ObjectCategory;
  marketingLanguage: LanguagePreferredLanguage;
  /** @format int64 */
  ownerId: string;
  type: ObjectobjectType;
}

export type ObjectobjectCreateBulkResponse = object;

export interface ObjectobjectCreateRequest {
  address: CommonaddressAddress;
  administrationType: ObjectAdministrationType;
  assignedSideRooms: string;
  availability: ObjectAvailability;
  /** @format int64 */
  awn: string;
  buildingId: string;
  category: ObjectCategory;
  characteristics: ObjectobjectCharacteristics;
  contacts: ObjectContacts;
  deposit: ObjectobjectDeposit;
  description: string;
  development: ObjectobjectDevelopment;
  documents: ObjectobjectDocument[];
  energySources: ObjectobjectEnergySource[];
  ewid: string;
  images: ObjectobjectImage[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  inactiveBy: TypeDate;
  marketingLanguage: LanguagePreferredLanguage;
  name: string;
  needsMatching: boolean;
  note: string;
  number: string;
  /** @format int64 */
  numericId: string;
  otoId: string;
  /** @format int64 */
  ownerId: string;
  parking: ObjectParking[];
  /** Do only use first array element. Others are ignored */
  positions: ObjectPosition[];
  pricing: ObjectPricing;
  publishAddress: boolean;
  published: boolean;
  renovations: ObjectRenovation[];
  sharedUse: string;
  specialFeatures: string;
  subCategories: ObjectSubCategory[];
  tags: string[];
  title: string;
  type: ObjectobjectType;
  unusualAgreement: string;
  utilizations: ObjectUtilization[];
  /** @format float */
  valueQuota: number;
  videos: ObjectVideo[];
  virtualTourUrl: string;
}

export interface ObjectobjectCreateResponse {
  object: ObjectObject;
}

export type ObjectobjectDeleteResponse = object;

export interface ObjectobjectDeposit {
  /** @format float */
  amount: number;
  bankDepotAccepted: boolean;
  bankDepotIds: string[];
  bankDepotNames: string[];
  bankGuaranteeAccepted: boolean;
  bankGuaranteeIds: string[];
  bankGuaranteeNames: string[];
  cashPaymentAccepted: boolean;
  collectiveInsuranceAccepted: boolean;
  collectiveInsuranceNames: string[];
  cooperativeShareAccepted: boolean;
  /** DO NOT USE! Deprecated. Use rent adjustments. */
  depositInsurance: ObjectDepositInsurance;
  depositNeeded: boolean;
  insuranceAccepted: boolean;
  insuranceNames: string[];
}

/**
 * The state of development of the plot
 * @default "UNDEFINED_DEVELOPMENT"
 */
export enum ObjectobjectDevelopment {
  UNDEFINED_DEVELOPMENT = "UNDEFINED_DEVELOPMENT",
  FULL = "FULL",
  PARTIAL = "PARTIAL",
  UNDEVELOPED = "UNDEVELOPED",
}

export interface ObjectobjectDocument {
  documentId: string;
  isFloorPlan: boolean;
  /** True if document is allowed to be published (e.g. to searching platforms) */
  isPublic: boolean;
}

export interface ObjectobjectEnergySource {
  documentIds: string[];
  hasStorage: boolean;
  /** @format int64 */
  orderDate: string;
  /** @format float */
  orderQuantity: number;
  /**
   * DO NOT USE! Deprecated and replaced with tankReadings.
   * @format float
   */
  tankContent: number;
  tankReadings: ObjectobjectTankReading[];
  /** @format float */
  tankSize: number;
  type: ObjectobjectEnergySourceType;
  unit: ObjectobjectEnergySourceUnit;
}

/** @default "UNDEFINED_ENERGY_SOURCE_TYPE" */
export enum ObjectobjectEnergySourceType {
  UNDEFINED_ENERGY_SOURCE_TYPE = "UNDEFINED_ENERGY_SOURCE_TYPE",
  ENERGY_SOURCE_NO = "ENERGY_SOURCE_NO",
  ENERGY_SOURCE_AIR = "ENERGY_SOURCE_AIR",
  ENERGY_SOURCE_GEOTHERMAL_ENERGY = "ENERGY_SOURCE_GEOTHERMAL_ENERGY",
  ENERGY_SOURCE_GEOTHERMAL_PROBE = "ENERGY_SOURCE_GEOTHERMAL_PROBE",
  ENERGY_SOURCE_EARTH_TUBE = "ENERGY_SOURCE_EARTH_TUBE",
  ENERGY_SOURCE_WATER = "ENERGY_SOURCE_WATER",
  ENERGY_SOURCE_GAS = "ENERGY_SOURCE_GAS",
  ENERGY_SOURCE_OIL = "ENERGY_SOURCE_OIL",
  ENERGY_SOURCE_WOOD_GENERIC = "ENERGY_SOURCE_WOOD_GENERIC",
  ENERGY_SOURCE_WOOD = "ENERGY_SOURCE_WOOD",
  ENERGY_SOURCE_WOOD_PELLETS = "ENERGY_SOURCE_WOOD_PELLETS",
  ENERGY_SOURCE_WOOD_CHIPS = "ENERGY_SOURCE_WOOD_CHIPS",
  ENERGY_SOURCE_WASTE_HEAT = "ENERGY_SOURCE_WASTE_HEAT",
  ENERGY_SOURCE_ELECTRICITY = "ENERGY_SOURCE_ELECTRICITY",
  ENERGY_SOURCE_SUN = "ENERGY_SOURCE_SUN",
  ENERGY_SOURCE_DISTRICT_HEATING_GENERIC = "ENERGY_SOURCE_DISTRICT_HEATING_GENERIC",
  ENERGY_SOURCE_DISTRICT_HEATING_HIGH = "ENERGY_SOURCE_DISTRICT_HEATING_HIGH",
  ENERGY_SOURCE_DISTRICT_HEATING_LOW = "ENERGY_SOURCE_DISTRICT_HEATING_LOW",
  ENERGY_SOURCE_HEAT_PUMP = "ENERGY_SOURCE_HEAT_PUMP",
  ENERGY_SOURCE_OTHER = "ENERGY_SOURCE_OTHER",
}

/** @default "ENERGY_SOURCE_UNIT_UNDEFINED" */
export enum ObjectobjectEnergySourceUnit {
  ENERGY_SOURCE_UNIT_UNDEFINED = "ENERGY_SOURCE_UNIT_UNDEFINED",
  ENERGY_SOURCE_UNIT_L = "ENERGY_SOURCE_UNIT_L",
  ENERGY_SOURCE_UNIT_KG = "ENERGY_SOURCE_UNIT_KG",
  ENERGYSOURCEUNITM3 = "ENERGY_SOURCE_UNIT_M3",
  ENERGY_SOURCE_UNIT_STERE = "ENERGY_SOURCE_UNIT_STERE",
}

export interface ObjectobjectFlatRateValues {
  type: PropertyFlatRateRentComponents;
  /** @format float */
  value: number;
}

export interface ObjectobjectGetMarketingStatisticsResponse {
  /** @format int32 */
  employeeInMarketing: number;
  /** @format int32 */
  employeeInMarketingFor12Weeks: number;
  /** @format int32 */
  employeeInMarketingFor4Weeks: number;
  /** @format int32 */
  employeeInMarketingFor8Weeks: number;
  /** @format int32 */
  employeeOnline: number;
  /** @format int32 */
  employeeOnlineFor12Weeks: number;
  /** @format int32 */
  employeeOnlineFor4Weeks: number;
  /** @format int32 */
  employeeOnlineFor8Weeks: number;
  /** @format int32 */
  overallInMarketing: number;
  /** @format int32 */
  overallInMarketingFor12Weeks: number;
  /** @format int32 */
  overallInMarketingFor4Weeks: number;
  /** @format int32 */
  overallInMarketingFor8Weeks: number;
  /** @format int32 */
  overallNewInMarketing: number;
  /** @format int32 */
  overallOnline: number;
  /** @format int32 */
  overallOnlineFor12Weeks: number;
  /** @format int32 */
  overallOnlineFor4Weeks: number;
  /** @format int32 */
  overallOnlineFor8Weeks: number;
}

export interface ObjectobjectGetResponse {
  object: ObjectObject;
}

export interface ObjectobjectGetUsedTagsResponse {
  tags: string[];
}

export interface ObjectobjectImage {
  mediaId: string;
  /** @format int32 */
  order: number;
  title: string;
}

export interface ObjectobjectListByBuildingIdsRequest {
  buildingIds: string[];
}

export interface ObjectobjectListByBuildingIdsResponse {
  objects: ObjectObject[];
}

export interface ObjectobjectListByContactIdResponse {
  objects: ObjectObject[];
}

export interface ObjectobjectListByIdsRequest {
  ids: string[];
}

export interface ObjectobjectListByIdsResponse {
  objects: ObjectObject[];
}

export interface ObjectobjectListFilterOptionsResponse {
  options: ObjectObjectFilterOption[];
}

export interface ObjectobjectListResponse {
  objects: ObjectObject[];
  /** @format int32 */
  totalObjectCount: number;
  /** @format int32 */
  totalPageCount: number;
}

/** @default "PARTICULARLY_QUIET" */
export enum ObjectobjectLocationQuality {
  PARTICULARLY_QUIET = "PARTICULARLY_QUIET",
  SUNNY = "SUNNY",
  BEAUTIFUL_VIEW = "BEAUTIFUL_VIEW",
  HILLSIDE = "HILLSIDE",
  LAKESIDE = "LAKESIDE",
  UNOBSTRUCTABLE = "UNOBSTRUCTABLE",
  FOREST = "FOREST",
  COMMERCIAL_CENTER = "COMMERCIAL_CENTER",
  PEDESTRIAN_AREA = "PEDESTRIAN_AREA",
  CENTRAL = "CENTRAL",
  WELL_DEVELOPED = "WELL_DEVELOPED",
}

export interface ObjectobjectPublishUpdatedEventsRequest {
  ownerIds: string[];
}

export type ObjectobjectPublishUpdatedEventsResponse = object;

export interface ObjectobjectTankReading {
  /** @format int64 */
  date: string;
  documentIds: string[];
  /** @format float */
  value: number;
}

/** @default "RENT" */
export enum ObjectobjectType {
  RENT = "RENT",
  SALE = "SALE",
}

export interface ObjectobjectUpdateResponse {
  object: ObjectObject;
}

export interface ObjectplotCreateRequest {
  plotCreations: PlotPlotCreation[];
}

export interface ObjectplotCreateResponse {
  plots: PlotPlot[];
}

export type ObjectplotDeleteResponse = object;

export interface ObjectplotListByIdsResponse {
  plots: PlotPlot[];
}

export interface ObjectplotUpdateRequest {
  plotUpdates: PlotPlotUpdate[];
}

export interface ObjectplotUpdateResponse {
  plots: PlotPlot[];
}

export interface ObjectpropertyAddDocumentsByNumericIdResponse {
  property: ObjectpropertyProperty;
}

export interface ObjectpropertyCountResponse {
  /** @format int32 */
  count: number;
}

export interface ObjectpropertyCreateRequest {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  acquisitionDate: TypeDate;
  additionalRentComponents: PropertyAdditionalRentComponents[];
  /** Only zip, city and state are required and set */
  address: CommonaddressAddress;
  advancePaymentRentComponents: PropertyAdvancePaymentRentComponents[];
  advancePaymentSeeContractDescription: string;
  buildingIds: string[];
  constructionZone: string;
  development: ObjectpropertyDevelopment;
  distributionKeys: PropertyDistributionKeys;
  documentIds: string[];
  egrid: string;
  flatRateRentComponents: PropertyFlatRateRentComponents[];
  flatRateSeeContractDescription: string;
  imageMediaId: string;
  landRegisterNumber: string;
  name: string;
  note: string;
  /** @format int32 */
  number: number;
  opted: boolean;
  optedPortionKey: ObjectpropertyOptedPortionKey;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  /** @format float */
  plotArea: number;
  transferToAccounting: boolean;
  type: ObjectpropertyPropertyType;
}

export interface ObjectpropertyCreateResponse {
  property: ObjectpropertyProperty;
}

export type ObjectpropertyDeleteResponse = object;

/** @default "UNDEFINED_DEVELOPMENT" */
export enum ObjectpropertyDevelopment {
  UNDEFINED_DEVELOPMENT = "UNDEFINED_DEVELOPMENT",
  FULL = "FULL",
  PARTIAL = "PARTIAL",
  UNDEVELOPED = "UNDEVELOPED",
}

export type ObjectpropertyFinishOnboardingResponse = object;

export interface ObjectpropertyListByBuildingIdsResponse {
  properties: ObjectpropertyProperty[];
}

export interface ObjectpropertyListByIdsResponse {
  properties: ObjectpropertyProperty[];
}

export interface ObjectpropertyListResponse {
  properties: ObjectpropertyProperty[];
  /** @format int32 */
  totalPageCount: number;
  /** @format int32 */
  totalPropertiesCount: number;
}

/** @default "OPTED_PORTION_KEY_UNDEFINED" */
export enum ObjectpropertyOptedPortionKey {
  OPTED_PORTION_KEY_UNDEFINED = "OPTED_PORTION_KEY_UNDEFINED",
  OPTEDPORTIONKEYM2 = "OPTED_PORTION_KEY_M2",
  OPTEDPORTIONKEYM3 = "OPTED_PORTION_KEY_M3",
  OPTED_PORTION_KEY_VALUE_RATIO = "OPTED_PORTION_KEY_VALUE_RATIO",
  OPTED_PORTION_KEY_TV = "OPTED_PORTION_KEY_TV",
  OPTED_PORTION_KEY_ELEVATOR = "OPTED_PORTION_KEY_ELEVATOR",
  OPTED_PORTION_KEY_LOCATION_COMPENSATION = "OPTED_PORTION_KEY_LOCATION_COMPENSATION",
  OPTED_PORTION_KEY_UNIT = "OPTED_PORTION_KEY_UNIT",
  OPTED_PORTION_KEY_NUMBER_OF_INHABITANTS = "OPTED_PORTION_KEY_NUMBER_OF_INHABITANTS",
  OPTED_PORTION_KEY_SHARE = "OPTED_PORTION_KEY_SHARE",
}

export interface ObjectpropertyProperty {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  acquisitionDate: TypeDate;
  additionalRentComponents: PropertyAdditionalRentComponents[];
  /** Only zip, city and state are required and set */
  address: CommonaddressAddress;
  advancePaymentRentComponents: PropertyAdvancePaymentRentComponents[];
  advancePaymentSeeContractDescription: string;
  buildingIds: string[];
  constructionZone: string;
  development: ObjectpropertyDevelopment;
  distributionKeys: PropertyDistributionKeys;
  documentIds: string[];
  egrid: string;
  flatRateRentComponents: PropertyFlatRateRentComponents[];
  flatRateSeeContractDescription: string;
  id: string;
  imageMediaId: string;
  landRegisterNumber: string;
  name: string;
  note: string;
  /** @format int32 */
  number: number;
  /** @format int64 */
  numericId: string;
  onboardingFinished: boolean;
  opted: boolean;
  optedPortionKey: ObjectpropertyOptedPortionKey;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  /** @format float */
  plotArea: number;
  transferToAccounting: boolean;
  type: ObjectpropertyPropertyType;
}

/** @default "PROPERTY_TYPE_UNDEFINED" */
export enum ObjectpropertyPropertyType {
  PROPERTY_TYPE_UNDEFINED = "PROPERTY_TYPE_UNDEFINED",
  PROPERTY_TYPE_RENTAL = "PROPERTY_TYPE_RENTAL",
  PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP = "PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP",
  PROPERTY_TYPE_COOPERATIVE = "PROPERTY_TYPE_COOPERATIVE",
}

export interface ObjectpropertyTransferToAccountingRequest {
  ids: string[];
}

export type ObjectpropertyTransferToAccountingResponse = object;

export interface ObjectpropertyUpdateResponse {
  property: ObjectpropertyProperty;
}

export interface Objectpropertyv2CreateRequest {
  additionalRentComponents: V2AdditionalRentComponentEntry[];
  /** Only zip, city and state are required and set */
  address: CommonaddressAddress;
  advancePaymentRentComponents: V2AdvancePaymentRentComponentEntry[];
  bankInfo: V2BankInfo[];
  buildingIds: string[];
  constructionZone: string;
  development: Propertyv2Development;
  documentIds: string[];
  egrid: string;
  flatRateRentComponents: V2FlatRateRentComponentEntry[];
  imageMediaId: string;
  landRegisterNumber: string;
  name: string;
  note: string;
  /** @format int64 */
  numericId: string;
  onboardingFinished: boolean;
  opted: boolean;
  optedPortionKey: Propertyv2OptedPortionKey;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  /** @format float */
  plotArea: number;
  /** @format int64 */
  propertyValue: string;
  transferToAccounting: boolean;
  type: Propertyv2PropertyType;
}

export interface Objectpropertyv2CreateResponse {
  property: Propertyv2Property;
}

export type Objectpropertyv2DeleteResponse = object;

export interface Objectpropertyv2ListByIdsResponse {
  properties: Propertyv2Property[];
}

export interface Objectpropertyv2ListResponse {
  properties: Propertyv2Property[];
  /** @format int32 */
  totalPageCount: number;
  /** @format int32 */
  totalPropertiesCount: number;
}

export interface Objectpropertyv2UpdateResponse {
  property: Propertyv2Property;
}

export interface ObjectrentalIndexCreateRequest {
  compositionLinesOrColumns: RentalIndexCompositionLinesOrColumns;
  compositionType: RentalIndexCompositionType;
  fields: RentalIndexTemplateField[];
  language: LanguagePreferredLanguage;
  name: string;
  /** @format int64 */
  ownerId: string;
  sortingType: RentalIndexSortingType;
  tenancyType: RentalIndexTenancyType;
}

export interface ObjectrentalIndexCreateResponse {
  template: RentalIndexRentalIndexTemplate;
}

export type ObjectrentalIndexDeleteResponse = object;

export interface ObjectrentalIndexListResponse {
  templates: RentalIndexRentalIndexTemplate[];
}

export interface ObjectrentalIndexRenderRequest {
  buildingIds: string[];
  compositionLinesOrColumns: RentalIndexCompositionLinesOrColumns;
  compositionType: RentalIndexCompositionType;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endDate: TypeDate;
  fields: RentalIndexTemplateField[];
  language: LanguagePreferredLanguage;
  sortingType: RentalIndexSortingType;
  /** If fixed date leave empty */
  startDate: TypeDate;
  tenancyType: RentalIndexTenancyType;
}

export interface ObjectrentalIndexRenderResponse {
  /** @format byte */
  file: string;
}

export interface ObjectrentalIndexUpdateResponse {
  template: RentalIndexRentalIndexTemplate;
}

export interface ObjectroomCreateRequest {
  roomCreations: RoomRoomCreation[];
}

export interface ObjectroomCreateResponse {
  rooms: ObjectroomRoom[];
}

export type ObjectroomDeleteResponse = object;

export interface ObjectroomListByIdsRequest {
  ids: string[];
}

export interface ObjectroomListByIdsResponse {
  rooms: ObjectroomRoom[];
}

export interface ObjectroomListByReferenceIdsRequest {
  referenceIds: string[];
}

export interface ObjectroomListByReferenceIdsResponse {
  rooms: ObjectroomRoom[];
}

export interface ObjectroomRoom {
  dimensions: RoomRoomDimensions;
  documentIds: string[];
  id: string;
  imageMediaIds: string[];
  name: string;
  note: string;
  /** @format int64 */
  ownerId: string;
  referenceId: string;
}

export interface ObjectroomUpdateRequest {
  roomUpdates: RoomRoomUpdate[];
}

export interface ObjectroomUpdateResponse {
  rooms: ObjectroomRoom[];
}

/** @default "UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE" */
export enum ObjectsettlementObjectAdditionalCostsBillingType {
  UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE = "UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE",
  FLATRATE_ADDITIONAL_COSTS_BILLING_TYPE = "FLATRATE_ADDITIONAL_COSTS_BILLING_TYPE",
  ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE = "ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE",
}

export interface ObjectsettlementObjectAdditionalValues {
  type: PropertyAdditionalRentComponents;
  /** @format float */
  value: number;
}

export interface ObjectsettlementObjectAdvancePaymentValue {
  type: PropertyAdvancePaymentRentComponents;
  /** @format float */
  value: number;
}

export interface ObjectsettlementObjectCreateRequest {
  activated: boolean;
  additionalCostsBillingType: ObjectsettlementObjectAdditionalCostsBillingType;
  billingContactId: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  billingEnd: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  billingStart: TypeDate;
  correspondenceContactId: string;
  description: string;
  opted: boolean;
  /** @format int64 */
  ownerId: string;
  ownerIsDebtor: boolean;
  referenceId: string;
  rentAdjustments: ObjectsettlementObjectRentAdjustment[];
}

export interface ObjectsettlementObjectCreateResponse {
  settlementObject: SettlementObjectSettlementObject;
}

export type ObjectsettlementObjectDeleteResponse = object;

export interface ObjectsettlementObjectFlatRateValues {
  type: PropertyFlatRateRentComponents;
  /** @format float */
  value: number;
}

export interface ObjectsettlementObjectListByIdsRequest {
  ids: string[];
}

export interface ObjectsettlementObjectListByIdsResponse {
  settlementObjects: SettlementObjectSettlementObject[];
}

export interface ObjectsettlementObjectRentAdjustment {
  additionalValues: ObjectsettlementObjectAdditionalValues[];
  advancePaymentValues: ObjectsettlementObjectAdvancePaymentValue[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  byDate: TypeDate;
  documentIds: string[];
  dueDates: number[];
  flatRateValues: ObjectsettlementObjectFlatRateValues[];
  /** @format double */
  netRent: number;
}

export interface ObjectsettlementObjectUpdateResponse {
  settlementObject: SettlementObjectSettlementObject;
}

export interface ObjecttenancyActivateResponse {
  tenancy: ObjecttenancyTenancy;
}

export interface ObjecttenancyAddDocumentsByNumericIdResponse {
  tenancy: ObjecttenancyTenancy;
}

export interface ObjecttenancyAddDocumentsResponse {
  tenancy: ObjecttenancyTenancy;
}

/** @default "UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE" */
export enum ObjecttenancyAdditionalCostsBillingType {
  UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE = "UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE",
  FLATRATE_ADDITIONAL_COSTS_BILLING_TYPE = "FLATRATE_ADDITIONAL_COSTS_BILLING_TYPE",
  ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE = "ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE",
}

export interface ObjecttenancyAdditionalValues {
  type: PropertyAdditionalRentComponents;
  /** @format float */
  value: number;
}

export interface ObjecttenancyAdvancePaymentValue {
  type: PropertyAdvancePaymentRentComponents;
  /** @format float */
  value: number;
}

export interface ObjecttenancyContract {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  activationDate: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  creationDate: TypeDate;
  intendedUse: string;
  licensePlate: string;
  number: string;
  rentType: TenancyRentType;
  type: TenancyContractType;
  typeOfUse: ObjecttenancyTypeOfUse;
}

export interface ObjecttenancyCreateRequest {
  activated: boolean;
  closed: boolean;
  contract: ObjecttenancyContract;
  contractAmendments: string;
  deposit: ObjecttenancyDeposit;
  /**
   * Date when tenant wants to move out
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  desiredMoveOutAt: TypeDate;
  documentIds: string[];
  earlyRightOfTerminationOptions: TenancyEarlyRightOfTerminationOption[];
  /**
   * Date when tenancy ends
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endingAt: TypeDate;
  familyFlat: boolean;
  finalInvoiceCreated: boolean;
  indexData: TenancyIndexData;
  /**
   * Date when tenant liability ends
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  liabilityEndsAt: TypeDate;
  /**
   * Date when tenant actually moved out
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  movedOutAt: TypeDate;
  /**
   * Date when next tenant could possibly move in
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  nextPossibleMoveInAt: TypeDate;
  note: string;
  /** @format int64 */
  numericId: string;
  objectId: string;
  opted: boolean;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  /** DO NOT USE! Deprecated and ignored. */
  reasonForTermination: string;
  renewalOptions: TenancyRenewalOption[];
  rent: ObjecttenancyRent;
  /**
   * Date when tenant returns object
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  returningAt: TypeDate;
  roommates: TenancyRoommate[];
  secondaryResidence: boolean;
  /**
   * Date when tenancy starts
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startingAt: TypeDate;
  subTenants: TenancySubTenant[];
  taxable: boolean;
  tenant: ObjecttenancyTenant;
  terminatedBy: TenancyTerminatedBy;
  /**
   * Date when on which date tenancy should be terminated
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminatedOn: TypeDate;
  /**
   * Date when termination was handed in
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminationCreatedAt: TypeDate;
  terminationJustification: string;
  terminationLandlord: TenancyTermination;
  terminationReason: TenancyTerminationReason[];
  /**
   * Date when termination was sent by tenant
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminationSentByTenantAt: TypeDate;
  terminationTenant: TenancyTermination;
  terminationType: TenancyTerminationType;
  unjustifiedUse: TenancyUnjustifiedUse;
  vacancy: boolean;
  vacancyReason: TenancyVacancyReason;
}

export interface ObjecttenancyCreateResponse {
  tenancy: ObjecttenancyTenancy;
}

export interface ObjecttenancyDeactivateByNumericIdResponse {
  tenancy: ObjecttenancyTenancy;
}

export interface ObjecttenancyDeactivateResponse {
  tenancy: ObjecttenancyTenancy;
}

export interface ObjecttenancyDeleteRentAdjustmentsRequest {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  byDate: TypeDate;
  objectIds: string[];
}

export type ObjecttenancyDeleteRentAdjustmentsResponse = object;

export type ObjecttenancyDeleteResponse = object;

export interface ObjecttenancyDeposit {
  address: CommonaddressAddress;
  /** @format float */
  amount: number;
  bankAccount: string;
  bankName: string;
  bic: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  certificateReceivedAt: TypeDate;
  /** @format int32 */
  clearingNumber: number;
  depositShares: TenancyDepositShare[];
  dividedBySeveralPersons: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dueDate: TypeDate;
  insuranceCompany: string;
  note: string;
  /** @format float */
  paidAmount: number;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  paidAt: TypeDate;
  paidOut: boolean;
  /** @format float */
  paidOutAmount: number;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  paidOutAt: TypeDate;
  policyNumber: string;
  type: TenancyDepositType;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  validFrom: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  validUntil: TypeDate;
}

export interface ObjecttenancyFlatRateValues {
  type: PropertyFlatRateRentComponents;
  /** @format float */
  value: number;
}

export interface ObjecttenancyGetResponse {
  tenancy: ObjecttenancyTenancy;
}

export interface ObjecttenancyListByBillingContactIdResponse {
  tenancies: ObjecttenancyTenancy[];
}

export interface ObjecttenancyListByContactIdResponse {
  tenancies: ObjecttenancyTenancy[];
}

export interface ObjecttenancyListByIdsRequest {
  ids: string[];
}

export interface ObjecttenancyListByIdsResponse {
  tenancies: ObjecttenancyTenancy[];
}

export interface ObjecttenancyListByOwnerResponse {
  tenancies: ObjecttenancyTenancy[];
  /** @format int32 */
  totalPageCount: number;
  /** @format int32 */
  totalTenanciesCount: number;
}

export interface ObjecttenancyListCurrentByObjectIdsRequest {
  objectIds: string[];
}

export interface ObjecttenancyListCurrentByObjectIdsResponse {
  tenancies: ObjecttenancyTenancy[];
}

export interface ObjecttenancyListRequest {
  objectIds: string[];
}

export interface ObjecttenancyListResponse {
  tenancies: ObjecttenancyTenancy[];
}

export interface ObjecttenancyPublishUpdatedEventsRequest {
  ownerIds: string[];
}

export type ObjecttenancyPublishUpdatedEventsResponse = object;

export interface ObjecttenancyRent {
  /**
   * Ignored on write. Only use as read value.
   * @format float
   */
  additionalCosts: number;
  additionalCostsBillingType: ObjecttenancyAdditionalCostsBillingType;
  adjustments: ObjecttenancyRentAdjustment[];
  /** DO NOT USE! Deprecated. Use rent adjustments. */
  advancePaymentValues: ObjecttenancyAdvancePaymentValue[];
  /**
   * DO NOT USE! Deprecated. Use rent adjustments.
   * @format float
   */
  coldRent: number;
  /**
   * UNIX timestamp in seconds
   * DO NOT USE! Deprecated. Use rent adjustments.
   * @format int64
   */
  costIncreaseDate: string;
  /** DO NOT USE! Deprecated. Use rent adjustments. */
  costIncreaseType: TenancyCostIncreaseType;
  /**
   * DO NOT USE! Deprecated. Use rent adjustments.
   * @format float
   */
  costIncreaseValue: number;
  /** DO NOT USE! Deprecated. Use rent adjustments. */
  countryIndex: TenancyCountryIndex;
  /** DO NOT USE! Deprecated. Use rent adjustments. */
  dueDates: number[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  firstRentAdjustmentPossibleAt: TypeDate;
  /** DO NOT USE! Deprecated. Use rent adjustments. */
  flatRateValues: ObjecttenancyFlatRateValues[];
  /**
   * Ignored on write. Only use as read value.
   * @format float
   */
  heatingCosts: number;
  /** DO NOT USE! Deprecated. Use rent adjustments. */
  inArrears: boolean;
  interval: TenancyTenancyPaymentInterval;
  /**
   * DO NOT USE! Deprecated. Use rent adjustments.
   * @format int32
   */
  monthlyRentDueBy: number;
  /** DO NOT USE! Deprecated. Use rent adjustments. */
  referenceInterestRate: TenancyReferenceInterestRate;
}

export interface ObjecttenancyRentAdjustment {
  /**
   * Ignored on write. Only use as read value.
   * @format float
   */
  additionalCosts: number;
  /** DO NOT USE! Deprecated and ignored. */
  additionalCostsBillingType: ObjecttenancyAdditionalCostsBillingType;
  /**
   * Ignored on write. Only use as read value.
   * @format float
   */
  additionalCostsPerMonth: number;
  additionalValues: ObjecttenancyAdditionalValues[];
  advancePaymentValues: ObjecttenancyAdvancePaymentValue[];
  /**
   * Date when this adjustment takes affect
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  byDate: TypeDate;
  calculationBasis: TenancyTenancyCalculationBasis;
  /** @format float */
  coldRent: number;
  /** @format float */
  coldRentM2PerYear: number;
  /** @format float */
  coldRentPerMonth: number;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  costIncreaseDate: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  costIncreasePossibleDate: TypeDate;
  costIncreaseType: TenancyCostIncreaseType;
  /** @format float */
  costIncreaseValue: number;
  countryIndex: TenancyCountryIndex;
  documentIds: string[];
  dueDates: number[];
  /** DO NOT USE! Deprecated and ignored. */
  finalized: boolean;
  flatRateValues: ObjecttenancyFlatRateValues[];
  /**
   * Ignored on write. Only use as read value.
   * @format float
   */
  grossRent: number;
  /**
   * Ignored on write. Only use as read value.
   * @format float
   */
  grossRentPerMonth: number;
  /**
   * Ignored on write. Only use as read value.
   * @format float
   */
  heatingCosts: number;
  inArrears: boolean;
  /** @format int32 */
  monthlyRentDueBy: number;
  referenceInterestRate: TenancyReferenceInterestRate;
  retentionOfRents: TenancyRetentionOfRent[];
}

export interface ObjecttenancyTenancy {
  activated: boolean;
  closed: boolean;
  contract: ObjecttenancyContract;
  contractAmendments: string;
  deposit: ObjecttenancyDeposit;
  /**
   * Date when tenant wants to move out
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  desiredMoveOutAt: TypeDate;
  documentIds: string[];
  earlyRightOfTerminationOptions: TenancyEarlyRightOfTerminationOption[];
  /**
   * Date when tenancy ends
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endingAt: TypeDate;
  familyFlat: boolean;
  finalInvoiceCreated: boolean;
  id: string;
  indexData: TenancyIndexData;
  isLatest: boolean;
  /**
   * Date when tenant liability ends
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  liabilityEndsAt: TypeDate;
  /**
   * Date when tenant actually moved out
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  movedOutAt: TypeDate;
  /**
   * Date when next tenant could possibly move in
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  nextPossibleMoveInAt: TypeDate;
  note: string;
  /** @format int64 */
  numericId: string;
  objectId: string;
  opted: boolean;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  /** DO NOT USE! Deprecated and ignored. */
  reasonForTermination: string;
  renewalOptions: TenancyRenewalOption[];
  rent: ObjecttenancyRent;
  /**
   * Date when tenant returns object
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  returningAt: TypeDate;
  roommates: TenancyRoommate[];
  secondaryResidence: boolean;
  /**
   * Date when tenancy starts
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startingAt: TypeDate;
  status: TenancyTenancyStatus;
  subTenants: TenancySubTenant[];
  taxable: boolean;
  tenant: ObjecttenancyTenant;
  terminatedBy: TenancyTerminatedBy;
  /**
   * Date when on which date tenancy should be terminated
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminatedOn: TypeDate;
  /**
   * Date when termination was handed in
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminationCreatedAt: TypeDate;
  terminationJustification: string;
  terminationLandlord: TenancyTermination;
  terminationReason: TenancyTerminationReason[];
  /**
   * Date when termination was sent by tenant
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminationSentByTenantAt: TypeDate;
  terminationTenant: TenancyTermination;
  terminationType: TenancyTerminationType;
  unjustifiedUse: TenancyUnjustifiedUse;
  /**
   * UNIX timestamp in seconds when tenancy was modified the last time
   * @format int64
   */
  updatedAt: string;
  vacancy: boolean;
  vacancyReason: TenancyVacancyReason;
}

export interface ObjecttenancyTenant {
  billingContactId: string;
  contactIds: string[];
  correspondenceContactId: string;
  guarantorContactIds: string[];
  /**
   * DO NOT USE! Deprecated and ignored. Use industryName
   * @format int32
   */
  industryCode: number;
  industryName: string;
  /** @format int32 */
  numberOfPersons: number;
  type: TenancyTenantType;
}

/** @default "TYPE_OF_USE_UNDEFINED" */
export enum ObjecttenancyTypeOfUse {
  TYPE_OF_USE_UNDEFINED = "TYPE_OF_USE_UNDEFINED",
  TYPE_OF_USE_VACATION = "TYPE_OF_USE_VACATION",
  TYPE_OF_USE_COMMERCIAL = "TYPE_OF_USE_COMMERCIAL",
  TYPE_OF_USE_PARKING = "TYPE_OF_USE_PARKING",
  TYPE_OF_USE_PARKING_EXTERNAL = "TYPE_OF_USE_PARKING_EXTERNAL",
  TYPE_OF_USE_AGRICULTURAL = "TYPE_OF_USE_AGRICULTURAL",
  TYPE_OF_USE_CONSTRUCTION = "TYPE_OF_USE_CONSTRUCTION",
  TYPE_OF_USE_GASTRONOMY = "TYPE_OF_USE_GASTRONOMY",
  TYPE_OF_USE_INDUSTRIAL = "TYPE_OF_USE_INDUSTRIAL",
  TYPE_OF_USE_INVESTMENT = "TYPE_OF_USE_INVESTMENT",
  TYPE_OF_USE_STORAGE = "TYPE_OF_USE_STORAGE",
  TYPE_OF_USE_RESIDENTIAL = "TYPE_OF_USE_RESIDENTIAL",
  TYPE_OF_USE_SECONDARY_ROOM = "TYPE_OF_USE_SECONDARY_ROOM",
  TYPE_OF_USE_OFFICE = "TYPE_OF_USE_OFFICE",
  TYPE_OF_USE_STORE = "TYPE_OF_USE_STORE",
  TYPE_OF_USE_ADVERTISING_AREA = "TYPE_OF_USE_ADVERTISING_AREA",
  TYPE_OF_USE_RADIO_ANTENNA = "TYPE_OF_USE_RADIO_ANTENNA",
}

export interface ObjecttenancyUpdateBulkEntry {
  activated: boolean;
  closed: boolean;
  contract: ObjecttenancyContract;
  contractAmendments: string;
  deposit: ObjecttenancyDeposit;
  /**
   * Date when tenant wants to move out
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  desiredMoveOutAt: TypeDate;
  documentIds: string[];
  earlyRightOfTerminationOptions: TenancyEarlyRightOfTerminationOption[];
  /**
   * Date when tenancy ends
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endingAt: TypeDate;
  familyFlat: boolean;
  finalInvoiceCreated: boolean;
  id: string;
  indexData: TenancyIndexData;
  /**
   * Date when tenant liability ends
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  liabilityEndsAt: TypeDate;
  /**
   * Date when tenant actually moved out
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  movedOutAt: TypeDate;
  /**
   * Date when next tenant could possibly move in
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  nextPossibleMoveInAt: TypeDate;
  note: string;
  objectId: string;
  opted: boolean;
  /** DO NOT USE! Deprecated and ignored. */
  reasonForTermination: string;
  renewalOptions: TenancyRenewalOption[];
  rent: ObjecttenancyRent;
  /**
   * Date when tenant returns object
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  returningAt: TypeDate;
  roommates: TenancyRoommate[];
  secondaryResidence: boolean;
  /**
   * Date when tenancy starts
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startingAt: TypeDate;
  subTenants: TenancySubTenant[];
  taxable: boolean;
  tenant: ObjecttenancyTenant;
  terminatedBy: TenancyTerminatedBy;
  /**
   * Date when on which date tenancy should be terminated
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminatedOn: TypeDate;
  /**
   * Date when termination was handed in
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminationCreatedAt: TypeDate;
  terminationJustification: string;
  terminationLandlord: TenancyTermination;
  terminationReason: TenancyTerminationReason[];
  /**
   * Date when termination was sent by tenant
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminationSentByTenantAt: TypeDate;
  terminationTenant: TenancyTermination;
  terminationType: TenancyTerminationType;
  unjustifiedUse: TenancyUnjustifiedUse;
  vacancyReason: TenancyVacancyReason;
}

export interface ObjecttenancyUpdateBulkRequest {
  entries: ObjecttenancyUpdateBulkEntry[];
}

export interface ObjecttenancyUpdateBulkResponse {
  tenancies: ObjecttenancyTenancy[];
}

export interface ObjecttenancyUpdateResponse {
  tenancy: ObjecttenancyTenancy;
}

export interface PlotListByOwnerIdResponse {
  plots: PlotPlot[];
  /** @format int32 */
  totalPageCount: number;
  /** @format int32 */
  totalPlotCount: number;
}

export interface PlotListByPropertyIdResponse {
  plots: PlotPlot[];
}

export interface PlotPlot {
  /** @format float */
  cadastralValue: number;
  constructionZone: string;
  documentIds: string[];
  easements: string;
  egrid: string;
  formOfOwnership: string;
  id: string;
  inventories: string;
  landRegisterNumber: string;
  loads: string;
  noiseSensitivity: string;
  note: string;
  /** @format int64 */
  numericId: string;
  /** @format int64 */
  ownerId: string;
  /** @format float */
  plotArea: number;
  plotType: string;
  priorityNotices: string;
  propertyId: string;
  /** @format float */
  purchasePrice: number;
  realEstateRights: string;
  utilizationZone: string;
}

export interface PlotPlotCreation {
  /** @format float */
  cadastralValue: number;
  constructionZone: string;
  documentIds: string[];
  easements: string;
  egrid: string;
  formOfOwnership: string;
  inventories: string;
  landRegisterNumber: string;
  loads: string;
  noiseSensitivity: string;
  note: string;
  /** @format int64 */
  ownerId: string;
  /** @format float */
  plotArea: number;
  plotType: string;
  priorityNotices: string;
  propertyId: string;
  /** @format float */
  purchasePrice: number;
  realEstateRights: string;
  utilizationZone: string;
}

export interface PlotPlotUpdate {
  /** @format float */
  cadastralValue: number;
  constructionZone: string;
  documentIds: string[];
  easements: string;
  egrid: string;
  formOfOwnership: string;
  id: string;
  inventories: string;
  landRegisterNumber: string;
  loads: string;
  noiseSensitivity: string;
  note: string;
  /** @format float */
  plotArea: number;
  plotType: string;
  priorityNotices: string;
  /** @format float */
  purchasePrice: number;
  realEstateRights: string;
  utilizationZone: string;
}

/**
 * - ADDITIONAL_RENT_COMPONENT_RENT: Added for COOZ-10074
 * @default "ADDITIONAL_RENT_COMPONENT_UNDEFINED"
 */
export enum PropertyAdditionalRentComponents {
  ADDITIONAL_RENT_COMPONENT_UNDEFINED = "ADDITIONAL_RENT_COMPONENT_UNDEFINED",
  ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION = "ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION",
  ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION = "ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION",
  ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT = "ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT",
  ADDITIONAL_RENT_COMPONENT_AMORTIZATION = "ADDITIONAL_RENT_COMPONENT_AMORTIZATION",
  ADDITIONAL_RENT_COMPONENT_RENT = "ADDITIONAL_RENT_COMPONENT_RENT",
}

/** @default "ADVANCE_PAYMENT_RENT_COMPONENT_UNDEFINED" */
export enum PropertyAdvancePaymentRentComponents {
  ADVANCE_PAYMENT_RENT_COMPONENT_UNDEFINED = "ADVANCE_PAYMENT_RENT_COMPONENT_UNDEFINED",
  ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY = "ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY",
  ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING = "ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING",
  ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER = "ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER",
  ADVANCE_PAYMENT_RENT_COMPONENT_HEATING = "ADVANCE_PAYMENT_RENT_COMPONENT_HEATING",
  ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE = "ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE",
  ADVANCE_PAYMENT_RENT_COMPONENT_TV = "ADVANCE_PAYMENT_RENT_COMPONENT_TV",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR",
  ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE = "ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE",
  ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS = "ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS",
  ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS = "ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS",
  ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE = "ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR = "ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR",
  ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION = "ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION",
  ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT = "ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT",
  ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION = "ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION",
}

export interface PropertyDistributionKey {
  activated: boolean;
  /** @format double */
  value: number;
}

export interface PropertyDistributionKeys {
  elevator: PropertyDistributionKey;
  locationCompensation: PropertyDistributionKey;
  m2: PropertyDistributionKey;
  m3: PropertyDistributionKey;
  tv: PropertyDistributionKey;
  valueRatio: PropertyDistributionKey;
}

/** @default "FLAT_RATE_RENT_COMPONENT_UNDEFINED" */
export enum PropertyFlatRateRentComponents {
  FLAT_RATE_RENT_COMPONENT_UNDEFINED = "FLAT_RATE_RENT_COMPONENT_UNDEFINED",
  FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING = "FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING",
  FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER = "FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER",
  FLAT_RATE_RENT_COMPONENT_HEATING = "FLAT_RATE_RENT_COMPONENT_HEATING",
  FLAT_RATE_RENT_COMPONENT_GARBAGE = "FLAT_RATE_RENT_COMPONENT_GARBAGE",
  FLAT_RATE_RENT_COMPONENT_ELEVATOR = "FLAT_RATE_RENT_COMPONENT_ELEVATOR",
  FLAT_RATE_RENT_COMPONENT_TV = "FLAT_RATE_RENT_COMPONENT_TV",
  FLAT_RATE_RENT_COMPONENT_ELECTRICITY = "FLAT_RATE_RENT_COMPONENT_ELECTRICITY",
  FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE = "FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE",
  FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE = "FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE",
  FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE = "FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE",
  FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE = "FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE",
  FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE = "FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE",
  FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS = "FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS",
  FLAT_RATE_RENT_COMPONENT_WATER = "FLAT_RATE_RENT_COMPONENT_WATER",
  FLAT_RATE_RENT_COMPONENT_MAINTENANCE = "FLAT_RATE_RENT_COMPONENT_MAINTENANCE",
  FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION = "FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION",
  FLAT_RATE_RENT_COMPONENT_DISH_WASHER = "FLAT_RATE_RENT_COMPONENT_DISH_WASHER",
  FLAT_RATE_RENT_COMPONENT_FREEZER = "FLAT_RATE_RENT_COMPONENT_FREEZER",
  FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT = "FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT",
  FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS = "FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS",
  FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND = "FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND",
  FLATRATERENTCOMPONENTINFAVOROFPROPERTY1 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_1",
  FLATRATERENTCOMPONENTINFAVOROFPROPERTY2 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_2",
  FLATRATERENTCOMPONENTINFAVOROFPROPERTY3 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_3",
  FLATRATERENTCOMPONENTINFAVOROFADDITIONALCOSTS1 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_1",
  FLATRATERENTCOMPONENTINFAVOROFADDITIONALCOSTS2 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_2",
  FLATRATERENTCOMPONENTINFAVOROFADDITIONALCOSTS3 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_3",
}

export interface Propertyv2AddBuildingResponse {
  property: Propertyv2Property;
}

export interface Propertyv2AddDocumentsByNumericIdResponse {
  property: Propertyv2Property;
}

export interface Propertyv2CountResponse {
  /** @format int32 */
  count: number;
}

/** @default "PROPERTY_DEVELOPMENT_UNDEFINED" */
export enum Propertyv2Development {
  PROPERTY_DEVELOPMENT_UNDEFINED = "PROPERTY_DEVELOPMENT_UNDEFINED",
  PROPERTY_DEVELOPMENT_FULL = "PROPERTY_DEVELOPMENT_FULL",
  PROPERTY_DEVELOPMENT_PARTIAL = "PROPERTY_DEVELOPMENT_PARTIAL",
  PROPERTY_DEVELOPMENT_UNDEVELOPED = "PROPERTY_DEVELOPMENT_UNDEVELOPED",
}

export type Propertyv2FinishOnboardingResponse = object;

export interface Propertyv2ListByBuildingIdsResponse {
  properties: Propertyv2Property[];
}

export interface Propertyv2ListFilterOptionsResponse {
  options: V2PropertyFilterOption[];
}

/** @default "OPTED_PORTION_KEY_UNDEFINED" */
export enum Propertyv2OptedPortionKey {
  OPTED_PORTION_KEY_UNDEFINED = "OPTED_PORTION_KEY_UNDEFINED",
  OPTEDPORTIONKEYM2 = "OPTED_PORTION_KEY_M2",
  OPTEDPORTIONKEYM3 = "OPTED_PORTION_KEY_M3",
  OPTED_PORTION_KEY_VALUE_RATIO = "OPTED_PORTION_KEY_VALUE_RATIO",
  OPTED_PORTION_KEY_TV = "OPTED_PORTION_KEY_TV",
  OPTED_PORTION_KEY_ELEVATOR = "OPTED_PORTION_KEY_ELEVATOR",
  OPTED_PORTION_KEY_LOCATION_COMPENSATION = "OPTED_PORTION_KEY_LOCATION_COMPENSATION",
  OPTED_PORTION_KEY_UNIT = "OPTED_PORTION_KEY_UNIT",
  OPTED_PORTION_KEY_NUMBER_OF_INHABITANTS = "OPTED_PORTION_KEY_NUMBER_OF_INHABITANTS",
  OPTED_PORTION_KEY_SHARE = "OPTED_PORTION_KEY_SHARE",
}

export interface Propertyv2Property {
  additionalRentComponents: V2AdditionalRentComponentEntry[];
  /** Only zip, city and state are required and set */
  address: CommonaddressAddress;
  advancePaymentRentComponents: V2AdvancePaymentRentComponentEntry[];
  bankInfo: V2BankInfo[];
  buildingIds: string[];
  constructionZone: string;
  development: Propertyv2Development;
  documentIds: string[];
  egrid: string;
  flatRateRentComponents: V2FlatRateRentComponentEntry[];
  id: string;
  imageMediaId: string;
  landRegisterNumber: string;
  name: string;
  note: string;
  /** @format int64 */
  numericId: string;
  onboardingFinished: boolean;
  opted: boolean;
  optedPortionKey: Propertyv2OptedPortionKey;
  /**
   * The account ID of the owner
   * @format int64
   */
  ownerId: string;
  /** @format float */
  plotArea: number;
  /** @format int64 */
  propertyValue: string;
  transferToAccounting: boolean;
  type: Propertyv2PropertyType;
}

/** @default "PROPERTY_TYPE_UNDEFINED" */
export enum Propertyv2PropertyType {
  PROPERTY_TYPE_UNDEFINED = "PROPERTY_TYPE_UNDEFINED",
  PROPERTY_TYPE_RENTAL = "PROPERTY_TYPE_RENTAL",
  PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP = "PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP",
  PROPERTY_TYPE_COOPERATIVE = "PROPERTY_TYPE_COOPERATIVE",
}

export interface Propertyv2TransferToAccountingRequest {
  ids: string[];
}

export type Propertyv2TransferToAccountingResponse = object;

/** @default "COMPOSITION_LINES_OR_COLUMNS_UNDEFINED" */
export enum RentalIndexCompositionLinesOrColumns {
  COMPOSITION_LINES_OR_COLUMNS_UNDEFINED = "COMPOSITION_LINES_OR_COLUMNS_UNDEFINED",
  COMPOSITION_LINES_OR_COLUMNS_VERTICAL = "COMPOSITION_LINES_OR_COLUMNS_VERTICAL",
  COMPOSITION_LINES_OR_COLUMNS_HORIZONTAL = "COMPOSITION_LINES_OR_COLUMNS_HORIZONTAL",
}

/** @default "COMPOSITION_TYPE_UNDEFINED" */
export enum RentalIndexCompositionType {
  COMPOSITION_TYPE_UNDEFINED = "COMPOSITION_TYPE_UNDEFINED",
  COMPOSITION_TYPE_SEPARATELY = "COMPOSITION_TYPE_SEPARATELY",
  COMPOSITION_TYPE_CONSOLIDATE = "COMPOSITION_TYPE_CONSOLIDATE",
}

export interface RentalIndexRenderPdfRequest {
  buildingBreakPage: boolean;
  buildingIds: string[];
  displayHeaderEveryPage: boolean;
  displayPropertySummary: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endDate: TypeDate;
  language: LanguagePreferredLanguage;
  sortingType: RentalIndexSortingType;
  /** If fixed date leave empty */
  startDate: TypeDate;
  withoutTenantsNames: boolean;
}

export interface RentalIndexRenderPdfResponse {
  /** @format byte */
  file: string;
}

export interface RentalIndexRentalIndexTemplate {
  compositionLinesOrColumns: RentalIndexCompositionLinesOrColumns;
  compositionType: RentalIndexCompositionType;
  fields: RentalIndexTemplateField[];
  id: string;
  language: LanguagePreferredLanguage;
  name: string;
  /** @format int64 */
  ownerId: string;
  sortingType: RentalIndexSortingType;
  tenancyType: RentalIndexTenancyType;
}

/** @default "SORTING_TYPE_UNDEFINED" */
export enum RentalIndexSortingType {
  SORTING_TYPE_UNDEFINED = "SORTING_TYPE_UNDEFINED",
  SORTING_TYPE_OBJECT_ID = "SORTING_TYPE_OBJECT_ID",
  SORTING_TYPE_OBJECT_NUMBER = "SORTING_TYPE_OBJECT_NUMBER",
  SORTING_TYPE_STANDARD = "SORTING_TYPE_STANDARD",
}

export interface RentalIndexTemplateField {
  name: string;
  /** @format int32 */
  order: number;
}

/** @default "TENANCY_TYPE_UNDEFINED" */
export enum RentalIndexTenancyType {
  TENANCY_TYPE_UNDEFINED = "TENANCY_TYPE_UNDEFINED",
  TENANCY_TYPE_ALL = "TENANCY_TYPE_ALL",
  TENANCY_TYPE_RENTED = "TENANCY_TYPE_RENTED",
  TENANCY_TYPE_VACANT = "TENANCY_TYPE_VACANT",
}

export interface RoomRoomCreation {
  dimensions: RoomRoomDimensions;
  documentIds: string[];
  imageMediaIds: string[];
  name: string;
  note: string;
  /** @format int64 */
  ownerId: string;
  referenceId: string;
}

export interface RoomRoomDimensions {
  /** @format float */
  area: number;
  /** @format float */
  height: number;
  /** @format float */
  length: number;
  /** @format float */
  volume: number;
  /** @format float */
  width: number;
}

export interface RoomRoomUpdate {
  dimensions: RoomRoomDimensions;
  documentIds: string[];
  id: string;
  imageMediaIds: string[];
  name: string;
  note: string;
}

export interface SettlementObjectListByReferenceIdResponse {
  settlementObjects: SettlementObjectSettlementObject[];
}

export interface SettlementObjectSettlementObject {
  activated: boolean;
  additionalCostsBillingType: ObjectsettlementObjectAdditionalCostsBillingType;
  billingContactId: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  billingEnd: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  billingStart: TypeDate;
  correspondenceContactId: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  debitUntil: TypeDate;
  description: string;
  id: string;
  /** @format int64 */
  numericContractId: string;
  /** @format int64 */
  numericId: string;
  opted: boolean;
  /** @format int64 */
  ownerId: string;
  ownerIsDebtor: boolean;
  referenceId: string;
  rentAdjustments: ObjectsettlementObjectRentAdjustment[];
}

/** @default "UNDEFINED_SCOPE" */
export enum ShareShareScope {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export interface TenancyCloseRequest {
  ids: string[];
}

export type TenancyCloseResponse = object;

/** @default "UNDEFINED_CONTRACT_TYPE" */
export enum TenancyContractType {
  UNDEFINED_CONTRACT_TYPE = "UNDEFINED_CONTRACT_TYPE",
  INDEFINITE = "INDEFINITE",
  LIMITED = "LIMITED",
  INDIVIDUAL = "INDIVIDUAL",
}

/** @default "COST_INCREASE_TYPE_UNDEFINED" */
export enum TenancyCostIncreaseType {
  COST_INCREASE_TYPE_UNDEFINED = "COST_INCREASE_TYPE_UNDEFINED",
  COST_INCREASE_TYPE_FLAT_RATE = "COST_INCREASE_TYPE_FLAT_RATE",
  COST_INCREASE_TYPE_INDEX = "COST_INCREASE_TYPE_INDEX",
}

export interface TenancyCountryIndex {
  /** @format int64 */
  baseDate: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  date: TypeDate;
  /** @format float */
  inflation: number;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  possibleDate: TypeDate;
  /** @format float */
  possibleInflation: number;
}

export interface TenancyDepositShare {
  /** @format float */
  amount: number;
  contactId: string;
}

/** @default "DEPOSIT_TYPE_UNDEFINED" */
export enum TenancyDepositType {
  DEPOSIT_TYPE_UNDEFINED = "DEPOSIT_TYPE_UNDEFINED",
  DEPOSIT_TYPE_NO_DEPOSIT = "DEPOSIT_TYPE_NO_DEPOSIT",
  DEPOSIT_TYPE_DEPOT = "DEPOSIT_TYPE_DEPOT",
  DEPOSIT_TYPE_INSURANCE_GUARANTEE = "DEPOSIT_TYPE_INSURANCE_GUARANTEE",
  DEPOSIT_TYPE_COOPERATIVE_SHARE = "DEPOSIT_TYPE_COOPERATIVE_SHARE",
  DEPOSIT_TYPE_CASH_PAYMENT = "DEPOSIT_TYPE_CASH_PAYMENT",
  DEPOSIT_TYPE_BANK_GUARANTEE = "DEPOSIT_TYPE_BANK_GUARANTEE",
  DEPOSIT_TYPE_COLLECTIVE_INSURANCE = "DEPOSIT_TYPE_COLLECTIVE_INSURANCE",
  DEPOSIT_TYPE_COMPULSORY_SHARE_CERTIFICATES = "DEPOSIT_TYPE_COMPULSORY_SHARE_CERTIFICATES",
}

export interface TenancyEarlyRightOfTerminationOption {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  exerciseUntil: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  until: TypeDate;
}

export interface TenancyHasActivatedTenancyEntry {
  hasActivatedTenancy: boolean;
  objectId: string;
}

export interface TenancyHasActivatedTenancyRequest {
  objectIds: string[];
}

export interface TenancyHasActivatedTenancyResponse {
  entries: TenancyHasActivatedTenancyEntry[];
}

export interface TenancyHasAdditionalRentComponentInUseResponse {
  inUse: boolean;
}

export interface TenancyHasAdvancePaymentRentComponentInUseResponse {
  inUse: boolean;
}

export interface TenancyHasFlatRateRentComponentInUseResponse {
  inUse: boolean;
}

export interface TenancyHasTenanciesWithContactIdsResponse {
  existing: boolean;
}

export interface TenancyIndexData {
  additionalCostsAdjustmentAllowed: boolean;
  belowInitialRentAllowed: boolean;
  /** @format float */
  contractPercentImputedAtIndex: number;
  /** @format float */
  maximumRate: number;
  /** @format int32 */
  referenceMonthForIndex: number;
  rentAdjustmentAdditionalBenefit: boolean;
  /** @format int32 */
  rentAdjustmentByMonths: number;
  /** @format int32 */
  rentAdjustmentByPoints: number;
  rentAdjustmentMonths: number[];
  rentAdjustmentNotification: TenancyRentAdjustmentNotification;
}

export interface TenancyListByDateAndObjectIdsRequest {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endDate: TypeDate;
  includeTenancies: boolean;
  includeVacancies: boolean;
  objectIds: string[];
  /** If fixed date leave empty */
  startDate: TypeDate;
}

export interface TenancyListByDateAndObjectIdsResponse {
  tenancies: ObjecttenancyTenancy[];
}

export interface TenancyListByGuarantorResponse {
  tenancies: ObjecttenancyTenancy[];
}

export interface TenancyListByTenantResponse {
  tenancies: ObjecttenancyTenancy[];
}

export interface TenancyReferenceInterestRate {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  date: TypeDate;
  /** @format float */
  rate: number;
  /** @format float */
  ratePossible: number;
}

export interface TenancyRenewalOption {
  automaticExtending: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  exerciseUntil: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  from: TypeDate;
  type: TenancyRenewalOptionType;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  until: TypeDate;
  used: boolean;
}

/** @default "RENEWAL_OPTION_TYPE_UNDEFINED" */
export enum TenancyRenewalOptionType {
  RENEWAL_OPTION_TYPE_UNDEFINED = "RENEWAL_OPTION_TYPE_UNDEFINED",
  RENEWAL_OPTION_TYPE_REAL = "RENEWAL_OPTION_TYPE_REAL",
  RENEWAL_OPTION_TYPE_UNREAL = "RENEWAL_OPTION_TYPE_UNREAL",
}

export interface TenancyRentAdjustmentNotification {
  unit: TenancyRentAdjustmentNotificationUnit;
  /** @format int32 */
  value: number;
}

/** @default "RENT_ADJUSTMENT_NOTIFICATION_DAYS" */
export enum TenancyRentAdjustmentNotificationUnit {
  RENT_ADJUSTMENT_NOTIFICATION_DAYS = "RENT_ADJUSTMENT_NOTIFICATION_DAYS",
  RENT_ADJUSTMENT_NOTIFICATION_MONTHS = "RENT_ADJUSTMENT_NOTIFICATION_MONTHS",
}

/** @default "RENT_TYPE_UNDEFINED" */
export enum TenancyRentType {
  RENT_TYPE_UNDEFINED = "RENT_TYPE_UNDEFINED",
  RENT_TYPE_VMWG = "RENT_TYPE_VMWG",
  RENT_TYPE_CONDOMINIUM_OWNERSHIP = "RENT_TYPE_CONDOMINIUM_OWNERSHIP",
  RENT_TYPE_INDEX = "RENT_TYPE_INDEX",
  RENT_TYPE_GRADUATED = "RENT_TYPE_GRADUATED",
  RENT_TYPE_TURNOVER = "RENT_TYPE_TURNOVER",
  RENT_TYPE_SUBSIDIZES = "RENT_TYPE_SUBSIDIZES",
  RENT_TYPE_NOT_VMWG = "RENT_TYPE_NOT_VMWG",
}

export interface TenancyRetentionOfRent {
  /** @format float */
  actual: number;
  inPercent: boolean;
  /** @format float */
  possible: number;
  remark: string;
  type: TenancyRetentionOfRentType;
}

/** @default "RETENTION_OF_RENT_INSUFFICIENT_GROSS_RENT" */
export enum TenancyRetentionOfRentType {
  RETENTION_OF_RENT_INSUFFICIENT_GROSS_RENT = "RETENTION_OF_RENT_INSUFFICIENT_GROSS_RENT",
  RETENTION_OF_RENT_INSUFFICIENT_NET_RETURN = "RETENTION_OF_RENT_INSUFFICIENT_NET_RETURN",
  RETENTION_OF_RENT_VALUE_ENHANCING_INVESTMENTS = "RETENTION_OF_RENT_VALUE_ENHANCING_INVESTMENTS",
  RETENTION_OF_RENT_ADJUSTMENT_NEIGHBORHOOD = "RETENTION_OF_RENT_ADJUSTMENT_NEIGHBORHOOD",
}

export interface TenancyRoommate {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  birthday: TypeDate;
  firstName: string;
  lastName: string;
}

export interface TenancySetTerminationResponse {
  tenancy: ObjecttenancyTenancy;
}

export interface TenancySubTenant {
  contactIds: string[];
  /**
   * Date when subtenancy ends
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endingAt: TypeDate;
  /**
   * Date when subtenancy starts
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startingAt: TypeDate;
}

export interface TenancySumRentValuesResponse {
  /**
   * Sum of cold rent values of real (non-vacancy) tenancies
   * @format float
   */
  realColdRent: number;
  /**
   * Sum of cold rent values of vacancy tenancies
   * @format float
   */
  vacancyColdRent: number;
}

/** @default "TACIT_CONTRACT_RENEWAL_UNDEFINED" */
export enum TenancyTacitContractRenewal {
  TACIT_CONTRACT_RENEWAL_UNDEFINED = "TACIT_CONTRACT_RENEWAL_UNDEFINED",
  TACITCONTRACTRENEWAL1YEAR = "TACIT_CONTRACT_RENEWAL_1_YEAR",
  TACITCONTRACTRENEWAL2YEAR = "TACIT_CONTRACT_RENEWAL_2_YEAR",
  TACITCONTRACTRENEWAL3YEAR = "TACIT_CONTRACT_RENEWAL_3_YEAR",
  TACITCONTRACTRENEWAL4YEAR = "TACIT_CONTRACT_RENEWAL_4_YEAR",
  TACITCONTRACTRENEWAL5YEAR = "TACIT_CONTRACT_RENEWAL_5_YEAR",
}

/** @default "TENANCY_CALCULATION_BASIS_DEFAULT" */
export enum TenancyTenancyCalculationBasis {
  TENANCY_CALCULATION_BASIS_DEFAULT = "TENANCY_CALCULATION_BASIS_DEFAULT",
  TENANCYCALCULATIONBASISM2YEAR = "TENANCY_CALCULATION_BASIS_M2_YEAR",
}

/** @default "UNDEFINED_TENANCY_PAYMENT_INTERVAL" */
export enum TenancyTenancyPaymentInterval {
  UNDEFINED_TENANCY_PAYMENT_INTERVAL = "UNDEFINED_TENANCY_PAYMENT_INTERVAL",
  TENANCY_PAYMENT_INTERVAL_MONTHLY = "TENANCY_PAYMENT_INTERVAL_MONTHLY",
  TENANCY_PAYMENT_INTERVAL_QUARTERLY = "TENANCY_PAYMENT_INTERVAL_QUARTERLY",
  TENANCY_PAYMENT_INTERVAL_HALF_YEARLY = "TENANCY_PAYMENT_INTERVAL_HALF_YEARLY",
  TENANCY_PAYMENT_INTERVAL_YEARLY = "TENANCY_PAYMENT_INTERVAL_YEARLY",
}

/** @default "TENANCY_STATUS_NOT_ACTIVATED" */
export enum TenancyTenancyStatus {
  TENANCY_STATUS_NOT_ACTIVATED = "TENANCY_STATUS_NOT_ACTIVATED",
  TENANCY_STATUS_CURRENT = "TENANCY_STATUS_CURRENT",
  TENANCY_STATUS_CURRENT_TERMINATED = "TENANCY_STATUS_CURRENT_TERMINATED",
  TENANCY_STATUS_TERMINATED = "TENANCY_STATUS_TERMINATED",
  TENANCY_STATUS_CLOSED = "TENANCY_STATUS_CLOSED",
  TENANCY_STATUS_FUTURE = "TENANCY_STATUS_FUTURE",
}

/** @default "UNDEFINED_TENANT_TYPE" */
export enum TenancyTenantType {
  UNDEFINED_TENANT_TYPE = "UNDEFINED_TENANT_TYPE",
  EXTERNAL_TENANT_TYPE = "EXTERNAL_TENANT_TYPE",
  OWN_TENANT_TYPE = "OWN_TENANT_TYPE",
  CORPORATE_TENANT_TYPE = "CORPORATE_TENANT_TYPE",
}

/** @default "TERMINATED_BY_UNDEFINED" */
export enum TenancyTerminatedBy {
  TERMINATED_BY_UNDEFINED = "TERMINATED_BY_UNDEFINED",
  TERMINATED_BY_LANDLORD = "TERMINATED_BY_LANDLORD",
  TERMINATED_BY_TENANT = "TERMINATED_BY_TENANT",
}

export interface TenancyTermination {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  earliestDate: TypeDate;
  /**
   * Notice period in months
   * @format int32
   */
  periodInMonths: number;
  /** On which months the contract can be terminated (1=January) */
  possibleMonths: number[];
  tacitContractRenewal: TenancyTacitContractRenewal;
}

/** @default "TERMINATION_REASON_UNDEFINED" */
export enum TenancyTerminationReason {
  TERMINATION_REASON_UNDEFINED = "TERMINATION_REASON_UNDEFINED",
  TERMINATION_REASON_IMPORTANT_REASONS = "TERMINATION_REASON_IMPORTANT_REASONS",
  TERMINATION_REASON_DEATH = "TERMINATION_REASON_DEATH",
  TERMINATION_REASON_TOO_SMALL = "TERMINATION_REASON_TOO_SMALL",
  TERMINATION_REASON_TOO_BIG = "TERMINATION_REASON_TOO_BIG",
  TERMINATION_REASON_TOO_EXPENSIVE = "TERMINATION_REASON_TOO_EXPENSIVE",
  TERMINATION_REASON_IMMISSIONS_TOO_HIGH = "TERMINATION_REASON_IMMISSIONS_TOO_HIGH",
  TERMINATION_REASON_FAMILY = "TERMINATION_REASON_FAMILY",
  TERMINATION_REASON_JOB = "TERMINATION_REASON_JOB",
  TERMINATION_REASON_VIOLATION_OF_DUE_DILIGENCE = "TERMINATION_REASON_VIOLATION_OF_DUE_DILIGENCE",
  TERMINATION_REASON_BANKRUPTCY_OF_TENANT = "TERMINATION_REASON_BANKRUPTCY_OF_TENANT",
  TERMINATION_REASON_OWN_NEED = "TERMINATION_REASON_OWN_NEED",
  TERMINATION_REASON_RENOVATION = "TERMINATION_REASON_RENOVATION",
  TERMINATION_REASON_TEMPORARY_CONTRACT = "TERMINATION_REASON_TEMPORARY_CONTRACT",
  TERMINATION_REASON_ARREARS = "TERMINATION_REASON_ARREARS",
  TERMINATION_REASON_ECONOMIC_REASONS = "TERMINATION_REASON_ECONOMIC_REASONS",
  TERMINATION_REASON_AREA_TOO_SMALL = "TERMINATION_REASON_AREA_TOO_SMALL",
  TERMINATION_REASON_AREA_TOO_LARGE = "TERMINATION_REASON_AREA_TOO_LARGE",
  TERMINATION_REASON_HOME_PURCHASE = "TERMINATION_REASON_HOME_PURCHASE",
  TERMINATION_REASON_SALE = "TERMINATION_REASON_SALE",
  TERMINATION_REASON_UNAUTHORISED_SUBLETTING = "TERMINATION_REASON_UNAUTHORISED_SUBLETTING",
  TERMINATION_REASON_PAYMENT_DEFAULT = "TERMINATION_REASON_PAYMENT_DEFAULT",
  TERMINATION_REASON_POSTSCRIPT = "TERMINATION_REASON_POSTSCRIPT",
}

/** @default "TERMINATION_TYPE_UNDEFINED" */
export enum TenancyTerminationType {
  TERMINATION_TYPE_UNDEFINED = "TERMINATION_TYPE_UNDEFINED",
  TERMINATION_TYPE_TERM = "TERMINATION_TYPE_TERM",
  TERMINATION_TYPE_OFF_TERM = "TERMINATION_TYPE_OFF_TERM",
  TERMINATION_TYPE_EXCEPTIONAL = "TERMINATION_TYPE_EXCEPTIONAL",
}

export interface TenancyUnjustifiedUse {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  from: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  until: TypeDate;
}

/** @default "VACANCY_REASON_UNDEFINED" */
export enum TenancyVacancyReason {
  VACANCY_REASON_UNDEFINED = "VACANCY_REASON_UNDEFINED",
  VACANCY_REASON_NO_NEXT_TENANT = "VACANCY_REASON_NO_NEXT_TENANT",
  VACANCY_REASON_INITIAL_LETTING = "VACANCY_REASON_INITIAL_LETTING",
  VACANCY_REASON_RENOVATION = "VACANCY_REASON_RENOVATION",
  VACANCY_REASON_TEMPORARY_TENANCY = "VACANCY_REASON_TEMPORARY_TENANCY",
  VACANCY_REASON_SALE = "VACANCY_REASON_SALE",
}

/**
 * - ADDITIONAL_RENT_COMPONENT_RENT: Added for COOZ-10074
 * @default "ADDITIONAL_RENT_COMPONENT_UNDEFINED"
 */
export enum V2AdditionalRentComponent {
  ADDITIONAL_RENT_COMPONENT_UNDEFINED = "ADDITIONAL_RENT_COMPONENT_UNDEFINED",
  ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION = "ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION",
  ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION = "ADDITIONAL_RENT_COMPONENT_ADDED_VALUE_E_CHARGING_STATION",
  ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT = "ADDITIONAL_RENT_COMPONENT_TENANT_IMPROVEMENT",
  ADDITIONAL_RENT_COMPONENT_AMORTIZATION = "ADDITIONAL_RENT_COMPONENT_AMORTIZATION",
  ADDITIONAL_RENT_COMPONENT_RENT = "ADDITIONAL_RENT_COMPONENT_RENT",
}

export interface V2AdditionalRentComponentEntry {
  description: string;
  inactive: boolean;
  rentComponent: V2AdditionalRentComponent;
}

/** @default "ADVANCE_PAYMENT_RENT_COMPONENT_UNDEFINED" */
export enum V2AdvancePaymentRentComponent {
  ADVANCE_PAYMENT_RENT_COMPONENT_UNDEFINED = "ADVANCE_PAYMENT_RENT_COMPONENT_UNDEFINED",
  ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY = "ADVANCE_PAYMENT_RENT_COMPONENT_GENERAL_ELECTRICITY",
  ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING = "ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING",
  ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE_ELECTRICITY_WATER_SEWAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER = "ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_HOT_WATER",
  ADVANCE_PAYMENT_RENT_COMPONENT_HEATING = "ADVANCE_PAYMENT_RENT_COMPONENT_HEATING",
  ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE = "ADVANCE_PAYMENT_RENT_COMPONENT_GARBAGE_MAINTENANCE",
  ADVANCE_PAYMENT_RENT_COMPONENT_TV = "ADVANCE_PAYMENT_RENT_COMPONENT_TV",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_MAINTENANCE_GARBAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_MAINTENANCE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_MAINTENANCE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE_ELEVATOR",
  ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE = "ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_MAINTENANCE",
  ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE = "ADVANCE_PAYMENT_RENT_COMPONENT_WATER_SEWAGE_GARBAGE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS = "ADVANCE_PAYMENT_RENT_COMPONENT_ADDITIONAL_COSTS",
  ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS = "ADVANCE_PAYMENT_RENT_COMPONENT_HEATING_ADDITIONAL_COSTS",
  ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE = "ADVANCE_PAYMENT_RENT_COMPONENT_MAINTENANCE",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER = "ADVANCE_PAYMENT_RENT_COMPONENT_ELECTRICITY_WATER",
  ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR = "ADVANCE_PAYMENT_RENT_COMPONENT_ELEVATOR",
  ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION = "ADVANCE_PAYMENT_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION",
  ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT = "ADVANCE_PAYMENT_RENT_COMPONENT_SEE_CONTRACT",
  ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION = "ADVANCE_PAYMENT_RENT_COMPONENT_E_CHARGING_STATION",
}

export interface V2AdvancePaymentRentComponentEntry {
  description: string;
  inactive: boolean;
  rentComponent: V2AdvancePaymentRentComponent;
}

export interface V2BankInfo {
  address: CommonaddressAddress;
  bankName: string;
  clearingNumber: string;
  documentIds: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  expiresAt: TypeDate;
  iban: string;
  inTheNameOf: string;
  note: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  validFrom: TypeDate;
}

export type V2DeleteRenderFileResponse = object;

export interface V2DeleteRenderFilesRequest {
  renderIds: string[];
}

export type V2DeleteRenderFilesResponse = object;

/** @default "FLAT_RATE_RENT_COMPONENT_UNDEFINED" */
export enum V2FlatRateRentComponent {
  FLAT_RATE_RENT_COMPONENT_UNDEFINED = "FLAT_RATE_RENT_COMPONENT_UNDEFINED",
  FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING = "FLAT_RATE_RENT_COMPONENT_MAINTENANCE_GARDEN_SURROUNDING",
  FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER = "FLAT_RATE_RENT_COMPONENT_HEATING_HOT_WATER",
  FLAT_RATE_RENT_COMPONENT_HEATING = "FLAT_RATE_RENT_COMPONENT_HEATING",
  FLAT_RATE_RENT_COMPONENT_GARBAGE = "FLAT_RATE_RENT_COMPONENT_GARBAGE",
  FLAT_RATE_RENT_COMPONENT_ELEVATOR = "FLAT_RATE_RENT_COMPONENT_ELEVATOR",
  FLAT_RATE_RENT_COMPONENT_TV = "FLAT_RATE_RENT_COMPONENT_TV",
  FLAT_RATE_RENT_COMPONENT_ELECTRICITY = "FLAT_RATE_RENT_COMPONENT_ELECTRICITY",
  FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE = "FLAT_RATE_RENT_COMPONENT_ELECTRICITY_GARBAGE",
  FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE = "FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE",
  FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE = "FLAT_RATE_RENT_COMPONENT_ELECTRICITY_WATER_SEWAGE_GARBAGE",
  FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE = "FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE",
  FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE = "FLAT_RATE_RENT_COMPONENT_WATER_SEWAGE_GARBAGE",
  FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS = "FLAT_RATE_RENT_COMPONENT_ADDITIONAL_COSTS",
  FLAT_RATE_RENT_COMPONENT_WATER = "FLAT_RATE_RENT_COMPONENT_WATER",
  FLAT_RATE_RENT_COMPONENT_MAINTENANCE = "FLAT_RATE_RENT_COMPONENT_MAINTENANCE",
  FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION = "FLAT_RATE_RENT_COMPONENT_SERVICE_MINERGIE_VENTILATION",
  FLAT_RATE_RENT_COMPONENT_DISH_WASHER = "FLAT_RATE_RENT_COMPONENT_DISH_WASHER",
  FLAT_RATE_RENT_COMPONENT_FREEZER = "FLAT_RATE_RENT_COMPONENT_FREEZER",
  FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT = "FLAT_RATE_RENT_COMPONENT_SEE_CONTRACT",
  FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS = "FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS",
  FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND = "FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND",
  FLATRATERENTCOMPONENTINFAVOROFPROPERTY1 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_1",
  FLATRATERENTCOMPONENTINFAVOROFPROPERTY2 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_2",
  FLATRATERENTCOMPONENTINFAVOROFPROPERTY3 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_3",
  FLATRATERENTCOMPONENTINFAVOROFADDITIONALCOSTS1 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_1",
  FLATRATERENTCOMPONENTINFAVOROFADDITIONALCOSTS2 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_2",
  FLATRATERENTCOMPONENTINFAVOROFADDITIONALCOSTS3 = "FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_3",
}

export interface V2FlatRateRentComponentEntry {
  description: string;
  inactive: boolean;
  rentComponent: V2FlatRateRentComponent;
}

export interface V2GetAllowedPropertyTypesResponse {
  types: Propertyv2PropertyType[];
}

export interface V2GetRenderFileResponse {
  /** @format byte */
  contract: string;
}

export interface V2GetRenderFilesRequest {
  renderIds: string[];
}

export interface V2GetRenderFilesResponse {
  /** @format byte */
  contract: string;
}

export interface V2GetRenderStatusResponse {
  errorMessage: string;
  failed: boolean;
  finished: boolean;
}

/** @default "EN" */
export enum V2Language {
  EN = "EN",
  DE = "DE",
  FR = "FR",
  IT = "IT",
}

export interface V2ListIdsResponse {
  numericIds: string[];
}

export interface V2ListRenderStatusRequest {
  renderIds: string[];
}

export interface V2ListRenderStatusResponse {
  status: V2RenderStatus[];
}

export interface V2PropertyFilterOption {
  buildingIds: string[];
  id: string;
  name: string;
  /** @format int64 */
  ownerId: string;
}

/** @default "RENDER_ORDER_DEFAULT" */
export enum V2RenderOrder {
  RENDER_ORDER_DEFAULT = "RENDER_ORDER_DEFAULT",
  RENDER_ORDER_NUMERIC_ID = "RENDER_ORDER_NUMERIC_ID",
}

export interface V2RenderStatus {
  errorMessage: string;
  failed: boolean;
  finished: boolean;
  renderId: string;
}

export interface V2RenderTenancyTemplateRequest {
  attachAutomatically: boolean;
  description: string;
  documentName: string;
  enforcePdf: boolean;
  id: string;
  sendingProfile: string;
  signatories: string[];
  tenancyId: string;
  title: string;
  useGuarantorAddress: boolean;
}

export interface V2RenderTenancyTemplateResponse {
  renderId: string;
}

export interface V2SellRequest {
  id: string;
  keepNetworkPartners: boolean;
  /** @format int64 */
  targetOwnerId: string;
}

export interface V2SellResponse {
  property: Propertyv2Property;
}

export type ReportingDeleteJdbcConnectionResponse = object;

export interface ReportingGetBuildingRentReportsRequest {
  buildingIds: string[];
}

export interface ReportingGetBuildingRentReportsResponse {
  reports: ReportingRentReport[];
}

export interface ReportingGetJdbcConnectionsResponse {
  connections: ReportingJdbcConnection[];
}

export interface ReportingGetPropertyRentReportsResponse {
  reports: ReportingRentReport[];
}

export interface ReportingGetReportingUrlsResponse {
  reportingUrls: ReportingReportingUrls;
}

export interface ReportingHasJdbcConnectionResponse {
  hasJdbcConnection: boolean;
}

export interface ReportingJdbcConnection {
  /** @format int64 */
  accountId: string;
  client: string;
  host: string;
  password: string;
  username: string;
}

export interface ReportingRentReport {
  /** @format float */
  grossActualRentPerYear: number;
  /** @format float */
  grossOverallRentPerYear: number;
  /** @format float */
  grossTargetRentPerYear: number;
  /** @format float */
  netActualRentPerYear: number;
  /** @format float */
  netOverallRentPerYear: number;
  /** @format float */
  netTargetRentPerYear: number;
  referenceId: string;
  targetRentIncomplete: boolean;
}

export interface ReportingReportingUrl {
  imageUrl: string;
  subTitle: string;
  title: string;
  url: string;
}

export interface ReportingReportingUrls {
  /** @format int64 */
  accountId: string;
  accounting: ReportingReportingUrl[];
  accountingOverview: ReportingReportingUrl[];
  adminOverview: ReportingReportingUrl[];
  administration: ReportingReportingUrl[];
  assetOverview: ReportingReportingUrl[];
  marketing: ReportingReportingUrl[];
  marketingOverview: ReportingReportingUrl[];
  overview: ReportingReportingUrl[];
  ownerOverview: ReportingReportingUrl[];
  publishHistory: ReportingReportingUrl[];
  tenantList: ReportingReportingUrl[];
}

export type ReportingSetJdbcConnectionResponse = object;

export type ReportingSetReportingUrlsResponse = object;

/** The tenancy doesn't have to anything with the tenancy of the object stack! */
export interface Applicationv2Tenancy {
  /** whether the current tenancy was canceled by the tenant (if not, it was cancelled by the landlord/owner) */
  cancelledByTenant: ApplicableApplicable;
  hasCurrentLandlord: boolean;
  landlordEmail: string;
  landlordName: string;
  landlordPersonName: string;
  landlordPhone: string;
  /** @format float */
  previousGrossRent: number;
  relationToMainIndividual: string;
  relocationReason: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  rentedSince: TypeDate;
  sameAsMainIndividual: boolean;
}

/** @default "UNDEFINED_CIVIL_STATUS" */
export enum Requestapplicationv2CivilStatus {
  UNDEFINED_CIVIL_STATUS = "UNDEFINED_CIVIL_STATUS",
  CIVIL_STATUS_SINGLE = "CIVIL_STATUS_SINGLE",
  CIVIL_STATUS_MARRIED = "CIVIL_STATUS_MARRIED",
  CIVIL_STATUS_DIVORCED = "CIVIL_STATUS_DIVORCED",
  CIVIL_STATUS_WIDOWED = "CIVIL_STATUS_WIDOWED",
  CIVIL_STATUS_SEPARATED = "CIVIL_STATUS_SEPARATED",
  CIVIL_STATUS_REGISTERED_PARTNERSHIP = "CIVIL_STATUS_REGISTERED_PARTNERSHIP",
  CIVIL_STATUS_DISSOLVED_PARTNERSHIP = "CIVIL_STATUS_DISSOLVED_PARTNERSHIP",
}

export interface Requestapplicationv2Company {
  address: CommonaddressAddress;
  contactPerson: V2CompanyContactPerson;
  debtEnforcementRegisterMediaIds: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  founded: TypeDate;
  idCardMediaIds: string[];
  insurance: V2InsuranceInformation;
  /** LegalInformation store's all necessary data in order to comply with any kind of law or are required by one. */
  legal: V2LegalInformation;
  /** DEPRECATED!. Use requestedParkingSpaces array. */
  licensePlate: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  liquidated: TypeDate;
  mediaIds: string[];
  name: string;
  /** @format int32 */
  numberOfEmployees: number;
  /**
   * DEPRECATED!. Use requestedParkingSpaces array.
   * @format int32
   */
  parkingSpacesRequested: number;
  preferredCommunicationChannel: V2PreferredCommunicationChannel;
  preferredDeposit: V2PreferredDeposit;
  preferredLanguage: LanguagePreferredLanguage;
  purpose: string;
  remark: string;
  requestedParkingSpaces: V2RequestedParkingSpace[];
  /** @format int32 */
  revenue: number;
  /** how the company plans to use the space to which it has applied to */
  spaceUtilization: string;
  tenancy: Applicationv2Tenancy;
  /** whether the company has an entry in the trade register */
  tradeRegisterEntry: ApplicableApplicable;
  tradeRegisterExcerptMediaIds: string[];
  /** whether the company is liable to value added tax (Mehrwertsteuerpflichtig) */
  vatLiable: ApplicableApplicable;
  website: string;
}

export interface Requestapplicationv2CreateRequest {
  company: Requestapplicationv2Company;
  /** Either individuals or company must be provided. Both are NOT allowed! */
  individuals: Requestapplicationv2Individual[];
}

export interface Requestapplicationv2CreateResponse {
  application: V2Application;
}

export type Requestapplicationv2DeleteResponse = object;

/** Employment stores the person's current employment information */
export interface Requestapplicationv2Employment {
  /** the person's company email */
  companyEmail: string;
  companyName: string;
  /** the person's position in the company */
  companyPosition: string;
  employmentDuration: V2EmploymentDuration;
  /** @format double */
  income: number;
  profession: string;
  /**
   * DEPRECATED!. Use employmentDuration enum.
   * @format int32
   */
  sinceYears: number;
  status: V2EmploymentStatus;
  /** the person's supervisor in the company */
  supervisor: string;
  /** email of the person's company supervisor in the company */
  supervisorEmail: string;
  /** phone number of the person's supervisor in the company */
  supervisorPhone: string;
  type: V2EmploymentType;
}

/** @default "UNDEFINED_GENDER" */
export enum Requestapplicationv2Gender {
  UNDEFINED_GENDER = "UNDEFINED_GENDER",
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface Requestapplicationv2GetLatestResponse {
  application: V2Application;
}

export interface Requestapplicationv2GetSettingsResponse {
  settings: Requestapplicationv2Settings;
}

export interface Requestapplicationv2Individual {
  address: CommonaddressAddress;
  /** @format int32 */
  children: number;
  civilStatus: Requestapplicationv2CivilStatus;
  creditTrustCertificateMediaIds: string[];
  debtEnforcementRegisterMediaIds: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dob: TypeDate;
  email: string;
  employment: Requestapplicationv2Employment;
  firstName: string;
  gender: Requestapplicationv2Gender;
  household: V2Household;
  idCardMediaIds: string[];
  insurance: V2InsuranceInformation;
  lastName: string;
  /** LegalInformation store's all necessary data in order to comply with any kind of law or are required by one. */
  legal: V2LegalInformation;
  mainIndividual: boolean;
  mediaIds: string[];
  mobileNumber: string;
  nationality: string;
  phoneNumber: string;
  pob: string;
  preferredCommunicationChannel: V2PreferredCommunicationChannel;
  preferredDeposit: V2PreferredDeposit;
  preferredLanguage: LanguagePreferredLanguage;
  remark: string;
  /**
   * residence status in switzerland
   * ResidenceStatus provides an enumeration for the different swiss residence status a person can have.
   *
   *  - RESIDENCE_STATUS_B_STAY: B - Aufenthaltsbewilligung
   *  - RESIDENCE_STATUS_C_SETTLE: C - Niederlassungsbewilligung
   *  - RESIDENCE_STATUS_CI_STAY_EMPLOYMENT: Ci - Aufenthaltsbewilligung mit Erwerbstätigkeit
   *  - RESIDENCE_STATUS_G_BORDER_CROSSER: G - Grenzgängerbewilligung
   *  - RESIDENCE_STATUS_L_SHORT_STAY: L -  Kurzaufenthaltsbewilligung
   *  - RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER: F - Vorläufig aufgenommene Ausländer
   *  - RESIDENCE_STATUS_N_SEEKING_ASYLUM: N - Asylsuchender
   *  - RESIDENCE_STATUS_S_PROTECTION_NEEDED: S - Schutzbedürftige
   */
  residenceStatus: Requestapplicationv2ResidenceStatus;
  /**
   * how long the residenceStatus value is valid
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  residenceStatusValidUntil: TypeDate;
  tenancy: Applicationv2Tenancy;
  wageStatementMediaIds: string[];
}

/**
 * ResidenceStatus provides an enumeration for the different swiss residence status a person can have.
 *
 *  - RESIDENCE_STATUS_B_STAY: B - Aufenthaltsbewilligung
 *  - RESIDENCE_STATUS_C_SETTLE: C - Niederlassungsbewilligung
 *  - RESIDENCE_STATUS_CI_STAY_EMPLOYMENT: Ci - Aufenthaltsbewilligung mit Erwerbstätigkeit
 *  - RESIDENCE_STATUS_G_BORDER_CROSSER: G - Grenzgängerbewilligung
 *  - RESIDENCE_STATUS_L_SHORT_STAY: L -  Kurzaufenthaltsbewilligung
 *  - RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER: F - Vorläufig aufgenommene Ausländer
 *  - RESIDENCE_STATUS_N_SEEKING_ASYLUM: N - Asylsuchender
 *  - RESIDENCE_STATUS_S_PROTECTION_NEEDED: S - Schutzbedürftige
 * @default "UNDEFINED_RESIDENCE_STATUS"
 */
export enum Requestapplicationv2ResidenceStatus {
  UNDEFINED_RESIDENCE_STATUS = "UNDEFINED_RESIDENCE_STATUS",
  RESIDENCE_STATUS_B_STAY = "RESIDENCE_STATUS_B_STAY",
  RESIDENCE_STATUS_C_SETTLE = "RESIDENCE_STATUS_C_SETTLE",
  RESIDENCE_STATUS_CI_STAY_EMPLOYMENT = "RESIDENCE_STATUS_CI_STAY_EMPLOYMENT",
  RESIDENCE_STATUS_G_BORDER_CROSSER = "RESIDENCE_STATUS_G_BORDER_CROSSER",
  RESIDENCE_STATUS_L_SHORT_STAY = "RESIDENCE_STATUS_L_SHORT_STAY",
  RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER = "RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER",
  RESIDENCE_STATUS_N_SEEKING_ASYLUM = "RESIDENCE_STATUS_N_SEEKING_ASYLUM",
  RESIDENCE_STATUS_S_PROTECTION_NEEDED = "RESIDENCE_STATUS_S_PROTECTION_NEEDED",
}

export interface Requestapplicationv2SetSettingsResponse {
  settings: Requestapplicationv2Settings;
}

export interface Requestapplicationv2Settings {
  /** @format int64 */
  accountId: string;
  /** @format float */
  contractCreationCost: number;
  showContractCreationCostAgreement: boolean;
}

export interface Requestrequestv2CreateRequest {
  checklist: V2Checklist;
  /** If receiver or requester read the last message */
  createdByReceiver: boolean;
  favorite: boolean;
  initialMessage: string;
  marketplaceName: string;
  objectId: string;
  /** @format int64 */
  ownerId: string;
  rejected: boolean;
  requesters: V2Requester[];
  sendDefaultAnswer: boolean;
  source: V2RequestSource;
  /** DO NOT USE! Deprecated. Use tenancyIds */
  tenancyId: string;
  tenancyIds: string[];
}

export interface Requestrequestv2CreateResponse {
  request: V2Request;
}

export type Requestrequestv2DeleteResponse = object;

export interface Requestrequestv2ListByIdsResponse {
  requests: V2Request[];
}

export interface Requestrequestv2MarkReadRequest {
  ids: string[];
  /** True=receiver read, false=requester read */
  readByReceiver: boolean;
}

export type Requestrequestv2MarkReadResponse = object;

export interface Requestrequestv2MarkUnreadRequest {
  ids: string[];
  /** True=receiver unread, false=requester unread */
  unreadByReceiver: boolean;
}

export type Requestrequestv2MarkUnreadResponse = object;

export interface Requestv2GetMarketingStatisticsResponse {
  /** @format int32 */
  employeeNewRequests: number;
  /** @format int32 */
  employeeUnreadRequests: number;
  /** @format int32 */
  overallNewRequests: number;
  /** @format int32 */
  overallUnreadRequests: number;
}

export interface Requestv2GetStatisticsResponse {
  statistics: V2RequestStatistics[];
}

export interface V2AddRequestersResponse {
  request: V2Request;
}

export interface V2Application {
  company: Requestapplicationv2Company;
  /** @format int64 */
  createdAt: string;
  creatorEmail: string;
  id: string;
  individuals: Requestapplicationv2Individual[];
  /** @format int64 */
  ownerId: string;
  /** @format int32 */
  version: number;
}

export interface V2Checklist {
  applicationFormReceived: boolean;
  applicationFormRequested: boolean;
  contractReceived: boolean;
  contractSent: boolean;
  creditCheckDone: boolean;
  referencesObtained: boolean;
  viewingAppointmentArranged: boolean;
  viewingAppointmentContactShared: boolean;
  viewingAppointmentDone: boolean;
}

export interface V2CompanyContactPerson {
  email: string;
  fax: string;
  firstName: string;
  gender: Requestapplicationv2Gender;
  lastName: string;
  mobileNumber: string;
  phoneNumber: string;
  position: string;
  status: V2EmploymentStatus;
}

export interface V2EmployerReference {
  contactPerson: string;
  duration: string;
  email: string;
  finalized: boolean;
  name: string;
  notes: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  obtainedOn: TypeDate;
  phone: string;
  probation: string;
  salary: string;
  temporalEmployment: string;
}

/** @default "UNDEFINED_EMPLOYMENT_DURATION" */
export enum V2EmploymentDuration {
  UNDEFINED_EMPLOYMENT_DURATION = "UNDEFINED_EMPLOYMENT_DURATION",
  EMPLOYMENTDURATIONLESSTHAN3MONTHS = "EMPLOYMENT_DURATION_LESS_THAN_3_MONTHS",
  EMPLOYMENTDURATIONMORETHAN3MONTHS = "EMPLOYMENT_DURATION_MORE_THAN_3_MONTHS",
  EMPLOYMENTDURATION1YEAR = "EMPLOYMENT_DURATION_1_YEAR",
  EMPLOYMENTDURATION2YEARS = "EMPLOYMENT_DURATION_2_YEARS",
  EMPLOYMENTDURATION3YEARS = "EMPLOYMENT_DURATION_3_YEARS",
  EMPLOYMENTDURATION4YEARS = "EMPLOYMENT_DURATION_4_YEARS",
  EMPLOYMENTDURATION5YEARS = "EMPLOYMENT_DURATION_5_YEARS",
  EMPLOYMENTDURATIONMORETHAN5YEARS = "EMPLOYMENT_DURATION_MORE_THAN_5_YEARS",
}

/** @default "UNDEFINED_EMPLOYMENT_STATUS" */
export enum V2EmploymentStatus {
  UNDEFINED_EMPLOYMENT_STATUS = "UNDEFINED_EMPLOYMENT_STATUS",
  EMPLOYMENT_STATUS_UNEMPLOYED = "EMPLOYMENT_STATUS_UNEMPLOYED",
  EMPLOYMENT_STATUS_EMPLOYEE = "EMPLOYMENT_STATUS_EMPLOYEE",
  EMPLOYMENT_STATUS_SELF_EMPLOYED = "EMPLOYMENT_STATUS_SELF_EMPLOYED",
  EMPLOYMENT_STATUS_APPRENTICE = "EMPLOYMENT_STATUS_APPRENTICE",
  EMPLOYMENT_STATUS_RETIRED = "EMPLOYMENT_STATUS_RETIRED",
}

/** @default "UNDEFINED_EMPLOYMENT_TYPE" */
export enum V2EmploymentType {
  UNDEFINED_EMPLOYMENT_TYPE = "UNDEFINED_EMPLOYMENT_TYPE",
  EMPLOYMENT_TYPE_TEMPORARY = "EMPLOYMENT_TYPE_TEMPORARY",
  EMPLOYMENT_TYPE_INDEFINITE = "EMPLOYMENT_TYPE_INDEFINITE",
  EMPLOYMENT_TYPE_LIMITED = "EMPLOYMENT_TYPE_LIMITED",
}

export interface V2GetByIdResponse {
  application: V2Application;
}

export interface V2Household {
  /** @format int32 */
  adults: number;
  /** @format int32 */
  children: number;
  instruments: ApplicableApplicable;
  instrumentsDescription: string;
  /** DEPRECATED!. Use requestedParkingSpaces array. */
  licensePlate: string;
  /**
   * amount of parking spaces requested by the household
   * DEPRECATED!. Use requestedParkingSpaces array.
   * @format int32
   */
  parkingSpacesRequested: number;
  pets: ApplicableApplicable;
  petsDescription: string;
  requestedParkingSpaces: V2RequestedParkingSpace[];
  /** are smokers living in the person's household? */
  smokers: ApplicableApplicable;
}

/** InsuranceInformation stores insurance information */
export interface V2InsuranceInformation {
  hasLiabilityInsurance: ApplicableApplicable;
}

export interface V2LandlordReference {
  complaints: string;
  contactPerson: string;
  costs: string;
  duration: string;
  email: string;
  finalized: boolean;
  name: string;
  notes: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  obtainedOn: TypeDate;
  phone: string;
  punctualPayment: string;
}

/** LegalInformation store's all necessary data in order to comply with any kind of law or are required by one. */
export interface V2LegalInformation {
  /** does the person have had a debt certificate issued in the last five years? */
  debtCertificateLastFiveYears: ApplicableApplicable;
  /** does the person have had a foreclosure in the last two years? */
  foreclosureLastTwoYears: ApplicableApplicable;
}

export interface V2ListByObjectIdAndRequesterEmailResponse {
  requests: V2Request[];
}

export interface V2ListByRequesterContactIdResponse {
  requests: V2Request[];
}

export interface V2ListReceivedByObjectIdsResponse {
  /** @format int32 */
  new: number;
  /** @format int32 */
  overall: number;
  requests: V2Request[];
  reserved: boolean;
  /** @format int32 */
  unread: number;
}

export interface V2ListSentResponse {
  requests: V2Request[];
}

export interface V2MarkRejectedRequest {
  ids: string[];
}

export type V2MarkRejectedResponse = object;

export interface V2MoveResponse {
  request: V2Request;
}

export interface V2PartiallySetChecklistRequest {
  applicationFormReceived: boolean;
  applicationFormRequested: boolean;
  contractReceived: boolean;
  contractSent: boolean;
  creditCheckDone: boolean;
  ids: string[];
  referencesObtained: boolean;
  viewingAppointmentArranged: boolean;
  viewingAppointmentContactShared: boolean;
  viewingAppointmentDone: boolean;
}

export interface V2PartiallySetChecklistResponse {
  requests: V2Request[];
}

export interface V2PreferredDeposit {
  bankDepotIds: string[];
  bankDepotNames: string[];
  bankGuaranteeIds: string[];
  bankGuaranteeNames: string[];
  collectiveInsuranceNames: string[];
  insuranceNames: string[];
  /** @format float */
  premiumPerYear: number;
  /** @format float */
  premiumThisYear: number;
  type: V2PreferredDepositType;
}

/** @default "PREFERRED_DEPOSIT_TYPE_UNDEFINED" */
export enum V2PreferredDepositType {
  PREFERRED_DEPOSIT_TYPE_UNDEFINED = "PREFERRED_DEPOSIT_TYPE_UNDEFINED",
  PREFERRED_DEPOSIT_TYPE_INSURANCE = "PREFERRED_DEPOSIT_TYPE_INSURANCE",
  PREFERRED_DEPOSIT_TYPE_COLLECTIVE_INSURANCE = "PREFERRED_DEPOSIT_TYPE_COLLECTIVE_INSURANCE",
  PREFERRED_DEPOSIT_TYPE_DEPOT = "PREFERRED_DEPOSIT_TYPE_DEPOT",
  PREFERRED_DEPOSIT_TYPE_BANK_GUARANTEE = "PREFERRED_DEPOSIT_TYPE_BANK_GUARANTEE",
  PREFERRED_DEPOSIT_TYPE_COOPERATIVE_SHARE = "PREFERRED_DEPOSIT_TYPE_COOPERATIVE_SHARE",
  PREFERRED_DEPOSIT_TYPE_CASH_PAYMENT = "PREFERRED_DEPOSIT_TYPE_CASH_PAYMENT",
}

export interface V2ReactivateRequest {
  ids: string[];
}

export type V2ReactivateResponse = object;

export interface V2RemoveRequestersResponse {
  request: V2Request;
}

export interface V2Request {
  applicationId: string;
  checklist: V2Checklist;
  favorite: boolean;
  id: string;
  initialMessage: string;
  /** @format int64 */
  lastModifiedAt: string;
  marketplaceName: string;
  /** If new request wasn't read by the receiver at least once */
  new: boolean;
  objectId: string;
  /**
   * Account ID of the owner of the object
   * @format int64
   */
  ownerId: string;
  readApplicationForm: boolean;
  /** If receiver read the last message */
  readByReceiver: boolean;
  /** If at least one requester read the last message */
  readByRequester: boolean;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  received: string;
  rejected: boolean;
  requesters: V2Requester[];
  source: V2RequestSource;
  /** DO NOT USE! Deprecated. Use tenancyIds */
  tenancyId: string;
  tenancyIds: string[];
}

/**
 * - PERSONAL_REQUEST_SOURCE: E.g. if requester came into office
 * @default "UNDEFINED_REQUEST_SOURCE"
 */
export enum V2RequestSource {
  UNDEFINED_REQUEST_SOURCE = "UNDEFINED_REQUEST_SOURCE",
  PHONE_REQUEST_SOURCE = "PHONE_REQUEST_SOURCE",
  MAIL_REQUEST_SOURCE = "MAIL_REQUEST_SOURCE",
  WEBSITE_REQUEST_SOURCE = "WEBSITE_REQUEST_SOURCE",
  MARKETPLACE_REQUEST_SOURCE = "MARKETPLACE_REQUEST_SOURCE",
  PERSONAL_REQUEST_SOURCE = "PERSONAL_REQUEST_SOURCE",
}

export interface V2RequestStatistics {
  /** @format int32 */
  applicationsReceived: number;
  /** @format int32 */
  appointmentsDone: number;
  objectId: string;
  /** @format int32 */
  overall: number;
  /** @format int32 */
  unread: number;
}

export interface V2RequestedParkingSpace {
  checked: boolean;
  licensePlate: string;
  type: ObjectParkingType;
}

export interface V2Requester {
  /**
   * Optional accountId of the requester if registered
   * @format int64
   */
  accountId: string;
  /** Always set to the contactId of a contact of the owner of the request */
  contactId: string;
  employerReference: V2EmployerReference;
  landlordReference: V2LandlordReference;
  /** Optional userId of the requester if registered */
  userId: string;
}

export type V2ReserveResponse = object;

export interface V2SendRequest {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  desiredStartOfContract: TypeDate;
  id: string;
  language: string;
  objectId: string;
  /** Optional. If not provided the email will be used to match. */
  requestId?: string;
  viewingAppointmentDone: boolean;
}

export type V2SendResponse = object;

export interface V2SetChecklistRequest {
  checklist: V2Checklist;
  ids: string[];
}

export type V2SetChecklistResponse = object;

export type V2SetEmployerReferenceFinalizedResponse = object;

export type V2SetEmployerReferenceResponse = object;

export interface V2SetFavoriteRequest {
  ids: string[];
}

export type V2SetFavoriteResponse = object;

export type V2SetLandlordReferenceFinalizedResponse = object;

export type V2SetLandlordReferenceResponse = object;

export interface V2SetNotFavoriteRequest {
  ids: string[];
}

export type V2SetNotFavoriteResponse = object;

export interface SearchGenerateApiKeyRequest {
  /** @format int64 */
  accountId: string;
}

export interface SearchGenerateApiKeyResponse {
  key: string;
}

export type SearchGenerateUnauthorizedApiKeyRequest = object;

export interface SearchGenerateUnauthorizedApiKeyResponse {
  key: string;
}

export interface SearchSearchIdsResponse {
  ids: string[];
}

/** @default "UNDEFINED_SEARCH_INDEX" */
export enum SearchSearchIndex {
  UNDEFINED_SEARCH_INDEX = "UNDEFINED_SEARCH_INDEX",
  CONTACT_SEARCH_INDEX = "CONTACT_SEARCH_INDEX",
}

export interface SearchUpdateOngoingRequest {
  entityIds: string[];
}

export interface SearchUpdateOngoingResponse {
  ongoing: boolean;
}

export interface SearchtableLoadSettingsResponse {
  tableSettings: TableTableSettings[];
}

export interface SearchtableSaveSettingsRequest {
  /** @format int64 */
  accountId: string;
  columns: string[];
  table: string;
}

export interface SearchtableSaveSettingsResponse {
  tableSettings: TableTableSettings;
}

export interface TableTableSettings {
  /** @format int64 */
  accountId: string;
  columns: string[];
  id: string;
  table: string;
}

export interface ShareAllowedAccountIdsEntry {
  allowedAccountIds: string[];
  entityId: string;
}

export interface ShareListAllowedAccountIdsRequest {
  entityIds: string[];
}

export interface ShareListAllowedAccountIdsResponse {
  result: ShareAllowedAccountIdsEntry[];
}

export interface ShareListByAccountIdsResponse {
  shares: ShareShare[];
}

export interface ShareListByResourceRequest {
  resourceIds: string[];
}

export interface ShareListByResourceResponse {
  shares: ShareShare[];
}

export interface ShareListBySubResourceIdsRequest {
  subResourceIds: string[];
}

export interface ShareListBySubResourceIdsResponse {
  shares: ShareShare[];
}

export interface ShareListByTargetAccountIdResponse {
  shares: ShareShare[];
}

export interface ShareListIdsByTargetAccountIdResponse {
  ids: string[];
}

export interface ShareListOwnersResponse {
  sourceAccountIds: string[];
}

export interface ShareListPartnersOfAssetResponse {
  partnerAccountIds: string[];
}

export interface ShareListPartnersResponse {
  targetAccountIds: string[];
}

export interface ShareListRootResourceIdsBySubResourceIdsResponse {
  rootResourceIds: string[];
}

export interface ShareListSubResourcesByResourceIdsRequest {
  resourceIds: string[];
  subResourceIdPrefixes: string[];
}

export interface ShareListSubResourcesByResourceIdsResponse {
  shares: ShareSubResourceShare[];
}

export interface ShareListSubResourcesByTargetAccountIdResponse {
  subResourceIds: string[];
}

export interface ShareListTargetAccountIdsBySubResourceIdsResponse {
  shares: ShareSubResourceShare[];
}

export interface ShareShare {
  resourceIds: string[];
  scope: ShareShareScope;
  /** @format int64 */
  sourceAccountId: string;
  /** @format int64 */
  targetAccountId: string;
}

export interface ShareSubResourceShare {
  /** @format int64 */
  sourceAccountId: string;
  subResourceId: string;
  targetAccountIds: string[];
}

export interface ShareUnsetRequest {
  resourceIds: string[];
  scopes: ShareShareScope[];
  /** @format int64 */
  sourceAccountId: string;
}

export type ShareUnsetResponse = object;

export interface ShareshareSetRequest {
  resourceIds: string[];
  scope: ShareShareScope;
  /** @format int64 */
  sourceAccountId: string;
  /** @format int64 */
  targetAccountId: string;
}

export interface ShareshareSetResponse {
  share: ShareShare;
}

export interface HistoryAssigneeChangedData {
  new: TicketAssignee;
  old: TicketAssignee;
}

export interface HistoryCommentDeletedData {
  comment: string;
}

export interface HistoryCommentEditedData {
  new: string;
  old: string;
}

export interface HistoryCommentNewData {
  comment: string;
  internal: boolean;
}

export interface HistoryConnectionsAddedData {
  references: TicketTicketReference[];
}

export interface HistoryConnectionsRemovedData {
  references: TicketTicketReference[];
}

export interface HistoryCorrespondencesChangedData {
  new: TicketCorrespondence[];
  old: TicketCorrespondence[];
}

export interface HistoryDescriptionChangedData {
  new: string;
  old: string;
}

export interface HistoryDueDateChanged {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  new: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  old: TypeDate;
}

export interface HistoryEditor {
  /** @format int64 */
  accountId: string;
  name: string;
  userId: string;
}

export interface HistoryExecutedFromChanged {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  new: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  old: TypeDate;
}

export interface HistoryFile {
  mediaId: string;
  name: string;
}

export interface HistoryFileAddedData {
  files: HistoryFile[];
}

export interface HistoryFileRemovedData {
  files: HistoryFile[];
}

/** @default "HISTORY_TYPE_TICKET_CREATED" */
export enum HistoryHistoryType {
  HISTORY_TYPE_TICKET_CREATED = "HISTORY_TYPE_TICKET_CREATED",
  HISTORY_TYPE_PRIORITY = "HISTORY_TYPE_PRIORITY",
  HISTORY_TYPE_ADDED_LINKS = "HISTORY_TYPE_ADDED_LINKS",
  HISTORY_TYPE_REMOVED_LINKS = "HISTORY_TYPE_REMOVED_LINKS",
  HISTORY_TYPE_TITLE = "HISTORY_TYPE_TITLE",
  HISTORY_TYPE_DESCRIPTION = "HISTORY_TYPE_DESCRIPTION",
  HISTORY_TYPE_ADDED_FILES = "HISTORY_TYPE_ADDED_FILES",
  HISTORY_TYPE_REMOVED_FILES = "HISTORY_TYPE_REMOVED_FILES",
  HISTORY_TYPE_ASSIGNEE = "HISTORY_TYPE_ASSIGNEE",
  HISTORY_TYPE_CORRESPONDENCES = "HISTORY_TYPE_CORRESPONDENCES",
  HISTORY_TYPE_DUE_DATE = "HISTORY_TYPE_DUE_DATE",
  HISTORY_TYPE_EXECUTED_FROM = "HISTORY_TYPE_EXECUTED_FROM",
  HISTORY_TYPE_STATUS = "HISTORY_TYPE_STATUS",
  HISTORY_TYPE_NEW_COMMENT = "HISTORY_TYPE_NEW_COMMENT",
  HISTORY_TYPE_EDITED_COMMENT = "HISTORY_TYPE_EDITED_COMMENT",
  HISTORY_TYPE_DELETED_COMMENT = "HISTORY_TYPE_DELETED_COMMENT",
  HISTORY_TYPE_ADDED_CONNECTIONS = "HISTORY_TYPE_ADDED_CONNECTIONS",
  HISTORY_TYPE_REMOVED_CONNECTIONS = "HISTORY_TYPE_REMOVED_CONNECTIONS",
  HISTORY_TYPE_TYPE = "HISTORY_TYPE_TYPE",
  HISTORY_TYPE_MAIL_LINKED = "HISTORY_TYPE_MAIL_LINKED",
  HISTORY_TYPE_CONTACT_PERSON_ON_SITE = "HISTORY_TYPE_CONTACT_PERSON_ON_SITE",
}

export interface HistoryLinksAddedData {
  links: TicketTicketLink[];
}

export interface HistoryLinksRemovedData {
  links: TicketTicketLink[];
}

export interface HistoryListChangesResponse {
  events: HistoryTicketChangedEvent[];
}

export interface HistoryMailLinkedData {
  subject: string;
  /** @format int64 */
  timestamp: string;
}

export interface HistoryOnSiteContactChangedData {
  new: TicketOnSiteContact;
  old: TicketOnSiteContact;
}

export interface HistoryPriorityChangedData {
  new: TicketTicketPriority;
  old: TicketTicketPriority;
}

export interface HistoryStatusChangedData {
  newStatus: TicketTicketStatus;
  oldStatus: TicketTicketStatus;
}

export interface HistoryTicketChangedEvent {
  assignee: HistoryAssigneeChangedData;
  commentDeleted: HistoryCommentDeletedData;
  commentEdited: HistoryCommentEditedData;
  commentNew: HistoryCommentNewData;
  connectionAdded: HistoryConnectionsAddedData;
  connectionRemoved: HistoryConnectionsRemovedData;
  correspondences: HistoryCorrespondencesChangedData;
  description: HistoryDescriptionChangedData;
  dueDate: HistoryDueDateChanged;
  editor: HistoryEditor;
  executeFrom: HistoryExecutedFromChanged;
  filesAdded: HistoryFileAddedData;
  filesRemoved: HistoryFileRemovedData;
  historyType: HistoryHistoryType;
  linksAdded: HistoryLinksAddedData;
  linksRemoved: HistoryLinksRemovedData;
  mailLinked: HistoryMailLinkedData;
  onSitePerson: HistoryOnSiteContactChangedData;
  priority: HistoryPriorityChangedData;
  status: HistoryStatusChangedData;
  /** @format int64 */
  timestamp: string;
  title: HistoryTitleChangedData;
  type: HistoryTypeChangedData;
}

export interface HistoryTitleChangedData {
  new: string;
  old: string;
}

export interface HistoryTypeChangedData {
  new: TicketTicketType;
  old: TicketTicketType;
}

export interface TicketAddWatcherResponse {
  ticket: TicketTicket;
}

export interface TicketAssignee {
  /** @format int64 */
  accountId: string;
  accountLogoUrl: string;
  accountName: string;
  address: CommonaddressAddress;
  email: string;
  name: string;
  phone: string;
  profilePictureUrl: string;
  userId: string;
}

export type TicketChangeStatusResponse = object;

export interface TicketCorrespondence {
  address: CommonaddressAddress;
  companyName: string;
  contactId: string;
  email: string;
  name: string;
  phone: string;
}

export interface TicketCountEntry {
  /** @format int32 */
  orders: number;
  referenceId: string;
  /** @format int32 */
  tasks: number;
}

export interface TicketCountMyUnreadTicketsResponse {
  /** @format int32 */
  amount: number;
}

export interface TicketCountTicketsRequest {
  referenceIds: string[];
}

export interface TicketCountTicketsResponse {
  entries: TicketCountEntry[];
}

export interface TicketEnableTicketingRequest {
  /** @format int64 */
  accountId: string;
  enable: boolean;
}

export type TicketEnableTicketingResponse = object;

export interface TicketExternalTicketWatcher {
  /** Optional */
  contactId?: string;
  email: string;
  includeCorrespondenceContact: boolean;
  /** Optional. Only set when contactId is set. */
  name?: string;
  notifyOnlyStatusChanges: boolean;
}

export interface TicketGetByKeyResponse {
  ticket: TicketTicket;
}

export interface TicketGetByPublicIdResponse {
  ticket: TicketTicket;
}

export interface TicketGetNotificationMailboxResponse {
  mailboxId: string;
}

export interface TicketGetTicketStatisticsResponse {
  closedTickets: TicketTicketPriorityGrouped;
  /** @format int32 */
  countOverall: number;
  doneTickets: TicketTicketPriorityGrouped;
  inProgressTickets: TicketTicketPriorityGrouped;
  openTickets: TicketTicketPriorityGrouped;
}

export interface TicketHasTicketingEnabledResponse {
  enabled: boolean;
}

export interface TicketIsContactInUseResponse {
  inUse: boolean;
}

export interface TicketListAllSchedulesResponse {
  schedules: TicketticketSchedule[];
}

export interface TicketListByQueryResponse {
  tickets: TicketTicket[];
}

export interface TicketListMyTicketsResponse {
  tickets: TicketTicket[];
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalTickets: number;
}

export interface TicketListSchedulesResponse {
  schedules: TicketticketSchedule[];
}

export interface TicketListTicketingEnabledRequest {
  accountIds: string[];
}

export interface TicketListTicketingEnabledResponse {
  enabledAccountIds: string[];
}

export interface TicketOnSiteContact {
  address: CommonaddressAddress;
  companyName: string;
  contactId: string;
  email: string;
  name: string;
  phone: string;
}

export interface TicketReadStatus {
  assignee: boolean;
}

export interface TicketRecurring {
  /** @format int32 */
  createAtAmount: number;
  createAtUnit: TicketScheduleUnit;
  /** @format int32 */
  repeatAmount: number;
  repeatUnit: TicketScheduleUnit;
}

export interface TicketReserveKeysRequest {
  /** @format int64 */
  accountId: string;
  /** @format int32 */
  amount: number;
}

export interface TicketReserveKeysResponse {
  keys: string[];
}

/** @default "SCHEDULE_UNIT_UNDEFINED" */
export enum TicketScheduleUnit {
  SCHEDULE_UNIT_UNDEFINED = "SCHEDULE_UNIT_UNDEFINED",
  SCHEDULE_UNIT_DAY = "SCHEDULE_UNIT_DAY",
  SCHEDULE_UNIT_MONTH = "SCHEDULE_UNIT_MONTH",
  SCHEDULE_UNIT_YEAR = "SCHEDULE_UNIT_YEAR",
}

export interface TicketSetAssignee {
  /** @format int64 */
  accountId: string;
  userId: string;
}

export interface TicketSetCorrespondence {
  contactId: string;
}

export type TicketSetNotificationMailboxResponse = object;

export interface TicketSetOnSiteContact {
  contactId: string;
  includeCorrespondenceContact: boolean;
  notify: boolean;
  notifyOnlyStatusChanges: boolean;
}

export interface TicketSetSchedulesRequest {
  schedules: TicketticketSchedule[];
}

export type TicketSetSchedulesResponse = object;

export interface TicketSetTicketWatcher {
  /** @format int64 */
  accountId: string;
  userId: string;
}

export interface TicketTicket {
  assignee: TicketAssignee;
  correspondences: TicketCorrespondence[];
  costs: TicketticketCosts;
  /** @format int64 */
  createdAt: string;
  creator: TicketticketCreator;
  description: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dueDate: TypeDate;
  externalTicketWatchers: TicketExternalTicketWatcher[];
  id: string;
  /** Human readable unique key */
  key: string;
  links: TicketTicketLink[];
  mediaIds: string[];
  /** DEPRECATED!. Use onSiteContacts array. */
  onSiteContact: TicketOnSiteContact;
  onSiteContacts: TicketOnSiteContact[];
  /** @format int64 */
  ownerId: string;
  priority: TicketTicketPriority;
  /** With this key a ticket can be read without any authz by non-registered members */
  publicId: string;
  readStatus: TicketReadStatus;
  recurring: TicketRecurring;
  references: TicketTicketReference[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startDate: TypeDate;
  status: TicketTicketStatus;
  title: string;
  type: TicketTicketType;
  /** @format int64 */
  updatedAt: string;
  watchers: TicketTicketWatcher[];
}

export interface TicketTicketLink {
  ticketId: string;
  type: TicketTicketLinkType;
}

/** @default "TICKET_LINK_TYPE_RELATES" */
export enum TicketTicketLinkType {
  TICKET_LINK_TYPE_RELATES = "TICKET_LINK_TYPE_RELATES",
  TICKET_LINK_TYPE_BLOCKS = "TICKET_LINK_TYPE_BLOCKS",
  TICKET_LINK_TYPE_IS_BLOCKED_BY = "TICKET_LINK_TYPE_IS_BLOCKED_BY",
}

/** @default "DEFAULT" */
export enum TicketTicketPriority {
  DEFAULT = "DEFAULT",
  LOW = "LOW",
  HIGH = "HIGH",
}

export interface TicketTicketPriorityGrouped {
  /** @format int32 */
  highPriority: number;
  /** @format int32 */
  lowPriority: number;
  /** @format int32 */
  normalPriority: number;
  /** @format int32 */
  overduePriority: number;
}

export interface TicketTicketReference {
  id: string;
  relatedIds: string[];
}

/** @default "TICKET_STATUS_OPEN" */
export enum TicketTicketStatus {
  TICKET_STATUS_OPEN = "TICKET_STATUS_OPEN",
  TICKET_STATUS_IN_PROGRESS = "TICKET_STATUS_IN_PROGRESS",
  TICKET_STATUS_DONE = "TICKET_STATUS_DONE",
  TICKET_STATUS_CLOSED = "TICKET_STATUS_CLOSED",
  TICKET_STATUS_DRAFT = "TICKET_STATUS_DRAFT",
}

/** @default "TICKET_TYPE_UNDEFINED" */
export enum TicketTicketType {
  TICKET_TYPE_UNDEFINED = "TICKET_TYPE_UNDEFINED",
  TICKET_TYPE_ORDER = "TICKET_TYPE_ORDER",
  TICKET_TYPE_OFFER = "TICKET_TYPE_OFFER",
  TICKET_TYPE_ACTIVITY = "TICKET_TYPE_ACTIVITY",
  TICKET_TYPE_TASK = "TICKET_TYPE_TASK",
}

export interface TicketTicketWatcher {
  /** @format int64 */
  accountId: string;
  accountName: string;
  name: string;
  profilePictureUrl: string;
  userId: string;
}

export interface TicketUpdateTicketOperation {
  assignee: TicketSetAssignee;
  correspondences: TicketSetCorrespondence[];
  costs: TicketticketCosts;
  description: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dueDate: TypeDate;
  externalTicketWatchers: TicketExternalTicketWatcher[];
  id: string;
  links: TicketTicketLink[];
  mediaIds: string[];
  /** DEPRECATED!. Use onSiteContacts array. */
  onSiteContact: TicketSetOnSiteContact;
  onSiteContacts: TicketSetOnSiteContact[];
  /** @format int64 */
  ownerId: string;
  priority: TicketTicketPriority;
  recurring: TicketRecurring;
  references: TicketTicketReference[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startDate: TypeDate;
  status: TicketTicketStatus;
  title: string;
  type: TicketTicketType;
  watchers: TicketSetTicketWatcher[];
}

export interface TicketticketCosts {
  costAbsorption: CostCostAbsorption;
  costShareUnit: CostCostShareUnit;
  /** @format double */
  costShareValue: number;
}

export interface TicketticketCreateRequest {
  assignee: TicketSetAssignee;
  correspondences: TicketSetCorrespondence[];
  costs: TicketticketCosts;
  description: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dueDate: TypeDate;
  externalTicketWatchers: TicketExternalTicketWatcher[];
  /** Optional */
  key?: string;
  links: TicketTicketLink[];
  mediaIds: string[];
  /** DEPRECATED!. Use onSiteContacts array. */
  onSiteContact: TicketSetOnSiteContact;
  onSiteContacts: TicketSetOnSiteContact[];
  /** @format int64 */
  ownerId: string;
  priority: TicketTicketPriority;
  recurring: TicketRecurring;
  references: TicketTicketReference[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startDate: TypeDate;
  status: TicketTicketStatus;
  title: string;
  type: TicketTicketType;
  watchers: TicketSetTicketWatcher[];
}

export interface TicketticketCreateResponse {
  ticket: TicketTicket;
}

export interface TicketticketCreator {
  /** @format int64 */
  accountId: string;
  accountLogoUrl: string;
  accountName: string;
  address: CommonaddressAddress;
  email: string;
  name: string;
  phone: string;
  profilePictureUrl: string;
  userId: string;
}

export type TicketticketDeleteResponse = object;

export interface TicketticketListByIdsRequest {
  ids: string[];
}

export interface TicketticketListByIdsResponse {
  tickets: TicketTicket[];
}

export interface TicketticketListByReferenceIdsRequest {
  referenceIds: string[];
}

export interface TicketticketListByReferenceIdsResponse {
  tickets: TicketTicket[];
}

export interface TicketticketMarkReadRequest {
  ids: string[];
}

export type TicketticketMarkReadResponse = object;

export interface TicketticketMarkUnreadRequest {
  ids: string[];
}

export type TicketticketMarkUnreadResponse = object;

export interface TicketticketSchedule {
  /** @format int64 */
  accountId: string;
  /** Optional. If objectAssignee=true then ignored. */
  assigneeUserId?: string;
  category: string;
  /** @format int32 */
  createAtAmount: number;
  createAtUnit: TicketScheduleUnit;
  /** @format int32 */
  dueDateAmount: number;
  dueDateUnit: TicketScheduleUnit;
  enabled: boolean;
  language: LanguagePreferredLanguage;
  /** Only valid for categories that are object related. */
  useObjectAssignee: boolean;
}

export interface TicketticketUpdateResponse {
  ticket: TicketTicket;
}

export interface AccountCompanyAbout {
  about: string;
  languages: AccountLanguages;
  show: boolean;
}

export interface AccountImportSettings {
  directPublication: boolean;
  importStatusMail: boolean;
}

export interface AccountLanguages {
  de: boolean;
  en: boolean;
  fr: boolean;
  it: boolean;
}

export interface Accountv2Account {
  accountingStates: AddressState[];
  assetStates: AddressState[];
  billing: V2Billing;
  company: Usercommonv2Company;
  contactPerson: Useraccountv2ContactPerson;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  createdAt: TypeDate;
  /** @format int64 */
  id: string;
  individual: Usercommonv2Individual;
  logoUrl: string;
  managementStates: AddressState[];
  marketingStates: AddressState[];
  subType: Commonv2SubType;
  type: Usercommonv2Type;
}

/** @default "UNDEFINED_STATE" */
export enum AddressState {
  UNDEFINED_STATE = "UNDEFINED_STATE",
  CH_ZH = "CH_ZH",
  CH_BE = "CH_BE",
  CH_LU = "CH_LU",
  CH_UR = "CH_UR",
  CH_SZ = "CH_SZ",
  CH_OW = "CH_OW",
  CH_NW = "CH_NW",
  CH_GL = "CH_GL",
  CH_ZG = "CH_ZG",
  CH_FR = "CH_FR",
  CH_SO = "CH_SO",
  CH_BS = "CH_BS",
  CH_BL = "CH_BL",
  CH_SH = "CH_SH",
  CH_AR = "CH_AR",
  CH_AI = "CH_AI",
  CH_SG = "CH_SG",
  CH_GR = "CH_GR",
  CH_AG = "CH_AG",
  CH_TG = "CH_TG",
  CH_TI = "CH_TI",
  CH_VD = "CH_VD",
  CH_VS = "CH_VS",
  CH_NE = "CH_NE",
  CH_GE = "CH_GE",
  CH_JU = "CH_JU",
}

/** @default "UNDEFINED" */
export enum Commonv2SubType {
  UNDEFINED = "UNDEFINED",
  TENANT = "TENANT",
  LANDLORD = "LANDLORD",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  MARKETING = "MARKETING",
  OWNER = "OWNER",
  LANDLORD_MARKETING = "LANDLORD_MARKETING",
  ACCOUNTING = "ACCOUNTING",
  LANDLORD_ACCOUNTING = "LANDLORD_ACCOUNTING",
  MARKETING_ACCOUNTING = "MARKETING_ACCOUNTING",
  LANDLORD_MARKETING_ACCOUNTING = "LANDLORD_MARKETING_ACCOUNTING",
  ASSET_MANAGEMENT = "ASSET_MANAGEMENT",
  ASSET_LANDLORD = "ASSET_LANDLORD",
  ASSET_MARKETING = "ASSET_MARKETING",
  ASSET_ACCOUNTING = "ASSET_ACCOUNTING",
  ASSET_LANDLORD_MARKETING = "ASSET_LANDLORD_MARKETING",
  ASSET_LANDLORD_ACCOUNTING = "ASSET_LANDLORD_ACCOUNTING",
  ASSET_MARKETING_ACCOUNTING = "ASSET_MARKETING_ACCOUNTING",
  ASSET_LANDLORD_MARKETING_ACCOUNTING = "ASSET_LANDLORD_MARKETING_ACCOUNTING",
}

export interface PartnerDocumentAddRequest {
  documentId: string;
  /** @format int64 */
  sourceAccountId: string;
  /** @format int64 */
  targetAccountId: string;
}

export interface PartnerDocumentAddResponse {
  partnerDocuments: PartnerDocumentPartnerDocuments;
}

export interface PartnerDocumentPartnerDocuments {
  documentIds: string[];
  /** @format int64 */
  sourceAccountId: string;
  /** @format int64 */
  targetAccountId: string;
}

export interface PartnerDocumentRemoveResponse {
  partnerDocuments: PartnerDocumentPartnerDocuments;
}

export interface PartnerDocumentpartnerDocumentGetResponse {
  partnerDocuments: PartnerDocumentPartnerDocuments;
}

export interface PortalAddLinkEntry {
  name: string;
  referenceId: string;
  url: string;
}

export interface PortalAddLinksRequest {
  links: PortalAddLinkEntry[];
}

export interface PortalAddLinksResponse {
  links: PortalLink[];
}

export interface PortalDocumentGroup {
  entries: PortalMediumEntry[];
  id: string;
  subTitle: string;
  title: string;
}

export interface PortalEnablePortalRequest {
  accountIds: string[];
  enable: boolean;
}

export type PortalEnablePortalResponse = object;

export interface PortalGetPortalDataResponse {
  data: PortalPortalData[];
}

export interface PortalHasPortalEnabledResponse {
  enabled: boolean;
}

export interface PortalLink {
  id: string;
  name: string;
  referenceId: string;
  url: string;
}

export interface PortalListDocumentGroupsResponse {
  groups: PortalDocumentGroup[];
}

export interface PortalListLinksByReferenceIdsRequest {
  referenceIds: string[];
}

export interface PortalListLinksByReferenceIdsResponse {
  links: PortalLink[];
}

export interface PortalMediumEntry {
  mediumId: string;
  mimeType: string;
  name: string;
  /** @format int32 */
  size: number;
  type: MediadocumentType;
  /**
   * UNIX timestamp in seconds when the medium was uploaded
   * @format int64
   */
  uploadedAt: string;
  url: string;
  /**
   * UNIX timestamp in seconds until which this URL is valid. Is set to 0 if no expiration.
   * @format int64
   */
  validUntil: string;
}

export interface PortalPortalData {
  entries: PortalMediumEntry[];
  id: string;
  links: PortalLink[];
  subTitle: string;
  title: string;
}

export type PortalRemoveLinksResponse = object;

export interface PortalUpdateLinksRequest {
  links: PortalLink[];
}

export type PortalUpdateLinksResponse = object;

/**
 * Represents a color in the RGBA color space. This representation is designed
 * for simplicity of conversion to/from color representations in various
 * languages over compactness. For example, the fields of this representation
 * can be trivially provided to the constructor of `java.awt.Color` in Java; it
 * can also be trivially provided to UIColor's `+colorWithRed:green:blue:alpha`
 * method in iOS; and, with just a little work, it can be easily formatted into
 * a CSS `rgba()` string in JavaScript.
 *
 * This reference page doesn't carry information about the absolute color
 * space
 * that should be used to interpret the RGB value (e.g. sRGB, Adobe RGB,
 * DCI-P3, BT.2020, etc.). By default, applications should assume the sRGB color
 * space.
 *
 * When color equality needs to be decided, implementations, unless
 * documented otherwise, treat two colors as equal if all their red,
 * green, blue, and alpha values each differ by at most 1e-5.
 *
 * Example (Java):
 *
 *      import com.google.type.Color;
 *
 *      // ...
 *      public static java.awt.Color fromProto(Color protocolor) {
 *        float alpha = protocolor.hasAlpha()
 *            ? protocolor.getAlpha().getValue()
 *            : 1.0;
 *
 *        return new java.awt.Color(
 *            protocolor.getRed(),
 *            protocolor.getGreen(),
 *            protocolor.getBlue(),
 *            alpha);
 *      }
 *
 *      public static Color toProto(java.awt.Color color) {
 *        float red = (float) color.getRed();
 *        float green = (float) color.getGreen();
 *        float blue = (float) color.getBlue();
 *        float denominator = 255.0;
 *        Color.Builder resultBuilder =
 *            Color
 *                .newBuilder()
 *                .setRed(red / denominator)
 *                .setGreen(green / denominator)
 *                .setBlue(blue / denominator);
 *        int alpha = color.getAlpha();
 *        if (alpha != 255) {
 *          result.setAlpha(
 *              FloatValue
 *                  .newBuilder()
 *                  .setValue(((float) alpha) / denominator)
 *                  .build());
 *        }
 *        return resultBuilder.build();
 *      }
 *      // ...
 *
 * Example (iOS / Obj-C):
 *
 *      // ...
 *      static UIColor* fromProto(Color* protocolor) {
 *         float red = [protocolor red];
 *         float green = [protocolor green];
 *         float blue = [protocolor blue];
 *         FloatValue* alpha_wrapper = [protocolor alpha];
 *         float alpha = 1.0;
 *         if (alpha_wrapper != nil) {
 *           alpha = [alpha_wrapper value];
 *         }
 *         return [UIColor colorWithRed:red green:green blue:blue alpha:alpha];
 *      }
 *
 *      static Color* toProto(UIColor* color) {
 *          CGFloat red, green, blue, alpha;
 *          if (![color getRed:&red green:&green blue:&blue alpha:&alpha]) {
 *            return nil;
 *          }
 *          Color* result = [[Color alloc] init];
 *          [result setRed:red];
 *          [result setGreen:green];
 *          [result setBlue:blue];
 *          if (alpha <= 0.9999) {
 *            [result setAlpha:floatWrapperWithValue(alpha)];
 *          }
 *          [result autorelease];
 *          return result;
 *     }
 *     // ...
 *
 *  Example (JavaScript):
 *
 *     // ...
 *
 *     var protoToCssColor = function(rgb_color) {
 *        var redFrac = rgb_color.red || 0.0;
 *        var greenFrac = rgb_color.green || 0.0;
 *        var blueFrac = rgb_color.blue || 0.0;
 *        var red = Math.floor(redFrac * 255);
 *        var green = Math.floor(greenFrac * 255);
 *        var blue = Math.floor(blueFrac * 255);
 *
 *        if (!('alpha' in rgb_color)) {
 *           return rgbToCssColor(red, green, blue);
 *        }
 *
 *        var alphaFrac = rgb_color.alpha.value || 0.0;
 *        var rgbParams = [red, green, blue].join(',');
 *        return ['rgba(', rgbParams, ',', alphaFrac, ')'].join('');
 *     };
 *
 *     var rgbToCssColor = function(red, green, blue) {
 *       var rgbNumber = new Number((red << 16) | (green << 8) | blue);
 *       var hexString = rgbNumber.toString(16);
 *       var missingZeros = 6 - hexString.length;
 *       var resultBuilder = ['#'];
 *       for (var i = 0; i < missingZeros; i++) {
 *          resultBuilder.push('0');
 *       }
 *       resultBuilder.push(hexString);
 *       return resultBuilder.join('');
 *     };
 *
 *     // ...
 */
export interface TypeColor {
  /**
   * The fraction of this color that should be applied to the pixel. That is,
   * the final pixel color is defined by the equation:
   * `pixel color = alpha * (this color) + (1.0 - alpha) * (background color)`
   *
   * This means that a value of 1.0 corresponds to a solid color, whereas
   * a value of 0.0 corresponds to a completely transparent color. This
   * uses a wrapper message rather than a simple float scalar so that it is
   * possible to distinguish between a default value and the value being unset.
   * If omitted, this color object is rendered as a solid color
   * (as if the alpha value had been explicitly given a value of 1.0).
   * @format float
   */
  alpha: number;
  /**
   * The amount of blue in the color as a value in the interval [0, 1].
   * @format float
   */
  blue: number;
  /**
   * The amount of green in the color as a value in the interval [0, 1].
   * @format float
   */
  green: number;
  /**
   * The amount of red in the color as a value in the interval [0, 1].
   * @format float
   */
  red: number;
}

export interface UseraccountAccount {
  company: UsercommonCompany;
  /**
   * the email is going to be associated with the user and company account
   * for the user it cannot be changed, but the company
   */
  email: string;
  /** the account owner's first_name */
  firstName: string;
  /**
   * Internal account ID to use in our system
   * @format int64
   */
  id: string;
  /** the account owner's last_name */
  lastName: string;
  phoneNumber: string;
  subType: UsercommonSubType;
  type: UsercommonType;
}

export interface UseraccountListResponse {
  accounts: UseraccountAccount[];
  /** @format int32 */
  pageCount: number;
  /** @format int32 */
  totalCount: number;
}

export interface Useraccountv2ContactPerson {
  email: string;
  name: string;
  phone: string;
  userId: string;
}

export interface Useraccountv2CreateRequest {
  /** Only set if type=company */
  company: Usercommonv2Company;
  /** Only set if type=individual */
  individual: Usercommonv2Individual;
  subType: Commonv2SubType;
  type: Usercommonv2Type;
}

export interface Useraccountv2CreateResponse {
  account: Accountv2Account;
}

export type Useraccountv2DeleteResponse = object;

export interface Useraccountv2GetResponse {
  account: Accountv2Account;
}

export interface Useraccountv2ListByIdsResponse {
  accounts: Accountv2Account[];
}

export interface Useraccountv2ListResponse {
  accounts: Accountv2Account[];
  /** @format int32 */
  pageCount: number;
  /** @format int32 */
  totalCount: number;
}

export interface Useraccountv2UpdateCompanyResponse {
  account: Accountv2Account;
}

export interface Useraccountv2UpdateContactPersonResponse {
  account: Accountv2Account;
}

export interface UsercommonAddress {
  city: string;
  country: string;
  street: string;
  zip: string;
}

export interface UsercommonCompany {
  address: UsercommonAddress;
  branch: string;
  email: string;
  /** @format int64 */
  foundedDate: string;
  name: string;
  phoneNumber: string;
  size: string;
  website: string;
}

/** @default "UNDEFINED" */
export enum UsercommonSubType {
  UNDEFINED = "UNDEFINED",
  TENANT = "TENANT",
  LANDLORD = "LANDLORD",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
}

/** @default "INDIVIDUAL" */
export enum UsercommonType {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
}

export interface Usercommonv2Company {
  aboutUs: string;
  address: CommonaddressAddress;
  email: string;
  name: string;
  phoneNumber: string;
  vatNumber: string;
  website: string;
}

/** @default "UNDEFINED_GENDER" */
export enum Usercommonv2Gender {
  UNDEFINED_GENDER = "UNDEFINED_GENDER",
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface Usercommonv2Individual {
  address: CommonaddressAddress;
  email: string;
  firstName: string;
  gender: Usercommonv2Gender;
  lastName: string;
  phoneNumber: string;
  website: string;
}

/** @default "INDIVIDUAL" */
export enum Usercommonv2Type {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
  PLATFORM_EMPLOYEE = "PLATFORM_EMPLOYEE",
}

export interface Usernotificationv2GetSettingsResponse {
  settings: Usernotificationv2Settings;
}

export interface Usernotificationv2SetSettingsRequest {
  channels: V2NotificationChannel[];
  ticketNotifications: V2TicketNotifications;
  userId: string;
}

export interface Usernotificationv2SetSettingsResponse {
  settings: Usernotificationv2Settings;
}

export interface Usernotificationv2Settings {
  channels: V2NotificationChannel[];
  ticketNotifications: V2TicketNotifications;
  userId: string;
}

export type UseruserDeleteUserResponse = object;

/** @default "UNDEFINED" */
export enum UseruserGender {
  UNDEFINED = "UNDEFINED",
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface UseruserListEmployeesResponse {
  employees: UseruserUser[];
}

export interface UseruserListResponse {
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  overallCount: number;
  /** @format int32 */
  page: number;
  /** @format int32 */
  pageCount: number;
  users: UseruserUser[];
}

export interface UseruserProfile {
  /** @format int64 */
  birthday: string;
  firstName: string;
  gender: UseruserGender;
  job: string;
  language: string;
  lastName: string;
  phoneNumber: string;
}

export interface UseruserUser {
  /**
   * Account to which this user has access to
   * @format int64
   */
  accountId: string;
  address: UsercommonAddress;
  deleted: boolean;
  email: string;
  /** Auth0 user ID */
  id: string;
  profile: UseruserProfile;
  roles: string[];
}

/** @default "UNDEFINED" */
export enum Useruserv2Gender {
  UNDEFINED = "UNDEFINED",
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface Useruserv2ListEmployeesResponse {
  employees: Userv2User[];
}

export interface Useruserv2ListResponse {
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  overallCount: number;
  /** @format int32 */
  page: number;
  /** @format int32 */
  pageCount: number;
  users: Userv2User[];
}

export type Userv2DeleteUserResponse = object;

export interface Userv2InviteEmployeeRequest {
  /**
   * The accountId to which the user should be invited
   * @format int64
   */
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  /** Optional as it can only be set if platform_employee type */
  password: string;
  roles: string[];
  sendPasswordReset: boolean;
}

export interface Userv2Profile {
  /**
   * DO NOT USE! Deprecated and ignored.
   * @format int64
   */
  birthday: string;
  /**
   * Represents a color in the RGBA color space. This representation is designed
   * for simplicity of conversion to/from color representations in various
   * languages over compactness. For example, the fields of this representation
   * can be trivially provided to the constructor of `java.awt.Color` in Java; it
   * can also be trivially provided to UIColor's `+colorWithRed:green:blue:alpha`
   * method in iOS; and, with just a little work, it can be easily formatted into
   * a CSS `rgba()` string in JavaScript.
   *
   * This reference page doesn't carry information about the absolute color
   * space
   * that should be used to interpret the RGB value (e.g. sRGB, Adobe RGB,
   * DCI-P3, BT.2020, etc.). By default, applications should assume the sRGB color
   * space.
   *
   * When color equality needs to be decided, implementations, unless
   * documented otherwise, treat two colors as equal if all their red,
   * green, blue, and alpha values each differ by at most 1e-5.
   *
   * Example (Java):
   *
   *      import com.google.type.Color;
   *
   *      // ...
   *      public static java.awt.Color fromProto(Color protocolor) {
   *        float alpha = protocolor.hasAlpha()
   *            ? protocolor.getAlpha().getValue()
   *            : 1.0;
   *
   *        return new java.awt.Color(
   *            protocolor.getRed(),
   *            protocolor.getGreen(),
   *            protocolor.getBlue(),
   *            alpha);
   *      }
   *
   *      public static Color toProto(java.awt.Color color) {
   *        float red = (float) color.getRed();
   *        float green = (float) color.getGreen();
   *        float blue = (float) color.getBlue();
   *        float denominator = 255.0;
   *        Color.Builder resultBuilder =
   *            Color
   *                .newBuilder()
   *                .setRed(red / denominator)
   *                .setGreen(green / denominator)
   *                .setBlue(blue / denominator);
   *        int alpha = color.getAlpha();
   *        if (alpha != 255) {
   *          result.setAlpha(
   *              FloatValue
   *                  .newBuilder()
   *                  .setValue(((float) alpha) / denominator)
   *                  .build());
   *        }
   *        return resultBuilder.build();
   *      }
   *      // ...
   *
   * Example (iOS / Obj-C):
   *
   *      // ...
   *      static UIColor* fromProto(Color* protocolor) {
   *         float red = [protocolor red];
   *         float green = [protocolor green];
   *         float blue = [protocolor blue];
   *         FloatValue* alpha_wrapper = [protocolor alpha];
   *         float alpha = 1.0;
   *         if (alpha_wrapper != nil) {
   *           alpha = [alpha_wrapper value];
   *         }
   *         return [UIColor colorWithRed:red green:green blue:blue alpha:alpha];
   *      }
   *
   *      static Color* toProto(UIColor* color) {
   *          CGFloat red, green, blue, alpha;
   *          if (![color getRed:&red green:&green blue:&blue alpha:&alpha]) {
   *            return nil;
   *          }
   *          Color* result = [[Color alloc] init];
   *          [result setRed:red];
   *          [result setGreen:green];
   *          [result setBlue:blue];
   *          if (alpha <= 0.9999) {
   *            [result setAlpha:floatWrapperWithValue(alpha)];
   *          }
   *          [result autorelease];
   *          return result;
   *     }
   *     // ...
   *
   *  Example (JavaScript):
   *
   *     // ...
   *
   *     var protoToCssColor = function(rgb_color) {
   *        var redFrac = rgb_color.red || 0.0;
   *        var greenFrac = rgb_color.green || 0.0;
   *        var blueFrac = rgb_color.blue || 0.0;
   *        var red = Math.floor(redFrac * 255);
   *        var green = Math.floor(greenFrac * 255);
   *        var blue = Math.floor(blueFrac * 255);
   *
   *        if (!('alpha' in rgb_color)) {
   *           return rgbToCssColor(red, green, blue);
   *        }
   *
   *        var alphaFrac = rgb_color.alpha.value || 0.0;
   *        var rgbParams = [red, green, blue].join(',');
   *        return ['rgba(', rgbParams, ',', alphaFrac, ')'].join('');
   *     };
   *
   *     var rgbToCssColor = function(red, green, blue) {
   *       var rgbNumber = new Number((red << 16) | (green << 8) | blue);
   *       var hexString = rgbNumber.toString(16);
   *       var missingZeros = 6 - hexString.length;
   *       var resultBuilder = ['#'];
   *       for (var i = 0; i < missingZeros; i++) {
   *          resultBuilder.push('0');
   *       }
   *       resultBuilder.push(hexString);
   *       return resultBuilder.join('');
   *     };
   *
   *     // ...
   */
  color: TypeColor;
  firstName: string;
  gender: Useruserv2Gender;
  job: string;
  language: string;
  lastName: string;
  phoneNumber: string;
  pictureUrl: string;
  signatureAuthorization: V2SignatureAuthorization;
  signatureMediaId: string;
}

export interface Userv2RegisterRequest {
  password: string;
  token: string;
}

export interface Userv2User {
  /**
   * Account to which this user has access to
   * @format int64
   */
  accountId: string;
  address: CommonaddressAddress;
  blocked: boolean;
  deleted: boolean;
  email: string;
  /** Auth0 user ID */
  id: string;
  profile: Userv2Profile;
  roles: string[];
}

export interface V2Billing {
  billable: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  billableSetDate: TypeDate;
  /** @format int64 */
  billingAddressAccountId: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  contractStart: TypeDate;
  lastModifiedBy: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  lastModifiedDate: TypeDate;
  note: string;
  pricingModel: V2PricingModel;
}

export type V2BlockResponse = object;

export type V2ChangeBillingResponse = object;

export type V2ChangeSubTypeResponse = object;

export interface V2GetFontSettingsResponse {
  family: string;
  size: string;
}

export interface V2GetTokenResponse {
  token: string;
}

export interface V2IsVerifiedResponse {
  emailVerified: boolean;
}

export interface V2ListAllEmployeesRequest {
  accountIds: string[];
}

export interface V2ListAllEmployeesResponse {
  employees: Userv2User[];
}

/** @default "NOTIFICATION_CHANNEL_EMAIL" */
export enum V2NotificationChannel {
  NOTIFICATION_CHANNEL_EMAIL = "NOTIFICATION_CHANNEL_EMAIL",
  NOTIFICATION_CHANNEL_PUSH = "NOTIFICATION_CHANNEL_PUSH",
}

/** @default "UNDEFINED_PRICING_MODEL" */
export enum V2PricingModel {
  UNDEFINED_PRICING_MODEL = "UNDEFINED_PRICING_MODEL",
  SAAS_PRICING_MODEL = "SAAS_PRICING_MODEL",
  LICENSE_PRICING_MODEL = "LICENSE_PRICING_MODEL",
  SAAS_AND_LICENSE_PRICING_MODEL = "SAAS_AND_LICENSE_PRICING_MODEL",
}

export interface V2PublishUpdateMessageRequest {
  message: string;
  messageEn: string;
  messageIt: string;
}

export type V2PublishUpdateMessageResponse = object;

export type V2RegisterResponse = object;

export interface V2ResolveTokenResponse {
  companyName: string;
  email: string;
  firstName: string;
  gender: Useruserv2Gender;
  lastName: string;
}

export type V2SetFontSettingsResponse = object;

export interface V2SetLogoResponse {
  account: Accountv2Account;
}

/** @default "SIGNATURE_AUTHORIZATION_UNDEFINED" */
export enum V2SignatureAuthorization {
  SIGNATURE_AUTHORIZATION_UNDEFINED = "SIGNATURE_AUTHORIZATION_UNDEFINED",
  SIGNATURE_AUTHORIZATION_SOLE = "SIGNATURE_AUTHORIZATION_SOLE",
  SIGNATURE_AUTHORIZATION_COLLECTIVELY_BY_TWO = "SIGNATURE_AUTHORIZATION_COLLECTIVELY_BY_TWO",
}

export interface V2TicketNotifications {
  assigned: boolean;
  comments: boolean;
  created: boolean;
  deleted: boolean;
  eventCreated: boolean;
  mailReceived: boolean;
  mailSent: boolean;
  statusChanged: boolean;
  updated: boolean;
}

export type V2UnblockResponse = object;

export interface V2UpdateIndividualResponse {
  account: Accountv2Account;
}

export interface V2UpdateStatesResponse {
  account: Accountv2Account;
}

export interface CertifactionServiceEnableDocumentSignatureParams {
  /** @format int64 */
  accountId?: string;
  enable?: boolean;
}

export type CertifactionServiceEnableDocumentSignatureData = CertifactionEnableDocumentSignatureResponse;

export type CertifactionServiceEnableDocumentSignatureError = GooglerpcStatus;

export interface CertifactionServiceHasDocumentSignatureEnabledParams {
  /** @format int64 */
  accountId?: string;
}

export type CertifactionServiceHasDocumentSignatureEnabledData = CommoncertifactionHasDocumentSignatureEnabledResponse;

export type CertifactionServiceHasDocumentSignatureEnabledError = GooglerpcStatus;

export interface CertifactionServiceListSignRequestsParams {
  /** @format int64 */
  accountId?: string;
}

export type CertifactionServiceListSignRequestsData = CommoncertifactionListSignRequestsResponse;

export type CertifactionServiceListSignRequestsError = GooglerpcStatus;

export interface CertifactionServiceSignDocumentParams {
  /** @format int64 */
  accountId?: string;
  name?: string;
  mediaIds?: string[];
  /** @default "PREFERRED_LANGUAGE_UNDEFINED" */
  language?: LanguageEnum;
  attachToReferenceIds?: string[];
}

/** @default "PREFERRED_LANGUAGE_UNDEFINED" */
export enum LanguageEnum {
  PREFERRED_LANGUAGE_UNDEFINED = "PREFERRED_LANGUAGE_UNDEFINED",
  PREFERRED_LANGUAGE_GERMAN = "PREFERRED_LANGUAGE_GERMAN",
  PREFERRED_LANGUAGE_ENGLISH = "PREFERRED_LANGUAGE_ENGLISH",
  PREFERRED_LANGUAGE_ITALIAN = "PREFERRED_LANGUAGE_ITALIAN",
  PREFERRED_LANGUAGE_FRENCH = "PREFERRED_LANGUAGE_FRENCH",
}

export type CertifactionServiceSignDocumentData = CommoncertifactionSignDocumentResponse;

export type CertifactionServiceSignDocumentError = GooglerpcStatus;

/** @default "PREFERRED_LANGUAGE_UNDEFINED" */
export enum CertifactionServiceSignDocumentParams1LanguageEnum {
  PREFERRED_LANGUAGE_UNDEFINED = "PREFERRED_LANGUAGE_UNDEFINED",
  PREFERRED_LANGUAGE_GERMAN = "PREFERRED_LANGUAGE_GERMAN",
  PREFERRED_LANGUAGE_ENGLISH = "PREFERRED_LANGUAGE_ENGLISH",
  PREFERRED_LANGUAGE_ITALIAN = "PREFERRED_LANGUAGE_ITALIAN",
  PREFERRED_LANGUAGE_FRENCH = "PREFERRED_LANGUAGE_FRENCH",
}

export type CertifactionServiceDownloadFileData = CommoncertifactionDownloadFileResponse;

export type CertifactionServiceDownloadFileError = GooglerpcStatus;

export interface CredittrustServiceGetCertificateParams {
  orderId?: string;
}

export type CredittrustServiceGetCertificateData = CredittrustGetCertificateResponse;

export type CredittrustServiceGetCertificateError = GooglerpcStatus;

export type CredittrustServiceOrderCertificateData = CredittrustOrderCertificateResponse;

export type CredittrustServiceOrderCertificateError = GooglerpcStatus;

export type CredittrustServiceVerifyCertificateData = CredittrustVerifyCertificateResponse;

export type CredittrustServiceVerifyCertificateError = GooglerpcStatus;

export type DeepsignServiceCountOpenSignRequestsData = DeepsignCountOpenSignRequestsResponse;

export type DeepsignServiceCountOpenSignRequestsError = GooglerpcStatus;

export interface DeepsignServiceGetPartnerAccessUrlParams {
  /** @format int64 */
  accountId?: string;
}

export type DeepsignServiceGetPartnerAccessUrlData = DeepsignGetPartnerAccessUrlResponse;

export type DeepsignServiceGetPartnerAccessUrlError = GooglerpcStatus;

export interface DeepsignServiceHasDocumentSignatureEnabledParams {
  /** @format int64 */
  accountId?: string;
}

export type DeepsignServiceHasDocumentSignatureEnabledData = CommondeepsignHasDocumentSignatureEnabledResponse;

export type DeepsignServiceHasDocumentSignatureEnabledError = GooglerpcStatus;

export interface DeepsignServiceListSignRequestsParams {
  /** @format int64 */
  accountId?: string;
}

export type DeepsignServiceListSignRequestsData = CommondeepsignListSignRequestsResponse;

export type DeepsignServiceListSignRequestsError = GooglerpcStatus;

export type DeepsignServiceListSignRequestsByAttachToReferenceIdsData =
  DeepsignListSignRequestsByAttachToReferenceIdsResponse;

export type DeepsignServiceListSignRequestsByAttachToReferenceIdsError = GooglerpcStatus;

export type DeepsignServiceSignDocumentData = CommondeepsignSignDocumentResponse;

export type DeepsignServiceSignDocumentError = GooglerpcStatus;

export interface DeepsignServiceCancelSignRequestParams {
  message?: string;
  id: string;
}

export type DeepsignServiceCancelSignRequestData = DeepsignCancelSignRequestResponse;

export type DeepsignServiceCancelSignRequestError = GooglerpcStatus;

export type DeepsignServiceDownloadFileData = CommondeepsignDownloadFileResponse;

export type DeepsignServiceDownloadFileError = GooglerpcStatus;

export type NoteServiceCreateData = CommonnoteCreateResponse;

export type NoteServiceCreateError = GooglerpcStatus;

export type NoteServiceCreateFromMailData = NoteCreateFromMailResponse;

export type NoteServiceCreateFromMailError = GooglerpcStatus;

export type NoteServiceCreateUnregisteredData = NoteCreateUnregisteredResponse;

export type NoteServiceCreateUnregisteredError = GooglerpcStatus;

export type NoteServiceDeleteData = CommonnoteDeleteResponse;

export type NoteServiceDeleteError = GooglerpcStatus;

export type NoteServiceListByReferenceIdsData = CommonnoteListByReferenceIdsResponse;

export type NoteServiceListByReferenceIdsError = GooglerpcStatus;

export interface NoteServiceUpdatePayload {
  mediaIds?: string[];
  mentions?: NoteMention[];
  note?: string;
}

export type NoteServiceUpdateData = CommonnoteUpdateResponse;

export type NoteServiceUpdateError = GooglerpcStatus;

export type ZipServiceGetInfoData = ZipGetInfoResponse;

export type ZipServiceGetInfoError = GooglerpcStatus;

export type ContactServiceActivateData = Contactv2ActivateResponse;

export type ContactServiceActivateError = GooglerpcStatus;

export type ActivityServiceCreateMixin7Data = Contactactivityv2CreateResponse;

export type ActivityServiceCreateMixin7Error = GooglerpcStatus;

export type ActivityServiceDeleteMixin7Data = Contactactivityv2DeleteResponse;

export type ActivityServiceDeleteMixin7Error = GooglerpcStatus;

export type ActivityServiceGetMixin7Data = Contactactivityv2GetResponse;

export type ActivityServiceGetMixin7Error = GooglerpcStatus;

export type ActivityServiceListByContactIdsData = Activityv2ListByContactIdsResponse;

export type ActivityServiceListByContactIdsError = GooglerpcStatus;

export type ActivityServiceListByReferenceIdsMixin7Data = Activityv2ListByReferenceIdsResponse;

export type ActivityServiceListByReferenceIdsMixin7Error = GooglerpcStatus;

export interface ActivityServiceUpdateMixin7Payload {
  contactIds?: string[];
  documentIds?: string[];
  employeeContactId?: string;
  referenceIds?: string[];
  subject?: string;
  text?: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  timestamp?: string;
}

export type ActivityServiceUpdateMixin7Data = Contactactivityv2UpdateResponse;

export type ActivityServiceUpdateMixin7Error = GooglerpcStatus;

export type ContactServiceCheckDuplicatesData = V2CheckDuplicatesResponse;

export type ContactServiceCheckDuplicatesError = GooglerpcStatus;

export type ContactServiceCopyContactsData = V2CopyContactsResponse;

export type ContactServiceCopyContactsError = GooglerpcStatus;

export type ContactServiceCreateCompanyData = V2CreateCompanyResponse;

export type ContactServiceCreateCompanyError = GooglerpcStatus;

export type ContactServiceCreatePersonData = V2CreatePersonResponse;

export type ContactServiceCreatePersonError = GooglerpcStatus;

export type ContactServiceDeactivateData = Contactv2DeactivateResponse;

export type ContactServiceDeactivateError = GooglerpcStatus;

export type ContactServiceDeleteData = Contactcontactv2DeleteResponse;

export type ContactServiceDeleteError = GooglerpcStatus;

export type ContactServiceGetOwnerContactData = V2GetOwnerContactResponse;

export type ContactServiceGetOwnerContactError = GooglerpcStatus;

export type ContactServiceGetUsedTagsData = Contactv2GetUsedTagsResponse;

export type ContactServiceGetUsedTagsError = GooglerpcStatus;

export type ContactServiceLinkCompaniesData = V2LinkCompaniesResponse;

export type ContactServiceLinkCompaniesError = GooglerpcStatus;

export type ContactServiceLinkPersonsData = V2LinkPersonsResponse;

export type ContactServiceLinkPersonsError = GooglerpcStatus;

export type ContactServiceListData = Contactcontactv2ListResponse;

export type ContactServiceListError = GooglerpcStatus;

export type ContactServiceListEmployeesData = Contactcontactv2ListEmployeesResponse;

export type ContactServiceListEmployeesError = GooglerpcStatus;

export type ContactServicePublishUpdatedEventsData = Contactv2PublishUpdatedEventsResponse;

export type ContactServicePublishUpdatedEventsError = GooglerpcStatus;

export type ContactServiceResolveData = Contactv2ResolveResponse;

export type ContactServiceResolveError = GooglerpcStatus;

export interface ContactServiceResolveByMailPayload {
  emails?: string[];
}

export type ContactServiceResolveByMailData = Contactv2ResolveByMailResponse;

export type ContactServiceResolveByMailError = GooglerpcStatus;

export type ContactServiceResolveEmployeesData = V2ResolveEmployeesResponse;

export type ContactServiceResolveEmployeesError = GooglerpcStatus;

export type ContactServiceSetAddressData = V2SetAddressResponse;

export type ContactServiceSetAddressError = GooglerpcStatus;

export type ContactServiceUnLinkCompaniesData = V2UnLinkCompaniesResponse;

export type ContactServiceUnLinkCompaniesError = GooglerpcStatus;

export type ContactServiceUnLinkPersonsData = V2UnLinkPersonsResponse;

export type ContactServiceUnLinkPersonsError = GooglerpcStatus;

export type ContactServiceUpdateAddressHistoryData = V2UpdateAddressHistoryResponse;

export type ContactServiceUpdateAddressHistoryError = GooglerpcStatus;

export interface ContactServiceAddContactPersonPayload {
  address?: CommonaddressAddress;
  birthName?: string;
  birthplace?: string;
  civilStatus?: Contactcontactv2CivilStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfBirth?: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfDeath?: TypeDate;
  email?: string;
  employment?: Contactcontactv2Employment;
  firstName?: string;
  gender?: Contactcontactv2Gender;
  includeInSerialEmails?: boolean;
  lastName?: string;
  /**
   * name is the full name of the person. It can be the only field set if no firstName+lastName separation is possible.
   * If 'firstName' and 'lastName' are set and 'name' is not, name is set to: firstName + ' ' + lastName
   */
  name?: string;
  nationality?: string;
  note?: string;
  /** @format int64 */
  numericId?: string;
  phoneNumbers?: Contactv2PhoneNumber[];
  portalAccess?: boolean;
  preferredLanguage?: LanguagePreferredLanguage;
  /**
   * ResidenceStatus provides an enumeration for the different swiss residence status a person can have.
   *
   *  - RESIDENCE_STATUS_B_STAY: B - Aufenthaltsbewilligung
   *  - RESIDENCE_STATUS_C_SETTLE: C - Niederlassungsbewilligung
   *  - RESIDENCE_STATUS_CI_STAY_EMPLOYMENT: Ci - Aufenthaltsbewilligung mit Erwerbstätigkeit
   *  - RESIDENCE_STATUS_G_BORDER_CROSSER: G - Grenzgängerbewilligung
   *  - RESIDENCE_STATUS_L_SHORT_STAY: L -  Kurzaufenthaltsbewilligung
   *  - RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER: F - Vorläufig aufgenommene Ausländer
   *  - RESIDENCE_STATUS_N_SEEKING_ASYLUM: N - Asylsuchender
   *  - RESIDENCE_STATUS_S_PROTECTION_NEEDED: S - Schutzbedürftige
   */
  residenceStatus?: Contactcontactv2ResidenceStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  residenceStatusValidUntil?: TypeDate;
  salutationName?: string;
  title?: string;
}

export type ContactServiceAddContactPersonData = V2AddContactPersonResponse;

export type ContactServiceAddContactPersonError = GooglerpcStatus;

export interface ContactServiceDeleteContactPersonsPayload {
  contactPersonIds?: string[];
}

export type ContactServiceDeleteContactPersonsData = V2DeleteContactPersonsResponse;

export type ContactServiceDeleteContactPersonsError = GooglerpcStatus;

export interface ContactServiceUpdateContactPersonPayload {
  address?: CommonaddressAddress;
  birthName?: string;
  birthplace?: string;
  civilStatus?: Contactcontactv2CivilStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfBirth?: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfDeath?: TypeDate;
  email?: string;
  employment?: Contactcontactv2Employment;
  firstName?: string;
  gender?: Contactcontactv2Gender;
  includeInSerialEmails?: boolean;
  lastName?: string;
  /**
   * name is the full name of the person. It can be the only field set if no firstName+lastName separation is possible.
   * If 'firstName' and 'lastName' are set and 'name' is not, name is set to: firstName + ' ' + lastName
   */
  name?: string;
  nationality?: string;
  note?: string;
  phoneNumbers?: Contactv2PhoneNumber[];
  portalAccess?: boolean;
  preferredLanguage?: LanguagePreferredLanguage;
  /**
   * ResidenceStatus provides an enumeration for the different swiss residence status a person can have.
   *
   *  - RESIDENCE_STATUS_B_STAY: B - Aufenthaltsbewilligung
   *  - RESIDENCE_STATUS_C_SETTLE: C - Niederlassungsbewilligung
   *  - RESIDENCE_STATUS_CI_STAY_EMPLOYMENT: Ci - Aufenthaltsbewilligung mit Erwerbstätigkeit
   *  - RESIDENCE_STATUS_G_BORDER_CROSSER: G - Grenzgängerbewilligung
   *  - RESIDENCE_STATUS_L_SHORT_STAY: L -  Kurzaufenthaltsbewilligung
   *  - RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER: F - Vorläufig aufgenommene Ausländer
   *  - RESIDENCE_STATUS_N_SEEKING_ASYLUM: N - Asylsuchender
   *  - RESIDENCE_STATUS_S_PROTECTION_NEEDED: S - Schutzbedürftige
   */
  residenceStatus?: Contactcontactv2ResidenceStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  residenceStatusValidUntil?: TypeDate;
  salutationName?: string;
  title?: string;
}

export type ContactServiceUpdateContactPersonData = Contactcontactv2UpdateContactPersonResponse;

export type ContactServiceUpdateContactPersonError = GooglerpcStatus;

export type ContactServiceAddDocumentsData = Contactv2AddDocumentsResponse;

export type ContactServiceAddDocumentsError = GooglerpcStatus;

export interface ContactServiceDeleteAddressPayload {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  validFrom?: TypeDate;
}

export type ContactServiceDeleteAddressData = V2DeleteAddressResponse;

export type ContactServiceDeleteAddressError = GooglerpcStatus;

export type ContactServiceRemoveDocumentsData = V2RemoveDocumentsResponse;

export type ContactServiceRemoveDocumentsError = GooglerpcStatus;

export interface ContactServiceUpdateCompanyPayload {
  address?: CommonaddressAddress;
  /** official contact email of the company, not the email of the responsible person (e.g. info@company.com) */
  email?: string;
  iban?: string;
  includeInSerialEmails?: boolean;
  industries?: IndustryIndustry[];
  name?: string;
  note?: string;
  /** @format int64 */
  ownerId?: string;
  phoneNumbers?: Contactv2PhoneNumber[];
  portalAccess?: boolean;
  preferredCommunicationChannel?: V2PreferredCommunicationChannel;
  preferredLanguage?: LanguagePreferredLanguage;
  salutationName?: string;
  tags?: string[];
  types?: Contactcontactv2Type[];
  vatNumber?: string;
  website?: string;
}

export type ContactServiceUpdateCompanyData = Contactcontactv2UpdateCompanyResponse;

export type ContactServiceUpdateCompanyError = GooglerpcStatus;

export interface ContactServiceUpdateGenericPayload {
  address?: CommonaddressAddress;
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  phoneNumbers?: Contactv2PhoneNumber[];
}

export type ContactServiceUpdateGenericData = V2UpdateGenericResponse;

export type ContactServiceUpdateGenericError = GooglerpcStatus;

export interface ContactServiceUpdatePersonPayload {
  address?: CommonaddressAddress;
  ahv?: string;
  birthName?: string;
  birthplace?: string;
  civilStatus?: Contactcontactv2CivilStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfBirth?: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  dateOfDeath?: TypeDate;
  email?: string;
  employment?: Contactcontactv2Employment;
  firstName?: string;
  gender?: Contactcontactv2Gender;
  iban?: string;
  industries?: IndustryIndustry[];
  lastName?: string;
  /**
   * name is the full name of the person. It can be the only field set if no firstName+lastName separation is possible.
   * If 'firstName' and 'lastName' are set and 'name' is not, name is set to: firstName + ' ' + lastName
   */
  name?: string;
  nationality?: string;
  note?: string;
  /** @format int64 */
  ownerId?: string;
  phoneNumbers?: Contactv2PhoneNumber[];
  preferredCommunicationChannel?: V2PreferredCommunicationChannel;
  preferredLanguage?: LanguagePreferredLanguage;
  /**
   * ResidenceStatus provides an enumeration for the different swiss residence status a person can have.
   *
   *  - RESIDENCE_STATUS_B_STAY: B - Aufenthaltsbewilligung
   *  - RESIDENCE_STATUS_C_SETTLE: C - Niederlassungsbewilligung
   *  - RESIDENCE_STATUS_CI_STAY_EMPLOYMENT: Ci - Aufenthaltsbewilligung mit Erwerbstätigkeit
   *  - RESIDENCE_STATUS_G_BORDER_CROSSER: G - Grenzgängerbewilligung
   *  - RESIDENCE_STATUS_L_SHORT_STAY: L -  Kurzaufenthaltsbewilligung
   *  - RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER: F - Vorläufig aufgenommene Ausländer
   *  - RESIDENCE_STATUS_N_SEEKING_ASYLUM: N - Asylsuchender
   *  - RESIDENCE_STATUS_S_PROTECTION_NEEDED: S - Schutzbedürftige
   */
  residenceStatus?: Contactcontactv2ResidenceStatus;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  residenceStatusValidUntil?: TypeDate;
  salutationName?: string;
  tags?: string[];
  title?: string;
  types?: Contactcontactv2Type[];
  website?: string;
  workEmail?: V2AdditionalEmail;
}

export type ContactServiceUpdatePersonData = V2UpdatePersonResponse;

export type ContactServiceUpdatePersonError = GooglerpcStatus;

export type ActivityServiceCreateData = Activityv3CreateResponse;

export type ActivityServiceCreateError = GooglerpcStatus;

export type ActivityServiceDeleteData = Activityv3DeleteResponse;

export type ActivityServiceDeleteError = GooglerpcStatus;

export type ActivityServiceGetData = Activityv3GetResponse;

export type ActivityServiceGetError = GooglerpcStatus;

export type ActivityServiceListByReferenceIdsData = Activityv3ListByReferenceIdsResponse;

export type ActivityServiceListByReferenceIdsError = GooglerpcStatus;

export interface ActivityServiceUpdatePayload {
  employeeContactIds?: string[];
  hasEndTime?: boolean;
  hasStartTime?: boolean;
  mediaIds?: string[];
  references?: V3ActivityReference[];
  source?: V3Source;
  subject?: string;
  text?: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  timestampEnd?: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  timestampStart?: string;
}

export type ActivityServiceUpdateData = Activityv3UpdateResponse;

export type ActivityServiceUpdateError = GooglerpcStatus;

export type CredentialServiceCreateData = CredentialsCreateCredentialsResponse;

export type CredentialServiceCreateError = GooglerpcStatus;

export type CredentialServiceFindDecryptedData = CredentialsFindDecryptedCredentialsResponse;

export type CredentialServiceFindDecryptedError = GooglerpcStatus;

export type CredentialServiceUpdateData = CredentialsUpdateCredentialsResponse;

export type CredentialServiceUpdateError = GooglerpcStatus;

export type ExportServiceCreateData = Exportv2CreateExportResponse;

export type ExportServiceCreateError = GooglerpcStatus;

export type ExportServiceDeleteData = Exportv2DeleteExportResponse;

export type ExportServiceDeleteError = GooglerpcStatus;

export type ExportServiceFindData = Exportv2FindExportResponse;

export type ExportServiceFindError = GooglerpcStatus;

export type ExportServiceListData = Exportv2ListExportsResponse;

export type ExportServiceListError = GooglerpcStatus;

export type ExportServiceListObjectPlatformsData = V2ListObjectPlatformsResponse;

export type ExportServiceListObjectPlatformsError = GooglerpcStatus;

export type ExportServiceListPresetsData = Exportv2ListPresetsResponse;

export type ExportServiceListPresetsError = GooglerpcStatus;

export type ExportServiceRemoveAllTargetsData = Exportv2RemoveAllTargetsResponse;

export type ExportServiceRemoveAllTargetsError = GooglerpcStatus;

export type HistoryServiceGetLatestData = Exporthistoryv2GetLatestResponse;

export type HistoryServiceGetLatestError = GooglerpcStatus;

export type HistoryServiceStatisticsData = Historyv2StatisticsResponse;

export type HistoryServiceStatisticsError = GooglerpcStatus;

export type ExportServiceSendExportUploadCommandData = Exportv2SendExportUploadCommandResponse;

export type ExportServiceSendExportUploadCommandError = GooglerpcStatus;

export type ExportServiceSetAgencyIdData = Exportv2SetAgencyIdResponse;

export type ExportServiceSetAgencyIdError = GooglerpcStatus;

export type ExportServiceSetPresetData = Exportv2SetPresetResponse;

export type ExportServiceSetPresetError = GooglerpcStatus;

export type ExportServiceStartData = Exportv2StartExportResponse;

export type ExportServiceStartError = GooglerpcStatus;

export type ExportServiceUpdateData = Exportv2UpdateExportResponse;

export type ExportServiceUpdateError = GooglerpcStatus;

export type ExportServiceUpdatePlatformsData = Exportv2UpdatePlatformsResponse;

export type ExportServiceUpdatePlatformsError = GooglerpcStatus;

export interface DocumentServiceGetParams {
  id?: string;
}

export type DocumentServiceGetData = MediadocumentDocument;

export type DocumentServiceGetError = GooglerpcStatus;

export type DocumentServiceSaveData = MediadocumentDocument;

export type DocumentServiceSaveError = GooglerpcStatus;

export type DocumentServiceListData = MediadocumentListResponse;

export type DocumentServiceListError = GooglerpcStatus;

export type DocumentServiceMergeAllDocumentsData = DocumentMergeAllDocumentsResponse;

export type DocumentServiceMergeAllDocumentsError = GooglerpcStatus;

export interface DocumentServiceUpdatePayload {
  name?: string;
  type?: MediadocumentType;
}

export type DocumentServiceUpdateData = MediadocumentUpdateResponse;

export type DocumentServiceUpdateError = GooglerpcStatus;

export interface DocumentServiceAddVersionPayload {
  mediaId?: string;
}

export type DocumentServiceAddVersionData = MediadocumentDocument;

export type DocumentServiceAddVersionError = GooglerpcStatus;

export type DocumentServiceRemoveVersionData = MediadocumentDocument;

export type DocumentServiceRemoveVersionError = GooglerpcStatus;

export type StoreServiceListData = StoreListMediaResponse;

export type StoreServiceListError = GooglerpcStatus;

export type PdfServiceGenerateFromHtmlData = PdfGenerateFromHtmlResponse;

export type PdfServiceGenerateFromHtmlError = GooglerpcStatus;

export type StoreServiceGetData = StoreGetMediaResponse;

export type StoreServiceGetError = GooglerpcStatus;

export type StoreServiceDeleteData = StoreDeleteMediaResponse;

export type StoreServiceDeleteError = GooglerpcStatus;

export type MailServiceActivateMailboxesData = V2ActivateMailboxesResponse;

export type MailServiceActivateMailboxesError = GooglerpcStatus;

export type MailServiceAddConfidentialEmailsData = V2AddConfidentialEmailsResponse;

export type MailServiceAddConfidentialEmailsError = GooglerpcStatus;

export type MailServiceArchiveData = V2ArchiveResponse;

export type MailServiceArchiveError = GooglerpcStatus;

export type MailServiceBulkSendMailData = V2BulkSendMailResponse;

export type MailServiceBulkSendMailError = GooglerpcStatus;

export type MailServiceCountDraftsData = V2CountDraftsResponse;

export type MailServiceCountDraftsError = GooglerpcStatus;

export type MailServiceCountUnreadMailsData = V2CountUnreadMailsResponse;

export type MailServiceCountUnreadMailsError = GooglerpcStatus;

export type MailServiceCreateDomainData = V2CreateDomainResponse;

export type MailServiceCreateDomainError = GooglerpcStatus;

export type MailServiceCreateMailboxData = V2CreateMailboxResponse;

export type MailServiceCreateMailboxError = GooglerpcStatus;

export type MailServiceDeactivateMailboxesData = V2DeactivateMailboxesResponse;

export type MailServiceDeactivateMailboxesError = GooglerpcStatus;

export type MailServiceDeleteDomainData = V2DeleteDomainResponse;

export type MailServiceDeleteDomainError = GooglerpcStatus;

export type MailServiceDeleteDraftsData = V2DeleteDraftsResponse;

export type MailServiceDeleteDraftsError = GooglerpcStatus;

export type MailServiceDeleteMailsData = V2DeleteMailsResponse;

export type MailServiceDeleteMailsError = GooglerpcStatus;

export interface MailServiceEditMailboxPayload {
  allowedUserIds?: string[];
  /** Can be empty if no specific user is using this mailbox */
  mainUserId?: string;
  name?: string;
  outboundEmail?: string;
  signatureId?: string;
}

export type MailServiceEditMailboxData = V2EditMailboxResponse;

export type MailServiceEditMailboxError = GooglerpcStatus;

export type MailServiceForwardData = V2ForwardResponse;

export type MailServiceForwardError = GooglerpcStatus;

export type MailServiceGetDeliveryAndEngagementDataData = V2GetDeliveryAndEngagementDataResponse;

export type MailServiceGetDeliveryAndEngagementDataError = GooglerpcStatus;

export type MailServiceGetMailboxData = V2GetMailboxResponse;

export type MailServiceGetMailboxError = GooglerpcStatus;

export interface MailServiceHasDomainDedicatedIpParams {
  fromEmail?: string;
}

export type MailServiceHasDomainDedicatedIpData = V2HasDomainDedicatedIpResponse;

export type MailServiceHasDomainDedicatedIpError = GooglerpcStatus;

export type MailServiceListConfidentialEmailsData = V2ListConfidentialEmailsResponse;

export type MailServiceListConfidentialEmailsError = GooglerpcStatus;

export type MailServiceListDomainsData = V2ListDomainsResponse;

export type MailServiceListDomainsError = GooglerpcStatus;

export type MailServiceListDraftsData = V2ListDraftsResponse;

export type MailServiceListDraftsError = GooglerpcStatus;

export type MailServiceListLatestMailsForReferenceIdsData = V2ListLatestMailsForReferenceIdsResponse;

export type MailServiceListLatestMailsForReferenceIdsError = GooglerpcStatus;

export type MailServiceListMailIdsData = V2ListMailIdsResponse;

export type MailServiceListMailIdsError = GooglerpcStatus;

export type MailServiceListMailboxesByAccountIdData = V2ListMailboxesByAccountIdResponse;

export type MailServiceListMailboxesByAccountIdError = GooglerpcStatus;

export type MailServiceListMailboxesByUserIdData = V2ListMailboxesByUserIdResponse;

export type MailServiceListMailboxesByUserIdError = GooglerpcStatus;

export type MailServiceListMailsData = V2ListMailsResponse;

export type MailServiceListMailsError = GooglerpcStatus;

export type MailServiceListMailsByIdsData = V2ListMailsByIdsResponse;

export type MailServiceListMailsByIdsError = GooglerpcStatus;

export type MailServiceListMailsByReferenceIdsData = V2ListMailsByReferenceIdsResponse;

export type MailServiceListMailsByReferenceIdsError = GooglerpcStatus;

export type MailServiceMarkAsNoSpamData = V2MarkAsNoSpamResponse;

export type MailServiceMarkAsNoSpamError = GooglerpcStatus;

export type MailServiceMarkAsSpamData = V2MarkAsSpamResponse;

export type MailServiceMarkAsSpamError = GooglerpcStatus;

export type MailServiceMarkReadData = Messagemailv2MarkReadResponse;

export type MailServiceMarkReadError = GooglerpcStatus;

export type MailServiceMarkUnreadData = Messagemailv2MarkUnreadResponse;

export type MailServiceMarkUnreadError = GooglerpcStatus;

export type MailServiceMoveToTrashData = V2MoveToTrashResponse;

export type MailServiceMoveToTrashError = GooglerpcStatus;

export type MailServiceRemoveConfidentialEmailsData = V2RemoveConfidentialEmailsResponse;

export type MailServiceRemoveConfidentialEmailsError = GooglerpcStatus;

export type MailServiceUnarchiveData = V2UnarchiveResponse;

export type MailServiceUnarchiveError = GooglerpcStatus;

export type MailServiceRestoreFromTrashData = V2RestoreFromTrashResponse;

export type MailServiceRestoreFromTrashError = GooglerpcStatus;

export type MailServiceSaveDraftData = V2SaveDraftResponse;

export type MailServiceSaveDraftError = GooglerpcStatus;

export type MailServiceSendMailData = V2SendMailResponse;

export type MailServiceSendMailError = GooglerpcStatus;

export type MailServiceSetAutomaticReplyData = V2SetAutomaticReplyResponse;

export type MailServiceSetAutomaticReplyError = GooglerpcStatus;

export type MailServiceSetConfidentialData = V2SetConfidentialResponse;

export type MailServiceSetConfidentialError = GooglerpcStatus;

export type MailServiceSetMailboxesConfidentialData = V2SetMailboxesConfidentialResponse;

export type MailServiceSetMailboxesConfidentialError = GooglerpcStatus;

export interface MailServiceSetReferenceIdsPayload {
  referenceIds?: string[];
}

export type MailServiceSetReferenceIdsData = V2SetReferenceIdsResponse;

export type MailServiceSetReferenceIdsError = GooglerpcStatus;

export type MailServiceValidateDomainData = V2ValidateDomainResponse;

export type MailServiceValidateDomainError = GooglerpcStatus;

export interface SignatureServiceListByAccountIdParams {
  /** @format int64 */
  accountId?: string;
}

export type SignatureServiceListByAccountIdData = V2ListByAccountIdResponse;

export type SignatureServiceListByAccountIdError = GooglerpcStatus;

export type SignatureServiceRenderByIdData = V2RenderByIdResponse;

export type SignatureServiceRenderByIdError = GooglerpcStatus;

export type SignatureServiceSetSignatureData = V2SetSignatureResponse;

export type SignatureServiceSetSignatureError = GooglerpcStatus;

export type SignatureServiceGetData = Messagesignaturev2GetResponse;

export type SignatureServiceGetError = GooglerpcStatus;

export type SignatureServiceRenderData = Messagesignaturev2RenderResponse;

export type SignatureServiceRenderError = GooglerpcStatus;

export interface SignatureServiceSetPayload {
  signature?: string;
}

export type SignatureServiceSetData = Signaturev2SetResponse;

export type SignatureServiceSetError = GooglerpcStatus;

export type SignatureServiceDeleteSignatureData = V2DeleteSignatureResponse;

export type SignatureServiceDeleteSignatureError = GooglerpcStatus;

export type TemplateServiceBulkRenderTextData = V2BulkRenderTextResponse;

export type TemplateServiceBulkRenderTextError = GooglerpcStatus;

export type TemplateServiceCreateData = Messagetemplatev2CreateResponse;

export type TemplateServiceCreateError = GooglerpcStatus;

export interface TemplateServiceListParams {
  /** @format int64 */
  ownerId?: string;
}

export type TemplateServiceListData = Messagetemplatev2ListResponse;

export type TemplateServiceListError = GooglerpcStatus;

export type TemplateServiceRenderTextData = V2RenderTextResponse;

export type TemplateServiceRenderTextError = GooglerpcStatus;

export type TemplateServiceDeleteData = Messagetemplatev2DeleteResponse;

export type TemplateServiceDeleteError = GooglerpcStatus;

export interface TemplateServiceRenderPayload {
  buildingId?: string;
  contactId?: string;
  objectId?: string;
  propertyId?: string;
}

export type TemplateServiceRenderData = Messagetemplatev2RenderResponse;

export type TemplateServiceRenderError = GooglerpcStatus;

export interface TemplateServiceUpdatePayload {
  attachmentMediaIds?: string[];
  language?: string;
  message?: string;
  name?: string;
  subject?: string;
}

export type TemplateServiceUpdateData = Messagetemplatev2UpdateResponse;

export type TemplateServiceUpdateError = GooglerpcStatus;

export type AccountingServiceChangeContractRelationData = AccountingChangeContractRelationResponse;

export type AccountingServiceChangeContractRelationError = GooglerpcStatus;

export type AccountingServiceGetEnabledData = AccountingGetEnabledResponse;

export type AccountingServiceGetEnabledError = GooglerpcStatus;

export interface AccountingServiceGetLogsParams {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
  /** @format int64 */
  accountId: string;
}

export type AccountingServiceGetLogsData = AccountingGetLogsResponse;

export type AccountingServiceGetLogsError = GooglerpcStatus;

export type AccountingServiceGetStatisticsData = ObjectaccountingGetStatisticsResponse;

export type AccountingServiceGetStatisticsError = GooglerpcStatus;

export type AccountingServiceIsEnabledData = AccountingIsEnabledResponse;

export type AccountingServiceIsEnabledError = GooglerpcStatus;

export type AccountingServiceListAllContractErrorsData = AccountingListAllContractErrorsResponse;

export type AccountingServiceListAllContractErrorsError = GooglerpcStatus;

export type AccountingServiceSetEnabledData = AccountingSetEnabledResponse;

export type AccountingServiceSetEnabledError = GooglerpcStatus;

export type AccountingServiceSkipNextEventData = AccountingSkipNextEventResponse;

export type AccountingServiceSkipNextEventError = GooglerpcStatus;

export type AccountingServiceTriggerDebitUntilSyncData = AccountingTriggerDebitUntilSyncResponse;

export type AccountingServiceTriggerDebitUntilSyncError = GooglerpcStatus;

export type AccountingServiceTriggerDocumentSyncData = AccountingTriggerDocumentSyncResponse;

export type AccountingServiceTriggerDocumentSyncError = GooglerpcStatus;

export type AccountingServiceTriggerRentAdjustmentSyncData = AccountingTriggerRentAdjustmentSyncResponse;

export type AccountingServiceTriggerRentAdjustmentSyncError = GooglerpcStatus;

export type ObjectServiceAddCraftsmenData = ObjectAddCraftsmenResponse;

export type ObjectServiceAddCraftsmenError = GooglerpcStatus;

export type ObjectServiceAddJanitorsData = ObjectAddJanitorsResponse;

export type ObjectServiceAddJanitorsError = GooglerpcStatus;

export type ObjectServiceAddServiceProvidersData = ObjectAddServiceProvidersResponse;

export type ObjectServiceAddServiceProvidersError = GooglerpcStatus;

export interface BriefbutlerServiceAcknowledgeErrorsParams {
  ids?: string[];
}

export type BriefbutlerServiceAcknowledgeErrorsData = BriefbutlerAcknowledgeErrorsResponse;

export type BriefbutlerServiceAcknowledgeErrorsError = GooglerpcStatus;

export interface BriefbutlerServiceListErrorsByAccountIdParams {
  /** @format int64 */
  ownerId?: string;
}

export type BriefbutlerServiceListErrorsByAccountIdData = BriefbutlerListErrorsByAccountIdResponse;

export type BriefbutlerServiceListErrorsByAccountIdError = GooglerpcStatus;

export interface BriefbutlerServiceLoadSettingsParams {
  /** @format int64 */
  accountId?: string;
}

export type BriefbutlerServiceLoadSettingsData = ObjectbriefbutlerLoadSettingsResponse;

export type BriefbutlerServiceLoadSettingsError = GooglerpcStatus;

export type BriefbutlerServiceSaveSettingsData = ObjectbriefbutlerSaveSettingsResponse;

export type BriefbutlerServiceSaveSettingsError = GooglerpcStatus;

export type BriefbutlerServiceSendRawDocumentData = BriefbutlerSendRawDocumentResponse;

export type BriefbutlerServiceSendRawDocumentError = GooglerpcStatus;

export type BuildingServiceAddBuildingContactsData = BuildingAddBuildingContactsResponse;

export type BuildingServiceAddBuildingContactsError = GooglerpcStatus;

export interface BuildingServiceCountParams {
  /** @default "UNDEFINED_SCOPE" */
  scope?: ScopeEnum;
  /** @format int64 */
  accountId: string;
}

/** @default "UNDEFINED_SCOPE" */
export enum ScopeEnum {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export type BuildingServiceCountData = ObjectbuildingCountResponse;

export type BuildingServiceCountError = GooglerpcStatus;

/** @default "UNDEFINED_SCOPE" */
export enum BuildingServiceCountParams1ScopeEnum {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export type BuildingServiceCreateData = ObjectbuildingCreateResponse;

export type BuildingServiceCreateError = GooglerpcStatus;

export type BuildingServiceEditContactNoteData = BuildingEditBuildingContactsResponse;

export type BuildingServiceEditContactNoteError = GooglerpcStatus;

export type BuildingServiceGetData = ObjectbuildingGetResponse;

export type BuildingServiceGetError = GooglerpcStatus;

export type BuildingServiceGetByObjectIdData = BuildingGetByObjectIdResponse;

export type BuildingServiceGetByObjectIdError = GooglerpcStatus;

export interface BuildingServiceListParams {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
  name?: string;
  address?: string;
  /** @format int32 */
  zipFrom?: number;
  /** @format int32 */
  zipTo?: number;
  networkPartnerIds?: string[];
  /** @default "UNDEFINED_SORT_ORDER" */
  sortObjectCount?: SortObjectCountEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortGrossTargetRentPerYear?: SortGrossTargetRentPerYearEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortGrossActualRentPerYear?: SortGrossActualRentPerYearEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortNetTargetRentPerYear?: SortNetTargetRentPerYearEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortNetActualRentPerYear?: SortNetActualRentPerYearEnum;
  /**
   * The accountId of the user who wants to list the buildings
   * @format int64
   */
  accountId: string;
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortObjectCountEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortGrossTargetRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortGrossActualRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortNetTargetRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortNetActualRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type BuildingServiceListData = ObjectbuildingListResponse;

export type BuildingServiceListError = GooglerpcStatus;

/** @default "UNDEFINED_SORT_ORDER" */
export enum BuildingServiceListParams1SortObjectCountEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum BuildingServiceListParams1SortGrossTargetRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum BuildingServiceListParams1SortGrossActualRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum BuildingServiceListParams1SortNetTargetRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum BuildingServiceListParams1SortNetActualRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type BuildingServiceListAllData = BuildingListAllResponse;

export type BuildingServiceListAllError = GooglerpcStatus;

export type BuildingServiceListByContactIdsData = ObjectbuildingListByContactIdsResponse;

export type BuildingServiceListByContactIdsError = GooglerpcStatus;

export interface BuildingServiceListByCoordinatesParams {
  /** @format double */
  southWestLat?: number;
  /** @format double */
  southWestLng?: number;
  /** @format double */
  northEastLat?: number;
  /** @format double */
  northEastLng?: number;
  /** @default "UNDEFINED_SCOPE" */
  scope?: ScopeEnum1;
}

/** @default "UNDEFINED_SCOPE" */
export enum ScopeEnum1 {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export type BuildingServiceListByCoordinatesData = BuildingListByCoordinatesResponse;

export type BuildingServiceListByCoordinatesError = GooglerpcStatus;

/** @default "UNDEFINED_SCOPE" */
export enum BuildingServiceListByCoordinatesParams1ScopeEnum {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export type BuildingServiceListByIdsData = ObjectbuildingListByIdsResponse;

export type BuildingServiceListByIdsError = GooglerpcStatus;

export type BuildingServiceListByObjectIdsData = ObjectbuildingListByObjectIdsResponse;

export type BuildingServiceListByObjectIdsError = GooglerpcStatus;

export type BuildingServiceListFilterOptionsData = ObjectbuildingListFilterOptionsResponse;

export type BuildingServiceListFilterOptionsError = GooglerpcStatus;

export type BuildingServiceRemoveBuildingContactsData = BuildingRemoveBuildingContactsResponse;

export type BuildingServiceRemoveBuildingContactsError = GooglerpcStatus;

export type BuildingServiceDeleteData = ObjectbuildingDeleteResponse;

export type BuildingServiceDeleteError = GooglerpcStatus;

export interface BuildingServiceMoveBuildingPayload {
  newPropertyId?: string;
}

export type BuildingServiceMoveBuildingData = BuildingMoveBuildingResponse;

export type BuildingServiceMoveBuildingError = GooglerpcStatus;

export interface BuildingServiceUpdatePayload {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  acquisitionDate?: TypeDate;
  address?: CommonaddressAddress;
  /** @format float */
  buildingArea?: number;
  buildingStrategy?: BuildingBuildingStrategy;
  /** @format int64 */
  buildingValue?: string;
  buildingZone?: string;
  characteristics?: ObjectbuildingCharacteristics;
  constructionTypes?: BuildingConstructionType[];
  /** @format int32 */
  constructionYear?: number;
  contacts?: BuildingBuildingContact[];
  contractAmendments?: string;
  distances?: BuildingDistances;
  documentIds?: string[];
  egid?: string;
  energySources?: ObjectbuildingEnergySource[];
  /** @format int32 */
  floors?: number;
  imageMediaId?: string;
  landRegisterNumber?: string;
  locationQuality?: ObjectbuildingLocationQuality;
  name?: string;
  note?: string;
  /** @format int32 */
  number?: number;
  objectIds?: string[];
  /** @format float */
  plotArea?: number;
  plotIds?: string[];
  rating?: BuildingRating;
  realEstateNumber?: string;
  /** @format int32 */
  renovationYear?: number;
  sia?: BuildingSia;
  /** @format float */
  volume?: number;
}

export type BuildingServiceUpdateData = ObjectbuildingUpdateResponse;

export type BuildingServiceUpdateError = GooglerpcStatus;

export interface BuildingServiceUpdateMicroLocationRatingProposalPayload {
  /** @format float */
  microLocationRatingProposal?: number;
  /** @format float */
  motorway?: number;
  /** @format float */
  publicTransport?: number;
  /** @format float */
  shop?: number;
}

export type BuildingServiceUpdateMicroLocationRatingProposalData = BuildingUpdateMicroLocationRatingProposalResponse;

export type BuildingServiceUpdateMicroLocationRatingProposalError = GooglerpcStatus;

export type CondominiumServiceCreateData = ObjectcondominiumCreateResponse;

export type CondominiumServiceCreateError = GooglerpcStatus;

export type CondominiumServiceDeleteRentAdjustmentsData = ObjectcondominiumDeleteRentAdjustmentsResponse;

export type CondominiumServiceDeleteRentAdjustmentsError = GooglerpcStatus;

export type CondominiumServiceGetData = ObjectcondominiumGetResponse;

export type CondominiumServiceGetError = GooglerpcStatus;

export type CondominiumServiceHasActivatedCondominiumData = CondominiumHasActivatedCondominiumResponse;

export type CondominiumServiceHasActivatedCondominiumError = GooglerpcStatus;

export type CondominiumServiceListByBillingContactIdData = ObjectcondominiumListByBillingContactIdResponse;

export type CondominiumServiceListByBillingContactIdError = GooglerpcStatus;

export interface CondominiumServiceListByContactIdParams {
  billingContact?: boolean;
  owners?: boolean;
  correspondence?: boolean;
  roommates?: boolean;
  tenants?: boolean;
  contactId: string;
}

export type CondominiumServiceListByContactIdData = ObjectcondominiumListByContactIdResponse;

export type CondominiumServiceListByContactIdError = GooglerpcStatus;

export type CondominiumServiceListByIdsData = ObjectcondominiumListByIdsResponse;

export type CondominiumServiceListByIdsError = GooglerpcStatus;

export type CondominiumServiceListByObjectIdsData = ObjectcondominiumListByObjectIdsResponse;

export type CondominiumServiceListByObjectIdsError = GooglerpcStatus;

export type CondominiumServiceListByOwnerData = ObjectcondominiumListByOwnerResponse;

export type CondominiumServiceListByOwnerError = GooglerpcStatus;

export type CondominiumServiceListByOwnerContactData = CondominiumListByOwnerContactResponse;

export type CondominiumServiceListByOwnerContactError = GooglerpcStatus;

export type CondominiumServiceListByTenantContactsData = CondominiumListByTenantContactsResponse;

export type CondominiumServiceListByTenantContactsError = GooglerpcStatus;

export type CondominiumServiceListCurrentByObjectIdsData = ObjectcondominiumListCurrentByObjectIdsResponse;

export type CondominiumServiceListCurrentByObjectIdsError = GooglerpcStatus;

export type CondominiumServicePublishUpdatedEventsData = ObjectcondominiumPublishUpdatedEventsResponse;

export type CondominiumServicePublishUpdatedEventsError = GooglerpcStatus;

export type CondominiumServiceUpdateBulkData = ObjectcondominiumUpdateBulkResponse;

export type CondominiumServiceUpdateBulkError = GooglerpcStatus;

export type CondominiumServiceDeleteData = ObjectcondominiumDeleteResponse;

export type CondominiumServiceDeleteError = GooglerpcStatus;

export type CondominiumServiceActivateData = ObjectcondominiumActivateResponse;

export type CondominiumServiceActivateError = GooglerpcStatus;

export type CondominiumServiceAddDocumentsData = ObjectcondominiumAddDocumentsResponse;

export type CondominiumServiceAddDocumentsError = GooglerpcStatus;

export type CondominiumServiceDeactivateData = ObjectcondominiumDeactivateResponse;

export type CondominiumServiceDeactivateError = GooglerpcStatus;

export interface CondominiumServiceUpdatePayload {
  activated?: boolean;
  closed?: boolean;
  contract?: ObjectcondominiumContract;
  documentIds?: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endingAt?: TypeDate;
  finalInvoiceCreated?: boolean;
  flatRateValues?: ObjectcondominiumFlatRateValues[];
  opted?: boolean;
  owner?: CondominiumOwner;
  rent?: ObjectcondominiumRent;
  secondaryResidence?: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startingAt?: TypeDate;
  taxable?: boolean;
  tenants?: ObjectcondominiumTenant[];
}

export type CondominiumServiceUpdateData = ObjectcondominiumUpdateResponse;

export type CondominiumServiceUpdateError = GooglerpcStatus;

export type CondominiumServiceDeactivateByNumericIdData = ObjectcondominiumDeactivateByNumericIdResponse;

export type CondominiumServiceDeactivateByNumericIdError = GooglerpcStatus;

export type ContractServiceCreateTemplateData = ObjectcontractCreateTemplateResponse;

export type ContractServiceCreateTemplateError = GooglerpcStatus;

export type ContractServiceDeleteTemplateData = ObjectcontractDeleteTemplateResponse;

export type ContractServiceDeleteTemplateError = GooglerpcStatus;

export type ContractServiceListTemplatesData = ObjectcontractListTemplatesResponse;

export type ContractServiceListTemplatesError = GooglerpcStatus;

export type ContractServiceRenderTemplateData = ContractRenderTemplateResponse;

export type ContractServiceRenderTemplateError = GooglerpcStatus;

export type ContractServiceRenderDeviceTemplateData = ObjectcontractRenderDeviceTemplateResponse;

export type ContractServiceRenderDeviceTemplateError = GooglerpcStatus;

export type ContractServiceRenderTenanciesTemplateData = ObjectcontractRenderTenanciesTemplateResponse;

export type ContractServiceRenderTenanciesTemplateError = GooglerpcStatus;

export interface ContractServiceUpdateTemplatePayload {
  mediaId?: string;
  title?: string;
}

export type ContractServiceUpdateTemplateData = ObjectcontractUpdateTemplateResponse;

export type ContractServiceUpdateTemplateError = GooglerpcStatus;

export type ContractServiceRenderCondominiumTemplateData = ObjectcontractRenderCondominiumTemplateResponse;

export type ContractServiceRenderCondominiumTemplateError = GooglerpcStatus;

export type ContractServiceRenderCondominiumsTemplateData = ObjectcontractRenderCondominiumsTemplateResponse;

export type ContractServiceRenderCondominiumsTemplateError = GooglerpcStatus;

export type ContractServiceRenderContactTemplateData = ObjectcontractRenderContactTemplateResponse;

export type ContractServiceRenderContactTemplateError = GooglerpcStatus;

export type ContractServiceRenderContactsTemplateData = ObjectcontractRenderContactsTemplateResponse;

export type ContractServiceRenderContactsTemplateError = GooglerpcStatus;

export interface CountryIndexServiceGetInflationParams {
  /** @default "COUNTRY_INDEX_BASE_YEAR_1914" */
  baseYear?: BaseYearEnum;
  /** @format int32 */
  month?: number;
  /** @format int32 */
  year?: number;
}

/** @default "COUNTRY_INDEX_BASE_YEAR_1914" */
export enum BaseYearEnum {
  COUNTRYINDEXBASEYEAR1914 = "COUNTRY_INDEX_BASE_YEAR_1914",
  COUNTRYINDEXBASEYEAR1939 = "COUNTRY_INDEX_BASE_YEAR_1939",
  COUNTRYINDEXBASEYEAR1966 = "COUNTRY_INDEX_BASE_YEAR_1966",
  COUNTRYINDEXBASEYEAR1977 = "COUNTRY_INDEX_BASE_YEAR_1977",
  COUNTRYINDEXBASEYEAR1982 = "COUNTRY_INDEX_BASE_YEAR_1982",
  COUNTRYINDEXBASEYEAR1993 = "COUNTRY_INDEX_BASE_YEAR_1993",
  COUNTRYINDEXBASEYEAR2000 = "COUNTRY_INDEX_BASE_YEAR_2000",
  COUNTRYINDEXBASEYEAR2005 = "COUNTRY_INDEX_BASE_YEAR_2005",
  COUNTRYINDEXBASEYEAR2010 = "COUNTRY_INDEX_BASE_YEAR_2010",
  COUNTRYINDEXBASEYEAR2015 = "COUNTRY_INDEX_BASE_YEAR_2015",
  COUNTRYINDEXBASEYEAR2020 = "COUNTRY_INDEX_BASE_YEAR_2020",
}

export type CountryIndexServiceGetInflationData = CountryIndexGetInflationResponse;

export type CountryIndexServiceGetInflationError = GooglerpcStatus;

/** @default "COUNTRY_INDEX_BASE_YEAR_1914" */
export enum CountryIndexServiceGetInflationParams1BaseYearEnum {
  COUNTRYINDEXBASEYEAR1914 = "COUNTRY_INDEX_BASE_YEAR_1914",
  COUNTRYINDEXBASEYEAR1939 = "COUNTRY_INDEX_BASE_YEAR_1939",
  COUNTRYINDEXBASEYEAR1966 = "COUNTRY_INDEX_BASE_YEAR_1966",
  COUNTRYINDEXBASEYEAR1977 = "COUNTRY_INDEX_BASE_YEAR_1977",
  COUNTRYINDEXBASEYEAR1982 = "COUNTRY_INDEX_BASE_YEAR_1982",
  COUNTRYINDEXBASEYEAR1993 = "COUNTRY_INDEX_BASE_YEAR_1993",
  COUNTRYINDEXBASEYEAR2000 = "COUNTRY_INDEX_BASE_YEAR_2000",
  COUNTRYINDEXBASEYEAR2005 = "COUNTRY_INDEX_BASE_YEAR_2005",
  COUNTRYINDEXBASEYEAR2010 = "COUNTRY_INDEX_BASE_YEAR_2010",
  COUNTRYINDEXBASEYEAR2015 = "COUNTRY_INDEX_BASE_YEAR_2015",
  COUNTRYINDEXBASEYEAR2020 = "COUNTRY_INDEX_BASE_YEAR_2020",
}

export type CountryIndexServiceReadData = CountryIndexReadResponse;

export type CountryIndexServiceReadError = GooglerpcStatus;

export type ObjectServiceCreateData = ObjectobjectCreateResponse;

export type ObjectServiceCreateError = GooglerpcStatus;

export type ObjectServiceCreateBulkData = ObjectobjectCreateBulkResponse;

export type ObjectServiceCreateBulkError = GooglerpcStatus;

export type DemoDataServiceGenerateData = DemoDataGenerateResponse;

export type DemoDataServiceGenerateError = GooglerpcStatus;

export type DeviceServiceCreateData = ObjectdeviceCreateResponse;

export type DeviceServiceCreateError = GooglerpcStatus;

export interface DeviceServiceGetDefaultDataParams {
  /**
   * Optional. If not provided the whole list for all device types will be returned.
   *
   *  - DEVICE_TYPE_CHROME_STEEP_FINISH: Not used
   * @default "DEVICE_TYPE_UNDEFINED"
   */
  type?: TypeEnum;
}

/**
 * Optional. If not provided the whole list for all device types will be returned.
 *
 *  - DEVICE_TYPE_CHROME_STEEP_FINISH: Not used
 * @default "DEVICE_TYPE_UNDEFINED"
 */
export enum TypeEnum {
  DEVICE_TYPE_UNDEFINED = "DEVICE_TYPE_UNDEFINED",
  DEVICE_TYPE_MASTER_BUILDER_WORK_DOWN_PIPES = "DEVICE_TYPE_MASTER_BUILDER_WORK_DOWN_PIPES",
  DEVICE_TYPE_MASTER_BUILDER_WORK_BASIC_LINE = "DEVICE_TYPE_MASTER_BUILDER_WORK_BASIC_LINE",
  DEVICE_TYPE_MASTER_BUILDER_WORK_DRAINAGE_LINE = "DEVICE_TYPE_MASTER_BUILDER_WORK_DRAINAGE_LINE",
  DEVICE_TYPE_MASTER_BUILDER_WORK_PUMP_CHAMBER = "DEVICE_TYPE_MASTER_BUILDER_WORK_PUMP_CHAMBER",
  DEVICE_TYPE_MASTER_BUILDER_WORK_TERRACE_DRAIN = "DEVICE_TYPE_MASTER_BUILDER_WORK_TERRACE_DRAIN",
  DEVICE_TYPE_WINDOW_DOOR_AUTOMATIC_SLIDING_DOOR = "DEVICE_TYPE_WINDOW_DOOR_AUTOMATIC_SLIDING_DOOR",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR",
  DEVICE_TYPE_WINDOW_DOOR_FIRE_GATE = "DEVICE_TYPE_WINDOW_DOOR_FIRE_GATE",
  DEVICE_TYPE_WINDOW_DOOR_ROOF_WINDOW = "DEVICE_TYPE_WINDOW_DOOR_ROOF_WINDOW",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW = "DEVICE_TYPE_WINDOW_DOOR_WINDOW",
  DEVICE_TYPE_WINDOW_DOOR_GATE_DRIVE = "DEVICE_TYPE_WINDOW_DOOR_GATE_DRIVE",
  DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR = "DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR",
  DEVICE_TYPE_WINDOW_DOOR_DOOR_AUTOMATION = "DEVICE_TYPE_WINDOW_DOOR_DOOR_AUTOMATION",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_SOLID_WOOD = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_SOLID_WOOD",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_WOOD_MATERIAL = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_WOOD_MATERIAL",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_METAL = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_METAL",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_DOUBLE_GLAZING = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_DOUBLE_GLAZING",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_PLASTIC = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_PLASTIC",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_METAL = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_METAL",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_METAL = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_METAL",
  DEVICE_TYPE_ROOFING_PENT_ROOF = "DEVICE_TYPE_ROOFING_PENT_ROOF",
  DEVICE_TYPE_ROOFING_SADDLE_ROOF = "DEVICE_TYPE_ROOFING_SADDLE_ROOF",
  DEVICE_TYPE_ROOFING_TERRACE = "DEVICE_TYPE_ROOFING_TERRACE",
  DEVICE_TYPE_ROOFING_HIPPED_ROOF = "DEVICE_TYPE_ROOFING_HIPPED_ROOF",
  DEVICE_TYPE_ROOFING_TENT_ROOF = "DEVICE_TYPE_ROOFING_TENT_ROOF",
  DEVICE_TYPE_ROOFING_FLAT_ROOF = "DEVICE_TYPE_ROOFING_FLAT_ROOF",
  DEVICE_TYPE_ROOFING_SHEARING_ROOF = "DEVICE_TYPE_ROOFING_SHEARING_ROOF",
  DEVICE_TYPE_ROOFING_FRONT_PANEL = "DEVICE_TYPE_ROOFING_FRONT_PANEL",
  DEVICE_TYPE_SEALING_INSULATION_FACADE = "DEVICE_TYPE_SEALING_INSULATION_FACADE",
  DEVICE_TYPE_SEALING_INSULATION_INTERIOR = "DEVICE_TYPE_SEALING_INSULATION_INTERIOR",
  DEVICE_TYPE_SEALING_INSULATION_FACADE_WOOD = "DEVICE_TYPE_SEALING_INSULATION_FACADE_WOOD",
  DEVICE_TYPE_SEALING_INSULATION_FACADE_PLATES = "DEVICE_TYPE_SEALING_INSULATION_FACADE_PLATES",
  DEVICE_TYPE_SEALING_INSULATION_FACADE_ETERNIT = "DEVICE_TYPE_SEALING_INSULATION_FACADE_ETERNIT",
  DEVICE_TYPE_FACADE_PLASTER_PAINTING = "DEVICE_TYPE_FACADE_PLASTER_PAINTING",
  DEVICE_TYPE_FACADE_PLASTER = "DEVICE_TYPE_FACADE_PLASTER",
  DEVICE_TYPE_OUTER_FINISHES_FOLDING_SHUTTERS = "DEVICE_TYPE_OUTER_FINISHES_FOLDING_SHUTTERS",
  DEVICE_TYPE_OUTER_FINISHES_SHUTTERS = "DEVICE_TYPE_OUTER_FINISHES_SHUTTERS",
  DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS = "DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS",
  DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS_VOLANT = "DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS_VOLANT",
  DEVICE_TYPE_OUTER_FINISHES_GLARE_PROTECTION = "DEVICE_TYPE_OUTER_FINISHES_GLARE_PROTECTION",
  DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_WOOD = "DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_WOOD",
  DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_METAL = "DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_METAL",
  DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS = "DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS",
  DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_OUTSIDE_ALUMINIUM = "DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_OUTSIDE_ALUMINIUM",
  DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_INSIDE = "DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_INSIDE",
  DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS = "DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS",
  DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_WOOD = "DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_WOOD",
  DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_METAL = "DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_METAL",
  DEVICE_TYPE_OUTER_FINISHES = "DEVICE_TYPE_OUTER_FINISHES",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_GLASS = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_GLASS",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_WOOD = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_WOOD",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_METAL = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_METAL",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LIGHTING = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LIGHTING",
  DEVICETYPEHEAVYCURRENTINSTALLATIONCO2 = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CO2",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SINA = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SINA",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SMOKE_VENT = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SMOKE_VENT",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PROOF_OF_SAFETY_SINA = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PROOF_OF_SAFETY_SINA",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHGEAR_COMBINATION = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHGEAR_COMBINATION",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PHOTOVOLTAIC = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PHOTOVOLTAIC",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LOW_VOLTAGE_SYSTEM = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LOW_VOLTAGE_SYSTEM",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CABLE = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CABLE",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCH = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCH",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHBOARD = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHBOARD",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SOCKET = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SOCKET",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_UPS = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_UPS",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_INFRASTRUCTURE_ELECTRIC_CHARGING = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_INFRASTRUCTURE_ELECTRIC_CHARGING",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_ELECTRIC_CHARGING_STATION = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_ELECTRIC_CHARGING_STATION",
  DEVICE_TYPE_LIGHTS_ILLUMINANT = "DEVICE_TYPE_LIGHTS_ILLUMINANT",
  DEVICE_TYPE_LIGHTS = "DEVICE_TYPE_LIGHTS",
  DEVICE_TYPE_LIGHTS_CEILING = "DEVICE_TYPE_LIGHTS_CEILING",
  DEVICE_TYPE_LIGHTS_CABINET = "DEVICE_TYPE_LIGHTS_CABINET",
  DEVICE_TYPE_LIGHTS_SPOTS = "DEVICE_TYPE_LIGHTS_SPOTS",
  DEVICE_TYPE_LIGHTS_MIRROR_CABINET = "DEVICE_TYPE_LIGHTS_MIRROR_CABINET",
  DEVICE_TYPE_TELEPHONE_CENTER = "DEVICE_TYPE_TELEPHONE_CENTER",
  DEVICE_TYPE_TELEPHONE_DISTRIBUTOR = "DEVICE_TYPE_TELEPHONE_DISTRIBUTOR",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM_VIDEO = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM_VIDEO",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_BELL = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_BELL",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_DOOR_OPENER = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_DOOR_OPENER",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_FIBERGLASS = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_FIBERGLASS",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_EMERGENCY_PHONE_LIFT = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_EMERGENCY_PHONE_LIFT",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTRUSION_DETECTION = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTRUSION_DETECTION",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_ISDN = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_ISDN",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_CABLE = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_CABLE",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCHBOARD = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCHBOARD",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SOCKET = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SOCKET",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_ANTENNA = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_ANTENNA",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_CABLE = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_CABLE",
  DEVICE_TYPE_OTHER_ELECTRICITY_BATTERY = "DEVICE_TYPE_OTHER_ELECTRICITY_BATTERY",
  DEVICE_TYPE_OTHER_ELECTRICITY_OTHER = "DEVICE_TYPE_OTHER_ELECTRICITY_OTHER",
  DEVICE_TYPE_OTHER_ELECTRICITY_LIGHTNING_PROTECTION = "DEVICE_TYPE_OTHER_ELECTRICITY_LIGHTNING_PROTECTION",
  DEVICE_TYPE_OTHER_ELECTRICITY = "DEVICE_TYPE_OTHER_ELECTRICITY",
  DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET = "DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_ROOF_VENTILATION = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_ROOF_VENTILATION",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_FILTER_REPLACEMENT = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_FILTER_REPLACEMENT",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_AIR_CONDITIONING = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_AIR_CONDITIONING",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_VENTILATION = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_VENTILATION",
  DEVICE_TYPE_ENERGY_SUPPLY_SUPPLY = "DEVICE_TYPE_ENERGY_SUPPLY_SUPPLY",
  DEVICE_TYPE_ENERGY_SUPPLY_LEAKAGE_WARNING = "DEVICE_TYPE_ENERGY_SUPPLY_LEAKAGE_WARNING",
  DEVICE_TYPE_ENERGY_SUPPLY_TANK = "DEVICE_TYPE_ENERGY_SUPPLY_TANK",
  DEVICE_TYPE_ENERGY_SUPPLY_STORAGE = "DEVICE_TYPE_ENERGY_SUPPLY_STORAGE",
  DEVICE_TYPE_ENERGY_SUPPLY_GAS_LINE = "DEVICE_TYPE_ENERGY_SUPPLY_GAS_LINE",
  DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_INSIDE = "DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_INSIDE",
  DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_EARTH_LOADED = "DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_EARTH_LOADED",
  DEVICE_TYPE_HEAT_GENERATION = "DEVICE_TYPE_HEAT_GENERATION",
  DEVICE_TYPE_HEAT_GENERATION_BOILER = "DEVICE_TYPE_HEAT_GENERATION_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_BURNER = "DEVICE_TYPE_HEAT_GENERATION_BURNER",
  DEVICE_TYPE_HEAT_GENERATION_EARTH_PROBE = "DEVICE_TYPE_HEAT_GENERATION_EARTH_PROBE",
  DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STORAGE_STOVE = "DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STORAGE_STOVE",
  DEVICE_TYPE_HEAT_GENERATION_EXPANSION_DEVICE = "DEVICE_TYPE_HEAT_GENERATION_EXPANSION_DEVICE",
  DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING = "DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING",
  DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING_SUBSTATION = "DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING_SUBSTATION",
  DEVICE_TYPE_HEAT_GENERATION_HEATING_BOILER = "DEVICE_TYPE_HEAT_GENERATION_HEATING_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_THERMAL_SOLAR_SYSTEM = "DEVICE_TYPE_HEAT_GENERATION_THERMAL_SOLAR_SYSTEM",
  DEVICE_TYPE_HEAT_GENERATION_HEAT_EXCHANGER = "DEVICE_TYPE_HEAT_GENERATION_HEAT_EXCHANGER",
  DEVICE_TYPE_HEAT_GENERATION_HEAT_PUMP = "DEVICE_TYPE_HEAT_GENERATION_HEAT_PUMP",
  DEVICE_TYPE_HEAT_GENERATION_FIREPLACE = "DEVICE_TYPE_HEAT_GENERATION_FIREPLACE",
  DEVICE_TYPE_HEAT_GENERATION_HEAT_ACCUMULATOR = "DEVICE_TYPE_HEAT_GENERATION_HEAT_ACCUMULATOR",
  DEVICE_TYPE_HEAT_GENERATION_SINGLE_WATER_BOILER = "DEVICE_TYPE_HEAT_GENERATION_SINGLE_WATER_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_COMBIBOILER_HEATING = "DEVICE_TYPE_HEAT_GENERATION_COMBIBOILER_HEATING",
  DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_BOILER = "DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_BOILER_GAS_APPLIANCES = "DEVICE_TYPE_HEAT_GENERATION_BOILER_GAS_APPLIANCES",
  DEVICE_TYPE_HEAT_GENERATION_COMBINED_BOILER = "DEVICE_TYPE_HEAT_GENERATION_COMBINED_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_SOLAR_PANELS = "DEVICE_TYPE_HEAT_GENERATION_SOLAR_PANELS",
  DEVICE_TYPE_HEAT_GENERATION_RADIATORS = "DEVICE_TYPE_HEAT_GENERATION_RADIATORS",
  DEVICE_TYPE_HEAT_GENERATION_RADIATORS_HEATWALLS = "DEVICE_TYPE_HEAT_GENERATION_RADIATORS_HEATWALLS",
  DEVICE_TYPE_HEAT_GENERATION_TOWEL_RADIATORS = "DEVICE_TYPE_HEAT_GENERATION_TOWEL_RADIATORS",
  DEVICE_TYPE_HEAT_GENERATION_FLOOR_HEATING = "DEVICE_TYPE_HEAT_GENERATION_FLOOR_HEATING",
  DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT = "DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT",
  DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STOVE = "DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STOVE",
  DEVICE_TYPE_HEAT_GENERATION_ROOF_HEATING = "DEVICE_TYPE_HEAT_GENERATION_ROOF_HEATING",
  DEVICE_TYPE_HEAT_DISTRIBUTION = "DEVICE_TYPE_HEAT_DISTRIBUTION",
  DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_METER = "DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_METER",
  DEVICE_TYPE_HEAT_DISTRIBUTION_CIRCULATING_PUMP = "DEVICE_TYPE_HEAT_DISTRIBUTION_CIRCULATING_PUMP",
  DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_QUANTITY_FLOW_METERS = "DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_QUANTITY_FLOW_METERS",
  DEVICE_TYPE_HEAT_DISTRIBUTION_HEATING_COST_ALLOCATOR = "DEVICE_TYPE_HEAT_DISTRIBUTION_HEATING_COST_ALLOCATOR",
  DEVICE_TYPE_VENTILATION_VENTILATION_CONTROL_CENTER = "DEVICE_TYPE_VENTILATION_VENTILATION_CONTROL_CENTER",
  DEVICE_TYPE_VENTILATION_VENTILATION = "DEVICE_TYPE_VENTILATION_VENTILATION",
  DEVICE_TYPE_VENTILATION_SUPPLY_AIR_FAN = "DEVICE_TYPE_VENTILATION_SUPPLY_AIR_FAN",
  DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN = "DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN",
  DEVICE_TYPE_VENTILATION_SMOKE_EXTRACTION_SYSTEM = "DEVICE_TYPE_VENTILATION_SMOKE_EXTRACTION_SYSTEM",
  DEVICE_TYPE_VENTILATION = "DEVICE_TYPE_VENTILATION",
  DEVICE_TYPE_VENTILATION_CONTROLLED_HOME = "DEVICE_TYPE_VENTILATION_CONTROLLED_HOME",
  DEVICE_TYPE_VENTILATION_CHANNELS = "DEVICE_TYPE_VENTILATION_CHANNELS",
  DEVICE_TYPE_AIR_CONDITIONING_RECOOLING = "DEVICE_TYPE_AIR_CONDITIONING_RECOOLING",
  DEVICE_TYPE_AIR_CONDITIONING = "DEVICE_TYPE_AIR_CONDITIONING",
  DEVICE_TYPE_AIR_CONDITIONING_INDIVIDUAL_ROOMS = "DEVICE_TYPE_AIR_CONDITIONING_INDIVIDUAL_ROOMS",
  DEVICE_TYPE_COOLING_CHILLERS = "DEVICE_TYPE_COOLING_CHILLERS",
  DEVICE_TYPE_COOLING_COLD_RESERVOIR = "DEVICE_TYPE_COOLING_COLD_RESERVOIR",
  DEVICE_TYPE_COOLING_COLD_WATER_TANK = "DEVICE_TYPE_COOLING_COLD_WATER_TANK",
  DEVICE_TYPE_COOLING_COLD_STORAGE_CELL = "DEVICE_TYPE_COOLING_COLD_STORAGE_CELL",
  DEVICE_TYPE_COOLING = "DEVICE_TYPE_COOLING",
  DEVICE_TYPE_SPECIAL_FACILITY = "DEVICE_TYPE_SPECIAL_FACILITY",
  DEVICE_TYPE_INSULATION = "DEVICE_TYPE_INSULATION",
  DEVICE_TYPE_INSULATION_CHIMNEY = "DEVICE_TYPE_INSULATION_CHIMNEY",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_BUILDING_MANAGEMENT_SYSTEM = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_BUILDING_MANAGEMENT_SYSTEM",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_CONTROL_SYSTEM = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_CONTROL_SYSTEM",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_VALVES = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_VALVES",
  DEVICE_TYPE_SANITARY_BATHTUB = "DEVICE_TYPE_SANITARY_BATHTUB",
  DEVICE_TYPE_SANITARY_RENOVATION = "DEVICE_TYPE_SANITARY_RENOVATION",
  DEVICE_TYPE_SANITARY_CONTROL_FITTINGS = "DEVICE_TYPE_SANITARY_CONTROL_FITTINGS",
  DEVICE_TYPE_SANITARY_WASHBASIN = "DEVICE_TYPE_SANITARY_WASHBASIN",
  DEVICE_TYPE_SANITARY_WASHBASIN_CERAMIC = "DEVICE_TYPE_SANITARY_WASHBASIN_CERAMIC",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET = "DEVICE_TYPE_SANITARY_MIRROR_CABINET",
  DEVICE_TYPE_SANITARY_BIDET = "DEVICE_TYPE_SANITARY_BIDET",
  DEVICE_TYPE_SANITARY_SHOWER = "DEVICE_TYPE_SANITARY_SHOWER",
  DEVICE_TYPE_SANITARY_LAVABO = "DEVICE_TYPE_SANITARY_LAVABO",
  DEVICE_TYPE_SANITARY_URINAL = "DEVICE_TYPE_SANITARY_URINAL",
  DEVICE_TYPE_SANITARY_FLUSH_TANK = "DEVICE_TYPE_SANITARY_FLUSH_TANK",
  DEVICE_TYPE_SANITARY_TOILET = "DEVICE_TYPE_SANITARY_TOILET",
  DEVICE_TYPE_SANITARY_TOILET_CERAMIC = "DEVICE_TYPE_SANITARY_TOILET_CERAMIC",
  DEVICE_TYPE_SANITARY_TOILET_CLOSOMAT = "DEVICE_TYPE_SANITARY_TOILET_CLOSOMAT",
  DEVICE_TYPE_SANITARY_TOILET_COVER = "DEVICE_TYPE_SANITARY_TOILET_COVER",
  DEVICE_TYPE_SANITARY_TOILET_COVER_SOFT_CLOSE = "DEVICE_TYPE_SANITARY_TOILET_COVER_SOFT_CLOSE",
  DEVICE_TYPE_SANITARY_BATHTUB_ACRYLIC = "DEVICE_TYPE_SANITARY_BATHTUB_ACRYLIC",
  DEVICE_TYPE_SANITARY_BATHTUB_STEEL = "DEVICE_TYPE_SANITARY_BATHTUB_STEEL",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET_PLASTIC = "DEVICE_TYPE_SANITARY_MIRROR_CABINET_PLASTIC",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET_WOODEN = "DEVICE_TYPE_SANITARY_MIRROR_CABINET_WOODEN",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET_METAL = "DEVICE_TYPE_SANITARY_MIRROR_CABINET_METAL",
  DEVICE_TYPE_SANITARY_FLUSH_TANK_FLUSH_MOUNTED = "DEVICE_TYPE_SANITARY_FLUSH_TANK_FLUSH_MOUNTED",
  DEVICE_TYPE_SANITARY_FLUSH_TANK_PLASTIC = "DEVICE_TYPE_SANITARY_FLUSH_TANK_PLASTIC",
  DEVICE_TYPE_SANITARY_FLUSH_TANK_CERAMIC = "DEVICE_TYPE_SANITARY_FLUSH_TANK_CERAMIC",
  DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL = "DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL",
  DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CERAMIC = "DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CERAMIC",
  DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CLOSOMAT = "DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CLOSOMAT",
  DEVICE_TYPE_SANITARY_SHOWER_CABIN = "DEVICE_TYPE_SANITARY_SHOWER_CABIN",
  DEVICE_TYPE_SANITARY_SHOWER_CABIN_PLASTIC = "DEVICE_TYPE_SANITARY_SHOWER_CABIN_PLASTIC",
  DEVICE_TYPE_SANITARY_SHOWER_CABIN_GLASWALLS = "DEVICE_TYPE_SANITARY_SHOWER_CABIN_GLASWALLS",
  DEVICE_TYPE_SPECIAL_SANITARY_WATER_SOFTENER = "DEVICE_TYPE_SPECIAL_SANITARY_WATER_SOFTENER",
  DEVICE_TYPE_SPECIAL_SANITARY_LIMESCALE_PROTECTION = "DEVICE_TYPE_SPECIAL_SANITARY_LIMESCALE_PROTECTION",
  DEVICE_TYPE_SPECIAL_SANITARY_DEHUMIDIFIER = "DEVICE_TYPE_SPECIAL_SANITARY_DEHUMIDIFIER",
  DEVICE_TYPE_SPECIAL_SANITARY_WASHING_MACHINE = "DEVICE_TYPE_SPECIAL_SANITARY_WASHING_MACHINE",
  DEVICE_TYPE_SPECIAL_SANITARY_COMBI_WASHER_DRYER = "DEVICE_TYPE_SPECIAL_SANITARY_COMBI_WASHER_DRYER",
  DEVICE_TYPE_SPECIAL_SANITARY_WASHING_ELECTRICITY_METER = "DEVICE_TYPE_SPECIAL_SANITARY_WASHING_ELECTRICITY_METER",
  DEVICE_TYPE_SPECIAL_SANITARY_TUMBLER = "DEVICE_TYPE_SPECIAL_SANITARY_TUMBLER",
  DEVICE_TYPE_SPECIAL_SANITARY_VACUUM_DEGASSING_DEVICE = "DEVICE_TYPE_SPECIAL_SANITARY_VACUUM_DEGASSING_DEVICE",
  DEVICE_TYPE_SPECIAL_SANITARY = "DEVICE_TYPE_SPECIAL_SANITARY",
  DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP = "DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SUPPLY = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SUPPLY",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_DISPOSAL = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_DISPOSAL",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_TREATMENT = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_TREATMENT",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_PUMP = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_PUMP",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_DISTRIBUTION_BATTERY = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_DISTRIBUTION_BATTERY",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_TANK = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_TANK",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_PIPING_COLD_WATER = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_PIPING_COLD_WATER",
  DEVICE_TYPE_SANITARY_PIPING_RAIN_WATER = "DEVICE_TYPE_SANITARY_PIPING_RAIN_WATER",
  DEVICE_TYPE_SANITARY_PIPING_WARM_WATER = "DEVICE_TYPE_SANITARY_PIPING_WARM_WATER",
  DEVICE_TYPE_SANITARY_PIPING = "DEVICE_TYPE_SANITARY_PIPING",
  DEVICE_TYPE_SANITARY_INSULATION = "DEVICE_TYPE_SANITARY_INSULATION",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_ONE_DOOR = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_ONE_DOOR",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_TWO_DOOR = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_TWO_DOOR",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR",
  DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE = "DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE",
  DEVICE_TYPE_SANITARY_EQUIPMENT_SHOWER_CABIN = "DEVICE_TYPE_SANITARY_EQUIPMENT_SHOWER_CABIN",
  DEVICE_TYPE_SANITARY_EQUIPMENT = "DEVICE_TYPE_SANITARY_EQUIPMENT",
  DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE = "DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE",
  DEVICE_TYPE_SANITARY_SHOWER_ROD = "DEVICE_TYPE_SANITARY_SHOWER_ROD",
  DEVICE_TYPE_SANITARY_SHOWER_ROD_CHROME_STEEL = "DEVICE_TYPE_SANITARY_SHOWER_ROD_CHROME_STEEL",
  DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOLDER = "DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOLDER",
  DEVICE_TYPE_ELECTRIC_PNEUMATIC_BOARD = "DEVICE_TYPE_ELECTRIC_PNEUMATIC_BOARD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR = "DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES = "DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES_COMPARTMENT = "DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES_COMPARTMENT",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_SEPARATING_SYSTEM = "DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_SEPARATING_SYSTEM",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_HOLDER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_HOLDER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_BLECH = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_BLECH",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRID = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRID",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_THERMOMETER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_THERMOMETER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRILL = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRILL",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_INDUCTION_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_INDUCTION_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_GLASS_CERAMIC_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_GLASS_CERAMIC_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_HOT_PLATES_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_HOT_PLATES_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_GAS_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_GAS_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_MINOR_KITCHEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_MINOR_KITCHEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FURNITURE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FURNITURE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_MICROWAVE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_MICROWAVE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STOVE_WITH_OVEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STOVE_WITH_OVEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_CARBON_FILTER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_CARBON_FILTER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_ALUMINIUM_FILTER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_ALUMINIUM_FILTER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRYER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRYER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COMBINATION_FURNITURE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COMBINATION_FURNITURE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_SEALING = "DEVICE_TYPE_KITCHEN_EQUIPMENT_SEALING",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_VENTILATION_GRILLE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_VENTILATION_GRILLE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT = "DEVICE_TYPE_KITCHEN_EQUIPMENT",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_CHROME_STEEL = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_CHROME_STEEL",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GRANITE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GRANITE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GLASS = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GLASS",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_SYNTHETIC_RESIN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_SYNTHETIC_RESIN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_WOODEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_WOODEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET = "DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_HIGH_GLOSS = "DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_HIGH_GLOSS",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_WOOD = "DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_WOOD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET = "DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_HIGH_GLOSS = "DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_HIGH_GLOSS",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_WOOD = "DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_WOOD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_CUTLERY = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_CUTLERY",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_BOTTLE_HOLDER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_BOTTLE_HOLDER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_SLIPS_MAT = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_SLIPS_MAT",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_CHROME_STEEL = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_CHROME_STEEL",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_PLASTIC = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_PLASTIC",
  DEVICE_TYPE_OTHER_SANITARY = "DEVICE_TYPE_OTHER_SANITARY",
  DEVICE_TYPE_ELEVATOR_HANDICAP_LIFT = "DEVICE_TYPE_ELEVATOR_HANDICAP_LIFT",
  DEVICE_TYPE_ELEVATOR_BED_LIFT = "DEVICE_TYPE_ELEVATOR_BED_LIFT",
  DEVICE_TYPE_ELEVATOR = "DEVICE_TYPE_ELEVATOR",
  DEVICE_TYPE_ELEVATOR_MERCHANDISE_LIFT = "DEVICE_TYPE_ELEVATOR_MERCHANDISE_LIFT",
  DEVICE_TYPE_MOVING_STAIRCASE = "DEVICE_TYPE_MOVING_STAIRCASE",
  DEVICE_TYPE_FACADE_CLEANING = "DEVICE_TYPE_FACADE_CLEANING",
  DEVICE_TYPE_GOODS_CONVEYING_OTHER = "DEVICE_TYPE_GOODS_CONVEYING_OTHER",
  DEVICE_TYPE_GOODS_CONVEYING_PNEUMATIC_TUBE = "DEVICE_TYPE_GOODS_CONVEYING_PNEUMATIC_TUBE",
  DEVICE_TYPE_GOODS_CONVEYING_FILE_LIFT = "DEVICE_TYPE_GOODS_CONVEYING_FILE_LIFT",
  DEVICE_TYPE_GOODS_CONVEYING = "DEVICE_TYPE_GOODS_CONVEYING",
  DEVICE_TYPE_LIFTING = "DEVICE_TYPE_LIFTING",
  DEVICE_TYPE_PARKING = "DEVICE_TYPE_PARKING",
  DEVICE_TYPE_TRANSPORT_OTHER = "DEVICE_TYPE_TRANSPORT_OTHER",
  DEVICE_TYPE_PLASTERER = "DEVICE_TYPE_PLASTERER",
  DEVICE_TYPE_METAL = "DEVICE_TYPE_METAL",
  DEVICE_TYPE_METAL_CAR_SHELTER = "DEVICE_TYPE_METAL_CAR_SHELTER",
  DEVICE_TYPE_METAL_MAILBOX = "DEVICE_TYPE_METAL_MAILBOX",
  DEVICE_TYPE_METAL_BALCONY_ENLARGEMENT = "DEVICE_TYPE_METAL_BALCONY_ENLARGEMENT",
  DEVICE_TYPE_CARPENTER_SCREED_EXTENSION_LADDER = "DEVICE_TYPE_CARPENTER_SCREED_EXTENSION_LADDER",
  DEVICE_TYPE_CARPENTER_CELLAR_DOOR = "DEVICE_TYPE_CARPENTER_CELLAR_DOOR",
  DEVICE_TYPE_CARPENTER_APARTMENT_DOOR = "DEVICE_TYPE_CARPENTER_APARTMENT_DOOR",
  DEVICE_TYPE_CARPENTER_DOOR = "DEVICE_TYPE_CARPENTER_DOOR",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME = "DEVICE_TYPE_CARPENTER_DOOR_FRAME",
  DEVICE_TYPE_CARPENTER_LOCKS = "DEVICE_TYPE_CARPENTER_LOCKS",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS",
  DEVICE_TYPE_CARPENTER_SLIDING_DOOR = "DEVICE_TYPE_CARPENTER_SLIDING_DOOR",
  DEVICE_TYPE_CARPENTER = "DEVICE_TYPE_CARPENTER",
  DEVICE_TYPE_CARPENTER_DOOR_WOODEN = "DEVICE_TYPE_CARPENTER_DOOR_WOODEN",
  DEVICE_TYPE_CARPENTER_DOOR_WOOD_MATERIAL = "DEVICE_TYPE_CARPENTER_DOOR_WOOD_MATERIAL",
  DEVICE_TYPE_CARPENTER_DOOR_METAL = "DEVICE_TYPE_CARPENTER_DOOR_METAL",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_WOOD = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_WOOD",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL_STONE = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL_STONE",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL",
  DEVICE_TYPE_CARPENTER_LOCKS_APARTMENT_DOOR = "DEVICE_TYPE_CARPENTER_LOCKS_APARTMENT_DOOR",
  DEVICE_TYPE_CARPENTER_LOCKS_ROOM_DOOR = "DEVICE_TYPE_CARPENTER_LOCKS_ROOM_DOOR",
  DEVICE_TYPE_CARPENTER_LOCKS_KITT_JOINT = "DEVICE_TYPE_CARPENTER_LOCKS_KITT_JOINT",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_CHANNELBOARDS = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_CHANNELBOARDS",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_WOODEN = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_WOODEN",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_HIGH_GLOSS = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_HIGH_GLOSS",
  DEVICE_TYPE_CARPENTER_SLIDING_DOOR_WOODEN_MATERIAL = "DEVICE_TYPE_CARPENTER_SLIDING_DOOR_WOODEN_MATERIAL",
  DEVICE_TYPE_CARPENTER_SLIDING_DOOR_ROLLS = "DEVICE_TYPE_CARPENTER_SLIDING_DOOR_ROLLS",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_COLOR_OIL = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_COLOR_OIL",
  DEVICE_TYPE_CARPENTER_GLASS_INSERT_DOOR = "DEVICE_TYPE_CARPENTER_GLASS_INSERT_DOOR",
  DEVICE_TYPE_CARPENTER_FITTINGS_FOR_DOOR = "DEVICE_TYPE_CARPENTER_FITTINGS_FOR_DOOR",
  DEVICE_TYPE_CARPENTER_RUBBER_SEALS_DOOR = "DEVICE_TYPE_CARPENTER_RUBBER_SEALS_DOOR",
  DEVICE_TYPE_CARPENTER_SHELF = "DEVICE_TYPE_CARPENTER_SHELF",
  DEVICE_TYPE_CARPENTER_SHELF_WOOD = "DEVICE_TYPE_CARPENTER_SHELF_WOOD",
  DEVICE_TYPE_CARPENTER_SHELF_METAL = "DEVICE_TYPE_CARPENTER_SHELF_METAL",
  DEVICE_TYPE_CARPENTER_CLOSET = "DEVICE_TYPE_CARPENTER_CLOSET",
  DEVICE_TYPE_CARPENTER_CLOSET_WOOD = "DEVICE_TYPE_CARPENTER_CLOSET_WOOD",
  DEVICE_TYPE_CARPENTER_CLOSET_METAL = "DEVICE_TYPE_CARPENTER_CLOSET_METAL",
  DEVICE_TYPE_WINDOW_DOUBLE_GLAZING = "DEVICE_TYPE_WINDOW_DOUBLE_GLAZING",
  DEVICE_TYPE_WINDOW_INSULATING_GLAZING = "DEVICE_TYPE_WINDOW_INSULATING_GLAZING",
  DEVICE_TYPE_WINDOW_FITTINGS = "DEVICE_TYPE_WINDOW_FITTINGS",
  DEVICE_TYPE_WINDOW_WINDOW_FRAME = "DEVICE_TYPE_WINDOW_WINDOW_FRAME",
  DEVICE_TYPE_WINDOW_SPECIAL_GLAZING = "DEVICE_TYPE_WINDOW_SPECIAL_GLAZING",
  DEVICE_TYPE_WINDOW_WINDOW_SILL = "DEVICE_TYPE_WINDOW_WINDOW_SILL",
  DEVICE_TYPE_WINDOW = "DEVICE_TYPE_WINDOW",
  DEVICE_TYPE_WINDOW_DOUBLE = "DEVICE_TYPE_WINDOW_DOUBLE",
  DEVICE_TYPE_WINDOW_TRIPLE = "DEVICE_TYPE_WINDOW_TRIPLE",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD = "DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD_PAINTED = "DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD_PAINTED",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_TILES = "DEVICE_TYPE_WINDOW_WINDOW_SILL_TILES",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_STONE = "DEVICE_TYPE_WINDOW_WINDOW_SILL_STONE",
  DEVICE_TYPE_LOCKING_LOCK = "DEVICE_TYPE_LOCKING_LOCK",
  DEVICE_TYPE_LOCKING_KEY = "DEVICE_TYPE_LOCKING_KEY",
  DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER = "DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER",
  DEVICE_TYPE_LOCKING = "DEVICE_TYPE_LOCKING",
  DEVICE_TYPE_INTERIOR_FINISHES = "DEVICE_TYPE_INTERIOR_FINISHES",
  DEVICE_TYPE_ELEMENT_WALL = "DEVICE_TYPE_ELEMENT_WALL",
  DEVICE_TYPE_CHROME_STEEP_FINISH = "DEVICE_TYPE_CHROME_STEEP_FINISH",
  DEVICETYPEOTHERINTERIOR1 = "DEVICE_TYPE_OTHER_INTERIOR1",
  DEVICE_TYPE_FLOORING_COVERING = "DEVICE_TYPE_FLOORING_COVERING",
  DEVICE_TYPE_FLOORING_BALL_YARN = "DEVICE_TYPE_FLOORING_BALL_YARN",
  DEVICE_TYPE_FLOORING_LAMINATE = "DEVICE_TYPE_FLOORING_LAMINATE",
  DEVICE_TYPE_FLOORING_LINOLEUM = "DEVICE_TYPE_FLOORING_LINOLEUM",
  DEVICE_TYPE_FLOORING_VINYL = "DEVICE_TYPE_FLOORING_VINYL",
  DEVICE_TYPE_FLOORING_DIRT_TRAP_CARPET = "DEVICE_TYPE_FLOORING_DIRT_TRAP_CARPET",
  DEVICE_TYPE_FLOORING_PARQUET = "DEVICE_TYPE_FLOORING_PARQUET",
  DEVICE_TYPE_FLOORING_PARQUET_SEALING = "DEVICE_TYPE_FLOORING_PARQUET_SEALING",
  DEVICE_TYPE_FLOORING_FLOOR_UNDERLAY = "DEVICE_TYPE_FLOORING_FLOOR_UNDERLAY",
  DEVICE_TYPE_FLOORING_INSTALLATION_FLOOR = "DEVICE_TYPE_FLOORING_INSTALLATION_FLOOR",
  DEVICE_TYPE_FLOORING_TILES = "DEVICE_TYPE_FLOORING_TILES",
  DEVICE_TYPE_FLOORING_CARPET = "DEVICE_TYPE_FLOORING_CARPET",
  DEVICE_TYPE_FLOORING_SKIRTING = "DEVICE_TYPE_FLOORING_SKIRTING",
  DEVICE_TYPE_FLOORING_JOINT = "DEVICE_TYPE_FLOORING_JOINT",
  DEVICE_TYPE_FLOORING = "DEVICE_TYPE_FLOORING",
  DEVICE_TYPE_FLOORING_PVC = "DEVICE_TYPE_FLOORING_PVC",
  DEVICE_TYPE_FLOORING_RUBBER = "DEVICE_TYPE_FLOORING_RUBBER",
  DEVICE_TYPE_FLOORING_CORK = "DEVICE_TYPE_FLOORING_CORK",
  DEVICE_TYPE_FLOORING_LAMINATE_MEDIUM_QUALITY = "DEVICE_TYPE_FLOORING_LAMINATE_MEDIUM_QUALITY",
  DEVICE_TYPE_FLOORING_LAMINATE_HIGH_QUALITY = "DEVICE_TYPE_FLOORING_LAMINATE_HIGH_QUALITY",
  DEVICE_TYPE_FLOORING_TILES_SOUND_PLATES = "DEVICE_TYPE_FLOORING_TILES_SOUND_PLATES",
  DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_SOFT = "DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_SOFT",
  DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_HARD = "DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_HARD",
  DEVICE_TYPE_FLOORING_TILES_CERAMIC = "DEVICE_TYPE_FLOORING_TILES_CERAMIC",
  DEVICE_TYPE_FLOORING_TILES_STONEWARE = "DEVICE_TYPE_FLOORING_TILES_STONEWARE",
  DEVICE_TYPE_FLOORING_TILES_ARTIFICIAL_STONE = "DEVICE_TYPE_FLOORING_TILES_ARTIFICIAL_STONE",
  DEVICE_TYPE_FLOORING_PARQUET_ADHESIVE = "DEVICE_TYPE_FLOORING_PARQUET_ADHESIVE",
  DEVICE_TYPE_FLOORING_PARQUET_HARDWOOD = "DEVICE_TYPE_FLOORING_PARQUET_HARDWOOD",
  DEVICE_TYPE_FLOORING_PARQUET_SOFTWOOD = "DEVICE_TYPE_FLOORING_PARQUET_SOFTWOOD",
  DEVICE_TYPE_FLOORING_PARQUET_FOURNIER = "DEVICE_TYPE_FLOORING_PARQUET_FOURNIER",
  DEVICE_TYPE_FLOORING_CARPET_NATURAL_FIBER = "DEVICE_TYPE_FLOORING_CARPET_NATURAL_FIBER",
  DEVICE_TYPE_FLOORING_CARPET_BALL_YARN = "DEVICE_TYPE_FLOORING_CARPET_BALL_YARN",
  DEVICE_TYPE_FLOORING_CARPET_NEEDLE_FELT = "DEVICE_TYPE_FLOORING_CARPET_NEEDLE_FELT",
  DEVICE_TYPE_FLOORING_CARPET_MEDIUM_QUALITY = "DEVICE_TYPE_FLOORING_CARPET_MEDIUM_QUALITY",
  DEVICE_TYPE_FLOORING_SKIRTING_PLASTIC = "DEVICE_TYPE_FLOORING_SKIRTING_PLASTIC",
  DEVICE_TYPE_FLOORING_SKIRTING_WOOD = "DEVICE_TYPE_FLOORING_SKIRTING_WOOD",
  DEVICE_TYPE_FLOORING_RAW = "DEVICE_TYPE_FLOORING_RAW",
  DEVICE_TYPE_FLOORING_COATED = "DEVICE_TYPE_FLOORING_COATED",
  DEVICE_TYPE_WALL_COVERING_WORK = "DEVICE_TYPE_WALL_COVERING_WORK",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER = "DEVICE_TYPE_WALL_COVERING_WALLPAPER",
  DEVICE_TYPE_WALL_COVERING_PLASTER = "DEVICE_TYPE_WALL_COVERING_PLASTER",
  DEVICE_TYPE_WALL_COVERING_ABRASION = "DEVICE_TYPE_WALL_COVERING_ABRASION",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER",
  DEVICE_TYPE_WALL_COVERING = "DEVICE_TYPE_WALL_COVERING",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER_MEDIUM_QUALITY = "DEVICE_TYPE_WALL_COVERING_WALLPAPER_MEDIUM_QUALITY",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER_GOOD_QUALITY = "DEVICE_TYPE_WALL_COVERING_WALLPAPER_GOOD_QUALITY",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER_GLASS_FIBER = "DEVICE_TYPE_WALL_COVERING_WALLPAPER_GLASS_FIBER",
  DEVICE_TYPE_WALL_COVERING_PLASTER_GLUE_COLOR = "DEVICE_TYPE_WALL_COVERING_PLASTER_GLUE_COLOR",
  DEVICE_TYPE_WALL_COVERING_PLASTER_ACRYL = "DEVICE_TYPE_WALL_COVERING_PLASTER_ACRYL",
  DEVICE_TYPE_WALL_COVERING_PLASTER_MINERALS = "DEVICE_TYPE_WALL_COVERING_PLASTER_MINERALS",
  DEVICE_TYPE_WALL_COVERING_PLASTER_PLASTIC = "DEVICE_TYPE_WALL_COVERING_PLASTER_PLASTIC",
  DEVICE_TYPE_WALL_COVERING_PLASTER_SILICATE_COATING = "DEVICE_TYPE_WALL_COVERING_PLASTER_SILICATE_COATING",
  DEVICE_TYPE_WALL_COVERING_PLASTER_DISPERSION_PAINT = "DEVICE_TYPE_WALL_COVERING_PLASTER_DISPERSION_PAINT",
  DEVICE_TYPE_WALL_COVERING_ABRASION_PLASTIC = "DEVICE_TYPE_WALL_COVERING_ABRASION_PLASTIC",
  DEVICE_TYPE_WALL_COVERING_ABRASION_ROUGH_PUTT = "DEVICE_TYPE_WALL_COVERING_ABRASION_ROUGH_PUTT",
  DEVICE_TYPE_WALL_COVERING_ABRASION_WHITE_PLASTER = "DEVICE_TYPE_WALL_COVERING_ABRASION_WHITE_PLASTER",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_WALL_ROUGH = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_WALL_ROUGH",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_LASED = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_LASED",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_PAINTED_OPAQUE = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_PAINTED_OPAQUE",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_GLAZE = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_GLAZE",
  DEVICE_TYPE_WALL_COVERING_CERAMIC = "DEVICE_TYPE_WALL_COVERING_CERAMIC",
  DEVICE_TYPE_WALL_COVERING_STONEWORK = "DEVICE_TYPE_WALL_COVERING_STONEWORK",
  DEVICE_TYPE_WALL_COVERING_STONEWARE = "DEVICE_TYPE_WALL_COVERING_STONEWARE",
  DEVICE_TYPE_WALL_COVERING_TILES = "DEVICE_TYPE_WALL_COVERING_TILES",
  DEVICE_TYPE_CEILING_WORK = "DEVICE_TYPE_CEILING_WORK",
  DEVICE_TYPE_CEILING_WALLPAPER = "DEVICE_TYPE_CEILING_WALLPAPER",
  DEVICE_TYPE_CEILING_PLASTER = "DEVICE_TYPE_CEILING_PLASTER",
  DEVICE_TYPE_CEILING_ABRASION = "DEVICE_TYPE_CEILING_ABRASION",
  DEVICE_TYPE_CEILING_WOOD_WORKER = "DEVICE_TYPE_CEILING_WOOD_WORKER",
  DEVICE_TYPE_CEILING = "DEVICE_TYPE_CEILING",
  DEVICE_TYPE_CEILING_REGULAR = "DEVICE_TYPE_CEILING_REGULAR",
  DEVICE_TYPE_CEILING_REGULAR_METAL = "DEVICE_TYPE_CEILING_REGULAR_METAL",
  DEVICE_TYPE_CEILING_REGULAR_WOOD = "DEVICE_TYPE_CEILING_REGULAR_WOOD",
  DEVICE_TYPE_CEILING_COVERING_ABRASION_PLASTIC = "DEVICE_TYPE_CEILING_COVERING_ABRASION_PLASTIC",
  DEVICE_TYPE_CEILING_COVERING_ABRASION_ROUGH_PUTT = "DEVICE_TYPE_CEILING_COVERING_ABRASION_ROUGH_PUTT",
  DEVICE_TYPE_CEILING_COVERING_ABRASION_WHITE_PLASTER = "DEVICE_TYPE_CEILING_COVERING_ABRASION_WHITE_PLASTER",
  DEVICE_TYPE_INTERIOR_SURFACE_TREATMENTS_PAINTING = "DEVICE_TYPE_INTERIOR_SURFACE_TREATMENTS_PAINTING",
  DEVICETYPEOTHERINTERIOR2SHUTTER = "DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER",
  DEVICETYPEOTHERINTERIOR2BLADE = "DEVICE_TYPE_OTHER_INTERIOR2_BLADE",
  DEVICETYPEOTHERINTERIOR2BLIND = "DEVICE_TYPE_OTHER_INTERIOR2_BLIND",
  DEVICETYPEOTHERINTERIOR2LETTERBOX = "DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX",
  DEVICETYPEOTHERINTERIOR2 = "DEVICE_TYPE_OTHER_INTERIOR2",
  DEVICETYPEOTHERINTERIOR2SETS = "DEVICE_TYPE_OTHER_INTERIOR2_SETS",
  DEVICETYPEOTHERINTERIOR2SETSSOAPHOLDER = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_SOAP_HOLDER",
  DEVICETYPEOTHERINTERIOR2SETSTOOTHGLASS = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOOTH_GLASS",
  DEVICETYPEOTHERINTERIOR2SETSGLASSSHELF = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_GLASS_SHELF",
  DEVICETYPEOTHERINTERIOR2SETSTOWELBARS = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOWEL_BARS",
  DEVICETYPEOTHERINTERIOR2SETSHOLDINGRACK = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_HOLDING_RACK",
  DEVICETYPEOTHERINTERIOR2SETSCURTAINROD = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_CURTAIN_ROD",
  DEVICETYPEOTHERINTERIOR2SETSPACKAGE = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PACKAGE",
  DEVICETYPEOTHERINTERIOR2SETSWASHBASINBOWL = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_WASHBASIN_BOWL",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDER = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDERCHROMESTEEL = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_CHROME_STEEL",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDERPLASTIC = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_PLASTIC",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDERHOOK = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_HOOK",
  DEVICETYPEOTHERINTERIOR2SETSPAPERHOLDER = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER",
  DEVICETYPEOTHERINTERIOR2SETSPAPERHOLDERCHROMESTEEL = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER_CHROME_STEEL",
  DEVICETYPEOTHERINTERIOR2SETSPAPERHOLDERPLASTIC = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER_PLASTIC",
  DEVICETYPEOTHERINTERIOR2ARMATURE = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE",
  DEVICETYPEOTHERINTERIOR2ARMATURESINGLELEVER = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_SINGLE_LEVER",
  DEVICETYPEOTHERINTERIOR2DOUBLELEVER = "DEVICE_TYPE_OTHER_INTERIOR2_DOUBLE_LEVER",
  DEVICETYPEOTHERINTERIOR2ARMATURESWIVEL = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_SWIVEL",
  DEVICETYPEOTHERINTERIOR2ARMATUREPULLOUTSPRAY = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_PULL_OUT_SPRAY",
  DEVICETYPEOTHERINTERIOR2ARMATURETHERMALMIXER = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_THERMAL_MIXER",
  DEVICETYPEOTHERINTERIOR2OPERATINGGARNITURE = "DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE",
  DEVICETYPEOTHERINTERIOR2CRANK = "DEVICE_TYPE_OTHER_INTERIOR2_CRANK",
  DEVICETYPEOTHERINTERIOR2BELT = "DEVICE_TYPE_OTHER_INTERIOR2_BELT",
  DEVICETYPEOTHERINTERIOR2ELECTRIC = "DEVICE_TYPE_OTHER_INTERIOR2_ELECTRIC",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_GREEN = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_GREEN",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_BLACK = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_BLACK",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_METAL = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_METAL",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_OUTDOOR_PARKING = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_OUTDOOR_PARKING",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_PLANTING = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_PLANTING",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_CONTAINER_SITE = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_CONTAINER_SITE",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_BIKE_SHELTER = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_BIKE_SHELTER",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_ACCESS_PATH = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_ACCESS_PATH",
  DEVICE_TYPE_GARDENING_FENCE = "DEVICE_TYPE_GARDENING_FENCE",
  DEVICE_TYPE_GARDENING_WALLS = "DEVICE_TYPE_GARDENING_WALLS",
  DEVICE_TYPE_GARDENING_CEMENT_BOARD = "DEVICE_TYPE_GARDENING_CEMENT_BOARD",
  DEVICE_TYPE_GARDENING_ACCESS_PATH = "DEVICE_TYPE_GARDENING_ACCESS_PATH",
  DEVICE_TYPE_GARDENING_LAWN = "DEVICE_TYPE_GARDENING_LAWN",
  DEVICE_TYPE_GARDENING = "DEVICE_TYPE_GARDENING",
  DEVICE_TYPE_FENCING = "DEVICE_TYPE_FENCING",
  DEVICE_TYPE_SUPERSTRUCTURE_TERRANCE = "DEVICE_TYPE_SUPERSTRUCTURE_TERRANCE",
  DEVICE_TYPE_SUPERSTRUCTURE_BIKE_STAND = "DEVICE_TYPE_SUPERSTRUCTURE_BIKE_STAND",
  DEVICE_TYPE_SUPERSTRUCTURE = "DEVICE_TYPE_SUPERSTRUCTURE",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_SAFETY_GLASS = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_SAFETY_GLASS",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_METAL = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_METAL",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CHROME_STEEL = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CHROME_STEEL",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CONCRETE = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CONCRETE",
  DEVICE_TYPE_PLAYGROUND = "DEVICE_TYPE_PLAYGROUND",
  DEVICE_TYPE_PLAYGROUND_DEVICE = "DEVICE_TYPE_PLAYGROUND_DEVICE",
  DEVICE_TYPE_GARDEN_OTHER = "DEVICE_TYPE_GARDEN_OTHER",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_FIRE_EXTINGUISHER = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_FIRE_EXTINGUISHER",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_LAWN_MOWER = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_LAWN_MOWER",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_SECURITY_CAMERA = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_SECURITY_CAMERA",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_INDOOR_BIKE_SHELTER = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_INDOOR_BIKE_SHELTER",
}

export type DeviceServiceGetDefaultDataData = DeviceGetDefaultDataResponse;

export type DeviceServiceGetDefaultDataError = GooglerpcStatus;

/**
 * Optional. If not provided the whole list for all device types will be returned.
 *
 *  - DEVICE_TYPE_CHROME_STEEP_FINISH: Not used
 * @default "DEVICE_TYPE_UNDEFINED"
 */
export enum DeviceServiceGetDefaultDataParams1TypeEnum {
  DEVICE_TYPE_UNDEFINED = "DEVICE_TYPE_UNDEFINED",
  DEVICE_TYPE_MASTER_BUILDER_WORK_DOWN_PIPES = "DEVICE_TYPE_MASTER_BUILDER_WORK_DOWN_PIPES",
  DEVICE_TYPE_MASTER_BUILDER_WORK_BASIC_LINE = "DEVICE_TYPE_MASTER_BUILDER_WORK_BASIC_LINE",
  DEVICE_TYPE_MASTER_BUILDER_WORK_DRAINAGE_LINE = "DEVICE_TYPE_MASTER_BUILDER_WORK_DRAINAGE_LINE",
  DEVICE_TYPE_MASTER_BUILDER_WORK_PUMP_CHAMBER = "DEVICE_TYPE_MASTER_BUILDER_WORK_PUMP_CHAMBER",
  DEVICE_TYPE_MASTER_BUILDER_WORK_TERRACE_DRAIN = "DEVICE_TYPE_MASTER_BUILDER_WORK_TERRACE_DRAIN",
  DEVICE_TYPE_WINDOW_DOOR_AUTOMATIC_SLIDING_DOOR = "DEVICE_TYPE_WINDOW_DOOR_AUTOMATIC_SLIDING_DOOR",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR",
  DEVICE_TYPE_WINDOW_DOOR_FIRE_GATE = "DEVICE_TYPE_WINDOW_DOOR_FIRE_GATE",
  DEVICE_TYPE_WINDOW_DOOR_ROOF_WINDOW = "DEVICE_TYPE_WINDOW_DOOR_ROOF_WINDOW",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW = "DEVICE_TYPE_WINDOW_DOOR_WINDOW",
  DEVICE_TYPE_WINDOW_DOOR_GATE_DRIVE = "DEVICE_TYPE_WINDOW_DOOR_GATE_DRIVE",
  DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR = "DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR",
  DEVICE_TYPE_WINDOW_DOOR_DOOR_AUTOMATION = "DEVICE_TYPE_WINDOW_DOOR_DOOR_AUTOMATION",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_SOLID_WOOD = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_SOLID_WOOD",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_WOOD_MATERIAL = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_WOOD_MATERIAL",
  DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_METAL = "DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_METAL",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_DOUBLE_GLAZING = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_DOUBLE_GLAZING",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_PLASTIC = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_PLASTIC",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_METAL = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_METAL",
  DEVICE_TYPE_WINDOW_DOOR_WINDOW_METAL = "DEVICE_TYPE_WINDOW_DOOR_WINDOW_METAL",
  DEVICE_TYPE_ROOFING_PENT_ROOF = "DEVICE_TYPE_ROOFING_PENT_ROOF",
  DEVICE_TYPE_ROOFING_SADDLE_ROOF = "DEVICE_TYPE_ROOFING_SADDLE_ROOF",
  DEVICE_TYPE_ROOFING_TERRACE = "DEVICE_TYPE_ROOFING_TERRACE",
  DEVICE_TYPE_ROOFING_HIPPED_ROOF = "DEVICE_TYPE_ROOFING_HIPPED_ROOF",
  DEVICE_TYPE_ROOFING_TENT_ROOF = "DEVICE_TYPE_ROOFING_TENT_ROOF",
  DEVICE_TYPE_ROOFING_FLAT_ROOF = "DEVICE_TYPE_ROOFING_FLAT_ROOF",
  DEVICE_TYPE_ROOFING_SHEARING_ROOF = "DEVICE_TYPE_ROOFING_SHEARING_ROOF",
  DEVICE_TYPE_ROOFING_FRONT_PANEL = "DEVICE_TYPE_ROOFING_FRONT_PANEL",
  DEVICE_TYPE_SEALING_INSULATION_FACADE = "DEVICE_TYPE_SEALING_INSULATION_FACADE",
  DEVICE_TYPE_SEALING_INSULATION_INTERIOR = "DEVICE_TYPE_SEALING_INSULATION_INTERIOR",
  DEVICE_TYPE_SEALING_INSULATION_FACADE_WOOD = "DEVICE_TYPE_SEALING_INSULATION_FACADE_WOOD",
  DEVICE_TYPE_SEALING_INSULATION_FACADE_PLATES = "DEVICE_TYPE_SEALING_INSULATION_FACADE_PLATES",
  DEVICE_TYPE_SEALING_INSULATION_FACADE_ETERNIT = "DEVICE_TYPE_SEALING_INSULATION_FACADE_ETERNIT",
  DEVICE_TYPE_FACADE_PLASTER_PAINTING = "DEVICE_TYPE_FACADE_PLASTER_PAINTING",
  DEVICE_TYPE_FACADE_PLASTER = "DEVICE_TYPE_FACADE_PLASTER",
  DEVICE_TYPE_OUTER_FINISHES_FOLDING_SHUTTERS = "DEVICE_TYPE_OUTER_FINISHES_FOLDING_SHUTTERS",
  DEVICE_TYPE_OUTER_FINISHES_SHUTTERS = "DEVICE_TYPE_OUTER_FINISHES_SHUTTERS",
  DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS = "DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS",
  DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS_VOLANT = "DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS_VOLANT",
  DEVICE_TYPE_OUTER_FINISHES_GLARE_PROTECTION = "DEVICE_TYPE_OUTER_FINISHES_GLARE_PROTECTION",
  DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_WOOD = "DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_WOOD",
  DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_METAL = "DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_METAL",
  DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS = "DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS",
  DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_OUTSIDE_ALUMINIUM = "DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_OUTSIDE_ALUMINIUM",
  DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_INSIDE = "DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_INSIDE",
  DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS = "DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS",
  DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_WOOD = "DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_WOOD",
  DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_METAL = "DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_METAL",
  DEVICE_TYPE_OUTER_FINISHES = "DEVICE_TYPE_OUTER_FINISHES",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_GLASS = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_GLASS",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_WOOD = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_WOOD",
  DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_METAL = "DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_METAL",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LIGHTING = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LIGHTING",
  DEVICETYPEHEAVYCURRENTINSTALLATIONCO2 = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CO2",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SINA = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SINA",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SMOKE_VENT = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SMOKE_VENT",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PROOF_OF_SAFETY_SINA = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PROOF_OF_SAFETY_SINA",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHGEAR_COMBINATION = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHGEAR_COMBINATION",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PHOTOVOLTAIC = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PHOTOVOLTAIC",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LOW_VOLTAGE_SYSTEM = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LOW_VOLTAGE_SYSTEM",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CABLE = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CABLE",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCH = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCH",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHBOARD = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHBOARD",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SOCKET = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SOCKET",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_UPS = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_UPS",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_INFRASTRUCTURE_ELECTRIC_CHARGING = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_INFRASTRUCTURE_ELECTRIC_CHARGING",
  DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_ELECTRIC_CHARGING_STATION = "DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_ELECTRIC_CHARGING_STATION",
  DEVICE_TYPE_LIGHTS_ILLUMINANT = "DEVICE_TYPE_LIGHTS_ILLUMINANT",
  DEVICE_TYPE_LIGHTS = "DEVICE_TYPE_LIGHTS",
  DEVICE_TYPE_LIGHTS_CEILING = "DEVICE_TYPE_LIGHTS_CEILING",
  DEVICE_TYPE_LIGHTS_CABINET = "DEVICE_TYPE_LIGHTS_CABINET",
  DEVICE_TYPE_LIGHTS_SPOTS = "DEVICE_TYPE_LIGHTS_SPOTS",
  DEVICE_TYPE_LIGHTS_MIRROR_CABINET = "DEVICE_TYPE_LIGHTS_MIRROR_CABINET",
  DEVICE_TYPE_TELEPHONE_CENTER = "DEVICE_TYPE_TELEPHONE_CENTER",
  DEVICE_TYPE_TELEPHONE_DISTRIBUTOR = "DEVICE_TYPE_TELEPHONE_DISTRIBUTOR",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM_VIDEO = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM_VIDEO",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_BELL = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_BELL",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_DOOR_OPENER = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_DOOR_OPENER",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_FIBERGLASS = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_FIBERGLASS",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_EMERGENCY_PHONE_LIFT = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_EMERGENCY_PHONE_LIFT",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTRUSION_DETECTION = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTRUSION_DETECTION",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_ISDN = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_ISDN",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_CABLE = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_CABLE",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCHBOARD = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCHBOARD",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SOCKET = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SOCKET",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_ANTENNA = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_ANTENNA",
  DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_CABLE = "DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_CABLE",
  DEVICE_TYPE_OTHER_ELECTRICITY_BATTERY = "DEVICE_TYPE_OTHER_ELECTRICITY_BATTERY",
  DEVICE_TYPE_OTHER_ELECTRICITY_OTHER = "DEVICE_TYPE_OTHER_ELECTRICITY_OTHER",
  DEVICE_TYPE_OTHER_ELECTRICITY_LIGHTNING_PROTECTION = "DEVICE_TYPE_OTHER_ELECTRICITY_LIGHTNING_PROTECTION",
  DEVICE_TYPE_OTHER_ELECTRICITY = "DEVICE_TYPE_OTHER_ELECTRICITY",
  DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET = "DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_ROOF_VENTILATION = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_ROOF_VENTILATION",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_FILTER_REPLACEMENT = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_FILTER_REPLACEMENT",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_AIR_CONDITIONING = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_AIR_CONDITIONING",
  DEVICE_TYPE_HEATING_TRANSITION_POSITION_VENTILATION = "DEVICE_TYPE_HEATING_TRANSITION_POSITION_VENTILATION",
  DEVICE_TYPE_ENERGY_SUPPLY_SUPPLY = "DEVICE_TYPE_ENERGY_SUPPLY_SUPPLY",
  DEVICE_TYPE_ENERGY_SUPPLY_LEAKAGE_WARNING = "DEVICE_TYPE_ENERGY_SUPPLY_LEAKAGE_WARNING",
  DEVICE_TYPE_ENERGY_SUPPLY_TANK = "DEVICE_TYPE_ENERGY_SUPPLY_TANK",
  DEVICE_TYPE_ENERGY_SUPPLY_STORAGE = "DEVICE_TYPE_ENERGY_SUPPLY_STORAGE",
  DEVICE_TYPE_ENERGY_SUPPLY_GAS_LINE = "DEVICE_TYPE_ENERGY_SUPPLY_GAS_LINE",
  DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_INSIDE = "DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_INSIDE",
  DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_EARTH_LOADED = "DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_EARTH_LOADED",
  DEVICE_TYPE_HEAT_GENERATION = "DEVICE_TYPE_HEAT_GENERATION",
  DEVICE_TYPE_HEAT_GENERATION_BOILER = "DEVICE_TYPE_HEAT_GENERATION_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_BURNER = "DEVICE_TYPE_HEAT_GENERATION_BURNER",
  DEVICE_TYPE_HEAT_GENERATION_EARTH_PROBE = "DEVICE_TYPE_HEAT_GENERATION_EARTH_PROBE",
  DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STORAGE_STOVE = "DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STORAGE_STOVE",
  DEVICE_TYPE_HEAT_GENERATION_EXPANSION_DEVICE = "DEVICE_TYPE_HEAT_GENERATION_EXPANSION_DEVICE",
  DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING = "DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING",
  DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING_SUBSTATION = "DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING_SUBSTATION",
  DEVICE_TYPE_HEAT_GENERATION_HEATING_BOILER = "DEVICE_TYPE_HEAT_GENERATION_HEATING_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_THERMAL_SOLAR_SYSTEM = "DEVICE_TYPE_HEAT_GENERATION_THERMAL_SOLAR_SYSTEM",
  DEVICE_TYPE_HEAT_GENERATION_HEAT_EXCHANGER = "DEVICE_TYPE_HEAT_GENERATION_HEAT_EXCHANGER",
  DEVICE_TYPE_HEAT_GENERATION_HEAT_PUMP = "DEVICE_TYPE_HEAT_GENERATION_HEAT_PUMP",
  DEVICE_TYPE_HEAT_GENERATION_FIREPLACE = "DEVICE_TYPE_HEAT_GENERATION_FIREPLACE",
  DEVICE_TYPE_HEAT_GENERATION_HEAT_ACCUMULATOR = "DEVICE_TYPE_HEAT_GENERATION_HEAT_ACCUMULATOR",
  DEVICE_TYPE_HEAT_GENERATION_SINGLE_WATER_BOILER = "DEVICE_TYPE_HEAT_GENERATION_SINGLE_WATER_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_COMBIBOILER_HEATING = "DEVICE_TYPE_HEAT_GENERATION_COMBIBOILER_HEATING",
  DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_BOILER = "DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_BOILER_GAS_APPLIANCES = "DEVICE_TYPE_HEAT_GENERATION_BOILER_GAS_APPLIANCES",
  DEVICE_TYPE_HEAT_GENERATION_COMBINED_BOILER = "DEVICE_TYPE_HEAT_GENERATION_COMBINED_BOILER",
  DEVICE_TYPE_HEAT_GENERATION_SOLAR_PANELS = "DEVICE_TYPE_HEAT_GENERATION_SOLAR_PANELS",
  DEVICE_TYPE_HEAT_GENERATION_RADIATORS = "DEVICE_TYPE_HEAT_GENERATION_RADIATORS",
  DEVICE_TYPE_HEAT_GENERATION_RADIATORS_HEATWALLS = "DEVICE_TYPE_HEAT_GENERATION_RADIATORS_HEATWALLS",
  DEVICE_TYPE_HEAT_GENERATION_TOWEL_RADIATORS = "DEVICE_TYPE_HEAT_GENERATION_TOWEL_RADIATORS",
  DEVICE_TYPE_HEAT_GENERATION_FLOOR_HEATING = "DEVICE_TYPE_HEAT_GENERATION_FLOOR_HEATING",
  DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT = "DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT",
  DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STOVE = "DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STOVE",
  DEVICE_TYPE_HEAT_GENERATION_ROOF_HEATING = "DEVICE_TYPE_HEAT_GENERATION_ROOF_HEATING",
  DEVICE_TYPE_HEAT_DISTRIBUTION = "DEVICE_TYPE_HEAT_DISTRIBUTION",
  DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_METER = "DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_METER",
  DEVICE_TYPE_HEAT_DISTRIBUTION_CIRCULATING_PUMP = "DEVICE_TYPE_HEAT_DISTRIBUTION_CIRCULATING_PUMP",
  DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_QUANTITY_FLOW_METERS = "DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_QUANTITY_FLOW_METERS",
  DEVICE_TYPE_HEAT_DISTRIBUTION_HEATING_COST_ALLOCATOR = "DEVICE_TYPE_HEAT_DISTRIBUTION_HEATING_COST_ALLOCATOR",
  DEVICE_TYPE_VENTILATION_VENTILATION_CONTROL_CENTER = "DEVICE_TYPE_VENTILATION_VENTILATION_CONTROL_CENTER",
  DEVICE_TYPE_VENTILATION_VENTILATION = "DEVICE_TYPE_VENTILATION_VENTILATION",
  DEVICE_TYPE_VENTILATION_SUPPLY_AIR_FAN = "DEVICE_TYPE_VENTILATION_SUPPLY_AIR_FAN",
  DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN = "DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN",
  DEVICE_TYPE_VENTILATION_SMOKE_EXTRACTION_SYSTEM = "DEVICE_TYPE_VENTILATION_SMOKE_EXTRACTION_SYSTEM",
  DEVICE_TYPE_VENTILATION = "DEVICE_TYPE_VENTILATION",
  DEVICE_TYPE_VENTILATION_CONTROLLED_HOME = "DEVICE_TYPE_VENTILATION_CONTROLLED_HOME",
  DEVICE_TYPE_VENTILATION_CHANNELS = "DEVICE_TYPE_VENTILATION_CHANNELS",
  DEVICE_TYPE_AIR_CONDITIONING_RECOOLING = "DEVICE_TYPE_AIR_CONDITIONING_RECOOLING",
  DEVICE_TYPE_AIR_CONDITIONING = "DEVICE_TYPE_AIR_CONDITIONING",
  DEVICE_TYPE_AIR_CONDITIONING_INDIVIDUAL_ROOMS = "DEVICE_TYPE_AIR_CONDITIONING_INDIVIDUAL_ROOMS",
  DEVICE_TYPE_COOLING_CHILLERS = "DEVICE_TYPE_COOLING_CHILLERS",
  DEVICE_TYPE_COOLING_COLD_RESERVOIR = "DEVICE_TYPE_COOLING_COLD_RESERVOIR",
  DEVICE_TYPE_COOLING_COLD_WATER_TANK = "DEVICE_TYPE_COOLING_COLD_WATER_TANK",
  DEVICE_TYPE_COOLING_COLD_STORAGE_CELL = "DEVICE_TYPE_COOLING_COLD_STORAGE_CELL",
  DEVICE_TYPE_COOLING = "DEVICE_TYPE_COOLING",
  DEVICE_TYPE_SPECIAL_FACILITY = "DEVICE_TYPE_SPECIAL_FACILITY",
  DEVICE_TYPE_INSULATION = "DEVICE_TYPE_INSULATION",
  DEVICE_TYPE_INSULATION_CHIMNEY = "DEVICE_TYPE_INSULATION_CHIMNEY",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_BUILDING_MANAGEMENT_SYSTEM = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_BUILDING_MANAGEMENT_SYSTEM",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_CONTROL_SYSTEM = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_CONTROL_SYSTEM",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY",
  DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_VALVES = "DEVICE_TYPE_SPECIAL_FACILITY_MEASUREMENT_CONTROL_TECHNOLOGY_VALVES",
  DEVICE_TYPE_SANITARY_BATHTUB = "DEVICE_TYPE_SANITARY_BATHTUB",
  DEVICE_TYPE_SANITARY_RENOVATION = "DEVICE_TYPE_SANITARY_RENOVATION",
  DEVICE_TYPE_SANITARY_CONTROL_FITTINGS = "DEVICE_TYPE_SANITARY_CONTROL_FITTINGS",
  DEVICE_TYPE_SANITARY_WASHBASIN = "DEVICE_TYPE_SANITARY_WASHBASIN",
  DEVICE_TYPE_SANITARY_WASHBASIN_CERAMIC = "DEVICE_TYPE_SANITARY_WASHBASIN_CERAMIC",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET = "DEVICE_TYPE_SANITARY_MIRROR_CABINET",
  DEVICE_TYPE_SANITARY_BIDET = "DEVICE_TYPE_SANITARY_BIDET",
  DEVICE_TYPE_SANITARY_SHOWER = "DEVICE_TYPE_SANITARY_SHOWER",
  DEVICE_TYPE_SANITARY_LAVABO = "DEVICE_TYPE_SANITARY_LAVABO",
  DEVICE_TYPE_SANITARY_URINAL = "DEVICE_TYPE_SANITARY_URINAL",
  DEVICE_TYPE_SANITARY_FLUSH_TANK = "DEVICE_TYPE_SANITARY_FLUSH_TANK",
  DEVICE_TYPE_SANITARY_TOILET = "DEVICE_TYPE_SANITARY_TOILET",
  DEVICE_TYPE_SANITARY_TOILET_CERAMIC = "DEVICE_TYPE_SANITARY_TOILET_CERAMIC",
  DEVICE_TYPE_SANITARY_TOILET_CLOSOMAT = "DEVICE_TYPE_SANITARY_TOILET_CLOSOMAT",
  DEVICE_TYPE_SANITARY_TOILET_COVER = "DEVICE_TYPE_SANITARY_TOILET_COVER",
  DEVICE_TYPE_SANITARY_TOILET_COVER_SOFT_CLOSE = "DEVICE_TYPE_SANITARY_TOILET_COVER_SOFT_CLOSE",
  DEVICE_TYPE_SANITARY_BATHTUB_ACRYLIC = "DEVICE_TYPE_SANITARY_BATHTUB_ACRYLIC",
  DEVICE_TYPE_SANITARY_BATHTUB_STEEL = "DEVICE_TYPE_SANITARY_BATHTUB_STEEL",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET_PLASTIC = "DEVICE_TYPE_SANITARY_MIRROR_CABINET_PLASTIC",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET_WOODEN = "DEVICE_TYPE_SANITARY_MIRROR_CABINET_WOODEN",
  DEVICE_TYPE_SANITARY_MIRROR_CABINET_METAL = "DEVICE_TYPE_SANITARY_MIRROR_CABINET_METAL",
  DEVICE_TYPE_SANITARY_FLUSH_TANK_FLUSH_MOUNTED = "DEVICE_TYPE_SANITARY_FLUSH_TANK_FLUSH_MOUNTED",
  DEVICE_TYPE_SANITARY_FLUSH_TANK_PLASTIC = "DEVICE_TYPE_SANITARY_FLUSH_TANK_PLASTIC",
  DEVICE_TYPE_SANITARY_FLUSH_TANK_CERAMIC = "DEVICE_TYPE_SANITARY_FLUSH_TANK_CERAMIC",
  DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL = "DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL",
  DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CERAMIC = "DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CERAMIC",
  DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CLOSOMAT = "DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CLOSOMAT",
  DEVICE_TYPE_SANITARY_SHOWER_CABIN = "DEVICE_TYPE_SANITARY_SHOWER_CABIN",
  DEVICE_TYPE_SANITARY_SHOWER_CABIN_PLASTIC = "DEVICE_TYPE_SANITARY_SHOWER_CABIN_PLASTIC",
  DEVICE_TYPE_SANITARY_SHOWER_CABIN_GLASWALLS = "DEVICE_TYPE_SANITARY_SHOWER_CABIN_GLASWALLS",
  DEVICE_TYPE_SPECIAL_SANITARY_WATER_SOFTENER = "DEVICE_TYPE_SPECIAL_SANITARY_WATER_SOFTENER",
  DEVICE_TYPE_SPECIAL_SANITARY_LIMESCALE_PROTECTION = "DEVICE_TYPE_SPECIAL_SANITARY_LIMESCALE_PROTECTION",
  DEVICE_TYPE_SPECIAL_SANITARY_DEHUMIDIFIER = "DEVICE_TYPE_SPECIAL_SANITARY_DEHUMIDIFIER",
  DEVICE_TYPE_SPECIAL_SANITARY_WASHING_MACHINE = "DEVICE_TYPE_SPECIAL_SANITARY_WASHING_MACHINE",
  DEVICE_TYPE_SPECIAL_SANITARY_COMBI_WASHER_DRYER = "DEVICE_TYPE_SPECIAL_SANITARY_COMBI_WASHER_DRYER",
  DEVICE_TYPE_SPECIAL_SANITARY_WASHING_ELECTRICITY_METER = "DEVICE_TYPE_SPECIAL_SANITARY_WASHING_ELECTRICITY_METER",
  DEVICE_TYPE_SPECIAL_SANITARY_TUMBLER = "DEVICE_TYPE_SPECIAL_SANITARY_TUMBLER",
  DEVICE_TYPE_SPECIAL_SANITARY_VACUUM_DEGASSING_DEVICE = "DEVICE_TYPE_SPECIAL_SANITARY_VACUUM_DEGASSING_DEVICE",
  DEVICE_TYPE_SPECIAL_SANITARY = "DEVICE_TYPE_SPECIAL_SANITARY",
  DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP = "DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SUPPLY = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SUPPLY",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_DISPOSAL = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_DISPOSAL",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_TREATMENT = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_TREATMENT",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_PUMP = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_PUMP",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_DISTRIBUTION_BATTERY = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_DISTRIBUTION_BATTERY",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_TANK = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_TANK",
  DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_PIPING_COLD_WATER = "DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_PIPING_COLD_WATER",
  DEVICE_TYPE_SANITARY_PIPING_RAIN_WATER = "DEVICE_TYPE_SANITARY_PIPING_RAIN_WATER",
  DEVICE_TYPE_SANITARY_PIPING_WARM_WATER = "DEVICE_TYPE_SANITARY_PIPING_WARM_WATER",
  DEVICE_TYPE_SANITARY_PIPING = "DEVICE_TYPE_SANITARY_PIPING",
  DEVICE_TYPE_SANITARY_INSULATION = "DEVICE_TYPE_SANITARY_INSULATION",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_ONE_DOOR = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_ONE_DOOR",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_TWO_DOOR = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_TWO_DOOR",
  DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR = "DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR",
  DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE = "DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE",
  DEVICE_TYPE_SANITARY_EQUIPMENT_SHOWER_CABIN = "DEVICE_TYPE_SANITARY_EQUIPMENT_SHOWER_CABIN",
  DEVICE_TYPE_SANITARY_EQUIPMENT = "DEVICE_TYPE_SANITARY_EQUIPMENT",
  DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE = "DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE",
  DEVICE_TYPE_SANITARY_SHOWER_ROD = "DEVICE_TYPE_SANITARY_SHOWER_ROD",
  DEVICE_TYPE_SANITARY_SHOWER_ROD_CHROME_STEEL = "DEVICE_TYPE_SANITARY_SHOWER_ROD_CHROME_STEEL",
  DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOLDER = "DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOLDER",
  DEVICE_TYPE_ELECTRIC_PNEUMATIC_BOARD = "DEVICE_TYPE_ELECTRIC_PNEUMATIC_BOARD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR = "DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES = "DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES_COMPARTMENT = "DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES_COMPARTMENT",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_SEPARATING_SYSTEM = "DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_SEPARATING_SYSTEM",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_HOLDER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_HOLDER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_BLECH = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_BLECH",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRID = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRID",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_THERMOMETER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_THERMOMETER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRILL = "DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRILL",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_INDUCTION_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_INDUCTION_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_GLASS_CERAMIC_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_GLASS_CERAMIC_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_HOT_PLATES_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_HOT_PLATES_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_GAS_HOB = "DEVICE_TYPE_KITCHEN_EQUIPMENT_GAS_HOB",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_MINOR_KITCHEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_MINOR_KITCHEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FURNITURE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FURNITURE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_MICROWAVE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_MICROWAVE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_STOVE_WITH_OVEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_STOVE_WITH_OVEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_CARBON_FILTER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_CARBON_FILTER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_ALUMINIUM_FILTER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_ALUMINIUM_FILTER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRYER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRYER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COMBINATION_FURNITURE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COMBINATION_FURNITURE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_SEALING = "DEVICE_TYPE_KITCHEN_EQUIPMENT_SEALING",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_VENTILATION_GRILLE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_VENTILATION_GRILLE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT = "DEVICE_TYPE_KITCHEN_EQUIPMENT",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_CHROME_STEEL = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_CHROME_STEEL",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GRANITE = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GRANITE",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GLASS = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GLASS",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_SYNTHETIC_RESIN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_SYNTHETIC_RESIN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_WOODEN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_WOODEN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET = "DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_HIGH_GLOSS = "DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_HIGH_GLOSS",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_WOOD = "DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_WOOD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET = "DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_HIGH_GLOSS = "DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_HIGH_GLOSS",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_WOOD = "DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_WOOD",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_CUTLERY = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_CUTLERY",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_BOTTLE_HOLDER = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_BOTTLE_HOLDER",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_SLIPS_MAT = "DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_SLIPS_MAT",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_CHROME_STEEL = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_CHROME_STEEL",
  DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_PLASTIC = "DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_PLASTIC",
  DEVICE_TYPE_OTHER_SANITARY = "DEVICE_TYPE_OTHER_SANITARY",
  DEVICE_TYPE_ELEVATOR_HANDICAP_LIFT = "DEVICE_TYPE_ELEVATOR_HANDICAP_LIFT",
  DEVICE_TYPE_ELEVATOR_BED_LIFT = "DEVICE_TYPE_ELEVATOR_BED_LIFT",
  DEVICE_TYPE_ELEVATOR = "DEVICE_TYPE_ELEVATOR",
  DEVICE_TYPE_ELEVATOR_MERCHANDISE_LIFT = "DEVICE_TYPE_ELEVATOR_MERCHANDISE_LIFT",
  DEVICE_TYPE_MOVING_STAIRCASE = "DEVICE_TYPE_MOVING_STAIRCASE",
  DEVICE_TYPE_FACADE_CLEANING = "DEVICE_TYPE_FACADE_CLEANING",
  DEVICE_TYPE_GOODS_CONVEYING_OTHER = "DEVICE_TYPE_GOODS_CONVEYING_OTHER",
  DEVICE_TYPE_GOODS_CONVEYING_PNEUMATIC_TUBE = "DEVICE_TYPE_GOODS_CONVEYING_PNEUMATIC_TUBE",
  DEVICE_TYPE_GOODS_CONVEYING_FILE_LIFT = "DEVICE_TYPE_GOODS_CONVEYING_FILE_LIFT",
  DEVICE_TYPE_GOODS_CONVEYING = "DEVICE_TYPE_GOODS_CONVEYING",
  DEVICE_TYPE_LIFTING = "DEVICE_TYPE_LIFTING",
  DEVICE_TYPE_PARKING = "DEVICE_TYPE_PARKING",
  DEVICE_TYPE_TRANSPORT_OTHER = "DEVICE_TYPE_TRANSPORT_OTHER",
  DEVICE_TYPE_PLASTERER = "DEVICE_TYPE_PLASTERER",
  DEVICE_TYPE_METAL = "DEVICE_TYPE_METAL",
  DEVICE_TYPE_METAL_CAR_SHELTER = "DEVICE_TYPE_METAL_CAR_SHELTER",
  DEVICE_TYPE_METAL_MAILBOX = "DEVICE_TYPE_METAL_MAILBOX",
  DEVICE_TYPE_METAL_BALCONY_ENLARGEMENT = "DEVICE_TYPE_METAL_BALCONY_ENLARGEMENT",
  DEVICE_TYPE_CARPENTER_SCREED_EXTENSION_LADDER = "DEVICE_TYPE_CARPENTER_SCREED_EXTENSION_LADDER",
  DEVICE_TYPE_CARPENTER_CELLAR_DOOR = "DEVICE_TYPE_CARPENTER_CELLAR_DOOR",
  DEVICE_TYPE_CARPENTER_APARTMENT_DOOR = "DEVICE_TYPE_CARPENTER_APARTMENT_DOOR",
  DEVICE_TYPE_CARPENTER_DOOR = "DEVICE_TYPE_CARPENTER_DOOR",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME = "DEVICE_TYPE_CARPENTER_DOOR_FRAME",
  DEVICE_TYPE_CARPENTER_LOCKS = "DEVICE_TYPE_CARPENTER_LOCKS",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS",
  DEVICE_TYPE_CARPENTER_SLIDING_DOOR = "DEVICE_TYPE_CARPENTER_SLIDING_DOOR",
  DEVICE_TYPE_CARPENTER = "DEVICE_TYPE_CARPENTER",
  DEVICE_TYPE_CARPENTER_DOOR_WOODEN = "DEVICE_TYPE_CARPENTER_DOOR_WOODEN",
  DEVICE_TYPE_CARPENTER_DOOR_WOOD_MATERIAL = "DEVICE_TYPE_CARPENTER_DOOR_WOOD_MATERIAL",
  DEVICE_TYPE_CARPENTER_DOOR_METAL = "DEVICE_TYPE_CARPENTER_DOOR_METAL",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_WOOD = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_WOOD",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL_STONE = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL_STONE",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL",
  DEVICE_TYPE_CARPENTER_LOCKS_APARTMENT_DOOR = "DEVICE_TYPE_CARPENTER_LOCKS_APARTMENT_DOOR",
  DEVICE_TYPE_CARPENTER_LOCKS_ROOM_DOOR = "DEVICE_TYPE_CARPENTER_LOCKS_ROOM_DOOR",
  DEVICE_TYPE_CARPENTER_LOCKS_KITT_JOINT = "DEVICE_TYPE_CARPENTER_LOCKS_KITT_JOINT",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_CHANNELBOARDS = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_CHANNELBOARDS",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_WOODEN = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_WOODEN",
  DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_HIGH_GLOSS = "DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_HIGH_GLOSS",
  DEVICE_TYPE_CARPENTER_SLIDING_DOOR_WOODEN_MATERIAL = "DEVICE_TYPE_CARPENTER_SLIDING_DOOR_WOODEN_MATERIAL",
  DEVICE_TYPE_CARPENTER_SLIDING_DOOR_ROLLS = "DEVICE_TYPE_CARPENTER_SLIDING_DOOR_ROLLS",
  DEVICE_TYPE_CARPENTER_DOOR_FRAME_COLOR_OIL = "DEVICE_TYPE_CARPENTER_DOOR_FRAME_COLOR_OIL",
  DEVICE_TYPE_CARPENTER_GLASS_INSERT_DOOR = "DEVICE_TYPE_CARPENTER_GLASS_INSERT_DOOR",
  DEVICE_TYPE_CARPENTER_FITTINGS_FOR_DOOR = "DEVICE_TYPE_CARPENTER_FITTINGS_FOR_DOOR",
  DEVICE_TYPE_CARPENTER_RUBBER_SEALS_DOOR = "DEVICE_TYPE_CARPENTER_RUBBER_SEALS_DOOR",
  DEVICE_TYPE_CARPENTER_SHELF = "DEVICE_TYPE_CARPENTER_SHELF",
  DEVICE_TYPE_CARPENTER_SHELF_WOOD = "DEVICE_TYPE_CARPENTER_SHELF_WOOD",
  DEVICE_TYPE_CARPENTER_SHELF_METAL = "DEVICE_TYPE_CARPENTER_SHELF_METAL",
  DEVICE_TYPE_CARPENTER_CLOSET = "DEVICE_TYPE_CARPENTER_CLOSET",
  DEVICE_TYPE_CARPENTER_CLOSET_WOOD = "DEVICE_TYPE_CARPENTER_CLOSET_WOOD",
  DEVICE_TYPE_CARPENTER_CLOSET_METAL = "DEVICE_TYPE_CARPENTER_CLOSET_METAL",
  DEVICE_TYPE_WINDOW_DOUBLE_GLAZING = "DEVICE_TYPE_WINDOW_DOUBLE_GLAZING",
  DEVICE_TYPE_WINDOW_INSULATING_GLAZING = "DEVICE_TYPE_WINDOW_INSULATING_GLAZING",
  DEVICE_TYPE_WINDOW_FITTINGS = "DEVICE_TYPE_WINDOW_FITTINGS",
  DEVICE_TYPE_WINDOW_WINDOW_FRAME = "DEVICE_TYPE_WINDOW_WINDOW_FRAME",
  DEVICE_TYPE_WINDOW_SPECIAL_GLAZING = "DEVICE_TYPE_WINDOW_SPECIAL_GLAZING",
  DEVICE_TYPE_WINDOW_WINDOW_SILL = "DEVICE_TYPE_WINDOW_WINDOW_SILL",
  DEVICE_TYPE_WINDOW = "DEVICE_TYPE_WINDOW",
  DEVICE_TYPE_WINDOW_DOUBLE = "DEVICE_TYPE_WINDOW_DOUBLE",
  DEVICE_TYPE_WINDOW_TRIPLE = "DEVICE_TYPE_WINDOW_TRIPLE",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD = "DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD_PAINTED = "DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD_PAINTED",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_TILES = "DEVICE_TYPE_WINDOW_WINDOW_SILL_TILES",
  DEVICE_TYPE_WINDOW_WINDOW_SILL_STONE = "DEVICE_TYPE_WINDOW_WINDOW_SILL_STONE",
  DEVICE_TYPE_LOCKING_LOCK = "DEVICE_TYPE_LOCKING_LOCK",
  DEVICE_TYPE_LOCKING_KEY = "DEVICE_TYPE_LOCKING_KEY",
  DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER = "DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER",
  DEVICE_TYPE_LOCKING = "DEVICE_TYPE_LOCKING",
  DEVICE_TYPE_INTERIOR_FINISHES = "DEVICE_TYPE_INTERIOR_FINISHES",
  DEVICE_TYPE_ELEMENT_WALL = "DEVICE_TYPE_ELEMENT_WALL",
  DEVICE_TYPE_CHROME_STEEP_FINISH = "DEVICE_TYPE_CHROME_STEEP_FINISH",
  DEVICETYPEOTHERINTERIOR1 = "DEVICE_TYPE_OTHER_INTERIOR1",
  DEVICE_TYPE_FLOORING_COVERING = "DEVICE_TYPE_FLOORING_COVERING",
  DEVICE_TYPE_FLOORING_BALL_YARN = "DEVICE_TYPE_FLOORING_BALL_YARN",
  DEVICE_TYPE_FLOORING_LAMINATE = "DEVICE_TYPE_FLOORING_LAMINATE",
  DEVICE_TYPE_FLOORING_LINOLEUM = "DEVICE_TYPE_FLOORING_LINOLEUM",
  DEVICE_TYPE_FLOORING_VINYL = "DEVICE_TYPE_FLOORING_VINYL",
  DEVICE_TYPE_FLOORING_DIRT_TRAP_CARPET = "DEVICE_TYPE_FLOORING_DIRT_TRAP_CARPET",
  DEVICE_TYPE_FLOORING_PARQUET = "DEVICE_TYPE_FLOORING_PARQUET",
  DEVICE_TYPE_FLOORING_PARQUET_SEALING = "DEVICE_TYPE_FLOORING_PARQUET_SEALING",
  DEVICE_TYPE_FLOORING_FLOOR_UNDERLAY = "DEVICE_TYPE_FLOORING_FLOOR_UNDERLAY",
  DEVICE_TYPE_FLOORING_INSTALLATION_FLOOR = "DEVICE_TYPE_FLOORING_INSTALLATION_FLOOR",
  DEVICE_TYPE_FLOORING_TILES = "DEVICE_TYPE_FLOORING_TILES",
  DEVICE_TYPE_FLOORING_CARPET = "DEVICE_TYPE_FLOORING_CARPET",
  DEVICE_TYPE_FLOORING_SKIRTING = "DEVICE_TYPE_FLOORING_SKIRTING",
  DEVICE_TYPE_FLOORING_JOINT = "DEVICE_TYPE_FLOORING_JOINT",
  DEVICE_TYPE_FLOORING = "DEVICE_TYPE_FLOORING",
  DEVICE_TYPE_FLOORING_PVC = "DEVICE_TYPE_FLOORING_PVC",
  DEVICE_TYPE_FLOORING_RUBBER = "DEVICE_TYPE_FLOORING_RUBBER",
  DEVICE_TYPE_FLOORING_CORK = "DEVICE_TYPE_FLOORING_CORK",
  DEVICE_TYPE_FLOORING_LAMINATE_MEDIUM_QUALITY = "DEVICE_TYPE_FLOORING_LAMINATE_MEDIUM_QUALITY",
  DEVICE_TYPE_FLOORING_LAMINATE_HIGH_QUALITY = "DEVICE_TYPE_FLOORING_LAMINATE_HIGH_QUALITY",
  DEVICE_TYPE_FLOORING_TILES_SOUND_PLATES = "DEVICE_TYPE_FLOORING_TILES_SOUND_PLATES",
  DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_SOFT = "DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_SOFT",
  DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_HARD = "DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_HARD",
  DEVICE_TYPE_FLOORING_TILES_CERAMIC = "DEVICE_TYPE_FLOORING_TILES_CERAMIC",
  DEVICE_TYPE_FLOORING_TILES_STONEWARE = "DEVICE_TYPE_FLOORING_TILES_STONEWARE",
  DEVICE_TYPE_FLOORING_TILES_ARTIFICIAL_STONE = "DEVICE_TYPE_FLOORING_TILES_ARTIFICIAL_STONE",
  DEVICE_TYPE_FLOORING_PARQUET_ADHESIVE = "DEVICE_TYPE_FLOORING_PARQUET_ADHESIVE",
  DEVICE_TYPE_FLOORING_PARQUET_HARDWOOD = "DEVICE_TYPE_FLOORING_PARQUET_HARDWOOD",
  DEVICE_TYPE_FLOORING_PARQUET_SOFTWOOD = "DEVICE_TYPE_FLOORING_PARQUET_SOFTWOOD",
  DEVICE_TYPE_FLOORING_PARQUET_FOURNIER = "DEVICE_TYPE_FLOORING_PARQUET_FOURNIER",
  DEVICE_TYPE_FLOORING_CARPET_NATURAL_FIBER = "DEVICE_TYPE_FLOORING_CARPET_NATURAL_FIBER",
  DEVICE_TYPE_FLOORING_CARPET_BALL_YARN = "DEVICE_TYPE_FLOORING_CARPET_BALL_YARN",
  DEVICE_TYPE_FLOORING_CARPET_NEEDLE_FELT = "DEVICE_TYPE_FLOORING_CARPET_NEEDLE_FELT",
  DEVICE_TYPE_FLOORING_CARPET_MEDIUM_QUALITY = "DEVICE_TYPE_FLOORING_CARPET_MEDIUM_QUALITY",
  DEVICE_TYPE_FLOORING_SKIRTING_PLASTIC = "DEVICE_TYPE_FLOORING_SKIRTING_PLASTIC",
  DEVICE_TYPE_FLOORING_SKIRTING_WOOD = "DEVICE_TYPE_FLOORING_SKIRTING_WOOD",
  DEVICE_TYPE_FLOORING_RAW = "DEVICE_TYPE_FLOORING_RAW",
  DEVICE_TYPE_FLOORING_COATED = "DEVICE_TYPE_FLOORING_COATED",
  DEVICE_TYPE_WALL_COVERING_WORK = "DEVICE_TYPE_WALL_COVERING_WORK",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER = "DEVICE_TYPE_WALL_COVERING_WALLPAPER",
  DEVICE_TYPE_WALL_COVERING_PLASTER = "DEVICE_TYPE_WALL_COVERING_PLASTER",
  DEVICE_TYPE_WALL_COVERING_ABRASION = "DEVICE_TYPE_WALL_COVERING_ABRASION",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER",
  DEVICE_TYPE_WALL_COVERING = "DEVICE_TYPE_WALL_COVERING",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER_MEDIUM_QUALITY = "DEVICE_TYPE_WALL_COVERING_WALLPAPER_MEDIUM_QUALITY",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER_GOOD_QUALITY = "DEVICE_TYPE_WALL_COVERING_WALLPAPER_GOOD_QUALITY",
  DEVICE_TYPE_WALL_COVERING_WALLPAPER_GLASS_FIBER = "DEVICE_TYPE_WALL_COVERING_WALLPAPER_GLASS_FIBER",
  DEVICE_TYPE_WALL_COVERING_PLASTER_GLUE_COLOR = "DEVICE_TYPE_WALL_COVERING_PLASTER_GLUE_COLOR",
  DEVICE_TYPE_WALL_COVERING_PLASTER_ACRYL = "DEVICE_TYPE_WALL_COVERING_PLASTER_ACRYL",
  DEVICE_TYPE_WALL_COVERING_PLASTER_MINERALS = "DEVICE_TYPE_WALL_COVERING_PLASTER_MINERALS",
  DEVICE_TYPE_WALL_COVERING_PLASTER_PLASTIC = "DEVICE_TYPE_WALL_COVERING_PLASTER_PLASTIC",
  DEVICE_TYPE_WALL_COVERING_PLASTER_SILICATE_COATING = "DEVICE_TYPE_WALL_COVERING_PLASTER_SILICATE_COATING",
  DEVICE_TYPE_WALL_COVERING_PLASTER_DISPERSION_PAINT = "DEVICE_TYPE_WALL_COVERING_PLASTER_DISPERSION_PAINT",
  DEVICE_TYPE_WALL_COVERING_ABRASION_PLASTIC = "DEVICE_TYPE_WALL_COVERING_ABRASION_PLASTIC",
  DEVICE_TYPE_WALL_COVERING_ABRASION_ROUGH_PUTT = "DEVICE_TYPE_WALL_COVERING_ABRASION_ROUGH_PUTT",
  DEVICE_TYPE_WALL_COVERING_ABRASION_WHITE_PLASTER = "DEVICE_TYPE_WALL_COVERING_ABRASION_WHITE_PLASTER",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_WALL_ROUGH = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_WALL_ROUGH",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_LASED = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_LASED",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_PAINTED_OPAQUE = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_PAINTED_OPAQUE",
  DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_GLAZE = "DEVICE_TYPE_WALL_COVERING_WOOD_WORKER_GLAZE",
  DEVICE_TYPE_WALL_COVERING_CERAMIC = "DEVICE_TYPE_WALL_COVERING_CERAMIC",
  DEVICE_TYPE_WALL_COVERING_STONEWORK = "DEVICE_TYPE_WALL_COVERING_STONEWORK",
  DEVICE_TYPE_WALL_COVERING_STONEWARE = "DEVICE_TYPE_WALL_COVERING_STONEWARE",
  DEVICE_TYPE_WALL_COVERING_TILES = "DEVICE_TYPE_WALL_COVERING_TILES",
  DEVICE_TYPE_CEILING_WORK = "DEVICE_TYPE_CEILING_WORK",
  DEVICE_TYPE_CEILING_WALLPAPER = "DEVICE_TYPE_CEILING_WALLPAPER",
  DEVICE_TYPE_CEILING_PLASTER = "DEVICE_TYPE_CEILING_PLASTER",
  DEVICE_TYPE_CEILING_ABRASION = "DEVICE_TYPE_CEILING_ABRASION",
  DEVICE_TYPE_CEILING_WOOD_WORKER = "DEVICE_TYPE_CEILING_WOOD_WORKER",
  DEVICE_TYPE_CEILING = "DEVICE_TYPE_CEILING",
  DEVICE_TYPE_CEILING_REGULAR = "DEVICE_TYPE_CEILING_REGULAR",
  DEVICE_TYPE_CEILING_REGULAR_METAL = "DEVICE_TYPE_CEILING_REGULAR_METAL",
  DEVICE_TYPE_CEILING_REGULAR_WOOD = "DEVICE_TYPE_CEILING_REGULAR_WOOD",
  DEVICE_TYPE_CEILING_COVERING_ABRASION_PLASTIC = "DEVICE_TYPE_CEILING_COVERING_ABRASION_PLASTIC",
  DEVICE_TYPE_CEILING_COVERING_ABRASION_ROUGH_PUTT = "DEVICE_TYPE_CEILING_COVERING_ABRASION_ROUGH_PUTT",
  DEVICE_TYPE_CEILING_COVERING_ABRASION_WHITE_PLASTER = "DEVICE_TYPE_CEILING_COVERING_ABRASION_WHITE_PLASTER",
  DEVICE_TYPE_INTERIOR_SURFACE_TREATMENTS_PAINTING = "DEVICE_TYPE_INTERIOR_SURFACE_TREATMENTS_PAINTING",
  DEVICETYPEOTHERINTERIOR2SHUTTER = "DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER",
  DEVICETYPEOTHERINTERIOR2BLADE = "DEVICE_TYPE_OTHER_INTERIOR2_BLADE",
  DEVICETYPEOTHERINTERIOR2BLIND = "DEVICE_TYPE_OTHER_INTERIOR2_BLIND",
  DEVICETYPEOTHERINTERIOR2LETTERBOX = "DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX",
  DEVICETYPEOTHERINTERIOR2 = "DEVICE_TYPE_OTHER_INTERIOR2",
  DEVICETYPEOTHERINTERIOR2SETS = "DEVICE_TYPE_OTHER_INTERIOR2_SETS",
  DEVICETYPEOTHERINTERIOR2SETSSOAPHOLDER = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_SOAP_HOLDER",
  DEVICETYPEOTHERINTERIOR2SETSTOOTHGLASS = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOOTH_GLASS",
  DEVICETYPEOTHERINTERIOR2SETSGLASSSHELF = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_GLASS_SHELF",
  DEVICETYPEOTHERINTERIOR2SETSTOWELBARS = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOWEL_BARS",
  DEVICETYPEOTHERINTERIOR2SETSHOLDINGRACK = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_HOLDING_RACK",
  DEVICETYPEOTHERINTERIOR2SETSCURTAINROD = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_CURTAIN_ROD",
  DEVICETYPEOTHERINTERIOR2SETSPACKAGE = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PACKAGE",
  DEVICETYPEOTHERINTERIOR2SETSWASHBASINBOWL = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_WASHBASIN_BOWL",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDER = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDERCHROMESTEEL = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_CHROME_STEEL",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDERPLASTIC = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_PLASTIC",
  DEVICETYPEOTHERINTERIOR2SETSBATHTOWELHOLDERHOOK = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_HOOK",
  DEVICETYPEOTHERINTERIOR2SETSPAPERHOLDER = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER",
  DEVICETYPEOTHERINTERIOR2SETSPAPERHOLDERCHROMESTEEL = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER_CHROME_STEEL",
  DEVICETYPEOTHERINTERIOR2SETSPAPERHOLDERPLASTIC = "DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER_PLASTIC",
  DEVICETYPEOTHERINTERIOR2ARMATURE = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE",
  DEVICETYPEOTHERINTERIOR2ARMATURESINGLELEVER = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_SINGLE_LEVER",
  DEVICETYPEOTHERINTERIOR2DOUBLELEVER = "DEVICE_TYPE_OTHER_INTERIOR2_DOUBLE_LEVER",
  DEVICETYPEOTHERINTERIOR2ARMATURESWIVEL = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_SWIVEL",
  DEVICETYPEOTHERINTERIOR2ARMATUREPULLOUTSPRAY = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_PULL_OUT_SPRAY",
  DEVICETYPEOTHERINTERIOR2ARMATURETHERMALMIXER = "DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_THERMAL_MIXER",
  DEVICETYPEOTHERINTERIOR2OPERATINGGARNITURE = "DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE",
  DEVICETYPEOTHERINTERIOR2CRANK = "DEVICE_TYPE_OTHER_INTERIOR2_CRANK",
  DEVICETYPEOTHERINTERIOR2BELT = "DEVICE_TYPE_OTHER_INTERIOR2_BELT",
  DEVICETYPEOTHERINTERIOR2ELECTRIC = "DEVICE_TYPE_OTHER_INTERIOR2_ELECTRIC",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_GREEN = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_GREEN",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_BLACK = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_BLACK",
  DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_METAL = "DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_METAL",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_OUTDOOR_PARKING = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_OUTDOOR_PARKING",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_PLANTING = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_PLANTING",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_CONTAINER_SITE = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_CONTAINER_SITE",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_BIKE_SHELTER = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_BIKE_SHELTER",
  DEVICE_TYPE_GARDENING_TRANSITION_POSITION_ACCESS_PATH = "DEVICE_TYPE_GARDENING_TRANSITION_POSITION_ACCESS_PATH",
  DEVICE_TYPE_GARDENING_FENCE = "DEVICE_TYPE_GARDENING_FENCE",
  DEVICE_TYPE_GARDENING_WALLS = "DEVICE_TYPE_GARDENING_WALLS",
  DEVICE_TYPE_GARDENING_CEMENT_BOARD = "DEVICE_TYPE_GARDENING_CEMENT_BOARD",
  DEVICE_TYPE_GARDENING_ACCESS_PATH = "DEVICE_TYPE_GARDENING_ACCESS_PATH",
  DEVICE_TYPE_GARDENING_LAWN = "DEVICE_TYPE_GARDENING_LAWN",
  DEVICE_TYPE_GARDENING = "DEVICE_TYPE_GARDENING",
  DEVICE_TYPE_FENCING = "DEVICE_TYPE_FENCING",
  DEVICE_TYPE_SUPERSTRUCTURE_TERRANCE = "DEVICE_TYPE_SUPERSTRUCTURE_TERRANCE",
  DEVICE_TYPE_SUPERSTRUCTURE_BIKE_STAND = "DEVICE_TYPE_SUPERSTRUCTURE_BIKE_STAND",
  DEVICE_TYPE_SUPERSTRUCTURE = "DEVICE_TYPE_SUPERSTRUCTURE",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_SAFETY_GLASS = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_SAFETY_GLASS",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_METAL = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_METAL",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CHROME_STEEL = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CHROME_STEEL",
  DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CONCRETE = "DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CONCRETE",
  DEVICE_TYPE_PLAYGROUND = "DEVICE_TYPE_PLAYGROUND",
  DEVICE_TYPE_PLAYGROUND_DEVICE = "DEVICE_TYPE_PLAYGROUND_DEVICE",
  DEVICE_TYPE_GARDEN_OTHER = "DEVICE_TYPE_GARDEN_OTHER",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_FIRE_EXTINGUISHER = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_FIRE_EXTINGUISHER",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_LAWN_MOWER = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_LAWN_MOWER",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_SECURITY_CAMERA = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_SECURITY_CAMERA",
  DEVICE_TYPE_DEVICE_TRANSITION_POSITION_INDOOR_BIKE_SHELTER = "DEVICE_TYPE_DEVICE_TRANSITION_POSITION_INDOOR_BIKE_SHELTER",
}

export type DeviceServiceListByIdsData = ObjectdeviceListByIdsResponse;

export type DeviceServiceListByIdsError = GooglerpcStatus;

export type DeviceServiceListByReferenceIdsData = ObjectdeviceListByReferenceIdsResponse;

export type DeviceServiceListByReferenceIdsError = GooglerpcStatus;

export type DeviceServiceDeleteData = ObjectdeviceDeleteResponse;

export type DeviceServiceDeleteError = GooglerpcStatus;

export interface DeviceServiceAddDocumentsParams {
  documentIds?: string[];
  id: string;
}

export type DeviceServiceAddDocumentsData = ObjectdeviceAddDocumentsResponse;

export type DeviceServiceAddDocumentsError = GooglerpcStatus;

export interface DeviceServiceUpdatePayload {
  /** @format float */
  acquisitionCosts?: number;
  /** @format int32 */
  amount?: number;
  /** Construction costs plan for heating (German: Baukostenplan Heizung) */
  bkpH?: string;
  brand?: string;
  /** @format float */
  cataloguePrice?: number;
  condition?: DeviceDeviceCondition;
  conditionNote?: string;
  documentIds?: string[];
  facilityPlant?: DeviceDeviceFacilityPlant;
  images?: ObjectdeviceImage[];
  inspection?: DeviceInspection;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  installation?: TypeDate;
  /** @format int32 */
  lifeSpan?: number;
  lockingKey?: DeviceLockingKey;
  maintenance?: DeviceMaintenance;
  manufacturer?: string;
  measure?: DeviceDeviceMeasure;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  measureUntil?: TypeDate;
  model?: string;
  note?: string;
  referenceId?: string;
  revision?: DeviceRevision;
  roomId?: string;
  serialNumber?: string;
  system?: DeviceDeviceSystem;
  type?: DeviceDeviceType;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  uninstallation?: TypeDate;
  unit?: ObjectdeviceDeviceUnit;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  warrantyEnd?: TypeDate;
}

export type DeviceServiceUpdateData = ObjectdeviceUpdateResponse;

export type DeviceServiceUpdateError = GooglerpcStatus;

export type EnergySourceServiceCreateData = ObjectenergySourceCreateResponse;

export type EnergySourceServiceCreateError = GooglerpcStatus;

export type EnergySourceServiceListByIdsData = ObjectenergySourceListByIdsResponse;

export type EnergySourceServiceListByIdsError = GooglerpcStatus;

export type EnergySourceServiceListByReferenceIdsData = ObjectenergySourceListByReferenceIdsResponse;

export type EnergySourceServiceListByReferenceIdsError = GooglerpcStatus;

export type EnergySourceServiceDeleteData = ObjectenergySourceDeleteResponse;

export type EnergySourceServiceDeleteError = GooglerpcStatus;

export interface EnergySourceServiceUpdatePayload {
  /** @format float */
  co2PerYear?: number;
  /** @format float */
  consumption?: number;
  /** @format float */
  consumptionKwhPerYear?: number;
  costDistribution?: EnergySourceCostDistribution;
  costDistribution2?: EnergySourceCostDistribution;
  documentIds?: string[];
  /** @format float */
  energyReferenceArea?: number;
  heatDistribution?: EnergySourceHeatDistribution;
  kind?: EnergySourceEnergySourceKind;
  orders?: EnergySourceOrder[];
  photovoltaic?: boolean;
  selfConsumptionCommunity?: boolean;
  tankReadings?: ObjectenergySourceTankReading[];
  /** @format float */
  tankSize?: number;
  type?: ObjectenergySourceEnergySourceType;
  unit?: ObjectenergySourceEnergySourceUnit;
}

export type EnergySourceServiceUpdateData = ObjectenergySourceUpdateResponse;

export type EnergySourceServiceUpdateError = GooglerpcStatus;

export type FahrlaenderServiceLoadConfigurationData = FahrlaenderLoadConfigurationResponse;

export type FahrlaenderServiceLoadConfigurationError = GooglerpcStatus;

export interface FahrlaenderServiceSaveConfigurationPayload {
  apiPassword?: string;
  apiUser?: string;
  features?: FahrlaenderFahrlaenderFeatures[];
}

export type FahrlaenderServiceSaveConfigurationData = FahrlaenderSaveConfigurationResponse;

export type FahrlaenderServiceSaveConfigurationError = GooglerpcStatus;

export type FahrlaenderServiceUpdateMarketRentData = FahrlaenderUpdateMarketRentResponse;

export type FahrlaenderServiceUpdateMarketRentError = GooglerpcStatus;

export type FahrlaenderServiceUpdateMicroLocationRatingsData = FahrlaenderUpdateMicroLocationRatingsResponse;

export type FahrlaenderServiceUpdateMicroLocationRatingsError = GooglerpcStatus;

export type ObjectServiceGetData = ObjectobjectGetResponse;

export type ObjectServiceGetError = GooglerpcStatus;

export type ObjectServiceGetAccountingStatisticsData = ObjectGetAccountingStatisticsResponse;

export type ObjectServiceGetAccountingStatisticsError = GooglerpcStatus;

export type ObjectServiceGetAdministrationStatisticsData = ObjectGetAdministrationStatisticsResponse;

export type ObjectServiceGetAdministrationStatisticsError = GooglerpcStatus;

export type ObjectServiceGetAssetStatisticsData = ObjectGetAssetStatisticsResponse;

export type ObjectServiceGetAssetStatisticsError = GooglerpcStatus;

export type ObjectServiceGetMarketingData = ObjectGetMarketingResponse;

export type ObjectServiceGetMarketingError = GooglerpcStatus;

export interface ObjectServiceGetMarketingStatisticsParams {
  /** The contactId which should be used for employee based statistics */
  contactIdAssigneeMarketing?: string;
  /**
   * The ID of the account whose marketing statistics are to be calculated (objects he got with share-scope=MARKETING)
   * @format int64
   */
  accountId: string;
}

export type ObjectServiceGetMarketingStatisticsData = ObjectobjectGetMarketingStatisticsResponse;

export type ObjectServiceGetMarketingStatisticsError = GooglerpcStatus;

export type ObjectServiceGetObjectTypesData = ObjectGetObjectTypesResponse;

export type ObjectServiceGetObjectTypesError = GooglerpcStatus;

export type ObjectServiceGetOwnerStatisticsData = ObjectGetOwnerStatisticsResponse;

export type ObjectServiceGetOwnerStatisticsError = GooglerpcStatus;

export type ObjectServiceGetTotalValueQuotaForBuildingData = ObjectGetTotalValueQuotaForBuildingResponse;

export type ObjectServiceGetTotalValueQuotaForBuildingError = GooglerpcStatus;

export type ObjectServiceGetUsedTagsData = ObjectobjectGetUsedTagsResponse;

export type ObjectServiceGetUsedTagsError = GooglerpcStatus;

export type GroupServiceAddBuildingData = ObjectgroupAddBuildingResponse;

export type GroupServiceAddBuildingError = GooglerpcStatus;

export type GroupServiceCreateData = ObjectgroupCreateResponse;

export type GroupServiceCreateError = GooglerpcStatus;

export type GroupServiceDeleteData = ObjectgroupDeleteResponse;

export type GroupServiceDeleteError = GooglerpcStatus;

export type GroupServiceGetData = ObjectgroupGetResponse;

export type GroupServiceGetError = GooglerpcStatus;

export type GroupServiceListData = ObjectgroupListResponse;

export type GroupServiceListError = GooglerpcStatus;

export type GroupServiceRemoveBuildingData = GroupRemoveBuildingResponse;

export type GroupServiceRemoveBuildingError = GooglerpcStatus;

export interface GroupServiceUpdatePayload {
  name?: string;
}

export type GroupServiceUpdateData = ObjectgroupUpdateResponse;

export type GroupServiceUpdateError = GooglerpcStatus;

export type HandoverProtocolServiceCreateData = ObjecthandoverProtocolCreateResponse;

export type HandoverProtocolServiceCreateError = GooglerpcStatus;

export type HandoverProtocolServiceEnableData = ObjecthandoverProtocolEnableResponse;

export type HandoverProtocolServiceEnableError = GooglerpcStatus;

export type HandoverProtocolServiceHasEnabledData = ObjecthandoverProtocolHasEnabledResponse;

export type HandoverProtocolServiceHasEnabledError = GooglerpcStatus;

export type HandoverProtocolServiceListByIdsData = ObjecthandoverProtocolListByIdsResponse;

export type HandoverProtocolServiceListByIdsError = GooglerpcStatus;

export type HandoverProtocolServiceListByObjectIdsData = ObjecthandoverProtocolListByObjectIdsResponse;

export type HandoverProtocolServiceListByObjectIdsError = GooglerpcStatus;

export interface HandoverProtocolServiceListMyProtocolsParams {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
}

export type HandoverProtocolServiceListMyProtocolsData = HandoverProtocolListMyProtocolsResponse;

export type HandoverProtocolServiceListMyProtocolsError = GooglerpcStatus;

export interface HandoverProtocolServiceListOtherProtocolsParams {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
}

export type HandoverProtocolServiceListOtherProtocolsData = HandoverProtocolListOtherProtocolsResponse;

export type HandoverProtocolServiceListOtherProtocolsError = GooglerpcStatus;

export type HandoverProtocolServiceDeleteData = ObjecthandoverProtocolDeleteResponse;

export type HandoverProtocolServiceDeleteError = GooglerpcStatus;

export interface HandoverProtocolServiceGetPdfPreviewParams {
  /** @default "PREFERRED_LANGUAGE_UNDEFINED" */
  language?: LanguageEnum1;
  id: string;
}

/** @default "PREFERRED_LANGUAGE_UNDEFINED" */
export enum LanguageEnum1 {
  PREFERRED_LANGUAGE_UNDEFINED = "PREFERRED_LANGUAGE_UNDEFINED",
  PREFERRED_LANGUAGE_GERMAN = "PREFERRED_LANGUAGE_GERMAN",
  PREFERRED_LANGUAGE_ENGLISH = "PREFERRED_LANGUAGE_ENGLISH",
  PREFERRED_LANGUAGE_ITALIAN = "PREFERRED_LANGUAGE_ITALIAN",
  PREFERRED_LANGUAGE_FRENCH = "PREFERRED_LANGUAGE_FRENCH",
}

export type HandoverProtocolServiceGetPdfPreviewData = HandoverProtocolGetPdfPreviewResponse;

export type HandoverProtocolServiceGetPdfPreviewError = GooglerpcStatus;

/** @default "PREFERRED_LANGUAGE_UNDEFINED" */
export enum HandoverProtocolServiceGetPdfPreviewParams1LanguageEnum {
  PREFERRED_LANGUAGE_UNDEFINED = "PREFERRED_LANGUAGE_UNDEFINED",
  PREFERRED_LANGUAGE_GERMAN = "PREFERRED_LANGUAGE_GERMAN",
  PREFERRED_LANGUAGE_ENGLISH = "PREFERRED_LANGUAGE_ENGLISH",
  PREFERRED_LANGUAGE_ITALIAN = "PREFERRED_LANGUAGE_ITALIAN",
  PREFERRED_LANGUAGE_FRENCH = "PREFERRED_LANGUAGE_FRENCH",
}

export interface HandoverProtocolServiceSaveDataPayload {
  devicesWithoutRoom?: HandoverProtocolSaveDataDevice[];
  note?: string;
  participants?: HandoverProtocolSaveDataParticipant[];
  rooms?: HandoverProtocolSaveDataRoom[];
}

export type HandoverProtocolServiceSaveDataData = HandoverProtocolSaveDataResponse;

export type HandoverProtocolServiceSaveDataError = GooglerpcStatus;

export interface HandoverProtocolServiceSignPayload {
  language?: LanguagePreferredLanguage;
  signatures?: ObjecthandoverProtocolSignature[];
}

export type HandoverProtocolServiceSignData = HandoverProtocolSignResponse;

export type HandoverProtocolServiceSignError = GooglerpcStatus;

export interface HandoverProtocolServiceUpdatePayload {
  assigneeContactId?: string;
  devicesWithoutRoom?: ObjecthandoverProtocolDevice[];
  movingInTenancyReferences?: HandoverProtocolWriteTenancyReference[];
  movingOutTenancyReferences?: HandoverProtocolWriteTenancyReference[];
  note?: string;
  participants?: HandoverProtocolWriteParticipant[];
  rooms?: ObjecthandoverProtocolRoom[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  scheduledDate?: TypeDate;
  /**
   * Represents a time of day. The date and time zone are either not significant
   * or are specified elsewhere. An API may choose to allow leap seconds. Related
   * types are [google.type.Date][google.type.Date] and
   * `google.protobuf.Timestamp`.
   */
  scheduledTime?: TypeTimeOfDay;
}

export type HandoverProtocolServiceUpdateData = ObjecthandoverProtocolUpdateResponse;

export type HandoverProtocolServiceUpdateError = GooglerpcStatus;

export type InsuranceServiceCreateData = ObjectinsuranceCreateResponse;

export type InsuranceServiceCreateError = GooglerpcStatus;

export type InsuranceServiceListByIdsData = ObjectinsuranceListByIdsResponse;

export type InsuranceServiceListByIdsError = GooglerpcStatus;

export type InsuranceServiceListByReferenceIdsData = ObjectinsuranceListByReferenceIdsResponse;

export type InsuranceServiceListByReferenceIdsError = GooglerpcStatus;

export type InsuranceServiceDeleteData = ObjectinsuranceDeleteResponse;

export type InsuranceServiceDeleteError = GooglerpcStatus;

export interface InsuranceServiceUpdatePayload {
  /** @format float */
  amount?: number;
  benefits?: InsuranceInsuranceBenefit[];
  documentIds?: string[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  earliestTerminationDate?: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endDate?: TypeDate;
  /** @format float */
  excess?: number;
  insuranceCompany?: string;
  notes?: string;
  policyNumber?: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  premiumPaidUntil?: TypeDate;
  /** Some opaque ID where this entity is attached to (e.g. propertyId or buildingId) */
  referenceId?: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startDate?: TypeDate;
  type?: InsuranceInsuranceType;
  /** @format float */
  yearlyCosts?: number;
}

export type InsuranceServiceUpdateData = ObjectinsuranceUpdateResponse;

export type InsuranceServiceUpdateError = GooglerpcStatus;

export type InvestPlanServiceCreateBulkData = ObjectinvestPlanCreateBulkResponse;

export type InvestPlanServiceCreateBulkError = GooglerpcStatus;

export type InvestPlanServiceDeleteData = ObjectinvestPlanDeleteResponse;

export type InvestPlanServiceDeleteError = GooglerpcStatus;

export type InvestPlanServiceEnableData = ObjectinvestPlanEnableResponse;

export type InvestPlanServiceEnableError = GooglerpcStatus;

export type InvestPlanServiceHasEnabledData = ObjectinvestPlanHasEnabledResponse;

export type InvestPlanServiceHasEnabledError = GooglerpcStatus;

export type InvestPlanServiceListByReferenceIdsData = ObjectinvestPlanListByReferenceIdsResponse;

export type InvestPlanServiceListByReferenceIdsError = GooglerpcStatus;

export type InvestPlanServiceUpdateBulkData = ObjectinvestPlanUpdateBulkResponse;

export type InvestPlanServiceUpdateBulkError = GooglerpcStatus;

export type ObjectServiceIsActiveData = ObjectIsActiveResponse;

export type ObjectServiceIsActiveError = GooglerpcStatus;

export interface ObjectServiceListParams {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
  /** DO NOT USE! Deprecated. */
  address?: string;
  /** DO NOT USE! Deprecated. */
  categories?: CategoriesEnum[];
  /**
   * DO NOT USE! Deprecated.
   * @format int32
   */
  zipFrom?: number;
  /**
   * DO NOT USE! Deprecated.
   * @format int32
   */
  zipTo?: number;
  /** DO NOT USE! Deprecated. */
  tenantContactIds?: string[];
  /** DO NOT USE! Deprecated. */
  networkPartnerIds?: string[];
  /**
   * DO NOT USE! Deprecated.
   * @default "UNDEFINED_SORT_ORDER"
   */
  sortFloor?: SortFloorEnum;
  /**
   * DO NOT USE! Deprecated.
   * @default "UNDEFINED_SORT_ORDER"
   */
  sortRentPrice?: SortRentPriceEnum;
  /** @format int64 */
  accountId: string;
}

export enum CategoriesEnum {
  UNDEFINED_CATEGORY = "UNDEFINED_CATEGORY",
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  COMMERCIAL = "COMMERCIAL",
  GASTRONOMY = "GASTRONOMY",
  GARDEN = "GARDEN",
  PARKING_SPACE = "PARKING_SPACE",
  SECONDARY_ROOM = "SECONDARY_ROOM",
  PLOT = "PLOT",
  AGRICULTURE = "AGRICULTURE",
}

/**
 * DO NOT USE! Deprecated.
 * @default "UNDEFINED_SORT_ORDER"
 */
export enum SortFloorEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * DO NOT USE! Deprecated.
 * @default "UNDEFINED_SORT_ORDER"
 */
export enum SortRentPriceEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type ObjectServiceListData = ObjectobjectListResponse;

export type ObjectServiceListError = GooglerpcStatus;

export enum ObjectServiceListParams1CategoriesEnum {
  UNDEFINED_CATEGORY = "UNDEFINED_CATEGORY",
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  COMMERCIAL = "COMMERCIAL",
  GASTRONOMY = "GASTRONOMY",
  GARDEN = "GARDEN",
  PARKING_SPACE = "PARKING_SPACE",
  SECONDARY_ROOM = "SECONDARY_ROOM",
  PLOT = "PLOT",
  AGRICULTURE = "AGRICULTURE",
}

/**
 * DO NOT USE! Deprecated.
 * @default "UNDEFINED_SORT_ORDER"
 */
export enum ObjectServiceListParams1SortFloorEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * DO NOT USE! Deprecated.
 * @default "UNDEFINED_SORT_ORDER"
 */
export enum ObjectServiceListParams1SortRentPriceEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type ObjectServiceListByBuildingIdsData = ObjectobjectListByBuildingIdsResponse;

export type ObjectServiceListByBuildingIdsError = GooglerpcStatus;

export type ObjectServiceListByIdsData = ObjectobjectListByIdsResponse;

export type ObjectServiceListByIdsError = GooglerpcStatus;

export type ObjectServiceListFilterOptionsData = ObjectobjectListFilterOptionsResponse;

export type ObjectServiceListFilterOptionsError = GooglerpcStatus;

export type ObjectServiceListFreeByDateData = ObjectListFreeByDateResponse;

export type ObjectServiceListFreeByDateError = GooglerpcStatus;

export type ObjectServiceListIdsInMarketingData = ObjectListIdsInMarketingResponse;

export type ObjectServiceListIdsInMarketingError = GooglerpcStatus;

export interface ObjectServiceListMarketingParams {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
  statuses?: StatusesEnum[];
  /** Optional: Only partial match needed */
  publicId?: string;
  /** Optional */
  address?: string;
  categories?: CategoriesEnum1[];
  /**
   * Optional: If -1 then do not filter for it
   * @format float
   */
  roomsFrom?: number;
  /**
   * Optional: If -1 then do not filter for it
   * @format float
   */
  roomsTo?: number;
  /** Optional */
  contactIdAssigneeMarketing?: string[];
  /** @default "UNDEFINED_SORT_ORDER" */
  sortUnreadRequests?: SortUnreadRequestsEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortViewingAppointments?: SortViewingAppointmentsEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortApplicationForms?: SortApplicationFormsEnum;
  /**
   * Get all objects with this as ownerId AND all objects that are shared with this accountId
   * @format int64
   */
  accountId: string;
}

export enum StatusesEnum {
  NO_MARKETING_STATUS_FILTER = "NO_MARKETING_STATUS_FILTER",
  NEW = "NEW",
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  RENTED = "RENTED",
}

export enum CategoriesEnum1 {
  UNDEFINED_CATEGORY = "UNDEFINED_CATEGORY",
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  COMMERCIAL = "COMMERCIAL",
  GASTRONOMY = "GASTRONOMY",
  GARDEN = "GARDEN",
  PARKING_SPACE = "PARKING_SPACE",
  SECONDARY_ROOM = "SECONDARY_ROOM",
  PLOT = "PLOT",
  AGRICULTURE = "AGRICULTURE",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortUnreadRequestsEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortViewingAppointmentsEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortApplicationFormsEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type ObjectServiceListMarketingData = ObjectListMarketingResponse;

export type ObjectServiceListMarketingError = GooglerpcStatus;

export enum ObjectServiceListMarketingParams1StatusesEnum {
  NO_MARKETING_STATUS_FILTER = "NO_MARKETING_STATUS_FILTER",
  NEW = "NEW",
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  RENTED = "RENTED",
}

export enum ObjectServiceListMarketingParams1CategoriesEnum {
  UNDEFINED_CATEGORY = "UNDEFINED_CATEGORY",
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  COMMERCIAL = "COMMERCIAL",
  GASTRONOMY = "GASTRONOMY",
  GARDEN = "GARDEN",
  PARKING_SPACE = "PARKING_SPACE",
  SECONDARY_ROOM = "SECONDARY_ROOM",
  PLOT = "PLOT",
  AGRICULTURE = "AGRICULTURE",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum ObjectServiceListMarketingParams1SortUnreadRequestsEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum ObjectServiceListMarketingParams1SortViewingAppointmentsEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum ObjectServiceListMarketingParams1SortApplicationFormsEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type ObjectServiceListPublishedData = ObjectListPublishedResponse;

export type ObjectServiceListPublishedError = GooglerpcStatus;

export interface ObjectServiceMarkForMarketingPayload {
  availability?: ObjectAvailability;
  deposit?: ObjectobjectDeposit;
  description?: string;
  documents?: ObjectobjectDocument[];
  images?: ObjectobjectImage[];
  marketingNote?: string;
  parking?: ObjectParking[];
  pricing?: ObjectPricing;
  publishAddress?: boolean;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  startMarketingAt?: string;
  title?: string;
  videos?: ObjectVideo[];
  virtualTourUrl?: string;
}

export type ObjectServiceMarkForMarketingData = ObjectMarkForMarketingResponse;

export type ObjectServiceMarkForMarketingError = GooglerpcStatus;

export type ObjectServiceMarketingMarkSeenData = ObjectMarketingMarkSeenResponse;

export type ObjectServiceMarketingMarkSeenError = GooglerpcStatus;

export type MortgageServiceCreateData = ObjectmortgageCreateResponse;

export type MortgageServiceCreateError = GooglerpcStatus;

export type MortgageServiceListByIdsData = ObjectmortgageListByIdsResponse;

export type MortgageServiceListByIdsError = GooglerpcStatus;

export type MortgageServiceListByReferenceIdsData = ObjectmortgageListByReferenceIdsResponse;

export type MortgageServiceListByReferenceIdsError = GooglerpcStatus;

export type MortgageServiceDeleteData = ObjectmortgageDeleteResponse;

export type MortgageServiceDeleteError = GooglerpcStatus;

export type MortgageServiceAddDocumentsData = ObjectmortgageAddDocumentsResponse;

export type MortgageServiceAddDocumentsError = GooglerpcStatus;

export interface MortgageServiceUpdatePayload {
  /** @format double */
  amortisationAmount?: number;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  amortizationFrom?: TypeDate;
  amortizations?: MortgageAmortization[];
  /** @format double */
  amount?: number;
  bankCountry?: string;
  bankName?: string;
  bankNumber?: string;
  bic?: string;
  debitAccount?: string;
  designation?: string;
  documentIds?: string[];
  dueDateMonthsAmortization?: number[];
  dueDateMonthsInterestRate?: number[];
  enableAmortizations?: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  expiresAt?: TypeDate;
  /** @format float */
  interestRate?: number;
  mortgageDesignation?: MortgageMortgageDesignation;
  mortgageeAddress?: CommonaddressAddress;
  number?: string;
  /** Could be property, building or object ID */
  referenceId?: string;
  terminableOn?: MortgageMortgageTerminableOn;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  validFrom?: TypeDate;
}

export type MortgageServiceUpdateData = ObjectmortgageUpdateResponse;

export type MortgageServiceUpdateError = GooglerpcStatus;

export type ObjectServiceMoveObjectsData = ObjectMoveObjectsResponse;

export type ObjectServiceMoveObjectsError = GooglerpcStatus;

export type PlotServiceCreateData = ObjectplotCreateResponse;

export type PlotServiceCreateError = GooglerpcStatus;

export type PlotServiceListByIdsData = ObjectplotListByIdsResponse;

export type PlotServiceListByIdsError = GooglerpcStatus;

export interface PlotServiceListByOwnerIdParams {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
  /** @format int64 */
  ownerId: string;
}

export type PlotServiceListByOwnerIdData = PlotListByOwnerIdResponse;

export type PlotServiceListByOwnerIdError = GooglerpcStatus;

export type PlotServiceListByPropertyIdData = PlotListByPropertyIdResponse;

export type PlotServiceListByPropertyIdError = GooglerpcStatus;

export type PlotServiceUpdateData = ObjectplotUpdateResponse;

export type PlotServiceUpdateError = GooglerpcStatus;

export type PlotServiceDeleteData = ObjectplotDeleteResponse;

export type PlotServiceDeleteError = GooglerpcStatus;

export interface PropertyServiceCountParams {
  /** @default "UNDEFINED_SCOPE" */
  scope?: ScopeEnum2;
  /** @format int64 */
  accountId: string;
}

/** @default "UNDEFINED_SCOPE" */
export enum ScopeEnum2 {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export type PropertyServiceCountData = ObjectpropertyCountResponse;

export type PropertyServiceCountError = GooglerpcStatus;

/** @default "UNDEFINED_SCOPE" */
export enum PropertyServiceCountParams1ScopeEnum {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export type PropertyServiceCreateData = ObjectpropertyCreateResponse;

export type PropertyServiceCreateError = GooglerpcStatus;

export interface PropertyServiceListParams {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
  name?: string;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  zipFrom?: number;
  /** @format int32 */
  zipTo?: number;
  city?: string;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortNumber?: SortNumberEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortLandRegisterNumber?: SortLandRegisterNumberEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortBuildingCount?: SortBuildingCountEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortGrossTargetRentPerYear?: SortGrossTargetRentPerYearEnum1;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortGrossActualRentPerYear?: SortGrossActualRentPerYearEnum1;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortNetTargetRentPerYear?: SortNetTargetRentPerYearEnum1;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortNetActualRentPerYear?: SortNetActualRentPerYearEnum1;
  /** @format int64 */
  ownerId: string;
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortNumberEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortLandRegisterNumberEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortBuildingCountEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortGrossTargetRentPerYearEnum1 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortGrossActualRentPerYearEnum1 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortNetTargetRentPerYearEnum1 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortNetActualRentPerYearEnum1 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type PropertyServiceListData = ObjectpropertyListResponse;

export type PropertyServiceListError = GooglerpcStatus;

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListParams1SortNumberEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListParams1SortLandRegisterNumberEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListParams1SortBuildingCountEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListParams1SortGrossTargetRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListParams1SortGrossActualRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListParams1SortNetTargetRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListParams1SortNetActualRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type PropertyServiceListByBuildingIdsData = ObjectpropertyListByBuildingIdsResponse;

export type PropertyServiceListByBuildingIdsError = GooglerpcStatus;

export type PropertyServiceListByIdsData = ObjectpropertyListByIdsResponse;

export type PropertyServiceListByIdsError = GooglerpcStatus;

export type PropertyServiceTransferToAccountingData = ObjectpropertyTransferToAccountingResponse;

export type PropertyServiceTransferToAccountingError = GooglerpcStatus;

export type PropertyServiceDeleteData = ObjectpropertyDeleteResponse;

export type PropertyServiceDeleteError = GooglerpcStatus;

export interface PropertyServiceUpdatePayload {
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  acquisitionDate?: TypeDate;
  additionalRentComponents?: PropertyAdditionalRentComponents[];
  /** Only zip, city and state are required and set */
  address?: CommonaddressAddress;
  advancePaymentRentComponents?: PropertyAdvancePaymentRentComponents[];
  advancePaymentSeeContractDescription?: string;
  buildingIds?: string[];
  constructionZone?: string;
  development?: ObjectpropertyDevelopment;
  distributionKeys?: PropertyDistributionKeys;
  documentIds?: string[];
  egrid?: string;
  flatRateRentComponents?: PropertyFlatRateRentComponents[];
  flatRateSeeContractDescription?: string;
  imageMediaId?: string;
  landRegisterNumber?: string;
  name?: string;
  note?: string;
  /** @format int32 */
  number?: number;
  opted?: boolean;
  optedPortionKey?: ObjectpropertyOptedPortionKey;
  /** @format float */
  plotArea?: number;
  transferToAccounting?: boolean;
  type?: ObjectpropertyPropertyType;
}

export type PropertyServiceUpdateData = ObjectpropertyUpdateResponse;

export type PropertyServiceUpdateError = GooglerpcStatus;

export type ObjectServicePublishData = ObjectPublishResponse;

export type ObjectServicePublishError = GooglerpcStatus;

export type ObjectServicePublishUpdatedEventsData = ObjectobjectPublishUpdatedEventsResponse;

export type ObjectServicePublishUpdatedEventsError = GooglerpcStatus;

export type ObjectServiceRemoveCraftsmenData = ObjectRemoveCraftsmenResponse;

export type ObjectServiceRemoveCraftsmenError = GooglerpcStatus;

export type ObjectServiceRemoveFromMarketingData = ObjectRemoveFromMarketingResponse;

export type ObjectServiceRemoveFromMarketingError = GooglerpcStatus;

export type ObjectServiceRemoveJanitorsData = ObjectRemoveJanitorsResponse;

export type ObjectServiceRemoveJanitorsError = GooglerpcStatus;

export type ObjectServiceRemoveServiceProvidersData = ObjectRemoveServiceProvidersResponse;

export type ObjectServiceRemoveServiceProvidersError = GooglerpcStatus;

export type RentalIndexServiceCreateData = ObjectrentalIndexCreateResponse;

export type RentalIndexServiceCreateError = GooglerpcStatus;

export interface RentalIndexServiceListParams {
  /** @format int64 */
  accountId?: string;
}

export type RentalIndexServiceListData = ObjectrentalIndexListResponse;

export type RentalIndexServiceListError = GooglerpcStatus;

export type RentalIndexServiceRenderData = ObjectrentalIndexRenderResponse;

export type RentalIndexServiceRenderError = GooglerpcStatus;

export type RentalIndexServiceRenderPdfData = RentalIndexRenderPdfResponse;

export type RentalIndexServiceRenderPdfError = GooglerpcStatus;

export type RentalIndexServiceDeleteData = ObjectrentalIndexDeleteResponse;

export type RentalIndexServiceDeleteError = GooglerpcStatus;

export interface RentalIndexServiceUpdatePayload {
  compositionLinesOrColumns?: RentalIndexCompositionLinesOrColumns;
  compositionType?: RentalIndexCompositionType;
  fields?: RentalIndexTemplateField[];
  language?: LanguagePreferredLanguage;
  name?: string;
  sortingType?: RentalIndexSortingType;
  tenancyType?: RentalIndexTenancyType;
}

export type RentalIndexServiceUpdateData = ObjectrentalIndexUpdateResponse;

export type RentalIndexServiceUpdateError = GooglerpcStatus;

export type RoomServiceCreateData = ObjectroomCreateResponse;

export type RoomServiceCreateError = GooglerpcStatus;

export type RoomServiceListByIdsData = ObjectroomListByIdsResponse;

export type RoomServiceListByIdsError = GooglerpcStatus;

export type RoomServiceListByReferenceIdsData = ObjectroomListByReferenceIdsResponse;

export type RoomServiceListByReferenceIdsError = GooglerpcStatus;

export type RoomServiceUpdateData = ObjectroomUpdateResponse;

export type RoomServiceUpdateError = GooglerpcStatus;

export type RoomServiceDeleteData = ObjectroomDeleteResponse;

export type RoomServiceDeleteError = GooglerpcStatus;

export type ObjectServiceSetAccountingAssigneeData = ObjectSetAccountingAssigneeResponse;

export type ObjectServiceSetAccountingAssigneeError = GooglerpcStatus;

export type ObjectServiceSetAdminAssigneeData = ObjectSetAdminAssigneeResponse;

export type ObjectServiceSetAdminAssigneeError = GooglerpcStatus;

export type ObjectServiceSetAssetAssigneeData = ObjectSetAssetAssigneeResponse;

export type ObjectServiceSetAssetAssigneeError = GooglerpcStatus;

export type ObjectServiceSetContactsData = ObjectSetContactsResponse;

export type ObjectServiceSetContactsError = GooglerpcStatus;

export type ObjectServiceSetMarketingAssigneeData = ObjectSetMarketingAssigneeResponse;

export type ObjectServiceSetMarketingAssigneeError = GooglerpcStatus;

export type ObjectServiceSetOwnerAssigneeData = ObjectSetOwnerAssigneeResponse;

export type ObjectServiceSetOwnerAssigneeError = GooglerpcStatus;

export type ObjectServiceSetViewingAppointmentContactData = ObjectSetViewingAppointmentContactResponse;

export type ObjectServiceSetViewingAppointmentContactError = GooglerpcStatus;

export type SettlementObjectServiceCreateData = ObjectsettlementObjectCreateResponse;

export type SettlementObjectServiceCreateError = GooglerpcStatus;

export type SettlementObjectServiceListByIdsData = ObjectsettlementObjectListByIdsResponse;

export type SettlementObjectServiceListByIdsError = GooglerpcStatus;

export type SettlementObjectServiceListByReferenceIdData = SettlementObjectListByReferenceIdResponse;

export type SettlementObjectServiceListByReferenceIdError = GooglerpcStatus;

export type SettlementObjectServiceDeleteData = ObjectsettlementObjectDeleteResponse;

export type SettlementObjectServiceDeleteError = GooglerpcStatus;

export interface SettlementObjectServiceUpdatePayload {
  activated?: boolean;
  additionalCostsBillingType?: ObjectsettlementObjectAdditionalCostsBillingType;
  billingContactId?: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  billingEnd?: TypeDate;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  billingStart?: TypeDate;
  correspondenceContactId?: string;
  description?: string;
  opted?: boolean;
  ownerIsDebtor?: boolean;
  rentAdjustments?: ObjectsettlementObjectRentAdjustment[];
}

export type SettlementObjectServiceUpdateData = ObjectsettlementObjectUpdateResponse;

export type SettlementObjectServiceUpdateError = GooglerpcStatus;

export type TenancyServiceCloseTenanciesData = TenancyCloseResponse;

export type TenancyServiceCloseTenanciesError = GooglerpcStatus;

export type TenancyServiceCreateData = ObjecttenancyCreateResponse;

export type TenancyServiceCreateError = GooglerpcStatus;

export type TenancyServiceDeleteRentAdjustmentsData = ObjecttenancyDeleteRentAdjustmentsResponse;

export type TenancyServiceDeleteRentAdjustmentsError = GooglerpcStatus;

export type TenancyServiceGetData = ObjecttenancyGetResponse;

export type TenancyServiceGetError = GooglerpcStatus;

export type TenancyServiceHasActivatedTenancyData = TenancyHasActivatedTenancyResponse;

export type TenancyServiceHasActivatedTenancyError = GooglerpcStatus;

export type TenancyServiceListData = ObjecttenancyListResponse;

export type TenancyServiceListError = GooglerpcStatus;

export type TenancyServiceListByBillingContactIdData = ObjecttenancyListByBillingContactIdResponse;

export type TenancyServiceListByBillingContactIdError = GooglerpcStatus;

export interface TenancyServiceListByContactIdParams {
  billingContact?: boolean;
  tenants?: boolean;
  correspondence?: boolean;
  guarantors?: boolean;
  subTenants?: boolean;
  contactId: string;
}

export type TenancyServiceListByContactIdData = ObjecttenancyListByContactIdResponse;

export type TenancyServiceListByContactIdError = GooglerpcStatus;

export type TenancyServiceListByDateAndObjectIdsData = TenancyListByDateAndObjectIdsResponse;

export type TenancyServiceListByDateAndObjectIdsError = GooglerpcStatus;

export type TenancyServiceListByGuarantorData = TenancyListByGuarantorResponse;

export type TenancyServiceListByGuarantorError = GooglerpcStatus;

export type TenancyServiceListByIdsData = ObjecttenancyListByIdsResponse;

export type TenancyServiceListByIdsError = GooglerpcStatus;

export interface TenancyServiceListByOwnerParams {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
  address?: string;
  categories?: CategoriesEnum2[];
  /** @format int32 */
  zipFrom?: number;
  /** @format int32 */
  zipTo?: number;
  tenantContactIds?: string[];
  /**
   * Int64 Unix Timestamp, NOT string!
   * @format int64
   */
  startingAtFrom?: string;
  /**
   * Int64 Unix Timestamp, NOT string!
   * @format int64
   */
  startingAtTo?: string;
  /**
   * Int64 Unix Timestamp, NOT string!
   * @format int64
   */
  endingAtFrom?: string;
  /**
   * Int64 Unix Timestamp, NOT string!
   * @format int64
   */
  endingAtTo?: string;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortColdRent?: SortColdRentEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortAdditionalCosts?: SortAdditionalCostsEnum;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortTotalRent?: SortTotalRentEnum;
  /** @format int64 */
  ownerId: string;
}

export enum CategoriesEnum2 {
  UNDEFINED_CATEGORY = "UNDEFINED_CATEGORY",
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  COMMERCIAL = "COMMERCIAL",
  GASTRONOMY = "GASTRONOMY",
  GARDEN = "GARDEN",
  PARKING_SPACE = "PARKING_SPACE",
  SECONDARY_ROOM = "SECONDARY_ROOM",
  PLOT = "PLOT",
  AGRICULTURE = "AGRICULTURE",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortColdRentEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortAdditionalCostsEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortTotalRentEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type TenancyServiceListByOwnerData = ObjecttenancyListByOwnerResponse;

export type TenancyServiceListByOwnerError = GooglerpcStatus;

export enum TenancyServiceListByOwnerParams1CategoriesEnum {
  UNDEFINED_CATEGORY = "UNDEFINED_CATEGORY",
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  COMMERCIAL = "COMMERCIAL",
  GASTRONOMY = "GASTRONOMY",
  GARDEN = "GARDEN",
  PARKING_SPACE = "PARKING_SPACE",
  SECONDARY_ROOM = "SECONDARY_ROOM",
  PLOT = "PLOT",
  AGRICULTURE = "AGRICULTURE",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum TenancyServiceListByOwnerParams1SortColdRentEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum TenancyServiceListByOwnerParams1SortAdditionalCostsEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum TenancyServiceListByOwnerParams1SortTotalRentEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type TenancyServiceListByTenantData = TenancyListByTenantResponse;

export type TenancyServiceListByTenantError = GooglerpcStatus;

export type TenancyServiceListCurrentByObjectIdsData = ObjecttenancyListCurrentByObjectIdsResponse;

export type TenancyServiceListCurrentByObjectIdsError = GooglerpcStatus;

export type TenancyServicePublishUpdatedEventsData = ObjecttenancyPublishUpdatedEventsResponse;

export type TenancyServicePublishUpdatedEventsError = GooglerpcStatus;

export type TenancyServiceUpdateBulkData = ObjecttenancyUpdateBulkResponse;

export type TenancyServiceUpdateBulkError = GooglerpcStatus;

export type TenancyServiceDeleteData = ObjecttenancyDeleteResponse;

export type TenancyServiceDeleteError = GooglerpcStatus;

export type TenancyServiceActivateData = ObjecttenancyActivateResponse;

export type TenancyServiceActivateError = GooglerpcStatus;

export type TenancyServiceAddDocumentsData = ObjecttenancyAddDocumentsResponse;

export type TenancyServiceAddDocumentsError = GooglerpcStatus;

export type TenancyServiceDeactivateData = ObjecttenancyDeactivateResponse;

export type TenancyServiceDeactivateError = GooglerpcStatus;

export interface TenancyServiceSetTerminationPayload {
  /**
   * Date when tenant wants to move out
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  desiredMoveOutAt?: TypeDate;
  documentIds?: string[];
  /**
   * Date when liability ends
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  liabilityEndsAt?: TypeDate;
  /**
   * Date when tenant actually moved out
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  movedOutAt?: TypeDate;
  /**
   * Date when next tenant could possibly move in
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  nextPossibleMoveInAt?: TypeDate;
  /** DO NOT USE! Deprecated and ignored. */
  reasonForTermination?: string;
  /**
   * Date when tenant returns object
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  returningAt?: TypeDate;
  /**
   * Date when tenancy should end
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminateAt?: TypeDate;
  terminatedBy?: TenancyTerminatedBy;
  /**
   * Date when on which date tenancy should be terminated
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminatedOn?: TypeDate;
  /**
   * Date when termination was handed in
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminationCreatedAt?: TypeDate;
  terminationJustification?: string;
  terminationReason?: TenancyTerminationReason[];
  /**
   * Date when termination was sent by tenant
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminationSentByTenantAt?: TypeDate;
  terminationType?: TenancyTerminationType;
}

export type TenancyServiceSetTerminationData = TenancySetTerminationResponse;

export type TenancyServiceSetTerminationError = GooglerpcStatus;

export interface TenancyServiceUpdatePayload {
  activated?: boolean;
  closed?: boolean;
  contract?: ObjecttenancyContract;
  contractAmendments?: string;
  deposit?: ObjecttenancyDeposit;
  /**
   * Date when tenant wants to move out
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  desiredMoveOutAt?: TypeDate;
  documentIds?: string[];
  earlyRightOfTerminationOptions?: TenancyEarlyRightOfTerminationOption[];
  /**
   * Date when tenancy ends
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  endingAt?: TypeDate;
  familyFlat?: boolean;
  finalInvoiceCreated?: boolean;
  indexData?: TenancyIndexData;
  /**
   * Date when tenant liability ends
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  liabilityEndsAt?: TypeDate;
  /**
   * Date when tenant actually moved out
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  movedOutAt?: TypeDate;
  /**
   * Date when next tenant could possibly move in
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  nextPossibleMoveInAt?: TypeDate;
  note?: string;
  objectId?: string;
  opted?: boolean;
  /** DO NOT USE! Deprecated and ignored. */
  reasonForTermination?: string;
  renewalOptions?: TenancyRenewalOption[];
  rent?: ObjecttenancyRent;
  /**
   * Date when tenant returns object
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  returningAt?: TypeDate;
  roommates?: TenancyRoommate[];
  secondaryResidence?: boolean;
  /**
   * Date when tenancy starts
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  startingAt?: TypeDate;
  subTenants?: TenancySubTenant[];
  taxable?: boolean;
  tenant?: ObjecttenancyTenant;
  terminatedBy?: TenancyTerminatedBy;
  /**
   * Date when on which date tenancy should be terminated
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminatedOn?: TypeDate;
  /**
   * Date when termination was handed in
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminationCreatedAt?: TypeDate;
  terminationJustification?: string;
  terminationLandlord?: TenancyTermination;
  terminationReason?: TenancyTerminationReason[];
  /**
   * Date when termination was sent by tenant
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  terminationSentByTenantAt?: TypeDate;
  terminationTenant?: TenancyTermination;
  terminationType?: TenancyTerminationType;
  unjustifiedUse?: TenancyUnjustifiedUse;
  vacancyReason?: TenancyVacancyReason;
}

export type TenancyServiceUpdateData = ObjecttenancyUpdateResponse;

export type TenancyServiceUpdateError = GooglerpcStatus;

export type TenancyServiceDeactivateByNumericIdData = ObjecttenancyDeactivateByNumericIdResponse;

export type TenancyServiceDeactivateByNumericIdError = GooglerpcStatus;

export type ObjectServiceUnPublishData = ObjectUnPublishResponse;

export type ObjectServiceUnPublishError = GooglerpcStatus;

export interface ObjectServiceUpdateMarketingInfoPayload {
  marketingNote?: string;
  /**
   * UNIX timestamp in seconds
   * @format int64
   */
  startMarketingAt?: string;
}

export type ObjectServiceUpdateMarketingInfoData = ObjectUpdateMarketingInfoResponse;

export type ObjectServiceUpdateMarketingInfoError = GooglerpcStatus;

export type ObjectServiceDeleteData = ObjectobjectDeleteResponse;

export type ObjectServiceDeleteError = GooglerpcStatus;

export interface ObjectServiceGetApplicationInfoParams {
  language?: string;
  id: string;
}

export type ObjectServiceGetApplicationInfoData = ObjectGetApplicationInfoResponse;

export type ObjectServiceGetApplicationInfoError = GooglerpcStatus;

export type ObjectServiceGetApplicationLinkData = ObjectGetApplicationLinkResponse;

export type ObjectServiceGetApplicationLinkError = GooglerpcStatus;

export type ObjectServiceGetDefaultAnswerData = ObjectGetDefaultAnswerResponse;

export type ObjectServiceGetDefaultAnswerError = GooglerpcStatus;

export interface ObjectServiceGetMarketingExposeParams {
  language?: string;
  id: string;
}

export type ObjectServiceGetMarketingExposeData = ObjectGetMarketingExposeResponse;

export type ObjectServiceGetMarketingExposeError = GooglerpcStatus;

export type ObjectServiceGetViewingAppointmentData = ObjectGetViewingAppointmentResponse;

export type ObjectServiceGetViewingAppointmentError = GooglerpcStatus;

export interface ObjectServiceSetDefaultAnswerPayload {
  defaultAnswer?: ObjectDefaultAnswer;
}

export type ObjectServiceSetDefaultAnswerData = ObjectSetDefaultAnswerResponse;

export type ObjectServiceSetDefaultAnswerError = GooglerpcStatus;

export interface ObjectServiceSetViewingAppointmentPayload {
  viewingAppointment?: ObjectViewingAppointment;
}

export type ObjectServiceSetViewingAppointmentData = ObjectSetViewingAppointmentResponse;

export type ObjectServiceSetViewingAppointmentError = GooglerpcStatus;

export interface ObjectServiceUpdatePayload {
  acceptsCreditTrust?: boolean;
  administrationType?: ObjectAdministrationType;
  assignedSideRooms?: string;
  availability?: ObjectAvailability;
  /** @format int64 */
  awn?: string;
  category?: ObjectCategory;
  characteristics?: ObjectobjectCharacteristics;
  contacts?: ObjectContacts;
  defaultAnswer?: ObjectDefaultAnswer;
  deposit?: ObjectobjectDeposit;
  description?: string;
  development?: ObjectobjectDevelopment;
  documents?: ObjectobjectDocument[];
  energySources?: ObjectobjectEnergySource[];
  ewid?: string;
  images?: ObjectobjectImage[];
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  inactiveBy?: TypeDate;
  marketingLanguage?: LanguagePreferredLanguage;
  name?: string;
  needsMatching?: boolean;
  note?: string;
  number?: string;
  otoId?: string;
  parking?: ObjectParking[];
  /** Do only use first array element. Others are ignored */
  positions?: ObjectPosition[];
  pricing?: ObjectPricing;
  publishAddress?: boolean;
  renovations?: ObjectRenovation[];
  sharedUse?: string;
  specialFeatures?: string;
  subCategories?: ObjectSubCategory[];
  tags?: string[];
  title?: string;
  type?: ObjectobjectType;
  unusualAgreement?: string;
  utilizations?: ObjectUtilization[];
  /** @format float */
  valueQuota?: number;
  videos?: ObjectVideo[];
  viewingAppointment?: ObjectViewingAppointment;
  virtualTourUrl?: string;
}

export type ObjectServiceUpdateData = ObjectobjectUpdateResponse;

export type ObjectServiceUpdateError = GooglerpcStatus;

export interface ObjectServiceUpdatePricingPayload {
  /** @format float */
  marketGrossRent?: number;
  /** @format float */
  marketHeatingAndAdditional?: number;
  /** @format float */
  marketNetRent?: number;
  /** @format float */
  underMarketGrossRent?: number;
  /** @format float */
  underMarketHeatingAndAdditional?: number;
  /** @format float */
  underMarketNetRent?: number;
  /** @format float */
  upperMarketGrossRent?: number;
  /** @format float */
  upperMarketHeatingAndAdditional?: number;
  /** @format float */
  upperMarketNetRent?: number;
}

export type ObjectServiceUpdatePricingData = ObjectUpdatePricingResponse;

export type ObjectServiceUpdatePricingError = GooglerpcStatus;

export type ContractServiceCreateTemplateMixin2Data = Contractv2CreateTemplateResponse;

export type ContractServiceCreateTemplateMixin2Error = GooglerpcStatus;

export type ContractServiceDeleteTemplateMixin2Data = Contractv2DeleteTemplateResponse;

export type ContractServiceDeleteTemplateMixin2Error = GooglerpcStatus;

export type ContractServiceDeleteRenderFileData = V2DeleteRenderFileResponse;

export type ContractServiceDeleteRenderFileError = GooglerpcStatus;

export type ContractServiceDeleteRenderFilesData = V2DeleteRenderFilesResponse;

export type ContractServiceDeleteRenderFilesError = GooglerpcStatus;

export type ContractServiceGetRenderFileData = V2GetRenderFileResponse;

export type ContractServiceGetRenderFileError = GooglerpcStatus;

export type ContractServiceGetRenderStatusData = V2GetRenderStatusResponse;

export type ContractServiceGetRenderStatusError = GooglerpcStatus;

export type ContractServiceListTemplatesMixin2Data = Contractv2ListTemplatesResponse;

export type ContractServiceListTemplatesMixin2Error = GooglerpcStatus;

export type ContractServiceListRenderStatusData = V2ListRenderStatusResponse;

export type ContractServiceListRenderStatusError = GooglerpcStatus;

export type ContractServiceRenderDeviceTemplateMixin2Data = Contractv2RenderDeviceTemplateResponse;

export type ContractServiceRenderDeviceTemplateMixin2Error = GooglerpcStatus;

export type ContractServiceRenderTenanciesTemplateMixin2Data = Contractv2RenderTenanciesTemplateResponse;

export type ContractServiceRenderTenanciesTemplateMixin2Error = GooglerpcStatus;

export type ContractServiceRenderTenancyTemplateData = V2RenderTenancyTemplateResponse;

export type ContractServiceRenderTenancyTemplateError = GooglerpcStatus;

export interface ContractServiceUpdateTemplateMixin2Payload {
  category?: Contractv2TemplateCategory;
  creatorName?: string;
  enforcePdf?: boolean;
  language?: V2Language;
  mediaId?: string;
  /** @format int64 */
  ownerId?: string;
  title?: string;
}

export type ContractServiceUpdateTemplateMixin2Data = Contractv2UpdateTemplateResponse;

export type ContractServiceUpdateTemplateMixin2Error = GooglerpcStatus;

export type ContractServiceGetRenderFilesData = V2GetRenderFilesResponse;

export type ContractServiceGetRenderFilesError = GooglerpcStatus;

export type ContractServiceRenderCondominiumTemplateMixin2Data = Contractv2RenderCondominiumTemplateResponse;

export type ContractServiceRenderCondominiumTemplateMixin2Error = GooglerpcStatus;

export type ContractServiceRenderCondominiumsTemplateMixin2Data = Contractv2RenderCondominiumsTemplateResponse;

export type ContractServiceRenderCondominiumsTemplateMixin2Error = GooglerpcStatus;

export type ContractServiceRenderContactTemplateMixin2Data = Contractv2RenderContactTemplateResponse;

export type ContractServiceRenderContactTemplateMixin2Error = GooglerpcStatus;

export type ContractServiceRenderContactsTemplateMixin2Data = Contractv2RenderContactsTemplateResponse;

export type ContractServiceRenderContactsTemplateMixin2Error = GooglerpcStatus;

export interface PropertyServiceCountMixin21Params {
  /** @default "UNDEFINED_SCOPE" */
  scope?: ScopeEnum3;
  /** @format int64 */
  accountId: string;
}

/** @default "UNDEFINED_SCOPE" */
export enum ScopeEnum3 {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export type PropertyServiceCountMixin21Data = Propertyv2CountResponse;

export type PropertyServiceCountMixin21Error = GooglerpcStatus;

/** @default "UNDEFINED_SCOPE" */
export enum PropertyServiceCountMixin21Params1ScopeEnum {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export type PropertyServiceCreateMixin21Data = Objectpropertyv2CreateResponse;

export type PropertyServiceCreateMixin21Error = GooglerpcStatus;

export type PropertyServiceGetAllowedPropertyTypesData = V2GetAllowedPropertyTypesResponse;

export type PropertyServiceGetAllowedPropertyTypesError = GooglerpcStatus;

export interface PropertyServiceListMixin21Params {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
  name?: string;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  zipFrom?: number;
  /** @format int32 */
  zipTo?: number;
  city?: string;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortNumber?: SortNumberEnum1;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortLandRegisterNumber?: SortLandRegisterNumberEnum1;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortBuildingCount?: SortBuildingCountEnum1;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortGrossTargetRentPerYear?: SortGrossTargetRentPerYearEnum2;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortGrossActualRentPerYear?: SortGrossActualRentPerYearEnum2;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortNetTargetRentPerYear?: SortNetTargetRentPerYearEnum2;
  /** @default "UNDEFINED_SORT_ORDER" */
  sortNetActualRentPerYear?: SortNetActualRentPerYearEnum2;
  /** @format int64 */
  ownerId: string;
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortNumberEnum1 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortLandRegisterNumberEnum1 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortBuildingCountEnum1 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortGrossTargetRentPerYearEnum2 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortGrossActualRentPerYearEnum2 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortNetTargetRentPerYearEnum2 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum SortNetActualRentPerYearEnum2 {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type PropertyServiceListMixin21Data = Objectpropertyv2ListResponse;

export type PropertyServiceListMixin21Error = GooglerpcStatus;

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListMixin21Params1SortNumberEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListMixin21Params1SortLandRegisterNumberEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListMixin21Params1SortBuildingCountEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListMixin21Params1SortGrossTargetRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListMixin21Params1SortGrossActualRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListMixin21Params1SortNetTargetRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

/** @default "UNDEFINED_SORT_ORDER" */
export enum PropertyServiceListMixin21Params1SortNetActualRentPerYearEnum {
  UNDEFINED_SORT_ORDER = "UNDEFINED_SORT_ORDER",
  ASC = "ASC",
  DESC = "DESC",
}

export type PropertyServiceListByBuildingIdsMixin21Data = Propertyv2ListByBuildingIdsResponse;

export type PropertyServiceListByBuildingIdsMixin21Error = GooglerpcStatus;

export type PropertyServiceListByIdsMixin21Data = Objectpropertyv2ListByIdsResponse;

export type PropertyServiceListByIdsMixin21Error = GooglerpcStatus;

export type PropertyServiceListFilterOptionsData = Propertyv2ListFilterOptionsResponse;

export type PropertyServiceListFilterOptionsError = GooglerpcStatus;

export type PropertyServiceListIdsData = V2ListIdsResponse;

export type PropertyServiceListIdsError = GooglerpcStatus;

export type PropertyServiceSellData = V2SellResponse;

export type PropertyServiceSellError = GooglerpcStatus;

export type PropertyServiceTransferToAccountingMixin21Data = Propertyv2TransferToAccountingResponse;

export type PropertyServiceTransferToAccountingMixin21Error = GooglerpcStatus;

export type PropertyServiceDeleteMixin21Data = Objectpropertyv2DeleteResponse;

export type PropertyServiceDeleteMixin21Error = GooglerpcStatus;

export interface PropertyServiceAddBuildingPayload {
  buildingId?: string;
}

export type PropertyServiceAddBuildingData = Propertyv2AddBuildingResponse;

export type PropertyServiceAddBuildingError = GooglerpcStatus;

export interface PropertyServiceUpdateMixin21Payload {
  additionalRentComponents?: V2AdditionalRentComponentEntry[];
  /** Only zip, city and state are required and set */
  address?: CommonaddressAddress;
  advancePaymentRentComponents?: V2AdvancePaymentRentComponentEntry[];
  bankInfo?: V2BankInfo[];
  buildingIds?: string[];
  constructionZone?: string;
  development?: Propertyv2Development;
  documentIds?: string[];
  egrid?: string;
  flatRateRentComponents?: V2FlatRateRentComponentEntry[];
  imageMediaId?: string;
  landRegisterNumber?: string;
  name?: string;
  note?: string;
  onboardingFinished?: boolean;
  opted?: boolean;
  optedPortionKey?: Propertyv2OptedPortionKey;
  /** @format float */
  plotArea?: number;
  /** @format int64 */
  propertyValue?: string;
  transferToAccounting?: boolean;
  type?: Propertyv2PropertyType;
}

export type PropertyServiceUpdateMixin21Data = Objectpropertyv2UpdateResponse;

export type PropertyServiceUpdateMixin21Error = GooglerpcStatus;

export type ReportingServiceDeleteJdbcConnectionData = ReportingDeleteJdbcConnectionResponse;

export type ReportingServiceDeleteJdbcConnectionError = GooglerpcStatus;

export type ReportingServiceGetBuildingRentReports2Data = ReportingGetBuildingRentReportsResponse;

export type ReportingServiceGetBuildingRentReports2Error = GooglerpcStatus;

export type ReportingServiceGetBuildingRentReportsData = ReportingGetBuildingRentReportsResponse;

export type ReportingServiceGetBuildingRentReportsError = GooglerpcStatus;

export type ReportingServiceGetJdbcConnectionsData = ReportingGetJdbcConnectionsResponse;

export type ReportingServiceGetJdbcConnectionsError = GooglerpcStatus;

export type ReportingServiceGetPropertyRentReportsData = ReportingGetPropertyRentReportsResponse;

export type ReportingServiceGetPropertyRentReportsError = GooglerpcStatus;

export type ReportingServiceGetReportingUrlsData = ReportingGetReportingUrlsResponse;

export type ReportingServiceGetReportingUrlsError = GooglerpcStatus;

export type ReportingServiceHasJdbcConnectionData = ReportingHasJdbcConnectionResponse;

export type ReportingServiceHasJdbcConnectionError = GooglerpcStatus;

export interface ReportingServiceSetJdbcConnectionPayload {
  client?: string;
  host?: string;
  password?: string;
  username?: string;
}

export type ReportingServiceSetJdbcConnectionData = ReportingSetJdbcConnectionResponse;

export type ReportingServiceSetJdbcConnectionError = GooglerpcStatus;

export interface ReportingServiceSetReportingUrlsPayload {
  accounting?: ReportingReportingUrl[];
  accountingOverview?: ReportingReportingUrl[];
  adminOverview?: ReportingReportingUrl[];
  administration?: ReportingReportingUrl[];
  assetOverview?: ReportingReportingUrl[];
  marketing?: ReportingReportingUrl[];
  marketingOverview?: ReportingReportingUrl[];
  overview?: ReportingReportingUrl[];
  ownerOverview?: ReportingReportingUrl[];
  publishHistory?: ReportingReportingUrl[];
  tenantList?: ReportingReportingUrl[];
}

export type ReportingServiceSetReportingUrlsData = ReportingSetReportingUrlsResponse;

export type ReportingServiceSetReportingUrlsError = GooglerpcStatus;

export type ApplicationServiceCreateData = Requestapplicationv2CreateResponse;

export type ApplicationServiceCreateError = GooglerpcStatus;

export interface ApplicationServiceDeleteParams {
  hard?: boolean;
  /** @minItems 1 */
  ids: string[];
}

export type ApplicationServiceDeleteData = Requestapplicationv2DeleteResponse;

export type ApplicationServiceDeleteError = GooglerpcStatus;

export type ApplicationServiceGetByIdData = V2GetByIdResponse;

export type ApplicationServiceGetByIdError = GooglerpcStatus;

export type ApplicationServiceGetLatestData = Requestapplicationv2GetLatestResponse;

export type ApplicationServiceGetLatestError = GooglerpcStatus;

export type ApplicationServiceSendData = V2SendResponse;

export type ApplicationServiceSendError = GooglerpcStatus;

export type ApplicationServiceGetSettingsData = Requestapplicationv2GetSettingsResponse;

export type ApplicationServiceGetSettingsError = GooglerpcStatus;

export interface ApplicationServiceSetSettingsPayload {
  /** @format float */
  contractCreationCost?: number;
  showContractCreationCostAgreement?: boolean;
}

export type ApplicationServiceSetSettingsData = Requestapplicationv2SetSettingsResponse;

export type ApplicationServiceSetSettingsError = GooglerpcStatus;

export type RequestServiceCreateData = Requestrequestv2CreateResponse;

export type RequestServiceCreateError = GooglerpcStatus;

export interface RequestServiceGetMarketingStatisticsParams {
  /** The contactId which should be used for employee based statistics */
  contactIdAssigneeMarketing?: string;
  /**
   * The ID of the account whose marketing statistics are to be calculated (requests to objects he got with share-scope=MARKETING)
   * @format int64
   */
  accountId: string;
}

export type RequestServiceGetMarketingStatisticsData = Requestv2GetMarketingStatisticsResponse;

export type RequestServiceGetMarketingStatisticsError = GooglerpcStatus;

export type RequestServiceGetStatisticsData = Requestv2GetStatisticsResponse;

export type RequestServiceGetStatisticsError = GooglerpcStatus;

export type RequestServiceListByIdsData = Requestrequestv2ListByIdsResponse;

export type RequestServiceListByIdsError = GooglerpcStatus;

export type RequestServiceListByRequesterContactIdData = V2ListByRequesterContactIdResponse;

export type RequestServiceListByRequesterContactIdError = GooglerpcStatus;

export interface RequestServiceListReceivedByObjectIdsParams {
  /** Optional: If new request wasn't read by the receiver at least once */
  new?: boolean;
  /** Optional: If receiver read the last message */
  readByReceiver?: boolean;
  checklistViewingAppointmentContactShared?: boolean;
  checklistViewingAppointmentArranged?: boolean;
  checklistViewingAppointmentDone?: boolean;
  checklistApplicationFormRequested?: boolean;
  checklistApplicationFormReceived?: boolean;
  checklistReferencesObtained?: boolean;
  checklistCreditCheckDone?: boolean;
  checklistContractSent?: boolean;
  checklistContractReceived?: boolean;
  /** Optional */
  marketplaceName?: string[];
  /** @minItems 1 */
  objectIds: string[];
}

export type RequestServiceListReceivedByObjectIdsData = V2ListReceivedByObjectIdsResponse;

export type RequestServiceListReceivedByObjectIdsError = GooglerpcStatus;

export type RequestServiceListSentData = V2ListSentResponse;

export type RequestServiceListSentError = GooglerpcStatus;

export type RequestServiceMarkReadData = Requestrequestv2MarkReadResponse;

export type RequestServiceMarkReadError = GooglerpcStatus;

export type RequestServiceMarkRejectedData = V2MarkRejectedResponse;

export type RequestServiceMarkRejectedError = GooglerpcStatus;

export type RequestServiceMarkUnreadData = Requestrequestv2MarkUnreadResponse;

export type RequestServiceMarkUnreadError = GooglerpcStatus;

export type RequestServicePartiallySetChecklistData = V2PartiallySetChecklistResponse;

export type RequestServicePartiallySetChecklistError = GooglerpcStatus;

export type RequestServiceReactivateData = V2ReactivateResponse;

export type RequestServiceReactivateError = GooglerpcStatus;

export type RequestServiceSetChecklistData = V2SetChecklistResponse;

export type RequestServiceSetChecklistError = GooglerpcStatus;

export type RequestServiceSetFavoriteData = V2SetFavoriteResponse;

export type RequestServiceSetFavoriteError = GooglerpcStatus;

export type RequestServiceSetNotFavoriteData = V2SetNotFavoriteResponse;

export type RequestServiceSetNotFavoriteError = GooglerpcStatus;

export type RequestServiceDeleteData = Requestrequestv2DeleteResponse;

export type RequestServiceDeleteError = GooglerpcStatus;

export interface RequestServiceAddRequestersPayload {
  requesters?: V2Requester[];
}

export type RequestServiceAddRequestersData = V2AddRequestersResponse;

export type RequestServiceAddRequestersError = GooglerpcStatus;

export interface RequestServiceMovePayload {
  objectId?: string;
}

export type RequestServiceMoveData = V2MoveResponse;

export type RequestServiceMoveError = GooglerpcStatus;

export interface RequestServiceRemoveRequestersPayload {
  contactIds?: string[];
}

export type RequestServiceRemoveRequestersData = V2RemoveRequestersResponse;

export type RequestServiceRemoveRequestersError = GooglerpcStatus;

export interface RequestServiceReservePayload {
  /** DO NOT USE! Deprecated. Use tenancyIds */
  tenancyId?: string;
  tenancyIds?: string[];
}

export type RequestServiceReserveData = V2ReserveResponse;

export type RequestServiceReserveError = GooglerpcStatus;

export interface RequestServiceSetEmployerReferencePayload {
  contactId?: string;
  employerReference?: V2EmployerReference;
  generatePdf?: boolean;
  language?: string;
}

export type RequestServiceSetEmployerReferenceData = V2SetEmployerReferenceResponse;

export type RequestServiceSetEmployerReferenceError = GooglerpcStatus;

export type RequestServiceSetEmployerReferenceFinalizedData = V2SetEmployerReferenceFinalizedResponse;

export type RequestServiceSetEmployerReferenceFinalizedError = GooglerpcStatus;

export interface RequestServiceSetLandlordReferencePayload {
  contactId?: string;
  generatePdf?: boolean;
  landlordReference?: V2LandlordReference;
  language?: string;
}

export type RequestServiceSetLandlordReferenceData = V2SetLandlordReferenceResponse;

export type RequestServiceSetLandlordReferenceError = GooglerpcStatus;

export type RequestServiceSetLandlordReferenceFinalizedData = V2SetLandlordReferenceFinalizedResponse;

export type RequestServiceSetLandlordReferenceFinalizedError = GooglerpcStatus;

export type SearchServiceGenerateApiKeyData = SearchGenerateApiKeyResponse;

export type SearchServiceGenerateApiKeyError = GooglerpcStatus;

export type SearchServiceGenerateUnauthorizedApiKeyData = SearchGenerateUnauthorizedApiKeyResponse;

export type SearchServiceGenerateUnauthorizedApiKeyError = GooglerpcStatus;

export type SearchServiceUpdateOngoingData = SearchUpdateOngoingResponse;

export type SearchServiceUpdateOngoingError = GooglerpcStatus;

export interface TableServiceLoadSettingsParams {
  /** @format int64 */
  accountId?: string;
}

export type TableServiceLoadSettingsData = SearchtableLoadSettingsResponse;

export type TableServiceLoadSettingsError = GooglerpcStatus;

export type TableServiceSaveSettingsData = SearchtableSaveSettingsResponse;

export type TableServiceSaveSettingsError = GooglerpcStatus;

export type ShareServiceListAllowedAccountIdsData = ShareListAllowedAccountIdsResponse;

export type ShareServiceListAllowedAccountIdsError = GooglerpcStatus;

export interface ShareServiceListByAccountIdsParams {
  /** If left empty all resourceIds should be returned */
  resourceIdPrefix?: string;
  /** @format int64 */
  sourceAccountId: string;
  /** @minItems 1 */
  targetAccountIds: string[];
}

export type ShareServiceListByAccountIdsData = ShareListByAccountIdsResponse;

export type ShareServiceListByAccountIdsError = GooglerpcStatus;

export type ShareServiceListByResourceData = ShareListByResourceResponse;

export type ShareServiceListByResourceError = GooglerpcStatus;

export type ShareServiceListBySubResourceIdsData = ShareListBySubResourceIdsResponse;

export type ShareServiceListBySubResourceIdsError = GooglerpcStatus;

export interface ShareServiceListByTargetAccountIdParams {
  /** If left empty all resourceIds should be returned */
  resourceIdPrefix?: string;
  /** @format int64 */
  targetAccountId: string;
}

export type ShareServiceListByTargetAccountIdData = ShareListByTargetAccountIdResponse;

export type ShareServiceListByTargetAccountIdError = GooglerpcStatus;

export interface ShareServiceListIdsByTargetAccountIdParams {
  resourceIdPrefix?: string;
  /** @format int64 */
  targetAccountId: string;
}

export type ShareServiceListIdsByTargetAccountIdData = ShareListIdsByTargetAccountIdResponse;

export type ShareServiceListIdsByTargetAccountIdError = GooglerpcStatus;

export type ShareServiceListOwnersData = ShareListOwnersResponse;

export type ShareServiceListOwnersError = GooglerpcStatus;

export type ShareServiceListPartnersData = ShareListPartnersResponse;

export type ShareServiceListPartnersError = GooglerpcStatus;

export type ShareServiceListPartnersOfAssetData = ShareListPartnersOfAssetResponse;

export type ShareServiceListPartnersOfAssetError = GooglerpcStatus;

export type ShareServiceListSubResourcesByResourceIdsData = ShareListSubResourcesByResourceIdsResponse;

export type ShareServiceListSubResourcesByResourceIdsError = GooglerpcStatus;

export interface ShareServiceListSubResourcesByTargetAccountIdParams {
  /** @default "UNDEFINED_SCOPE" */
  scope?: ScopeEnum4;
  subResourceIdPrefix?: string;
  /** @format int64 */
  targetAccountId: string;
}

/** @default "UNDEFINED_SCOPE" */
export enum ScopeEnum4 {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export type ShareServiceListSubResourcesByTargetAccountIdData = ShareListSubResourcesByTargetAccountIdResponse;

export type ShareServiceListSubResourcesByTargetAccountIdError = GooglerpcStatus;

/** @default "UNDEFINED_SCOPE" */
export enum ShareServiceListSubResourcesByTargetAccountIdParams1ScopeEnum {
  UNDEFINED_SCOPE = "UNDEFINED_SCOPE",
  MARKETING_SCOPE = "MARKETING_SCOPE",
  MANAGEMENT_SCOPE = "MANAGEMENT_SCOPE",
  ACCOUNTING_SCOPE = "ACCOUNTING_SCOPE",
  ASSET_SCOPE = "ASSET_SCOPE",
}

export type ShareServiceSetData = ShareshareSetResponse;

export type ShareServiceSetError = GooglerpcStatus;

export type ShareServiceUnsetData = ShareUnsetResponse;

export type ShareServiceUnsetError = GooglerpcStatus;

export interface TicketServiceCountMyUnreadTicketsParams {
  userIds?: string[];
}

export type TicketServiceCountMyUnreadTicketsData = TicketCountMyUnreadTicketsResponse;

export type TicketServiceCountMyUnreadTicketsError = GooglerpcStatus;

export type TicketServiceCountTicketsData = TicketCountTicketsResponse;

export type TicketServiceCountTicketsError = GooglerpcStatus;

export type TicketServiceCreateData = TicketticketCreateResponse;

export type TicketServiceCreateError = GooglerpcStatus;

export type TicketServiceEnableTicketingData = TicketEnableTicketingResponse;

export type TicketServiceEnableTicketingError = GooglerpcStatus;

export type TicketServiceGetByKeyData = TicketGetByKeyResponse;

export type TicketServiceGetByKeyError = GooglerpcStatus;

export type TicketServiceGetByPublicIdData = TicketGetByPublicIdResponse;

export type TicketServiceGetByPublicIdError = GooglerpcStatus;

export type TicketServiceGetNotificationMailboxData = TicketGetNotificationMailboxResponse;

export type TicketServiceGetNotificationMailboxError = GooglerpcStatus;

export type TicketServiceGetTicketStatisticsData = TicketGetTicketStatisticsResponse;

export type TicketServiceGetTicketStatisticsError = GooglerpcStatus;

export interface TicketServiceHasTicketingEnabledParams {
  /** @format int64 */
  accountId?: string;
}

export type TicketServiceHasTicketingEnabledData = TicketHasTicketingEnabledResponse;

export type TicketServiceHasTicketingEnabledError = GooglerpcStatus;

export interface HistoryServiceListChangesParams {
  publicTicketId?: string;
  ticketId: string;
}

export type HistoryServiceListChangesData = HistoryListChangesResponse;

export type HistoryServiceListChangesError = GooglerpcStatus;

export type TicketServiceIsContactInUseData = TicketIsContactInUseResponse;

export type TicketServiceIsContactInUseError = GooglerpcStatus;

export type TicketServiceListByIdsData = TicketticketListByIdsResponse;

export type TicketServiceListByIdsError = GooglerpcStatus;

export interface TicketServiceListByQueryParams {
  query?: string;
}

export type TicketServiceListByQueryData = TicketListByQueryResponse;

export type TicketServiceListByQueryError = GooglerpcStatus;

export type TicketServiceListByReferenceIdsData = TicketticketListByReferenceIdsResponse;

export type TicketServiceListByReferenceIdsError = GooglerpcStatus;

export interface TicketServiceListMyTicketsParams {
  /** Optional filter parameter */
  query?: string;
  /**
   * Optional. Leave out or set to 0 to disable pagination
   * @format int32
   */
  page?: number;
  /**
   * Only used when page is set
   * @format int32
   */
  itemsPerPage?: number;
  /** Optional filter parameter */
  priorities?: PrioritiesEnum[];
  /** Optional filter parameter */
  types?: TypesEnum[];
  /** Optional filter parameter */
  statuses?: StatusesEnum1[];
  /** Optional filter parameter */
  ownerIds?: string[];
  /** Optional filter parameter */
  creatorUserIds?: string[];
  /** Optional filter parameter */
  assigneeUserIds?: string[];
  /** Optional filter parameter */
  correspondenceContactIds?: string[];
  /** Optional filter parameter */
  involvedContactIds?: string[];
  /** Optional filter parameter */
  referenceIds?: string[];
  closedOnly?: boolean;
  overdueOnly?: boolean;
  watchingOnly?: boolean;
  directReferenceIdsOnly?: boolean;
  includeClosed?: boolean;
  unreadByAssigneeOnly?: boolean;
}

export enum PrioritiesEnum {
  DEFAULT = "DEFAULT",
  LOW = "LOW",
  HIGH = "HIGH",
}

export enum TypesEnum {
  TICKET_TYPE_UNDEFINED = "TICKET_TYPE_UNDEFINED",
  TICKET_TYPE_ORDER = "TICKET_TYPE_ORDER",
  TICKET_TYPE_OFFER = "TICKET_TYPE_OFFER",
  TICKET_TYPE_ACTIVITY = "TICKET_TYPE_ACTIVITY",
  TICKET_TYPE_TASK = "TICKET_TYPE_TASK",
}

export enum StatusesEnum1 {
  TICKET_STATUS_OPEN = "TICKET_STATUS_OPEN",
  TICKET_STATUS_IN_PROGRESS = "TICKET_STATUS_IN_PROGRESS",
  TICKET_STATUS_DONE = "TICKET_STATUS_DONE",
  TICKET_STATUS_CLOSED = "TICKET_STATUS_CLOSED",
  TICKET_STATUS_DRAFT = "TICKET_STATUS_DRAFT",
}

export type TicketServiceListMyTicketsData = TicketListMyTicketsResponse;

export type TicketServiceListMyTicketsError = GooglerpcStatus;

export enum TicketServiceListMyTicketsParams1PrioritiesEnum {
  DEFAULT = "DEFAULT",
  LOW = "LOW",
  HIGH = "HIGH",
}

export enum TicketServiceListMyTicketsParams1TypesEnum {
  TICKET_TYPE_UNDEFINED = "TICKET_TYPE_UNDEFINED",
  TICKET_TYPE_ORDER = "TICKET_TYPE_ORDER",
  TICKET_TYPE_OFFER = "TICKET_TYPE_OFFER",
  TICKET_TYPE_ACTIVITY = "TICKET_TYPE_ACTIVITY",
  TICKET_TYPE_TASK = "TICKET_TYPE_TASK",
}

export enum TicketServiceListMyTicketsParams1StatusesEnum {
  TICKET_STATUS_OPEN = "TICKET_STATUS_OPEN",
  TICKET_STATUS_IN_PROGRESS = "TICKET_STATUS_IN_PROGRESS",
  TICKET_STATUS_DONE = "TICKET_STATUS_DONE",
  TICKET_STATUS_CLOSED = "TICKET_STATUS_CLOSED",
  TICKET_STATUS_DRAFT = "TICKET_STATUS_DRAFT",
}

export type TicketServiceListTicketingEnabledData = TicketListTicketingEnabledResponse;

export type TicketServiceListTicketingEnabledError = GooglerpcStatus;

export type TicketServiceMarkRead2Data = TicketticketMarkReadResponse;

export type TicketServiceMarkRead2Error = GooglerpcStatus;

export type TicketServiceMarkUnread2Data = TicketticketMarkUnreadResponse;

export type TicketServiceMarkUnread2Error = GooglerpcStatus;

export type TicketServiceReserveKeysData = TicketReserveKeysResponse;

export type TicketServiceReserveKeysError = GooglerpcStatus;

export type TicketServiceSetSchedulesData = TicketSetSchedulesResponse;

export type TicketServiceSetSchedulesError = GooglerpcStatus;

export interface TicketServiceListSchedulesParams {
  /** Optional filter parameter */
  categories?: string[];
  /** @format int64 */
  accountId: string;
}

export type TicketServiceListSchedulesData = TicketListSchedulesResponse;

export type TicketServiceListSchedulesError = GooglerpcStatus;

export interface TicketServiceSetNotificationMailboxPayload {
  mailboxId?: string;
}

export type TicketServiceSetNotificationMailboxData = TicketSetNotificationMailboxResponse;

export type TicketServiceSetNotificationMailboxError = GooglerpcStatus;

export type TicketServiceMarkReadData = TicketticketMarkReadResponse;

export type TicketServiceMarkReadError = GooglerpcStatus;

export type TicketServiceMarkUnreadData = TicketticketMarkUnreadResponse;

export type TicketServiceMarkUnreadError = GooglerpcStatus;

export interface TicketServiceAddWatcherPayload {
  watcher?: TicketSetTicketWatcher;
}

export type TicketServiceAddWatcherData = TicketAddWatcherResponse;

export type TicketServiceAddWatcherError = GooglerpcStatus;

export interface TicketServiceChangeStatusPayload {
  email?: string;
  name?: string;
  status?: TicketTicketStatus;
}

export type TicketServiceChangeStatusData = TicketChangeStatusResponse;

export type TicketServiceChangeStatusError = GooglerpcStatus;

export interface TicketServiceDeleteParams {
  deleteRecurrence?: boolean;
  id: string;
}

export type TicketServiceDeleteData = TicketticketDeleteResponse;

export type TicketServiceDeleteError = GooglerpcStatus;

export interface TicketServiceUpdatePayload {
  update?: {
    assignee?: TicketSetAssignee;
    correspondences?: TicketSetCorrespondence[];
    costs?: TicketticketCosts;
    description?: string;
    /**
     * * A full date, with non-zero year, month, and day values
     * * A month and day value, with a zero year, such as an anniversary
     * * A year on its own, with zero month and day values
     * * A year and month value, with a zero day, such as a credit card expiration
     * date
     *
     * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
     * `google.protobuf.Timestamp`.
     */
    dueDate?: TypeDate;
    externalTicketWatchers?: TicketExternalTicketWatcher[];
    links?: TicketTicketLink[];
    mediaIds?: string[];
    /** DEPRECATED!. Use onSiteContacts array. */
    onSiteContact?: TicketSetOnSiteContact;
    onSiteContacts?: TicketSetOnSiteContact[];
    /** @format int64 */
    ownerId?: string;
    priority?: TicketTicketPriority;
    recurring?: TicketRecurring;
    references?: TicketTicketReference[];
    /**
     * * A full date, with non-zero year, month, and day values
     * * A month and day value, with a zero year, such as an anniversary
     * * A year on its own, with zero month and day values
     * * A year and month value, with a zero day, such as a credit card expiration
     * date
     *
     * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
     * `google.protobuf.Timestamp`.
     */
    startDate?: TypeDate;
    status?: TicketTicketStatus;
    title?: string;
    type?: TicketTicketType;
    watchers?: TicketSetTicketWatcher[];
  };
  updateMask?: string;
  updateRecurrence?: boolean;
}

export type TicketServiceUpdateData = TicketticketUpdateResponse;

export type TicketServiceUpdateError = GooglerpcStatus;

export type PortalServiceAddLinksData = PortalAddLinksResponse;

export type PortalServiceAddLinksError = GooglerpcStatus;

export type PortalServiceEnablePortalData = PortalEnablePortalResponse;

export type PortalServiceEnablePortalError = GooglerpcStatus;

export type PortalServiceGetPortalDataData = PortalGetPortalDataResponse;

export type PortalServiceGetPortalDataError = GooglerpcStatus;

export type PortalServiceHasPortalEnabledData = PortalHasPortalEnabledResponse;

export type PortalServiceHasPortalEnabledError = GooglerpcStatus;

export type PortalServiceListDocumentGroupsData = PortalListDocumentGroupsResponse;

export type PortalServiceListDocumentGroupsError = GooglerpcStatus;

export type PortalServiceListLinksByReferenceIdsData = PortalListLinksByReferenceIdsResponse;

export type PortalServiceListLinksByReferenceIdsError = GooglerpcStatus;

export type PortalServiceRemoveLinksData = PortalRemoveLinksResponse;

export type PortalServiceRemoveLinksError = GooglerpcStatus;

export type PortalServiceUpdateLinksData = PortalUpdateLinksResponse;

export type PortalServiceUpdateLinksError = GooglerpcStatus;

export type AccountServiceCreateData = Useraccountv2CreateResponse;

export type AccountServiceCreateError = GooglerpcStatus;

export type AccountServiceGetData = Useraccountv2GetResponse;

export type AccountServiceGetError = GooglerpcStatus;

export interface AccountServiceListParams {
  /**
   * Items per page to load. Defaults to 10
   * @format int32
   */
  perPage?: number;
  /** The type to filter for */
  types?: TypesEnum1[];
  /** The type to filter for */
  subTypes?: SubTypesEnum[];
  /** Email to filter for (optional) */
  email?: string;
  /** Company name to filter for (optional) */
  companyName?: string;
  /** Contact person name to filter for (optional) */
  contactPersonName?: string;
  /** Optional: All given states need to be part of marketing OR management */
  states?: StatesEnum[];
  /**
   * Page to load. Defaults to 1
   * @format int32
   */
  page: number;
}

export enum TypesEnum1 {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
  PLATFORM_EMPLOYEE = "PLATFORM_EMPLOYEE",
}

export enum SubTypesEnum {
  UNDEFINED = "UNDEFINED",
  TENANT = "TENANT",
  LANDLORD = "LANDLORD",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  MARKETING = "MARKETING",
  OWNER = "OWNER",
  LANDLORD_MARKETING = "LANDLORD_MARKETING",
  ACCOUNTING = "ACCOUNTING",
  LANDLORD_ACCOUNTING = "LANDLORD_ACCOUNTING",
  MARKETING_ACCOUNTING = "MARKETING_ACCOUNTING",
  LANDLORD_MARKETING_ACCOUNTING = "LANDLORD_MARKETING_ACCOUNTING",
  ASSET_MANAGEMENT = "ASSET_MANAGEMENT",
  ASSET_LANDLORD = "ASSET_LANDLORD",
  ASSET_MARKETING = "ASSET_MARKETING",
  ASSET_ACCOUNTING = "ASSET_ACCOUNTING",
  ASSET_LANDLORD_MARKETING = "ASSET_LANDLORD_MARKETING",
  ASSET_LANDLORD_ACCOUNTING = "ASSET_LANDLORD_ACCOUNTING",
  ASSET_MARKETING_ACCOUNTING = "ASSET_MARKETING_ACCOUNTING",
  ASSET_LANDLORD_MARKETING_ACCOUNTING = "ASSET_LANDLORD_MARKETING_ACCOUNTING",
}

export enum StatesEnum {
  UNDEFINED_STATE = "UNDEFINED_STATE",
  CH_ZH = "CH_ZH",
  CH_BE = "CH_BE",
  CH_LU = "CH_LU",
  CH_UR = "CH_UR",
  CH_SZ = "CH_SZ",
  CH_OW = "CH_OW",
  CH_NW = "CH_NW",
  CH_GL = "CH_GL",
  CH_ZG = "CH_ZG",
  CH_FR = "CH_FR",
  CH_SO = "CH_SO",
  CH_BS = "CH_BS",
  CH_BL = "CH_BL",
  CH_SH = "CH_SH",
  CH_AR = "CH_AR",
  CH_AI = "CH_AI",
  CH_SG = "CH_SG",
  CH_GR = "CH_GR",
  CH_AG = "CH_AG",
  CH_TG = "CH_TG",
  CH_TI = "CH_TI",
  CH_VD = "CH_VD",
  CH_VS = "CH_VS",
  CH_NE = "CH_NE",
  CH_GE = "CH_GE",
  CH_JU = "CH_JU",
}

export type AccountServiceListData = Useraccountv2ListResponse;

export type AccountServiceListError = GooglerpcStatus;

export enum AccountServiceListParams1TypesEnum {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
  PLATFORM_EMPLOYEE = "PLATFORM_EMPLOYEE",
}

export enum AccountServiceListParams1SubTypesEnum {
  UNDEFINED = "UNDEFINED",
  TENANT = "TENANT",
  LANDLORD = "LANDLORD",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  MARKETING = "MARKETING",
  OWNER = "OWNER",
  LANDLORD_MARKETING = "LANDLORD_MARKETING",
  ACCOUNTING = "ACCOUNTING",
  LANDLORD_ACCOUNTING = "LANDLORD_ACCOUNTING",
  MARKETING_ACCOUNTING = "MARKETING_ACCOUNTING",
  LANDLORD_MARKETING_ACCOUNTING = "LANDLORD_MARKETING_ACCOUNTING",
  ASSET_MANAGEMENT = "ASSET_MANAGEMENT",
  ASSET_LANDLORD = "ASSET_LANDLORD",
  ASSET_MARKETING = "ASSET_MARKETING",
  ASSET_ACCOUNTING = "ASSET_ACCOUNTING",
  ASSET_LANDLORD_MARKETING = "ASSET_LANDLORD_MARKETING",
  ASSET_LANDLORD_ACCOUNTING = "ASSET_LANDLORD_ACCOUNTING",
  ASSET_MARKETING_ACCOUNTING = "ASSET_MARKETING_ACCOUNTING",
  ASSET_LANDLORD_MARKETING_ACCOUNTING = "ASSET_LANDLORD_MARKETING_ACCOUNTING",
}

export enum AccountServiceListParams1StatesEnum {
  UNDEFINED_STATE = "UNDEFINED_STATE",
  CH_ZH = "CH_ZH",
  CH_BE = "CH_BE",
  CH_LU = "CH_LU",
  CH_UR = "CH_UR",
  CH_SZ = "CH_SZ",
  CH_OW = "CH_OW",
  CH_NW = "CH_NW",
  CH_GL = "CH_GL",
  CH_ZG = "CH_ZG",
  CH_FR = "CH_FR",
  CH_SO = "CH_SO",
  CH_BS = "CH_BS",
  CH_BL = "CH_BL",
  CH_SH = "CH_SH",
  CH_AR = "CH_AR",
  CH_AI = "CH_AI",
  CH_SG = "CH_SG",
  CH_GR = "CH_GR",
  CH_AG = "CH_AG",
  CH_TG = "CH_TG",
  CH_TI = "CH_TI",
  CH_VD = "CH_VD",
  CH_VS = "CH_VS",
  CH_NE = "CH_NE",
  CH_GE = "CH_GE",
  CH_JU = "CH_JU",
}

export type AccountServiceListByIdsData = Useraccountv2ListByIdsResponse;

export type AccountServiceListByIdsError = GooglerpcStatus;

export interface AccountServiceChangeBillingPayload {
  billable?: boolean;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  billableSetDate?: TypeDate;
  /** @format int64 */
  billingAddressAccountId?: string;
  /**
   * * A full date, with non-zero year, month, and day values
   * * A month and day value, with a zero year, such as an anniversary
   * * A year on its own, with zero month and day values
   * * A year and month value, with a zero day, such as a credit card expiration
   * date
   *
   * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
   * `google.protobuf.Timestamp`.
   */
  contractStart?: TypeDate;
  note?: string;
  pricingModel?: V2PricingModel;
}

export type AccountServiceChangeBillingData = V2ChangeBillingResponse;

export type AccountServiceChangeBillingError = GooglerpcStatus;

export interface AccountServiceChangeSubTypePayload {
  subType?: Commonv2SubType;
}

export type AccountServiceChangeSubTypeData = V2ChangeSubTypeResponse;

export type AccountServiceChangeSubTypeError = GooglerpcStatus;

export type AccountServiceGetFontSettingsData = V2GetFontSettingsResponse;

export type AccountServiceGetFontSettingsError = GooglerpcStatus;

export interface AccountServiceSetFontSettingsPayload {
  family?: string;
  size?: string;
}

export type AccountServiceSetFontSettingsData = V2SetFontSettingsResponse;

export type AccountServiceSetFontSettingsError = GooglerpcStatus;

export interface AccountServiceSetLogoPayload {
  logoUrl?: string;
}

export type AccountServiceSetLogoData = V2SetLogoResponse;

export type AccountServiceSetLogoError = GooglerpcStatus;

export interface AccountServiceUpdateCompanyPayload {
  company?: Usercommonv2Company;
}

export type AccountServiceUpdateCompanyData = Useraccountv2UpdateCompanyResponse;

export type AccountServiceUpdateCompanyError = GooglerpcStatus;

export interface AccountServiceUpdateContactPersonPayload {
  contactPerson?: Useraccountv2ContactPerson;
}

export type AccountServiceUpdateContactPersonData = Useraccountv2UpdateContactPersonResponse;

export type AccountServiceUpdateContactPersonError = GooglerpcStatus;

export interface AccountServiceUpdateIndividualPayload {
  individual?: Usercommonv2Individual;
}

export type AccountServiceUpdateIndividualData = V2UpdateIndividualResponse;

export type AccountServiceUpdateIndividualError = GooglerpcStatus;

export interface AccountServiceUpdateStatesPayload {
  accountingStates?: AddressState[];
  assetStates?: AddressState[];
  managementStates?: AddressState[];
  marketingStates?: AddressState[];
}

export type AccountServiceUpdateStatesData = V2UpdateStatesResponse;

export type AccountServiceUpdateStatesError = GooglerpcStatus;

export type UserServiceGetData = Userv2User;

export type UserServiceGetError = GooglerpcStatus;

export type UserServiceGetByMailData = Userv2User;

export type UserServiceGetByMailError = GooglerpcStatus;

export type UserServiceInviteEmployeeData = Userv2User;

export type UserServiceInviteEmployeeError = GooglerpcStatus;

export interface UserServiceListParams {
  /**
   * Starting from 1
   * @format int32
   */
  page?: number;
  /** @format int32 */
  itemsPerPage?: number;
  /** @format int64 */
  accountId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export type UserServiceListData = Useruserv2ListResponse;

export type UserServiceListError = GooglerpcStatus;

export type UserServiceListAllEmployeesData = V2ListAllEmployeesResponse;

export type UserServiceListAllEmployeesError = GooglerpcStatus;

export type UserServiceListEmployeesData = Useruserv2ListEmployeesResponse;

export type UserServiceListEmployeesError = GooglerpcStatus;

export interface NotificationServiceGetTokenParams {
  userId?: string;
}

export type NotificationServiceGetTokenData = V2GetTokenResponse;

export type NotificationServiceGetTokenError = GooglerpcStatus;

export type NotificationServicePublishUpdateMessageData = V2PublishUpdateMessageResponse;

export type NotificationServicePublishUpdateMessageError = GooglerpcStatus;

export interface NotificationServiceGetSettingsParams {
  userId?: string;
}

export type NotificationServiceGetSettingsData = Usernotificationv2GetSettingsResponse;

export type NotificationServiceGetSettingsError = GooglerpcStatus;

export type NotificationServiceSetSettingsData = Usernotificationv2SetSettingsResponse;

export type NotificationServiceSetSettingsError = GooglerpcStatus;

export type PartnerDocumentServiceAddData = PartnerDocumentAddResponse;

export type PartnerDocumentServiceAddError = GooglerpcStatus;

export interface PartnerDocumentServiceRemoveParams {
  /** @format int64 */
  sourceAccountId?: string;
  /** @format int64 */
  targetAccountId?: string;
  documentId?: string;
}

export type PartnerDocumentServiceRemoveData = PartnerDocumentRemoveResponse;

export type PartnerDocumentServiceRemoveError = GooglerpcStatus;

export type PartnerDocumentServiceGetData = PartnerDocumentpartnerDocumentGetResponse;

export type PartnerDocumentServiceGetError = GooglerpcStatus;

export type UserServiceRegisterData = V2RegisterResponse;

export type UserServiceRegisterError = GooglerpcStatus;

export type UserServiceResolveTokenData = V2ResolveTokenResponse;

export type UserServiceResolveTokenError = GooglerpcStatus;

export type UserServiceBlockData = V2BlockResponse;

export type UserServiceBlockError = GooglerpcStatus;

export type UserServiceUnblockData = V2UnblockResponse;

export type UserServiceUnblockError = GooglerpcStatus;

export type UserServiceAddRoleData = Userv2User;

export type UserServiceAddRoleError = GooglerpcStatus;

export interface UserServiceChangeEmailPayload {
  newEmail?: string;
}

export type UserServiceChangeEmailData = Userv2User;

export type UserServiceChangeEmailError = GooglerpcStatus;

export interface UserServiceChangePasswordPayload {
  newPassword?: string;
}

export type UserServiceChangePasswordData = Userv2User;

export type UserServiceChangePasswordError = GooglerpcStatus;

export type UserServiceRemoveRoleData = Userv2User;

export type UserServiceRemoveRoleError = GooglerpcStatus;

export interface UserServiceUpdateProfilePayload {
  profile?: Userv2Profile;
}

export type UserServiceUpdateProfileData = Userv2User;

export type UserServiceUpdateProfileError = GooglerpcStatus;

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Main API Document
 * @version 1.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  web = {
    /**
     * No description
     *
     * @tags CertifactionService
     * @name CertifactionServiceEnableDocumentSignature
     * @request POST:/web/common/certifaction/enable
     */
    certifactionServiceEnableDocumentSignature: (
      query: CertifactionServiceEnableDocumentSignatureParams,
      params: RequestParams = {},
    ) =>
      this.request<CertifactionServiceEnableDocumentSignatureData, CertifactionServiceEnableDocumentSignatureError>({
        path: `/web/common/certifaction/enable`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CertifactionService
     * @name CertifactionServiceHasDocumentSignatureEnabled
     * @summary Misc
     * @request GET:/web/common/certifaction/isEnabled
     */
    certifactionServiceHasDocumentSignatureEnabled: (
      query: CertifactionServiceHasDocumentSignatureEnabledParams,
      params: RequestParams = {},
    ) =>
      this.request<
        CertifactionServiceHasDocumentSignatureEnabledData,
        CertifactionServiceHasDocumentSignatureEnabledError
      >({
        path: `/web/common/certifaction/isEnabled`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CertifactionService
     * @name CertifactionServiceListSignRequests
     * @request GET:/web/common/certifaction/listSignRequests
     */
    certifactionServiceListSignRequests: (
      query: CertifactionServiceListSignRequestsParams,
      params: RequestParams = {},
    ) =>
      this.request<CertifactionServiceListSignRequestsData, CertifactionServiceListSignRequestsError>({
        path: `/web/common/certifaction/listSignRequests`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CertifactionService
     * @name CertifactionServiceSignDocument
     * @request POST:/web/common/certifaction/signDocument
     */
    certifactionServiceSignDocument: (query: CertifactionServiceSignDocumentParams, params: RequestParams = {}) =>
      this.request<CertifactionServiceSignDocumentData, CertifactionServiceSignDocumentError>({
        path: `/web/common/certifaction/signDocument`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CertifactionService
     * @name CertifactionServiceDownloadFile
     * @request GET:/web/common/certifaction/{id}/downloadFile
     */
    certifactionServiceDownloadFile: (id: string, params: RequestParams = {}) =>
      this.request<CertifactionServiceDownloadFileData, CertifactionServiceDownloadFileError>({
        path: `/web/common/certifaction/${id}/downloadFile`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CredittrustService
     * @name CredittrustServiceGetCertificate
     * @request GET:/web/common/credittrust/getCertificate
     */
    credittrustServiceGetCertificate: (query: CredittrustServiceGetCertificateParams, params: RequestParams = {}) =>
      this.request<CredittrustServiceGetCertificateData, CredittrustServiceGetCertificateError>({
        path: `/web/common/credittrust/getCertificate`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CredittrustService
     * @name CredittrustServiceOrderCertificate
     * @request POST:/web/common/credittrust/orderCertificate
     */
    credittrustServiceOrderCertificate: (body: CredittrustOrderCertificateRequest, params: RequestParams = {}) =>
      this.request<CredittrustServiceOrderCertificateData, CredittrustServiceOrderCertificateError>({
        path: `/web/common/credittrust/orderCertificate`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CredittrustService
     * @name CredittrustServiceVerifyCertificate
     * @request POST:/web/common/credittrust/verifyCertificate
     */
    credittrustServiceVerifyCertificate: (body: CredittrustVerifyCertificateRequest, params: RequestParams = {}) =>
      this.request<CredittrustServiceVerifyCertificateData, CredittrustServiceVerifyCertificateError>({
        path: `/web/common/credittrust/verifyCertificate`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepsignService
     * @name DeepsignServiceCountOpenSignRequests
     * @request GET:/web/common/deepsign/countOpenSignRequests/{accountId}
     */
    deepsignServiceCountOpenSignRequests: (accountId: string, params: RequestParams = {}) =>
      this.request<DeepsignServiceCountOpenSignRequestsData, DeepsignServiceCountOpenSignRequestsError>({
        path: `/web/common/deepsign/countOpenSignRequests/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepsignService
     * @name DeepsignServiceGetPartnerAccessUrl
     * @request GET:/web/common/deepsign/getPartnerAccessUrl
     */
    deepsignServiceGetPartnerAccessUrl: (query: DeepsignServiceGetPartnerAccessUrlParams, params: RequestParams = {}) =>
      this.request<DeepsignServiceGetPartnerAccessUrlData, DeepsignServiceGetPartnerAccessUrlError>({
        path: `/web/common/deepsign/getPartnerAccessUrl`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepsignService
     * @name DeepsignServiceHasDocumentSignatureEnabled
     * @summary Misc
     * @request GET:/web/common/deepsign/isEnabled
     */
    deepsignServiceHasDocumentSignatureEnabled: (
      query: DeepsignServiceHasDocumentSignatureEnabledParams,
      params: RequestParams = {},
    ) =>
      this.request<DeepsignServiceHasDocumentSignatureEnabledData, DeepsignServiceHasDocumentSignatureEnabledError>({
        path: `/web/common/deepsign/isEnabled`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepsignService
     * @name DeepsignServiceListSignRequests
     * @request GET:/web/common/deepsign/listSignRequests
     */
    deepsignServiceListSignRequests: (query: DeepsignServiceListSignRequestsParams, params: RequestParams = {}) =>
      this.request<DeepsignServiceListSignRequestsData, DeepsignServiceListSignRequestsError>({
        path: `/web/common/deepsign/listSignRequests`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepsignService
     * @name DeepsignServiceListSignRequestsByAttachToReferenceIds
     * @request POST:/web/common/deepsign/listSignRequestsByAttachToReferenceIds
     */
    deepsignServiceListSignRequestsByAttachToReferenceIds: (
      body: DeepsignListSignRequestsByAttachToReferenceIdsRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        DeepsignServiceListSignRequestsByAttachToReferenceIdsData,
        DeepsignServiceListSignRequestsByAttachToReferenceIdsError
      >({
        path: `/web/common/deepsign/listSignRequestsByAttachToReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepsignService
     * @name DeepsignServiceSignDocument
     * @request POST:/web/common/deepsign/signDocument
     */
    deepsignServiceSignDocument: (body: CommondeepsignSignDocumentRequest, params: RequestParams = {}) =>
      this.request<DeepsignServiceSignDocumentData, DeepsignServiceSignDocumentError>({
        path: `/web/common/deepsign/signDocument`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepsignService
     * @name DeepsignServiceCancelSignRequest
     * @request POST:/web/common/deepsign/{id}/cancel
     */
    deepsignServiceCancelSignRequest: (
      { id, ...query }: DeepsignServiceCancelSignRequestParams,
      params: RequestParams = {},
    ) =>
      this.request<DeepsignServiceCancelSignRequestData, DeepsignServiceCancelSignRequestError>({
        path: `/web/common/deepsign/${id}/cancel`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepsignService
     * @name DeepsignServiceDownloadFile
     * @request GET:/web/common/deepsign/{id}/downloadFile
     */
    deepsignServiceDownloadFile: (id: string, params: RequestParams = {}) =>
      this.request<DeepsignServiceDownloadFileData, DeepsignServiceDownloadFileError>({
        path: `/web/common/deepsign/${id}/downloadFile`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteService
     * @name NoteServiceCreate
     * @request POST:/web/common/note/create
     */
    noteServiceCreate: (body: CommonnoteCreateRequest, params: RequestParams = {}) =>
      this.request<NoteServiceCreateData, NoteServiceCreateError>({
        path: `/web/common/note/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteService
     * @name NoteServiceCreateFromMail
     * @request POST:/web/common/note/createFromMail
     */
    noteServiceCreateFromMail: (body: NoteCreateFromMailRequest, params: RequestParams = {}) =>
      this.request<NoteServiceCreateFromMailData, NoteServiceCreateFromMailError>({
        path: `/web/common/note/createFromMail`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteService
     * @name NoteServiceCreateUnregistered
     * @request POST:/web/common/note/createUnregistered
     */
    noteServiceCreateUnregistered: (body: NoteCreateUnregisteredRequest, params: RequestParams = {}) =>
      this.request<NoteServiceCreateUnregisteredData, NoteServiceCreateUnregisteredError>({
        path: `/web/common/note/createUnregistered`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteService
     * @name NoteServiceDelete
     * @request DELETE:/web/common/note/delete/{id}
     */
    noteServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<NoteServiceDeleteData, NoteServiceDeleteError>({
        path: `/web/common/note/delete/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteService
     * @name NoteServiceListByReferenceIds
     * @request POST:/web/common/note/listByReferenceIds
     */
    noteServiceListByReferenceIds: (body: CommonnoteListByReferenceIdsRequest, params: RequestParams = {}) =>
      this.request<NoteServiceListByReferenceIdsData, NoteServiceListByReferenceIdsError>({
        path: `/web/common/note/listByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteService
     * @name NoteServiceUpdate
     * @request POST:/web/common/note/{id}/update
     */
    noteServiceUpdate: (id: string, body: NoteServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<NoteServiceUpdateData, NoteServiceUpdateError>({
        path: `/web/common/note/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ZipService
     * @name ZipServiceGetInfo
     * @request GET:/web/common/zip/{zip}
     */
    zipServiceGetInfo: (zip: string, params: RequestParams = {}) =>
      this.request<ZipServiceGetInfoData, ZipServiceGetInfoError>({
        path: `/web/common/zip/${zip}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceActivate
     * @request POST:/web/v2/contact/activate
     */
    contactServiceActivate: (body: Contactv2ActivateRequest, params: RequestParams = {}) =>
      this.request<ContactServiceActivateData, ContactServiceActivateError>({
        path: `/web/v2/contact/activate`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceCreateMixin7
     * @summary Create
     * @request POST:/web/v2/contact/activity/create
     */
    activityServiceCreateMixin7: (body: Contactactivityv2CreateRequest, params: RequestParams = {}) =>
      this.request<ActivityServiceCreateMixin7Data, ActivityServiceCreateMixin7Error>({
        path: `/web/v2/contact/activity/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceDeleteMixin7
     * @summary Delete
     * @request DELETE:/web/v2/contact/activity/delete/{id}
     */
    activityServiceDeleteMixin7: (id: string, params: RequestParams = {}) =>
      this.request<ActivityServiceDeleteMixin7Data, ActivityServiceDeleteMixin7Error>({
        path: `/web/v2/contact/activity/delete/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceGetMixin7
     * @summary Read
     * @request GET:/web/v2/contact/activity/get/{id}
     */
    activityServiceGetMixin7: (id: string, params: RequestParams = {}) =>
      this.request<ActivityServiceGetMixin7Data, ActivityServiceGetMixin7Error>({
        path: `/web/v2/contact/activity/get/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceListByContactIds
     * @request POST:/web/v2/contact/activity/listByContactIds
     */
    activityServiceListByContactIds: (body: Activityv2ListByContactIdsRequest, params: RequestParams = {}) =>
      this.request<ActivityServiceListByContactIdsData, ActivityServiceListByContactIdsError>({
        path: `/web/v2/contact/activity/listByContactIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceListByReferenceIdsMixin7
     * @request POST:/web/v2/contact/activity/listByReferenceIds
     */
    activityServiceListByReferenceIdsMixin7: (body: Activityv2ListByReferenceIdsRequest, params: RequestParams = {}) =>
      this.request<ActivityServiceListByReferenceIdsMixin7Data, ActivityServiceListByReferenceIdsMixin7Error>({
        path: `/web/v2/contact/activity/listByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceUpdateMixin7
     * @summary Update
     * @request POST:/web/v2/contact/activity/update/{id}
     */
    activityServiceUpdateMixin7: (id: string, body: ActivityServiceUpdateMixin7Payload, params: RequestParams = {}) =>
      this.request<ActivityServiceUpdateMixin7Data, ActivityServiceUpdateMixin7Error>({
        path: `/web/v2/contact/activity/update/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceCheckDuplicates
     * @request POST:/web/v2/contact/checkDuplicates
     */
    contactServiceCheckDuplicates: (body: V2CheckDuplicatesRequest, params: RequestParams = {}) =>
      this.request<ContactServiceCheckDuplicatesData, ContactServiceCheckDuplicatesError>({
        path: `/web/v2/contact/checkDuplicates`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceCopyContacts
     * @request POST:/web/v2/contact/copy
     */
    contactServiceCopyContacts: (body: V2CopyContactsRequest, params: RequestParams = {}) =>
      this.request<ContactServiceCopyContactsData, ContactServiceCopyContactsError>({
        path: `/web/v2/contact/copy`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceCreateCompany
     * @request POST:/web/v2/contact/createCompany
     */
    contactServiceCreateCompany: (body: V2CreateCompanyRequest, params: RequestParams = {}) =>
      this.request<ContactServiceCreateCompanyData, ContactServiceCreateCompanyError>({
        path: `/web/v2/contact/createCompany`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceCreatePerson
     * @request POST:/web/v2/contact/createPerson
     */
    contactServiceCreatePerson: (body: V2CreatePersonRequest, params: RequestParams = {}) =>
      this.request<ContactServiceCreatePersonData, ContactServiceCreatePersonError>({
        path: `/web/v2/contact/createPerson`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceDeactivate
     * @request POST:/web/v2/contact/deactivate
     */
    contactServiceDeactivate: (body: Contactv2DeactivateRequest, params: RequestParams = {}) =>
      this.request<ContactServiceDeactivateData, ContactServiceDeactivateError>({
        path: `/web/v2/contact/deactivate`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceDelete
     * @request DELETE:/web/v2/contact/delete/{ids}
     */
    contactServiceDelete: (ids: string[], params: RequestParams = {}) =>
      this.request<ContactServiceDeleteData, ContactServiceDeleteError>({
        path: `/web/v2/contact/delete/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceGetOwnerContact
     * @request GET:/web/v2/contact/getOwnerContact/{accountId}
     */
    contactServiceGetOwnerContact: (accountId: string, params: RequestParams = {}) =>
      this.request<ContactServiceGetOwnerContactData, ContactServiceGetOwnerContactError>({
        path: `/web/v2/contact/getOwnerContact/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceGetUsedTags
     * @request GET:/web/v2/contact/getUsedTags/{accountId}
     */
    contactServiceGetUsedTags: (accountId: string, params: RequestParams = {}) =>
      this.request<ContactServiceGetUsedTagsData, ContactServiceGetUsedTagsError>({
        path: `/web/v2/contact/getUsedTags/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceLinkCompanies
     * @request POST:/web/v2/contact/linkCompanies
     */
    contactServiceLinkCompanies: (body: V2LinkCompaniesRequest, params: RequestParams = {}) =>
      this.request<ContactServiceLinkCompaniesData, ContactServiceLinkCompaniesError>({
        path: `/web/v2/contact/linkCompanies`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceLinkPersons
     * @request POST:/web/v2/contact/linkPersons
     */
    contactServiceLinkPersons: (body: V2LinkPersonsRequest, params: RequestParams = {}) =>
      this.request<ContactServiceLinkPersonsData, ContactServiceLinkPersonsError>({
        path: `/web/v2/contact/linkPersons`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceList
     * @request GET:/web/v2/contact/list/{ownerId}
     */
    contactServiceList: (ownerId: string, params: RequestParams = {}) =>
      this.request<ContactServiceListData, ContactServiceListError>({
        path: `/web/v2/contact/list/${ownerId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceListEmployees
     * @request GET:/web/v2/contact/listEmployees/{ownerId}
     */
    contactServiceListEmployees: (ownerId: string, params: RequestParams = {}) =>
      this.request<ContactServiceListEmployeesData, ContactServiceListEmployeesError>({
        path: `/web/v2/contact/listEmployees/${ownerId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServicePublishUpdatedEvents
     * @request POST:/web/v2/contact/publishUpdatedEvents
     */
    contactServicePublishUpdatedEvents: (body: Contactv2PublishUpdatedEventsRequest, params: RequestParams = {}) =>
      this.request<ContactServicePublishUpdatedEventsData, ContactServicePublishUpdatedEventsError>({
        path: `/web/v2/contact/publishUpdatedEvents`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceResolve
     * @request POST:/web/v2/contact/resolve
     */
    contactServiceResolve: (body: Contactv2ResolveRequest, params: RequestParams = {}) =>
      this.request<ContactServiceResolveData, ContactServiceResolveError>({
        path: `/web/v2/contact/resolve`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceResolveByMail
     * @request POST:/web/v2/contact/resolveByMail/{ownerId}
     */
    contactServiceResolveByMail: (
      ownerId: string,
      body: ContactServiceResolveByMailPayload,
      params: RequestParams = {},
    ) =>
      this.request<ContactServiceResolveByMailData, ContactServiceResolveByMailError>({
        path: `/web/v2/contact/resolveByMail/${ownerId}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceResolveEmployees
     * @request POST:/web/v2/contact/resolveEmployees
     */
    contactServiceResolveEmployees: (body: V2ResolveEmployeesRequest, params: RequestParams = {}) =>
      this.request<ContactServiceResolveEmployeesData, ContactServiceResolveEmployeesError>({
        path: `/web/v2/contact/resolveEmployees`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceSetAddress
     * @request POST:/web/v2/contact/setAddress
     */
    contactServiceSetAddress: (body: V2SetAddressRequest, params: RequestParams = {}) =>
      this.request<ContactServiceSetAddressData, ContactServiceSetAddressError>({
        path: `/web/v2/contact/setAddress`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceUnLinkCompanies
     * @request POST:/web/v2/contact/unLinkCompanies
     */
    contactServiceUnLinkCompanies: (body: V2UnLinkCompaniesRequest, params: RequestParams = {}) =>
      this.request<ContactServiceUnLinkCompaniesData, ContactServiceUnLinkCompaniesError>({
        path: `/web/v2/contact/unLinkCompanies`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceUnLinkPersons
     * @request POST:/web/v2/contact/unLinkPersons
     */
    contactServiceUnLinkPersons: (body: V2UnLinkPersonsRequest, params: RequestParams = {}) =>
      this.request<ContactServiceUnLinkPersonsData, ContactServiceUnLinkPersonsError>({
        path: `/web/v2/contact/unLinkPersons`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceUpdateAddressHistory
     * @request POST:/web/v2/contact/updateAddressHistory
     */
    contactServiceUpdateAddressHistory: (body: V2UpdateAddressHistoryRequest, params: RequestParams = {}) =>
      this.request<ContactServiceUpdateAddressHistoryData, ContactServiceUpdateAddressHistoryError>({
        path: `/web/v2/contact/updateAddressHistory`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceAddContactPerson
     * @request POST:/web/v2/contact/{companyContactId}/addContactPerson
     */
    contactServiceAddContactPerson: (
      companyContactId: string,
      body: ContactServiceAddContactPersonPayload,
      params: RequestParams = {},
    ) =>
      this.request<ContactServiceAddContactPersonData, ContactServiceAddContactPersonError>({
        path: `/web/v2/contact/${companyContactId}/addContactPerson`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceDeleteContactPersons
     * @request POST:/web/v2/contact/{companyContactId}/deleteContactPerson
     */
    contactServiceDeleteContactPersons: (
      companyContactId: string,
      body: ContactServiceDeleteContactPersonsPayload,
      params: RequestParams = {},
    ) =>
      this.request<ContactServiceDeleteContactPersonsData, ContactServiceDeleteContactPersonsError>({
        path: `/web/v2/contact/${companyContactId}/deleteContactPerson`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceUpdateContactPerson
     * @request POST:/web/v2/contact/{companyContactId}/updateContactPerson/{contactPersonId}
     */
    contactServiceUpdateContactPerson: (
      companyContactId: string,
      contactPersonId: string,
      body: ContactServiceUpdateContactPersonPayload,
      params: RequestParams = {},
    ) =>
      this.request<ContactServiceUpdateContactPersonData, ContactServiceUpdateContactPersonError>({
        path: `/web/v2/contact/${companyContactId}/updateContactPerson/${contactPersonId}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceAddDocuments
     * @request POST:/web/v2/contact/{id}/addDocuments
     */
    contactServiceAddDocuments: (
      id: string,
      body: {
        documentIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ContactServiceAddDocumentsData, ContactServiceAddDocumentsError>({
        path: `/web/v2/contact/${id}/addDocuments`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceDeleteAddress
     * @request POST:/web/v2/contact/{id}/deleteAddress
     */
    contactServiceDeleteAddress: (id: string, body: ContactServiceDeleteAddressPayload, params: RequestParams = {}) =>
      this.request<ContactServiceDeleteAddressData, ContactServiceDeleteAddressError>({
        path: `/web/v2/contact/${id}/deleteAddress`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceRemoveDocuments
     * @request DELETE:/web/v2/contact/{id}/removeDocuments/{documentIds}
     */
    contactServiceRemoveDocuments: (id: string, documentIds: string[], params: RequestParams = {}) =>
      this.request<ContactServiceRemoveDocumentsData, ContactServiceRemoveDocumentsError>({
        path: `/web/v2/contact/${id}/removeDocuments/${documentIds}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceUpdateCompany
     * @request POST:/web/v2/contact/{id}/updateCompany
     */
    contactServiceUpdateCompany: (id: string, body: ContactServiceUpdateCompanyPayload, params: RequestParams = {}) =>
      this.request<ContactServiceUpdateCompanyData, ContactServiceUpdateCompanyError>({
        path: `/web/v2/contact/${id}/updateCompany`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceUpdateGeneric
     * @request POST:/web/v2/contact/{id}/updateGeneric
     */
    contactServiceUpdateGeneric: (id: string, body: ContactServiceUpdateGenericPayload, params: RequestParams = {}) =>
      this.request<ContactServiceUpdateGenericData, ContactServiceUpdateGenericError>({
        path: `/web/v2/contact/${id}/updateGeneric`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactService
     * @name ContactServiceUpdatePerson
     * @request POST:/web/v2/contact/{id}/updatePerson
     */
    contactServiceUpdatePerson: (id: string, body: ContactServiceUpdatePersonPayload, params: RequestParams = {}) =>
      this.request<ContactServiceUpdatePersonData, ContactServiceUpdatePersonError>({
        path: `/web/v2/contact/${id}/updatePerson`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceCreate
     * @summary Create
     * @request POST:/web/v3/contact/activity/create
     */
    activityServiceCreate: (body: Activityv3CreateRequest, params: RequestParams = {}) =>
      this.request<ActivityServiceCreateData, ActivityServiceCreateError>({
        path: `/web/v3/contact/activity/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceDelete
     * @summary Delete
     * @request DELETE:/web/v3/contact/activity/delete/{id}
     */
    activityServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<ActivityServiceDeleteData, ActivityServiceDeleteError>({
        path: `/web/v3/contact/activity/delete/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceGet
     * @summary Read
     * @request GET:/web/v3/contact/activity/get/{id}
     */
    activityServiceGet: (id: string, params: RequestParams = {}) =>
      this.request<ActivityServiceGetData, ActivityServiceGetError>({
        path: `/web/v3/contact/activity/get/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceListByReferenceIds
     * @request POST:/web/v3/contact/activity/listByReferenceIds
     */
    activityServiceListByReferenceIds: (body: Activityv3ListByReferenceIdsRequest, params: RequestParams = {}) =>
      this.request<ActivityServiceListByReferenceIdsData, ActivityServiceListByReferenceIdsError>({
        path: `/web/v3/contact/activity/listByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityService
     * @name ActivityServiceUpdate
     * @summary Update
     * @request POST:/web/v3/contact/activity/update/{id}
     */
    activityServiceUpdate: (id: string, body: ActivityServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<ActivityServiceUpdateData, ActivityServiceUpdateError>({
        path: `/web/v3/contact/activity/update/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CredentialService
     * @name CredentialServiceCreate
     * @request POST:/web/v2/export/credentials/create
     */
    credentialServiceCreate: (body: CredentialsCreateCredentialsRequest, params: RequestParams = {}) =>
      this.request<CredentialServiceCreateData, CredentialServiceCreateError>({
        path: `/web/v2/export/credentials/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CredentialService
     * @name CredentialServiceFindDecrypted
     * @request GET:/web/v2/export/credentials/findDecrypted/{id}
     */
    credentialServiceFindDecrypted: (id: string, params: RequestParams = {}) =>
      this.request<CredentialServiceFindDecryptedData, CredentialServiceFindDecryptedError>({
        path: `/web/v2/export/credentials/findDecrypted/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CredentialService
     * @name CredentialServiceUpdate
     * @request POST:/web/v2/export/credentials/update
     */
    credentialServiceUpdate: (body: CredentialsUpdateCredentialsRequest, params: RequestParams = {}) =>
      this.request<CredentialServiceUpdateData, CredentialServiceUpdateError>({
        path: `/web/v2/export/credentials/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceCreate
     * @request POST:/web/v2/export/export/create
     */
    exportServiceCreate: (body: Exportv2CreateExportRequest, params: RequestParams = {}) =>
      this.request<ExportServiceCreateData, ExportServiceCreateError>({
        path: `/web/v2/export/export/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceDelete
     * @request POST:/web/v2/export/export/delete/{id}
     */
    exportServiceDelete: (id: string, body: object, params: RequestParams = {}) =>
      this.request<ExportServiceDeleteData, ExportServiceDeleteError>({
        path: `/web/v2/export/export/delete/${id}`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceFind
     * @request GET:/web/v2/export/export/find/{id}
     */
    exportServiceFind: (id: string, params: RequestParams = {}) =>
      this.request<ExportServiceFindData, ExportServiceFindError>({
        path: `/web/v2/export/export/find/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceList
     * @request POST:/web/v2/export/export/list/{accountId}
     */
    exportServiceList: (accountId: string, body: object, params: RequestParams = {}) =>
      this.request<ExportServiceListData, ExportServiceListError>({
        path: `/web/v2/export/export/list/${accountId}`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceListObjectPlatforms
     * @request GET:/web/v2/export/export/listObjectPlatforms/{objectId}
     */
    exportServiceListObjectPlatforms: (objectId: string, params: RequestParams = {}) =>
      this.request<ExportServiceListObjectPlatformsData, ExportServiceListObjectPlatformsError>({
        path: `/web/v2/export/export/listObjectPlatforms/${objectId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceListPresets
     * @request GET:/web/v2/export/export/listPresets
     */
    exportServiceListPresets: (params: RequestParams = {}) =>
      this.request<ExportServiceListPresetsData, ExportServiceListPresetsError>({
        path: `/web/v2/export/export/listPresets`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceRemoveAllTargets
     * @request POST:/web/v2/export/export/removeAllTargets/{exportId}
     */
    exportServiceRemoveAllTargets: (exportId: string, body: object, params: RequestParams = {}) =>
      this.request<ExportServiceRemoveAllTargetsData, ExportServiceRemoveAllTargetsError>({
        path: `/web/v2/export/export/removeAllTargets/${exportId}`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryService
     * @name HistoryServiceGetLatest
     * @request GET:/web/v2/export/export/runs/latest/{accountId}
     */
    historyServiceGetLatest: (accountId: string, params: RequestParams = {}) =>
      this.request<HistoryServiceGetLatestData, HistoryServiceGetLatestError>({
        path: `/web/v2/export/export/runs/latest/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryService
     * @name HistoryServiceStatistics
     * @request GET:/web/v2/export/export/runs/statistics
     */
    historyServiceStatistics: (params: RequestParams = {}) =>
      this.request<HistoryServiceStatisticsData, HistoryServiceStatisticsError>({
        path: `/web/v2/export/export/runs/statistics`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceSendExportUploadCommand
     * @request POST:/web/v2/export/export/sendExportUploadCommand
     */
    exportServiceSendExportUploadCommand: (body: Exportv2SendExportUploadCommandRequest, params: RequestParams = {}) =>
      this.request<ExportServiceSendExportUploadCommandData, ExportServiceSendExportUploadCommandError>({
        path: `/web/v2/export/export/sendExportUploadCommand`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceSetAgencyId
     * @request POST:/web/v2/export/export/setAgencyId
     */
    exportServiceSetAgencyId: (body: Exportv2SetAgencyIdRequest, params: RequestParams = {}) =>
      this.request<ExportServiceSetAgencyIdData, ExportServiceSetAgencyIdError>({
        path: `/web/v2/export/export/setAgencyId`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceSetPreset
     * @request POST:/web/v2/export/export/setPreset
     */
    exportServiceSetPreset: (body: Exportv2SetPresetRequest, params: RequestParams = {}) =>
      this.request<ExportServiceSetPresetData, ExportServiceSetPresetError>({
        path: `/web/v2/export/export/setPreset`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceStart
     * @request POST:/web/v2/export/export/start/{id}
     */
    exportServiceStart: (id: string, body: object, params: RequestParams = {}) =>
      this.request<ExportServiceStartData, ExportServiceStartError>({
        path: `/web/v2/export/export/start/${id}`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceUpdate
     * @request POST:/web/v2/export/export/update
     */
    exportServiceUpdate: (body: Exportv2UpdateExportRequest, params: RequestParams = {}) =>
      this.request<ExportServiceUpdateData, ExportServiceUpdateError>({
        path: `/web/v2/export/export/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportService
     * @name ExportServiceUpdatePlatforms
     * @request POST:/web/v2/export/export/updatePlatforms
     */
    exportServiceUpdatePlatforms: (body: Exportv2UpdatePlatformsRequest, params: RequestParams = {}) =>
      this.request<ExportServiceUpdatePlatformsData, ExportServiceUpdatePlatformsError>({
        path: `/web/v2/export/export/updatePlatforms`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentService
     * @name DocumentServiceGet
     * @request GET:/web/media/documents
     */
    documentServiceGet: (query: DocumentServiceGetParams, params: RequestParams = {}) =>
      this.request<DocumentServiceGetData, DocumentServiceGetError>({
        path: `/web/media/documents`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentService
     * @name DocumentServiceSave
     * @request POST:/web/media/documents
     */
    documentServiceSave: (body: DocumentSaveRequest, params: RequestParams = {}) =>
      this.request<DocumentServiceSaveData, DocumentServiceSaveError>({
        path: `/web/media/documents`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentService
     * @name DocumentServiceList
     * @request POST:/web/media/documents/list
     */
    documentServiceList: (body: MediadocumentListRequest, params: RequestParams = {}) =>
      this.request<DocumentServiceListData, DocumentServiceListError>({
        path: `/web/media/documents/list`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentService
     * @name DocumentServiceMergeAllDocuments
     * @request POST:/web/media/documents/mergeAllDocuments
     */
    documentServiceMergeAllDocuments: (body: DocumentMergeAllDocumentsRequest, params: RequestParams = {}) =>
      this.request<DocumentServiceMergeAllDocumentsData, DocumentServiceMergeAllDocumentsError>({
        path: `/web/media/documents/mergeAllDocuments`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentService
     * @name DocumentServiceUpdate
     * @request POST:/web/media/documents/{id}/update
     */
    documentServiceUpdate: (id: string, body: DocumentServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<DocumentServiceUpdateData, DocumentServiceUpdateError>({
        path: `/web/media/documents/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentService
     * @name DocumentServiceAddVersion
     * @request POST:/web/media/documents/{id}/version
     */
    documentServiceAddVersion: (id: string, body: DocumentServiceAddVersionPayload, params: RequestParams = {}) =>
      this.request<DocumentServiceAddVersionData, DocumentServiceAddVersionError>({
        path: `/web/media/documents/${id}/version`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentService
     * @name DocumentServiceRemoveVersion
     * @request DELETE:/web/media/documents/{id}/version/{version}
     */
    documentServiceRemoveVersion: (id: string, version: number, params: RequestParams = {}) =>
      this.request<DocumentServiceRemoveVersionData, DocumentServiceRemoveVersionError>({
        path: `/web/media/documents/${id}/version/${version}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StoreService
     * @name StoreServiceList
     * @request POST:/web/media/list
     */
    storeServiceList: (body: StoreListMediaRequest, params: RequestParams = {}) =>
      this.request<StoreServiceListData, StoreServiceListError>({
        path: `/web/media/list`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PdfService
     * @name PdfServiceGenerateFromHtml
     * @request POST:/web/media/pdf/html
     */
    pdfServiceGenerateFromHtml: (body: PdfGenerateFromHtmlRequest, params: RequestParams = {}) =>
      this.request<PdfServiceGenerateFromHtmlData, PdfServiceGenerateFromHtmlError>({
        path: `/web/media/pdf/html`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StoreService
     * @name StoreServiceGet
     * @request GET:/web/media/{id}
     */
    storeServiceGet: (id: string, params: RequestParams = {}) =>
      this.request<StoreServiceGetData, StoreServiceGetError>({
        path: `/web/media/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StoreService
     * @name StoreServiceDelete
     * @request DELETE:/web/media/{id}
     */
    storeServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<StoreServiceDeleteData, StoreServiceDeleteError>({
        path: `/web/media/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceActivateMailboxes
     * @request POST:/web/v2/message/mail/activateMailboxes
     */
    mailServiceActivateMailboxes: (body: V2ActivateMailboxesRequest, params: RequestParams = {}) =>
      this.request<MailServiceActivateMailboxesData, MailServiceActivateMailboxesError>({
        path: `/web/v2/message/mail/activateMailboxes`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceAddConfidentialEmails
     * @summary Confidential email list
     * @request POST:/web/v2/message/mail/addConfidentialEmails
     */
    mailServiceAddConfidentialEmails: (body: V2AddConfidentialEmailsRequest, params: RequestParams = {}) =>
      this.request<MailServiceAddConfidentialEmailsData, MailServiceAddConfidentialEmailsError>({
        path: `/web/v2/message/mail/addConfidentialEmails`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceArchive
     * @request POST:/web/v2/message/mail/archive
     */
    mailServiceArchive: (body: V2ArchiveRequest, params: RequestParams = {}) =>
      this.request<MailServiceArchiveData, MailServiceArchiveError>({
        path: `/web/v2/message/mail/archive`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceBulkSendMail
     * @request POST:/web/v2/message/mail/bulkSendMail
     */
    mailServiceBulkSendMail: (body: V2BulkSendMailRequest, params: RequestParams = {}) =>
      this.request<MailServiceBulkSendMailData, MailServiceBulkSendMailError>({
        path: `/web/v2/message/mail/bulkSendMail`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceCountDrafts
     * @request GET:/web/v2/message/mail/countDrafts
     */
    mailServiceCountDrafts: (params: RequestParams = {}) =>
      this.request<MailServiceCountDraftsData, MailServiceCountDraftsError>({
        path: `/web/v2/message/mail/countDrafts`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceCountUnreadMails
     * @request GET:/web/v2/message/mail/countUnreadMails/{mailboxIds}
     */
    mailServiceCountUnreadMails: (mailboxIds: string[], params: RequestParams = {}) =>
      this.request<MailServiceCountUnreadMailsData, MailServiceCountUnreadMailsError>({
        path: `/web/v2/message/mail/countUnreadMails/${mailboxIds}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceCreateDomain
     * @summary Domain
     * @request POST:/web/v2/message/mail/createDomain
     */
    mailServiceCreateDomain: (body: V2CreateDomainRequest, params: RequestParams = {}) =>
      this.request<MailServiceCreateDomainData, MailServiceCreateDomainError>({
        path: `/web/v2/message/mail/createDomain`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceCreateMailbox
     * @summary User mailboxes
     * @request POST:/web/v2/message/mail/createMailbox
     */
    mailServiceCreateMailbox: (body: V2CreateMailboxRequest, params: RequestParams = {}) =>
      this.request<MailServiceCreateMailboxData, MailServiceCreateMailboxError>({
        path: `/web/v2/message/mail/createMailbox`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceDeactivateMailboxes
     * @request POST:/web/v2/message/mail/deactivateMailboxes
     */
    mailServiceDeactivateMailboxes: (body: V2DeactivateMailboxesRequest, params: RequestParams = {}) =>
      this.request<MailServiceDeactivateMailboxesData, MailServiceDeactivateMailboxesError>({
        path: `/web/v2/message/mail/deactivateMailboxes`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceDeleteDomain
     * @request DELETE:/web/v2/message/mail/deleteDomain/{id}
     */
    mailServiceDeleteDomain: (id: string, params: RequestParams = {}) =>
      this.request<MailServiceDeleteDomainData, MailServiceDeleteDomainError>({
        path: `/web/v2/message/mail/deleteDomain/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceDeleteDrafts
     * @request DELETE:/web/v2/message/mail/deleteDrafts/{ids}
     */
    mailServiceDeleteDrafts: (ids: string[], params: RequestParams = {}) =>
      this.request<MailServiceDeleteDraftsData, MailServiceDeleteDraftsError>({
        path: `/web/v2/message/mail/deleteDrafts/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceDeleteMails
     * @request DELETE:/web/v2/message/mail/deleteMails/{ids}
     */
    mailServiceDeleteMails: (ids: string[], params: RequestParams = {}) =>
      this.request<MailServiceDeleteMailsData, MailServiceDeleteMailsError>({
        path: `/web/v2/message/mail/deleteMails/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceEditMailbox
     * @request POST:/web/v2/message/mail/editMailbox/{id}
     */
    mailServiceEditMailbox: (id: string, body: MailServiceEditMailboxPayload, params: RequestParams = {}) =>
      this.request<MailServiceEditMailboxData, MailServiceEditMailboxError>({
        path: `/web/v2/message/mail/editMailbox/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceForward
     * @request POST:/web/v2/message/mail/forward
     */
    mailServiceForward: (body: V2ForwardRequest, params: RequestParams = {}) =>
      this.request<MailServiceForwardData, MailServiceForwardError>({
        path: `/web/v2/message/mail/forward`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceGetDeliveryAndEngagementData
     * @request POST:/web/v2/message/mail/getDeliveryAndEngagementData
     */
    mailServiceGetDeliveryAndEngagementData: (
      body: V2GetDeliveryAndEngagementDataRequest,
      params: RequestParams = {},
    ) =>
      this.request<MailServiceGetDeliveryAndEngagementDataData, MailServiceGetDeliveryAndEngagementDataError>({
        path: `/web/v2/message/mail/getDeliveryAndEngagementData`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceGetMailbox
     * @request GET:/web/v2/message/mail/getMailbox/{id}
     */
    mailServiceGetMailbox: (id: string, params: RequestParams = {}) =>
      this.request<MailServiceGetMailboxData, MailServiceGetMailboxError>({
        path: `/web/v2/message/mail/getMailbox/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceHasDomainDedicatedIp
     * @request GET:/web/v2/message/mail/hasDomainDedicatedIp
     */
    mailServiceHasDomainDedicatedIp: (query: MailServiceHasDomainDedicatedIpParams, params: RequestParams = {}) =>
      this.request<MailServiceHasDomainDedicatedIpData, MailServiceHasDomainDedicatedIpError>({
        path: `/web/v2/message/mail/hasDomainDedicatedIp`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceListConfidentialEmails
     * @request GET:/web/v2/message/mail/listConfidentialEmails/{accountId}
     */
    mailServiceListConfidentialEmails: (accountId: string, params: RequestParams = {}) =>
      this.request<MailServiceListConfidentialEmailsData, MailServiceListConfidentialEmailsError>({
        path: `/web/v2/message/mail/listConfidentialEmails/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceListDomains
     * @request GET:/web/v2/message/mail/listDomains/{ownerId}
     */
    mailServiceListDomains: (ownerId: string, params: RequestParams = {}) =>
      this.request<MailServiceListDomainsData, MailServiceListDomainsError>({
        path: `/web/v2/message/mail/listDomains/${ownerId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceListDrafts
     * @request GET:/web/v2/message/mail/listDrafts
     */
    mailServiceListDrafts: (params: RequestParams = {}) =>
      this.request<MailServiceListDraftsData, MailServiceListDraftsError>({
        path: `/web/v2/message/mail/listDrafts`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceListLatestMailsForReferenceIds
     * @request POST:/web/v2/message/mail/listLatestMailsForReferenceIds
     */
    mailServiceListLatestMailsForReferenceIds: (
      body: V2ListLatestMailsForReferenceIdsRequest,
      params: RequestParams = {},
    ) =>
      this.request<MailServiceListLatestMailsForReferenceIdsData, MailServiceListLatestMailsForReferenceIdsError>({
        path: `/web/v2/message/mail/listLatestMailsForReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceListMailIds
     * @request POST:/web/v2/message/mail/listMailIds
     */
    mailServiceListMailIds: (body: V2ListMailIdsRequest, params: RequestParams = {}) =>
      this.request<MailServiceListMailIdsData, MailServiceListMailIdsError>({
        path: `/web/v2/message/mail/listMailIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceListMailboxesByAccountId
     * @request GET:/web/v2/message/mail/listMailboxesByAccountId/{accountId}
     */
    mailServiceListMailboxesByAccountId: (accountId: string, params: RequestParams = {}) =>
      this.request<MailServiceListMailboxesByAccountIdData, MailServiceListMailboxesByAccountIdError>({
        path: `/web/v2/message/mail/listMailboxesByAccountId/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceListMailboxesByUserId
     * @request GET:/web/v2/message/mail/listMailboxesByUserId/{userId}
     */
    mailServiceListMailboxesByUserId: (userId: string, params: RequestParams = {}) =>
      this.request<MailServiceListMailboxesByUserIdData, MailServiceListMailboxesByUserIdError>({
        path: `/web/v2/message/mail/listMailboxesByUserId/${userId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceListMails
     * @request POST:/web/v2/message/mail/listMails
     */
    mailServiceListMails: (body: V2ListMailsRequest, params: RequestParams = {}) =>
      this.request<MailServiceListMailsData, MailServiceListMailsError>({
        path: `/web/v2/message/mail/listMails`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceListMailsByIds
     * @request POST:/web/v2/message/mail/listMailsByIds
     */
    mailServiceListMailsByIds: (body: V2ListMailsByIdsRequest, params: RequestParams = {}) =>
      this.request<MailServiceListMailsByIdsData, MailServiceListMailsByIdsError>({
        path: `/web/v2/message/mail/listMailsByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceListMailsByReferenceIds
     * @request POST:/web/v2/message/mail/listMailsByReferenceIds
     */
    mailServiceListMailsByReferenceIds: (body: V2ListMailsByReferenceIdsRequest, params: RequestParams = {}) =>
      this.request<MailServiceListMailsByReferenceIdsData, MailServiceListMailsByReferenceIdsError>({
        path: `/web/v2/message/mail/listMailsByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceMarkAsNoSpam
     * @request POST:/web/v2/message/mail/markAsNoSpam
     */
    mailServiceMarkAsNoSpam: (body: V2MarkAsNoSpamRequest, params: RequestParams = {}) =>
      this.request<MailServiceMarkAsNoSpamData, MailServiceMarkAsNoSpamError>({
        path: `/web/v2/message/mail/markAsNoSpam`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceMarkAsSpam
     * @request POST:/web/v2/message/mail/markAsSpam
     */
    mailServiceMarkAsSpam: (body: V2MarkAsSpamRequest, params: RequestParams = {}) =>
      this.request<MailServiceMarkAsSpamData, MailServiceMarkAsSpamError>({
        path: `/web/v2/message/mail/markAsSpam`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceMarkRead
     * @request POST:/web/v2/message/mail/markRead
     */
    mailServiceMarkRead: (body: Messagemailv2MarkReadRequest, params: RequestParams = {}) =>
      this.request<MailServiceMarkReadData, MailServiceMarkReadError>({
        path: `/web/v2/message/mail/markRead`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceMarkUnread
     * @request POST:/web/v2/message/mail/markUnread
     */
    mailServiceMarkUnread: (body: Messagemailv2MarkUnreadRequest, params: RequestParams = {}) =>
      this.request<MailServiceMarkUnreadData, MailServiceMarkUnreadError>({
        path: `/web/v2/message/mail/markUnread`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceMoveToTrash
     * @request POST:/web/v2/message/mail/moveToTrash
     */
    mailServiceMoveToTrash: (body: V2MoveToTrashRequest, params: RequestParams = {}) =>
      this.request<MailServiceMoveToTrashData, MailServiceMoveToTrashError>({
        path: `/web/v2/message/mail/moveToTrash`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceRemoveConfidentialEmails
     * @request POST:/web/v2/message/mail/removeConfidentialEmails
     */
    mailServiceRemoveConfidentialEmails: (body: V2RemoveConfidentialEmailsRequest, params: RequestParams = {}) =>
      this.request<MailServiceRemoveConfidentialEmailsData, MailServiceRemoveConfidentialEmailsError>({
        path: `/web/v2/message/mail/removeConfidentialEmails`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceUnarchive
     * @request POST:/web/v2/message/mail/restore
     */
    mailServiceUnarchive: (body: V2UnarchiveRequest, params: RequestParams = {}) =>
      this.request<MailServiceUnarchiveData, MailServiceUnarchiveError>({
        path: `/web/v2/message/mail/restore`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceRestoreFromTrash
     * @request POST:/web/v2/message/mail/restoreFromTrash
     */
    mailServiceRestoreFromTrash: (body: V2RestoreFromTrashRequest, params: RequestParams = {}) =>
      this.request<MailServiceRestoreFromTrashData, MailServiceRestoreFromTrashError>({
        path: `/web/v2/message/mail/restoreFromTrash`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceSaveDraft
     * @summary Mail drafts
     * @request POST:/web/v2/message/mail/saveDraft
     */
    mailServiceSaveDraft: (body: V2SaveDraftRequest, params: RequestParams = {}) =>
      this.request<MailServiceSaveDraftData, MailServiceSaveDraftError>({
        path: `/web/v2/message/mail/saveDraft`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceSendMail
     * @summary Mails
     * @request POST:/web/v2/message/mail/sendMail
     */
    mailServiceSendMail: (body: V2SendMailRequest, params: RequestParams = {}) =>
      this.request<MailServiceSendMailData, MailServiceSendMailError>({
        path: `/web/v2/message/mail/sendMail`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceSetAutomaticReply
     * @request POST:/web/v2/message/mail/setAutomaticReply
     */
    mailServiceSetAutomaticReply: (body: V2SetAutomaticReplyRequest, params: RequestParams = {}) =>
      this.request<MailServiceSetAutomaticReplyData, MailServiceSetAutomaticReplyError>({
        path: `/web/v2/message/mail/setAutomaticReply`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceSetConfidential
     * @request POST:/web/v2/message/mail/setConfidential
     */
    mailServiceSetConfidential: (body: V2SetConfidentialRequest, params: RequestParams = {}) =>
      this.request<MailServiceSetConfidentialData, MailServiceSetConfidentialError>({
        path: `/web/v2/message/mail/setConfidential`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceSetMailboxesConfidential
     * @request POST:/web/v2/message/mail/setMailboxesConfidential
     */
    mailServiceSetMailboxesConfidential: (body: V2SetMailboxesConfidentialRequest, params: RequestParams = {}) =>
      this.request<MailServiceSetMailboxesConfidentialData, MailServiceSetMailboxesConfidentialError>({
        path: `/web/v2/message/mail/setMailboxesConfidential`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceSetReferenceIds
     * @request POST:/web/v2/message/mail/setReferenceIds/{ids}
     */
    mailServiceSetReferenceIds: (ids: string[], body: MailServiceSetReferenceIdsPayload, params: RequestParams = {}) =>
      this.request<MailServiceSetReferenceIdsData, MailServiceSetReferenceIdsError>({
        path: `/web/v2/message/mail/setReferenceIds/${ids}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailService
     * @name MailServiceValidateDomain
     * @request POST:/web/v2/message/mail/validateDomain/{id}
     */
    mailServiceValidateDomain: (id: string, body: object, params: RequestParams = {}) =>
      this.request<MailServiceValidateDomainData, MailServiceValidateDomainError>({
        path: `/web/v2/message/mail/validateDomain/${id}`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignatureService
     * @name SignatureServiceListByAccountId
     * @request GET:/web/v2/message/signature/listByAccountId
     */
    signatureServiceListByAccountId: (query: SignatureServiceListByAccountIdParams, params: RequestParams = {}) =>
      this.request<SignatureServiceListByAccountIdData, SignatureServiceListByAccountIdError>({
        path: `/web/v2/message/signature/listByAccountId`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignatureService
     * @name SignatureServiceRenderById
     * @request GET:/web/v2/message/signature/renderById/{id}/user/{userId}
     */
    signatureServiceRenderById: (id: string, userId: string, params: RequestParams = {}) =>
      this.request<SignatureServiceRenderByIdData, SignatureServiceRenderByIdError>({
        path: `/web/v2/message/signature/renderById/${id}/user/${userId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignatureService
     * @name SignatureServiceSetSignature
     * @summary New RPCs that support multiple signatures per account
     * @request POST:/web/v2/message/signature/setSignature
     */
    signatureServiceSetSignature: (body: V2SetSignatureRequest, params: RequestParams = {}) =>
      this.request<SignatureServiceSetSignatureData, SignatureServiceSetSignatureError>({
        path: `/web/v2/message/signature/setSignature`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignatureService
     * @name SignatureServiceGet
     * @request GET:/web/v2/message/signature/{accountId}/get
     */
    signatureServiceGet: (accountId: string, params: RequestParams = {}) =>
      this.request<SignatureServiceGetData, SignatureServiceGetError>({
        path: `/web/v2/message/signature/${accountId}/get`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignatureService
     * @name SignatureServiceRender
     * @request GET:/web/v2/message/signature/{accountId}/render/{userId}
     */
    signatureServiceRender: (accountId: string, userId: string, params: RequestParams = {}) =>
      this.request<SignatureServiceRenderData, SignatureServiceRenderError>({
        path: `/web/v2/message/signature/${accountId}/render/${userId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignatureService
     * @name SignatureServiceSet
     * @request POST:/web/v2/message/signature/{accountId}/set
     */
    signatureServiceSet: (accountId: string, body: SignatureServiceSetPayload, params: RequestParams = {}) =>
      this.request<SignatureServiceSetData, SignatureServiceSetError>({
        path: `/web/v2/message/signature/${accountId}/set`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignatureService
     * @name SignatureServiceDeleteSignature
     * @request DELETE:/web/v2/message/signature/{id}/delete
     */
    signatureServiceDeleteSignature: (id: string, params: RequestParams = {}) =>
      this.request<SignatureServiceDeleteSignatureData, SignatureServiceDeleteSignatureError>({
        path: `/web/v2/message/signature/${id}/delete`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateService
     * @name TemplateServiceBulkRenderText
     * @request POST:/web/v2/message/template/bulkRenderText
     */
    templateServiceBulkRenderText: (body: V2BulkRenderTextRequest, params: RequestParams = {}) =>
      this.request<TemplateServiceBulkRenderTextData, TemplateServiceBulkRenderTextError>({
        path: `/web/v2/message/template/bulkRenderText`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateService
     * @name TemplateServiceCreate
     * @summary Create
     * @request POST:/web/v2/message/template/create
     */
    templateServiceCreate: (body: Messagetemplatev2CreateRequest, params: RequestParams = {}) =>
      this.request<TemplateServiceCreateData, TemplateServiceCreateError>({
        path: `/web/v2/message/template/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateService
     * @name TemplateServiceList
     * @summary Read
     * @request GET:/web/v2/message/template/list
     */
    templateServiceList: (query: TemplateServiceListParams, params: RequestParams = {}) =>
      this.request<TemplateServiceListData, TemplateServiceListError>({
        path: `/web/v2/message/template/list`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateService
     * @name TemplateServiceRenderText
     * @request POST:/web/v2/message/template/renderText
     */
    templateServiceRenderText: (body: V2RenderTextRequest, params: RequestParams = {}) =>
      this.request<TemplateServiceRenderTextData, TemplateServiceRenderTextError>({
        path: `/web/v2/message/template/renderText`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateService
     * @name TemplateServiceDelete
     * @summary Delete
     * @request DELETE:/web/v2/message/template/{ids}
     */
    templateServiceDelete: (ids: string[], params: RequestParams = {}) =>
      this.request<TemplateServiceDeleteData, TemplateServiceDeleteError>({
        path: `/web/v2/message/template/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateService
     * @name TemplateServiceRender
     * @request POST:/web/v2/message/template/{id}/render
     */
    templateServiceRender: (id: string, body: TemplateServiceRenderPayload, params: RequestParams = {}) =>
      this.request<TemplateServiceRenderData, TemplateServiceRenderError>({
        path: `/web/v2/message/template/${id}/render`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateService
     * @name TemplateServiceUpdate
     * @summary Update
     * @request POST:/web/v2/message/template/{id}/update
     */
    templateServiceUpdate: (id: string, body: TemplateServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<TemplateServiceUpdateData, TemplateServiceUpdateError>({
        path: `/web/v2/message/template/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceChangeContractRelation
     * @request POST:/web/objects/accounting/changeContractRelation
     */
    accountingServiceChangeContractRelation: (
      body: AccountingChangeContractRelationRequest,
      params: RequestParams = {},
    ) =>
      this.request<AccountingServiceChangeContractRelationData, AccountingServiceChangeContractRelationError>({
        path: `/web/objects/accounting/changeContractRelation`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceGetEnabled
     * @request GET:/web/objects/accounting/getEnabled/{accountId}
     */
    accountingServiceGetEnabled: (accountId: string, params: RequestParams = {}) =>
      this.request<AccountingServiceGetEnabledData, AccountingServiceGetEnabledError>({
        path: `/web/objects/accounting/getEnabled/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceGetLogs
     * @request GET:/web/objects/accounting/getLogs/{accountId}
     */
    accountingServiceGetLogs: ({ accountId, ...query }: AccountingServiceGetLogsParams, params: RequestParams = {}) =>
      this.request<AccountingServiceGetLogsData, AccountingServiceGetLogsError>({
        path: `/web/objects/accounting/getLogs/${accountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceGetStatistics
     * @request GET:/web/objects/accounting/getStatistics/{accountId}
     */
    accountingServiceGetStatistics: (accountId: number, params: RequestParams = {}) =>
      this.request<AccountingServiceGetStatisticsData, AccountingServiceGetStatisticsError>({
        path: `/web/objects/accounting/getStatistics/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceIsEnabled
     * @request POST:/web/objects/accounting/isEnabled
     */
    accountingServiceIsEnabled: (body: AccountingIsEnabledRequest, params: RequestParams = {}) =>
      this.request<AccountingServiceIsEnabledData, AccountingServiceIsEnabledError>({
        path: `/web/objects/accounting/isEnabled`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceListAllContractErrors
     * @request GET:/web/objects/accounting/listAllContractErrors
     */
    accountingServiceListAllContractErrors: (params: RequestParams = {}) =>
      this.request<AccountingServiceListAllContractErrorsData, AccountingServiceListAllContractErrorsError>({
        path: `/web/objects/accounting/listAllContractErrors`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceSetEnabled
     * @request POST:/web/objects/accounting/setEnabled
     */
    accountingServiceSetEnabled: (body: AccountingSetEnabledRequest, params: RequestParams = {}) =>
      this.request<AccountingServiceSetEnabledData, AccountingServiceSetEnabledError>({
        path: `/web/objects/accounting/setEnabled`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceSkipNextEvent
     * @request POST:/web/objects/accounting/skipNextEvent
     */
    accountingServiceSkipNextEvent: (body: AccountingSkipNextEventRequest, params: RequestParams = {}) =>
      this.request<AccountingServiceSkipNextEventData, AccountingServiceSkipNextEventError>({
        path: `/web/objects/accounting/skipNextEvent`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceTriggerDebitUntilSync
     * @summary RPCs to trigger back-sync from accounting system to propbase manually
     * @request POST:/web/objects/accounting/triggerDebitUntilSync/{accountId}
     */
    accountingServiceTriggerDebitUntilSync: (accountId: string, params: RequestParams = {}) =>
      this.request<AccountingServiceTriggerDebitUntilSyncData, AccountingServiceTriggerDebitUntilSyncError>({
        path: `/web/objects/accounting/triggerDebitUntilSync/${accountId}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceTriggerDocumentSync
     * @request POST:/web/objects/accounting/triggerDocumentSync/{accountId}
     */
    accountingServiceTriggerDocumentSync: (accountId: string, params: RequestParams = {}) =>
      this.request<AccountingServiceTriggerDocumentSyncData, AccountingServiceTriggerDocumentSyncError>({
        path: `/web/objects/accounting/triggerDocumentSync/${accountId}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingService
     * @name AccountingServiceTriggerRentAdjustmentSync
     * @request POST:/web/objects/accounting/triggerRentAdjustmentSync/{accountId}
     */
    accountingServiceTriggerRentAdjustmentSync: (accountId: string, params: RequestParams = {}) =>
      this.request<AccountingServiceTriggerRentAdjustmentSyncData, AccountingServiceTriggerRentAdjustmentSyncError>({
        path: `/web/objects/accounting/triggerRentAdjustmentSync/${accountId}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceAddCraftsmen
     * @request POST:/web/objects/addCraftsmen
     */
    objectServiceAddCraftsmen: (body: ObjectAddCraftsmenRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceAddCraftsmenData, ObjectServiceAddCraftsmenError>({
        path: `/web/objects/addCraftsmen`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceAddJanitors
     * @request POST:/web/objects/addJanitors
     */
    objectServiceAddJanitors: (body: ObjectAddJanitorsRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceAddJanitorsData, ObjectServiceAddJanitorsError>({
        path: `/web/objects/addJanitors`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceAddServiceProviders
     * @request POST:/web/objects/addServiceProviders
     */
    objectServiceAddServiceProviders: (body: ObjectAddServiceProvidersRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceAddServiceProvidersData, ObjectServiceAddServiceProvidersError>({
        path: `/web/objects/addServiceProviders`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BriefbutlerService
     * @name BriefbutlerServiceAcknowledgeErrors
     * @request GET:/web/objects/briefbutler/acknowledgeErrors
     */
    briefbutlerServiceAcknowledgeErrors: (
      query: BriefbutlerServiceAcknowledgeErrorsParams,
      params: RequestParams = {},
    ) =>
      this.request<BriefbutlerServiceAcknowledgeErrorsData, BriefbutlerServiceAcknowledgeErrorsError>({
        path: `/web/objects/briefbutler/acknowledgeErrors`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BriefbutlerService
     * @name BriefbutlerServiceListErrorsByAccountId
     * @request GET:/web/objects/briefbutler/listErrorsByAccountId
     */
    briefbutlerServiceListErrorsByAccountId: (
      query: BriefbutlerServiceListErrorsByAccountIdParams,
      params: RequestParams = {},
    ) =>
      this.request<BriefbutlerServiceListErrorsByAccountIdData, BriefbutlerServiceListErrorsByAccountIdError>({
        path: `/web/objects/briefbutler/listErrorsByAccountId`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BriefbutlerService
     * @name BriefbutlerServiceLoadSettings
     * @summary Settings
     * @request GET:/web/objects/briefbutler/loadSettings
     */
    briefbutlerServiceLoadSettings: (query: BriefbutlerServiceLoadSettingsParams, params: RequestParams = {}) =>
      this.request<BriefbutlerServiceLoadSettingsData, BriefbutlerServiceLoadSettingsError>({
        path: `/web/objects/briefbutler/loadSettings`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BriefbutlerService
     * @name BriefbutlerServiceSaveSettings
     * @request POST:/web/objects/briefbutler/saveSettings
     */
    briefbutlerServiceSaveSettings: (body: ObjectbriefbutlerSaveSettingsRequest, params: RequestParams = {}) =>
      this.request<BriefbutlerServiceSaveSettingsData, BriefbutlerServiceSaveSettingsError>({
        path: `/web/objects/briefbutler/saveSettings`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BriefbutlerService
     * @name BriefbutlerServiceSendRawDocument
     * @request POST:/web/objects/briefbutler/sendRawDocument
     */
    briefbutlerServiceSendRawDocument: (body: BriefbutlerSendRawDocumentRequest, params: RequestParams = {}) =>
      this.request<BriefbutlerServiceSendRawDocumentData, BriefbutlerServiceSendRawDocumentError>({
        path: `/web/objects/briefbutler/sendRawDocument`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceAddBuildingContacts
     * @request POST:/web/objects/building/addBuildingContacts
     */
    buildingServiceAddBuildingContacts: (body: BuildingAddBuildingContactsRequest, params: RequestParams = {}) =>
      this.request<BuildingServiceAddBuildingContactsData, BuildingServiceAddBuildingContactsError>({
        path: `/web/objects/building/addBuildingContacts`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceCount
     * @request GET:/web/objects/building/count/{accountId}
     */
    buildingServiceCount: ({ accountId, ...query }: BuildingServiceCountParams, params: RequestParams = {}) =>
      this.request<BuildingServiceCountData, BuildingServiceCountError>({
        path: `/web/objects/building/count/${accountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceCreate
     * @summary Create
     * @request POST:/web/objects/building/create
     */
    buildingServiceCreate: (body: ObjectbuildingCreateRequest, params: RequestParams = {}) =>
      this.request<BuildingServiceCreateData, BuildingServiceCreateError>({
        path: `/web/objects/building/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceEditContactNote
     * @request POST:/web/objects/building/editContactNote
     */
    buildingServiceEditContactNote: (body: BuildingEditBuildingContactsRequest, params: RequestParams = {}) =>
      this.request<BuildingServiceEditContactNoteData, BuildingServiceEditContactNoteError>({
        path: `/web/objects/building/editContactNote`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceGet
     * @summary Read
     * @request GET:/web/objects/building/get/{id}
     */
    buildingServiceGet: (id: string, params: RequestParams = {}) =>
      this.request<BuildingServiceGetData, BuildingServiceGetError>({
        path: `/web/objects/building/get/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceGetByObjectId
     * @request GET:/web/objects/building/getByObjectId/{objectId}
     */
    buildingServiceGetByObjectId: (objectId: string, params: RequestParams = {}) =>
      this.request<BuildingServiceGetByObjectIdData, BuildingServiceGetByObjectIdError>({
        path: `/web/objects/building/getByObjectId/${objectId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceList
     * @request GET:/web/objects/building/list/{accountId}
     */
    buildingServiceList: ({ accountId, ...query }: BuildingServiceListParams, params: RequestParams = {}) =>
      this.request<BuildingServiceListData, BuildingServiceListError>({
        path: `/web/objects/building/list/${accountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceListAll
     * @request GET:/web/objects/building/listAll/{accountId}
     */
    buildingServiceListAll: (accountId: string, params: RequestParams = {}) =>
      this.request<BuildingServiceListAllData, BuildingServiceListAllError>({
        path: `/web/objects/building/listAll/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceListByContactIds
     * @request POST:/web/objects/building/listByContactIds
     */
    buildingServiceListByContactIds: (body: ObjectbuildingListByContactIdsRequest, params: RequestParams = {}) =>
      this.request<BuildingServiceListByContactIdsData, BuildingServiceListByContactIdsError>({
        path: `/web/objects/building/listByContactIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceListByCoordinates
     * @request GET:/web/objects/building/listByCoordinates
     */
    buildingServiceListByCoordinates: (query: BuildingServiceListByCoordinatesParams, params: RequestParams = {}) =>
      this.request<BuildingServiceListByCoordinatesData, BuildingServiceListByCoordinatesError>({
        path: `/web/objects/building/listByCoordinates`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceListByIds
     * @request POST:/web/objects/building/listByIds
     */
    buildingServiceListByIds: (body: ObjectbuildingListByIdsRequest, params: RequestParams = {}) =>
      this.request<BuildingServiceListByIdsData, BuildingServiceListByIdsError>({
        path: `/web/objects/building/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceListByObjectIds
     * @request POST:/web/objects/building/listByObjectIds
     */
    buildingServiceListByObjectIds: (body: ObjectbuildingListByObjectIdsRequest, params: RequestParams = {}) =>
      this.request<BuildingServiceListByObjectIdsData, BuildingServiceListByObjectIdsError>({
        path: `/web/objects/building/listByObjectIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceListFilterOptions
     * @request GET:/web/objects/building/listFilterOptions
     */
    buildingServiceListFilterOptions: (params: RequestParams = {}) =>
      this.request<BuildingServiceListFilterOptionsData, BuildingServiceListFilterOptionsError>({
        path: `/web/objects/building/listFilterOptions`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceRemoveBuildingContacts
     * @request POST:/web/objects/building/removeBuildingContacts
     */
    buildingServiceRemoveBuildingContacts: (body: BuildingRemoveBuildingContactsRequest, params: RequestParams = {}) =>
      this.request<BuildingServiceRemoveBuildingContactsData, BuildingServiceRemoveBuildingContactsError>({
        path: `/web/objects/building/removeBuildingContacts`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceDelete
     * @summary Delete
     * @request DELETE:/web/objects/building/{id}
     */
    buildingServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<BuildingServiceDeleteData, BuildingServiceDeleteError>({
        path: `/web/objects/building/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceMoveBuilding
     * @request POST:/web/objects/building/{id}/move
     */
    buildingServiceMoveBuilding: (id: string, body: BuildingServiceMoveBuildingPayload, params: RequestParams = {}) =>
      this.request<BuildingServiceMoveBuildingData, BuildingServiceMoveBuildingError>({
        path: `/web/objects/building/${id}/move`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceUpdate
     * @summary Update
     * @request POST:/web/objects/building/{id}/update
     */
    buildingServiceUpdate: (id: string, body: BuildingServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<BuildingServiceUpdateData, BuildingServiceUpdateError>({
        path: `/web/objects/building/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingService
     * @name BuildingServiceUpdateMicroLocationRatingProposal
     * @request POST:/web/objects/building/{id}/updateMicroLocationRatingProposal
     */
    buildingServiceUpdateMicroLocationRatingProposal: (
      id: string,
      body: BuildingServiceUpdateMicroLocationRatingProposalPayload,
      params: RequestParams = {},
    ) =>
      this.request<
        BuildingServiceUpdateMicroLocationRatingProposalData,
        BuildingServiceUpdateMicroLocationRatingProposalError
      >({
        path: `/web/objects/building/${id}/updateMicroLocationRatingProposal`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceCreate
     * @summary Create
     * @request POST:/web/objects/condominium/create
     */
    condominiumServiceCreate: (body: ObjectcondominiumCreateRequest, params: RequestParams = {}) =>
      this.request<CondominiumServiceCreateData, CondominiumServiceCreateError>({
        path: `/web/objects/condominium/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceDeleteRentAdjustments
     * @request POST:/web/objects/condominium/deleteRentAdjustments
     */
    condominiumServiceDeleteRentAdjustments: (
      body: ObjectcondominiumDeleteRentAdjustmentsRequest,
      params: RequestParams = {},
    ) =>
      this.request<CondominiumServiceDeleteRentAdjustmentsData, CondominiumServiceDeleteRentAdjustmentsError>({
        path: `/web/objects/condominium/deleteRentAdjustments`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceGet
     * @summary Read
     * @request GET:/web/objects/condominium/get/{id}
     */
    condominiumServiceGet: (id: string, params: RequestParams = {}) =>
      this.request<CondominiumServiceGetData, CondominiumServiceGetError>({
        path: `/web/objects/condominium/get/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceHasActivatedCondominium
     * @request POST:/web/objects/condominium/hasActivatedCondominium
     */
    condominiumServiceHasActivatedCondominium: (
      body: CondominiumHasActivatedCondominiumRequest,
      params: RequestParams = {},
    ) =>
      this.request<CondominiumServiceHasActivatedCondominiumData, CondominiumServiceHasActivatedCondominiumError>({
        path: `/web/objects/condominium/hasActivatedCondominium`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceListByBillingContactId
     * @request GET:/web/objects/condominium/listByBillingContactId/{billingContactId}
     */
    condominiumServiceListByBillingContactId: (billingContactId: string, params: RequestParams = {}) =>
      this.request<CondominiumServiceListByBillingContactIdData, CondominiumServiceListByBillingContactIdError>({
        path: `/web/objects/condominium/listByBillingContactId/${billingContactId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceListByContactId
     * @request GET:/web/objects/condominium/listByContactId/{contactId}
     */
    condominiumServiceListByContactId: (
      { contactId, ...query }: CondominiumServiceListByContactIdParams,
      params: RequestParams = {},
    ) =>
      this.request<CondominiumServiceListByContactIdData, CondominiumServiceListByContactIdError>({
        path: `/web/objects/condominium/listByContactId/${contactId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceListByIds
     * @request POST:/web/objects/condominium/listByIds
     */
    condominiumServiceListByIds: (body: ObjectcondominiumListByIdsRequest, params: RequestParams = {}) =>
      this.request<CondominiumServiceListByIdsData, CondominiumServiceListByIdsError>({
        path: `/web/objects/condominium/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceListByObjectIds
     * @request POST:/web/objects/condominium/listByObjectIds
     */
    condominiumServiceListByObjectIds: (body: ObjectcondominiumListByObjectIdsRequest, params: RequestParams = {}) =>
      this.request<CondominiumServiceListByObjectIdsData, CondominiumServiceListByObjectIdsError>({
        path: `/web/objects/condominium/listByObjectIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceListByOwner
     * @request GET:/web/objects/condominium/listByOwner/{ownerId}
     */
    condominiumServiceListByOwner: (ownerId: string, params: RequestParams = {}) =>
      this.request<CondominiumServiceListByOwnerData, CondominiumServiceListByOwnerError>({
        path: `/web/objects/condominium/listByOwner/${ownerId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceListByOwnerContact
     * @request GET:/web/objects/condominium/listByOwnerContact/{ownerContactId}
     */
    condominiumServiceListByOwnerContact: (ownerContactId: string, params: RequestParams = {}) =>
      this.request<CondominiumServiceListByOwnerContactData, CondominiumServiceListByOwnerContactError>({
        path: `/web/objects/condominium/listByOwnerContact/${ownerContactId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceListByTenantContacts
     * @request GET:/web/objects/condominium/listByTenantContacts/{tenantContactIds}
     */
    condominiumServiceListByTenantContacts: (tenantContactIds: string[], params: RequestParams = {}) =>
      this.request<CondominiumServiceListByTenantContactsData, CondominiumServiceListByTenantContactsError>({
        path: `/web/objects/condominium/listByTenantContacts/${tenantContactIds}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceListCurrentByObjectIds
     * @request POST:/web/objects/condominium/listCurrentByObjectIds
     */
    condominiumServiceListCurrentByObjectIds: (
      body: ObjectcondominiumListCurrentByObjectIdsRequest,
      params: RequestParams = {},
    ) =>
      this.request<CondominiumServiceListCurrentByObjectIdsData, CondominiumServiceListCurrentByObjectIdsError>({
        path: `/web/objects/condominium/listCurrentByObjectIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServicePublishUpdatedEvents
     * @request POST:/web/objects/condominium/publishUpdatedEvents
     */
    condominiumServicePublishUpdatedEvents: (
      body: ObjectcondominiumPublishUpdatedEventsRequest,
      params: RequestParams = {},
    ) =>
      this.request<CondominiumServicePublishUpdatedEventsData, CondominiumServicePublishUpdatedEventsError>({
        path: `/web/objects/condominium/publishUpdatedEvents`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceUpdateBulk
     * @request POST:/web/objects/condominium/updateBulk
     */
    condominiumServiceUpdateBulk: (body: ObjectcondominiumUpdateBulkRequest, params: RequestParams = {}) =>
      this.request<CondominiumServiceUpdateBulkData, CondominiumServiceUpdateBulkError>({
        path: `/web/objects/condominium/updateBulk`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceDelete
     * @summary Delete
     * @request DELETE:/web/objects/condominium/{id}
     */
    condominiumServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<CondominiumServiceDeleteData, CondominiumServiceDeleteError>({
        path: `/web/objects/condominium/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceActivate
     * @request POST:/web/objects/condominium/{id}/activate
     */
    condominiumServiceActivate: (
      id: string,
      body: {
        documentIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<CondominiumServiceActivateData, CondominiumServiceActivateError>({
        path: `/web/objects/condominium/${id}/activate`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceAddDocuments
     * @summary Document
     * @request POST:/web/objects/condominium/{id}/addDocument
     */
    condominiumServiceAddDocuments: (
      id: string,
      body: {
        documentIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<CondominiumServiceAddDocumentsData, CondominiumServiceAddDocumentsError>({
        path: `/web/objects/condominium/${id}/addDocument`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceDeactivate
     * @request POST:/web/objects/condominium/{id}/deactivate
     */
    condominiumServiceDeactivate: (id: string, body: object, params: RequestParams = {}) =>
      this.request<CondominiumServiceDeactivateData, CondominiumServiceDeactivateError>({
        path: `/web/objects/condominium/${id}/deactivate`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceUpdate
     * @summary Update
     * @request POST:/web/objects/condominium/{id}/update
     */
    condominiumServiceUpdate: (id: string, body: CondominiumServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<CondominiumServiceUpdateData, CondominiumServiceUpdateError>({
        path: `/web/objects/condominium/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CondominiumService
     * @name CondominiumServiceDeactivateByNumericId
     * @request POST:/web/objects/condominium/{numericId}/deactivateByNumericId
     */
    condominiumServiceDeactivateByNumericId: (
      numericId: string,
      body: {
        /** @format int64 */
        ownerId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CondominiumServiceDeactivateByNumericIdData, CondominiumServiceDeactivateByNumericIdError>({
        path: `/web/objects/condominium/${numericId}/deactivateByNumericId`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceCreateTemplate
     * @summary Create
     * @request POST:/web/objects/contract/template/create
     */
    contractServiceCreateTemplate: (body: ObjectcontractCreateTemplateRequest, params: RequestParams = {}) =>
      this.request<ContractServiceCreateTemplateData, ContractServiceCreateTemplateError>({
        path: `/web/objects/contract/template/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceDeleteTemplate
     * @summary Delete
     * @request DELETE:/web/objects/contract/template/delete/{ids}
     */
    contractServiceDeleteTemplate: (ids: string[], params: RequestParams = {}) =>
      this.request<ContractServiceDeleteTemplateData, ContractServiceDeleteTemplateError>({
        path: `/web/objects/contract/template/delete/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceListTemplates
     * @summary Read
     * @request GET:/web/objects/contract/template/list/{ownerIds}
     */
    contractServiceListTemplates: (ownerIds: string[], params: RequestParams = {}) =>
      this.request<ContractServiceListTemplatesData, ContractServiceListTemplatesError>({
        path: `/web/objects/contract/template/list/${ownerIds}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderTemplate
     * @request POST:/web/objects/contract/template/render
     */
    contractServiceRenderTemplate: (body: ContractRenderTemplateRequest, params: RequestParams = {}) =>
      this.request<ContractServiceRenderTemplateData, ContractServiceRenderTemplateError>({
        path: `/web/objects/contract/template/render`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderDeviceTemplate
     * @request POST:/web/objects/contract/template/renderDevice
     */
    contractServiceRenderDeviceTemplate: (
      body: ObjectcontractRenderDeviceTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceRenderDeviceTemplateData, ContractServiceRenderDeviceTemplateError>({
        path: `/web/objects/contract/template/renderDevice`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderTenanciesTemplate
     * @request POST:/web/objects/contract/template/renderTenancies
     */
    contractServiceRenderTenanciesTemplate: (
      body: ObjectcontractRenderTenanciesTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceRenderTenanciesTemplateData, ContractServiceRenderTenanciesTemplateError>({
        path: `/web/objects/contract/template/renderTenancies`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceUpdateTemplate
     * @summary Update
     * @request POST:/web/objects/contract/template/update/{id}
     */
    contractServiceUpdateTemplate: (
      id: string,
      body: ContractServiceUpdateTemplatePayload,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceUpdateTemplateData, ContractServiceUpdateTemplateError>({
        path: `/web/objects/contract/template/update/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderCondominiumTemplate
     * @request POST:/web/objects/contracts/template/renderCondominium
     */
    contractServiceRenderCondominiumTemplate: (
      body: ObjectcontractRenderCondominiumTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceRenderCondominiumTemplateData, ContractServiceRenderCondominiumTemplateError>({
        path: `/web/objects/contracts/template/renderCondominium`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderCondominiumsTemplate
     * @request POST:/web/objects/contracts/template/renderCondominiums
     */
    contractServiceRenderCondominiumsTemplate: (
      body: ObjectcontractRenderCondominiumsTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceRenderCondominiumsTemplateData, ContractServiceRenderCondominiumsTemplateError>({
        path: `/web/objects/contracts/template/renderCondominiums`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderContactTemplate
     * @request POST:/web/objects/contracts/template/renderContact
     */
    contractServiceRenderContactTemplate: (
      body: ObjectcontractRenderContactTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceRenderContactTemplateData, ContractServiceRenderContactTemplateError>({
        path: `/web/objects/contracts/template/renderContact`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderContactsTemplate
     * @request POST:/web/objects/contracts/template/renderContacts
     */
    contractServiceRenderContactsTemplate: (
      body: ObjectcontractRenderContactsTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceRenderContactsTemplateData, ContractServiceRenderContactsTemplateError>({
        path: `/web/objects/contracts/template/renderContacts`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryIndexService
     * @name CountryIndexServiceGetInflation
     * @request GET:/web/objects/country-index/getInflation
     */
    countryIndexServiceGetInflation: (query: CountryIndexServiceGetInflationParams, params: RequestParams = {}) =>
      this.request<CountryIndexServiceGetInflationData, CountryIndexServiceGetInflationError>({
        path: `/web/objects/country-index/getInflation`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryIndexService
     * @name CountryIndexServiceRead
     * @request POST:/web/objects/country-index/read
     */
    countryIndexServiceRead: (body: CountryIndexReadRequest, params: RequestParams = {}) =>
      this.request<CountryIndexServiceReadData, CountryIndexServiceReadError>({
        path: `/web/objects/country-index/read`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceCreate
     * @summary Create
     * @request POST:/web/objects/create
     */
    objectServiceCreate: (body: ObjectobjectCreateRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceCreateData, ObjectServiceCreateError>({
        path: `/web/objects/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceCreateBulk
     * @request POST:/web/objects/createBulk
     */
    objectServiceCreateBulk: (body: ObjectobjectCreateBulkRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceCreateBulkData, ObjectServiceCreateBulkError>({
        path: `/web/objects/createBulk`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DemoDataService
     * @name DemoDataServiceGenerate
     * @request POST:/web/objects/demo-data/generate
     */
    demoDataServiceGenerate: (body: DemoDataGenerateRequest, params: RequestParams = {}) =>
      this.request<DemoDataServiceGenerateData, DemoDataServiceGenerateError>({
        path: `/web/objects/demo-data/generate`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceService
     * @name DeviceServiceCreate
     * @request POST:/web/objects/device/create
     */
    deviceServiceCreate: (body: ObjectdeviceCreateRequest, params: RequestParams = {}) =>
      this.request<DeviceServiceCreateData, DeviceServiceCreateError>({
        path: `/web/objects/device/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceService
     * @name DeviceServiceGetDefaultData
     * @request GET:/web/objects/device/getDefaultData
     */
    deviceServiceGetDefaultData: (query: DeviceServiceGetDefaultDataParams, params: RequestParams = {}) =>
      this.request<DeviceServiceGetDefaultDataData, DeviceServiceGetDefaultDataError>({
        path: `/web/objects/device/getDefaultData`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceService
     * @name DeviceServiceListByIds
     * @request POST:/web/objects/device/listByIds
     */
    deviceServiceListByIds: (body: ObjectdeviceListByIdsRequest, params: RequestParams = {}) =>
      this.request<DeviceServiceListByIdsData, DeviceServiceListByIdsError>({
        path: `/web/objects/device/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceService
     * @name DeviceServiceListByReferenceIds
     * @request POST:/web/objects/device/listByReferenceIds
     */
    deviceServiceListByReferenceIds: (body: ObjectdeviceListByReferenceIdsRequest, params: RequestParams = {}) =>
      this.request<DeviceServiceListByReferenceIdsData, DeviceServiceListByReferenceIdsError>({
        path: `/web/objects/device/listByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceService
     * @name DeviceServiceDelete
     * @request DELETE:/web/objects/device/{id}
     */
    deviceServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<DeviceServiceDeleteData, DeviceServiceDeleteError>({
        path: `/web/objects/device/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceService
     * @name DeviceServiceAddDocuments
     * @request POST:/web/objects/device/{id}/addDocuments
     */
    deviceServiceAddDocuments: ({ id, ...query }: DeviceServiceAddDocumentsParams, params: RequestParams = {}) =>
      this.request<DeviceServiceAddDocumentsData, DeviceServiceAddDocumentsError>({
        path: `/web/objects/device/${id}/addDocuments`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceService
     * @name DeviceServiceUpdate
     * @request POST:/web/objects/device/{id}/update
     */
    deviceServiceUpdate: (id: string, body: DeviceServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<DeviceServiceUpdateData, DeviceServiceUpdateError>({
        path: `/web/objects/device/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EnergySourceService
     * @name EnergySourceServiceCreate
     * @request POST:/web/objects/energy-source/create
     */
    energySourceServiceCreate: (body: ObjectenergySourceCreateRequest, params: RequestParams = {}) =>
      this.request<EnergySourceServiceCreateData, EnergySourceServiceCreateError>({
        path: `/web/objects/energy-source/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EnergySourceService
     * @name EnergySourceServiceListByIds
     * @request POST:/web/objects/energy-source/listByIds
     */
    energySourceServiceListByIds: (body: ObjectenergySourceListByIdsRequest, params: RequestParams = {}) =>
      this.request<EnergySourceServiceListByIdsData, EnergySourceServiceListByIdsError>({
        path: `/web/objects/energy-source/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EnergySourceService
     * @name EnergySourceServiceListByReferenceIds
     * @request POST:/web/objects/energy-source/listByReferenceIds
     */
    energySourceServiceListByReferenceIds: (
      body: ObjectenergySourceListByReferenceIdsRequest,
      params: RequestParams = {},
    ) =>
      this.request<EnergySourceServiceListByReferenceIdsData, EnergySourceServiceListByReferenceIdsError>({
        path: `/web/objects/energy-source/listByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EnergySourceService
     * @name EnergySourceServiceDelete
     * @request DELETE:/web/objects/energy-source/{ids}
     */
    energySourceServiceDelete: (ids: string[], params: RequestParams = {}) =>
      this.request<EnergySourceServiceDeleteData, EnergySourceServiceDeleteError>({
        path: `/web/objects/energy-source/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EnergySourceService
     * @name EnergySourceServiceUpdate
     * @request POST:/web/objects/energy-source/{id}/update
     */
    energySourceServiceUpdate: (id: string, body: EnergySourceServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<EnergySourceServiceUpdateData, EnergySourceServiceUpdateError>({
        path: `/web/objects/energy-source/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FahrlaenderService
     * @name FahrlaenderServiceLoadConfiguration
     * @request GET:/web/objects/fahrlaender/{accountId}/loadConfiguration
     */
    fahrlaenderServiceLoadConfiguration: (accountId: string, params: RequestParams = {}) =>
      this.request<FahrlaenderServiceLoadConfigurationData, FahrlaenderServiceLoadConfigurationError>({
        path: `/web/objects/fahrlaender/${accountId}/loadConfiguration`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FahrlaenderService
     * @name FahrlaenderServiceSaveConfiguration
     * @request POST:/web/objects/fahrlaender/{accountId}/saveConfiguration
     */
    fahrlaenderServiceSaveConfiguration: (
      accountId: string,
      body: FahrlaenderServiceSaveConfigurationPayload,
      params: RequestParams = {},
    ) =>
      this.request<FahrlaenderServiceSaveConfigurationData, FahrlaenderServiceSaveConfigurationError>({
        path: `/web/objects/fahrlaender/${accountId}/saveConfiguration`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FahrlaenderService
     * @name FahrlaenderServiceUpdateMarketRent
     * @summary Business logic
     * @request POST:/web/objects/fahrlaender/{accountId}/updateMarketRent
     */
    fahrlaenderServiceUpdateMarketRent: (accountId: string, body: object, params: RequestParams = {}) =>
      this.request<FahrlaenderServiceUpdateMarketRentData, FahrlaenderServiceUpdateMarketRentError>({
        path: `/web/objects/fahrlaender/${accountId}/updateMarketRent`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FahrlaenderService
     * @name FahrlaenderServiceUpdateMicroLocationRatings
     * @request POST:/web/objects/fahrlaender/{accountId}/updateMicroLocationRating
     */
    fahrlaenderServiceUpdateMicroLocationRatings: (accountId: string, body: object, params: RequestParams = {}) =>
      this.request<FahrlaenderServiceUpdateMicroLocationRatingsData, FahrlaenderServiceUpdateMicroLocationRatingsError>(
        {
          path: `/web/objects/fahrlaender/${accountId}/updateMicroLocationRating`,
          method: "POST",
          body: body,
          ...params,
        },
      ),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGet
     * @summary Read
     * @request GET:/web/objects/get/{id}
     */
    objectServiceGet: (id: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetData, ObjectServiceGetError>({
        path: `/web/objects/get/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetAccountingStatistics
     * @request GET:/web/objects/getAccountingStatistics/{accountId}
     */
    objectServiceGetAccountingStatistics: (accountId: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetAccountingStatisticsData, ObjectServiceGetAccountingStatisticsError>({
        path: `/web/objects/getAccountingStatistics/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetAdministrationStatistics
     * @request GET:/web/objects/getAdministrationStatistics/{accountId}
     */
    objectServiceGetAdministrationStatistics: (accountId: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetAdministrationStatisticsData, ObjectServiceGetAdministrationStatisticsError>({
        path: `/web/objects/getAdministrationStatistics/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetAssetStatistics
     * @request GET:/web/objects/getAssetStatistics/{accountId}
     */
    objectServiceGetAssetStatistics: (accountId: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetAssetStatisticsData, ObjectServiceGetAssetStatisticsError>({
        path: `/web/objects/getAssetStatistics/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetMarketing
     * @summary Marketing
     * @request GET:/web/objects/getMarketing/{id}
     */
    objectServiceGetMarketing: (id: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetMarketingData, ObjectServiceGetMarketingError>({
        path: `/web/objects/getMarketing/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetMarketingStatistics
     * @request GET:/web/objects/getMarketingStatistics/{accountId}
     */
    objectServiceGetMarketingStatistics: (
      { accountId, ...query }: ObjectServiceGetMarketingStatisticsParams,
      params: RequestParams = {},
    ) =>
      this.request<ObjectServiceGetMarketingStatisticsData, ObjectServiceGetMarketingStatisticsError>({
        path: `/web/objects/getMarketingStatistics/${accountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetObjectTypes
     * @request GET:/web/objects/getObjectTypes/{accountId}
     */
    objectServiceGetObjectTypes: (accountId: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetObjectTypesData, ObjectServiceGetObjectTypesError>({
        path: `/web/objects/getObjectTypes/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetOwnerStatistics
     * @summary Statistics
     * @request GET:/web/objects/getOwnerStatistics/{accountId}
     */
    objectServiceGetOwnerStatistics: (accountId: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetOwnerStatisticsData, ObjectServiceGetOwnerStatisticsError>({
        path: `/web/objects/getOwnerStatistics/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetTotalValueQuotaForBuilding
     * @request GET:/web/objects/getTotalValueQuotaForBuilding/{buildingId}
     */
    objectServiceGetTotalValueQuotaForBuilding: (buildingId: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetTotalValueQuotaForBuildingData, ObjectServiceGetTotalValueQuotaForBuildingError>({
        path: `/web/objects/getTotalValueQuotaForBuilding/${buildingId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetUsedTags
     * @request GET:/web/objects/getUsedTags/{accountId}
     */
    objectServiceGetUsedTags: (accountId: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetUsedTagsData, ObjectServiceGetUsedTagsError>({
        path: `/web/objects/getUsedTags/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupService
     * @name GroupServiceAddBuilding
     * @request POST:/web/objects/group/addBuilding/{id}/{buildingId}
     */
    groupServiceAddBuilding: (id: string, buildingId: string, body: object, params: RequestParams = {}) =>
      this.request<GroupServiceAddBuildingData, GroupServiceAddBuildingError>({
        path: `/web/objects/group/addBuilding/${id}/${buildingId}`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupService
     * @name GroupServiceCreate
     * @request POST:/web/objects/group/create
     */
    groupServiceCreate: (body: ObjectgroupCreateRequest, params: RequestParams = {}) =>
      this.request<GroupServiceCreateData, GroupServiceCreateError>({
        path: `/web/objects/group/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupService
     * @name GroupServiceDelete
     * @request DELETE:/web/objects/group/delete/{id}
     */
    groupServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<GroupServiceDeleteData, GroupServiceDeleteError>({
        path: `/web/objects/group/delete/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupService
     * @name GroupServiceGet
     * @request GET:/web/objects/group/get/{id}
     */
    groupServiceGet: (id: string, params: RequestParams = {}) =>
      this.request<GroupServiceGetData, GroupServiceGetError>({
        path: `/web/objects/group/get/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupService
     * @name GroupServiceList
     * @request GET:/web/objects/group/list/{accountId}
     */
    groupServiceList: (accountId: string, params: RequestParams = {}) =>
      this.request<GroupServiceListData, GroupServiceListError>({
        path: `/web/objects/group/list/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupService
     * @name GroupServiceRemoveBuilding
     * @request POST:/web/objects/group/removeBuilding/{id}/{buildingId}
     */
    groupServiceRemoveBuilding: (id: string, buildingId: string, body: object, params: RequestParams = {}) =>
      this.request<GroupServiceRemoveBuildingData, GroupServiceRemoveBuildingError>({
        path: `/web/objects/group/removeBuilding/${id}/${buildingId}`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupService
     * @name GroupServiceUpdate
     * @request POST:/web/objects/group/update/{id}
     */
    groupServiceUpdate: (id: string, body: GroupServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<GroupServiceUpdateData, GroupServiceUpdateError>({
        path: `/web/objects/group/update/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceCreate
     * @request POST:/web/objects/handover-protocol/create
     */
    handoverProtocolServiceCreate: (body: ObjecthandoverProtocolCreateRequest, params: RequestParams = {}) =>
      this.request<HandoverProtocolServiceCreateData, HandoverProtocolServiceCreateError>({
        path: `/web/objects/handover-protocol/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceEnable
     * @request POST:/web/objects/handover-protocol/enable/{accountId}
     */
    handoverProtocolServiceEnable: (
      accountId: string,
      body: {
        enable?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<HandoverProtocolServiceEnableData, HandoverProtocolServiceEnableError>({
        path: `/web/objects/handover-protocol/enable/${accountId}`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceHasEnabled
     * @summary Feature enabling
     * @request GET:/web/objects/handover-protocol/hasEnabled/{accountId}
     */
    handoverProtocolServiceHasEnabled: (accountId: string, params: RequestParams = {}) =>
      this.request<HandoverProtocolServiceHasEnabledData, HandoverProtocolServiceHasEnabledError>({
        path: `/web/objects/handover-protocol/hasEnabled/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceListByIds
     * @request POST:/web/objects/handover-protocol/listByIds
     */
    handoverProtocolServiceListByIds: (body: ObjecthandoverProtocolListByIdsRequest, params: RequestParams = {}) =>
      this.request<HandoverProtocolServiceListByIdsData, HandoverProtocolServiceListByIdsError>({
        path: `/web/objects/handover-protocol/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceListByObjectIds
     * @request POST:/web/objects/handover-protocol/listByObjectIds
     */
    handoverProtocolServiceListByObjectIds: (
      body: ObjecthandoverProtocolListByObjectIdsRequest,
      params: RequestParams = {},
    ) =>
      this.request<HandoverProtocolServiceListByObjectIdsData, HandoverProtocolServiceListByObjectIdsError>({
        path: `/web/objects/handover-protocol/listByObjectIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceListMyProtocols
     * @request GET:/web/objects/handover-protocol/listMyProtocols
     */
    handoverProtocolServiceListMyProtocols: (
      query: HandoverProtocolServiceListMyProtocolsParams,
      params: RequestParams = {},
    ) =>
      this.request<HandoverProtocolServiceListMyProtocolsData, HandoverProtocolServiceListMyProtocolsError>({
        path: `/web/objects/handover-protocol/listMyProtocols`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceListOtherProtocols
     * @request GET:/web/objects/handover-protocol/listOtherProtocols
     */
    handoverProtocolServiceListOtherProtocols: (
      query: HandoverProtocolServiceListOtherProtocolsParams,
      params: RequestParams = {},
    ) =>
      this.request<HandoverProtocolServiceListOtherProtocolsData, HandoverProtocolServiceListOtherProtocolsError>({
        path: `/web/objects/handover-protocol/listOtherProtocols`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceDelete
     * @request DELETE:/web/objects/handover-protocol/{id}
     */
    handoverProtocolServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<HandoverProtocolServiceDeleteData, HandoverProtocolServiceDeleteError>({
        path: `/web/objects/handover-protocol/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceGetPdfPreview
     * @request GET:/web/objects/handover-protocol/{id}/getPdfPreview
     */
    handoverProtocolServiceGetPdfPreview: (
      { id, ...query }: HandoverProtocolServiceGetPdfPreviewParams,
      params: RequestParams = {},
    ) =>
      this.request<HandoverProtocolServiceGetPdfPreviewData, HandoverProtocolServiceGetPdfPreviewError>({
        path: `/web/objects/handover-protocol/${id}/getPdfPreview`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceSaveData
     * @request POST:/web/objects/handover-protocol/{id}/saveData
     */
    handoverProtocolServiceSaveData: (
      id: string,
      body: HandoverProtocolServiceSaveDataPayload,
      params: RequestParams = {},
    ) =>
      this.request<HandoverProtocolServiceSaveDataData, HandoverProtocolServiceSaveDataError>({
        path: `/web/objects/handover-protocol/${id}/saveData`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceSign
     * @request POST:/web/objects/handover-protocol/{id}/sign
     */
    handoverProtocolServiceSign: (id: string, body: HandoverProtocolServiceSignPayload, params: RequestParams = {}) =>
      this.request<HandoverProtocolServiceSignData, HandoverProtocolServiceSignError>({
        path: `/web/objects/handover-protocol/${id}/sign`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandoverProtocolService
     * @name HandoverProtocolServiceUpdate
     * @request POST:/web/objects/handover-protocol/{id}/update
     */
    handoverProtocolServiceUpdate: (
      id: string,
      body: HandoverProtocolServiceUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<HandoverProtocolServiceUpdateData, HandoverProtocolServiceUpdateError>({
        path: `/web/objects/handover-protocol/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InsuranceService
     * @name InsuranceServiceCreate
     * @summary Create
     * @request POST:/web/objects/insurance/create
     */
    insuranceServiceCreate: (body: ObjectinsuranceCreateRequest, params: RequestParams = {}) =>
      this.request<InsuranceServiceCreateData, InsuranceServiceCreateError>({
        path: `/web/objects/insurance/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InsuranceService
     * @name InsuranceServiceListByIds
     * @request GET:/web/objects/insurance/listByIds/{ids}
     */
    insuranceServiceListByIds: (ids: string[], params: RequestParams = {}) =>
      this.request<InsuranceServiceListByIdsData, InsuranceServiceListByIdsError>({
        path: `/web/objects/insurance/listByIds/${ids}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InsuranceService
     * @name InsuranceServiceListByReferenceIds
     * @summary Read
     * @request GET:/web/objects/insurance/listByReferenceIds/{referenceIds}
     */
    insuranceServiceListByReferenceIds: (referenceIds: string[], params: RequestParams = {}) =>
      this.request<InsuranceServiceListByReferenceIdsData, InsuranceServiceListByReferenceIdsError>({
        path: `/web/objects/insurance/listByReferenceIds/${referenceIds}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InsuranceService
     * @name InsuranceServiceDelete
     * @summary Delete
     * @request DELETE:/web/objects/insurance/{id}
     */
    insuranceServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<InsuranceServiceDeleteData, InsuranceServiceDeleteError>({
        path: `/web/objects/insurance/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InsuranceService
     * @name InsuranceServiceUpdate
     * @summary Update
     * @request POST:/web/objects/insurance/{id}/update
     */
    insuranceServiceUpdate: (id: string, body: InsuranceServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<InsuranceServiceUpdateData, InsuranceServiceUpdateError>({
        path: `/web/objects/insurance/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestPlanService
     * @name InvestPlanServiceCreateBulk
     * @request POST:/web/objects/invest-plan/createBulk
     */
    investPlanServiceCreateBulk: (body: ObjectinvestPlanCreateBulkRequest, params: RequestParams = {}) =>
      this.request<InvestPlanServiceCreateBulkData, InvestPlanServiceCreateBulkError>({
        path: `/web/objects/invest-plan/createBulk`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestPlanService
     * @name InvestPlanServiceDelete
     * @request DELETE:/web/objects/invest-plan/delete/{id}
     */
    investPlanServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<InvestPlanServiceDeleteData, InvestPlanServiceDeleteError>({
        path: `/web/objects/invest-plan/delete/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestPlanService
     * @name InvestPlanServiceEnable
     * @request POST:/web/objects/invest-plan/enable/{accountId}
     */
    investPlanServiceEnable: (
      accountId: string,
      body: {
        enable?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvestPlanServiceEnableData, InvestPlanServiceEnableError>({
        path: `/web/objects/invest-plan/enable/${accountId}`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestPlanService
     * @name InvestPlanServiceHasEnabled
     * @summary Feature enabling
     * @request GET:/web/objects/invest-plan/hasEnabled/{accountId}
     */
    investPlanServiceHasEnabled: (accountId: string, params: RequestParams = {}) =>
      this.request<InvestPlanServiceHasEnabledData, InvestPlanServiceHasEnabledError>({
        path: `/web/objects/invest-plan/hasEnabled/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestPlanService
     * @name InvestPlanServiceListByReferenceIds
     * @request POST:/web/objects/invest-plan/listByReferenceIds
     */
    investPlanServiceListByReferenceIds: (
      body: ObjectinvestPlanListByReferenceIdsRequest,
      params: RequestParams = {},
    ) =>
      this.request<InvestPlanServiceListByReferenceIdsData, InvestPlanServiceListByReferenceIdsError>({
        path: `/web/objects/invest-plan/listByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestPlanService
     * @name InvestPlanServiceUpdateBulk
     * @request POST:/web/objects/invest-plan/update
     */
    investPlanServiceUpdateBulk: (body: ObjectinvestPlanUpdateBulkRequest, params: RequestParams = {}) =>
      this.request<InvestPlanServiceUpdateBulkData, InvestPlanServiceUpdateBulkError>({
        path: `/web/objects/invest-plan/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceIsActive
     * @request GET:/web/objects/isActive/{id}
     */
    objectServiceIsActive: (id: string, params: RequestParams = {}) =>
      this.request<ObjectServiceIsActiveData, ObjectServiceIsActiveError>({
        path: `/web/objects/isActive/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceList
     * @request GET:/web/objects/list/{accountId}
     */
    objectServiceList: ({ accountId, ...query }: ObjectServiceListParams, params: RequestParams = {}) =>
      this.request<ObjectServiceListData, ObjectServiceListError>({
        path: `/web/objects/list/${accountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceListByBuildingIds
     * @request POST:/web/objects/listByBuildingIds
     */
    objectServiceListByBuildingIds: (body: ObjectobjectListByBuildingIdsRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceListByBuildingIdsData, ObjectServiceListByBuildingIdsError>({
        path: `/web/objects/listByBuildingIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceListByIds
     * @request POST:/web/objects/listByIds
     */
    objectServiceListByIds: (body: ObjectobjectListByIdsRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceListByIdsData, ObjectServiceListByIdsError>({
        path: `/web/objects/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceListFilterOptions
     * @request GET:/web/objects/listFilterOptions
     */
    objectServiceListFilterOptions: (params: RequestParams = {}) =>
      this.request<ObjectServiceListFilterOptionsData, ObjectServiceListFilterOptionsError>({
        path: `/web/objects/listFilterOptions`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceListFreeByDate
     * @request POST:/web/objects/listFreeByDate
     */
    objectServiceListFreeByDate: (body: ObjectListFreeByDateRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceListFreeByDateData, ObjectServiceListFreeByDateError>({
        path: `/web/objects/listFreeByDate`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceListIdsInMarketing
     * @request GET:/web/objects/listIdsInMarketing/{marketerAccountId}
     */
    objectServiceListIdsInMarketing: (marketerAccountId: string, params: RequestParams = {}) =>
      this.request<ObjectServiceListIdsInMarketingData, ObjectServiceListIdsInMarketingError>({
        path: `/web/objects/listIdsInMarketing/${marketerAccountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceListMarketing
     * @request GET:/web/objects/listMarketing/{accountId}
     */
    objectServiceListMarketing: (
      { accountId, ...query }: ObjectServiceListMarketingParams,
      params: RequestParams = {},
    ) =>
      this.request<ObjectServiceListMarketingData, ObjectServiceListMarketingError>({
        path: `/web/objects/listMarketing/${accountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceListPublished
     * @request GET:/web/objects/listPublished/{ownerId}
     */
    objectServiceListPublished: (ownerId: string, params: RequestParams = {}) =>
      this.request<ObjectServiceListPublishedData, ObjectServiceListPublishedError>({
        path: `/web/objects/listPublished/${ownerId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceMarkForMarketing
     * @request POST:/web/objects/markForMarketing/{id}
     */
    objectServiceMarkForMarketing: (
      id: string,
      body: ObjectServiceMarkForMarketingPayload,
      params: RequestParams = {},
    ) =>
      this.request<ObjectServiceMarkForMarketingData, ObjectServiceMarkForMarketingError>({
        path: `/web/objects/markForMarketing/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceMarketingMarkSeen
     * @request POST:/web/objects/marketingMarkSeen/{id}
     */
    objectServiceMarketingMarkSeen: (id: string, body: object, params: RequestParams = {}) =>
      this.request<ObjectServiceMarketingMarkSeenData, ObjectServiceMarketingMarkSeenError>({
        path: `/web/objects/marketingMarkSeen/${id}`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MortgageService
     * @name MortgageServiceCreate
     * @summary Create
     * @request POST:/web/objects/mortgage/create
     */
    mortgageServiceCreate: (body: ObjectmortgageCreateRequest, params: RequestParams = {}) =>
      this.request<MortgageServiceCreateData, MortgageServiceCreateError>({
        path: `/web/objects/mortgage/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MortgageService
     * @name MortgageServiceListByIds
     * @summary Read
     * @request POST:/web/objects/mortgage/listByIds
     */
    mortgageServiceListByIds: (body: ObjectmortgageListByIdsRequest, params: RequestParams = {}) =>
      this.request<MortgageServiceListByIdsData, MortgageServiceListByIdsError>({
        path: `/web/objects/mortgage/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MortgageService
     * @name MortgageServiceListByReferenceIds
     * @request POST:/web/objects/mortgage/listByReferenceIds
     */
    mortgageServiceListByReferenceIds: (body: ObjectmortgageListByReferenceIdsRequest, params: RequestParams = {}) =>
      this.request<MortgageServiceListByReferenceIdsData, MortgageServiceListByReferenceIdsError>({
        path: `/web/objects/mortgage/listByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MortgageService
     * @name MortgageServiceDelete
     * @summary Delete
     * @request DELETE:/web/objects/mortgage/{ids}
     */
    mortgageServiceDelete: (ids: string[], params: RequestParams = {}) =>
      this.request<MortgageServiceDeleteData, MortgageServiceDeleteError>({
        path: `/web/objects/mortgage/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MortgageService
     * @name MortgageServiceAddDocuments
     * @request POST:/web/objects/mortgage/{id}/addDocument
     */
    mortgageServiceAddDocuments: (
      id: string,
      body: {
        documentIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<MortgageServiceAddDocumentsData, MortgageServiceAddDocumentsError>({
        path: `/web/objects/mortgage/${id}/addDocument`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MortgageService
     * @name MortgageServiceUpdate
     * @summary Update
     * @request POST:/web/objects/mortgage/{id}/update
     */
    mortgageServiceUpdate: (id: string, body: MortgageServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<MortgageServiceUpdateData, MortgageServiceUpdateError>({
        path: `/web/objects/mortgage/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceMoveObjects
     * @request POST:/web/objects/moveObjects
     */
    objectServiceMoveObjects: (body: ObjectMoveObjectsRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceMoveObjectsData, ObjectServiceMoveObjectsError>({
        path: `/web/objects/moveObjects`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlotService
     * @name PlotServiceCreate
     * @summary Create
     * @request POST:/web/objects/plot/create
     */
    plotServiceCreate: (body: ObjectplotCreateRequest, params: RequestParams = {}) =>
      this.request<PlotServiceCreateData, PlotServiceCreateError>({
        path: `/web/objects/plot/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlotService
     * @name PlotServiceListByIds
     * @request GET:/web/objects/plot/listByIds/{ids}
     */
    plotServiceListByIds: (ids: string[], params: RequestParams = {}) =>
      this.request<PlotServiceListByIdsData, PlotServiceListByIdsError>({
        path: `/web/objects/plot/listByIds/${ids}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlotService
     * @name PlotServiceListByOwnerId
     * @summary Read
     * @request GET:/web/objects/plot/listByOwnerId/{ownerId}
     */
    plotServiceListByOwnerId: ({ ownerId, ...query }: PlotServiceListByOwnerIdParams, params: RequestParams = {}) =>
      this.request<PlotServiceListByOwnerIdData, PlotServiceListByOwnerIdError>({
        path: `/web/objects/plot/listByOwnerId/${ownerId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlotService
     * @name PlotServiceListByPropertyId
     * @request GET:/web/objects/plot/listByPropertyId/{propertyId}
     */
    plotServiceListByPropertyId: (propertyId: string, params: RequestParams = {}) =>
      this.request<PlotServiceListByPropertyIdData, PlotServiceListByPropertyIdError>({
        path: `/web/objects/plot/listByPropertyId/${propertyId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlotService
     * @name PlotServiceUpdate
     * @summary Update
     * @request POST:/web/objects/plot/updatePlots
     */
    plotServiceUpdate: (body: ObjectplotUpdateRequest, params: RequestParams = {}) =>
      this.request<PlotServiceUpdateData, PlotServiceUpdateError>({
        path: `/web/objects/plot/updatePlots`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlotService
     * @name PlotServiceDelete
     * @summary Delete
     * @request DELETE:/web/objects/plot/{ids}
     */
    plotServiceDelete: (ids: string[], params: RequestParams = {}) =>
      this.request<PlotServiceDeleteData, PlotServiceDeleteError>({
        path: `/web/objects/plot/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceCount
     * @request GET:/web/objects/property/count/{accountId}
     */
    propertyServiceCount: ({ accountId, ...query }: PropertyServiceCountParams, params: RequestParams = {}) =>
      this.request<PropertyServiceCountData, PropertyServiceCountError>({
        path: `/web/objects/property/count/${accountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceCreate
     * @summary Create
     * @request POST:/web/objects/property/create
     */
    propertyServiceCreate: (body: ObjectpropertyCreateRequest, params: RequestParams = {}) =>
      this.request<PropertyServiceCreateData, PropertyServiceCreateError>({
        path: `/web/objects/property/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceList
     * @summary Read
     * @request GET:/web/objects/property/list/{ownerId}
     */
    propertyServiceList: ({ ownerId, ...query }: PropertyServiceListParams, params: RequestParams = {}) =>
      this.request<PropertyServiceListData, PropertyServiceListError>({
        path: `/web/objects/property/list/${ownerId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceListByBuildingIds
     * @request GET:/web/objects/property/listByBuildingIds/{buildingIds}
     */
    propertyServiceListByBuildingIds: (buildingIds: string[], params: RequestParams = {}) =>
      this.request<PropertyServiceListByBuildingIdsData, PropertyServiceListByBuildingIdsError>({
        path: `/web/objects/property/listByBuildingIds/${buildingIds}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceListByIds
     * @request GET:/web/objects/property/listByIds/{ids}
     */
    propertyServiceListByIds: (ids: string[], params: RequestParams = {}) =>
      this.request<PropertyServiceListByIdsData, PropertyServiceListByIdsError>({
        path: `/web/objects/property/listByIds/${ids}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceTransferToAccounting
     * @request POST:/web/objects/property/transferToAccounting
     */
    propertyServiceTransferToAccounting: (
      body: ObjectpropertyTransferToAccountingRequest,
      params: RequestParams = {},
    ) =>
      this.request<PropertyServiceTransferToAccountingData, PropertyServiceTransferToAccountingError>({
        path: `/web/objects/property/transferToAccounting`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceDelete
     * @summary Delete
     * @request DELETE:/web/objects/property/{ids}
     */
    propertyServiceDelete: (ids: string[], params: RequestParams = {}) =>
      this.request<PropertyServiceDeleteData, PropertyServiceDeleteError>({
        path: `/web/objects/property/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceUpdate
     * @summary Update
     * @request POST:/web/objects/property/{id}/update
     */
    propertyServiceUpdate: (id: string, body: PropertyServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<PropertyServiceUpdateData, PropertyServiceUpdateError>({
        path: `/web/objects/property/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServicePublish
     * @request POST:/web/objects/publish
     */
    objectServicePublish: (body: ObjectPublishRequest, params: RequestParams = {}) =>
      this.request<ObjectServicePublishData, ObjectServicePublishError>({
        path: `/web/objects/publish`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServicePublishUpdatedEvents
     * @request POST:/web/objects/publishUpdatedEvents
     */
    objectServicePublishUpdatedEvents: (body: ObjectobjectPublishUpdatedEventsRequest, params: RequestParams = {}) =>
      this.request<ObjectServicePublishUpdatedEventsData, ObjectServicePublishUpdatedEventsError>({
        path: `/web/objects/publishUpdatedEvents`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceRemoveCraftsmen
     * @request POST:/web/objects/removeCraftsmen
     */
    objectServiceRemoveCraftsmen: (body: ObjectRemoveCraftsmenRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceRemoveCraftsmenData, ObjectServiceRemoveCraftsmenError>({
        path: `/web/objects/removeCraftsmen`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceRemoveFromMarketing
     * @request POST:/web/objects/removeFromMarketing
     */
    objectServiceRemoveFromMarketing: (body: ObjectRemoveFromMarketingRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceRemoveFromMarketingData, ObjectServiceRemoveFromMarketingError>({
        path: `/web/objects/removeFromMarketing`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceRemoveJanitors
     * @request POST:/web/objects/removeJanitors
     */
    objectServiceRemoveJanitors: (body: ObjectRemoveJanitorsRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceRemoveJanitorsData, ObjectServiceRemoveJanitorsError>({
        path: `/web/objects/removeJanitors`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceRemoveServiceProviders
     * @request POST:/web/objects/removeServiceProviders
     */
    objectServiceRemoveServiceProviders: (body: ObjectRemoveServiceProvidersRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceRemoveServiceProvidersData, ObjectServiceRemoveServiceProvidersError>({
        path: `/web/objects/removeServiceProviders`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RentalIndexService
     * @name RentalIndexServiceCreate
     * @summary CRUD
     * @request POST:/web/objects/rental-index/create
     */
    rentalIndexServiceCreate: (body: ObjectrentalIndexCreateRequest, params: RequestParams = {}) =>
      this.request<RentalIndexServiceCreateData, RentalIndexServiceCreateError>({
        path: `/web/objects/rental-index/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RentalIndexService
     * @name RentalIndexServiceList
     * @request GET:/web/objects/rental-index/list
     */
    rentalIndexServiceList: (query: RentalIndexServiceListParams, params: RequestParams = {}) =>
      this.request<RentalIndexServiceListData, RentalIndexServiceListError>({
        path: `/web/objects/rental-index/list`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RentalIndexService
     * @name RentalIndexServiceRender
     * @request POST:/web/objects/rental-index/render
     */
    rentalIndexServiceRender: (body: ObjectrentalIndexRenderRequest, params: RequestParams = {}) =>
      this.request<RentalIndexServiceRenderData, RentalIndexServiceRenderError>({
        path: `/web/objects/rental-index/render`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RentalIndexService
     * @name RentalIndexServiceRenderPdf
     * @request POST:/web/objects/rental-index/renderPdf
     */
    rentalIndexServiceRenderPdf: (body: RentalIndexRenderPdfRequest, params: RequestParams = {}) =>
      this.request<RentalIndexServiceRenderPdfData, RentalIndexServiceRenderPdfError>({
        path: `/web/objects/rental-index/renderPdf`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RentalIndexService
     * @name RentalIndexServiceDelete
     * @request DELETE:/web/objects/rental-index/{id}
     */
    rentalIndexServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<RentalIndexServiceDeleteData, RentalIndexServiceDeleteError>({
        path: `/web/objects/rental-index/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RentalIndexService
     * @name RentalIndexServiceUpdate
     * @request POST:/web/objects/rental-index/{id}/update
     */
    rentalIndexServiceUpdate: (id: string, body: RentalIndexServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<RentalIndexServiceUpdateData, RentalIndexServiceUpdateError>({
        path: `/web/objects/rental-index/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomService
     * @name RoomServiceCreate
     * @summary Create
     * @request POST:/web/objects/room/create
     */
    roomServiceCreate: (body: ObjectroomCreateRequest, params: RequestParams = {}) =>
      this.request<RoomServiceCreateData, RoomServiceCreateError>({
        path: `/web/objects/room/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomService
     * @name RoomServiceListByIds
     * @summary Read
     * @request POST:/web/objects/room/listByIds
     */
    roomServiceListByIds: (body: ObjectroomListByIdsRequest, params: RequestParams = {}) =>
      this.request<RoomServiceListByIdsData, RoomServiceListByIdsError>({
        path: `/web/objects/room/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomService
     * @name RoomServiceListByReferenceIds
     * @request POST:/web/objects/room/listByReferenceIds
     */
    roomServiceListByReferenceIds: (body: ObjectroomListByReferenceIdsRequest, params: RequestParams = {}) =>
      this.request<RoomServiceListByReferenceIdsData, RoomServiceListByReferenceIdsError>({
        path: `/web/objects/room/listByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomService
     * @name RoomServiceUpdate
     * @summary Update
     * @request POST:/web/objects/room/updateRooms
     */
    roomServiceUpdate: (body: ObjectroomUpdateRequest, params: RequestParams = {}) =>
      this.request<RoomServiceUpdateData, RoomServiceUpdateError>({
        path: `/web/objects/room/updateRooms`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomService
     * @name RoomServiceDelete
     * @summary Delete
     * @request DELETE:/web/objects/room/{ids}
     */
    roomServiceDelete: (ids: string[], params: RequestParams = {}) =>
      this.request<RoomServiceDeleteData, RoomServiceDeleteError>({
        path: `/web/objects/room/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceSetAccountingAssignee
     * @request POST:/web/objects/setAccountingAssignee
     */
    objectServiceSetAccountingAssignee: (body: ObjectSetAccountingAssigneeRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceSetAccountingAssigneeData, ObjectServiceSetAccountingAssigneeError>({
        path: `/web/objects/setAccountingAssignee`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceSetAdminAssignee
     * @request POST:/web/objects/setAdminAssignee
     */
    objectServiceSetAdminAssignee: (body: ObjectSetAdminAssigneeRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceSetAdminAssigneeData, ObjectServiceSetAdminAssigneeError>({
        path: `/web/objects/setAdminAssignee`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceSetAssetAssignee
     * @request POST:/web/objects/setAssetAssignee
     */
    objectServiceSetAssetAssignee: (body: ObjectSetAssetAssigneeRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceSetAssetAssigneeData, ObjectServiceSetAssetAssigneeError>({
        path: `/web/objects/setAssetAssignee`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceSetContacts
     * @request POST:/web/objects/setContacts
     */
    objectServiceSetContacts: (body: ObjectSetContactsRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceSetContactsData, ObjectServiceSetContactsError>({
        path: `/web/objects/setContacts`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceSetMarketingAssignee
     * @request POST:/web/objects/setMarketingAssignee
     */
    objectServiceSetMarketingAssignee: (body: ObjectSetMarketingAssigneeRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceSetMarketingAssigneeData, ObjectServiceSetMarketingAssigneeError>({
        path: `/web/objects/setMarketingAssignee`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceSetOwnerAssignee
     * @request POST:/web/objects/setOwnerAssignee
     */
    objectServiceSetOwnerAssignee: (body: ObjectSetOwnerAssigneeRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceSetOwnerAssigneeData, ObjectServiceSetOwnerAssigneeError>({
        path: `/web/objects/setOwnerAssignee`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceSetViewingAppointmentContact
     * @request POST:/web/objects/setViewingAppointmentContact
     */
    objectServiceSetViewingAppointmentContact: (
      body: ObjectSetViewingAppointmentContactRequest,
      params: RequestParams = {},
    ) =>
      this.request<ObjectServiceSetViewingAppointmentContactData, ObjectServiceSetViewingAppointmentContactError>({
        path: `/web/objects/setViewingAppointmentContact`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SettlementObjectService
     * @name SettlementObjectServiceCreate
     * @summary Create
     * @request POST:/web/objects/settlement-object/create
     */
    settlementObjectServiceCreate: (body: ObjectsettlementObjectCreateRequest, params: RequestParams = {}) =>
      this.request<SettlementObjectServiceCreateData, SettlementObjectServiceCreateError>({
        path: `/web/objects/settlement-object/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SettlementObjectService
     * @name SettlementObjectServiceListByIds
     * @summary Read
     * @request POST:/web/objects/settlement-object/listByIds
     */
    settlementObjectServiceListByIds: (body: ObjectsettlementObjectListByIdsRequest, params: RequestParams = {}) =>
      this.request<SettlementObjectServiceListByIdsData, SettlementObjectServiceListByIdsError>({
        path: `/web/objects/settlement-object/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SettlementObjectService
     * @name SettlementObjectServiceListByReferenceId
     * @request GET:/web/objects/settlement-object/listByReferenceId/{referenceId}
     */
    settlementObjectServiceListByReferenceId: (referenceId: string, params: RequestParams = {}) =>
      this.request<SettlementObjectServiceListByReferenceIdData, SettlementObjectServiceListByReferenceIdError>({
        path: `/web/objects/settlement-object/listByReferenceId/${referenceId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SettlementObjectService
     * @name SettlementObjectServiceDelete
     * @summary Delete
     * @request DELETE:/web/objects/settlement-object/{id}/delete
     */
    settlementObjectServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<SettlementObjectServiceDeleteData, SettlementObjectServiceDeleteError>({
        path: `/web/objects/settlement-object/${id}/delete`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SettlementObjectService
     * @name SettlementObjectServiceUpdate
     * @summary Update
     * @request POST:/web/objects/settlement-object/{id}/update
     */
    settlementObjectServiceUpdate: (
      id: string,
      body: SettlementObjectServiceUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<SettlementObjectServiceUpdateData, SettlementObjectServiceUpdateError>({
        path: `/web/objects/settlement-object/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceCloseTenancies
     * @request POST:/web/objects/tenancy/close
     */
    tenancyServiceCloseTenancies: (body: TenancyCloseRequest, params: RequestParams = {}) =>
      this.request<TenancyServiceCloseTenanciesData, TenancyServiceCloseTenanciesError>({
        path: `/web/objects/tenancy/close`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceCreate
     * @summary Create
     * @request POST:/web/objects/tenancy/create
     */
    tenancyServiceCreate: (body: ObjecttenancyCreateRequest, params: RequestParams = {}) =>
      this.request<TenancyServiceCreateData, TenancyServiceCreateError>({
        path: `/web/objects/tenancy/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceDeleteRentAdjustments
     * @request POST:/web/objects/tenancy/deleteRentAdjustments
     */
    tenancyServiceDeleteRentAdjustments: (
      body: ObjecttenancyDeleteRentAdjustmentsRequest,
      params: RequestParams = {},
    ) =>
      this.request<TenancyServiceDeleteRentAdjustmentsData, TenancyServiceDeleteRentAdjustmentsError>({
        path: `/web/objects/tenancy/deleteRentAdjustments`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceGet
     * @summary Read
     * @request GET:/web/objects/tenancy/get/{id}
     */
    tenancyServiceGet: (id: string, params: RequestParams = {}) =>
      this.request<TenancyServiceGetData, TenancyServiceGetError>({
        path: `/web/objects/tenancy/get/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceHasActivatedTenancy
     * @request POST:/web/objects/tenancy/hasActivatedTenancy
     */
    tenancyServiceHasActivatedTenancy: (body: TenancyHasActivatedTenancyRequest, params: RequestParams = {}) =>
      this.request<TenancyServiceHasActivatedTenancyData, TenancyServiceHasActivatedTenancyError>({
        path: `/web/objects/tenancy/hasActivatedTenancy`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceList
     * @request POST:/web/objects/tenancy/list
     */
    tenancyServiceList: (body: ObjecttenancyListRequest, params: RequestParams = {}) =>
      this.request<TenancyServiceListData, TenancyServiceListError>({
        path: `/web/objects/tenancy/list`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceListByBillingContactId
     * @request GET:/web/objects/tenancy/listByBillingContactId/{billingContactId}
     */
    tenancyServiceListByBillingContactId: (billingContactId: string, params: RequestParams = {}) =>
      this.request<TenancyServiceListByBillingContactIdData, TenancyServiceListByBillingContactIdError>({
        path: `/web/objects/tenancy/listByBillingContactId/${billingContactId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceListByContactId
     * @request GET:/web/objects/tenancy/listByContactId/{contactId}
     */
    tenancyServiceListByContactId: (
      { contactId, ...query }: TenancyServiceListByContactIdParams,
      params: RequestParams = {},
    ) =>
      this.request<TenancyServiceListByContactIdData, TenancyServiceListByContactIdError>({
        path: `/web/objects/tenancy/listByContactId/${contactId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceListByDateAndObjectIds
     * @request POST:/web/objects/tenancy/listByDateAndObjectIds
     */
    tenancyServiceListByDateAndObjectIds: (body: TenancyListByDateAndObjectIdsRequest, params: RequestParams = {}) =>
      this.request<TenancyServiceListByDateAndObjectIdsData, TenancyServiceListByDateAndObjectIdsError>({
        path: `/web/objects/tenancy/listByDateAndObjectIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceListByGuarantor
     * @request GET:/web/objects/tenancy/listByGuarantor/{guarantorContactId}
     */
    tenancyServiceListByGuarantor: (guarantorContactId: string, params: RequestParams = {}) =>
      this.request<TenancyServiceListByGuarantorData, TenancyServiceListByGuarantorError>({
        path: `/web/objects/tenancy/listByGuarantor/${guarantorContactId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceListByIds
     * @request POST:/web/objects/tenancy/listByIds
     */
    tenancyServiceListByIds: (body: ObjecttenancyListByIdsRequest, params: RequestParams = {}) =>
      this.request<TenancyServiceListByIdsData, TenancyServiceListByIdsError>({
        path: `/web/objects/tenancy/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceListByOwner
     * @request GET:/web/objects/tenancy/listByOwner/{ownerId}
     */
    tenancyServiceListByOwner: ({ ownerId, ...query }: TenancyServiceListByOwnerParams, params: RequestParams = {}) =>
      this.request<TenancyServiceListByOwnerData, TenancyServiceListByOwnerError>({
        path: `/web/objects/tenancy/listByOwner/${ownerId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceListByTenant
     * @request GET:/web/objects/tenancy/listByTenant/{ownerId}/tenant/{tenantContactId}
     */
    tenancyServiceListByTenant: (ownerId: string, tenantContactId: string, params: RequestParams = {}) =>
      this.request<TenancyServiceListByTenantData, TenancyServiceListByTenantError>({
        path: `/web/objects/tenancy/listByTenant/${ownerId}/tenant/${tenantContactId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceListCurrentByObjectIds
     * @request POST:/web/objects/tenancy/listCurrentByObjectIds
     */
    tenancyServiceListCurrentByObjectIds: (
      body: ObjecttenancyListCurrentByObjectIdsRequest,
      params: RequestParams = {},
    ) =>
      this.request<TenancyServiceListCurrentByObjectIdsData, TenancyServiceListCurrentByObjectIdsError>({
        path: `/web/objects/tenancy/listCurrentByObjectIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServicePublishUpdatedEvents
     * @request POST:/web/objects/tenancy/publishUpdatedEvents
     */
    tenancyServicePublishUpdatedEvents: (body: ObjecttenancyPublishUpdatedEventsRequest, params: RequestParams = {}) =>
      this.request<TenancyServicePublishUpdatedEventsData, TenancyServicePublishUpdatedEventsError>({
        path: `/web/objects/tenancy/publishUpdatedEvents`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceUpdateBulk
     * @request POST:/web/objects/tenancy/updateBulk
     */
    tenancyServiceUpdateBulk: (body: ObjecttenancyUpdateBulkRequest, params: RequestParams = {}) =>
      this.request<TenancyServiceUpdateBulkData, TenancyServiceUpdateBulkError>({
        path: `/web/objects/tenancy/updateBulk`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceDelete
     * @summary Delete
     * @request DELETE:/web/objects/tenancy/{id}
     */
    tenancyServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<TenancyServiceDeleteData, TenancyServiceDeleteError>({
        path: `/web/objects/tenancy/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceActivate
     * @request POST:/web/objects/tenancy/{id}/activate
     */
    tenancyServiceActivate: (
      id: string,
      body: {
        documentIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<TenancyServiceActivateData, TenancyServiceActivateError>({
        path: `/web/objects/tenancy/${id}/activate`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceAddDocuments
     * @summary Document
     * @request POST:/web/objects/tenancy/{id}/addDocument
     */
    tenancyServiceAddDocuments: (
      id: string,
      body: {
        documentIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<TenancyServiceAddDocumentsData, TenancyServiceAddDocumentsError>({
        path: `/web/objects/tenancy/${id}/addDocument`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceDeactivate
     * @request POST:/web/objects/tenancy/{id}/deactivate
     */
    tenancyServiceDeactivate: (id: string, body: object, params: RequestParams = {}) =>
      this.request<TenancyServiceDeactivateData, TenancyServiceDeactivateError>({
        path: `/web/objects/tenancy/${id}/deactivate`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceSetTermination
     * @request POST:/web/objects/tenancy/{id}/setTermination
     */
    tenancyServiceSetTermination: (id: string, body: TenancyServiceSetTerminationPayload, params: RequestParams = {}) =>
      this.request<TenancyServiceSetTerminationData, TenancyServiceSetTerminationError>({
        path: `/web/objects/tenancy/${id}/setTermination`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceUpdate
     * @summary Update
     * @request POST:/web/objects/tenancy/{id}/update
     */
    tenancyServiceUpdate: (id: string, body: TenancyServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<TenancyServiceUpdateData, TenancyServiceUpdateError>({
        path: `/web/objects/tenancy/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenancyService
     * @name TenancyServiceDeactivateByNumericId
     * @request POST:/web/objects/tenancy/{numericId}/deactivateByNumericId
     */
    tenancyServiceDeactivateByNumericId: (
      numericId: string,
      body: {
        /** @format int64 */
        ownerId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TenancyServiceDeactivateByNumericIdData, TenancyServiceDeactivateByNumericIdError>({
        path: `/web/objects/tenancy/${numericId}/deactivateByNumericId`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceUnPublish
     * @request POST:/web/objects/unpublish
     */
    objectServiceUnPublish: (body: ObjectUnPublishRequest, params: RequestParams = {}) =>
      this.request<ObjectServiceUnPublishData, ObjectServiceUnPublishError>({
        path: `/web/objects/unpublish`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceUpdateMarketingInfo
     * @request POST:/web/objects/updateMarketingInfo/{id}
     */
    objectServiceUpdateMarketingInfo: (
      id: string,
      body: ObjectServiceUpdateMarketingInfoPayload,
      params: RequestParams = {},
    ) =>
      this.request<ObjectServiceUpdateMarketingInfoData, ObjectServiceUpdateMarketingInfoError>({
        path: `/web/objects/updateMarketingInfo/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceDelete
     * @summary Delete
     * @request DELETE:/web/objects/{id}
     */
    objectServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<ObjectServiceDeleteData, ObjectServiceDeleteError>({
        path: `/web/objects/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetApplicationInfo
     * @request GET:/web/objects/{id}/getApplicationInfo
     */
    objectServiceGetApplicationInfo: (
      { id, ...query }: ObjectServiceGetApplicationInfoParams,
      params: RequestParams = {},
    ) =>
      this.request<ObjectServiceGetApplicationInfoData, ObjectServiceGetApplicationInfoError>({
        path: `/web/objects/${id}/getApplicationInfo`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetApplicationLink
     * @request GET:/web/objects/{id}/getApplicationLink
     */
    objectServiceGetApplicationLink: (id: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetApplicationLinkData, ObjectServiceGetApplicationLinkError>({
        path: `/web/objects/${id}/getApplicationLink`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetDefaultAnswer
     * @request GET:/web/objects/{id}/getDefaultAnswer
     */
    objectServiceGetDefaultAnswer: (id: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetDefaultAnswerData, ObjectServiceGetDefaultAnswerError>({
        path: `/web/objects/${id}/getDefaultAnswer`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetMarketingExpose
     * @request GET:/web/objects/{id}/getMarketingExpose
     */
    objectServiceGetMarketingExpose: (
      { id, ...query }: ObjectServiceGetMarketingExposeParams,
      params: RequestParams = {},
    ) =>
      this.request<ObjectServiceGetMarketingExposeData, ObjectServiceGetMarketingExposeError>({
        path: `/web/objects/${id}/getMarketingExpose`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceGetViewingAppointment
     * @request GET:/web/objects/{id}/getViewingAppointment
     */
    objectServiceGetViewingAppointment: (id: string, params: RequestParams = {}) =>
      this.request<ObjectServiceGetViewingAppointmentData, ObjectServiceGetViewingAppointmentError>({
        path: `/web/objects/${id}/getViewingAppointment`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceSetDefaultAnswer
     * @request POST:/web/objects/{id}/setDefaultAnswer
     */
    objectServiceSetDefaultAnswer: (
      id: string,
      body: ObjectServiceSetDefaultAnswerPayload,
      params: RequestParams = {},
    ) =>
      this.request<ObjectServiceSetDefaultAnswerData, ObjectServiceSetDefaultAnswerError>({
        path: `/web/objects/${id}/setDefaultAnswer`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceSetViewingAppointment
     * @request POST:/web/objects/{id}/setViewingAppointment
     */
    objectServiceSetViewingAppointment: (
      id: string,
      body: ObjectServiceSetViewingAppointmentPayload,
      params: RequestParams = {},
    ) =>
      this.request<ObjectServiceSetViewingAppointmentData, ObjectServiceSetViewingAppointmentError>({
        path: `/web/objects/${id}/setViewingAppointment`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceUpdate
     * @summary Update
     * @request POST:/web/objects/{id}/update
     */
    objectServiceUpdate: (id: string, body: ObjectServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<ObjectServiceUpdateData, ObjectServiceUpdateError>({
        path: `/web/objects/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObjectService
     * @name ObjectServiceUpdatePricing
     * @request POST:/web/objects/{id}/updatePricing
     */
    objectServiceUpdatePricing: (id: string, body: ObjectServiceUpdatePricingPayload, params: RequestParams = {}) =>
      this.request<ObjectServiceUpdatePricingData, ObjectServiceUpdatePricingError>({
        path: `/web/objects/${id}/updatePricing`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceCreateTemplateMixin2
     * @summary Create
     * @request POST:/web/v2/objects/contract/template/create
     */
    contractServiceCreateTemplateMixin2: (body: Contractv2CreateTemplateRequest, params: RequestParams = {}) =>
      this.request<ContractServiceCreateTemplateMixin2Data, ContractServiceCreateTemplateMixin2Error>({
        path: `/web/v2/objects/contract/template/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceDeleteTemplateMixin2
     * @summary Delete
     * @request DELETE:/web/v2/objects/contract/template/delete/{ids}
     */
    contractServiceDeleteTemplateMixin2: (ids: string[], params: RequestParams = {}) =>
      this.request<ContractServiceDeleteTemplateMixin2Data, ContractServiceDeleteTemplateMixin2Error>({
        path: `/web/v2/objects/contract/template/delete/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceDeleteRenderFile
     * @request DELETE:/web/v2/objects/contract/template/deleteRenderFile/{renderId}
     */
    contractServiceDeleteRenderFile: (renderId: string, params: RequestParams = {}) =>
      this.request<ContractServiceDeleteRenderFileData, ContractServiceDeleteRenderFileError>({
        path: `/web/v2/objects/contract/template/deleteRenderFile/${renderId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceDeleteRenderFiles
     * @request POST:/web/v2/objects/contract/template/deleteRenderFiles
     */
    contractServiceDeleteRenderFiles: (body: V2DeleteRenderFilesRequest, params: RequestParams = {}) =>
      this.request<ContractServiceDeleteRenderFilesData, ContractServiceDeleteRenderFilesError>({
        path: `/web/v2/objects/contract/template/deleteRenderFiles`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceGetRenderFile
     * @request GET:/web/v2/objects/contract/template/getRenderFile/{renderId}
     */
    contractServiceGetRenderFile: (renderId: string, params: RequestParams = {}) =>
      this.request<ContractServiceGetRenderFileData, ContractServiceGetRenderFileError>({
        path: `/web/v2/objects/contract/template/getRenderFile/${renderId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceGetRenderStatus
     * @summary Async render process
     * @request GET:/web/v2/objects/contract/template/getRenderStatus/{renderId}
     */
    contractServiceGetRenderStatus: (renderId: string, params: RequestParams = {}) =>
      this.request<ContractServiceGetRenderStatusData, ContractServiceGetRenderStatusError>({
        path: `/web/v2/objects/contract/template/getRenderStatus/${renderId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceListTemplatesMixin2
     * @summary Read
     * @request GET:/web/v2/objects/contract/template/list/{ownerIds}
     */
    contractServiceListTemplatesMixin2: (ownerIds: string[], params: RequestParams = {}) =>
      this.request<ContractServiceListTemplatesMixin2Data, ContractServiceListTemplatesMixin2Error>({
        path: `/web/v2/objects/contract/template/list/${ownerIds}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceListRenderStatus
     * @request POST:/web/v2/objects/contract/template/listRenderStatus
     */
    contractServiceListRenderStatus: (body: V2ListRenderStatusRequest, params: RequestParams = {}) =>
      this.request<ContractServiceListRenderStatusData, ContractServiceListRenderStatusError>({
        path: `/web/v2/objects/contract/template/listRenderStatus`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderDeviceTemplateMixin2
     * @request POST:/web/v2/objects/contract/template/renderDevice
     */
    contractServiceRenderDeviceTemplateMixin2: (
      body: Contractv2RenderDeviceTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceRenderDeviceTemplateMixin2Data, ContractServiceRenderDeviceTemplateMixin2Error>({
        path: `/web/v2/objects/contract/template/renderDevice`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderTenanciesTemplateMixin2
     * @request POST:/web/v2/objects/contract/template/renderTenancies
     */
    contractServiceRenderTenanciesTemplateMixin2: (
      body: Contractv2RenderTenanciesTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceRenderTenanciesTemplateMixin2Data, ContractServiceRenderTenanciesTemplateMixin2Error>(
        {
          path: `/web/v2/objects/contract/template/renderTenancies`,
          method: "POST",
          body: body,
          type: ContentType.Json,
          ...params,
        },
      ),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderTenancyTemplate
     * @summary Render
     * @request POST:/web/v2/objects/contract/template/renderTenancy
     */
    contractServiceRenderTenancyTemplate: (body: V2RenderTenancyTemplateRequest, params: RequestParams = {}) =>
      this.request<ContractServiceRenderTenancyTemplateData, ContractServiceRenderTenancyTemplateError>({
        path: `/web/v2/objects/contract/template/renderTenancy`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceUpdateTemplateMixin2
     * @summary Update
     * @request POST:/web/v2/objects/contract/template/update/{id}
     */
    contractServiceUpdateTemplateMixin2: (
      id: string,
      body: ContractServiceUpdateTemplateMixin2Payload,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceUpdateTemplateMixin2Data, ContractServiceUpdateTemplateMixin2Error>({
        path: `/web/v2/objects/contract/template/update/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceGetRenderFiles
     * @request POST:/web/v2/objects/contracts/template/getRenderFiles
     */
    contractServiceGetRenderFiles: (body: V2GetRenderFilesRequest, params: RequestParams = {}) =>
      this.request<ContractServiceGetRenderFilesData, ContractServiceGetRenderFilesError>({
        path: `/web/v2/objects/contracts/template/getRenderFiles`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderCondominiumTemplateMixin2
     * @request POST:/web/v2/objects/contracts/template/renderCondominium
     */
    contractServiceRenderCondominiumTemplateMixin2: (
      body: Contractv2RenderCondominiumTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        ContractServiceRenderCondominiumTemplateMixin2Data,
        ContractServiceRenderCondominiumTemplateMixin2Error
      >({
        path: `/web/v2/objects/contracts/template/renderCondominium`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderCondominiumsTemplateMixin2
     * @request POST:/web/v2/objects/contracts/template/renderCondominiums
     */
    contractServiceRenderCondominiumsTemplateMixin2: (
      body: Contractv2RenderCondominiumsTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        ContractServiceRenderCondominiumsTemplateMixin2Data,
        ContractServiceRenderCondominiumsTemplateMixin2Error
      >({
        path: `/web/v2/objects/contracts/template/renderCondominiums`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderContactTemplateMixin2
     * @request POST:/web/v2/objects/contracts/template/renderContact
     */
    contractServiceRenderContactTemplateMixin2: (
      body: Contractv2RenderContactTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceRenderContactTemplateMixin2Data, ContractServiceRenderContactTemplateMixin2Error>({
        path: `/web/v2/objects/contracts/template/renderContact`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractService
     * @name ContractServiceRenderContactsTemplateMixin2
     * @request POST:/web/v2/objects/contracts/template/renderContacts
     */
    contractServiceRenderContactsTemplateMixin2: (
      body: Contractv2RenderContactsTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ContractServiceRenderContactsTemplateMixin2Data, ContractServiceRenderContactsTemplateMixin2Error>({
        path: `/web/v2/objects/contracts/template/renderContacts`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceCountMixin21
     * @request GET:/web/v2/objects/property/count/{accountId}
     */
    propertyServiceCountMixin21: (
      { accountId, ...query }: PropertyServiceCountMixin21Params,
      params: RequestParams = {},
    ) =>
      this.request<PropertyServiceCountMixin21Data, PropertyServiceCountMixin21Error>({
        path: `/web/v2/objects/property/count/${accountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceCreateMixin21
     * @summary Create
     * @request POST:/web/v2/objects/property/create
     */
    propertyServiceCreateMixin21: (body: Objectpropertyv2CreateRequest, params: RequestParams = {}) =>
      this.request<PropertyServiceCreateMixin21Data, PropertyServiceCreateMixin21Error>({
        path: `/web/v2/objects/property/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceGetAllowedPropertyTypes
     * @request GET:/web/v2/objects/property/getAllowedPropertyTypes/{accountId}
     */
    propertyServiceGetAllowedPropertyTypes: (accountId: string, params: RequestParams = {}) =>
      this.request<PropertyServiceGetAllowedPropertyTypesData, PropertyServiceGetAllowedPropertyTypesError>({
        path: `/web/v2/objects/property/getAllowedPropertyTypes/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceListMixin21
     * @summary Read
     * @request GET:/web/v2/objects/property/list/{ownerId}
     */
    propertyServiceListMixin21: ({ ownerId, ...query }: PropertyServiceListMixin21Params, params: RequestParams = {}) =>
      this.request<PropertyServiceListMixin21Data, PropertyServiceListMixin21Error>({
        path: `/web/v2/objects/property/list/${ownerId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceListByBuildingIdsMixin21
     * @request GET:/web/v2/objects/property/listByBuildingIds/{buildingIds}
     */
    propertyServiceListByBuildingIdsMixin21: (buildingIds: string[], params: RequestParams = {}) =>
      this.request<PropertyServiceListByBuildingIdsMixin21Data, PropertyServiceListByBuildingIdsMixin21Error>({
        path: `/web/v2/objects/property/listByBuildingIds/${buildingIds}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceListByIdsMixin21
     * @request GET:/web/v2/objects/property/listByIds/{ids}
     */
    propertyServiceListByIdsMixin21: (ids: string[], params: RequestParams = {}) =>
      this.request<PropertyServiceListByIdsMixin21Data, PropertyServiceListByIdsMixin21Error>({
        path: `/web/v2/objects/property/listByIds/${ids}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceListFilterOptions
     * @request GET:/web/v2/objects/property/listFilterOptions
     */
    propertyServiceListFilterOptions: (params: RequestParams = {}) =>
      this.request<PropertyServiceListFilterOptionsData, PropertyServiceListFilterOptionsError>({
        path: `/web/v2/objects/property/listFilterOptions`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceListIds
     * @request GET:/web/v2/objects/property/listIds/{ownerId}
     */
    propertyServiceListIds: (ownerId: string, params: RequestParams = {}) =>
      this.request<PropertyServiceListIdsData, PropertyServiceListIdsError>({
        path: `/web/v2/objects/property/listIds/${ownerId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceSell
     * @request POST:/web/v2/objects/property/sell
     */
    propertyServiceSell: (body: V2SellRequest, params: RequestParams = {}) =>
      this.request<PropertyServiceSellData, PropertyServiceSellError>({
        path: `/web/v2/objects/property/sell`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceTransferToAccountingMixin21
     * @request POST:/web/v2/objects/property/transferToAccounting
     */
    propertyServiceTransferToAccountingMixin21: (
      body: Propertyv2TransferToAccountingRequest,
      params: RequestParams = {},
    ) =>
      this.request<PropertyServiceTransferToAccountingMixin21Data, PropertyServiceTransferToAccountingMixin21Error>({
        path: `/web/v2/objects/property/transferToAccounting`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceDeleteMixin21
     * @summary Delete
     * @request DELETE:/web/v2/objects/property/{ids}
     */
    propertyServiceDeleteMixin21: (ids: string[], params: RequestParams = {}) =>
      this.request<PropertyServiceDeleteMixin21Data, PropertyServiceDeleteMixin21Error>({
        path: `/web/v2/objects/property/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceAddBuilding
     * @request POST:/web/v2/objects/property/{id}/addBuildingId
     */
    propertyServiceAddBuilding: (id: string, body: PropertyServiceAddBuildingPayload, params: RequestParams = {}) =>
      this.request<PropertyServiceAddBuildingData, PropertyServiceAddBuildingError>({
        path: `/web/v2/objects/property/${id}/addBuildingId`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyService
     * @name PropertyServiceUpdateMixin21
     * @summary Update
     * @request POST:/web/v2/objects/property/{id}/update
     */
    propertyServiceUpdateMixin21: (id: string, body: PropertyServiceUpdateMixin21Payload, params: RequestParams = {}) =>
      this.request<PropertyServiceUpdateMixin21Data, PropertyServiceUpdateMixin21Error>({
        path: `/web/v2/objects/property/${id}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingService
     * @name ReportingServiceDeleteJdbcConnection
     * @request DELETE:/web/reporting/deleteJdbcConnection/{accountId}
     */
    reportingServiceDeleteJdbcConnection: (accountId: string, params: RequestParams = {}) =>
      this.request<ReportingServiceDeleteJdbcConnectionData, ReportingServiceDeleteJdbcConnectionError>({
        path: `/web/reporting/deleteJdbcConnection/${accountId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingService
     * @name ReportingServiceGetBuildingRentReports2
     * @request POST:/web/reporting/getBuildingRentReports
     */
    reportingServiceGetBuildingRentReports2: (
      body: ReportingGetBuildingRentReportsRequest,
      params: RequestParams = {},
    ) =>
      this.request<ReportingServiceGetBuildingRentReports2Data, ReportingServiceGetBuildingRentReports2Error>({
        path: `/web/reporting/getBuildingRentReports`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingService
     * @name ReportingServiceGetBuildingRentReports
     * @request GET:/web/reporting/getBuildingRentReports/{buildingIds}
     */
    reportingServiceGetBuildingRentReports: (buildingIds: string[], params: RequestParams = {}) =>
      this.request<ReportingServiceGetBuildingRentReportsData, ReportingServiceGetBuildingRentReportsError>({
        path: `/web/reporting/getBuildingRentReports/${buildingIds}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingService
     * @name ReportingServiceGetJdbcConnections
     * @request GET:/web/reporting/getJdbcConnections
     */
    reportingServiceGetJdbcConnections: (params: RequestParams = {}) =>
      this.request<ReportingServiceGetJdbcConnectionsData, ReportingServiceGetJdbcConnectionsError>({
        path: `/web/reporting/getJdbcConnections`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingService
     * @name ReportingServiceGetPropertyRentReports
     * @request GET:/web/reporting/getPropertyRentReports/{propertyIds}
     */
    reportingServiceGetPropertyRentReports: (propertyIds: string[], params: RequestParams = {}) =>
      this.request<ReportingServiceGetPropertyRentReportsData, ReportingServiceGetPropertyRentReportsError>({
        path: `/web/reporting/getPropertyRentReports/${propertyIds}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingService
     * @name ReportingServiceGetReportingUrls
     * @request GET:/web/reporting/getReportingUrls/{accountId}
     */
    reportingServiceGetReportingUrls: (accountId: string, params: RequestParams = {}) =>
      this.request<ReportingServiceGetReportingUrlsData, ReportingServiceGetReportingUrlsError>({
        path: `/web/reporting/getReportingUrls/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingService
     * @name ReportingServiceHasJdbcConnection
     * @request GET:/web/reporting/hasJdbcConnection/{accountId}
     */
    reportingServiceHasJdbcConnection: (accountId: string, params: RequestParams = {}) =>
      this.request<ReportingServiceHasJdbcConnectionData, ReportingServiceHasJdbcConnectionError>({
        path: `/web/reporting/hasJdbcConnection/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingService
     * @name ReportingServiceSetJdbcConnection
     * @request POST:/web/reporting/setJdbcConnection/{accountId}
     */
    reportingServiceSetJdbcConnection: (
      accountId: string,
      body: ReportingServiceSetJdbcConnectionPayload,
      params: RequestParams = {},
    ) =>
      this.request<ReportingServiceSetJdbcConnectionData, ReportingServiceSetJdbcConnectionError>({
        path: `/web/reporting/setJdbcConnection/${accountId}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingService
     * @name ReportingServiceSetReportingUrls
     * @request POST:/web/reporting/setReportingUrls/{accountId}
     */
    reportingServiceSetReportingUrls: (
      accountId: string,
      body: ReportingServiceSetReportingUrlsPayload,
      params: RequestParams = {},
    ) =>
      this.request<ReportingServiceSetReportingUrlsData, ReportingServiceSetReportingUrlsError>({
        path: `/web/reporting/setReportingUrls/${accountId}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApplicationService
     * @name ApplicationServiceCreate
     * @summary Create
     * @request POST:/web/v2/request/application/create
     */
    applicationServiceCreate: (body: Requestapplicationv2CreateRequest, params: RequestParams = {}) =>
      this.request<ApplicationServiceCreateData, ApplicationServiceCreateError>({
        path: `/web/v2/request/application/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApplicationService
     * @name ApplicationServiceDelete
     * @summary Delete
     * @request DELETE:/web/v2/request/application/delete/{ids}
     */
    applicationServiceDelete: ({ ids, ...query }: ApplicationServiceDeleteParams, params: RequestParams = {}) =>
      this.request<ApplicationServiceDeleteData, ApplicationServiceDeleteError>({
        path: `/web/v2/request/application/delete/${ids}`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApplicationService
     * @name ApplicationServiceGetById
     * @request GET:/web/v2/request/application/getById/{id}
     */
    applicationServiceGetById: (id: string, params: RequestParams = {}) =>
      this.request<ApplicationServiceGetByIdData, ApplicationServiceGetByIdError>({
        path: `/web/v2/request/application/getById/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApplicationService
     * @name ApplicationServiceGetLatest
     * @summary Read
     * @request GET:/web/v2/request/application/getLatest/{creatorEmail}
     */
    applicationServiceGetLatest: (creatorEmail: string, params: RequestParams = {}) =>
      this.request<ApplicationServiceGetLatestData, ApplicationServiceGetLatestError>({
        path: `/web/v2/request/application/getLatest/${creatorEmail}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApplicationService
     * @name ApplicationServiceSend
     * @request POST:/web/v2/request/application/send
     */
    applicationServiceSend: (body: V2SendRequest, params: RequestParams = {}) =>
      this.request<ApplicationServiceSendData, ApplicationServiceSendError>({
        path: `/web/v2/request/application/send`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApplicationService
     * @name ApplicationServiceGetSettings
     * @request GET:/web/v2/request/application/settings/{accountId}
     */
    applicationServiceGetSettings: (accountId: string, params: RequestParams = {}) =>
      this.request<ApplicationServiceGetSettingsData, ApplicationServiceGetSettingsError>({
        path: `/web/v2/request/application/settings/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApplicationService
     * @name ApplicationServiceSetSettings
     * @summary Settings
     * @request POST:/web/v2/request/application/settings/{accountId}
     */
    applicationServiceSetSettings: (
      accountId: string,
      body: ApplicationServiceSetSettingsPayload,
      params: RequestParams = {},
    ) =>
      this.request<ApplicationServiceSetSettingsData, ApplicationServiceSetSettingsError>({
        path: `/web/v2/request/application/settings/${accountId}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceCreate
     * @summary Create
     * @request POST:/web/v2/request/create
     */
    requestServiceCreate: (body: Requestrequestv2CreateRequest, params: RequestParams = {}) =>
      this.request<RequestServiceCreateData, RequestServiceCreateError>({
        path: `/web/v2/request/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceGetMarketingStatistics
     * @request GET:/web/v2/request/getMarketingStatistics/{accountId}
     */
    requestServiceGetMarketingStatistics: (
      { accountId, ...query }: RequestServiceGetMarketingStatisticsParams,
      params: RequestParams = {},
    ) =>
      this.request<RequestServiceGetMarketingStatisticsData, RequestServiceGetMarketingStatisticsError>({
        path: `/web/v2/request/getMarketingStatistics/${accountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceGetStatistics
     * @request GET:/web/v2/request/getStatistics/{objectIds}
     */
    requestServiceGetStatistics: (objectIds: string[], params: RequestParams = {}) =>
      this.request<RequestServiceGetStatisticsData, RequestServiceGetStatisticsError>({
        path: `/web/v2/request/getStatistics/${objectIds}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceListByIds
     * @summary Read
     * @request GET:/web/v2/request/listByIds/{ids}
     */
    requestServiceListByIds: (ids: string[], params: RequestParams = {}) =>
      this.request<RequestServiceListByIdsData, RequestServiceListByIdsError>({
        path: `/web/v2/request/listByIds/${ids}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceListByRequesterContactId
     * @request GET:/web/v2/request/listByRequesterContactId/{contactId}
     */
    requestServiceListByRequesterContactId: (contactId: string, params: RequestParams = {}) =>
      this.request<RequestServiceListByRequesterContactIdData, RequestServiceListByRequesterContactIdError>({
        path: `/web/v2/request/listByRequesterContactId/${contactId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceListReceivedByObjectIds
     * @request GET:/web/v2/request/listReceivedByObjectIds/{objectIds}
     */
    requestServiceListReceivedByObjectIds: (
      { objectIds, ...query }: RequestServiceListReceivedByObjectIdsParams,
      params: RequestParams = {},
    ) =>
      this.request<RequestServiceListReceivedByObjectIdsData, RequestServiceListReceivedByObjectIdsError>({
        path: `/web/v2/request/listReceivedByObjectIds/${objectIds}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceListSent
     * @request GET:/web/v2/request/listSent/{accountId}
     */
    requestServiceListSent: (accountId: string, params: RequestParams = {}) =>
      this.request<RequestServiceListSentData, RequestServiceListSentError>({
        path: `/web/v2/request/listSent/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceMarkRead
     * @summary Update
     * @request POST:/web/v2/request/markRead
     */
    requestServiceMarkRead: (body: Requestrequestv2MarkReadRequest, params: RequestParams = {}) =>
      this.request<RequestServiceMarkReadData, RequestServiceMarkReadError>({
        path: `/web/v2/request/markRead`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceMarkRejected
     * @request POST:/web/v2/request/markRejected
     */
    requestServiceMarkRejected: (body: V2MarkRejectedRequest, params: RequestParams = {}) =>
      this.request<RequestServiceMarkRejectedData, RequestServiceMarkRejectedError>({
        path: `/web/v2/request/markRejected`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceMarkUnread
     * @request POST:/web/v2/request/markUnread
     */
    requestServiceMarkUnread: (body: Requestrequestv2MarkUnreadRequest, params: RequestParams = {}) =>
      this.request<RequestServiceMarkUnreadData, RequestServiceMarkUnreadError>({
        path: `/web/v2/request/markUnread`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServicePartiallySetChecklist
     * @request POST:/web/v2/request/partiallySetChecklist
     */
    requestServicePartiallySetChecklist: (body: V2PartiallySetChecklistRequest, params: RequestParams = {}) =>
      this.request<RequestServicePartiallySetChecklistData, RequestServicePartiallySetChecklistError>({
        path: `/web/v2/request/partiallySetChecklist`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceReactivate
     * @request POST:/web/v2/request/reactivate
     */
    requestServiceReactivate: (body: V2ReactivateRequest, params: RequestParams = {}) =>
      this.request<RequestServiceReactivateData, RequestServiceReactivateError>({
        path: `/web/v2/request/reactivate`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceSetChecklist
     * @request POST:/web/v2/request/setChecklist
     */
    requestServiceSetChecklist: (body: V2SetChecklistRequest, params: RequestParams = {}) =>
      this.request<RequestServiceSetChecklistData, RequestServiceSetChecklistError>({
        path: `/web/v2/request/setChecklist`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceSetFavorite
     * @request POST:/web/v2/request/setFavorite
     */
    requestServiceSetFavorite: (body: V2SetFavoriteRequest, params: RequestParams = {}) =>
      this.request<RequestServiceSetFavoriteData, RequestServiceSetFavoriteError>({
        path: `/web/v2/request/setFavorite`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceSetNotFavorite
     * @request POST:/web/v2/request/setNotFavorite
     */
    requestServiceSetNotFavorite: (body: V2SetNotFavoriteRequest, params: RequestParams = {}) =>
      this.request<RequestServiceSetNotFavoriteData, RequestServiceSetNotFavoriteError>({
        path: `/web/v2/request/setNotFavorite`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceDelete
     * @summary Delete
     * @request DELETE:/web/v2/request/{ids}
     */
    requestServiceDelete: (ids: string[], params: RequestParams = {}) =>
      this.request<RequestServiceDeleteData, RequestServiceDeleteError>({
        path: `/web/v2/request/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceAddRequesters
     * @request POST:/web/v2/request/{id}/addRequesters
     */
    requestServiceAddRequesters: (id: string, body: RequestServiceAddRequestersPayload, params: RequestParams = {}) =>
      this.request<RequestServiceAddRequestersData, RequestServiceAddRequestersError>({
        path: `/web/v2/request/${id}/addRequesters`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceMove
     * @request POST:/web/v2/request/{id}/move
     */
    requestServiceMove: (id: string, body: RequestServiceMovePayload, params: RequestParams = {}) =>
      this.request<RequestServiceMoveData, RequestServiceMoveError>({
        path: `/web/v2/request/${id}/move`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceRemoveRequesters
     * @request POST:/web/v2/request/{id}/removeRequesters
     */
    requestServiceRemoveRequesters: (
      id: string,
      body: RequestServiceRemoveRequestersPayload,
      params: RequestParams = {},
    ) =>
      this.request<RequestServiceRemoveRequestersData, RequestServiceRemoveRequestersError>({
        path: `/web/v2/request/${id}/removeRequesters`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceReserve
     * @request POST:/web/v2/request/{id}/reserve
     */
    requestServiceReserve: (id: string, body: RequestServiceReservePayload, params: RequestParams = {}) =>
      this.request<RequestServiceReserveData, RequestServiceReserveError>({
        path: `/web/v2/request/${id}/reserve`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceSetEmployerReference
     * @request POST:/web/v2/request/{id}/setEmployerReference
     */
    requestServiceSetEmployerReference: (
      id: string,
      body: RequestServiceSetEmployerReferencePayload,
      params: RequestParams = {},
    ) =>
      this.request<RequestServiceSetEmployerReferenceData, RequestServiceSetEmployerReferenceError>({
        path: `/web/v2/request/${id}/setEmployerReference`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceSetEmployerReferenceFinalized
     * @request POST:/web/v2/request/{id}/setEmployerReferenceFinalized
     */
    requestServiceSetEmployerReferenceFinalized: (
      id: string,
      body: {
        finalized?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<RequestServiceSetEmployerReferenceFinalizedData, RequestServiceSetEmployerReferenceFinalizedError>({
        path: `/web/v2/request/${id}/setEmployerReferenceFinalized`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceSetLandlordReference
     * @request POST:/web/v2/request/{id}/setLandlordReference
     */
    requestServiceSetLandlordReference: (
      id: string,
      body: RequestServiceSetLandlordReferencePayload,
      params: RequestParams = {},
    ) =>
      this.request<RequestServiceSetLandlordReferenceData, RequestServiceSetLandlordReferenceError>({
        path: `/web/v2/request/${id}/setLandlordReference`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RequestService
     * @name RequestServiceSetLandlordReferenceFinalized
     * @request POST:/web/v2/request/{id}/setLandlordReferenceFinalized
     */
    requestServiceSetLandlordReferenceFinalized: (
      id: string,
      body: {
        finalized?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<RequestServiceSetLandlordReferenceFinalizedData, RequestServiceSetLandlordReferenceFinalizedError>({
        path: `/web/v2/request/${id}/setLandlordReferenceFinalized`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SearchService
     * @name SearchServiceGenerateApiKey
     * @request POST:/web/search/search/generateApiKey
     */
    searchServiceGenerateApiKey: (body: SearchGenerateApiKeyRequest, params: RequestParams = {}) =>
      this.request<SearchServiceGenerateApiKeyData, SearchServiceGenerateApiKeyError>({
        path: `/web/search/search/generateApiKey`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SearchService
     * @name SearchServiceGenerateUnauthorizedApiKey
     * @request POST:/web/search/search/generateUnauthorizedApiKey
     */
    searchServiceGenerateUnauthorizedApiKey: (
      body: SearchGenerateUnauthorizedApiKeyRequest,
      params: RequestParams = {},
    ) =>
      this.request<SearchServiceGenerateUnauthorizedApiKeyData, SearchServiceGenerateUnauthorizedApiKeyError>({
        path: `/web/search/search/generateUnauthorizedApiKey`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SearchService
     * @name SearchServiceUpdateOngoing
     * @request POST:/web/search/search/updateOngoing
     */
    searchServiceUpdateOngoing: (body: SearchUpdateOngoingRequest, params: RequestParams = {}) =>
      this.request<SearchServiceUpdateOngoingData, SearchServiceUpdateOngoingError>({
        path: `/web/search/search/updateOngoing`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TableService
     * @name TableServiceLoadSettings
     * @request GET:/web/search/table/loadSettings
     */
    tableServiceLoadSettings: (query: TableServiceLoadSettingsParams, params: RequestParams = {}) =>
      this.request<TableServiceLoadSettingsData, TableServiceLoadSettingsError>({
        path: `/web/search/table/loadSettings`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TableService
     * @name TableServiceSaveSettings
     * @request POST:/web/search/table/saveSettings
     */
    tableServiceSaveSettings: (body: SearchtableSaveSettingsRequest, params: RequestParams = {}) =>
      this.request<TableServiceSaveSettingsData, TableServiceSaveSettingsError>({
        path: `/web/search/table/saveSettings`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListAllowedAccountIds
     * @request POST:/web/share/listAllowedAccountIds
     */
    shareServiceListAllowedAccountIds: (body: ShareListAllowedAccountIdsRequest, params: RequestParams = {}) =>
      this.request<ShareServiceListAllowedAccountIdsData, ShareServiceListAllowedAccountIdsError>({
        path: `/web/share/listAllowedAccountIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListByAccountIds
     * @request GET:/web/share/listByAccountIds/{sourceAccountId}/target/{targetAccountIds}
     */
    shareServiceListByAccountIds: (
      { sourceAccountId, targetAccountIds, ...query }: ShareServiceListByAccountIdsParams,
      params: RequestParams = {},
    ) =>
      this.request<ShareServiceListByAccountIdsData, ShareServiceListByAccountIdsError>({
        path: `/web/share/listByAccountIds/${sourceAccountId}/target/${targetAccountIds}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListByResource
     * @request POST:/web/share/listByResource
     */
    shareServiceListByResource: (body: ShareListByResourceRequest, params: RequestParams = {}) =>
      this.request<ShareServiceListByResourceData, ShareServiceListByResourceError>({
        path: `/web/share/listByResource`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListBySubResourceIds
     * @request POST:/web/share/listBySubResourceIds
     */
    shareServiceListBySubResourceIds: (body: ShareListBySubResourceIdsRequest, params: RequestParams = {}) =>
      this.request<ShareServiceListBySubResourceIdsData, ShareServiceListBySubResourceIdsError>({
        path: `/web/share/listBySubResourceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListByTargetAccountId
     * @request GET:/web/share/listByTargetAccountId/{targetAccountId}
     */
    shareServiceListByTargetAccountId: (
      { targetAccountId, ...query }: ShareServiceListByTargetAccountIdParams,
      params: RequestParams = {},
    ) =>
      this.request<ShareServiceListByTargetAccountIdData, ShareServiceListByTargetAccountIdError>({
        path: `/web/share/listByTargetAccountId/${targetAccountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListIdsByTargetAccountId
     * @request GET:/web/share/listIdsByTargetAccountId/{targetAccountId}
     */
    shareServiceListIdsByTargetAccountId: (
      { targetAccountId, ...query }: ShareServiceListIdsByTargetAccountIdParams,
      params: RequestParams = {},
    ) =>
      this.request<ShareServiceListIdsByTargetAccountIdData, ShareServiceListIdsByTargetAccountIdError>({
        path: `/web/share/listIdsByTargetAccountId/${targetAccountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListOwners
     * @request GET:/web/share/listOwners/{targetAccountId}
     */
    shareServiceListOwners: (targetAccountId: string, params: RequestParams = {}) =>
      this.request<ShareServiceListOwnersData, ShareServiceListOwnersError>({
        path: `/web/share/listOwners/${targetAccountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListPartners
     * @request GET:/web/share/listPartners/{sourceAccountId}
     */
    shareServiceListPartners: (sourceAccountId: string, params: RequestParams = {}) =>
      this.request<ShareServiceListPartnersData, ShareServiceListPartnersError>({
        path: `/web/share/listPartners/${sourceAccountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListPartnersOfAsset
     * @request GET:/web/share/listPartnersOfAsset/{assetAccountId}
     */
    shareServiceListPartnersOfAsset: (assetAccountId: string, params: RequestParams = {}) =>
      this.request<ShareServiceListPartnersOfAssetData, ShareServiceListPartnersOfAssetError>({
        path: `/web/share/listPartnersOfAsset/${assetAccountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListSubResourcesByResourceIds
     * @request POST:/web/share/listSubResourceByResourceIds
     */
    shareServiceListSubResourcesByResourceIds: (
      body: ShareListSubResourcesByResourceIdsRequest,
      params: RequestParams = {},
    ) =>
      this.request<ShareServiceListSubResourcesByResourceIdsData, ShareServiceListSubResourcesByResourceIdsError>({
        path: `/web/share/listSubResourceByResourceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceListSubResourcesByTargetAccountId
     * @request GET:/web/share/listSubResourcesByTargetAccountId/{targetAccountId}
     */
    shareServiceListSubResourcesByTargetAccountId: (
      { targetAccountId, ...query }: ShareServiceListSubResourcesByTargetAccountIdParams,
      params: RequestParams = {},
    ) =>
      this.request<
        ShareServiceListSubResourcesByTargetAccountIdData,
        ShareServiceListSubResourcesByTargetAccountIdError
      >({
        path: `/web/share/listSubResourcesByTargetAccountId/${targetAccountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceSet
     * @request POST:/web/share/set
     */
    shareServiceSet: (body: ShareshareSetRequest, params: RequestParams = {}) =>
      this.request<ShareServiceSetData, ShareServiceSetError>({
        path: `/web/share/set`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShareService
     * @name ShareServiceUnset
     * @request POST:/web/share/unset
     */
    shareServiceUnset: (body: ShareUnsetRequest, params: RequestParams = {}) =>
      this.request<ShareServiceUnsetData, ShareServiceUnsetError>({
        path: `/web/share/unset`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceCountMyUnreadTickets
     * @request GET:/web/ticket/countMyUnreadTickets
     */
    ticketServiceCountMyUnreadTickets: (query: TicketServiceCountMyUnreadTicketsParams, params: RequestParams = {}) =>
      this.request<TicketServiceCountMyUnreadTicketsData, TicketServiceCountMyUnreadTicketsError>({
        path: `/web/ticket/countMyUnreadTickets`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceCountTickets
     * @request POST:/web/ticket/countTickets
     */
    ticketServiceCountTickets: (body: TicketCountTicketsRequest, params: RequestParams = {}) =>
      this.request<TicketServiceCountTicketsData, TicketServiceCountTicketsError>({
        path: `/web/ticket/countTickets`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceCreate
     * @summary Create
     * @request POST:/web/ticket/create
     */
    ticketServiceCreate: (body: TicketticketCreateRequest, params: RequestParams = {}) =>
      this.request<TicketServiceCreateData, TicketServiceCreateError>({
        path: `/web/ticket/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceEnableTicketing
     * @request POST:/web/ticket/enableTicketing
     */
    ticketServiceEnableTicketing: (body: TicketEnableTicketingRequest, params: RequestParams = {}) =>
      this.request<TicketServiceEnableTicketingData, TicketServiceEnableTicketingError>({
        path: `/web/ticket/enableTicketing`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceGetByKey
     * @request GET:/web/ticket/getByKey/{key}
     */
    ticketServiceGetByKey: (key: string, params: RequestParams = {}) =>
      this.request<TicketServiceGetByKeyData, TicketServiceGetByKeyError>({
        path: `/web/ticket/getByKey/${key}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceGetByPublicId
     * @summary Read
     * @request GET:/web/ticket/getByPublicId/{publicId}
     */
    ticketServiceGetByPublicId: (publicId: string, params: RequestParams = {}) =>
      this.request<TicketServiceGetByPublicIdData, TicketServiceGetByPublicIdError>({
        path: `/web/ticket/getByPublicId/${publicId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceGetNotificationMailbox
     * @request GET:/web/ticket/getNotificationMailbox/{accountId}
     */
    ticketServiceGetNotificationMailbox: (accountId: string, params: RequestParams = {}) =>
      this.request<TicketServiceGetNotificationMailboxData, TicketServiceGetNotificationMailboxError>({
        path: `/web/ticket/getNotificationMailbox/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceGetTicketStatistics
     * @summary Statistics
     * @request GET:/web/ticket/getTicketStatistics/{userId}
     */
    ticketServiceGetTicketStatistics: (userId: string, params: RequestParams = {}) =>
      this.request<TicketServiceGetTicketStatisticsData, TicketServiceGetTicketStatisticsError>({
        path: `/web/ticket/getTicketStatistics/${userId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceHasTicketingEnabled
     * @summary Misc
     * @request GET:/web/ticket/hasTicketingEnabled
     */
    ticketServiceHasTicketingEnabled: (query: TicketServiceHasTicketingEnabledParams, params: RequestParams = {}) =>
      this.request<TicketServiceHasTicketingEnabledData, TicketServiceHasTicketingEnabledError>({
        path: `/web/ticket/hasTicketingEnabled`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryService
     * @name HistoryServiceListChanges
     * @request GET:/web/ticket/history/{ticketId}
     */
    historyServiceListChanges: ({ ticketId, ...query }: HistoryServiceListChangesParams, params: RequestParams = {}) =>
      this.request<HistoryServiceListChangesData, HistoryServiceListChangesError>({
        path: `/web/ticket/history/${ticketId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceIsContactInUse
     * @request GET:/web/ticket/isContactInUse/{contactId}
     */
    ticketServiceIsContactInUse: (contactId: string, params: RequestParams = {}) =>
      this.request<TicketServiceIsContactInUseData, TicketServiceIsContactInUseError>({
        path: `/web/ticket/isContactInUse/${contactId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceListByIds
     * @request POST:/web/ticket/listByIds
     */
    ticketServiceListByIds: (body: TicketticketListByIdsRequest, params: RequestParams = {}) =>
      this.request<TicketServiceListByIdsData, TicketServiceListByIdsError>({
        path: `/web/ticket/listByIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceListByQuery
     * @request GET:/web/ticket/listByQuery
     */
    ticketServiceListByQuery: (query: TicketServiceListByQueryParams, params: RequestParams = {}) =>
      this.request<TicketServiceListByQueryData, TicketServiceListByQueryError>({
        path: `/web/ticket/listByQuery`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceListByReferenceIds
     * @request POST:/web/ticket/listByReferenceIds
     */
    ticketServiceListByReferenceIds: (body: TicketticketListByReferenceIdsRequest, params: RequestParams = {}) =>
      this.request<TicketServiceListByReferenceIdsData, TicketServiceListByReferenceIdsError>({
        path: `/web/ticket/listByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceListMyTickets
     * @request GET:/web/ticket/listMyTickets
     */
    ticketServiceListMyTickets: (query: TicketServiceListMyTicketsParams, params: RequestParams = {}) =>
      this.request<TicketServiceListMyTicketsData, TicketServiceListMyTicketsError>({
        path: `/web/ticket/listMyTickets`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceListTicketingEnabled
     * @request POST:/web/ticket/listTicketingEnabled
     */
    ticketServiceListTicketingEnabled: (body: TicketListTicketingEnabledRequest, params: RequestParams = {}) =>
      this.request<TicketServiceListTicketingEnabledData, TicketServiceListTicketingEnabledError>({
        path: `/web/ticket/listTicketingEnabled`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceMarkRead2
     * @request POST:/web/ticket/markRead
     */
    ticketServiceMarkRead2: (body: TicketticketMarkReadRequest, params: RequestParams = {}) =>
      this.request<TicketServiceMarkRead2Data, TicketServiceMarkRead2Error>({
        path: `/web/ticket/markRead`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceMarkUnread2
     * @request POST:/web/ticket/markUnread
     */
    ticketServiceMarkUnread2: (body: TicketticketMarkUnreadRequest, params: RequestParams = {}) =>
      this.request<TicketServiceMarkUnread2Data, TicketServiceMarkUnread2Error>({
        path: `/web/ticket/markUnread`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceReserveKeys
     * @request POST:/web/ticket/reserveKeys
     */
    ticketServiceReserveKeys: (body: TicketReserveKeysRequest, params: RequestParams = {}) =>
      this.request<TicketServiceReserveKeysData, TicketServiceReserveKeysError>({
        path: `/web/ticket/reserveKeys`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceSetSchedules
     * @summary Automatic tickets
     * @request POST:/web/ticket/schedules
     */
    ticketServiceSetSchedules: (body: TicketSetSchedulesRequest, params: RequestParams = {}) =>
      this.request<TicketServiceSetSchedulesData, TicketServiceSetSchedulesError>({
        path: `/web/ticket/schedules`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceListSchedules
     * @request GET:/web/ticket/schedules/{accountId}
     */
    ticketServiceListSchedules: (
      { accountId, ...query }: TicketServiceListSchedulesParams,
      params: RequestParams = {},
    ) =>
      this.request<TicketServiceListSchedulesData, TicketServiceListSchedulesError>({
        path: `/web/ticket/schedules/${accountId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceSetNotificationMailbox
     * @request POST:/web/ticket/setNotificationMailbox/{accountId}
     */
    ticketServiceSetNotificationMailbox: (
      accountId: string,
      body: TicketServiceSetNotificationMailboxPayload,
      params: RequestParams = {},
    ) =>
      this.request<TicketServiceSetNotificationMailboxData, TicketServiceSetNotificationMailboxError>({
        path: `/web/ticket/setNotificationMailbox/${accountId}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceMarkRead
     * @request POST:/web/ticket/{ids}/markRead
     */
    ticketServiceMarkRead: (ids: string[], params: RequestParams = {}) =>
      this.request<TicketServiceMarkReadData, TicketServiceMarkReadError>({
        path: `/web/ticket/${ids}/markRead`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceMarkUnread
     * @request POST:/web/ticket/{ids}/markUnread
     */
    ticketServiceMarkUnread: (ids: string[], params: RequestParams = {}) =>
      this.request<TicketServiceMarkUnreadData, TicketServiceMarkUnreadError>({
        path: `/web/ticket/${ids}/markUnread`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceAddWatcher
     * @request POST:/web/ticket/{id}/addWatcher
     */
    ticketServiceAddWatcher: (id: string, body: TicketServiceAddWatcherPayload, params: RequestParams = {}) =>
      this.request<TicketServiceAddWatcherData, TicketServiceAddWatcherError>({
        path: `/web/ticket/${id}/addWatcher`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceChangeStatus
     * @request POST:/web/ticket/{id}/changeStatus
     */
    ticketServiceChangeStatus: (id: string, body: TicketServiceChangeStatusPayload, params: RequestParams = {}) =>
      this.request<TicketServiceChangeStatusData, TicketServiceChangeStatusError>({
        path: `/web/ticket/${id}/changeStatus`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceDelete
     * @summary Delete
     * @request DELETE:/web/ticket/{id}/delete
     */
    ticketServiceDelete: ({ id, ...query }: TicketServiceDeleteParams, params: RequestParams = {}) =>
      this.request<TicketServiceDeleteData, TicketServiceDeleteError>({
        path: `/web/ticket/${id}/delete`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketService
     * @name TicketServiceUpdate
     * @summary Update
     * @request POST:/web/ticket/{update.id}/update
     */
    ticketServiceUpdate: (updateId: string, body: TicketServiceUpdatePayload, params: RequestParams = {}) =>
      this.request<TicketServiceUpdateData, TicketServiceUpdateError>({
        path: `/web/ticket/${updateId}/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortalService
     * @name PortalServiceAddLinks
     * @request POST:/web/user/portal/addLinks
     */
    portalServiceAddLinks: (body: PortalAddLinksRequest, params: RequestParams = {}) =>
      this.request<PortalServiceAddLinksData, PortalServiceAddLinksError>({
        path: `/web/user/portal/addLinks`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortalService
     * @name PortalServiceEnablePortal
     * @request POST:/web/user/portal/enablePortal
     */
    portalServiceEnablePortal: (body: PortalEnablePortalRequest, params: RequestParams = {}) =>
      this.request<PortalServiceEnablePortalData, PortalServiceEnablePortalError>({
        path: `/web/user/portal/enablePortal`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortalService
     * @name PortalServiceGetPortalData
     * @request GET:/web/user/portal/getPortalData/{email}
     */
    portalServiceGetPortalData: (email: string, params: RequestParams = {}) =>
      this.request<PortalServiceGetPortalDataData, PortalServiceGetPortalDataError>({
        path: `/web/user/portal/getPortalData/${email}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortalService
     * @name PortalServiceHasPortalEnabled
     * @request GET:/web/user/portal/hasPortalEnabled/{accountId}
     */
    portalServiceHasPortalEnabled: (accountId: string, params: RequestParams = {}) =>
      this.request<PortalServiceHasPortalEnabledData, PortalServiceHasPortalEnabledError>({
        path: `/web/user/portal/hasPortalEnabled/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortalService
     * @name PortalServiceListDocumentGroups
     * @request GET:/web/user/portal/listDocumentGroups/{email}
     */
    portalServiceListDocumentGroups: (email: string, params: RequestParams = {}) =>
      this.request<PortalServiceListDocumentGroupsData, PortalServiceListDocumentGroupsError>({
        path: `/web/user/portal/listDocumentGroups/${email}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortalService
     * @name PortalServiceListLinksByReferenceIds
     * @request POST:/web/user/portal/listLinksByReferenceIds
     */
    portalServiceListLinksByReferenceIds: (body: PortalListLinksByReferenceIdsRequest, params: RequestParams = {}) =>
      this.request<PortalServiceListLinksByReferenceIdsData, PortalServiceListLinksByReferenceIdsError>({
        path: `/web/user/portal/listLinksByReferenceIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortalService
     * @name PortalServiceRemoveLinks
     * @request DELETE:/web/user/portal/removeLinks/{ids}
     */
    portalServiceRemoveLinks: (ids: string[], params: RequestParams = {}) =>
      this.request<PortalServiceRemoveLinksData, PortalServiceRemoveLinksError>({
        path: `/web/user/portal/removeLinks/${ids}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortalService
     * @name PortalServiceUpdateLinks
     * @request POST:/web/user/portal/updateLinks
     */
    portalServiceUpdateLinks: (body: PortalUpdateLinksRequest, params: RequestParams = {}) =>
      this.request<PortalServiceUpdateLinksData, PortalServiceUpdateLinksError>({
        path: `/web/user/portal/updateLinks`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceCreate
     * @request POST:/web/v2/user/account/create
     */
    accountServiceCreate: (body: Useraccountv2CreateRequest, params: RequestParams = {}) =>
      this.request<AccountServiceCreateData, AccountServiceCreateError>({
        path: `/web/v2/user/account/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceGet
     * @request GET:/web/v2/user/account/get/{id}
     */
    accountServiceGet: (id: string, params: RequestParams = {}) =>
      this.request<AccountServiceGetData, AccountServiceGetError>({
        path: `/web/v2/user/account/get/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceList
     * @request GET:/web/v2/user/account/list/{page}
     */
    accountServiceList: ({ page, ...query }: AccountServiceListParams, params: RequestParams = {}) =>
      this.request<AccountServiceListData, AccountServiceListError>({
        path: `/web/v2/user/account/list/${page}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceListByIds
     * @request GET:/web/v2/user/account/listByIds/{ids}
     */
    accountServiceListByIds: (ids: string[], params: RequestParams = {}) =>
      this.request<AccountServiceListByIdsData, AccountServiceListByIdsError>({
        path: `/web/v2/user/account/listByIds/${ids}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceChangeBilling
     * @request POST:/web/v2/user/account/{id}/changeBilling
     */
    accountServiceChangeBilling: (id: string, body: AccountServiceChangeBillingPayload, params: RequestParams = {}) =>
      this.request<AccountServiceChangeBillingData, AccountServiceChangeBillingError>({
        path: `/web/v2/user/account/${id}/changeBilling`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceChangeSubType
     * @request POST:/web/v2/user/account/{id}/changeSubType
     */
    accountServiceChangeSubType: (id: string, body: AccountServiceChangeSubTypePayload, params: RequestParams = {}) =>
      this.request<AccountServiceChangeSubTypeData, AccountServiceChangeSubTypeError>({
        path: `/web/v2/user/account/${id}/changeSubType`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceGetFontSettings
     * @request GET:/web/v2/user/account/{id}/getFontSettings
     */
    accountServiceGetFontSettings: (id: string, params: RequestParams = {}) =>
      this.request<AccountServiceGetFontSettingsData, AccountServiceGetFontSettingsError>({
        path: `/web/v2/user/account/${id}/getFontSettings`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceSetFontSettings
     * @request POST:/web/v2/user/account/{id}/setFontSettings
     */
    accountServiceSetFontSettings: (
      id: string,
      body: AccountServiceSetFontSettingsPayload,
      params: RequestParams = {},
    ) =>
      this.request<AccountServiceSetFontSettingsData, AccountServiceSetFontSettingsError>({
        path: `/web/v2/user/account/${id}/setFontSettings`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceSetLogo
     * @request POST:/web/v2/user/account/{id}/setLogo
     */
    accountServiceSetLogo: (id: string, body: AccountServiceSetLogoPayload, params: RequestParams = {}) =>
      this.request<AccountServiceSetLogoData, AccountServiceSetLogoError>({
        path: `/web/v2/user/account/${id}/setLogo`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceUpdateCompany
     * @request POST:/web/v2/user/account/{id}/updateCompany
     */
    accountServiceUpdateCompany: (id: string, body: AccountServiceUpdateCompanyPayload, params: RequestParams = {}) =>
      this.request<AccountServiceUpdateCompanyData, AccountServiceUpdateCompanyError>({
        path: `/web/v2/user/account/${id}/updateCompany`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceUpdateContactPerson
     * @request POST:/web/v2/user/account/{id}/updateContactPerson
     */
    accountServiceUpdateContactPerson: (
      id: string,
      body: AccountServiceUpdateContactPersonPayload,
      params: RequestParams = {},
    ) =>
      this.request<AccountServiceUpdateContactPersonData, AccountServiceUpdateContactPersonError>({
        path: `/web/v2/user/account/${id}/updateContactPerson`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceUpdateIndividual
     * @request POST:/web/v2/user/account/{id}/updateIndividual
     */
    accountServiceUpdateIndividual: (
      id: string,
      body: AccountServiceUpdateIndividualPayload,
      params: RequestParams = {},
    ) =>
      this.request<AccountServiceUpdateIndividualData, AccountServiceUpdateIndividualError>({
        path: `/web/v2/user/account/${id}/updateIndividual`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountService
     * @name AccountServiceUpdateStates
     * @request POST:/web/v2/user/account/{id}/updateStates
     */
    accountServiceUpdateStates: (id: string, body: AccountServiceUpdateStatesPayload, params: RequestParams = {}) =>
      this.request<AccountServiceUpdateStatesData, AccountServiceUpdateStatesError>({
        path: `/web/v2/user/account/${id}/updateStates`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceGet
     * @summary Read
     * @request GET:/web/v2/user/get/{userId}
     */
    userServiceGet: (userId: string, params: RequestParams = {}) =>
      this.request<UserServiceGetData, UserServiceGetError>({
        path: `/web/v2/user/get/${userId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceGetByMail
     * @request GET:/web/v2/user/getByMail/{email}
     */
    userServiceGetByMail: (email: string, params: RequestParams = {}) =>
      this.request<UserServiceGetByMailData, UserServiceGetByMailError>({
        path: `/web/v2/user/getByMail/${email}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceInviteEmployee
     * @summary Employees
     * @request POST:/web/v2/user/inviteEmployee
     */
    userServiceInviteEmployee: (body: Userv2InviteEmployeeRequest, params: RequestParams = {}) =>
      this.request<UserServiceInviteEmployeeData, UserServiceInviteEmployeeError>({
        path: `/web/v2/user/inviteEmployee`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceList
     * @request GET:/web/v2/user/list
     */
    userServiceList: (query: UserServiceListParams, params: RequestParams = {}) =>
      this.request<UserServiceListData, UserServiceListError>({
        path: `/web/v2/user/list`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceListAllEmployees
     * @request POST:/web/v2/user/listAllEmployees
     */
    userServiceListAllEmployees: (body: V2ListAllEmployeesRequest, params: RequestParams = {}) =>
      this.request<UserServiceListAllEmployeesData, UserServiceListAllEmployeesError>({
        path: `/web/v2/user/listAllEmployees`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceListEmployees
     * @request GET:/web/v2/user/listEmployees/{accountId}
     */
    userServiceListEmployees: (accountId: string, params: RequestParams = {}) =>
      this.request<UserServiceListEmployeesData, UserServiceListEmployeesError>({
        path: `/web/v2/user/listEmployees/${accountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationService
     * @name NotificationServiceGetToken
     * @request GET:/web/v2/user/notification/getToken
     */
    notificationServiceGetToken: (query: NotificationServiceGetTokenParams, params: RequestParams = {}) =>
      this.request<NotificationServiceGetTokenData, NotificationServiceGetTokenError>({
        path: `/web/v2/user/notification/getToken`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationService
     * @name NotificationServicePublishUpdateMessage
     * @request POST:/web/v2/user/notification/publishUpdateMessage
     */
    notificationServicePublishUpdateMessage: (body: V2PublishUpdateMessageRequest, params: RequestParams = {}) =>
      this.request<NotificationServicePublishUpdateMessageData, NotificationServicePublishUpdateMessageError>({
        path: `/web/v2/user/notification/publishUpdateMessage`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationService
     * @name NotificationServiceGetSettings
     * @request GET:/web/v2/user/notification/settings
     */
    notificationServiceGetSettings: (query: NotificationServiceGetSettingsParams, params: RequestParams = {}) =>
      this.request<NotificationServiceGetSettingsData, NotificationServiceGetSettingsError>({
        path: `/web/v2/user/notification/settings`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationService
     * @name NotificationServiceSetSettings
     * @request POST:/web/v2/user/notification/settings
     */
    notificationServiceSetSettings: (body: Usernotificationv2SetSettingsRequest, params: RequestParams = {}) =>
      this.request<NotificationServiceSetSettingsData, NotificationServiceSetSettingsError>({
        path: `/web/v2/user/notification/settings`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PartnerDocumentService
     * @name PartnerDocumentServiceAdd
     * @request POST:/web/v2/user/partner-document/add
     */
    partnerDocumentServiceAdd: (body: PartnerDocumentAddRequest, params: RequestParams = {}) =>
      this.request<PartnerDocumentServiceAddData, PartnerDocumentServiceAddError>({
        path: `/web/v2/user/partner-document/add`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PartnerDocumentService
     * @name PartnerDocumentServiceRemove
     * @request DELETE:/web/v2/user/partner-document/remove
     */
    partnerDocumentServiceRemove: (query: PartnerDocumentServiceRemoveParams, params: RequestParams = {}) =>
      this.request<PartnerDocumentServiceRemoveData, PartnerDocumentServiceRemoveError>({
        path: `/web/v2/user/partner-document/remove`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PartnerDocumentService
     * @name PartnerDocumentServiceGet
     * @request GET:/web/v2/user/partner-document/{sourceAccountId}/target/{targetAccountId}
     */
    partnerDocumentServiceGet: (sourceAccountId: string, targetAccountId: string, params: RequestParams = {}) =>
      this.request<PartnerDocumentServiceGetData, PartnerDocumentServiceGetError>({
        path: `/web/v2/user/partner-document/${sourceAccountId}/target/${targetAccountId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceRegister
     * @summary Create
     * @request POST:/web/v2/user/register
     */
    userServiceRegister: (body: Userv2RegisterRequest, params: RequestParams = {}) =>
      this.request<UserServiceRegisterData, UserServiceRegisterError>({
        path: `/web/v2/user/register`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceResolveToken
     * @request GET:/web/v2/user/resolveToken/{token}
     */
    userServiceResolveToken: (token: string, params: RequestParams = {}) =>
      this.request<UserServiceResolveTokenData, UserServiceResolveTokenError>({
        path: `/web/v2/user/resolveToken/${token}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceBlock
     * @summary Delete/block
     * @request POST:/web/v2/user/{userIds}/block
     */
    userServiceBlock: (userIds: string[], params: RequestParams = {}) =>
      this.request<UserServiceBlockData, UserServiceBlockError>({
        path: `/web/v2/user/${userIds}/block`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceUnblock
     * @request POST:/web/v2/user/{userIds}/unBlock
     */
    userServiceUnblock: (userIds: string[], params: RequestParams = {}) =>
      this.request<UserServiceUnblockData, UserServiceUnblockError>({
        path: `/web/v2/user/${userIds}/unBlock`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceAddRole
     * @request POST:/web/v2/user/{userId}/addRole
     */
    userServiceAddRole: (
      userId: string,
      body: {
        role?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserServiceAddRoleData, UserServiceAddRoleError>({
        path: `/web/v2/user/${userId}/addRole`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceChangeEmail
     * @request POST:/web/v2/user/{userId}/changeEmail
     */
    userServiceChangeEmail: (userId: string, body: UserServiceChangeEmailPayload, params: RequestParams = {}) =>
      this.request<UserServiceChangeEmailData, UserServiceChangeEmailError>({
        path: `/web/v2/user/${userId}/changeEmail`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceChangePassword
     * @request POST:/web/v2/user/{userId}/changePassword
     */
    userServiceChangePassword: (userId: string, body: UserServiceChangePasswordPayload, params: RequestParams = {}) =>
      this.request<UserServiceChangePasswordData, UserServiceChangePasswordError>({
        path: `/web/v2/user/${userId}/changePassword`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceRemoveRole
     * @request POST:/web/v2/user/{userId}/removeRole
     */
    userServiceRemoveRole: (
      userId: string,
      body: {
        role?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserServiceRemoveRoleData, UserServiceRemoveRoleError>({
        path: `/web/v2/user/${userId}/removeRole`,
        method: "POST",
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserService
     * @name UserServiceUpdateProfile
     * @summary Update
     * @request POST:/web/v2/user/{userId}/updateProfile
     */
    userServiceUpdateProfile: (userId: string, body: UserServiceUpdateProfilePayload, params: RequestParams = {}) =>
      this.request<UserServiceUpdateProfileData, UserServiceUpdateProfileError>({
        path: `/web/v2/user/${userId}/updateProfile`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
}
