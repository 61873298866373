<template>
  <div class="row">
    <div class="col d-flex justify-content-center m-3">
      <coozzy-spinner />
    </div>
  </div>
</template>

<script>
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import { user } from '@/mixins/user'
import * as Sentry from '@sentry/browser'
import Vue from 'vue'

export default {
  name: 'CallbackView',
  components: { CoozzySpinner },
  mixins: [user],
  created() {
    this.$auth.handleAuthenticationCallback()
      .catch((e) => {
        Sentry.captureException(e)
        Vue.toasted.show(`Login error: ${JSON.stringify(e)}`, { type: 'error' })

        setTimeout(() => {
          this.$router.push({ name: 'Login' })
        }, 5000)
      })
  },
  methods: {
    handleLoginEvent() {
      if (!this.$auth.isUserCompletelyRegistered()) {
        this.$router.push({ name: 'PostRegistration' })
      } else if (localStorage.loginRedirect && !localStorage.loginRedirect.includes('login')) {
        window.location.href = localStorage.loginRedirect
        localStorage.removeItem('loginRedirect')
      } else {
        if (this.hasAccessAdminBase) {
          this.$router.push({ name: 'AdminDashboardView' })
        } else if (this.hasAccessPropBase) {
          this.$router.push({ name: 'OwnerDashboardOverview' })
        } else if (this.hasAccessAssetBase) {
          this.$router.push({ name: 'AssetDashboardView' })
        } else if (this.hasAccessAccountingBase) {
          this.$router.push({ name: 'AccountingDashboardView' })
        } else if (this.hasAccessMarketBase) {
          this.$router.push({ name: 'MarketingDashboardView' })
        } else if (this.hasAccessUserBase) {
          if (localStorage.getItem('previous-page').startsWith('/object')) {
            this.$router.push(localStorage.getItem('previous-page'))
          } else {
            this.$router.push({ name: 'UserRequests' })
          }
        } else {
          // Fallback that should never happen: Redirect to PropBase
          this.$router.push({ name: 'OwnerDashboardOverview' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
