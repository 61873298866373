<template>
  <img
    width="18px"
    height="18px"
    src="@/assets/icon/checkMarkIcon.png"
    alt="filterIcon">
</template>

<script>

export default {
  name: 'CoozzyCheckMarkIcon'
}
</script>

<style lang="scss" scoped>
</style>
