<template>
  <section>
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-for="(property, index) in properties"
      v-else
      :key="index"
      class="col-12">
      <coozzy-card
        class="global-card">
        <div
          v-b-toggle="`coozzy-card-property${index}`"
          class="collapse-title">
          <h5 class="cursor-pointer">
            {{ property.name }} (<address-text :address="property.address" />)
          </h5>
        </div>
        <b-collapse
          :id="'coozzy-card-property' + index"
          :visible="true">
          <b-table
            id="index"
            :ref="'PropertiesViewTable' + index"
            class="mt-2"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            fixed
            :fields="fields"
            :items="buildings.filter(x => property.buildingIds.includes(x.id))">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <!-- Headings -->
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                :initial="propertyIds.length > 0 || buildings.every(x => buildingIds.includes(x.id))"
                @change="(value) => { handleCheckboxesInsideTable(value, index) }" />
              <!--                :initial="property.buildingIds.length === property.buildingIds.filter(x => buildingIds.includes(x)).length"-->
            </template>
            <template #head(thumbnail)>
              <span>{{ $t('message.tableColumnHeadings.thumbnail') }}</span>
            </template>
            <template #head(buildingsName)>
              <span>{{ $t('message.tableColumnHeadings.buildingsName') }}</span>
            </template>
            <template #head(numericId)>
              <span>{{ $t('message.generic.buildingID.idShort') }}</span>
            </template>
            <template #head(buildingStrategy)>
              <span>{{ $t('message.onBoarding.buildings.buildingStrategy.type' ) }}</span>
            </template>
            <template #head(building_strategy_review)>
              <coozzy-sort-dropdown
                ref="building_strategy_review_heading"
                sorting-field="building_strategy_review"
                :desc-only="true"
                :text="$t('message.onBoarding.buildings.buildingStrategy.reviewAt')"
                @updateSorting="updateSorting" />
            </template>
            <template #head(address)>
              {{ $t('message.generic.address') }}
            </template>
            <template #head(objects)>
              {{ $t('message.managementOverview.objects') }}
            </template>
            <template #head(building_target_gross)>
              <coozzy-sort-dropdown
                ref="building_target_gross_heading"
                sorting-field="building_target_gross"
                :desc-only="true"
                :text="$t('message.tableColumnHeadings.grossTargetRentYear')"
                @updateSorting="updateSorting" />
            </template>
            <template #head(building_actual_gross)>
              <coozzy-sort-dropdown
                ref="building_actual_gross_heading"
                sorting-field="building_actual_gross"
                :desc-only="true"
                :text="$t('message.tableColumnHeadings.grossActualRentYear')"
                @updateSorting="updateSorting" />
            </template>
            <template #head(building_target_net)>
              <coozzy-sort-dropdown
                ref="building_target_net_heading"
                sorting-field="building_target_net"
                :desc-only="true"
                :text="$t('message.tableColumnHeadings.netTargetRentYear')"
                @updateSorting="updateSorting" />
            </template>
            <template #head(building_actual_net)>
              <coozzy-sort-dropdown
                ref="building_actual_net_heading"
                sorting-field="building_actual_net"
                :desc-only="true"
                :text="$t('message.tableColumnHeadings.netActualRentYear')"
                @updateSorting="updateSorting" />
            </template>
            <template
              #head(owner)>
              <span>{{ $t('message.tableColumnHeadings.owner') }}</span>
            </template>

            <!-- Data -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="'check_' + data.item.name"
                :initial="propertyIds.length > 0 || buildingIds.includes(data.item.id)"
                @change="(value) => { buildingSelected(data.item, value) }" />
            </template>
            <template #cell(thumbnail)="data">
              <coozzy-thumbnail
                v-if="data.item.image && data.item.image.media.thumbnails.length"
                :src="data.item.image.media.thumbnails[0].url"
                :original="data.item.image.url" />
              <div
                v-else
                class="d-flex justify-content-center">
                <coozzy-thumbnail
                  class="thumbnail"
                  src="/img/image-placeholder.png"
                  alt="Placeholder" />
              </div>
            </template>
            <template #cell(buildingsName)="data">
              {{ data.item.name | displayOptionalValue }}
            </template>
            <template #cell(numericId)="data">
              {{ data.item.numericId | displayOptionalValue }}
            </template>
            <template #cell(buildingStrategy)="data">
              <template v-if="!data.item.buildingStrategy || data.item.buildingStrategy.type === 'BUILDING_STRATEGY_TYPE_UNDEFINED'">
                -
              </template>
              <template v-else>
                {{ $t('message.onBoarding.buildings.buildingStrategy.' + data.item.buildingStrategy.type ) }}
              </template>
            </template>
            <template #cell(building_strategy_review)="data">
              <template v-if="data.item.buildingStrategy">
                {{ data.item.buildingStrategy.reviewAt | objectToDate }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(address)="data">
              <address-text :address="data.item.address" />
            </template>
            <template #cell(objects)="data">
              {{ data.item.objectIds.length | displayOptionalValue }}
            </template>
            <template #cell(building_target_gross)="data">
              {{ data.item.grossTargetRentPerYear | formatPrice }}
              <coozzy-info-circle-icon
                v-if="targetRentIncomplete(data.item.objectID)"
                v-b-tooltip="{container: '#buildingsTable', title: $t('message.manageBuilding.noExactValue')}" />
            </template>
            <template #cell(owner)="data">
              <span class="d-block">
                <img
                  v-if="data.item.ownerId"
                  height="20px"
                  src="/img/propbase-icon.png"
                  alt="prop-base">
                {{ data.item.ownerName | displayOptionalValue }}
              </span>
            </template>
            <template #cell(building_actual_gross)="data">
              {{ data.item.grossActualRentPerYear | formatPrice }}
            </template>
            <template #cell(building_target_net)="data">
              {{ data.item.netTargetRentPerYear | formatPrice }}
              <coozzy-info-circle-icon
                v-if="targetRentIncomplete(data.item.objectID)"
                v-b-tooltip="{container: '#buildingsTable', title: $t('message.manageBuilding.noExactValue')}" />
            </template>
            <template #cell(building_actual_net)="data">
              {{ data.item.netActualRentPerYear | formatPrice }}
            </template>
          </b-table>
        </b-collapse>
      </coozzy-card>
    </div>
  </section>
</template>

<script>

import ObjectApi from '@/misc/apis/ObjectApi'
import AddressText from '../framework/components/misc/AddressText'
import { tables } from '@/mixins/tables'
import Vue from 'vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import MediaApi from '@/misc/apis/MediaApi'
import ReportingApi from '@/misc/apis/ReportingApi'
import UserApi from '@/misc/apis/UserApi'
import CoozzyThumbnail from '@/framework/components/img/CoozzyThumbnail.vue'

export default {
  name: 'SelectPropertiesAndBuildings',
  components: {
    CoozzyThumbnail,
    CoozzyFormCheckbox,
    AddressText
  },
  mixins: [tables],
  props: {
    propertyIds: {
      type: Array,
      default() {
        return []
      }
    },
    buildingIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: true,
      buildings: [],
      properties: [],
      selectedEntries: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'thumbnail',
          label: this.$t('message.tableColumnHeadings.thumbnail'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle'
        },
        {
          key: 'buildingsName',
          label: this.$t('message.tableColumnHeadings.buildingsName'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'numericId',
          label: this.$t('message.generic.buildingID.idShort'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center border-left-0'
        },
        {
          key: 'buildingStrategy',
          label: this.$t('message.generic.buildingStrategy.type'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center border-left-0'
        },
        {
          key: 'building_strategy_review',
          label: this.$t('message.onBoarding.buildings.buildingStrategy.reviewAt'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center border-left-0'
        },
        {
          key: 'address',
          label: this.$t('message.generic.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'objects',
          label: this.$t('message.managementOverview.objects'),
          tdClass: 'text-center align-middle text-center',
          thClass: 'align-middle text-center'
        },
        {
          key: 'building_target_gross',
          label: this.$t('message.tableColumnHeadings.grossTargetRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'building_actual_gross',
          label: this.$t('message.tableColumnHeadings.grossActualRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'building_target_net',
          label: this.$t('message.tableColumnHeadings.netTargetRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'building_actual_net',
          label: this.$t('message.tableColumnHeadings.netActualRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'owner',
          label: this.$t('message.tableColumnHeadings.owner'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ]
    }
  },
  watch: {
    buildingIds: {
      handler() {
        this.fetchBuildingsAndProperties()
      },
      deep: true
    },
    propertyIds: {
      handler() {
        this.fetchBuildingsAndProperties()
      },
      deep: true
    }
  },
  async mounted() {
    this.loading = true
    await this.fetchBuildingsAndProperties()
    this.loading = false
  },
  methods: {
    async fetchBuildingsAndProperties() {
      if (this.propertyIds.length) {
        await ObjectApi.getPropertyListByIds(this.propertyIds).then(values => {
          this.properties = values.properties
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
          this.$router.back()
        })
      } else if (this.buildingIds.length) {
        await ObjectApi.getPropertiesByBuildingIds(this.buildingIds).then(values => {
          this.properties = values.properties
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
          this.$router.back()
        })
      }

      try {
        const buildingResponse = await ObjectApi.getBuildingsListByIds(this.properties.flatMap(x => x.buildingIds))
        const promises = []
        this.buildingList = buildingResponse.buildings
        for (const building of this.buildingList) {
          if (building.imageMediaId) {
            promises.push(MediaApi.getMediaByID(building.imageMediaId))
          } else {
            promises.push(Promise.resolve(null))
          }
        }
        const imagesResponses = await Promise.all(promises)
        for (const building of this.buildingList) {
          if (building.imageMediaId) {
            building.image = imagesResponses.find(x => x && x.media && x.media.id === building.imageMediaId)
          }
        }

        const reportingResponse = await ReportingApi.getBuildingRentReports(this.buildingList.map(x => x.id))
        for (const report of reportingResponse.reports) {
          const building = this.buildingList.find(x => x.id === report.referenceId)
          if (building) {
            building.grossActualRentPerYear = report.grossActualRentPerYear
            building.grossTargetRentPerYear = report.grossTargetRentPerYear
            building.netActualRentPerYear = report.netActualRentPerYear
            building.netTargetRentPerYear = report.netTargetRentPerYear
            building.targetRentIncomplete = report.targetRentIncomplete
          }
        }

        const ownerIds = []
        for (const building of this.buildingList) {
          if (!ownerIds.find(x => x === building.ownerId)) {
            ownerIds.push(building.ownerId)
          }
        }
        const ownersResponse = await UserApi.listAccountsByIds(ownerIds)
        for (const building of this.buildingList) {
          building.ownerName = ownersResponse.accounts.find(x => x.id === building.ownerId)?.company?.name ||
            (ownersResponse.accounts.find(x => x.id === building.ownerId)?.individual ? ownersResponse.accounts.find(x => x.id === building.ownerId)?.individual.firstName + ' ' + ownersResponse.accounts.find(x => x.id === building.ownerId)?.individual.lastName : '')
        }

        this.buildings = this.buildingList
        this.mountSelectedBuildings(this.buildings.map(x => x.id).filter(x => this.buildingIds.includes(x) || this.propertyIds.length))
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
        this.$router.back()
      }
    },
    mountSelectedBuildings(buildingIds) {
      this.$emit('selected-objects', buildingIds)
      this.selectedEntries = buildingIds
    },
    selectedObject(object) {
      this.$emit('selected-object', object)
    },
    clearCheckbox() {
      this.selectedEntries = []
      this.selectedEntriesTransferred = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    handleCheckboxesInsideTable(value, index) {
      if (this.$refs['PropertiesViewTable' + index]) {
        const ref = this.$refs['PropertiesViewTable' + index]
        if (ref.length) {
          const allCheckboxesComponents = ref[0].$children[1].$children
          this.handleCheckbox(allCheckboxesComponents, value)
        }
      }
    },
    buildingSelected(building, checked) {
      if (checked) {
        this.selectedEntries.push(building.id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(
          entry => entry !== building.id
        )
      }
      this.$emit('selected-objects', this.selectedEntries)
    },
    targetRentIncomplete(buildingId) {
      if (this.buildingRentReports?.length) {
        const report = this.buildingRentReports.find(report => report.referenceId === buildingId)
        if (report) {
          return report.targetRentIncomplete
        }
      }
      return false
    },
    updateSorting(value, field) {
      // if (field !== 'building_target_gross') {
      //   this.$refs.building_target_gross_heading.direction = ''
      // }
      // if (field !== 'building_target_net') {
      //   this.$refs.building_target_net_heading.direction = ''
      // }
      // if (field !== 'building_actual_gross') {
      //   this.$refs.building_actual_gross_heading.direction = ''
      // }
      // if (field !== 'building_actual_net') {
      //   this.$refs.building_actual_net_heading.direction = ''
      // }
      // let element = this.$refs['sort-' + field][0]
      // if (value === 'asc') {
      //   element = this.$refs['sort-building'][0]
      // }
      // if (field !== 'building_strategy_review') {
      //   this.$refs.building_strategy_review_heading.direction = ''
      // }
      // element.click()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
