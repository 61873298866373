<template>
  <img
    width="13px"
    src="@/assets/icon/trash.png"
    alt="filterIcon">
</template>

<script>

export default {
  name: 'CoozzyDeleteIconImg'
}
</script>

<style lang="scss" scoped>
</style>
