<template>
  <span class="align-middle w-100 text-nowrap">
    <span
      v-if="count !== null"
      class="cursor-pointer"
      @click="countClicked">{{ count }} </span>
    <coozzy-badge
      class="p-1"
      :clickable="unread !== 0"
      :design="unread !== 0 ? 'red' : 'grey'"
      size="large"
      @click="badgeClicked">
      {{ unread }}
    </coozzy-badge>
  </span>
</template>

<script>
export default {
  name: 'CoozzyCountSpan',
  props: {
    count: {
      type: Number,
      default: 0
    },
    unread: {
      type: Number,
      default: 0
    }
  },
  methods: {
    countClicked(e) {
      this.$emit('count-click', e)
    },
    badgeClicked(e) {
      if (this.unread !== 0) {
        this.$emit('badge-click', e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
