import { render, staticRenderFns } from "./CoozzyDeleteIconImg.vue?vue&type=template&id=fde7d224&scoped=true"
import script from "./CoozzyDeleteIconImg.vue?vue&type=script&lang=js"
export * from "./CoozzyDeleteIconImg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fde7d224",
  null
  
)

export default component.exports