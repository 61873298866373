<template>
  <section>
    <b-modal
      v-if="referenceType === 'employer'"
      id="reference-employer"
      ref="reference-employer"
      no-close-on-backdrop>
      <div slot="modal-title">
        {{ $t('message.interestedParty.reference.employer.title') }}
      </div>
      <div
        class="row">
        <div
          v-if="!requesterContacts.length < 1"
          class="col-sm-12 col-md-12 mb-2">
          <coozzy-button
            v-for="item in requesterContacts"
            :key="item.id"
            :design="item.id === contactId ? 'primary' : (isEmpty(item.id, 'employer') ? 'default' : 'prop-green')"
            class="mr-2 mb-2"
            @click="showRequester(item.id)">
            {{ item.name }}
          </coozzy-button>
        </div>
      </div>
      <div
        class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-sm-6 col-md-6 mb-2">
              <coozzy-form-input
                :key="contactId"
                v-model="employerObtainedOn"
                type="date"
                :name="$t('message.interestedParty.reference.obtainedOn') + ':'"
                @blur="dateToObject('employer')" />
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-input
            v-model="employerReference.name"
            :name="$t('message.interestedParty.reference.employer.employer')" />
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-input
            v-model="employerReference.contactPerson"
            :name="$t('message.interestedParty.reference.employer.contactPerson')" />
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-input
            v-model="employerReference.email"
            :name="$t('message.interestedParty.reference.employer.email')" />
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-input
            v-model="employerReference.phone"
            :name="$t('message.interestedParty.reference.employer.phone')" />
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <label for="duration">{{ $t('message.interestedParty.reference.employer.duration') }}</label>
          <coozzy-form-textarea
            id="duration"
            v-model="employerReference.duration"
            :text="employerReference.duration"
            :initial="employerReference.duration"
            :show-label="false"
            :rows="3">
            {{ employerReference.duration }}
          </coozzy-form-textarea>
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <label for="probation">{{ $t('message.interestedParty.reference.employer.probation') }}</label>
          <coozzy-form-textarea
            id="probation"
            v-model="employerReference.probation"
            :text="employerReference.probation"
            :initial="employerReference.probation"
            :show-label="false"
            :rows="3">
            {{ employerReference.probation }}
          </coozzy-form-textarea>
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <label for="temporalEmployment">
            {{ $t('message.interestedParty.reference.employer.temporalEmployment') }}
          </label>
          <coozzy-form-textarea
            id="temporalEmployment"
            v-model="employerReference.temporalEmployment"
            :text="employerReference.temporalEmployment"
            :initial="employerReference.temporalEmployment"
            :show-label="false"
            :rows="3">
            {{ employerReference.temporalEmployment }}
          </coozzy-form-textarea>
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <label for="salary">{{ $t('message.interestedParty.reference.employer.salary') }}</label>
          <coozzy-form-textarea
            id="salary"
            v-model="employerReference.salary"
            :text="employerReference.salary"
            :initial="employerReference.salary"
            :show-label="false"
            :rows="3">
            {{ employerReference.salary }}
          </coozzy-form-textarea>
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <label for="notes">{{ $t('message.interestedParty.reference.employer.notes') }}</label>
          <coozzy-form-textarea
            id="notes"
            v-model="employerReference.notes"
            :text="employerReference.notes"
            :initial="employerReference.notes"
            :show-label="false"
            :rows="3">
            {{ employerReference.notes }}
          </coozzy-form-textarea>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          design="green"
          class="float-right ml-3"
          :disabled="loading"
          @click="saveEmployerReference(false)">
          {{ $t('message.marketingObjects.editAdvert.saveDraft') }}
        </coozzy-button>
        <coozzy-button
          design="prop-green"
          class="float-right ml-3"
          :disabled="loading"
          @click="finishEmployerReference">
          {{ $t('message.interestedParty.reference.finishReference') }}
        </coozzy-button>
        <coozzy-button
          design="default"
          :disabled="loading"
          @click="$bvModal.hide('reference-employer')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      v-if="referenceType === 'landlord'"
      id="reference-landlord"
      ref="reference-landlord"
      no-close-on-backdrop>
      <div slot="modal-title">
        {{ $t('message.interestedParty.reference.landlord.title') }}
      </div>
      <div
        class="row">
        <div
          v-if="!requesterContacts.length < 1"
          class="col-sm-12 col-md-12 mb-2">
          <coozzy-button
            v-for="item in requesterContacts"
            :key="item.id"
            :design="item.id === contactId ? 'primary' : isEmpty(item.id, 'landlord') ? 'default' : 'prop-green'"
            class="mr-2 mb-2"
            @click="showRequester(item.id)">
            {{ item.name }}
          </coozzy-button>
        </div>
      </div>
      <div
        class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-sm-6 col-md-6 mb-2">
              <coozzy-form-input
                :key="contactId"
                v-model="landlordObtainedOn"
                type="date"
                :name="$t('message.interestedParty.reference.obtainedOn') + ':'"
                @blur="dateToObject('landlord')" />
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-input
            v-model="landlordReference.name"
            :name="$t('message.interestedParty.reference.landlord.landlord')" />
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-input
            v-model="landlordReference.contactPerson"
            :name="$t('message.interestedParty.reference.landlord.contactPerson')" />
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-input
            v-model="landlordReference.email"
            :name="$t('message.interestedParty.reference.landlord.email')" />
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-input
            v-model="landlordReference.phone"
            :name="$t('message.interestedParty.reference.landlord.phone')" />
        </div>
        <div
          class="col-sm-6 col-md-6 mb-2">
          <label for="durationLandlord">{{ $t('message.interestedParty.reference.landlord.duration') }}</label>
          <coozzy-form-textarea
            id="durationLandlord"
            v-model="landlordReference.duration"
            :text="landlordReference.duration"
            :initial="landlordReference.duration"
            :show-label="false"
            :rows="3">
            {{ landlordReference.duration }}
          </coozzy-form-textarea>
        </div>
        <div
          class="col-sm-6 col-md-6 mb-2">
          <label for="punctualPayment">{{ $t('message.interestedParty.reference.landlord.punctualPayment') }}</label>
          <coozzy-form-textarea
            id="punctualPayment"
            v-model="landlordReference.punctualPayment"
            :text="landlordReference.punctualPayment"
            :initial="landlordReference.punctualPayment"
            :show-label="false"
            :rows="3">
            {{ landlordReference.punctualPayment }}
          </coozzy-form-textarea>
        </div>
        <div
          class="col-sm-6 col-md-6 mb-2">
          <label for="complaints">{{ $t('message.interestedParty.reference.landlord.complaints') }}</label>
          <coozzy-form-textarea
            id="complaints"
            v-model="landlordReference.complaints"
            :text="landlordReference.complaints"
            :initial="landlordReference.complaints"
            :show-label="false"
            :rows="3">
            {{ landlordReference.complaints }}
          </coozzy-form-textarea>
        </div>
        <div
          class="col-sm-6 col-md-6 mb-2">
          <label for="costs">{{ $t('message.interestedParty.reference.landlord.costs') }}</label>
          <coozzy-form-textarea
            id="costs"
            v-model="landlordReference.costs"
            :text="landlordReference.costs"
            :initial="landlordReference.costs"
            :show-label="false"
            :rows="3">
            {{ landlordReference.costs }}
          </coozzy-form-textarea>
        </div>
        <div
          class="col-sm-12 col-md-12 mb-2">
          <label for="notesLandlord">{{ $t('message.interestedParty.reference.landlord.notes') }}</label>
          <coozzy-form-textarea
            id="notesLandlord"
            v-model="landlordReference.notes"
            :text="landlordReference.notes"
            :initial="landlordReference.notes"
            :show-label="false"
            :rows="3">
            {{ landlordReference.notes }}
          </coozzy-form-textarea>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          design="green"
          class="float-right ml-3"
          :disabled="loading"
          @click="saveLandlordReference(false)">
          {{ $t('message.marketingObjects.editAdvert.saveDraft') }}
        </coozzy-button>
        <coozzy-button
          design="prop-green"
          class="float-right ml-3"
          :disabled="loading"
          @click="finishLandlordReference">
          {{ $t('message.interestedParty.reference.finishReference') }}
        </coozzy-button>
        <coozzy-button
          design="default"
          :disabled="loading"
          @click="$bvModal.hide('reference-landlord')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import RequestApi from '@/marketingBasic/api/RequestApi'
import Vue from 'vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'

export default {
  name: 'ReferenceModal',
  components: {
    CoozzyFormTextarea,
    CoozzyFormInput,
    CoozzyButton
  },
  props: {
    request: {
      type: Object,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    referenceType: {
      type: String,
      default: ''
    },
    requesterContacts: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      contactId: '',
      employerReferences: {},
      landlordReferences: {},
      employerReference: {
        contactPerson: '',
        duration: '',
        name: '',
        notes: '',
        probation: '',
        salary: '',
        temporalEmployment: '',
        email: '',
        phone: '',
        finalized: false,
        obtainedOn: null
      },
      landlordReference: {
        complaints: '',
        contactPerson: '',
        costs: '',
        duration: '',
        name: '',
        notes: '',
        punctualPayment: '',
        email: '',
        phone: '',
        finalized: false,
        obtainedOn: null
      },
      loading: false,
      employerObtainedOn: null,
      landlordObtainedOn: null
    }
  },
  computed: {
    // checkEmpty() {
    //   if (this.referenceType === 'landlord') {
    //     const landl = this.request.requesters.filter(o => o.landlordReference !== null)
    //     const pp = landl.map(land => land.landlordReference.complaints !== '' || land.landlordReference.contactPerson !== '' || land.landlordReference.costs !== '' || land.landlordReference.duration !== '' || land.landlordReference.name !== '' || land.landlordReference.notes !== '' || land.landlordReference.punctualPayment !== '').filter(x => x === true)
    //     return pp.length > 0
    //   } else if (this.referenceType === 'employer') {
    //     const emp = this.request.requesters.filter(o => o.employerReference !== null)
    //     const pp = emp.map(empl => empl.employerReference.contactPerson !== '' || empl.employerReference.duration !== '' || empl.employerReference.name !== '' || empl.employerReference.notes !== '' || empl.employerReference.probation !== '' || empl.employerReference.salary !== '' || empl.employerReference.temporalEmployment !== '').filter(x => x === true)
    //     return pp.length > 0
    //   }
    //   return false
    // }
  },
  watch: {
    landlordReference: function () {
      if (this.contactId !== '') {
        this.landlordReferences[this.contactId] = this.landlordReference
      }
    },
    employerReference: function () {
      if (this.contactId !== '') {
        this.employerReferences[this.contactId] = this.employerReference
      }
    }
  },
  mounted: function () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'reference-employer' || modalId === 'reference-landlord') {
        // We need at least 1 contact
        if (this.requesterContacts.length === 0) {
          this.hide()
          return
        }

        // Store references of all requesters
        for (const requester of this.request.requesters) {
          if (requester.landlordReference) {
            this.landlordReferences[requester.contactId] = requester.landlordReference
          } else {
            this.landlordReferences[requester.contactId] = {
              complaints: '',
              contactPerson: '',
              costs: '',
              duration: '',
              name: '',
              notes: '',
              punctualPayment: '',
              email: '',
              phone: '',
              finalized: false
            }
          }
          if (requester.employerReference) {
            this.employerReferences[requester.contactId] = requester.employerReference
          } else {
            this.employerReferences[requester.contactId] = {
              contactPerson: '',
              duration: '',
              name: '',
              notes: '',
              probation: '',
              salary: '',
              temporalEmployment: '',
              email: '',
              phone: '',
              finalized: false
            }
          }
        }

        this.showRequester(this.requesterContacts[0].id)
      }
    })
  },
  methods: {
    dateToObject(value) {
      if (value === 'employer') {
        if (this.employerObtainedOn !== null && this.employerObtainedOn !== '') {
          const employerObtainedOn = new Date(this.employerObtainedOn)
          this.employerReference.obtainedOn = {
            year: employerObtainedOn.getFullYear(),
            month: employerObtainedOn.getMonth() + 1,
            day: employerObtainedOn.getDate()
          }
        } else {
          this.employerReference.obtainedOn = null
        }
      } else if (value === 'landlord') {
        if (this.landlordObtainedOn !== null && this.landlordObtainedOn !== '') {
          const landlordObtainedOn = new Date(this.landlordObtainedOn)
          this.landlordReference.obtainedOn = {
            year: landlordObtainedOn.getFullYear(),
            month: landlordObtainedOn.getMonth() + 1,
            day: landlordObtainedOn.getDate()
          }
        } else {
          this.landlordReference.obtainedOn = null
        }
      }
    },
    getDatesFromObject () {
      this.employerObtainedOn = this.employerReference.obtainedOn ? `${this.employerReference.obtainedOn.year}-${this.employerReference.obtainedOn.month.toString().padStart(2, '0')}-${this.employerReference.obtainedOn.day.toString().padStart(2, '0')}` : null
      this.landlordObtainedOn = this.landlordReference.obtainedOn ? `${this.landlordReference.obtainedOn.year}-${this.landlordReference.obtainedOn.month.toString().padStart(2, '0')}-${this.landlordReference.obtainedOn.day.toString().padStart(2, '0')}` : null
    },
    finishLandlordReference() {
      if (!this.landlordReference.finalized) {
        this.landlordReference.finalized = true
      }
      this.saveLandlordReference(true)
      this.$emit('new-landlord-references', this.landlordReferences)
      this.hide()
    },
    finishEmployerReference() {
      if (!this.employerReference.finalized) {
        this.employerReference.finalized = true
      }
      this.saveEmployerReference(true)
      this.$emit('new-employer-references', this.employerReferences)
      this.hide()
    },
    getRequester(contactId) {
      return this.request && this.request.requesters ? this.request.requesters.find(r => r.contactId === contactId) : null
    },
    show() {
      if (this.referenceType === 'landlord') {
        this.$refs['reference-landlord'].show()
      } else {
        this.$refs['reference-employer']?.show()
      }
    },
    hide() {
      if (this.referenceType === 'landlord') {
        if (this.$refs['reference-landlord']) {
          this.$refs['reference-landlord'].hide()
        }
      } else {
        if (this.$refs['reference-employer']) {
          this.$refs['reference-employer'].hide()
        }
      }
    },
    showRequester(contactId) {
      if (this.contactId !== contactId) {
        this.contactId = contactId
        if (this.isEmpty(contactId, 'landlord')) {
          this.landlordReferences[contactId] = {
            complaints: '',
            contactPerson: '',
            costs: '',
            duration: '',
            name: '',
            notes: '',
            punctualPayment: '',
            email: '',
            phone: '',
            finalized: false
          }
        }
        if (this.isEmpty(contactId, 'employer')) {
          this.employerReferences[contactId] = {
            contactPerson: '',
            duration: '',
            name: '',
            notes: '',
            probation: '',
            salary: '',
            temporalEmployment: '',
            email: '',
            phone: '',
            finalized: false
          }
        }
        this.landlordReference = this.landlordReferences[contactId]
        this.employerReference = this.employerReferences[contactId]
        this.getDatesFromObject()
      }
    },
    isEmpty(contactId, type) {
      if (type === 'landlord') {
        if (this.landlordReferences[contactId] === undefined) {
          return true
        }

        return !(this.landlordReferences[contactId].complaints !== '' ||
          this.landlordReferences[contactId].contactPerson !== '' ||
          this.landlordReferences[contactId].costs !== '' ||
          this.landlordReferences[contactId].duration !== '' ||
          this.landlordReferences[contactId].name !== '' ||
          this.landlordReferences[contactId].notes !== '' ||
          this.landlordReferences[contactId].punctualPayment !== '' ||
          this.landlordReferences[contactId].email !== '' ||
          this.landlordReferences[contactId].phone !== '')
      } else if (type === 'employer') {
        if (this.employerReferences[contactId] === undefined) {
          return true
        }

        return !(this.employerReferences[contactId].contactPerson !== '' ||
          this.employerReferences[contactId].duration !== '' ||
          this.employerReferences[contactId].name !== '' ||
          this.employerReferences[contactId].notes !== '' ||
          this.employerReferences[contactId].probation !== '' ||
          this.employerReferences[contactId].salary !== '' ||
          this.employerReferences[contactId].temporalEmployment !== '' ||
          this.employerReferences[contactId].email !== '' ||
          this.employerReferences[contactId].phone !== '')
      }
    },
    saveEmployerReference(generatePdf) {
      this.loading = true
      const promises = []

      for (const requester of this.request.requesters) {
        promises.push(
          RequestApi.setEmployerReference(
            this.employerReferences[requester.contactId],
            this.request.id,
            requester.contactId,
            generatePdf,
            this.object.marketingLanguage
          )
        )
        requester.employerReference = this.employerReferences[requester.contactId]
      }

      Promise.all(promises)
        .then(() => {
          this.$emit('refresh-documents')
          this.$refs['reference-employer']?.hide()
          Vue.toasted.show(this.$t('message.successMessages.setEmployerReference'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.setEmployerReference'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveLandlordReference(generatePdf) {
      this.loading = true
      const promises = []

      for (const requester of this.request.requesters) {
        promises.push(
          RequestApi.setLandlordReference(
            this.landlordReferences[requester.contactId],
            this.request.id,
            requester.contactId,
            generatePdf,
            this.object.marketingLanguage
          )
        )
        requester.landlordReference = this.landlordReferences[requester.contactId]
      }

      Promise.all(promises)
        .then(() => {
          this.$emit('refresh-documents')
          this.$refs['reference-landlord'].hide()
          Vue.toasted.show(this.$t('message.successMessages.setLandlordReference'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.setLandlordReference'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
