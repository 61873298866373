<template>
  <b-modal
    ref="cropperModal"
    no-close-on-backdrop
    hide-footer>
    <div class="row mb-2">
      <div
        v-if="warningMessage === 'uploadSignature'"
        class="col-12">
        <coozzy-alert>
          {{ $t('message.notifications.messages.warningUploadSignature') }}
        </coozzy-alert>
      </div>
      <div class="col-12">
        <vue-cropper
          ref="cropper"
          :src="selectedFile"
          alt="Source Image"
          :class="warningMessage === 'uploadSignature' ? 'coozzy-cropper-rectangular' : 'coozzy-cropper-circle'"
          :aspect-ratio="defaultRatio !== '' ? defaultRatio : 1/1"
          :initial-aspect-ratio="defaultRatio !== '' ? defaultRatio : 1/1" />
        <coozzy-button
          size="normal"
          design="green"
          class="float-right mt-3"
          @click="saveImage()">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
        <coozzy-button
          size="normal"
          class="border mt-3"
          design="transparent"
          @click="hide()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import CoozzyAlert from '@/framework/components/alert/CoozzyAlert.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'

export default {
  name: 'CropperModal',
  components: { CoozzyButton, CoozzyAlert, VueCropper },
  props: {
    selectedFile: {
      type: String,
      default: ''
    },
    warningMessage: {
      type: String,
      default: ''
    },
    defaultRatio: {
      type: String,
      default: ''
    }
  },
  watch: {
    selectedFile() {
      this.$nextTick(() => {
        this.replaceSrc()
      })
    }
  },
  methods: {
    saveImage() {
      this.$emit('save-image', this.$refs.cropper)
      this.hide()
    },
    replaceSrc() {
      this.$refs.cropper?.replace(this.selectedFile)
    },
    show() {
      this.$refs.cropperModal.show()
    },
    hide() {
      this.$refs.cropperModal.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
.coozzy-cropper-circle {
  :deep(.cropper-crop-box), :deep(.cropper-view-box) {
    border-radius: 50%;
  }
}
.coozzy-cropper-rectangular {
  :deep(.cropper-crop-box), :deep(.cropper-view-box) {
    border-radius: 0;
  }
}
</style>
