<template>
  <font-awesome-icon
    icon="reply"
    class="fa fa-rotate-180" />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faReply } from '@fortawesome/free-solid-svg-icons'

library.add(faReply)

export default {
  name: 'CoozzyForwardIcon'
}
</script>

<style lang="scss" scoped>
  .fa {
    color: $color-text-grey;
  }
  .fa-rotate-180 {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
</style>
