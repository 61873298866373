<template>
  <div class="favorite">
    <font-awesome-icon
      v-if="statefavorite"
      :icon="['fas', 'star']" />
    <font-awesome-icon
      v-if="!statefavorite"
      :icon="['far', 'star']" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas)
library.add(far)

export default {
  name: 'CoozzyFavoriteIcon',
  props: {
    statefavorite: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .favorite {
    color: $color-yellow;
    display: inline;
  }
</style>
