<template>
  <coozzy-card :title="$t('message.settings.synchronisation.abacusCard.title')">
    <p>
      {{ $t('message.settings.synchronisation.abacusCard.subTitle') }}
    </p>
    <div>
      <div class="row">
        <!--    Sync debit until date    -->
        <div class="col-10 col-xl-7 mt-3">
          <h5>{{ $t('message.settings.synchronisation.abacusCard.syncDebitUntilDate') }}</h5>
        </div>
        <div class="col-2 col-xl-5">
          <coozzy-button
            class="mt-2"
            design="prop-green"
            @click="SyncDebitUntil">
            {{ $t('message.settings.synchronisation.syncNow') }}
          </coozzy-button>
        </div>

        <!--    Sync rent adjustments    -->
        <div class="col-10 col-xl-7 mt-2">
          <h5>{{ $t('message.settings.synchronisation.abacusCard.syncRentAdjustments') }}</h5>
        </div>
        <div class="col-2 col-xl-5">
          <coozzy-button
            class="mt-2"
            design="prop-green"
            @click="SyncRentAdjustments">
            {{ $t('message.settings.synchronisation.syncNow') }}
          </coozzy-button>
        </div>

        <!--    Sync documents     -->
        <div class="col-10 col-xl-7 mt-2">
          <h5>{{ $t('message.settings.synchronisation.abacusCard.syncDocuments') }}</h5>
        </div>
        <div class="col-2 col-xl-5">
          <coozzy-button
            class="mt-2"
            design="prop-green"
            @click="SyncDocuments">
            {{ $t('message.settings.synchronisation.syncNow') }}
          </coozzy-button>
        </div>
      </div>
    </div>
  </coozzy-card>
</template>
<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import SynchronisationApi from '@/misc/apis/SynchronisationApi'
import { user } from '@/mixins/user'
import Vue from 'vue'

export default {
  name: 'SynchronisationSettings',
  components: {
    CoozzyButton,
    CoozzyCard
  },
  mixins: [user],
  methods: {
    SyncDebitUntil() {
      SynchronisationApi.triggerDebitUntilSync(this.accountId).then(() => {
        Vue.toasted.show(this.$tc('message.successMessages.syncStarted'), { type: 'success' })
      }).catch(() => {
        Vue.toasted.show(this.$tc('message.savingErrors.savingApplicationForm'), { type: 'error' })
      })
    },
    SyncRentAdjustments() {
      SynchronisationApi.TriggerRentAdjustmentSync(this.accountId).then(() => {
        Vue.toasted.show(this.$tc('message.successMessages.syncStarted'), { type: 'success' })
      }).catch(() => {
        Vue.toasted.show(this.$tc('message.savingErrors.savingApplicationForm'), { type: 'error' })
      })
    },
    SyncDocuments() {
      SynchronisationApi.triggerDocumentSync(this.accountId).then(() => {
        Vue.toasted.show(this.$tc('message.successMessages.syncStarted'), { type: 'success' })
      }).catch(() => {
        Vue.toasted.show(this.$tc('message.savingErrors.savingApplicationForm'), { type: 'error' })
      })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
