import { render, staticRenderFns } from "./ContactReminderCard.vue?vue&type=template&id=ecc9aaa0&scoped=true"
import script from "./ContactReminderCard.vue?vue&type=script&lang=js"
export * from "./ContactReminderCard.vue?vue&type=script&lang=js"
import style0 from "./ContactReminderCard.vue?vue&type=style&index=0&id=ecc9aaa0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecc9aaa0",
  null
  
)

export default component.exports