<template>
  <section>
    <b-modal
      id="modal-add-reminder"
      ref="modal-add-reminder"
      :modal-class="'has-confirmation-modal'"
      no-close-on-backdrop
      hide-footer>
      <div slot="modal-title">
        {{ $t('message.calendar.reminder.create') }}
        <span
          v-if="source !== 'remindersView' && source !== 'message'"
          class="reminderSubTitle"> {{ getReminderSubTitle }}</span>
      </div>
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <div
        v-else
        class="d-block">
        <div class="row mb-2">
          <div class="col">
            <label for="activity">{{ $t('message.calendar.reminder.activity.title') }}</label>
            <coozzy-form-select
              id="activity"
              v-model="reminder.activity">
              <option value="UNDEFINED_REMINDER_ACTIVITY">
                -
              </option>
              <option value="TODO_REMINDER_ACTIVITY">
                {{ $t('message.calendar.reminder.activity.TODO_REMINDER_ACTIVITY') }}
              </option>
              <option value="CALL_REMINDER_ACTIVITY">
                {{ $t('message.calendar.reminder.activity.CALL_REMINDER_ACTIVITY') }}
              </option>
              <option value="MAIL_REMINDER_ACTIVITY">
                {{ $t('message.calendar.reminder.activity.MAIL_REMINDER_ACTIVITY') }}
              </option>
              <option value="POST_REMINDER_ACTIVITY">
                {{ $t('message.calendar.reminder.activity.POST_REMINDER_ACTIVITY') }}
              </option>
            </coozzy-form-select>
          </div>
          <div class="col">
            <label for="contact">{{ $t('message.calendar.reminder.contact') }}</label>
            <coozzy-form-select
              id="contact"
              v-model="reminder.contactId"
              :disabled="source !== ''"
              name="contact">
              <option
                v-for="element in sortedContactsList"
                :key="element.id"
                :value="element.id">
                {{ element.name }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <label for="subject">{{ $t('message.calendar.reminder.subject') }}*</label>
            <coozzy-form-textarea
              id="subject"
              v-model="reminder.subject"
              :initial="reminder.subject"
              :value-text-area="reminder.subject"
              :text="reminder.subject"
              :show-label="false"
              :check-valide="$v.reminder.subject.$dirty && $v.reminder.subject.$error ? 'is-invalid' : ''"
              :rows="3" />
          </div>
          <div class="col">
            <label for="employee">{{ $t('message.calendar.reminder.employee') }}</label>
            <coozzy-form-select
              id="employee"
              v-model="reminder.employeeUserId"
              name="employee">
              <option :value="null">
                -
              </option>
              <option
                v-for="element in employeeList"
                :key="element.value"
                :value="element.value">
                {{ element.label }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 col-md-4 mb-3 mb-md-0">
            <label>{{ $t('message.calendar.event.date') }}*</label>
            <coozzy-form-input
              id="startDate"
              ref="startDate"
              v-model="onlyDate"
              type="date"
              :state="$v.onlyDate.$dirty && $v.onlyDate.$error ? false : null" />
          </div>
          <div class="col-6 col-md-2 mb-3 mb-md-0">
            <label>{{ $t('message.calendar.event.time') }}</label>
            <coozzy-form-select
              id="startTime"
              v-model="reminder.onlyTime">
              <option
                v-for="t in timeList"
                :key="'start' + t"
                :value="t">
                {{ t }}
              </option>
            </coozzy-form-select>
          </div>
          <div class="col">
            <label for="priority">{{ $t('message.calendar.reminder.priority.title') }}</label>
            <coozzy-form-select
              id="priority"
              v-model="reminder.priority">
              <option value="LOW_REMINDER_PRIORITY">
                {{ $t('message.calendar.reminder.priority.LOW_REMINDER_PRIORITY') }}
              </option>
              <option value="NORMAL_REMINDER_PRIORITY">
                {{ $t('message.calendar.reminder.priority.NORMAL_REMINDER_PRIORITY') }}
              </option>
              <option value="HIGH_REMINDER_PRIORITY">
                {{ $t('message.calendar.reminder.priority.HIGH_REMINDER_PRIORITY') }}
              </option>
            </coozzy-form-select>
          </div>
          <div
            v-if="reminderDetail !== null"
            class="col-12 mt-2">
            <coozzy-form-checkbox
              v-model="done"
              :initial="reminder.done !== '0'"
              name="reminderdone">
              {{ $t('message.calendar.reminder.done') }}
            </coozzy-form-checkbox>
          </div>
        </div>
      </div>
      <div
        class="d-block w-100 mt-3">
        <coozzy-button
          design="green"
          class="float-right ml-3"
          :disabled="loading"
          @click="newReminder">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
        <coozzy-button
          design="default"
          :disabled="loading"
          @click="$bvModal.hide('modal-add-reminder')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import CalendarApi from '@/misc/apis/CalendarApi'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { user } from '@/mixins/user'
import { reminder } from '@/mixins/reminder'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReminderCreationModal',
  components: { CoozzyFormCheckbox, CoozzySpinner, CoozzyButton, CoozzyFormInput, CoozzyFormSelect },
  mixins: [user, reminder],
  props: {
    availableEmployees: {
      type: Array,
      default() {
        return []
      }
    },
    availableContacts: {
      type: Array,
      default() {
        return []
      }
    },
    referencesIds: {
      type: Array,
      default() {
        return []
      }
    },
    source: {
      type: String,
      default: ''
    },
    reminderDetail: {
      type: Object,
      default: null
    },
    contactId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      reminder: {
        activity: 'UNDEFINED_REMINDER_ACTIVITY',
        contactId: this.contactId !== '' ? this.contactId : null,
        subject: '',
        referenceId: '',
        id: null,
        employeeUserId: this.userId,
        date: null,
        onlyTime: '',
        priority: 'NORMAL_REMINDER_PRIORITY',
        creatorUserId: this.userId
      },
      onlyDate: '',
      employeeList: [],
      contactsList: [],
      loading: false,
      timeList: [],
      isFirstLoad: true,
      isChange: false,
      done: false
    }
  },
  computed: {
    getListeEmployee() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees().filter(
          e => !e.deleted
        )
      }
      return []
    },
    sortedContactsList() {
      const array = this.contactsList
      array.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }
        return 0
      })
      return array
    },
    getReminderSubTitle() {
      if (this.source === 'object') {
        return this.$t('message.calendar.reminder.subTitle.object')
      } else if (this.source === 'property') {
        return this.$t('message.calendar.reminder.subTitle.property')
      } else if (this.source === 'building') {
        return this.$t('message.calendar.reminder.subTitle.building')
      } else if (this.source === 'tenancies') {
        return this.$t('message.calendar.reminder.subTitle.tenancies')
      } else if (this.source === 'tenants') {
        return this.$t('message.calendar.reminder.subTitle.tenants')
      } else if (this.source === 'condominium') {
        return this.$t('message.calendar.reminder.subTitle.condominium')
      } else {
        return this.$t('message.calendar.reminder.subTitle.addressbook')
      }
    }
  },
  watch: {
    reminder: {
      deep: true,
      handler: function () {
        this.$store.dispatch('user/setChangedModal', true).catch(e => console.log(e))
      }
    },
    availableContacts: function(newValue) {
      if (newValue.length !== 0) {
        this.contactsList = newValue
      }
    },
    getListeEmployee: function() {
      this.fillEmployees()
    },
    done: {
      deep: true,
      handler: function () {
        if (this.isFirstLoad === false) {
          this.isChange = true
          this.$store.dispatch('user/setChangedModal', true).catch(e => console.log(e))
        }
      }
    }
  },
  mounted: function () {
    this.getListTimes()

    // Set next 15 minutes time slot
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      // Only do that on reminder creation
      if (modalId === 'modal-add-reminder' && this.reminderDetail === null) {
        const today = new Date()
        let minutes = Math.ceil(today.getMinutes() / 15) * 15
        minutes = (minutes === 0 || minutes === 60 ? '00' : minutes)
        const hours = today.getMinutes() > 45 ? today.getHours() + 1 : today.getHours()
        this.reminder.onlyTime = `${hours.toString().padStart(2, '0')}:${minutes}`
        this.onlyDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`
        this.reminder.employeeUserId = this.userId
        this.reminder.creatorUserId = this.userId
        this.reminder.subject = ''
        this.reminder.activity = 'TODO_REMINDER_ACTIVITY'
        this.reminder.priority = 'NORMAL_REMINDER_PRIORITY'
        this.reminder.contactId = this.contactId !== '' ? this.contactId : null

        this.$v.onlyDate.$reset()
        this.$v.reminder.$reset()

        this.$nextTick(function () {
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        })
      }
      this.isFirstLoad = false
    })
    // Load contacts if no ones were provided
    if (this.availableContacts.length > 0) {
      this.contactsList = this.availableContacts
    }
    this.fillEmployees()

    if (this.reminderDetail !== null) {
      this.reminder = JSON.parse(JSON.stringify(this.reminderDetail))
      this.onlyDate = this.formatDateForDateInput(this.reminder.date)
      this.reminder.onlyTime = this.formatTimeForCalendar(this.reminder.date)
      this.done = this.reminderDetail.done === '1'

      this.$nextTick(function () {
        this.$store.dispatch('user/setChangedModal', false).catch(e => console.log(e))
      })
    }
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    fillEmployees() {
      if (!this.availableEmployees.some(att => att.employee === 'All') || (this.availableEmployees.some(att => att.employee === 'All') && this.availableEmployees[0].employees.length === 0) || this.availableEmployees.length === 0) {
        this.loadEmployees()
        this.$nextTick(function () {
          const employees = this.getEmployees()
          const array = []
          if (employees) {
            for (const employee of employees) {
              if (employee && employee.profile && !employee.blocked) {
                array.push({
                  label: (employee.profile.firstName ? employee.profile.firstName.substr(0, 1) + '. ' : '') + employee.profile.lastName + (employee.deleted ? ` (${this.$t('message.generic.deleted')})` : ''),
                  value: employee.id
                })
              }
            }
          }
          this.employeeList = array
        })
      } else {
        this.availableEmployees.forEach((emp) => {
          if (typeof (emp.employees) !== 'undefined') {
            this.employeeList = emp.employees.filter(x => x.active)
          }
        })
      }
    },
    formatDateForDateInput(date) {
      return this.$options.filters.timestampToDateInput(date)
    },
    formatTimeForCalendar(time) {
      const date = new Date(time * 1000)
      return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    },
    getListTimes() {
      let hours, minutes
      const list = []
      for (let i = 0; i <= 1425; i += 15) {
        hours = Math.floor(i / 60)
        minutes = i % 60
        if (minutes < 10) {
          minutes = '0' + minutes // adding leading zero
        }
        if (hours < 10) {
          hours = '0' + hours // adding leading zero
        }
        if (hours === 24) {
          hours = '00'
        }
        list.push(hours + ':' + minutes)
      }
      this.timeList = list
    },
    newReminder() {
      this.reminder.subject.trim()
      this.$v.onlyDate.$touch()
      this.$v.reminder.$touch()
      if (!this.$v.reminder.$invalid) {
        this.loading = true
        this.reminder.date = new Date((this.onlyDate + ' ' + this.reminder.onlyTime).replace(/-/g, '/'))
        if (this.reminder.id === null) {
          const promises = []
          if (this.referencesIds.length === 0) {
            promises.push(CalendarApi.createReminder(this.$store.getters['user/getAccountId'], this.reminder, ['']))
          } else {
            promises.push(CalendarApi.createBulkReminder(this.$store.getters['user/getAccountId'], this.reminder, this.referencesIds))
          }
          Promise.all(promises)
            .then((response) => {
              if (this.isChange) {
                this.markeAsDone()
              }
              this.$emit('new-reminder', response[0].reminder)
              Vue.toasted.show(this.$t('message.successMessages.reminderCreation'), { type: 'success' })
              if (this.source === 'message') {
                this.$emit('refresh-list')
              }
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.updateReminder'), { type: 'error' })
            })
            .finally(() => {
              this.loading = false
              this.getCountOverdueReminders()
              this.$store.dispatch('user/setChangedModal', false).finally(() => {
                this.$refs['modal-add-reminder']?.hide()
              })
            })
        } else {
          this.reminder.date = Math.trunc(this.reminder.date.getTime() / 1000)
          CalendarApi.updateReminder(this.reminder)
            .then(() => {
              if (this.isChange) {
                this.markeAsDone()
              }
              this.$emit('update-reminder', this.reminder)
              this.loading = false
              this.$store.dispatch('user/setChangedModal', false).finally(() => {
                this.$refs['modal-add-reminder']?.hide()
              })
              Vue.toasted.show(this.$t('message.successMessages.reminderUpdated'), { type: 'success' })
            })
            .catch(e => {
              this.loading = false
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.updateReminder'), { type: 'error' })
            })
            .finally(() => {
              this.getCountOverdueReminders()
            })
        }
      }
    },
    show() {
      this.$refs['modal-add-reminder'].show()
    },
    markeAsDone() {
      if (this.done === false) {
        CalendarApi.setUnDone([this.reminder.id])
          .then(() => {
            this.$store.dispatch('user/setChangedModal', false).catch(e => console.log(e))
            this.isChange = false
            this.reminder.done = '0'
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.updateReminder'), { type: 'error' })
          })
      } else {
        CalendarApi.setDone([this.reminder.id])
          .then(() => {
            this.$store.dispatch('user/setChangedModal', false).catch(e => console.log(e))
            this.isChange = false
            this.reminder.done = '1'
            this.$emit('update-reminder', this.reminder)
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.updateReminder'), { type: 'error' })
          })
      }
    }
  },
  validations: {
    reminder: {
      subject: {
        required
      }
    },
    onlyDate: {
      required
    }
  }
}
</script>
<style lang="scss" scoped>
  .reminderSubTitle {
    display: block;
    color: #333;
    font-size: 15px;
  }
</style>
