<template>
  <section>
    <coozzy-page-title :title="$t('message.userRequestDetails.title')" />
    <div>
      <coozzy-card>
        <application-form-object-view
          :request-id="id" />
      </coozzy-card>
    </div>
    <div class="row mt-2">
      <div class="col">
        <b-table
          ref="messageTable"
          bordered
          hover
          selectable
          select-mode="single"
          :busy="isBusy"
          :fields="fields"
          :items="messageList"
          :tbody-tr-class="rowClass"
          @row-clicked="onRowClicked">
          <!-- Busy state -->
          <div
            slot="table-busy"
            class="text-center text-danger my-2">
            <coozzy-spinner />
          </div>

          <!-- Headings -->
          <template
            #head(date)>
            <coozzy-sort-dropdown
              sorting-field="date"
              :text="$t('message.tableColumnHeadings.date' )"
              :asc-desc-only="true"
              :initial-sorting="'desc'"
              @updateSorting="updateSorting" />
          </template>
          <template
            #head(from)>
            <span>{{ $t('message.tableColumnHeadings.from') }}</span>
          </template>
          <template
            #head(to)>
            <span>{{ $t('message.tableColumnHeadings.to') }}</span>
          </template>
          <template
            #head(subject)>
            <span>{{ $t('message.tableColumnHeadings.subject') }}</span>
          </template>
          <template
            #head(message)>
            <span>{{ $t('message.tableColumnHeadings.message') }}</span>
          </template>

          <!-- Data -->
          <template #cell(date)="data">
            <span>{{ data.item.timestamp | timestampToDateTime }}</span>
          </template>

          <template #cell(from)="data">
            <template
              v-if="getContactReference(data.item) && getContactReference(data.item).direction === 'from'">
              <span v-if="getContactReference(data.item).companyName">
                {{ getContactReference(data.item).companyName }}<br>
              </span>
              <span>
                {{ getContactReference(data.item).contactName }}<br>
              </span>
              <span>
                {{ getContactReference(data.item).email }}
              </span>
            </template>
            <template
              v-else>
              <span>{{ data.item.from.name }}</span><br>
              <span>{{ data.item.from.email }}</span>
            </template>
          </template>

          <template #cell(to)="data">
            <template
              v-if="getContactReference(data.item) && getContactReference(data.item).direction === 'to'">
              <span v-if="getContactReference(data.item).companyName">
                {{ getContactReference(data.item).companyName }}<br>
              </span>
              <span>
                {{ getContactReference(data.item).contactName }}<br>
              </span>
              <span>
                {{ getContactReference(data.item).email }}
              </span>
              <span v-if="data.item.to.length > 1">+{{ data.item.to.length - 1 }}</span>
            </template>
            <template
              v-else>
              <span>{{ data.item.to[0].name }}</span><br>
              <span>{{ data.item.to[0].email }} </span>
              <span v-if="data.item.to.length > 1">+{{ data.item.to.length - 1 }}</span>
            </template>
          </template>

          <template #cell(subject)="data">
            <p>
              <coozzy-caret-up-icon
                v-if="mailboxesForCurrentUser.find(obj => obj.outboundEmail === data.item.from.email)"
                class="d-inline" />
              <coozzy-caret-down-icon
                v-else
                class="d-inline" />
              {{ data.item.subject | displayOptionalValue }}
              <coozzy-paper-clip-icon v-if="data.item.attachmentMediaIds.length > 0" />
            </p>
          </template>

          <template #cell(message)="data">
            <p class="text-msg mb-0">
              {{ data.item.bodyText }}
            </p>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          class="mb-3"
          align="center"
          :per-page="nbrPerPage"
          @change="paginateTo" />
      </div>
    </div>
    <fake-send-mail-modal
      v-if="selectedMail"
      ref="fake-send-mail"
      :base-email="selectedMail"
      @send-mail="refreshListing()"
      @hide-mail="refreshListing()" />
    <message-details-modal
      v-if="selectedMail"
      ref="mail-detail-modal"
      :key="selectedMail.id"
      :email="selectedMail"
      :contacts="contacts"
      @reply-mail="replyMail"
      @forward-mail="forwardMail"
      @forward-as-attachement="forwardAsAttachement" />
  </section>
</template>
<script>
import ApplicationFormObjectView from '@/marketingBasic/components/applicationForm/ApplicationFormObjectView'
import RequestApi from '@/marketingBasic/api/RequestApi'
import MessageApi from '@/misc/apis/MessageApi'
import ContactApi from '@/misc/apis/ContactApi'
import MessageDetailsModal from '@/contact/components/MessageDetailsModal'
import FakeSendMailModal from './FakeSendMailModal'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { user } from '@/mixins/user'
import Vue from 'vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzySortDropdown from '@/framework/components/dropdown/CoozzySortDropdown'
import CoozzyCaretUpIcon from '@/framework/components/icons/CoozzyCaretUpIcon'
import CoozzyCaretDownIcon from '@/framework/components/icons/CoozzyCaretDownIcon'
import CoozzyPaperClipIcon from '@/framework/components/icons/CoozzyPaperClipIcon'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import { mail } from '@/mixins/mail'

export default {
  name: 'UserRequestsDetailView',
  components: {
    CoozzyPageTitle,
    CoozzyPaperClipIcon,
    CoozzyCaretDownIcon,
    CoozzyCaretUpIcon,
    CoozzySortDropdown,
    CoozzySpinner,
    CoozzyCard,
    ApplicationFormObjectView,
    MessageDetailsModal,
    FakeSendMailModal
  },
  mixins: [user, mail],
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      objectId: null,
      selectedMail: null,
      messageList: [],
      nbrPerPage: 10,
      currentPage: 1,
      isBusy: true,
      fields: [
        {
          key: 'date',
          label: 'Date',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'from',
          label: 'From',
          thClass: 'align-middle d-none d-md-table-cell',
          tdClass: 'align-middle pr-0 d-none d-md-table-cell'
        },
        {
          key: 'to',
          label: 'To',
          thClass: 'align-middle th-received d-none d-sm-table-cell',
          tdClass: 'd-none d-sm-table-cell'
        },
        {
          key: 'subject',
          label: 'Subject',
          thClass: 'align-middle'
        },
        {
          key: 'message',
          label: 'Message',
          thClass: 'align-middle message-text'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails', 'getMailboxesForUserId']),
    mailboxesForCurrentUser() {
      return this.getMailboxesForUserId(this.userId)
    }
  },
  mounted: function () {
    RequestApi.getListSent(this.$store.getters['user/getAccountId'])
      .then(response => {
        this.objectId = response.requests.find(obj => this.id === obj.id).objectId
      })
    this.filterMessages(1)
  },
  methods: {
    ...mapActions('message', ['loadMailboxesForUserId']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailHasContext',
      'setSubject',
      'setEmailToForwardAsAttachment'
    ]),
    filterMessages() {
      this.selectedEntries = []
      this.isBusy = true
      MessageApi.listMailsByReferenceIds([this.id])
        .then(async response => {
          this.messageList = response.mails
          this.loadContacts()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.message'), { type: 'error' })
        })
    },
    refreshListing() {
      this.filterMessages(1)
      this.selectedMail = null
    },
    loadContacts() {
      ContactApi.contactResolve(
        this.messageList.map(mail => mail.referenceIds).flat().filter(id => id.includes('contact_'))
      ).then(response => {
        this.contacts = response.persons.concat(response.companies)
      }).catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
      }).finally(() => {
        this.isBusy = false
      })
    },
    getDirection(mail) {
      return this.mailboxesForCurrentUser.find(obj => obj.outboundEmail === mail.from.email) ? 'to' : 'from'
    },
    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.readEntries.some(element => parseInt(element.accountId) === parseInt(this.accountId))) {
          return ''
        } else {
          return 'unread-mail'
        }
      } else {
        return null
      }
    },
    getContactReference(mail) {
      const direction = this.getDirection(mail)
      const email = direction === 'to' ? mail.to[0].email : mail.from.email

      // Search for contact data
      for (const contact of this.contacts) {
        if (contact.email === email) {
          return {
            direction: direction,
            email: email,
            contactId: contact.id,
            contactName: contact.name
          }
        }
        if (contact.contactPersons && contact.contactPersons.length > 0) {
          for (const contactPerson of contact.contactPersons) {
            if (contactPerson.email === email) {
              return {
                direction: direction,
                email: email,
                contactId: contact.id,
                companyName: contact.name,
                contactName: contactPerson.name
              }
            }
          }
        }
      }
    },
    onRowClicked(item) {
      this.showDetail(item)
    },
    paginateTo(page) {
      this.filterMessages(page)
    },
    replyMail() {
      this.$refs['fake-send-mail'].show()
    },
    showDetail(mail) {
      this.selectedMail = mail
      if (!mail.readEntries.some(element => element.accountId === this.accountId)) {
        MessageApi.mailMarkRead([mail.id], this.accountId)
          .then(() => {
            for (let index = 0; index < this.messageList.length; index++) {
              if (this.messageList[index].id === mail.id) {
                this.messageList[index].readEntries.push({ accountId: this.accountId })
              }
            }
          })
          .catch(e => {
            console.log(e)
          })
      }
      this.$nextTick(function () {
        this.$refs['mail-detail-modal'].show()
      })
    },
    updateSorting(value) {
      console.log(value)
      if (value === 'desc') {
        this.sortReceivedAt = 'DESC'
      } else if (value === 'asc') {
        this.sortReceivedAt = 'ASC'
      } else {
        this.sortReceivedAt = 'UNDEFINED_SORT_ORDER'
      }
      this.filterMessages(1)
    },
    forwardMail(email) {
      this.prepareForwardMail(email)
    },
    forwardAsAttachement(mediaList, email) {
      this.setSubject('Fwd: ' + email.subject)
      this.setSendMailHasContext(true)
      this.setSendMailContacts([])
      this.setEmailToForwardAsAttachment(mediaList)
      this.$nextTick(function () {
        if (this.getMailboxesForUserId(this.userId).length === 0) {
          this.$nextTick(function () {
            Vue.toasted.show(this.$t('message.settings.mailbox.configMailboxToast'), { type: 'error' })
          })
        } else {
          this.callShowSendMailModal()
        }
      })
    }
  }
}
</script>
