<template>
  <app-header
    v-if="isAuthenticated"
    fixed
    class="pr-3 p-0">
    <!-- Menu toggler -->
    <div :class="[isUserModule ? 'user-header' : '', 'header-left']">
      <div
        v-if="displayLogo"
        class="d-none d-md-inline-block pl-3"
        @dblclick="enableTracking">
        <img
          v-if="showCompanyLogo && stateLogoUrl"
          class="navbar-brand-full"
          :src="stateLogoUrl"
          alt="PROPbase"
          @error="onErrorImage()">
        <img
          v-else
          class="navbar-brand-full"
          src="/logo.png"
          alt="PROPbase">
      </div>
      <sidebar-toggler
        ref="mobile-toggler"
        class="d-lg-none"
        display="md"
        mobile />
      <sidebar-toggler
        v-if="!hideNavigation()"
        ref="sidebar-toggler"
        :default-open="!isSideBarClosed"
        class="d-md-down-none float-right shadow-none"
        display="lg"
        @click.native="togglerClicked" />
    </div>
    <!-- Modules -->
    <b-navbar-nav
      v-if="isAuthenticated"
      class="mr-auto h-100">
      <b-nav-item
        v-if="hasAccessPropBase"
        class="item-header border-right h-100"
        :class="[$route.path.startsWith('/owner') > 0 ? 'bg-white' : '']"
        :to="{name: 'OwnerDashboardOverview'}">
        <img
          class="d-none d-lg-block mx-2 mt-1"
          height="30px"
          src="/img/owner-base.png"
          alt="owner-base">
        <img
          class="d-block d-lg-none mx-2 mt-1"
          height="30px"
          src="/img/propbase-icon.png"
          alt="owner-base">
      </b-nav-item>
      <b-nav-item
        v-if="hasAccessAssetBase"
        class="item-header border-right h-100"
        :class="[isAssetModule ? 'bg-white' : '']"
        :to="{name: 'AssetDashboardView'}">
        <img
          class="d-none d-lg-block mx-2 mt-1"
          height="30px"
          src="/img/asset-base.png"
          alt="asset-base">
        <img
          class="d-block d-lg-none mx-2 mt-1"
          height="30px"
          src="/img/assetbase-icon.png"
          alt="asset-base">
      </b-nav-item>
      <b-nav-item
        v-if="hasAccessAccountingBase"
        class="item-header border-right h-100"
        :class="[$route.path.startsWith('/accounting') > 0 ? 'bg-white' : '']"
        :to="{name: 'AccountingDashboardView'}">
        <img
          class="d-none d-lg-block mx-2 mt-1"
          height="30px"
          src="/img/finance-base.png"
          alt="finance-base">
        <img
          class="d-block d-lg-none mx-2 mt-1"
          height="30px"
          src="/img/financebase-icon.png"
          alt="financebase-base">
      </b-nav-item>
      <b-nav-item
        v-if="hasAccessAdminBase"
        class="item-header border-right h-100"
        :class="[$route.path.startsWith('/admin') > 0 ? 'bg-white' : '']"
        :to="{name: 'AdminDashboardView'}">
        <img
          class="d-none d-lg-block mx-2 mt-1"
          height="30px"
          src="/img/admin-base.png"
          alt="admin-base">
        <img
          class="d-block d-lg-none mx-2 mt-1"
          height="30px"
          src="/img/adminbase-icon.png"
          alt="admin-base">
      </b-nav-item>
      <b-nav-item
        v-if="hasAccessMarketBase"
        class="item-header border-right h-100"
        :class="[$route.path.startsWith('/marketing') > 0 ? 'bg-white' : '']"
        :to="{name: 'MarketingDashboardView'}">
        <img
          class="d-none d-lg-block mx-2 mt-1"
          height="30px"
          src="/img/market-base.png"
          alt="market-base">
        <img
          class="d-block d-lg-none mx-2 mt-1"
          height="30px"
          src="/img/marketbase-icon.png"
          alt="market-base">
      </b-nav-item>
      <b-nav-item
        v-if="hasAccessUserBase"
        class="item-header border-right h-100"
        :class="[$route.path.startsWith('/user') > 0 ? 'bg-white' : '']"
        :to="{name: 'AdminDashboardView'}">
        <img
          class="mx-2 mt-1"
          height="30px"
          src="/img/userbase.png"
          alt="admin-base">
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <!-- Contract errors -->
      <b-nav-item
        v-if="isPlatformEmployee && isCompanyAdmin && hasContractError"
        class="d-none d-md-block item-header border-right h-100 mr-1 pb-2 pr-1"
        :to="{ name: 'PlatformContractErrorsView'}">
        <coozzy-warning-icon
          v-if="hasContractError"
          class="mr-2" />
        {{ $t('message.navigation.contractErrors') }}
      </b-nav-item>
      <!-- Logs -->
      <b-nav-item
        v-if="hasLogs && (hasAccessAccountingBase || hasAccessAdminBase)"
        class="d-none d-md-block item-header border-right h-100 mr-1 pb-2 pr-1"
        :to="{name: isAdminModule ? 'AdminLogFilesView' : isOwnerModule ? 'OwnerLogFilesView' : isAccountingModule ? 'AccountingLogFilesView' : isAssetModule ? 'AssetLogFilesView' : 'MarketingLogFilesView' }">
        <coozzy-warning-icon
          v-if="hasLogsErrors"
          class="mr-2" />
        {{ $t('message.navigation.logFiles') }}
      </b-nav-item>
      <!-- Profile picture -->
      <b-nav-item
        v-if="isAuthenticated">
        <b-nav-item-dropdown
          id="dropdown-1"
          class="user-dropdown">
          <template slot="button-content">
            <img
              :class="['align-self-center', 'avatar-image']"
              :src="avatar"
              alt="avatar">
            <span
              class="d-none d-lg-inline-block mx-2 align-middle font-weight-bold text-truncate text-grey-dark"
              style="max-width: 138px">
              {{ fullName }}
            </span>
          </template>
          <b-nav-item-dropdown
            id="profile-dropdown"
            class="lang-dropdown"
            dropleft>
            <template slot="button-content">
              <b-dropdown-item>
                {{ currentLanguage.toUpperCase() }}
              </b-dropdown-item>
            </template>
            <b-dropdown-item @click="changeLanguage('en')">
              <a>{{ $t('message.generic.english') }}</a>
            </b-dropdown-item>
            <b-dropdown-item @click="changeLanguage('de')">
              <a>{{ $t('message.generic.german') }}</a>
            </b-dropdown-item>
            <b-dropdown-item @click="changeLanguage('it')">
              <a>{{ $t('message.generic.italian') }}</a>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-dropdown-divider
            v-if="hasLogs"
            class="d-block d-md-none" />
          <b-dropdown-item
            v-if="hasLogs"
            class="d-block d-md-none"
            @click="$router.push({name: isAdminModule ? 'AdminLogFilesView' : isOwnerModule ? 'OwnerLogFilesView' : isAccountingModule ? 'AccountingLogFilesView' : isAssetModule ? 'AssetLogFilesView' : 'MarketingLogFilesView' })">
            Log Files
          </b-dropdown-item>
          <template v-if="isPlatformEmployee">
            <b-dropdown-divider />
            <b-dropdown-item :to="{ name: 'AccountsView' }">
              Kunden
            </b-dropdown-item>
          </template>
          <b-dropdown-divider />
          <b-dropdown-item @click="logout()">
            {{ $t('message.header.logout') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav-item>
      <!-- Notification -->
      <b-nav-item
        v-if="isAuthenticated && !hasAccessUserBase"
        class="d-md-block mr-0 mr-md-3">
        <b-nav-item-dropdown
          id="dropdown-1"
          class="user-dropdown">
          <template slot="button-content">
            <img
              class="notification-image"
              src="@/assets/icon/notification.svg"
              alt="avatar">
            <span
              v-if="countUnread"
              class="dot">
              <small v-if="countUnread >= 10">
                +9
              </small>
              <small
                v-else
                style="margin-left: 0.1em">
                {{ countUnread }}
              </small>
            </span>
          </template>
          <b-dropdown-item
            class="notification-items"
            href="javascript:;">
            <coozzy-notification
              ref="notifications"
              @count-unread="updateCountUnread"
              @event-reminder-list="updateListEventReminder" />
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav-item>
    </b-navbar-nav>
    <b-toast
      v-for="(item, index) in computedListEventReminder"
      :id="'example-toast-' + index"
      :key="index"
      ref="toasts"
      class="toast-events"
      no-auto-hide
      :style="{ 'margin-top': `${60 + index * 10}px`, 'display': 'none' }"
      @hidden="closeToast(item, index)">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <img
            :src="baseLogo !== '' ? baseLogo : '/avatar.png'"
            alt=""
            class="notification-system-image">
        </div>
      </template>
      <div @click="onToastClick(index)">
        {{ $t('message.notifications.messages.eventReminder', { title: item.eventTitle, startDate: getDateFormat(item.eventStartDate), startTime: item.eventStarttime?.array[0] + ':' + (item.eventStarttime?.array[1] ? item.eventStarttime?.array[1] || '00' : '00') }), }}
      </div>
    </b-toast>
  </app-header>
  <div
    v-else-if="!$route.path.startsWith('/login') && !$route.path.startsWith('/callback') && publicRoutes.findIndex(r => $route.path.startsWith(r)) !== -1"
    class="container-fluid">
    <header
      class="row px-2 top-menu-header"
      :class="['/object', '/register'].findIndex(r => $route.path.startsWith(r)) !== -1 ? 'application-form-header' : ''">
      <div class="col-1 pt-3 pb-2 pl-4 d-flex align-items-center toggle-menu">
        <a @click="hideMenu = !hideMenu">
          <coozzy-bars-icon />
        </a>
      </div>
      <div class="col-md-2 col-2 pt-2 pb-2 pl-4 d-flex align-items-center">
        <a
          v-if="['/object', '/register'].findIndex(r => $route.path.startsWith(r)) === -1"
          href="https://propbase.ch"
          class="company-logo-link">
          <img
            class="company-logo mt-2 mb-1"
            src="/logo.png"
            alt="PROPbase">
        </a>
        <a
          v-else
          href="https://propbase.ch"
          class="company-logo-link">
          <img
            class="company-logo mt-2 mb-1"
            src="/img/userbase.png"
            alt="PROPbase">
        </a>
      </div>
      <div
        class="col pr-0"
        :class="isAuthenticated ? '': hideMenu ? 'responsive-menu d-none d-sm-none d-md-block mr-4': 'responsive-menu mr-4' ">
        <div class="row justify-content-end sub-responsive-menu pt-3">
          <div
            v-if="isAuthenticated"
            class="d-none d-md-flex col-auto align-items-center">
            <img
              :class="['align-self-center', 'avatar-image']"
              :src="avatar"
              alt="avatar">
            <span class="d-none d-md-inline-block align-middle font-weight-bold text-capital">
              {{ fullName }}
            </span>
          </div>
          <div
            v-if="isAuthenticated"
            class="col-auto px-md-0 pr-md-4 d-flex align-items-center">
            <coozzy-button
              design="transparent"
              @click="logout()">
              {{ $t('message.header.logout') }}
              <coozzy-logout-icon />
            </coozzy-button>
          </div>
          <div
            v-else-if="$route.name !== 'TicketPublicView' && $route.name !== 'ApplicationFormLandingPage'"
            class="col-auto px-md-0 pr-md-4 d-flex">
            <span class="d-sm-none d-md-block font-weight-bold mr-4">
              {{ $t('message.header.haveAccountMessage') }}
            </span>
            <coozzy-button
              design="green"
              class="px-4"
              @click="login()">
              {{ $t('message.header.login') }}
            </coozzy-button>
          </div>
        </div>
      </div>
      <div class="col-2 col-md-1 pl-0 select-language-div">
        <div class="row justify-content-end pt-1">
          <div
            class="col-auto py-2 pl-0 select-language"
            :class="$route.name === 'TicketPublicView' ? 'adjustPadding' : 'pr-3'">
            <coozzy-form-select
              :value="currentLanguage"
              @change="changeLanguage">
              <option value="en">
                {{ $t('message.generic.english') }}
              </option>
              <option value="de">
                {{ $t('message.generic.german') }}
              </option>
              <option value="it">
                {{ $t('message.generic.italian') }}
              </option>
              <option
                v-if="$route.name === 'ApplicationFormLandingPage'"
                value="fr">
                {{ $t('message.generic.french') }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { Header as AppHeader, SidebarToggler } from '@coreui/vue'
import { hideNavigation } from '@/mixins/hideNavigation'
import { logout } from '@/mixins/logout'
import { user } from '@/mixins/user'
import UserApi from '../../misc/apis/UserApi'
import ObjectApi from '../../misc/apis/ObjectApi'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyBarsIcon from '@/framework/components/icons/CoozzyBarsIcon'
import CoozzyLogoutIcon from '@/framework/components/icons/CoozzyLogoutIcon'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyNotification from '@/framework/layout/CoozzyNotification'
import CoozzyWarningIcon from '@/framework/components/icons/CoozzyWarningIcon'
import { tracking } from '@/mixins/tracking'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CoozzyHeader',
  components: {
    CoozzyNotification,
    CoozzyWarningIcon,
    CoozzyLogoutIcon,
    CoozzyBarsIcon,
    CoozzyFormSelect,
    CoozzyButton,
    AppHeader,
    SidebarToggler
  },
  mixins: [tracking, routeChecks, user, hideNavigation, logout],
  data() {
    return {
      isProfileDropdownVisible: false,
      userProfile: null,
      hasLogs: false,
      hasLogsErrors: false,
      hasContractError: false,
      showCompanyLogo: true,
      logoInterval: null,
      loadingInProgress: false,
      hideMenu: true,
      loading: {
        login: false,
        registration: false
      },
      publicRoutes: [
        '/callback',
        '/login',
        '/register',
        '/application-form',
        '/help',
        '/about-us',
        '/tos',
        '/privacy-policy',
        '/imprint',
        '/cookies',
        '/object',
        '/ticket/'
      ],
      countUnread: 0,
      listEventReminder: [],
      logsInterval: null,
      contractInterval: null
    }
  },
  computed: {
    computedListEventReminder() {
      return this.listEventReminder
    },
    isSideBarClosed() {
      return localStorage.getItem('is-side-bar-closed') && localStorage.getItem('is-side-bar-closed') === 'true'
    },
    displayLogo() {
      // Do not display the logo on UserBase routes + ApplicationFormLandingPage
      return !this.$route.path.startsWith('/user') && this.$route.name !== 'ApplicationFormLandingPage'
    },
    avatar() {
      if (this.getEmployees().length > 0) {
        const user = this.getEmployees().filter(x => !x.blocked).find(x => x.id === this.userId)
        return user?.profile?.pictureUrl || '/avatar.png'
      } else {
        return '/avatar.png'
      }
    },
    baseLogo() {
      return `${process.env.VUE_APP_BASE_API_URL}/img/prop-base.png`
    },
    fullName() {
      const profile = this.$store.getters['user/getCurrentUser']
      if (profile) {
        return `${profile['https://coozzy.ch/firstName'].substr(0, 1)}. ${profile['https://coozzy.ch/lastName']}`
      } else {
        return ''
      }
    },
    stateLogoUrl() {
      const stateLogoUrl = this.$store.getters['user/getLogoUrl']
      if (stateLogoUrl) {
        return stateLogoUrl
      } else {
        return null
      }
    },
    subType() {
      const subType = this.$store.getters['user/getCurrentUser']
      if (subType) {
        return subType['https://coozzy.ch/subType']
      } else {
        return ''
      }
    },
    getAccountId() {
      return this.$store.getters['user/getAccountId'] || null
    }
  },
  watch: {
    getAccountId: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.updateLogsErrors()
        this.updateContractError()
      }
    },
    computedListEventReminder(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        for (let index = 0; index < newVal.length; index++) {
          setTimeout(() => {
            this.$bvToast.show('example-toast-' + index)
          }, 2000 * index)
        }
      }
    }
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'profile-dropdown') {
        this.isProfileDropdownVisible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'profile-dropdown') {
        this.isProfileDropdownVisible = false
      }
      if (this.isProfileDropdownVisible) {
        bvEvent.preventDefault()
      }
    })
    this.logoInterval = setInterval(async () => {
      if (this.accountId && !this.loadingInProgress) {
        this.loadingInProgress = true
        await this.loadEmployees()
        UserApi.getAccount(this.accountId)
          .then(response => {
            this.$store.dispatch('user/setLogoUrl', response.account.logoUrl).then().catch(e => console.log(e))
            clearInterval(this.logoInterval)
            this.logoInterval = null
          })
      }
    }, 100)
    this.logsInterval = setInterval(() => this.updateLogsErrors(), 60000)
    this.contractErrorsInterval = setInterval(() => this.updateContractError(), 60000)
  },
  beforeDestroy() {
    clearInterval(this.logsInterval)
    clearInterval(this.contractErrorsInterval)
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    updateLogsErrors() {
      if (this.accountId) {
        ObjectApi.getLogs(this.accountId, 1, 1)
          .then(response => {
            this.hasLogs = response.logEntries.length > 0
            this.hasLogsErrors = response.logEntries.length > 0 && (response.logEntries.filter(x => !x.success)).length > 0
          })
          .catch(e => {
            console.error(e)
          })
      }
    },
    updateContractError() {
      if (this.isPlatformEmployee && this.isCompanyAdmin) {
        ObjectApi.listAllContractErrors()
          .then(response => {
            this.hasContractError = response.errors.length > 0
          })
          .catch(e => {
            console.error(e)
          })
      }
    },
    updateCountUnread(countUnread) {
      this.countUnread = countUnread
    },
    updateListEventReminder(listEventReminder) {
      this.listEventReminder = listEventReminder
    },
    onToastClick(index) {
      this.$refs.notifications.notificationClicked(this.listEventReminder[index])
      this.$router.replace({ name: this.isAdminModule ? 'AdminCalendarOverview' : this.isOwnerModule ? 'OwnerCalendarOverview' : this.isAccountingModule ? 'AccountingCalendarOverview' : this.isAssetModule ? 'AssetCalendarOverview' : 'MarketingCalendarOverview', params: { viewCalendar: 'week' }, query: { elementSelected: this.listEventReminder[index].eventId } })
      this.listEventReminder = this.listEventReminder.filter(x => x.eventId !== this.listEventReminder[index].eventId)
    },
    closeToast(notification, index) {
      this.$refs.notifications.notificationClicked(notification, true)
      this.$refs.toasts[index].hide()
      this.listEventReminder = this.listEventReminder.filter(x => x.eventId !== this.listEventReminder[index].eventId)
    },
    getDateFormat(dateArray) {
      return dateArray.array[2] + '.' + dateArray.array[1] + '.' + dateArray.array[0]
    },
    changeLanguage(language) {
      this.$store.dispatch('internationalization/changeCurrentLanguage', language).then()
    },
    togglerClicked() {
      localStorage.setItem('is-side-bar-closed', (!(localStorage.getItem('is-side-bar-closed') && localStorage.getItem('is-side-bar-closed') === 'true')).toString())
    },
    login() {
      this.loading.login = true
      this.$auth.login('', { language: this.currentLanguage })
      this.loading.login = false
    },
    onErrorImage() {
      this.showCompanyLogo = false
    },
    register() {
      this.loading.registration = true
      this.$auth.login('signUp', { language: this.currentLanguage })
      this.loading.registration = false
    },
    searchLink() {
      window.location.href = process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        (this.$store.getters['internationalization/getCurrentLanguage'] + '/search')
    }
  }
}
</script>

<style lang="scss" scoped>
.user-dropdown {
  :deep(.dropdown-item) {
    display: flex;
    align-items: center;
    text-decoration: none !important;

    &:hover {
      text-decoration: none;
      background-color: #f8f9fa;
    }
  }

  :deep(.dropdown-toggle) {
    &:after {
      display: none;
    }
  }

}

.lang-dropdown {
  :deep(.dropdown-item) {
    display: flex;
    align-items: center;
    text-decoration: none !important;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
        background-color: #f8f9fa;
      }
    }
  }

  :deep(.dropdown-toggle) {
    &::before {
      display: none;
    }

    &:hover {
      text-decoration: none !important;
    }
  }

}

.header-left {
  background: #fff;
  padding: 10px 0;
  max-height: 54px;

  @media (min-width: 768px) {
    width: 200px;
  }
}

.top-menu-header {
  line-height: 41px;
  background-color: $color-text-white;

  .company-logo {
    max-width: 150px;
    max-height: 40px;
  }

  :deep(.vertical-ruler) {
    height: 15px;
    border-right: 2px solid $color-grey-dark;
  }
}

span {
  font-size: 13px;
  letter-spacing: 1px;
  font-family: 'Lato', sans-serif !important;
}

.toggle-menu {
  display: none !important;
}

@media (max-width: 768px) {
  .company-logo-link {
    margin: auto;
  }
  .toggle-menu {
    display: block !important;
  }
  .responsive-menu {
    position: absolute;
    top: 66px;
    left: 0;
    z-index: 1;

    .sub-responsive-menu {
      padding-bottom: 1rem;
    }

    > .row {
      background: white;
      width: 150px;
      justify-content: flex-start !important;

      button {
        padding: 8px 15px;
      }
    }
  }
  .select-language-div {
    position: absolute;
    right: 10px;
  }
  .d-sm-none {
    display: none !important;
  }
}

.navbar-brand-full, .company-logo {
  width: auto;
  height: auto;
  max-width: 89px;
  max-height: 38px;
}

.app-header {
  position: fixed !important;
  background-color: $color-bg-grey;

  :deep(.dropdown-menu) {
    padding-top: 0;
    padding-bottom: 0;
  }

  :deep(.dropdown-divider) {
    margin: 0;
  }
}

.border-right {
  border-right: 1px solid #666;
}

.item-header {
  :deep(.nav-link) {
    height: inherit;
    padding-top: 0.5rem !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}

.bg-white {
  background-color: $color-bg-white;
}

.application-form-header {
  background: #eee;
  border-bottom: 1px solid #c8ced3;
}

.user-header {
  width: 200px !important;
}

@media (max-width: 996px) {
  .user-header {
    width: auto !important;
  }
}

.avatar-image {
  border-radius: 50%;
  max-width: 40px;
}

.notification-image {
  margin-left: 10px;
  width: 40px;
  height: 40px;
}

.notification-items {
  min-width: 350px;
  max-width: 500px;
  :deep(.dropdown-item) {
    padding: 2px;
    cursor: initial;
    color: #16181b !important;
    background-color: transparent !important;
  }
}

.dot {
  position: absolute;
  right: 0;
  top: 0;
  width: 1.3em;
  height: 1.3em;
  line-height: 1.2em;
  border-radius: 0.65em;
  background: red;
  color: white;
  display: inline-block;
  text-align: center;
}
.notification-system-image {
  max-width: 100px;
}
.adjustPadding {
  padding-right: 2.5rem;
}
</style>
