<template>
  <div class="row">
    <div class="col-12">
      <h5>{{ $t('message.generic.contactPerson') }}</h5>
    </div>
    <!-- contactPerson -->
    <!-- Gender -->
    <div class="col-12">
      <label class="d-block">{{ $t('message.generic.title.title') }} {{ sending ? '*' : '' }}</label>
      <coozzy-form-radio
        v-model="company.contactPerson.gender"
        name="gender"
        class="mr-3"
        select-value="MALE"
        :state="$v.gender.$dirty && $v.gender.$error ? false : null">
        {{ $t('message.generic.title.mr') }}
      </coozzy-form-radio>
      <coozzy-form-radio
        v-model="company.contactPerson.gender"
        name="gender"
        class="mr-3"
        select-value="FEMALE">
        {{ $t('message.generic.title.mrs') }}
      </coozzy-form-radio>
      <coozzy-form-radio
        v-model="company.contactPerson.gender"
        name="gender"
        class="mr-3"
        select-value="OTHER">
        {{ $t('message.generic.title.divers') }}
      </coozzy-form-radio>
    </div>
    <!-- Name -->
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="company.contactPerson.firstName"
        :name="$t('message.generic.firstName') + (sending ? '*' : '' )"
        :state="$v.firstName.$dirty && $v.firstName.$error ? false : null"
        type="text" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="company.contactPerson.lastName"
        :name="$t('message.generic.lastName') + (sending ? '*' : '' )"
        :state="$v.lastName.$dirty && $v.lastName.$error ? false : null"
        type="text" />
    </div>
    <div class="col-sm-12 col-md-6 mb-3">
      <coozzy-form-input
        v-model="company.contactPerson.position"
        :name="$t('message.generic.jobTitle')+ (sending ? '*' : '' )"
        :state="$v.position.$dirty && $v.position.$error ? false : null" />
    </div>
    <!-- Contact info (phone, email) -->
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="company.contactPerson.email"
        :name="$t('message.generic.email') + (sending ? '*' : '' )"
        :state="$v.email.$dirty && $v.email.$error ? false : null"
        type="text" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="company.contactPerson.phoneNumber"
        :name="$t('message.generic.phone.home') + (sending ? '*' : '' )"
        :state="$v.phoneNumber.$dirty && $v.phoneNumber.$error ? false : null"
        type="text" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="company.contactPerson.mobileNumber"
        :name="$t('message.generic.phone.mobile')"
        type="text" />
    </div>
    <div class="col-sm-12 col-md-6">
      <coozzy-form-input
        v-model="company.contactPerson.fax"
        type="tel"
        :name="$t('message.generic.phone.fax')" />
    </div>
  </div>
</template>

<script>
import CoozzyFormRadio from '@/framework/components/form/radio/CoozzyFormRadio'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import { mapMutations } from 'vuex'
import { validation } from '@/mixins/validation'
import { required, email } from 'vuelidate/lib/validators'
import * as Sentry from '@sentry/vue'

export default {
  name: 'ApplicationFormCompanyContactPerson',
  components: { CoozzyFormInput, CoozzyFormRadio },
  mixins: [validation],
  props: {
    company: {
      type: Object,
      default: null
    },
    sending: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dates: {
        dob: null
      }
    }
  },
  computed: {
    gender: {
      get() {
        return this.company.contactPerson.gender
      },
      set(value) {
        const com = this.company
        com.contactPerson.gender = value
        this.updateCompany(com)
      }
    },
    firstName: {
      get() {
        return this.company.contactPerson.firstName
      },
      set(value) {
        const com = this.company
        com.contactPerson.firstName = value
        this.updateCompany(com)
      }
    },
    lastName: {
      get() {
        return this.company.contactPerson.lastName
      },
      set(value) {
        const com = this.company
        com.contactPerson.lastName = value
        this.updateCompany(com)
      }
    },
    email: {
      get() {
        return this.company.contactPerson.email
      },
      set(value) {
        const com = this.company
        com.contactPerson.email = value
        this.updateCompany(com)
      }
    },
    phoneNumber: {
      get() {
        return this.company.contactPerson.phoneNumber
      },
      set(value) {
        const com = this.company
        com.contactPerson.phoneNumber = value
        this.updateCompany(com)
      }
    },
    mobileNumber: {
      get() {
        return this.company.contactPerson.mobileNumber
      },
      set(value) {
        const com = this.company
        com.contactPerson.mobileNumber = value
        this.updateCompany(com)
      }
    },
    fax: {
      get() {
        return this.company.contactPerson.fax
      },
      set(value) {
        const com = this.company
        com.contactPerson.fax = value
        this.updateCompany(com)
      }
    },
    position: {
      get() {
        return this.company.contactPerson.position
      },
      set(value) {
        const com = this.company
        com.contactPerson.position = value
        this.updateCompany(com)
      }
    }
  },
  mounted() {
    this.dates.dob = this.$options.filters.timestampToDateInput(this.dob)
  },
  methods: {
    ...mapMutations('applicationForm', ['updateCompany']),
    dateToTimestamp() {
      this.dob = this.toTimestamp(this.dates.dob)
    },
    toTimestamp(strDate) {
      return Math.floor(Date.parse(strDate) / 1000)
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        Sentry.setExtra('companyContactPersonValidation', this.$v)
        this.scrollToError()
        return true
      }
      return false
    },
    isInvalidEmail() {
      this.$v.email.$touch()
      if (this.$v.email.$invalid) {
        Sentry.setExtra('companyContactPersonEmailValidation', this.$v)
        this.scrollToError()
        return true
      }
      return false
    }
  },
  validations: {
    gender: {
      required
    },
    firstName: {
      required
    },
    lastName: {
      required
    },
    email: {
      email,
      required
    },
    phoneNumber: {
      required
    },
    position: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-radio {
  display: inline-block;
}
</style>
