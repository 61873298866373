const generateExcel = {
  data() {
    return {
      defaultFields: [
        { name: 'owner', category: 'property' },
        { name: 'propertyName', category: 'property' },
        { name: 'propertyId', category: 'property' },
        { name: 'buildingName', category: 'building' },
        { name: 'buildingId', category: 'building' },
        { name: 'egid', category: 'building' },
        { name: 'constructionYear', category: 'building' },
        { name: 'street', category: 'building' },
        { name: 'houseNumber', category: 'building' },
        { name: 'zip', category: 'building' },
        { name: 'city', category: 'building' },
        { name: 'numericId', category: 'object' },
        { name: 'objectId', category: 'object' },
        { name: 'objectName', category: 'object' },
        { name: 'category', category: 'object' },
        { name: 'subcategory', category: 'object' },
        { name: 'area', category: 'object' },
        { name: 'rooms', category: 'object' },
        { name: 'floor', category: 'object' },
        { name: 'ewid', category: 'object' },
        { name: 'awn', category: 'object' },
        { name: 'status', category: 'object' },
        { name: 'position', category: 'object' },
        { name: 'salutation', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'name', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'email', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'workEmail', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'mobile', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'phone', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'tenantAddress', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'birthday', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'nationality', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'civilStatus', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'companyName', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'occupation', category: 'tenantOwner', subgroup: 'tenant' },
        { name: 'startOfContract', category: 'tenancy' },
        { name: 'endOfContract', category: 'tenancy' },
        { name: 'rentByDate', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'netRentMonthly', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'netRentPeriod', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'heatingAndAdditionalCosts', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'heatingAndAdditionalCostsPeriod', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'advancePayment', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'advancePaymentPeriod', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'flatRate', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'flatRatePeriod', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'additionalRentComponents', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'additionalRentComponentsPeriod', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'grossRentMonthly', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'grossRentPeriod', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'typeOfUse', category: 'tenancy' },
        { name: 'contractType', category: 'tenancy' },
        { name: 'contractNumber', category: 'tenancy' },
        { name: 'correspondenceAddress', category: 'tenancy' },
        { name: 'guarantor', category: 'tenancy' },
        { name: 'subtenants', category: 'tenancy', subgroup: 'subtenancy' },
        { name: 'subtenancyStart', category: 'tenancy', subgroup: 'subtenancy' },
        { name: 'subtenancyEnd', category: 'tenancy', subgroup: 'subtenancy' },
        { name: 'netRentYearly', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'netRentM2Yearly', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'grossRentYearly', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'tax', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'taxNetRent', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'taxHeating', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'grossRentInclTax', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'targetNetRent', category: 'tenancy' },
        { name: 'marketNetRent', category: 'tenancy' },
        { name: 'dueDates', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'assignee', category: 'tenancy' },
        { name: 'opted', category: 'tenancy' },
        { name: 'taxable', category: 'tenancy' },
        { name: 'contractAmendments', category: 'tenancy' },
        { name: 'referenceInterestRateDate', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'referenceInterestRateEffective', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'referenceRatePossible', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'countryIndexBaseYear', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'countryIndexDate', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'countryIndexInflation', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'costIncreaseType', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'costIncreaseDate', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'costIncreaseValue', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'costIncreaseDatePossible', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'rentMonthlyDueBy', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'retentionOfRentEffective', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'retentionOfRentPossible', category: 'tenancy', subgroup: 'rentAdjustment' },
        { name: 'rentType', category: 'tenancy' },
        { name: 'depositType', category: 'tenancy' },
        { name: 'depositInstitute', category: 'tenancy' },
        { name: 'depositNumber', category: 'tenancy' },
        { name: 'depositAmount', category: 'tenancy' },
        { name: 'depositDue', category: 'tenancy' },
        { name: 'depositPaidAt', category: 'tenancy' },
        { name: 'depositPaidAmount', category: 'tenancy' },
        { name: 'depositPaidOut', category: 'tenancy' },
        { name: 'depositRefundedOn', category: 'tenancy' },
        { name: 'depositRefundAmount', category: 'tenancy' },
        { name: 'depositNote', category: 'tenancy' },
        { name: 'netRentMarketing', category: 'marketing' },
        { name: 'heatingAndAdditionalMarketing', category: 'marketing' },
        { name: 'grossRentMarketing', category: 'marketing' },
        { name: 'statusMarketing', category: 'marketing' }
      ]
    }
  }
}

export { generateExcel }
