import auth0 from 'auth0-js'
import EventEmitter from 'events'
import store from '@/store/store'
import * as Sentry from '@sentry/browser'

const webAuth = new auth0.WebAuth({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  redirectUri: `${window.location.origin}/callback`,
  clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  responseType: 'token id_token',
  scope: 'openid profile email'
})

class AuthService extends EventEmitter {
  accessToken = null
  profile = null
  tokenExpiry = null

  login(initialScreen, additionalDefaultValues) {
    webAuth.authorize({
      initial_screen: initialScreen,
      language: additionalDefaultValues.language
    })
  }

  isUserCompletelyRegistered() {
    const profile = store.getters['user/getCurrentUser']
    return typeof profile['https://coozzy.ch/accountId'] !== 'undefined'
  }

  handleAuthenticationCallback() {
    return new Promise((resolve, reject) => {
      webAuth.parseHash(async (error, authResult) => {
        if (error) {
          reject(error)
        } else {
          try {
            await this.localLogin(authResult)
          } catch (e) {
            reject(e)
          }
          resolve(authResult.accessToken)
        }
      })
    })
  }

  async localLogin(authResult) {
    this.accessToken = authResult.accessToken
    this.profile = authResult.idTokenPayload
    this.tokenExpiry = new Date(this.profile.exp * 1000)

    localStorage.setItem('isCoozzyUserAuthenticated', 'true')
    this.emit('authStateChanged', {
      loggedIn: true,
      profile: authResult.idTokenPayload,
      state: authResult.appState || {},
      accessToken: authResult.accessToken
    })
  }

  renewTokens() {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem('isCoozzyUserAuthenticated') !== 'true') {
        return reject(new Error('not_logged_in'))
      }

      webAuth.checkSession({}, async (error, authResult) => {
        if (error) {
          reject(error)
        } else {
          try {
            await this.localLogin(authResult)
          } catch (e) {
            reject(e)
          }
          resolve(authResult)
        }
      })
    })
  }

  async logOut() {
    localStorage.removeItem('isCoozzyUserAuthenticated')
    localStorage.removeItem('isCompatibleBrowser')
    store.dispatch('user/logout')
      .catch(e => {
        console.log(e)
        Sentry.captureException(e)
      })

    this.accessToken = null
    this.tokenExpiry = null
    this.profile = null

    webAuth.logout({
      returnTo: window.location.origin
    })

    this.emit('authStateChanged', {
      loggedIn: false
    })
  }

  isAuthenticated() {
    return Date.now() < this.tokenExpiry && localStorage.getItem('isCoozzyUserAuthenticated') === 'true'
  }
}

export default new AuthService()
