<template>
  <coozzy-card :title="$t('message.settings.deepsign.title')">
    <div
      v-if="loading"
      style="height: 30px">
      <coozzy-spinner />
    </div>
    <div v-else>
      <div
        v-if="deepsignEnabled"
        class="col-12 mt-3">
        <coozzy-alert variant="success">
          {{ $t('message.settings.deepsign.accountLinkedToDeepsign') }}
        </coozzy-alert>
      </div>
      <div
        v-else
        class="col-12 mt-3">
        <coozzy-button
          class="mt-3"
          design="green"
          @click="forwardToDeepsign">
          {{ $t('message.settings.deepsign.linkYourAccount') }}
        </coozzy-button>
      </div>
    </div>
  </coozzy-card>
</template>
<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import DeepsignApi from '@/misc/apis/DeepSignApi'
import { user } from '@/mixins/user'
import CoozzyAlert from '@/framework/components/alert/CoozzyAlert.vue'
export default {
  name: 'DeepsignSettings',
  components: {
    CoozzyAlert,
    CoozzyButton,
    CoozzySpinner,
    CoozzyCard
  },
  mixins: [user],
  data() {
    return {
      loading: false,
      deepsignEnabled: false
    }
  },
  mounted: function () {
    this.loading = true
    DeepsignApi.isDeepSignEnabled(this.accountId).then((response) => {
      this.deepsignEnabled = response.enabled
      this.loading = false
    }).catch((error) => {
      console.log(error)
      this.loading = false
    })
  },
  methods: {
    forwardToDeepsign() {
      DeepsignApi.getPartnerAccessUrl(this.accountId).then((response) => {
        window.open(response.url, '_blank')
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>
