<template>
  <li>
    <router-link
      v-if="linkToView"
      :class="['text-grey-dark', customClass !== null ? customClass : '']"
      :to="{name: linkToView, params: parameters}"
      :target="target">
      <slot>Link</slot>
    </router-link>
    <a
      v-else
      v-bind="$attrs"
      class="text-dark"
      :target="target"
      v-on="mountEventListeners">
      <slot>Link</slot>
    </a>
  </li>
</template>

<script>
export default {
  name: 'CoozzyNavigationItem',
  inheritAttrs: false,
  props: {
    customClass: {
      type: String,
      default: null
    },
    linkToView: {
      type: String,
      default: ''
    },
    parameters: {
      type: Object,
      default: () => {
      }
    },
    rel: {
      type: String,
      default: '',
      validator: (value) => {
        return [
          'alternate',
          'author',
          'dns-prefetch',
          'help',
          'icon',
          'license',
          'next',
          'pingback',
          'preconnect',
          'prefetch',
          'preload',
          'prerender',
          'prev',
          'search',
          'stylesheet'
        ].includes(value) || value === ''
      }
    },
    target: {
      type: String,
      default: '_self',
      validator: (value) => {
        return [
          '_blank',
          '_self',
          '_top',
          '_parent'
        ].includes(value) || typeof value === 'undefined'
      }
    }
  },
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style lang="scss" scoped>
  li {

    height: 3rem;

    a,
    .router-link {
      height: 3rem;
      padding: 0.7rem 1rem;
      display: block;
      text-decoration: none;
    }

    a:hover,
    .router-link:hover {
      border-bottom: 2px solid $color-primary !important;
    }

    a.router-link-active {
      color: $color-primary !important;
      border-bottom: 2px solid $color-primary !important;
    }
  }
</style>
