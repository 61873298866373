<template>
  <coozzy-card class="global-card">
    <div
      v-b-toggle.collapse-region
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ $t('message.networkPartner.region') }}
      </h5>
    </div>
    <div class="col-12 pl-0">
      <b-collapse
        id="collapse-region">
        <div class="row">
          <div
            v-if="partner.subType.includes('ASSET')"
            class="col-6">
            <label>
              ASSETbase
            </label>
            <p class="mb-0">
              {{
                partner.assetStates.map(state => state.substring(3)).sort().join(', ') | displayOptionalValue
              }}
            </p>
          </div>
          <div
            v-if="partner.subType.includes('MARKETING')"
            class="col-6">
            <label>
              MARKETbase
            </label>
            <p class="mb-0">
              {{
                partner.marketingStates.map(state => state.substring(3)).sort().join(', ') | displayOptionalValue
              }}
            </p>
          </div>
          <div
            v-if="partner.subType.includes('LANDLORD')"
            class="col-6">
            <label>
              ADMINbase
            </label>
            <p class="mb-0">
              {{
                partner.managementStates.map(state => state.substring(3)).sort().join(', ') | displayOptionalValue
              }}
            </p>
          </div>
          <div
            v-if="partner.subType.includes('ACCOUNTING')"
            class="col-6">
            <label>
              FINANCEbase
            </label>
            <p class="mb-0">
              {{
                partner.accountingStates.map(state => state.substring(3)).sort().join(', ') | displayOptionalValue
              }}
            </p>
          </div>
        </div>
      </b-collapse>
    </div>
  </coozzy-card>
</template>

<script>
import CoozzyCard from '@/framework/components/card/CoozzyCard'

export default {
  name: 'NetworkPartnerRegionCard',
  components: { CoozzyCard },
  props: {
    partner: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
