<template>
  <div class="row mb-4">
    <div
      v-if="loading"
      class="text-center">
      <coozzy-spinner />
    </div>
    <div
      v-if="!loading && lastTenancy.id"
      class="col-12 p-0">
      <h5 class="col-12 cursor-pointer -mb-3 font-weight-normal">
        {{ object.number + ' - ' + object.name }}
      </h5>
      <b-collapse
        :visible="true">
        <object-tenancy
          ref="object-last-tenancy"
          :tenancy="lastTenancy"
          :title-bold="true"
          :read-only="true"
          :expand-by-default="false"
          :object="object"
          :enable-tenant-selection="false"
          :index="lastTenancy.id"
          :hide-actions="true"
          mode="adding" />
      </b-collapse>
    </div>
    <div class="col-12 mt-3">
      <h4>
        {{ $t('message.enterTermination.reviewTermination') }}
      </h4>
      <h5>
        {{ $t('message.enterTermination.choosePartnersQuestion') }}
      </h5>
    </div>
    <div class="col-6 col-md-6 col-sm-12">
      <coozzy-form-checkbox
        v-for="contact in contactsForTenant"
        :key="contact.id"
        :ref="'checkbox' + contact.id"
        class="font-weight-normal"
        @change="(value) => { itemSelectedReview(value, contact.typeInAddingTenancyProcess, contact.id) }">
        {{ contact.firstName && contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.name }} - {{ contact.typeInAddingTenancyProcess === 'TENANT' ? $t('message.enterTermination.tenant') : $t('message.enterTermination.guarantor') }}
      </coozzy-form-checkbox>
    </div>
    <div
      v-if="lastTenancy.familyFlat"
      class="col-6 col-md-6 col-sm-12">
      <coozzy-form-checkbox
        :initial="true"
        :disabled="true"
        @change="(value) => { itemSelectedReview(value, 'FAMILYFLAT') }">
        {{ $t('message.onBoarding.buildings.objects.tenancies.familyFlat') }}
      </coozzy-form-checkbox>
      <div>{{ $t('message.enterTermination.familyFlatAttention') }}</div>
    </div>
    <div
      v-if="canCopyData"
      class="col-12 text-align-end">
      <coozzy-button
        size="small"
        class="mb-0 border float-right"
        design="transparent"
        @click="copyDataClicked">
        {{ $t('message.enterTermination.takeData') }}
      </coozzy-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ContactApi from '@/misc/apis/ContactApi'
import ObjectApi from '@/misc/apis/ObjectApi'
import ObjectTenancy from '@/building/tabs/components/ObjectTenancy'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'

export default {
  name: 'EnterTerminationReviewObject',
  components: {
    CoozzyFormCheckbox,
    ObjectTenancy
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    canCopyData: {
      type: Boolean,
      default: false
    },
    defaultSelectContact: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      contactsForTenant: [],
      contacts: [],
      lastTenancy: {},
      loading: false,
      selectedEntriesReview: []
    }
  },
  computed: {},
  watch: {
    defaultSelectContact: {
      handler: function (val) {
        for (let index = 0; index < val.length; index++) {
          this.itemSelectedReview(true, val[index].type, val[index].id)
          this.$refs['checkbox' + val[index].id][0].checked = true
        }
      },
      deep: true
    }
  },
  mounted() {
    this.loading = true
    const t = this
    ObjectApi.getTenancies([this.object.id]).then(response => {
      const promises = []
      if (response.tenancies.length > 0) {
        if (response.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && !x.endingAt)) {
          this.lastTenancy = response.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && !x.endingAt)
        } else {
          this.lastTenancy = response.tenancies.sort(function (a, b) {
            return new Date(t.$options.filters.objectToDateInput(b.endingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.endingAt)).getTime()
          })[0]
        }
        promises.push(this.loadContacts())
        if (this.lastTenancy.tenant.guarantorContactIds) {
          promises.push(this.fetchGuarantor(this.lastTenancy.tenant.guarantorContactIds))
        }
        Promise.all(promises)
          .then(() => {
            this.emitSelectedEntriesReview()
            this.loading = false
          })
      }
    }).catch(e => {
      this.loading = false
      console.log(e)
      Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
    })
  },
  methods: {
    loadContacts() {
      return ContactApi.contactResolve(this.lastTenancy.tenant.contactIds)
        .then(response => {
          let tempcontacts = response.persons.concat(response.companies)
          tempcontacts = tempcontacts.filter(x => this.lastTenancy.tenant.contactIds.includes(x.id))
          for (let index = 0; index < tempcontacts.length; index++) {
            if (!tempcontacts[index].typeInAddingTenancyProcess) {
              tempcontacts[index].typeInAddingTenancyProcess = 'TENANT'
            }
          }
          this.contactsForTenant = this.contactsForTenant.concat(tempcontacts)
        })
    },
    fetchGuarantor(guarantorContactId) {
      return ContactApi.contactResolve(guarantorContactId)
        .then(response => {
          let tempcontacts = response.persons.concat(response.companies)
          tempcontacts = tempcontacts.filter(x => guarantorContactId.includes(x.id))
          for (let index = 0; index < tempcontacts.length; index++) {
            tempcontacts[index].typeInAddingTenancyProcess = 'GUARANTORCONTACT'
          }
          this.contactsForTenant = this.contactsForTenant.concat(tempcontacts.filter(x => x.typeInAddingTenancyProcess === 'GUARANTORCONTACT'))
        })
    },
    itemSelectedReview(value, type, id = 0) {
      const item = {}
      item.id = id
      item.type = type
      if (value) {
        if (this.selectedEntriesReview.filter(x => x.id === item.id && x.type === item.type).length === 0) {
          // above condition is solution for broken checkbox on localhost
          this.selectedEntriesReview.push(item)
        }
      } else {
        for (let i in this.selectedEntriesReview) {
          if (this.selectedEntriesReview[i].id === item.id && this.selectedEntriesReview[i].type === item.type) {
            this.selectedEntriesReview.splice(i, 1)
            break
          }
        }
      }
      this.emitSelectedEntriesReview()
    },
    copyDataClicked() {
      this.$emit('copy-data-from-first-review', this.object.id, this.selectedEntriesReview)
    },
    emitSelectedEntriesReview() {
      if (this.selectedEntriesReview.length === this.contactsForTenant.length) {
        this.$emit('selected-entries-review', true, this.object.id)
      } else {
        this.$emit('selected-entries-review', false, this.object.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.-mb-3 {
  margin-bottom: -1rem;
}

.font-weight-normal :deep(.custom-control-label) {
  font-weight: normal !important;
}

</style>
