// @ts-nocheck
import network from './network'

export default {
  inviteEmployee(accountId, email, firstName, lastName, sendPasswordReset, password = '', roles) {
    return network.post('/web/v2/user/inviteEmployee', {
      accountId: accountId,
      email: email,
      firstName: firstName,
      roles: roles,
      lastName: lastName,
      sendPasswordReset: sendPasswordReset,
      password: password
    }).then(response => {
      return response.data
    })
  },
  getEmployees(accountId) {
    return network.get(`web/v2/user/listEmployees/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  addRole(userId, role) {
    return network.post(`/web/v2/user/${userId}/addRole`, { role: role }).then(response => {
      return response.data
    })
  },
  removeRole(userId, role) {
    return network.post(`/web/v2/user/${userId}/removeRole`, { role: role }).then(response => {
      return response.data
    })
  },
  deleteUser(userId) {
    return network.delete(`web/v2/user/${userId}/delete`).then(response => {
      return response.data
    })
  }
}
