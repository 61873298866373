<template>
  <div>
    <label
      v-if="!withoutLabel"
      for="country">
      {{ label }}<template v-if="required && !isReadOnly">*</template>
    </label>
    <coozzy-form-select
      v-if="!isReadOnly"
      v-model="country"
      :class="(state === false ? ' is-invalid' : '')"
      class="basic"
      v-on="$listeners">
      <option value="">
        -
      </option>
      <option value="CH">
        {{ $t('message.generic.countries.switzerland') }}
      </option>
      <option value="FOREIGN_CITIZENSHIP">
        {{ $t('message.generic.nationality.foreignCitizenship') }}
      </option>
      <option
        v-for="c in countrycitizenship"
        :key="c.countryShortCode"
        :value="c.countryShortCode">
        {{ c.countryName }}
      </option>
    </coozzy-form-select>
    <country-select
      v-if="!isReadOnly"
      id="countrycitizenship"
      :ref="'countrycitizenship'"
      style="display: none;"
      v-bind="$attrs"
      :country="country"
      top-country="CH"
      placeholder="-"
      :class-name="'custom-select custom-select-sm' + (state === false ? ' is-invalid' : '')"
      v-on="$listeners" />
    <span v-else>
      <template v-if="country === 'FOREIGN_CITIZENSHIP'">
        {{ $t('message.generic.nationality.foreignCitizenship') }}
      </template>
      <template v-if="country === ''">
        -
      </template>
      <template v-else>
        {{ translateCountry(country) }}
      </template>
    </span>
  </div>
</template>

<script>

import { country } from '@/mixins/country'

export default {
  name: 'CoozzyFormCountryCitizenshipSelect',
  mixins: [country],
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: true
    },
    initialValue: {
      type: String,
      default: ''
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    },
    label: {
      type: String,
      default: function () {
        return this.$t('message.generic.country')
      }
    },
    withoutLabel: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      country: this.initialValue,
      countrycitizenship: []
    }
  },
  watch: {
    initialValue: function (newVal) {
      if (newVal !== this.country) {
        this.country = this.initialValue
      }
    }
  },
  mounted() {
    if (this.$refs.countrycitizenship) {
      this.$watch(vm => [vm.$refs.countrycitizenship.countries], {
        immediate: true,
        handler: function (val) {
          if (val && val.length > 0) {
            this.$nextTick(() => {
              this.countrycitizenship = val[0]
            })
          }
        },
        deep: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
