import store from '@/store/store'
import BriefButlerApi from '@/misc/apis/BriefButlerApi'
import Vue from 'vue'

export default {
  namespaced: true,
    state: {
        briefButlerLoadedAt: 0,
        loading: false
  },
  getters: {
    getBriefButlerLoadedAt: (state) => {
      return state.briefButlerLoadedAt
    },
    getLoading: (state) => {
      return state.loading
    },
    getNumberBriefButler: (state) => {
      return state.numberBriefButler
    }
  },
  mutations: {
    setNumberBriefButler: (state, numberBriefButler) => {
      Vue.set(state, 'numberBriefButler', numberBriefButler)
      state.briefButlerLoadedAt = Date.now()
      state.loading = false
    },
    setLoading: (state) => {
      state.loading = true
    }
  },
    actions: {
      setNumberBriefButler: ({ commit }, numberBriefButler) => {
        commit('setNumberBriefButler', numberBriefButler)
      },
      loadBriefButlerCounts: async ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        // Setup watcher for accountId if not yet available
        if (!store.getters['user/getAccountId']) {
          commit('setLoading')

          store.watch((watchedState, getters) => getters['user/getAccountId'], value => {
            BriefButlerApi.listErrorsByAccountId(store.getters['user/getAccountId'])
                    .then(response => {
                    resolve(response.errors.length)
                  commit('setNumberBriefButler', response.errors.length)
                })
                .catch(e => {
                  console.error(e)
                  reject(e)
                })
          })
        } else if ((state.loading === false && (state.briefButlerLoadedAt === 0 || (Date.now() - state.briefButlerLoadedAt) > 30000))) {
          // If force OR loading is not already triggered and stored mails count are older than 30 seconds -> Load new data
          commit('setLoading')
          // Load and sort users
          BriefButlerApi.listErrorsByAccountId(store.getters['user/getAccountId'])
              .then(response => {
                resolve(response.errors.length)
                  commit('setNumberBriefButler', response.errors.length)
              })
              .catch(e => {
                console.error(e)
                reject(e)
              })
        } else {
          resolve(state.numberBriefButler)
        }
      })
    }
  }
}
