<template>
  <section>
    <div
      v-if="isEditor || isJanitorEditor"
      class="floating-wrapper">
      <coozzy-button
        :class="['floating-button', tabIndex === 5 ? 'd-none' : '']"
        design="transparent"
        @click="editClicked">
        <coozzy-edit-icon class="too-left" />
      </coozzy-button>
    </div>
    <div class="row">
      <div
        v-if="building === null || onboardingLoading"
        class="col-12 text-center">
        <coozzy-spinner class="m-3" />
      </div>
      <div
        v-else
        class="col-12 custumeTabs">
        <coozzy-page-title
          :title="$t('message.onBoarding.buildings.title') + ': ' + buildingName"
          :sub-title="$t('message.propertiesView.tabs.property') +': ' + ( properties[0] ? properties[0].name : '-' ) + ' | ' + $t('message.onBoarding.buildings.address') + ': ' + building.address.street + ' ' + building.address.streetNumber + ', ' +building.address.zip + ' ' + building.address.city" />
        <!-- Action buttons on mobile -->
        <div class="row d-md-none">
          <div class="col-12 mb-2">
            <router-link
              :to="toPropertyClicked() !== undefined ? toPropertyClicked() : ''">
              <coozzy-button
                design="secondary"
                class="w-100">
                {{ $t('message.onBoardingTabs.toProperty') }}
              </coozzy-button>
            </router-link>
          </div>
          <div
            v-if="isEditor || isJanitorEditor"
            class="col-12 mb-2">
            <coozzy-button
              design="primary"
              class="w-100"
              @click="editClicked">
              {{ $t('message.generic.edit') }}
            </coozzy-button>
          </div>
          <div class="col-12 mb-2">
            <coozzy-button
              design="secondary"
              class="w-100"
              @click="backClicked">
              {{ $t('message.generic.back') }}
            </coozzy-button>
          </div>
        </div>
        <b-tabs
          v-model="tabIndex"
          class="mb-3">
          <b-tab
            class="p-0"
            lazy>
            <template #title>
              <router-link
                :to="getBuildingRoute('building') !== undefined ? getBuildingRoute('building') : ''">
                <span>{{ $t('message.onBoardingTabs.building') }}</span>
              </router-link>
            </template>
            <create-building-tab
              :read-only="true"
              :is-active="tabIndex === 0"
              :expand-room-id="expandRoomId"
              :expand-building-mortgage-id="expandBuildingMortgageId"
              :expand-building-insurance-id="expandBuildingInsuranceId" />
          </b-tab>
          <b-tab
            lazy
            class="p-0">
            <template #title>
              <router-link
                :to="getBuildingRoute('objects') !== undefined ? getBuildingRoute('objects') : ''">
                <span>{{ $t('message.onBoardingTabs.objects') }}</span>
              </router-link>
            </template>
            <create-objects-tab
              ref="objectsTab"
              :element-selected="elementSelected"
              :source="from"
              :read-only="true"
              :is-active="tabIndex === 1"
              :object-filters="objectFilters"
              :expand-object-room-id="expandObjectRoomId"
              @selected-object="updateElementSelected"
              @change-filter="updateWithFilter" />
          </b-tab>
          <template
            v-if="!isJanitor">
            <b-tab
              v-if="propertyType === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"
              lazy
              class="p-0">
              <template #title>
                <router-link
                  :to="getBuildingRoute('condominium') !== undefined ? getBuildingRoute('condominium') : ''">
                  <span>{{ $t('message.onBoardingTabs.condominium') }}</span>
                </router-link>
              </template>
              <create-condominium-tab
                ref="condominiumTab"
                :element-selected="elementSelected"
                :read-only="true"
                :is-active="tabIndex === 2"
                :condominium-filters="condominiumFilters"
                @selected-condominium="updateElementSelected"
                @change-filter="updateWithFilter" />
            </b-tab>
            <b-tab
              v-else
              lazy
              class="p-0">
              <template #title>
                <router-link
                  :to="getBuildingRoute('tenants') !== undefined ? getBuildingRoute('tenants') : ''">
                  <span>{{ $t('message.onBoardingTabs.tenants') }}</span>
                </router-link>
              </template>
              <create-tenants-tab
                ref="tenantsTab"
                :element-selected="elementSelected"
                :read-only="true"
                :is-active="tabIndex === 2"
                :tenant-filters="tenantFilters"
                @selected-tenancy="updateElementSelected"
                @change-filter="updateWithFilter" />
            </b-tab>
          </template>
          <b-tab
            lazy
            class="p-0">
            <template #title>
              <router-link
                :to="getBuildingRoute('devices') !== undefined ? getBuildingRoute('devices') : ''">
                <span>{{ $t('message.onBoardingTabs.devices') }}</span>
              </router-link>
            </template>
            <create-devices-tab
              ref="devicesTab"
              :read-only="true"
              :is-active="tabIndex === 3"
              :element-selected="elementSelected"
              :property-id="propertyId"
              :device-filters="deviceFilters"
              @selected-device="updateElementSelected"
              @change-filter="updateWithFilter" />
          </b-tab>
          <b-tab
            v-if="isInvestPlanEnabled"
            lazy
            class="p-0">
            <template #title>
              <router-link
                :to="getBuildingRoute('investmentPlanning') !== undefined ? getBuildingRoute('investmentPlanning') : ''">
                <span>{{ $t('message.onBoarding.investmentPlanning.title') }}</span>
              </router-link>
            </template>
            <building-invest-plan-tab
              ref="investPlanTab"
              :read-only="true" />
          </b-tab>
          <b-tab
            lazy
            class="p-0">
            <template #title>
              <router-link
                :to="getBuildingRoute('contacts') !== undefined ? getBuildingRoute('contacts') : ''">
                <span>{{ $t('message.onBoardingTabs.contacts') }}</span>
              </router-link>
            </template>
            <create-contacts-tab />
          </b-tab>
          <b-tab
            class="p-0">
            <template #title>
              <router-link
                class="tab_title_color"
                :to="getBuildingRoute('history') !== undefined ? getBuildingRoute('history') : ''">
                <span>{{ $t('message.onBoardingTabs.history') }}</span>
                <span
                  v-if="hasNotification"
                  class="small-dot" />
              </router-link>
            </template>
            <history-tab
              ref="activitiesTab"
              :building="building"
              :device-list="buildingDevices.concat(objectDevices)"
              :history-filters="historyFilters"
              :route-name="$route.name"
              :is-active="tabIndex === 5"
              :element-selected="elementSelected"
              @has-notification="updateHasNotification"
              @change-filter="updateWithFilter" />
          </b-tab>
          <b-tab
            v-if="isPortalLinkEnabled"
            lazy
            class="p-0">
            <template #title>
              <router-link
                :to="getBuildingRoute('portalLinks') !== undefined ? getBuildingRoute('portalLinks') : ''">
                <span>{{ $t('message.portalTab.title') }}</span>
              </router-link>
            </template>
            <building-portal-links-tab
              ref="portalLinksTab"
              :read-only="true" />
          </b-tab>
          <template #tabs-end>
            <b-nav-item
              v-if="isEditor || isJanitorEditor"
              href="#"
              class="d-none d-md-block item-button">
              <coozzy-button
                design="primary"
                class="nav-button nav-button-center"
                @click="editClicked">
                {{ $t('message.generic.edit') }}
              </coozzy-button>
            </b-nav-item>
            <b-nav-item
              href="#"
              class="d-none d-md-block item-button">
              <coozzy-button
                design="secondary"
                class="nav-button nav-button-right"
                @click="backClicked">
                {{ $t('message.generic.back') }}
              </coozzy-button>
            </b-nav-item>
            <b-nav-item
              href="#"
              class="d-none d-md-block item-button">
              <router-link
                :to="toPropertyClicked() !== undefined ? toPropertyClicked() : ''">
                <coozzy-button
                  design="secondary"
                  :class="isEditor || isJanitorEditor ? 'nav-button-right adaptToPropertyButton' : 'nav-button-center'"
                  class="nav-button">
                  {{ $t('message.onBoardingTabs.toProperty') }}
                </coozzy-button>
              </router-link>
            </b-nav-item>
          </template>
        </b-tabs>
      </div>
    </div>
  </section>
</template>

<script>
import { user } from '../mixins/user'
// import { ticket } from '../mixins/ticket'
import { onboarding } from '../mixins/onboarding'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import CreateBuildingTab from './tabs/CreateBuildingTab'
import CreateObjectsTab from './tabs/CreateObjectsTab'
import CreateTenantsTab from './tabs/CreateTenantsTab'
import CreateCondominiumTab from './tabs/CreateCondominiumTab'
import CreateDevicesTab from './tabs/CreateDevicesTab'
import CreateContactsTab from './tabs/CreateContactsTab'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon'
import HistoryTab from '@/property/tabs/HistoryTab.vue'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import BuildingInvestPlanTab from '@/building/tabs/components/BuildingInvestPlanTab.vue'
import BuildingPortalLinksTab from '@/building/tabs/BuildingPortalLinksTab.vue'

export default {
  name: 'BuildingDetailsView',
  components: {
    BuildingPortalLinksTab,
    BuildingInvestPlanTab,
    CoozzyEditIcon,
    CoozzyButton,
    CreateBuildingTab,
    CreateObjectsTab,
    CreateTenantsTab,
    CreateCondominiumTab,
    CreateDevicesTab,
    CreateContactsTab,
    CoozzySpinner,
    CoozzyPageTitle,
    HistoryTab
  },
  mixins: [routeChecks, user, onboarding],
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('onboarding/setOpenedCollapses')
    next()
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    view: {
      type: String,
      default: 'building'
    },
    elementSelected: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    },
    objectFilters: {
      type: String,
      default: ''
    },
    deviceFilters: {
      type: String,
      default: ''
    },
    tenantFilters: {
      type: String,
      default: ''
    },
    condominiumFilters: {
      type: String,
      default: ''
    },
    historyFilters: {
      type: String,
      default: ''
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.onBoarding.buildings.title') + ' ' + this.buildingName
    }
  },
  data() {
    return {
      tabIndex: 0,
      scrollValue: 0,
      expandRoomId: '',
      expandObjectRoomId: '',
      expandBuildingMortgageId: '',
      expandBuildingInsuranceId: '',
      hasNotification: false
    }
  },
  computed: {
    buildingName() {
      return this.building && this.building.name ? this.building.name : ''
    },
    propertyId() {
      return this.properties[0] ? this.properties[0].id : null
    },
    propertyType() {
      return this.properties.length > 0 ? this.properties[0].type : ''
    },
    isPortalLinkEnabled() {
      return this.$store.getters['onboarding/getPortalLinkEnabled']
    }
  },
  watch: {
    view: function () {
      if (this.isInvestPlanEnabled !== null) {
        this.openSelectedTabs()
      }
    },
    isInvestPlanEnabled: function () {
      this.openSelectedTabs()
    },
    tabIndex: function (newVal) {
      let newView = 'building'
      const propertyType = this.view === 'condominium'
      if (newVal === 0) {
        newView = 'building'
      } else if (newVal === 1) {
        newView = 'objects'
      } else if (newVal === 2) {
        if (propertyType) {
          newView = 'condominium'
        } else {
          newView = 'tenants'
        }
      } else if (newVal === 3) {
        newView = 'devices'
      } else if (newVal === 4 && this.isInvestPlanEnabled) {
        newView = 'investmentPlanning'
      } else if ((newVal === 5 && this.isInvestPlanEnabled) || (newVal === 4 && !this.isInvestPlanEnabled)) {
        newView = 'contacts'
      } else if ((newVal === 6 && this.isInvestPlanEnabled) || (newVal === 5 && !this.isInvestPlanEnabled)) {
        newView = 'history'
      } else if ((newVal === 7 && this.isInvestPlanEnabled) || (newVal === 6 && !this.isInvestPlanEnabled)) {
        newView = 'portalLinks'
      }
      if (this.view !== newView) {
        if (this.isOwnerModule) {
          this.$router.push({ name: 'OwnerBuildingDetailsView', params: { id: this.id }, query: { view: newView, elementSelected: this.elementSelected, from: this.from, objectFilters: this.objectFilters, tenantFilters: this.tenantFilters, condominiumFilters: this.condominiumFilters, deviceFilters: this.deviceFilters, historyFilters: this.historyFilters } }, () => {})
        } else if (this.isAdminModule) {
          this.$router.push({ name: 'AdminBuildingDetailsView', params: { id: this.id }, query: { view: newView, elementSelected: this.elementSelected, from: this.from, objectFilters: this.objectFilters, tenantFilters: this.tenantFilters, condominiumFilters: this.condominiumFilters, deviceFilters: this.deviceFilters, historyFilters: this.historyFilters } }, () => {})
        } else if (this.isAssetModule) {
          this.$router.push({ name: 'AssetBuildingDetailsView', params: { id: this.id }, query: { view: newView, elementSelected: this.elementSelected, from: this.from, objectFilters: this.objectFilters, tenantFilters: this.tenantFilters, condominiumFilters: this.condominiumFilters, deviceFilters: this.deviceFilters, historyFilters: this.historyFilters } }, () => {})
        } else if (this.isAccountingModule) {
          this.$router.push({ name: 'AccountingBuildingDetailsView', params: { id: this.id }, query: { view: newView, elementSelected: this.elementSelected, from: this.from, objectFilters: this.objectFilters, tenantFilters: this.tenantFilters, condominiumFilters: this.condominiumFilters, deviceFilters: this.deviceFilters, historyFilters: this.historyFilters } }, () => {})
        }
      }
    },
    onboardingLoading: function () {
      this.$store.dispatch('onboarding/setLoadingFinish', true)
      this.$nextTick(() => {
        let scrollpos = localStorage.getItem('scrollpos')
        if (scrollpos) {
          window.scrollTo(0, parseInt(scrollpos))
        }
      })
    }
  },
  created() {
    this.$store.dispatch('onboarding/setLoadingFinish', false)
  },
  mounted() {
    this.$store.dispatch('onboarding/setIsBuildingObjectsFiltered', [])
    if (this.isInvestPlanEnabled !== null) {
      this.openSelectedTabs()
    }
    this.loadOnboarding(this.id)
    window.addEventListener('beforeunload', (event) => {
      localStorage.setItem('scrollpos', this.scrollValue)
    })
    // Scroll to room collapse in building
    if (localStorage.getItem('expandBuildingRoomId')) {
      this.expandRoomId = localStorage.getItem('expandBuildingRoomId')
      localStorage.removeItem('expandBuildingRoomId')
    }
    // Scroll to room collapse in object tab
    if (localStorage.getItem('expandObjectRoomId')) {
      this.expandObjectRoomId = localStorage.getItem('expandObjectRoomId')
      localStorage.removeItem('expandObjectRoomId')
    }
    // Scroll to mortgage collapse in building
    if (localStorage.getItem('expandBuildingMortgageId')) {
      this.expandBuildingMortgageId = localStorage.getItem('expandBuildingMortgageId')
      localStorage.removeItem('expandBuildingMortgageId')
    }
    // Scroll to insurance collapse in building
    if (localStorage.getItem('expandBuildingInsuranceId')) {
      this.expandBuildingInsuranceId = localStorage.getItem('expandBuildingInsuranceId')
      localStorage.removeItem('expandBuildingInsuranceId')
    }
  },
  methods: {
    updateHasNotification(value) {
      this.hasNotification = value
    },
    toPropertyClicked() {
      if (this.properties.length > 0) {
        if (this.isOwnerModule) {
          return { name: 'OwnerPropertyDetailsView', params: { id: this.properties[0].id } }
        } else if (this.isAdminModule) {
          return { name: 'AdminPropertyDetailsView', params: { id: this.properties[0].id } }
        } else if (this.isAssetModule) {
          return { name: 'AssetPropertyDetailsView', params: { id: this.properties[0].id } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingPropertyDetailsView', params: { id: this.properties[0].id } }
        }
      }
    },
    updateWithFilter(newVal, field) {
      const query = { view: this.view, elementSelected: this.elementSelected, from: this.from, objectFilters: this.objectFilters, tenantFilters: this.tenantFilters, condominiumFilters: this.condominiumFilters, deviceFilters: this.deviceFilters, historyFilters: this.historyFilters }
      const initQuery = JSON.stringify(query)
      query[field] = newVal
      if (initQuery !== JSON.stringify(query)) {
        if (this.isOwnerModule) {
          this.$router.push({ name: 'OwnerBuildingDetailsView', params: { id: this.id }, query: query })
        } else if (this.isAdminModule) {
          this.$router.push({ name: 'AdminBuildingDetailsView', params: { id: this.id }, query: query })
        } else if (this.isAssetModule) {
          this.$router.push({ name: 'AssetBuildingDetailsView', params: { id: this.id }, query: query })
        } else if (this.isAccountingModule) {
          this.$router.push({ name: 'AccountingBuildingDetailsView', params: { id: this.id }, query: query })
        }
      }
    },
    updateElementSelected(item) {
      if (item && item.id !== this.elementSelected) {
        const query = { view: this.view, elementSelected: item.id, from: this.from, objectFilters: this.objectFilters, tenantFilters: this.tenantFilters, condominiumFilters: this.condominiumFilters, deviceFilters: this.deviceFilters, historyFilters: this.historyFilters }
        if (this.isOwnerModule) {
          this.$router.push({ name: 'OwnerBuildingDetailsView', params: { id: this.id }, query: query })
        } else if (this.isAdminModule) {
          this.$router.push({ name: 'AdminBuildingDetailsView', params: { id: this.id }, query: query })
        } else if (this.isAssetModule) {
          this.$router.push({ name: 'AssetBuildingDetailsView', params: { id: this.id }, query: query })
        } else if (this.isAccountingModule) {
          this.$router.push({ name: 'AccountingBuildingDetailsView', params: { id: this.id }, query: query })
        }
      }
    },
    openSelectedTabs() {
      if (this.view === 'building') {
        this.tabIndex = 0
      } else if (this.view === 'objects') {
        this.tabIndex = 1
      } else if (this.view === 'tenants' || this.view === 'condominium') {
        this.tabIndex = 2
      } else if (this.view === 'devices') {
        this.tabIndex = 3
      } else if (this.view === 'investmentPlanning' && this.isInvestPlanEnabled) {
        this.tabIndex = 4
      } else if (this.view === 'contacts') {
        this.tabIndex = this.isInvestPlanEnabled ? 5 : 4
      } else if (this.view === 'history') {
        this.tabIndex = this.isInvestPlanEnabled ? 6 : 5
      } else if (this.view === 'portalLinks') {
        this.tabIndex = this.isInvestPlanEnabled ? 7 : 6
      }
    },
    editClicked() {
      localStorage.setItem('scrollpos', window.scrollY)
      const query = { view: this.view, elementSelected: this.elementSelected, from: this.from, objectFilters: this.objectFilters, tenantFilters: this.tenantFilters, condominiumFilters: this.condominiumFilters, deviceFilters: this.deviceFilters, historyFilters: this.historyFilters }
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerOnboardingView', params: { id: this.id }, query: query })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminOnboardingView', params: { id: this.id }, query: query })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetOnboardingView', params: { id: this.id }, query: query })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingOnboardingView', params: { id: this.id }, query: query })
      }
    },
    backClicked() {
      this.$router.go(-1)
    },
    autocompletePlaceChanged(data) {
      this.updateWithAddressComponents(data.address_components)
    },
    addNewDevice() {
      this.buildings[0].devices.push({ internalId: Date.now() })
    },
    updateDevice(item) {
      this.$set(this.buildings[0].devices, item.index, item.device)
    },
    removeDevice(index) {
      this.$delete(this.buildings[0].devices, index)
    },
    updateWithAddressComponents(addressComponents) {
      const streetNo = addressComponents.find((component) => component.types.find((type) => type === 'street_number'))
      const street = addressComponents.find((component) => component.types.find((type) => type === 'route'))
      const city = addressComponents.find((component) => component.types.find((type) => type === 'locality'))
      const zip = addressComponents.find((component) => component.types.find((type) => type === 'postal_code'))
      const state = addressComponents.find((component) => component.types.find((type) => type === 'administrative_area_level_1'))
      const country = addressComponents.find((component) => component.types.find((type) => type === 'country'))

      if (country && country.short_name === 'CH') {
        if (streetNo) {
          this.buildings[0].address.streetNo = streetNo.long_name
        }
        if (street) {
          this.buildings[0].address.street = street.long_name
        }
        if (city) {
          this.buildings[0].address.city = city.long_name
        }
        if (zip) {
          this.buildings[0].address.zip = zip.long_name
        }
        if (state) {
          this.buildings[0].address.state = state.short_name
        }
      } else {
        // TODO Alert
      }
    },
    getBuildingRoute(view) {
      if (this.isOwnerModule) {
        return { name: 'OwnerBuildingDetailsView', params: { id: this.id }, query: { view: view, elementSelected: this.elementSelected } }
      } else if (this.isAdminModule) {
        return { name: 'AdminBuildingDetailsView', params: { id: this.id }, query: { view: view, elementSelected: this.elementSelected } }
      } else if (this.isAssetModule) {
        return { name: 'AssetBuildingDetailsView', params: { id: this.id }, query: { view: view, elementSelected: this.elementSelected } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingBuildingDetailsView', params: { id: this.id }, query: { view: view, elementSelected: this.elementSelected } }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .too-left{
    margin-right: -2px;
  }
  .inputs-container {
    overflow-y: scroll;
    height: calc(100vh - 100px);
  }

  .item {
    cursor: pointer;
  }

  :deep(.details-row-bg-1) {
    background-color: $color-table-row-2;
  }

  :deep(.details-row-bg-2) {
    background-color: $color-table-row-1;
  }

  :deep(label) {
    width: 100%;
  }

  :deep(.is-disabled) {
    .sort button{
      display: block !important;
    }
    button:not(.sort):not(.page-link) {
      display: none;
    }

    .multiselect, .custom-checkbox {
      pointer-events: none;

      .multiselect__select, .multiselect__placeholder {
        display: none;
      }
    }
    .filter-inputs .multiselect, .custom-checkbox {
      pointer-events: auto !important;

      .multiselect__select, .multiselect__placeholder {
        display: block !important;
      }
    }
    .basic {
      input {
        opacity: 1;
        border: thin solid #ced4da;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
        margin-bottom: 0;
        pointer-events: initial;
        padding-left: 0.2rem;
        background: none;
      }
    }

    .multi-select-value {
      line-height: 1.5;
    }
  }

  :deep(.collapse-title) {
    &.collapsed {
      h5 {
        background-image: url('../assets/arrow_down.svg');
      }
    }

    h5 {
      background-image: url('../assets/arrow_up.svg');
      background-repeat: no-repeat;
      font-size: large;
      font-weight: 500 !important;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      background-position: right;
    }
  }

  :deep(.global-card) {
    margin-bottom: 0;

    & > .card-body {
      -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    }

    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    &.no-top-border {
      border-top: 0;
    }
  }
  .floating-wrapper{
    right: 5vw;
    bottom: 4vh;
    z-index: 999;
    position: fixed;
    .floating-button{
      border-radius: 100px !important;
      display: flex;
      align-items: center;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.20), 0 4px 4px rgba(0, 0, 0, 0.15);
      z-index: 2;
      justify-content: center;
      background-color: $color-primary-dark;
      padding: 20px !important;
      cursor: pointer;
      svg{
        color: white;
        font-size: 15px;
      }
    }
  }
  :deep(.tabs .nav-tabs .nav-link a.router-link-active), :deep(.tabs .nav-tabs .nav-link a) {
    color: inherit !important;
    text-decoration: none !important;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation]) {
    margin-right: 0.2rem;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item > a.nav-link) {
    margin-right: 0;
    padding: 0;
    box-shadow: none;
    border: 0;
    z-index: -1;
    height: 26.5px;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link.active > a) {
    font-weight: bold;
    background-color: #fff;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link:hover > a) {
    font-weight: bold;
    cursor: pointer;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link > a) {
    color: rgb(0, 68, 95) !important;
    border-color: #c8ced3 !important;
    border-radius: 0 !important;
    border-bottom: 0 !important;
    margin-right: 0;
    padding: 0.55rem 1.5rem;
    font-weight: 300;
    -webkit-box-shadow: inset 0 16px 20px -15px rgb(238 238 238), 3px 0 2px -2px #ccc;
    box-shadow: inset 0 16px 20px -15px rgb(238 238 238), 3px 0 2px -2px #ccc;
    cursor: default;
    border: 1px solid transparent;
    height: 36.25px;
  }
  :deep(.card.shadow) {
    box-shadow: none !important;
  }
  .item-button button.small {
    padding: 0.2rem 0.5rem;
  }
  .adaptToPropertyButton {
    // check css on staging before change it
    top: 40px;
    max-height: 30px;
  }
</style>
