import networkTS from '@/misc/apis/networkTS'

export default {
  triggerDebitUntilSync(accountId: string) {
    return networkTS.accountingServiceTriggerDebitUntilSync(accountId).then(response => {
        return response.data
      })
  },
  TriggerRentAdjustmentSync(accountId: string) {
    return networkTS.accountingServiceTriggerRentAdjustmentSync(accountId).then(response => {
        return response.data
      })
  },
  triggerDocumentSync(accountId: string) {
    return networkTS.accountingServiceTriggerDocumentSync(accountId).then(response => {
        return response.data
      })
  }
}
