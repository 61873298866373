<template>
  <font-awesome-icon
    icon="dollar-sign"
    class="fa" />
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'

library.add(faDollarSign)

export default {
  name: 'CoozzySaleIcon'
}
</script>

<style lang="scss" scoped>
  .fa {
    color: $color-yellow;
  }
</style>
