<template>
  <section>
    <div
      v-if="!searchClient || loading"
      class="text-center">
      <coozzy-spinner />
    </div>
    <ais-instant-search
      v-else
      ref="instant-search"
      :search-client="searchClient"
      index-name="object_marketing"
      :routing="routing">
      <app-refresh ref="app-refresh" />
      <ais-stats>
        <template slot-scope="{ nbHits }">
          <coozzy-page-title
            :title="$t('message.managementOverview.marketing')"
            :count="nbHits"
            :count-text="$t('message.managementOverview.objects')"
            :clear-filter="checkfilter"
            @clear-filter="clickOnClear" />
        </template>
      </ais-stats>
      <!-- Filter -->
      <div class="row">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-search-box>
            <div slot-scope="{ currentRefinement }">
              <debounced-algolia-search-box
                ref="debouncedAlgolia"
                :default-value="currentRefinement"
                :delay="300" />
            </div>
          </ais-search-box>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="marketingStatus.status"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchStatus', items) }}
              <coozzy-multiselect
                ref="searchStatusSelect"
                v-model="searchStatus"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.status')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelStatus"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="onRefine($event.value,refine)"
                @remove="onRefine($event.value,refine)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="category"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchCategories', items) }}
              <coozzy-multiselect
                v-model="searchCategories"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.onBoarding.buildings.objects.category.title')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelCategory"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="onRefine($event.value,refine)"
                @remove="onRefine($event.value,refine)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="rooms"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchRooms', items) }}
              <coozzy-multiselect
                v-model="searchRooms"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.rooms')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="onRefine($event.value,refine)"
                @remove="onRefine($event.value,refine)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="assignees.marketing.name"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchAssignees', items) }}
              <coozzy-assignee-multiselect
                v-model="searchAssignees"
                :options="sortedFilterValues(getAssigneeData(items), customLabelAssignee)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.assignee')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelAssignee"
                :group-select="true"
                :track-by="'value'"
                label="name"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="onRefine($event.value,refine)"
                @remove="onRefine($event.value,refine)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="requestStatistics.status"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchRequestStatus', items) }}
              <coozzy-multiselect
                v-model="searchRequestStatus"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.request')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelRequestStatus"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="onRefine($event.value,refine)"
                @remove="onRefine($event.value,refine)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0 mt-md-1">
          <ais-refinement-list
            attribute="owner.name"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchAdminOwner', items) }}
              <coozzy-multiselect
                v-model="searchAdminOwner"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.owner')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                label="name"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="networkPartnerAsset.name"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchAssetManager', items) }}
              <coozzy-multiselect
                v-model="searchAssetManager"
                :options="sortOptions(items)"
                :multiple="true"
                :placeholder="$t('message.networkPartner.subTypes.asset_management')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="networkPartnerAdministration.name"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchAdministration', items) }}
              <coozzy-multiselect
                v-model="searchAdministration"
                :options="getAdministrationData(items)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.administration')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelAssignee"
                :track-by="'value'"
                label="name"
                group-values="employees"
                group-label="employee"
                :group-select="true"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="networkPartnerAccounting.name"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchAccounting', items) }}
              <coozzy-multiselect
                v-model="searchAccounting"
                :options="sortOptions(items)"
                :multiple="true"
                :placeholder="$t('message.networkPartner.subTypes.accounting')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </div>
          </ais-refinement-list>
        </div>
      </div>
      <!-- eslint-disable vue/attribute-hyphenation -->
      <ais-configure
        :hitsPerPage="nbrPerPage"
        :filters="filterQuery" />
      <!-- eslint-disable vue/attribute-hyphenation -->
      <div
        id="rowConfig"
        class="row mt-2 align-items-center">
        <div
          v-if="linkMail === ''"
          class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              v-if="isTicketingEnabled && (isEditor || isTicketEditor)"
              :disabled="selectedEntries.length === 0"
              @click="createTicket">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? $refs['reminder-creation'].show() : ''">
              {{ $t('message.calendar.reminder.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider />
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="openSetAssigneeModal(null)">
              {{ $t('message.marketingObjects.setAssignee') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="$bvModal.show('openExposeModal')">
              {{ $t('message.advertisement.action.openExpose') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider />
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="openRemoveFromMarketingModal">
              {{ $t('message.marketingObjects.removeFromMarketing') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
          <ais-clear-refinements :included-attributes="['query', 'marketingStatus.status', 'category', 'rooms', 'assignees.marketing.name', 'requestStatistics.status','owner.name', 'assignees.administration.name', 'networkPartnerAsset.name', 'networkPartnerAdministration.name', 'networkPartnerAccounting.name']">
            <div slot-scope="{ canRefine, refine }">
              {{ setCanRefine(canRefine) }}
              <a
                v-if="canRefine"
                ref="clearClick"
                @click="initFilter(refine)" />
            </div>
          </ais-clear-refinements>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          :class="linkMail === '' ? 'offset-md-5' : 'offset-md-7'"
          class="col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12  mt-2 mt-sm-0"
          :class="!isCompanyAdmin && linkMail === '' ? 'offset-md-5' : !isCompanyAdmin && linkMail !== '' ? 'offset-md-7' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <ais-state-results>
        <template
          slot-scope="{ hits, results }">
          {{ getState(results) }}
          <b-table
            ref="objectsTable"
            :busy="isBusy"
            class="mt-2"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            :fields="fields"
            :items="filteredHits(hits)"
            @row-clicked="onRowClicked"
            @row-middle-clicked="onMiddleClicked">
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(status)>
              {{ $t('message.tableColumnHeadings.status') }}
            </template>
            <template #head(thumbnail)>
              {{ $t('message.generic.thumbnail') }}
            </template>
            <template #head(numericId)>
              {{ $t('message.tableColumnHeadings.numericId') }}
            </template>
            <template #head(publicId)>
              {{ $t('message.tableColumnHeadings.publicId') }}
            </template>
            <template #head(address)>
              {{ $t('message.tableColumnHeadings.address') }}
            </template>
            <template #head(type)>
              {{ $t('message.tableColumnHeadings.type') }}
            </template>
            <template #head(floor)>
              {{ $t('message.tableColumnHeadings.floor') }}
            </template>
            <template #head(rooms)>
              {{ $t('message.tableColumnHeadings.rooms') }}
            </template>
            <template #head(livingArea)>
              {{ $t('message.tableColumnHeadings.livingArea') }}
            </template>
            <template #head(assignee)>
              {{ $t('message.tableColumnHeadings.assignee') }}
            </template>
            <template #head(request)>
              {{ $t('message.tableColumnHeadings.request') }}
            </template>
            <template #head(appointment)>
              {{ $t('message.tableColumnHeadings.appointment') }}
            </template>
            <template #head(applicationForm)>
              {{ $t('message.tableColumnHeadings.applicationForm') }}
            </template>
            <template #head(netRent)>
              {{ $t('message.tableColumnHeadings.netRent') }}
            </template>
            <template #head(grossRent)>
              {{ $t('message.tableColumnHeadings.grossRent') }}
            </template>
            <template #head(viewingContact)>
              {{ $t('message.tableColumnHeadings.viewingContact') }}
            </template>
            <template #head(availableFrom)>
              {{ $t('message.tableColumnHeadings.availableFrom') }}
            </template>
            <template #head(objects)>
              {{ $t('message.tableColumnHeadings.objects') }}
            </template>

            <!-- Data -->
            <template
              #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="'check_' + data.item.objectID"
                @change="(value) => { itemSelected(data.item, value) }" />
            </template>
            <template #cell(status)="data">
              <div
                :id="data.item.objectID"
                :class="calculateMarketingStatus(data.item.marketingStatus).color">
                {{ calculateMarketingStatus(data.item.marketingStatus).label }}
                <template v-if="calculateMarketingStatus(data.item.marketingStatus).date">
                  <br>
                  {{ calculateMarketingStatus(data.item.marketingStatus).date }}
                </template>
                <template v-if="data.item.marketingStatus.status.includes('reserved')">
                  <br>
                  {{ $t('message.manageInterestedParties.reserved') }}
                </template>
                <template v-if="data.item.marketingStatus.status.includes('rented')">
                  <template v-if="data.item.futureTenancy && data.item.futureTenancy.startingAt">
                    <br>
                    {{ data.item.futureTenancy.startingAt | objectToDate }}
                  </template>
                  <template v-else-if="data.item.tenancy && data.item.tenancy.startingAt">
                    <br>
                    {{ data.item.tenancy.startingAt | objectToDate }}
                  </template>
                  <br>
                  {{ $t('message.marketingObjects.status.' + data.item.inMarketingStatus) }}
                </template>
              </div>
              <b-tooltip
                v-if="calculateMarketingStatus(data.item.marketingStatus).label === 'Online'"
                :target="data.item.objectID"
                :custom-class="'tooltip-marketing'"
                placement="bottom">
                <template v-if="data.item.exportPlatforms.length > 0">
                  {{ $t('message.marketingObjects.onlineTooltip') }}
                  <ul class="text-left">
                    <li
                      v-for="platform in data.item.exportPlatforms"
                      :key="platform">
                      {{ $t('message.export.' + platform) }}
                    </li>
                  </ul>
                  <br>
                </template>
                <template v-if="data.item.marketingStatus.startMarketingAt">
                  {{ $t('message.marketingObjects.startMarketing') }} : {{ data.item.marketingStatus.startMarketingAt | timestampToDate }}
                  <br>
                </template>
                <template v-if="data.item.marketingStatus.marketingNote">
                  {{ $t('message.marketingObjects.marketingNote') }} : <br>
                  {{ data.item.marketingStatus.marketingNote }}
                </template>
              </b-tooltip>
            </template>
            <template #cell(thumbnail)="data">
              <coozzy-thumbnail
                v-if="data.item.images && data.item.images.length > 0"
                :src="sortImageByOrder(data.item.images)[0].thumbnailSUrl"
                :original="sortImageByOrder(data.item.images)[0].url" />
              <div
                v-else
                class="d-flex justify-content-center">
                <coozzy-thumbnail
                  class="thumbnail"
                  src="/img/image-placeholder.png"
                  alt="Placeholder" />
              </div>
            </template>
            <template #cell(numericId)="data">
              {{ data.item.numericId | displayOptionalValue }}
            </template>
            <template #cell(publicId)="data">
              {{ data.item.publicId | displayOptionalValue }}
            </template>
            <template #cell(address)="data">
              <address-text :address="data.item.address" />
            </template>
            <template
              #cell(type)="data">
              <template v-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template
              #cell(floor)="data">
              <div class="text-center">
                {{ formatFloor(data.item.floor) }}
              </div>
            </template>
            <template
              #cell(rooms)="data">
              <div class="text-center">
                {{ data.item.rooms | displayOptionalValue }}
              </div>
            </template>
            <template
              #cell(livingArea)="data">
              <div class="text-center">
                {{ data.item.livingArea | formatArea }}
              </div>
            </template>
            <template #cell(assignee)="data">
              <div class="text-center">
                <coozzy-button
                  design="default"
                  size="small"
                  @click="openSetAssigneeModal(data.item)">
                  {{
                    data.item.assignees && data.item.assignees.marketing && data.item.assignees.marketing.name && data.item.assignees.marketing.name !== 'Unassigned Nicht zugeordnet Non assegnato' ? data.item.assignees.marketing.name : '-'
                  }}
                </coozzy-button>
              </div>
            </template>
            <template #cell(request)="data">
              <div class="row justify-content-start badgeFilter">
                <div class="col-4 text-center-badge badgeOverall">
                  {{ $t('message.manageInterestedParties.status.all') }}:
                </div>
                <div class="col-3 badgeFilter">
                  <coozzy-badge
                    size="large"
                    class="badgeOverall"
                    design="grey">
                    {{ data.item.requestStatistics.overall }}
                  </coozzy-badge>
                </div>
              </div>
              <div class="row justify-content-start mt-1 badgeFilter">
                <div class="col-4 text-center-badge badgeNew">
                  {{ $t('message.manageInterestedParties.status.new') }}:
                </div>
                <div class="col-3 badgeFilter">
                  <coozzy-badge
                    size="large"
                    class="badgeNew"
                    :design="data.item.requestStatistics.new > 0 ? 'red' : 'grey'">
                    {{ data.item.requestStatistics.new }}
                  </coozzy-badge>
                </div>
              </div>
              <div class="row justify-content-start mt-1 badgeFilter">
                <div class="col-4 text-center-badge badgeResponse">
                  {{ $t('message.manageInterestedParties.status.responses_short') }}:
                </div>
                <div class="col-3 badgeFilter">
                  <coozzy-badge
                    size="large"
                    class="badgeResponse"
                    :design="data.item.requestStatistics.unread > 0 ? 'red' : 'grey'">
                    {{ data.item.requestStatistics.unread }}
                  </coozzy-badge>
                </div>
              </div>
            </template>
            <template #cell(appointment)="data">
              <div class="badgeFilter">
                <div class="badgeDone badgeDone-title">
                  {{ $t('message.onBoarding.buildings.statistics.done') }}:
                </div>
                <coozzy-badge
                  class="p-1 badgeDone"
                  size="large"
                  design="grey">
                  {{ data.item.requestStatistics.appointmentsDone }}
                </coozzy-badge>
              </div>
            </template>
            <template #cell(applicationForm)="data">
              <div class="badgeFilter">
                <div class="badgeReceived badgeReceived-title">
                  {{ $t('message.onBoarding.buildings.statistics.received') }}:
                </div>
                <coozzy-badge
                  class="p-1 badgeReceived"
                  size="large"
                  design="grey">
                  {{ data.item.requestStatistics.applicationsReceived }}
                </coozzy-badge>
              </div>
            </template>
            <template
              #cell(netRent)="data">
              <div class="text-center">
                {{ data.item.pricing.netRent | formatPrice }}
              </div>
            </template>
            <template
              #cell(grossRent)="data">
              <div class="text-center">
                {{ data.item.pricing.grossRent | formatPrice }}
              </div>
            </template>
            <template
              #cell(objects)="data">
              <div class="text-center">
                {{ data.item.name | displayOptionalValue }}
              </div>
            </template>
            <template #cell(viewingContact)="data">
              <template v-if="data.item.assignees && data.item.assignees.viewingAppointment && data.item.assignees.viewingAppointment.contactId && data.item.assignees.viewingAppointment.contactId !== ''">
                <router-link
                  :to="{ name: 'AdminContactDetailsView', params: { id: data.item.assignees.viewingAppointment.contactId } }">
                  <span v-if="data.item.assignees.viewingAppointment.name !== 'Unassigned Nicht zugeordnet Non assegnato'">
                    {{ data.item.assignees.viewingAppointment.name }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </router-link>
              </template>
              <template v-else-if="data.item.assignees.viewingAppointment.name !== 'Unassigned Nicht zugeordnet Non assegnato'">
                <span>
                  {{ data.item.assignees.viewingAppointment.name }}
                </span>
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(availableFrom)="data">
              <span v-if="data.item.tenancy.nextPossibleMoveInAt && data.item.tenancy.vacancyReason === 'VACANCY_REASON_RENOVATION'">{{ oneDayAfter(data.item.tenancy.nextPossibleMoveInAt) | objectToDate }}</span>
              <span v-else-if="data.item.tenancy.vacancyReason === 'VACANCY_REASON_RENOVATION'">
                <coozzy-info-circle-icon
                  v-b-tooltip="{boundary : 'window', title : $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.renovationTooltip')}" />
              </span>
              <span v-else-if="data.item.tenancy.nextPossibleMoveInAt">{{ data.item.tenancy.nextPossibleMoveInAt | objectToDate }}</span>
              <span v-else>-</span>
            </template>
          </b-table>
        </template>
      </ais-state-results>
      <pagination-algolia
        ref="paginator"
        @changed-page="clearCheckbox()" />
    </ais-instant-search>
    <set-advert-relations
      ref="setAssigneeModal"
      :title="$t('message.advertisementsOverview.setAssignee')"
      :element-list="contactList"
      type="employee"
      @save="setAssignee" />
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.marketingObjects.confirmModal.confirmationTitle')">
      <div class="col pl-0">
        <p>{{ textConfirmation }}</p>
      </div>
      <div class="col pl-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          v-if="actionPublish === 'publish'"
          size="small"
          class="float-right mb-0"
          design="green"
          @click="setPublish()">
          {{ $t('message.marketingObjects.publish') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="'removeFromMarketingModal'"
      ref="removeFromMarketingModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.marketingObjects.removeFromMarketing')">
      <div
        v-if="removeFromMarketingLoading"
        class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
      <template v-else>
        <div class="col pl-0">
          <p>
            {{ $t('message.marketingObjects.confirmModal.removeFromMarketingText') }}
          </p>
        </div>
        <div class="col pl-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            :disabled="removeFromMarketingLoading"
            @click="$bvModal.hide('removeFromMarketingModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            :disabled="removeFromMarketingLoading"
            @click="removeFromMarketing()">
            {{ $t('message.generic.confirm') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <reminder-creation-modal
      ref="reminder-creation"
      :references-ids="selectedEntriesIds"
      :source="'object'"
      @new-reminder="clearCheckbox" />
    <b-modal
      :id="'openExposeModal'"
      ref="openExposeModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.advertisement.action.openExpose')">
      <template>
        <div class="col-12">
          <p>{{ $t('message.advertisement.action.titleModal') }}</p>
        </div>
        <div class="col-12 mt-3 mb-3">
          <coozzy-form-select
            v-model="language">
            <option
              v-for="option in optionsLanguages"
              :key="option.key"
              :value="option.key">
              {{ option.value }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('openExposeModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="openAdvertExpose">
            {{ $t('message.generic.confirm') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import ContactApi from '../misc/apis/ContactApi'
import AddressText from '../framework/components/misc/AddressText'
import { onboarding } from '@/mixins/onboarding'
import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import { algolia } from '@/mixins/algolia'
import ObjectApi from '../misc/apis/ObjectApi'
import Vue from 'vue'
import { tables } from '@/mixins/tables'
import CoozzyFormCheckbox from '../framework/components/form/checkbox/CoozzyFormCheckbox'
import SetAdvertRelations from '@/marketingBasic/components/advertisements/management/SetAdvertRelations.vue'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import AppRefresh from '../framework/components/RefreshAlgolia'
import DebouncedAlgoliaSearchBox from '../framework/components/DebouncedAlgoliaSearchBox'
import PaginationAlgolia from '../framework/components/PaginationAlgolia'
import CoozzyBadge from '../framework/components/badge/CoozzyBadge'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CoozzyAssigneeMultiselect from '../framework/components/multiselect/CoozzyAssigneeMultiselect'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import SearchApi from '@/misc/apis/SearchApi'
import AssembleListModal from '@/contact/components/AssembleListModal'
import CoozzyThumbnail from '@/framework/components/img/CoozzyThumbnail'
import { user } from '@/mixins/user'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import { mapActions, mapGetters } from 'vuex'
import RequestApi from '@/marketingBasic/api/RequestApi'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'

export default {
  name: 'MarketingObjectsView',
  components: {
    CoozzyInfoCircleIcon,
    CoozzyFiltersIcon,
    CoozzyThumbnail,
    CoozzyFormSelect,
    CoozzyDropdownSpaceDivider,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyMultiselect,
    CoozzyPageTitle,
    CoozzySpinner,
    AddressText,
    CoozzyAssigneeMultiselect,
    AssembleListModal,
    DebouncedAlgoliaSearchBox,
    AppRefresh,
    PaginationAlgolia,
    CoozzyButton,
    CoozzyFormCheckbox,
    SetAdvertRelations,
    CoozzyBadge,
    ReminderCreationModal
  },
  mixins: [user, onboarding, obj, formatting, algolia, tables],
  props: {
    linkMail: {
      type: String,
      default: ''
    },
    linkEmail: {
      type: String,
      default: ''
    },
    linkContactId: {
      type: String,
      default: ''
    },
    linkRequest: {
      type: String,
      default: ''
    },
    originalObjectId: {
      type: String,
      default: ''
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.marketing.title')
    }
  },
  data() {
    return {
      isBusy: false,
      checkfilter: false,
      showFirst: false,
      searchStatus: [],
      searchRooms: [],
      searchAssignees: [],
      searchRequestStatus: [],
      searchAdminOwner: [],
      searchAssetManager: [],
      searchAdministration: [],
      searchAccounting: [],
      galleryImages: [],
      contactList: [],
      textConfirmation: '',
      actionPublish: null,
      selectedObjectIds: null,
      selectedEntries: [],
      searchCategories: [],
      searchMarketer: [],
      removeFromMarketingLoading: false,
      objectToLink: null,
      contactToLink: null,
      loading: false,
      language: 'EN',
      selectedFields: [],
      fields: [],
      dataFields: [
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'thumbnail',
          label: this.$t('message.generic.thumbnail'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'numericId',
          label: this.$t('message.tableColumnHeadings.numericId'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'publicId',
          label: this.$t('message.tableColumnHeadings.publicId'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'livingArea',
          label: this.$t('message.tableColumnHeadings.livingArea'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'request',
          label: this.$t('message.tableColumnHeadings.request'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'appointment',
          label: this.$t('message.tableColumnHeadings.appointment'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'applicationForm',
          label: this.$t('message.tableColumnHeadings.applicationForm'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'netRent',
          label: this.$t('message.tableColumnHeadings.netRent'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'grossRent',
          label: this.$t('message.tableColumnHeadings.grossRent'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'availableFrom',
          label: this.$t('message.tableColumnHeadings.availableFrom'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'viewingContact',
          label: this.$t('message.tableColumnHeadings.viewingContact'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        }
      ],
      paramsAlgolia: {
        query: '',
        params: '',
        index: ''
      },
      nbrTotalOfHits: 0,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    optionsLanguages() {
      return [
        { key: 'EN', value: this.$t('message.language.english') },
        { key: 'DE', value: this.$t('message.language.german') },
        { key: 'IT', value: this.$t('message.language.italian') }]
    },
    getUserLang() {
      return this.$store.getters['user/getCurrentUser']['https://coozzy.ch/lang'].toUpperCase()
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.objectID) : this.selectedEntries.map(obj => obj.objectID)
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    filterQuery() {
      let query = 'marketingStatus.inMarketing:true'

      if (!this.isPlatformEmployee) {
        query += ` AND networkPartners.combinedId:${this.accountId.toString()}-marketing`
      }

      return query
    }
  },
  watch: {
    checkfilter: function () {
      this.clearCheckbox()
    },
    nbrPerPage: function () {
      if (this.showFirst) {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.refresh()
          }
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.redirect(this.$route.query.page)
          }
        })
      }
      this.showFirst = true
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        // check if we already call algolia with the same params
        if (this.nbrTotalOfHits !== this.allEntries.length) {
          this.allEntries = await this.getAllAvailableDataByIndex(this.paramsAlgolia)
        }
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.language = this.getUserLang || 'DE'
    })
    if (this.linkMail === '' || this.linkRequest === '') {
      this.fields.unshift(
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        })
    }
    if (localStorage.getItem('managementObjectsConfig') !== null && localStorage.getItem('managementObjectsConfig') !== '') {
      const res = localStorage.getItem('managementObjectsConfig').split(',')
      for (let index = 0; index < res.length; index++) {
        const item = this.dataFields.filter(x => x.key === res[index])
        this.fields.push(item)
      }
    } else {
      this.fields.push(...this.dataFields.filter(x => x.key === 'status' || x.key === 'thumbnail' || x.key === 'numericId' || x.key === 'publicId' || x.key === 'address' || x.key === 'type' || x.key === 'floor' || x.key === 'rooms' || x.key === 'livingArea' || x.key === 'assignee' || x.key === 'request' || x.key === 'appointment' || x.key === 'applicationForm' || x.key === 'netRent' || x.key === 'grossRent'))
    }
    if (this.linkContactId) {
      ContactApi.contactResolve([this.linkContactId])
        .then(response => {
          this.contactToLink = response.persons.concat(response.companies)[0]
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    }
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'marketing_object_view') {
            this.loadFields(setting)
          }
        })
      }
    }).catch(e => {
      console.log(e)
    })
    this.getContacts()
    this.loadInstantSearch()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    getState(result) {
      this.nbrTotalOfHits = result.nbHits
      const params = this.editAlgoliaParams(result.params)
      this.paramsAlgolia = {
        query: result.query,
        params: params,
        index: result.index,
        attributesToRetrieve: ['objectID']
      }
      return ''
    },
    filteredHits(hits) {
      if (this.linkRequest !== '' && this.originalObjectId !== '') {
        return hits.filter(hit => {
          return hit.objectID !== this.originalObjectId
        })
      }
      return hits
    },
    createTicket() {
      const defaultAssignee = this.selectedEntries.every((val, i, arr) => val.networkPartnerAsset?.accountId === arr[0].networkPartnerAsset?.accountId)
        ? this.selectedEntries[0].networkPartnerAsset.accountId
: this.selectedEntries.every((val, i, arr) => val.networkPartnerAdministration?.accountId === arr[0].networkPartnerAdministration?.accountId)
          ? this.selectedEntries[0].networkPartnerAdministration.accountId
: ''
      this.$router.push({ name: 'MarketingTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee } })
    },
    async openAdvertExpose() {
      if (this.selectedEntriesIds.length > 0) {
        for (const element of this.selectedEntriesIds) {
          this.loading = true
          this.$bvModal.hide('openExposeModal')
          const blob = new Blob([await ObjectApi.getMarketingExpose(element, this.language)], { type: 'application/pdf' })
          const blobURL = URL.createObjectURL(blob)
          window.open(blobURL)
          this.loading = false
        }
        this.clearCheckbox()
      }
    },
    getSortedHits(hits) {
      return hits.sort(function (a, b) {
        if (a.marketingStatus.status === 'rented') return 1
        if (b.marketingStatus.status === 'rented') return -1
        return 0
      })
    },
    getContacts() {
      ContactApi.getContacts(this.$store.getters['user/getAccountId'])
        .then(response => {
          const allContacts = response.persons.concat(response.companies)
          this.contactList = allContacts.filter(con => con.types.includes('EMPLOYEE'))
          this.contactList = this.contactList.filter(contact => {
            const matchingEmployee = this.employeeList
              .filter(employee => !employee.blocked)
              .find(employee => employee?.email?.toLowerCase()?.trim() === contact?.email?.toLowerCase()?.trim())

            return matchingEmployee !== undefined && matchingEmployee !== null
          })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        }).finally(() => {
          setTimeout(() => {
            this.$refs.debouncedAlgolia?.setFocusInput()
          }, 300)
        })
    },
    clickOnClear() {
      this.$refs.clearClick.click()
    },
    setCanRefine(canRefine) {
      this.checkfilter = canRefine
      return ''
    },
    customLabelCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelRequestStatus(item) {
      if (item.value === 'none') {
        return '-'
      }
      return `${this.$t('message.manageInterestedParties.status.' + item.value)} (${item.count})`
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.linkEmail === '') {
        if (this.$refs.header_checkbox) {
          this.$refs.header_checkbox.checked = false
        }
        this.handleCheckboxesInsideTable(false)
      }
    },
    onRefine(item, refine) {
      this.isBusy = true
      refine(item)
      setTimeout(() => {
        this.isBusy = false
      }, 300)
    },
    selectValue(item, items, refine) {
      this.isBusy = true
      if (item === undefined) {
        if (this.searchAssignees.length > 0) {
          this.searchAssignees.map(a => a.value).forEach(element => {
            refine(element)
          })
        }
        const result = items.map(a => a.value)
        result.forEach(element => {
          refine(element)
        })
      } else {
        refine(item)
      }
      setTimeout(() => {
        this.isBusy = false
      }, 300)
    },
    removeValue(item, items, refine) {
      this.isBusy = true
      if (item === undefined) {
        const result = items.map(a => a.value)
        result.forEach(element => {
          refine(element)
          this.searchAssignees.length = 0
        })
      } else {
        refine(item)
      }
      setTimeout(() => {
        this.isBusy = false
      }, 300)
    },
    customLabel(item) {
      if (item.value === 'Unassigned Nicht zugeordnet Non assegnato') {
        return this.$t('message.generic.unassigned') + ' (' + item.count + ')'
      }
      return item.value + ' (' + item.count + ')'
    },
    customLabelStatus(item) {
      return this.$t('message.marketingObjects.status.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    onRowClicked(item, index, event) {
      if (event.srcElement.parentElement.classList.contains('badgeFilter')) {
        let filterProps = ''
        if (event.srcElement.classList.contains('badgeOverall')) {
          filterProps = 'all'
        } else if (event.srcElement.classList.contains('badgeNew')) {
          filterProps = 'new'
        } else if (event.srcElement.classList.contains('badgeResponse')) {
          filterProps = 'responses'
        } else if (event.srcElement.classList.contains('badgeDone')) {
          filterProps = 'viewingAppointmentDone'
        } else if (event.srcElement.classList.contains('badgeReceived')) {
          filterProps = 'applicationFormReceived'
        }
        this.$router.push({
          name: 'MarketingObjectDetailsView',
          params: { id: item.objectID },
          query: {
            view: 0,
            filter: filterProps
          }
        })
      } else if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
        const element = document.getElementById('check_' + item.objectId)
        if (element) {
          this.itemSelected(item, !element.checked)
          element.checked = !element.checked
        }
      } else {
        if (this.linkEmail !== '' || this.linkMail !== '' || this.linkRequest !== '') {
          this.objectToLink = item
          if (this.linkRequest !== '') {
            this.linkRequestToObject()
          } else {
            this.$router.push({
              name: 'MarketingObjectDetailsView',
              params: { id: item.objectID },
              query: {
                view: 0,
                linkMail: this.linkMail,
                linkEmail: this.linkEmail
              }
            })
          }
        } else {
          this.navigateToOverview(item, index, event, false)
        }
      }
    },
    onMiddleClicked(item, index, event) {
      if (event.srcElement.parentElement.classList.contains('badgeFilter')) {
        let filterProps = ''
        if (event.srcElement.classList.contains('badgeOverall')) {
          filterProps = 'all'
        } else if (event.srcElement.classList.contains('badgeNew')) {
          filterProps = 'new'
        } else if (event.srcElement.classList.contains('badgeResponse')) {
          filterProps = 'responses'
        } else if (event.srcElement.classList.contains('badgeDone')) {
          filterProps = 'viewingAppointmentDone'
        } else if (event.srcElement.classList.contains('badgeReceived')) {
          filterProps = 'applicationFormReceived'
        }
        const route = this.$router.resolve({
          name: 'MarketingObjectDetailsView',
          params: { id: item.objectID },
          query: {
            view: 0,
            filter: filterProps
          }
        })
        window.open(route.href)
      } else {
        this.navigateToOverview(item, index, event, true)
      }
    },
    linkRequestToObject() {
      const promises = []
      const arrayRequest = this.linkRequest.split(',')
      arrayRequest.forEach(element => {
        promises.push(RequestApi.moveRequest(element, this.objectToLink.objectID))
      })
      Promise.all(promises)
        .then(() => {
          Vue.toasted.show(this.$t('message.successMessages.requesMoved'), { type: 'success' })
          if (arrayRequest.length > 1) {
            this.$router.push({ name: 'MarketingObjectDetailsView', params: { id: this.objectToLink.objectID }, query: { view: 0 } })
          } else {
            this.$router.push({ name: 'MarketingObjectDetailsView', params: { id: this.objectToLink.objectID }, query: { view: 0, elementSelected: arrayRequest[0] } })
          }
        })
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.objectsTable) {
        const allCheckboxesComponents = this.$refs.objectsTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    initFilter(refine) {
      this.searchStatus = []
      this.searchRooms = []
      this.searchAssignees = []
      this.searchCategories = []
      this.searchAdminOwner = []
      this.searchRequestStatus = []
      this.searchAssetManager = []
      this.searchAdministration = []
      this.searchAccounting = []
      refine()
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.objectID !== item.objectID)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.objectsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'marketing_object_view').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    navigateToOverview(item, index, event, newTab = false) {
      const element = event.srcElement
      if (element.tagName === 'IMG' && element.classList.contains('thumbnail')) {
        if (item.images.length > 0) {
          this.galleryImages = [this.sortImageByOrder(item.images).map(x => x.url)[0]]
          this.$nextTick(function () {
            this.$viewerApi({
              images: this.galleryImages,
              options: this.viewerOptions
            })
          })
        }
      } else {
        if (item.marketingStatus.newInMarketing === true) {
          ObjectApi.marketingMarkSeen(item.objectID)
            .catch(e => {
              console.log(e)
            })
        }
        if (!newTab) {
          this.$router.push({
            name: 'MarketingObjectDetailsView',
            params: { id: item.objectID },
            query: { view: 0 }
          })
        } else {
          const route = this.$router.resolve({
            name: 'MarketingObjectDetailsView',
            params: { id: item.objectID },
            query: { view: 0 }
          })
          window.open(route.href)
        }
      }
    },
    setPublish() {
      if (this.actionPublish === 'publish') {
        ObjectApi.publishObject(this.selectedEntriesIds)
          .then(() => {
            this.isBusy = true
            this.$refs.confirmationModal.hide()
            Vue.toasted.show(this.$t('message.successMessages.publishObject'), { type: 'success' })
            setTimeout(() => {
              this.$refs['app-refresh'].refresh()
              setTimeout(() => {
                this.isBusy = false
              }, 1000)
            }, 1700)
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.publishObject'), { type: 'error' })
          })
      } else if (this.actionPublish === 'unPublish') {
        ObjectApi.unPublishObject(this.selectedEntriesIds)
          .then(() => {
            this.isBusy = true
            this.$refs.confirmationModal.hide()
            Vue.toasted.show(this.$t('message.successMessages.unPublishObject'), { type: 'success' })
            setTimeout(() => {
              this.$refs['app-refresh'].refresh()
              setTimeout(() => {
                this.isBusy = false
              }, 1000)
            }, 1700)
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.unPublishObject'), { type: 'error' })
          })
      }
    },
    openSetAssigneeModal(item) {
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      if (item === null) {
        this.selectedObjectIds = this.selectedEntriesIds
      } else {
        this.selectedObjectIds = [item.objectID]
      }
      this.$refs.setAssigneeModal.newElementId =
        item && item.assignees && item.assignees.marketing ? item.assignees.marketing.contactId || null : null
      this.$refs.setAssigneeModal.setModalShow = true
    },
    openRemoveFromMarketingModal() {
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      this.$refs.removeFromMarketingModal.show()
    },
    removeFromMarketing() {
      this.removeFromMarketingLoading = true
      ObjectApi.removeFromMarketing(this.selectedEntriesIds)
        .then(() => {
          this.clearCheckbox()
          setTimeout(() => {
            this.$refs['app-refresh'].refresh()
            this.removeFromMarketingLoading = false
            this.$refs.removeFromMarketingModal.hide()
            Vue.toasted.show(this.$t('message.successMessages.removeFromMarketing'), { type: 'success' })
          }, 2000)
        })
        .catch(e => {
          console.log(e)
        })
    },
    setAssignee(newEmployee) {
      this.$refs.setAssigneeModal.setModalDisabled = true
      ObjectApi.setMarketingAssignee(newEmployee ? newEmployee.id : null, this.selectedObjectIds)
        .then(() => {
          this.clearCheckbox()
          setTimeout(() => {
            this.$refs['app-refresh'].refresh()
            Vue.toasted.show(this.$t('message.successMessages.setMarketingObjectAssignee'), { type: 'success' })
            this.$refs.setAssigneeModal.setModalShow = false
            this.$refs.setAssigneeModal.setModalDisabled = false
          }, 2000)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.setMarketingObjectAssignee'), { type: 'error' })
        })
    },
    redirectToAllRequest() {
      this.$router.push({
        name: 'MarketingObjectDetailsView',
        params: { id: this.id },
        query: {
          view: 0,
          elementSelected: ''
        }
      })
    },
    sortImageByOrder(images) {
      const imgs = [...images]
      return imgs.sort((a, b) => (a.order > b.order ? 1 : -1))
    },
    sortOptions(item) {
      return item.sort(function(a, b) {
        if (a.value === b.value) return 0
        if (a.value === 'Unassigned Nicht zugeordnet Non assegnato') return 1
        if (b.value === 'Unassigned Nicht zugeordnet Non assegnato') return -1
        return 0
      })
    },
    getAdministrationData(items) {
      items.forEach(element => {
        const emp = this.employeeList.find(x => (x.profile.lastName + ' ' + x.profile.firstName) === element.label)
        if (emp) {
          element.active = !emp.blocked
        }
      })
      return [{
        employee: this.$t('message.search.filterAll'),
        employees: items
      }]
    },
    getAssigneeData(items) {
      items.forEach(element => {
        const emp = this.employeeList.find(x => (x.profile.lastName + ' ' + x.profile.firstName) === element.label)
        if (emp) {
          element.active = !emp.blocked
        }
      })
      return items
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(th button) {
  padding-right: 0 !important;
}

:deep(.span-dropdown) {
  padding: 0 !important;
}

.clickable-data {
  text-decoration: underline;
  color: $color-blue-dark;
}

.text-center-badge {
  margin-top: 0.125rem;
}
.badgeDone-title{
  display: inline !important;
}
.badgeReceived-title{
  display: inline !important;
}
</style>
