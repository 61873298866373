// @ts-nocheck
import network from './network'

export default {
  toObject(stringDate) {
    if (!stringDate) {
      return null
    }

    const convDate = new Date(stringDate)
    return convDate instanceof Date && isFinite(convDate.getTime())
? {
      day: convDate.getDate(),
      month: convDate.getMonth() + 1,
      year: convDate.getFullYear()
    }
: null
  },
  getReminders(ownerId, employeeIds, priority) {
    let employeeFilter = ''
    for (const employeeId of employeeIds) {
      employeeFilter += `&employeeIds=${employeeId}`
    }

    if ((priority !== '' && priority !== null) || (employeeIds.length > 0)) {
      if (priority === null || priority === '') {
        return network.get(`web/calendar/reminder/list/${ownerId}?${employeeFilter.substr(1)}`)
          .then(response => {
            return response.data
          })
      }
      return network.get(`web/calendar/reminder/list/${ownerId}?${employeeFilter.substr(1)}&priority=${priority}`)
        .then(response => {
          return response.data
        })
    } else {
      return network.get(`web/calendar/reminder/list/${ownerId}`)
        .then(response => {
          return response.data
        })
    }
  },
  getReminderById(reminderId) {
    return network.get(`/web/calendar/reminder/${reminderId}`)
      .then(response => {
        return response.data
      })
  },
  createReminder(ownerId, reminder, referenceIds = []) {
    return network.post('/web/calendar/reminder/create', {
      activity: reminder.activity,
      contactId: reminder.contactId,
      date: Math.round(reminder.date.getTime() / 1000),
      done: 0,
      employeeUserId: reminder.employeeUserId,
      ownerId: ownerId,
      priority: reminder.priority,
      referenceIds: referenceIds,
      subject: reminder.subject
    })
      .then(response => {
        return response.data
      })
  },
  createBulkReminder(ownerId, reminder, referenceIds = []) {
    return network.post('/web/calendar/reminder/createBulk', {
      activity: reminder.activity,
      contactId: reminder.contactId,
      date: Math.round(reminder.date.getTime() / 1000),
      done: 0,
      employeeUserId: reminder.employeeUserId,
      ownerId: ownerId,
      priority: reminder.priority,
      buckets: referenceIds.map(bucket => {
        return {
          referenceIds: [bucket]
        }
      }),
      subject: reminder.subject
    })
      .then(response => {
        return response.data
      })
  },
  updateReminder(reminder) {
    return network.post('/web/calendar/reminder/update', {
      id: reminder.id,
      activity: reminder.activity,
      contactId: reminder.contactId,
      date: reminder.date,
      done: 0,
      employeeUserId: reminder.employeeUserId,
      priority: reminder.priority,
      referenceIds: reminder.referenceIds,
      subject: reminder.subject
    })
      .then(response => {
        return response.data
      })
  },
  setDone(ids) {
    return network.post('/web/calendar/reminder/setDone', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  setUnDone(ids) {
    return network.post('/web/calendar/reminder/setUnDone', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  updateDate(ids, date) {
    return network.post('/web/calendar/reminder/updateDate', {
      ids: ids,
      date: date
    })
      .then(response => {
        return response.data
      })
  },
  updateEmployee(ids, employeeUserId) {
    return network.post('/web/calendar/reminder/updateEmployee', {
      ids: ids,
      employeeUserId: employeeUserId
    })
      .then(response => {
        return response.data
      })
  },
  updatePriority(ids, priority) {
    return network.post('/web/calendar/reminder/updatePriority', {
      ids: ids,
      priority: priority
    })
      .then(response => {
        return response.data
      })
  },
  countOverdueReminders(ownerId, userId) {
    return network.get(`web/calendar/reminder/countOverdueReminders/${ownerId}/user/${userId}`)
      .then(response => {
        return response.data
      })
  },
  getRemindersByReferenceIds(referenceIds, ownerId = '', includeDone = false) {
    let url = `web/calendar/reminder/listByReferenceIds/${referenceIds}`
    if (ownerId !== '') {
      url += `?ownerId=${ownerId}`
    }
    url += `&includeDone=${includeDone}`
    return network.get(url)
      .then(response => {
        return response.data
      })
  },
  getEventStat(ownerId, userIds) {
    const now = new Date()
    const nextThreeMonth = (new Date()).setDate(now.getDate() + 90)
    return network.post('web/calendar/listEvents', {
      ownerId: ownerId,
      userIds: userIds,
      sortStart: 'ASC',
      endDate: this.toObject(nextThreeMonth),
      startDate: this.toObject(now)
    }).then(response => {
      return response.data
    })
  },
  getCalDavCredentials(userId) {
    return network.get(`web/calendar/getCalDavCredentials/${userId}`)
      .then(response => {
        return response.data
      })
  },
  createEvent(event, ownerId, participants) {
    return network.post('/web/calendar/createEvent', {
      title: event.title,
      description: event.description,
      categories: [event.category],
      location: event.location,
      ownerId: ownerId,
      referenceIds: event.referenceIds,
      startTime: event.startTimeObject,
      endTime: event.endTimeObject,
      startDate: event.startDateObject,
      endDate: event.endDateObject,
      attendees: participants,
      mailboxId: event.mailBox,
      private: event.private,
      userId: event.userId,
      mediaIds: event.mediaIds,
      alarms: event.alarms,
      frequency: event.frequency,
      endByDateRecurrence: event.endByDateRecurrenceObject,
      countRecurrence: event.countRecurrence,
      endDateOccurrencesResponse: event.endDateOccurrencesResponse,
      startDateOccurrencesResponse: event.startDateOccurrencesResponse
    })
      .then(response => {
        return response.data
      })
  },
  updateEvent(event, ownerId, participants, referenceId = [], occurrenceEventDate = null) {
    const eventData = {
      title: event.title,
      description: event.description,
      categories: [event.category],
      location: event.location,
      ownerId: ownerId,
      url: event.url,
      referenceIds: referenceId,
      startTime: event.startTimeObject,
      endTime: event.endTimeObject,
      startDate: event.startDateObject,
      endDate: event.endDateObject,
      attendees: participants,
      mailboxId: event.mailBox,
      private: event.private,
      userId: event.userId,
      mediaIds: event.mediaIds,
      alarms: event.alarms,
      frequency: event.frequency,
      endByDateRecurrence: event.endByDateRecurrenceObject,
      countRecurrence: event.countRecurrence,
      endDateOccurrencesResponse: event.endDateOccurrencesResponse,
      startDateOccurrencesResponse: event.startDateOccurrencesResponse
    }
    if (occurrenceEventDate !== null) {
      eventData.occurrenceEventDate = occurrenceEventDate
    }
    if (event.resetOccurrencesEvents && occurrenceEventDate === null) {
      eventData.resetOccurrencesEvents = event.resetOccurrencesEvents
    }
    return network.post('/web/calendar/updateEvent', eventData)
      .then(response => {
        return response.data
      })
  },
  deleteEvent(urls, mailBox, type, occurrenceEventDate = null) {
    let url = `web/calendar/deleteEvent/${urls}?mailboxId=${mailBox}`
    if (type === 'single' && occurrenceEventDate) {
      url += `&occurrenceEventDate.year=${occurrenceEventDate.year}&occurrenceEventDate.month=${occurrenceEventDate.month}&occurrenceEventDate.day=${occurrenceEventDate.day}`
    }
    return network.delete(url).then(response => {
      return response.data
    })
  },
  getEvents(ownerId, referenceIds = [], userIds, startDate = null,
    endDate = null) {
    return network.post('web/calendar/listEvents', {
      referenceIds: referenceIds,
      ownerId: ownerId,
      userIds: userIds,
      startDate: startDate,
      endDate: endDate,
      sortStart: 'ASC'
    })
      .then(response => {
        return response.data
      })
  },
  updateAttendeesStatus(url, attendees, occurrenceEventDate = null) {
    const eventData = {
      url: url,
      attendees: attendees
    }
    if (occurrenceEventDate !== null) {
      eventData.occurrenceEventDate = occurrenceEventDate
    }
    return network.post(`/web/calendar/updateAttendeesStatus/${url}`, eventData)
      .then(response => {
        return response.data
      })
  },
  setExternalAttendeesStatus(status, token) {
    return network.post('/web/calendar/setExternalAttendeesStatus', {
      status: status,
      token: token
    })
      .then(response => {
        return response.data
      })
  },
  readEventFromIcal(mediaId) {
    return network.get(`/web/calendar/readEventFromIcal?mediaId=${mediaId}`)
      .then(response => {
        return response.data
      })
  }
}
