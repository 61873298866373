let allLoadedComponents = {}

const requireRootComponents = function () {
  return require.context(
    '@/calendar/components',
    false,
    /[A-Z]\w+\.vue$/
  )
}

const loadComponents = function (components) {
  const loadedComponents = {}

  components.keys().forEach(fileName => {
    const componentConfig = components(fileName)
    const componentName = componentConfig.default.name

    loadedComponents[componentName] = componentConfig.default || componentConfig
  })

  return loadedComponents
}

const loadRootComponents = function () {
  const rootComponents = requireRootComponents()
  allLoadedComponents = loadComponents(rootComponents)
}

loadRootComponents()

export const CalendarComponents = allLoadedComponents
