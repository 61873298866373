<template>
  <section>
    <b-modal
      id="detail-domain-modal"
      ref="detail-domain-modal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.settings.domain.name') ">
      <div
        v-if="domain"
        class="row">
        <div class="col-sm-12 col-md-12">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <span class="font-weight-bold">{{ $t('message.settings.domain.name') }}: </span>  {{ domain.domain }}
            </div>
            <div class="col-sm-12 col-md-12">
              <span class="font-weight-bold">{{ $t('message.settings.domain.validationKey') }}: </span>  {{ domain.validationKey }}
            </div>
            <div class="col-sm-12 col-md-12">
              <span class="font-weight-bold">{{ $t('message.settings.domain.isValid') }}: </span>
              <coozzy-check-circle-icon
                v-if="isValid"
                class="d-inline" />
              <coozzy-fail-icon v-else />
            </div>
            <div
              v-if="!isValid"
              class="col-sm-12 col-md-12 mt-2">
              <p>{{ $t('message.settings.domain.invalidText') }}</p>
            </div>
            <div class="col">
              <template v-if="!isValid">
                <coozzy-button
                  v-if="!loading"
                  size="small"
                  class="mb-0 float-right"
                  design="primary"
                  @click="validateDomain">
                  {{ $t('message.settings.domain.verify') }}
                </coozzy-button>
                <coozzy-spinner
                  v-else
                  class="mb-0 float-right" />
              </template>
              <coozzy-button
                size="small"
                class="mb-0 float-right mr-2"
                design="red"
                @click="$bvModal.show('domainConfirmationModal')">
                {{ $t('message.generic.delete') }}
              </coozzy-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      :id="'domainConfirmationModal'"
      ref="domainConfirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.settings.domain.confirmation.title')">
      <div class="col">
        <p>{{ $t('message.settings.domain.confirmation.text') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('domainConfirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="loading"
          @click="deleteDomain()">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import MessageApi from '../../misc/apis/MessageApi'
import Vue from 'vue'
import CoozzyFailIcon from '../../framework/components/icons/CoozzyFailIcon'
import CoozzyCheckCircleIcon from '../../framework/components/icons/CoozzyCheckCircleIcon'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'

export default {
  name: 'DomainDetailsModal',
  components: { CoozzySpinner, CoozzyButton, CoozzyCheckCircleIcon, CoozzyFailIcon },
  props: {
    domain: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      isValid: false
    }
  },
  mounted() {
    this.isValid = this.domain ? this.domain.isValid : false
  },
  methods: {
    show() {
      this.$refs['detail-domain-modal'].show()
    },
    hide() {
      this.$refs['detail-domain-modal'].hide()
    },
    deleteDomain() {
      this.loading = true
      MessageApi.deleteDomain(this.domain.id)
        .then(() => {
          this.$refs.domainConfirmationModal.hide()
          this.hide()
          this.loading = false
          this.$emit('refresh-domains')
          Vue.toasted.show(this.$t('message.settings.domain.deletedSuccessfully'), { type: 'success' })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.domain'), { type: 'error' })
        })
    },
    validateDomain() {
      this.loading = true
      MessageApi.validateDomain(this.domain.id)
        .then((response) => {
          this.loading = false
          if (response.isValid) {
            Vue.toasted.show(this.$t('message.settings.domain.validatedSuccessfully'), { type: 'success' })
          } else {
            Vue.toasted.show(this.$t('message.settings.domain.validationFailed'), { type: 'error' })
          }
          this.isValid = response.isValid
          this.$emit('refresh-domains')
        })
        .catch(e => {
          this.loading = false
          console.log(e)
          Vue.toasted.show(this.$t('message.settings.domain.validationFailed'), { type: 'error' })
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
