<template>
  <div>
    <b-popover
      container="#container"
      v-bind="$attrs"
      v-on="mountEventListeners">
      <slot />
    </b-popover>
  </div>
</template>

<script>
export default {
  name: 'CoozzyPopover',
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style lang="scss" scoped>
  .popover {
    max-width: 50%;
  }
</style>
