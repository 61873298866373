<template>
  <div class="row">
    <div class="col d-flex justify-content-center m-3">
      <coozzy-spinner />
    </div>
  </div>
</template>

<script>
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'

export default {
  name: 'LoginView',
  components: { CoozzySpinner },
  created() {
    this.$auth.login('', { language: this.$store.getters['internationalization/getCurrentLanguage'] })
  }
}
</script>

<style lang="scss" scoped>

</style>
