<template>
  <font-awesome-icon
    :icon="{ prefix: 'fab', iconName: 'facebook-f' }" />
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

library.add(faFacebookF)

export default {
  name: 'CoozzyFacebookIcon'
}
</script>

<style lang="scss" scoped>
  .fa {
    color: $color-yellow;
  }
</style>
