<template>
  <coozzy-card
    :class="`box-bg mb-0 card-color ${internalDesign}`"
    @click="emitClick"
    @click.middle="emitMiddleClick">
    <div class="d-flex flex-column justify-content-center h-100 header-text">
      <h4 class="mb-0">
        {{ value }}
      </h4>
      <span class="d-inline-flex align-items-center">
        {{ name }}
        <coozzy-info-circle-icon
          v-if="infoTooltipText"
          v-b-tooltip.hover.right="infoTooltipText"
          style="height: 0.75rem"
          class="text-white" />
      </span>
    </div>
  </coozzy-card>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'

export default {
  name: 'SingleValue',
  components: { CoozzyInfoCircleIcon, CoozzyCard },
  props: {
    name: {
      type: String,
      default:
        ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    design: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['default', 'green', 'lime', 'red', 'dark-blue', 'blue', 'amber', 'teal', 'orange', 'light-blue', 'gray', 'deep-orange', 'purple'].includes(value)
      }
    },
    infoTooltipText: {
      type: String,
      default: ''
    }
  },
  computed: {
    internalDesign() {
      switch (this.design) {
        case 'green':
          return 'bg-green'
        case 'blue':
          return 'default'
        case 'orange':
          return 'bg-orange'
        case 'deep-orange':
          return 'bg-deep-orange'
        case 'light-blue':
          return 'bg-lightblue'
        case 'gray':
          return 'gray'
        default:
          return 'default'
      }
    }
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
    emitMiddleClick() {
      this.$emit('click-middle')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.card-body) {
  padding: 0 !important;
}

.box-bg {
  height: 102px;
  border: 1px solid transparent;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .15));
  background-color: rgb(0, 68, 95);
}

.header-text {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.card-color {
  :deep(.header-text) {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  }

  :deep(h4) {
    margin-bottom: 4px !important;
  }
}

.bg-green {
  background-color: #4caf50;
}

.text-primary {
  color: $color-text-grey-dark;
}

.bg-lime {
  background-color: #cddc39;
}

.text-lime {
  color: $color-text-grey-dark;
}

.bg-red {
  background-color: #f44336;
}

.text-red {
  color: $color-text-grey-dark;
}

.bg-dark-blue {
  background-color: #3f51b5;
}

.text-dark-blue {
  color: $color-text-white;
}

.bg-blue {
  background-color: #2196f3;
}

.text-blue {
  color: $color-text-grey-dark;
}

.bg-amber {
  background-color: #ffc107;
}

.text-amber {
  color: $color-text-grey-dark;
}

.bg-teal {
  background-color: #009688;
}

.text-teal {
  color: $color-text-grey-dark;
}

.bg-orange {
  background-color: #ff9800;
}

.text-orange {
  color: $color-text-grey-dark;
}

.bg-deep-orange {
  background-color: #ff5722;
}

.text-deep-orange {
  color: $color-text-grey-dark;
}

.bg-purple {
  background-color: #9c27b0;
}

.text-purple {
  color: $color-text-white;
}

.bg-lightblue {
  background-color: #75a2b7;
}

.gray {
  background-color: $color-grey-dark;
}

h4 {
  color: $color-text-white;
  font-weight: normal !important;
}

span {
  color: $color-text-white;
  font-size: 18px;
  line-height: 22px;
}
</style>
