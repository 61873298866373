const responsivity = {
  methods: {
    calculateNumbersOfLines(firstbreakpoint, secondbreakpoint, thirdbreakpoint) {
      if (window.innerHeight >= 950) {
        return thirdbreakpoint
      } else if (window.innerHeight < 950 && window.innerHeight > 850) {
        return secondbreakpoint
      } else if (window.innerHeight <= 850) {
        return firstbreakpoint
      }
    },
    getNWordsFromString(str, nbrWords = 100, filterHtml = false) {
      let text = str
      if (filterHtml) {
        text = this.convertToPlainWithSpaces(text)
      }
      const wordsCount = text.split(' ').filter(word => word !== '').length
      if (wordsCount < nbrWords) {
        return text.replace(/\n+$/, '')
      }
      const firstNWords = text.split(' ').slice(0, nbrWords).join(' ') + '...'
      return firstNWords.replace(/\n+$/, '')
    },
    getTooltipCorrectString(str) {
      const lineBreakCount = (str.match(/\n/g)).length
      if (lineBreakCount > 10) {
        return str.substr(0, this.indexOfNth(str, '\n', 10)) + '...'
      }
      return this.getNWordsFromString(str, 30)
    },
    indexOfNth(string, char, nth, fromIndex = 0) {
      const indexChar = string.indexOf(char, fromIndex)
      if (indexChar === -1) {
        return -1
      } else if (nth === 1) {
        return indexChar
      } else {
        return this.indexOfNth(string, char, nth - 1, indexChar + 1)
      }
    },
    hasSomeParentTheClass(element, classname) {
      if (element && classname && element.classList && element.classList !== '' && element.classList.contains(classname)) return true
      if (element.parentNode) return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname)
      return false
    },
    convertToPlainWithSpaces(html) {
      let htmlCleaned = html
      // remove code brakes and tabs
      htmlCleaned = htmlCleaned.replace(/\n/g, '')
      htmlCleaned = htmlCleaned.replace(/\t/g, '')

      // keep html brakes and tabs
      htmlCleaned = htmlCleaned.replace(/<\/td>/g, ' ')
      htmlCleaned = htmlCleaned.replace(/<\/table>/g, ' ')
      htmlCleaned = htmlCleaned.replace(/<\/tr>/g, ' ')
      htmlCleaned = htmlCleaned.replace(/<\/p>/g, '  ')
      htmlCleaned = htmlCleaned.replace(/<\/div>/g, ' ')
      htmlCleaned = htmlCleaned.replace(/<\/h>/g, ' ')
      htmlCleaned = htmlCleaned.replace(/<br>/g, ' ')
      htmlCleaned = htmlCleaned.replace(/<br( )*\/>/g, ' ')
      const tempDivElement = document.createElement('div')
      tempDivElement.innerHTML = htmlCleaned
      return tempDivElement.textContent || tempDivElement.innerText || ''
    }
  }
}

export { responsivity }
