<template>
  <b-modal
    id="add-mailbox-modal"
    ref="add-mailbox-modal"
    :modal-class="'has-confirmation-modal'"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.settings.mailbox.create') ">
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-else
      class="row">
      <div class="col-sm-12 col-md-12">
        <coozzy-form-input
          v-model="mailbox.outbound"
          class="mb-3"
          :state="$v.mailbox.outbound.$dirty && $v.mailbox.outbound.$error ? false : null"
          :name="$t('message.settings.mailbox.outbound') " />
      </div>
      <div class="col-sm-12 col-md-12">
        <coozzy-form-input
          v-model="mailbox.name"
          class="mb-3"
          :state="$v.mailbox.name.$dirty && $v.mailbox.name.$error ? false : null"
          :name="$t('message.settings.mailbox.name') " />
      </div>
      <div class="col-sm-12 col-md-12">
        <label>{{ $t('message.settings.mailbox.mainUser') }}</label>
        <coozzy-form-select
          v-model="mailbox.mainUser">
          <option
            v-for="(employee, employeeIndex) in employeeList"
            :key="employeeIndex"
            :value="employee.id">
            {{ employee.profile.firstName }} {{ employee.profile.lastName }}
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-sm-12 col-md-12">
        <label class="mt-2">{{ $t('message.settings.mailbox.allowedUsers') }}</label>
        <coozzy-multiselect
          v-model="mailbox.allowedUsers"
          :options="employeeList"
          :multiple="true"
          :placeholder=" $t('message.settings.mailbox.allowedUsers')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          :custom-label="customLabelEmployee"
          track-by="id" />
      </div>
      <div class="col mt-2">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="hide()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="createMailbox">
          {{ $t('message.settings.mailbox.create') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MessageApi from '../../misc/apis/MessageApi'
import { required, email } from 'vuelidate/lib/validators'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import Vue from 'vue'
import { mapActions } from 'vuex'

export default {
  name: 'CreateMailboxModal',
  components: {
    CoozzyMultiselect
  },
  props: {
    employeeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      mailbox: {
        outbound: '',
        name: '',
        mainUser: '',
        allowedUsers: []
      },
      loading: false
    }
  },
  watch: {
    mailbox: {
      handler: function (val) {
        if (val !== '') {
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'add-mailbox-modal') {
        this.mailbox = {
          outbound: '',
          name: '',
          mainUser: '',
          allowedUsers: []
        }
        this.$nextTick(function () {
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        })
      }
    })
  },
  methods: {
    ...mapActions('message', ['resetMailboxes']),
    createMailbox() {
      this.$v.mailbox.$touch()
      if (!this.$v.mailbox.$invalid) {
        this.loading = true
        MessageApi.createMailbox(this.mailbox, this.$store.getters['user/getAccountId'])
          .then(response => {
            this.resetMailboxes()
            this.$emit('mailbox-created', response)
            this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
            this.loading = false
            this.hide()
            this.$v.$reset()
            Vue.toasted.show(this.$t('message.successMessages.mailboxCreation'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            if (e.response.data.error === 'Domain is not validated for your account') {
              Vue.toasted.show(this.$t('message.savingErrors.mailboxUnvalid'), { type: 'error' })
            } else {
              Vue.toasted.show(this.$t('message.savingErrors.mailbox'), { type: 'error' })
            }
          })
      }
    },
    show() {
      this.$refs['add-mailbox-modal'].show()
    },
    hide() {
      this.$refs['add-mailbox-modal'].hide()
    },
    customLabelEmployee(item) {
      return item.profile.firstName + ' ' + item.profile.lastName
    }
  },
  validations: {
    mailbox: {
      outbound: {
        required,
        email
      },
      name: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
