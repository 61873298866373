<!--suppress XmlDuplicatedId -->
<template>
  <section class="4862">
    <fab
      v-if="showFloatingActionButton"
      :actions="fabActions"
      :fixed-tooltip="true"
      icon-size="small"
      bg-color="#ff5a5f"
      @object="newObject"
      @ticket="newTicket" />
    <send-mail-modal
      v-if="isAuthenticated"
      :ref="'ref-send-mail-modal'" />
    <modal-send-mail-type
      v-if="isAuthenticated"
      :ref="'ref-modal-send-mail-type'" />
    <b-modal
      :id="'draft-writing-email-modal'"
      ref="draft-writing-email-modal"
      size="md"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.marketingMessages.draftWrintingEmail.title')">
      <div class="col p-0">
        <p>{{ $t('message.marketingMessages.draftWrintingEmail.text') }}</p>
      </div>
      <div class="col p-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="discradDraft()">
          {{ $t('message.marketingMessages.draftWrintingEmail.discard') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="continueEditing()">
          {{ $t('message.marketingMessages.draftWrintingEmail.continueEditing') }}
        </coozzy-button>
      </div>
    </b-modal>
    <vue-cookie-law
      :button-text="$t('message.cookie.gotIt')"
      :message="$t('message.cookie.message')" />

    <!-- Sidebar menu -->
    <div
      v-if="isAuthenticated"
      id="app"
      class="app"
      :class="[hasAccessUserBase ? 'with-footer' : '']">
      <coozzy-header />

      <div class="app-body">
        <app-sidebar
          v-if="!hideNavigation()"
          fixed>
          <coozzy-sidebar-nav ref="coozzy-header" />
        </app-sidebar>
        <main class="main">
          <div class="container-fluid mt-3">
            <router-view ref="router-view" />
          </div>
        </main>
      </div>
    </div>

    <!-- menu -->
    <div
      v-else
      id="app"
      class="app"
      :class="[ ['ApplicationFormLandingPage'].includes(currentRouteName) ? 'with-footer' : '']">
      <coozzy-header
        ref="top-header" />
      <router-view />
    </div>
    <coozzy-userbase-footer v-if="hasAccessUserBase || [ 'ApplicationFormLandingPage'].includes(currentRouteName)" />
    <b-modal
      id="globalConfirmationModal"
      ref="globalConfirmationModal"
      no-close-on-backdrop
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="$bvModal.hide('globalConfirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="closeCurrentModal">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      id="incompatibleBrowserModal"
      ref="incompatibleBrowserModal"
      no-close-on-backdrop
      :title="$t('message.generic.browserSupport.title')"
      hide-footer>
      <p>{{ $t('message.generic.browserSupport.textBody') }}</p>
      <div class="col">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="$bvModal.hide('incompatibleBrowserModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="$bvModal.hide('incompatibleBrowserModal')">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationTitle') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { Sidebar as AppSidebar } from '@coreui/vue'
import CoozzySidebarNav from './navigation/CoozzySidebarNav'
import VueCookieLaw from 'vue-cookie-law'
import { hideNavigation } from './mixins/hideNavigation'
import CoozzyHeader from './framework/layout/CoozzyHeader'
import CoozzyUserbaseFooter from './framework/layout/CoozzyUserbaseFooter'
import { user } from './mixins/user'
import { contract } from './mixins/contract'
import fab from 'vue-fab'
import SendMailModal from '@/contact/components/SendMailModal'
import ModalSendMailType from '@/contact/components/ModalSendMailType'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import router from '@/router/router'
import UserApi from '@/misc/apis/UserApi'

export default {
  components: {
    CoozzyButton,
    SendMailModal,
    ModalSendMailType,
    CoozzyHeader,
    CoozzyUserbaseFooter,
    CoozzySidebarNav,
    VueCookieLaw,
    AppSidebar,
    fab
  },
  mixins: [user, hideNavigation, contract],
  metaInfo: {
    title: '',
    titleTemplate: function (titleChunk) {
      let moduleName = ''
      if (this.$route.path.startsWith('/owner')) {
        moduleName = 'OWNERbase'
      } else if (this.$route.path.startsWith('/admin')) {
        moduleName = 'ADMINbase'
      } else if (this.$route.path.startsWith('/marketing')) {
        moduleName = 'MARKETbase'
      } else if (this.$route.path.startsWith('/asset')) {
        moduleName = 'ASSETbase'
      } else if (this.$route.path.startsWith('/accounting')) {
        moduleName = 'FINANCEbase'
      } else if (this.$route.path.startsWith('/user') || this.$route.path.startsWith('/object/')) {
        moduleName = 'USERbase'
      } else if (this.$route.path.startsWith('/platform')) {
        moduleName = 'Intern'
      } else {
        moduleName = 'PROPbase'
      }

      if (titleChunk) {
        return `${titleChunk} | ${moduleName}`
      } else {
        return moduleName
      }
    }
  },
  data() {
    return {
      fabActions: [
        {
          name: 'object',
          icon: 'home',
          tooltip: ''
        },
        {
          name: 'ticket',
          icon: 'assignment',
          tooltip: ''
        },
        {
          name: 'contact',
          icon: 'account_box',
          tooltip: ''
        }
      ],
      userLoginInterval: null,
      currentModal: null
    }
  },
  computed: {
    showFloatingActionButton() {
      return this.isAuthenticated &&
        [
          'OwnerOnboardingView'
        ].findIndex(r => this.$route.path.startsWith(r) !== -1)
    },
    currentRouteName() {
      return this.$route.name
    }
  },
  watch: {
    $route() {
      const onboardingListRoute = ['OwnerOnboardingView', 'OnboardingView', 'AdminOnboardingView', 'AssetOnboardingView', 'AccountingOnboardingView', 'OwnerBuildingDetailsView', 'AdminBuildingDetailsView', 'AssetBuildingDetailsView', 'AccountingBuildingDetailsView']
      if (!onboardingListRoute.includes(this.$route.name)) {
        localStorage.removeItem('openCollapse')
      }
      const propertyListRoute = ['PropertyEditingView', 'PropertyDetailsView']
      if (!propertyListRoute.includes(this.$route.name)) {
        localStorage.removeItem('openCollapseProperty')
      }
      if (!propertyListRoute.includes(this.$route.name) && !onboardingListRoute.includes(this.$route.name)) {
        localStorage.removeItem('scrollpos')
      }
      let noAccess = false
      if (this.$route.path.startsWith('/marketing') && !this.hasAccessMarketBase) {
        noAccess = true
      } else if (this.$route.path.startsWith('/owner') && !this.hasAccessPropBase) {
        noAccess = true
      } else if (this.$route.path.startsWith('/accounting') && !this.hasAccessAccountingBase) {
        noAccess = true
      } else if (this.$route.path.startsWith('/admin') && !this.hasAccessAdminBase) {
        noAccess = true
      } else if (this.$route.path.startsWith('/user') && !this.hasAccessUserBase) {
        noAccess = true
      } else if (this.$route.path.startsWith('/asset') && !this.hasAccessAssetBase) {
        noAccess = true
      }

      if (noAccess) {
        if (this.hasAccessPropBase) {
          this.$router.push({ name: 'OwnerDashboardOverview' })
        } else if (this.hasAccessAccountingBase) {
          this.$router.push({ name: 'AccountingDashboardView' })
        } else if (this.hasAccessAdminBase) {
          this.$router.push({ name: 'AdminDashboardView' })
        } else if (this.hasAccessMarketBase) {
          this.$router.push({ name: 'MarketingDashboardView' })
        } else if (this.hasAccessUserBase) {
          this.$router.push({ name: 'UserRequests' })
        } else if (this.hasAccessAssetBase) {
          this.$router.push({ name: 'AssetDashboardView' })
        }
      }
    }
  },
  created() {
    document.body.classList.add('body')
  },
  mounted() {
    window.addEventListener('beforeunload', (event) => {
      if (this.$store.getters['user/isSendMailModalOpen']) {
        event.returnValue = this.$t('message.generic.discardChanges')
      }
    })
    document.addEventListener('mousedown', this.preventMethod, false)
    document.addEventListener('pointerdown', this.preventMethod, false)
    this.isCompatibleBrowser()
    this.$root.$on('bv::modal::hide', (bvEvent) => {
      if (bvEvent.vueTarget.modalClass === 'has-confirmation-modal') {
        if (this.$store.getters['user/hasChangedModal']) {
          bvEvent.preventDefault()
          this.currentModal = bvEvent
          this.$refs.globalConfirmationModal.show()
        }
      }
    })
    this.$nextTick(function () {
      this.fabActions[0].tooltip = this.$t('message.generic.createNewObjectActionButton')
      this.fabActions[1].tooltip = this.$t('message.ticketOverview.titleModalCreate')
      this.fabActions[2].tooltip = this.$t('message.contact.newContact')
      if (typeof hasAccessMarketBase === 'boolean' && !this.hasAccessMarketBase && typeof hasAccessPropBase === 'boolean' && !this.hasAccessPropBase && typeof hasAccessAccountingBase === 'boolean' && !this.hasAccessAccountingBase && typeof hasAccessAdminBase === 'boolean' && !this.hasAccessAdminBase && typeof hasAccessUserBase === 'boolean' && !this.hasAccessUserBase && typeof hasAccessAssetBase === 'boolean' && !this.hasAccessAssetBase) {
        this.$router.push({
          name: 'OwnerSettingsView',
          query: { view: '2' }
        })
      }
    })
    this.userLoginInterval = setInterval(() => {
      this.$auth.renewTokens()
        .catch(() => {
          // For public routes we don't have to redirect to login
          if (this.$route.path === '/' || this.$route.path.startsWith('/object') || this.$route.path.startsWith('/ticket')) {
            return
          }
          // If we are not logged in we cannot renew anything. Redirect to login.
          this.$router.app.$auth.login('', {
            language: router.app.$store.getters['internationalization/getCurrentLanguage']
          })
        })
    }, 3600000)
    const contracts = localStorage && localStorage.getItem('renderId') ? JSON.parse(localStorage.getItem('renderId')) : null
    let renderId = []
    if (contracts) {
      renderId = contracts.map(x => x.renderId)
    }
    this.renderId = setInterval(() => this.getRenderStatus(), renderId.length > 0 && renderId.length < 4 ? 1000 : 3000)
  },
  beforeDestroy: function () {
    clearInterval(this.userLoginInterval)
    if (this.renderId) {
      clearInterval(this.renderId)
      localStorage.removeItem('renderId')
    }
    localStorage.removeItem('hasAccessAdminBase')
    localStorage.removeItem('hasAccessPropBase')
    localStorage.removeItem('hasAccessAssetBase')
    localStorage.removeItem('hasAccessAccountingBase')
    localStorage.removeItem('hasAccessMarketBase')
  },
  methods: {
    preventMethod(e) {
      if (e && e.srcElement && e.srcElement.nodeName !== 'TEXTAREA' && e.srcElement.nodeName !== 'SELECT' && e.srcElement.nodeName !== 'DIV' && e.srcElement.nodeName !== 'A' && e.srcElement.parentElement?.closest('table') && !e.srcElement.parentElement?.closest('#bodyMessage') && (typeof e.srcElement.className === 'string' ? !e.srcElement.className.includes('allowClickInputOnTable') : true)) {
        e.preventDefault()
        return false
      }
    },
    setFontSetting(accountId) {
      UserApi.getFontSettings(accountId)
        .then(response => {
          this.$store.dispatch('user/setFontSettings', response)
        })
        .catch(e => {
          if (!e.response || e.response.status !== 404) {
            console.log(e)
          }
        })
    },
    isCompatibleBrowser() {
      if (this.$store.getters['user/getIsCompatibleBrowser'] === null) {
        if (!this.$browserDetect.isChrome && !this.$browserDetect.isFirefox && !this.$browserDetect.isEdge) {
          this.$store.dispatch('user/setIsCompatibleBrowser', false)
          this.$refs.incompatibleBrowserModal.show()
        }
      }
    },
    closeCurrentModal() {
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      this.$store.dispatch('onboarding/setIsBuildingObjectsFiltered', [])
      this.$refs.globalConfirmationModal.hide()
      this.currentModal.vueTarget.hide()
    },
    handleLoginEvent(data) {
      this.$store.dispatch('user/setAccessToken', data.accessToken)
        .catch(e => {
          console.log(e)
          Sentry.captureException(e)
        })

      this.$store.dispatch('user/changeCurrentUser', data.profile).then(() => {
        if (!localStorage.getItem('language')) {
          this.$store.dispatch('internationalization/changeCurrentLanguage', this.$store.getters['user/getLang']).then()
        } else {
          this.$store.dispatch('internationalization/changeCurrentLanguage', localStorage.getItem('language')).then()
        }
        Sentry.getCurrentScope().setUser({ id: this.accountId })
        this.setFontSetting(this.accountId)
      }).catch(e => console.log(e))
    },
    newObject() {
      this.$router.push({ name: 'ObjectCreation' })
    },
    newTicket() {
      this.$refs['add-ticket-modal'].show()
    },
    discradDraft() {
      this.$bvModal.hide('draft-writing-email-modal')
      this.$store.dispatch('user/setSendMailModalOpen', false)
      this.$refs['ref-send-mail-modal'].discradDraft = true
      this.$bvModal.hide('modal-send-mail')
      setTimeout(() => {
        this.$bvModal.show('modal-send-mail')
      }, 500)
    },
    continueEditing() {
      this.$bvModal.hide('draft-writing-email-modal')
      this.$refs['ref-send-mail-modal'].minus = false
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css');
@import '~@coreui/coreui/scss/coreui';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '~@/framework/assets/scss/custom-table.scss';
@import '~@/framework/assets/scss/base.scss';

.modal-header .close {
  background-image: url('~@/assets/icon_close.svg');
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  font-size: 0;
  margin: 0 !important;
}

#blueimp-gallery .close {
  background-image: url('~@/assets/icon_close_white.svg');
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  font-size: 0;
  margin: 0 !important;
}

.sidebar {
  -webkit-box-shadow: 1px 0 6px #666;
  -moz-box-shadow: 1px 0 6px #666;
  box-shadow: 1px 0 6px #666;
  background-color: $color-bg-white;
}

.disable-links {
  pointer-events: none;
}

.float-right {
  float: right;
}

.with-footer {
  min-height: calc(100vh - 52px);
}
</style>
