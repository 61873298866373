<template>
  <b-badge
    v-bind="$attrs"
    :class="[size, design, 'badge-size-' + size, circle ? 'rounded-circle' : '', clickable ? 'clickable' : '']"
    v-on="mountEventListeners">
    <slot />
  </b-badge>
</template>

<script>
export default {
  name: 'CoozzyBadge',
  inheritAttrs: false,
  props: {
    design: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['default', 'green', 'red', 'blue', 'grey', 'white', 'white-red'].includes(value)
      }
    },
    clickable: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal',
      validator: (value) => {
        return ['normal', 'large'].includes(value)
      }
    }
  },
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style lang="scss" scoped>
  span.badge {

    &.badge-size-large {
      font-size: 1rem;
      font-weight: normal;
    }

    &.clickable {
      cursor: pointer;
    }

    &.default {
      background-color: $color-bg-grey-dark;
      border-color: $color-bg-grey-dark;
      color: $color-text-grey;
    }

    &.green {
      background-color: $color-primary;
      border-color: $color-primary;

      &.clickable:hover {
        background-color: $color-primary-dark;
        border-color: $color-primary-dark;
      }
    }

    &.red {
      background-color: $color-red;
      border-color: $color-red;
      color: $color-text-white;

      &.clickable:hover {
        background-color: $color-red-dark;
        border-color: $color-red-dark;
      }
    }

    &.blue {
      background-color: $color-blue;
      border-color: $color-blue;

      &.clickable:hover {
        background-color: $color-blue-dark;
        border-color: $color-blue-dark;
      }
    }

    &.yellow {
      background-color: $color-yellow;
      border-color: $color-yellow;

      &.clickable:hover {
        background-color: $color-yellow-dark;
        border-color: $color-yellow-dark;
      }
    }

    &.grey {
      color: $color-text-grey;
      background-color: $color-grey;
      border-color: $color-grey;

      &.clickable:hover {
        background-color: $color-grey;
        border-color: $color-grey;
      }
    }

    &.white {
      color: $color-text-grey;
      background-color: #fff;
      border-color: #fff;

      &.clickable:hover {
        background-color: $color-grey;
        border-color: $color-grey;
      }
    }

    &.white-red {
      color: $color-red;
      background-color: #fff;
      border-color: #fff;

      &.clickable:hover {
        background-color: $color-grey;
        border-color: $color-grey;
      }
    }
  }
</style>
