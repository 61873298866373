<template>
  <section
    id="condominiumObjectOverview">
    <div
      v-if="!searchClient || algoliaDataLoading"
      class="text-center">
      <coozzy-spinner />
    </div>
    <ais-instant-search
      v-else
      ref="instant-search"
      :search-client="searchClient"
      :index-name="algoliaIndex"
      :routing="routing">
      <app-refresh
        ref="app-refresh"
        @refresh-done="refreshDone" />
      <ais-stats>
        <template #default="{ nbHits }">
          <coozzy-page-title
            :title="$t('message.managementOverview.objects')"
            :count="nbHits"
            :count-text="$t('message.managementOverview.objects')"
            :clear-filter="checkfilter"
            @clear-filter="clickOnClear" />
        </template>
      </ais-stats>
      <!-- Filter -->
      <div class="row align-items-center">
        <div class="col-12 col-md mb-2 mb-md-0">
          <ais-search-box>
            <template #default="{ currentRefinement }">
              <debounced-algolia-search-box
                ref="debouncedAlgolia"
                :default-value="currentRefinement"
                :delay="300" />
            </template>
          </ais-search-box>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="property.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchPropertyName', items) }}
              <coozzy-multiselect
                v-model="searchPropertyName"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.propertiesView.tabs.property')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :track-by="'value'"
                :custom-label="customLabel"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="building.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchBuildingName', items) }}
              <coozzy-multiselect
                v-model="searchBuildingName"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.onBoardingTabs.building')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :track-by="'value'"
                :custom-label="customLabel"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="address.zip"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchZip', items) }}
              <coozzy-multiselect
                v-model="searchZip"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.generic.zip')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="address.city"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchCity', items) }}
              <coozzy-multiselect
                v-model="searchCity"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.generic.city')"
                :close-on-select="false"
                :clear-on-select="false"
                :custom-label="customLabel"
                :preserve-search="true"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="category"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchCategories', items) }}
              <coozzy-multiselect
                v-model="searchCategories"
                :options="sortedFilterValues(items, customLabelCategory)"
                :multiple="true"
                :placeholder="$t('message.onBoarding.buildings.objects.category.title')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelCategory"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-2">
          <ais-refinement-list
            attribute="subCategories"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('subCategories', items) }}
              <coozzy-multiselect
                v-model="searchSubCategory"
                :options="sortedFilterValues(items, customLabelSubCategory)"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelSubCategory"
                :placeholder="$t('message.tableColumnHeadings.subCategory')"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="floor"
            :transform-items="transformItems"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchFloor', items) }}
              <coozzy-multiselect
                v-model="searchFloor"
                :options="sortedFloor(items)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.floor')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelFloor"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="refineWithNegativeNumbers(refine, $event.value)"
                @remove="refineWithNegativeNumbers(refine, $event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="rooms"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchRooms', items) }}
              <coozzy-multiselect
                v-model="searchRooms"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.rooms')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="status"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchStatus', items) }}
              <coozzy-multiselect
                v-model="searchStatus"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.generic.currentStatus')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelStatus"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="futureRentalStatus"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchFutureStatus', items) }}
              <coozzy-multiselect
                v-model="searchFutureStatus"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.generic.futureStatus')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelFutureStatus"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="assignees.asset.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchAssetAssignee', items) }}
              <coozzy-assignee-multiselect
                v-model="searchAssetAssignee"
                :options="getAssigneeData(items)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.assetAssignee')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelAssignee"
                :track-by="'value'"
                label="name"
                group-values="employees"
                group-label="employee"
                :group-select="true"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="owner.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchOwner', items) }}
              <coozzy-multiselect
                v-model="searchOwner"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.owner')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                label="name"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="networkPartnerAdministration.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchAdministration', items) }}
              <coozzy-multiselect
                v-model="searchAdministration"
                :options="sortedFilterValues(sortOptions(items), customLabel)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.administration')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                label="name"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="networkPartnerAccounting.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchAccounting', items) }}
              <coozzy-multiselect
                v-model="searchAccounting"
                :options="sortedFilterValues(sortOptions(items), customLabel)"
                :multiple="true"
                :placeholder="$t('message.networkPartner.subTypes.accounting')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-2">
          <ais-refinement-list
            attribute="preferredCommunicationChannel"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchPreferredCommunicationChannel', items) }}
              <coozzy-multiselect
                v-model="searchPreferredCommunicationChannel"
                :options="sortedFilterValues(sortOptions(items), customLabel)"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelChannel"
                :placeholder="$t('message.contact.preferredCommunicationChannel')"
                :track-by="'value'"
                label="name"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-2">
          <ais-refinement-list
            attribute="obj.tags"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchTags', items) }}
              <coozzy-multiselect
                v-model="searchTags"
                :options="sortedFilterValues(items, customLabelTags)"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelTags"
                :placeholder="$t('message.generic.tags')"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-2">
          <ais-refinement-list
            attribute="debitUntilFilter"
            :limit="100">
            <div
              id="date-picker-container"
              slot-scope="{items}">
              {{ defaultRefining('searchDebitUntilFilter', items) }}
              <coozzy-form-input
                v-model="searchDebitUntilFilterLabel"
                :placeholder="$t('message.tableColumnHeadings.debitUntil')" />
              <date-picker
                id="date-picker-input"
                v-model="searchDebitUntilFilter"
                :default-value="defaultCalendar"
                value-type="format"
                format="M/YYYY"
                title-format="M"
                :multiple="true"
                type="month"
                :lang="getLang"
                @calendar-change="dateChanged">
                <div
                  slot="footer">
                  <coozzy-form-checkbox
                    v-model="noDebitUntil"
                    :initial="noDebitUntil"
                    :class="'p-0 w-100'"
                    name="noDebitUntil"
                    button>
                    {{ $t('message.tableColumnHeadings.noDebitUntil.title') }}
                    <coozzy-info-circle-icon
                      v-b-tooltip.hover.html="$t('message.tableColumnHeadings.noDebitUntil.tooltip')"
                      class="ml-1" />
                  </coozzy-form-checkbox>
                </div>
              </date-picker>
            </div>
          </ais-refinement-list>
        </div>
      </div>
      <!-- eslint-disable vue/attribute-hyphenation -->
      <ais-configure
        :hitsPerPage="nbrPerPage"
        :filters="filterQuery" />
      <!-- eslint-disable vue/attribute-hyphenation -->
      <div
        id="rowConfig"
        class="row mt-2 align-items-center">
        <div class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <coozzy-dropdown-item
              v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator)"
              :disabled="selectedEntries.length === 0"
              @click="createTicket('TICKET_TYPE_TASK')">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <!--            <coozzy-dropdown-item-->
            <!--              v-if="isTicketingEnabled && (isEditor || isTicketEditor)"-->
            <!--              :disabled="selectedEntries.length === 0"-->
            <!--              @click="navigateToCreateOrder()">-->
            <!--              {{ $t('message.ticketOverview.createOrder') }}-->
            <!--            </coozzy-dropdown-item>-->
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="showActivity()">
              {{ $t('message.contact.activity.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="$refs['reminder-creation'].show()">
              {{ $t('message.calendar.reminder.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="serialEmail()">
              {{ selectedEntries.length === 1 ? $t('message.generic.oneEmail') : $t('message.generic.serialEmail') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="serialLetter()">
              {{ selectedEntries.length === 1 ? $t('message.generic.oneSerialLetter') : $t('message.generic.serialLetter') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="openSetAssigneeModal(null)">
              {{ $t('message.marketingObjects.setAssignee') }}
            </coozzy-dropdown-item>
            <div
              v-if="isEditor"
              class="row m-0"
              :class="selectedEntries.length === 0 || hasSelectedEntriesAdministrationSet ? 'coozzyDropdownItemTooltip' : ''">
              <coozzy-dropdown-item
                class="col pr-0 pl-0"
                :disabled="selectedEntries.length === 0 || hasSelectedEntriesAdministrationSet"
                @click="openSetClientModal('MANAGEMENT_SCOPE')">
                {{ $t('message.managementObjectsConfig.bulkActions.setAdministrationClient') }}
              </coozzy-dropdown-item>
              <div class="pl-0">
                <coozzy-info-circle-icon
                  v-if="hasSelectedEntriesAdministrationSet"
                  v-b-tooltip.hover.html="$t('message.managementObjectsConfig.bulkActions.tooltip.administrationAction')"
                  class="mt-2" />
              </div>
            </div>
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <div
              v-if="isEditor"
              class="row m-0"
              :class="selectedEntries.length === 0 || isTenanciesActivated > 0 ? 'coozzyDropdownItemTooltip' : ''">
              <coozzy-dropdown-item
                class="col pr-0 pl-0"
                :disabled="selectedEntries.length === 0 || isTenanciesActivated > 0"
                @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                {{ $t('message.generic.delete') }}
              </coozzy-dropdown-item>
              <div class="pl-0">
                <coozzy-info-circle-icon
                  v-if="isTenanciesActivated > 0"
                  v-b-tooltip.hover.html="$t('message.deleteErrors.deleteActiveTenancyTooltip')"
                  class="mt-2" />
              </div>
            </div>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
          <ais-clear-refinements :included-attributes="['query', 'debitUntilFilter', 'subCategories', 'status','futureRentalStatus', 'address.zip', 'category', 'floor', 'rooms', 'assignees.asset.name', 'owner.name', 'networkPartnerMarketing.name', 'networkPartnerAsset.name', 'property.name', 'building.name', 'address.city', 'preferredCommunicationChannel' ,'obj.tags']">
            <template #default="{ canRefine, refine }">
              {{ setCanRefine(canRefine) }}
              <a
                v-if="canRefine"
                ref="clearClick"
                @click="initFilter(refine)" />
            </template>
          </ais-clear-refinements>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
          :class="isCompanyAdmin !== true ? 'offset-md-6' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <ais-state-results>
        <template #default="{ hits, results }">
          {{ getState(results) }}
          <b-table
            ref="objectsTable"
            class="mt-2"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            :fields="fields"
            :items="hits"
            @row-clicked="onRowClicked"
            @row-middle-clicked="onMiddlelicked">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <!-- Headings -->
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(status)>
              {{ $t('message.tableColumnHeadings.status') }}
            </template>
            <template #head(numericId)>
              {{ $t('message.tableColumnHeadings.numericId') }}
            </template>
            <template #head(objectNumber)>
              {{ $t('message.tableColumnHeadings.objectNumber') }}
            </template>
            <template #head(objects)>
              {{ $t('message.tableColumnHeadings.objects') }}
            </template>
            <template #head(address)>
              {{ $t('message.tableColumnHeadings.address') }}
            </template>
            <template #head(type)>
              {{ $t('message.tableColumnHeadings.type') }}
            </template>
            <template #head(floor)>
              {{ $t('message.tableColumnHeadings.floor') }}
            </template>
            <template #head(rooms)>
              {{ $t('message.tableColumnHeadings.rooms') }}
            </template>
            <template #head(area)>
              {{ $t('message.tableColumnHeadings.area') }}
            </template>
            <template #head(condominiumOwner)>
              {{ $t('message.tableColumnHeadings.condominiumOwner') }}
            </template>
            <template #head(tenant)>
              {{ $t('message.tableColumnHeadings.tenant') }}
            </template>
            <template #head(valueQuota)>
              {{ $t('message.tableColumnHeadings.valueQuota') }}
            </template>
            <template #head(assignee)>
              {{ $t('message.tableColumnHeadings.assignee') }}
            </template>
            <template #head(partner)>
              {{ $t('message.tableColumnHeadings.partner') }}
            </template>
            <template #head(ownershipStart)>
              {{ $t('message.tableColumnHeadings.ownershipStart') }}
            </template>
            <template #head(ownershipEnd)>
              {{ $t('message.tableColumnHeadings.ownershipEnd') }}
            </template>
            <template #head(roommate)>
              {{ $t('message.tableColumnHeadings.roommate') }}
            </template>
            <template #head(debitUntil)>
              {{ $t('message.tableColumnHeadings.debitUntil') }}
            </template>
            <template #head(subCategory)>
              {{ $t('message.tableColumnHeadings.subCategory') }}
            </template>

            <!-- Data -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="'check_' + data.item.objectID"
                @change="(value) => { itemSelected(data.item, value) }" />
            </template>
            <template #cell(status)="data">
              <div
                class="color-box mr-2"
                :class="getFullStatusColorClass(data.item)" />
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="getFullStatusText(data.item)" />
              <!-- eslint-enable vue/no-v-html -->
            </template>
            <template #cell(numericId)="data">
              {{ data.item.numericId | displayOptionalValue }}
            </template>
            <template #cell(objectNumber)="data">
              {{ data.item.number | displayOptionalValue }}
            </template>
            <template #cell(objects)="data">
              {{ data.item.name | displayOptionalValue }}
            </template>
            <template #cell(address)="data">
              <address-text :address="data.item.address" />
            </template>
            <template #cell(type)="data">
              <template v-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(floor)="data">
              <div class="text-center">
                {{ formatFloor(data.item.floor) }}
              </div>
            </template>
            <template #cell(rooms)="data">
              <div class="text-center">
                {{ data.item.rooms | displayOptionalValue }}
              </div>
            </template>
            <template
              #cell(area)="data">
              {{ getLA(data.item) | formatArea }}
            </template>
            <template #cell(condominiumOwner)="data">
              <template v-if="data.item.condominium && data.item.condominium.owner && data.item.condominium.owner.length > 0">
                <router-link
                  v-for="owner in data.item.condominium.owner"
                  :key="owner.contactId"
                  :to="{ name: 'AdminContactDetailsView', params: { id: owner.contactId } }">
                  {{ owner.name }}<br>
                </router-link>
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template
              #cell(valueQuota)="data">
              <div class="text-nowrap">
                <template>
                  <span>{{ data.item.valueQuotaWithTotal | displayOptionalValue }}</span>
                </template>
              </div>
            </template>
            <template
              #cell(tenant)="data">
              <template v-if="!data.item.condominiumOwnership">
                <template v-if="data.item.tenancy && data.item.tenancy.tenants && data.item.tenancy.tenants.length > 0">
                  <router-link
                    v-for="tenant in data.item.tenancy.tenants"
                    :key="tenant.contactId"
                    :to="{ name: 'AdminContactDetailsView', params: { id: tenant.contactId } }">
                    {{ tenant.name }}<br>
                  </router-link>
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template
                v-else>
                <template v-if="data.item.condominium && data.item.condominium.tenants && data.item.condominium.tenants.length > 0">
                  <router-link
                    v-for="tenant in data.item.condominium.tenants"
                    :key="tenant.contactId"
                    :to="{ name: 'AdminContactDetailsView', params: { id: tenant.contactId } }">
                    {{ tenant.name }}<br>
                  </router-link>
                </template>
                <template v-else>
                  -
                </template>
              </template>
            </template>
            <template #cell(assignee)="data">
              <coozzy-button
                design="default"
                size="small"
                @click="openSetAssigneeModal(data.item)">
                {{
                  data.item.assignees && data.item.assignees.asset && data.item.assignees.asset.name && data.item.assignees.asset.name !== 'Unassigned Nicht zugeordnet Non assegnato' ? data.item.assignees.asset.name : '-'
                }}
              </coozzy-button>
            </template>
            <template #cell(partner)="data">
              <span
                v-b-tooltip="{container: '#condominiumObjectOverview', title: $t('message.generic.accountId') + data.item.owner.id}"
                class="d-block">
                <img
                  height="20px"
                  src="/img/propbase-icon.png"
                  alt="prop-base">
                {{ data.item.owner.name }}
              </span>
              <span
                v-if="data.item.networkPartnerAdministration && data.item.networkPartnerAdministration.name !== 'Unassigned Nicht zugeordnet Non assegnato'"
                v-b-tooltip="{container: '#condominiumObjectOverview', title: $t('message.generic.accountId') + data.item.networkPartnerAdministration.accountId}"
                class="d-block">
                <img
                  height="20px"
                  src="/img/adminbase-icon.png"
                  alt="asset-base">
                {{ data.item.networkPartnerAdministration.name }}
              </span>
              <span
                v-if="data.item.networkPartnerMarketing && data.item.networkPartnerMarketing.name !== 'Unassigned Nicht zugeordnet Non assegnato'"
                v-b-tooltip="{container: '#condominiumObjectOverview', title: $t('message.generic.accountId') + data.item.networkPartnerMarketing.accountId}"
                class="d-block">
                <img
                  height="20px"
                  src="/img/marketbase-icon.png"
                  alt="market-base">
                {{ data.item.networkPartnerMarketing.name }}
              </span>
              <span
                v-if="data.item.networkPartnerAccounting && data.item.networkPartnerAccounting.name !== 'Unassigned Nicht zugeordnet Non assegnato'"
                v-b-tooltip="{container: '#condominiumObjectOverview', title: $t('message.generic.accountId') + data.item.networkPartnerAccounting.accountId}"
                class="d-block">
                <img
                  height="20px"
                  src="/img/financebase-icon.png"
                  alt="market-base">
                {{ data.item.networkPartnerAccounting.name }}
              </span>
            </template>
            <template #cell(ownershipStart)="data">
              <div class="text-center">
                {{ data.item.condominium.startingAt | timestampToDate }}
              </div>
            </template>
            <template #cell(ownershipEnd)="data">
              <div class="text-center">
                {{ data.item.condominium.endingAt | timestampToDate }}
              </div>
            </template>
            <template #cell(roommate)="data">
              <template v-if="!data.item.condominium.roommates || data.item.condominium.roommates.length === 0 || data.item.condominium.roommates.map(n => n.name).join(', ') === ''">
                -
              </template>
              <template v-else>
                <router-link
                  v-for="(roommate, roommateIndex) in data.item.condominium.roommates"
                  :key="roommateIndex"
                  :to="{ name: 'AccountingContactDetailsView', params: { id: roommate.contactId } }">
                  {{ roommate.name }}<br>
                </router-link>
              </template>
            </template>
            <template #cell(debitUntil)="data">
              <template v-if="data.item.debitUntil">
                {{ data.item.debitUntil | objectToDate }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(subCategory)="data">
              <template v-if="data.item.subCategories?.length > 0">
                <template v-for="(subCat, subCatIndex) in data.item.subCategories">
                  <span
                    v-if="subCat !== 'UNDEFINED_SUB_CATEGORY'"
                    :key="subCatIndex">
                    {{ $t(`message.onBoarding.buildings.objects.subCategory.` + subCat?.toLowerCase()) }}
                  </span>
                  <template v-else>
                    -
                  </template>
                </template>
              </template>
              <template v-else>
                -
              </template>
            </template>
          </b-table>
        </template>
      </ais-state-results>
      <pagination-algolia
        ref="paginator"
        @changed-page="clearCheckbox()" />
    </ais-instant-search>
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.advertisementManagementOverview.deletion.confirmation.title')">
      <div
        v-if="loadingDeletion"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col p-0">
          <p>{{ $t('message.advertisementManagementOverview.deletion.confirmation.text') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteObject()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <set-advert-relations
      ref="setClientModal"
      :title="scope === 'MARKETING_SCOPE' ? $t('message.managementObjectsConfig.bulkActions.setMarketingClient') : scope === 'ASSET_SCOPE' ? $t('message.managementObjectsConfig.bulkActions.setAssetManager') : $t('message.managementObjectsConfig.bulkActions.setAdministrationClient')"
      :element-list="filtredNetworkPartner()"
      type="networkPartner"
      @save="setClient" />
    <set-advert-relations
      ref="setAssigneeModal"
      :title="$t('message.advertisementsOverview.setAssignee')"
      :element-list="contactList"
      type="employee"
      @save="setAssignee" />
    <reminder-creation-modal
      ref="reminder-creation"
      :references-ids="selectedEntriesIds"
      :source="'object'"
      @new-reminder="clearCheckbox" />
    <b-modal
      :id="'shareconfirmationModal'"
      ref="shareconfirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="scope === 'MARKETING_SCOPE' ? $t('message.managementObjectsConfig.modal.titleSetMarketingClient') : scope === 'ASSET_SCOPE' ? $t('message.managementObjectsConfig.modal.titleSetAssetManager') : $t('message.managementObjectsConfig.modal.titleSetAdministrationClient')">
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col">
          <p>{{ scope === 'MARKETING_SCOPE' ? $t('message.managementObjectsConfig.modal.bodySetMarketingClient', { SELECTED_OBJECT_COUNT: selectedEntries.length , SELECTED_MARKETING_NAME: selectedClientName }) : scope === 'ASSET_SCOPE' ? $t('message.managementObjectsConfig.modal.bodySetAssetManager', { SELECTED_OBJECT_COUNT: selectedEntries.length , SELECTED_ASSET_NAME: selectedClientName }) : $t('message.managementObjectsConfig.modal.bodySetAdministrationClient', { SELECTED_OBJECT_COUNT: selectedEntries.length , SELECTED_ADMINISTRATION_NAME: selectedClientName }) }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('shareconfirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            :disabled="loading"
            @click="saveShares()">
            {{ $t('message.managementObjectsConfig.modal.confirmationBtn') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
    <create-activity-modal
      ref="activity-creation"
      :key="keyActivity"
      :ids="selectedEntriesIds"
      @activity-created="clearCheckbox" />
  </section>
</template>

<script>
import AddressText from '../framework/components/misc/AddressText'
import SetAdvertRelations from '@/marketingBasic/components/advertisements/management/SetAdvertRelations.vue'
import { user } from '@/mixins/user'
import { onboarding } from '@/mixins/onboarding'
import { formatting } from '@/mixins/formatting'
import { algolia } from '@/mixins/algolia'
import ObjectApi from '../misc/apis/ObjectApi'
import ShareApi from '../misc/apis/ShareApi'
import ContactApi from '../misc/apis/ContactApi'
import NetworkPartnerAPI from '../misc/apis/NetworkPartnerApi'
import Vue from 'vue'
import { tables } from '@/mixins/tables'
import CoozzyFormCheckbox from '../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import CoozzyMultiselect from '../framework/components/multiselect/CoozzyMultiselect'
import CoozzyAssigneeMultiselect from '../framework/components/multiselect/CoozzyAssigneeMultiselect'
import CoozzyDropdown from '../framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '../framework/components/dropdown/CoozzyDropdownItem'
import CoozzyFormSelect from '../framework/components/form/select/CoozzyFormSelect'
import CoozzyDropdownSpaceDivider from '../framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import CoozzyPageTitle from '../framework/layout/CoozzyPageTitle'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import AppRefresh from '../framework/components/RefreshAlgolia'
import DebouncedAlgoliaSearchBox from '../framework/components/DebouncedAlgoliaSearchBox'
import PaginationAlgolia from '../framework/components/PaginationAlgolia'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import { obj } from '@/mixins/object'
import { mail } from '@/mixins/mail'
import AssembleListModal from '@/contact/components/AssembleListModal'
import SearchApi from '@/misc/apis/SearchApi'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/de'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'AssetCondominiumObjectOverview',
  components: {
    CoozzyFiltersIcon,
    CoozzyFormInput,
    DebouncedAlgoliaSearchBox,
    AppRefresh,
    PaginationAlgolia,
    ReminderCreationModal,
    SetAdvertRelations,
    CoozzyPageTitle,
    CoozzySpinner,
    CoozzyDropdownSpaceDivider,
    CoozzyFormSelect,
    CoozzyAssigneeMultiselect,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyMultiselect,
    CoozzyButton,
    CreateActivityModal,
    CoozzyFormCheckbox,
    AddressText,
    CoozzyInfoCircleIcon,
    AssembleListModal,
    DatePicker
  },
  mixins: [user, onboarding, obj, formatting, algolia, tables, mail, routeChecks],
  props: {
    filter: {
      type: String,
      default: null
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.management.objects')
    }
  },
  data() {
    return {
      algoliaIndex: 'object',
      searchPropertyName: [],
      searchBuildingName: [],
      searchCity: [],
      showFirst: false,
      checkfilter: false,
      appRefreshAction: '',
      loading: false,
      loadingDeletion: false,
      algoliaDataLoading: false,
      searchText: '',
      searchCategories: [],
      searchAssetAssignee: [],
      searchOwner: [],
      searchRooms: [],
      searchFloor: [],
      searchZip: [],
      searchStatus: [],
      searchFutureStatus: [],
      searchNetworkPartner: [],
      sharesList: [],
      networkPartnerList: [],
      selectedEntries: [],
      selectedEntriesActivatedTenancy: [],
      contactList: [],
      searchPreferredCommunicationChannel: [],
      searchAccounting: [],
      searchAdministration: [],
      searchTags: [],
      searchSubCategory: [],
      selectedObjectIds: null,
      keyActivity: 0,
      scope: null,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle status-td-icon',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objectNumber',
          label: this.$t('message.tableColumnHeadings.objectNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'numericId',
          label: this.$t('message.tableColumnHeadings.numericId'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'area',
          label: this.$t('message.tableColumnHeadings.area'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'condominiumOwner',
          label: this.$t('message.tableColumnHeadings.condominiumOwner'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'valueQuota',
          label: this.$t('message.tableColumnHeadings.valueQuota'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'partner',
          label: this.$t('message.tableColumnHeadings.partner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'ownershipStart',
          label: this.$t('message.tableColumnHeadings.ownershipStart'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'ownershipEnd',
          label: this.$t('message.tableColumnHeadings.ownershipEnd'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'roommate',
          label: this.$t('message.tableColumnHeadings.roommate'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'debitUntil',
          label: this.$t('message.tableColumnHeadings.debitUntil'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'subCategory',
          label: this.$t('message.tableColumnHeadings.subCategory'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        }
      ],
      selectedFields: [],
      searchDebitUntilFilter: [],
      searchDebitUntilFilterLabel: '',
      noDebitUntil: false,
      defaultCalendar: new Date(),
      paramsAlgolia: {
        query: '',
        params: '',
        index: ''
      },
      nbrTotalOfHits: 0,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    selectedClientName() {
      if (this.selectedClient && this.selectedClient.company) {
        return this.selectedClient.company.name
      } else if (this.selectedClient && this.selectedClient.individual) {
        return this.selectedClient.individual.firstName.substr(0, 1) + '.' + this.selectedClient.individual.lastName
      }
      return ''
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.objectID) : this.selectedEntries.map(obj => obj.objectID)
    },
    filterQuery() {
      let query = 'condominiumOwnership:true'

      if (!this.isPlatformEmployee) {
        query += ` AND networkPartners.combinedId:${this.accountId.toString()}-asset`
      }

      if (this.searchDebitUntilFilter.length === 0 && this.noDebitUntil) {
        query += ' AND debitUntilFilter = 0'
      }
      if (this.searchDebitUntilFilter.length > 0 && this.noDebitUntil) {
        query += ` AND debitUntilFilter <= ${this.dateToTimestamp(this.searchDebitUntilFilter, 'start')}`
      }
      if (this.searchDebitUntilFilter.length > 0 && !this.noDebitUntil) {
        query += ` AND debitUntilFilter >= ${this.dateToTimestamp(this.searchDebitUntilFilter, 'start')} AND debitUntilFilter <= ${this.dateToTimestamp(this.searchDebitUntilFilter, 'end')}`
      }

      return query
    },
    isTenanciesActivated() {
      return this.selectedEntriesActivatedTenancy.filter(x => x.hasActivatedTenancy).length
    }
  },
  watch: {
    searchDebitUntilFilter: function (newVal) {
      if (newVal.length > 0) {
        const dateArray = newVal[0].split('/')
        this.defaultCalendar = new Date(dateArray[1], dateArray[0] - 1, 1)
      }
      this.searchDebitUntilFilterLabel = newVal ? newVal.join(', ') : ''
    },
    checkfilter: function () {
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    nbrPerPage: function () {
      if (this.showFirst) {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.refresh()
          }
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.redirect(this.$route.query.page)
          }
        })
      }
      this.showFirst = true
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        // check if we already call algolia with the same params
        if (this.nbrTotalOfHits !== this.allEntries.length) {
          this.allEntries = await this.getAllAvailableDataByIndex(this.paramsAlgolia)
        }
        this.selectedEntriesActivatedTenancy = this.allEntries.map(item => {
          return { hasActivatedTenancy: item.hasActivatedTenancy, id: item.objectID }
        })
      } else {
        this.selectedEntriesActivatedTenancy = this.selectedEntries.map(item => {
          return { hasActivatedTenancy: item.hasActivatedTenancy, id: item.objectID }
        })
      }
    }
  },
  mounted: function () {
    this.getContacts()
    this.loadEmployees()
    this.loadInstantSearch()
    if (localStorage.getItem('managementObjectsConfig') !== null && localStorage.getItem('managementObjectsConfig') !== '') {
      const res = localStorage.getItem('managementObjectsConfig').split(',')
      for (let index = 0; index < res.length; index++) {
        const item = this.dataFields.filter(x => x.key === res[index])
        this.fields.push(item)
      }
    } else {
      this.fields.push(...this.dataFields.filter(x => x.key === 'status' || x.key === 'name' || x.key === 'objectNumber' || x.key === 'numericId' || x.key === 'address' || x.key === 'type' || x.key === 'floor' || x.key === 'rooms' || x.key === 'area' || x.key === 'tenant' || x.key === 'valueQuota' || x.key === 'condominiumOwner' || x.key === 'marketRent' || x.key === 'networkPartners' || x.key === 'assignee' || x.key === 'partner'))
    }
    this.algoliaDataLoading = true
    NetworkPartnerAPI.listNetworkPartner(
      0,
      0,
      [],
      ['LANDLORD', 'MARKETING', 'LANDLORD_MARKETING', 'ASSET_LANDLORD', 'ASSET_MARKETING', 'ASSET_LANDLORD_MARKETING', 'ASSET_LANDLORD_ACCOUNTING', 'ASSET_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING_ACCOUNTING', 'LANDLORD_MARKETING_ACCOUNTING'],
      '',
      '',
      ''
    ).then(response => {
      this.algoliaDataLoading = false
      this.networkPartnerList = response.accounts
    }).catch(e => {
      this.algoliaDataLoading = false
      console.log(e)
      Vue.toasted.show(this.$t('message.loadingErrors.networkPartners'), { type: 'error' })
    }).finally(() => {
      setTimeout(() => {
        this.$refs.debouncedAlgolia?.setFocusInput()
      }, 300)
    })
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'condominium_overview') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.key !== 'ownershipStart' && x.key !== 'ownershipEnd' && x.key !== 'roommate'))
        let elm = null
        this.dataFields.forEach(element => {
          if (element.key !== 'ownershipStart' && element.key !== 'ownershipEnd' && element.key !== 'roommate') {
            elm = { key: element.key, selected: true }
          } else {
            elm = { key: element.key, selected: false }
          }
          this.selectedFields.push(elm)
        })
      }
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSendMailSecondaryTitle',
      'setUseBcc',
      'setSendMailContactsWithoutEmail',
      'setRenderWithObjectId',
      'setSendMailContactsWithObject',
      'setShowAllPlaceholders'
    ]),
    getState(result) {
      this.nbrTotalOfHits = result.nbHits
      const params = this.editAlgoliaParams(result.params)
      this.paramsAlgolia = {
        query: result.query,
        params: params,
        index: result.index,
        attributesToRetrieve: ['objectID', 'condominium', 'hasActivatedTenancy', 'condominiumOwnership']
      }
      return ''
    },
    dateChanged(e) {
      this.defaultCalendar = e
    },
    dateToTimestamp(list, type) {
      const timeStampList = []
      if (type === 'start' && list.length > 0) {
        list.forEach(item => {
          const dateArray = item.split('/')
          const date = new Date(dateArray[1], dateArray[0] - 1, 1, 0, 0, 0)
          timeStampList.push(date.getTime() / 1000)
        })
        timeStampList.sort(function(x, y) {
          return x - y
        })
        return timeStampList[0]
      }
      if (type === 'end' && list.length > 0) {
        list.forEach(item => {
          const dateArray = item.split('/')
          const date = new Date(dateArray[1], dateArray[0], 0, 23, 59, 59)
          timeStampList.push((date.getTime() / 1000))
        })
        timeStampList.sort(function(x, y) {
          return y - x
        })
        return timeStampList[0]
      }
      return 0
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.objectsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'condominium_overview').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    createTicket(type) {
      const defaultAssignee = this.selectedEntries.every((val, i, arr) => val.networkPartnerAdministration.accountId === arr[0].networkPartnerAdministration.accountId)
        ? this.selectedEntries[0].networkPartnerAdministration.accountId
: ''
      this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const objectIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { objectIds: objectIds, source: 'condominium' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { objectIds: objectIds, source: 'condominium' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { objectIds: objectIds, source: 'condominium' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { objectIds: objectIds, source: 'condominium' } }, () => {})
      }
    },
    serialLetter() {
      const uniqKey = uuidv4()
      localStorage.setItem(uniqKey, this.selectedEntriesIds)
      this.$nextTick(() => {
        this.$router.push({
          name: 'AdminSerialLetterProcessView',
          query: {
            source: 'condominium',
            uuid: uniqKey,
            previousRoute: this.$route.fullPath
          }
        })
      })
    },
    async serialEmail() {
      this.setShowAllPlaceholders(true)
      await this.prepareSerialEmail(this.selectedEntries)
      this.loading = false
    },
    getContacts() {
      ContactApi.getContacts(this.$store.getters['user/getAccountId'])
        .then(response => {
          const allContacts = response.persons.concat(response.companies)
          this.contactList = allContacts.filter(con => con.types.includes('EMPLOYEE'))
          this.contactList = this.contactList.filter(contact => {
            const matchingEmployee = this.employeeList
              .filter(employee => !employee.blocked)
              .find(employee => employee?.email?.toLowerCase()?.trim() === contact?.email?.toLowerCase()?.trim())

            return matchingEmployee !== undefined && matchingEmployee !== null
          })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    },
    clickOnClear() {
      this.$refs.clearClick.click()
    },
    setCanRefine(canRefine) {
      this.checkfilter = canRefine
      return ''
    },
    transformItems(items) {
      return items.filter(item => item.label.indexOf('\\-') === -1)
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    getAssigneeData(items) {
      items.forEach(element => {
        const emp = this.employeeList.find(x => (x.profile.lastName + ' ' + x.profile.firstName) === element.label)
        if (emp) {
          element.active = !emp.blocked
        }
      })
      return [{
        employee: this.$t('message.search.filterAll'),
        employees: items
      }]
    },
    onRowClicked(item, index, event) {
      if (!event.srcElement.classList.contains('tenant-link')) {
        if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
          this.itemSelected(item, !document.getElementById('check_' + item.objectID).checked)
          document.getElementById('check_' + item.objectID).checked = !document.getElementById('check_' + item.objectID).checked
        } else {
          this.navigateToOverview(item, index, event)
        }
      }
    },
    clickSearch() {
      this.currentPage = 1
    },
    openSetAssigneeModal(item) {
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      if (item === null) {
        this.selectedObjectIds = this.selectedEntriesIds
      } else {
        this.selectedObjectIds = [item.objectID]
      }
      this.$refs.setAssigneeModal.newElementId =
        item && item.assignees && item.assignees.asset ? item.assignees.asset.contactId || null : null
      this.$refs.setAssigneeModal.setModalShow = true
    },
    initFilter(refine) {
      this.searchText = ''
      this.searchCategories = []
      this.searchAssetAssignee = []
      this.searchOwner = []
      this.searchRooms = []
      this.searchFloor = []
      this.searchZip = []
      this.searchStatus = []
      this.searchFutureStatus = []
      this.searchNetworkPartner = []
      this.searchPropertyName = []
      this.searchBuildingName = []
      this.searchCity = []
      this.searchPreferredCommunicationChannel = []
      this.searchAdministration = []
      this.searchAccounting = []
      this.searchTags = []
      this.searchSubCategory = []
      refine('')
    },
    setClient(newClient) {
      this.selectedClient = null
      if (this.selectedClient === undefined || this.selectedClient === null) {
        this.$refs.setClientModal.setModalShow = false
        this.$refs.setClientModal.setModalDisabled = false
        this.selectedClient = newClient
        this.$nextTick(() => {
          this.$refs.shareconfirmationModal.show()
        })
      }
    },
    setAssignee(newEmployee) {
      this.$refs.setAssigneeModal.setModalDisabled = true
      ObjectApi.setAssetAssignee(newEmployee ? newEmployee.id : null, this.selectedObjectIds)
        .then(() => {
          this.appRefreshAction = 'assignee'
          this.$refs['app-refresh'].refresh()
        })
        .catch(e => {
          console.log(e)
          this.$refs.setAssigneeModal.setModalDisabled = false
          Vue.toasted.show(this.$t('message.savingErrors.setMarketingObjectAssignee'), { type: 'error' })
        })
    },
    openSetClientModal(type) {
      if (type === 'MANAGEMENT_SCOPE') {
        this.scope = 'MANAGEMENT_SCOPE'
      } else if (type === 'MARKETING_SCOPE') {
        this.scope = 'MARKETING_SCOPE'
      }
      this.$refs.setClientModal.setModalShow = true
    },
    filtredNetworkPartner() {
      if (this.scope) {
        if (this.scope === 'MANAGEMENT_SCOPE') {
          return this.networkPartnerList.filter(x => x.subType.includes('LANDLORD'))
        } else if (this.scope === 'MARKETING_SCOPE') {
          return this.networkPartnerList.filter(x => x.subType.includes('MARKETING'))
        }
      }
      return this.networkPartnerList
    },
    refreshDone() {
      if (this.appRefreshAction === 'assignee') {
        this.clearCheckbox()
        if (this.$refs.setAssigneeModal) {
          Vue.toasted.show(this.$t('message.successMessages.setMarketingObjectAssignee'), { type: 'success' })
          this.$refs.setAssigneeModal.setModalShow = false
          this.$refs.setAssigneeModal.setModalDisabled = false
        }
      } else if (this.appRefreshAction === 'share') {
        Vue.toasted.show(this.$t('message.successMessages.networkPartnerUpdated'), { type: 'success' })
        this.clearCheckbox()
        this.loading = false
        if (this.$refs.shareconfirmationModal) {
          this.$refs.shareconfirmationModal.hide()
        }
      } else if (this.appRefreshAction === 'delete') {
        this.loadingDeletion = false
        this.selectedEntries = []
        this.clearCheckbox()
        Vue.toasted.show(this.$t('message.successMessages.objectDeletion'), { type: 'success' })
        if (this.$refs.confirmationModal) {
          this.$refs.confirmationModal.hide()
        }
      }

      this.appRefreshAction = ''
    },
    saveShares() {
      this.loading = true
      if (!this.selectedEntries.every((val, i, arr) => val.owner.id === arr[0].owner.id)) {
        for (let index = 0; index < this.selectedEntries.length; index++) {
          if (this.selectedClient === undefined || this.selectedClient === null) {
            ShareApi.shareUnset([this.scope], this.selectedEntries[index].owner.id, [this.selectedEntries[index].objectID])
              .then(() => {})
              .catch(e => {
                console.log(e)
                this.$refs.setClientModal.setModalDisabled = false
                Vue.toasted.show(this.$t('message.savingErrors.unsetNetworkPartner'), { type: 'error' })
              })
          } else {
            ShareApi.shareSet(this.scope, this.selectedEntries[index].owner.id, [this.selectedEntries[index].objectID], this.selectedClient.id)
              .then((response) => {
                const existantShare = this.sharesList.findIndex(function (obj) {
                  return response.share.sourceAccountId === obj.sourceAccountId && response.share.targetAccountId === obj.targetAccountId && response.share.scope === obj.scope
                })
                if (existantShare !== -1) {
                  this.sharesList.resourceIds = response.share.resourceIds.concat(this.sharesList.resourceIds)
                } else {
                  this.sharesList.push(response.share)
                }
              })
              .catch(e => {
                console.log(e)
                this.$refs.setClientModal.setModalDisabled = false
                Vue.toasted.show(this.$t('message.savingErrors.setNetworkPartner'), { type: 'error' })
              })
          }
          setTimeout(() => {
            this.clearCheckbox()
            this.appRefreshAction = 'share'
            this.$refs['app-refresh'].refresh()
          }, 2000)
        }
      } else {
        if (this.selectedClient === undefined || this.selectedClient === null) {
          ShareApi.shareUnset([this.scope], this.selectedEntries[0].owner.id, this.selectedEntries.map(x => x.objectID))
            .then(() => {
              setTimeout(() => {
                this.clearCheckbox()
                this.appRefreshAction = 'share'
                this.$refs['app-refresh'].refresh()
              }, 2000)
            })
            .catch(e => {
              console.log(e)
              this.$refs.setClientModal.setModalDisabled = false
              Vue.toasted.show(this.$t('message.savingErrors.unsetNetworkPartner'), { type: 'error' })
            })
        } else {
          ShareApi.shareSet(this.scope, this.selectedEntries[0].owner.id, this.selectedEntries.map(x => x.objectID), this.selectedClient.id)
            .then((response) => {
              const existantShare = this.sharesList.findIndex(function (obj) {
                return response.share.sourceAccountId === obj.sourceAccountId && response.share.targetAccountId === obj.targetAccountId && response.share.scope === obj.scope
              })
              if (existantShare !== -1) {
                this.sharesList.resourceIds = response.share.resourceIds.concat(this.sharesList.resourceIds)
              } else {
                this.sharesList.push(response.share)
              }
              setTimeout(() => {
                this.appRefreshAction = 'share'
                this.$refs['app-refresh'].refresh()
              }, 2000)
            })
            .catch(e => {
              console.log(e)
              this.$refs.setClientModal.setModalDisabled = false
              Vue.toasted.show(this.$t('message.savingErrors.setNetworkPartner'), { type: 'error' })
            })
        }
      }
    },
    customLabel(item) {
      return item.value + ' (' + item.count + ')'
    },
    customLabelChannel(item) {
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL') {
        return this.$t('message.contact.preferredCommunicationChannelType.email') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_LETTER') {
        return this.$t('message.contact.preferredCommunicationChannelType.letter') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED') {
        return '- (' + item.count + ')'
      }
      return item.value
    },
    customLabelSubCategory(item) {
      if (item.value !== 'UNDEFINED_SUB_CATEGORY') {
        return this.$t('message.onBoarding.buildings.objects.subCategory.' + item.value.toLowerCase()) + ' (' + item.count + ')'
      } else {
        return '- (' + item.count + ')'
      }
    },
    customLabelTags(item) {
      return item.label + ' (' + item.count + ')'
    },
    customLabelStatus(item) {
      return this.$t('message.onBoarding.buildings.objects.status.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelFutureStatus(item) {
      return this.$t('message.onBoarding.buildings.objects.futureStatus.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelFloor(item) {
      return this.formatFloor(item.value) + ' (' + item.count + ')'
    },
    customLabelCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    openConfirmationModal() {
      this.$refs.confirmationModal.show()
    },
    selectValue(item, items, refine) {
      if (item === undefined) {
        if (this.searchAssetAssignee.length > 0) {
          this.searchAssetAssignee.map(a => a.value).forEach(element => {
            refine(element)
          })
        }
        const result = items.map(a => a.value)
        result.forEach(element => {
          refine(element)
        })
      } else {
        refine(item)
      }
    },
    removeValue(item, items, refine) {
      if (item === undefined) {
        const result = items.map(a => a.value)
        result.forEach(element => {
          refine(element)
          this.searchAssetAssignee.length = 0
        })
      } else {
        refine(item)
      }
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    deleteObject() {
      const promises = []
      const current = this
      for (let index = 0; index < this.selectedEntriesIds.length; index++) {
        promises.push(ObjectApi.deleteObject(this.selectedEntriesIds[index]))
      }
      this.loadingDeletion = true
      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            this.appRefreshAction = 'delete'
            this.$refs['app-refresh'].refresh()
          }, 2000)
        })
        .catch(e => {
          this.loadingDeletion = false
          current.$refs.confirmationModal.hide()
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.object'), { type: 'error' })
        })
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.objectsTable) {
        const allCheckboxesComponents = this.$refs.objectsTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
        this.selectedEntriesActivatedTenancy.push({ hasActivatedTenancy: item.hasActivatedTenancy, id: item.objectID })
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.objectID !== item.objectID)
        this.selectedEntriesActivatedTenancy = this.selectedEntriesActivatedTenancy.filter(entry => entry.id !== item.objectID)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    navigateToOverview(item) {
      this.$router.push({
        name: 'AssetBuildingDetailsView',
        params: { id: item.buildingId },
        query: {
          view: 'objects',
          elementSelected: item.objectID
        }
      })
    },
    onMiddlelicked(item, index, event) {
      const element = event.srcElement
      if (element.tagName !== 'A') {
        const route = this.$router.resolve({
          name: 'AssetBuildingDetailsView',
          params: { id: item.buildingId },
          query: {
            view: 'objects',
            elementSelected: item.objectID
          }
        })
        window.open(route.href)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.col-20 {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.offset-md-20 {
  margin-left: 60%;
}
:deep(#date-picker-container) {
  position: relative;
  #date-picker-input {
    height: 30px;
    position: absolute;
    width: 100%;
    opacity: 0;
    top:0px;
    z-index: 1;
  }
}
.tooltip.b-tooltip {
  z-index: 2003;
}
</style>
