<template>
  <section>
    <div class="col-4">
      <coozzy-form-input
        id="desiredStartOfContract"
        ref="desiredStartOfContract"
        v-model="desiredStartOfContract"
        :filter="'formatDate'"
        type="date"
        :name="$tc('message.applicationForm.desiredStartOfContract') + '*'"
        :tooltip-text="$tc('message.massRental.desiredStartOfContractTooltip')" />
    </div>
    <div
      v-if="showTable"
      class="col-12 mt-3">
      <div
        class="row">
        <h5 class="ml-3 pt-1 col-12">
          {{ listObjects.length }} {{ $t('message.onBoarding.buildings.objects.titleObjects' ) }}
        </h5>
        <div
          class="col-12">
          <div
            class="row mb-4">
            <div class="col-12 col-md mb-2 mb-md-0">
              <coozzy-form-input
                ref="searchText"
                v-model="searchText"
                :placeholder="$t('message.generic.search')"
                @keyup="searchInputText()" />
            </div>
            <div
              v-if="source === 'property'"
              class="col-12 col-md mb-2 mb-md-0">
              <coozzy-multiselect
                v-model="searchBuilding"
                :options="buildingFilter"
                :multiple="true"
                :placeholder="$tc('message.onBoardingTabs.building')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelBuilding"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="filterObjectList"
                @remove="filterObjectList" />
            </div>
            <div class="col-12 col-md mb-2 mb-md-0">
              <coozzy-multiselect
                v-model="searchFloor"
                class="filter-inputs"
                :options="floorFilter"
                :multiple="true"
                :placeholder="$tc('message.tableColumnHeadings.floor')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelFloor"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="filterObjectList"
                @remove="filterObjectList" />
            </div>
            <div class="col-12 col-md mb-2 mb-md-0">
              <coozzy-multiselect
                v-model="searchRooms"
                class="filter-inputs"
                :options="roomsFilter"
                :multiple="true"
                :placeholder="$tc('message.tableColumnHeadings.rooms')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="filterObjectList"
                @remove="filterObjectList" />
            </div>
            <div class="col-12 col-md mb-2 mb-md-0">
              <coozzy-multiselect
                v-model="searchCategories"
                class="filter-inputs"
                :options="sortedFilterValues(categoryFilter, customLabelCategory, 'value')"
                :multiple="true"
                :placeholder="$tc('message.onBoarding.buildings.objects.category.title')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelCategory"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="filterObjectList"
                @remove="filterObjectList" />
            </div>
          </div>
        </div>
      </div>
      <b-table
        id="table-groups"
        ref="table-groups"
        bordered
        responsive="true"
        stacked="md"
        class="mb-4"
        :fields="fields"
        :items="filteredObjects">
        <!-- Headings -->
        <template #head(checkbox)>
          <coozzy-form-checkbox
            ref="header_checkbox"
            @change="(value) => { handleCheckboxesInsideTable(value) }" />
        </template>
        <template #head(status)>
          {{ $t('message.tableColumnHeadings.status') }}
        </template>
        <template #head(objects)>
          {{ $t('message.tableColumnHeadings.objects') }}
        </template>
        <template #head(objectNumber)>
          {{ $t('message.tableColumnHeadings.objectNumber') }}
        </template>
        <template #head(numericId)>
          {{ $t('message.tableColumnHeadings.numericId') }}
        </template>
        <template #head(address)>
          {{ $t('message.tableColumnHeadings.address') }}
        </template>
        <template #head(type)>
          {{ $t('message.tableColumnHeadings.type') }}
        </template>
        <template #head(floor)>
          {{ $t('message.tableColumnHeadings.floor') }}
        </template>
        <template #head(rooms)>
          {{ $t('message.tableColumnHeadings.rooms') }}
        </template>
        <template #head(area)>
          {{ $t('message.tableColumnHeadings.area') }}
        </template>
        <template #head(tenant)>
          {{ $t('message.tableColumnHeadings.tenant') }}
        </template>
        <template #head(netRent)>
          {{ $t('message.tableColumnHeadings.netRent') }}
        </template>
        <template #head(grossRent)>
          {{ $t('message.tableColumnHeadings.grossRent') }}
        </template>
        <template #head(assignee)>
          {{ $t('message.tableColumnHeadings.assignee') }}
        </template>
        <template #head(partner)>
          {{ $t('message.tableColumnHeadings.partner') }}
        </template>

        <!-- Body -->
        <template #cell(checkbox)="data">
          <coozzy-form-checkbox
            :id="'check_' + data.item.id"
            @change="(value) => { itemSelected(data.item, value) }" />
        </template>
        <template #cell(status)="data">
          <div
            class="color-box mr-2 mt-2"
            :class="getFullStatusColorClass(data.item)" />
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="getStatusText(data.item)" />
        <!-- eslint-enable vue/no-v-html -->
        </template>
        <template #cell(objects)="data">
          {{ data.item.name | displayOptionalValue }}
        </template>
        <template #cell(objectNumber)="data">
          {{ data.item.number | displayOptionalValue }}
        </template>
        <template #cell(numericId)="data">
          {{ data.item.numericId | displayOptionalValue }}
        </template>
        <template #cell(address)="data">
          <address-text :address="data.item.address" />
        </template>
        <template #cell(type)="data">
          <template v-if="data.item.category !== 'UNDEFINED_CATEGORY'">
            {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template #cell(floor)="data">
          <div class="text-center">
            {{ formatFloor(data.item.characteristics ? data.item.characteristics.floor : '') }}
          </div>
        </template>
        <template #cell(rooms)="data">
          <div class="text-center">
            {{ data.item.characteristics.rooms | displayOptionalValue }}
          </div>
        </template>
        <template #cell(area)="data">
          {{ getArea(data.item.characteristics.areas) | formatArea }}
        </template>
        <template #cell(tenant)="data">
          <div v-if="!data.item.condominiumOwnership && getSortedContactIdsCurrentTenancy(data.item.id)">
            <router-link
              v-for="(contactId, tenantIndex) in getSortedContactIdsCurrentTenancy(data.item.id)"
              :key="contactId"
              :to="redirectToDetailPage(contactId)">
              {{ tenantIndex === 0 ? getContactName(contactId).substring(2) : getContactName(contactId) }}
            </router-link>
          </div>
          <span v-else>-</span>
        </template>
        <template #cell(netRent)="data">
          <div class="text-nowrap">
            <span>{{ getNetRent(data.item.id) | formatPrice }}</span>
          </div>
        </template>
        <template #cell(grossRent)="data">
          <span class="text-nowrap">{{ getTotalRent(data.item.id) | formatPrice }}</span>
        </template>
        <template #cell(assignee)="data">
          <span> {{ data.item.contacts && data.item.contacts.contactIdAssigneeAdministration !== '' ? getContactName(data.item.contacts.contactIdAssigneeAdministration).substring(2) : '-' }} </span>
        </template>
        <template #cell(partner)="data">
          <span
            v-if="!isOwnerModule"
            v-b-tooltip="{container: '#buildingObjectList', title: $t('message.generic.accountId') + (ownerAccount ? ownerAccount.id : '')}"
            class="d-block">
            <img
              src="/img/propbase-icon.png"
              class="img_partner"
              alt="prop-base">
            <template v-if="ownerAccount && ownerAccount.company">
              {{ ownerAccount.company.name }}
            </template>
            <template v-if="ownerAccount && ownerAccount.individual">
              {{ ownerAccount.individual.firstName }}. {{ ownerAccount.individual.lastName }}
            </template>
          </span>
          <span
            v-if="!$route.path.startsWith('/asset') && getNetworkPartnersByScope(data.item.id, 'ASSET_SCOPE')"
            v-b-tooltip="{container: '#buildingObjectList', title: $t('message.generic.accountId') + getNetworkPartnersByScope(data.item.id, 'ASSET_SCOPE').id}"
            class="d-block">
            <img
              src="/img/assetbase-icon.png"
              class="img_partner"
              alt="asset-base">
            <template v-if="getNetworkPartnersByScope(data.item.id, 'ASSET_SCOPE')?.company">
              {{ getNetworkPartnersByScope(data.item.id, 'ASSET_SCOPE').company.name }}
            </template>
            <template v-if="getNetworkPartnersByScope(data.item.id, 'ASSET_SCOPE').individual">
              {{ getNetworkPartnersByScope(data.item.id, 'ASSET_SCOPE').individual.firstName }}. {{ getNetworkPartnersByScope(data.item.id, 'ASSET_SCOPE').individual.lastName }}
            </template>
          </span>
          <span
            v-if="!$route.path.startsWith('/admin') && getNetworkPartnersByScope(data.item.id, 'MANAGEMENT_SCOPE')"
            v-b-tooltip="{container: '#buildingObjectList', title: $t('message.generic.accountId') + getNetworkPartnersByScope(data.item.id, 'MANAGEMENT_SCOPE').id}"
            class="d-block">
            <img
              src="/img/adminbase-icon.png"
              class="img_partner"
              alt="admine-base">
            <template v-if="getNetworkPartnersByScope(data.item.id, 'MANAGEMENT_SCOPE').company">
              {{ getNetworkPartnersByScope(data.item.id, 'MANAGEMENT_SCOPE').company.name }}
            </template>
            <template v-if="getNetworkPartnersByScope(data.item.id, 'MANAGEMENT_SCOPE').individual">
              {{ getNetworkPartnersByScope(data.item.id, 'MANAGEMENT_SCOPE').individual.firstName }}. {{ getNetworkPartnersByScope(data.item.id, 'MANAGEMENT_SCOPE').individual.lastName }}
            </template>
          </span>
          <span
            v-if="!$route.path.startsWith('/accounting') && getNetworkPartnersByScope(data.item.id, 'ACCOUNTING_SCOPE')"
            v-b-tooltip="{container: '#buildingObjectList', title: $t('message.generic.accountId') + getNetworkPartnersByScope(data.item.id, 'ACCOUNTING_SCOPE').id}"
            class="d-block">
            <img
              src="/img/financebase-icon.png"
              class="img_partner"
              alt="finance-base">
            <template v-if="getNetworkPartnersByScope(data.item.id, 'ACCOUNTING_SCOPE').company">
              {{ getNetworkPartnersByScope(data.item.id, 'ACCOUNTING_SCOPE').company.name }}
            </template>
            <template v-if="getNetworkPartnersByScope(data.item.id, 'ACCOUNTING_SCOPE').individual">
              {{ getNetworkPartnersByScope(data.item.id, 'ACCOUNTING_SCOPE').individual.firstName }}. {{ getNetworkPartnersByScope(data.item.id, 'ACCOUNTING_SCOPE').individual.lastName }}
            </template>
          </span>
          <span
            v-if="getNetworkPartnersByScope(data.item.id, 'MARKETING_SCOPE')"
            v-b-tooltip="{container: '#buildingObjectList', title: $t('message.generic.accountId') + getNetworkPartnersByScope(data.item.id, 'MARKETING_SCOPE').id}"
            class="d-block">
            <img
              src="/img/marketbase-icon.png"
              class="img_partner"
              alt="market-base">
            <template v-if="getNetworkPartnersByScope(data.item.id, 'MARKETING_SCOPE').company">
              {{ getNetworkPartnersByScope(data.item.id, 'MARKETING_SCOPE').company.name }}
            </template>
            <template v-if="getNetworkPartnersByScope(data.item.id, 'MARKETING_SCOPE').individual">
              {{ getNetworkPartnersByScope(data.item.id, 'MARKETING_SCOPE').individual.firstName }}. {{ getNetworkPartnersByScope(data.item.id, 'MARKETING_SCOPE').individual.lastName }}
            </template>
          </span>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script lang="ts">

import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import { algolia } from '@/mixins/algolia'
import { mapActions, mapGetters } from 'vuex'
import AddressText from '@/framework/components/misc/AddressText.vue'
import Vue, { PropType } from 'vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'

import { countryIndex } from '@/mixins/countryIndex'
import { tables } from '@/mixins/tables'

// import types from api.ts
import {
  Accountv2Account, BuildingBuilding,
  Contactcontactv2Company, ObjectAreas,
  ObjectObject,
  ObjecttenancyTenancy,
  ShareShare,
  V2Person
} from '@/types/api'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect.vue'
import debounce from 'debounce'

type Contact = V2Person | Contactcontactv2Company
type Label = {
  count: number,
  value: string
}

export default Vue.extend({
  name: 'CreateMassRentalObjectsTable',
  components: {
    CoozzyMultiselect,
    CoozzyFormInput,
    AddressText,
    CoozzyFormCheckbox
  },
  mixins: [formatting, obj, routeChecks, algolia, countryIndex, tables],
  props: {
    source: {
      type: String,
      default: ''
    },
    ownerAccount: {
      type: Object as PropType<Accountv2Account>,
      default: () => {}
    },
    objects: {
      type: Array as PropType<ObjectObject[]>,
      default: () => []
    },
    tenancies: {
      type: Array as PropType<ObjecttenancyTenancy[]>,
      default: () => []
    },
    contacts: {
      type: Array as PropType<Contact[]>,
      default: () => []
    },
    shares: {
      type: Array as PropType<ShareShare[]>,
      default: () => []
    },
    networkPartners: {
      type: Array as PropType<Accountv2Account[]>,
      default: () => []
    },
    buildings: {
      type: Array as PropType<BuildingBuilding[]>,
      default: () => []
    }
  },
  data() {
    return {
      loading: true,
      selectedEntries: [],
      filteredObjects: [] as ObjectObject[],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle alignFoot',
          tdClass: 'align-middle'
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'objectNumber',
          label: this.$t('message.tableColumnHeadings.objectNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'numericId',
          label: this.$t('message.tableColumnHeadings.numericId'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'area',
          label: this.$t('message.tableColumnHeadings.area'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'netRent',
          label: this.$t('message.tableColumnHeadings.netRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'grossRent',
          label: this.$t('message.tableColumnHeadings.grossRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'partner',
          label: this.$t('message.tableColumnHeadings.partner'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      desiredStartOfContract: '',
      searchText: '',
      searchCategories: [],
      searchRooms: [],
      searchFloor: [],
      searchBuilding: []
    }
  },
  computed: {
    showTable() {
      const date = new Date(this.desiredStartOfContract)
      return this.isValidDate(date) && !this.disabledBefore1914(date)
    },
    startDateEqualOrMoreThanToday() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const startDate = new Date(this.desiredStartOfContract)
      startDate.setHours(0, 0, 0, 0)
      return startDate.getTime() >= today.getTime()
    },
    floorFilter() {
      let counts = []
      const current = this
      current.listObjects.map((obj: ObjectObject) => obj.characteristics.decimalFloor).forEach(function(x: number) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -10000) {
          const sameNetwork = current.listObjects.map((obj: ObjectObject) => obj.characteristics.decimalFloor).filter((z: number) => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      if (counts.length > 0) {
        counts = this.sortedLocalFloor(counts)
      }
      return counts
    },
    roomsFilter() {
      const counts = []
      const current = this
      current.listObjects.map((obj: ObjectObject) => obj.characteristics.rooms).forEach(function(x: number) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -1) {
          const sameNetwork = current.listObjects.map((obj: ObjectObject) => obj.characteristics.rooms).filter((z: number) => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    categoryFilter() {
      const counts = []
      const current = this
      current.listObjects.map((obj: ObjectObject) => obj.category).forEach(function(x: string) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== 'UNDEFINED_CATEGORY') {
          const sameNetwork = current.listObjects.map((obj: ObjectObject) => obj.category).filter((z: string) => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    buildingFilter() {
      const counts = []
      const current = this
      current.listObjects.map((obj: ObjectObject) => obj.buildingId).forEach(function(x: string) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0) {
          const sameNetwork = current.listObjects.map((obj: ObjectObject) => obj.buildingId).filter((z: string) => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    listObjects(): ObjectObject[] {
      return this.isMarketingModule ? this.objects : this.objects.filter((obj: ObjectObject) => this.getMarketingStatus(obj) === 'no')
    }
  },
  watch: {
    desiredStartOfContract: function (newValue) {
      if (this.showTable && this.startDateEqualOrMoreThanToday) {
        this.$emit('desired-start-date-changed', newValue)
      }
    },
    listObjects: function () {
      this.filterObjectList()
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    searchInputText: debounce(function () {
        this.filterObjectList()
    }, 300),
    customLabel(item: Label) {
      return item.value + ' (' + item.count + ')'
    },
    customLabelBuilding(item: Label) {
      return this.buildings.find((x: BuildingBuilding) => x.id === item.value).name + ' (' + item.count + ')'
    },
    customLabelFloor(item: Label) {
      return this.formatFloor(item.value) + ' (' + item.count + ')'
    },
    customLabelCategory(item: Label) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    filterObjectList() : void {
      const t = this
      this.$nextTick(function () {
        this.filteredObjects = this.listObjects.filter((item: ObjectObject) => {
          const floorMapped = this.searchFloor.map((x: Label) => x.value)
          const cdtFloor = floorMapped.includes(item.characteristics.decimalFloor) || this.searchFloor.length === 0

          const roomMapped = this.searchRooms.map((x: Label) => x.value)
          const cdtRooms = roomMapped.includes(item.characteristics.rooms) || this.searchRooms.length === 0

          const categoryMapped = this.searchCategories.map((x: Label) => x.value)
          const cdtCategory = categoryMapped.includes(item.category) || this.searchCategories.length === 0

          const buildingMapped = this.searchBuilding.map((x: Label) => x.value)
          const cdtBuilding = buildingMapped.includes(item.buildingId) || this.searchBuilding.length === 0

          const currentTenancy = this.getCurrentTenancy(item.id)
          let cdtSearch: boolean
          if (currentTenancy) {
            const currentTenancyTenant = currentTenancy.tenant.contactIds.map((element: string) => this.getContactName(element))
            const cdtSearchTenant = currentTenancyTenant.map((element: string) => element.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
            cdtSearch = item.name.toLowerCase().includes(this.searchText.toLowerCase()) || cdtSearchTenant.includes(true) || item.numericId.toString().includes(this.searchText.toLowerCase()) || item.number.toString().includes(this.searchText.toLowerCase())
          } else {
            cdtSearch = item.name.toLowerCase().includes(this.searchText.toLowerCase()) || item.numericId.toString().includes(this.searchText.toLowerCase()) || item.number.toString().includes(this.searchText.toLowerCase())
          }
          this.clearAllSelectedObject()
          return cdtFloor && cdtRooms && cdtCategory && cdtSearch && cdtBuilding
        }).sort(function(a: ObjectObject, b: ObjectObject) {
          if (a.inactiveBy !== null && new Date(t.$options.filters.objectToDateInput(a.inactiveBy)) <= new Date()) return 0
          if (b.inactiveBy === null) return 1
          return -1
        })
      })
    },
    sortedLocalFloor(items: Label[]) {
      if (items) {
        const posFloor = []
        const negFloor = []
        const floorZero = []
        for (const item of items) {
          if (parseInt(item.value) > 0) {
            posFloor.push(item)
          }
          if (parseInt(item.value) < 0) {
            negFloor.push(item)
          }
          if (parseInt(item.value) === 0) {
            floorZero.push(item)
          }
        }
        posFloor.sort(function(a, b) {
          return parseInt(a.value) - parseInt(b.value)
        })
        negFloor.sort(function(a, b) {
          return parseInt(b.value) - parseInt(a.value)
        })
        return floorZero.concat(posFloor).concat(negFloor)
      }
      return 0
    },
    getArea(area: ObjectAreas) {
      return area.effectiveArea !== -1 ? area.effectiveArea : area.livingArea ? area.livingArea : -1
    },
    clearAllSelectedObject() {
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    handleCheckboxesInsideTable(value: boolean) {
      this.$nextTick(() => {
        if (this.$refs['table-groups'] && this.$refs['table-groups'].$children[1]) {
          const allCheckboxesComponents = this.$refs['table-groups'].$children[1].$children
          this.handleCheckbox(allCheckboxesComponents, value)
        }
      })
    },
    getSortedContactIdsCurrentTenancy(objectId: string) {
      // this function returns the contactIds of the current tenancy sorted by debtor first then tenants
      const startOfDayTimestamp = this.getStartOfDayTimestamp()
      const tenancy = this.getFilteredTenancies(objectId)
        .sort((a: ObjecttenancyTenancy, b: ObjecttenancyTenancy) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
        .find((t: ObjecttenancyTenancy) => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))
      let contactIds = []
      if (tenancy) {
        if (tenancy.tenant.contactIds.includes(tenancy.tenant.billingContactId)) {
          contactIds = [tenancy.tenant.billingContactId].concat(tenancy.tenant.contactIds.filter((x: string) => x !== tenancy.tenant.billingContactId))
        } else {
          contactIds = tenancy.tenant.contactIds
        }
      }
      return contactIds
    },
    redirectToDetailPage(contactId: string) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return { name: 'OwnerContactDetailsView', params: { id: contactId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetContactDetailsView', params: { id: contactId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingContactDetailsView', params: { id: contactId } }
        } else {
          return { name: 'AdminContactDetailsView', params: { id: contactId } }
        }
      }
    },
    itemSelected(item: ObjectObject, checked: boolean) {
      if (checked) {
        this.selectedEntries.push(item)
      } else {
        this.selectedEntries = this.selectedEntries.filter((entry: ObjectObject) => entry.id !== item.id)
      }
      this.$nextTick(() => {
        this.$emit('selected-objects', this.selectedEntries)
      })
    },
    getNetworkPartnersByScope(objectId: string, scope: string): Accountv2Account {
      const shares = this.shares.filter((share: ShareShare) => {
        return share.resourceIds.includes(objectId)
      })
      let item = null
      if (shares.length > 0 && this.networkPartners.length > 0) {
        const partners = this.networkPartners.filter((partner: Accountv2Account) => {
          return shares.map((share: ShareShare) => share.targetAccountId).includes(partner.id)
        })
        partners.forEach((element: Accountv2Account) => {
            const cnd = shares.filter((share: ShareShare) => share.targetAccountId === element.id && share.scope === scope)
            if (cnd.length > 0) {
              item = element
            }
          })
      }
      return item
    },
    getCurrentTenancy(objectId: string): ObjecttenancyTenancy {
      return this.getFilteredTenancies(objectId).find((t: ObjecttenancyTenancy) => t.status === 'TENANCY_STATUS_CURRENT' || t.status === 'TENANCY_STATUS_CURRENT_TERMINATED')
    },
    getContactName(contactId: string): string {
      const contact = this.contacts.concat(this.contacts).find(function (contact: Contact) {
        return contact.id === contactId
      })
      return contact ? ', ' + contact.name : '-'
    },
    getNetRent(objectId: string) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      return adjustedRentValues.coldRent
    },
    getStartOfContract(objectId: string) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      return this.$options.filters.objectToDate(tenancy.startingAt)
    },
    getTotalRent(objectId: string) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      const total = (adjustedRentValues.heatingCosts !== -1 ? adjustedRentValues.heatingCosts : 0) +
        (adjustedRentValues.additionalCosts !== -1 ? adjustedRentValues.additionalCosts : 0) +
        (adjustedRentValues.coldRent !== -1 ? adjustedRentValues.coldRent : 0)
      return total !== 0 ? total : null
    },
    getAdjustedRentValues(tenancy: ObjecttenancyTenancy) {
      let selectedTenancy = tenancy.rent.adjustments[0]
      const current = this
      const pastAdjustements = tenancy.rent.adjustments.filter(obj => obj.byDate && new Date(current.$options.filters.objectToDateInput(obj.byDate)).getTime() < Date.now())
      if (pastAdjustements.length > 0) {
        pastAdjustements.sort(function (a, b) {
          return new Date(current.$options.filters.objectToDateInput(b.byDate)).getTime() - new Date(current.$options.filters.objectToDateInput(a.byDate)).getTime()
        })
        selectedTenancy = pastAdjustements[0]
      }
      return selectedTenancy
    },
    getStatusText(obj: ObjectObject) {
      if (!obj) {
        return 'n/a'
      }
      return this.getFullStatusText(obj)
      // return this.$t('message.onBoarding.buildings.objects.status.' + status)
    },
    getFilteredTenancies(objectId: string): ObjecttenancyTenancy[] {
      return this.tenancies.filter((ten: ObjecttenancyTenancy) => {
        return ten.objectId === objectId
      })
    },
    getFullStatusColorClass(obj: ObjectObject) {
      return this.getStatus(obj)
    }
  }
})
</script>

<style lang="scss" scoped>
.img_partner {
  height: 20px;
}
</style>
