<template>
  <footer id="footer">
    <div
      id="prefooter">
      <div class="row m-0">
        <div class="col">
          <span>© Copyright 2021 Produkt der <a
            href="https://propbase.ch/"
            target="_blank"
            rel="PROPBASE AG">PROPbase AG</a>
          </span>
        </div>
        <div class="col">
          <a
            class="float-right"
            href="#"
            @click="$bvModal.show('privacy-modal')"> {{ $t('message.footer.privacypolicy') }}</a>
          <privacy-modal />
          <a
            v-b-modal.impressum
            class="float-right mr-3"
            href="#">{{ $t('message.footer.imprint') }}</a>
          <b-modal
            id="impressum"
            class="impressum-modal"
            hide-footer>
            <p class="western">
              <b>Impressum</b>
            </p>
            <p class="my-4">
              PROPbase AG<br>
              Im RhyTech 15<br>
              8212 Neuhausen am Rheinfall<br>
              Tel. +41 (0)44 512 76 76
            </p>
          </b-modal>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import PrivacyModal from './PrivacyModal'

export default {
  name: 'CoozzyUserbaseFooter',
  components: {
    PrivacyModal
  }
}
</script>
<style lang="scss" scoped>
  :deep(#impressum .modal-dialog) {
    max-width: 400px !important;
    .modal-body {
      padding-top: 0;
    }
    p {
      text-align: center;
      font-size: 10.5pt;
    }
  }
  a {
    color: $color-text-grey;
  }
  .western b{
    line-height: 20px;
    font-size: 13pt;
  }
  :deep(#app) {
    min-height: calc(100vh - 84px);
  }

  .col-xs-5ths,
  .col-sm-5ths,
  .col-md-5ths,
  .col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-xs-5ths {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%;
  }

  @media (min-width: 992px) {
    .col-md-5ths {
      -webkit-box-flex: 0;
      flex: 0 0 21.75%;
      max-width: 21.75%;
      float: left;

      &.social {
        -ms-flex: 0 0 13.5%;
        -webkit-box-flex: 0;
        flex: 0 0 13.5%;
        max-width: 13%;
      }
    }
  }

  #footer {
    background: #eee;
    border-bottom: 1px solid #c8ced3;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    position: relative;

    #prefooter {
      padding: 15px 0;

      button {
        font-weight: bold;
        color: $color-text-grey-dark;
      }

      ul.staticlist {
        list-style: none;
        padding-left: 0;

        li {
          margin-bottom: 5px;
          font-size: 13px;

          a {
            color: $color-text-grey;
          }
        }

        li:first-child {
          font-weight: 600;
          margin-bottom: 15px;
        }
      }

      span {
        font-size: .8rem;
      }
    }

    .copyright {
      line-height: 22px;
      color: $color-text-grey;
      margin-top: 20px;
      font-size: 13px;
    }

    .footerlink {
      color: $color-text-grey;
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;

      svg {
        color: $color-text-grey;
        font-size: 18px;
        font-weight: bold;
        margin-left: 0;
        margin-right: 30px;

        &.last {
          margin-right: 0;
        }
      }
    }
  }
</style>
