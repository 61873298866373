<template>
  <span v-if="!loading">{{ employeeName | displayOptionalValue }}</span>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EmployeeNameText',
  props: {
    userId: {
      type: String,
      default: ''
    },
    shorten: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    employees() {
      return this.$store.getters['employee/getEmployees']
    },
    employeeName() {
      const employee = this.employees.find(employee => this.userId === employee.id)

      if (employee) {
        return (employee.profile.firstName
          ? (this.shorten ? employee.profile.firstName.substr(0, 1) + '. ' : employee.profile.firstName + ' ')
          : '') +
          employee.profile.lastName +
          (employee.deleted ? ` (${this.$t('message.generic.deleted')})` : '')
      } else {
        return this.userId
      }
    }
  },
  mounted() {
    if (this.employees.length === 0) {
      this.loadEmployees()
    }
    this.loading = false
  },
  methods: {
    ...mapActions('employee', ['loadEmployees'])
  }
}
</script>

<style lang="scss" scoped>

</style>
