<template>
  <input
    ref="bInput"
    v-model="query"
    type="search"
    class="form-control form-control-sm search-box"
    :placeholder="$t('message.generic.search')">
</template>

<script>
import { connectSearchBox } from 'instantsearch.js/es/connectors'
import { createWidgetMixin } from 'vue-instantsearch'

export default {
  name: 'DebouncedAlgoliaSearchBox',
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  props: {
    delay: {
      type: Number,
      default: 300,
      required: false
    },
    defaultValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localQuery: ''
    }
  },
  computed: {
    query: {
      get() {
        return this.localQuery
      },
      set(val) {
        if (this.localQuery !== '') clearTimeout(this.timeoutId)
        this.localQuery = val
        this.timeoutId = setTimeout(() => {
          if (this.state) {
            this.state.refine(this.localQuery)
          }
        }, this.delay)
      }
    }
  },
  watch: {
    defaultValue: function (newVal) {
      if (newVal === '' || newVal === null) {
        this.query = ''
      }
    }
  },
  mounted: function () {
    this.query = this.defaultValue
  },
  destroyed() {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }
  },
  methods: {
    setFocusInput() {
      this.$refs.bInput.focus()
    },
    setValue(query) {
      this.query = query
    }
  }
}
</script>
