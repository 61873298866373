<template>
  <b-card
    class="shadow"
    v-bind="$attrs"
    v-on="mountEventListeners">
    <slot />
  </b-card>
</template>

<script>
export default {
  name: 'CoozzyCard',
  inheritAttrs: false,
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
  }

  .card-subtitle {
    color: $color-text-grey-dark !important;
  }
</style>
