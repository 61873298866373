<template>
  <section class="w-100">
    <div
      :id="`condominium-tenant-${index}`"
      v-b-toggle="`collapse-condominium-tenant-${index}`"
      class="pt-3 mb-0">
      <div class="row">
        <div class="col">
          <h5 class="cursor-pointer hover width-only-content">
            <span v-if="tenant.contactIds.length === 0">
              n/a
            </span>
            <span v-else>
              {{ getTenantsName }}
            </span>
            <span>
              :
              <template v-if="tenant.startingAt && tenant.startingAt !== -1 && tenant.startingAt !== '-1'">
                {{ tenant.startingAt | objectToDate }}
              </template>
              <template v-else>
                n/a
              </template>
              -
              <template v-if="tenant.endingAt && tenant.endingAt !== -1 && tenant.endingAt !== '-1'">
                {{ tenant.endingAt | objectToDate }}
              </template>
              <template v-else>
                n/a
              </template>
            </span>
          </h5>
        </div>
      </div>
    </div>
    <b-collapse
      :id="`collapse-condominium-tenant-${index}`"
      ref="collapse"
      :visible="expandByDefault">
      <div class="row">
        <div
          v-if="readOnly"
          class="col-sm-12 col-md-3">
          <label for="tenants">
            {{ $t('message.onBoarding.buildings.objects.tenancies.tenants.title') }}
          </label>
          <div v-if="tenant.contactIds.length > 0">
            <template
              v-for="(contactId, conIndex) in tenant.contactIds">
              <div
                v-if="getContact(contactId)"
                :key="contactId + conIndex">
                <a
                  role="button"
                  class="cursor-pointer"
                  tabindex="0"
                  @click="redirectToDetailPage(contactId)">
                  {{ getContact(contactId).name }}
                </a>
                <br>
                <address-text
                  :show-short-country="true"
                  :address="getContact(contactId).address" />
              </div>
            </template>
          </div>
          <span
            v-else
            class="d-block">-</span>
        </div>
        <div
          v-else
          class="col-sm-12 col-md-3">
          <!-- <coozzy-contact-multiselect
            id="tenants"
            v-model="tenants"
            :options="availableTenants"
            :clear-on-select="true"
            label="label"
            track-by="label">
            {{ $t('message.onBoarding.buildings.objects.tenancies.tenants.title') }}
          </coozzy-contact-multiselect> -->
          <ais-instant-search
            v-if="searchClientInternal"
            :search-client="searchClientInternal"
            :routing="routing"
            index-name="contact">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-configure
              :hitsPerPage="10"
              :filters="'active:true AND isEmployee:false AND owner.id:' + building.ownerId" />
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-autocomplete>
              <template slot-scope="{ indices, refine }">
                <label>{{ $t('message.onBoarding.buildings.objects.tenancies.tenants.title') }}</label>
                <coozzy-search-multiselect
                  v-model="tenants"
                  :options="indices[0].hits.filter(x => x && x.objectID)"
                  :placeholder="''"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                  :taggable="false"
                  track-by="objectID"
                  label="name"
                  :with-address="false"
                  @search-change="see(refine, $event)" />
              </template>
            </ais-autocomplete>
          </ais-instant-search>
        </div>
        <div
          v-if="readOnly"
          class="col-sm-12 col-md-3">
          <label>
            {{ $t('message.onBoarding.buildings.objects.tenancies.correspondenceAddress') }}
          </label>
          <div v-if="correspondenceContactOption">
            <div v-if="getContact(correspondenceContactOption.objectID || correspondenceContactOption.id)">
              <a
                role="button"
                class="cursor-pointer"
                tabindex="0"
                @click="redirectToDetailPage(correspondenceContactOption.objectID || correspondenceContactOption.id)">
                {{ getContact(correspondenceContactOption.objectID || correspondenceContactOption.id).name }}
              </a>
              <template v-if="getContact(correspondenceContactOption.objectID || correspondenceContactOption.id)">
                <br>
                <address-text
                  :show-short-country="true"
                  :address="getContact(correspondenceContactOption.objectID || correspondenceContactOption.id).address" />
              </template>
            </div>
          </div>
          <span
            v-else
            class="d-block">-</span>
        </div>
        <div
          v-else
          class="col-sm-12 col-md-3">
          <!-- <coozzy-contact-multiselect
            id="copyOfContactId"
            v-model="correspondenceContactOption"
            :options="availableTenantsWithAddress"
            :multiple="false"
            :close-on-select="true"
            label="label"
            track-by="label">
            {{ $t('message.onBoarding.buildings.objects.tenancies.correspondenceAddress') }}
          </coozzy-contact-multiselect> -->
          <ais-instant-search
            v-if="searchClientInternal"
            :search-client="searchClientInternal"
            :routing="routing"
            index-name="contact">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-configure
              :hitsPerPage="10"
              :filters="'active:true AND isEmployee:false AND owner.id:' + building.ownerId" />
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-autocomplete>
              <template slot-scope="{ indices, refine }">
                <label>{{ $t('message.onBoarding.buildings.objects.tenancies.correspondenceAddress') }}</label>
                <coozzy-search-multiselect
                  v-model="correspondenceContactOption"
                  :options="indices[0].hits.filter(x => x && x.objectID)"
                  :placeholder="''"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                  :taggable="false"
                  track-by="objectID"
                  label="name"
                  @search-change="see(refine, $event)" />
              </template>
            </ais-autocomplete>
          </ais-instant-search>
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.startingAt"
            :is-read-only="readOnly"
            :filter="'formatDate'"
            type="date"
            :state="$v.startingAt.$dirty && $v.startingAt.$error ? false : null"
            :name="$t('message.onBoarding.buildings.objects.tenancies.startAt')"
            @blur="dateToObject()" />
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.endingAt"
            :is-read-only="readOnly"
            :filter="'formatDate'"
            type="date"
            :state="$v.endingAt.$dirty && $v.endingAt.$error ? false : null"
            :name="$t('message.onBoarding.buildings.objects.tenancies.endAt')"
            @blur="dateToObject()" />
        </div>
        <div
          v-if="!readOnly"
          class="col-md-1 offset-md-11 text-right">
          <label
            v-if="!searchClientInternal"
            for="delete"
            class="text-white">delete</label>
          <coozzy-button
            design="alert"
            class="align-self-center"
            :class="searchClientInternal ? 'mt-3' : ''"
            @click="openConfirmationModal()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </div>
    </b-collapse>
    <b-modal
      :id="'tenant-confirmationModal'"
      ref="tenant-confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.titleTenants')">
      <template>
        <div class="col">
          <p>{{ $t('message.onBoarding.deleteModals.bodyTenants') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('tenant-confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteTenantClicked()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>
<script>
import Vue from 'vue'
import { routeChecks } from '@/mixins/routeChecks'
import { onboarding } from '@/mixins/onboarding'
import { validation } from '@/mixins/validation'
import { algolia } from '@/mixins/algolia'
import AddressText from '@/framework/components/misc/AddressText'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import ContactApi from '@/misc/apis/ContactApi'

function lessThanEnding(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.endingAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) <= new Date(this.$options.filters.objectToDateInput(vm.endingAt))
}
function moreThanStartingContract(value, vm) {
  if (!value) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.condominium.startingAt))
}
function moreThanStarting(value, vm) {
  if (!value) {
    return true
  }
  if ((!vm.startingAt || new Date(this.$options.filters.objectToDateInput(vm.startingAt)).getTime() / 1000 <= 0)) {
    return true
  }

  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(vm.startingAt))
}
export default {
  name: 'CondominiumTenant',
  components: {
    AddressText,
    CoozzySearchMultiselect
  },
  mixins: [routeChecks, onboarding, validation, algolia],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    tenant: {
      type: Object,
      default: null
    },
    condominium: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      dates: {
        startingAt: null,
        endingAt: null
      },
      usedContacts: []
    }
  },
  computed: {
    getTenantsName() {
      let names = ''
      const array = this.tenant.contactIds
      for (let index = 0; index < array.length; index++) {
        names = names + (index === 0 ? this.getContactName(array[index]).substr(2) : this.getContactName(array[index]))
      }
      return names
    },
    tenants: {
      get() {
        return this.tenant.contactIds.map(tenantId => {
          return this.usedContacts.find(t => {
            return t.objectID ? t.objectID === tenantId : t.id === tenantId
          })
        }).filter(t => {
          return t
        })
      },
      set(value) {
        const newSubTenant = this.tenant
        newSubTenant.contactIds = value.map(v => v.objectID)
        this.usedContacts = this.usedContacts.concat(value)
        this.usedContacts = [...this.removeDuplicatesContact(this.usedContacts)]
        this.$emit('change-tenant', newSubTenant)
      }
    },
    correspondenceContactOption: {
      get() {
        return this.usedContacts.find(t => {
          return t.objectID ? t.objectID === this.tenant.correspondenceContactId : t.id === this.tenant.correspondenceContactId
        })
      },
      set(value) {
        const newSubTenant = this.tenant
        if (value.length > 0) {
          newSubTenant.correspondenceContactId = value[value.length - 1].objectID
          this.usedContacts.push(value[value.length - 1])
          this.usedContacts = [...this.removeDuplicatesContact(this.usedContacts)]
        } else {
          newSubTenant.correspondenceContactId = null
        }
        this.$emit('change-tenant', newSubTenant)
      }
    },
    startingAt: {
      get() {
        return this.tenant.startingAt !== -1 ? this.tenant.startingAt : null
      },
      set(value) {
        const newSubTenant = this.tenant
        newSubTenant.startingAt = value
        this.$emit('change-tenant', newSubTenant)
      }
    },
    endingAt: {
      get() {
        return this.tenant.endingAt !== -1 && this.tenant.endingAt !== '0' ? this.tenant.endingAt : null
      },
      set(value) {
        const newSubTenant = this.tenant
        newSubTenant.endingAt = value
        this.$emit('change-tenant', newSubTenant)
      }
    }
  },
  mounted() {
    this.loadInstantSearchInternal()
    this.dates.startingAt = this.startingAt ? this.$options.filters.objectToDateInput(this.startingAt) : ''
    this.dates.endingAt = this.endingAt ? this.$options.filters.objectToDateInput(this.endingAt) : ''
    const contactsToLoadAlgolia = this.tenant.contactIds.concat([this.tenant.correspondenceContactId])
    this.availableTenants(contactsToLoadAlgolia.filter(x => x !== null && x !== ''))
  },
  methods: {
    openConfirmationModal() {
      this.$refs['tenant-confirmationModal'].show()
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    },
    dateToObject() {
      const startingAt = new Date(this.dates.startingAt)
      this.startingAt = startingAt instanceof Date && isFinite(startingAt)
? {
        day: startingAt.getDate(),
        month: startingAt.getMonth() + 1,
        year: startingAt.getFullYear()
      }
: null
      const endingAt = new Date(this.dates.endingAt)
      this.endingAt = endingAt instanceof Date && isFinite(endingAt)
? {
        day: endingAt.getDate(),
        month: endingAt.getMonth() + 1,
        year: endingAt.getFullYear()
      }
: null
    },
    getContactName(contactId) {
      const contact = this.getContact(contactId)
      return contact ? ', ' + contact.name : '-'
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          })
        } else {
          this.$router.push({
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          })
        }
      }
    },
    getContact(contactId) {
      return this.usedContacts.find(contact => contact.id === contactId || contact.objectID === contactId)
    },
    deleteTenantClicked() {
      this.$emit('delete-tenant')
      this.$refs['tenant-confirmationModal'].hide()
    },
    isInvalid() {
      let valid = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        valid = false
      }
      if (this.$v.startingAt.$dirty && this.$v.startingAt.$error) {
        Vue.toasted.show(this.$t('message.savingErrors.contractTenantBeforeOwnership'), { type: 'error' })
      }
      return !valid
    },
    see(refine, value) {
      refine(value)
    },
    availableTenants(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          this.usedContacts = this.usedContacts.concat(contacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  validations: {
    startingAt: {
      moreThanStartingContract,
      lessThanEnding
    },
    endingAt: {
      moreThanStarting
    }
  }
}
</script>
