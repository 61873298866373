<template>
  <section class="container-fluid">
    <div class="row">
      <div class="col-">
        <img :src="realEstateInformation.previewImageHref">
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <p>
              {{ realEstateInformation.title }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>
              <span>{{ realEstateInformation.postalCode }}</span>
              <span>{{ realEstateInformation.city }}</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>
              {{ realEstateInformation.rent | formatPrice }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CoozzyAdvertisementPreviewSideBarCard',
  props: {
    realEstateInformation: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 0.5em;
  }

  div {
    &.col {
      p {
        > span:nth-of-type(2)::before {
          content: '\00a0';
        }
      }
    }
  }

  img {
    max-width: 100%;
    width: 8.5em;
  }

  section:hover {
    background-color: rgb(184, 210, 227);
  }
</style>
