<template>
  <div class="row">
    <handover-objects-table
      :objects-with-same-tenants="objectsWithSameTenants"
      :same-tenancies-by-object="sameTenanciesByObject"
      :object-id="objectId"
      @selected-object="selectedObject" />
  </div>
</template>

<script>
import HandoverObjectsTable from '@/handover/component/HandoverObjectsTable.vue'

export default {
  name: 'HandoverOtherTenancies',
  components: {
    HandoverObjectsTable
  },
  props: {
    objectsWithSameTenants: {
      type: Array,
      required: true
    },
    sameTenanciesByObject: {
      type: Array,
      required: true
    },
    objectId: {
      type: String,
      required: true
    }
  },
  methods: {
    selectedObject(object) {
      this.$emit('selected-object', object)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
