<template>
  <coozzy-alert
    v-if="run !== null"
    :variant="variant">
    {{ text }}
    <template v-if="variant === 'danger' && errorMessage">
      <br><br>
      {{ $t('message.generic.error') }}:
      <br>
      {{ errorMessage }}
    </template>
  </coozzy-alert>
</template>

<script>
import CoozzyAlert from '@/framework/components/alert/CoozzyAlert'

export default {
  name: 'ExportSettingsRunDetails',
  components: { CoozzyAlert },
  props: {
    run: {
      type: Object,
      default: null
    }
  },
  computed: {
    variant() {
      if (this.run.status === 'finished') {
        return 'success'
      } else if (this.run.status === 'failed') {
        return 'danger'
      } else {
        return 'info'
      }
    },
    text() {
      if (this.run.status === 'finished') {
        return this.$t('message.export.successfulDescription')
      } else if (this.run.status === 'failed') {
        return this.$t('message.export.failedDescription')
      } else if (this.run.status === 'pending' || this.run.status === 'started') {
        return this.$t('message.export.pendingDescription')
      }

      return ''
    },
    errorMessage() {
      if (this.run.events && this.run.events.length > 0) {
        return this.run.events[this.run.events.length - 1].message
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
