<template>
  <b-list-group-item
    v-bind="$attrs"
    v-on="mountEventListeners">
    <slot />
  </b-list-group-item>
</template>

<script>
export default {
  name: 'CoozzyListItem',
  inheritAttrs: false,
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-group-item {
    color: #000;

    &:hover {
      background-color: #e4e6e7;
    }

    &.active {
      background-color: #e4e6e7;
      border-color: #d7d7d7 ;
    }
  }
</style>
