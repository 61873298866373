<template>
  <section>
    <div
      class="row">
      <div class="col">
        <!-- Edit profile -->
        <coozzy-card
          :title="$t('message.companyProfileOverview.editProfile')"
          class="mb-3">
          <div
            v-if="loading"
            style="height: 30px">
            <coozzy-spinner />
          </div>
          <coozzy-form v-else>
            <div class="justify-content-start align-items-center mb-3">
              <img
                v-if="showCompanyLogo && localLogoUrl"
                ref="companyLogo"
                :src="localLogoUrl"
                alt=""
                class="picture-company mr-sm-3 mb-md-0 mb-2 mx-auto ml-sm-0 d-block d-sm-inline"
                @error="onError()">
              <coozzy-form-file-input
                ref="coozzy-file-input"
                class="company-logo-file-input"
                accept="image/jpeg, image/png"
                :placeholder="$t('message.companyProfileOverview.uploadCompanyLogo')"
                @change="addFile" />
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6 mb-2">
                <coozzy-form-input
                  v-model="company.name"
                  type="text"
                  :name="$t('message.generic.companyName') + '*'"
                  :state="$v.company.name.$dirty && $v.company.name.$error ? false : null" />
              </div>
              <div class="col-sm-12 col-md-6 mb-2">
                <coozzy-form-input
                  v-model="company.email"
                  type="email"
                  :state="$v.company.email.$dirty && $v.company.email.$error ? false : null"
                  :name="$t('message.generic.email') + '*'" />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <coozzy-form-input
                  v-model="company.address.streetAddition"
                  type="text"
                  :name="$t('message.generic.addressAddition')" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-10 mb-2">
                <coozzy-form-input
                  v-model="company.address.street"
                  type="text"
                  :name="$t('message.generic.streetHouseNumber')" />
              </div>
              <div class="col-sm-12 col-md-2 mb-2">
                <coozzy-form-input
                  v-model="company.address.streetNumber"
                  type="text"
                  :name="$t('message.generic.streetNumber')" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-3 mb-2">
                <coozzy-form-country-select
                  v-model="company.address.country"
                  :initial-value="company.address.country"
                  :required="false" />
              </div>
              <div class="col-sm-6 col-md-3 mb-2">
                <coozzy-form-region-select
                  v-model="company.address.state"
                  :country="company.address.country"
                  :initial-value="company.address.state"
                  :required="false" />
              </div>
              <div class="col-sm-6 col-md-3 mb-2">
                <coozzy-form-input
                  v-model="company.address.zip"
                  type="text"
                  :name="$t('message.generic.zip')" />
              </div>
              <div class="col-sm-12 col-md-3 mb-2">
                <coozzy-form-input
                  v-model="company.address.city"
                  type="text"
                  :name="$t('message.generic.city')" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-4 mb-2">
                <coozzy-form-input
                  v-model="company.phoneNumber"
                  type="text"
                  :name="$t('message.generic.phoneNumber')" />
              </div>
              <div class="col-sm-12 col-md-4 mb-2">
                <coozzy-form-input
                  v-model="company.website"
                  type="text"
                  :name="$t('message.generic.website')" />
              </div>
              <div class="col-sm-12 col-md-4 mb-2">
                <coozzy-form-input
                  v-model="company.vatNumber"
                  type="text"
                  :name="$t('message.generic.vatNumber')" />
              </div>
              <div
                v-if="account"
                class="col-sm-12 col-md-6 mb-2">
                <label>{{ $t('message.generic.contactPerson') }}</label>
                <coozzy-form-select
                  v-model="contactPerson">
                  <option value="">
                    -
                  </option>
                  <option
                    v-for="employee in employees"
                    :key="employee.id"
                    :value="employee">
                    <employee-name-text :user-id="employee.id" />
                  </option>
                </coozzy-form-select>
              </div>
              <div
                v-if="account"
                class="col-sm-12 col-md-12 mb-2">
                <coozzy-form-textarea
                  v-model="company.aboutUs"
                  :initial="company.aboutUs"
                  :value-text-area="company.aboutUs"
                  :rows="calculateNumbersOfLines(2,3,4)">
                  {{ $t('message.companyProfileOverview.aboutLanguage.aboutUs') }}
                </coozzy-form-textarea>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <coozzy-button
                  class="float-right mt-3"
                  design="green"
                  @click="saveCompany">
                  {{ $t('message.generic.form.save') }}
                </coozzy-button>
              </div>
            </div>
          </coozzy-form>
        </coozzy-card>
      </div>
    </div>

    <!-- Delete -->
    <div
      v-if="false"
      class="row">
      <div class="col">
        <coozzy-card
          :title="$t('message.companyProfileOverview.deleteCompanyPart.title')"
          :sub-title="$t('message.companyProfileOverview.deleteCompanyPart.info')"
          class="mb-3">
          <div class="row">
            <div class="col">
              <coozzy-button
                class="float-right"
                design="red"
                :disabled="!isDeletable"
                @click="deleteCompany">
                {{ $t('message.generic.form.delete') }}
              </coozzy-button>
            </div>
          </div>
        </coozzy-card>
      </div>
    </div>
  </section>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import { user } from '@/mixins/user'
import { logout } from '@/mixins/logout'
import EmployeeApi from '../../misc/apis/EmployeeApi'
import EmployeeNameText from '../../framework/components/misc/EmployeeNameText'
import UserApi from '../../misc/apis/UserApi'
import MediaApi from '../../misc/apis/MediaApi'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import Vue from 'vue'
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyForm from '../../framework/components/form/CoozzyForm'
import CoozzyFormFileInput from '../../framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import CoozzyFormTextarea from '../../framework/components/form/textarea/CoozzyFormTextarea'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyFormCountrySelect from '@/framework/components/form/select/CoozzyFormCountrySelect'
import CoozzyFormRegionSelect from '@/framework/components/form/select/CoozzyFormRegionSelect'
import { responsivity } from '@/mixins/responsivity'
import { media } from '@/mixins/media'

export default {
  name: 'CompanyProfileSettings',
  components: {
    CoozzyFormRegionSelect,
    CoozzyFormCountrySelect,
    CoozzyButton,
    CoozzyFormTextarea,
    CoozzyFormSelect,
    CoozzyFormInput,
    CoozzyFormFileInput,
    CoozzyForm,
    CoozzyCard,
    CoozzySpinner,
    EmployeeNameText
  },
  mixins: [user, logout, responsivity, media],
  data() {
    return {
      companyLogo: null,
      showCompanyLogo: true,
      localLogoUrl: null,
      account: null,
      contactPerson: null,
      company: {
        name: '',
        email: '',
        phoneNumber: '',
        website: '',
        vatNumber: '',
        address: {
          country: 'CH',
          state: '',
          zip: '',
          city: '',
          street: '',
          streetNumber: '',
          streetAddition: ''
        }
      },
      loading: false
    }
  },
  computed: {
    isDeletable() {
      return (this.$store.getters['employee/getEmployees'] || []).length === 1
    },
    employees() {
      return this.$store.getters['employee/getEmployees'].filter(
        emp => !emp.blocked
      )
    }
  },
  mounted: function () {
    this.loading = true

    const promiseCompany = UserApi.getAccount(this.accountId)
    const promiseEmployees = EmployeeApi.getEmployees(this.accountId)

    Promise.all([promiseCompany, promiseEmployees])
      .then(values => {
        this.localLogoUrl = values[0].account.logoUrl
        if (values[0].account.company) {
          this.company = values[0].account.company
          if (values[0].account.contactPerson !== null) {
            this.contactPerson = values[1].employees.find(em => em.id === values[0].account.contactPerson.userId)
          }
          this.account = values[0].account
        }

        this.loading = false
      })
      .catch(e => {
        this.loading = false
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.accountSettings'), { type: 'error' })
      })
  },
  methods: {
    deleteCompany() {
      this.loading = true

      EmployeeApi.deleteUser(this.userId)
        .then(() => {
          this.logout()
          Vue.toasted.show(this.$t('message.successMessages.deleteCompany'), { type: 'success' })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.company'), { type: 'error' })
        })
    },
    async addFile(event) {
      const files = await this.getFilesFromEvent(event)
      if (files.length > 0) {
        this.companyLogo = files[0]
        await this.setLogo()
      }
    },
    async setLogo() {
      this.loading = true
      const selectedFile = this.companyLogo ? [this.companyLogo] : []
      const response = await this.uploadMedia(selectedFile)
      if (response.length > 0) {
        const media = response[0]
        this.localLogoUrl = media.url
        UserApi.setAccountLogo(this.accountId, media.url)
          .then(() => {
            this.loading = false
            this.$store.dispatch('user/setLogoUrl', media.url).then().catch(e => console.log(e))
            Vue.toasted.show(this.$t('message.successMessages.saveLogo'), { type: 'success' })
          })
      } else {
        this.loading = false
      }
    },
    onError() {
      this.showCompanyLogo = false
    },
    saveCompany() {
      this.$v.company.$touch()
      if (!this.$v.company.$invalid) {
        this.loading = true
        let selectedContactPerson = {}
        if (this.contactPerson !== '' && this.contactPerson !== null) {
          selectedContactPerson = {
            email: this.contactPerson.email,
            name: this.contactPerson.profile.firstName + ' ' + this.contactPerson.profile.lastName,
            phone: this.contactPerson.profile.phoneNumber,
            userId: this.contactPerson.id
          }
        }

        const promiseSetCompany = UserApi.setCompanyData(this.accountId, this.company)
        const promiseContactPerson = UserApi.updateContactPerson(this.accountId, selectedContactPerson)

        Promise.all([promiseSetCompany, promiseContactPerson])
          .then(() => {
            this.loading = false
            Vue.toasted.show(this.$t('message.successMessages.profileUpdated'), { type: 'success' })
          })
          .catch(e => {
            this.loading = false
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.accountSettings'), { type: 'error' })
          })
      }
    }
  },
  validations: {
    company: {
      name: {
        required
      },
      email: {
        required,
        email
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.custom-file-label) {
    overflow: hidden;
  }

  .company-logo-file-input {
    max-width: 400px;
  }

  .show-preview {
    color: $color-primary;
  }

  .picture-company {
    max-width: 100px;
    max-height: 100px;
  }

  .spinner-border {
    position: absolute;
    left: 50%;
    right: 50%;
  }
</style>
