<template>
  <section>
    <button
      class="d-none"
      @click="refresh">
      Refresh
    </button>
  </section>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch'
const connectRefresh = (
  renderFn,
  unmountFn
) => (/* this widget has no parameters */) => ({
  init() {
    renderFn({ refresh: () => {} }, true)
  },
  render({ instantSearchInstance }) {
    if (instantSearchInstance) {
      const refresh = instantSearchInstance.refresh.bind(instantSearchInstance)
      renderFn({ refresh }, false)
    }
  },
  dispose() {
    unmountFn()
  }
})
export default {
  name: 'RefreshAlgolia',
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  methods: {
    refresh(timeout = true) {
      if (timeout) {
        setTimeout(() => {
          this.state.refresh()
          this.$emit('refresh-done')
        }, 2000)
      } else {
        this.state.refresh()
        this.$emit('refresh-done')
      }
    }
  }
}
</script>
