<template>
  <div>
    <coozzy-spinner v-if="loading" />
    <div v-if="!loading && !hasNewHomeExport && !hasImmoScoutExport && !hasHomegateExport && !hasCasaoneExport && !hasFlatfoxExport">
      {{ $t('message.export.noOneConfigured') }}
    </div>
    <div
      v-else-if="source === 'editAdvert'"
      class="col-12 p-0 mt-3">
      <b-table
        ref="platformTables"
        hover
        bordered
        class="mb-0 platform-tables"
        :fields="fields"
        :items="platformsData">
        <!-- Busy state -->
        <div
          slot="table-busy"
          class="text-center text-danger my-2">
          <coozzy-spinner />
        </div>

        <!-- Header -->
        <template
          #head(checkbox)>
          <coozzy-form-checkbox
            ref="header_checkbox"
            @change="(value) => { handleCheckboxesInsideTable(value) }" />
        </template>
        <template
          #head(platform)>
          <span>{{ $t('message.tableColumnHeadings.platform') }}</span>
        </template>
        <template
          #head(information)>
          <span>{{ $t('message.tableColumnHeadings.information') }}</span>
        </template>

        <!-- Body -->
        <template
          #cell(checkbox)="data">
          <coozzy-form-checkbox
            :id="'check_' + data.item.name"
            :initial="platforms[data.item.name]"
            @change="(value) => { itemSelected(data.item.name) }" />
        </template>
        <template
          #cell(platform)="data">
          <img
            :src="data.item.img"
            class="logo">
        </template>
        <template
          #cell(information)="data">
          {{ data.item.info }}
        </template>
      </b-table>
    </div>
    <div
      v-else
      class="mt-2">
      <coozzy-form-checkbox
        v-if="hasNewHomeExport"
        v-model="platforms.newhome"
        :initial="platforms.newhome"
        name="NEWHOME_PLATFORM">
        NewHome
      </coozzy-form-checkbox>
      <coozzy-form-checkbox
        v-if="hasImmoScoutExport"
        v-model="platforms.immoscout"
        :initial="platforms.immoscout"
        name="IMMOSCOUT_PLATFORM">
        ImmoScout
      </coozzy-form-checkbox>
      <coozzy-form-checkbox
        v-if="hasHomegateExport"
        v-model="platforms.homegate"
        :initial="platforms.homegate"
        name="HOMEGATE_PLATFORM">
        Homegate
      </coozzy-form-checkbox>
      <coozzy-form-checkbox
        v-if="hasCasaoneExport"
        v-model="platforms.casaone"
        :initial="platforms.casaone"
        name="CASAONE_PLATFORM">
        Casasoft
      </coozzy-form-checkbox>
      <coozzy-form-checkbox
        v-if="hasFlatfoxExport"
        v-model="platforms.flatfox"
        :initial="platforms.flatfox"
        name="FLATFOX_PLATFORM">
        Flatfox
      </coozzy-form-checkbox>

      <coozzy-button
        v-if="showSaveButton"
        design="green"
        class="my-3 float-right"
        @click="savePlatforms">
        {{ $t('message.generic.form.save') }}
      </coozzy-button>
    </div>
  </div>
</template>

<script>
import ExportApi from '../../misc/apis/ExportApi'
import Vue from 'vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'

export default {
  name: 'ExportPlatforms',
  components: { CoozzySpinner, CoozzyButton, CoozzyFormCheckbox },
  props: {
    advertIds: {
      type: Array,
      default: () => []
    },
    showSaveButton: {
      type: Boolean,
      default: true
    },
    source: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: true,
      exportConfigs: [],
      platforms: {
        newhome: false,
        immoscout: false,
        homegate: false,
        casaone: false,
        flatfox: false
      },
      platformsData: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'platform',
          label: 'platform',
          tdClass: 'align-middle'
        },
        {
          key: 'information',
          label: 'information',
          tdClass: 'align-middle'
        }
      ]
    }
  },
  computed: {
    hasNewHomeExport() {
      return this.exportConfigs.find(e => e.platform === 'NEWHOME_PLATFORM') !== undefined
    },
    hasImmoScoutExport() {
      return this.exportConfigs.find(e => e.platform === 'IMMOSCOUT_PLATFORM') !== undefined
    },
    hasHomegateExport() {
      return this.exportConfigs.find(e => e.platform === 'HOMEGATE_PLATFORM') !== undefined
    },
    hasCasaoneExport() {
      return this.exportConfigs.find(e => e.platform === 'CASAONE_PLATFORM') !== undefined
    },
    hasFlatfoxExport() {
      return this.exportConfigs.find(e => e.platform === 'FLATFOX_PLATFORM') !== undefined
    }
  },
  watch: {
    platforms: {
      deep: true,
      handler: function (val) {
        this.$emit('platforms-changed', this.platforms)
      }
    }
  },
  mounted() {
    const promises = [ExportApi.getExports(this.$store.getters['user/getAccountId'])]
    if (this.advertIds.length === 1) {
      promises.push(ExportApi.getExportPlatforms(this.advertIds[0]))
    }

    Promise.all(promises)
      .then(values => {
        this.exportConfigs = values[0].exports

        this.platforms.newhome = values[1].platforms.includes('NEWHOME_PLATFORM')
        this.platforms.immoscout = values[1].platforms.includes('IMMOSCOUT_PLATFORM')
        this.platforms.homegate = values[1].platforms.includes('HOMEGATE_PLATFORM')
        this.platforms.casaone = values[1].platforms.includes('CASAONE_PLATFORM')
        this.platforms.flatfox = values[1].platforms.includes('FLATFOX_PLATFORM')
        this.checkTableData()
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.exportPlatforms'), { type: 'error' })
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    savePlatforms() {
      if (!this.hasNewHomeExport && !this.hasImmoScoutExport && !this.hasHomegateExport && !this.hasCasaoneExport && !this.hasFlatfoxExport) {
        this.$emit('platforms-saved')
      } else {
        this.loading = true
        this.$emit('saving-platforms')

        const platforms = []
        if (this.platforms.newhome) {
          platforms.push('NEWHOME_PLATFORM')
        }
        if (this.platforms.immoscout) {
          platforms.push('IMMOSCOUT_PLATFORM')
        }
        if (this.platforms.homegate) {
          platforms.push('HOMEGATE_PLATFORM')
        }
        if (this.platforms.casaone) {
          platforms.push('CASAONE_PLATFORM')
        }
        if (this.platforms.flatfox) {
          platforms.push('FLATFOX_PLATFORM')
        }

        const promises = []
        this.advertIds.forEach(id => {
          promises.push(ExportApi.setExportPlatforms(this.$store.getters['user/getAccountId'], id, platforms))
        })

        Promise.all(promises)
          .then(() => {
            this.$emit('platforms-saved')
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.exportPlatforms'), { type: 'error' })
          })
          .then(() => {
            this.loading = false
          })
      }
    },
    itemSelected(name) {
      this.platforms[name] = !this.platforms[name]
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.platformTables) {
        const allCheckboxesComponents = this.$refs.platformTables.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
      }
    },
    checkTableData() {
      if (this.hasImmoScoutExport) {
        this.platformsData.push({ name: 'immoscout', img: require('@/assets/exportCard/Immoscout.png'), info: this.$t('message.export.information.immoscout') })
      }
      if (this.hasNewHomeExport) {
        this.platformsData.push({ name: 'newhome', img: require('@/assets/exportCard/newhome.png'), info: this.$t('message.export.information.newhome') })
      }
      if (this.hasHomegateExport) {
        this.platformsData.push({ name: 'homegate', img: require('@/assets/exportCard/homegate.png'), info: this.$t('message.export.information.homegate') })
      }
      if (this.hasCasaoneExport) {
        this.platformsData.push({ name: 'casaone', img: require('@/assets/exportCard/casasoft.svg'), info: this.$t('message.export.information.casaone') })
      }
      if (this.hasFlatfoxExport) {
        this.platformsData.push({ name: 'flatfox', img: require('@/assets/exportCard/flatfox.svg'), info: this.$t('message.export.information.flatfox') })
      }
    },
    handleCheckbox(allCheckboxesComponents, value) {
      allCheckboxesComponents.forEach(component => {
        for (const node of component.$el.childNodes[0].childNodes[0].childNodes) {
          if (node.getAttribute('type') === 'checkbox') {
            if (node.checked !== value) {
              node.checked = value
              let event = new Event('change')
              node.dispatchEvent(event)
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.logo{
  max-height: 30px;
}
</style>
