<template>
  <section class="w-100">
    <div
      :id="`bank-details-${index}`"
      v-b-toggle="`collapse-bank-details-${index}`"
      class="col pt-3 mb-0">
      <div class="row">
        <div class="col">
          <h5 class="cursor-pointer hover width-only-content ">
            {{ title }}
          </h5>
        </div>
        <div
          class="col mr-5">
          <coozzy-dropdown
            v-if="isEditor"
            :text="$t('message.generic.actions')"
            class="w-20 sort float-right"
            size="sm">
            <coozzy-dropdown-item
              @click="readOnly ? goToEditMode() : addDocumentClicked('mortgage' + index);$event.stopPropagation()">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
      </div>
    </div>
    <div class="col-12">
      <b-collapse
        :id="`collapse-bank-details-${index}`"
        ref="collapse"
        :visible="expandByDefault">
        <div class="row details-row-bg-1 py-01rem">
          <div
            v-if="!readOnly"
            class="col-sm-12 col-md-3">
            <label for="autosuggest">{{
              $t('message.onBoarding.bankDetails.bankName')
            }}*</label>
            <ais-instant-search
              v-if="searchClient"
              :search-client="searchClient"
              :routing="routing"
              index-name="banks">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                filters="countryCode:CH OR countryCode:DE OR countryCode:AT OR countryCode:LI" />
              <!-- eslint-enable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <vue-autosuggest
                    ref="autosuggest"
                    v-model="bankName"
                    :suggestions="indicesToSuggestions(indices)"
                    :get-suggestion-value="s => s.item.bankOrInstitutionName"
                    :input-props="{id:'autosuggest__input', class : 'form-control form-control-sm', placeholder: $t('message.generic.search'),}"
                    :class="$v.bankName.$dirty && $v.bankName.$error ? 'is-invalid' : ''"
                    @selected="selectHandler"
                    @item-changed="selectHandler"
                    @input="refine">
                    <template slot-scope="{ suggestion }">
                      <span class="my-suggestion-item">{{
                        suggestion.item.newIid ? suggestion.item.newIid : suggestion.item.iid
                      }}-{{ suggestion.item.bankOrInstitutionName }}-{{
                        suggestion.item.domicileAddress
                      }}-{{ suggestion.item.zipCode }}-{{ suggestion.item.place }}</span>
                    </template>
                  </vue-autosuggest>
                </template>
              </ais-autocomplete>
            </ais-instant-search>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <label for="autosuggest">{{
              $t('message.onBoarding.bankDetails.bankName')
            }}</label>
            {{ bankName }}
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="street"
              :is-read-only="readOnly"
              :disabled="true"
              :name="$t('message.generic.street')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="zip"
              :is-read-only="readOnly"
              :disabled="true"
              :name="$t('message.generic.zip')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="city"
              :is-read-only="readOnly"
              :disabled="true"
              :name="$t('message.generic.city')" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-12 col-md-3">
            <label for="country">{{ $t('message.generic.country') }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="country"
              v-model="country"
              :disabled="true">
              <option value="CH">
                CH
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">
              {{ country }}
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="bankNumber"
              :is-integer="true"
              :disabled="true"
              :is-integer-format="true"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.bankDetails.bankNumber')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="bic"
              :is-read-only="readOnly"
              :disabled="true"
              :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.bic')" />
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="iban"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.bankDetails.iban')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="inTheNameOf"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.bankDetails.inTheNameOf')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="bankDetailsDate.validFrom"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.bankDetails.validFrom') + (readOnly ? '' : '*')"
              :state="$v.validFrom.$dirty && $v.validFrom.$error ? false : null"
              @blur="dateToObject()"
              @keyup="limitToDateLength()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="bankDetailsDate.expiresAt"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.bankDetails.expirationDate')"
              :state="$v.bankDetailsDate.expiresAt.$dirty && $v.bankDetailsDate.expiresAt.$error ? false : null"
              @blur="dateToObject()"
              @keyup="limitToDateLength()" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-12 col-md-6">
            <label>{{ $t('message.onBoarding.bankDetails.remark') }}</label>
            <coozzy-form-textarea
              ref="note"
              v-model="note"
              class="textAreaHeight"
              :is-read-only="readOnly"
              :initial="note"
              :value-text-area="note"
              :rows="1"
              :show-label="false"
              name="note" />
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem mt-2">
          <div class="col-12 col-md-12">
            <add-document-modal
              :suffix="'bankDetails' + index"
              :owner-id="ownerId"
              @document-created="documentCreated" />
          </div>
          <div class="col-md-12 col-12">
            <div class="row">
              <div class="col-md-2 col-12">
                <coozzy-button
                  v-if="!readOnly"
                  design="prop-green"
                  class="w-100"
                  @click="addDocumentClicked('bankDetails' + index)">
                  {{ $t('message.manageDocuments.addDocument') }}
                </coozzy-button>
              </div>
            </div>
          </div>
          <div
            v-if="documents.length > 0"
            class="col-12 mt-2">
            <documents-list
              :documents-list="documents"
              :is-read-only="readOnly"
              @version-added="versionAdded"
              @document-deleted="deleteDocument" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-2">
            <coozzy-button
              v-if="!readOnly"
              size="small"
              design="red"
              class="float-right"
              @click="removeClicked">
              {{ $t('message.onBoarding.bankDetails.deleteBankDetails') }}
            </coozzy-button>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-modal
      :id="'deleteModal'"
      ref="deleteModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodyBankDetails') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('deleteModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric'
import { required } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'
import MediaApi from '@/misc/apis/MediaApi'
import { algolia } from '@/mixins/algolia'
import { VueAutosuggest } from 'vue-autosuggest'
import DocumentsList from '@/properties/components/DocumentsList.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import { user } from '@/mixins/user'
import { routeChecks } from '@/mixins/routeChecks'

function moreThanStarting(value, vm) {
  if (!value) {
    return true
  }
  if (!this.bankDetailsDate.validFrom) {
    return true
  }
  return new Date(value) >= new Date(this.bankDetailsDate.validFrom)
}

export default {
  name: 'PropertyBankDetails',
  components: {
    CoozzyDropdown,
    CoozzyDropdownItem,
    DocumentsList,
    VueAutosuggest,
    CoozzyFormInputNumeric,
    CoozzyFormSelect,
    CoozzyFormInput,
    CoozzyButton,
    AddDocumentModal
    },
  mixins: [validation, algolia, user, routeChecks],
  props: {
    index: {
      type: Number,
      default: 0
    },
    ownerId: {
      type: String,
      required: true
    },
    bankDetails: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    addNewDocument: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bankDetailsDate: {
        validFrom: null,
        expiresAt: null
      },
      bankDetailsDocuments: []
    }
  },
  computed: {
    title() {
      let title = ''

      if (this.bankName) {
        title += ` - ${this.bankName}`
      }

      if (this.interestRate) {
        title += ` - ${this.interestRate}%`
      }

      if (title === '') {
        title += this.$t('message.onBoarding.bankDetails.newBankDetails')
      }

      if (this.bankDetailsDate.validFrom) {
        title += ` - ${this.$options.filters.formatDate(this.bankDetailsDate.validFrom)}`
      } else {
        title += ' - n/a'
      }

      if (this.bankDetailsDate.expiresAt) {
        title += ` - ${this.$options.filters.formatDate(this.bankDetailsDate.expiresAt)}`
      } else {
        title += ' - n/a'
      }

      if (title.startsWith(' - ')) {
        title = title.substr(3)
      }

      return title
    },
    bankName: {
      get() {
        return this.bankDetails.bankName
      },
      set(value) {
        const newBankDetails = this.bankDetails
        newBankDetails.bankName = value
      }
    },
    street: {
      get() {
        return this.bankDetails.address?.street
      },
      set(value) {
        const newBankDetails = this.bankDetails
        if (!newBankDetails.address) {
          newBankDetails.address = {}
        }
        newBankDetails.address.street = value
      }
    },
    zip: {
      get() {
        return this.bankDetails.address?.zip
      },
      set(value) {
        const newBankDetails = this.bankDetails
        if (!newBankDetails.address) {
          newBankDetails.address = {}
        }
        newBankDetails.address.zip = value
      }
    },
    city: {
      get() {
        return this.bankDetails.address?.city
      },
      set(value) {
        const newBankDetails = this.bankDetails
        if (!newBankDetails.address) {
          newBankDetails.address = {}
        }
        newBankDetails.address.city = value
      }
    },
    country: {
      get() {
        return this.bankDetails.address?.country
      },
      set(value) {
        const newBankDetails = this.bankDetails
        if (!newBankDetails.address) {
          newBankDetails.address = {}
        }
        newBankDetails.address.country = value
      }
    },
    bankNumber: {
      get() {
        return this.bankDetails.clearingNumber && this.bankDetails.clearingNumber !== '' ? parseInt(this.bankDetails.clearingNumber) : null
      },
      set(value) {
        const newBankDetails = this.bankDetails
        newBankDetails.clearingNumber = value.toString()
      }
    },
    bic: {
      get() {
        return this.bankDetails.bic
      },
      set(value) {
        const newBankDetails = this.bankDetails
        newBankDetails.bic = value
      }
    },
    validFrom: {
      get() {
        return this.bankDetails.validFrom !== '-1' ? this.bankDetails.validFrom : null
      },
      set(value) {
        const newBankDetails = this.bankDetails
        newBankDetails.validFrom = value
      }
    },
    expiresAt: {
      get() {
        return this.bankDetails.expiresAt !== '-1' ? this.bankDetails.expiresAt : null
      },
      set(value) {
        const newBankDetails = this.bankDetails
        newBankDetails.expiresAt = value
      }
    },
    iban: {
      get() {
        return this.bankDetails.iban
      },
      set(value) {
        const newBankDetails = this.bankDetails
        newBankDetails.iban = value
      }
    },
    inTheNameOf: {
      get() {
        return this.bankDetails.inTheNameOf
      },
      set(value) {
        const newBankDetails = this.bankDetails
        newBankDetails.inTheNameOf = value
      }
    },
    note: {
      get() {
        return this.bankDetails.note
      },
      set(value) {
        const newBankDetails = this.bankDetails
        newBankDetails.note = value
      }
    },
    documents() {
      return this.getFilteredBankDetailsDocuments(this.bankDetails.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
    }
  },
  mounted() {
    this.loadInstantSearch(true)
    this.bankDetailsDate.validFrom = this.validFrom && this.validFrom.year !== -1 && this.validFrom.year > 0 ? this.validFrom.year + '-' + ('0' + this.validFrom.month).slice(-2) + '-' + ('0' + this.validFrom.day).slice(-2) : ''
    this.bankDetailsDate.expiresAt = this.expiresAt && this.expiresAt.year !== -1 && this.expiresAt.year > 0 ? this.expiresAt.year + '-' + ('0' + this.expiresAt.month).slice(-2) + '-' + ('0' + this.expiresAt.day).slice(-2) : ''
    if (this.bankDetails.documentIds.length > 0) {
      MediaApi.listDocuments(this.bankDetails.documentIds)
        .then(response => {
          this.bankDetailsDocuments = response.documents
        })
    }
    this.$nextTick(() => {
      if (!this.readOnly && this.addNewDocument) {
        this.addDocumentClicked('bankDetails' + this.index)
      }
    })
  },
  methods: {
    goToEditMode() {
      localStorage.setItem('propBankIndexNewDocument', this.index)
      this.$router.push({
        name: this.moduleRoutePrefix + 'PropertyEditingView',
        params: { id: this.$route.params.id },
        query: {
          view: 'property'
        }
      })
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    selectHandler(selected) {
      if (selected && selected.item) {
        this.bankNumber = selected.item.newIid ? selected.item.newIid : selected.item.iid
        this.bic = selected.item.bic ? selected.item.bic : ''
        this.bankName = null
        this.$nextTick(function () {
          this.bankName = selected.item.bankOrInstitutionName
        })
        this.zip = selected.item.zipCode
        this.city = selected.item.place
        this.country = selected.item.countryCode
        this.street = selected.item.domicileAddress
      }
    },
    getFilteredBankDetailsDocuments(documentIds) {
      return this.bankDetailsDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    addDocumentClicked(suffix = '') {
      localStorage.removeItem('propBankIndexNewDocument')
      this.$bvModal.show('add-document-modal' + suffix)
    },
    dateToObject() {
      const validFrom = new Date(this.bankDetailsDate.validFrom)
      this.validFrom = validFrom instanceof Date && isFinite(validFrom)
? {
        day: validFrom.getDate(),
        month: validFrom.getMonth() + 1,
        year: validFrom.getFullYear()
      }
: null
      const expiresAt = new Date(this.bankDetailsDate.expiresAt)
      this.expiresAt = expiresAt instanceof Date && isFinite(expiresAt)
? {
        day: expiresAt.getDate(),
        month: expiresAt.getMonth() + 1,
        year: expiresAt.getFullYear()
      }
: null
    },
    documentCreated(document) {
      const bankDetails = this.bankDetails
      bankDetails.documentIds.push(document.id)

      // Add whole new document to vuex store to display it
      this.bankDetailsDocuments.push(document)
      this.$emit('update-bank-details', this.bankDetails.internalId || this.bankDetails.id)
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    removeConfirmed() {
      this.$emit('remove-bank-details', this.bankDetails.internalId || this.bankDetails.id)
    },
    versionAdded(updatedDocument) {
      this.insuranceDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.insuranceDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    deleteDocument(document) {
      this.bankDetails.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.bankDetails.documentIds.splice(index, 1)
          this.$emit('update-bank-details', this.bankDetails.internalId || this.bankDetails.id)
        }
      }, this)
    },
    limitToDateLength() {
      if (this.bankDetailsDate.validFrom) {
        const arrayValidFrom = this.bankDetailsDate.validFrom.split('-')
        this.bankDetailsDate.validFrom = arrayValidFrom[0].substring(0, 4) + '-' + arrayValidFrom[1] + '-' + arrayValidFrom[2]
      }
      if (this.bankDetailsDate.expiresAt) {
        const arrayInactiveFrom = this.bankDetailsDate.expiresAt.split('-')
        this.bankDetailsDate.expiresAt = arrayInactiveFrom[0].substring(0, 4) + '-' + arrayInactiveFrom[1] + '-' + arrayInactiveFrom[2]
      }
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      return this.$v.$invalid
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    }
  },
  validations() {
    const validationBankDetails = {
      bankName: {
        required
      },
      validFrom: {
        required
      },
      bankDetailsDate: {
        expiresAt: {
          moreThanStarting
        }
      }
    }
    return validationBankDetails
  }
}
</script>

<style lang="scss" scoped>

</style>
