<template>
  <font-awesome-icon
    icon="plus-circle"
    class="fal" />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faPlusCircle)

export default {
  name: 'CoozzyPlusCircleIcon'
}
</script>

<style lang="scss" scoped>
  .fal {
    color: $color-grey-dark;
  }
</style>
