<template>
  <svg
    :id="name"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="14px"
    height="14px"
    viewBox="0 0 512 512"
    enable-background="new 0 0 512 512"
    class="nav-icon fa ticket-icon"
    :style="active ? 'filter: invert(30%) sepia(8%) saturate(171%) hue-rotate(161deg) brightness(92%) contrast(89%)' : 'filter: invert(97%) sepia(5%) saturate(507%) hue-rotate(102deg) brightness(117%) contrast(100%);'">
    <path d="m434.929 46.131c-10.38-10.402-24.184-16.131-38.871-16.131h-24.808v-5c0-13.785-11.215-25-25-25h-180c-13.785 0-25 11.215-25 25v5h-24.897c-30.261 0-54.908 24.646-54.942 54.939l-.411 372c-.016 14.702 5.691 28.528 16.07 38.93 10.38 10.402 24.185 16.131 38.872 16.131h279.704c30.262 0 54.909-24.646 54.942-54.939l.412-372c.017-14.703-5.691-28.529-16.071-38.93zm-263.679-16.131h170v30h-170zm249.37 427.027c-.016 13.771-11.219 24.973-24.974 24.973h-279.704c-6.676 0-12.951-2.604-17.669-7.332-4.718-4.729-7.312-11.013-7.305-17.695l.411-372c.015-13.771 11.218-24.973 24.974-24.973h24.897v5c0 13.785 11.215 25 25 25h180c13.785 0 25-11.215 25-25v-5h24.808c6.676 0 12.951 2.604 17.669 7.332s7.313 11.013 7.305 17.695z" />
    <path d="m261.099 200h106.571c8.284 0 15-6.716 15-15s-6.716-15-15-15h-106.571c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
    <path d="m261.099 300h106.571c8.284 0 15-6.716 15-15s-6.716-15-15-15h-106.571c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
    <path d="m368.099 370h-107c-8.284 0-15 6.716-15 15s6.716 15 15 15h107c8.284 0 15-6.716 15-15s-6.715-15-15-15z" />
    <path d="m197.256 144.157-34.592 34.592-8.156-8.157c-5.858-5.858-15.355-5.858-21.213 0-5.858 5.857-5.858 15.355 0 21.213l18.763 18.764c2.813 2.813 6.628 4.394 10.607 4.394 3.978 0 7.793-1.58 10.606-4.394l45.199-45.198c5.858-5.857 5.858-15.355 0-21.213-5.858-5.859-15.355-5.859-21.214-.001z" />
    <path d="m197.256 251.794-34.592 34.592-8.156-8.156c-5.858-5.858-15.355-5.858-21.213 0-5.858 5.857-5.858 15.354 0 21.213l18.763 18.764c2.813 2.813 6.628 4.394 10.607 4.394 3.978 0 7.794-1.58 10.606-4.394l45.199-45.199c5.858-5.857 5.858-15.355 0-21.213s-15.356-5.858-21.214-.001z" />
    <path d="m197.256 351.794-34.592 34.592-8.156-8.156c-5.858-5.858-15.355-5.858-21.213 0-5.858 5.857-5.858 15.354 0 21.213l18.763 18.764c2.813 2.813 6.628 4.394 10.607 4.394 3.978 0 7.794-1.58 10.606-4.394l45.199-45.199c5.858-5.857 5.858-15.355 0-21.213s-15.356-5.858-21.214-.001z" />
  </svg>
</template>

<script>

export default {
  name: 'CoozzyTicketIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
