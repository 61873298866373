<template>
  <b-modal
    id="modal-invite-employee"
    ref="modal-add-employee"
    no-close-on-backdrop
    :title="$t('message.employeesSettings.addEmployee')">
    <div class="row">
      <div class="col">
        <coozzy-form-input
          v-model="email"
          :state="$v.email.$dirty && $v.email.$error ? false : null"
          :name="$t('message.generic.email') + '*'" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <coozzy-form-input
          v-model="firstName"
          :state="$v.firstName.$dirty && $v.firstName.$error ? false : null"
          :name="$t('message.generic.firstName') + '*'" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <coozzy-form-input
          v-model="lastName"
          :state="$v.lastName.$dirty && $v.lastName.$error ? false : null"
          :name="$t('message.generic.lastName') + '*'" />
      </div>
    </div>
    <div class="row">
      <div
        class="col">
        <coozzy-multiselect
          v-model="currentRole"
          :options="availableRoles"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :searchable="false"
          :taggable="false"
          label="label"
          track-by="label">
          {{ $t('message.employeesSettings.administrator') }}
        </coozzy-multiselect>
      </div>
    </div>
    <div
      slot="modal-footer"
      class="w-100 float-right">
      <coozzy-button
        size="normal"
        design="green"
        class="float-right"
        :disabled="loading"
        @click="addEmployee(false)">
        {{ $t('message.generic.add') }}
      </coozzy-button>
      <coozzy-button
        size="normal"
        design="default"
        class="float-right mr-2"
        :disabled="loading"
        @click="addEmployee(true)">
        {{ $t('message.generic.addAnother') }}
      </coozzy-button>
      <coozzy-button
        size="normal"
        class="float-left mt-sm-0 mt-4"
        :disabled="loading"
        @click="hide()">
        {{ $t('message.generic.cancel') }}
      </coozzy-button>
    </div>
  </b-modal>
</template>

<script>
import EmployeeApi from '../../misc/apis/EmployeeApi'
import { email, required } from 'vuelidate/lib/validators'
import { user } from '../../mixins/user'
import Vue from 'vue'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'

export default {
  name: 'InviteEmployeeModal',
  components: { CoozzyMultiselect },
  mixins: [user],
  data() {
    return {
      currentRole: [],
      roles: ['company_admin', 'manage_documents', 'manage_templates', 'manage_mails', 'editor', 'janitor', 'janitor_editor', 'ticket_editor', 'ticket_delete'],
      email: '',
      firstName: '',
      lastName: '',
      loading: false
    }
  },
  computed: {
    availableRoles() {
      const array = []
      this.roles.forEach(role => {
        array.push({
          label: this.$t('message.employeesSettings.roles.' + role),
          value: role
        })
      })
      return array
    }
  },
  mounted() {
    if (this.isPlatformEmployee) {
      this.roles.push('publish_update_message')
      this.roles.push('generate_data_sets')
    }
    if (this.hasAccessMarketBase) {
      this.roles.push('marketing')
    }
    if (this.hasAccessAssetBase) {
      this.roles.push('asset')
    }
    if (this.hasAccessAdminBase) {
      this.roles.push('management')
    }
    if (this.hasAccessAccountingBase) {
      this.roles.push('accounting')
    }
  },
  methods: {
    addEmployee(more) {
      this.trimText()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        EmployeeApi.inviteEmployee(this.accountId, this.email, this.firstName, this.lastName, true, '', this.currentRole.flatMap(x => x.value))
          .then(response => {
            // this.$store.dispatch('employee/addEmployee', response).then().catch(e => console.log(e))
            this.$store.dispatch('employee/loadEmployees', true)
            this.loading = false

            this.email = ''
            this.firstName = ''
            this.lastName = ''
            this.$v.$reset()
            Vue.toasted.show(this.$t('message.successMessages.addEmployee'), { type: 'success' })
            if (!more) {
              this.hide()
            }
            this.$emit('reload-employees')
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            if (e.response.status === 409) {
              Vue.toasted.show(this.$t('message.savingErrors.employeeRegistered'), { type: 'error' })
            } else {
              Vue.toasted.show(this.$t('message.savingErrors.addEmployee'), { type: 'error' })
            }
          })
      }
    },
    show() {
      this.$refs['modal-add-employee'].show()
    },
    hide() {
      this.email = ''
      this.firstName = ''
      this.lastName = ''
      this.$refs['modal-add-employee'].hide()
    },
    trimText() {
      this.firstName = this.firstName.trim()
      this.lastName = this.lastName.trim()
    }
  },
  validations: {
    email: {
      required,
      email
    },
    firstName: {
      required
    },
    lastName: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
