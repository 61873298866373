<template>
  <section>
    <div class="row mb-2">
      <div class="col-12 big-calendar-container">
        <div class="row mb-2">
          <div class="col-md-6">
            <title-marketing-object-view
              v-if="object"
              :item="object"
              :show-marketing-info-box="false" />
            <h1
              v-else-if="contacts.length > 0">
              {{ getNameMainContact() }}
            </h1>
            <coozzy-page-title
              v-else
              :class-title="'mb-0'"
              :title="$t('message.navigation.calendar.title')"
              :count="countEvents"
              :count-text="$t('message.generic.events').toString()" />
          </div>
          <div class="col-md-6 pt-4">
            <coozzy-button
              size="small"
              class="float-right mb-0 ml-4"
              @click="switchView(selectedView, new Date())">
              {{ $t('message.navigation.calendar.today') }}
            </coozzy-button>
            <b-button-group class="float-right shadow">
              <coozzy-button
                size="small"
                class="shadow-none mb-0"
                :design="selectedView === 'list' ? 'primary': 'default'"
                @click="switchView('list')">
                {{ $t('message.navigation.calendar.list') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="shadow-none mb-0"
                :design="selectedView === 'day' ? 'primary': 'default'"
                @click="switchView('day');hideWeekends=false">
                {{ $t('message.navigation.calendar.day') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="shadow-none mb-0"
                :design="selectedView === 'week' && hideWeekends ? 'primary': 'default'"
                @click="hideWeekends=true;switchView('week');">
                {{ $t('message.navigation.calendar.workWeek') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="shadow-none mb-0"
                :design="selectedView === 'week' && !hideWeekends ? 'primary': 'default'"
                @click="hideWeekends=false;switchView('week');">
                {{ $t('message.navigation.calendar.week') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="shadow-none mb-0"
                :design="selectedView === 'month' ? 'primary': 'default'"
                @click="switchView('month');hideWeekends=false">
                {{ $t('message.navigation.calendar.month') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="shadow-none mb-0"
                :design="selectedView === 'year' ? 'primary': 'default'"
                @click="switchView('year');hideWeekends=false">
                {{ $t('message.navigation.calendar.year') }}
              </coozzy-button>
            </b-button-group>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-2 px-0 small-calendar-container">
        <div class="row">
          <div class="col pt-4 mb-3">
            <coozzy-button
              class="w-100"
              design="prop-green"
              @click="createEvent(new Date())">
              {{ $t('message.calendar.createEvent') }}
            </coozzy-button>
          </div>
        </div>
      </div>
      <div
        class="col-12 big-calendar-container"
        @mousemove="onMouseMove($event)">
        <vue-cal
          v-if="selectedView !== 'list'"
          id="globalCal"
          :key="'global-' + selectedView"
          :ref="'globalCal'"
          class="shadow"
          :locale="getCurrentLanguage"
          :on-event-click="onEventClick"
          :events="events"
          :hide-view-selector="true"
          :active-view.sync="selectedView"
          :show-week-numbers="true"
          :events-on-month-view="true"
          :selected-date="dayFocus"
          :events-count-on-year-view="true"
          :deletable="false"
          :resizable="false"
          :dblclick-to-navigate="false"
          :cell-click-hold="false"
          :disable-views="['years']"
          :show-all-day-events="true"
          :time-from="6 * 60"
          :time-to="22 * 60"
          :hide-weekends="hideWeekends"
          :time-cell-height="timeCellHeight"
          @cell-click="clickOnCell"
          @cell-dblclick="createEvent"
          @day-focus="onDayFocus($event)"
          @view-change="onViewChange(selectedView, $event)"
          @ready="onCalReady($event)"
          @event-mouse-leave="mouseLeaveEvents($event)"
          @event-mouse-enter="mouseEnterEvents($event)">
          <template #event="{ event, view }">
            <div
              v-if="view !== 'year' "
              class="container-event-month"
              :class="getEventStatus(event)">
              <div
                class="event-title d-block"
                v-html="event.title" />
              <div
                v-if="view === 'month'"
                class="vuecal__event-time d-block">
                <!-- Using Vue Cal Date prototypes (activated by default) -->
                <span>{{ event.start.formatTime() }}</span> {{ $t('message.generic.until') }} <span>{{ event.end.formatTime() }}</span>
              </div>
            </div>
          </template>
          <template #cell-content="content">
            <span
              v-if="content.view.id === 'month'"
              class="vuecal__cell-date"
              style="position: absolute;
                top: 1px;
                text-align: left;
                left: 5px;">
              {{ content.cell.content }}
            </span>
            <span
              v-if="content.view.id === 'year'"
              class="container_cell_year_info"
              @click="content.goNarrower">
              <span
                class="vuecal__cell-date"
                :class="content.view.id">
                {{ content.cell.content }}
              </span>
              <span
                v-if="countYearEvents(content) > 0"
                class="vuecal__cell-events-count">{{ countYearEvents(content) }}</span>
            </span>
            <span
              v-if="['week', 'day'].includes(content.view.id) && !content.events.length && !isAllDayCell(content.cell)"
              class="vuecal__no-event">
              {{ $t('message.calendar.noEvent') }}
            </span>
          </template>
        </vue-cal>
        <div v-else>
          <h4
            v-if="eventByDate.length === 0"
            class="col-12 mt-5 pl-0 text-center text-lg">
            {{ $t('message.calendar.noFutureEvent') }}
          </h4>
          <template v-else>
            <div
              v-for="(group, key) in eventByDate"
              :key="key">
              <coozzy-card
                :title="getDayNameWithDate(group[1][0].start)"
                class="cursor-pointer">
                <b-table
                  hover
                  responsive="true"
                  bordered
                  class="mb-0 mt-2"
                  :fields="fields"
                  :items="sortedGroup(group[1])"
                  @row-clicked="onEventAgendaClick">
                  <template
                    #head(title)>
                    <span>{{ $t('message.calendar.event.title') }}</span>
                  </template>
                  <template
                    #head(time)>
                    <span>{{ $t('message.calendar.event.time') }}</span>
                  </template>
                  <template
                    #head(location)>
                    <span>{{ $t('message.calendar.event.location') }}</span>
                  </template>
                  <template
                    #head(description)>
                    <span>{{ $t('message.calendar.event.description') }}</span>
                  </template>
                  <template
                    #head(attendees)>
                    <span>{{ $t('message.calendar.event.participants') }}</span>
                  </template>
                  <template
                    #head(from)>
                    <span>{{ $t('message.calendar.event.from') }}</span>
                  </template>
                  <template
                    #cell(title)="data">
                    {{ data.item.originalTitle }}
                  </template>
                  <template
                    #cell(time)="data">
                    <template v-if="data.item.allDay">
                      {{ $t('message.calendar.event.allDay') }}
                    </template>
                    <template v-else>
                      {{ data.item.start.substr(11,5) }} - {{ data.item.end.substr(11,5) }}
                    </template>
                  </template>
                  <template
                    #cell(description)="data">
                    <p class="m-0">
                      {{ convertToPlainWithSpaces(data.item.contentFull) }}
                    </p>
                  </template>
                  <template
                    #cell(attendees)="data">
                    <div
                      v-for="(contact, index) in data.item.attendees"
                      :key="index">
                      <span v-if="contact.displayName !== ''">{{ contact.displayName }}</span>
                      <span v-else>{{ contact.email }}</span>
                      <span v-if="index !== data.item.attendees.length - 1">, </span>
                    </div>
                  </template>
                  <template
                    #cell(from)="data">
                    <div
                      class="color-box mx-2 agenda"
                      :style="{ background: getColor(data.item.color) }" />
                    {{ data.item.organizer }}
                  </template>
                </b-table>
              </coozzy-card>
            </div>
          </template>
        </div>
      </div>
      <div class="col-12 col-md-2 px-0 small-calendar-container">
        <vue-cal
          :key="'small-' + viewCalendar"
          class="shadow"
          :locale="getCurrentLanguage"
          :on-event-click="onEventClick"
          :events="events"
          :selected-date="dayFocus"
          hide-view-selector
          :active-view="'month'"
          :disable-views="['years', 'year', 'week', 'day']"
          xsmall
          @cell-click="selectedDate = $event"
          @cell-focus="onDayFocus($event)"
          @view-change="onViewChange('month', $event)"
          @ready="onCalReady($event)" />
        <div class="employees-calendar shadow p-2 mt-3">
          <div
            class="row">
            <div class="col-9">
              <h6 class="mb-3">
                {{ $t('message.navigation.calendar.title') }} <coozzy-sync-alt-icon
                  v-b-tooltip.hover.html="$t('message.calendar.calDAV')"
                  v-b-modal="'calDAV-setting-modal'" />
              </h6>
            </div>
            <div
              class="col-3">
              <coozzy-form-checkbox
                ref="AllCheckbox"
                @change="(value) => { selectAll(value) }" />
            </div>
          </div>
          <template v-if="queryParamsProcessed">
            <div
              v-for="employee in sortedEmployeeList"
              :key="employee.id">
              <div
                class="row">
                <div class="contact-content col-9">
                  <div
                    class="color-box mx-2 mt-0"
                    :style="{ background: getColor(employee.profile.color) }" />
                  <label>
                    {{ employee.profile.lastName }} {{ employee.profile.firstName }}
                  </label>
                </div>
                <div
                  class="col-3"
                  style="margin-top: -2px;">
                  <coozzy-form-checkbox
                    ref="checkbox"
                    :initial="selectedCalendars.includes(employee.email.toLowerCase())"
                    @change="employeeSelected(employee)" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <event-creation-modal
      v-if="selectedEvent !== null || showCreationModal"
      ref="modal-creation"
      :key="keyId + (selectedEvent !== null ? 'event-modal-creation-'+ selectedEvent?.url : 'event-modal-creation' + defaultDate)"
      :event-detail="selectedEvent"
      :is-all-day="isAllDay"
      :default-date="defaultDate"
      :source="fromPage"
      :reference-id="$store.getters['user/getAccountId'].toString()"
      :available-employees="availableEmployees"
      :contacts-list="contacts"
      :additional-references="referenceIds"
      :object-reference="object"
      :user-contact="userContact"
      :start-date-occurrences-response="computedStartDateOccurrencesResponse"
      :end-date-occurrences-response="computedEndDateOccurrencesResponse"
      @post-creation="postCreation"
      @remove-event="removeEvent"
      @hide-modal="closeDetailEvent"
      @attendees-changed="afterEdit" />
    <b-modal
      :id="'calDAV-setting-modal'"
      :ref="'calDAV-setting-modal'"
      hide-footer
      :title="$t('message.calendar.calDAV')">
      <div class="col-12 col-sm-12 mb-2">
        <h4>
          {{ $t('message.calendar.export.general') }}
        </h4>
      </div>
      <div class="col-12 col-sm-12 mb-2">
        <coozzy-form-copy-input
          :value="credentials.username"
          :name="$t('message.marketingSettings.export.username')"
          type="text"
          @click="copyExportClicked(credentials.username)" />
      </div>
      <div class="col-12 col-sm-12 mb-2">
        <coozzy-form-copy-input
          :value="credentials.password"
          :name="$t('message.marketingSettings.export.password')"
          type="password"
          @click="copyExportClicked(credentials.password)" />
      </div>
      <div class="col-12 col-sm-12 mt-3 mb-2">
        <h4>
          {{ $t('message.calendar.export.apple') }}
        </h4>
      </div>
      <div class="col-12 col-sm-12 mb-2">
        <coozzy-alert variant="blue">
          {{ $t('message.calendar.export.appleDescription') }}
        </coozzy-alert>
      </div>
      <div class="col-12 col-sm-12 mb-2">
        <coozzy-form-copy-input
          type="text"
          :name="$t('message.calendar.export.appleUrl')"
          :value="credentials.appleUrl"
          @click="copyExportClicked(credentials.appleUrl)" />
      </div>
      <div class="col-12 col-sm-12 mb-2">
        <coozzy-form-copy-input
          type="text"
          :name="$t('message.calendar.export.applePath')"
          :value="credentials.applePath"
          @click="copyExportClicked(credentials.applePath)" />
      </div>
      <div class="col-12 col-sm-12 mb-2">
        <coozzy-form-copy-input
          type="number"
          :name="$t('message.calendar.export.port')"
          :value="443"
          @click="copyExportClicked(443)" />
      </div>
      <div class="col-12 col-sm-12 mt-3 mb-2">
        <h4>
          {{ $t('message.calendar.export.other') }}
        </h4>
      </div>
      <div class="col-12 col-sm-12 mb-2">
        <coozzy-form-copy-input
          type="text"
          :name="$t('message.calendar.export.url')"
          :value="credentials.url"
          @click="copyExportClicked(credentials.url)" />
      </div>
    </b-modal>
    <div
      v-if="showPopover"
      id="eventPopover"
      class="eventPopover"
      :style="{ left: page.left + 'px', top: page.top + 'px'}">
      <div
        v-if="selectedPopOverEvent"
        class="row">
        <div class="content shadow p-3 mt-3">
          <div
            class="row mb-2">
            <div class="col-12 m-negative-5">
              <span class="font-weight-bold">
                {{ selectedPopOverEvent.start.toLocaleDateString('de-DE', { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' }) }}<template v-if="!selectedPopOverEvent.allDay">,</template>
                <template v-if="!selectedPopOverEvent.allDay">
                  {{ selectedPopOverEvent.start.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }) }}
                  -
                  {{ selectedPopOverEvent.end.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }) }}
                </template>
              </span>
            </div>
            <div class="col-12 m-negative-5">
              <span class="font-weight-bold">
                {{ selectedPopOverEvent.originalTitle }}
              </span>
            </div>
          </div>
          <div
            v-if="(!selectedPopOverEvent.private || selectedPopOverEvent.userId === currentUserId) && selectedPopOverEvent.contentFull !== '-'"
            class="row mb-2">
            <div class="col-12 text-85 m-negative-5">
              {{ $t('message.calendar.event.description') }}:
            </div>
            <div class="col-12 text-85 m-negative-5 description-text">
              {{ getNWordsFromString(selectedPopOverEvent.contentFull, 30, true) }}
            </div>
          </div>
          <div
            v-if="(!selectedPopOverEvent.private || selectedPopOverEvent.userId === currentUserId) && selectedPopOverEvent.attendees.length > 0"
            class="row mb-2">
            <div class="col-12 text-85 m-negative-5">
              <p class="mt-2 mb-0">
                {{ $t('message.calendar.event.participants') }}:
              </p>
            </div>
            <div
              v-for="attendee in selectedPopOverEvent.attendees"
              :key="attendee.email"
              class="col-12 text-85 m-negative-5">
              <img
                v-if="attendee.status === 'ATTENDEE_STATUS_NEEDS_ACTION'"
                width="10px"
                height="10px"
                src="@/assets/icon/hourglass.svg"
                :alt="'hourglass'">
              <coozzy-success-icon v-if="attendee.status === 'ATTENDEE_STATUS_ACCEPTED'" />
              <coozzy-fail-icon v-if="attendee.status === 'ATTENDEE_STATUS_DECLINED'" />
              <span>
                {{ attendee.displayName !== '' ? attendee.displayName : attendee.email }}
              </span>
            </div>
          </div>
          <div
            v-if="(!selectedPopOverEvent.private || selectedPopOverEvent.userId === currentUserId) && selectedPopOverEvent.location !== '-'"
            class="row">
            <div class="col-12 text-85 m-negative-5">
              {{ $t('message.calendar.event.location') }}: {{ selectedPopOverEvent.location }}
            </div>
          </div>
          <div
            v-if="!selectedPopOverEvent.allDay"
            class="row">
            <div class="col-12 text-85 m-negative-5">
              {{ $t('message.tableColumnHeadings.duration') }}: {{ calculateDuration(selectedPopOverEvent) }}h
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 text-85 m-negative-5">
              {{ $t('message.navigation.calendar.title') }}:
              <div
                class="color-box text d-inline-block ml-1"
                :style="{
                  background: getColor(selectedPopOverEvent.color),
                  color: pickTextColorBasedOnBgColorSimple(getColor(selectedPopOverEvent.color))
                }">
                {{ selectedPopOverEventOrganizer?.lastName?.substring(0, 1) }}{{ selectedPopOverEventOrganizer?.firstName?.substring(0, 1) }}
              </div>
              {{ selectedPopOverEventOrganizer?.lastName }}
              {{ selectedPopOverEventOrganizer?.firstName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import 'vue-cal/dist/i18n/de.es.js'
import 'vue-cal/dist/i18n/it.es.js'
import 'vue-cal/dist/i18n/fr.es.js'
import CalendarApi from '@/misc/apis/CalendarApi'
import EventCreationModal from './EventCreationModal'
import CoozzySyncAltIcon from '@/framework/components/icons/CoozzySyncAltIcon'
import Vue from 'vue'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyFormCheckbox from '../../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyPageTitle from '../../framework/layout/CoozzyPageTitle'
import ObjectApi from '../../misc/apis/ObjectApi'
import ContactApi from '../../misc/apis/ContactApi'
import { routeChecks } from '@/mixins/routeChecks'
import TitleMarketingObjectView from '@/marketing/TitleMarketingObjectView'
import RequestApi from '@/marketingBasic/api/RequestApi'
import { user } from '@/mixins/user'
import { mapActions, mapGetters } from 'vuex'
import CoozzyFormCopyInput from '@/framework/components/form/input/CoozzyFormCopyInput'
import CoozzyAlert from '@/framework/components/alert/CoozzyAlert'
import { calendar } from '@/mixins/calendar'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon'
import { responsivity } from '@/mixins/responsivity'
import { dateUtils } from '@/mixins/dateUtils'
import { language } from '@/mixins/language'
import { eventBus } from '@/mixins/mail'

export default {
  name: 'CalendarOverview',
  components: { CoozzyAlert, CoozzyFormCopyInput, CoozzyPageTitle, TitleMarketingObjectView, CoozzyFormCheckbox, CoozzySyncAltIcon, CoozzyCard, CoozzyButton, VueCal, EventCreationModal, CoozzySuccessIcon, CoozzyFailIcon },
  mixins: [user, routeChecks, calendar, responsivity, dateUtils, language],
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      if (to.query.elementSelected && to.query.elementSelected !== '') {
        this.changeStatingEndingDate(this.startDate ? new Date(this.startDate) : new Date(to.query.startDate), this.selectedView, true)
          this.loadEvents(true, to.query.elementSelected, to.query.startDate)
          return
      }
    }
    next()
  },
  props: {
    dayToFocus: {
      type: Date,
      default: function () { return new Date() }
    },
    viewCalendar: {
      type: String,
      default: 'week'
    },
    calendars: {
      type: String,
      default: ''
    },
    referenceIds: {
      type: String,
      default: ''
    },
    objectId: {
      type: String,
      default: ''
    },
    elementSelected: {
      type: String,
      default: ''
    },
    fromPage: {
      type: String,
      default: ''
    },
    startDate: {
      type: String,
      default: ''
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.calendar.title')
    }
  },
  data() {
    return {
      loadExtremeEvent: false,
      startDateFilter: null,
      endDateFilter: null,
      firstLoad: false,
      countEvents: 0,
      showPopover: true,
      hideWeekends: localStorage.getItem('hideWeekends') ? localStorage.getItem('hideWeekends') === 'true' : true,
      keyId: '',
      page: {
        left: 0,
        top: 0
      },
      credentials: {
        url: null,
        username: null,
        password: null
      },
      object: null,
      contacts: [],
      dayFocus: this.dayToFocus,
      events: [],
      dvEvents: [],
      selectedEvent: null,
      selectedPopOverEvent: null,
      showDialog: false,
      defaultDate: null,
      fetchedMonth: [],
      queryParamsProcessed: false,
      selectedCalendars: [],
      selectedView: 'week',
      fields: [
        {
          key: 'time',
          label: 'time',
          thClass: 'table-header',
          tdClass: 'align-middle time-cell',
          thStyle: { width: '10%' }
        },
        {
          key: 'title',
          label: 'title',
          thClass: 'table-header',
          tdClass: 'align-middle title-cell',
          thStyle: { width: '15%' }
        },
        {
          key: 'description',
          label: 'description',
          thClass: 'table-header',
          tdClass: 'align-middle description-cell',
          thStyle: { width: '25%' }
        },
        {
          key: 'location',
          label: 'location',
          thClass: 'table-header',
          tdClass: 'align-middle location-cell',
          thStyle: { width: '15%' }
        },
        {
          key: 'attendees',
          label: 'attendees',
          thClass: 'table-header',
          tdClass: 'align-middle attendees-cell',
          thStyle: { width: '20%' }
        },
        {
          key: 'from',
          label: 'from',
          thClass: 'table-header',
          tdClass: 'align-middle from-cell',
          thStyle: { width: '15%' }
        }
      ],
      isAllDay: false,
      rangeStart: null,
      rangeEnd: null,
      userContact: null,
      windowHeight: window.innerHeight,
      headerHeight: 0,
      calendarHeaderHeight: 0,
      showCreationModal: false
    }
  },
  computed: {
    timeCellHeight() {
      // Subtract the heights of the headers from windowHeight before dividing by 17
      const value = (this.windowHeight - this.headerHeight - this.calendarHeaderHeight) / 17
      return value > 40 ? value : 40
    },
    computedStartDateOccurrencesResponse() {
      return this.startDateFilter
    },
    computedEndDateOccurrencesResponse() {
      return this.endDateFilter
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees().filter(e => !e.deleted && !e.blocked)
      }
      return []
    },
    daysOftheWeek() {
      return [this.$t('message.generic.daysComplete.sunday'),
        this.$t('message.generic.daysComplete.monday'),
        this.$t('message.generic.daysComplete.tuesday'),
        this.$t('message.generic.daysComplete.wednesday'),
        this.$t('message.generic.daysComplete.thursday'),
        this.$t('message.generic.daysComplete.friday'),
        this.$t('message.generic.daysComplete.saturday')]
    },
    sortedEmployeeList() {
      if (this.employeeList.length === 0) {
        return []
      }
      const currentUser = (this.employeeList.filter(employee =>
        employee.id === this.userId
      ))
      let sortedEmployees = this.employeeList.filter(employee =>
        employee.id !== this.userId
      )
      sortedEmployees = sortedEmployees.sort(function (a, b) {
        return (a.profile.lastName.localeCompare(b.profile.lastName))
      })
      return currentUser.concat(sortedEmployees)
    },
    currentUserId() {
      return this.$store.getters['user/getUserId']
    },
    eventByDate() {
      let groups = {}
      let newArrayEvent = []
      const current = this
      this.events.forEach(function (event) {
        const start = new Date(event.start.substr(0, 10))
        const end = new Date(event.end.substr(0, 10))
        const differenceInDays = current.getDifferenceInDays(start, end)
        if (differenceInDays > 1 && event.allDay) {
          for (let index = 0; index < differenceInDays; index++) {
            const elm = { ...event }
            const startDate = new Date(elm.start.substr(0, 10))
            startDate.setDate(startDate.getDate() + index)
            elm.startDate = startDate instanceof Date && isFinite(startDate)
? {
              day: startDate.getDate(),
              month: startDate.getMonth() + 1,
              year: startDate.getFullYear()
            }
: null

            const d = new Date()
            d.setHours(0, 0, 0, 0)
            const newStartDate = new Date(current.formatDateForCalendar(elm.startDate, elm.startTime))
            if (newStartDate >= d) {
              newArrayEvent.push(elm)
            }
          }
        } else {
          newArrayEvent.push(event)
        }
      })
      newArrayEvent = newArrayEvent.filter((event, index, self) =>
        index === self.findIndex((e) =>
          e.id === event.id &&
          e.start.substr(0, 10) === event.start.substr(0, 10) &&
          e.end.substr(0, 10) === event.end.substr(0, 10)
        )
      )
      newArrayEvent.forEach(function (event) {
        let date = event.start.substr(0, 10)
        if (date in groups) {
          groups[date].push(event)
        } else {
          groups[date] = new Array(event)
        }
      })
      let groupArray = []
      for (let item in groups) {
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        const newDate = new Date(item.replace('\\-', ' '))
        if (newDate.getTime() >= today.getTime()) {
          groupArray.push([item, groups[item]])
        }
      }
      groupArray.sort(function (a, b) {
        return new Date(a[0].replace('\\-', ' ')) - new Date(b[0].replace('\\-', ' '))
      })
      return groupArray
    },
    availableEmployees() {
      const array = []
      if (this.employeeList.length !== 0) {
        const empList = this.employeeList.filter(x => (x.blocked === false && x.deleted === false))
        empList.forEach(element => {
          const cont = {
            firstName: element.profile.firstName,
            lastName: element.profile.lastName,
            email: element.email,
            id: element.id
          }
          array.push(cont)
        })
      }
      return array
    },
    getCurrentLanguage() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    },
    selectedPopOverEventOrganizer() {
      if (this.selectedPopOverEvent) {
        return this.availableEmployees.find(employee => employee.id === this.selectedPopOverEvent.userId)
      }
      return null
    }

  },
  watch: {
    employeeList: {
      immediate: true,
      handler: function(newValue, oldValue) {
        if (this.employeeList.length > 0 && (this.firstLoad || (oldValue?.length === 0 && newValue?.length > 0))) {
          this.changeStatingEndingDate(null, this.selectedView)
          this.$nextTick(function () {
            this.loadEvents()
          })
        }
      }
    },
    selectedCalendars: function (newVal, oldVal) {
      this.updateURLCalendarView(newVal)
      if (!this.startDateFilter && !this.endDateFilter) {
        this.changeStatingEndingDate(null, this.selectedView)
      }
      this.$nextTick(function () {
        if (this.employeeList.length > 0) {
          this.loadEvents(true)
        }
      })
    },
    selectedView: function (newValue) {
      this.updateURLCalendarView(this.selectedCalendars)
      if (this.firstLoad) {
        eventBus.$emit('lastUsedCalendarView', newValue)
        localStorage.setItem('lastUsedCalendarView', newValue)
        this.$nextTick(() => {
          this.updateEventCounts()
        })
      }
    },
    hideWeekends: function (newValue) {
      localStorage.setItem('hideWeekends', newValue.toString())
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    eventBus.$on('updateEventsList', () => {
      this.loadEvents(true)
    })
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'modal-add-event') {
        if (this.$route.query && this.$route.query.elementSelected) {
          const query = Object.assign({}, this.$route.query)
          delete query.elementSelected
          delete query.startDate
          this.$router.replace({ query })
        }
      }
    })
    // Load data and map query params to internal variables
    this.selectedView = this.viewCalendar
    if (this.calendars) {
      this.selectedCalendars = [...new Set((this.calendars || '').split(',').filter(e => e).map(e => e.toLowerCase()))]
    } else {
      this.selectedCalendars = [this.userEmail.toLowerCase()]
    }
    this.queryParamsProcessed = true
    this.loadEmployees()
    this.loadCalDavCredentials()

    if (this.objectId !== '') {
      ObjectApi.getObject(this.objectId)
        .then(response => {
          this.object = response.object
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    }
    if (this.referenceIds !== '') {
      const allContacts = this.referenceIds.split(',').filter(x => x.startsWith('contact'))
      ContactApi.contactResolve(allContacts)
        .then(response => {
          this.contacts = (response.persons.concat(response.companies)).filter(x => allContacts.includes(x.id))
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    }
    this.loadUserContact()
    this.handleResize()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    countYearEvents(content) {
      // we use this method to get the event count in year view
      // because the all-day events are not supported in year view in vue-cal
      // source info: copilot
      let count = 0
      if (content) {
        const startDate = content.cell?.startDate ? new Date(content.cell.startDate).getTime() : null
        const endDate = content.cell?.endDate ? new Date(content.cell.endDate).getTime() : null
        if (content.view?.events?.length > 0 && startDate && endDate) {
          content.view.events.forEach(event => {
            const eventStart = new Date(event.start).getTime()
            const eventEnd = new Date(event.end).getTime()
            if (eventStart >= startDate && eventEnd <= endDate) {
              count++
            }
          })
        }
      }
      return count
    },
    handleResize() {
      this.windowHeight = window.innerHeight || 0
      // Get the heights of the headers
      const navBar = document.getElementsByClassName('app-header navbar')
      const bigCalendarItems = document.getElementsByClassName('big-calendar-container')
      let pageTitle = 0
      let calendarHeader = 0
      // calculate header (navbar) height
      if (navBar?.length > 0) {
        this.headerHeight = navBar[0].offsetHeight
      }
      // get the height of the page title
      if (bigCalendarItems?.length > 0) {
        pageTitle = bigCalendarItems[0].offsetHeight
      }
      // get the height of the calendar header
      if (bigCalendarItems?.length > 1) {
        const vueCalHeader = bigCalendarItems[1].getElementsByClassName('vuecal__header')
        if (vueCalHeader.length > 0) {
          calendarHeader = vueCalHeader[0].offsetHeight
        }
      }
      // the 30px added is for the margin top of the page container
      this.calendarHeaderHeight = pageTitle + calendarHeader + 30
      this.$nextTick(() => {
        const vuecalBg = document.querySelector('.vuecal__bg')
        const vuecalAllDay = document.querySelector('.vuecal__all-day')

        if (vuecalBg && vuecalAllDay) {
          const overflowY = window.getComputedStyle(vuecalBg).getPropertyValue('overflow-y')
          if ((overflowY === 'scroll' || overflowY === 'auto') && vuecalBg.scrollHeight > vuecalBg.clientHeight) {
            vuecalAllDay.style.overflowY = 'scroll'
          } else {
            vuecalAllDay.style.overflowY = 'hidden'
          }
        }
      })
    },
    updateURLCalendarView(calendars) {
      let checkRoute = ''
      if (this.isOwnerModule) {
        checkRoute = 'OwnerCalendarOverview'
      } else if (this.isMarketingModule) {
        checkRoute = 'MarketingCalendarOverview'
      } else if (this.isAdminModule) {
        checkRoute = 'AdminCalendarOverview'
      } else if (this.isAccountingModule) {
        checkRoute = 'AccountingCalendarOverview'
      } else if (this.isAssetModule) {
        checkRoute = 'AssetCalendarOverview'
      }

      const joinedCalendars = calendars.join(',')

      if (checkRoute !== '') {
        const query = { calendars: joinedCalendars }
        const params = this.$route.params
        if (this.objectId !== '' || this.$route.query.objectId !== '') {
          query.objectId = this.objectId !== '' ? this.objectId : this.$route.query.objectId
        }
        if (this.referenceIds !== '' || this.$route.query.referenceIds !== '') {
          query.referenceIds = this.referenceIds ? this.referenceIds : this.$route.query.referenceIds
        }
        if (this.elementSelected !== '' || this.$route.query.elementSelected !== '') {
          query.elementSelected = this.elementSelected ? this.elementSelected : this.$route.query.elementSelected
        }
        if (this.fromPage !== '' || this.$route.query.fromPage !== '') {
          query.fromPage = this.fromPage ? this.fromPage : this.$route.query.fromPage
        }
        if (this.selectedView !== '' || this.$route.query.viewCalendar !== '') {
          params.viewCalendar = this.selectedView ? this.selectedView : this.$route.query.viewCalendar
        }
        // do not change the link if there is no change in the URL
        const oldURL = this.$route.fullPath
        const newURL = this.$router.resolve({
          name: checkRoute,
          params: params,
          query: query
        }).href

        if (oldURL !== newURL) {
          this.$router.replace({
            name: checkRoute,
            params: params,
            query: query
          })
        }
      }
    },
    loadUserContact() {
      ContactApi.resolveByMail(this.accountId, [this.userEmail])
        .then(response => {
          if (response.persons.length > 0) {
            this.userContact = response.persons[0]
          } else if (response.companies.length > 0) {
            this.userContact = response.companies[0]
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    getEventStatus(event) {
      if (event.attendees.length === 0) {
        return 'accepted'
      } else {
        const attendee = event.attendees.find(x => x.email === this.userEmail)
        if (attendee) {
          if (attendee.status === 'ATTENDEE_STATUS_ACCEPTED') {
            return 'accepted'
          }
          if (attendee.status === 'ATTENDEE_STATUS_DECLINED') {
            return 'declined'
          } else {
            return ''
          }
        } else {
          return 'accepted'
        }
      }
    },
    updateEventCounts() {
      if (this.selectedView !== 'list') {
        const view = this.$refs.globalCal?.view
        if (view) {
          const start = view.startDate
          let end = view.endDate

          // Filter events based on the current view
          const filteredEvents = this.events.filter((event) => {
            const eventStart = new Date(event.start).getTime()
            return eventStart >= start.getTime() && eventStart <= end.getTime()
          })

          // Update count
          this.countEvents = filteredEvents.length
        } else {
          this.countEvents = 0
        }
      } else if (this.selectedView === 'list') {
        let sum = 0

        this.eventByDate.forEach(group => {
          const listEvents = group[1]
          sum += listEvents.length
        })

        this.countEvents = sum
      }
    },
    mouseLeaveEvents() {
      this.selectedPopOverEvent = null
      this.showPopover = true
    },
    mouseEnterEvents(event) {
      this.selectedPopOverEvent = event
      this.showPopover = true
    },
    onMouseMove(e) {
      if (document.getElementById('eventPopover')) {
        const tooltipWidth = document.getElementById('eventPopover').clientWidth
        const tooltipHeight = document.getElementById('eventPopover').clientHeight
        const windowWidth = window.innerWidth
        const windowHeight = document.body.clientHeight
        if ((windowWidth - 100) >= (tooltipWidth + e.pageX)) {
          this.page.left = e.pageX
        } else {
          this.page.left = e.pageX - tooltipWidth
        }
        if ((windowHeight - 100) >= (tooltipHeight + e.pageY)) {
          this.page.top = e.pageY
        } else {
          this.page.top = e.pageY - tooltipHeight
        }
      }
    },
    calculateDuration(event) {
      return Math.round(((event.end.getTime() - event.start.getTime()) / 1000 / 3600) * 100) / 100
    },
    loadCalDavCredentials() {
      CalendarApi.getCalDavCredentials(this.userId)
        .then(response => {
          this.credentials = response
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.calendarSyncToken'), { type: 'error' })
        })
    },
    updateColors() {
      this.$nextTick(function () {
        const eventDomList = document.getElementsByClassName('colorful-event')
        for (let index = 0; index < eventDomList.length; index++) {
          const element = eventDomList[index]
          let classList = element.className.split(' ')
          classList = classList.filter(c => {
            return c.includes('color-')
          })
          const color = classList[0].replace('color-', '')
          let childAccepted = element.querySelector('.container-event-month.accepted')
          if (childAccepted) {
            childAccepted.style = childAccepted.style.cssText + 'color: ' + this.pickTextColorBasedOnBgColorSimple(color) + ' !important;'
            childAccepted.style = childAccepted.style.cssText + 'background-color: ' + color + ' !important;'
            childAccepted.style = childAccepted.style.cssText + 'border-color: ' + color + ' !important;'
          } else {
            let child = element.querySelector('.container-event-month')
            child.style = child.style.cssText + 'color: ' + color + ' !important;'
            child.style = child.style.cssText + 'background-color: white !important;'
            child.style = child.style.cssText + 'border-color: ' + color + ' !important;'
          }
        }
      })
    },
    pickTextColorBasedOnBgColorSimple(bgColor) {
      const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1) : bgColor
      // If we have alpha value then make special check
      if (color.length > 6) {
        const a = parseInt(color.substring(6, 8), 16) // hexToB
        if (a < 125) {
          return '#000000'
        }
      }
      const r = parseInt(color.substring(0, 2), 16) // hexToR
      const g = parseInt(color.substring(2, 4), 16) // hexToG
      const b = parseInt(color.substring(4, 6), 16) // hexToB
      return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? '#000000' : '#ffffff'
    },
    isAllDayCell(cell) {
      const eventAll = this.events.filter(obj => obj.allDay && (obj.startDate.year + '-' + ('0' + obj.startDate.month).slice(-2) + '-' + ('0' + obj.startDate.day).slice(-2)) === cell.formattedDate)
      return eventAll.length > 0
    },
    clickOnCell($event) {
      this.dayFocus = this.formatDateForCalendar(this.toObject(($event)))
    },
    changeStatingEndingDate(date, view, forceLoad) {
      let currentDate = null
      if (view === 'day') {
        if (date && !forceLoad) {
          currentDate = date
        } else {
          currentDate = new Date()
        }
        this.startDateFilter = this.toObject(currentDate.addDays(-1))
        this.endDateFilter = this.toObject(currentDate.addDays(+1))
      }
      if (view === 'week') {
        if (date && !forceLoad) {
          currentDate = date
        } else {
          const now = new Date()
          const mondayTimeStamp = now.setDate(now.getDate() - now.getDay() + 1)
          currentDate = new Date(mondayTimeStamp)
        }
        this.startDateFilter = this.toObject(currentDate.addDays(-7))
        this.endDateFilter = this.toObject(currentDate.addDays(+7))
      }
      if (view === 'month') {
        if (date && (!forceLoad || new Date(date) > new Date())) {
          currentDate = date
        } else {
          const now = new Date()
          currentDate = new Date(now.getFullYear(), now.getMonth(), 1)
        }
        const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
        const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0)
        this.startDateFilter = this.toObject(previousMonth)
        this.endDateFilter = this.toObject(nextMonth)
      }
      if (view === 'year') {
        if (date && !forceLoad) {
          currentDate = date
        } else {
          const now = new Date()
          currentDate = new Date(now.getFullYear(), 0, 1)
        }
        const previousYear = new Date(currentDate.getFullYear() - 1, 0, 1)
        this.startDateFilter = this.toObject(previousYear)
        const nextYear = new Date(currentDate.getFullYear() + 1, 0, 1)
        this.endDateFilter = this.toObject(nextYear)
      }
      if (view === 'list') {
        currentDate = new Date()
        const previousMonth = currentDate
        const nextMonth = (new Date()).setDate(currentDate.getDate() + 90)
        this.startDateFilter = this.toObject(previousMonth)
        this.endDateFilter = this.toObject(nextMonth)
        this.rangeStart = null
      }
      if (forceLoad) {
        this.loadEvents(true)
      }
    },
    loadEvents(forceRefresh = false, eventId = '', startDate = '') {
      if (this.selectedCalendars.length === 0) {
        return
      }
      const id = this.elementSelected !== '' ? this.elementSelected : eventId !== '' ? eventId : ''
      const current = this
      if ((startDate || current.startDate) && !this.firstLoad && id !== '' && !this.loadExtremeEvent) {
        this.loadExtremeEvent = true
        this.changeStatingEndingDate(startDate !== '' ? new Date(startDate) : new Date(this.startDate), this.selectedView)
      }
        if (!forceRefresh && this.employeeList.length === 0) {
        return
      }

      const userArray = this.employeeList.filter(employee => this.selectedCalendars.includes(employee.email.toLowerCase()))
      const userIds = userArray.map(employee => employee.id)
      const oldRangeStart = JSON.parse(JSON.stringify(this.rangeStart))
      const oldRangeEnd = JSON.parse(JSON.stringify(this.rangeEnd))
      if (forceRefresh || (!oldRangeStart || (oldRangeStart && this.dateObjectToTimeStamp(oldRangeStart) > this.dateObjectToTimeStamp(this.startDateFilter))) || (!oldRangeEnd || (oldRangeEnd && this.dateObjectToTimeStamp(oldRangeEnd) < this.dateObjectToTimeStamp(this.endDateFilter)))) {
        let startDateLocale = this.startDateFilter
        let endDateLocale = this.endDateFilter
        if (forceRefresh) {
          startDateLocale = this.startDateFilter || oldRangeStart
          endDateLocale = this.endDateFilter || oldRangeEnd
        } else {
          this.rangeStart = this.startDateFilter
          this.rangeEnd = this.endDateFilter
        }
        if (startDateLocale && endDateLocale) {
          CalendarApi.getEvents(this.accountId, [], userIds, startDateLocale, endDateLocale)
            .then(response => {
              response.events.forEach(item => {
                // temporary solution because outlook create all day events without endTime
                if (!item.startTime) {
                  item.startTime = { hours: 0, minutes: 0, seconds: 0, nanos: 0 }
                }
                if (!item.endTime) {
                  item.endTime = { hours: 0, minutes: 0, seconds: 0, nanos: 0 }
                }
                if (this.dvEvents.find(x => this.getUniqueId(x) === this.getUniqueId(item) && item.userId === x.userId)) {
                  this.dvEvents = this.dvEvents.filter(x => !(this.getUniqueId(x) === this.getUniqueId(item) && item.userId === x.userId))
                }
                this.dvEvents.push(item)
                this.addEventToCalendarFormat(item)
              })
              this.updateEventCounts()
              const id = this.elementSelected !== '' ? this.elementSelected : eventId !== '' ? eventId : ''
              if ((!this.firstLoad || startDate) && id) {
                this.onEventClick(this.dvEvents.find(x => x.id === id))
                this.firstLoad = true
                this.loadExtremeEvent = false
              }
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.calendar'), { type: 'error' })
            })
            .finally(() => {
              this.handleResize()
            })
        }
      }
    },
    closeDetailEvent() {
      this.selectedEvent = null
      this.showCreationModal = false
      this.keyId = Math.random().toString(36).substr(2, 5)
    },
    addEventToCalendarFormat(item) {
      const organizerEmployee = this.employeeList.find(employee => employee.id === (item.userId || (item.organizer ? item.organizer.id : null)))
      const organizerName = organizerEmployee && organizerEmployee.profile ? `${organizerEmployee.profile.firstName} ${organizerEmployee.profile.lastName}` : ''
      const color = organizerEmployee && organizerEmployee.profile ? organizerEmployee.profile.color : ''
      const userArray = this.employeeList
        .filter(employee => this.selectedCalendars.includes(employee.email.toLowerCase()))
      const userIds = userArray.map(employee => employee.id)
      if (this.formatDateForCalendar(item.startDate, item.startTime) === this.formatDateForCalendar(item.endDate, item.endTime) || this.formatOnlyTimeForCalendar(item.startTime) === this.formatOnlyTimeForCalendar(item.endTime) || (item.startTime === null && item.endTime === null)) {
        // decrease one day from the end date of item
        let endDate = new Date(this.formatDateForCalendar(item.endDate, item.endTime))
        endDate.setDate(endDate.getDate() - 1)
        endDate = this.toObject(endDate)
        const event = {
          organizer: organizerName,
          startDate: item.startDate,
          endDate: endDate,
          startTime: !item.startTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : item.startTime,
          endTime: !item.endTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : item.endTime,
          attendees: item.attendees,
          referenceIds: item.referenceIds,
          id: item.id,
          url: item.url,
          userId: item.userId,
          private: item.private,
          mediaIds: item.mediaIds,
          occurrenceEventDate: item.occurrenceEventDate,
          endByDateRecurrence: item.endByDateRecurrence,
          countRecurrence: item.countRecurrence,
          allDay: true,
          // start and end need to be in format the calendar package can understand
          start: this.formatDateForCalendar(item.startDate, !item.startTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : item.startTime),
          end: this.formatDateForCalendar(item.endDate, !item.endTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : item.endTime),
          title: item.private && item.userId !== this.currentUserId ? this.$t('message.calendar.event.privateEvent') : this.getFormattedEventTitle(item),
          originalTitle: item.private && item.userId !== this.currentUserId ? this.$t('message.calendar.event.privateEvent') : item.title,
          contentFull: this.$options.filters.displayOptionalValue(item.description),
          location: this.$options.filters.displayOptionalValue(item.location),
          class: 'colorful-event color-' + this.getColor(color) + ' ' + this.getEventsDuration(item, true),
          color: color
        }

        this.events = this.events.filter(x => x.id !== item.id || (x.userId !== item.userId && x.id === item.id) || (x.id === item.id && x.occurrenceEventDate && item.occurrenceEventDate && !this.isSameDate(x.occurrenceEventDate, item.occurrenceEventDate)))
        this.events.push(event)

        this.events = this.events.filter(e => userIds?.includes(e.userId))
        this.updateColors()
      } else {
        const event = {
          organizer: organizerName,
          startDate: item.startDate,
          endDate: item.endDate,
          startTime: !item.startTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : item.startTime,
          endTime: !item.endTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : item.endTime,
          attendees: item.attendees,
          id: item.id,
          url: item.url,
          userId: item.userId,
          private: item.private,
          mediaIds: item.mediaIds,
          occurrenceEventDate: item.occurrenceEventDate,
          endByDateRecurrence: item.endByDateRecurrence,
          countRecurrence: item.countRecurrence,
          allDay: false,
          // start and end need to be in format the calendar package can understand
          start: this.formatDateForCalendar(item.startDate, !item.startTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : item.startTime),
          end: this.formatDateForCalendar(item.endDate, !item.endTime ? { hours: 0, minutes: 0, seconds: 0, nanos: 0 } : item.endTime),
          title: item.private && item.userId !== this.currentUserId ? this.$t('message.calendar.event.privateEvent') : this.getFormattedEventTitle(item),
          referenceIds: item.referenceIds,
          originalTitle: item.private && item.userId !== this.currentUserId ? this.$t('message.calendar.event.privateEvent') : item.title,
          contentFull: this.$options.filters.displayOptionalValue(item.description),
          location: this.$options.filters.displayOptionalValue(item.location),
          class: 'colorful-event color-' + this.getColor(color) + ' ' + this.getEventsDuration(item, true),
          color: color
        }

        this.events = this.events.filter(x => x.id !== item.id || (x.userId !== item.userId && x.id === item.id) || (x.id === item.id && x.occurrenceEventDate && item.occurrenceEventDate && !this.isSameDate(x.occurrenceEventDate, item.occurrenceEventDate)))
        this.events.push(event)

        this.events = this.events.filter(e => userIds?.includes(e.userId))

        // sort events by start date
        this.events.sort((a, b) => {
          return new Date(a.start).getTime() - new Date(b.start).getTime()
        })
        this.updateColors()
      }
    },
    isSameDate(date1, date2) {
      if (!date1 || !date2) {
        return false
      }
      return date1.year === date2.year && date1.month === date2.month && date1.day === date2.day
    },
    getDayNameWithDate(date) {
      const days = this.daysOftheWeek
      return `${this.$options.filters.formatDate(date.substr(0, 10))} - ${days[new Date(date).getDay()]}`
    },
    getEventsDuration(item, isWholeDay) {
      if (!isWholeDay) {
        const start = new Date(this.formatDateForCalendar(item.startDate, item.startTime))
        const end = new Date(this.formatDateForCalendar(item.endDate, item.endTime))
        const differenceInTime = end.getTime() - start.getTime()
        const differenceInMin = differenceInTime / (1000 * 60)
        return differenceInMin <= 15 ? 'xsmall-font-size' : ''
      } else {
        return ''
      }
    },
    createEvent(item) {
      this.isAllDay = event?.srcElement?.parentElement?.parentNode?.parentElement?.classList?.contains('vuecal__all-day') || false
      this.defaultDate = item
      this.selectedEvent = null
      this.showCreationModal = true
      this.$nextTick(function () {
        if (!item || !item.private || (item.private && item.userId === this.currentUserId)) {
          this.$refs['modal-creation'].show()
        }
      })
    },
    sortedGroup(group) {
      return group.sort(function (a, b) {
        let results = 0
        if (a.startTime === null && b.startTime === null) {
          return 0
        } else if (a.startTime === null) {
          return 1
        } else if (b.startTime === null) {
          return -1
        }
        if (results === 0) {
          results = a.startTime.hours > b.startTime.hours ? 1 : a.startTime.hours < b.startTime.hours ? -1 : 0
        }
        if (results === 0) {
          results = a.startTime.minutes > b.startTime.minutes ? 1 : a.startTime.minutes < b.startTime.minutes ? -1 : 0
        }
        return results
      })
    },
    employeeSelected(employee) {
      if (!this.selectedCalendars.includes(employee.email.toLowerCase())) {
        this.selectedCalendars.push(employee.email.toLowerCase())
      } else {
        this.selectedCalendars = this.selectedCalendars.filter(entry => entry.toLowerCase() !== employee.email.toLowerCase())
      }
      this.updateColors()
    },
    // formatDateFromTime(dateTime) {
    //   const formatedDate = new Date(dateTime)
    //   return formatedDate.getUTCFullYear() + '-' + ('0' + (formatedDate.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + formatedDate.getUTCDate()).slice(-2) + ' ' + ('0' + formatedDate.getUTCHours()).slice(-2) + ':' + ('0' + formatedDate.getUTCMinutes()).slice(-2)
    // },
    // formatOnlyDateForCalendar(date) {
    //   // start and end need to be in format the calendar package can understand
    //   return date.year + '-' + ((date.month).toString().length === 2 ? (date.month).toString() : '0' + (date.month).toString()) + '-' + (date.day.toString().length === 2 ? date.day.toString() : '0' + date.day.toString())
    // },
    formatOnlyTimeForCalendar(time) {
      return time ? ((time.hours.toString().length === 2 ? time.hours.toString() : '0' + time.hours.toString()) + ':' + (time.minutes.toString().length === 2 ? time.minutes.toString() : '0' + time.minutes.toString()) + ':00') : ''
    },
    // formatDateShowModal(date, time) {
    //   return (date.day.toString().length === 2 ? date.day.toString() : '0' + date.day.toString()) + '.' + ((date.month).toString().length === 2 ? (date.month).toString() : '0' + (date.month).toString()) + '.' + date.year + ' ' + (time.hours.toString().length === 2 ? time.hours.toString() : '0' + time.hours.toString()) + ':' + (time.minutes.toString().length === 2 ? time.minutes.toString() : '0' + time.minutes.toString()) + ':00'
    // },
    getColor(color) {
      if (color) {
        let colorCss = '#'
        colorCss += color.red.toString(16).length > 1 ? color.red.toString(16) : '0' + color.red.toString(16)
        colorCss += color.green.toString(16).length > 1 ? color.green.toString(16) : '0' + color.green.toString(16)
        colorCss += color.blue.toString(16).length > 1 ? color.blue.toString(16) : '0' + color.blue.toString(16)
        colorCss += color.alpha !== null ? (color.alpha * 255).toString(16).substring(0, 2) : ''
        return colorCss
      } else {
        return ''
      }
    },
    onCalReady() {
      this.updateColors()
      this.scrollToSpecificTime()
    },
    scrollToSpecificTime () {
      // timeCellHeight default: 40
      const calendar = document.querySelector('#globalCal .vuecal__bg')
      const hours = 6 + 0 / 60
      if (calendar) {
        calendar.scrollTo({ top: hours * 40, behavior: 'smooth' })
      }
    },
    onDayFocus(event) {
      this.dayFocus = event
    },
    onEventClick(event, e) {
      if (event) {
        this.keyId = Math.random().toString(36).substr(2, 5)
        const emailsAttendees = event.attendees?.length > 0 ? event.attendees.map(x => x.email) : []
        const isEmailAttendeesInCalendars = emailsAttendees.some(email => this.calendars.includes(email))
        const checkExportedEvent = (!isEmailAttendeesInCalendars && !(event.organizer?.email && this.calendars.includes(event.organizer?.email))) || false
        // we search for the events with the current user or events for selected calendar or exported events
        const list = this.dvEvents.filter(obj => this.getUniqueId(obj) === this.getUniqueId(event) && (obj.userId === this.userId || (obj.organizer?.email && this.calendars.includes(obj.organizer?.email)) || isEmailAttendeesInCalendars || checkExportedEvent))
        // we try to find the event for current user first and if we don't find then we search for the selected calendar then we search for the exported events
        const selectedEvent = (list?.find(obj => obj.userId === this.userId) ? list?.find(obj => obj.userId === this.userId) : list?.find(obj => (obj.organizer?.email && this.calendars.includes(obj.organizer?.email)) || isEmailAttendeesInCalendars || checkExportedEvent)) || null
        if (selectedEvent && !selectedEvent.endDateEdited && ((selectedEvent.endTime === null && selectedEvent.startTime === null) || JSON.stringify(selectedEvent.endTime) === JSON.stringify(selectedEvent.startTime) || (selectedEvent.startTime === null || (selectedEvent.startTime && selectedEvent.endTime && selectedEvent.startTime.hours === 0 && selectedEvent.startTime.minutes === 0 &&
        selectedEvent.endTime.hours === 23 && selectedEvent.endTime.minutes === 59)))) {
          let endDate = new Date(this.formatDateForCalendar(selectedEvent.endDate, selectedEvent.endTime))
          endDate.setDate(endDate.getDate() - 1)
          endDate = this.toObject(endDate)
          selectedEvent.endDate = endDate
          selectedEvent.endDateEdited = true
        }
        this.selectedEvent = selectedEvent

        // reserved for recurrent events part
        // this.selectedEvent = this.dvEvents.find(obj => obj.url === event.url && JSON.stringify(obj.occurrenceEventDate) === JSON.stringify(event.occurrenceEventDate))

        this.$nextTick(function () {
          if (this.selectedEvent && (!event || !event.private || (event.private && event.userId === this.currentUserId))) {
            this.$refs['modal-creation'].show()
          }
        })
      }
      // Prevent navigating to narrower view (default vue-cal behavior).
      if (e) {
        e.stopPropagation()
      }
    },
    onEventAgendaClick(event) {
      this.keyId = Math.random().toString(36).substr(2, 5)
      const selectedEvent = this.dvEvents.find(obj => this.getUniqueId(obj) === this.getUniqueId(event) && (obj.userId === this.userId || (obj.organizer?.email && this.calendars.includes(obj.organizer?.email)))) || null
      if (selectedEvent && !selectedEvent.endDateEdited && ((selectedEvent.endTime === null && selectedEvent.startTime === null) || JSON.stringify(selectedEvent.endTime) === JSON.stringify(selectedEvent.startTime) || (selectedEvent.startTime === null || (selectedEvent.startTime.hours === 0 && selectedEvent.startTime.minutes === 0 &&
        selectedEvent.endTime.hours === 23 && selectedEvent.endTime.minutes === 59)))) {
        let endDate = new Date(this.formatDateForCalendar(selectedEvent.endDate, selectedEvent.endTime))
        endDate.setDate(endDate.getDate() - 1)
        endDate = this.toObject(endDate)
        selectedEvent.endDate = endDate
        selectedEvent.endDateEdited = true
      }
      this.selectedEvent = selectedEvent

      // reserved for recurrent events part
      // this.selectedEvent = this.dvEvents.find(obj => obj.url === event.url && JSON.stringify(obj.occurrenceEventDate) === JSON.stringify(event.occurrenceEventDate))

      this.$nextTick(function () {
        if (!event || !event.private || (event.private && event.userId === this.currentUserId)) {
          this.$refs['modal-creation'].show()
        }
      })
    },
    onViewChange(view, e) {
      const startDate = e.startDate
      this.changeStatingEndingDate(startDate, view)
      this.$nextTick(function () {
        this.loadEvents()
      })
      this.updateEventCounts()
      this.updateColors()
      this.handleResize()
    },
    afterEdit() {
      this.$nextTick(function () {
        this.closeDetailEvent()
        this.loadEvents(true)
      })
    },
    getNameMainContact() {
      return this.contacts.find(x => x.id === this.referenceIds.split(',')[0])?.name
    },
    postCreation(items, bulk = false) {
      this.keyId = Math.random().toString(36).substr(2, 5)
      this.selectedEvent = null
      if (this.objectId !== '') {
        const requestId = this.referenceIds.split(',').find(element => element.indexOf('request_') !== -1)
        if (requestId) {
          RequestApi.partiallySetChecklist([requestId], { viewingAppointmentArranged: true })
            .then(() => {
              this.$router.push({ name: 'MarketingObjectDetailsView', params: { id: this.objectId }, query: { view: 0, elementSelected: this.referenceIds.split(',')[0] } })
            })
        } else {
          this.$router.push({ name: 'MarketingObjectDetailsView', params: { id: this.objectId }, query: { view: 0, elementSelected: this.referenceIds.split(',')[0] } })
        }
      } else if (this.referenceIds !== '') {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerContactDetailsView',
            params: { id: this.referenceIds.split(',').find(x => x.startsWith('contact')) }
          })
        } else if (this.isMarketingModule) {
          this.$router.push({
            name: 'MarketingContactDetailsView',
            params: { id: this.referenceIds.split(',').find(x => x.startsWith('contact')) }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingContactDetailsView',
            params: { id: this.referenceIds.split(',').find(x => x.startsWith('contact')) }
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetContactDetailsView',
            params: { id: this.referenceIds.split(',').find(x => x.startsWith('contact')) }
          })
        } else {
          this.$router.push({
            name: 'AdminContactDetailsView',
            params: { id: this.referenceIds.split(',').find(x => x.startsWith('contact')) }
          })
        }
      }
      if (bulk) {
        this.dvEvents = this.dvEvents.filter(x => x.id !== items[0].id)
        this.events = this.events.filter(x => x.id !== items[0].id)
      }
      if (this.selectedView === 'list') {
        items.reverse().forEach(item => {
          this.events = this.events.filter(x => x.id !== item.id || (x.userId !== item.userId && x.id === item.id) || (x.id === item.id && x.occurrenceEventDate && item.occurrenceEventDate && !this.isSameDate(x.occurrenceEventDate, item.occurrenceEventDate)))
        })
      }
      // this.events = this.events.filter(x => x.id !== items[0].id)
      items.reverse().forEach(item => {
        this.dvEvents = this.dvEvents.filter(x => x.id !== item.id || (x.id === item.id && item.occurrenceEventDate && !this.isSameDate(x.occurrenceEventDate, item.occurrenceEventDate)))
        const isEmailExists = this.selectedCalendars.some(email =>
          item.attendees.some(attendee => attendee && attendee.email.toLowerCase() === email.toLowerCase())
        )
        if (this.selectedCalendars.includes(item.organizer?.email.toLowerCase()) || isEmailExists) {
          this.addEventToCalendarFormat(item)
          this.dvEvents.push(item)
          this.updateColors()
          this.dayFocus = this.formatDateForCalendar(item.startDate, item.startTime)
          this.updateEventCounts()
          Vue.toasted.show(this.$t('message.successMessages.eventCreated'), { type: 'success' })
        } else {
          Vue.toasted.show(this.$t('message.successMessages.eventCreatedNotVisible'), { type: 'success' })
        }
      })
    },
    copyExportClicked(item) {
      this.$clipboard(item)
    },
    removeEvent(event, isSingle) {
      this.events = this.events.filter(x => isSingle ? x.id !== event.id || (x.id === event.id && event.occurrenceEventDate && !this.isSameDate(x.occurrenceEventDate, event.occurrenceEventDate)) : x.id !== event.id)
      this.updateColors()
    },
    selectAll(value) {
      const allCheckboxesComponents = this.$refs.checkbox
      if (allCheckboxesComponents) {
        allCheckboxesComponents.forEach(component => {
          const node = component.$el.childNodes[0]
          if (node.checked !== value) {
            node.checked = value
            let event = new Event('change')
            node.dispatchEvent(event)
          }
        })
      }
    },
    switchView(view, date = null) {
      this.selectedView = view
      this.changeStatingEndingDate(null, view)
      this.$nextTick(function () {
        this.loadEvents()
        this.$nextTick(function () {
          this.updateColors()
        })
      })
      if (this.$refs.globalCal && this.selectedView !== 'list') {
        this.$nextTick(function () {
          if (date) {
            this.$refs.globalCal.switchView(view, date)
          } else {
            this.$refs.globalCal.switchView(view)
          }
        })
      }
    },
    getFormattedEventTitle(item) {
      let title = ''
      if (item.title !== '' && item.location === '') {
        title = item.title
      } else if (item.title === '' && item.location !== '') {
        title = item.location
      } else if (item.title !== '' && item.location !== '') {
        title = `<b>${item.title}</b><br>${item.location}`
      }
      return this.$options.filters.displayOptionalValue(title)
    }
  }
}
</script>

<style lang="scss" scoped>

  .employees-calendar {
    background: $color-bg-grey;
  }

  .contact-content label {
    font-size: 0.8rem;
    width: calc(100% - 36px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :deep(.xsmall-font-size .vuecal__event-title) {
    font-size: 0.55rem !important;
    position: relative;
    top: -2px;
  }
  :deep(.xsmall-font-size) {
    border-width: 0;
  }
  .color-box {
    border-radius: 50%;
    border: 1px solid #00000045;
    float: left;
    height: 20px;
    width: 20px;
    font-size: 10px;
    line-height: 18px;
    text-align: center;

    &.text {
      float: none;
    }

    &.agenda{
      margin-top: 3px;
    }
  }
  :deep(.time-cell) {
    width: 125px;
  }
  :deep(.title-cell, .description-cell, .attendees-cell, .location-cell, .from-cell) {
      max-width: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  :deep(.description-cell) {
      width: 25%;
    }
  :deep(.title-cell) {
      width: 10%;
    }
  :deep(.location-cell) {
      width: 15%;
    }

  :deep(button.vuecal__arrow) {
    box-shadow: none;
  }

  :deep(.vuecal) {
    height: calc(100vh - 160px);
    background: $color-bg-white;
    .vuecal__event--all-day{
      min-height: 26px;
    }
    .vuecal__all-day {
      scrollbar-width: none;
    }
    .vuecal__bg{
      overflow: auto;
      overflow-x: hidden;
      scrollbar-width: none;
    }
    .vuecal__all-day {
      padding-right: 0;
      overflow-y: auto;
    }
    .vuecal__header {
      font-size: 0.8rem !important;
        span{
        font-size: 0.875rem !important;
        }
    }

    .vuecal__title-bar {
      background-color: $color-primary !important;
    }

    .vuecal__title {
      background-color: $color-primary !important;
      span{
        font-size: 1em !important;
      }
      button {
        box-shadow: none;
      }
    }

    .vuecal__title, .vuecal__title button, .vuecal__arrow {
      color: $color-text-white !important;
      .angle{
      margin-bottom: 2px;
      }
    }
    .month-view .vuecal__cell-events {
      margin-top: 25px;
    }

    .vuecal__cell-events-count {
      margin-top: 1px;
      background-color: $color-primary;
    }

    .vuecal__no-event, .weekday-label {
      @media screen and (max-width: 767px) {
        font-size: 70%;
      }
    }

    .vuecal__event {
      border: 1.5px solid #fff !important;
      background-color: $color-primary;
      color: $color-text-white;
      cursor: pointer;
    }

    & .vuecal__event-title {
      font-size: 0.8rem !important;
      text-align: left;
    }

    &.vuecal--month-view .vuecal__event {
      display: flex;
    }

    &.vuecal--month-view .vuecal__event-title {
      order: 2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 2px 0 5px;
    }
    &.vuecal--week-view .vuecal__event-title {
      padding-left: 3px;
    }

    &.vuecal--month-view .vuecal__event-time {
      order: 1;
      white-space: nowrap;
      display: inline;
      padding-left: 5px;
    }

    .vuecal__event-time {
      display: none;
      font-size: .85em;
    }

    .vuecal__weekdays-headings {
      color: $color-text-grey;
    }

    .vuecal__menu li {
      border-bottom-color: $color-text-white;
      color: $color-text-white;
    }

    .vuecal__menu li.vuecal__view-btn--active {
      background-color: rgba(255, 255, 255, 0.15);
    }

    .vuecal__title-bar {
      height: 36px;
    }

    .vuecal__title {
      color: $color-text-grey-dark;
      background-color: $color-grey;
    }

    .vuecal__cell.vuecal__cell--today, .vuecal__cell.vuecal__cell--current {
      color: $color-primary;
    }

    .vuecal:not(.vuecal--day-view) .vuecal__cell.vuecal__cell--selected {
      background-color: rgba(235, 255, 245, 0.4);
    }

    .vuecal__cell.vuecal__cell--selected:before {
      border-color: rgba(66, 185, 131, 0.5);
    }
  }
  :deep(.big-calendar-container) {
    @media (min-width: 768px) {
      max-width: calc(100% - 200px);
    }
  }
  :deep(.small-calendar-container) {
    .vuecal {
      height: 315px;

      .vuecal__arrow {
        .angle{
        margin-bottom: 1px;
        }
      }
    }

    @media (min-width: 768px) {
      max-width: 200px;
    }
  }

  .eventPopover {
    position: absolute;
    z-index: 1000;
    pointer-events: none;

    .content {
      border-radius: 5px;
      background: #ededed;
      color: black;
      font-size: 14px;
      max-width: 300px;
    }
    .description-text {
      word-wrap: break-word;
    }
  }
  :deep(.vuecal__event.colorful-event) {
    border: none !important;
    background-color: white !important;
    padding: 1px;
    color: #515151 !important;
  }
  //background-color: #1f2223;
  .container-event-month {
    width: 100%;
    border: 1px solid #0c52c7 !important;
    color: #62d0ff;
    background-color: transparent !important;
    border-radius: 5px;
    height: 100%;
    overflow: hidden;
    word-wrap: normal;
    .event-title, .vuecal__event-time {
      padding-left: 7px;
      text-align: left;
    }
    .event-title {
      font-weight: bold;
    }
    .vuecal__event-time {
      margin-bottom: 0.5rem;
    }
  }
  .container-event-month.accepted {
    color: #FFFFFF;
    border: 1px solid #0c57d3 !important;
    background-color: #0c57d3 !important;
  }
  .container-event-month.declined {
    text-decoration: line-through;
  }
  :deep(.vuecal__cell .container_cell_year_info) {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .vuecal__cell-date.year {
      flex: 1;
      max-height: 30px;
    }
  }
  :deep(.vuecal__header .vuecal__weekdays-headings *) {
    pointer-events: none;
  }
  .b-table .description-cell p {
    word-break: break-all;
  }
</style>
