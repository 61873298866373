<template>
  <section>
    <b-modal
      :id="modalId"
      :ref="modalId"
      :static="true"
      no-close-on-backdrop
      :title="$t('message.managementObjectsConfig.modal.assembleList')"
      @close="hideModal()"
      @show="showModal">
      <div class="">
        <draggable
          :list="selectedFields"
          class="row pr-2 pl-2">
          <template
            v-for="(item, itemIndex) in selectedFields">
            <div
              :key="`item_${itemIndex}`"
              class="col-6 pl-0 pr-0">
              <coozzy-form-checkbox
                v-model="item.selected"
                :initial="item.selected"
                class="borderFields pt-2 pb-2 pr-1 m-1">
                <template v-if="item.key === 'name'">
                  {{ $t(`message.tableColumnHeadings.${item.key}.objects`) }}
                </template>
                <template v-else-if="item.key === 'tenants'">
                  {{ $t(`message.tableColumnHeadings.tenant`) }}
                </template>
                <template v-else-if="item.key === 'currentTenant'">
                  {{ $t(`message.onBoarding.buildings.objects.tenancies.tenants.current`) }}
                </template>
                <template v-else-if="item.key === 'networkPartners'">
                  {{ $t(`message.tableColumnHeadings.networkPartner`) }}
                </template>
                <template v-else-if="item.key === 'startAt' || item.key === 'endAt'">
                  {{ $t(`message.onBoarding.buildings.objects.tenancies.${item.key}`) }}
                </template>
                <template v-else>
                  {{ $t(`message.tableColumnHeadings.${item.key}`) }}
                </template>
              </coozzy-form-checkbox>
            </div>
          </template>
        </draggable>
      </div>
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          size="normal"
          design="green"
          class="float-right save-btn"
          @click="saveClicked">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
        <coozzy-button
          size="normal"
          design="green"
          class="float-right mr-2"
          @click="resetFields">
          {{ $t('message.generic.form.reset') }}
        </coozzy-button>
        <coozzy-button
          size="normal"
          class="float-left"
          @click="hideModal()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>

import draggable from 'vuedraggable'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'

export default {
  name: 'AssembleListModal',
  components: {
    CoozzyButton,
    CoozzyFormCheckbox,
    draggable
  },
  mixins: [routeChecks],
  props: {
    modalId: {
      type: String,
      default: 'create-contact-modal'
    },
    fields: {
      type: Array,
      default: () => {
        return []
      }
    },
    defaultFields: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      newFields: []
    }
  },
  computed: {
    selectedFields() {
      return this.newFields
    }
  },
  watch: {
    fields: function(newVal) {
      this.newFields = newVal
    }
  },
  mounted() {
    let elm = null
    this.defaultFields.forEach(element => {
      if (element.key !== 'm2PerYear' && element.key !== 'marketNetRent' && element.key !== 'marketGrossRent' && element.key !== 'targetNetRent' && element.key !== 'targetGrossRent' && element.key !== 'tenancieObjectId' && element.key !== 'tenanciePartner' && element.key !== 'startOfContract' && element.key !== 'endOfContract' && element.key !== 'subtenant') {
        elm = { key: element.key, selected: true }
      } else {
        elm = { key: element.key, selected: false }
      }
      this.newFields.push(elm)
    })
  },
  methods: {
    saveClicked() {
      this.$emit('save-fields', this.selectedFields)
    },
    showModal() {
      window.addEventListener('keydown', this.keydownHandler)
    },
    hideModal() {
      window.removeEventListener('keydown', this.keydownHandler)
      this.$bvModal.hide(this.modalId)
    },
    keydownHandler(e) {
      if (e.key === 'Escape') {
        this.hideModal()
      }
    },
    resetFields() {
      this.newFields = []
      this.newFields = JSON.parse(JSON.stringify(this.defaultFields))
    }
  }
}
</script>
<style lang="scss" scoped>
  .btn {
    display: block !important;
  }
  .borderFields {
    padding-left: 2rem;
    border: 0.55px solid rgba(0,0,0,0.1);
  }
  button.primary:focus {
    background-color: rgb(0, 68, 95);
    border-color: rgb(0, 68, 95);
    box-shadow: 0 0 0 0.2rem rgb(0 68 95 / 50%) !important;
  }
  .save-btn{
    box-shadow: none !important;
  }
</style>
