<template>
  <div>
    <ul
      :class="classObject"
      v-bind="$attrs"
      v-on="mountEventListeners">
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CoozzyNavigationSidebar',
  inheritAttrs: false,
  props: {
    inline: {
      type: String,
      default: ''
    }
  },
  computed: {
    mountEventListeners() {
      return this.$listeners
    },
    classObject() {
      return {
        inline: typeof this.inline !== 'undefined'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ul {
    list-style-type: none;
    padding: 0;
    background-color: $color-grey-light;
  }

  .inline {
    li {
      display: inline-block;
    }
  }
</style>
