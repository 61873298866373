<template>
  <footer id="footer">
    <div id="prefooter">
      <div class="container p-0">
        <div class="row p-md-0 p-sm-4 col-sm-12 m-0">
          <div class="col-md-5ths col-sm-6 col-6 order-1 order-lg-1">
            <ul class="staticlist">
              <li class="formlabel">
                {{ $t('message.footer.overview') }}
              </li>
              <li class="formlabel2">
                <a
                  :href="blogLink"
                  target="_blank">{{ $t('message.footer.blog') }}</a>
              </li>
              <li class="formlabel2">
                <a href="https://propbase.ch">
                  <span>{{ $t('message.footer.howitworks') }}</span>
                </a>
              </li>
              <li class="formlabel2">
                <a :href="searchLink">{{ $t('message.footer.prices') }}</a>
              </li>
              <li class="formlabel2">
                <router-link
                  :to="{name: 'Help'}">
                  <span>{{ $t('message.footer.help') }}</span>
                </router-link>
              </li>
              <li class="formlabel2">
                <router-link :to="{name: 'Login'}">
                  {{ $t('message.footer.login') }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-5ths col-sm-6 col-6 order-3 order-lg-2">
            <ul class="staticlist">
              <li class="formlabel">
                {{ $t('message.footer.rentIn') }}
              </li>
              <li class="formlabel2">
                <a :href="cityZurichLinkToRent">{{ $t('message.footer.cities.zurich') }}</a>
              </li>
              <li class="formlabel2">
                <a :href="cityGenevaLinkToRent">{{ $t('message.footer.cities.genf') }}</a>
              </li>
              <li class="formlabel2">
                <a :href="cityBaselLinkToRent">{{ $t('message.footer.cities.basel') }}</a>
              </li>
              <li class="formlabel2">
                <a :href="cityBernLinkToRent">{{ $t('message.footer.cities.bern') }}</a>
              </li>
              <li class="formlabel2">
                <a :href="cityLausanneLinkToRent">{{ $t('message.footer.cities.lausanne') }}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-5ths col-sm-6 col-6 order-4 order-lg-3">
            <ul class="staticlist">
              <li class="formlabel">
                {{ $t('message.footer.buyIn') }}
              </li>
              <li class="formlabel2">
                <a :href="cityZurichLinkTobuy">{{ $t('message.footer.cities.zurich') }}</a>
              </li>
              <li class="formlabel2">
                <a :href="cityGenevaLinkTobuy">{{ $t('message.footer.cities.genf') }}</a>
              </li>
              <li class="formlabel2">
                <a :href="cityBaselLinkTobuy">{{ $t('message.footer.cities.basel') }}</a>
              </li>
              <li class="formlabel2">
                <a :href="cityBernLinkTobuy">{{ $t('message.footer.cities.bern') }}</a>
              </li>
              <li class="formlabel2">
                <a :href="cityLausanneLinkTobuy">{{ $t('message.footer.cities.lausanne') }}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-5ths col-sm-6 col-6 order-2 order-lg-4">
            <ul class="staticlist">
              <li class="formlabel">
                Coozzy
              </li>
              <li class="formlabel2">
                <router-link
                  :to="{name: 'AboutUs'}">
                  <span>{{ $t('message.footer.aboutus') }}</span>
                </router-link>
              </li>
              <li class="formlabel2">
                <router-link
                  :to="{name: 'ToS'}">
                  <span>{{ $t('message.footer.terms_conditions') }}</span>
                </router-link>
              </li>
              <li class="formlabel2">
                <router-link
                  :to="{name: 'PrivacyPolicy'}">
                  <span>{{ $t('message.footer.privacypolicy') }}</span>
                </router-link>
              </li>
              <li class="formlabel2">
                <router-link
                  :to="{name: 'Imprint'}">
                  <span>{{ $t('message.footer.imprint') }}</span>
                </router-link>
              </li>
              <li class="formlabel2">
                <router-link
                  :to="{name: 'Cookies'}">
                  <span>{{ $t('message.footer.cookies') }}</span>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-5ths col-sm-6 col-12 px-3 px-lg-0 social order-5 order-lg-5">
            <ul class="staticlist">
              <li class="formlabel">
                {{ $t('message.footer.socialMedias') }}
              </li>
              <div>
                <span class="footerlink rdnone">
                  <a
                    target="_blank"
                    :href="facebookLink">
                    <coozzy-facebook-icon />
                  </a>
                  <a
                    target="_blank"
                    :href="instagramLink">
                    <coozzy-instagram-icon />
                  </a>
                  <a
                    target="_blank"
                    :href="pinterestLink">
                    <coozzy-pinterest-icon />
                  </a>
                </span>
              </div>
              <div class="mt-4">
                <span class="footerlink rdnone">
                  <a
                    target="_blank"
                    :href="linkedinLink">
                    <coozzy-linkedin-icon />
                  </a>
                  <a
                    target="_blank"
                    :href="twitterLink">
                    <coozzy-twitter-icon />
                  </a>
                  <a
                    target="_blank"
                    :href="youtubeLink">
                    <coozzy-youtube-icon />
                  </a>
                </span>
              </div>
            </ul>
          </div>
        </div>
        <div class="p-md-0 p-sm-4 col-sm-12">
          <div class="col-md-12 copyright">
            Copyrights © 2019 {{ $t('message.footer.copyright') }} PropBase AG
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { links } from '../../mixins/links'

export default {
  name: 'CoozzyFooter',
  mixins: [links]
}
</script>

<style lang="scss" scoped>
  .col-xs-5ths,
  .col-sm-5ths,
  .col-md-5ths,
  .col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-xs-5ths {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%;
  }

  @media (min-width: 992px) {
    .col-md-5ths {
      -webkit-box-flex: 0;
      flex: 0 0 21.75%;
      max-width: 21.75%;
      float: left;

      &.social {
        -ms-flex: 0 0 13.5%;
        -webkit-box-flex: 0;
        flex: 0 0 13.5%;
        max-width: 13%;
      }
    }
  }

  #footer {
    background: $color-text-white;
    border-top: 1px solid $color-bg-grey;

    #prefooter {
      padding: 30px 0;

      button {
        font-weight: bold;
        color: $color-text-grey-dark;
      }

      ul.staticlist {
        list-style: none;
        padding-left: 0;

        li {
          margin-bottom: 5px;
          font-size: 13px;

          a {
            color: $color-text-grey;
          }
        }

        li:first-child {
          font-weight: 600;
          margin-bottom: 15px;
        }
      }
    }

    .copyright {
      line-height: 22px;
      color: $color-text-grey;
      margin-top: 20px;
      font-size: 13px;
    }

    .footerlink {
      color: $color-text-grey;
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;

      svg {
        color: $color-text-grey;
        font-size: 18px;
        font-weight: bold;
        margin-left: 0;
        margin-right: 30px;

        &.last {
          margin-right: 0;
        }
      }
    }
  }
</style>
