<template>
  <div>
    <div
      v-if="$slots.default"
      class="row">
      <div class="col">
        <label class="mb-0">
          <slot />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <multiselect
          v-bind="$attrs"
          :value="value"
          :options="options"
          :placeholder="placeholder"
          :multiple="true"
          :select-label="''"
          :close-on-select="closeOnSelect"
          :clear-on-select="clearOnSelect"
          :class="checkValide"
          v-on="mountEventListeners"
          @open="onOpen">
          <template slot="noOptions">
            {{ $t('message.generic.ListIsEmpty') }}
          </template>
          <span slot="noResult">{{ $t('message.generic.noResult') }}</span>
          <template
            slot="option"
            slot-scope="props">
            <div
              :id="'opt-'+props.option.value + randomString"
              :ref="'opt-'+props.option.value + randomString"
              class="tyo">
              <b-tooltip
                :disabled="!props.option.isDisabled"
                :target="'opt-'+props.option.value + randomString"
                :title="$t('message.onBoarding.buildings.objects.tenancies.tenants.address.addressRequeredTooltip')" />
              <div
                class="option__desc"
                :class="props.option.isDisabled ? 'disableOptionHover' : ''">
                <b><span class="option__title">{{ props.option.label }}</span></b>
              </div>
              <div
                v-if="props.option.address"
                class="option__address"
                :class="props.option.isDisabled ? 'disableOptionHover' : ''">
                <span>{{ props.option.address | addressText }}</span>
              </div>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CoozzyContactMultiselect',
  props: {
    placeholder: {
      type: String,
      default: function () {
        return this.$t('message.generic.select')
      }
    },
    value: {
      type: [Array, String, Object],
      default: function () {
        return []
      }
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    checkValide: {
      type: String,
      default: ''
    },
    closeOnSelect: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    clearOnSelect: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      randomString: this.randomizationString()
    }
  },
  computed: {
    mountEventListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: (value) => {
            if (!this.multiple) {
              const array = []
              if (value.length > 0) {
                array.push(value[value.length - 1])
              }
              this.$emit('input', array.filter(x => !x.isDisabled))
            } else {
              this.$emit('input', value.filter(x => !x.isDisabled))
            }
          }
        }
      )
    }
  },
  methods: {
    onOpen() {
      this.options.forEach(e => {
        if (e && e.isDisabled) {
          if (this.$refs['opt-' + e.value + this.randomString] && this.$refs['opt-' + e.value + this.randomString].parentElement && this.$refs['opt-' + e.value + this.randomString].parentElement.parentElement) {
            if (!this.$refs['opt-' + e.value + this.randomString].parentElement.parentElement.classList.contains('disableOption')) {
              this.$refs['opt-' + e.value + this.randomString].parentElement.parentElement.classList = this.$refs['opt-' + e.value + this.randomString].parentElement.parentElement.classList + ' disableOption'
            }
          }
        }
      })
    },
    randomizationString() {
      const result = []
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 4; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
      }
      return result.join('')
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.multiselect__tags) {
    border: 1px solid $color-input-border;
    border-radius: 0.2rem;
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 3px 40px 0 8px;
    box-shadow: 1px 1px 6px #ccc;
  }

  :deep(.multiselect__placeholder) {
    padding-top: 0;
    margin-bottom: 5px;
    color: #6d757d;
  }

  :deep(.multiselect__tag) {
    padding: 3px 22px 3px 7px;
    margin-bottom: 1px;
    background-color: $color-primary;
  }

  :deep(.multiselect__input) {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  :deep(.multiselect--disabled) {
    .multiselect__select {
      display: none;
    }
  }

  :deep(.multiselect__select) {
    height: 33px;
  }

  :deep(.multiselect) {
    min-height: 33px;
  }

  :deep(.multiselect) {
    min-height: 30px;
    &.is-invalid .multiselect__tags{
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-repeat: no-repeat;
      background-position: center right calc(0.375em + 0.1875rem);
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  :deep(.multiselect__tag-icon) {
    &:after {
      color: $color-text-white !important;
    }

    &:hover {
      background-color: $color-primary-dark;
    }
  }

  :deep(.multiselect__element) {
    &:hover {
      background-color: $color-primary;
    }
  }

  :deep(.multiselect__option--highlight:after) {
    content: none !important;
  }

  :deep(.multiselect__option--highlight) {
    background-color: $color-primary;
  }

  :deep(.multiselect__option--highlight.multiselect__option--selected) {
    background-color: $color-red;
  }

  .option__address{
    font-size: 14px;
  }
  :deep(.disableOption) {
    color: #6c757d;
    background-color: #e9ecef;

    &:hover {
      color: #6c757d !important;
      background-color: #e9ecef !important;
    }
  }
  .disableOptionHover {
    cursor: default !important;
    color: #6c757d !important;
  }
</style>
