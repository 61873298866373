<template>
  <span v-if="!loading">{{ userName | displayOptionalValue }}</span>
</template>

<script>

import UserApi from '@/misc/apis/UserApi'
import Vue from 'vue'

export default {
  name: 'UserFullnameText',
  props: {
    userId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: true,
      user: null
    }
  },
  computed: {
    userName() {
      if (this.user) {
        return (this.user.profile.firstName ? this.user.profile.firstName + ' ' : '') + this.user.profile.lastName
      } else {
        return this.userId
      }
    }
  },
  mounted() {
    UserApi.getUser(this.userId)
      .then(response => {
        this.user = response
        this.loading = false
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.user'), { type: 'error' })
      })
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
