<template>
  <svg
    :id="name"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    height="16"
    viewBox="0 0 32 32"
    width="16"><g id="layer1"><path
      id="path1193"
      d="m6.0138425 16.980468h19.9719705c1.35233.0191 1.35233-2.019139 0-1.999999h-19.9719705c-.56377-.008-1.02249.45187-1.01378 1.0095.009.557639.46225.998049 1.01378.990499z"
      font-variant-ligatures="normal"
      font-variant-position="normal"
      font-variant-caps="normal"
      font-variant-numeric="normal"
      font-variant-alternates="normal"
      font-feature-settings="normal"
      text-indent="0"
      text-align="start"
      text-decoration-line="none"
      text-decoration-style="solid"
      text-decoration-color="rgb(0,0,0)"
      text-transform="none"
      text-orientation="mixed"
      white-space="normal"
      shape-padding="0"
      isolation="auto"
      mix-blend-mode="normal"
      solid-color="rgb(0,0,0)"
      solid-opacity="1"
      vector-effect="none" /></g></svg>
</template>

<script>

export default {
  name: 'CoozzyMinusIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
  color: $color-text-grey;
}
</style>
