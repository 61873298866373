<template>
  <coozzy-card>
    <div class="row">
      <div class="col-12">
        <h5 class="mb-0">
          {{ $t('message.applicationForm.legalNotice') }}
        </h5>
      </div>
      <div class="col-12 mb-3">
        <small>{{ $t('message.generic.pleaseSelect') }}</small>
      </div>
      <div
        v-if="loadingSettings || loadingAccount"
        class="col-12 text-center">
        <coozzy-spinner />
      </div>
      <template v-else>
        <div
          v-if="showContractCreationCostAgreement"
          class="col-12">
          <coozzy-form-checkbox
            v-model="costAgreement"
            :initial="false"
            :state="$v.costAgreement && $v.costAgreement.$dirty && $v.costAgreement.$error ? false : undefined">
            {{ $t('message.applicationForm.contractCreationCost', { cost: $options.filters.formatPrice(contractCreationCost, 'CHF', true) }) }}*
          </coozzy-form-checkbox>
        </div>
        <div
          class="col-12"
          :class="showContractCreationCostAgreement ? 'mt-3' : ''">
          <coozzy-form-checkbox
            v-model="obtainInfoAgreement"
            :state="$v.obtainInfoAgreement.$dirty && $v.obtainInfoAgreement.$error ? false : undefined"
            :initial="false">
            {{ $t('message.marketingSettings.applicationForm.obtainInfoAgreement', { name: name }) }}*
          </coozzy-form-checkbox>
        </div>
        <div class="col-12 mt-3">
          <p class="ml-4">
            {{ $t('message.marketingSettings.applicationForm.privacyPolicy') }}
            <a
              href="#"
              @click="$bvModal.show('privacy-modal')"> {{ $t('message.generic.privacypolicyButton') }}</a>
            {{ $t('message.marketingSettings.applicationForm.privacyPolicy2') }}
          </p>
        </div>
      </template>
    </div>
  </coozzy-card>
</template>

<script>
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import ObjectApi from '@/misc/apis/ObjectApi'
import Vue from 'vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import RequestApi from '@/marketingBasic/api/RequestApi'
import UserApi from '@/misc/apis/UserApi'
import { validation } from '@/mixins/validation'
import * as Sentry from '@sentry/vue'

export default {
  name: 'ApplicationFormLegalNotice',
  components: {
    CoozzyFormCheckbox,
    CoozzySpinner,
    CoozzyCard
  },
  mixins: [validation],
  props: {
    objectId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingSettings: true,
      loadingAccount: true,
      account: null,
      showContractCreationCostAgreement: false,
      contractCreationCost: null,
      costAgreement: false,
      obtainInfoAgreement: false
    }
  },
  computed: {
    name() {
      if (this.account && this.account.company) {
        return this.account.company.name
      } else if (this.account && this.account.individual) {
        return this.account.individual.firstName + ' ' + this.account.individual.lastName
      }

      return 'n/a'
    }
  },
  mounted() {
    this.loadObject()
  },
  methods: {
    loadObject() {
      ObjectApi.getMarketingObject(this.objectId)
        .then(objectResponse => {
          if (parseInt(objectResponse.marketingAccountId || -1) !== -1) {
            RequestApi.getApplicationFormSettings(objectResponse.marketingAccountId)
              .then(response => {
                this.showContractCreationCostAgreement = response.settings.showContractCreationCostAgreement
                this.contractCreationCost = response.settings.contractCreationCost
              })
              .catch(e => {
                if (!e.response || e.response.status !== 404) {
                  Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
                }
              })
              .finally(() => {
                this.loadingSettings = false
              })

            UserApi.getAccount(objectResponse.marketingAccountId)
              .then(response => {
                this.account = response.account
              })
              .catch(() => {
                Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
              })
              .finally(() => {
                this.loadingAccount = false
              })
          } else {
            this.loadingSettings = false
            this.loadingAccount = false
          }
        })
        .catch(() => {
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        Sentry.setExtra('legalValidation', this.$v)
        this.scrollToError()
        return true
      }
      return false
    }
  },
  validations() {
    const val = {
      obtainInfoAgreement: {
        checked: value => value === true
      }
    }

    if (this.showContractCreationCostAgreement) {
      val.costAgreement = {
        checked: value => value === true
      }
    }

    return val
  }
}
</script>

<style lang="scss" scoped>
:deep(.custom-checkbox > label) {
  font-weight: normal;
}
</style>
