<template>
  <router-view />
</template>

<script>
export default {
  name: 'CoozzyRouterViewWrapper'
}
</script>

<style lang="scss" scoped>

</style>
