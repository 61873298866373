<template>
  <div class="row">
    <div class="col-12">
      <h5>{{ $t('message.applicationForm.employment') }}</h5>
    </div>
    <div class="col-12 col-md-6">
      <label :for="`status${individualId}`">{{ $t('message.applicationForm.employment') + (isEmploymentRequired ? '*' : '') }}</label>
      <coozzy-form-select
        :id="`status${individualId}`"
        v-model="status"
        :state="($v.status && $v.status.$dirty && $v.status.$error) ? false : null">
        <option value="UNDEFINED_EMPLOYMENT_STATUS">
          -
        </option>
        <option value="EMPLOYMENT_STATUS_EMPLOYEE">
          {{ $t('message.applicationForm.employed') }}
        </option>
        <option value="EMPLOYMENT_STATUS_SELF_EMPLOYED">
          {{ $t('message.applicationForm.selfEmployed') }}
        </option>
        <option value="EMPLOYMENT_STATUS_APPRENTICE">
          {{ $t('message.applicationForm.apprentice') }}
        </option>
        <option value="EMPLOYMENT_STATUS_UNEMPLOYED">
          {{ $t('message.applicationForm.unemployed') }}
        </option>
        <option value="EMPLOYMENT_STATUS_RETIRED">
          {{ $t('message.applicationForm.retired') }}
        </option>
      </coozzy-form-select>
    </div>
    <div class="col-12 col-md-6">
      <label :for="`employmentDuration${individualId}`"> {{ $t('message.applicationForm.sinceYears') + (isEmploymentRequired ? '*' : '') }}</label>
      <coozzy-form-select
        :id="`employmentDuration${individualId}`"
        v-model="employmentDuration"
        :state="($v.employmentDuration && $v.employmentDuration.$dirty && $v.employmentDuration.$error) ? false : null">
        <option value="EMPLOYMENT_DURATION_LESS_THAN_3_MONTHS">
          {{ $t('message.applicationForm.less3Month') }}
        </option>
        <option value="EMPLOYMENT_DURATION_MORE_THAN_3_MONTHS">
          {{ $t('message.applicationForm.more3Month') }}
        </option>
        <option value="EMPLOYMENT_DURATION_1_YEAR">
          {{ $t('message.applicationForm.since1Year') }}
        </option>
        <option value="EMPLOYMENT_DURATION_2_YEARS">
          {{ $t('message.applicationForm.since2Years') }}
        </option>
        <option value="EMPLOYMENT_DURATION_3_YEARS">
          {{ $t('message.applicationForm.since3Years') }}
        </option>
        <option value="EMPLOYMENT_DURATION_4_YEARS">
          {{ $t('message.applicationForm.since4Years') }}
        </option>
        <option value="EMPLOYMENT_DURATION_5_YEARS">
          {{ $t('message.applicationForm.since5Years') }}
        </option>
        <option value="EMPLOYMENT_DURATION_MORE_THAN_5_YEARS">
          {{ $t('message.applicationForm.moreThan5Years') }}
        </option>
      </coozzy-form-select>
    </div>
    <div class="col-12 col-md-6">
      <label :for="`income${individualId}`">{{ $t('message.applicationForm.income') + (isEmploymentRequired ? '*' : '') }}</label>
      <coozzy-form-select
        :id="`income${individualId}`"
        v-model="income"
        :state="($v.income && $v.income.$dirty && $v.income.$error) ? false : null">
        <option :value="0">
          {{ $t('message.applicationForm.noIncome') }}
        </option>
        <option :value="44999">
          {{ $t('message.applicationForm.upTo45000') }}
        </option>
        <option :value="45000">
          {{ $t('message.applicationForm.45000to55000') }}
        </option>
        <option :value="55000">
          {{ $t('message.applicationForm.55000to70000') }}
        </option>
        <option :value="70000">
          {{ $t('message.applicationForm.70000to85000') }}
        </option>
        <option :value="85000">
          {{ $t('message.applicationForm.85000to100000') }}
        </option>
        <option :value="100000">
          {{ $t('message.applicationForm.moreThan100000') }}
        </option>
      </coozzy-form-select>
    </div>
    <div class="col-12 col-md-6">
      <label :for="`type${individualId}`">{{ $t('message.applicationForm.type') + (isEmploymentRequired && isUserEmployedOrApprentice ? '*' : '') }}</label>
      <coozzy-form-select
        :id="`type${individualId}`"
        v-model="type"
        :state="($v.type && $v.type.$dirty && $v.type.$error) ? false : null">
        <option value="UNDEFINED_EMPLOYMENT_TYPE">
          -
        </option>
        <option value="EMPLOYMENT_TYPE_INDEFINITE">
          {{ $t('message.applicationForm.indefinite') }}
        </option>
        <option value="EMPLOYMENT_TYPE_LIMITED">
          {{ $t('message.applicationForm.limited') }}
        </option>
        <option value="EMPLOYMENT_TYPE_TEMPORARY">
          {{ $t('message.applicationForm.temporary') }}
        </option>
      </coozzy-form-select>
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="companyPosition"
        :name="$tc('message.applicationForm.companyPosition') + (isEmploymentRequired && (isUserEmployedOrApprentice || isUserSelfEmployed) ? '*' : '')"
        type="text"
        :state="($v.companyPosition && $v.companyPosition.$dirty && $v.companyPosition.$error) ? false : null" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="companyName"
        :name="$tc('message.generic.companyName') + (isEmploymentRequired && (isUserEmployedOrApprentice || isUserSelfEmployed) ? '*' : '')"
        type="text"
        :state="($v.companyName && $v.companyName.$dirty && $v.companyName.$error) ? false : null" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="supervisor"
        :name="$tc('message.applicationForm.supervisor') + (isEmploymentRequired && isUserEmployedOrApprentice ? '*' : '')"
        type="text"
        :state="($v.supervisor && $v.supervisor.$dirty && $v.supervisor.$error) ? false : null" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="supervisorPhone"
        :name="$tc('message.applicationForm.supervisorPhone') + (isEmploymentRequired && isUserEmployedOrApprentice ? '*' : '')"
        type="text"
        :state="($v.supervisorPhone && $v.supervisorPhone.$dirty && $v.supervisorPhone.$error) ? false : null" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="supervisorEmail"
        :name="$tc('message.applicationForm.supervisorEmail') + (isEmploymentRequired && isUserEmployedOrApprentice ? '*' : '')"
        type="text"
        :state="($v.supervisorEmail && $v.supervisorEmail.$dirty && $v.supervisorEmail.$error) ? false : null" />
    </div>
  </div>
</template>

<script>
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import { mapGetters, mapMutations } from 'vuex'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { required, email } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'

export default {
  name: 'ApplicationFormEmployment',
  components: {
    CoozzyFormSelect,
    CoozzyFormInput
  },
  mixins: [validation],
  props: {
    individualId: {
      type: String,
      required: true
    },
    sending: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('applicationForm', ['getIndividualByInternalId']),
    individual() {
      return this.getIndividualByInternalId(this.individualId)
    },
    status: {
      get() {
        return this.individual.employment.status
      },
      set(value) {
        const ind = this.individual
        ind.employment.status = value
        this.updateIndividual(ind)
      }
    },
    type: {
      get() {
        return this.individual.employment.type
      },
      set(value) {
        const ind = this.individual
        ind.employment.type = value
        this.updateIndividual(ind)
      }
    },
    employmentDuration: {
      get() {
        return this.individual.employment.employmentDuration
      },
      set(value) {
        const ind = this.individual
        ind.employment.employmentDuration = value
        this.updateIndividual(ind)
      }
    },
    income: {
      get() {
        return this.individual.employment.income
      },
      set(value) {
        const ind = this.individual
        ind.employment.income = value
        this.updateIndividual(ind)
      }
    },
    companyName: {
      get() {
        return this.individual.employment.companyName
      },
      set(value) {
        const ind = this.individual
        ind.employment.companyName = value
        this.updateIndividual(ind)
      }
    },
    companyEmail: {
      get() {
        return this.individual.employment.companyEmail
      },
      set(value) {
        const ind = this.individual
        ind.employment.companyEmail = value
        this.updateIndividual(ind)
      }
    },
    companyPosition: {
      get() {
        return this.individual.employment.companyPosition
      },
      set(value) {
        const ind = this.individual
        ind.employment.companyPosition = value
        this.updateIndividual(ind)
      }
    },
    supervisor: {
      get() {
        return this.individual.employment.supervisor
      },
      set(value) {
        const ind = this.individual
        ind.employment.supervisor = value
        this.updateIndividual(ind)
      }
    },
    supervisorPhone: {
      get() {
        return this.individual.employment.supervisorPhone
      },
      set(value) {
        const ind = this.individual
        ind.employment.supervisorPhone = value
        this.updateIndividual(ind)
      }
    },
    supervisorEmail: {
      get() {
        return this.individual.employment.supervisorEmail
      },
      set(value) {
        const ind = this.individual
        ind.employment.supervisorEmail = value
        this.updateIndividual(ind)
      }
    },
    supervisorRequired() {
      return this.status === 'EMPLOYMENT_STATUS_EMPLOYEE' || this.status === 'EMPLOYMENT_STATUS_APPRENTICE'
    },
    isEmploymentRequired() {
      return this.object && ['HOUSE', 'APARTMENT', 'COMMERCIAL', 'GASTRONOMY'].includes(this.object.category)
    },
    isUserEmployedOrApprentice() {
      return this.status === 'EMPLOYMENT_STATUS_EMPLOYEE' || this.status === 'EMPLOYMENT_STATUS_APPRENTICE'
    },
    isUserSelfEmployed() {
      return this.status === 'EMPLOYMENT_STATUS_SELF_EMPLOYED'
    }
  },
  methods: {
    ...mapMutations('applicationForm', ['updateIndividual']),
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        return true
      }
      return false
    }
  },
  validations() {
    const validationEmployment = {}
    if (this.isEmploymentRequired) {
      validationEmployment.status = {
        required
      }
      validationEmployment.employmentDuration = {
        required
      }
      validationEmployment.income = {
        required
      }
      if (this.isUserEmployedOrApprentice) {
        validationEmployment.type = {
          required
        }
        validationEmployment.companyPosition = {
          required
        }
        validationEmployment.companyName = {
          required
        }
        validationEmployment.supervisor = {
          required
        }
        validationEmployment.supervisorPhone = {
          required
        }
        validationEmployment.supervisorEmail = {
          required,
          email
        }
      }
      if (this.isUserSelfEmployed) {
        validationEmployment.companyPosition = {
          required
        }
        validationEmployment.companyName = {
          required
        }
      }
    }
    return validationEmployment
  }
}
</script>

<style lang="scss" scoped>
</style>
