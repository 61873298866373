// @ts-nocheck
import Vue from 'vue'

const toastError = {
  data: function () {
    return {
      throttle: false
    }
  },
  methods: {
    async showManyToast(array) {
      if (this.throttle === false && array.length > 0) {
        this.throttle = true
        await this.sleep(1000)
        const uniqueArray = [...new Set(array)]
        let stringError = ''
        for (let i = 0; i < uniqueArray.length; i++) {
          stringError += uniqueArray[i] + '<br>'
        }
        Vue.toasted.error(stringError, { type: 'error', duration: 5000 + uniqueArray.length * 1000 })
        setTimeout(() => {
          this.throttle = false
        }, 1000)
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  }
}

export { toastError }
