// @ts-nocheck
import network from './network'
import networkTS from './networkTS'

export default {
  loadSettings(accountId) {
    return network.get(`/web/objects/briefbutler/loadSettings?accountId=${accountId}`)
      .then(response => {
        return response.data
      })
  },
  saveSettings(accountId, enabled, profiles) {
    return network.post('/web/objects/briefbutler/saveSettings', {
      accountId: accountId,
      enabled: enabled,
      profiles: profiles
    }).then(response => {
      return response.data
    })
  },
  sendRawDocument(briefbutlerId, mediaId) {
    return network.post('/web/objects/briefbutler/sendRawDocument', {
      briefbutlerId: briefbutlerId,
      mediaId: mediaId
    }).then(response => {
      return response.data
    })
  },
  listErrorsByAccountId(ownerId) {
    return network.get(`/web/objects/briefbutler/listErrorsByAccountId?ownerId=${ownerId}`)
      .then(response => {
      return response.data
    })
  },
  acknowledgeErrors(ids) {
    return networkTS.briefbutlerServiceAcknowledgeErrors({ ids: ids })
      .then(response => {
        return response.data
      })
  }
}
