<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 475.176 475.176"
    viewBox="0 0 475.176 475.176"
    x="0px"
    y="0px"
    width="18px"
    height="18px"
    class="nav-icon fa"
    xmlns="http://www.w3.org/2000/svg"><g><g><path
      d="m448.776 422.378h-422.377c-14.58 0-26.399-11.819-26.399-26.399s11.819-26.399 26.399-26.399h422.378c14.58 0 26.399 11.82 26.399 26.399s-11.82 26.399-26.4 26.399zm0-105.594h-422.377c-14.58 0-26.399-11.819-26.399-26.399s11.819-26.398 26.399-26.398h422.378c14.58 0 26.399 11.818 26.399 26.398s-11.82 26.399-26.4 26.399zm0-105.595h-422.377c-14.58 0-26.399-11.819-26.399-26.399s11.819-26.399 26.399-26.399h422.378c14.58 0 26.399 11.819 26.399 26.399s-11.82 26.399-26.4 26.399zm0-105.594h-422.377c-14.58 0-26.399-11.819-26.399-26.399s11.819-26.399 26.399-26.399h422.378c14.58 0 26.399 11.819 26.399 26.399s-11.82 26.399-26.4 26.399z"
      fill="rgb(0,0,0)" /></g></g></svg>
</template>

<script>
export default {
  name: 'CoozzyViewListIcon',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.fa {
  color: $color-text-grey;
}
</style>
