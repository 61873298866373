// @ts-nocheck
import network from './network'

export default {
  getReportingUrls(accountId) {
    return network.get(`/web/reporting/getReportingUrls/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  setReportingUrls(accountId, urls) {
    return network.post(`/web/reporting/setReportingUrls/${accountId}`, urls)
      .then(response => {
        return response.data
      })
  },
  getBuildingRentReports(buildingIds) {
    return network.post('/web/reporting/getBuildingRentReports', {
      buildingIds: buildingIds
    })
      .then(response => {
        return response.data
      })
  }
}
