
// import network from './network'
import networkTS from './networkTS'
import { CommondeepsignSignDocumentRequest } from '@/types/api'

export default {
  listSignRequests(accountId: string) {
    return networkTS.deepsignServiceListSignRequests({ accountId: accountId })
    .then(response => {
        return response.data
      })
  },
  isDeepSignEnabled(accountId: string) {
    return networkTS.deepsignServiceHasDocumentSignatureEnabled({ accountId: accountId })
      .then(response => {
        return response.data
      })
  },
  getPartnerAccessUrl(accountId: string) {
    return networkTS.deepsignServiceGetPartnerAccessUrl({ accountId: accountId })
      .then(response => {
        return response.data
      })
  },
  // signers: {contactId, order}
  // landlord (user): Add contactId of user to signers array with order=2
  // tenant and guarantor: Add 1 entry for each to signers array with order=1
  signDocument(data: CommondeepsignSignDocumentRequest) {
    return networkTS.deepsignServiceSignDocument(data)
      .then(response => {
        return response.data
      })
  },
  cancelRequest(id: string, message: string = '') {
    return networkTS.deepsignServiceCancelSignRequest({ id: id, message: message })
      .then(response => {
        return response.data
      })
  },
  listSignRequestsByAttachToReferenceIds(referenceIds: string[]) {
    return networkTS.deepsignServiceListSignRequestsByAttachToReferenceIds({ referenceIds: referenceIds })
      .then(response => {
        return response.data
      })
  },
  countOpenSignRequests(accountId: string) {
    return networkTS.deepsignServiceCountOpenSignRequests(accountId)
      .then((response) => {
        return response.data
      })
  }
}
