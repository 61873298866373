// @ts-nocheck
import axios from 'axios'
import store from '../../store/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: 30000,
  withCredentials: true
})

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  const token = store.getters['user/getAccessToken']
  config.headers.Authorization = `Bearer ${token}`

  // Create a new cancel token source for each request
  config.cancelToken = new axios.CancelToken((cancel) => {
    // Add the cancel function to the request's metadata
    config.metadata = { cancel }
  })

  return config
})

const abortController = new AbortController()

const customInstance: any = {
  ...instance,
  abortController
}

export default customInstance
