<template>
  <section class="w-100">
    <div
      v-if="countEvent !== 0">
      <coozzy-detail-card
        :title="$t('message.generic.events') + ' (' + countEvent + ')'">
        <div class="col p-0">
          <b-table
            ref="eventTable"
            bordered
            hover
            responsive="true"
            stacked="md"
            class="m-0"
            selectable
            :busy="isBusyEvent"
            :fields="fieldsEvent"
            :items="countEvent <= 5 || showAllEvents ? listEventsAdapted : listEventsAdapted.slice(0,5)"
            @row-clicked="editEvent">
            <!-- Headings -->
            <template #head(date)>
              {{ $t('message.calendar.reminder.date') }}
            </template>
            <template #head(title)>
              {{ $t('message.calendar.event.title') }}
            </template>
            <template #head(employee)>
              {{ $t('message.calendar.event.participants') }}
            </template>

            <!-- Columns -->
            <template
              #cell(date)="data">
              {{ formatDateForCalendar(data.item.startDate, data.item.startTime, 'dd.mm.yyyy', false) }}
            </template>
            <template
              #cell(title)="data">
              {{ truncateText(data.item.title) }}
            </template>
            <template
              #cell(employee)="data">
              <span
                v-for="(item, index) in data.item.attendees"
                :key="index">
                {{ item.displayName !== '' ? item.displayName : item.email }}: {{ $t('message.calendar.event.status.' + item.status) }} <br>
              </span>
            </template>
          </b-table>
          <div class="text-center">
            <a
              v-if="countEvent > 5"
              class="link"
              @click="showAllEvents = !showAllEvents">
              <coozzy-angle-double-up-icon v-if="showAllEvents === true" />
              <coozzy-angle-double-down-icon v-else />
            </a>
          </div>
        </div>
      </coozzy-detail-card>
    </div>
    <event-creation-modal
      ref="event-modal-creation"
      :key="selectedEvent !== null ? 'event-modal-creation-'+selectedEvent.id : 'event-modal-creation-'+uniqueId"
      :available-employees="availableEmpWithCurrentUser"
      :event-detail="eventForEdit"
      :reference="contact"
      :source="source"
      :contacts-list="availableAttendee"
      @post-creation="loadEvents"
      @remove-event="loadEvents" />
    <div
      v-if="showPopoverEvent"
      class="eventPopover"
      :style="{ left: page.left + 'px', top: page.top + 'px'}">
      <div
        v-if="selectedPopOverEvent"
        class="row">
        <div class="employees-calendar shadow py-3 px-4 mt-3">
          <div class="employees-calendar shadow py-3 px-4 mt-3">
            <div
              class="row">
              <div class="col-12">
                <h4 class="mb-1 font-weight-bold">
                  {{ $t('message.manageInterestedParties.eventCreation.title') }}: {{ selectedPopOverEvent.originalTitle }}
                </h4>
                <p class="mb-1">
                  {{ $t('message.navigation.calendar.title') }}: {{ selectedPopOverEventOrganizer ? selectedPopOverEventOrganizer.firstName : null }} {{ selectedPopOverEventOrganizer ? selectedPopOverEventOrganizer.lastName : null }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-1">
                <span>
                  {{ $t('message.calendar.event.location') }}: {{ selectedPopOverEvent.location }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="mb-0">
                  {{ $t('message.calendar.event.description') }}:
                </p>
                <p class="mb-0">
                  {{ getNWordsFromString(selectedPopOverEvent.description, 100, true) }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="mt-2 mb-0">
                  {{ $t('message.calendar.event.participants') }}:
                </p>
              </div>
            </div>
            <div
              v-for="attendee in selectedPopOverEvent.attendees"
              :key="attendee.email">
              <div
                class="row">
                <div class="col-10">
                  <span>
                    {{ attendee.displayName !== '' ? attendee.displayName : attendee.email }}
                  </span>
                </div>
                <div class="col-2 p-0">
                  <img
                    v-if="attendee.status === 'ATTENDEE_STATUS_NEEDS_ACTION'"
                    width="15px"
                    height="15px"
                    src="@/assets/icon/hourglass.svg"
                    :alt="'hourglass'">
                  <coozzy-success-icon v-if="attendee.status === 'ATTENDEE_STATUS_ACCEPTED'" />
                  <coozzy-fail-icon v-if="attendee.status === 'ATTENDEE_STATUS_DECLINED'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import EventCreationModal from '@/calendar/components/EventCreationModal'
import Vue from 'vue'
import CalendarApi from '../../misc/apis/CalendarApi'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard'
import CoozzyAngleDoubleUpIcon from '@/framework/components/icons/CoozzyAngleDoubleUpIcon'
import CoozzyAngleDoubleDownIcon from '@/framework/components/icons/CoozzyAngleDoubleDownIcon'
import { user } from '@/mixins/user'
import { mapActions, mapGetters } from 'vuex'
import { calendar } from '@/mixins/calendar'
import { responsivity } from '@/mixins/responsivity'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon.vue'

export default {
  name: 'ContactEventCard',
  components: { CoozzyFailIcon, CoozzySuccessIcon, CoozzyAngleDoubleDownIcon, CoozzyAngleDoubleUpIcon, CoozzyDetailCard, EventCreationModal },
  mixins: [user, calendar, responsivity, dateUtils],
  props: {
    availableAttendees: {
      type: Array,
      default() {
        return []
      }
    },
    contact: {
      type: Object,
      default: null
    },
    source: {
      type: String,
      default: ''
    },
    contactsList: {
      type: Array,
      default() {
        return []
      }
    },
    contactId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      attendeeEmails: [],
      referenceIds: [],
      showAllEvents: false,
      isBusyEvent: false,
      selectedEvent: null,
      uniqueId: '',
      eventList: [],
      eventForEdit: null,
      contactResult: [],
      fieldsEvent: [
        {
          key: 'date',
          label: this.$t('message.calendar.reminder.date'),
          tdClass: 'align-middle'
        },
        {
          key: 'title',
          label: this.$t('message.calendar.event.title'),
          tdClass: 'align-middle'
        },
        {
          key: 'employee',
          label: this.$t('message.calendar.event.participants'),
          tdClass: 'align-middle'
        }
      ],
      showPopoverEvent: false,
      selectedPopOverEvent: null,
      page: {
        left: 0,
        top: 0
      }
    }
  },
  computed: {
    selectedPopOverEventOrganizer() {
      if (this.selectedPopOverEvent) {
        return this.employeeList.find(employee => employee.id === this.selectedPopOverEvent.userId)
      }
      return null
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    countEvent() {
      return this.eventList.length
    },
    availableEmpWithCurrentUser() {
      return this.employeeList.filter(e => (!e.deleted && !e.blocked))
    },
    availableAttendee() {
      const profile = this.$store.getters['user/getCurrentUser']
      const temp = this.availableAttendees
      temp.unshift({
        firstName: profile['https://coozzy.ch/firstName'],
        lastName: profile['https://coozzy.ch/lastName'],
        email: profile.email
      })
      temp.forEach(element => {
        const cc = this.contactResult.find(x => x.email === element.email)
        if (cc) {
          element.id = cc.id
        }
      })
      return temp
    },
    listEventsAdapted() {
      const lastEvents = []
      if (this.eventList.length === 0) {
        return []
      } else {
        this.eventList.forEach(element => {
          let endDate = element.endDate
          if (element.allDay) {
            endDate = new Date(this.formatDateForCalendar(element.endDate, element.endTime))
            endDate.setDate(endDate.getDate() - 1)
            endDate = this.toObject(endDate)
          }
          const start = new Date(this.formatDateForCalendar(element.startDate, !element.startTime
? {
            hours: 0,
            minutes: 0,
            seconds: 0,
            nanos: 0
          }
: element.startTime))
          const end = new Date(this.formatDateForCalendar(endDate, !element.endTime
? {
            hours: 0,
            minutes: 0,
            seconds: 0,
            nanos: 0
          }
: element.endTime))
          const differenceInDays = this.getDifferenceInDays(start, end)
          if (element.allDay) {
            for (let index = 0; index < differenceInDays; index++) {
              element.endDate = endDate
              element.end = this.formatDateForCalendar(endDate, !element.endTime
? {
                hours: 0,
                minutes: 0,
                seconds: 0,
                nanos: 0
              }
: element.endTime)
              lastEvents.push(element)
            }
          } else {
            lastEvents.push(element)
          }
        })
      }
      return lastEvents
    }
  },
  mounted() {
    this.referenceIds = [this.contact.id || this.contactId]
    if (this.contact && this.contact.email) {
      this.attendeeEmails = [this.contact.email]
      if (this.source === 'request') {
        this.contactsList.forEach(element => {
          this.referenceIds.push(element.id)
          if (element.email !== '' && element.mail !== null) {
            this.attendeeEmails.push(element.email)
          }
        })
      } else {
        this.attendeeEmails = this.attendeeEmails.concat((this.contact.contactPersons || []).map(contactPerson => contactPerson.email))
        this.referenceIds = this.referenceIds.concat((this.contact.contactPersons || []).map(contactPerson => contactPerson.id))
      }
      this.loadEmployees()
      this.loadEvents()
    } else {
      this.loadEvents()
    }
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    loadEvents() {
      const now = new Date()
      const fiveYearsBefore = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate())
      const fiveYearsAfter = new Date(now.getFullYear() + 5, now.getMonth(), now.getDate())
      CalendarApi.getEvents(this.accountId, [...new Set(this.referenceIds)], [], this.toObject(fiveYearsBefore), this.toObject(fiveYearsAfter))
        .then(response => {
          const list = response.events.map(element => {
            element.allDay = !element.startTime ||
              !element.endTime ||
              (element.startTime.hours === 0 &&
                element.startTime.minutes === 0 &&
                element.startTime.seconds === 0 &&
                element.endTime.hours === 0 &&
                element.endTime.minutes === 0 &&
                element.endTime.seconds === 0)
            return element
          })
          this.eventList = list.filter(x => !x.private || (x.private && x.userId === this.$store.getters['user/getUserId']))
          this.eventList = this.eventList.filter((event, index, self) => {
            return index === self.findIndex(e => e.id === event.id)
          })
          this.eventList.sort((a, b) => {
            const aStartDate = new Date(this.formatDateForSort(a.startDate))
            const bStartDate = new Date(this.formatDateForSort(b.startDate))
            if (aStartDate > bStartDate) {
              return -1
            }
            if (aStartDate < bStartDate) {
              return 1
            }
            return 0
          })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.calendar'), { type: 'error' })
        })
    },
    toObject(stringDate) {
      if (!stringDate) {
        return null
      }

      const convDate = new Date(stringDate)
      return convDate instanceof Date && isFinite(convDate.getTime())
      ? {
        day: convDate.getDate(),
        month: convDate.getMonth() + 1,
        year: convDate.getFullYear()
      }
      : null
    },
    editEvent(item) {
      this.createEventClicked(item)
      this.$nextTick(function () {
        this.$refs['event-modal-creation'].show()
      })
    },
    createEventClicked(event = null) {
      this.changeUniqueId()
      if (event !== null) {
        this.eventForEdit = event
      } else {
        this.eventForEdit = null
      }
      this.$nextTick(function () {
        this.$refs['event-modal-creation'].show()
      })
    },
    changeUniqueId() {
      this.uniqueId = Math.random().toString(36).substr(2, 9)
    },
    formatDateForSort(date) {
      return date.year + '-' + ((date.month).toString().length === 2 ? (date.month).toString() : '0' + (date.month).toString()) + '-' + (date.day.toString().length === 2 ? date.day.toString() : '0' + date.day.toString())
    },
    truncateText(value) {
      if (value === null || value === '' || value === undefined || value === -1 || value === '-1') {
        return '-'
      } else {
        if (value.length > 30) {
          return value.substring(0, 30) + '...'
        }
        return value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.eventPopover {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  .employees-calendar{
    background: #15445f;
    opacity: 0.8;
    color: white;
    font-size: 14px;
    max-width: 500px;
    h4 {
      font-size: 20px;
    }
  }
}
</style>
