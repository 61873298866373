<template>
  <section>
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-else
      id="interestedPartyTab">
      <div class="row mb-2">
        <div
          v-if="isFiltered"
          class="col-12 col-md-2">
          <coozzy-button
            class="w-100 my-2"
            @click="initFilter()">
            <coozzy-filter-icon /> {{ $t('message.generic.clearFilters') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md mb-2 mb-md-0">
          <coozzy-form-input
            v-model="searchText"
            :placeholder="$t('message.generic.search')"
            @keyup="searchInputText()" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-button
            size="small"
            class="mb-0 w-100"
            :design="newFilter === null && unreadFilter === null ? 'tab-selection' : 'default'"
            @click="newFilter = null; unreadFilter = null">
            {{ $t('message.manageInterestedParties.status.all') }} ({{ countOverall }})
          </coozzy-button>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-button
            size="small"
            class="mb-0 w-100"
            :design="newFilter ? 'tab-selection' : 'default'"
            @click="newFilter = true; unreadFilter = false">
            {{ $t('message.manageInterestedParties.status.new') }} ({{ countNew }})
          </coozzy-button>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-button
            size="small"
            class="mb-0 w-100"
            :design="(newFilter === false && unreadFilter === false) ? 'tab-selection' : 'default'"
            @click="newFilter = false; unreadFilter = false">
            {{ $t('message.manageInterestedParties.status.responses') }} ({{ countUnread }})
          </coozzy-button>
        </div>
        <div class="col-12 col-md">
          <coozzy-multiselect
            v-model="sourceFilter"
            :options="marketplaceNameList"
            :multiple="true"
            :placeholder="$t('message.onBoarding.interestedParties.marketplaceName')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :preselect-first="false"
            :taggable="false"
            label="label"
            track-by="label" />
        </div>
        <div class="col-12 col-md">
          <coozzy-multiselect
            v-model="checkFilter"
            :options="translatedCheckList"
            :multiple="true"
            :placeholder="$t('message.onBoarding.interestedParties.progress')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :preselect-first="false"
            :taggable="false"
            label="label"
            track-by="label" />
        </div>
      </div>
      <div class="row my-2 align-items-center">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? $bvModal.show('confirmationModalRequest') : ''">
              {{ $t('message.generic.delete') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="bulkEmail()">
              {{ $t('message.generic.email') }}
            </coozzy-dropdown-item>
            <div
              class="row m-0"
              :class="selectedEntries.length === 0">
              <coozzy-dropdown-item
                class="col pr-0 pl-0"
                :disabled="selectedEntries.length === 0 || hasTenancy"
                @click="bulkReject()">
                {{ $t('message.generic.reject') }}
              </coozzy-dropdown-item>
              <div
                v-if="hasTenancy"
                class="pr-3">
                <coozzy-info-circle-icon
                  v-b-tooltip.hover.html="$t('message.onBoarding.interestedParties.reject.tootltip')"
                  class="mt-2" />
              </div>
            </div>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="bulkReactivate()">
              {{ $t('message.generic.reactivate') }}
            </coozzy-dropdown-item>
            <div
              class="row m-0">
              <coozzy-dropdown-item
                class="col pr-0 pl-0"
                :disabled="selectedEntries.length === 0 || hasTenancy"
                @click="moveRequest()">
                {{ $t('message.generic.moveRequest') }}
              </coozzy-dropdown-item>
              <div
                v-if="hasTenancy"
                class="pr-3">
                <coozzy-info-circle-icon
                  v-b-tooltip.hover.html="$t('message.onBoarding.interestedParties.moveRequest.tootltip')"
                  class="mt-2" />
              </div>
            </div>
            <coozzy-dropdown-item
              v-if="countNewRequest"
              :disabled="selectedEntries.length === 0"
              @click="markAsRead()">
              {{ $t('message.marketingMessages.markAsRead') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="countReadRequest"
              :disabled="selectedEntries.length === 0"
              @click="markAsUnRead()">
              {{ $t('message.marketingMessages.markAsUnRead') }}
            </coozzy-dropdown-item>
            <div
              class="d-flex m-0">
              <coozzy-dropdown-item
                class=""
                :class="listAllEmployees.length === 0 ? 'no-padding-right pr-2' : ''"
                :disabled="selectedEntries.length === 0 || listAllEmployees.length === 0"
                @click="createTicketInterestedParty()">
                {{ $t('message.generic.interestedPartySelectionOwner') }}
              </coozzy-dropdown-item>
              <div
                v-if="listAllEmployees.length === 0"
                class="pr-3">
                <coozzy-info-circle-icon
                  v-b-tooltip="{ container: '#interestedPartyTab',title: $t('message.onBoarding.interestedParties.ticketForOwnerTooltip')}"
                  class="mt-2" />
              </div>
            </div>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div class="col-12 col-md-2 offset-md-6">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option :value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option :value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option :value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option :value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option value="all">
              {{ $t('message.manageInterestedParties.filter.allEntries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div class="row my-1">
        <div class="col">
          <b-table
            ref="requestsTable"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            :busy="isBusy"
            :fields="fields"
            :items="filteredRequest"
            @row-clicked="onRowClicked"
            @row-middle-clicked="onMiddlelicked">
            <!-- Headings -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(date)>
              <coozzy-sort-dropdown
                sorting-field="date"
                :text="$t('message.tableColumnHeadings.date' )"
                :asc-desc-only="true"
                :initial-sorting="'desc'"
                @updateSorting="updateSorting" />
            </template>
            <template #head(marketplaceName)>
              {{ $t('message.tableColumnHeadings.marketplaceName') }}
            </template>
            <template #head(from)>
              {{ $t('message.tableColumnHeadings.from') }}
            </template>
            <template #head(communication)>
              {{ $t('message.generic.communication') }}
            </template>
            <template #head(progress)>
              {{ $t('message.tableColumnHeadings.progress') }}
            </template>
            <template #head(viewingDone)>
              {{ $t('message.tableColumnHeadings.viewingDone') }}
            </template>
            <template #head(subject)>
              {{ $t('message.tableColumnHeadings.subject') }}
            </template>
            <template #head(message)>
              {{ $t('message.tableColumnHeadings.message') }}
            </template>

            <!-- Data cells -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="'check_' + data.item.id"
                @change="(value) => { selectRequest(data.item.id, value) }" />
            </template>
            <template #cell(favorite)="data">
              <coozzy-favorite-icon :statefavorite="favoriteRequestIds.includes(data.item.id)" />
            </template>
            <template #cell(date)="data">
              <span
                :class="[data.item.rejected ? 'text-gray' : '', data.item.readByReceiver && !data.item.new ? '' : 'font-weight-bold']">
                {{ data.item.received | timestampToDate }}
              </span>
            </template>
            <template
              #cell(marketplaceName)="data">
              <span
                :class="[data.item.rejected ? 'text-gray' : '', data.item.readByReceiver && !data.item.new ? '' : 'font-weight-bold']">
                {{ data.item.marketplaceName === '' && data.item.source !== 'PERSONAL_REQUEST_SOURCE' ? '-' : getMarketPlaceName(data.item) }}
              </span>
            </template>
            <template
              #cell(from)="data">
              <span
                :class="[data.item.rejected ? 'text-gray' : '', data.item.readByReceiver && !data.item.new ? '' : 'font-weight-bold']">
                <template v-for="(requester, index) in data.item.requesters">
                  {{ getContactName(requester.contactId) }} <br :key="index">
                </template>
              </span>
            </template>
            <template
              #cell(communication)="data">
              <span
                :class="[data.item.rejected ? 'text-gray' : '', data.item.readByReceiver && !data.item.new ? '' : 'font-weight-bold']">
                <template v-for="(requester, index) in data.item.requesters">
                  <div :key="index">
                    <div
                      v-if="getContactCommunication(requester.contactId) && getContactCommunication(requester.contactId).phoneNumbers && getPhoneNumbers(getContactCommunication(requester.contactId).phoneNumbers, 'MOBILE') !== '-' && !getContactCommunication(requester.contactId).isCompany"
                      class="row">
                      <div class="col-1">
                        <coozzy-mobile-alt-icon />
                      </div>
                      <div class="col">
                        <a
                          class="link"
                          :href="'tel:' + getPhoneNumbers(getContactCommunication(requester.contactId).phoneNumbers, 'MOBILE')">
                          {{ getPhoneNumbers(getContactCommunication(requester.contactId).phoneNumbers, 'MOBILE') }}
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="getContactCommunication(requester.contactId) && getContactCommunication(requester.contactId).phoneNumbers && getPhoneNumbers(getContactCommunication(requester.contactId).phoneNumbers, 'HOME') !== '-' && !getContactCommunication(requester.contactId).isCompany"
                      class="row">
                      <div class="col-1">
                        <coozzy-phone-icon />
                      </div>
                      <div class="col">
                        <a
                          class="link"
                          :href="'tel:' + getPhoneNumbers(getContactCommunication(requester.contactId).phoneNumbers, 'HOME')">
                          {{ getPhoneNumbers(getContactCommunication(requester.contactId).phoneNumbers, 'HOME') }}
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="getContactCommunication(requester.contactId) && getContactCommunication(requester.contactId).phoneNumbers && getPhoneNumbers(getContactCommunication(requester.contactId).phoneNumbers, 'WORK') !== '-'"
                      class="row">
                      <div class="col-1">
                        <coozzy-headset-icon />
                      </div>
                      <div class="col">
                        <a
                          class="link"
                          :href="'tel:' + getPhoneNumbers(getContactCommunication(requester.contactId).phoneNumbers, 'WORK')">
                          {{ getPhoneNumbers(getContactCommunication(requester.contactId).phoneNumbers, 'WORK') }}
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="getContactCommunication(requester.contactId) && getContactCommunication(requester.contactId).email !== ''"
                      class="row">
                      <div class="col-1">
                        <img
                          class="email-icon-img"
                          src="@/assets/icon/arroba.svg"
                          alt="Placeholder">
                      </div>
                      <div class="col">
                        <template v-if="getContactCommunication(requester.contactId).email !== ''">
                          <a
                            class="link"
                            tabindex="0"
                            @click="sendEmailModal(getContactCommunication(requester.contactId))">
                            {{ getContactCommunication(requester.contactId).email }}
                          </a>
                        </template>
                        <template v-else>
                          {{ getContactCommunication(requester.contactId).email | displayOptionalValue }}
                        </template>
                      </div>
                    </div>
                    <div
                      v-if="getContactCommunication(requester.contactId) && getContactCommunication(requester.contactId).website !== ''"
                      class="row">
                      <div class="col-1">
                        <coozzy-globe-icon />
                      </div>
                      <div class="col">
                        <template v-if="getContactCommunication(requester.contactId).website !== ''">
                          <a
                            class="link"
                            tabindex="0"
                            target="_blank"
                            :href="getUrlWebsite(getContactCommunication(requester.contactId).website)">
                            {{ getContactCommunication(requester.contactId).website }}
                          </a>
                        </template>
                        <template v-else>
                          {{ getContactCommunication(requester.contactId).website | displayOptionalValue }}
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </span>
            </template>
            <template #cell(viewingDone)="data">
              <div>
                <template v-if="data.item.checklist.viewingAppointmentDone">
                  <coozzy-success-icon />
                </template>
                <template v-else>
                  <coozzy-fail-icon />
                </template>
              </div>
            </template>
            <template #cell(reserved)="data">
              <template v-if="data.item.tenancyId">
                <img
                  v-if="isRented"
                  width="26px"
                  height="26px"
                  src="@/assets/icon/rented.svg"
                  :alt="$t('message.marketingObjects.status.rented')">
                <img
                  v-else
                  width="26px"
                  height="26px"
                  src="@/assets/icon/reserved.svg"
                  :alt="$t('message.manageInterestedParties.reserved')">
              </template>
            </template>
            <template #cell(progress)="data">
              <span :class="[data.item.rejected ? 'text-gray' : '', data.item.readByReceiver && !data.item.new ? '' : 'font-weight-bold']">
                {{ highestProgress(data.item.checklist) }}
                ({{ countChecklist(data.item) + '/' + Object.keys(data.item.checklist).length }})
              </span>
            </template>
            <template #cell(subject)="data">
              <content-loader
                v-if="loadingMails"
                style="width: 100px;"
                width="50"
                height="24">
                <rect
                  x="0"
                  y="10"
                  rx="3"
                  ry="3"
                  width="50"
                  height="5" />
              </content-loader>
              <span
                v-else
                :class="[data.item.rejected ? 'text-gray' : '', data.item.readByReceiver && !data.item.new ? '' : 'font-weight-bold']">
                {{ getLatestMail(data.item.id) ? getLatestMail(data.item.id).subject : '-' }}
              </span>
            </template>
            <template #cell(message)="data">
              <content-loader
                v-if="loadingMails"
                style="width: 100px;"
                width="50"
                height="24">
                <rect
                  x="0"
                  y="10"
                  rx="3"
                  ry="3"
                  width="50"
                  height="5" />
              </content-loader>
              <span
                v-else
                :class="[data.item.rejected ? 'text-gray' : '', data.item.readByReceiver && !data.item.new ? '' : 'font-weight-bold']"
                class="message-body">
                {{ getLatestMail(data.item.id) ? getLatestMail(data.item.id).bodyText : '-' }}
              </span>
            </template>
          </b-table>
          <b-pagination
            v-if="nbrPerPage !== 'all'"
            v-model="currentPage"
            align="center"
            class="mt-3 mb-3"
            :total-rows="requestList.length"
            :per-page="nbrPerPage" />
        </div>
      </div>
    </div>
    <b-modal
      :id="'confirmationModalRequest'"
      ref="confirmation-modal-request"
      no-close-on-backdrop
      :title="$t('message.onBoarding.interestedParties.deleteConfirmation.title')"
      hide-footer
      @ok="deleteRequests()">
      <div class="col pl-0">
        <p>{{ $t('message.onBoarding.interestedParties.deleteConfirmation.text') }}</p>
      </div>
      <div class="col pl-0">
        <coozzy-button
          size="small"
          class="float-left mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModalRequest')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          design="green"
          class="float-right ml-3"
          @click="deleteRequests()">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      id="requestRejectConfirmationModal"
      ref="requestRejectConfirmationModal"
      no-close-on-backdrop
      :title="$t('message.onBoarding.interestedParties.rejectConfirmation.title')"
      hide-footer>
      <div class="col pl-0">
        <coozzy-button
          size="small"
          design="default"
          @click="$bvModal.hide('requestRejectConfirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="prop-green"
          @click="markAndRejectRequest()">
          {{ $t('message.generic.rejectWithoutMail') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0 mr-3"
          design="prop-green"
          @click="rejectMail">
          {{ $t('message.generic.rejectWithMail') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import debounce from 'debounce'
import { ContentLoader } from 'vue-content-loader'
import ContactApi from '@/misc/apis/ContactApi'
import MessageApi from '@/misc/apis/MessageApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFavoriteIcon from '@/framework/components/icons/CoozzyFavoriteIcon'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon'
import { request } from '@/mixins/request'
import { mail } from '@/mixins/mail'
import { tables } from '@/mixins/tables'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon'
import CoozzyHeadsetIcon from '@/framework/components/icons/CoozzyHeadsetIcon'
import CoozzyGlobeIcon from '@/framework/components/icons/CoozzyGlobeIcon'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon'
import TicketApi from '@/misc/apis/TicketApi'
import UserApi from '@/misc/apis/UserApi'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import CoozzySortDropdown from '@/framework/components/dropdown/CoozzySortDropdown.vue'
import * as Sentry from '@sentry/vue'

export default {
  name: 'InterestedPartiesTab',
  components: {
    CoozzySortDropdown,
    CoozzyInfoCircleIcon,
    CoozzyFailIcon,
    CoozzySuccessIcon,
    CoozzyGlobeIcon,
    CoozzyHeadsetIcon,
    CoozzyPhoneIcon,
    CoozzyMobileAltIcon,
    CoozzyFormInput,
    CoozzyFilterIcon,
    CoozzyFormCheckbox,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzySpinner,
    CoozzyFormSelect,
    CoozzyMultiselect,
    CoozzyButton,
    CoozzyFavoriteIcon,
    ContentLoader
  },
  mixins: [request, tables, mail],
  props: {
    object: {
      type: Object,
      default: null
    },
    requestSelected: {
      type: String,
      default: ''
    },
    filter: {
      type: String,
      default: ''
    },
    linkMail: {
      type: String,
      default: ''
    },
    linkEmail: {
      type: String,
      default: ''
    },
    isRented: {
      type: Boolean,
      default: false
    },
    filterProgress: {
      type: String,
      default: ''
    },
    filterMarketPlaceName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      nbrPerPage: 'all',
      selectedEntries: [],
      onlyMessage: false,
      searchText: '',
      currentPage: 1,
      checkFilter: [],
      sourceFilter: [],
      newFilter: null,
      unreadFilter: null,
      contactList: [],
      mailList: [],
      requestIds: [],
      favoriteRequestIds: [],
      countOverall: 0,
      countNew: 0,
      countUnread: 0,
      loading: false,
      isBusy: false,
      loadingMails: false,
      requestList: [],
      initialRequestList: [],
      contactsWithoutEmail: [],
      oldSelectedEntries: [],
      progressOrder: ['viewingAppointmentContactShared', 'viewingAppointmentArranged', 'viewingAppointmentDone', 'applicationFormRequested', 'applicationFormReceived', 'referencesObtained', 'creditCheckDone', 'contractSent', 'contractReceived'],
      sourceList: ['immoscout', 'homegate', 'newhome', 'comparis', 'tutti', 'own_website', 'print', 'poster', 'instagram', 'facebook', 'Other', 'applicationForm'],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox align-middle',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'favorite',
          label: '',
          thClass: 'th-checkbox align-middle',
          tdClass: 'td-favorite align-middle text-center'
        },
        {
          key: 'date',
          label: this.$t('message.tableColumnHeadings.date'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'marketplaceName',
          label: this.$t('message.tableColumnHeadings.marketplaceName'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'from',
          label: this.$t('message.tableColumnHeadings.from'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'communication',
          label: this.$t('message.generic.communication'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'reserved',
          label: '',
          thClass: 'align-middle',
          tdClass: 'td-reserved align-middle'
        },
        {
          key: 'progress',
          label: this.$t('message.tableColumnHeadings.progress'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'viewingDone',
          label: this.$t('message.tableColumnHeadings.progress'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'subject',
          label: this.$t('message.tableColumnHeadings.subject'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'message',
          label: this.$t('message.tableColumnHeadings.message'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      filteredRequest: [],
      listAllEmployees: [],
      hasTenancy: false,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails']),
    nbrTotalOfHits() {
      return this.requestList.length
    },
    translatedCheckList() {
      const array = []
      this.progressOrder.forEach(check => {
        const count = this.requestList.filter(x => x.checklist[check]).length
        array.push({
          label: this.$t('message.onBoarding.interestedParties.checklist.' + check) + ' (' + count + ')',
          value: check
        })
      })
      return array
    },
    marketplaceNameList() {
      const array = []
      this.sourceList.forEach(src => {
        const count = this.requestList.filter(x => x.marketplaceName === src).length
        if (src === 'applicationForm') {
          console.log('applicationForm')
          const applicationFormRequest = this.requestList.filter(item => item.source === 'PERSONAL_REQUEST_SOURCE')
          const count = applicationFormRequest.length || 0
          array.push({
            label: this.$t('message.contact.marketplace.applicationForm') + ' (' + count + ')',
            value: 'applicationForm'
          })
        } else if (src === 'own_website') {
          array.push({
            label: this.$t('message.contact.marketplace.ownWebsite') + ' (' + count + ')',
            value: src
          })
        } else if (src === 'print') {
          array.push({
            label: this.$t('message.contact.marketplace.print') + ' (' + count + ')',
            value: src
          })
        } else if (src === 'poster') {
          array.push({
            label: this.$t('message.contact.marketplace.poster') + ' (' + count + ')',
            value: src
          })
        } else if (src === 'Other') {
          array.push({
            label: this.$t('message.contact.marketplace.other') + ' (' + count + ')',
            value: src
          })
        } else if (src === 'immoscout') {
          array.push({
            label: 'Immoscout24 (' + count + ')',
            value: src
          })
        } else {
          array.push({
            label: src.charAt(0).toUpperCase() + src.slice(1) + ' (' + count + ')',
            value: src
          })
        }
      })
      return array
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id) : this.selectedEntries
    },
    isFiltered() {
      return this.sourceFilter.length > 0 || this.checkFilter.length > 0 || this.searchText !== '' || this.newFilter !== null || this.unreadFilter !== null
    },
    countNewRequest() {
      return this.requestList.filter(x => !x.readByReceiver).length > 0
    },
    countReadRequest() {
      return this.requestList.filter(x => x.readByReceiver).length > 0
    },
    availableMarketplaceNameOptions() {
      const counts = []
      const current = this
      current.requestList.forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x.marketplaceName)
        if (existingCount.length === 0 && x !== -1) {
          const sameNetwork = current.requestList.filter(z => z.marketplaceName === x.marketplaceName)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x.marketplaceName
            })
          } else {
            counts.push({
              count: 1,
              value: x.marketplaceName
            })
          }
        }
      })
      return counts
    }
  },
  watch: {
    checkFilter: function () {
      this.isBusy = true
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.addPropsFilter()
      this.filterHasChanged()
    },
    sourceFilter: function () {
      this.isBusy = true
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.addPropsFilter()
      this.filterHasChanged()
    },
    getSentMails: function (newVal) {
      if (this.contactsWithoutEmail.length > 0) {
        this.$store.dispatch('request/setRequests', this.requestList)
      }
      if (newVal.length > 0 && !this.onlyMessage) {
        this.markAsRead()
        this.rejectRequests(this.requestList, this.selectedEntriesIds)
      } else if (newVal.length > 0 && this.onlyMessage && this.contactsWithoutEmail.length > 0) {
        this.oldSelectedEntries = this.selectedEntriesIds
      }
      this.onlyMessage = false
      this.loadRequests()
    },
    requestList: function () {
      this.filterRequests()
    },
    selectedEntries: function (newVal) {
      this.hasTenancy = false
      this.requestList.forEach(element => {
        if (newVal.includes(element.id) && element.tenancyId !== '') {
          this.hasTenancy = true
        }
      })
    },
    currentPage: function () {
      this.filterRequests()
    },
    nbrPerPage: function () {
      this.filterRequests()
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = this.requestList
      }
    }
  },
  mounted() {
    this.applyPropsFilter()
    if (this.initialRequestList !== null) {
      this.loadRequests()
    }
    this.filterHasChanged = debounce(() => {
      this.loadRequests()
    }, 300)
    this.getListEmployees([this.object.ownerId])
    this.$watch(vm => [vm.newFilter, vm.unreadFilter], () => {
      this.addPropsFilter()
      this.isBusy = true
      this.loadRequests()
    })
  },
  methods: {
    ...mapMutations('message', [
      'setRenderWithObjectId',
      'setShowAllPlaceholders'
    ]),
    createTicketInterestedParty() {
      // check if any contact of requests has documents
      const listDocsPerRequest = []
      const contactsWithoutDocuments = []
      this.selectedEntriesIds.forEach(requestId => {
        const request = this.requestList.find(x => x.id === requestId)
        const contactIds = request.requesters.map(x => x.contactId)
        const contacts = this.contactList.filter(x => contactIds.includes(x.id))
        const documents = contacts.length > 0 ? contacts.flatMap(contact => contact.documentIds) : []
        const customContactsData = contacts.map(x => ({ id: x.id, name: x.name }))
        listDocsPerRequest.push({ documents: documents, contacts: customContactsData, requestId: requestId })
        if (contacts.length > 0 && documents.length === 0) {
          contactsWithoutDocuments.push(...contacts.filter(x => x.documentIds.length === 0))
        }
      })
      if (contactsWithoutDocuments.length > 0) {
        let errorMsg = ''
        if (contactsWithoutDocuments.length === 1) {
          errorMsg = contactsWithoutDocuments[0].name + ' ' + this.$t('message.interestedParty.singleNoDocumentStored')
        } else {
          contactsWithoutDocuments.forEach((contact, index) => {
            errorMsg += contact.name + ' '
            if (index < contactsWithoutDocuments.length - 1) {
              errorMsg += this.$t('message.interestedParty.and') + ' '
            }
          })
          errorMsg += this.$t('message.interestedParty.pluralNoDocumentStored')
        }
        Vue.toasted.show(errorMsg, { type: 'error' })
      } else {
        const title = this.$t('message.interestedParty.interestedPartySelection') + ' - ' + this.object.name
        const requestsData = { title: title, listDocsPerRequest: listDocsPerRequest, objectOwner: this.object.ownerId }
        const defaultAssignee = this.object.contacts.contactIdAssigneeOwner
        this.$router.push({
          name: 'MarketingTicketCreation',
          query: {
            fromBulk: true,
            ids: this.selectedEntriesIds,
            defaultAssignee: defaultAssignee,
            ticketType: 'TICKET_TYPE_TASK',
            requestsData: JSON.stringify(requestsData),
            ticketPriority: 'HIGH'
          }
        })
      }
    },
    getListEmployees(listIds) {
      TicketApi.listTicketingEnabled(listIds).then(rsp => {
        UserApi.listAllEmployees(rsp.enabledAccountIds).then(response1 => {
          const employeeList = response1.employees.filter(x => (x.blocked === false && x.deleted === false))
          if (employeeList.length > 0) {
            const ids = employeeList.map(x => x.accountId)
            UserApi.listAccountsByIds(ids)
              .then(accounts => {
                this.listAllEmployees = accounts.accounts
              })
          }
        })
      })
    },
    filterRequests(sort = 'desc') {
      const requestList = this.requestList.slice()
      if (sort === 'desc') {
        requestList.sort((a, b) => a.received > b.received ? -1 : 1)
          .sort((a, b) => {
            const x = !!a.readByReceiver
            const y = !!b.readByReceiver
            return (x === y) ? 0 : x ? 1 : -1
          })
          .sort((a, b) => {
            const x = !!a.favorite
            const y = !!b.favorite
            return (x === y) ? 0 : x ? -1 : 1
          })
          .sort((a, b) => {
            const x = !!a.tenancyId
            const y = !!b.tenancyId
            return (x === y) ? 0 : x ? -1 : 1
          })
          .sort((a, b) => {
            const x = !!a.rejected
            const y = !!b.rejected
            return (x === y) ? 0 : x ? 1 : -1
          })
      } else if (sort === 'asc') {
        requestList.sort((a, b) => a.received < b.received ? -1 : 1)
          .sort((a, b) => {
            const x = !!a.readByReceiver
            const y = !!b.readByReceiver
            return (x === y) ? 0 : x ? 1 : -1
          })
          .sort((a, b) => {
            const x = !!a.favorite
            const y = !!b.favorite
            return (x === y) ? 0 : x ? -1 : 1
          })
          .sort((a, b) => {
            const x = !!a.tenancyId
            const y = !!b.tenancyId
            return (x === y) ? 0 : x ? -1 : 1
          })
          .sort((a, b) => {
            const x = !!a.rejected
            const y = !!b.rejected
            return (x === y) ? 0 : x ? 1 : -1
          })
      }
      if (this.nbrPerPage === 'all') {
        this.filteredRequest = requestList.slice()
      } else {
        this.filteredRequest = requestList.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
      }
    },
    updateSorting(value) {
      this.filterRequests(value)
    },
    closeModal() {
      this.$bvModal.hide('listContactsWithoutEmailModal')
      this.contactsWithoutEmail = []
    },
    initFilter() {
      this.checkFilter = []
      this.sourceFilter = []
      this.newFilter = null
      this.unreadFilter = null
      this.searchText = ''
      this.filterHasChanged()
    },
    getMarketPlaceName(item) {
      if (item.source === 'PERSONAL_REQUEST_SOURCE') {
        return this.$t('message.contact.marketplace.applicationForm')
      }
      if (item.marketplaceName === 'own_website') {
        return this.$t('message.contact.marketplace.ownWebsite')
      } else if (item.marketplaceName === 'print') {
        return this.$t('message.contact.marketplace.print')
      } else if (item.marketplaceName === 'poster') {
        return this.$t('message.contact.marketplace.poster')
      } else if (item.marketplaceName === 'Other') {
        return this.$t('message.contact.marketplace.other')
      } else if (item.marketplaceName === 'immoscout') {
        return 'Immoscout24'
      } else {
        return item.marketplaceName.charAt(0).toUpperCase() + item.marketplaceName.slice(1)
      }
    },
    searchInputText() {
      if (this.timer) {
        console.log('wait I am still typing, clear my previous Interval, If any exists')
        clearTimeout(this.timer)
        this.timer = null
      }
      if (this.searchText !== '') {
        this.requestList = this.filterIt(this.initialRequestList, this.searchText.toLowerCase())
      } else {
        this.requestList = this.initialRequestList
      }
    },
    markAsRead() {
      const requestIdsTomarkAsRead = []
      for (let index = 0; index < this.selectedEntriesIds.length; index++) {
        const request = this.requestList.find(request => request.id === this.selectedEntriesIds[index])

        if (request.readByReceiver !== true) {
          requestIdsTomarkAsRead.push(request.id)
        }
      }
      if (requestIdsTomarkAsRead.length > 0) {
        this.loading = true
        RequestApi.markRead(requestIdsTomarkAsRead)
          .then(() => {
            Vue.toasted.show(this.$t('message.successMessages.markAsReadRequest'), { type: 'success' })
            this.loadRequests()
          })
          .catch(() => {
            this.loading = false
            Vue.toasted.show(this.$t('message.savingErrors.request'), { type: 'error' })
          })
      }
    },
    markAsUnRead() {
      const requestIdsTomarkAsUnRead = []
      for (let index = 0; index < this.selectedEntriesIds.length; index++) {
        const request = this.requestList.find(request => request.id === this.selectedEntriesIds[index])

        if (request.readByReceiver !== false) {
          requestIdsTomarkAsUnRead.push(request.id)
        }
      }
      if (requestIdsTomarkAsUnRead.length > 0) {
        this.loading = true
        RequestApi.markUnRead(requestIdsTomarkAsUnRead)
          .then(() => {
            Vue.toasted.show(this.$t('message.successMessages.markAsUnReadRequest'), { type: 'success' })
            this.loadRequests()
          })
          .catch(() => {
            this.loading = false
            Vue.toasted.show(this.$t('message.savingErrors.request'), { type: 'error' })
          })
      }
    },
    bulkReject() {
      if (this.selectedEntriesIds.length !== 0) {
        const isRejected = this.checkIfRejected(this.requestList, this.selectedEntriesIds)
        const isReserved = this.checkIfReserved(this.requestList, this.selectedEntriesIds)
        if (isRejected) {
          Vue.toasted.show(this.$t('message.loadingErrors.rejectRequestsOnce'), { type: 'error' })
        } else if (isReserved) {
          Vue.toasted.show(this.$t('message.loadingErrors.rejectReservedRequests'), { type: 'error' })
        } else {
          this.$bvModal.show('requestRejectConfirmationModal')
        }
      }
    },
    bulkReactivate() {
      this.reactivateRequests(this.requestList, this.selectedEntriesIds)
    },
    bulkEmail() {
      this.setShowAllPlaceholders(true)
      this.onlyMessage = true
      this.setRenderWithObjectId(this.object.id)
      const list = this.requestList.filter(request => this.selectedEntriesIds.includes(request.id))
      this.setSelectedEntries(list)
      this.rejectRequestMail(this.requestList, this.selectedEntriesIds, this.contactList, this.object, true, true)
    },
    addPropsFilter() {
      const filter = (this.newFilter === null && this.unreadFilter === null) ? 'all' : (this.newFilter === true && this.unreadFilter === false) ? 'new' : (this.unreadFilter === false && this.newFilter === false) ? 'responses' : ''
      const filterProgress = this.checkFilter.flatMap(x => x.value).join().length > 0 ? this.checkFilter.flatMap(x => x.value).join() : ''
      const filterMarketPlaceName = this.sourceFilter.flatMap(x => x.value).join().length > 0 ? this.sourceFilter.flatMap(x => x.value).join() : ''

      if (!this.$route.query || this.$route.query.filter !== filter || this.$route.query.filterProgress !== filterProgress || this.$route.query.filterMarketPlaceName !== filterMarketPlaceName) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            filter: filter,
            filterProgress: filterProgress,
            filterMarketPlaceName: filterMarketPlaceName,
            timestamp: Date.now()
          }
        })
      }
    },
    applyPropsFilter() {
      if (this.filter === 'all') {
        this.newFilter = null
        this.unreadFilter = null
      } else if (this.filter === 'new') {
        this.newFilter = true
        this.unreadFilter = false
      } else if (this.filter === 'responses') {
        this.newFilter = false
        this.unreadFilter = false
      }
      if (this.filterProgress !== '') {
        const progresses = this.filterProgress.split(',')
        progresses.forEach(element => {
          this.checkFilter.push({
            label: this.$t('message.onBoarding.interestedParties.checklist.' + element),
            value: element
          })
        })
      }
      if (this.filterMarketPlaceName !== '') {
        const mrkplc = this.filterMarketPlaceName.split(',')
        mrkplc.forEach(element => {
          this.sourceFilter.push({
            label: element,
            value: element
          })
        })
      }
    },
    ...mapMutations('message', ['setSendMailContacts', 'setSendMailContactsWithoutEmail', 'setSendMailPreselectedContact', 'setSendMailReferenceIds', 'setSendMailEmailToReply', 'setRenderWithObjectId', 'setBccReferenceIds', 'setAdditionalText', 'setUseBcc', 'setSendMailHasContext', 'setSendMailSecondaryTitle', 'setSelectedEntries']),
    rejectMail() {
      this.$refs.requestRejectConfirmationModal.hide()
      this.onlyMessage = false
      const list = this.requestList.filter(request => this.selectedEntriesIds.includes(request.id))
      this.setSelectedEntries(list)
      this.rejectRequestMail(this.requestList, this.selectedEntriesIds, this.contactList, this.object)
    },
    markAndRejectRequest() {
      this.markAsRead()
      this.rejectRequests(this.requestList, this.selectedEntriesIds)
    },
    countChecklist(item) {
      return Object.values(item.checklist).reduce((op, inp) => op + inp, 0)
    },
    filterIt(arr, searchKey) {
      const t = this
      const array = []
      arr.forEach(element => {
        let shouldSkip = false
        element.requesters.forEach(element2 => {
          if (shouldSkip) {
            return
          }
          if (t.getContactName(element2.contactId).toLowerCase().includes(searchKey)) {
            array.push(element)
            shouldSkip = true
          }
        })
      })
      return array
    },
    loadRequests() {
      if (!this.isBusy) {
        this.loading = true
      }

      this.selectedEntries = []
      this.selectAllEntries = false
      this.selectAllVisibleEntries = false
      const marketPlaceFilter = this.sourceFilter.map(o => o.value).filter(o => o !== 'applicationForm')
      RequestApi.listReceivedByObjectIds([this.object.id], this.newFilter, this.checkFilter.map(o => o.value), marketPlaceFilter, this.unreadFilter)
        .then(response => {
          this.initialRequestList = response.requests
          if (this.searchText !== '') {
            this.requestList = this.filterIt(this.initialRequestList, this.searchText.toLowerCase())
          } else {
            this.requestList = this.initialRequestList
          }

          if (this.sourceFilter.map(o => o.value).includes('applicationForm')) {
            this.requestList = this.requestList.filter(request => request.source === 'PERSONAL_REQUEST_SOURCE')
          }
          this.$emit('loaded-request', response.requests)

          // Emit event if we have a reserved request
          const reservedRequest = response.requests.find(request => request.tenancyId !== '' || request.tenancyIds.length > 0) || null
          if (response.reserved === true || reservedRequest) {
            this.$emit('loaded-reserved-request')
          }

          this.requestList.forEach(request => {
            if (request.favorite === true) {
              this.favoriteRequestIds.push(request.id)
            }
          })
          this.countOverall = response.overall
          this.countNew = response.new
          this.countUnread = response.unread

          // Load mails
          const requestIds = this.requestList.map(v => v.id)
          if (requestIds.length > 0) {
            this.loadingMails = true
            MessageApi.listLatestMailsForReferenceIds(requestIds)
              .then(response => {
                this.mailList = response.mails
              })
              .catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.message'), { type: 'error' })
              })
              .finally(() => {
                this.loadingMails = false
              })
          }

          // Load requesters
          const contactIds = this.requestList.map(v => v.requesters.map(requester => requester.contactId)).flat()
          if (contactIds.length > 0) {
            ContactApi.contactResolve(contactIds)
              .then(response => {
                this.contactList = response.persons.concat(response.companies)
                if (this.requestSelected !== '') {
                  this.$emit('set-request-selected', this.requestList.find(obj => obj.id === this.requestSelected))
                }
              })
              .catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
              })
              .finally(() => {
                this.isBusy = false
                this.loading = false
              })
          }

          if (this.requestList.length === 0) {
            this.isBusy = false
            this.loading = false
          }
        })
        .catch(e => {
          console.log(e)
          this.isBusy = false
          this.loading = false
          Vue.toasted.show(this.$t('message.loadingErrors.request'), { type: 'error' })
        })
    },
    deleteRequests() {
      this.loading = true
      RequestApi.deleteRequests(this.selectedEntriesIds)
        .then(() => {
          this.loadRequests()
          Vue.toasted.show(this.$t('message.onBoarding.interestedParties.request') + ' ' + this.$t('message.successMessages.deletion'), { type: 'success' })
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.code === 9) {
            Vue.toasted.show(this.$t('message.deleteErrors.reservedRequest'), { type: 'error' })
          } else {
            console.log(e)
            Vue.toasted.show(this.$t('message.deleteErrors.request'), { type: 'error' })
          }
          this.loading = false
        })
        .finally(() => {
          if (this.$refs['confirmation-modal-request']) {
            this.$refs['confirmation-modal-request'].hide()
          }
        })
    },
    setRequestsFavorite(state, id) {
      if (state !== true) {
        RequestApi.unsetRequestFavorite([id])
          .catch(() => {
            Vue.toasted.show(this.$t('message.savingErrors.favorite'), { type: 'error' })
          })
      } else {
        RequestApi.setRequestFavorite([id])
          .catch(() => {
            Vue.toasted.show(this.$t('message.savingErrors.favorite'), { type: 'error' })
          })
      }
    },
    rejectOtherRequest(withouRequest) {
      setTimeout(() => {
        this.selectedEntriesIds = this.filteredRequest.filter(request => request.rejected === false && request.id !== withouRequest).map(x => x.id)
      }, 2000)
      this.$bvModal.show('requestRejectConfirmationModal')
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs.requestsTable.$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
      this.selectAllVisibleEntries = value
      if (value === false) {
        this.selectAllEntries = false
      }
    },
    selectRequest(requestId, checked) {
      if (checked) {
        this.selectedEntries.push(requestId)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(
          entry => entry !== requestId
        )
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    highestProgress(checklist) {
      let result = '-'
      this.progressOrder.forEach(element => {
        if (checklist[element]) {
          result = this.$t('message.onBoarding.interestedParties.checklist.' + element)
        }
      })
      return result
    },
    moveRequest() {
      this.$router.push({ name: 'MarketingObjectsView', query: { linkRequest: this.selectedEntriesIds.toString(), originalObjectId: this.object.id } })
    },
    getContactName(contactId) {
      if (!this.contactList) {
        Sentry.setExtra('getContactName contactId', contactId)
        Sentry.captureMessage('Contact list empty on getContactName')
        return '-'
      }
      const contact = this.contactList.find(function (contact) {
        return contact.id === contactId
      })
      return contact ? contact.name : '-'
    },
    getContactCommunication(contactId) {
      if (!this.contactList) {
        Sentry.setExtra('getContactCommunication contactId', contactId)
        Sentry.captureMessage('Contact list empty on getContactCommunication')
        return '-'
      }
      return this.contactList.find(contact => contact.id === contactId)
    },
    getPhoneNumbers(phoneNumbers, type) {
      if (phoneNumbers && phoneNumbers.length > 0) {
        if (phoneNumbers.find(element => element.type === type)) {
          return phoneNumbers.find(element => element.type === type).number
        }
      }
      return '-'
    },
    getLatestMail(requestId) {
      const requestMails = this.mailList.filter(mail => mail.referenceIds.includes(requestId)).sort(function (a, b) {
        return b.timestamp - a.timestamp
      })
      if (requestMails.length === 0) {
        return null
      }
      requestMails[0].date = this.$options.filters.timestampToDate(requestMails[0].timestamp)
      return requestMails[0]
    },
    sendEmailModal(contact) {
      this.setSendMailContacts([contact])
      this.setSendMailPreselectedContact(contact)
      this.setSendMailReferenceIds([])
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    onRowClicked(item, index, event) {
      if (this.linkEmail !== '') {
        this.loading = true
        const sameEmail = item.requesters.map(x => x.contactId).find(a => this.getContactCommunication(a).email === this.linkEmail)
        const toLink = sameEmail || item.requesters[0].contactId
        MessageApi.setReferenceIds([this.linkMail], [toLink, item.id])
          .then(() => {
            this.loading = false
            Vue.toasted.show(this.$t('message.successMessages.emailLinked'), { type: 'success' })
            this.$emit('set-request-selected', item)
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.reference'), { type: 'error' })
          })
      } else {
        if (
          (event.srcElement.farthestViewportElement && (event.srcElement.farthestViewportElement.classList.contains('td-favorite') || event.srcElement.farthestViewportElement.classList.contains('fa-star'))) ||
          event.srcElement.classList.contains('fa-star') ||
          event.srcElement.classList.contains('td-favorite')) {
          if (this.favoriteRequestIds.includes(item.id)) {
            const res = this.requestList.indexOf(item)
            this.requestList[res].favorite = false
            const index = this.favoriteRequestIds.indexOf(item.id)
            if (index > -1) {
              this.favoriteRequestIds.splice(index, 1)
            }
          } else {
            this.favoriteRequestIds.push(item.id)
            const res = this.requestList.indexOf(item)
            this.requestList[res].favorite = true
          }
          this.setRequestsFavorite(this.favoriteRequestIds.includes(item.id), item.id)
        } else {
          this.$emit('set-request-selected', item)
        }
      }
    },
    onMiddlelicked(item) {
      if (item) {
        const route = this.$router.resolve({
          name: 'MarketingObjectDetailsView',
          params: { id: item.objectId },
          query: {
            view: 0,
            elementSelected: item.id
          }
        })
        window.open(route.href)
      }
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.custom-checkbox) {
  margin-right: 0;
  padding-right: 0;
}

:deep(.td-reserved) {
  width: 26px;
}

.message-body {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;
}

.text-gray {
  color: $color-text-grey-light;
  a {
    color: inherit;
  }
}
.no-padding-right :deep(.dropdown-item) {
  padding-right: 0;
}
</style>
