<template>
  <section>
    <coozzy-spinner v-if="loading" />
    <div v-if="!loading">
      <coozzy-card
        :title="$t('message.generic.changePassword')"
        class="mb-3">
        <div class="row">
          <div class="col">
            <coozzy-form-input
              ref="password"
              v-model="changePassword.newPassword"
              type="password"
              :state="$v.changePassword.newPassword.$dirty && $v.changePassword.newPassword.$error ? false : null"
              :name="$t('message.generic.newPassword') + '*'"
              :secondary-title="$t('message.generic.passwordRequirment')"
              class="mb-2" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <coozzy-form-input
              v-model="changePassword.repeatPassword"
              type="password"
              :state="$v.changePassword.repeatPassword.$dirty && $v.changePassword.repeatPassword.$error ? false : null"
              :name="$t('message.generic.confirmPassword') + '*'"
              class="mb-2" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <coozzy-button
              class="float-right"
              design="green"
              @click="savePassword">
              {{ $t('message.generic.form.save') }}
            </coozzy-button>
          </div>
        </div>
      </coozzy-card>
    </div>
  </section>
</template>

<script>
import UserApi from '../../misc/apis/UserApi'
import { minLength, required, sameAs } from 'vuelidate/lib/validators'
import Vue from 'vue'

export default {
  name: 'EditPasswordSettings',
  data() {
    return {
      loading: false,
      changePassword: {
        newPassword: null,
        repeatPassword: null
      }
    }
  },
  methods: {
    savePassword() {
      this.$v.changePassword.$touch()
      if (!this.$v.changePassword.$invalid) {
        this.loading = true
        UserApi.changePassword(this.$store.getters['user/getUserId'], this.changePassword.newPassword)
          .then(response => {
            this.loading = false
            this.changePassword.newPassword = null
            this.changePassword.repeatPassword = null
            this.$v.$reset()
            Vue.toasted.show(this.$t('message.accountSettings.updatedPassword'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            Vue.toasted.show(this.$t('message.savingErrors.password'), { type: 'error' })
          })
      }
    }
  },
  validations: {
    changePassword: {
      newPassword: {
        required,
        minLength: minLength(8),
        containsUppercase: function(value) {
          return /[A-Z]/.test(value)
        },
        containsLowercase: function(value) {
          return /[a-z]/.test(value)
        },
        containsNumber: function(value) {
          return /[0-9]/.test(value)
        }
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('newPassword')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .spinner-border {
    position: absolute;
    left: 50%;
    right: 50%;
  }
</style>
