<template>
  <div class="col">
    <div
      slot="table-busy"
      class="text-center text-danger my-2">
      <coozzy-spinner
        v-if="loading" />
    </div>
    <b-table
      v-if="!loading"
      id="table-groups"
      ref="table-groups"
      bordered
      responsive="true"
      stacked="md"
      class="mb-4"
      :fields="fields"
      :items="objectsWithSameTenants">
      <!-- Headings -->
      <template #head(checkbox) />
      <template #head(status)>
        {{ $t('message.tableColumnHeadings.status') }}
      </template>
      <template #head(number)>
        {{ $t('message.tableColumnHeadings.numericId') }}
      </template>
      <template #head(name)>
        {{ $t('message.tableColumnHeadings.objects') }}
      </template>
      <template #head(type)>
        {{ $t('message.tableColumnHeadings.type') }}
      </template>
      <template #head(floor)>
        {{ $t('message.tableColumnHeadings.floor') }}
      </template>
      <template #head(rooms)>
        {{ $t('message.tableColumnHeadings.rooms') }}
      </template>
      <template #head(tenant)>
        {{ $t('message.tableColumnHeadings.tenant') }}
      </template>
      <template #head(netRent)>
        {{ $t('message.tableColumnHeadings.netRent') }}
      </template>
      <template #head(grossRent)>
        {{ $t('message.tableColumnHeadings.grossRent') }}
      </template>
      <template #head(assignee)>
        {{ $t('message.tableColumnHeadings.assignee') }}
      </template>
      <template #head(startOfContract)>
        {{ $t('message.onBoarding.buildings.objects.tenancies.startAt') }}
      </template>
      <template #head(partner)>
        {{ $t('message.tableColumnHeadings.partner') }}
      </template>

      <!-- Body -->
      <template #cell(checkbox)="data">
        <coozzy-form-checkbox
          :id="'check_' + data.item.id"
          :initial="data.item.id === objectId"
          :disabled="data.item.id === objectId"
          @change="(value) => { itemSelected(data.item, value) }" />
      </template>
      <template #cell(status)="data">
        <div
          class="color-box mr-2 mt-2"
          :class="getFullStatusColorClass(data.item)" />
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="getFullStatusText(data.item, true)" />
        <!-- eslint-enable vue/no-v-html -->
      </template>
      <template #cell(number)="data">
        {{ data.item.numericId | displayOptionalValue }}
      </template>
      <template #cell(name)="data">
        {{ data.item.name | displayOptionalValue }}
      </template>
      <template #cell(type)="data">
        <template v-if="data.item.category !== 'UNDEFINED_CATEGORY'">
          {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #cell(floor)="data">
        <div class="text-center">
          {{ formatFloor(data.item.characteristics ? data.item.characteristics.floor : '') }}
        </div>
      </template>
      <template #cell(rooms)="data">
        <div class="text-center">
          {{ data.item.characteristics.rooms | displayOptionalValue }}
        </div>
      </template>
      <template #cell(partner)="data">
        <span class="d-block">
          <img
            height="20px"
            src="/img/propbase-icon.png"
            alt="prop-base">
          <template v-if="ownerAccount && ownerAccount.company">
            {{ ownerAccount.company.name }}
          </template>
          <template v-if="ownerAccount && ownerAccount.individual">
            {{ ownerAccount.individual.firstName.substr(0, 1) }}. {{ ownerAccount.individual.lastName }}
          </template>
        </span>
        <span
          v-if="!$route.path.startsWith('/asset') && getNetworkPartners(data.item.id).length > 0 && getNetworkPartners(data.item.id).find(element => element.scope ==='ASSET_SCOPE')"
          class="d-block">
          <img
            height="20px"
            src="/img/assetbase-icon.png"
            alt="asset-base">
          <template v-if="getNetworkPartners(data.item.id).find(element => element.scope ==='ASSET_SCOPE').company">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='ASSET_SCOPE').company.name }}
          </template>
          <template v-if="getNetworkPartners(data.item.id).find(element => element.scope ==='ASSET_SCOPE').individual">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='ASSET_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.id).find(element => element.scope ==='ASSET_SCOPE').individual.lastName }}
          </template>
        </span>
        <span
          v-if="!$route.path.startsWith('/admin') && getNetworkPartners(data.item.id).length > 0 && getNetworkPartners(data.item.id).find(element => element.scope ==='MANAGEMENT_SCOPE')"
          class="d-block">
          <img
            height="20px"
            src="/img/adminbase-icon.png"
            alt="admine-base">
          <template v-if="getNetworkPartners(data.item.id).find(element => element.scope ==='MANAGEMENT_SCOPE').company">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='MANAGEMENT_SCOPE').company.name }}
          </template>
          <template v-if="getNetworkPartners(data.item.id).find(element => element.scope ==='MANAGEMENT_SCOPE').individual">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='MANAGEMENT_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE').individual.lastName }}
          </template>
        </span>
        <span
          v-if="getNetworkPartners(data.item.id).length > 0 && getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE')"
          class="d-block">
          <img
            height="20px"
            src="/img/marketbase-icon.png"
            alt="market-base">
          <template v-if="getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE').company">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE').company.name }}
          </template>
          <template v-if="getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE').individual">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE').individual.lastName }}
          </template>
        </span>
      </template>
      <template #cell(tenant)="data">
        <div v-if="getCurrentTenancy(data.item.id)">
          <a
            v-for="(contactId, tenantIndex) in getCurrentTenancy(data.item.id).tenant.contactIds"
            :key="contactId"
            tabindex="0"
            @click="redirectToDetailPage(contactId)"> {{ tenantIndex === 0 ? getContactName(contactId).substr(2) : getContactName(contactId) }}</a>
        </div>
        <span v-else>-</span>
      </template>
      <template #cell(netRent)="data">
        <div class="text-nowrap">
          <span>{{ getNetRent(data.item.id) | formatPrice }}</span>
        </div>
      </template>
      <template #cell(grossRent)="data">
        <span class="text-nowrap">{{ getTotalRent(data.item.id) | formatPrice }}</span>
      </template>
      <template #cell(startOfContract)="data">
        <span class="text-nowrap d-block mx-auto text-center">{{ getStartOfContract(data.item.id) }}</span>
      </template>
      <template #cell(assignee)="data">
        <span> {{ data.item.contacts && data.item.contacts.contactIdAssigneeAdministration !== '' ? getContactName(data.item.contacts.contactIdAssigneeAdministration).substring(2) : '-' }} </span>
      </template>
    </b-table>
  </div>
</template>

<script>

import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import { onboarding } from '@/mixins/onboarding'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { algolia } from '@/mixins/algolia'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { mapActions, mapGetters } from 'vuex'
import ContactApi from '@/misc/apis/ContactApi'

export default {
  name: 'HandoverObjectsTable',
  components: {
    CoozzyFormCheckbox,
    CoozzySpinner
  },
  mixins: [formatting, obj, onboarding, routeChecks, algolia],
  props: {
    objectsWithSameTenants: {
      type: Array,
      required: true
    },
    sameTenanciesByObject: {
      type: Array,
      required: true
    },
    objectId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      selectedEntries: [],
      filteredObjects: [],
      usedContacts: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'number',
          label: this.$t('message.tableColumnHeadings.numericId'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'netRent',
          label: this.$t('message.tableColumnHeadings.netRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'grossRent',
          label: this.$t('message.tableColumnHeadings.grossRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'startOfContract',
          label: this.$t('message.tableColumnHeadings.startOfContract'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'partner',
          label: this.$t('message.tableColumnHeadings.partner'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ]
    }
  },
  computed: {
    employeeContacts() {
      let empContacts = this.contacts.filter(c => c.types.includes('EMPLOYEE'))
      if (this.getEmployees().length > 0) {
        empContacts = empContacts.filter(contact => {
          const matchingEmployee = this.getEmployees()
            .filter(employee => !employee.blocked)
            .find(employee => employee?.email?.toLowerCase()?.trim() === contact?.email?.toLowerCase()?.trim())

          return matchingEmployee !== undefined && matchingEmployee !== null
        })
      }
      return empContacts
    }
  },
  watch: {
    objectsWithSameTenants: {
      handler() {
        let contactIds = []
        this.objectsWithSameTenants.forEach(element => {
          const contactIdAssignee = element.contacts && element.contacts.contactIdAssigneeAdministration ? [element.contacts.contactIdAssigneeAdministration] : []
          contactIds = contactIds.concat(this.getCurrentTenancy(element.id) && this.getCurrentTenancy(element.id).tenant.contactIds.length > 0 ? this.getCurrentTenancy(element.id).tenant.contactIds : []).concat(contactIdAssignee)
        })
        this.availableTenants(contactIds.filter(x => x !== null && x !== ''))
      },
      deep: true
    },
    sameTenanciesByObject: {
      handler() {
        let contactIds = []
        this.sameTenanciesByObject.forEach(element => {
          contactIds = contactIds.concat(element.tenant.contactIds.length > 0 ? element.tenant.contactIds : [])
        })
        this.availableTenants(contactIds.filter(x => x !== null && x !== ''))
      },
      deep: true
    }
  },
  mounted() {
    this.loadInstantSearchInternal()
    const obj = this.objectsWithSameTenants.filter(x => x.id === this.objectId)[0]
    this.loadEmployees()
    this.selectedEntries.push(obj)
    this.$emit('selected-object', this.selectedEntries)
    this.loadOnboarding(this.selectedEntries[0].buildingId)
    this.loading = false
    let contactIds = []
    this.objectsWithSameTenants.forEach(element => {
      const contactIdAssignee = element.contacts && element.contacts.contactIdAssigneeAdministration ? [element.contacts.contactIdAssigneeAdministration] : []
      contactIds = contactIds.concat(this.getCurrentTenancy(element.id) && this.getCurrentTenancy(element.id).tenant.contactIds.length > 0 ? this.getCurrentTenancy(element.id).tenant.contactIds : []).concat(contactIdAssignee)
    })
    this.availableTenants(contactIds.filter(x => x !== null && x !== ''))
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== item.id)
      }
      this.$emit('selected-object', this.selectedEntries)
    },
    getNetworkPartners(objectId) {
      const shares = this.shares.filter(share => {
        return share.resourceIds.includes(objectId)
      })
      if (shares.length > 0 && this.networkPartners.length !== 0) {
        const partners = this.networkPartners.filter(partner => {
          return shares.map(share => share.targetAccountId).includes(partner.id)
        })
        const networkPartner = []
        partners.forEach(element => {
          const cnd = shares.filter(share => share.targetAccountId === element.id)
          if (cnd.length > 0) {
            for (let index = 0; index < cnd.length; index++) {
              element.scope = cnd[index].scope
              networkPartner.push({ ...element })
            }
          }
        })
        return networkPartner
      }
      return []
    },
    getCurrentTenancy(objectId) {
      return this.getFilteredTenancies(objectId).concat(this.sameTenanciesByObject).find(t => t.status === 'TENANCY_STATUS_CURRENT' || t.status === 'TENANCY_STATUS_CURRENT_TERMINATED')
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        this.$router.push({ name: 'AdminContactDetailsView', params: { id: contactId } })
      }
    },
    getContactName(contactId) {
      const contact = this.usedContacts.concat(this.contacts).find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })
      return contact ? ', ' + contact.name : '-'
    },
    getNetRent(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      return adjustedRentValues.coldRent
    },
    getStartOfContract(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      return this.$options.filters.objectToDate(tenancy.startingAt)
    },
    getTotalRent(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      const total = (adjustedRentValues.heatingCosts !== -1 ? adjustedRentValues.heatingCosts : 0) +
        (adjustedRentValues.additionalCosts !== -1 ? adjustedRentValues.additionalCosts : 0) +
        (adjustedRentValues.coldRent !== -1 ? adjustedRentValues.coldRent : 0)
      return total !== 0 ? total : null
    },
    getAdjustedRentValues(tenancy) {
      let selectedTenancy = tenancy.rent
      const current = this
      const pastAdjustements = tenancy.rent.adjustments.filter(obj => parseInt(obj.byDate) !== -1 && new Date(current.$options.filters.objectToDateInput(obj.byDate)) < Date.now())
      if (pastAdjustements.length > 0) {
        pastAdjustements.sort(function (a, b) {
          return new Date(current.$options.filters.objectToDateInput(b.byDate)) - new Date(current.$options.filters.objectToDateInput(a.byDate))
        })
        selectedTenancy = pastAdjustements[0]
      }
      return selectedTenancy
    },
    getFilteredTenancies(objectId) {
      const tempTenancies = this.tenancies.concat(this.sameTenanciesByObject).filter(ten => {
        return ten.objectId === objectId
      })
      return tempTenancies
    },
    getStatus(obj) {
      if (!obj) {
        return 'n/a'
      }
      const startOfDayTimestamp = this.getStartOfDayTimestamp()
      const currentTenancy = this.getFilteredTenancies(obj.id)
        .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
        .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))

      if (currentTenancy) {
        if (currentTenancy.vacancy === true) {
          if (currentTenancy.activated) {
            return 'vacant'
          } else {
            return 'vacant_not_activated'
          }
        } else if (currentTenancy.contract.type === 'LIMITED' && currentTenancy.activated === true) {
          return 'temporary'
        } else if (currentTenancy.status) {
          return currentTenancy.status.replace('TENANCY_STATUS_', '').toLowerCase()
        } else {
          return 'undefined'
        }
      } else {
        return 'undefined'
      }
    },
    getFullStatusColorClass(obj) {
      return this.getStatus(obj)
    },
    availableTenants(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          let contacts = response.persons.concat(response.companies)
          // remove duplicated contacts
          contacts = contacts.filter((contact, index, self) =>
            index === self.findIndex((t) => (
              t.id === contact.id
            ))
          )
          this.usedContacts = this.usedContacts.concat(contacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
