<template>
  <section>
    <coozzy-card v-if="!tickets">
      <div class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
    </coozzy-card>
    <coozzy-card class="statictics-card">
      <h5 class="mb-0 title-card">
        {{ $t('message.generic.myTicketsOverview') }}
        <span
          v-if="countTickets"
          class="text-blue">
          | <b>{{ countTickets }}</b>
        </span>
      </h5>
      <div class="col body text-center bottom_radius p-0">
        <b-table
          ref="ticketsTable"
          class="statictics-table m-0"
          bordered
          hover
          responsive
          :fields="fields"
          :items="tickets"
          @row-clicked="toTicketOverView">
          <template #head(emptySquare)>
            <span>{{
              $t('message.ticketOverview.ticket.priority.title')
            }}</span>
          </template>
          <template #head(openTickets)>
            <span>
              {{ $t('message.ticketOverview.status.TICKET_STATUS_OPEN') }}
            </span>
          </template>
          <template #head(inProgressTickets)>
            <span>
              {{
                $t('message.ticketOverview.status.TICKET_STATUS_IN_PROGRESS')
              }}
            </span>
          </template>
          <template #head(doneTickets)>
            <span>
              {{ $t('message.ticketOverview.status.TICKET_STATUS_DONE') }}
            </span>
          </template>
          <template #head(closedTickets)>
            <span>
              {{ $t('message.ticketOverview.status.TICKET_STATUS_CLOSED') }}
            </span>
          </template>
          <template #cell(emptySquare)="data">
            <p
              class="mb-0"
              :class="data.item.priority === 'overdue' ? 'overdue' : ''">
              {{
                $t('message.ticketOverview.ticket.priority.' + data.item.priority)
              }}
            </p>
          </template>
          <template #cell(openTickets)="data">
            <p
              class="mb-0">
              {{ data.item.open }}
            </p>
          </template>
          <template #cell(inProgressTickets)="data">
            <p
              class="mb-0"
              :class="data.item.priority === 'overdue' ? 'overdue' : ''">
              {{ data.item.inProgress }}
            </p>
          </template>
          <template #cell(doneTickets)="data">
            <p
              class="mb-0"
              :class="data.item.priority === 'overdue' ? 'overdue' : ''">
              {{ data.item.done }}
            </p>
          </template>
          <template #cell(closedTickets)="data">
            <p
              class="mb-0">
              {{ data.item.closed }}
            </p>
          </template>
        </b-table>
      </div>
    </coozzy-card>
  </section>
</template>
<script>
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { routeChecks } from '@/mixins/routeChecks'
import TicketApi from '@/misc/apis/TicketApi'
import { user } from '@/mixins/user'

export default {
  name: 'TicketStatisticsTable',
  components: { CoozzySpinner, CoozzyCard },
  mixins: [routeChecks, user],
  props: {},
  data() {
    return {
      tickets: [],
      fields: [
        {
          key: 'emptySquare',
          label: this.$t('message.ticketOverview.ticket.priority.title'),
          thClass: 'text-left font-weight-bold padding-y-2',
          tdClass: 'text-left padding-y-3'
        },
        {
          key: 'openTickets',
          label: this.$t('message.ticketOverview.status.TICKET_STATUS_OPEN'),
          thClass: 'padding-y-2',
          tdClass: 'padding-y-3'
        },
        {
          key: 'inProgressTickets',
          label: this.$t('message.ticketOverview.status.TICKET_STATUS_IN_PROGRESS'),
          thClass: 'padding-y-2',
          tdClass: 'padding-y-3'
        },
        {
          key: 'doneTickets',
          label: this.$t('message.ticketOverview.status.TICKET_STATUS_DONE'),
          thClass: 'padding-y-2',
          tdClass: 'padding-y-3'
        },
        {
          key: 'closedTickets',
          label: this.$t('message.ticketOverview.status.TICKET_STATUS_CLOSED'),
          thClass: 'padding-y-2',
          tdClass: 'padding-y-3'
        }
      ],
      countTickets: 0
    }
  },
  computed: {},
  mounted() {
    this.loadTicketStatistics()
  },
  methods: {
    loadTicketStatistics() {
      TicketApi.getTicketStatistics(this.userId).then((response) => {
        this.countTickets = response.countOverall
        const result = response
        if (
          result.openTickets &&
          result.inProgressTickets &&
          result.doneTickets &&
          result.closedTickets
        ) {
          this.tickets = []

          const highTickets = {
            open: result.openTickets.highPriority,
            inProgress: result.inProgressTickets.highPriority,
            done: result.doneTickets.highPriority,
            closed: result.closedTickets.highPriority,
            priority: 'HIGH'
          }
          this.tickets.push(highTickets)

          const normalTickets = {
            open: result.openTickets.normalPriority,
            inProgress: result.inProgressTickets.normalPriority,
            done: result.doneTickets.normalPriority,
            closed: result.closedTickets.normalPriority,
            priority: 'DEFAULT'
          }
          this.tickets.push(normalTickets)

          const lowTickets = {
            open: result.openTickets.lowPriority,
            inProgress: result.inProgressTickets.lowPriority,
            done: result.doneTickets.lowPriority,
            closed: result.closedTickets.lowPriority,
            priority: 'LOW'
          }
          this.tickets.push(lowTickets)

          const overdueTickets = {
            open: result.openTickets.overduePriority,
            inProgress: result.inProgressTickets.overduePriority,
            done: result.doneTickets.overduePriority,
            closed: '-',
            priority: 'overdue'
          }
          this.tickets.push(overdueTickets)
        }
      })
    },
    toTicketOverView(item) {
      const priority = item.priority
      if (priority === 'overdue') {
        this.$router.push({
          name: this.moduleRoutePrefix + 'TicketOverview',
          query: { defaultAssigne: this.userId, defaultOverdue: 'true' }
        })
      } else {
        this.$router.push({
          name: this.moduleRoutePrefix + 'TicketOverview',
          query: { defaultAssigne: this.userId, defaultPriority: priority }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title-card {
  background-color: rgba(0, 68, 95, 0.06);
  border: none;
  position: relative;
  padding: 0 1.25rem;
  margin-bottom: 0;
  line-height: 3rem;
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}
table.table > thead > tr > th {
  border-bottom: 1px solid rgba(0, 68, 95, 0.15);
  background: none !important;
  width: 20% !important;
}

.statictics-card :deep(.card-title) {
  background-color: rgba(0, 68, 95, 0.06);
  margin-bottom: 0;
  font-size: 1.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  line-height: 3rem;
}

.statictics-card :deep(.card-body) {
  padding: 0;
}

.statictics-card :deep(table.table) {
  padding-bottom: 2rem;
  margin-bottom: 0;
  box-shadow: none;
}

.statictics-card :deep(thead tr th) {
  border-bottom: 1px solid rgba(0, 68, 95, 0.15);
  background: none;
}

.statictics-card :deep(tbody tr) {
  border-bottom: 1px solid rgba(0, 68, 95, 0.15);
}

.statictics-card :deep(tr td div) {
  overflow-wrap: anywhere;
}
.overdue {
  color: #dc3545;
}
.statictics-table :deep(table.b-table) {
  padding-bottom: 0 !important;
  tr:nth-last-child(1) td {
    border-bottom: none !important;
  }
  tbody tr {
    cursor: pointer;
  }
}
.bottom_radius {
    border-bottom-left-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
}
:deep(.padding-y-2) {
  padding-bottom: 11px !important;
  padding-top: 11px !important;
}
:deep(.padding-y-3) {
  padding-bottom: 17px !important;
  padding-top: 18px !important;
}
.statictics-card :deep(.card-body) {
  padding: 0 !important;
  height: 320px;
}
</style>
