// @ts-nocheck
import network from './network'

export default {
  createIdxExport(accountId, platform, credentialsId, agencyId) {
    return network.post('/web/v2/export/export/create', {
      accountId: accountId,
      type: 'IDX_EXPORT_TYPE',
      platform: platform,
      agencyId: agencyId,
      credentialsId: credentialsId
    }).then(response => {
      return response.data
    })
  },
  getExports(accountId) {
    return network.post(`/web/v2/export/export/list/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  getLatestExportRuns(accountId) {
    return network.get(`/web/v2/export/export/runs/latest/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  getCredential(credentialId) {
    return network.get(`/web/v2/export/credentials/findDecrypted/${credentialId}`)
      .then(response => {
        return response.data
      })
  },
  createCredential(hostname, port, username, password) {
    return network.post('web/v2/export/credentials/create', {
      hostname: hostname,
      port: port,
      username: username,
      password: password
    }).then(response => {
      return response.data
    })
  },
  updateCredentials(credentialId, hostname, port, username, password, updatePassword) {
    return network.post('/web/v2/export/credentials/update', {
      id: credentialId,
      hostname: hostname,
      port: port,
      username: username,
      password: password,
      updatePassword: updatePassword
    }).then(response => {
      return response.data
    })
  },
  updateAgencyId(exportId, agencyId) {
    return network.post('/web/v2/export/export/setAgencyId', {
      AgencyId: agencyId,
      ExportId: exportId
    }).then(response => {
      return response.data
    })
  },
  updatePreset(exportId, preset) {
    return network.post('web/v2/export/export/setPreset', {
      preset: preset,
      exportId: exportId
    }).then(response => {
      return response.data
    })
  },
  startExport(exportId) {
    return network.post(`/web/v2/export/export/start/${exportId}`, {})
      .then(response => {
        return response.data
      })
  },
  deleteExport(exportId) {
    return network.post(`/web/v2/export/export/delete/${exportId}`, {})
      .then(response => {
        return response.data
      })
  },
  getExportPlatforms(objectId) {
    return network.get(`/web/v2/export/export/listObjectPlatforms/${objectId}`)
      .then(response => {
        return response.data
      })
  },
  setExportPlatforms(accountId, advertId, platforms) {
    return network.post('/web/v2/export/export/updatePlatforms', {
      accountId: accountId,
      objectId: advertId,
      platforms: platforms
    }).then(response => {
      return response.data
    })
  }
}
