
const calendar = {
  methods: {
    getCountEventCurrentWeek(item) {
      let count = 0
      item.forEach(element => {
        const start = new Date(this.formatDateForCalendar(element.startDate, element.startTime))
        const end = new Date(this.formatDateForCalendar(element.endDate, element.endTime))
        const differenceInDays = this.getDifferenceInDays(start, end)
        if (Math.round(differenceInDays) > 1) {
          count += Math.round(differenceInDays)
        } else {
          count += 1
        }
      })
      this.countEventCurrentWeek = count
    },
    getUniqueId(item) {
      return item.id + '-' + (item.occurrenceEventDate ? item.occurrenceEventDate?.day + '-' + item.occurrenceEventDate?.month + '-' + item.occurrenceEventDate?.year : '')
    },
    formatDateForCalendar(date, time = null, format = null, includeSeconds = true) {
      if (!date) {
        return null
      }
      // start and end need to be in format the calendar package can understand
      if (format === 'dd.mm.yyyy') {
        return (date.day.toString().length === 2 ? date.day.toString() : '0' + date.day.toString()) + '.' + ((date.month).toString().length === 2 ? (date.month).toString() : '0' + (date.month).toString()) + '.' + date.year +
        (time ? ' ' + ((time.hours.toString().length === 2 ? time.hours.toString() : '0' + time.hours.toString()) + ':' + (time.minutes.toString().length === 2 ? time.minutes.toString() : '0' + time.minutes.toString()) + (includeSeconds ? ':00' : '')) : '')
      }
      return date.year + '-' + ((date.month).toString().length === 2 ? (date.month).toString() : '0' + (date.month).toString()) + '-' + (date.day.toString().length === 2 ? date.day.toString() : '0' + date.day.toString()) +
        (time ? ' ' + ((time.hours.toString().length === 2 ? time.hours.toString() : '0' + time.hours.toString()) + ':' + (time.minutes.toString().length === 2 ? time.minutes.toString() : '0' + time.minutes.toString()) + (includeSeconds ? ':00' : '')) : '')
    },
    getDifferenceInDays(start, end) {
      if (!(start instanceof Date) || !(end instanceof Date)) {
        return null
      }
      const differenceInTime = end.getTime() - start.getTime()
      const differenceInDays = Math.round(differenceInTime / (1000 * 60 * 60 * 24))
      return differenceInDays + 1
    }
  }
}

export { calendar }
