<template>
  <section class="w-100">
    <div
      :id="`inspection-${target}-${index}`"
      v-b-toggle="`collapse-inspection-${target}-${index}`"
      class="col mb-0">
      <h6 class="d-inline-block font-weight-bold cursor-pointer width-only-content hover mt-3">
        {{ $t('message.onBoarding.devices.maintenance.titleInspection') }}
      </h6>
    </div>
    <div class="col-12 px-0">
      <b-collapse
        :id="`collapse-inspection-${target}-${index}`"
        :visible="false"
        class="col">
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-sm-12 col-md-3">
            <ais-instant-search
              v-if="searchClientInternal && !readOnly"
              :search-client="searchClientInternal"
              :routing="routing"
              index-name="contact">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                :filters="'active:true AND isEmployee:false AND isOnlyInterestedParty:false'" />
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <label>{{ $t('message.onBoarding.devices.maintenance.company') }}</label>
                  <coozzy-search-multiselect
                    v-model="contact"
                    :options="indices[0].hits.filter(x => x && x.objectID)"
                    :placeholder="''"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :taggable="false"
                    track-by="objectID"
                    label="name"
                    from="inspector"
                    :with-address="true"
                    @search-change="see(refine, $event)" />
                </template>
              </ais-autocomplete>
            </ais-instant-search>
            <span
              v-else
              class="show-data">
              <label
                class="mb-0">
                {{ $t('message.onBoarding.devices.maintenance.company') }}
              </label>
              <template v-if="isContactObjectExist(contact)">
                -
              </template>
              <template v-else>
                <router-link
                  :key="contact.value"
                  :to="getContactDetailRoute(contact.value || contact.objectID)">
                  {{ getContactObject(contact).name }}
                </router-link>
                <br>
                <address-text
                  :show-short-country="true"
                  :hide-if-empty="true"
                  :address="getContactObject(contact).address" />
              </template>
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="contract"
              :is-read-only="readOnly"
              class="mb-2"
              :name="$t('message.onBoarding.devices.contractnumber')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.subscriptionStart"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.devices.maintenance.subscriptionStart')"
              :state="$v.subscriptionStart.$dirty && $v.subscriptionStart.$error ? false : null"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.subscriptionEnd"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.devices.maintenance.subscriptionEnd')"
              :state="$v.subscriptionStart.$dirty && $v.subscriptionStart.$error ? false : null"
              @blur="dateToObject()" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.lastOfficialControl"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.devices.maintenance.lastInspection')"
              :state="$v.lastOfficialControl.$dirty && $v.lastOfficialControl.$error ? false : null"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="interval">{{ $t('message.onBoarding.devices.maintenance.costs.interval.title') }}</label>
            <coozzy-form-select
              v-if="!readOnly"
              id="interval"
              v-model="intervalInspection">
              <option value="UNDEFINED_INTERVAL">
                -
              </option>
              <option value="MONTHLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.monthly') }}
              </option>
              <option value="BI_MONTHLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.bi_monthly') }}
              </option>
              <option value="QUARTERLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.quarterly') }}
              </option>
              <option value="TRIANNUAL">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.triannual') }}
              </option>
              <option value="HALF_YEARLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.half_yearly') }}
              </option>
              <option value="YEARLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.yearly') }}
              </option>
              <option value="TWO_YEARLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.two_yearly') }}
              </option>
              <option value="THREE_YEARLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.three_yearly') }}
              </option>
              <option value="FOUR_YEARLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.four_yearly') }}
              </option>
              <option value="FIVE_YEARLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.five_yearly') }}
              </option>
              <option value="TEN_YEARLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.ten_yearly') }}
              </option>
              <option value="TWENTY_YEARLY">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.twenty_yearly') }}
              </option>
              <option value="EVERY_6_YEARS">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.every_6_years') }}
              </option>
              <option value="EVERY_8_YEARS">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.every_8_years') }}
              </option>
              <option value="EVERY_15_YEARS">
                {{ $t('message.onBoarding.devices.maintenance.costs.interval.every_15_years') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">
              {{ !intervalInspection || intervalInspection === 'UNDEFINED_INTERVAL' ? '-' : $t(`message.onBoarding.devices.maintenance.costs.interval.${intervalInspection.toLowerCase()}`) }}
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.nextOfficialControl"
              type="date"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.devices.maintenance.nextInspection')"
              :state="$v.nextOfficialControl.$dirty && $v.nextOfficialControl.$error ? false : null"
              @blur="dateToObject()" />
          </div>
        </div>
        <div class="row">
          <device-inspection-costs
            ref="device-inspection-costs"
            :index="index"
            :target="target"
            :device="device"
            :read-only="readOnly"
            @device-update="deviceUpdated" />
        </div>
        <div class="row">
          <div class="col-12">
            <coozzy-form-textarea
              v-model="note"
              :is-read-only="readOnly"
              :initial="note">
              {{ $t('message.generic.note') }}
            </coozzy-form-textarea>
          </div>
        </div>
      </b-collapse>
    </div>
  </section>
</template>

<script>

import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import { onboarding } from '@/mixins/onboarding'
import DeviceInspectionCosts from './DeviceInspectionCosts'
// import CoozzySearchSelect from '@/framework/components/form/select/CoozzySearchSelect'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import { validation } from '@/mixins/validation'
import AddressText from '@/framework/components/misc/AddressText'
import { contact } from '@/mixins/contact'
import { toastError } from '@/mixins/toastError'
import ContactApi from '@/misc/apis/ContactApi'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import { algolia } from '@/mixins/algolia'
import { dateUtils } from '@/mixins/dateUtils'

function notInFuture(value) {
  return !(value && new Date(this.objectToIsoDate(value)) >= new Date())
}

function lessThanEnding(value, vm) {
  if (!vm.subscriptionEnd || vm.subscriptionEnd <= 0) {
    return true
  }

  return new Date(this.objectToIsoDate(value)) <= new Date(this.objectToIsoDate(vm.subscriptionEnd))
}
function moreThanStarting(value, vm) {
  if (!value || value < 0) {
    return true
  }
  if ((!vm.lastOfficialControl || vm.lastOfficialControl <= 0)) {
    return true
  }

  return new Date(this.objectToIsoDate(value)) >= new Date(this.objectToIsoDate(vm.lastOfficialControl))
}
function notEqualStarting(value, vm) {
  if (!value || value < 0) {
    return true
  }
  if ((!vm.lastOfficialControl || vm.lastOfficialControl <= 0)) {
    return true
  }
  return new Date(this.objectToIsoDate(value)) !== new Date(this.objectToIsoDate(vm.lastOfficialControl))
}

export default {
  name: 'DeviceInspection',
  components: {
    CoozzyFormTextarea,
    CoozzyFormSelect,
    DeviceInspectionCosts,
    CoozzyFormInput,
    CoozzySearchMultiselect,
    // CoozzySearchSelect,
    AddressText
  },
  mixins: [onboarding, validation, contact, toastError, dateUtils, algolia],
  props: {
    index: {
      type: Number,
      default: 0
    },
    target: {
      type: String,
      default: 'building'
    },
    device: {
      type: Object,
      default: null
    },
    contactsList: {
      type: Array,
      default() {
        return []
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dates: {
        lastOfficialControl: null,
        nextOfficialControl: null,
        subscriptionStart: null,
        subscriptionEnd: null
      },
      usedContacts: [],
      loadingFinished: false
    }
  },
  computed: {
    lastOfficialControl: {
      get() {
        return this.device.inspection.lastOfficialControl || null
      },
      set(value) {
        const device = this.device
        device.inspection.lastOfficialControl = value
        this.dispatchDeviceUpdate(device)
      }
    },
    nextOfficialControl: {
      get() {
        return this.device.inspection.nextOfficialControl || null
      },
      set(value) {
        const device = this.device
        device.inspection.nextOfficialControl = value
        this.dispatchDeviceUpdate(device)
      }
    },
    globalContact() {
      return this.usedContacts.concat(this.contactsList)
    },
    contact: {
      get() {
        if (this.device.inspection && this.device.inspection.contact) {
          const contact = this.globalContact.find(x => x.id === this.device.inspection.contact.userReference || x.objectID === this.device.inspection.contact.userReference)
          if (contact) {
            return [contact]
          }
          return null
        }
        return null
      },
      set(value) {
        this.usedContacts = this.usedContacts.concat(value)
        this.usedContacts = [...this.removeDuplicatesContact(this.usedContacts)]
        const contact = value.length > 0 ? value[value.length - 1] : {}
        const device = this.device
        device.inspection.contact = {
          userReference: contact.userReference || contact.id || contact.objectID || null,
          accountId: contact.accountId || contact.owner ? contact.owner.id : null
        }
        this.dispatchDeviceUpdate(device)
      }
    },
    subscriptionStart: {
      get() {
        return this.device.inspection.subscriptionStart || null
      },
      set(value) {
        const device = this.device
        device.inspection.subscriptionStart = value
        this.dispatchDeviceUpdate(device)
      }
    },
    subscriptionEnd: {
      get() {
        return this.device.inspection.subscriptionEnd || null
      },
      set(value) {
        const device = this.device
        device.inspection.subscriptionEnd = value
        this.dispatchDeviceUpdate(device)
      }
    },
    contract: {
      get() {
        return this.device.inspection.contract !== '' ? this.device.inspection.contract : null
      },
      set(value) {
        const device = this.device
        device.inspection.contract = value
        this.dispatchDeviceUpdate(device)
      }
    },
    intervalInspection: {
      get() {
        return this.device.inspection.costs.interval
      },
      set(value) {
        const device = this.device
        device.inspection.costs.interval = value
        this.dispatchDeviceUpdate(device)
      }
    },
    note: {
      get() {
        return this.device.inspection.note
      },
      set(value) {
        const newDevice = this.device
        newDevice.inspection.note = value
        this.dispatchDeviceUpdate(newDevice)
      }
    }
  },
  watch: {
    intervalInspection: function (value) {
      if (this.lastOfficialControl && value) {
        if (value === 'UNDEFINED_INTERVAL') {
          this.dates.nextOfficialControl = null
          this.dateToObject()
        } else {
          this.dates.nextOfficialControl = this.addDayToDate(this.dates.lastOfficialControl, value)
          this.dateToObject()
        }
      }
    },
    'dates.lastOfficialControl': function (value) {
      if (this.intervalInspection && this.intervalInspection !== 'UNDEFINED_INTERVAL' && value) {
        this.dates.nextOfficialControl = this.addDayToDate(value, this.intervalInspection)
        this.dateToObject()
      }
    }

  },
  mounted() {
    this.getDatesFromObject()
    this.loadInstantSearchInternal()
    const contactsToLoadAlgolia = this.device.inspection.contact && this.device.inspection.contact.userReference ? [this.device.inspection.contact.userReference] : []
    this.availableContacts(contactsToLoadAlgolia.filter(x => x !== null && x !== ''))
    this.$nextTick(() => {
      this.loadingFinished = true
    })
  },
  methods: {
    addDayToDate(startDate, interval) {
      const date = new Date(startDate)
      if (interval === 'YEARLY') {
        date.setFullYear(date.getFullYear() + 1)
      } else if (interval === 'MONTHLY') {
        date.setMonth(date.getMonth() + 1)
      } else if (interval === 'TRIANNUAL') {
        date.setMonth(date.getMonth() + 4)
      } else if (interval === 'QUARTERLY') {
        date.setMonth(date.getMonth() + 3)
      } else if (interval === 'HALF_YEARLY') {
        date.setMonth(date.getMonth() + 6)
      } else if (interval === 'BI_MONTHLY') {
        date.setMonth(date.getMonth() + 2)
      } else if (interval === 'TWO_YEARLY') {
        date.setFullYear(date.getFullYear() + 2)
      } else if (interval === 'THREE_YEARLY') {
        date.setFullYear(date.getFullYear() + 3)
      } else if (interval === 'FOUR_YEARLY') {
        date.setFullYear(date.getFullYear() + 4)
      } else if (interval === 'FIVE_YEARLY') {
        date.setFullYear(date.getFullYear() + 5)
      } else if (interval === 'TEN_YEARLY') {
        date.setFullYear(date.getFullYear() + 10)
      } else if (interval === 'TWENTY_YEARLY') {
        date.setFullYear(date.getFullYear() + 20)
      } else if (interval === 'EVERY_6_YEARS') {
        date.setFullYear(date.getFullYear() + 6)
      } else if (interval === 'EVERY_8_YEARS') {
        date.setFullYear(date.getFullYear() + 8)
      } else if (interval === 'EVERY_15_YEARS') {
        date.setFullYear(date.getFullYear() + 15)
      }
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
    },
    dispatchDeviceUpdate(newDevice) {
      if (this.loadingFinished) {
        if (this.target === 'building') {
          this.$store.dispatch('onboarding/updateBuildingDevice', newDevice)
        } else {
          this.$store.dispatch('onboarding/updateObjectDevice', newDevice)
        }
        this.$emit('device-update', newDevice)
      }
    },
    deviceUpdated(newDevice) {
      this.$emit('device-update', newDevice)
    },
    getDatesFromObject: function () {
      this.dates.lastOfficialControl = this.objectToIsoDate(this.lastOfficialControl)
      this.dates.nextOfficialControl = this.objectToIsoDate(this.nextOfficialControl)
      this.dates.subscriptionStart = this.objectToIsoDate(this.subscriptionStart)
      this.dates.subscriptionEnd = this.objectToIsoDate(this.subscriptionEnd)
    },
    dateToObject() {
      this.lastOfficialControl = this.toObject(this.dates.lastOfficialControl)
      this.nextOfficialControl = this.toObject(this.dates.nextOfficialControl)
      this.subscriptionStart = this.toObject(this.dates.subscriptionStart)
      this.subscriptionEnd = this.toObject(this.dates.subscriptionEnd)
    },
    isInvalid() {
      this.$v.$touch()
      let valid = false
      const errorArray = []
      if (this.$v.$invalid) {
        if (this.$v.lastOfficialControl.$dirty && this.$v.lastOfficialControl.$error) {
          errorArray.push(this.$t('message.savingErrors.lastOfficialInspection'))
          valid = true
        }
        if (this.$v.nextOfficialControl.$dirty && this.$v.nextOfficialControl.$error) {
          valid = true
        }
        if (this.$v.subscriptionStart.$dirty && this.$v.subscriptionStart.$error) {
          errorArray.push(this.$t('message.savingErrors.subscription'))
          valid = true
        }
        if (new Date(this.objectToIsoDate(this.nextOfficialControl)) < new Date(this.objectToIsoDate(this.lastOfficialControl))) {
          errorArray.push(this.$t('message.savingErrors.nextOfficialInspectionBeforeLast'))
        }
        if (new Date(this.objectToIsoDate(this.nextOfficialControl)) === new Date(this.objectToIsoDate(this.lastOfficialControl))) {
          errorArray.push(this.$t('message.savingErrors.nextOfficialInspectionEqual'))
        }
        if (valid) {
          return [
            true,
            errorArray
          ]
        }
      }
      return [
        this.$refs['device-inspection-costs'].isInvalid() && this.$v.$invalid,
        errorArray
      ]
    },
    isContactObjectExist(contact) {
      return !contact || !this.globalContact.find(c => c.id === contact.value || c.objectID === contact.value)
    },
    getContactObject(contact) {
      return this.globalContact.find(c => c.id === contact.value || c.objectID === contact.value)
    },
    see(refine, value) {
      refine(value)
    },
    availableContacts(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          this.usedContacts = this.usedContacts.concat(contacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  validations: {
    lastOfficialControl: {
      notInFuture
    },
    nextOfficialControl: {
      moreThanStarting,
      notEqualStarting
    },
    subscriptionStart: {
      lessThanEnding
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
