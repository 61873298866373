// @ts-nocheck
import network from './network'

export default {
  getAlgoliaKey(accountId) {
    return network.post('web/search/search/generateApiKey', {
      accountId: accountId
    }).then(response => {
      return response.data
    })
  },
  getAlgoliaUnauthorizedKey() {
    return network.post('web/search/search/generateUnauthorizedApiKey').then(response => {
      return response.data
    })
  },
  loadSettings(accountId) {
    return network.get(`web/search/table/loadSettings?accountId=${accountId}`).then(response => {
      return response.data
    })
  },
  saveSettings(accountId, columns, table) {
    return network.post('web/search/table/saveSettings', {
      accountId: accountId,
      columns: columns,
      table: table
    }).then(response => {
      return response.data
    })
  }
}
