<template>
  <font-awesome-icon
    icon="user-plus"
    :class="[defaultColor ? 'is-default' : '', 'fa']" />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'

library.add(faUserPlus)

export default {
  name: 'CoozzyContactPersonIcon',
  props: {
    defaultColor: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .fa {
    color: $color-text-grey;
    &.is-default{
      color: $color-text-white;
    }
  }
</style>
