import { render, staticRenderFns } from "./CoozzyButton.vue?vue&type=template&id=95d82dae&scoped=true"
import script from "./CoozzyButton.vue?vue&type=script&lang=js"
export * from "./CoozzyButton.vue?vue&type=script&lang=js"
import style0 from "./CoozzyButton.vue?vue&type=style&index=0&id=95d82dae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95d82dae",
  null
  
)

export default component.exports