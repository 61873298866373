<template>
  <section>
    <!-- eslint-disable vue/no-v-html -->
    <p
      v-if="source ==='tenancy'"
      v-html="$t('message.addTenancy.contractTemplateDescription')" />
    <!-- eslint-enable vue/no-v-html -->
    <div class="row mt-2 justify-content-between">
      <div
        v-if="source === 'setting'"
        class="col-12 col-md-2 mb-2">
        <coozzy-dropdown
          design="green"
          size="sm"
          class="w-100"
          :text="selectedEntriesText">
          <coozzy-dropdown-item
            :disabled="selectedEntries.length === 0"
            @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
            {{ $t('message.generic.delete') }}
          </coozzy-dropdown-item>
        </coozzy-dropdown>
      </div>
      <div
        v-if="isFiltered && source === 'setting'"
        class="col-12 offset-md-4 col-md-2">
        <coozzy-button
          class="w-100 sort"
          @click="initFilter()">
          <coozzy-filter-icon /> {{ $t('message.generic.clearFilters') }}
        </coozzy-button>
      </div>
      <div
        v-if="source === 'setting'"
        :class="isFiltered ? '' : 'offset-md-6'"
        class="col-12 col-md-2 mb-2">
        <coozzy-button
          class="w-100"
          @click="showPlaceholdersClicked()">
          {{ $t('message.settings.template.showPlaceholders') }}
        </coozzy-button>
      </div>
      <div
        v-if="source === 'setting'"
        class="col-12 col-md-2 mb-2">
        <coozzy-button
          class="w-100"
          design="prop-green"
          @click="createTemplateContractClicked()">
          {{ $t('message.settings.template.createContractTemplate') }}
        </coozzy-button>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div
        :class="source !== 'objectTenancy' ? 'col-md-2': 'col-md-3'"
        class="col-12 mb-2 mb-md-0">
        <coozzy-form-input
          v-model="searchText"
          :placeholder="$t('message.generic.search')" />
      </div>
      <div
        v-if="!isOwnerModule"
        :class="source !== 'objectTenancy' ? 'col-md-2': 'col-md-3'"
        class="col-12 mb-2 mb-md-0">
        <coozzy-multiselect
          v-model="searchOwner"
          class="filter-inputs"
          :options="availableOwner"
          :multiple="true"
          :placeholder="$t('message.tableColumnHeadings.owner')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :track-by="'value'"
          :custom-label="customLabelOwner"
          :taggable="false" />
      </div>
      <div
        :class="source !== 'objectTenancy' ? 'col-md-2': 'col-md-3'"
        class="col-12 mb-2 mb-md-0">
        <coozzy-multiselect
          v-model="searchCategories"
          class="filter-inputs"
          :options="categoryFilter"
          :multiple="true"
          :placeholder="$t('message.settings.template.placeholderTemplate.category')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :custom-label="customLabelCategory"
          :track-by="'value'"
          :preselect-first="false"
          :taggable="false" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-table
          id="table-contract"
          ref="table-contract"
          hover
          bordered
          responsive="true"
          stacked="md"
          select-mode="single"
          class="mb-4"
          :busy="isBusy || renderContractLoading"
          :fields="fields.filter(x => x.selected)"
          :items="contractTemplateFiltered"
          @row-clicked="editTemplate">
          <!-- Busy state -->
          <div
            slot="table-busy"
            class="text-center text-danger my-2">
            <coozzy-spinner />
          </div>

          <!-- Headings -->
          <template #head(checkbox)>
            <coozzy-form-checkbox
              ref="header_checkbox"
              @change="(value) => { handleCheckboxesInsideTable(value) }" />
          </template>
          <template #head(title)>
            {{ $t('message.tableColumnHeadings.title') }}
          </template>
          <template #head(category)>
            {{ $t('message.settings.template.placeholderTemplate.category') }}
          </template>
          <template #head(language)>
            {{ $t('message.settings.template.placeholderTemplate.language') }}
          </template>
          <template #head(enforcePdf)>
            {{ $t('message.settings.template.placeholderTemplate.enforcePdf') }}
          </template>
          <template #head(creator)>
            {{ $t('message.settings.template.contractTemplateCreator') }}
          </template>
          <template #head(owner)>
            {{ $t('message.tableColumnHeadings.owner') }}
          </template>
          <template #head(date)>
            {{ $t('message.tableColumnHeadings.date') }}
          </template>
          <template #head(firstSignature)>
            {{ $t('message.settings.template.placeholderTemplate.signature.firstSignature') }}
          </template>
          <template #head(secondSignature)>
            {{ $t('message.settings.template.placeholderTemplate.signature.secondSignature') }}
          </template>

          <!-- Body -->
          <template
            #cell(checkbox)="data">
            <coozzy-form-checkbox
              :id="'check_' + data.item.id + '_' + objectId"
              :key="data.item.id"
              :initial="selectedEntries.findIndex(x => x === data.item.id) !== -1"
              @change="(value) => { itemSelected(data.item.id, value) }" />
          </template>
          <template #cell(title)="data">
            {{ data.item.title | displayOptionalValue }}
          </template>
          <template #cell(category)="data">
            {{ $t(`message.settings.template.contractTemplateCategories.${data.item.category}`) }}
          </template>
          <template #cell(language)="data">
            {{ data.item.language }}
          </template>
          <template #cell(enforcePdf)="data">
            {{ data.item.enforcePdf === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}
          </template>
          <template #cell(creator)="data">
            {{ data.item.creatorName }}
          </template>
          <template #cell(owner)="data">
            {{ getOwnerName(data.item.ownerId) }}
          </template>
          <template
            v-if="source === 'setting'"
            #cell(download)="data">
            <span class="float-right">
              <a
                class="ml-1"
                target="_BLANK"
                @click="downloadFile(data.item.mediaId, data.item.title)">
                <coozzy-file-download-icon />
                {{ $t('message.generic.download') }}
              </a>
            </span>
          </template>
          <template #cell(date)="data">
            {{ data.item.updatedAt | timestampToDateTime }}
          </template>
          <template
            #cell(firstSignature)="data">
            <coozzy-form-select
              id="firstSignature"
              :ref="'firstSignature_' + data.item.id"
              v-model="data.item.firstSignature">
              <option value="">
                -
              </option>
              <option
                v-for="employee of employeeList"
                :key="employee.id"
                :value="employee.id">
                {{ employee.profile?.firstName + ' ' + employee.profile?.lastName }}
              </option>
            </coozzy-form-select>
          </template>
          <template
            #cell(secondSignature)="data">
            <coozzy-form-select
              id="secondSignature"
              :ref="'secondSignature_' + data.item.id"
              v-model="data.item.secondSignature">
              <option value="">
                -
              </option>
              <option
                v-for="employee of employeeList"
                :key="employee.id"
                :value="employee.id">
                {{ employee.profile?.firstName + ' ' + employee.profile?.lastName }}
              </option>
            </coozzy-form-select>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.settings.template.confirmationContract.title')">
      <div
        v-if="loadingDeletion"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col p-0">
          <p>{{ $t('message.settings.template.confirmationContract.body') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteTemplateContract()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <template-contract-modal
      v-if="showContractCreationModal"
      ref="modal-contract-creation"
      key="modal-contract-creation"
      :owner-accounts="ownerAccounts"
      :template-to-edit="selectedTemplate"
      @on-creation="loadContracts()"
      @hide-modal="closeContractCreationModal" />
    <contract-placeholders-modal />
  </section>
</template>

<script>
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import MediaApi from '@/misc/apis/MediaApi'
import Vue from 'vue'
import TemplateContractModal from './TemplateContractModal'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon'
import { user } from '@/mixins/user'
import { tables } from '@/mixins/tables'
import ContractPlaceholdersModal from '@/settings/components/ContractPlaceholdersModal'
import UserApi from '@/misc/apis/UserApi'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import ShareApi from '@/misc/apis/ShareApi'
import { saveAs } from 'file-saver'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'

export default {
  name: 'TemplateTable',
  components: {
    CoozzyFormSelect,
    CoozzyFilterIcon,
    CoozzyFormInput,
    ContractPlaceholdersModal,
    CoozzyFormCheckbox,
    CoozzySpinner,
    CoozzyButton,
    CoozzyDropdownItem,
    CoozzyDropdown,
    TemplateContractModal,
    CoozzyFileDownloadIcon,
    CoozzyMultiselect
  },
  mixins: [user, tables, routeChecks],
  props: {
    source: {
      type: String,
      default: ''
    },
    objectId: {
      type: String,
      default: null
    },
    defaultCategory: {
      type: String,
      default: null
    },
    renderContractLoading: {
      type: Boolean,
      default: false
    },
    byOwnersIds: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      isBusy: false,
      selectedEntries: [],
      contractTemplate: [],
      listMedia: [],
      ownerAccounts: [],
      contactList: [],
      searchCategories: [],
      loadingDeletion: false,
      searchOwner: [],
      searchText: '',
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell',
          selected: true
        },
        {
          key: 'title',
          label: this.$t('message.tableColumnHeadings.title'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'category',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'language',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'owner',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'enforcePdf',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'creator',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'date',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'download',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'firstSignature',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'secondSignature',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        }
      ],
      user: null,
      showContractCreationModal: false,
      selectedTemplate: null
    }
  },
  computed: {
    employeeList() {
      const employees = this.$store.getters['employee/getEmployees'].filter(
        emp => !emp.deleted && !emp.blocked
      )
      employees.forEach(employe => {
        if (employe.id === this.user.id) {
          if (employe.profile?.signatureAuthorization === 'SIGNATURE_AUTHORIZATION_SOLE') {
            this.fields.forEach(field => {
              if (field.key === 'firstSignature') {
                field.selected = true
              }
              if (field.key === 'secondSignature') {
                field.selected = false
              }
            })
          } else if (employe.profile?.signatureAuthorization === 'SIGNATURE_AUTHORIZATION_COLLECTIVELY_BY_TWO') {
            this.fields.forEach(field => {
              if (field.key === 'firstSignature' || field.key === 'secondSignature') {
                field.selected = true
              }
            })
          } else {
            this.fields.forEach(field => {
              if (field.key === 'firstSignature' || field.key === 'secondSignature') {
                field.selected = false
              }
            })
          }
        }
      })
      return employees
    },
    isFiltered() {
      return this.searchText !== '' || this.searchCategories.length > 0 || this.searchOwner.length > 0
    },
    selectedEntriesText() {
      return this.$t('message.generic.selectedEntries') + ':  ' + this.selectedEntries.length
    },
    categoryFilter() {
      const counts = []
      const current = this
      current.contractTemplate.map(contact => contact.category).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0) {
          const sameNetwork = current.contractTemplate.map(contact => contact.category).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    availableOwner() {
      const counts = []
      const current = this
      current.contractTemplate.map(contact => contact.ownerId).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0) {
          const sameNetwork = current.contractTemplate.map(contact => contact.ownerId).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    contractTemplateFiltered() {
      let contractTemplate = null
      if (this.byOwnersIds.length > 0) {
        contractTemplate = this.contractTemplate.filter(c => this.byOwnersIds.concat(this.accountId).includes(parseInt(c.ownerId)))
      } else {
        contractTemplate = this.contractTemplate
      }
      contractTemplate = contractTemplate.filter(item => {
        const cdtSearch = item.title.toLowerCase().includes(this.searchText.toLowerCase())
        const cdtCategory = this.searchCategories.map(category => category.value).includes(item.category) || this.searchCategories.length === 0
        const cdtOwner = this.searchOwner.map(owner => owner.value).includes(item.ownerId) || this.searchOwner.length === 0

        return cdtSearch && cdtCategory && cdtOwner
      }).sort((a, b) => {
        // First sort by category
        const translatedCategoryA = this.$t(`message.settings.template.contractTemplateCategories.${a.category}`)
        const translatedCategoryB = this.$t(`message.settings.template.contractTemplateCategories.${b.category}`)
        if (translatedCategoryA < translatedCategoryB) {
          return -1
        }
        if (translatedCategoryA > translatedCategoryB) {
          return 1
        }

        // Then by title
        if (a.title < b.title) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }

        return 0
      })
      const list = contractTemplate
      for (let i = 0; i < list.length; i++) {
        list[i].firstSignature = this.userId
        list[i].secondSignature = ''
      }
      return list
    }
  },
  watch: {
    contractTemplateFiltered: function (newVal) {
      this.selectedEntries.forEach(element => {
        if (newVal.findIndex(x => x.id === element) === -1) {
          this.clearCheckbox()
        }
      })
    }
  },
  mounted() {
    if (this.source === 'setting') {
      this.fields = this.fields.filter(field => field.key !== 'firstSignature' && field.key !== 'secondSignature')
    } else {
      this.fields = this.fields.filter(field => field.key !== 'date')
    }
    if (this.userId) {
      UserApi.getUser(this.userId)
        .then(user => {
          this.user = user
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show('Der Nutzer konnte nicht geladen werden', { type: 'error' })
        })
    }
    ContactApi.getContacts(this.accountId)
      .then(response => {
        const allContacts = response.persons.concat(response.companies)
        this.contactList = allContacts.filter(con => con.types.includes('EMPLOYEE'))
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$tc('message.loadingErrors.contacts'), { type: 'error' })
      })
    if (!this.hasAccessPropBase) {
      this.fields.splice(
        4,
        0,
        {
          key: 'owner',
          label: this.$t('message.tableColumnHeadings.owner'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      )
    }
    this.loadContracts()
  },
  methods: {
    editTemplate(item) {
      if (this.source === 'setting') {
        this.selectedTemplate = item
        this.$nextTick(function () {
          this.createTemplateContractClicked()
        })
      }
    },
    downloadFile(mediaId, fileName) {
      const media = this.getMediaUrl(mediaId)
      const url = media?.url || null
      const extension = media?.id.split('.').pop()
      if (url) {
        saveAs(url, fileName + '.' + extension)
      }
    },
    initFilter() {
      this.searchText = ''
      this.searchCategories = []
      this.searchOwner = []
    },
    getOwnerName(ownerId) {
      if (ownerId !== this.ownerId) {
        const account = this.ownerAccounts.find(account => account.id === ownerId)

        if (!account) {
          return ''
        }

        if (account.company) {
          return account.company.name
        }
        if (account.individual) {
          return account.individual.firstName.substr(0, 1) + '. ' + account.individual.lastName
        }
      } else {
        return this.fullName
      }
    },
    customLabelCategory(item) {
      return this.$t('message.settings.template.contractTemplateCategories.' + item.value) + ' (' + item.count + ')'
    },
    customLabelOwner(item) {
      return this.getOwnerName(item.value) + ' (' + item.count + ')'
    },
    getMediaUrl(id) {
      if (this.listMedia.length > 0) {
        const media = this.listMedia.find(x => x.media.id === id)
        if (media) {
          return this.listMedia.find(x => x.media.id === id).media
        }
      }
      return null
    },
    loadContracts() {
      this.isBusy = true
      this.contractTemplate = []

      // If it's an owner then simply load his contracts
      if (this.hasAccessPropBase) {
        UserApi.getAccount(this.accountId)
          .then(response => {
            this.ownerAccounts = [response.account]
            ObjectApi.getTemplateContract([this.accountId]).then(response => {
              this.contractTemplate = response.templates
              this.$emit('contracts-loaded', this.contractTemplate)
              if (this.defaultCategory) {
                this.setDefaultCategory()
              }
              if (this.source === 'setting') {
                this.loadMedia()
              } else {
                this.isBusy = false
              }
            }).catch(e => {
              console.log(e)
              Vue.toasted.show(this.$tc('message.loadingErrors.template'), { type: 'error' })
            })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$tc('message.loadingErrors.template'), { type: 'error' })
          })
      } else {
        // If it's a network partner then load all owners he works for and then load their contracts
        ShareApi.listByTargetAccountId(this.accountId)
          .then(async response => {
            const accountsToLoad = []
            response.shares.map(share => {
              if (!accountsToLoad.includes(share.sourceAccountId)) {
                accountsToLoad.push(share.sourceAccountId)
              }
              return ''
            })
            accountsToLoad.push(this.accountId)

            // Load the accounts to display owner names
            UserApi.listAccountsByIds(accountsToLoad).then(response => {
              this.ownerAccounts = response.accounts

              ObjectApi.getTemplateContract(this.ownerAccounts.map(a => a.id))
                .then(response => {
                  this.contractTemplate = response.templates
                  this.$emit('contracts-loaded', this.contractTemplate)
                })
                .catch(e => {
                  console.log(e)
                  Vue.toasted.show(this.$tc('message.loadingErrors.template'), { type: 'error' })
                })
                .finally(() => {
                  if (this.defaultCategory) {
                    this.setDefaultCategory()
                  }
                  if (this.source === 'setting') {
                    this.loadMedia()
                  } else {
                    this.isBusy = false
                  }
                })
            })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$tc('message.loadingErrors.template'), { type: 'error' })
          })
      }
    },
    loadMedia() {
      const promises = []
      for (const media of this.contractTemplate) {
        promises.push(MediaApi.getMediaByID(media.mediaId))
      }
      Promise.all(promises)
        .then(values => {
          this.listMedia = values
          this.isBusy = false
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$tc('message.loadingErrors.media'), { type: 'error' })
        })
        .then(() => {
          this.isBusy = false
        })
    },
    openConfirmationModal() {
      this.$refs.confirmationModal.show()
    },
    itemSelected(id, checked) {
      if (checked && !this.selectedEntries.includes(id)) {
        this.selectedEntries.push(id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== id)
      }
      if (this.objectId) {
        this.$emit('selected-contract', this.selectedEntries, this.contractTemplateFiltered, this.objectId)
      } else {
        this.$emit('selected-contract', this.selectedEntries, this.contractTemplateFiltered)
      }
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs['table-contract'].$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
    },
    showPlaceholdersClicked() {
      this.$nextTick(function () {
        this.$bvModal.show('contract-placeholder-modal')
      })
    },
    createTemplateContractClicked() {
      this.showContractCreationModal = true
      this.$nextTick(function () {
        this.$refs['modal-contract-creation'].show()
      })
    },
    closeContractCreationModal() {
      this.selectedTemplate = null
      this.showContractCreationModal = false
    },
    deleteTemplateContract() {
      this.loadingDeletion = true
      ObjectApi.deleteContractTemplate(this.selectedEntries)
        .then(() => {
          this.loadingDeletion = false
          this.selectedEntries = []
          this.handleCheckboxesInsideTable(false)
          this.$refs.header_checkbox.checked = false
          this.$refs.confirmationModal.hide()
          this.loadContracts()
          Vue.toasted.show(this.$tc('message.successMessages.templateContractDeletion'), { type: 'success' })
        })
        .catch(e => {
          this.loadingDeletion = false
          console.log(e)
          Vue.toasted.show(this.$tc('message.deleteErrors.template'), { type: 'error' })
        })
    },
    setDefaultCategory() {
      const count = this.contractTemplate.filter(e => e.category === this.defaultCategory).length
      this.searchCategories.push({ value: this.defaultCategory, count: count })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
