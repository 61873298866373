const componentUtils = {
  data() {
    return {
      typeComponentsProperty: [
        'COMPONENT_TYPE_SURROUNDINGS',
        'COMPONENT_TYPE_PLAYGROUND',
        'COMPONENT_TYPE_PAVED_PATHS',
        'COMPONENT_TYPE_UNDERGROUND_GARAGE'
      ],
      typeComponentsBuilding: [
        'COMPONENT_TYPE_FACADE',
        'COMPONENT_TYPE_THERMAL_INSULATION',
        'COMPONENT_TYPE_EXTERIOR_DOORS',
        'COMPONENT_TYPE_BLINDS',
        'COMPONENT_TYPE_SUNSHADE',
        'COMPONENT_TYPE_ROOF',
        'COMPONENT_TYPE_BALCONY',
        'COMPONENT_TYPE_INTERPHONE',
        'COMPONENT_TYPE_INTERIOR_DOORS',
        'COMPONENT_TYPE_ELEVATOR',
        'COMPONENT_TYPE_STAIRCASE',
        'COMPONENT_TYPE_GENERAL_ROOMS',
        'COMPONENT_TYPE_GENERAL_ELECTRICAL_INSTALLATIONS',
        'COMPONENT_TYPE_ENERGY_STORAGE',
        'COMPONENT_TYPE_HEAT_GENERATION',
        'COMPONENT_TYPE_HEAT_DISTRIBUTION',
        'COMPONENT_TYPE_WATER',
        'COMPONENT_TYPE_OTHERS'
      ],
      typeComponentsObject: [
        'COMPONENT_TYPE_APARTMENT_DOOR',
        'COMPONENT_TYPE_WINDOW',
        'COMPONENT_TYPE_KITCHEN',
        'COMPONENT_TYPE_WETROOM',
        'COMPONENT_TYPE_FLOOR'
      ]
    }
  },
  methods: {
    getBkpComponent(system) {
      switch (system) {
        case 'COMPONENT_TYPE_SURROUNDINGS':
          return '421'
        case 'COMPONENT_TYPE_PLAYGROUND':
          return '423'
        case 'COMPONENT_TYPE_PAVED_PATHS':
          return '421'
        case 'COMPONENT_TYPE_UNDERGROUND_GARAGE':
          return '285'
        case 'COMPONENT_TYPE_FACADE':
          return '226'
        case 'COMPONENT_TYPE_THERMAL_INSULATION':
          return '226'
        case 'COMPONENT_TYPE_EXTERIOR_DOORS':
          return '221'
        case 'COMPONENT_TYPE_BLINDS':
          return '228'
        case 'COMPONENT_TYPE_SUNSHADE':
          return '228'
        case 'COMPONENT_TYPE_ROOF':
          return '222'
        case 'COMPONENT_TYPE_BALCONY':
          return '282'
        case 'COMPONENT_TYPE_INTERPHONE':
          return '282'
        case 'COMPONENT_TYPE_INTERIOR_DOORS':
          return '273'
        case 'COMPONENT_TYPE_ELEVATOR':
          return '261'
        case 'COMPONENT_TYPE_STAIRCASE':
          return '282'
        case 'COMPONENT_TYPE_GENERAL_ROOMS':
          return '277'
        case 'COMPONENT_TYPE_GENERAL_ELECTRICAL_INSTALLATIONS':
          return '230'
        case 'COMPONENT_TYPE_ENERGY_STORAGE':
          return '241'
        case 'COMPONENT_TYPE_HEAT_GENERATION':
          return '242'
        case 'COMPONENT_TYPE_HEAT_DISTRIBUTION':
          return '243'
        case 'COMPONENT_TYPE_WATER':
          return '254'
        case 'COMPONENT_TYPE_OTHERS':
          return ''
        case 'COMPONENT_TYPE_APARTMENT_DOOR':
          return '273'
        case 'COMPONENT_TYPE_WINDOW':
          return '221'
        case 'COMPONENT_TYPE_KITCHEN':
          return '258'
        case 'COMPONENT_TYPE_WETROOM':
          return '251'
        case 'COMPONENT_TYPE_FLOOR':
          return '271/281'
        default:
          return ''
      }
    },
    getLifeSpanComponent(system) {
      switch (system) {
        case 'COMPONENT_TYPE_SURROUNDINGS':
          return 30
        case 'COMPONENT_TYPE_PLAYGROUND':
          return 24
        case 'COMPONENT_TYPE_PAVED_PATHS':
          return 30
        case 'COMPONENT_TYPE_UNDERGROUND_GARAGE':
          return 30
        case 'COMPONENT_TYPE_FACADE':
          return 25
        case 'COMPONENT_TYPE_THERMAL_INSULATION':
          return 25
        case 'COMPONENT_TYPE_EXTERIOR_DOORS':
          return 30
        case 'COMPONENT_TYPE_BLINDS':
          return 25
        case 'COMPONENT_TYPE_SUNSHADE':
          return 25
        case 'COMPONENT_TYPE_ROOF':
          return 30
        case 'COMPONENT_TYPE_BALCONY':
          return 30
        case 'COMPONENT_TYPE_INTERPHONE':
          return 20
        case 'COMPONENT_TYPE_INTERIOR_DOORS':
          return 30
        case 'COMPONENT_TYPE_ELEVATOR':
          return 30
        case 'COMPONENT_TYPE_STAIRCASE':
          return 30
        case 'COMPONENT_TYPE_GENERAL_ROOMS':
          return 20
        case 'COMPONENT_TYPE_GENERAL_ELECTRICAL_INSTALLATIONS':
          return 25
        case 'COMPONENT_TYPE_ENERGY_STORAGE':
          return 50
        case 'COMPONENT_TYPE_HEAT_GENERATION':
          return 25
        case 'COMPONENT_TYPE_HEAT_DISTRIBUTION':
          return 25
        case 'COMPONENT_TYPE_WATER':
          return 25
        case 'COMPONENT_TYPE_OTHERS':
          return 0
        case 'COMPONENT_TYPE_APARTMENT_DOOR':
          return 25
        case 'COMPONENT_TYPE_WINDOW':
          return 25
        case 'COMPONENT_TYPE_KITCHEN':
          return 20
        case 'COMPONENT_TYPE_WETROOM':
          return 25
        case 'COMPONENT_TYPE_FLOOR':
          return 30
        default:
          return 0
      }
    },
    getPercentageComponent(system) {
      switch (system) {
        case 'COMPONENT_TYPE_SURROUNDINGS':
          return 3
        case 'COMPONENT_TYPE_PLAYGROUND':
          return 0
        case 'COMPONENT_TYPE_PAVED_PATHS':
          return 0
        case 'COMPONENT_TYPE_UNDERGROUND_GARAGE':
          return 0
        case 'COMPONENT_TYPE_FACADE':
          return 5
        case 'COMPONENT_TYPE_THERMAL_INSULATION':
          return 3
        case 'COMPONENT_TYPE_EXTERIOR_DOORS':
          return 0
        case 'COMPONENT_TYPE_BLINDS':
          return 0
        case 'COMPONENT_TYPE_SUNSHADE':
          return 0
        case 'COMPONENT_TYPE_ROOF':
          return 4
        case 'COMPONENT_TYPE_BALCONY':
          return 4
        case 'COMPONENT_TYPE_INTERPHONE':
          return 1
        case 'COMPONENT_TYPE_INTERIOR_DOORS':
          return 1
        case 'COMPONENT_TYPE_ELEVATOR':
          return 1
        case 'COMPONENT_TYPE_STAIRCASE':
          return 1
        case 'COMPONENT_TYPE_GENERAL_ROOMS':
          return 1
        case 'COMPONENT_TYPE_GENERAL_ELECTRICAL_INSTALLATIONS':
          return 1
        case 'COMPONENT_TYPE_ENERGY_STORAGE':
          return 1
        case 'COMPONENT_TYPE_HEAT_GENERATION':
          return 1
        case 'COMPONENT_TYPE_HEAT_DISTRIBUTION':
          return 1
        case 'COMPONENT_TYPE_WATER':
          return 1
        case 'COMPONENT_TYPE_OTHERS':
          return 0
        case 'COMPONENT_TYPE_APARTMENT_DOOR':
          return 1
        case 'COMPONENT_TYPE_WINDOW':
          return 1
        case 'COMPONENT_TYPE_KITCHEN':
          return 1
        case 'COMPONENT_TYPE_WETROOM':
          return 1
        case 'COMPONENT_TYPE_FLOOR':
          return 1
        default:
          return 0
      }
    }
  }
}
export { componentUtils }
