<template>
  <div>
    <b-form-tags
      v-model="tagList"
      no-outer-focus
      class="m-0 p-0"
      v-bind="$attrs"
      tag-variant="primary"
      tag-pills
      size="md"
      separator=""
      v-on="mountEventListeners">
      <template
        #default="{ tags, tagVariant, addTag, removeTag, inputAttrs, inputHandlers }">
        <b-form-tag
          v-for="tag in tags"
          :key="tag"
          :title="tag"
          :variant="tagVariant"
          class="tag-class mb-2"
          @remove="removeTag(tag);tagsRemoved(tag)">
          {{ tag }}
        </b-form-tag>
        <b-input-group class="tag-input-row">
          <vue-autosuggest
            :ref="'vueAutoSuggest'"
            v-model="searchQuery"
            v-bind="inputAttrs"
            class="tag-input"
            :suggestions="filteredOptions"
            :input-props="{id: 'autosuggest__input', placeholder: $t('message.contact.addTag')}"
            v-on="inputHandlers"
            @selected="onSuggestionSelected">
            <template slot-scope="{ suggestion }">
              <strong>
                {{ suggestion.item }}
              </strong>
            </template>
          </vue-autosuggest>
          <b-input-group-append
            class="tag-btn">
            <coozzy-button
              size="normal"
              class="light-grey"
              @click="addTag()">
              {{ $t('message.generic.add') }}
            </coozzy-button>
          </b-input-group-append>
        </b-input-group>
      </template>
    </b-form-tags>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'

export default {
  name: 'CoozzyFormTag',
  components: {
    CoozzyButton,
    VueAutosuggest
  },
  props: {
    initial: {
      type: Array,
      default: () => {
        return []
      }
    },
    suggestions: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      tagList: this.initial,
      searchQuery: ''
    }
  },
  computed: {
    mountEventListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: (value) => {
            this.$emit('input', value)
          }
        }
      )
    },
    filteredOptions() {
      return [
        {
          data: this.suggestions.filter(option => {
            return option?.toLowerCase().indexOf(this.searchQuery?.toLowerCase()) > -1
          })
        }
      ]
    }
  },
  watch: {
    tagList: {
      handler(oldVal, newVal) {
        if (newVal.length < oldVal.length) {
          this.searchQuery = ''
        }
      }
    },
    initial: {
      handler(oldVal, newVal) {
        if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
          this.tagList = this.initial
        }
      }
    }
  },
  methods: {
    onSuggestionSelected(suggestion) {
      if (suggestion) {
        const { setChangeItem } = this.$refs.vueAutoSuggest
        setChangeItem(-1, true)
        this.tagList.push(suggestion.item)
        this.searchQuery = ''
        this.$emit('suggestionselected', suggestion.item)
      }
    },
    tagsRemoved(tag) {
      this.$emit('suggestion-removed', tag)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep() {
  .light-grey {
    background: #f0f3f5 !important;
  }
  .b-form-tags{
    border: none;
  }
  .tag-input {
    flex: 1;
    input {
      width: 100%;
      padding: 0.2em 8px;
      height: 38px;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
    }
    input:focus,input:focus-visible{
      border-color: #80bdff;
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
    ul{
      list-style: none;
      display: inline-block;
      padding: 0;
      margin: 0;
      min-width: 100%;
      vertical-align: top;
      li {
        font-size: 16px;
        padding-left: 10px;
      }
      li:hover {
        background-color: $color-primary;
        color: white;
      }
    }
  }
  .tag-input-row{
    display: flex;
  }
  .tag-class {
    font-size: 1rem;
    background-color: $color-primary;
    button {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }
  .tag-btn{
    height: 38px;
  }
}
</style>
