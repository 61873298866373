<template>
  <div>
    <div class="row mt-2 mb-4">
      <div class="col-12 offset-md-8 col-md-2">
        <coozzy-button
          v-if="showAddRoom"
          class="w-100"
          design="prop-green"
          @click="addRoom">
          {{ $t('message.processHandover.rooms.addRoom') }}
        </coozzy-button>
      </div>
      <div class="col-12 col-md-2">
        <coozzy-button
          class="w-100"
          design="prop-green"
          @click="showCreateDeviceModal">
          {{ $t('message.onBoarding.devices.add') }}
        </coozzy-button>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-2">
        <coozzy-form-input
          ref="searchText"
          v-model="searchText"
          :placeholder="$t('message.generic.search')"
          @keyup="searchInputText()" />
      </div>
      <div class="col-12 col-md-2">
        <coozzy-multiselect
          v-model="searchRoom"
          class="filter-inputs"
          :options="roomFilter"
          :multiple="true"
          :placeholder="$t('message.onBoarding.devices.room')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :custom-label="customLabelRoom"
          :track-by="'value'"
          :preselect-first="false"
          :taggable="false"
          @select="filterDevicesList"
          @remove="filterDevicesList" />
      </div>
    </div>
    <b-table
      ref="deviceTable"
      bordered
      hover
      responsive="true"
      stacked="md"
      selectable
      select-mode="single"
      :fields="fields"
      :items="displayedDevices">
      <template #head(title)>
        {{ $t('message.onBoarding.devices.description') }}
      </template>
      <template #head(type)>
        <coozzy-sort-dropdown
          ref="type_sort"
          sorting-field="type"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.type')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(brand)>
        {{ $t('message.onBoarding.devices.brand') }}
      </template>
      <template #head(model)>
        {{ $t('message.onBoarding.devices.model') }}
      </template>
      <template #head(acquisitionCosts)>
        <coozzy-sort-dropdown
          ref="acquisitionCosts_sort"
          sorting-field="acquisitionCosts"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.acquisitionCosts')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(installation)>
        <coozzy-sort-dropdown
          ref="installation_sort"
          sorting-field="installation"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.installation')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(uninstallation)>
        <coozzy-sort-dropdown
          ref="uninstallation_sort"
          sorting-field="uninstallation"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.uninstallation')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(warrantyExpiration)>
        <coozzy-sort-dropdown
          ref="warrantyExpiration_sort"
          sorting-field="warrantyExpiration"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.warrantyExpiration')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(nextMaintenance)>
        <coozzy-sort-dropdown
          ref="nextMaintenance_sort"
          sorting-field="nextMaintenance"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.maintenance.nextControl')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(maintenanceCosts)>
        <coozzy-sort-dropdown
          ref="maintenanceCosts_sort"
          sorting-field="maintenanceCosts"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.maintenance.costs.maintenanceCosts')"
          @updateSorting="updateSorting" />
      </template>
      <!-- Body -->
      <template #cell(checkbox)="data">
        <coozzy-form-checkbox
          :id="'check_' + data.item.id"
          :initial="selectedEntries.some(x => x.id === data.item.id)"
          @change="(value) => { itemSelected(data.item, value) }" />
      </template>
      <template #cell(title)="data">
        <template v-if="data.item.referenceId.startsWith('prop_')">
          {{ $t('message.propertiesView.property') }}
        </template>
        <template v-else-if="data.item.referenceId.startsWith('building_')">
          {{ $t('message.onBoardingTabs.building') + ' ' + getBuildingName(data.item.referenceId) }}
        </template>
        <template v-else-if="data.item.referenceId.startsWith('obj_')">
          {{
            $t('message.onBoarding.buildings.objects.titleObject') + ' ' + getObjectName(data.item.referenceId)
          }}
        </template>
      </template>
      <template #cell(type)="data">
        <template v-if="data.item.type && data.item.type !== 'DEVICE_TYPE_UNDEFINED'">
          {{ $t('message.onBoarding.devices.typeValues.' + data.item.type) }}
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #cell(brand)="data">
        {{ data.item.brand | displayOptionalValue }}
      </template>
      <template #cell(acquisitionCosts)="data">
        {{ data.item.acquisitionCosts | formatPrice }}
      </template>
      <template #cell(model)="data">
        {{ data.item.model | displayOptionalValue }}
      </template>
      <template #cell(installation)="data">
        {{ data.item.installation | objectToDate }}
      </template>
      <template #cell(uninstallation)="data">
        {{ data.item.uninstallation | objectToDate }}
      </template>
      <template #cell(warrantyExpiration)="data">
        {{ data.item.warrantyEnd | objectToDate }}
      </template>
      <template #cell(nextMaintenance)="data">
        {{ data.item.maintenance.nextMaintenance | objectToDate }}
      </template>
      <template #cell(maintenanceCosts)="data">
        {{ data.item.maintenance.costs.amount | formatPrice }}
      </template>
      <template
        #row-details="data"
        class="p-0">
        <div class="col-12 p-0">
          <coozzy-form-textarea
            ref="note"
            :key="data.item.id"
            v-model="data.item.orderDescription"
            :initial="data.item.orderDescription"
            :value-text-area="data.item.orderDescription"
            :show-label="false"
            name="note"
            :rows="5"
            :placeholder="$t('message.createOrder.orderDescription')"
            @blur="noteChanged(data.item.id, data.item.orderDescription)" />
          <div class="col-12 p-0">
            <coozzy-button
              design="transparent"
              class="p-0 mb-2 float-right ml-2 allowClickInputOnTable"
              @click="editDevice(data.item)">
              <coozzy-edit-icon-img
                class="allowClickInputOnTable" />
            </coozzy-button>
          </div>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="displayedDevices.length > 0"
      v-model="currentPage"
      align="center"
      class="mt-3 mb-3"
      :total-rows="listDevices.length"
      :per-page="5" />
    <!--  Add device modals  -->
    <b-modal
      v-if="showCreateDeviceModalBool"
      :id="'createDeviceModalOrderProcess'"
      ref="createDeviceModalOrderProcess"
      :title="$t('message.onBoarding.devices.add')"
      hide-footer
      no-close-on-backdrop>
      <template>
        <div class="col p-0">
          <b-table
            id="table-groups"
            ref="table-groups"
            :fields="fieldsAddDevice"
            :items="listObjects"
            bordered
            class="mb-4"
            hover
            responsive="true"
            stacked="md">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>
            <template #head(category)>
              {{ $t('message.advertisement.objectCategory.name') }}
            </template>
            <template #head(name)>
              {{ $t('message.onBoarding.devices.description') }}
            </template>
            <template #head(tenant)>
              {{ $t('message.tableColumnHeadings.tenant') }}
            </template>
            <template #head(condominium)>
              {{ $t('message.onBoardingTabs.condominium') }}
            </template>
            <!-- Body -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="data.item.id"
                :disabled="selectedEntriesCreateDevice.length > 0 && !selectedEntriesCreateDevice.some(el => el.id === data.item.id)"
                @change="(value) => { itemSelectedCreateDevice(data.item, value) }" />
            </template>
            <template #cell(category)="data">
              <template v-if="data.item.id.startsWith('building')">
                {{ $t('message.generic.building') }}
              </template>
              <template v-else-if="data.item.id.startsWith('prop')">
                {{ $t('message.propertiesView.tabs.property') }}
              </template>
              <template v-else-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(tenant)="data">
              {{ getTenantName(data.item) }}
            </template>
            <template #cell(condominium)="data">
              {{ getCondominiumName(data.item) }}
            </template>
          </b-table>
        </div>
        <div class="col p-0">
          <coozzy-button
            class="mb-0 border"
            design="transparent"
            size="small"
            @click="hideCreateDeviceModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            :disabled="selectedEntriesCreateDevice.length === 0"
            class="float-right mb-0"
            design="green"
            size="small"
            @click="createDeviceClicked()">
            {{ $t('message.generic.add') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-if="showEditDeviceModalBool"
      :id="'editDeviceOrderProcess'"
      ref="editDeviceOrderProcess"
      no-close-on-backdrop
      :title="$t('message.onBoarding.buildings.objects.renovation.editDevice.title')"
      hide-footer>
      <div class="col">
        <device
          ref="object-device"
          :target="deviceTarget"
          :device="deviceToEdit"
          :handover-rooms="filteredRooms"
          :from-handover="true"
          :expand-by-default="true"
          @device-update="deviceUpdated" />
      </div>
      <div class="col mt-2">
        <coozzy-button
          size="small"
          class="float-left mb-0 border"
          design="transparent"
          @click="hideEditDeviceModal">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          class="float-left ml-2 mb-0 border"
          design="red"
          size="small"
          @click="showDeleteDeviceModal">
          {{ $t('message.onBoarding.devices.deleteDevice') }}
        </coozzy-button>
        <coozzy-button
          design="green"
          class="float-right ml-3"
          @click="saveDevice()">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </b-modal>
    <!--  Add room modals  -->
    <b-modal
      v-if="showCreateRoomModalBool"
      :id="'createRoomModalOrderProcess'"
      ref="createRoomModalOrderProcess"
      :title="$t('message.processHandover.rooms.addRoom')"
      hide-footer
      no-close-on-backdrop>
      <template>
        <div class="col p-0">
          <b-table
            id="table-groups"
            ref="table-groups"
            :fields="fieldsAddRoom"
            :items="listObjectsRoom"
            bordered
            class="mb-4"
            hover
            responsive="true"
            stacked="md">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>
            <template #head(category)>
              {{ $t('message.advertisement.objectCategory.name') }}
            </template>
            <template #head(name)>
              {{ $t('message.onBoarding.devices.description') }}
            </template>
            <template #head(tenant)>
              {{ $t('message.tableColumnHeadings.tenant') }}
            </template>
            <template #head(condominium)>
              {{ $t('message.onBoardingTabs.condominium') }}
            </template>
            <!-- Body -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="data.item.id"
                :disabled="selectedEntriesCreateRoom.length > 0 && !selectedEntriesCreateRoom.some(el => el.id === data.item.id)"
                @change="(value) => { itemSelectedCreateRoom(data.item, value) }" />
            </template>
            <template #cell(category)="data">
              <template v-if="data.item.id.startsWith('building')">
                {{ $t('message.generic.building') }}
              </template>
              <template v-else-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(tenant)="data">
              {{ getTenantName(data.item) }}
            </template>
            <template #cell(condominium)="data">
              {{ getCondominiumName(data.item) }}
            </template>
          </b-table>
        </div>
        <div class="col p-0">
          <coozzy-button
            class="mb-0 border"
            design="transparent"
            size="small"
            @click="hideCreateRoomModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            :disabled="selectedEntriesCreateRoom.length === 0"
            class="float-right mb-0"
            design="green"
            size="small"
            @click="createRoomClicked()">
            {{ $t('message.generic.add') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-if="showEditRoomModalBool"
      :id="'editRoomOrderProcess'"
      ref="editRoomOrderProcess"
      no-close-on-backdrop
      :title="$t('message.processHandover.rooms.editRoom')"
      hide-footer>
      <div class="col">
        <room
          :id="`room_${roomToEdit.id || roomToEdit.internalId}`"
          :key="`room-${roomToEdit.id || roomToEdit.internalId}`"
          ref="room"
          :target="roomTarget"
          :read-only="false"
          :room="roomToEdit"
          @room-update="roomUpdated" />
      </div>
      <div class="col mt-2">
        <coozzy-button
          size="small"
          class="float-left mb-0 border"
          design="transparent"
          @click="hideEditRoomModal">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          design="green"
          class="float-right ml-3"
          @click="saveRoom()">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </b-modal>
    <!--  Confirm delete device  -->
    <b-modal
      v-if="showDeleteDeviceModalBool"
      :id="'deleteDeviceModalOrderProcess'"
      ref="deleteDeviceModalOrderProcess"
      :title="$t('message.onBoarding.deleteModals.confirmation')"
      hide-footer
      no-close-on-backdrop>
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodyDevice') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          class="mb-0 border"
          design="transparent"
          size="small"
          @click="hideDeleteDeviceModal">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="deleteDevice">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CoozzySortDropdown from '@/framework/components/dropdown/CoozzySortDropdown'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import { helper } from '@/types/helper'
import Device from '@/building/tabs/components/Device.vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import debounce from 'debounce'
import CoozzyEditIconImg from '@/framework/components/icons/CoozzyEditIconImg.vue'
import Room from '@/building/tabs/components/Room.vue'
import RoomApi from '@/misc/apis/RoomApi'

export default {
  name: 'OrderDeviceTab',
  components: {
    Room,
    CoozzyEditIconImg,
    CoozzyFormInput,
    CoozzyMultiselect,
    Device,
    CoozzySpinner,
    CoozzyButton,
    CoozzyFormCheckbox,
    CoozzyFormTextarea,
    CoozzySortDropdown
  },
  mixins: [dateUtils],
  props: {
    devices: {
      type: Array,
      default: () => []
    },
    objects: {
      type: Array,
      default: () => []
    },
    buildings: {
      type: Array,
      default: () => []
    },
    properties: {
      type: Array,
      default: () => []
    },
    rooms: {
      type: Array,
      default: () => []
    },
    selectedObjects: {
      type: Array,
      default: () => []
    },
    contacts: {
      type: Array,
      default: () => []
    },
    tenancies: {
      type: Array,
      default: () => []
    },
    condoOwners: {
      type: Array,
      default: () => []
    },
    preselectedDevices: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'title',
          label: this.$t('message.onBoarding.devices.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'type',
          label: this.$t('message.onBoarding.buildings.objects.type.title'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'brand',
          label: this.$t('message.onBoarding.devices.brand'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'model',
          label: this.$t('message.onBoarding.devices.model'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'acquisitionCosts',
          label: this.$t('message.onBoarding.devices.acquisitionCosts'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'installation',
          label: this.$t('message.onBoarding.devices.installation'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'uninstallation',
          label: this.$t('message.onBoarding.devices.uninstallation'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'warrantyExpiration',
          label: this.$t('message.onBoarding.devices.warrantyExpiration'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'nextMaintenance',
          label: this.$t('message.onBoarding.devices.maintenance.nextControl'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'maintenanceCosts',
          label: this.$t('message.onBoarding.devices.maintenance.costs.maintenanceCosts'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        }
      ],
      selectedEntries: [],
      fieldsAddDevice: [],
      defaultFieldsAddDevice: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'category',
          label: this.$t('message.advertisement.objectCategory.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: this.$t('message.onBoarding.devices.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'condominium',
          label: this.$t('message.onBoardingTabs.condominium'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      selectedEntriesCreateDevice: [],
      showCreateDeviceModalBool: false,
      showEditDeviceModalBool: false,
      deviceToEdit: null,
      listObjects: [],
      searchRoom: [],
      searchText: '',
      debouncedFilterDevicesList: null,
      listDevices: [],
      showCreateRoomModalBool: false,
      fieldsAddRoom: [],
      listObjectsRoom: [],
      selectedEntriesCreateRoom: [],
      roomToEdit: null,
      showEditRoomModalBool: false,
      showDeleteDeviceModalBool: false
    }
  },
  computed: {
    showAddRoom() {
      return this.selectedObjects.length > 0 ? !this.selectedObjects[0].id.startsWith('prop_') : this.source !== 'property'
    },
    deviceTarget() {
      const id = this.selectedEntriesCreateDevice.length > 0 ? this.selectedEntriesCreateDevice[0].id : this.selectedObjects.length > 0 ? this.selectedObjects[0].id : this.deviceToEdit ? this.deviceToEdit.referenceId : ''
      return id.startsWith('obj_') ? 'object' : id.startsWith('building_') ? 'building' : 'property'
    },
    roomTarget() {
      return this.selectedEntriesCreateRoom.length > 0 && this.selectedEntriesCreateRoom[0].id.startsWith('obj_') ? 'object' : 'building'
    },
    displayedDevices() {
      let list = this.listDevices
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          list[i]._showDetails = true
          const l = this.devicesForApi && this.devicesForApi.length > 0 ? this.devicesForApi.find(x => x.id === list[i].id).note : null
          if (l) {
            list[i].orderDescription = l
          }
        }
      }
      list = list.slice((this.currentPage - 1) * 5, this.currentPage * 5)
      return list
    },
    roomFilter() {
      const counts = []
      const current = this
      if (current.devices.length === 0) {
        return counts
      }
      current.devices.map(obj => current.rooms.find(r => obj?.roomId === r.id)).forEach(function(x) {
        if (x !== -1 && x !== undefined && x !== 'undefined') {
          const existingCount = counts.filter(e => e.value === x.name)
          if (existingCount.length === 0) {
            const sameNetwork = current.devices.map(obj => current.rooms.find(r => obj.roomId === r.id)).filter(z => z && z.name === x.name)
            if (x !== -1) {
              if (existingCount.length === 0) {
                counts.push({
                  count: sameNetwork.length,
                  value: x.name
                })
              } else {
                counts.push({
                  count: 1,
                  value: x.name
                })
              }
            }
          }
        }
      })
      return counts
    },
    filteredRooms() {
      return this.rooms.filter(room => this.deviceToEdit && this.deviceToEdit.referenceId === room.referenceId) || []
    }
  },
  watch: {
    properties: {
      handler() {
        this.selectionChanged()
      },
      deep: true
    },
    buildings: {
      handler() {
        this.selectionChanged()
      },
      deep: true
    },
    objects: {
      handler() {
        this.selectionChanged()
      },
      deep: true
    },
    selectedObjects: {
      handler() {
        this.selectionChanged()
      },
      deep: true
    },
    devices: {
      handler() {
        this.listDevices = this.devices
      },
      deep: true
    }
  },
  created() {
    // Initialize the debounced function in the created lifecycle hook
    this.debouncedFilterDevicesList = debounce(() => {
      this.filterDevicesList()
    }, 300)
  },
  mounted() {
    this.selectionChanged()
    this.filterDevicesList()
    this.$nextTick(() => {
      if (this.preselectedDevices !== '') {
        const preselectedDevices = this.preselectedDevices.split(',')
        preselectedDevices.forEach(deviceId => {
          const device = this.devices.find(x => x.id === deviceId)
          if (device) {
            this.itemSelected(device, true)
          }
        })
      }
    })
  },
  methods: {
    addRoom() {
      if (this.condoOwners.length > 0) {
        this.fieldsAddRoom = this.defaultFieldsAddDevice.filter(x => x.key !== 'tenant')
      } else {
        this.fieldsAddRoom = this.defaultFieldsAddDevice.filter(x => x.key !== 'condominium')
      }
      this.showCreateRoomModalBool = true
      this.$nextTick(() => {
        this.$bvModal.show('createRoomModalOrderProcess')
      })
    },
    editDevice(device) {
      this.deviceToEdit = device
      this.showEditDeviceModalBool = true
      this.$nextTick(() => {
        this.$bvModal.show('editDeviceOrderProcess')
      })
    },
    getTenantName(item) {
      if (item.id.startsWith('obj_')) {
        const currentTenancy = this.getCurrentTenancy(item.id) ? this.getCurrentTenancy(item.id) : null
        if (currentTenancy && currentTenancy.vacancy === true) {
          return this.$t('message.onBoarding.buildings.objects.tenancies.vacancy')
        } else if (currentTenancy && currentTenancy.vacancy === false && currentTenancy.tenant.contactIds.length > 0) {
          let names = ''
          currentTenancy.tenant.contactIds.forEach(element => {
            names += this.getContactName(element)
          })
          return names !== '' ? names.substring(1) : ''
        }
        return '-'
      }
      return '-'
    },
    getCondominiumName(item) {
      if (item.id.startsWith('obj_')) {
        const currentCondo = this.getCurrentCondominium(item.id) ? this.getCurrentCondominium(item.id) : null
        if (currentCondo && currentCondo.owner.contactIds.length > 0) {
          let names = ''
          currentCondo.owner.contactIds.forEach(element => {
            names += this.getContactName(element)
          })
          return names !== '' ? names.substring(1) : ''
        }
        return '-'
      }
      return '-'
    },
    getCurrentCondominium(objectId) {
      return this.getFilteredCondominiums(objectId).find((t) => t.status === 'CONDOMINIUM_STATUS_CURRENT' || t.status === 'CONDOMINIUM_STATUS_CURRENT_TERMINATED')
    },
    getFilteredCondominiums(objectId) {
      return this.condoOwners.filter((ten) => {
        return ten.objectId === objectId
      })
    },
    getCurrentTenancy(objectId) {
      return this.getFilteredTenancies(objectId).find((t) => t.status === 'TENANCY_STATUS_CURRENT' || t.status === 'TENANCY_STATUS_CURRENT_TERMINATED')
    },
    getFilteredTenancies(objectId) {
      return this.tenancies.filter((ten) => {
        return ten.objectId === objectId
      })
    },
    getContactName(contactId) {
      const contact = this.contacts.find(function (contact) {
        return contact.id === contactId
      })
      return contact ? ', ' + contact.name : '-'
    },
    customLabelRoom(item) {
      if (!item) {
        return ''
      }
      return item.value + ' (' + item.count + ')'
    },
    filterDevicesList() {
      this.$nextTick(function () {
        this.listDevices = this.devices.filter(item => {
          const typeMapped = this.$t('message.onBoarding.devices.typeValues.' + item.type)
          const brandSearch = item.brand ? item.brand.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const modelSearch = item.model ? item.model.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const systemSearch = item.system ? item.system.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const cdtSearch = typeMapped ? typeMapped.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const roomDeviceMapped = this.searchRoom.map(x => x.value)
          let cdtRoom
          if (item.referenceId.startsWith('obj_') || item.referenceId.startsWith('building_')) {
            cdtRoom = roomDeviceMapped.some(r => this.getRoomeName(item.roomId) === r) || this.searchRoom.length === 0
            return (cdtSearch || brandSearch || modelSearch || systemSearch) && cdtRoom
          }

          return cdtSearch || brandSearch || modelSearch || systemSearch
        })
      })
    },
    getRoomeName(roomId) {
      const rooms = this.rooms.find(x => x.id === roomId)
      if (rooms) {
        return rooms.name
      } else {
        return '-'
      }
    },
    searchInputText() {
      this.debouncedFilterDevicesList()
    },
    selectionChanged() {
      const list = this.properties.concat(this.buildings).concat(this.objects)
      this.listObjects = list.filter(item => this.selectedObjects.length === 0 || this.selectedObjects.some(x => x.id === item.id))
      // list objects without properties
      const list2 = this.buildings.concat(this.objects)
      this.listObjectsRoom = list2.filter(item => this.selectedObjects.length === 0 || this.selectedObjects.some(x => x.id === item.id))
    },
    showCreateDeviceModal() {
      if (this.selectedObjects.length === 1) {
        this.selectedEntriesCreateDevice = this.selectedObjects
        this.createDeviceClicked()
      } else {
        if (this.condoOwners.length > 0) {
          this.fieldsAddDevice = this.defaultFieldsAddDevice.filter(x => x.key !== 'tenant')
        } else {
          this.fieldsAddDevice = this.defaultFieldsAddDevice.filter(x => x.key !== 'condominium')
        }
        this.showCreateDeviceModalBool = true
        this.$nextTick(() => {
          this.$bvModal.show('createDeviceModalOrderProcess')
        })
      }
    },
    showDeleteDeviceModal() {
      this.showDeleteDeviceModalBool = true
      this.$nextTick(() => {
        this.$bvModal.show('deleteDeviceModalOrderProcess')
      })
    },
    hideDeleteDeviceModal() {
      this.$bvModal.show('deleteDeviceModalOrderProcess')
      this.showDeleteDeviceModalBool = false
    },
    hideCreateDeviceModal() {
      this.$bvModal.hide('createDeviceModalOrderProcess')
      this.showCreateDeviceModalBool = false
      this.selectedEntriesCreateDevice = []
    },
    hideCreateRoomModal() {
      this.$bvModal.hide('createRoomModalOrderProcess')
      this.showCreateRoomModalBool = false
      this.selectedEntriesCreateRoom = []
    },
    deleteDevice() {
      this.$emit('device-deleted', this.deviceToEdit)
      this.hideDeleteDeviceModal()
      this.hideEditDeviceModal()
    },
    createRoomClicked() {
      const ref = this.selectedEntriesCreateRoom[0]
      this.roomToEdit = JSON.parse(JSON.stringify(helper.emptyRoom))
      this.roomToEdit.internalId = Date.now() + Math.floor(Math.random() * 1000)
      this.roomToEdit.ownerId = ref.owner ? ref.owner.id : ref.ownerId
      this.roomToEdit.referenceId = ref.id
      this.selectedEntriesCreateRoom = []
      this.hideCreateRoomModal()
      this.showEditRoomModalBool = true
      this.$nextTick(() => {
        this.$bvModal.show('editRoomOrderProcess')
      })
    },
    createDeviceClicked() {
      const ref = this.selectedEntriesCreateDevice[0]
      this.deviceToEdit = JSON.parse(JSON.stringify(helper.emptyDevice))
      this.deviceToEdit.internalId = Date.now() + Math.floor(Math.random() * 1000)
      this.deviceToEdit.ownerId = ref.owner ? ref.owner.id : ref.ownerId
      this.deviceToEdit.referenceId = ref.id
      this.selectedEntriesCreateDevice = []
      this.hideCreateDeviceModal()
      this.showEditDeviceModalBool = true
      this.$nextTick(() => {
        this.$bvModal.show('editDeviceOrderProcess')
      })
    },
    hideEditDeviceModal() {
      this.$bvModal.hide('editDeviceOrderProcess')
      this.showEditDeviceModalBool = false
      this.deviceToEdit = null
    },
    hideEditRoomModal() {
      this.$bvModal.hide('editRoomOrderProcess')
      this.showEditRoomModalBool = false
      this.roomToEdit = null
    },
    deviceUpdated(device) {
      this.deviceToEdit = device
    },
    roomUpdated(device) {
      this.roomToEdit = device
    },
    async saveRoom() {
      let response = null
      if (this.roomToEdit.id !== '') {
        response = await RoomApi.updateRooms([this.roomToEdit])
      } else {
        response = await RoomApi.createRooms([this.roomToEdit])
      }
      this.$emit('room-added', response.rooms[0])
      this.hideEditRoomModal()
    },
    async saveDevice() {
      let addDevice = false
      let response = null
      if (this.deviceToEdit.id) {
        response = await ObjectApi.updateDevice(this.deviceToEdit)
      } else {
        addDevice = true
        response = await ObjectApi.createDevice(this.deviceToEdit)
      }
      this.$emit('device-added', response.device)
      this.hideEditDeviceModal()
      this.$nextTick(() => {
        if (addDevice) {
          this.itemSelected(response.device, true)
        }
      })
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== item.id)
      }
      this.$emit('device-selection-updated', this.selectedEntries)
    },
    itemSelectedCreateDevice(item, checked) {
      if (checked) {
        this.selectedEntriesCreateDevice.push(item)
      } else {
        this.selectedEntriesCreateDevice = this.selectedEntriesCreateDevice.filter(entry => entry.id !== item.id)
      }
    },
    itemSelectedCreateRoom(item, checked) {
      if (checked) {
        this.selectedEntriesCreateRoom.push(item)
      } else {
        this.selectedEntriesCreateRoom = this.selectedEntriesCreateRoom.filter(entry => entry.id !== item.id)
      }
    },
    getObjectName(objectId) {
      const obj = this.objects.find(e => e.id === objectId)
      return obj ? obj.name : ''
    },
    getBuildingName(buildingId) {
      const building = this.buildings.find(e => e.id === buildingId)
      return building ? building.name : ''
    },
    noteChanged(id, note) {
      this.$emit('note-changed', id, note)
    },
    updateSorting(value, field) {
      if (field !== 'acquisitionCosts') {
        this.$refs.acquisitionCosts_sort.direction = ''
      }
      if (field !== 'installation') {
        this.$refs.installation_sort.direction = ''
      }
      if (field !== 'uninstallation') {
        this.$refs.uninstallation_sort.direction = ''
      }
      if (field !== 'warrantyExpiration') {
        this.$refs.warrantyExpiration_sort.direction = ''
      }
      if (field !== 'nextMaintenance') {
        this.$refs.nextMaintenance_sort.direction = ''
      }
      if (field !== 'maintenanceCosts') {
        this.$refs.maintenanceCosts_sort.direction = ''
      }
      if (field !== 'type') {
        this.$refs.type_sort.direction = ''
      }
      this.sortValue = value
      this.sortField = field
      this.$nextTick(function () {
        if (this.sortValue !== '' && this.sortField !== '') {
          this.listDevices.sort(this.filterDevices(this.sortField, this.sortValue))
        }
      })
    },
    filterDevices(key, order = 'asc') {
      const current = this
      return function innerSort(a, b) {
        let varA
        let varB
        if (key === 'installation') {
          varA = current.dateObjectToTimeStamp(a[key])
          varB = current.dateObjectToTimeStamp(b[key])
        } else if (key === 'uninstallation') {
          varA = current.dateObjectToTimeStamp(a[key])
          varB = current.dateObjectToTimeStamp(b[key])
        } else if (key === 'warrantyExpiration') {
          varA = current.dateObjectToTimeStamp(a.warrantyEnd)
          varB = current.dateObjectToTimeStamp(b.warrantyEnd)
        } else if (key === 'nextMaintenance') {
          varA = current.dateObjectToTimeStamp(a.maintenance.nextMaintenance)
          varB = current.dateObjectToTimeStamp(b.maintenance.nextMaintenance)
        } else if (key === 'maintenanceCosts') {
          varA = a.maintenance.costs.amount
          varB = b.maintenance.costs.amount
        } else if (key === 'type') {
          varA = current.$t('message.onBoarding.devices.typeValues.' + a[key])
          varB = current.$t('message.onBoarding.devices.typeValues.' + b[key])
        } else {
          varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase()
: a[key]
          varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase()
: b[key]
        }
        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep #editDeviceOrderProcess .modal-dialog, ::v-deep #editRoomOrderProcess .modal-dialog {
  max-width: 80% !important;
}
</style>
