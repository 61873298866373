import { routeChecks } from '@/mixins/routeChecks'

const contact = {
  mixins: [routeChecks],
  data() {
    return {
      industriesList: [
        'INDUSTRY_LIVING_FURNISHING_HOUSEHOLD_APPLIANCES',
        'INDUSTRY_LIVING_FURNISHING_MAINTENANCE',
        'INDUSTRY_LIVING_FURNISHING_REAL_ESTATE',
        'INDUSTRY_LIVING_FURNISHING_INTERIOR_DESIGN',
        'INDUSTRY_LIVING_FURNISHING_INTERIOR_DECORATION',
        'INDUSTRY_LIVING_FURNISHING_KITCHEN_FACILITIES',
        'INDUSTRY_LIVING_FURNISHING_REAL_ESTATE_TRADING',
        'INDUSTRY_LIVING_FURNISHING_REAL_ESTATE_MANAGEMENT',
        'INDUSTRY_LIVING_FURNISHING_FURNITURE',
        'INDUSTRY_LIVING_FURNISHING_CLEANING',
        'INDUSTRY_LIVING_FURNISHING_KEY_SERVICE',
        'INDUSTRY_LIVING_FURNISHING_BLINDS_AND_SHUTTERS',
        'INDUSTRY_LIVING_FURNISHING_CARPETS',
        'INDUSTRY_LIVING_FURNISHING_MOVING',
        'INDUSTRY_LIVING_FURNISHING_CURTAINS',
        'INDUSTRY_LIVING_FURNISHING_OTHER',
        'INDUSTRY_BUILD_RENOVATE_ARCHITECTURE',
        'INDUSTRY_BUILD_RENOVATE_CONSTRUCTION',
        'INDUSTRY_BUILD_RENOVATE_ROOFING',
        'INDUSTRY_BUILD_RENOVATE_FLOORING_WALL_COVERINGS',
        'INDUSTRY_BUILD_RENOVATE_PLASTERER',
        'INDUSTRY_BUILD_RENOVATE_HEATING',
        'INDUSTRY_BUILD_RENOVATE_WOOD_CONSTRUCTION',
        'INDUSTRY_BUILD_RENOVATE_ENGINEERING',
        'INDUSTRY_BUILD_RENOVATE_PAINTER',
        'INDUSTRY_BUILD_RENOVATE_METAL_CONSTRUCTION',
        'INDUSTRY_BUILD_RENOVATE_SANITARY',
        'INDUSTRY_BUILD_RENOVATE_LOCKSMITH',
        'INDUSTRY_BUILD_RENOVATE_JOINERY',
        'INDUSTRY_BUILD_RENOVATE_PLUMBING',
        'INDUSTRY_BUILD_RENOVATE_CARPENTRY',
        'INDUSTRY_BUILD_RENOVATE_ELECTRICIAN',
        'INDUSTRY_BUILD_RENOVATE_CRAFTSMAN',
        'INDUSTRY_BUILD_RENOVATE_OTHER',
        'INDUSTRY_BUILD_GLAZIER',
        'INDUSTRY_BUILD_SCAFFOLDER',
        'INDUSTRY_FINANCIAL_LAW_ADVOCACY',
        'INDUSTRY_FINANCIAL_LAW_BANK',
        'INDUSTRY_FINANCIAL_LAW_ACCOUNTING',
        'INDUSTRY_FINANCIAL_LAW_FINANCIAL_CONSULTING',
        'INDUSTRY_FINANCIAL_LAW_NOTARY',
        'INDUSTRY_FINANCIAL_LAW_LAWYER',
        'INDUSTRY_FINANCIAL_LAW_TAX_CONSULTING',
        'INDUSTRY_FINANCIAL_LAW_TRUSTEESHIP',
        'INDUSTRY_FINANCIAL_LAW_ASSET_MANAGEMENT',
        'INDUSTRY_FINANCIAL_LAW_INSURANCE',
        'INDUSTRY_FINANCIAL_LAW_OTHER',
        'INDUSTRY_GARDEN_PLANTS_FLORIST',
        'INDUSTRY_GARDEN_PLANTS_HORTICULTURE',
        'INDUSTRY_GARDEN_PLANTS_GARDEN_MAINTENANCE',
        'INDUSTRY_GARDEN_PLANTS_NURSERY',
        'INDUSTRY_GARDEN_PLANTS_DUMPSTER_SERVICE',
        'INDUSTRY_GARDEN_PLANTS_OTHER',
        'INDUSTRY_OTHER_METER_READING',
        'INDUSTRY_OTHER_FACILITY_MANAGEMENT',
        'INDUSTRY_OTHER_MAINTENANCE_INSTALLATION',
        'INDUSTRY_OTHER_BUILDING_SERVICES_ENGINEERING',
        'INDUSTRY_OTHER_CLEANING',
        'INDUSTRY_OTHER_WHITE_GOODS',
        'INDUSTRY_OTHER_OTHER',
        'INDUSTRY_OTHER_FIRE_PROTECTION',
        'INDUSTRY_BUILD_RENOVATE_FACADES',
        'INDUSTRY_OTHER_ELECTRICITY_PLANT',
        'INDUSTRY_OTHER_WATERWORKS',
        'INDUSTRY_OTHER_SWEEPINGS',
        'INDUSTRY_OTHER_ANTENNA_SYSTEM',
        'INDUSTRY_OTHER_PEST_CONTROL',
        'INDUSTRY_FINANCIAL_LAW_PUBLIC_SECTOR',
        'INDUSTRY_BUILD_RENOVATE_DISTRICT_HEATING',
        'INDUSTRY_BUILD_RENOVATE_AIR_CONDITIONING',
        'INDUSTRY_BUILD_RENOVATE_GARAGE_GATES',
        'INDUSTRY_BUILD_RENOVATE_MOLD_CONTROL',
        'INDUSTRY_OTHER_MARKINGS',
        'INDUSTRY_BUILD_RENOVATE_JOINTS',
        'INDUSTRY_OTHER_OIL',
        'INDUSTRY_BUILD_RENOVATE_LIFT_SERVICE',
        'INDUSTRY_OTHER_WATER_TREATMENT',
        'INDUSTRY_OTHER_ADVERTISING',
        'INDUSTRY_OTHER_GROCERIES',
        'INDUSTRY_OTHER_CAR_DEALERSHIP',
        'INDUSTRY_OTHER_STATIONERY_SHOP',
        'INDUSTRY_OTHER_CHIMNEY_SWEEPER',
        'INDUSTRY_OTHER_ENGRAVING',
        'INDUSTRY_BUILD_MASONRY'
      ]
    }
  },
  methods: {
    getContactDetailRoute(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return {
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isMarketingModule) {
          return {
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          }
        }
      }
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isMarketingModule) {
          this.$router.push({
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          })
        }
      }
    },
    translatedIndustriesList(fullNames = true) {
      const array = []
      this.industriesList.forEach(industry => {
        let text = this.$t('message.contact.industries.' + industry)
        if (fullNames === false) {
          text = text.substr(text.indexOf(' - ') + 3)
        }

        array.push({
          label: text,
          value: industry
        })
      })
      array.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
        }
        return 0
      })
      return array
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    },
    getPhoneNumbers(phoneNumbers, type) {
      if (phoneNumbers && phoneNumbers.length > 0) {
        if (phoneNumbers.find(element => element.type?.toLowerCase() === type?.toLowerCase())) {
          return phoneNumbers.find(element => element.type.toLowerCase() === type.toLowerCase()).number
        }
      }
      return '-'
    },
    getTranslatedIndustries(contact, fullNames = true) {
      if (!contact.industries ||
        contact.industries.length === 0 ||
        (contact.industries && contact.industries.length === 1 && contact.industries[0] === 'INDUSTRY_UNDEFINED_VALUE')) {
        return '-'
      } else {
        const array = []
        contact.industries.forEach((industry) => {
          if (industry !== 'INDUSTRY_UNDEFINED_VALUE') {
            if (fullNames) {
              array.push(this.$t('message.contact.industries.' + industry))
            } else {
              const text = this.$t('message.contact.industries.' + industry)
              array.push(text.substr(text.indexOf(' - ') + 3))
            }
          }
        })
        return array.join(', ')
      }
    },
    renderSalutationTooltip(contactType = 'private', salutation = '') {
      if (!salutation) salutation = contactType ? this.$t('message.contact.salutation.defaultSalutationIndividual') : this.$t('message.contact.salutation.defaultSalutationCompany')
      const prefix = this.$t('message.contact.salutation.salutationTooltip1')
      const postfix = this.$t('message.contact.salutation.salutationTooltip2')
      return prefix + salutation + postfix
    },
    renderSalutationPlaceholder(contactType = 'private', salutation = '', addExampleStringToPlaceholder) {
      if (!salutation) salutation = contactType ? this.$t('message.contact.salutation.defaultSalutationIndividual') : this.$t('message.contact.salutation.defaultSalutationCompany')
      let example = ''
      if (addExampleStringToPlaceholder) {
        example = this.$t('message.contact.salutation.example')
      }
      return example + salutation
    }
  }
}

export { contact }
