<template>
  <b-spinner
    :small="small"
    variant="grey" />
</template>

<script>
export default {
  name: 'CoozzySpinner',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
