<template>
  <coozzy-card
    :title="$t('message.portalTab.title')"
    class="global-card no-top-border table-card">
    <p>
      {{ $t('message.portalTab.subTitle') }}
    </p>
    <div
      v-if="loading"
      class="row">
      <div class="col">
        <coozzy-spinner />
      </div>
    </div>
    <template v-else>
      <template
        v-for="(item, index) in listLinks">
        <div
          :key="item.id || item.internalId"
          class="row">
          <div class="col-sm-12 col-md-3">
            <label for="fontFamily">{{ $t('message.portalTab.description') }}</label>
            <coozzy-form-input
              ref="itemDescription"
              v-model="item.name"
              :is-read-only="readOnly"
              :placeholder="$t('message.portalTab.description')"
              @keyup="itemChanged(item)" />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="fontSize">{{ $t('message.portalTab.link') }}</label>
            <coozzy-form-input
              ref="itemLink"
              v-model="item.url"
              :is-read-only="readOnly"
              :placeholder="$t('message.portalTab.link')"
              :state="($v.listLinks.$each[index].url.$dirty && $v.listLinks.$each[index].url.$error) ? false : null"
              @keyup="itemChanged(item)" />
          </div>
          <div
            v-if="!readOnly"
            class="col align-content-end">
            <coozzy-button
              class="m-0"
              design="red"
              @click="deleteLink(item)">
              {{ $t('message.portalTab.deleteLink') }}
            </coozzy-button>
          </div>
        </div>
      </template>
      <div
        v-if="!readOnly"
        class="row">
        <div class="col-6 col-md-2">
          <coozzy-button
            class="mt-3 w-100"
            design="prop-green"
            @click="addLink">
            {{ $t('message.portalTab.addLink') }}
          </coozzy-button>
        </div>
      </div>
    </template>
  </coozzy-card>
</template>

<script lang="ts">
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import { user } from '@/mixins/user'
import Vue from 'vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import { PortalLink } from '@/types/api'
import { onboarding } from '@/mixins/onboarding'
import { required } from 'vuelidate/lib/validators'

interface linkEntry extends PortalLink {
  internalId: number
}

function isValidURL(url) {
  const urlPattern = new RegExp(
    '((([a-zA-Z0-9-_]+\\.)+[a-zA-Z]{2,})|' + // Domain name
    'localhost|' + // Localhost
    '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IPv4
    '\\[([a-fA-F0-9:]+)\\])' + // IPv6
    '(\\:\\d+)?' + // Port
    '(\\/[-a-zA-Z0-9%_.~+]*)*' + // Path
    '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // Query string
    '(\\#[-a-zA-Z0-9%_.~+=]*)?$', // Fragment
    'i' // Case insensitive
  )
  return urlPattern.test(url)
}

export default Vue.extend({
  name: 'BuildingPortalLinksTab',
  components: {
    CoozzyFormInput,
    CoozzySpinner,
    CoozzyButton,
    CoozzyCard
  },
  mixins: [user, onboarding],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false as boolean,
      listLinks: [] as linkEntry[],
      deletedLinks: [] as string[]
    }
  },
  computed: {
  },
  watch: {
    portalLinks: {
      handler(newVal) {
        if (newVal) {
          this.listLinks = newVal
        }
      },
      deep: true
    }
  },
  mounted() {
    this.listLinks = this.portalLinks
  },
  methods: {
    deleteLink(item) {
      this.removePortalLink(item)
    },
    addLink() {
      if (this.building) {
        this.addPortalLink(this.building.id)
      }
    },
    itemChanged(item) {
      this.updatePortalLink(item)
    },
    isInvalid() {
      this.$v.$touch()
      return this.$v.$invalid
    }
  },
  validations() {
    return {
      listLinks: {
        $each: {
          url: {
            required,
            isValidURL
          }
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
