import { routeChecks } from '@/mixins/routeChecks'
import { mapMutations } from 'vuex'
import CalendarApi from '@/misc/apis/CalendarApi'
import { user } from '@/mixins/user'

const reminder = {
  mixins: [routeChecks, user],
  computed: {
    countReminders() {
      return this.$store.getters['reminder/getCountReminders']
    }
  },
  mounted: function () {
    this.getCountOverdueReminders()
  },
  methods: {
    ...mapMutations('reminder', ['setCountReminders']),
    getReferenceDetailPageRoute(item) {
      if (item) {
        switch (item.entity.split('_')[0]) {
          case 'prop':
            return {
              name: this.isOwnerModule ? 'OwnerPropertyDetailsView' : this.isAssetModule ? 'AssetPropertyDetailsView' : this.isAccountingModule ? 'AccountingPropertyDetailsView' : 'AdminPropertyDetailsView',
              params: { id: item.value.id }
            }
          case 'building':
            return {
              name: this.isOwnerModule ? 'OwnerBuildingDetailsView' : this.isAssetModule ? 'AssetBuildingDetailsView' : this.isAccountingModule ? 'AccountingBuildingDetailsView' : 'AdminBuildingDetailsView',
              params: { id: item.value.id }
            }
          case 'obj':
            if (this.isMarketingModule) {
              return {
                name: 'MarketingObjectDetailsView',
                params: { id: item.value.id }
              }
            } else {
              return {
                name: this.isOwnerModule ? 'OwnerBuildingDetailsView' : this.isAssetModule ? 'AssetBuildingDetailsView' : this.isAccountingModule ? 'AccountingBuildingDetailsView' : 'AdminBuildingDetailsView',
                params: { id: item.value.buildingId },
                query: {
                  view: 'objects',
                  elementSelected: item.value.id
                }
              }
            }
          case 'tenancy':
            if (this.isOwnerModule) {
              return {
                name: 'OwnerBuildingDetailsView',
                params: { id: item.value.object.buildingId },
                query: {
                  view: 'tenants',
                  elementSelected: item.entity
                }
              }
            } else if (this.isAdminModule) {
              return {
                name: 'AdminBuildingDetailsView',
                params: { id: item.value.object.buildingId },
                query: {
                  view: 'tenants',
                  elementSelected: item.entity
                }
              }
            } else if (this.isAssetModule) {
              return {
                name: 'AssetBuildingDetailsView',
                params: { id: item.value.object.buildingId },
                query: {
                  view: 'tenants',
                  elementSelected: item.entity
                }
              }
            } else if (this.isAccountingModule) {
              return {
                name: 'AccountingBuildingDetailsView',
                params: { id: item.value.object.buildingId },
                query: {
                  view: 'tenants',
                  elementSelected: item.entity
                }
              }
            }
            break
          case 'condominium':
            if (this.isOwnerModule) {
              return {
                name: 'OwnerBuildingDetailsView',
                params: { id: item.value[0].object.buildingId },
                query: {
                  view: 'condominium',
                  elementSelected: item.entity
                }
              }
            } else if (this.isAdminModule) {
              return {
                name: 'AdminBuildingDetailsView',
                params: { id: item.value[0].object.buildingId },
                query: {
                  view: 'condominium',
                  elementSelected: item.entity
                }
              }
            } else if (this.isAssetModule) {
              return {
                name: 'AssetBuildingDetailsView',
                params: { id: item.value[0].object.buildingId },
                query: {
                  view: 'condominium',
                  elementSelected: item.entity
                }
              }
            } else if (this.isAccountingModule) {
              return {
                name: 'AccountingBuildingDetailsView',
                params: { id: item.value[0].object.buildingId },
                query: {
                  view: 'condominium',
                  elementSelected: item.entity
                }
              }
            }
            break
          case 'request':
            return {
              name: 'MarketingObjectDetailsView',
              params: { id: item.value.id },
              query: {
                view: 0,
                elementSelected: item.entity
              }
            }
          case 'contact':
            return this.getContactDetailRoute(item.value.id)
          default:
            return '-'
        }
      }
    },
    getCountOverdueReminders() {
      CalendarApi.countOverdueReminders(this.accountId, this.userId).then(response => {
        this.setCountReminders(response.count)
      })
    }
  }
}

export { reminder }
