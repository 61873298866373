
// import network from './network'
import networkTS from './networkTS'

export default {
  hasHandoverProtocolEnabled(accountId: string) {
    return networkTS.handoverProtocolServiceHasEnabled(accountId)
      .then((response) => {
        return response.data
      })
  },
  enableHandoverProtocol(accountId: string, enable: boolean) {
    return networkTS.handoverProtocolServiceEnable(accountId, { enable: enable })
      .then((response) => {
        return response.data
      })
  },
  handoverProtocolServiceListByObjectIds(objectIds) {
    return networkTS.handoverProtocolServiceListByObjectIds({ objectIds: objectIds })
      .then((response) => {
        return response.data
      })
  },
  handoverProtocolServiceDelete(id) {
    return networkTS.handoverProtocolServiceDelete(id)
      .then((response) => {
        return response.data
      })
  }
}
