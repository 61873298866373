<template>
  <coozzy-card>
    <div
      id="confidential-emails"
      class="row">
      <div class="col-12 col-md">
        <h4 class="card-title">
          {{ $t('message.settings.confidentialEmails.title') }}
          <span class="ml-1">
            <coozzy-info-circle-icon
              v-b-tooltip="{container: '#confidential-emails', title: $t('message.settings.confidentialEmails.titleTooltip')}"
              class="infoCircleIcon" />
          </span>
        </h4>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-2 mb-2">
            <coozzy-dropdown
              design="green"
              size="sm"
              class="w-100"
              :text="selectedEntriesText">
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="removeConfidentialEmails()">
                {{ $t('message.generic.delete') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
          </div>
          <div class="col-12 col-md-2 offset-md-8 mb-2">
            <coozzy-button
              class="w-100"
              design="prop-green"
              @click="openModalAddEmail()">
              {{ $t('message.settings.confidentialEmails.addEmail') }}
            </coozzy-button>
          </div>
          <div class="col-12">
            <b-table
              ref="confidentialEmailTable"
              hover
              bordered
              responsive="true"
              stacked="md"
              class="mb-0"
              selectable
              :busy="loading"
              :fields="fields"
              :items="emailList">
              <!-- Busy state -->
              <div
                slot="table-busy"
                class="text-center text-danger my-2">
                <coozzy-spinner />
              </div>

              <!-- Header -->
              <template #head(checkbox)>
                <coozzy-form-checkbox
                  ref="header_checkbox"
                  @change="(value) => { handleCheckboxesInsideTable(value) }" />
              </template>
              <template #head(emailOrDomain)>
                {{ $t('message.settings.confidentialEmails.modalAddEmail.emailOrDomain') }}
              </template>

              <!-- Body -->
              <template
                #cell(checkbox)="data">
                <!-- data needs to be used in order to work properly -->
                <coozzy-form-checkbox
                  :id="'check_' + data.item"
                  @change="(value) => { itemSelected(data.item, value) }" />
              </template>
              <template #cell(emailOrDomain)="data">
                {{ data.item }}
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-if="showModal"
      id="addConfidentialEmail"
      ref="addConfidentialEmail"
      :title="$t('message.settings.confidentialEmails.modalAddEmail.title')"
      hide-footer
      no-close-on-backdrop>
      <div class="row">
        <div class="col-12 mb-3">
          <label>{{ $t('message.settings.confidentialEmails.modalAddEmail.emailOrDomain') }}*</label>
          <ais-instant-search
            :search-client="searchClient"
            :routing="routing"
            index-name="contact">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-configure
              :hitsPerPage="15"
              :filters="filterQuery" />
            <!-- eslint-enable vue/attribute-hyphenation -->
            <ais-autocomplete>
              <template slot-scope="{ indices, refine }">
                <coozzy-search-multiselect
                  id="contacts-multiselect"
                  v-model="contacts"
                  :options="indicesToSuggestions(indices[0].hits)"
                  :multiple="true"
                  :placeholder="''"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                  :taggable="true"
                  :custom-label="nameWithEmail"
                  :internal-search="false"
                  :track-by="'email'"
                  :with-address="false"
                  :email-with-name="true"
                  @search-change="see(refine, $event)" />
              </template>
            </ais-autocomplete>
          </ais-instant-search>
        </div>
        <div class="col-12">
          <coozzy-button
            size="small"
            class="border mb-0"
            design="transparent"
            @click="$bvModal.hide('addConfidentialEmail')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="addConfidentialEmails">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </div>
    </b-modal>
  </coozzy-card>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import MessageApi from '@/misc/apis/MessageApi'
import { user } from '@/mixins/user'
import Vue from 'vue'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect.vue'
import { algolia } from '@/mixins/algolia'
import { tables } from '@/mixins/tables'

export default {
  name: 'ConfidentialEmailList',
  components: {
    CoozzySearchMultiselect,
    CoozzyFormCheckbox,
    CoozzyDropdown,
    CoozzyDropdownItem,
    CoozzyInfoCircleIcon,
    CoozzyButton,
    CoozzySpinner,
    CoozzyCard
  },
  mixins: [user, algolia, tables],
  data() {
    return {
      loading: false,
      emailList: [],
      selectedEntries: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox align-middle',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'emailOrDomain',
          label: this.$t('message.settings.confidentialEmails.modalAddEmail.emailOrDomain'),
          thClass: 'table-header align-middle',
          tdClass: 'align-middle'
        }
      ],
      contacts: [],
      showModal: false
    }
  },
  computed: {
    selectedEntriesText() {
      return this.$t('message.generic.selectedEntries') + ':  ' + this.selectedEntries.length
    },
    filterQuery() {
      const query = 'isOwner:false AND active:true AND hasEmail:true AND isOnlyInterestedParty:false AND numericId >= 40000'
      return query
    }
  },
  mounted() {
    this.loadInstantSearch()
    this.loadListConfidentialEmails()
  },
  methods: {
    loadListConfidentialEmails() {
      MessageApi.listConfidentialEmails(this.accountId)
        .then(response => {
          this.emailList = response.emails
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    },
    openModalAddEmail() {
      this.showModal = true
      this.$nextTick(() => {
        this.$refs.addConfidentialEmail.show()
      })
    },
    addConfidentialEmails() {
      if (this.contacts.length === 0) {
        return
      }
      const emails = this.contacts.map(contact => contact.email)
      MessageApi.addConfidentialEmails(this.accountId, emails)
        .then(() => {
          this.loadListConfidentialEmails()
          this.$refs.addConfidentialEmail.hide()
          this.showModal = false
          this.contacts = []
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    },
    removeConfidentialEmails() {
      MessageApi.removeConfidentialEmails(this.accountId, this.selectedEntries)
        .then(() => {
          this.loadListConfidentialEmails()
          Vue.toasted.show(this.$tc('message.settings.confidentialEmails.successRemoveEmail'), { type: 'success' })
          this.clearCheckbox()
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs.confidentialEmailTable.$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
    },
    itemSelected(id, checked) {
      if (checked && !this.selectedEntries.includes(id)) {
        this.selectedEntries.push(id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== id)
      }
    },
    nameWithEmail(item) {
      return item.name + ' ' + '<' + item.email + '>'
    },
    indicesToSuggestions(hits) {
      const merged = [].concat.apply([], hits.map(x => x.contactPersons ? x.contactPersons.filter(y => y.email) : []))
      const list = hits.filter(x => x.email).concat(merged.length > 0 ? merged[0] : [])
      list.forEach(element => {
        const obj2 = JSON.parse(JSON.stringify(element))
        if (obj2.workEmail && obj2.workEmail.email !== '') {
          obj2.email = obj2.workEmail.email
          list.push(obj2)
        }
      })
      return list
    },
    see(refine, value) {
      refine(value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .infoCircleIcon {
    font-size: 18px;
  }
</style>
