<template>
  <b-modal
    id="edit-mailbox-modal"
    ref="edit-mailbox-modal"
    :modal-class="'has-confirmation-modal'"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.settings.mailbox.edit') ">
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-else
      class="row">
      <div class="col-sm-12 col-md-12">
        <coozzy-form-input
          v-model="mailbox.outbound"
          class="mb-3"
          :state="$v.mailbox.outbound.$dirty && $v.mailbox.outbound.$error ? false : null"
          :name="$t('message.settings.mailbox.outbound') " />
      </div>
      <div class="col-sm-12 col-md-12 mb-3">
        <label>{{ $t('message.calendar.event.signature') }}</label>
        <coozzy-form-select
          v-model="mailbox.signatureId">
          <option
            v-for="(signature, signatureIndex) in signatures"
            :key="signatureIndex"
            :value="signature.id">
            {{ signature.name }}
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-sm-12 col-md-12">
        <coozzy-form-input
          v-model="mailbox.name"
          class="mb-3"
          :state="$v.mailbox.name.$dirty && $v.mailbox.name.$error ? false : null"
          :name="$t('message.settings.mailbox.name') " />
      </div>
      <div class="col-sm-12 col-md-12">
        <label>{{ $t('message.settings.mailbox.mainUser') }}</label>
        <coozzy-form-select
          v-model="mailbox.mainUser">
          <option
            v-for="(employee, employeeIndex) in employeeList"
            :key="employeeIndex"
            :value="employee.id">
            {{ employee.profile.firstName }} {{ employee.profile.lastName }}
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-sm-12 col-md-12 mb-3">
        <label class="mt-2">{{ $t('message.settings.mailbox.allowedUsers') }}</label>
        <coozzy-multiselect
          v-model="mailbox.allowedUsers"
          :options="employeeList.filter(x=> x.blocked === false)"
          :multiple="true"
          :placeholder=" $t('message.settings.mailbox.allowedUsers')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          :custom-label="customLabelEmployee"
          track-by="id" />
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="hide()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="editMailbox">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MessageApi from '../../misc/apis/MessageApi'
import { email, required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import { mapActions } from 'vuex'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyButton from '@/framework/components/button/CoozzyButton'

export default {
  name: 'EditMailboxModal',
  components: { CoozzyButton, CoozzyMultiselect, CoozzyFormSelect, CoozzyFormInput, CoozzySpinner },
  props: {
    employeeList: {
      type: Array,
      default: () => []
    },
    defaultMailbox: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      mailbox: {
        outbound: '',
        name: '',
        mainUser: '',
        allowedUsers: []
      },
      signatures: [],
      loading: false
    }
  },
  computed: {
    allowedUserIds() {
      return this.employeeList.filter(emp => {
        return this.defaultMailbox.allowedUserIds.includes(emp.id)
      })
    }
  },
  watch: {
    mailbox: {
      deep: true,
      handler: function (newVal) {
        if (newVal.name !== this.defaultMailbox.name || newVal.outbound !== this.defaultMailbox.outboundEmail || newVal.mainUser !== this.defaultMailbox.mainUserId || JSON.stringify(newVal.allowedUsers) !== JSON.stringify(this.allowedUserIds)) {
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    }
  },
  mounted() {
    if (this.defaultMailbox) {
      this.mailbox = {
        name: this.defaultMailbox.name,
        outbound: this.defaultMailbox.outboundEmail,
        mainUser: this.defaultMailbox.mainUserId,
        allowedUsers: this.allowedUserIds,
        signatureId: this.defaultMailbox.signatureId
      }
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
        if (modalId === 'edit-mailbox-modal') {
          this.mailbox = {
            outbound: this.defaultMailbox.outboundEmail,
            name: this.defaultMailbox.name,
            mainUser: this.defaultMailbox.mainUserId,
            allowedUsers: this.allowedUserIds,
            signatureId: this.defaultMailbox.signatureId
          }
          this.$nextTick(function () {
            this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
          })
        }
      })
      MessageApi.getSignatures(this.$store.getters['user/getAccountId'])
        .then(response => {
          this.signatures = response.signatures
          if (this.mailbox.signatureId === '' || this.mailbox.signatureId === null) {
            this.mailbox.signatureId = this.signatures.find(x => x.name === 'Standard')?.id
          }
        })
        .catch(e => {
          if (!e.response || e.response.status !== 404) {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.signature'), { type: 'error' })
          }
        })
    }
  },
  methods: {
    ...mapActions('message', ['resetMailboxes']),
    editMailbox() {
      this.$v.mailbox.$touch()
      if (!this.$v.mailbox.$invalid) {
        this.loading = true
        MessageApi.editMailbox(this.mailbox, this.defaultMailbox.id)
          .then(() => {
            this.resetMailboxes()
            this.$emit('mailbox-edited', this.mailbox, this.defaultMailbox.id)
            this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
            this.loading = false
            this.hide()
            this.$v.$reset()
            Vue.toasted.show(this.$t('message.successMessages.mailboxUpdated'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            Vue.toasted.show(this.$t('message.savingErrors.mailbox'), { type: 'error' })
          })
      }
    },
    show() {
      this.$refs['edit-mailbox-modal'].show()
    },
    hide() {
      this.$refs['edit-mailbox-modal'].hide()
    },
    customLabelEmployee(item) {
      return item.profile.firstName + ' ' + item.profile.lastName
    }
  },
  validations: {
    mailbox: {
      outbound: {
        required,
        email
      },
      name: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
