<template>
  <div>
    <div
      v-if="$slots.default"
      class="row">
      <div class="col">
        <label class="mb-0">
          <slot />
        </label>
      </div>
    </div>
    <div class="row">
      <div
        class="col"
        :class="costumClass">
        <multiselect
          v-bind="$attrs"
          :value="value"
          :options="options"
          :placeholder="placeholder"
          :multiple="multiple"
          :select-label="''"
          :close-on-select="closeOnSelect"
          :selected-label="$t('message.generic.selected')"
          :clear-on-select="true"
          :class="[checkValide]"
          v-on="mountEventListeners">
          <template slot="noOptions">
            {{ $t('message.generic.ListIsEmpty') }}
          </template>
          <span slot="noResult">{{ $t('message.generic.noResult') }}</span>
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CoozzyMultiselect',
  props: {
    htmlLabel: {
      type: Function,
      default: function () {
        return []
      }
    },
    placeholder: {
      type: String,
      default: function () {
        return this.$t('message.generic.select')
      }
    },
    value: {
      type: [Array, String, Object],
      default: function () {
        return []
      }
    },
    costumClass: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: true
    },
    closeOnSelect: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    checkValide: {
      type: String,
      default: ''
    }
  },
  computed: {
    mountEventListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: (value) => {
            this.$emit('input', value)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.multiselect__tags) {
    border: 1px solid $color-input-border;
    border-radius: 0.2rem;
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 3px 40px 0 8px;
    box-shadow: 1px 1px 6px #ccc;
  }

  :deep(.multiselect__placeholder) {
    padding-top: 0;
    margin-bottom: 5px;
    color: #6d757d;
  }

  :deep(.multiselect__tag) {
    padding: 3px 22px 3px 7px;
    margin-bottom: 1px;
    background-color: $color-primary;
  }

  :deep(.multiselect__input) {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  :deep(.multiselect--disabled) {
    .multiselect__select {
      display: none;
    }
  }

  :deep(.multiselect__select) {
    height: 33px;
  }

  :deep(.multiselect) {
    min-height: 33px;
    color: #495057;
  }
  :deep(.multiselect--above .multiselect__tags) {
    padding-top: 0.25rem;
  }
  :deep(.multiselect__single) {
    padding-left: 0px;
    margin-bottom: 0px;
    font-size: 0.875rem;
  }

  :deep(.multiselect) {
    min-height: 30px;
    &.is-invalid .multiselect__tags{
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-repeat: no-repeat;
      background-position: center right calc(0.375em + 0.1875rem);
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  :deep(.multiselect__tag-icon) {
    &:after {
      color: $color-text-white !important;
    }

    &:hover {
      background-color: $color-primary-dark;
    }
  }

  :deep(.multiselect__element) {
    &:hover {
      background-color: $color-primary;
    }
  }

  :deep(.multiselect__option--highlight:after) {
    content: none !important;
  }

  :deep(.multiselect__option--highlight) {
    background-color: $color-primary;
  }

  :deep(.multiselect__option--highlight.multiselect__option--selected) {
    background-color: $color-red;
  }
</style>
