<template>
  <b-modal
    id="add-domain-modal"
    ref="add-domain-modal"
    :modal-class="'has-confirmation-modal'"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.settings.domain.create') ">
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-else
      class="row">
      <div class="col-sm-12 col-md-12">
        <coozzy-form-input
          v-model="domain"
          class="mb-3"
          :state="$v.domain.$dirty && $v.domain.$error ? false : null"
          :name="$t('message.settings.domain.name') " />
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="hide()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="createDomain">
          {{ $t('message.settings.domain.create') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MessageApi from '../../misc/apis/MessageApi'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'

function isDomainValid(value, vm) {
  return /^([a-z0-9|-]+[a-z0-9]{1,}\.)*[a-z0-9|-]+[a-z0-9]{1,}\.[a-z]{2,}$/.test(value)
}

export default {
  name: 'CreateDomainModal',
  data() {
    return {
      domain: '',
      loading: false
    }
  },
  watch: {
    domain: {
      handler: function (val) {
        if (val !== '') {
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'add-domain-modal') {
        this.domain = ''
      }
    })
  },
  methods: {
    createDomain() {
      this.$v.domain.$touch()
      if (!this.$v.domain.$invalid) {
        this.loading = true
        MessageApi.createDomain(this.domain, this.$store.getters['user/getAccountId'])
          .then(response => {
            this.$emit('domain-created', response)
            this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
            this.loading = false
            this.hide()
            Vue.toasted.show(this.$t('message.successMessages.domainCreation'), { type: 'success' })
            this.$v.$reset()
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            Vue.toasted.show(this.$t('message.savingErrors.domain'), { type: 'error' })
            this.$v.$reset()
          })
      }
    },
    show() {
      this.$refs['add-domain-modal'].show()
    },
    hide() {
      this.$refs['add-domain-modal'].hide()
    }
  },
  validations: {
    domain: {
      required,
      isDomainValid
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
