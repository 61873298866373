<template>
  <section>
    <b-modal
      id="digital-signature"
      ref="digital-signature"
      :title="$t('message.deepSign.modal.title')"
      hide-footer
      no-close-on-backdrop
      @hide="hide()">
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <div
        v-else
        class="row">
        <div
          :key="'signature'"
          class="col-sm-12 col-md-6 mb-2">
          <a
            :href="getLinkSignature"
            class="link"
            target="_blank">
            <h5>
              {{ $t('message.deepSign.modal.signature') }}
            </h5>
          </a>
          <coozzy-form-select
            v-if="!readOnly"
            v-model="signatureInvitation.signType"
            :disabled="readOnly"
            class="select-entries"
            name="listSignatures">
            <option
              v-for="(option, indexSign) in legalWeightOptions"
              :key="indexSign"
              :value="option">
              {{ $t('message.deepSign.modal.options.' + option) }}
            </option>
          </coozzy-form-select>
          <span
            v-else
            class="d-block">
            <span v-if="signatureInvitation.signType === 'LEGAL_WEIGHT_STANDARD'">
              {{ $t('message.deepSign.modal.options.LEGAL_WEIGHT_STANDARD') }}
            </span>
            <span v-else-if="signatureInvitation.signType === 'LEGAL_WEIGHT_PROFESSIONAL'">
              {{ $t('message.deepSign.modal.options.LEGAL_WEIGHT_PROFESSIONAL') }}
            </span>
            <span v-else-if="signatureInvitation.signType === 'LEGAL_WEIGHT_QUALIFIED'">
              {{ $t('message.deepSign.modal.options.LEGAL_WEIGHT_QUALIFIED') }}
            </span>
          </span>
        </div>
        <!-- LandLord Part -->
        <div class="col-12 mb-2">
          <div class="row">
            <h5
              :class="readOnly ? 'col-12' : 'col-8'">
              {{ $t('message.deepSign.modal.landlord') }}
            </h5>
            <div
              v-if="!readOnly"
              :class="readOnly ? '' : 'col-4'">
              <coozzy-button
                class="mb-0 border float-right min-width-btns"
                design="transparent"
                size="small"
                @click="addLandLord()">
                {{ $t('message.deepSign.modal.addLandlord') }}
              </coozzy-button>
            </div>
            <template
              v-for="index in signatureInvitation.landlord.length">
              <div
                :key="'userId_' + index"
                class="col-sm-12 col-md-6 mb-2">
                <label>{{ $t('message.deepSign.modal.landlord') }}</label>
                <coozzy-form-select
                  v-if="!readOnly"
                  v-model="signatureInvitation.landlord[index - 1].userId"
                  :disabled="readOnly"
                  class="select-entries float-right"
                  name="listUsers"
                  @change="userChanged(index - 1)">
                  <option
                    v-for="(emp, indexEmp) in listEmployee"
                    :key="indexEmp"
                    :value="emp.id">
                    {{ emp.profile.lastName }} {{ emp.profile.firstName }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else
                  class="d-block">
                  {{ getContactName(signatureInvitation.landlord[index - 1].contactId) }}
                </span>
              </div>
              <div
                :key="'email_' + index"
                class="col-sm-12 col-md-6 mb-2">
                <label>{{ $t('message.generic.email') }}</label>
                <div :class="!readOnly ? 'containerDeleteICon row' : ''">
                  <coozzy-form-input
                    v-model="signatureInvitation.landlord[index - 1].email"
                    :class="!readOnly ? 'col pr-0 adjustWidthInput' : ''"
                    :disabled="true"
                    :is-read-only="readOnly"
                    :placeholder="$t('message.generic.email')"
                    :state="$v.checkEmails.$dirty && $v.checkEmails.$error && checkEmails.includes(signatureInvitation.landlord[index - 1].email) ? false : null" />
                  <coozzy-button
                    v-if="!readOnly"
                    class="deleteBtn col ml-1"
                    @click="deleteLandLord(index - 1)">
                    <coozzy-delete-icon
                      class="deleteIcon" />
                  </coozzy-button>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- Tenants Part -->
        <div
          v-if="signatureInvitation.tenant.length > 0"
          class="col-12 mb-2">
          <div class="row">
            <h5 class="col-12">
              {{ $t('message.deepSign.modal.tenant') }}
            </h5>
            <template
              v-for="index in signatureInvitation.tenant.length">
              <div
                :key="'tenant_' + index"
                class="col-sm-12 col-md-6 mb-2">
                <label>{{ $t('message.deepSign.modal.tenant') }}</label>
                <coozzy-form-select
                  v-if="!readOnly"
                  v-model="signatureInvitation.tenant[index - 1].contactId"
                  :disabled="readOnly"
                  class="select-entries float-right"
                  name="listUsers"
                  @change="tenantChanged(index - 1)">
                  <option
                    v-for="(tenant, tenantIndex) in listTenants"
                    :key="tenantIndex"
                    :value="tenant.id">
                    {{ tenant.name }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else
                  class="d-block">
                  {{ getContactName(signatureInvitation.tenant[index - 1].contactId) }}
                </span>
              </div>
              <div
                :key="'tenantEmail_' + index"
                :class="!readOnly ? 'pr-0' : ''"
                class="col-sm-12 col-md-6 mb-2">
                <label>{{ $t('message.generic.email') }}</label>
                <div :class="!readOnly ? 'containerDeleteICon' : ''">
                  <coozzy-form-input
                    v-model="signatureInvitation.tenant[index - 1].email"
                    :disabled="readOnly"
                    :is-read-only="readOnly"
                    :placeholder="$t('message.generic.email')"
                    :state="$v.checkEmails.$dirty && $v.checkEmails.$error && checkEmails.includes(signatureInvitation.tenant[index - 1].email) ? false : null"
                    @blur="hasDuplicateEmails()" />
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- Contacts Part -->
        <div
          v-if="!tenancy"
          class="col-12 mb-2">
          <div
            id="list-contacts"
            class="row">
            <h5
              :class="readOnly ? 'col-12' : 'col-8'">
              {{ $t('message.generic.contacts') }}
            </h5>
            <div
              v-if="!readOnly"
              :class="readOnly ? '' : 'col-4'">
              <coozzy-button
                class="mb-0 border float-right min-width-btns"
                design="transparent"
                size="small"
                @click="addContact()">
                {{ $t('message.contact.actions.contact') }}
              </coozzy-button>
            </div>
            <template
              v-for="indexContact in signatureInvitation.contacts.length">
              <div
                :id="'contact_' + indexContact"
                :key="'contact_' + indexContact"
                class="col-sm-12 col-md-6 mb-2 ">
                <label>{{ $t('message.calendar.reminder.contact') }}</label>
                <ais-instant-search
                  v-if="!readOnly && searchClient"
                  :ref="'instantSearch_' + indexContact"
                  :routing="routing"
                  :search-client="searchClient"
                  index-name="contact">
                  <!-- eslint-disable vue/attribute-hyphenation -->
                  <ais-configure
                    :filters="filterQuery"
                    :hitsPerPage="10" />
                  <!-- eslint-enable vue/attribute-hyphenation -->
                  <ais-autocomplete>
                    <template slot-scope="{ indices, refine }">
                      <vue-autosuggest
                        :ref="'autosuggest_' + indexContact"
                        :input-props="{
                          placeholder: $t('message.generic.search'),
                          class: 'custom-select',
                          id: 'autosuggest__input',
                        }"
                        :suggestions="indicesToSuggestions(indices)"
                        @input="onChange(refine, $event, indexContact - 1)"
                        @selected="onSelect($event, indexContact - 1)">
                        <template slot-scope="{ suggestion }">
                          <div
                            :id="'tooltip-target_' + (suggestion.item.objectID || suggestion.item.id)"
                            :class="checkSelectedElement(suggestion.item.objectID || suggestion.item.id) ? 'selected' : ''">
                            <strong>{{ suggestion.item.name }} {{ suggestion.item.types?.length > 0 ? '-' : '' }} {{
                              suggestion.item.types?.map(obj => $t('message.contact.types.' + obj.toUpperCase())).join(', ')
                            }}</strong>
                            <span v-if="suggestion.item.address"> <br>{{ suggestion.item.address.street }} {{
                              suggestion.item.address.streetNumber
                            }} <span
                              v-if="(suggestion.item.address.streetNumber && suggestion.item.address.streetNumber !== '') || (suggestion.item.address.street && suggestion.item.address.street !== '')">,</span> {{
                              suggestion.item.address.zip
                            }} {{
                              suggestion.item.address.city
                            }}</span>
                          </div>
                          <b-tooltip
                            v-if="checkSelectedElement(suggestion.item.objectID || suggestion.item.id)"
                            :container="'#list-contacts'"
                            :target="'tooltip-target_' + (suggestion.item.objectID || suggestion.item.id)"
                            placement="bottom"
                            triggers="hover">
                            {{ $t('message.savingErrors.contactAlreadySelected') }}
                          </b-tooltip>
                        </template>
                      </vue-autosuggest>
                    </template>
                  </ais-autocomplete>
                </ais-instant-search>
                <span
                  v-else
                  class="d-block">
                  {{ getContactName(signatureInvitation.contacts[indexContact - 1].contactId) }}
                </span>
              </div>
              <div
                :key="'email_' + indexContact"
                class="col-sm-12 col-md-6 mb-2">
                <label>{{ $t('message.generic.email') }}</label>
                <div :class="!readOnly ? 'containerDeleteICon row' : ''">
                  <coozzy-form-input
                    v-model="signatureInvitation.contacts[indexContact - 1].email"
                    :class="!readOnly ? 'col pr-0 adjustWidthInput' : ''"
                    :is-read-only="readOnly"
                    :placeholder="$t('message.generic.email')"
                    :state="$v.checkEmails.$dirty && $v.checkEmails.$error && checkEmails.includes(signatureInvitation.contacts[indexContact - 1].email) ? false : null"
                    @blur="hasDuplicateEmails()" />
                  <coozzy-button
                    v-if="!readOnly"
                    class="deleteBtn col ml-1"
                    @click="deleteContact(indexContact - 1)">
                    <coozzy-delete-icon
                      class="deleteIcon" />
                  </coozzy-button>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- Guarantor Part -->
        <div
          v-if="signatureInvitation.guarantor.length > 0"
          class="col-12">
          <div class="row">
            <h5 class="col-12">
              {{ $t('message.deepSign.modal.guarantor') }}
            </h5>
            <template v-for="guarantorIndex in signatureInvitation.guarantor.length">
              <div
                :key="'guarantor_' + guarantorIndex"
                class="col-sm-12 col-md-6 mb-2">
                <label>{{ $t('message.deepSign.modal.guarantor') }}</label>
                <coozzy-form-select
                  v-if="!readOnly"
                  v-model="signatureInvitation.guarantor[guarantorIndex - 1].contactId"
                  :disabled="readOnly"
                  class="select-entries float-right"
                  name="listUsers"
                  @change="guarantorChanged(index - 1)">
                  <option
                    v-for="(guarantor, index) in listGuarantors"
                    :key="index"
                    :value="guarantor.id">
                    {{ guarantor.name }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else
                  class="d-block">
                  {{ getContactName(signatureInvitation.guarantor[guarantorIndex - 1].contactId) }}
                </span>
              </div>
              <div
                :key="'guarantorEmail_' + guarantorIndex"
                :class="!readOnly ? 'pr-0' : ''"
                class="col-sm-12 col-md-6 mb-2">
                <label>{{ $t('message.generic.email') }}</label>
                <div :class="!readOnly ? 'containerDeleteICon' : ''">
                  <coozzy-form-input
                    v-model="signatureInvitation.guarantor[guarantorIndex - 1].email"
                    :disabled="readOnly"
                    :is-read-only="readOnly"
                    :placeholder="$t('message.generic.email')"
                    :state="$v.checkEmails.$dirty && $v.checkEmails.$error && checkEmails.includes(signatureInvitation.guarantor[guarantorIndex - 1].email) ? false : null"
                    @blur="hasDuplicateEmails()" />
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- Message -->
        <div class="col-12">
          <div class="row">
            <h5 class="col-12">
              {{ $t('message.generic.message') }}
            </h5>
            <div
              v-if="!readOnly"
              class="col-sm-12 col-md-12 mb-2">
              <label>{{ $t('message.settings.template.textTemplate') }}</label>
              <coozzy-form-select
                v-model="templateId">
                <option :value="null" />
                <option
                  v-for="template in sortedTemplate"
                  :key="template.id"
                  :value="template">
                  {{ template.name }}
                </option>
              </coozzy-form-select>
            </div>
            <div
              class="col-md-12 mb-2">
              <coozzy-form-textarea
                v-model="signatureInvitation.note"
                :disabled="readOnly"
                :initial="signatureInvitation.note"
                :is-read-only="readOnly"
                :placeholder="$t('message.generic.message')"
                :resizable="false"
                :rows="5"
                :show-label="false"
                :text="signatureInvitation.note"
                :value-text-area="signatureInvitation.note" />
            </div>
          </div>
        </div>
        <!-- Obligatory documents -->
        <div class="col-12">
          <div class="row">
            <h5 class="col-12">
              {{ $t('message.deepSign.modal.signatureObligatoryDocs') }}
            </h5>
            <div
              class="col-md-12 mb-2">
              <div
                class="row">
                <div
                  :key="'docName'"
                  class="col-12">
                  <label>{{ $t('message.manageDocuments.documentName') }}</label>
                  <coozzy-form-input
                    v-model="documentName"
                    :disabled="readOnly"
                    :is-read-only="readOnly"
                    :placeholder="$t('message.manageDocuments.documentName')"
                    :state=" $v.documentName.$dirty && $v.documentName.$error ? false : null" />
                </div>
                <div
                  :key="'docType'"
                  class="col-12">
                  <label>{{ $t('message.manageDocuments.type.title') }}</label>
                  <coozzy-form-select
                    v-if="!readOnly"
                    v-model="documentType"
                    :disabled="readOnly"
                    :state="$v.documentType.$dirty && $v.documentType.$error ? false : null"
                    class="select-entries float-right"
                    name="listUsers">
                    <option
                      v-for="(type, j) in documentsType"
                      :key="j"
                      :value="type">
                      {{ $t('message.manageDocuments.type.' + type) }}
                    </option>
                  </coozzy-form-select>
                  <span
                    v-else
                    class="d-block">
                    {{ documentType !== '' ? $t('message.manageDocuments.type.' + documentType) : '-' }}
                  </span>
                </div>
                <div
                  :key="'docFile'"
                  class="col-12 attachment">
                  <label for="attachment"> {{ $t('message.ticketOverview.ticket.attachment') }}</label>
                  <coozzy-form-file-input
                    v-if="!readOnly"
                    :id="'fileInput'"
                    :key="'fileInput'"
                    :ref="'fileInput'"
                    :disabled="uploading"
                    :state="$v.mediasList.$dirty && $v.mediasList.$error ? false : null"
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .pdf"
                    multiple
                    @change="addFile($event)" />
                  <div class="row">
                    <template
                      v-for="(media, index) in mediasList">
                      <div
                        :key="media.id"
                        class="col-12 square">
                        <div
                          class="file-block row">
                          <a
                            :href="media.url"
                            class="link adjustWidthInput col"
                            target="_blank">
                            {{ media.filename }}
                          </a>
                          <coozzy-button
                            v-if="!readOnly"
                            class="deleteBtn col ml-1"
                            @click="deleteDocument(index)">
                            <coozzy-delete-icon
                              class="deleteIcon" />
                          </coozzy-button>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div
              v-if="!signatureRequest || getStatus() === 'incomplete'"
              class="col mt-3">
              <coozzy-button
                class="mb-0 border"
                design="transparent"
                size="small"
                @click="hide()">
                {{ $t('message.generic.cancel') }}
              </coozzy-button>
              <coozzy-button
                v-if="!readOnly"
                :disabled="uploading"
                class="float-right mb-0"
                design="green"
                size="small"
                @click="submitSendDocument">
                {{ $t('message.generic.create') }}
              </coozzy-button>
              <coozzy-button
                v-else
                :disabled="signatureRequest.cancelled"
                class="float-right mb-0"
                design="green"
                size="small"
                @click="cancelSignature">
                {{ $t('message.deepSign.cancelSignature') }}
              </coozzy-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="cancelSignatureModal"
      ref="cancelSignatureModal"
      :title="$t('message.generic.confirm')"
      hide-footer
      no-close-on-backdrop
      @hide="hideCancelSignature()">
      <div class="col p-0">
        <p>{{ $t('message.deepSign.messageCancelSignature') }}</p>
      </div>
      <div class="col p-0">
        <coozzy-button
          class="mb-0 border"
          design="transparent"
          size="small"
          @click="hideCancelSignature">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="cancelSignatureConfirmation">
          {{ $t('message.generic.confirm') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import { user } from '@/mixins/user'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import Vue from 'vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput.vue'
import { media } from '@/mixins/media'
import ContactApi from '@/misc/apis/ContactApi'
import MessageApi from '@/misc/apis/MessageApi'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import DeepSignApi from '@/misc/apis/DeepSignApi'
import MediaApi from '@/misc/apis/MediaApi'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import { algolia } from '@/mixins/algolia'
import { mapState } from 'vuex'
import { VueAutosuggest } from 'vue-autosuggest'
import { deepSign } from '@/mixins/deepSign'

export default {
  name: 'DigitalSignatureModal',
  components: {
    CoozzySpinner,
    CoozzyDeleteIcon,
    CoozzyFormFileInput,
    CoozzyButton,
    CoozzyFormInput,
    CoozzyFormTextarea,
    CoozzyFormSelect,
    VueAutosuggest
  },
  mixins: [user, media, algolia, deepSign],
  props: {
    requestId: {
      type: String,
      default: ''
    },
    tenancy: {
      type: Object,
      default: null
    },
    contacts: {
      type: Array,
      required: true,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    signatureRequest: {
      type: Object,
      default: null
    },
    objectId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      templateId: null,
      signatureInvitation: {
        signType: 'LEGAL_WEIGHT_STANDARD',
        landlord: [],
        tenant: [],
        contacts: [],
        guarantor: [],
        note: ''
      },
      legalWeightOptions: ['LEGAL_WEIGHT_STANDARD', 'LEGAL_WEIGHT_PROFESSIONAL', 'LEGAL_WEIGHT_QUALIFIED'],
      documentName: '',
      documentType: 'contract',
      documentsType: ['handover_protocol', 'maintenance_contract_doc_type', 'regulations', 'regulations_for_use_and_management', 'other', 'contract', 'agreement', 'key_list', 'letter'],
      uploading: false,
      loading: false,
      attachment: [],
      mediasList: [],
      listContacts: [],
      checkEmails: [],
      searchedContacts: []
    }
  },
  computed: {
    ...mapState('message', ['templates']),
    listEmployee() {
      return this.$store.getters['employee/getEmployees'].filter(
        e => !e.deleted && !e.blocked && (e.roles.includes('digital_signature') || e.roles.includes('company_admin'))
      )
    },
    listTenants() {
      if (this.tenancy !== null && this.listContacts.length > 0) {
        const contactIds = this.tenancy.tenant?.contactIds
        return this.listContacts.filter(c => contactIds.includes(c.id))
      }
      return []
    },
    listGuarantors() {
      if (this.tenancy !== null && this.listContacts.length > 0) {
        const contactIds = this.tenancy.tenant?.guarantorContactIds
        return this.listContacts.filter(c => contactIds.includes(c.id))
      }
      return []
    },
    getLinkSignature() {
      let link = ''
      if (this.currentLanguage === 'en') {
        link = 'https://www.deepsign.swiss/en/electronic-signature/'
      } else if (this.currentLanguage === 'de') {
        link = 'https://www.deepsign.swiss/de/elektronische-signatur/'
      } else if (this.currentLanguage === 'it') {
        link = 'https://www.deepsign.swiss/it/firma-elettronica/'
      }
      return link
    },
    filterQuery() {
      let query = ''
      if (this.ownerId && this.accountId) {
        query += 'owner.id=' + this.ownerId + ' OR owner.id=' + this.accountId
      }
      return query
    },
    sortedTemplate() {
      return this.templates.filter(x => x.type === 'TEMPLATE_TYPE_DEEP_SIGN').slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    }
  },
  watch: {
    templateId: function (val) {
      if (val !== null) {
        this.renderTemplate()
      } else {
        this.bodyHtml = this.getSignatureForUserId(this.userId, this.from?.signatureId) +
          (this.sendMailData.emailToReply && this.sendMailData.emailToReply.bodyHtml ? '\n\n' + this.sendMailData.emailToReply.bodyHtml : '')
        this.subject = ''
        this.mediasList = []
      }
    }
  },
  mounted() {
    this.loadInstantSearch()
    this.listContacts = this.contacts
    if (this.tenancy?.tenant?.contactIds?.length > 0) {
      this.tenancy?.tenant?.contactIds.forEach(id => {
        this.signatureInvitation.tenant.push({
          contactId: id,
          email: this.listContacts.find(c => c.id === id)?.email || ''
        })
      })
    } else if (!this.tenancy && !this.signatureRequest) {
      this.signatureInvitation.contacts.push({
        contactId: '',
        email: '',
        name: ''
      })
    }
    if (this.tenancy?.tenant?.guarantorContactIds?.length > 0) {
      this.tenancy?.tenant?.guarantorContactIds.forEach(id => {
        this.signatureInvitation.guarantor.push({
          contactId: id,
          email: this.listContacts.find(c => c.id === id)?.email || ''
        })
      })
    }
    if (this.signatureRequest) {
      this.signatureInvitation.note = this.signatureRequest.note
      this.signatureInvitation.signType = this.signatureRequest.legalWeight
      this.documentName = this.signatureRequest.name
      this.documentType = this.signatureRequest.documentType?.toLocaleLowerCase()
      if (this.signatureRequest.mediaIds.length > 0 || this.signatureRequest.signedMediaId !== '') {
        this.loading = true
        const mediaIds = this.signatureRequest.signedMediaId !== '' ? [this.signatureRequest.signedMediaId] : this.signatureRequest.mediaIds
        MediaApi.getListMedia(mediaIds).then(response => {
          for (const media of response.media) {
            this.mediasList.push(media)
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
      this.loading = true
      if (this.signatureRequest.signers.length > 0) {
        const isContactRequest = this.signatureRequest.attachToReferenceIds.filter(x => x.startsWith('contact')).length > 0
        this.signatureRequest.signers.forEach(signer => {
          const contact = this.listContacts.find(c => c.id === signer.contactId)
          if (contact) {
            if (signer.order === 1 && isContactRequest) {
              this.signatureInvitation.contacts.push({
                contactId: contact.id,
                email: contact.email,
                name: contact.name
              })
            } else if (signer.order === 2) {
              this.signatureInvitation.landlord.push({
                contactId: contact.id,
                email: contact.email,
                name: contact.name
              })
            }
          }
        })
      }
    } else {
      this.addLandLord()
      this.signatureInvitation.landlord[0].userId = this.userId
      this.signatureInvitation.landlord[0].email = this.userEmail
      if (this.listEmployee.length > 0) {
        const currentUser = this.listEmployee.find(e => e.id === this.userId)
        if (currentUser && currentUser.profile && currentUser.profile.signatureAuthorization === 'SIGNATURE_AUTHORIZATION_COLLECTIVELY_BY_TWO') {
          this.addLandLord()
        }
      }
    }
    this.$nextTick(() => {
      this.hasDuplicateEmails()
    })
  },
  methods: {
    renderTemplate(objectId = '') {
      // Reset media
      const currentObjectId = objectId !== '' ? objectId : ''
      MessageApi.renderTemplate(this.templateId.id, '', currentObjectId)
        .then(response => {
          this.signatureInvitation.note = response.text

          const template = this.templates.find(element => element.id === this.templateId.id)
          const promises = template.attachmentMediaIds.map(mediaId => MediaApi.getMediaByID(mediaId))

          Promise.all(promises)
            .then((response) => {
              this.mediasList = this.mediasList.concat(response.map(obj => obj.media))
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
            })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.renderTemplate'), { type: 'error' })
        })
    },
    checkSelectedElement(id) {
      if (id) {
        if (this.signatureInvitation.contacts.find(x => x.contactId === id)) {
          return true
        }
      }
      return false
    },
    getStatus() {
      if (this.signatureRequest) {
        if (this.signatureRequest.cancelled) {
          return 'cancelled'
        } else if (this.signatureRequest.signers.every((x) => x.signed) || this.signatureRequest.completed) {
          return 'complete'
        } else if (this.signatureRequest.signers.some(signer => !signer.signed)) {
          return 'incomplete'
        }
      }
      return ''
    },
    onChange(refine, name) {
      refine(name)
    },
    onSelect(selected, index) {
      if (!this.checkSelectedElement(selected.item.objectID)) {
        if (selected.item) {
          this.signatureInvitation.contacts[index].name = selected.item.name
          this.signatureInvitation.contacts[index].contactId = selected.item.objectID || selected.item.id
          this.signatureInvitation.contacts[index].email = selected.item.email
          if (!this.listContacts.find(x => x.id === selected.item.objectID || x.id === selected.item.id)) {
            if (selected.item.objectID) {
              selected.item.id = selected.item.objectID
            }
            this.listContacts.push(selected.item)
          }
        }
        this.hasDuplicateEmails()
      } else {
        if (this.$refs['autosuggest_' + (index + 1)].length > 0) {
          this.$refs['autosuggest_' + (index + 1)][0].internalValue = ''
        }
        this.signatureInvitation.contacts[index].contactId = ''
        this.signatureInvitation.contacts[index].email = ''
        this.signatureInvitation.contacts[index].name = ''
      }
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits.filter(hit => {
          return hit.active
        }).concat(hits.flatMap(hit => {
          if ('contactPersons' in hit && hit.contactPersons.length > 0) {
            return hit.contactPersons.map(cp => {
              const obj = JSON.parse(JSON.stringify(hit))
              delete obj.contactPersons
              cp.company = obj
              return cp
            })
          } else {
            return []
          }
        }))
      }))
    },
    getLandLordName(userId) {
      const emp = this.listEmployee.find(e => e.id === userId)
      if (emp) {
        return emp.profile.firstName + ' ' + emp.profile.lastName
      }
      return '-'
    },
    getContactName(contactId) {
      const contact = this.listContacts.find(e => e.id === contactId)
      if (contact) {
        return contact.name
      }
      return '-'
    },
    hide() {
      this.$refs['digital-signature']?.hide()
      this.$emit('hide')
    },
    show() {
      this.$refs['digital-signature']?.show()
    },
    async addFile(event) {
      let hasError = false
      const docType = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
      const files = await this.getFilesFromEvent(event)
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          const element = files[index]
          if (docType.includes(element.type) && element.size > 0) {
            this.attachment.push(element)
          } else {
            hasError = true
          }
        }
      }
      if (this.attachment.length > 0) {
        this.sendFile()
      }
      this.$nextTick(() => {
        if (hasError) {
          Vue.toasted.show(this.$tc('message.deepSign.modal.warningDocType'), { type: 'error' })
        }
        if (this.$refs.fileInput) {
          this.$refs.fileInput.resetPlaceholder()
        }
      })
    },
    async sendFile() {
      const promises = []
      this.uploading = true
      const response = await this.uploadMedia(this.attachment)
      if (response.length > 0) {
        for (const media of response) {
          this.mediasList.push(media)
        }
        this.$nextTick(() => {
          this.attachment = []
          this.uploading = false
        })
      } else {
        this.uploading = false
      }
    },
    addLandLord() {
      this.signatureInvitation.landlord.push({
        userId: '',
        email: ''
      })
    },
    addContact() {
      this.signatureInvitation.contacts.push({
        contactId: '',
        email: '',
        name: ''
      })
    },
    userChanged(index) {
      const userId = this.signatureInvitation.landlord[index].userId
      this.signatureInvitation.landlord[index].email = this.listEmployee.find(e => e.id === userId).email
      this.hasDuplicateEmails()
    },
    tenantChanged(index) {
      const contactId = this.signatureInvitation.tenant[index].contactId
      this.signatureInvitation.tenant[index].email = this.contacts.find(e => e.id === contactId).email
      this.hasDuplicateEmails()
    },
    guarantorChanged(index) {
      const contactId = this.signatureInvitation.guarantor[index].contactId
      this.signatureInvitation.guarantor[index].email = this.contacts.find(e => e.id === contactId).email
      this.hasDuplicateEmails()
    },

    deleteDocument(index) {
      this.mediasList.splice(index, 1)
    },
    deleteLandLord(index) {
      this.signatureInvitation.landlord.splice(index, 1)
    },
    deleteContact(index) {
      this.signatureInvitation.contacts.splice(index, 1)
    },
    updateContact(contact, email) {
      contact.email = email
      contact.gender = contact.gender?.toUpperCase()
      if (contact.types?.length) {
        contact.types = contact.types.map(type => type.toUpperCase())
      }
      // check if contact is a contactPerson
      const companyContact = this.listContacts.find(x => x.id === contact.id)
      if (contact.company) {
        ContactApi.editContactPerson(contact, contact.company.objectID, contact.id).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$tc('message.savingErrors.editContact'), { type: 'error' })
        })
      } else {
        ContactApi.editContact(contact).then(response => {
          const data = { contact: null }
          if (response.person) {
            data.contact = response.person
          } else if (response.company) {
            data.contact = response.company
          }
          this.$emit('contact-updated', data)
        })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$tc('message.savingErrors.editContact'), { type: 'error' })
          })
      }
    },
    submitSendDocument() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        if (this.$v.checkEmails.$dirty && this.$v.checkEmails.$error) {
          Vue.toasted.show(this.$tc('message.savingErrors.signersHasSameEmails'), { type: 'error' })
        }
      }
      if (!this.$v.$invalid) {
        this.uploading = true
        this.loading = true
        // check if tenant email updated
        if (this.signatureInvitation.tenant.length > 0) {
          this.signatureInvitation.tenant.forEach(tenant => {
            if (tenant.contactId) {
              const contact = this.listContacts.find(e => e.id === tenant.contactId)
              if (tenant.email !== contact.email) {
                this.updateContact(contact, tenant.email)
              }
            }
          })
        }
        // check if Contact email updated
        if (this.signatureInvitation.contacts.length > 0) {
          this.signatureInvitation.contacts.forEach(element => {
            if (element.contactId) {
              const contact = this.listContacts.find(e => e.id === element.contactId)
              if (element.email !== contact.email) {
                this.updateContact(contact, element.email)
              }
            }
          })
        }
        // check if guarantor email updated
        if (this.signatureInvitation.guarantor.length > 0) {
          this.signatureInvitation.guarantor.forEach(element => {
            if (element.contactId) {
              const contact = this.listContacts.find(e => e.id === element.contactId)
              if (element.email !== contact.email) {
                this.updateContact(contact, element.email)
              }
            }
          })
        }
        // get contactId for landlord
        const landLordEmployeeUserIds = this.signatureInvitation.landlord.map(e => e.userId)
        const landLordEmployeeEmails = this.listEmployee.filter(e => landLordEmployeeUserIds.includes(e.id)).map(e => e.email)
        ContactApi.resolveByMail(this.accountId, landLordEmployeeEmails)
          .then(response => {
            let landLord = []
            if (response.persons.length > 0) {
              landLord = landLord.concat(response.persons)
            } else if (response.companies.length > 0) {
              landLord = landLord.concat(response.companies)
            }
            landLord = landLord.filter(e => e.types.includes('EMPLOYEE'))
            let signers = []
            if (this.signatureInvitation.tenant.length > 0) {
              this.signatureInvitation.tenant.forEach(tenant => {
                if (tenant.contactId) {
                  signers.push({
                    contactId: tenant.contactId,
                    order: 1
                  })
                }
              })
            } else if (this.signatureInvitation.contacts.length > 0) {
              this.signatureInvitation.contacts.forEach(contact => {
                if (contact.contactId) {
                  signers.push({
                    contactId: contact.contactId,
                    order: 1
                  })
                }
              })
            }
            if (this.signatureInvitation.guarantor.length > 0) {
              this.signatureInvitation.guarantor.forEach(guarantor => {
                if (guarantor.contactId) {
                  signers.push({
                    contactId: guarantor.contactId,
                    order: 1
                  })
                }
              })
            }
            if (landLord.length > 0) {
              landLord.forEach(user => {
                signers.push({
                  contactId: user.id,
                  order: 2
                })
              })
            }
            let attachToRefs = []
            if (this.tenancy) {
              attachToRefs.push(this.tenancy.id)
            }
            if (this.requestId !== '') {
              attachToRefs.push(this.requestId)
            }
            if (this.signatureInvitation.contacts.length > 0) {
              const contactIds = this.signatureInvitation.contacts.map(e => e.contactId)
              attachToRefs = attachToRefs.concat(contactIds)
            }
            let data = {
              accountId: this.accountId,
              name: this.documentName,
              mediaIds: this.mediasList.map(m => m.id),
              legalWeight: this.signatureInvitation.signType,
              signers: signers,
              note: this.signatureInvitation.note,
              attachToReferenceIds: attachToRefs,
              documentType: this.documentType.toUpperCase()
            }
            if (signers.length > 0) {
              DeepSignApi.signDocument(data).then((response) => {
                this.loading = false
                this.$emit('signature-added', response.signRequest)
                Vue.toasted.show(this.$tc('message.deepSign.messageSuccess'), { type: 'success' })
                this.hide()
                this.$nextTick(() => {
                  this.getCountOpenSignRequests()
                })
              })
                .catch(e => {
                  console.log(e)
                  this.loading = false
                  Vue.toasted.show(e.message, { type: 'error' })
                  this.hide()
                })
            } else {
              this.loading = false
              this.uploading = false
              Vue.toasted.show(this.$tc('message.savingErrors.mandatoryMissing'), { type: 'error' })
            }
          })
          .catch(e => {
            console.log(e)
          })
      }
    },
    cancelSignatureConfirmation() {
      DeepSignApi.cancelRequest(this.signatureRequest.id).then((response) => {
        this.$emit('cancel-signature', this.signatureRequest.id)
        this.hideCancelSignature()
        this.hide()
        this.$nextTick(() => {
          this.getCountOpenSignRequests()
        })
      })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(e.message, { type: 'error' })
        })
    },
    cancelSignature() {
      this.$refs.cancelSignatureModal?.show()
    },
    hideCancelSignature() {
      this.$refs.cancelSignatureModal?.hide()
    },
    hasDuplicateEmails() {
      const contacts = this.signatureInvitation.landlord.concat(this.signatureInvitation.tenant).concat(this.signatureInvitation.contacts).concat(this.signatureInvitation.guarantor)
      const duplicatedEmails = []
      const emailMap = new Map()
      for (const contact of contacts) {
        const email = contact.email.toLowerCase()
        if (emailMap.has(email)) {
          duplicatedEmails.push(email)
        } else {
          emailMap.set(email, true)
        }
      }
      this.checkEmails = duplicatedEmails
    }
  },
  validations() {
    return {
      checkEmails: {
        maxLength: maxLength(0)
      },
      documentName: {
        required
      },
      documentType: {
        required
      },
      mediasList: {
        required,
        minLength: minLength(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.custom-file-label) {
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;

  &::after {
    padding: 0.25rem 0.5rem !important;
  }
}

button.primary:focus {
  color: #fff !important;
  background-color: rgb(0, 68, 95) !important;
  border-color: rgb(0, 68, 95) !important;
}

.containerDeleteICon {
  .root {
    width: calc(100% - 30px);
    font-size: 0.825rem;
    display: inline-block;
  }
}

.attachment {
  .custom-file {
    font-size: 0.825rem;
    display: inline-block;
  }
}

.deleteBtn {
  max-width: 30px;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  height: calc(1.5em + 0.3rem);
  display: block;
  align-items: center;
  padding: 0 6px;

  &:hover {
    box-shadow: none !important;
    background-color: transparent !important;
  }
}

:deep(#autosuggest__input) {
  font-size: 0.825rem;
}

.adjustWidthInput {
  max-width: calc(100% - 30px - 0.25rem) !important;
}

.selected {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.08);
}

:deep(.tooltip) {
  z-index: 10000002 !important;
}

.min-width-btns {
  min-width: 100px;
}
</style>
