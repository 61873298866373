<template>
  <section
    id="ownerRentalObjects">
    <div>
      <ais-instant-search
        v-if="searchClient && !algoliaDataLoading"
        ref="instant-search"
        :search-client="searchClient"
        :index-name="algoliaIndex"
        :routing="routing">
        <app-refresh
          ref="app-refresh"
          @refresh-done="refreshDone" />
        <ais-stats>
          <template #default="{ nbHits }">
            <coozzy-page-title
              :title="$t('message.managementOverview.objects')"
              :count="nbHits"
              :count-text="$t('message.managementOverview.objects')"
              :clear-filter="checkfilter"
              @clear-filter="clickOnClear" />
          </template>
        </ais-stats>
        <!-- Filter -->
        <div class="row align-items-center">
          <div class="col-12 col-md mb-2 mb-md-0">
            <ais-search-box>
              <template #default="{ currentRefinement }">
                <debounced-algolia-search-box
                  ref="debouncedAlgolia"
                  :default-value="currentRefinement"
                  :delay="300" />
              </template>
            </ais-search-box>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="property.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchPropertyName', items) }}
                <coozzy-multiselect
                  v-model="searchPropertyName"
                  :options="items"
                  :multiple="true"
                  :placeholder="$t('message.propertiesView.tabs.property')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="building.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchBuildingName', items) }}
                <coozzy-multiselect
                  v-model="searchBuildingName"
                  :options="items"
                  :multiple="true"
                  :placeholder="$t('message.onBoardingTabs.building')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="address.zip"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchZip', items) }}
                <coozzy-multiselect
                  v-model="searchZip"
                  :options="sortedFilterValues(items, customLabel)"
                  :multiple="true"
                  :placeholder="$t('message.generic.zip')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="address.city"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchCity', items) }}
                <coozzy-multiselect
                  v-model="searchCity"
                  :options="items"
                  :multiple="true"
                  :placeholder="$t('message.generic.city')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="category"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('searchCategories', items) }}
                <coozzy-multiselect
                  v-model="searchCategories"
                  :options="sortedFilterValues(items, customLabelCategory)"
                  :multiple="true"
                  :placeholder="$t('message.onBoarding.buildings.objects.category.title')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelCategory"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-2">
            <ais-refinement-list
              attribute="subCategories"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('subCategories', items) }}
                <coozzy-multiselect
                  v-model="searchSubCategory"
                  :options="sortedFilterValues(items, customLabelSubCategory)"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelSubCategory"
                  :placeholder="$t('message.tableColumnHeadings.subCategory')"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0 mt-0 mt-md-1">
            <ais-refinement-list
              attribute="floor"
              :transform-items="transformItems"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('searchFloor', items) }}
                <coozzy-multiselect
                  v-model="searchFloor"
                  :options="sortedFloor(items)"
                  :multiple="true"
                  :placeholder="$t('message.tableColumnHeadings.floor')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelFloor"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refineResultWithNegativeNumbers(refine, $event.value)"
                  @remove="refineResultWithNegativeNumbers(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0 mt-0 mt-md-1">
            <ais-refinement-list
              attribute="rooms"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchRooms', items) }}
                <coozzy-multiselect
                  v-model="searchRooms"
                  :options="sortedFilterValues(items, customLabel)"
                  :multiple="true"
                  :placeholder="$t('message.tableColumnHeadings.rooms')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0 mt-0 mt-md-1">
            <ais-refinement-list
              attribute="status"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('searchStatus', items) }}
                <coozzy-multiselect
                  v-model="searchStatus"
                  :options="sortedFilterValues(items, customLabelStatus)"
                  :multiple="true"
                  :placeholder="$t('message.generic.currentStatus')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelStatus"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0 mt-0 mt-md-1">
            <ais-refinement-list
              attribute="futureRentalStatus"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('searchFutureStatus', items) }}
                <coozzy-multiselect
                  v-model="searchFutureStatus"
                  :options="sortedFilterValues(items, customLabelFutureStatus)"
                  :multiple="true"
                  :placeholder="$t('message.generic.futureStatus')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelFutureStatus"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0 mt-0 mt-md-1">
            <ais-refinement-list
              attribute="inMarketingStatus"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('searchMarketingStatus', items) }}
                <coozzy-multiselect
                  v-model="searchMarketingStatus"
                  :options="sortedFilterValues(items, customLabelMarketingStatus)"
                  :multiple="true"
                  :placeholder="$t('message.generic.marketingStatus')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelMarketingStatus"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="assignees.owner.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchOwnerAssignee', items) }}
                <coozzy-assignee-multiselect
                  v-model="searchOwnerAssignee"
                  :options="sortedFilterValues(getAssigneeData(items), customLabelAssignee)"
                  :multiple="true"
                  :placeholder="$t('message.tableColumnHeadings.adminAssignee')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelAssignee"
                  :track-by="'value'"
                  label="name"
                  group-values="employees"
                  group-label="employee"
                  :group-select="true"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="selectValue($event.value, items, refine)"
                  @remove="removeValue($event.value, items, refine)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="networkPartnerAsset.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchAdminAsset', items) }}
                <coozzy-multiselect
                  v-model="searchAdminAsset"
                  :options="sortedFilterValues(sortOptions(items), customLabel)"
                  :multiple="true"
                  :placeholder="$t('message.tableColumnHeadings.asset')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  label="name"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="selectValue($event.value, items, refine)"
                  @remove="removeValue($event.value, items, refine)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="networkPartnerAdministration.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchAdministration', items) }}
                <coozzy-multiselect
                  v-model="searchAdministration"
                  :options="sortedFilterValues(sortOptions(items), customLabel)"
                  :multiple="true"
                  :placeholder="$t('message.tableColumnHeadings.administration')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  label="name"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="selectValue($event.value, items, refine)"
                  @remove="removeValue($event.value, items, refine)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="networkPartnerMarketing.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchAdminMarketing', items) }}
                <coozzy-multiselect
                  v-model="searchAdminMarketing"
                  :options="sortedFilterValues(sortOptions(items), customLabel)"
                  :multiple="true"
                  :placeholder="$t('message.tableColumnHeadings.marketing')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  label="name"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="selectValue($event.value, items, refine)"
                  @remove="removeValue($event.value, items, refine)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1">
            <ais-refinement-list
              attribute="networkPartnerAccounting.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchAccounting', items) }}
                <coozzy-multiselect
                  v-model="searchAccounting"
                  :options="sortedFilterValues(sortOptions(items), customLabel)"
                  :multiple="true"
                  :placeholder="$t('message.networkPartner.subTypes.accounting')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  label="name"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="selectValue($event.value, items, refine)"
                  @remove="removeValue($event.value, items, refine)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-2">
            <ais-refinement-list
              attribute="preferredCommunicationChannel"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchPreferredCommunicationChannel', items) }}
                <coozzy-multiselect
                  v-model="searchPreferredCommunicationChannel"
                  :options="sortedFilterValues(sortOptions(items), customLabel)"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelChannel"
                  :placeholder="$t('message.contact.preferredCommunicationChannel')"
                  :track-by="'value'"
                  label="name"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="selectValue($event.value, items, refine)"
                  @remove="removeValue($event.value, items, refine)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-2">
            <ais-refinement-list
              attribute="tags"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchTags', items) }}
                <coozzy-multiselect
                  v-model="searchTags"
                  :options="sortedFilterValues(items, customLabelTags)"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelTags"
                  :placeholder="$t('message.generic.tags')"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-2">
            <ais-refinement-list
              attribute="debitUntilFilter"
              :limit="100">
              <div
                id="date-picker-container"
                slot-scope="{items}">
                {{ defaultRefining('searchDebitUntilFilter', items) }}
                <coozzy-form-input
                  v-model="searchDebitUntilFilterLabel"
                  :placeholder="$t('message.tableColumnHeadings.debitUntil')" />
                <date-picker
                  id="date-picker-input"
                  v-model="searchDebitUntilFilter"
                  :default-value="defaultCalendar"
                  value-type="format"
                  format="M/YYYY"
                  title-format="M"
                  :multiple="true"
                  type="month"
                  :lang="getLang"
                  @calendar-change="dateChanged">
                  <div
                    slot="footer">
                    <coozzy-form-checkbox
                      v-model="noDebitUntil"
                      :initial="noDebitUntil"
                      :class="'p-0 w-100'"
                      name="noDebitUntil"
                      button>
                      {{ $t('message.tableColumnHeadings.noDebitUntil.title') }}
                      <coozzy-info-circle-icon
                        v-b-tooltip.hover.html="$t('message.tableColumnHeadings.noDebitUntil.tooltip')"
                        class="ml-1" />
                    </coozzy-form-checkbox>
                  </div>
                </date-picker>
              </div>
            </ais-refinement-list>
          </div>
        </div>
        <!-- eslint-disable vue/attribute-hyphenation -->
        <ais-configure
          :hitsPerPage="nbrPerPage"
          :filters="filterQuery" />
        <!-- eslint-enable vue/attribute-hyphenation -->
        <div
          id="rowConfig"
          class="row mt-2 align-items-center">
          <div class="col-md-2">
            <coozzy-dropdown
              design="green"
              size="sm"
              class="w-100"
              :text="selectedEntriesText">
              <coozzy-dropdown-item
                v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator)"
                :disabled="selectedEntries.length === 0"
                @click="createTicket('TICKET_TYPE_TASK')">
                {{ $t('message.ticketOverview.createTicket') }}
              </coozzy-dropdown-item>
              <!-- <coozzy-dropdown-item
                v-if="isTicketingEnabled && (isEditor || isTicketEditor)"
                :disabled="selectedEntries.length === 0"
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item> -->
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="showActivity()">
                {{ $t('message.contact.activity.create') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="$refs['reminder-creation'].show()">
                {{ $t('message.calendar.reminder.create') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="serialEmail()">
                {{ selectedEntries.length === 1 ? $t('message.generic.oneEmail') : $t('message.generic.serialEmail') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="serialLetter()">
                {{ selectedEntries.length === 1 ? $t('message.generic.oneSerialLetter') : $t('message.generic.serialLetter') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-space-divider />
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="openSetAssigneeModal(null)">
                {{ $t('message.marketingObjects.setAssignee') }}
              </coozzy-dropdown-item>
              <div
                v-if="isAdmin"
                class="row m-0"
                :class="selectedEntries.length === 0 || hasSelectedEntriesAssetSet ? 'coozzyDropdownItemTooltip' : ''">
                <coozzy-dropdown-item
                  class="col pr-0 pl-0"
                  :disabled="selectedEntries.length === 0 || hasSelectedEntriesAssetSet"
                  @click="openSetClientModal('MANAGEMENT_SCOPE')">
                  {{ $t('message.managementObjectsConfig.bulkActions.setAdministrationClient') }}
                </coozzy-dropdown-item>
                <div class="pl-0">
                  <coozzy-info-circle-icon
                    v-if="hasSelectedEntriesAssetSet"
                    v-b-tooltip.hover.html="$t('message.managementObjectsConfig.bulkActions.tooltip.administrationAction')"
                    class="mt-2" />
                </div>
              </div>
              <div
                v-if="isAdmin"
                class="row m-0"
                :class="selectedEntries.length === 0 || hasSelectedEntriesAssetSet ? 'coozzyDropdownItemTooltip' : ''">
                <coozzy-dropdown-item
                  class="col pr-0 pl-0"
                  :disabled="selectedEntries.length === 0 || hasSelectedEntriesAssetSet"
                  @click="openSetClientModal('MARKETING_SCOPE')">
                  {{ $t('message.managementObjectsConfig.bulkActions.setMarketingClient') }}
                </coozzy-dropdown-item>
                <div class="pl-0">
                  <coozzy-info-circle-icon
                    v-if="hasSelectedEntriesAssetSet"
                    v-b-tooltip.hover.html="$t('message.managementObjectsConfig.bulkActions.tooltip.marketingAction')"
                    class="mt-2" />
                </div>
              </div>
              <coozzy-dropdown-space-divider
                v-if="isCompanyAdmin" />
              <div
                v-if="isCompanyAdmin"
                class="row m-0"
                :class="selectedEntries.length === 0 || isTenanciesActivated > 0 ? 'coozzyDropdownItemTooltip' : ''">
                <coozzy-dropdown-item
                  class="col pr-0 pl-0"
                  :disabled="selectedEntries.length === 0 || isTenanciesActivated > 0"
                  @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                  {{ $t('message.generic.delete') }}
                </coozzy-dropdown-item>
                <div class="pl-0">
                  <coozzy-info-circle-icon
                    v-if="isTenanciesActivated > 0"
                    v-b-tooltip.hover.html="$t('message.deleteErrors.deleteActiveTenancyTooltip')"
                    class="mt-2" />
                </div>
              </div>
            </coozzy-dropdown>
          </div>
          <div class="col-md-2">
            <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
              <coozzy-form-checkbox
                v-model="selectAllEntries"
                :initial="selectAllEntries"
                :class="'p-0 w-100 btn-height-auto'"
                name="selectAllEntries"
                button>
                {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
              </coozzy-form-checkbox>
            </template>
            <ais-clear-refinements :included-attributes="['query', 'debitUntilFilter', 'subCategories', 'floor', 'status', 'futureRentalStatus', 'inMarketingStatus', 'address.zip', 'category', 'rooms', 'assignees.owner.name', 'networkPartnerAdministration.name', 'networkPartnerAsset.name','networkPartnerMarketing.name', 'networkPartnerAccounting.name', 'property.name', 'building.name', 'address.city', 'preferredCommunicationChannel', 'tags']">
              <template #default="{ canRefine, refine }">
                {{ setCanRefine(canRefine) }}
                <a
                  v-if="canRefine"
                  ref="clearClick"
                  @click="initFilter(refine)" />
              </template>
            </ais-clear-refinements>
          </div>
          <div
            v-if="isCompanyAdmin === true"
            class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
            <div
              @click="openAssembleListModal()">
              <coozzy-filters-icon
                v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
            </div>
          </div>
          <div
            class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
            :class="isCompanyAdmin !== true ? 'offset-md-6' : ''">
            <coozzy-form-select
              v-model="nbrPerPage"
              name="numberEntries"
              class="select-entries float-right">
              <option value="10">
                10 {{ $t('message.generic.entries') }}
              </option>
              <option value="50">
                50 {{ $t('message.generic.entries') }}
              </option>
              <option value="100">
                100 {{ $t('message.generic.entries') }}
              </option>
              <option value="500">
                500 {{ $t('message.generic.entries') }}
              </option>
              <option value="1000">
                1000 {{ $t('message.generic.entries') }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
        <ais-state-results
          class="shadow mt-3 mb-3">
          <template #default="{ hits, results }">
            {{ getState(results) }}
            <b-table
              ref="objectsTable"
              class="m-0"
              bordered
              hover
              responsive="true"
              stacked="md"
              selectable
              select-mode="single"
              :fields="fields"
              :items="getHits(hits)"
              @row-clicked="onRowClicked"
              @row-middle-clicked="onMiddlelicked">
              <!-- Busy state -->
              <div
                slot="table-busy"
                class="text-center text-danger my-2">
                <coozzy-spinner />
              </div>

              <!-- Headings -->
              <template #head(checkbox)>
                <coozzy-form-checkbox
                  ref="header_checkbox"
                  @change="(value) => { handleCheckboxesInsideTable(value) }" />
              </template>
              <template #head(status)>
                {{ $t('message.tableColumnHeadings.status') }}
              </template>
              <template #head(numericId)>
                {{ $t('message.tableColumnHeadings.numericId') }}
              </template>
              <template #head(objectNumber)>
                {{ $t('message.tableColumnHeadings.objectNumber') }}
              </template>
              <template #head(objects)>
                {{ $t('message.tableColumnHeadings.objects') }}
              </template>
              <template #head(address)>
                {{ $t('message.tableColumnHeadings.address') }}
              </template>
              <template #head(type)>
                {{ $t('message.tableColumnHeadings.type') }}
              </template>
              <template #head(floor)>
                {{ $t('message.tableColumnHeadings.floor') }}
              </template>
              <template #head(rooms)>
                {{ $t('message.tableColumnHeadings.rooms') }}
              </template>
              <template #head(area)>
                {{ $t('message.tableColumnHeadings.area') }}
              </template>
              <template #head(tenant)>
                {{ $t('message.tableColumnHeadings.tenant') }}
              </template>
              <template #head(startOfContract)>
                {{ $t('message.tableColumnHeadings.startOfContract') }}
              </template>
              <template #head(endOfContract)>
                {{ $t('message.tableColumnHeadings.endOfContract') }}
              </template>
              <template #head(debitUntil)>
                {{ $t('message.tableColumnHeadings.debitUntil') }}
              </template>
              <template #head(subtenant)>
                {{ $t('message.tableColumnHeadings.subtenant') }}
              </template>
              <template #head(netRent)>
                <span>{{ $t('message.tableColumnHeadings.netRent') }}</span>
              </template>
              <template #head(grossRent)>
                <span>{{ $t('message.tableColumnHeadings.grossRent') }}</span>
              </template>
              <template #head(partner)>
                {{ $t('message.tableColumnHeadings.partner') }}
              </template>
              <template #head(assignee)>
                {{ $t('message.tableColumnHeadings.assignee') }}
              </template>
              <template #head(m2PerYear)>
                {{ $t('message.tableColumnHeadings.m2PerYear') }}
              </template>
              <template #head(marketNetRent)>
                {{ $t('message.tableColumnHeadings.marketNetRent') }}
              </template>
              <template #head(targetNetRent)>
                {{ $t('message.tableColumnHeadings.targetNetRent') }}
              </template>
              <template #head(marketGrossRent)>
                {{ $t('message.tableColumnHeadings.marketGrossRent') }}
              </template>
              <template #head(targetGrossRent)>
                {{ $t('message.tableColumnHeadings.targetGrossRent') }}
              </template>
              <template #head(subCategory)>
                {{ $t('message.tableColumnHeadings.subCategory') }}
              </template>
              <template #head(HeatingAdditionalCosts)>
                {{ $t('message.tableColumnHeadings.HeatingAdditionalCosts') }}
              </template>
              <!-- Data -->
              <template
                #cell(checkbox)="data">
                <coozzy-form-checkbox
                  :id="'check_' + data.item.objectID"
                  @change="(value) => { itemSelected(data.item, value) }" />
              </template>
              <template #cell(status)="data">
                <div
                  class="color-box mr-2"
                  :class="getFullStatusColorClass(data.item)" />
                <!-- eslint-disable vue/no-v-html -->
                <span v-html="getFullStatusText(data.item)" />
                <!-- eslint-enable vue/no-v-html -->
              </template>
              <template #cell(numericId)="data">
                {{ data.item.numericId | displayOptionalValue }}
              </template>
              <template #cell(objectNumber)="data">
                {{ data.item.number | displayOptionalValue }}
              </template>
              <template #cell(objects)="data">
                {{ data.item.name | displayOptionalValue }}
              </template>
              <template #cell(address)="data">
                <address-text :address="data.item.address" />
              </template>
              <template
                #cell(type)="data">
                <template v-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                  {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template #cell(floor)="data">
                <div class="text-center">
                  {{ formatFloor(data.item.floor) }}
                </div>
              </template>
              <template #cell(rooms)="data">
                <div class="text-center">
                  {{ data.item.rooms | displayOptionalValue }}
                </div>
              </template>
              <template
                #cell(area)="data">
                {{ getLA(data.item) | formatArea }}
              </template>
              <template #cell(tenant)="data">
                <template v-if="data.item.tenancy && data.item.tenancy.tenants && data.item.tenancy.tenants.length > 0">
                  <router-link
                    v-for="(tenant, indexTenant) in data.item.tenancy.tenants"
                    :key="tenant.contactId"
                    :to="{ name: 'OwnerContactDetailsView', params: { id: tenant.contactId } }">
                    {{ tenant.name }}<span v-if="indexTenant < data.item.tenancy.tenants.length - 1">,</span><br>
                  </router-link>
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template #cell(startOfContract)="data">
                {{ data.item.tenancy.startingAt | objectToDate }}
              </template>
              <template #cell(endOfContract)="data">
                {{ data.item.tenancy.endingAt | objectToDate }}
              </template>
              <template #cell(debitUntil)="data">
                <template v-if="data.item.debitUntil">
                  {{ data.item.debitUntil | objectToDate }}
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template #cell(subtenant)="data">
                <template v-if="data.item.tenancy && data.item.tenancy.subTenants && data.item.tenancy.subTenants.length > 0">
                  <template
                    v-for="(subtenant, indexSubTenant) in data.item.tenancy.subTenants">
                    <template
                      v-if="subtenant.tenant.length > 0">
                      <router-link
                        v-for="(tenant, indexTenant) in subtenant.tenant"
                        :key="tenant.contactId"
                        :to="{ name: 'OwnerContactDetailsView', params: { id: tenant.contactId } }">
                        {{ tenant.name }}<span v-if="indexTenant < subtenant.tenant.length - 1 || indexSubTenant < data.item.tenancy.subTenants.length - 1">,</span><br>
                      </router-link>
                    </template>
                  </template>
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template
                #cell(netRent)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.tenancy && data.item.tenancy.coldRentPerMonth">
                    <span>{{ data.item.tenancy.coldRentPerMonth | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                  <coozzy-info-circle-icon
                    v-if="data.item.tenancy.taxable || data.item.tenancy.opted"
                    v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"
                    class="ml-1" />
                </div>
              </template>
              <template
                #cell(m2PerYear)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.tenancy && data.item.tenancy.coldRentM2PerYear">
                    <span>{{ Math.round(data.item.tenancy.coldRentM2PerYear) | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template
                #cell(marketNetRent)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.pricing && data.item.pricing.marketNetRent">
                    <span>{{ data.item.pricing.marketNetRent | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template
                #cell(targetNetRent)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.pricing && data.item.pricing.targetNetRent">
                    <span>{{ data.item.pricing.targetNetRent | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template
                #cell(marketGrossRent)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.pricing && data.item.pricing.marketGrossRent">
                    <span>{{ data.item.pricing.marketGrossRent | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template
                #cell(targetGrossRent)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.pricing && data.item.pricing.targetGrossRent">
                    <span>{{ data.item.pricing.targetGrossRent | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template #cell(grossRent)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.tenancy && data.item.tenancy.grossRentPerMonth">
                    <span>{{ data.item.tenancy.grossRentPerMonth | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                  <coozzy-info-circle-icon
                    v-if="data.item.tenancy.taxable || data.item.tenancy.opted"
                    v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"
                    class="ml-1" />
                </div>
              </template>
              <template #cell(HeatingAdditionalCosts)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.tenancy && data.item.tenancy.additionalCosts">
                    <span>{{ data.item.tenancy.additionalCosts | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template #cell(subCategory)="data">
                <template v-if="data.item.subCategories?.length > 0">
                  <template v-for="(subCat, subCatIndex) in data.item.subCategories">
                    <span
                      v-if="subCat !== 'UNDEFINED_SUB_CATEGORY'"
                      :key="subCatIndex">
                      {{ $t(`message.onBoarding.buildings.objects.subCategory.` + subCat?.toLowerCase()) }}
                    </span>
                    <template v-else>
                      -
                    </template>
                  </template>
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template #cell(assignee)="data">
                <coozzy-button
                  design="default"
                  size="small"
                  @click="openSetAssigneeModal(data.item)">
                  {{
                    data.item.assignees && data.item.assignees.owner && data.item.assignees.owner.name && data.item.assignees.owner.name !== 'Unassigned Nicht zugeordnet Non assegnato' ? data.item.assignees.owner.name : '-'
                  }}
                </coozzy-button>
              </template>
              <template #cell(partner)="data">
                <template v-if="data.item.networkPartners.length > 0">
                  <span
                    v-if="data.item.networkPartners.find(element => element.scope ==='asset')"
                    v-b-tooltip="{container: '#ownerRentalObjects', title: $t('message.generic.accountId') + data.item.networkPartners.find(element => element.scope ==='asset').accountId}">
                    <img
                      height="20px"
                      src="/img/assetbase-icon.png"
                      alt="asset-base">
                    <router-link
                      class="network-link"
                      :to="redirectToNetworkDetailPage(data.item.networkPartners.find(element => element.scope ==='asset').accountId)">
                      {{ data.item.networkPartners.find(element => element.scope === 'asset').name }}
                    </router-link>
                    <br>
                  </span>
                  <span
                    v-if="data.item.networkPartners.find(element => element.scope ==='marketing')"
                    v-b-tooltip="{container: '#ownerRentalObjects', title: $t('message.generic.accountId') + data.item.networkPartners.find(element => element.scope ==='marketing').accountId}">
                    <img
                      height="20px"
                      src="/img/marketbase-icon.png"
                      alt="market-base">
                    <router-link
                      class="network-link"
                      :to="redirectToNetworkDetailPage(data.item.networkPartners.find(element => element.scope ==='marketing').accountId)">
                      {{ data.item.networkPartners.find(element => element.scope === 'marketing').name }}
                    </router-link>
                    <br>
                  </span>
                  <span
                    v-if="data.item.networkPartners.find(element => element.scope ==='management')"
                    v-b-tooltip="{container: '#ownerRentalObjects', title: $t('message.generic.accountId') + data.item.networkPartners.find(element => element.scope ==='management').accountId}">
                    <img
                      height="20px"
                      src="/img/adminbase-icon.png"
                      alt="admin-base">
                    <router-link
                      class="network-link"
                      :to="redirectToNetworkDetailPage(data.item.networkPartners.find(element => element.scope ==='management').accountId)">
                      {{ data.item.networkPartners.find(element => element.scope === 'management').name }}
                    </router-link>
                    <br>
                  </span>
                  <span
                    v-if="data.item.networkPartners.find(element => element.scope ==='accounting')"
                    v-b-tooltip="{container: '#ownerRentalObjects', title: $t('message.generic.accountId') + data.item.networkPartners.find(element => element.scope ==='accounting').accountId}">
                    <img
                      height="20px"
                      src="/img/financebase-icon.png"
                      alt="admin-base">
                    <router-link
                      class="network-link"
                      :to="redirectToNetworkDetailPage(data.item.networkPartners.find(element => element.scope ==='accounting').accountId)">
                      {{ data.item.networkPartners.find(element => element.scope === 'accounting').name }}
                    </router-link>
                  </span>
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <!-- footer total -->
              <template
                v-if="fieldsIncludeCalculatedRent()"
                #custom-foot>
                <!-- Total -->
                <tr>
                  <th
                    v-for="field in fields"
                    :key="field.key"
                    class="shadow-none pb-1 pl-2 pr-2 pt-2"
                    :class="field.key === 'status' ? 'text-left' : 'text-right'">
                    <template v-if="field.key === 'status'">
                      {{ $t('message.generic.total') }}
                    </template>
                    <template v-else-if="field.key === 'netRent'">
                      {{ totalNetRent.sum | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'grossRent'">
                      {{ totalGrossRent.sum | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'marketNetRent'">
                      {{ totalMarketNetRent.sum | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'targetNetRent'">
                      {{ totalTargetNetRent.sum | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'marketGrossRent'">
                      {{ totalMarketGrossRent.sum | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'targetGrossRent'">
                      {{ totalTargetGrossRent.sum | formatPrice }}
                    </template>
                    <template v-else>
                      <span />
                    </template>
                  </th>
                </tr>
                <!-- Total without vacancy -->
                <tr>
                  <th
                    v-for="field in fields"
                    :key="field.key"
                    class="shadow-none pl-2 pr-2 p-1"
                    :class="field.key === 'status' ? 'text-left alignFoot' : 'text-right'">
                    <template v-if="field.key === 'status'">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <span v-html="$t('message.generic.totalWithoutVacancy')" />
                    </template>
                    <template v-else-if="field.key === 'netRent'">
                      {{ totalNetRent.tenancy | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'grossRent'">
                      {{ totalGrossRent.tenancy | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'marketNetRent'">
                      {{ totalMarketNetRent.tenancy | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'targetNetRent'">
                      {{ totalTargetNetRent.tenancy | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'marketGrossRent'">
                      {{ totalMarketGrossRent.tenancy | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'targetGrossRent'">
                      {{ totalTargetGrossRent.tenancy | formatPrice }}
                    </template>
                    <template v-else>
                      <span />
                    </template>
                  </th>
                </tr>
                <!-- Total vacancy -->
                <tr>
                  <th
                    v-for="field in fields"
                    :key="field.key"
                    class="pt-1 pl-2 pr-2 pb-2"
                    :class="field.key === 'status' ? 'text-left alignFoot' : 'text-right'">
                    <template v-if="field.key === 'status'">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <span v-html="$t('message.generic.totalVacancy')" />
                    </template>
                    <template v-else-if="field.key === 'netRent'">
                      {{ totalNetRent.vacancy | formatPrice }} ({{ ((totalNetRent.vacancy / totalNetRent.sum) * 100) || 0 | formatPercent }})
                    </template>
                    <template v-else-if="field.key === 'grossRent'">
                      {{ totalGrossRent.vacancy | formatPrice }} ({{ ((totalGrossRent.vacancy / totalGrossRent.sum) * 100) || 0 | formatPercent }})
                    </template>
                    <template v-else-if="field.key === 'marketNetRent'">
                      {{ totalMarketNetRent.vacancy | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'targetNetRent'">
                      {{ totalTargetNetRent.vacancy | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'marketGrossRent'">
                      {{ totalMarketGrossRent.vacancy | formatPrice }}
                    </template>
                    <template v-else-if="field.key === 'targetGrossRent'">
                      {{ totalTargetGrossRent.vacancy | formatPrice }}
                    </template>
                    <template v-else>
                      <span />
                    </template>
                  </th>
                </tr>
              </template>
            </b-table>
            <b-table
              id="responsiveFooterTable"
              stacked
              :items="responsiveTableItems">
              <template #head(total)>
                {{ $t('message.generic.totalWithoutVacancy') }}
              </template>
              <template #head(netRent)>
                {{ $t('message.onBoarding.buildings.objects.pricing.coldRent') }}
              </template>
              <template #head(grossRent)>
                {{ $t('message.onBoarding.buildings.objects.pricing.totalRent') }}
              </template>
              <template
                #cell(netRent)>
                {{ totalNetRent.sum | formatPrice }}
              </template>
              <template
                #cell(grossRent)>
                {{ totalGrossRent.sum | formatPrice }}
              </template>
            </b-table>
          </template>
        </ais-state-results>
        <pagination-algolia
          ref="paginator"
          @changed-page="clearCheckbox()" />
      </ais-instant-search>
    </div>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
    <set-advert-relations
      ref="setClientModal"
      :title="scope === 'MARKETING_SCOPE' ? $t('message.managementObjectsConfig.bulkActions.setMarketingClient') : scope === 'ASSET_SCOPE' ? $t('message.managementObjectsConfig.bulkActions.setAssetManager') : $t('message.managementObjectsConfig.bulkActions.setAdministrationClient')"
      :element-list="filtredNetworkPartner()"
      type="networkPartner"
      @save="setClient" />
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.advertisementManagementOverview.deletion.confirmation.title')">
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col p-0">
          <p>{{ $t('message.advertisementManagementOverview.deletion.confirmation.text') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteObject()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <reminder-creation-modal
      ref="reminder-creation"
      :references-ids="selectedEntriesIds"
      :source="'object'"
      @new-reminder="clearCheckbox" />
    <set-advert-relations
      v-if="contactList"
      ref="setAssigneeModal"
      :key="Math.random().toString(36).substr(2, 9)"
      :title="$t('message.advertisementsOverview.setAssignee')"
      :element-list="contactList"
      type="employee"
      @save="setAssignee" />
    <b-modal
      :id="'shareconfirmationModal'"
      ref="shareconfirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="scope === 'MARKETING_SCOPE' ? $t('message.managementObjectsConfig.modal.titleSetMarketingClient') : scope === 'ASSET_SCOPE' ? $t('message.managementObjectsConfig.modal.titleSetAssetManager') : $t('message.managementObjectsConfig.modal.titleSetAdministrationClient')">
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col">
          <p>{{ scope === 'MARKETING_SCOPE' ? $t('message.managementObjectsConfig.modal.bodySetMarketingClient', { SELECTED_OBJECT_COUNT: selectedEntriesIds.length , SELECTED_MARKETING_NAME: selectedClientName }) : scope === 'ASSET_SCOPE' ? $t('message.managementObjectsConfig.modal.bodySetAssetManager', { SELECTED_OBJECT_COUNT: selectedEntriesIds.length , SELECTED_ASSET_NAME: selectedClientName }) : $t('message.managementObjectsConfig.modal.bodySetAdministrationClient', { SELECTED_OBJECT_COUNT: selectedEntriesIds.length , SELECTED_ADMINISTRATION_NAME: selectedClientName }) }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('shareconfirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            :disabled="loading"
            @click="saveShares()">
            {{ $t('message.managementObjectsConfig.modal.confirmationBtn') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <create-activity-modal
      ref="activity-creation"
      :key="keyActivity"
      :ids="selectedEntriesIds"
      @activity-created="clearCheckbox" />
  </section>
</template>

<script>
import SetAdvertRelations from '../marketingBasic/components/advertisements/management/SetAdvertRelations.vue'
import ShareApi from '../misc/apis/ShareApi'
import AddressText from '../framework/components/misc/AddressText'
import { onboarding } from '@/mixins/onboarding'
import { obj } from '@/mixins/object'
import { algolia } from '@/mixins/algolia'
import { tables } from '@/mixins/tables'
import { formatting } from '@/mixins/formatting'
import ObjectApi from '../misc/apis/ObjectApi'
import NetworkPartnerAPI from '../misc/apis/NetworkPartnerApi'
import Vue from 'vue'
import CoozzyFormCheckbox from '../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import CoozzyAssigneeMultiselect from '../framework/components/multiselect/CoozzyAssigneeMultiselect'
import CoozzyMultiselect from '../framework/components/multiselect/CoozzyMultiselect'
import CoozzyDropdown from '../framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '../framework/components/dropdown/CoozzyDropdownItem'
import CoozzyFormSelect from '../framework/components/form/select/CoozzyFormSelect'
import CoozzyDropdownSpaceDivider from '../framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import CoozzyPageTitle from '../framework/layout/CoozzyPageTitle'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import AppRefresh from '../framework/components/RefreshAlgolia'
import DebouncedAlgoliaSearchBox from '../framework/components/DebouncedAlgoliaSearchBox'
import PaginationAlgolia from '../framework/components/PaginationAlgolia'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import ContactApi from '../misc/apis/ContactApi'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { routeChecks } from '@/mixins/routeChecks'
import { user } from '@/mixins/user'
import { mail } from '@/mixins/mail'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import AssembleListModal from '@/contact/components/AssembleListModal'
import SearchApi from '@/misc/apis/SearchApi'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/de'
import { share } from '@/mixins/share'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'OwnerRentalObjectOverview',
  components: {
    CoozzyFormInput,
    DebouncedAlgoliaSearchBox,
    AppRefresh,
    PaginationAlgolia,
    CreateActivityModal,
    ReminderCreationModal,
    CoozzyPageTitle,
    CoozzySpinner,
    CoozzyDropdownSpaceDivider,
    CoozzyFormSelect,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyMultiselect,
    CoozzyAssigneeMultiselect,
    CoozzyButton,
    CoozzyFormCheckbox,
    AddressText,
    SetAdvertRelations,
    CoozzyInfoCircleIcon,
    CoozzyFiltersIcon,
    AssembleListModal,
    DatePicker
  },
  mixins: [user, onboarding, obj, formatting, algolia, tables, mail, routeChecks, share],
  props: {
    filter: {
      type: String,
      default: null
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.management.objects')
    }
  },
  data() {
    return {
      algoliaIndex: 'object',
      searchPropertyName: [],
      searchBuildingName: [],
      keyActivity: 0,
      searchCity: [],
      showFirst: false,
      checkfilter: false,
      searchOwnerAssignee: [],
      searchAdminOwner: [],
      searchAdminAsset: [],
      searchAdminMarketing: [],
      searchCategories: [],
      appRefreshAction: '',
      loading: false,
      algoliaDataLoading: false,
      searchRooms: [],
      searchFloor: [],
      searchZip: [],
      searchStatus: [],
      searchSubCategory: [],
      searchFutureStatus: [],
      searchMarketingStatus: [],
      searchAdministration: [],
      searchAccounting: [],
      searchPreferredCommunicationChannel: [],
      searchTags: [],
      sharesList: [],
      networkPartnerList: [],
      selectedEntries: [],
      selectedEntriesActivatedTenancy: [],
      selectedClient: null,
      scope: null,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle alignFoot',
          tdClass: 'align-middle status-td-icon',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objectNumber',
          label: this.$t('message.tableColumnHeadings.objectNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'numericId',
          label: this.$t('message.tableColumnHeadings.numericId'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'area',
          label: this.$t('message.tableColumnHeadings.area'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'netRent',
          label: this.$t('message.tableColumnHeadings.netRent'),
          thClass: 'align-middle text-right case-total',
          tdClass: 'align-middle text-right case-total',
          selected: true
        },
        {
          key: 'grossRent',
          label: this.$t('message.tableColumnHeadings.grossRent'),
          thClass: 'align-middle text-right case-total',
          tdClass: 'align-middle text-right case-total',
          selected: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'partner',
          label: this.$t('message.tableColumnHeadings.partner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'subtenant',
          label: this.$t('message.tableColumnHeadings.subtenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'startOfContract',
          label: this.$t('message.tableColumnHeadings.startOfContract'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'endOfContract',
          label: this.$t('message.tableColumnHeadings.endOfContract'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'm2PerYear',
          label: this.$t('message.tableColumnHeadings.m2PerYear'),
          thClass: 'align-middle text-right case-total',
          tdClass: 'align-middle text-right case-total',
          selected: false
        },
        {
          key: 'marketNetRent',
          label: this.$t('message.tableColumnHeadings.marketNetRent'),
          thClass: 'align-middle text-right case-total',
          tdClass: 'align-middle text-right case-total',
          selected: false
        },
        {
          key: 'targetNetRent',
          label: this.$t('message.tableColumnHeadings.targetNetRent'),
          thClass: 'align-middle text-right case-total',
          tdClass: 'align-middle text-right case-total',
          selected: false
        },
        {
          key: 'marketGrossRent',
          label: this.$t('message.tableColumnHeadings.marketGrossRent'),
          thClass: 'align-middle text-right case-total',
          tdClass: 'align-middle text-right case-total',
          selected: false
        },
        {
          key: 'targetGrossRent',
          label: this.$t('message.tableColumnHeadings.targetGrossRent'),
          thClass: 'align-middle text-right case-total',
          tdClass: 'align-middle text-right case-total',
          selected: false
        },
        {
          key: 'debitUntil',
          label: this.$t('message.tableColumnHeadings.debitUntil'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'subCategory',
          label: this.$t('message.tableColumnHeadings.subCategory'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'HeatingAdditionalCosts',
          label: this.$t('message.tableColumnHeadings.HeatingAdditionalCosts'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        }
      ],
      contactList: [],
      selectedObjectIds: null,
      responsiveTableItems: [
        { totalWithoutVacancy: '', netRent: this.totalNetRent, grossRent: this.totalGrossRent }
      ],
      objectsPage: [],
      selectedFields: [],
      searchDebitUntilFilter: [],
      searchDebitUntilFilterLabel: '',
      noDebitUntil: false,
      defaultCalendar: new Date(),
      paramsAlgolia: {
        query: '',
        params: '',
        index: ''
      },
      nbrTotalOfHits: 0,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    filterQuery() {
      let query = 'condominiumOwnership:false'

      if (this.searchDebitUntilFilter.length === 0 && this.noDebitUntil) {
        query += ' AND debitUntilFilter = 0'
      }
      if (this.searchDebitUntilFilter.length > 0 && this.noDebitUntil) {
        query += ` AND debitUntilFilter <= ${this.dateToTimestamp(this.searchDebitUntilFilter, 'start')} AND (inactiveDate = 0 OR inactiveDate >= ${this.dateToTimestamp(this.searchDebitUntilFilter, 'start')})`
      }
      if (this.searchDebitUntilFilter.length > 0 && !this.noDebitUntil) {
        query += ` AND debitUntilFilter >= ${this.dateToTimestamp(this.searchDebitUntilFilter, 'start')} AND debitUntilFilter <= ${this.dateToTimestamp(this.searchDebitUntilFilter, 'end')}`
      }

      return query
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    totalNetRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const coldRent = this.objectsPage[i].tenancy?.coldRentPerMonth > 0 ? this.objectsPage[i].tenancy?.coldRentPerMonth : 0
          tenancy += coldRent
        } else {
          const coldRent = this.objectsPage[i].tenancy?.coldRentPerMonth > 0 ? this.objectsPage[i].tenancy?.coldRentPerMonth : 0
          vacancy += coldRent
        }
      }
      return { tenancy: tenancy, vacancy: vacancy, sum: tenancy + vacancy }
    },
    totalGrossRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const grossRent = this.objectsPage[i].tenancy?.grossRentPerMonth > 0 ? this.objectsPage[i].tenancy?.grossRentPerMonth : 0
          tenancy += grossRent
        } else {
          const grossRent = this.objectsPage[i].tenancy?.grossRentPerMonth > 0 ? this.objectsPage[i].tenancy?.grossRentPerMonth : 0
          vacancy += grossRent
        }
      }
      return { tenancy: tenancy, vacancy: vacancy, sum: tenancy + vacancy }
    },
    totalMarketNetRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const marketNetRent = this.objectsPage[i].pricing?.marketNetRent > 0 ? this.objectsPage[i].pricing?.marketNetRent : 0
          tenancy += marketNetRent
        } else {
          const marketNetRent = this.objectsPage[i].pricing?.marketNetRent > 0 ? this.objectsPage[i].pricing?.marketNetRent : 0
          vacancy += marketNetRent
        }
      }
      return { tenancy: tenancy, vacancy: vacancy, sum: tenancy + vacancy }
    },
    totalTargetNetRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const targetNetRent = this.objectsPage[i].pricing?.targetNetRent > 0 ? this.objectsPage[i].pricing?.targetNetRent : 0
          tenancy += targetNetRent
        } else {
          const targetNetRent = this.objectsPage[i].pricing?.targetNetRent > 0 ? this.objectsPage[i].pricing?.targetNetRent : 0
          vacancy += targetNetRent
        }
      }
      return { tenancy: tenancy, vacancy: vacancy, sum: tenancy + vacancy }
    },
    totalMarketGrossRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const marketGrossRent = this.objectsPage[i].pricing?.marketGrossRent > 0 ? this.objectsPage[i].pricing?.marketGrossRent : 0
          tenancy += marketGrossRent
        } else {
          const marketGrossRent = this.objectsPage[i].pricing?.marketGrossRent > 0 ? this.objectsPage[i].pricing?.marketGrossRent : 0
          vacancy += marketGrossRent
        }
      }
      return { tenancy: tenancy, vacancy: vacancy, sum: tenancy + vacancy }
    },
    totalTargetGrossRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const targetGrossRent = this.objectsPage[i].pricing?.targetGrossRent > 0 ? this.objectsPage[i].pricing?.targetGrossRent : 0
          tenancy += targetGrossRent
        } else {
          const targetGrossRent = this.objectsPage[i].pricing?.targetGrossRent > 0 ? this.objectsPage[i].pricing?.targetGrossRent : 0
          vacancy += targetGrossRent
        }
      }
      return { tenancy: tenancy, vacancy: vacancy, sum: tenancy + vacancy }
    },
    hasSelectedEntriesAssetSet() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(e => e.networkPartnerAsset && e.networkPartnerAsset.name !== 'Unassigned Nicht zugeordnet Non assegnato').length > 0
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.objectID) : this.selectedEntries.map(obj => obj.objectID)
    },
    selectedClientName() {
      if (this.selectedClient && this.selectedClient.company) {
        return this.selectedClient.company.name
      } else if (this.selectedClient && this.selectedClient.individual) {
        return this.selectedClient.individual.firstName.substr(0, 1) + '.' + this.selectedClient.individual.lastName
      }
      return ''
    },
    isTenanciesActivated() {
      return this.selectedEntriesActivatedTenancy.filter(x => x.hasActivatedTenancy).length
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    isAdmin() {
      if (this.isOwnerModule) {
        return this.$store.getters['user/getCurrentUser']['https://coozzy.ch/roles'].includes('company_admin')
      }
      return false
    },
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    }
  },
  watch: {
    searchDebitUntilFilter: function (newVal) {
      if (newVal.length > 0) {
        const dateArray = newVal[0].split('/')
        this.defaultCalendar = new Date(dateArray[1], dateArray[0] - 1, 1)
      }
      this.searchDebitUntilFilterLabel = newVal ? newVal.join(', ') : ''
    },
    checkfilter: function () {
      this.clearCheckbox()
    },
    nbrPerPage: function () {
      if (this.showFirst) {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.refresh()
          }
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.redirect(this.$route.query.page)
          }
        })
      }
      this.showFirst = true
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        // check if we already call algolia with the same params
        if (this.nbrTotalOfHits !== this.allEntries.length) {
          this.allEntries = await this.getAllAvailableDataByIndex(this.paramsAlgolia)
        }
        this.selectedEntriesActivatedTenancy = this.allEntries.map(item => {
          return { hasActivatedTenancy: item.hasActivatedTenancy, id: item.objectID }
        })
      } else {
        this.selectedEntriesActivatedTenancy = this.selectedEntries.map(item => {
          return { hasActivatedTenancy: item.hasActivatedTenancy, id: item.objectID }
        })
      }
    }
  },
  mounted: function () {
    this.getContacts()
    this.loadEmployees()
    this.loadInstantSearch()
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'object_overview') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    })
    this.algoliaDataLoading = true
    NetworkPartnerAPI.listNetworkPartner(
      0,
      0,
      [],
      ['LANDLORD', 'MARKETING', 'LANDLORD_MARKETING', 'ASSET_LANDLORD', 'ASSET_MARKETING', 'ASSET_LANDLORD_MARKETING', 'ASSET_LANDLORD_ACCOUNTING', 'ASSET_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING_ACCOUNTING', 'LANDLORD_MARKETING_ACCOUNTING'],
      '',
      '',
      ''
    ).then(response => {
      this.algoliaDataLoading = false
      this.networkPartnerList = response.accounts
    }).catch(e => {
      console.log(e)
      this.algoliaDataLoading = false
      Vue.toasted.show(this.$t('message.loadingErrors.networkPartners'), { type: 'error' })
    }).finally(() => {
      setTimeout(() => {
        this.$refs.debouncedAlgolia?.setFocusInput()
      }, 300)
    })
    this.getContacts()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSendMailSecondaryTitle',
      'setUseBcc',
      'setSendMailContactsWithoutEmail',
      'setRenderWithObjectId',
      'setSendMailContactsWithObject',
      'setShowAllPlaceholders'
    ]),
    getState(result) {
      this.nbrTotalOfHits = result.nbHits
      const params = this.editAlgoliaParams(result.params)
      this.paramsAlgolia = {
        query: result.query,
        params: params,
        index: result.index,
        attributesToRetrieve: ['objectID', 'tenancy', 'hasActivatedTenancy', 'condominiumOwnership', 'networkPartnerAsset', 'networkPartnerAdministration', 'owner']
      }
      return ''
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    dateChanged(e) {
      this.defaultCalendar = e
    },
    dateToTimestamp(list, type) {
      const timeStampList = []
      if (type === 'start' && list.length > 0) {
        list.forEach(item => {
          const dateArray = item.split('/')
          const date = new Date(dateArray[1], dateArray[0] - 1, 1, 0, 0, 0)
          timeStampList.push(date.getTime() / 1000)
        })
        timeStampList.sort(function(x, y) {
          return x - y
        })
        return timeStampList[0]
      }
      if (type === 'end' && list.length > 0) {
        list.forEach(item => {
          const dateArray = item.split('/')
          const date = new Date(dateArray[1], dateArray[0], 0, 23, 59, 59)
          timeStampList.push((date.getTime() / 1000))
        })
        timeStampList.sort(function(x, y) {
          return y - x
        })
        return timeStampList[0]
      }
      return 0
    },
    fieldsIncludeCalculatedRent() {
      const items = this.fields.filter(x => x.key === 'netRent' || x.key === 'grossRent' || x.key === 'marketNetRent' || x.key === 'targetNetRent' || x.key === 'marketGrossRent' || x.key === 'targetGrossRent')
      if (items?.length > 0) {
        return true
      }
      return false
    },
    createTicket(type) {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      const defaultAssignee = listEntries.every((val, i, arr) => val.networkPartnerAsset && arr[0].networkPartnerAsset && val.networkPartnerAsset.accountId === arr[0].networkPartnerAsset.accountId)
        ? listEntries[0].networkPartnerAsset.accountId
        : listEntries.every((val, i, arr) => val.networkPartnerAdministration && arr[0].networkPartnerAdministration && val.networkPartnerAdministration.accountId === arr[0].networkPartnerAdministration.accountId)
          ? listEntries[0].networkPartnerAdministration.accountId
: ''
      this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const objectIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      }
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.objectsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'object_overview').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    getHits(hits) {
      this.objectsPage = hits
      return hits
    },
    refineResultWithNegativeNumbers(refine, value) {
      this.refineWithNegativeNumbers(refine, value)
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    refineResult(refin, value) {
      refin(value)
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    serialLetter() {
      const uniqKey = uuidv4()
      localStorage.setItem(uniqKey, this.selectedEntriesIds)
      this.$nextTick(() => {
        this.$router.push({
          name: 'OwnerSerialLetterProcessView',
          query: {
            source: 'tenancy',
            uuid: uniqKey,
            previousRoute: this.$route.fullPath
          }
        })
      })
    },
    async serialEmail() {
      this.setShowAllPlaceholders(true)
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      await this.prepareSerialEmail(listEntries)
      this.loading = false
    },
    sortOptions(item) {
      return item.sort(function(a, b) {
        if (a.value === 'Unassigned Nicht zugeordnet Non assegnato') return -1
        if (b.value === 'Unassigned Nicht zugeordnet Non assegnato') return -1
        return 0
      })
    },
    clickOnClear() {
      this.$refs.clearClick.click()
    },
    setCanRefine(canRefine) {
      this.checkfilter = canRefine
      return ''
    },
    transformItems(items) {
      return items.filter(item => item.label.indexOf('\\-') === -1)
    },
    clearCheckbox() {
      this.selectedEntries = []
      this.selectedEntriesActivatedTenancy = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    redirectToNetworkDetailPage(id) {
      return { name: 'OwnerNetworkPartnerDetailsView', params: { id: id } }
    },
    onRowClicked(item, index, event) {
      if (!event.srcElement.classList.contains('tenant-link') && !event.srcElement.classList.contains('network-link')) {
        if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
          this.itemSelected(item, !document.getElementById('check_' + item.objectID).checked)
          document.getElementById('check_' + item.objectID).checked = !document.getElementById('check_' + item.objectID).checked
        } else {
          this.navigateToOverview(item, index, event)
        }
      }
    },
    initFilter(refine) {
      this.searchCategories = []
      this.searchRooms = []
      this.searchFloor = []
      this.searchZip = []
      this.searchStatus = []
      this.searchFutureStatus = []
      this.searchMarketingStatus = []
      this.searchOwnerAssignee = []
      this.searchAdministration = []
      this.searchAdminAsset = []
      this.searchAdminMarketing = []
      this.searchAccounting = []
      this.searchPropertyName = []
      this.searchBuildingName = []
      this.searchCity = []
      this.searchPreferredCommunicationChannel = []
      this.searchTags = []
      this.searchSubCategory = []
      refine('')
    },
    clickSearch() {
      this.currentPage = 1
    },
    customLabel(item) {
      if (item.value === 'Unassigned Nicht zugeordnet Non assegnato') {
        return '- (' + item.count + ')'
      }
      return item.value + ' (' + item.count + ')'
    },
    customLabelChannel(item) {
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL') {
        return this.$t('message.contact.preferredCommunicationChannelType.email') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_LETTER') {
        return this.$t('message.contact.preferredCommunicationChannelType.letter') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED') {
        return '- (' + item.count + ')'
      }
      return item.value
    },
    customLabelTags(item) {
      return item.label + ' (' + item.count + ')'
    },
    customLabelStatus(item) {
      return this.$t('message.onBoarding.buildings.objects.status.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelSubCategory(item) {
      if (item.value !== 'UNDEFINED_SUB_CATEGORY') {
        return this.$t('message.onBoarding.buildings.objects.subCategory.' + item.value.toLowerCase()) + ' (' + item.count + ')'
      } else {
        return '- (' + item.count + ')'
      }
    },
    customLabelFutureStatus(item) {
      if (item.value === 'future') {
        return this.$t('message.onBoarding.buildings.objects.futureStatus.' + item.value.toLowerCase() + 'Rented') + ' (' + item.count + ')'
      }
      return this.$t('message.onBoarding.buildings.objects.futureStatus.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelMarketingStatus(item) {
      if (item.value === 'Unassigned Nicht zugeordnet Non assegnato') {
        return '-'
      }
      return this.$t('message.onBoarding.buildings.objects.inMarketingStatus.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelFloor(item) {
      return this.formatFloor(item.value) + ' (' + item.count + ')'
    },
    customLabelCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    openConfirmationModal() {
      this.$refs.confirmationModal.show()
    },
    deleteObject() {
      const promises = []
      const current = this
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      for (let index = 0; index < listEntries.length; index++) {
        promises.push(ObjectApi.deleteObject(listEntries[index].objectID))
      }
      this.loading = true
      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            this.appRefreshAction = 'delete'
            this.$refs['app-refresh'].refresh()
          }, 2000)
        })
        .catch(e => {
          this.loading = false
          current.$refs.confirmationModal.hide()
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.object'), { type: 'error' })
        })
        .then(() => {
        })
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.objectsTable) {
        const allCheckboxesComponents = this.$refs.objectsTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
        this.selectedEntriesActivatedTenancy.push({ hasActivatedTenancy: item.hasActivatedTenancy, id: item.objectID })
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.objectID !== item.objectID)
        this.selectedEntriesActivatedTenancy = this.selectedEntriesActivatedTenancy.filter(entry => entry.id !== item.objectID)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    navigateToOverview(item) {
      this.$router.push({
        name: 'OwnerBuildingDetailsView',
        params: { id: item.buildingId },
        query: { view: 'objects', elementSelected: item.objectID }
      })
    },
    onMiddlelicked(item, index, event) {
      const element = event.srcElement
      if (element.tagName !== 'A') {
        const route = this.$router.resolve({
          name: 'OwnerBuildingDetailsView',
          params: { id: item.buildingId },
          query: {
            view: 'objects',
            elementSelected: item.objectID
          }
        })
        window.open(route.href)
      }
    },
    openSetClientModal(type) {
      if (type === 'MANAGEMENT_SCOPE') {
        this.scope = 'MANAGEMENT_SCOPE'
      } else if (type === 'MARKETING_SCOPE') {
        this.scope = 'MARKETING_SCOPE'
      }
      this.$refs.setClientModal.setModalShow = true
    },
    filtredNetworkPartner() {
      if (this.scope) {
        if (this.scope === 'MANAGEMENT_SCOPE') {
          return this.networkPartnerList.filter(x => x.subType.includes('LANDLORD'))
        } else if (this.scope === 'MARKETING_SCOPE') {
          return this.networkPartnerList.filter(x => x.subType.includes('MARKETING'))
        }
      }
      return this.networkPartnerList
    },
    setClient(newClient) {
      this.selectedClient = null
      if (this.selectedClient === undefined || this.selectedClient === null) {
        this.$refs.setClientModal.setModalShow = false
        this.$refs.setClientModal.setModalDisabled = false
        this.selectedClient = newClient
        this.$nextTick(() => {
          this.$refs.shareconfirmationModal.show()
        })
      }
    },
    saveShares() {
      this.loading = true
      const entries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      if (!entries.every((val, i, arr) => val.owner.id === arr[0].owner.id)) {
        const listByOwner = this.regroupObjectsByOwner(entries)
        for (let index = 0; index < listByOwner.length; index++) {
          if (this.selectedClient === undefined || this.selectedClient === null) {
            ShareApi.shareUnset([this.scope], listByOwner[index].owner, listByOwner[index].objects)
              .then(() => {})
              .catch(e => {
                console.log(e)
                this.$refs.setClientModal.setModalDisabled = false
                Vue.toasted.show(this.$t('message.savingErrors.unsetNetworkPartner'), { type: 'error' })
              })
          } else {
            ShareApi.shareSet(this.scope, listByOwner[index].owner, listByOwner[index].objects, this.selectedClient.id)
              .then((response) => {
                const existantShare = this.sharesList.findIndex(function (obj) {
                  return response.share.sourceAccountId === obj.sourceAccountId && response.share.targetAccountId === obj.targetAccountId && response.share.scope === obj.scope
                })
                if (existantShare !== -1) {
                  this.sharesList.resourceIds = response.share.resourceIds.concat(this.sharesList.resourceIds)
                } else {
                  this.sharesList.push(response.share)
                }
              })
              .catch(e => {
                console.log(e)
                this.$refs.setClientModal.setModalDisabled = false
                Vue.toasted.show(this.$t('message.savingErrors.setNetworkPartner'), { type: 'error' })
              })
          }
          setTimeout(() => {
            this.clearCheckbox()
            this.appRefreshAction = 'share'
            this.$refs['app-refresh'].refresh()
          }, 2000)
        }
      } else {
        if (this.selectedClient === undefined || this.selectedClient === null) {
          ShareApi.shareUnset([this.scope], this.selectedEntries[0].owner.id, this.selectedEntriesIds)
            .then(() => {
              setTimeout(() => {
                this.clearCheckbox()
                this.appRefreshAction = 'share'
                this.$refs['app-refresh'].refresh()
              }, 2000)
            })
            .catch(e => {
              console.log(e)
              this.$refs.setClientModal.setModalDisabled = false
              Vue.toasted.show(this.$t('message.savingErrors.unsetNetworkPartner'), { type: 'error' })
            })
        } else {
          ShareApi.shareSet(this.scope, this.selectedEntries[0].owner.id, this.selectedEntriesIds, this.selectedClient.id)
            .then((response) => {
              const existantShare = this.sharesList.findIndex(function (obj) {
                return response.share.sourceAccountId === obj.sourceAccountId && response.share.targetAccountId === obj.targetAccountId && response.share.scope === obj.scope
              })
              if (existantShare !== -1) {
                this.sharesList.resourceIds = response.share.resourceIds.concat(this.sharesList.resourceIds)
              } else {
                this.sharesList.push(response.share)
              }
              setTimeout(() => {
                this.appRefreshAction = 'share'
                this.$refs['app-refresh'].refresh()
              }, 2000)
            })
            .catch(e => {
              console.log(e)
              this.$refs.setClientModal.setModalDisabled = false
              Vue.toasted.show(this.$t('message.savingErrors.setNetworkPartner'), { type: 'error' })
            })
        }
      }
    },
    refreshDone() {
      if (this.appRefreshAction === 'delete') {
        this.clearCheckbox()
        Vue.toasted.show(this.$t('message.successMessages.objectDeletion'), { type: 'success' })
        if (this.$refs.confirmationModal) {
          this.$refs.confirmationModal.hide()
        }
      } else if (this.appRefreshAction === 'share') {
        Vue.toasted.show(this.$t('message.successMessages.networkPartnerUpdated'), { type: 'success' })
        this.clearCheckbox()
        this.loading = false
        if (this.$refs.shareconfirmationModal) {
          this.$refs.shareconfirmationModal.hide()
        }
      } else if (this.appRefreshAction === 'assignee') {
        this.clearCheckbox()
        if (this.$refs.setAssigneeModal) {
          Vue.toasted.show(this.$t('message.successMessages.setMarketingObjectAssignee'), { type: 'success' })
          this.$refs.setAssigneeModal.setModalShow = false
          this.$refs.setAssigneeModal.setModalDisabled = false
        }
      }

      this.appRefreshAction = ''
    },
    getAssigneeData(items) {
      items.forEach(element => {
        const emp = this.employeeList.find(x => (x.profile.lastName + ' ' + x.profile.firstName) === element.label)
        if (emp) {
          element.active = !emp.blocked
        }
      })
      return [{
        employee: this.$t('message.search.filterAll'),
        employees: items
      }]
    },
    openSetAssigneeModal(item) {
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      if (item === null) {
        this.selectedObjectIds = this.selectedEntriesIds
      } else {
        this.selectedObjectIds = [item.objectID]
      }
      if (this.$refs.setAssigneeModal) {
        this.$refs.setAssigneeModal.newElementId =
          item && item.assignees && item.assignees.owner ? item.assignees.owner.contactId || null : null
        // this.$refs.setAssigneeModal.setModalShow = true
        this.$nextTick(() => {
          this.$refs.setAssigneeModal.show()
        })
      }
    },
    setAssignee(newEmployee) {
      this.$refs.setAssigneeModal.setModalDisabled = true
      ObjectApi.setOwnerAssignee(newEmployee ? newEmployee.id : null, this.selectedObjectIds)
        .then(() => {
          setTimeout(() => {
            this.appRefreshAction = 'assignee'
            this.$refs['app-refresh'].refresh()
          }, 2000)
        })
        .catch(e => {
          console.log(e)
          this.$refs.setAssigneeModal.setModalDisabled = false
          Vue.toasted.show(this.$t('message.savingErrors.setMarketingObjectAssignee'), { type: 'error' })
        })
    },
    getContacts() {
      ContactApi.listEmployees(this.accountId)
        .then(response => {
          this.contactList = response.persons.filter(p => {
            return this.employeeList.filter(e => !e.blocked).find(e => e.email === p.email)
          })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    },
    selectValue(item, items, refine) {
      if (item === undefined) {
        if (this.searchOwnerAssignee.length > 0) {
          this.searchOwnerAssignee.map(a => a.value).forEach(element => {
            refine(element)
          })
        }
        const result = items.map(a => a.value)
        result.forEach(element => {
          refine(element)
        })
      } else {
        refine(item)
      }
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    removeValue(item, items, refine) {
      if (item === undefined) {
        const result = items.map(a => a.value)
        result.forEach(element => {
          refine(element)
          this.searchOwnerAssignee.length = 0
        })
      } else {
        refine(item)
      }
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @media (min-width: 768px) {
    #responsiveFooterTable {
      display: none;
    }
  }
  :deep(table.table tfoot th.case-total) {
    padding-left: 0;
  }
  :deep(tfoot tr) {
    position: relative;
  }
  :deep(tfoot .alignFoot) {
    height: 100%;
    position: absolute;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  :deep(#date-picker-container) {
    position: relative;
    #date-picker-input {
      height: 30px;
      position: absolute;
      width: 100%;
      opacity: 0;
      top:0px;
      z-index: 1;
    }
  }
  .tooltip.b-tooltip {
    z-index: 2003;
  }
</style>
