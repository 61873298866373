<template>
  <div>
    <font-awesome-icon
      :icon="'caret-up'"
      class="fa" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'

library.add(faCaretUp)

export default {
  name: 'CoozzyCaretUpIcon'
}
</script>

<style lang="scss" scoped>
  .fa {
    color: $color-grey-dark;
  }
</style>
