<template>
  <section class="w-100">
    <div class="row">
      <div
        v-if="readOnly"
        class="col-sm-12 col-md-3">
        <label for="tenants">
          {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.depositShare.contactId') }}
        </label>
        <div v-if="depositShare.contactId !== ''">
          {{ getContact(depositShare.contactId).name }}
        </div>
        <span
          v-else
          class="d-block">-</span>
      </div>
      <div
        v-else
        class="col-sm-12 col-md-3">
        <ais-instant-search
          v-if="searchClientInternal"
          :search-client="searchClientInternal"
          :routing="routing"
          index-name="contact">
          <!-- eslint-disable vue/attribute-hyphenation -->
          <ais-configure
            :hitsPerPage="10"
            :filters="'active:true AND isEmployee:false AND isOnlyInterestedParty:false AND owner.id:' + building.ownerId" />
          <!-- eslint-disable vue/attribute-hyphenation -->
          <ais-autocomplete>
            <template slot-scope="{ indices, refine }">
              <label>{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.depositShare.contactId') }}</label>
              <coozzy-search-multiselect
                v-model="contactId"
                :options="editObject(indices[0].hits)"
                :placeholder="''"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                :taggable="false"
                :multiple="false"
                track-by="id"
                label="name"
                :with-address="true"
                :with-numeric-id="true"
                @search-change="see(refine, $event)" />
            </template>
          </ais-autocomplete>
        </ais-instant-search>
      </div>
      <div class="col-sm-12 col-md-3">
        <coozzy-form-input-numeric
          v-model="amount"
          type="number"
          :is-read-only="readOnly"
          :filter="'formatPrice'"
          placeholder="CHF"
          :is-decimal="true"
          :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.depositShare.amount')" />
      </div>
      <div
        v-if="!readOnly"
        class="col-md-1">
        <label
          for="delete"
          class="text-white d-block">delete</label>
        <coozzy-button
          design="alert"
          class="align-self-center"
          @click="openConfirmationModal()">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </div>
    <b-modal
      :id="'depositShare-confirmationModal'"
      ref="depositShare-confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.titledepositShares')">
      <template>
        <div class="col">
          <p>{{ $t('message.onBoarding.deleteModals.bodydepositShares') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('depositShare-confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deletedepositShareClicked()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>
<script>
import { routeChecks } from '@/mixins/routeChecks'
import { onboarding } from '@/mixins/onboarding'
import { algolia } from '@/mixins/algolia'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import ContactApi from '@/misc/apis/ContactApi'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
export default {
  name: 'TenancyDepositShares',
  components: {
    CoozzyButton,
    CoozzySearchMultiselect
  },
  mixins: [routeChecks, onboarding, algolia],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    depositShare: {
      type: Object,
      default: null
    },
    tenancy: {
      type: Object,
      default: null
    },
    contactsLoaded: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      usedContacts: this.contactsLoaded || []
    }
  },
  computed: {
    contactId: {
      get() {
        return this.usedContacts?.find(t => {
          if (t && t.objectID) {
            return t.objectID === this.depositShare.contactId
          } else if (t && t.id) {
            return t.id === this.depositShare.contactId
          } else {
            return false
          }
        })
      },
      set(value) {
        const newDepositShare = this.depositShare
        newDepositShare.contactId = value.objectID || value.id
        this.usedContacts = this.usedContacts.concat(value)
        this.usedContacts = [...this.removeDuplicatesContact(this.usedContacts)]
        this.$emit('change-depositShare', newDepositShare)
      }
    },
    amount: {
      get() {
        return this.depositShare.amount
      },
      set(value) {
        const newDepositShare = this.depositShare
        newDepositShare.amount = value
        this.$emit('change-depositShare', newDepositShare)
      }
    }
  },
  watch: {

  },
  mounted() {
    this.loadInstantSearchInternal()
    const contactsToLoadAlgolia = [this.depositShare.contactId]
    this.availableTenants(contactsToLoadAlgolia.filter(x => x !== null && x !== ''))
    this.usedContacts = this.usedContacts.concat(this.contactsLoaded)
  },
  methods: {
    editObject(data) {
      for (const item of data) {
        if (item.objectID) {
          item.id = item.objectID
        }
      }
      return data
    },
    openConfirmationModal() {
      this.$refs['depositShare-confirmationModal'].show()
    },
    getContactName(contactId) {
      const contact = this.getContact(contactId)
      return contact ? contact.name : '-'
    },
    getContact(contactId) {
      return this.usedContacts?.find(contact => contact.objectID === contactId || contact.id === contactId) || null
    },
    deletedepositShareClicked() {
      this.$emit('delete-depositShare')
      this.$refs['depositShare-confirmationModal'].hide()
    },
    see(refine, value) {
      refine(value)
    },
    availableTenants(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          this.usedContacts = this.usedContacts.concat(contacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
</script>
