<template>
  <section>
    <div>
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <div v-else>
        <coozzy-card :title="$t('message.briefButler.errorsTable.title')">
          <div>
            <div class="row mt-2">
              <div class="col-12 col-md-2 mb-2 mb-md-0">
                <coozzy-dropdown
                  design="green"
                  size="sm"
                  class="w-100"
                  :text="selectedEntriesText">
                  <coozzy-dropdown-item
                    :disabled="selectedEntries.length === 0"
                    @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                    {{ $t('message.briefButler.errorsTable.acknowledge') }}
                  </coozzy-dropdown-item>
                </coozzy-dropdown>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <b-table
                  ref="errorEntriesTable"
                  bordered
                  hover
                  responsive="true"
                  stacked="md"
                  select-mode="single"
                  :busy="loading"
                  :fields="fields"
                  :items="errorEntries">
                  <!-- Busy state -->
                  <div
                    slot="table-busy"
                    class="text-center text-danger my-2">
                    <coozzy-spinner />
                  </div>

                  <!-- Headings -->
                  <template #head(checkbox)="">
                    <coozzy-form-checkbox
                      ref="header_checkbox"
                      @change="(value) => { handleCheckboxesInsideTable(value) }" />
                  </template>
                  <template #head(contact)>
                    {{ $t('message.tableColumnHeadings.contact') }}
                  </template>
                  <template #head(date)>
                    {{ $t('message.tableColumnHeadings.date') }}
                  </template>
                  <template #head(reason)>
                    {{ $t('message.tableColumnHeadings.reason') }}
                  </template>
                  <template #head(document)>
                    {{ $t('message.tableColumnHeadings.document') }}
                  </template>

                  <!-- Data -->
                  <template #cell(checkbox)="data">
                    <!-- data needs to be used in order to work properly -->
                    <coozzy-form-checkbox
                      :id="'check_Item_' + data.item.id"
                      @change="(value) => { itemSelected(data.item.id, value) }" />
                  </template>
                  <template #cell(contact)="data">
                    <router-link
                      v-if="data.item.entityId.startsWith('contact')"
                      :to="{ name: moduleRoutePrefix + 'ContactDetailsView', params: { id: data.item.entityId } }">
                      {{ getContactName(data.item.entityId) !== '' ? getContactName(data.item.entityId) : '-' }}
                    </router-link>
                    <router-link
                      v-if="data.item.entityId.startsWith('tenancy') && data.item.tenancy && data.item.tenancy.tenant"
                      :to="{ name: moduleRoutePrefix + 'BuildingDetailsView', params: { id: data.item.building.id }, query: { view: 'tenants', elementSelected: data.item.entityId } }">
                      <template
                        v-for="(contactId, tenantIndex) in data.item.tenancy.tenant.contactIds">
                        <span
                          :key="Math.random() + '_' + tenantIndex">
                          {{ tenantIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}
                        </span>
                      </template>
                    </router-link>
                    <router-link
                      v-if="data.item.entityId.startsWith('cond') && data.item.condominium && data.item.condominium.owner"
                      :to="{ name: moduleRoutePrefix + 'BuildingDetailsView', params: { id: data.item.building.id }, query: { view: 'condominium', elementSelected: data.item.entityId } }">
                      <template
                        v-for="(contactId, ownerIndex) in data.item.condominium.owner.contactIds">
                        <span
                          :key="Math.random() + '_' + ownerIndex">
                          {{ ownerIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}
                        </span>
                      </template>
                    </router-link>
                  </template>
                  <template #cell(date)="data">
                    <p class="mb-0">
                      {{ data.item.timestamp / 1000 | timestampToDate }}
                    </p>
                  </template>
                  <template #cell(reason)="data">
                    <p class="mb-0">
                      {{ data.item.errorMessage }}
                    </p>
                  </template>
                  <template #cell(document)="data">
                    <a
                      v-if="getMedia(data.item.mediaId)"
                      target="_blank"
                      class="mb-0"
                      :href="getMedia(data.item.mediaId).url">
                      {{ getMedia(data.item.mediaId).filename }}
                    </a>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <b-modal
            :id="'confirmationModal'"
            ref="confirmationModal"
            no-close-on-backdrop
            hide-footer
            :title="$t('message.briefButler.errorsTable.acknowledge')">
            <div class="col p-0">
              <p>{{ $t('message.settings.template.confirmation.text') }}</p>
            </div>
            <div class="col p-0">
              <coozzy-button
                size="small"
                class="mb-0 border"
                design="transparent"
                @click="$bvModal.hide('confirmationModal')">
                {{ $t('message.generic.cancel') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="float-right mb-0"
                design="green"
                @click="deleteError()">
                {{ $t('message.briefButler.errorsTable.acknowledge') }}
              </coozzy-button>
            </div>
          </b-modal>
        </coozzy-card>
      </div>
    </div>
  </section>
</template>

<script>
import BriefButlerApi from '../../misc/apis/BriefButlerApi'
import Vue from 'vue'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import { media } from '@/mixins/media'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import { tables } from '@/mixins/tables'
import ContactApi from '@/misc/apis/ContactApi'
import { routeChecks } from '@/mixins/routeChecks'
import MediaApi from '@/misc/apis/MediaApi'
import contract from '@/store/modules/contract'

export default {
  name: 'BriefButlerErrors',
  components: {
    CoozzyCard,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyFormCheckbox,
    CoozzyButton,
    CoozzySpinner
  },
  mixins: [media, tables, routeChecks, contract],
  data() {
    return {
      loading: true,
      selectedEntries: [],
      errorEntries: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'contact',
          label: this.$t('message.tableColumnHeadings.contact'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'date',
          label: this.$t('message.tableColumnHeadings.date'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'reason',
          label: this.$t('message.tableColumnHeadings.reason'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'document',
          label: this.$t('message.tableColumnHeadings.document'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      contacts: [],
      listTenancies: [],
      listCondominiums: [],
      media: []
    }
  },
  computed: {
    selectedEntriesText() {
      return this.$t('message.generic.selectedEntries') + ':  ' + this.selectedEntries.length
    }
  },
  watch: {
  },
  mounted: function () {
    this.loadListError()
  },
  methods: {
    async loadListError() {
      try {
        const response = await BriefButlerApi.listErrorsByAccountId(this.$store.getters['user/getAccountId'])
        this.$store.dispatch('contract/setNumberBriefButler', response.errors.length)
        this.errorEntries = response.errors
        const condominiumIds = this.errorEntries.filter(x => x.entityId.startsWith('cond')).map(entry => entry.entityId)
        const tenancyIds = this.errorEntries.filter(x => x.entityId.startsWith('tenancy')).map(entry => entry.entityId)
        const contactIds = this.errorEntries.filter(x => x.entityId.startsWith('contact')).map(entry => entry.entityId)
        const promises = [
          ObjectApi.getTenanciesByIds(tenancyIds),
          ObjectApi.getCondominiumByIds(condominiumIds)
        ]

        const [tenanciesResponse, condominiumsResponse] = await Promise.all(promises)

        this.listTenancies = tenanciesResponse.tenancies
        this.listCondominiums = condominiumsResponse.condominiums

        const tenancyContactIds = this.listTenancies.map(tenancy => tenancy.tenant.contactIds).flat()
        const condominiumContactIds = this.listCondominiums.map(condominium => condominium.owner.contactIds).flat()
        const allContactIds = [...tenancyContactIds, ...condominiumContactIds, ...contactIds]

        const buildingResponse = await ObjectApi.getBuildingByObjectIds([...this.listTenancies.map(tenancy => tenancy.objectId), ...this.listCondominiums.map(condominium => condominium.objectId)])
        const buildings = buildingResponse.buildings

        this.listTenancies.forEach(tenancy => {
          const building = buildings.find(building => building.objectIds.includes(tenancy.objectId))
          if (building) {
            tenancy.building = building
          }
        })

        this.listCondominiums.forEach(condominium => {
          const building = buildings.find(building => building.objectIds.includes(condominium.objectId))
          if (building) {
            condominium.building = building
          }
        })

        await this.loadContacts(allContactIds)
        this.loadMedia(this.errorEntries.map(entry => entry.mediaId))

        this.errorEntries.forEach(errorEntry => {
          if (errorEntry.entityId.startsWith('tenancy')) {
            const tenancy = this.listTenancies.find(tenancy => tenancy.id === errorEntry.entityId)
            if (tenancy) {
              errorEntry.contactIds = tenancy.tenant.contactIds
              errorEntry.tenancy = tenancy
              errorEntry.building = tenancy.building
            }
          } else if (errorEntry.entityId.startsWith('cond')) {
            const condominium = this.listCondominiums.find(condominium => condominium.id === errorEntry.entityId)
            if (condominium) {
              errorEntry.contactIds = condominium.owner.contactIds
              errorEntry.condominium = condominium
              errorEntry.building = condominium.building
            }
          }
        })
      } catch (error) {
        console.error(error)
        Vue.toasted.show('Die Buchhaltungsstatistiken konnten nicht geladen werden', { type: 'error' })
      } finally {
        this.loading = false
      }
    },
    openConfirmationModal() {
      this.$refs.confirmationModal.show()
    },
    itemSelected(id, checked) {
      if (checked && !this.selectedEntries.includes(id)) {
        this.selectedEntries.push(id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== id)
      }
    },
    deleteError() {
      const errorIds = this.selectedEntries
      BriefButlerApi.acknowledgeErrors(errorIds)
        .then(() => {
          this.clearCheckbox()
          this.$refs.confirmationModal.hide()
          this.selectedEntries = []
          this.loadListError()
          Vue.toasted.show(this.$t('message.briefButler.errorsTable.acknowledgeSuccess'), { type: 'success' })
        })
        .catch(e => {
          console.error(e)
          Vue.toasted.show(this.$t('message.briefButler.errorsTable.acknowledgeError'), { type: 'error' })
        })
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs.errorEntriesTable.$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
    },
    loadContacts(ids) {
      ContactApi.contactResolve(ids)
        .then(response => {
          this.contacts = response.persons.concat(response.companies)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
        })
    },
    loadMedia(ids) {
      MediaApi.getListMedia(ids)
        .then(response => {
          for (const media of response.media) {
            this.media.push(media)
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
        })
    },
    getContactName(id) {
      const contact = this.contacts.find(contact => contact.id === id)
      return contact ? contact.name : ''
    },
    getMedia(mediaId) {
      const media = this.media.find(media => media.id === mediaId)
      return media || null
    }
  }
}
</script>
