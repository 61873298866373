<template>
  <coozzy-card
    :title="$t('message.portalTab.title')"
    class="global-card no-top-border table-card">
    <p>
      {{ $t('message.portalTab.subTitle') }}
    </p>
    <div
      v-if="loading"
      class="row">
      <div class="col">
        <coozzy-spinner />
      </div>
    </div>
    <template v-else>
      <template
        v-for="(item, index) in listLinks">
        <div
          :key="item.id || item.internalId"
          class="row">
          <div class="col-sm-12 col-md-3">
            <label for="fontFamily">{{ $t('message.portalTab.description') }}</label>
            <coozzy-form-input
              ref="itemDescription"
              v-model="item.name"
              :is-read-only="readOnly"
              :placeholder="$t('message.portalTab.description')"
              @keyup="itemChanged(item)" />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="fontSize">{{ $t('message.portalTab.link') }}</label>
            <coozzy-form-input
              ref="itemLink"
              v-model="item.url"
              :is-read-only="readOnly"
              :placeholder="$t('message.portalTab.link')"
              :state="($v.listLinks.$each[index].url.$dirty && $v.listLinks.$each[index].url.$error) ? false : null"
              @keyup="itemChanged(item)" />
          </div>
          <div
            v-if="!readOnly"
            class="col align-content-end">
            <coozzy-button
              class="m-0"
              design="red"
              @click="deleteLink(item)">
              {{ $t('message.portalTab.deleteLink') }}
            </coozzy-button>
          </div>
        </div>
      </template>
      <div
        v-if="!readOnly"
        class="row">
        <div class="col-6 col-md-2">
          <coozzy-button
            class="mt-3 w-100"
            design="prop-green"
            @click="addLink">
            {{ $t('message.portalTab.addLink') }}
          </coozzy-button>
        </div>
      </div>
    </template>
  </coozzy-card>
</template>

<script lang="ts">
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import { user } from '@/mixins/user'
import Vue from 'vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import PortalApi from '@/misc/apis/PortalApi'
import { PortalLink } from '@/types/api'
import { required } from 'vuelidate/lib/validators'

interface linkEntry extends PortalLink {
  internalId: number
}
function isValidURL(url) {
  const urlPattern = new RegExp(
    '((([a-zA-Z0-9-_]+\\.)+[a-zA-Z]{2,})|' + // Domain name
    'localhost|' + // Localhost
    '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IPv4
    '\\[([a-fA-F0-9:]+)\\])' + // IPv6
    '(\\:\\d+)?' + // Port
    '(\\/[-a-zA-Z0-9%_.~+]*)*' + // Path
    '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // Query string
    '(\\#[-a-zA-Z0-9%_.~+=]*)?$', // Fragment
    'i' // Case insensitive
  )
  return urlPattern.test(url)
}
export default Vue.extend({
  name: 'PortalLinksTab',
  components: {
    CoozzyFormInput,
    CoozzySpinner,
    CoozzyButton,
    CoozzyCard
  },
  mixins: [user],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    property: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false as boolean,
      listLinks: [] as linkEntry[],
      deletedLinks: [] as string[]
    }
  },
  computed: {
  },
  watch: {},
  mounted() {
    this.loadLinks()
  },
  methods: {
    deleteLink(item: linkEntry) {
      if (item.id) {
        this.deletedLinks.push(item.id)
      }
      this.listLinks = this.listLinks.filter((link: linkEntry) => link.id && item.id ? link.id !== item.id : link.internalId !== item.internalId)
      this.$emit('change-link', item)
    },
    addLink() {
      const item = {
        internalId: Date.now(),
        name: '',
        referenceId: this.property.id,
        url: ''
      }
      this.listLinks.push(item)
      this.$emit('change-link', item)
    },
    itemChanged(item: linkEntry) {
      const index = this.listLinks.findIndex((link: linkEntry) => link.id === item.id)
      if (index !== -1) {
        this.listLinks[index].modified = true
      }
      this.$emit('change-link', item)
    },
    loadLinks() {
      this.loading = true
      PortalApi.listLinksByReferenceIds([this.property.id]).then((response) => {
        this.listLinks = response.links
        this.loading = false
      })
    },
    async saveData() {
      this.loading = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        Vue.toasted.show(this.$tc('default.message.savingErrors.onboarding'), { type: 'error' })
        this.loading = false
        return
      }
      const promises = []
      const linksToCreate = []
      const linksToEdit = []
      for (const link of this.listLinks) {
        if (link.internalId) {
          linksToCreate.push(link)
        } else if (link.modified) {
          linksToEdit.push(link)
        }
      }
      if (linksToCreate.length > 0) {
        promises.push(PortalApi.addLinks(linksToCreate))
      }
      if (linksToEdit.length > 0) {
        promises.push(PortalApi.updateLinks(linksToEdit))
      }
      if (this.deletedLinks.length > 0) {
        promises.push(PortalApi.removeLinks(this.deletedLinks))
      }
      return Promise.all(promises).then(() => {
        Vue.toasted.show(this.$tc('message.successMessages.linkSaved'), { type: 'success' })
        this.loadLinks()
      })
    }
  },
  validations() {
    return {
      listLinks: {
        $each: {
          url: {
            required,
            isValidURL
          }
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
