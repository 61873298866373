<template>
  <span class="vertical-ruler" />
</template>

<script>
export default {
  name: 'CoozzyVerticalRuler'
}
</script>

<style lang="scss" scoped>
  .vertical-ruler {
    border-right: 1px solid $color-input-border;
  }
</style>
