import { mapActions } from 'vuex'

const ticket = {
  data() {
    return {
      countMyUnreadTickets: '-'
    }
  },
  computed: {
  },
  mounted() {
    this.getCountMyUnreadTickets(true)
  },
  beforeDestroy() {
    clearInterval(this.getNumberInterval)
  },
  methods: {
    ...mapActions('ticket', ['loadCountTickets']),
    getCountMyUnreadTickets(force = false) {
      this.$nextTick(async () => {
          this.countMyUnreadTickets = await this.loadCountTickets(force)
        })
      }
  }
}
export { ticket }
