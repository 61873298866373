<template>
  <section
    id="marketingObject">
    <div
      v-if="!searchClient"
      class="text-center">
      <coozzy-spinner />
    </div>
    <ais-instant-search
      v-else
      ref="instant-search"
      :search-client="searchClient"
      index-name="object_admin_marketing"
      :routing="routing">
      <ais-stats>
        <template slot-scope="{ nbHits }">
          <coozzy-page-title
            :title="$t('message.navigation.management.marketingList')"
            :count="nbHits"
            :count-text="$t('message.managementOverview.objects')"
            :clear-filter="checkfilter"
            @clear-filter="clickOnClear" />
        </template>
      </ais-stats>
      <div class="row">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-search-box>
            <div slot-scope="{ currentRefinement }">
              <debounced-algolia-search-box
                ref="debouncedAlgolia"
                :default-value="currentRefinement"
                :delay="300" />
            </div>
          </ais-search-box>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="status"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchStatus', items) }}
              <coozzy-multiselect
                v-model="searchStatus"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.generic.currentStatus')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelStatus"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="futureRentalStatus"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchFutureStatus', items) }}
              <coozzy-multiselect
                v-model="searchFutureStatus"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.generic.futureStatus')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelFutureStatus"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="inMarketingStatus"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchMarketingStatus', items) }}
              <coozzy-multiselect
                v-model="searchMarketingStatus"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.generic.marketingStatus')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelMarketingStatus"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="category"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchCategories', items) }}
              <coozzy-multiselect
                v-model="searchCategories"
                :options="items"
                :multiple="true"
                :placeholder="$t('message.onBoarding.buildings.objects.category.title')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelCategory"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="assignees.administration.name"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchAdminAssignee', items) }}
              <coozzy-assignee-multiselect
                v-model="searchAdminAssignee"
                :options="getAssigneeData(items)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.adminAssignee')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelAssignee"
                :track-by="'value'"
                label="name"
                group-values="employees"
                group-label="employee"
                :group-select="true"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="owner.name"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchAdminOwner', items) }}
              <coozzy-multiselect
                v-model="searchAdminOwner"
                :options="sortOptions(items)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.owner')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="networkPartnerAsset.name"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchAssetManager', items) }}
              <coozzy-multiselect
                v-model="searchAssetManager"
                :options="sortOptions(items)"
                :multiple="true"
                :placeholder="$t('message.networkPartner.subTypes.asset_management')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="networkPartnerAdministration.name"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchAdministration', items) }}
              <coozzy-multiselect
                v-model="searchAdministration"
                :options="sortOptions(items)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.administration')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                label="name"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </div>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1">
          <ais-refinement-list
            attribute="networkPartnerMarketing.name"
            :limit="100">
            <div
              slot-scope="{items, refine, searchForItems}">
              {{ defaultRefining('searchMarketer', items) }}
              <coozzy-multiselect
                v-model="searchMarketer"
                :options="sortOptions(items)"
                :multiple="true"
                :placeholder="$t('message.contact.types.MARKETER')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </div>
          </ais-refinement-list>
        </div>
      </div>
      <!-- eslint-disable vue/attribute-hyphenation -->
      <ais-configure
        :hitsPerPage="nbrPerPage"
        :filters="filterQuery" />
      <!-- eslint-enable vue/attribute-hyphenation -->
      <div
        id="rowConfig"
        class="row mt-2">
        <div class="col-md-2">
          <ais-clear-refinements :included-attributes="['query','category', 'owner.name', 'inMarketingStatus', 'networkPartnerAsset.name', 'networkPartnerMarketing.name', 'networkPartnerAdministration.name', 'assignees.administration.name']">
            <div slot-scope="{ canRefine, refine }">
              {{ setCanRefine(canRefine) }}
              <a
                v-if="canRefine"
                ref="clearClick"
                @click="initFilter(refine)" />
            </div>
          </ais-clear-refinements>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-7 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
          :class="isCompanyAdmin !== true ? 'offset-md-6' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <ais-state-results>
        <template
          slot-scope="{ hits }">
          <b-table
            ref="objectsTable"
            class="mt-2"
            bordered
            responsive="true"
            stacked="md"
            hover
            selectable
            select-mode="single"
            :fields="fields"
            :items="hits"
            @row-clicked="onRowClicked">
            <!-- Headings -->
            <template #head(status)>
              {{ $t('message.tableColumnHeadings.status') }}
            </template>
            <template #head(availableFrom)>
              {{ $t('message.tableColumnHeadings.availableFrom') }}
            </template>
            <template #head(numericId)>
              {{ $t('message.tableColumnHeadings.numericId') }}
            </template>
            <template #head(objectNumber)>
              {{ $t('message.tableColumnHeadings.objectNumber') }}
            </template>
            <template #head(objects)>
              {{ $t('message.tableColumnHeadings.objects') }}
            </template>
            <template #head(reference)>
              {{ $t('message.tableColumnHeadings.reference') }}
            </template>
            <template #head(address)>
              {{ $t('message.tableColumnHeadings.address') }}
            </template>
            <template #head(type)>
              {{ $t('message.tableColumnHeadings.type') }}
            </template>
            <template #head(assignee)>
              {{ $t('message.tableColumnHeadings.assignee') }}
            </template>
            <template #head(partner)>
              {{ $t('message.tableColumnHeadings.partner') }}
            </template>
            <template #head(noteForMarketing)>
              {{ $t('message.tableColumnHeadings.noteForMarketing') }}
            </template>
            <template #head(viewingContact)>
              {{ $t('message.tableColumnHeadings.viewingContact') }}
            </template>
            <template #head(tenant)>
              {{ $t('message.tableColumnHeadings.tenant') }}
            </template>
            <template #head(netRent)>
              <span>{{ $t('message.tableColumnHeadings.netRent') }}</span>
            </template>
            <template #head(grossRent)>
              <span>{{ $t('message.tableColumnHeadings.grossRent') }}</span>
            </template>
            <template #head(HeatingAdditionalCosts)>
              {{ $t('message.tableColumnHeadings.HeatingAdditionalCosts') }}
            </template>

            <!-- Data -->
            <template
              #cell(status)="data">
              <div
                class="color-box mr-2"
                :class="getFullStatusColorClass(data.item)" />
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="getFullStatusText(data.item, true)" />
              <!-- eslint-enable vue/no-v-html -->
            </template>
            <template #cell(info)="data">
              <span v-if="data.item.tenancy.vacancy && data.item.status.toLowerCase() === 'vacant'"> {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.' + data.item.tenancy.vacancyReason) }}</span>
              <span v-else-if="!data.item.tenancy.vacancy && data.item.status.toLowerCase() === 'current_terminated'"> {{ getTerminationReasons(data.item.tenancy) }}</span>
              <span v-else> - </span>
            </template>
            <template #cell(availableFrom)="data">
              <span v-if="data.item.tenancy.nextPossibleMoveInAt && data.item.tenancy.vacancyReason === 'VACANCY_REASON_RENOVATION'">{{ oneDayAfter(data.item.tenancy.nextPossibleMoveInAt) | objectToDate }}</span>
              <span v-else-if="data.item.tenancy.vacancyReason === 'VACANCY_REASON_RENOVATION'">
                <coozzy-info-circle-icon
                  v-b-tooltip="{boundary : 'window', title : $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.renovationTooltip')}" />
              </span>
              <span v-else-if="data.item.tenancy.nextPossibleMoveInAt">{{ data.item.tenancy.nextPossibleMoveInAt | objectToDate }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(numericId)="data">
              <router-link
                :to="{ name: 'AccountingBuildingDetailsView', params: { id: data.item.buildingId }, query: {view: 'objects', elementSelected: data.item.objectID } }">
                {{ data.item.numericId | displayOptionalValue }}
              </router-link>
            </template>
            <template #cell(objectNumber)="data">
              {{ data.item.number | displayOptionalValue }}
            </template>
            <template
              #cell(reference)="data">
              <router-link
                v-if="hasAccessMarketBase && data.item.networkPartnerMarketing && data.item.networkPartnerMarketing.accountId === accountId && data.item.inMarketingStatus !== 'no'"
                :to="{ name: 'MarketingObjectDetailsView', params: { id: data.item.objectID }, query: { view: 0 } }">
                {{ data.item.publicId | displayOptionalValue }}
              </router-link>
              <span v-else>
                {{ data.item.publicId | displayOptionalValue }}
              </span>
            </template>
            <template #cell(objects)="data">
              <router-link
                :to="{ name: 'AccountingBuildingDetailsView', params: { id: data.item.buildingId }, query: {view: 'objects', elementSelected: data.item.objectID } }">
                {{ data.item.name | displayOptionalValue }}
              </router-link>
            </template>
            <template #cell(address)="data">
              <address-text :address="data.item.address" />
            </template>
            <template
              #cell(type)="data">
              <template v-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(assignee)="data">
              {{
                data.item.assignees && data.item.assignees.administration && data.item.assignees.administration.name && data.item.assignees.administration.name !== 'Unassigned Nicht zugeordnet Non assegnato' ? data.item.assignees.administration.name : '-'
              }}
            </template>
            <template #cell(partner)="data">
              <span
                v-b-tooltip="{container: '#marketingObject', title: $t('message.generic.accountId') + data.item.owner.id}"
                class="d-block">
                <img
                  v-if="data.item.owner.name"
                  height="20px"
                  src="/img/propbase-icon.png"
                  alt="prop-base">
                {{ data.item.owner.name | displayOptionalValue }}
              </span>
              <span
                v-if="data.item.networkPartnerAsset && data.item.networkPartnerAsset.name !== 'Unassigned Nicht zugeordnet Non assegnato'"
                v-b-tooltip="{container: '#marketingObject', title: $t('message.generic.accountId') + data.item.networkPartnerAsset.accountId}"
                class="d-block">
                <img
                  height="20px"
                  src="/img/assetbase-icon.png"
                  alt="asset-base">
                {{ data.item.networkPartnerAsset.name }}
              </span>
              <span
                v-if="data.item.networkPartnerAdministration && data.item.networkPartnerAdministration.name !== 'Unassigned Nicht zugeordnet Non assegnato'"
                v-b-tooltip="{container: '#marketingObject', title: $t('message.generic.accountId') + data.item.networkPartnerAdministration.accountId}"
                class="d-block">
                <img
                  height="20px"
                  src="/img/adminbase-icon.png"
                  alt="admin-base">
                {{ data.item.networkPartnerAdministration.name }}
              </span>
              <span
                v-if="data.item.networkPartners.length > 0 && data.item.networkPartners.find(element => element.scope ==='marketing')"
                v-b-tooltip="{container: '#marketingObject', title: $t('message.generic.accountId') + data.item.networkPartners.find(element => element.scope ==='marketing').accountId}"
                class="d-block">
                <img
                  height="20px"
                  src="/img/marketbase-icon.png"
                  alt="market-base">
                {{ data.item.networkPartners.find(element => element.scope === 'marketing').name }}
              </span>
            </template>
            <template #cell(noteForMarketing)="data">
              <template v-if="data.item.marketingStatus && data.item.marketingStatus.marketingNote">
                {{ data.item.marketingStatus.marketingNote }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(viewingContact)="data">
              <template v-if="data.item.assignees && data.item.assignees.viewingAppointment && data.item.assignees.viewingAppointment.contactId && data.item.assignees.viewingAppointment.contactId !== ''">
                <router-link
                  :to="{ name: 'AccountingContactDetailsView', params: { id: data.item.assignees.viewingAppointment.contactId } }">
                  <span v-if="data.item.assignees.viewingAppointment.name !== 'Unassigned Nicht zugeordnet Non assegnato'">
                    {{ data.item.assignees.viewingAppointment.name }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </router-link>
              </template>
              <template v-else-if="data.item.assignees.viewingAppointment.name !== 'Unassigned Nicht zugeordnet Non assegnato'">
                <span>
                  {{ data.item.assignees.viewingAppointment.name }}
                </span>
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(tenant)="data">
              <template v-if="data.item.tenancy && data.item.tenancy.tenants && data.item.tenancy.tenants.length > 0">
                <router-link
                  v-for="(tenant, indexTenant) in data.item.tenancy.tenants"
                  :key="tenant.contactId"
                  :to="{ name: 'AdminContactDetailsView', params: { id: tenant.contactId } }">
                  {{ tenant.name }}<span v-if="indexTenant < data.item.tenancy.tenants.length - 1">,</span><br>
                </router-link>
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template
              #cell(netRent)="data">
              <div class="text-nowrap">
                <template v-if="data.item.pricing && data.item.pricing.netRent">
                  <span>{{ data.item.pricing.netRent | formatPrice }}</span>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </template>
            <template #cell(grossRent)="data">
              <div class="text-nowrap">
                <template v-if="data.item.pricing && data.item.pricing.grossRent">
                  <span>{{ data.item.pricing.grossRent | formatPrice }}</span>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </template>
            <template #cell(HeatingAdditionalCosts)="data">
              <div class="text-nowrap">
                <template v-if="data.item.pricing && data.item.pricing.additionalCosts">
                  <span>{{ data.item.pricing.additionalCosts | formatPrice }}</span>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </template>
          </b-table>
        </template>
      </ais-state-results>
      <pagination-algolia
        ref="paginator" />
    </ais-instant-search>
    <b-modal
      id="adminMarketingConfirmationModal"
      ref="adminMarketingConfirmationModal"
      no-close-on-backdrop
      :title="$t('message.marketingObjects.chooseAction')"
      hide-footer>
      <p>{{ $t('message.marketingObjects.chooseActionDescription') }}</p>
      <div class="col pl-0">
        <coozzy-button
          size="small"
          design="default"
          @click="$bvModal.hide('adminMarketingConfirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="prop-green"
          @click="markForMarketingClicked(false)">
          {{ $t('message.marketingObjects.markForMarketing') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0 mr-3"
          design="prop-green"
          @click="addNewTenantClicked">
          {{ $t('message.marketingObjects.addNewTenant') }}
        </coozzy-button>
      </div>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import AddressText from '../framework/components/misc/AddressText'
import CoozzyFormSelect from '../framework/components/form/select/CoozzyFormSelect'
import CoozzyPageTitle from '../framework/layout/CoozzyPageTitle'
import DebouncedAlgoliaSearchBox from '../framework/components/DebouncedAlgoliaSearchBox'
import PaginationAlgolia from '../framework/components/PaginationAlgolia'
import CoozzyAssigneeMultiselect from '../framework/components/multiselect/CoozzyAssigneeMultiselect'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import { onboarding } from '@/mixins/onboarding'
import { formatting } from '@/mixins/formatting'
import { algolia } from '@/mixins/algolia'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { user } from '@/mixins/user'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { obj } from '@/mixins/object'
import { tables } from '@/mixins/tables'
import { mapActions, mapGetters } from 'vuex'
import AssembleListModal from '@/contact/components/AssembleListModal'
import SearchApi from '@/misc/apis/SearchApi'

export default {
  name: 'AccountingMarketingObjectsView',
  components: {
    CoozzyButton,
    CoozzySpinner,
    CoozzyMultiselect,
    DebouncedAlgoliaSearchBox,
    CoozzyPageTitle,
    PaginationAlgolia,
    CoozzyFormSelect,
    CoozzyAssigneeMultiselect,
    AddressText,
    AssembleListModal
  },
  mixins: [user, onboarding, obj, formatting, algolia, tables],
  props: {
    filter: {
      type: String,
      default: null
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.management.marketingList')
    }
  },
  data() {
    return {
      showFirst: false,
      checkfilter: false,
      searchText: '',
      searchStatus: [],
      searchFutureStatus: [],
      searchMarketingStatus: [],
      searchCategories: [],
      searchAdministration: [],
      searchAdminOwner: [],
      searchAdminAssignee: [],
      selectedItem: null,
      fields: [],
      dataFields: [
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle status-td-icon',
          selected: true
        },
        {
          key: 'info',
          label: this.$t('message.tableColumnHeadings.info'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'availableFrom',
          label: this.$t('message.tableColumnHeadings.availableFrom'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objectNumber',
          label: this.$t('message.tableColumnHeadings.objectNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'numericId',
          label: this.$t('message.tableColumnHeadings.numericId'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'reference',
          label: this.$t('message.tableColumnHeadings.reference'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'partner',
          label: this.$t('message.tableColumnHeadings.partner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'noteForMarketing',
          label: this.$t('message.tableColumnHeadings.noteForMarketing'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'viewingContact',
          label: this.$t('message.tableColumnHeadings.viewingContact'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'netRent',
          label: this.$t('message.tableColumnHeadings.netRent'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'HeatingAdditionalCosts',
          label: this.$t('message.tableColumnHeadings.HeatingAdditionalCosts'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'grossRent',
          label: this.$t('message.tableColumnHeadings.grossRent'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        }
      ],
      selectedFields: []
    }
  },
  computed: {
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    filterQuery() {
      let query = 'condominiumOwnership:false AND marketingStatus.vacantAt > 0 AND tenancy.notActivatedAndTenancy:false AND inactive:false'

      if (!this.isPlatformEmployee) {
        query += ` AND networkPartners.combinedId:${this.accountId.toString()}-accounting`
      }

      return query
    }
  },
  watch: {
    nbrPerPage: function () {
      if (this.showFirst) {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.refresh()
          }
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.redirect(this.$route.query.page)
          }
        })
      }
      this.showFirst = true
    }
  },
  mounted: function () {
    this.loadInstantSearch()
    this.dataFields = this.dataFields.filter(x => x.selected).concat(this.dataFields.filter(x => !x.selected))
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'marketing_objects') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    })
    setTimeout(() => {
      this.$refs.debouncedAlgolia?.setFocusInput()
    }, 500)
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'marketing_objects').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting, false)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.objectsTable?.refresh()
    },
    oneDayAfter(item) {
      let timestamp = Date.parse(item.month + '/' + item.day + '/' + item.year)
      let dateObject = new Date(timestamp)
      dateObject.setDate(dateObject.getDate() + 1)
      const newObject = {
        day: dateObject.getDate(),
        month: dateObject.getMonth() + 1,
        year: dateObject.getFullYear()
      }
      return newObject
    },
    getAssigneeData(items) {
      items.forEach(element => {
        const emp = this.employeeList.find(x => (x.profile.lastName + ' ' + x.profile.firstName) === element.label)
        if (emp) {
          element.active = !emp.blocked
        }
      })
      return [{
        employee: this.$t('message.search.filterAll'),
        employees: items
      }]
    },
    selectValue(item, items, refine) {
      if (item === undefined) {
        if (this.searchAdminAssignee.length > 0) {
          this.searchAdminAssignee.map(a => a.value).forEach(element => {
            refine(element)
          })
        }
        const result = items.map(a => a.value)
        result.forEach(element => {
          refine(element)
        })
      } else {
        refine(item)
      }
    },
    removeValue(item, items, refine) {
      if (item === undefined) {
        const result = items.map(a => a.value)
        result.forEach(element => {
          refine(element)
          this.searchAdminAssignee.length = 0
        })
      } else {
        refine(item)
      }
    },
    getDisplayedStatus(obj) {
      const status = this.getStatus(obj)
      const marketingStatus = this.getMarketingStatus(obj)
      const futureStatus = this.getFutureStatus(obj)
      return this.$t('message.onBoarding.buildings.objects.status.' + status) +
        (marketingStatus !== 'no' ? '<br>' + this.$t('message.onBoarding.buildings.objects.inMarketingStatus.' + marketingStatus) : '') +
        (obj.marketingStatus && obj.marketingStatus.markedForMarketingAt > 0 && marketingStatus !== 'no' ? `<br>${this.$t('message.generic.since')} ${this.$options.filters.timestampToDate(obj.marketingStatus.markedForMarketingAt)}` : '') +
        (futureStatus ? '<br>' + this.$t('message.onBoarding.buildings.objects.futureStatus.' + futureStatus) : '')
    },
    getTerminationReasons(tenancy) {
      const array = []
      const list = tenancy.terminationReasons.filter(function(item, pos) {
        return tenancy.terminationReasons.indexOf(item) === pos
      })
      for (const reason of list) {
        array.push(this.$t('message.enterTermination.reasons.' + reason))
      }
      return array.join(', ')
    },
    initFilter(refine) {
      this.searchText = ''
      this.searchStatus = []
      this.searchFutureStatus = []
      this.searchMarketingStatus = []
      this.searchCategories = []
      this.searchAdminOwner = []
      this.searchAssetManager = []
      this.searchMarketer = []
      this.searchAdministration = []
      this.searchAdminAssignee = []
      refine()
    },
    clickOnClear() {
      if (this.$refs.clearClick) {
        this.$refs.clearClick.click()
      } else {
        this.searchText = ''
        this.searchStatus = []
        this.searchFutureStatus = []
        this.searchMarketingStatus = []
        this.searchCategories = []
        this.searchAdminOwner = []
        this.searchAssetManager = []
        this.searchMarketer = []
      }
    },
    setCanRefine(canRefine) {
      this.checkfilter = canRefine || this.searchStatus.length > 0
      return ''
    },
    customLabelStatus(item) {
      return this.$t('message.onBoarding.buildings.objects.status.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelFutureStatus(item) {
      return this.$t('message.onBoarding.buildings.objects.futureStatus.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelMarketingStatus(item) {
      return this.$t('message.onBoarding.buildings.objects.inMarketingStatus.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    redirectToNetworkDetailPage(id) {
      this.$router.push({
        name: 'OwnerNetworkPartnerDetailsView',
        params: { id: id }
      })
    },
    navigateToContactDetail(id) {
      this.$router.push({
        name: 'AccountingContactDetailsView',
        params: { id: id }
      })
    },
    onRowClicked(item) {
      if (this.userWithoutRole) {
        return
      }
      this.selectedItem = item
      if ((this.getFutureStatus(item) !== 'reserved' && this.getStatus(item) !== 'not_activated') || (this.getStatus(item) === 'current_terminated' && this.getFutureStatus(item) === 'reserved')) {
        if (!item.marketingStatus.inMarketing) {
          this.$bvModal.show('adminMarketingConfirmationModal')
        } else {
          this.$nextTick(() => {
            this.markForMarketingClicked(true)
          })
        }
      } else {
        if (item.marketingStatus.inMarketing) {
          this.$nextTick(() => {
            this.markForMarketingClicked(true)
          })
        }
      }
    },
    getLA(object) {
      if (object.category === 'house' || object.category === 'APARTMENT' || object.category === 'commercial' || object.category === 'parking_space' || object.category === 'secondary_room') {
        return object.effectiveArea
      } else {
        return object.livingArea
      }
    },
    customLabel(item) {
      if (item.value === 'Unassigned Nicht zugeordnet Non assegnato') {
        return '- (' + item.count + ')'
      }
      return item.value + ' (' + item.count + ')'
    },
    customLabelFloor(item) {
      return this.formatFloor(item.value) + ' (' + item.count + ')'
    },
    customLabelCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    markForMarketingClicked(editMarketingInfoMode) {
      this.$router.push({
        name: 'AccountingMarkForMarketingView',
        params: { id: this.selectedItem.objectID, from: 'accountingOpenRental' },
        query: { editMarketingInfoMode: editMarketingInfoMode }
      })
    },
    addNewTenantClicked() {
      this.$router.push({
        name: 'AccountingAddTenancyView',
        params: {
          objectId: this.selectedItem.objectID
        }
      })
    },
    sortOptions(item) {
      return item.sort(function(a, b) {
        if (a.value === b.value) return 0
        if (a.value === 'Unassigned Nicht zugeordnet Non assegnato') return 1
        if (b.value === 'Unassigned Nicht zugeordnet Non assegnato') return -1
        return 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
