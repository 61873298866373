<template>
  <font-awesome-icon
    icon="sign-out-alt"
    class="fa" />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faSignOutAlt)

export default {
  name: 'CoozzyLogoutIcon'
}
</script>
