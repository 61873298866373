import { render, staticRenderFns } from "./EnterTerminationReview.vue?vue&type=template&id=5bb32570&scoped=true"
import script from "./EnterTerminationReview.vue?vue&type=script&lang=js"
export * from "./EnterTerminationReview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb32570",
  null
  
)

export default component.exports