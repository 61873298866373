import store from '../../store/store'
import * as Api from '@/types/api'
import axios, { AxiosRequestConfig } from 'axios'

const instance = new Api.Api({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: 30000,
  withCredentials: true
})
// WeakMap to store metadata
const requestMetadata = new WeakMap<AxiosRequestConfig, { controller: AbortController }>()

// Add a request interceptor
instance.instance.interceptors.request.use(function (config) {
  const token = store.getters['user/getAccessToken']
  config.headers.Authorization = `Bearer ${token}`

  // Create a new AbortController for each request
  const controller = new AbortController()
  config.signal = controller.signal

  // Store the controller in the WeakMap
  requestMetadata.set(config, { controller })

  return config
})

export default {
  ...instance.web,
  getController(config: AxiosRequestConfig) {
    return requestMetadata.get(config)?.controller
  }

}
