<template>
  <section>
    <b-modal
      id="setClient"
      ref="setClient"
      v-model="setModalShow"
      no-close-on-backdrop
      :class="setModalDisabled ? 'no-close' : ''">
      <div slot="modal-title">
        {{ title }}
      </div>
      <div
        v-if="setModalDisabled"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <div
        v-else
        class="d-block text-left">
        <coozzy-form-select
          v-model="newElementId">
          <option :value="null">
            -
          </option>
          <option
            v-for="element in elementListSorted"
            :key="element.id"
            :value="element.id">
            <template v-if="type === 'networkPartner' && element.company">
              {{ element.company.name }}
            </template>
            <template v-else-if="type === 'networkPartner' && element.individual">
              {{ element.individual.firstName.substr(0, 1) }}. {{ element.individual.lastName }}
            </template>
            <template v-else-if="type === 'employee'">
              {{ element.firstName }} {{ element.lastName }}
            </template>
          </option>
        </coozzy-form-select>
      </div>
      <div
        slot="modal-footer"
        class="w-100">
        <coozzy-button
          design="green"
          :disabled="setModalDisabled"
          class="float-right ml-3"
          @click="saveElement">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
        <coozzy-button
          design="default"
          :disabled="setModalDisabled"
          @click="setModalShow = false">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyButton from '@/framework/components/button/CoozzyButton'

export default {
  name: 'SetAdvertRelations',
  components: {
    CoozzyButton,
    CoozzyFormSelect,
    CoozzySpinner
  },
  props: {
    elementList: {
      type: Array,
      default: function () {
        return []
      }
    },
    title: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      setModalDisabled: false,
      setModalShow: false,
      newElementId: null
    }
  },
  computed: {
    elementListSorted() {
      const array = JSON.parse(JSON.stringify(this.elementList))
      array.sort((a, b) => {
        const nameA = (this.type === 'networkPartner' && a.company) ? a.company.name : (this.type === 'networkPartner' && a.individual) ? a.individual.firstName + ' ' + a.individual.lastName : this.type === 'employee' ? a.firstName + ' ' + a.lastName : ''
        const nameB = (this.type === 'networkPartner' && b.company) ? b.company.name : (this.type === 'networkPartner' && b.individual) ? b.individual.firstName + ' ' + b.individual.lastName : this.type === 'employee' ? b.firstName + ' ' + b.lastName : ''
        if (nameA > nameB) {
          return 1
        }
        if (nameA < nameB) {
          return -1
        }
        return 0
      })
      return array
    }
  },
  methods: {
    saveElement() {
      this.setModalDisabled = true
      this.$emit('save', this.elementList.find(e => e.userReference === this.newElementId || e.id === this.newElementId))
      this.newElementId = null
    },
    show() {
      this.$refs.setClient.show()
    },
    hide() {
      this.$refs.setClient.hide()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
