<template>
  <font-awesome-icon
    icon="external-link-alt"
    class="fa" />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faExternalLinkAlt)

export default {
  name: 'CoozzyExternalLinkIcon'
}
</script>

<style lang="scss" scoped>
  .fa {
    color: $color-text-grey;
    width: 1rem;
  }
</style>
