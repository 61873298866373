<template>
  <b-alert
    show=""
    :class="[size === 'sm' ? 'font-lg' : '', variant === 'blue' ? 'alert-blue' : '']"
    :variant="variant">
    <slot />
  </b-alert>
</template>

<script>
export default {
  name: 'CoozzyAlert',
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: 'warning'
    },
    size: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-blue {
  background-color: $color-table-row-2;
}
</style>
