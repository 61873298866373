<template>
  <section>
    <div class="row details-row-bg-1 py-01rem">
      <div class="col-sm-12 col-md-8">
        <coozzy-form-input
          :value="building.address.street === '' || building.address.street === null ? '-' : building.address.street + ' ' + (building.address.streetNumber || '') + ', ' + building.address.city + ' ' + building.address.zip "
          :is-read-only="readOnly"
          :disabled="!readOnly"
          :name="$t('message.onBoarding.buildings.address')" />
      </div>
      <div class="col-sm-12 col-md-4">
        <label for="constructionYear">{{ $t('message.onBoarding.buildings.constructionYear') }}</label>
        <coozzy-form-select
          v-if="!readOnly"
          id="constructionYear"
          v-model="constructionYear"
          :state="$v.constructionYear.$dirty && $v.constructionYear.$error ? false : null">
          <option value="-1" />
          <option
            v-for="year in years"
            :key="year"
            :value="year">
            {{ year }}
          </option>
        </coozzy-form-select>
        <span
          v-else
          class="show-data">{{ constructionYear === null ? '-' : constructionYear }}</span>
      </div>
    </div>
    <div class="row details-row-bg-2 py-01rem">
      <div class="col-sm-12 col-md-4">
        <label for="yearLastRenovation">{{ $t('message.onBoarding.buildings.renovationYear') }}</label>
        <coozzy-form-select
          v-if="!readOnly"
          id="yearLastRenovation"
          v-model="renovationYear"
          :state="$v.renovationYear.$dirty && $v.renovationYear.$error ? false : null">
          <option value="-1" />
          <option
            v-for="year in years"
            :key="year"
            :value="year">
            {{ year }}
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-sm-12 col-md-4">
        <coozzy-form-input-numeric
          v-model="plotArea"
          type="number"
          :disabled="true"
          :filter="'formatArea'"
          placeholder="m²"
          :is-decimal="true"
          :check-valide="$v.plotArea.$dirty && $v.plotArea.$error ? 'is-invalid' : ''"
          :name="$t('message.manageBuilding.plotArea')" />
      </div>
      <div class="col-sm-12 col-md-4">
        <coozzy-multiselect
          id="constructionTypes"
          v-model="constructionTypes"
          :options="translatedConstructionTypes"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          label="label"
          track-by="label">
          {{ $t('message.onBoarding.buildings.constructionTypes.title') }}
        </coozzy-multiselect>
      </div>
    </div>
  </section>
</template>

<script>
import { onboarding } from '../mixins/onboarding'
import { maxLength, minLength, minValue, numeric, required } from 'vuelidate/lib/validators'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import { formatting } from '../mixins/formatting'
import { validation } from '@/mixins/validation'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'

export default {
  name: 'MarketingObjectBasics',
  components: {
    CoozzyFormSelect,
    CoozzyMultiselect
  },
  mixins: [onboarding, formatting, validation],
  props: {
    objectSelected: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      item: this.objectSelected,
      availableConstructionTypes: ['OLD_BUILDING', 'NEW_BUILDING', 'HIGH_RISE_BUILDING', 'MIDDLE_TERRACED_HOUSE', 'RAISED_GROUND_FLOOR', 'CORNER_BUILDING', 'MINERGIE_CONSTRUCTION', 'MINERGIE_CERTIFIED', 'LISTED_BUILDING'],
      plotArea: 0
    }
  },
  computed: {
    years () {
      const year = new Date().getFullYear()
      return Array.from({ length: year - 1800 }, (value, index) => 1801 + index)
    },
    constructionYear: {
      get() {
        return this.building.constructionYear !== -1 ? this.building.constructionYear : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          { field: 'constructionYear', value: value, dataType: 'number' })
        this.$emit('edit-building', { field: 'constructionYear', value: value })
      }
    },
    constructionTypes: {
      get() {
        return this.building.constructionTypes.filter(con => con !== 'UNDEFINED_CONSTRUCTION_TYPE').map(conTyp => {
          return {
            label: this.$t('message.onBoarding.buildings.constructionTypes.' + conTyp),
            value: conTyp
          }
        })
      },
      set(value) {
        const conTypValues = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateBuildingField', { field: 'constructionTypes', value: conTypValues })
        this.$emit('edit-building', { field: 'constructionTypes', value: value })
      }
    },
    translatedConstructionTypes() {
      const array = []
      this.availableConstructionTypes.forEach(conTyp => {
        array.push({
          label: this.$t('message.onBoarding.buildings.constructionTypes.' + conTyp),
          value: conTyp
        })
      })
      return array
    },
    renovationYear: {
      get() {
        return this.building.renovationYear !== -1 ? this.building.renovationYear : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          { field: 'renovationYear', value: value, dataType: 'number' })
        this.$emit('edit-building', { field: 'renovationYear', value: value })
      }
    }
  },
  watch: {
  },
  mounted: function () {
    const plotIds = this.building.plotIds
    plotIds.forEach(element => {
      const searchedElement = this.plots.find(x => x.id === element)
      if (searchedElement) {
        this.plotArea += parseInt(searchedElement.plotArea)
      }
    })
  },
  methods: {
    autocompletePlaceChanged(data) {
      this.updateWithAddressComponents(data.address_components)
    },
    updateWithAddressComponents(addressComponents) {
      const streetNo = addressComponents.find((component) => component.types.find((type) => type === 'street_number'))
      const street = addressComponents.find((component) => component.types.find((type) => type === 'route'))
      const city = addressComponents.find((component) => component.types.find((type) => type === 'locality'))
      const zip = addressComponents.find((component) => component.types.find((type) => type === 'postal_code'))
      const state = addressComponents.find((component) => component.types.find((type) => type === 'administrative_area_level_1'))
      const country = addressComponents.find((component) => component.types.find((type) => type === 'country'))

      if (country && country.short_name === 'CH') {
        if (streetNo) {
          this.building.address.streetNo = streetNo.long_name
        }
        if (street) {
          this.building.address.street = street.long_name
        }
        if (city) {
          this.building.address.city = city.long_name
        }
        if (zip) {
          this.building.address.zip = zip.long_name
        }
        if (state) {
          this.building.address.state = state.short_name
        }
      } else {
        // TODO Alert
      }
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      return this.$v.$invalid
    }
  },
  validations: {
    building: {
      address: {
        street: {
          required
        }
      }
    },
    constructionYear: {
      minLength: minLength(4),
      maxLength: maxLength(4),
      numeric
    },
    renovationYear: {
      minLength: minLength(4),
      maxLength: maxLength(4),
      numeric
    },
    plotArea: {
      minValue: minValue(0)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
