<template>
  <section>
    <div
      v-b-toggle="`collapse-object-pricing-${item.id}`"
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ $t('message.onBoarding.buildings.objects.pricing.title') }}
      </h5>
    </div>
    <b-collapse
      :id="`collapse-object-pricing-${item.id}`"
      :visible="expandByDefault || openedCollapses.filter(s => s.includes(`collapse-object-pricing-`)).length > 0">
      <div class="row details-row-bg-1">
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="upperMarketNetRent"
            type="number"
            min="0"
            :disabled="true"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.upperMarketNetRent')" />
        </div>
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="upperMarketHeatingAndAdditional"
            type="number"
            min="0"
            :disabled="true"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.upperMarketHeatingAndAdditional')" />
        </div>
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="upperMarketGrossRent"
            type="number"
            min="0"
            :disabled="true"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.upperMarketGrossRent')" />
        </div>
      </div>
      <div class="row details-row-bg-2">
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="underMarketNetRent"
            type="number"
            min="0"
            :disabled="true"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.underMarketNetRent')" />
        </div>
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="underMarketHeatingAndAdditional"
            type="number"
            min="0"
            :disabled="true"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.underMarketHeatingAndAdditional')" />
        </div>
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="underMarketGrossRent"
            type="number"
            min="0"
            :disabled="true"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.underMarketGrossRent')" />
        </div>
      </div>
      <div class="row details-row-bg-1">
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="marketNetRent"
            type="number"
            min="0"
            :disabled="true"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.marketNetRent')" />
        </div>
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="marketHeatingAndAdditional"
            type="number"
            min="0"
            :disabled="true"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.marketHeatingAndAdditional')" />
        </div>
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="marketGrossRent"
            type="number"
            min="0"
            :disabled="true"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.marketGrossRent')" />
        </div>
      </div>
      <div class="row details-row-bg-2">
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="targetMarketNetRent"
            type="number"
            min="0"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.targetMarketNetRent')"
            @input="calculateTargetMarketGrossRent()" />
        </div>
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="targetMarketHeatingAndAdditional"
            type="number"
            min="0"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.targetMarketHeatingAndAdditional')"
            @input="calculateTargetMarketGrossRent()" />
        </div>
        <div class="col-sm-12 col-md-4 mt-3">
          <coozzy-form-input-numeric
            v-model="targetMarketGrossRent"
            type="number"
            min="0"
            :disabled="true"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.targetMarketGrossRent')" />
        </div>
      </div>
      <!-- <div class="row details-row-bg-2">
        <div class="col-sm-12 col-md-3 mt-3">
          <label for="paymentInterval">
            {{ $t('message.onBoarding.buildings.objects.pricing.paymentInterval.title') }}
          </label>
          <coozzy-form-select
            v-if="!readOnly"
            id="paymentInterval"
            v-model="paymentInterval">
            <option value="UNDEFINED_PAYMENT_INTERVAL" />
            <option value="MONTHLY">
              {{ $t('message.onBoarding.buildings.objects.pricing.paymentInterval.monthly') }}
            </option>
            <option value="QUARTERLY">
              {{ $t('message.onBoarding.buildings.objects.pricing.paymentInterval.quarterly') }}
            </option>
            <option value="HALF_YEARLY">
              {{ $t('message.onBoarding.buildings.objects.pricing.paymentInterval.halfYearly') }}
            </option>
            <option value="YEARLY">
              {{ $t('message.onBoarding.buildings.objects.pricing.paymentInterval.yearly') }}
            </option>
          </coozzy-form-select>
          <span
            v-else
            class="show-data">
            <span v-if="paymentInterval === 'MONTHLY'"> {{ $t('message.onBoarding.buildings.objects.pricing.paymentInterval.monthly') }} </span>
            <span v-else-if="paymentInterval === 'QUARTERLY'"> {{ $t('message.onBoarding.buildings.objects.pricing.paymentInterval.quarterly') }} </span>
            <span v-else-if="paymentInterval === 'HALF_YEARLY'"> {{ $t('message.onBoarding.buildings.objects.pricing.paymentInterval.halfYearly') }} </span>
            <span v-else-if="paymentInterval === 'YEARLY'"> {{ $t('message.onBoarding.buildings.objects.pricing.paymentInterval.yearly') }} </span>
            <span v-else> - </span>
          </span>
        </div>
        <div class="col-sm-12 col-md-3 mt-3">
          <coozzy-form-input-numeric
            v-model="marketRent"
            type="number"
            min="0"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.marketRent')" />
        </div>
        <div class="col-sm-12 col-md-3 mt-3">
          <coozzy-form-input-numeric
            v-model="commonDistrictRent"
            type="number"
            min="0"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.commonDistrictRent')" />
        </div>
        <div class="col-sm-12 col-md-3 mt-3">
          <coozzy-form-input-numeric
            v-model="rentReserves"
            type="number"
            min="0"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.rentReserves')" />
        </div>
      </div>
      <div class="row details-row-bg-2">
        <div class="col-sm-12 col-md-3 mt-3">
          <coozzy-form-input-numeric
            v-model="targetColdRent"
            type="number"
            min="0"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.targetColdRent')" />
        </div>
        <div class="col-sm-12 col-md-3 mt-3">
          <coozzy-form-input-numeric
            v-model="targetAdditional"
            type="number"
            min="0"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.targetAdditional')" />
        </div>
        <div class="col-sm-12 col-md-3 mt-3">
          <coozzy-form-input-numeric
            v-model="targetHeating"
            type="number"
            min="0"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.targetHeating')" />
        </div>
        <div class="col-sm-12 col-md-3 mt-3">
          <coozzy-form-input
            v-if="targetGrossRent=== null"
            :is-read-only="readOnly"
            :value="'-'"
            :disabled="true"
            class="mb-3"
            :name="$t('message.onBoarding.buildings.objects.pricing.targetGrossRent')" />
          <coozzy-form-input-numeric
            v-else
            v-model="targetGrossRent"
            type="number"
            min="0"
            :is-read-only="readOnly"
            :disabled="true"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.targetGrossRent')" />
        </div>
      </div>
      <div class="row details-row-bg-1">
        <div class="col-sm-12 col-md-3 mt-3">
          <coozzy-form-input-numeric
            v-model="targetHeating"
            type="number"
            min="0"
            :is-read-only="readOnly"
            :is-decimal="true"
            :filter="'formatPrice'"
            :name="$t('message.onBoarding.buildings.objects.pricing.targetHeating')" />
        </div>
      </div> -->
    </b-collapse>
  </section>
</template>

<script>

// import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import { onboarding } from '../../../mixins/onboarding'
import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'

export default {
  name: 'ObjectPricing',
  components: { CoozzyFormInputNumeric },
  mixins: [onboarding],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    objectSelected: {
      type: Object,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      item: this.objectSelected
    }
  },
  computed: {
    // targetGrossRent() {
    //   if (this.targetColdRent === null && this.targetAdditional === null && this.targetHeating === null) {
    //     return null
    //   } else {
    //     return (this.targetColdRent + this.targetAdditional + this.targetHeating)
    //   }
    // },
    // paymentInterval: {
    //   get() {
    //     return this.item.pricing.paymentInterval
    //   },
    //   set(value) {
    //     const object = this.item
    //     object.pricing.paymentInterval = value
    //     this.$store.dispatch('onboarding/updateObject', object)
    //     this.$emit('update-object-pricing', object)
    //   }
    // },
    // marketRent: {
    //   get() {
    //     return this.item.pricing.marketRent !== -1 ? this.item.pricing.marketRent : null
    //   },
    //   set(value) {
    //     const object = this.item
    //     object.pricing.marketRent = value
    //     this.$store.dispatch('onboarding/updateObject', object)
    //     this.$emit('update-object-pricing', object)
    //   }
    // },
    upperMarketNetRent: {
      get() {
        return this.item.pricing.upperMarketNetRent !== -1 ? this.item.pricing.upperMarketNetRent : null
      },
      set(value) {
        const object = this.item
        object.pricing.upperMarketNetRent = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    upperMarketHeatingAndAdditional: {
      get() {
        return this.item.pricing.upperMarketHeatingAndAdditional !== -1 ? this.item.pricing.upperMarketHeatingAndAdditional : null
      },
      set(value) {
        const object = this.item
        object.pricing.upperMarketHeatingAndAdditional = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    upperMarketGrossRent: {
      get() {
        return this.item.pricing.upperMarketGrossRent !== -1 ? this.item.pricing.upperMarketGrossRent : null
      },
      set(value) {
        const object = this.item
        object.pricing.upperMarketGrossRent = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    underMarketNetRent: {
      get() {
        return this.item.pricing.underMarketNetRent !== -1 ? this.item.pricing.underMarketNetRent : null
      },
      set(value) {
        const object = this.item
        object.pricing.underMarketNetRent = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    underMarketHeatingAndAdditional: {
      get() {
        return this.item.pricing.underMarketHeatingAndAdditional !== -1 ? this.item.pricing.underMarketHeatingAndAdditional : null
      },
      set(value) {
        const object = this.item
        object.pricing.underMarketHeatingAndAdditional = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    underMarketGrossRent: {
      get() {
        return this.item.pricing.underMarketGrossRent !== -1 ? this.item.pricing.underMarketGrossRent : null
      },
      set(value) {
        const object = this.item
        object.pricing.underMarketGrossRent = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    marketNetRent: {
      get() {
        return this.item.pricing.marketNetRent !== -1 ? this.item.pricing.marketNetRent : null
      },
      set(value) {
        const object = this.item
        object.pricing.marketNetRent = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    marketHeatingAndAdditional: {
      get() {
        return this.item.pricing.marketHeatingAndAdditional !== -1 ? this.item.pricing.marketHeatingAndAdditional : null
      },
      set(value) {
        const object = this.item
        object.pricing.marketHeatingAndAdditional = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    marketGrossRent: {
      get() {
        return this.item.pricing.marketGrossRent !== -1 ? this.item.pricing.marketGrossRent : null
      },
      set(value) {
        const object = this.item
        object.pricing.marketGrossRent = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    targetMarketNetRent: {
      get() {
        return this.item.pricing.targetMarketNetRent !== -1 ? this.item.pricing.targetMarketNetRent : null
      },
      set(value) {
        const object = this.item
        object.pricing.targetMarketNetRent = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    targetMarketHeatingAndAdditional: {
      get() {
        return this.item.pricing.targetMarketHeatingAndAdditional !== -1 ? this.item.pricing.targetMarketHeatingAndAdditional : null
      },
      set(value) {
        const object = this.item
        object.pricing.targetMarketHeatingAndAdditional = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    },
    targetMarketGrossRent: {
      get() {
        return this.item.pricing.targetMarketGrossRent !== -1 ? this.item.pricing.targetMarketGrossRent : null
      },
      set(value) {
        const object = this.item
        object.pricing.targetMarketGrossRent = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-pricing', object)
      }
    }
    // rentReserves: {
    //   get() {
    //     return this.item.pricing.rentReserves !== -1 ? this.item.pricing.rentReserves : null
    //   },
    //   set(value) {
    //     const object = this.item
    //     object.pricing.rentReserves = value
    //     this.$store.dispatch('onboarding/updateObject', object)
    //     this.$emit('update-object-pricing', object)
    //   }
    // },
    // targetColdRent: {
    //   get() {
    //     return this.item.pricing.targetColdRent !== -1 ? this.item.pricing.targetColdRent : null
    //   },
    //   set(value) {
    //     const object = this.item
    //     object.pricing.targetColdRent = value
    //     this.$store.dispatch('onboarding/updateObject', object)
    //     this.$emit('update-object-pricing', object)
    //   }
    // },
    // targetAdditional: {
    //   get() {
    //     return this.item.pricing.targetAdditional !== -1 ? this.item.pricing.targetAdditional : null
    //   },
    //   set(value) {
    //     const object = this.item
    //     object.pricing.targetAdditional = value
    //     this.$store.dispatch('onboarding/updateObject', object)
    //     this.$emit('update-object-pricing', object)
    //   }
    // },
    // targetHeating: {
    //   get() {
    //     return this.item.pricing.targetHeating !== -1 ? this.item.pricing.targetHeating : null
    //   },
    //   set(value) {
    //     const object = this.item
    //     object.pricing.targetHeating = value
    //     this.$store.dispatch('onboarding/updateObject', object)
    //     this.$emit('update-object-pricing', object)
    //   }
    // }
  },
  methods: {
    calculateTargetMarketGrossRent() {
      if (this.targetMarketNetRent === null && this.targetMarketHeatingAndAdditional === null) {
        this.targetMarketGrossRent = null
      } else {
        this.targetMarketGrossRent = (this.targetMarketNetRent + this.targetMarketHeatingAndAdditional)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
