<template>
  <coozzy-card :title="$t('message.settings.senderEmailTicket.title')">
    <div class="row">
      <div class="col-12">
        <p class="text-muted">
          {{ $t('message.settings.senderEmailTicket.description') }}
        </p>
      </div>
      <div class="col-sm-3 col-md-3 mb-2">
        <label>{{ $t('message.marketingMessages.mail.mailbox') }}</label>
        <coozzy-form-select
          v-model="selectedMailbox">
          <option
            value="">
            -
          </option>
          <option
            v-for="mailbox in mailBoxList"
            :key="mailbox.id"
            :value="mailbox.id">
            {{ mailbox.outboundEmail }}
          </option>
        </coozzy-form-select>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <coozzy-button
          class="float-right mt-3"
          design="primary"
          @click="saveClicked">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </div>
  </coozzy-card>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import MessageApi from '../../misc/apis/MessageApi'
import TicketApi from '../../misc/apis/TicketApi'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'

export default {
  name: 'SenderEmailSettings',
  components: { CoozzyButton, CoozzyFormSelect, CoozzyCard },
  data() {
    return {
      selectedMailbox: '',
      mailBoxList: []
    }
  },
  computed: {
    ...mapGetters('message', ['getMailboxesForUserId'])
  },
  mounted() {
    this.getMailBoxList()
    this.getNotificationMailbox()
  },
  methods: {
    getMailBoxList() {
      this.loading = false
      if (this.isCompanyAdmin) {
        MessageApi.listMailboxes(this.$store.getters['user/getAccountId'])
          .then(response => {
            this.mailBoxList = response.mailboxes
            this.loading = false
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            Vue.toasted.show(this.$t('message.loadingErrors.mailbox'), { type: 'error' })
          })
      } else {
        MessageApi.listMailboxesByUserId(this.$store.getters['user/getUserId'])
          .then(response => {
            this.mailBoxList = response.mailboxes
            this.loading = false
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            Vue.toasted.show(this.$t('message.loadingErrors.mailbox'), { type: 'error' })
          })
      }
    },
    getNotificationMailbox() {
      TicketApi.getNotificationMailbox(this.$store.getters['user/getAccountId'])
        .then(response => {
          this.selectedMailbox = response.mailboxId
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.mailbox'), { type: 'error' })
        })
    },
    saveClicked() {
      TicketApi.setNotificationMailbox(this.$store.getters['user/getAccountId'], this.selectedMailbox)
        .then(response => {
          console.log(response)
          Vue.toasted.show(this.$t('message.settings.senderEmailTicket.savedSuccessfully'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.settings.senderEmailTicket.savedFailed'), { type: 'error' })
        })
    },
    getAutomaticResponseStatus(automaticResponse) {
      if (automaticResponse?.enabled &&
        automaticResponse.startDate &&
        new Date(this.$options.filters.objectToDateInput(automaticResponse.startDate)) <= new Date()) {
        if (automaticResponse.endDate && new Date(this.$options.filters.objectToDateInput(automaticResponse.endDate)) > new Date()) {
          const startHour = automaticResponse?.startTime?.hours
          const startMinutes = '0' + automaticResponse?.startTime?.minutes
          const endHour = automaticResponse?.endTime?.hours
          const endMinutes = '0' + automaticResponse?.endTime?.minutes
          return this.$t('message.settings.mailbox.automaticResponseStatusWithEnd', { from: this.$options.filters.objectToDate(automaticResponse.startDate), startTime: (((startHour < 10) ? '0' + startHour : startHour) + ':' + startMinutes.substr(-2)), until: this.$options.filters.objectToDate(automaticResponse.endDate), endTime: (((endHour < 10) ? '0' + endHour : startHour) + ':' + endMinutes.substr(-2)) })
        }
        const startHour = automaticResponse?.startTime?.hours
        const startMinutes = '0' + automaticResponse?.startTime?.minutes
        return this.$t('message.settings.mailbox.automaticResponseStatusWithoutEnd', { from: this.$options.filters.objectToDate(automaticResponse.startDate), startTime: (((startHour < 10) ? '0' + startHour : startHour) + ':' + startMinutes.substr(-2)) })
      }
      return '-'
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.copy-icon) {
  color: $color-primary !important;
}
</style>
