<template>
  <b-modal
    :id="'activate-vacancy-modal-'+tenancyId"
    :ref="'activate-vacancy-modal-'+tenancyId"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.onBoarding.buildings.objects.tenancies.activateVacancyConfirmationTitle')">
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col mb-3">
          {{ $t('message.onBoarding.buildings.objects.tenancies.activateVacancyConfirmationBody') }}
        </div>
      </div>

      <div class="row">
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="hide">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="prop-green"
            @click="saveClicked">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import ObjectApi from '@/misc/apis/ObjectApi'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'

export default {
  name: 'ActivateVacancyModal',
  components: {
    CoozzySpinner,
    CoozzyButton
  },
  props: {
    tenancyId: {
      type: [String, Number],
      default: ''
    },
    ownerId: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'activate-vacancy-modal-' + this.tenancyId) {
        this.loading = false
      }
    })
  },
  methods: {
    saveClicked() {
      this.loading = true
      ObjectApi.activateTenancy(this.tenancyId)
        .then(() => {
          Vue.toasted.show(this.$t('message.successMessages.vacancyActivated'), { type: 'success' })
          this.$emit('tenancy-activated')
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.vacancy'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
          this.hide()
        })
    },
    hide() {
      this.$refs['activate-vacancy-modal-' + this.tenancyId].hide()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
