<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="18px"
    height="18px"
    viewBox="0 0 512.007 512.007"
    enable-background="new 0 0 512.007 512.007"
    class="nav-icon fa"
    :style="'filter: invert(30%) sepia(8%) saturate(171%) hue-rotate(161deg) brightness(92%) contrast(89%)'">
    <g>
      <path d="m313.163 257.26c.707-.418 1.423-.823 2.154-1.203 4.512-57.599 57.179-100.677 115.69-91.929v-77.334l-155.894 137.186z" />
      <path d="m497.007 447.013c8.284 0 15-6.716 15-15v-136c0-8.271-6.703-15-15-15h-10c0-17.549.106-19.216-.335-23.822-2.591-27.04-20.385-49.538-44.404-59.123-46.694-18.657-97.261 16.069-97.261 65.944v17h-9c-8.284 0-15 6.716-15 15v136c0 8.284 6.716 15 15 15h161zm-66-79c0 8.284-6.716 15-15 15s-15-6.716-15-15v-8c0-8.284 6.716-15 15-15s15 6.716 15 15zm-56-104c0-22.607 18.393-41 41-41s41 18.393 41 41v17h-82z" />
      <path d="m.016 86.825c-.009 39.395-.043 232.278.026 274.354l156.647-137.167z" />
      <path d="m215.982 236.054 194.312-170.995c-49.145-.081-338.918-.104-389.559.033z" />
      <path d="m291.007 296.013c0-5.549 1.014-10.864 2.858-15.776l-41.466-36.268c-27.811 24.473-26.935 23.885-28.808 24.985-4.535 2.653-10.378 2.805-15.151.01-1.847-1.083-.649-.208-28.98-25.015l-158.797 139.049c17.903.075 76.36.021 270.344.016z" />
    </g>
  </svg>
</template>

<script>

export default {
  name: 'CoozzyConfidentialIcon',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.fa {
  color: $color-text-grey;
}
</style>
