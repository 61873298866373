// @ts-nocheck
const sort = {
  methods: {
    sortByDateInsurances(array) {
      const that = this
      return array.sort(function (a, b) {
        const aStartDate = a.startDate && a.startDate.year && a.startDate.year !== -1 ? new Date(a.startDate.year + '-' + ('0' + a.startDate.month).slice(-2) + '-' + ('0' + a.startDate.day).slice(-2)).getTime() : null
        const aEndDate = a.endDate && a.endDate.year && a.endDate.year !== -1 ? new Date(a.endDate.year + '-' + ('0' + a.endDate.month).slice(-2) + '-' + ('0' + a.endDate.day).slice(-2)).getTime() : null
        const aIsNewItem = a.isNewItem ? 1 : 0
        const bStartDate = b.startDate && b.startDate.year && b.startDate.year !== -1 ? new Date(b.startDate.year + '-' + ('0' + b.startDate.month).slice(-2) + '-' + ('0' + b.startDate.day).slice(-2)).getTime() : null
        const bEndDate = b.endDate && b.endDate.year && b.endDate.year !== -1 ? new Date(b.endDate.year + '-' + ('0' + b.endDate.month).slice(-2) + '-' + ('0' + b.endDate.day).slice(-2)).getTime() : null
        const bIsNewItem = b.isNewItem ? 1 : 0
        return that.sortByDate(aStartDate, aEndDate, bStartDate, bEndDate, aIsNewItem, bIsNewItem)
      })
    },
    sortByDateMortgages(array) {
      const that = this
      return array.sort(function (a, b) {
        const aStartDate = a.validFrom && a.validFrom.year && a.validFrom.year !== -1 ? new Date(a.validFrom.year + '-' + ('0' + a.validFrom.month).slice(-2) + '-' + ('0' + a.validFrom.day).slice(-2)).getTime() : null
        const aEndDate = a.expiresAt && a.expiresAt.year && a.expiresAt.year !== -1 ? new Date(a.expiresAt.year + '-' + ('0' + a.expiresAt.month).slice(-2) + '-' + ('0' + a.expiresAt.day).slice(-2)).getTime() : null
        const aIsNewItem = a.isNewItem ? 1 : 0
        const bStartDate = b.validFrom && b.validFrom.year && b.validFrom.year !== -1 ? new Date(b.validFrom.year + '-' + ('0' + b.validFrom.month).slice(-2) + '-' + ('0' + b.validFrom.day).slice(-2)).getTime() : null
        const bEndDate = b.expiresAt && b.expiresAt.year && b.expiresAt.year !== -1 ? new Date(b.expiresAt.year + '-' + ('0' + b.expiresAt.month).slice(-2) + '-' + ('0' + b.expiresAt.day).slice(-2)).getTime() : null
        const bIsNewItem = b.isNewItem ? 1 : 0
        return that.sortByDate(aStartDate, aEndDate, bStartDate, bEndDate, aIsNewItem, bIsNewItem)
      })
    },
    sortByDate(aStart, aEnd, bStart, bEnd, aIsNewItem, bIsNewItem) {
      return aIsNewItem - bIsNewItem || (bStart === null) - (aStart === null) || (bEnd === null) - (aEnd === null) || bEnd - aEnd || bStart - aStart
    }
  }
}

export { sort }
