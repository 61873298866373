<template>
  <section class="w-100">
    <div v-if="countBuilding !== 0">
      <coozzy-detail-card
        :title="cardTitle">
        <div class="col p-0">
          <div
            v-if="loadingBuildings"
            class="text-center">
            <coozzy-spinner />
          </div>
          <b-table
            v-else
            id="tenantsTable"
            ref="tenantsTable"
            bordered
            hover
            responsive
            selectable
            select-mode="single"
            :fields="fields"
            :items="buildingsList"
            @row-clicked="onRowClicked">
            <template #head(buildingsName)>
              {{ $t('message.tableColumnHeadings.buildingsName') }}
            </template>
            <template #head(buildingId)>
              {{ $t('message.tableColumnHeadings.buildingId') }}
            </template>
            <template #head(address)>
              {{ $t('message.tableColumnHeadings.address') }}
            </template>
            <template #head(owner)>
              {{ $t('message.tableColumnHeadings.owner') }}
            </template>
            <template #head(roleInBuilding)>
              {{ $t('message.tableColumnHeadings.roleInBuilding') }}
            </template>
            <template #head(note)>
              {{ $t('message.tableColumnHeadings.note') }}
            </template>
            <!-- Table content -->
            <template #cell(buildingsName)="data">
              <div
                class="" />
              {{ data.item.name }}
            </template>
            <template #cell(buildingId)="data">
              <a
                class="link"
                target="_blank"
                tabindex="0"
                @click="getRouteBuilding(data.item.id)"
                @click.middle="getRouteBuildingMiddle(data.item.id)">
                {{ data.item.numericId | displayOptionalValue }}
              </a>
            </template>
            <template
              #cell(address)="data">
              <address-text :address="data.item.address" />
            </template>
            <template #cell(owner)="data">
              {{ getOwnerName(data.item.ownerId) }}
            </template>
            <template
              #cell(roleInBuilding)="data">
              {{ getContactInBuilding(data.item) ? $t('message.contact.types.' + getContactInBuilding(data.item).type) : '-' }}
            </template>
            <template
              #cell(note)="data">
              {{ (getContactInBuilding(data.item) && getContactInBuilding(data.item).note !== '' ? getContactInBuilding(data.item).note : data.item.note) | displayOptionalValue }}
            </template>
          </b-table>
          <div class="text-center">
            <a
              v-if="countBuilding > 5"
              class="link"
              @click="showAllBuildings = !showAllBuildings">
              <coozzy-angle-double-up-icon v-if="showAllBuildings === true" />
              <coozzy-angle-double-down-icon v-else />
            </a>
          </div>
        </div>
      </coozzy-detail-card>
    </div>
  </section>
</template>

<script>
import { tables } from '@/mixins/tables'
import { routeChecks } from '@/mixins/routeChecks'
import ObjectApi from '@/misc/apis/ObjectApi'
import Vue from 'vue'
import { user } from '@/mixins/user'
import { formatting } from '@/mixins/formatting'
import AddressText from '@/framework/components/misc/AddressText'
import UserApi from '../../misc/apis/UserApi'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyAngleDoubleUpIcon from '@/framework/components/icons/CoozzyAngleDoubleUpIcon.vue'
import CoozzyAngleDoubleDownIcon from '@/framework/components/icons/CoozzyAngleDoubleDownIcon.vue'

export default {
  name: 'BuildingListContactCard',
  components: {
    CoozzyAngleDoubleDownIcon,
    CoozzyAngleDoubleUpIcon,
    CoozzySpinner,
    AddressText,
    CoozzyDetailCard
  },
  mixins: [tables, routeChecks, user, formatting],
  props: {
    availableContact: {
      type: Array,
      default() {
        return []
      }
    },
    contact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'buildingsName',
          label: this.$t('message.tableColumnHeadings.buildingsName'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'buildingId',
          label: this.$t('message.tableColumnHeadings.buildingId'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'owner',
          label: this.$t('message.tableColumnHeadings.owner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'roleInBuilding',
          label: this.$t('message.tableColumnHeadings.roleInBuilding'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'note',
          label: this.$t('message.tableColumnHeadings.note'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        }
      ],
      buildings: [],
      networkPartners: [],
      showAllBuildings: false,
      loadingBuildings: false,
      ownerAccounts: []
    }
  },
  computed: {
    cardTitle() {
      return this.$t('message.tableColumnHeadings.buildings')
    },
    countBuilding() {
      return this.buildings.length
    },
    buildingsList() {
      if (this.showAllBuildings) {
        return this.buildings
      } else {
        return this.buildings.slice(0, 5)
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (this.availableContact.length > 0) {
        this.loadingBuildings = true
        ObjectApi.listBuildingByContactIds(this.availableContact.map(contact => contact.id || contact.objectID).filter(x => x))
          .then(response => {
            this.buildings = response.buildings
            this.$emit('list-building', response.buildings)
            if (this.buildings.length > 0) {
              const promises = []
              this.buildings.forEach(element => {
                promises.push(UserApi.getAccount(element.ownerId))
              })
              Promise.all(promises).then(response => {
                response.forEach(element => {
                  this.ownerAccounts.push(element.account)
                })
              })
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.buildings'), { type: 'error' })
          })
          .finally(() => {
            this.loadingBuildings = false
           })
      }
    },
    getRouteBuilding(buildingId) {
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'Building'
          }
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'Building'
          }
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'Building'
          }
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'Building'
          }
        })
      } else if (this.isMarketingModule) {
        if (this.hasAccessAdminBase) {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'Building'
            }
          })
        } else if (this.hasAccessAccountingBase) {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'Building'
            }
          })
        }
      }
    },
    getRouteBuildingMiddle(buildingId) {
      let route = ''
      if (this.isOwnerModule) {
        route = this.$router.resolve({
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'building'
          }
        })
      } else if (this.isAdminModule) {
        route = this.$router.resolve({
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'building'
          }
        })
      } else if (this.isAccountingModule) {
        route = this.$router.resolve({
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'building'
          }
        })
      } else if (this.isAssetModule) {
        route = this.$router.resolve({
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'building'
          }
        })
      } else if (this.isMarketingModule) {
        if (this.hasAccessAdminBase) {
          route = this.$router.resolve({
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'building'
            }
          })
        } else if (this.hasAccessAccountingBase) {
          route = this.$router.resolve({
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'building'
            }
          })
        }
      }
      window.open(route.href)
    },
    onRowClicked(building, index, event) {
      if (!event.srcElement.classList.contains('currentContact')) {
        const buildingId = building.id
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'contacts'
            }
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'contacts'
            }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'contacts'
            }
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'contacts'
            }
          })
        } else if (this.isMarketingModule) {
          if (this.hasAccessAdminBase) {
            this.$router.push({
              name: 'AdminBuildingDetailsView',
              params: { id: buildingId },
              query: {
                view: 'contacts'
              }
            })
          } else if (this.hasAccessAccountingBase) {
            this.$router.push({
              name: 'AccountingBuildingDetailsView',
              params: { id: buildingId },
              query: {
                view: 'contacts'
              }
            })
          }
        }
      }
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return { name: 'OwnerContactDetailsView', params: { id: contactId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetContactDetailsView', params: { id: contactId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingContactDetailsView', params: { id: contactId } }
        } else {
          return { name: 'AdminContactDetailsView', params: { id: contactId } }
        }
      }
    },
    getContactInBuilding(building) {
      return building.contacts.find(contact => this.availableContact.map(x => x.id).includes(contact.id))
    },
    getOwnerName(ownerId) {
      if (ownerId !== this.ownerId) {
        const account = this.ownerAccounts.find(account => account.id === ownerId)

        if (!account) {
          return ''
        }

        if (account.company) {
          return account.company.name
        }
        if (account.individual) {
          return account.individual.firstName.substr(0, 1) + '. ' + account.individual.lastName
        }
      } else {
        return this.fullName
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-grey {
  color: $color-text-grey-light;
}
:deep(#tenantsTable) {
  overflow: unset !important;
}
:deep(.sticky-header) {
  background-color: #f8f8f8 !important;
}
:deep(.b-table-sticky-column) {
  background-image: none !important;
}
</style>
