// @ts-nocheck
import zipCodeData from '@/assets/zipcode/postalcodeformat.json'
import { helpers } from 'vuelidate/lib/validators'

const isZipValid = (param) =>
  helpers.withParams(
    { value: param },
    (value) => {
      const country = param.country
      if (value && value.length === 4 && country === 'CH') {
        return true
      } else if (value && value.length === 5 && country === 'DE') {
        return true
      } else if (value && value.length <= 15 && (country !== 'DE' && country !== 'CH')) {
        return true
      }
      return false
    }
  )

function noFutureDateObject(value) {
  let newValue = null
  const now = new Date()
  if (typeof value === 'string') {
    const date = new Date(value)
    newValue = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }
  } else {
    newValue = value
  }
  if (newValue && newValue.year <= now.getFullYear()) {
    return true
  } else if (newValue && newValue.month <= (now.getMonth() + 1)) {
    return true
  } else if (newValue && newValue.day < now.getDate()) {
    return true
  }

  return false
}
const validation = {
  data() {
    return {
      interval: null
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      localStorage.setItem('error-scroll-progress', 'false')
    }, 10000)
  },
  beforeDestroy() {
    // Stop polling for latest export run updates
    clearInterval(this.interval)
  },
  methods: {
    getAllInvalidFields() {
      const invalidFields = {}

      for (const [field, validation] of Object.entries(this.$v)) {
        if (typeof validation !== 'object' || validation === null) {
          continue
        }

        if (validation.$invalid) {
          invalidFields[field] = {}
          for (const [rule, valid] of Object.entries(validation)) {
            if (rule.startsWith('$')) continue
            if (!valid) {
              invalidFields[field][rule] = 'invalid'
            }
          }
        }
      }

      return invalidFields
    },
    overlap(dateRanges) {
      const sortedRanges = dateRanges.sort((previous, current) => {
        // get the start date from previous and current
        const previousTime = new Date(this.$options.filters.objectToDateInput(previous.start))
        const currentTime = new Date(this.$options.filters.objectToDateInput(current.start))
        // if the previous is earlier than the current
        if (previousTime < currentTime) {
          return -1
        }
        // if the previous time is the same as the current time
        if (previousTime === currentTime) {
          return 0
        }
        // if the previous time is later than the current time
        return 1
      })
      const result = sortedRanges.reduce((result, current, idx, arr) => {
        // get the previous range
        if (idx === 0) { return result }
        const previous = arr[idx - 1]

        // check for any overlap
        const previousEnd = previous.end ? new Date(this.$options.filters.objectToDateInput(previous.end)) : null
        const previousStart = new Date(this.$options.filters.objectToDateInput(previous.start))
        const currentStart = new Date(this.$options.filters.objectToDateInput(current.start))
        const currentEnd = current.end ? new Date(this.$options.filters.objectToDateInput(current.end)) : null// d
        let overlap = null
        if (previousEnd === null && currentEnd === null) {
          return result
        } else if (currentEnd === null) {
          overlap = (previousEnd >= currentStart)
        } else if (previousEnd === null) {
          overlap = (previousStart >= currentEnd)
        } else {
          overlap = (previousStart <= currentEnd && previousEnd >= currentStart)
        }
        // store the result
        if (overlap) {
          // yes, there is overlap
          result.overlap = true
          // store the specific ranges that overlap
          result.ranges.push({
            previous: previous,
            current: current
          })
        }
        return result
      }, { overlap: false, ranges: [] })

      return result
    },
    scrollToError() {
      this.$nextTick(() => {
        if (localStorage.getItem('error-scroll-progress') === 'false') {
          const elements = document.querySelectorAll('.is-invalid')
          const collapsedIds = []
          for (let i = 0; i < elements.length; i++) {
            const element = elements[i]
            if (element) {
              const domRect = element.getBoundingClientRect()
              if (i === 0) {
                window.scrollTo(
                  domRect.left + document.documentElement.scrollLeft,
                  domRect.top + document.documentElement.scrollTop - 200
                )
              }
            }
            const collapseParent = element.closest('.collapse')
            if (collapseParent) {
              if (collapseParent.className.indexOf('show') === -1 && !collapsedIds.includes(collapseParent.id)) {
                collapsedIds.push(collapseParent.id)
                this.$root.$emit('bv::toggle::collapse', collapseParent.id)
              }
              const collapseParent2 = collapseParent.parentNode.closest('.collapse')
              if (collapseParent2) {
                if (collapseParent2.className.indexOf('show') === -1 && !collapsedIds.includes(collapseParent2.id)) {
                  collapsedIds.push(collapseParent2.id)
                  this.$root.$emit('bv::toggle::collapse', collapseParent2.id)
                }
                const collapseParent3 = collapseParent2.parentNode.closest('.collapse')
                if (collapseParent3) {
                  if (collapseParent3.className.indexOf('show') === -1 && !collapsedIds.includes(collapseParent3.id)) {
                    collapsedIds.push(collapseParent3.id)
                    this.$root.$emit('bv::toggle::collapse', collapseParent3.id)
                  }
                  const collapseParent4 = collapseParent3.parentNode.closest('.collapse')
                  if (collapseParent4) {
                    if (collapseParent4.className.indexOf('show') === -1 && !collapsedIds.includes(collapseParent4.id)) {
                      collapsedIds.push(collapseParent4.id)
                      this.$root.$emit('bv::toggle::collapse', collapseParent4.id)
                    }
                  }
                }
              }
            }
          }
          localStorage.setItem('error-scroll-progress', 'true')
        }
      })
    },
    validDatesViaTraverseChildren(parent) {
      let valid = true
      parent.$children.forEach((child) => {
        if (child.$options.name === 'CoozzyFormInput' && child.type === 'date') {
          if (child.isInvalid()) {
            valid = false
          }
        }
        if (child.$children.length > 0) {
          if (this.validDatesViaTraverseChildren(child)) {
            valid = false
          }
        }
      })
      return !valid
    }
  }
}
export { validation, noFutureDateObject, isZipValid, zipCodeData }
