<template>
  <div
    class="row mt-2 align-items-center">
    <!--      Insurance       -->
    <div class="col-6 col-md-2">
      <coozzy-form-checkbox
        v-model="enabled"
        :initial="enabled"
        name="insuranceExpiryEnabled">
        <template v-if="category !== ''">
          {{ $t('message.settings.ticketing.' + category) }}
        </template>
      </coozzy-form-checkbox>
    </div>
    <div class="col-6 col-md-4">
      <div class="d-flex align-items-center">
        <span>{{ $t('message.settings.ticketing.createdAt') }}</span>
        <span>
          <coozzy-form-input-numeric
            v-model="createAtAmount"
            type="number"
            min="0"
            :is-decimal="true"
            class="inputAmount"
            :check-valide="enabled && $v.createAtAmount.$dirty && $v.createAtAmount.$error ? 'is-invalid' : ''" />
        </span>
        <span>
          <coozzy-form-select
            v-model="createAtUnit"
            class="mr-1 ml-1"
            :state="enabled && $v.createAtUnit.$dirty && $v.createAtUnit.$error ? false : null">
            <option value="SCHEDULE_UNIT_UNDEFINED">
              -
            </option>
            <option value="SCHEDULE_UNIT_DAY">
              {{ $t(`message.navigation.calendar.day_s`) }}
            </option>
            <option value="SCHEDULE_UNIT_MONTH">
              {{ $t(`message.navigation.calendar.month`) }}
            </option>
            <option value="SCHEDULE_UNIT_YEAR">
              {{ $t(`message.navigation.calendar.year`) }}
            </option>
          </coozzy-form-select>
        </span>
        <span
          v-if="category === 'device_inspection_next'"
          class="ml-2">{{ $t('message.settings.ticketing.beforeInspection') }} {{ enabled ? '*' : '' }}</span>
        <span
          v-else-if="category === 'device_maintenance_next'"
          class="ml-2">{{ $t('message.settings.ticketing.beforeMaintenance') }} {{ enabled ? '*' : '' }}</span>
        <span
          v-else-if="category === 'device_revision_next'"
          class="ml-2">{{ $t('message.settings.ticketing.beforeRevision') }} {{ enabled ? '*' : '' }}</span>
        <span
          v-else
          class="ml-2">{{ $t('message.settings.ticketing.createdBefore') }} {{ enabled ? '*' : '' }}</span>
      </div>
    </div>
    <div class="col-6 col-md-4">
      <div class="d-flex align-items-center">
        <span>{{ $t('message.settings.ticketing.dueAt') }}</span>
        <span>
          <coozzy-form-input-numeric
            v-model="dueDateAmount"
            type="number"
            min="0"
            :is-decimal="true"
            class="inputAmount"
            :check-valide="enabled && $v.dueDateAmount.$dirty && $v.dueDateAmount.$error ? 'is-invalid' : ''" />
        </span>
        <span>
          <coozzy-form-select
            v-model="dueDateUnit"
            class="mr-1 ml-1"
            :state="enabled && $v.dueDateUnit.$dirty && $v.dueDateUnit.$error ? false : null">
            <option value="SCHEDULE_UNIT_UNDEFINED">
              -
            </option>
            <option value="SCHEDULE_UNIT_DAY">
              {{ $t(`message.navigation.calendar.day_s`) }}
            </option>
            <option value="SCHEDULE_UNIT_MONTH">
              {{ $t(`message.navigation.calendar.month`) }}
            </option>
            <option value="SCHEDULE_UNIT_YEAR">
              {{ $t(`message.navigation.calendar.year`) }}
            </option>
          </coozzy-form-select>
        </span>
        <span
          v-if="category === 'device_inspection_next'"
          class="ml-2">{{ $t('message.settings.ticketing.beforeInspectionDue') }} {{ enabled ? '*' : '' }}</span>
        <span
          v-else-if="category === 'device_maintenance_next'"
          class="ml-2">{{ $t('message.settings.ticketing.beforeMaintenanceDue') }} {{ enabled ? '*' : '' }}</span>
        <span
          v-else-if="category === 'device_revision_next'"
          class="ml-2">{{ $t('message.settings.ticketing.beforeRevisionDue') }} {{ enabled ? '*' : '' }}</span>
        <span
          v-else
          class="ml-2">{{ $t('message.settings.ticketing.dueBefore') }} {{ enabled ? '*' : '' }}</span>
      </div>
    </div>
    <div
      class="col-6 col-md-2">
      <label>{{ $t('message.ticketOverview.ticket.assignee') }}</label>
      <v-select
        v-model="assignee"
        :filter="searchAssignee"
        :options="listFilteredEmployee"
        class="assignee">
        <template
          v-if="option.value || option.label"
          slot="option"
          slot-scope="option">
          <img
            :alt="option.label"
            :src="option.value && option.value?.profile?.pictureUrl !== '' ? option.value?.profile?.pictureUrl : '/avatar.png'"
            class="align-self-center avatar"
            height="15px">
          {{ option.label === 'unassigned' ? $t('message.generic.unassigned') : option.label === 'assigneeObject' ? $t('message.generic.assigneeObject') : option.label }}
        </template>
        <template
          v-if="option.value || option.label"
          slot="selected-option"
          slot-scope="option">
          <div>
            <img
              :alt="option.label"
              :src="option.value && option.value?.profile?.pictureUrl !== '' ? option.value?.profile?.pictureUrl : '/avatar.png'"
              class="align-self-center avatar"
              height="15">
            {{ option.label === 'unassigned' ? $t('message.generic.unassigned') : option.label === 'assigneeObject' ? $t('message.generic.assigneeObject') : option.label }}
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import { minValue, required } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'

const notUndefinedUnit = value => value !== 'SCHEDULE_UNIT_UNDEFINED'

export default {
  name: 'InformationTicketItem',
  components: {
    CoozzyFormSelect,
    CoozzyFormInputNumeric,
    CoozzyFormCheckbox
  },
  mixins: [validation],
  props: {
    schedule: {
      type: Object,
      required: true
    },
    listEmployee: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    }
  },
  computed: {
    category() {
      return this.schedule.category || ''
    },
    enabled: {
      get() {
        return this.schedule.enabled
      },
      set(value) {
        const newSchedule = this.schedule
        newSchedule.enabled = value
        this.$emit('schedule-updated', newSchedule)
      }
    },
    createAtAmount: {
      get() {
        return this.schedule.createAtAmount
      },
      set(value) {
        const newSchedule = this.schedule
        newSchedule.createAtAmount = value
        this.$emit('schedule-updated', newSchedule)
      }
    },
    createAtUnit: {
      get() {
        return this.schedule.createAtUnit
      },
      set(value) {
        const newSchedule = this.schedule
        newSchedule.createAtUnit = value
        this.$emit('schedule-updated', newSchedule)
      }
    },
    dueDateAmount: {
      get() {
        return this.schedule.dueDateAmount
      },
      set(value) {
        const newSchedule = this.schedule
        newSchedule.dueDateAmount = value
        this.$emit('schedule-updated', newSchedule)
      }
    },
    dueDateUnit: {
      get() {
        return this.schedule.dueDateUnit
      },
      set(value) {
        const newSchedule = this.schedule
        newSchedule.dueDateUnit = value
        this.$emit('schedule-updated', newSchedule)
      }
    },
    assignee: {
      get() {
        return this.listFilteredEmployee.find(item => this.schedule.assigneeUserId ? item.value?.id === this.schedule.assigneeUserId || item.label === this.schedule.assigneeUserId : this.schedule.useObjectAssignee ? item.label === 'assigneeObject' : item.label === 'unassigned') || ''
      },
      set(value) {
        const newSchedule = this.schedule
        if (value?.label === 'unassigned') {
          newSchedule.language = 'PREFERRED_LANGUAGE_UNDEFINED'
        } else {
          if (value?.value?.profile?.language) {
            if (value?.value?.profile?.language === 'de') {
              newSchedule.language = 'PREFERRED_LANGUAGE_GERMAN'
            } else if (value?.value?.profile?.language === 'en') {
              newSchedule.language = 'PREFERRED_LANGUAGE_ENGLISH'
            } else if (value?.value?.profile?.language === 'it') {
              newSchedule.language = 'PREFERRED_LANGUAGE_ITALIAN'
            } else {
              newSchedule.language = 'PREFERRED_LANGUAGE_UNDEFINED'
            }
          } else {
            newSchedule.language = 'PREFERRED_LANGUAGE_UNDEFINED'
          }
        }
        newSchedule.assigneeUserId = value?.value?.id ? value?.value?.id : value?.label === 'unassigned' ? 'unassigned' : 'assigneeObject'
        this.$emit('schedule-updated', newSchedule)
      }
    },
    listFilteredEmployee() {
      const list = JSON.parse(JSON.stringify(this.listEmployee))
      if (!list.find(e => e.value === 'unassigned')) {
        const unassigned = {
          label: 'unassigned',
          value: null,
          accountId: '',
          active: true,
          company: '',
          profile: null
        }
        list.unshift(unassigned)
      }
      if (!list.find(e => e.value === 'assigneeObject')) {
        const unassigned = {
          label: 'assigneeObject',
          value: null,
          accountId: '',
          active: true,
          company: '',
          profile: null
        }
        list.unshift(unassigned)
      }
      return list
    }
  },
  methods: {
    searchAssignee(options, search) {
      return search.length
        ? options.filter(item => item.label.toLowerCase().includes(search.toLowerCase()) || item.company.toLowerCase().includes(search.toLowerCase()))
        : options
    },
    isInvalid() {
      this.$v.$touch()
      return this.$v.$invalid
    }
  },
  validations() {
    if (this.enabled) {
      return {
        createAtAmount: {
          required,
          minValue: minValue(1)
        },
        createAtUnit: {
          required,
          validOption: notUndefinedUnit
        },
        dueDateAmount: {
          required,
          minValue: minValue(1)
        },
        dueDateUnit: {
          required,
          validOption: notUndefinedUnit
        }
      }
    }
    return {}
  }
}
</script>

<style lang="scss" scoped>
:deep(.collapse-title) {
  &.collapsed {
    h5 {
      background-image: url('../../assets/arrow_down.svg');
    }
  }

  h5 {
    background-image: url('../../assets/arrow_up.svg');
    background-repeat: no-repeat;
    font-size: large;
    font-weight: 500 !important;
    height: 1.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    background-position: right;
  }
}

.inputAmount :deep(.form-control) {
  max-width: 70px;
  text-align: center;
  padding: 0.2em;
  margin: 0 0.25rem;
}
:deep .is-invalid .vs__dropdown-toggle {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("../../assets/icon/is-invalid.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.assignee :deep(.vs__clear) {
  box-shadow: none;
}
</style>
