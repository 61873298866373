<template>
  <section>
    <div
      v-b-toggle.collapse-baseData
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ $t('message.onBoarding.createBuilding.baseData') }}
      </h5>
    </div>
    <div class="col-12 pl-0">
      <b-collapse
        id="collapse-baseData"
        :ref="'collapse'"
        :visible="expandByDefault">
        <div class="row">
          <div class="col-sm-12 col-md-9">
            <div class="row details-row-bg-2 mt-3 py-2">
              <div class="col-sm-12 col-md-4">
                <label for="buildingStrategy">{{ $t('message.onBoarding.buildings.buildingStrategy.type') }}</label>
                <coozzy-form-select
                  v-if="!readOnly"
                  id="buildingStrategy"
                  v-model="buildingStrategy"
                  :disabled="isJanitor">
                  <option
                    value="BUILDING_STRATEGY_TYPE_UNDEFINED">
                    -
                  </option>
                  <option
                    v-for="value in valueBuildingStrategy"
                    :key="value"
                    :value="value">
                    {{ $t('message.onBoarding.buildings.buildingStrategy.' + value) }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else-if="!valueBuildingStrategy || buildingStrategy === 'BUILDING_STRATEGY_TYPE_UNDEFINED'"
                  class="show-data">{{ '-' }}</span>
                <span
                  v-else
                  class="show-data">
                  {{ $t('message.onBoarding.buildings.buildingStrategy.' + buildingStrategy) }}
                </span>
              </div>
              <div class="col-sm-12 col-md-4">
                <coozzy-form-input
                  v-model="dates.buildingStrategyReviewAt"
                  :disabled="isJanitor"
                  :filter="'formatDate'"
                  :is-read-only="readOnly"
                  :name="$t('message.onBoarding.buildings.buildingStrategy.reviewAt')"
                  type="date"
                  @blur="dateToObject()" />
              </div>
            </div>
            <div class="row details-row-bg-1 py-01rem">
              <div class="col-sm-12 col-md-4">
                <label for="microLocationRatingProposal">
                  {{ $t('message.onBoarding.buildings.microLocationRatingProposal') }}
                  <coozzy-info-circle-icon
                    v-b-tooltip.hover.html="$t('message.onBoarding.buildings.microLocationRatingProposalToolTip')" />
                </label>
                <coozzy-form-select
                  v-if="!readOnly"
                  id="microLocationRatingProposal"
                  v-model="microLocationRatingProposal"
                  :disabled="true"
                  class="background-image-none">
                  <option
                    value="-1">
                    -
                  </option>
                  <option
                    v-for="value in valueMicroLocationRating"
                    :key="value"
                    :value="value">
                    {{ value % 1 ? value.toString() : value.toString() + '.0' }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else-if="!microLocationRatingProposal || microLocationRatingProposal === -1"
                  class="show-data">{{ '-' }}</span>
                <span
                  v-else
                  class="show-data">{{
                    microLocationRatingProposal % 1 ? microLocationRatingProposal.toString() : microLocationRatingProposal.toString() + '.0'
                  }}</span>
              </div>
              <div class="col-sm-12 col-md-4">
                <label for="microLocationRating">{{ $t('message.onBoarding.buildings.microLocationRating') }}</label>
                <coozzy-form-select
                  v-if="!readOnly"
                  id="microLocationRating"
                  v-model="microLocationRating"
                  :disabled="isJanitor">
                  <option
                    value="-1">
                    -
                  </option>
                  <option
                    v-for="value in valueMicroLocationRating"
                    :key="value"
                    :value="value">
                    {{ value % 1 ? value.toString() : value.toString() + '.0' }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else-if="!microLocationRating || microLocationRating === -1"
                  class="show-data">{{ '-' }}</span>
                <span
                  v-else
                  class="show-data">{{
                    microLocationRating % 1 ? microLocationRating.toString() : microLocationRating.toString() + '.0'
                  }}</span>
              </div>
            </div>
            <div class="row details-row-bg-2 py-01rem">
              <div class="col-sm-12 col-md-4">
                <coozzy-form-input
                  v-model="name"
                  :disabled="isJanitor"
                  :is-read-only="readOnly"
                  :name="$t('message.onBoarding.buildings.name') + (readOnly ? '' : '*')"
                  :state="$v.name.$dirty && $v.name.$error ? false : null" />
              </div>
              <div class="col-sm-12 col-md-2">
                <coozzy-form-input-numeric
                  v-model="numericId"
                  :disabled="true"
                  :is-integer="true"
                  :is-integer-format="true"
                  :is-read-only="readOnly"
                  :name="$t('message.generic.buildingID.id')" />
              </div>
              <div class="col-sm-12 col-md-2">
                <coozzy-form-input
                  v-model="egid"
                  :disabled="isJanitor"
                  :is-read-only="readOnly"
                  :name="$t('message.onBoarding.buildings.egid')"
                  :state="$v.egid.$dirty && $v.egid.$error ? false : null"
                  type="number" />
              </div>
              <div
                class="col-sm-12 col-md-4">
                <label for="constructionYear">{{ $t('message.onBoarding.buildings.constructionYear') }}</label>
                <coozzy-form-select
                  v-if="!readOnly"
                  id="constructionYear"
                  v-model="constructionYear"
                  :disabled="isJanitor"
                  :state="$v.constructionYear.$dirty && $v.constructionYear.$error ? false : null">
                  <option value="-1" />
                  <option
                    v-for="year in years2"
                    :key="year"
                    :value="year">
                    {{ year }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else
                  class="show-data">{{ constructionYear === null ? '-' : constructionYear }}</span>
              </div>
            </div>
            <div class="row details-row-bg-1 py-01rem">
              <div class="col-sm-12 col-md-4">
                <coozzy-form-country-select
                  v-model="building.address.country"
                  :disabled="true"
                  :initial-value="building.address.country"
                  :is-read-only="readOnly" />
              </div>
              <div class="col-sm-12 col-md-4">
                <coozzy-form-region-select
                  v-model="building.address.state"
                  :country="building.address.country"
                  :disabled="true"
                  :initial-value="building.address.state"
                  :is-read-only="readOnly" />
              </div>
              <div class="col-sm-12 col-md-4">
                <coozzy-form-input
                  :disabled="true"
                  :is-read-only="readOnly"
                  :name="$t('message.generic.zip') + (readOnly ? '' : '*')"
                  :value="building.address.zip === '' || building.address.zip === null ? '-' :building.address.zip " />
              </div>
            </div>
            <div class="row details-row-bg-2 py-01rem">
              <div class="col-sm-12 col-md-4">
                <coozzy-form-input
                  v-model="building.address.city"
                  :disabled="true"
                  :is-read-only="readOnly"
                  :name="$t('message.generic.city')" />
              </div>
              <div class="col-sm-12 col-md-4">
                <coozzy-form-input
                  v-model="building.address.street"
                  :disabled="isJanitor"
                  :is-read-only="readOnly"
                  :name="$t('message.generic.street') + (readOnly ? '' : '*')"
                  :state="$v.building.address.street.$dirty && $v.building.address.street.$error ? false : null" />
              </div>
              <div
                :class="propertyType !== 'PROPERTY_TYPE_RENTAL' ? 'col-md-2' : 'col-md-4'"
                class="col-sm-12">
                <coozzy-form-input
                  v-model="building.address.streetNumber"
                  :disabled="isJanitor"
                  :is-read-only="readOnly"
                  :name="$t('message.generic.streetNumber')"
                  type="text" />
              </div>
              <div
                v-if="propertyType !== 'PROPERTY_TYPE_RENTAL'"
                class="col-sm-12 col-md-2">
                <coozzy-form-input
                  v-model="quotaReadOnly"
                  :disabled="true"
                  :is-read-only="readOnly"
                  :name="$t('message.tableColumnHeadings.valueQuotaBuilding')"
                  type="text" />
              </div>
            </div>
            <div class="row details-row-bg-1 py-01rem">
              <div class="col-sm-12 col-md-4">
                <label for="currency">{{ $t('message.onBoarding.buildings.floors') }}</label>
                <coozzy-form-select
                  v-if="!readOnly"
                  id="currency"
                  v-model="floors"
                  :disabled="isJanitor">
                  <option value="0" />
                  <option
                    v-for="index in valueNumberOfFloors"
                    :key="index"
                    :value="index">
                    {{ index === 21 ? '+20' : index }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else
                  class="show-data">{{
                    floors === '' || floors === null ? '-' : (floors === 20.5 ? '+20' : floors)
                  }}</span>
              </div>
              <div class="col-sm-12 col-md-4">
                <coozzy-form-input-numeric
                  v-model="buildingArea"
                  :check-valide="$v.buildingArea.$dirty && $v.buildingArea.$error ? 'is-invalid' : ''"
                  :disabled="isJanitor"
                  :filter="'formatArea'"
                  :is-decimal="true"
                  :is-read-only="readOnly"
                  :name="$t('message.manageBuilding.buildingArea')"
                  placeholder="m²"
                  type="number" />
              </div>
              <div class="col-sm-12 col-md-4">
                <coozzy-form-input-numeric
                  v-model="volume"
                  :check-valide="$v.volume.$dirty && $v.volume.$error ? 'is-invalid' : ''"
                  :disabled="isJanitor"
                  :filter="'formatVolume'"
                  :is-decimal="true"
                  :is-read-only="readOnly"
                  :name="$t('message.onBoarding.buildings.volume')"
                  type="number" />
              </div>
            </div>
            <div class="row details-row-bg-2 py-01rem">
              <div
                v-if="readOnly"
                class="col-sm-12 col-md-4">
                <label>{{ $t('message.onBoarding.buildings.landRegisterNo') }}</label>
                <p
                  v-if="plotIds.length > 0"
                  class="mb-0 multi-select-value">
                  <span
                    v-for="(plot, index) in plotIds"
                    :key="plot">
                    <template v-if="getPlotsNumber(plot)">
                      <span v-if="index > 0">, </span>
                      <span v-if="isMarketingModule">{{ getPlotsNumber(plot) }}</span>
                      <a
                        v-else
                        tabindex="0"
                        @click="showDetailLandRegister(plot)">
                        {{ getPlotsNumber(plot) }}
                      </a>
                    </template>
                  </span>
                </p>
                <p
                  v-else
                  class="mb-0 multi-select-value">
                  -
                </p>
              </div>
              <div
                v-else
                class="col-sm-12 col-md-4">
                <coozzy-multiselect
                  id="plotIds"
                  v-model="landRegisterNumberDummy"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :disabled="isJanitor"
                  :multiple="true"
                  :options="landRegisterList"
                  :preselect-first="false"
                  :preserve-search="true"
                  :taggable="false"
                  label="label"
                  track-by="label">
                  {{ $t('message.onBoarding.buildings.landRegisterNo') }}
                </coozzy-multiselect>
              </div>
              <div class="col-sm-12 col-md-4">
                <label
                  v-if="readOnly">{{ $t('message.manageBuilding.plotArea') }}</label>
                <p
                  v-if="readOnly"
                  class="mb-0 multi-select-value">
                  {{ plotAreaValue | formatArea }}
                </p>
                <coozzy-form-input
                  v-else
                  v-model="plotArea"
                  :disabled="true"
                  :filter="'formatArea'"
                  :is-read-only="readOnly"
                  :name="$t('message.manageBuilding.plotArea')" />
              </div>
              <div class="col-sm-12 col-md-4">
                <label
                  v-if="readOnly">{{ $t('message.onBoarding.buildings.buildingZone') }}</label>
                <p
                  v-if="readOnly"
                  class="mb-0 multi-select-value">
                  {{ buildingZoneValue }}
                </p>
                <coozzy-form-input
                  v-else
                  v-model="buildingZone"
                  :disabled="true"
                  :is-read-only="readOnly"
                  :name="$t('message.onBoarding.buildings.buildingZone')" />
              </div>
            </div>
            <div class="row details-row-bg-1 py-01rem">
              <div
                v-if="readOnly"
                class="col-sm-12 col-md-4">
                <label>{{ $t('message.onBoarding.buildings.constructionTypes.title') }}</label>
                <p
                  v-if="getConstructionTypes.length > 0"
                  class="mb-0 multi-select-value">
                  {{ getConstructionTypes }}
                </p>
                <p
                  v-else
                  class="mb-0 multi-select-value">
                  -
                </p>
              </div>
              <div
                v-else
                class="col-sm-12 col-md-4">
                <coozzy-multiselect
                  id="constructionTypes"
                  v-model="constructionTypes"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :disabled="isJanitor"
                  :multiple="true"
                  :options="translatedConstructionTypes"
                  :preselect-first="false"
                  :preserve-search="true"
                  :taggable="false"
                  label="label"
                  track-by="label">
                  {{ $t('message.onBoarding.buildings.constructionTypes.title') }}
                </coozzy-multiselect>
              </div>
              <div
                class="col-sm-12 col-md-4">
                <label for="yearLastRenovation">{{ $t('message.onBoarding.buildings.renovationYear') }}</label>
                <coozzy-form-select
                  v-if="!readOnly"
                  id="yearLastRenovation"
                  v-model="renovationYear"
                  :disabled="isJanitor"
                  :state="$v.renovationYear.$dirty && $v.renovationYear.$error ? false : null">
                  <option value="-1" />
                  <option
                    v-for="year in years"
                    :key="year"
                    :value="year">
                    {{ year }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else
                  class="show-data">{{ renovationYear === null ? '-' : renovationYear }}</span>
              </div>
              <div class="col-sm-12 col-md-4">
                <label for="contractAmendments">{{ $t('message.onBoarding.buildings.contractAmendments') }}</label>
                <coozzy-form-textarea
                  id="contractAmendments"
                  ref="contractAmendments"
                  v-model="contractAmendments"
                  :disabled="isJanitor"
                  :initial="contractAmendments"
                  :is-read-only="readOnly"
                  :show-label="false"
                  :value-text-area="contractAmendments"
                  name="contractAmendments" />
              </div>
              <div class="col-sm-12 col-md-4">
                <label for="note">{{ $t('message.generic.note') }}</label>
                <coozzy-form-textarea
                  id="note"
                  ref="note"
                  v-model="note"
                  :disabled="isJanitor"
                  :initial="note"
                  :is-read-only="readOnly"
                  :show-label="false"
                  :value-text-area="note"
                  name="note" />
              </div>
            </div>
          </div>
          <div class="d-flex col-sm-12 col-md-3 mt-3 mt-md-0 justify-content-center">
            <coozzy-spinner
              v-if="mediaUploadProcessing"
              class="align-self-center" />
            <div
              v-if="!mediaUploadProcessing && buildingImageMedia"
              :class="[readOnly ? 'cursor-pointer' : '']"
              :draggable="true"
              :style="{ 'background-image': 'url(' + buildingMediaUrl + ')' }"
              class="uploaded-file my-2 w-100"
              style="background-repeat: no-repeat;background-size: cover;background-position: center center;"
              @click="showImage"
              @dragstart="handleDragStart(buildingImageMedia.media.id)">
              <div
                :class="['object-image-remove-container', 'cursor-pointer', readOnly || isJanitor ? 'd-none' : '']"
                @click="deleteMedia()">
                <coozzy-close-icon class="object-image-remove bg" />
                <coozzy-close-icon class="object-image-remove" />
              </div>
            </div>
            <img
              v-if="!mediaUploadProcessing && !buildingImageMedia"
              :class="['align-self-center', 'building-image', readOnly || isJanitor ? '' : 'cursor-pointer']"
              alt="Placeholder"
              src="/img/image-placeholder.png"
              @click="uploadImageClicked">

            <coozzy-form-file-input
              v-if="!readOnly && !isJanitor"
              ref="mediaUpload"
              accept="image/png,image/jpeg,image/jpg"
              class="d-none"
              @change="addFile" />
          </div>
        </div>
      </b-collapse>
    </div>
  </section>
</template>

<script>

import { onboarding } from '@/mixins/onboarding'
import MediaApi from '@/misc/apis/MediaApi'
import Vue from 'vue'
import { maxLength, minLength, minValue, numeric, required } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzyCloseIcon from '@/framework/components/icons/CoozzyCloseIcon'
import { routeChecks } from '@/mixins/routeChecks'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import { media } from '@/mixins/media'
import { user } from '@/mixins/user'
import { formatting } from '@/mixins/formatting'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import CoozzyFormCountrySelect from '@/framework/components/form/select/CoozzyFormCountrySelect.vue'
import CoozzyFormRegionSelect from '@/framework/components/form/select/CoozzyFormRegionSelect.vue'

export default {
  name: 'BuildingBasic',
  components: {
    CoozzyFormRegionSelect,
    CoozzyFormCountrySelect,
    CoozzyInfoCircleIcon,
    CoozzyFormTextarea,
    CoozzyCloseIcon,
    CoozzyFormFileInput,
    CoozzySpinner,
    CoozzyMultiselect,
    CoozzyFormInputNumeric,
    CoozzyFormSelect,
    CoozzyFormInput
  },
  mixins: [onboarding, validation, routeChecks, dateUtils, media, user, formatting],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    expandByDefault: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mediaUpload: null,
      landRegisterNumberDummy: [],
      buildingZone: '',
      plotArea: '',
      galleryImages: [],
      mediaUploadProcessing: false,
      availableConstructionTypes: ['OLD_BUILDING', 'NEW_BUILDING', 'HIGH_RISE_BUILDING', 'MIDDLE_TERRACED_HOUSE', 'RAISED_GROUND_FLOOR', 'CORNER_BUILDING', 'MINERGIE_CONSTRUCTION', 'MINERGIE_CERTIFIED', 'LISTED_BUILDING'],
      dates: {
        buildingStrategyReviewAt: null
      }
    }
  },
  computed: {
    plotAreaValue() {
      return this.plotArea
    },
    isMarketingPath() {
      return this.$route.name.startsWith('Marketing')
    },
    buildingZoneValue() {
      return this.buildingZone
    },
    numericId() {
      return typeof this.building.numericId === 'string' ? parseInt(this.building.numericId) : this.building.numericId
    },
    landRegisterList() {
      const array = []
      this.plots.forEach(plot => {
        array.push({
          label: plot.landRegisterNumber,
          value: plot
        })
      })
      return [...new Set(array)]
    },
    years() {
      const year = new Date().getFullYear()
      return Array.from({ length: year - 1800 }, (value, index) => 1801 + index)
    },
    years2() {
      const year = new Date().getFullYear()
      return Array.from({ length: year - 999 }, (value, index) => 1000 + index)
    },
    valueNumberOfFloors() {
      const array = []
      for (let index = 1; index < 22; index += 1) {
        array.push(index)
      }
      return array
    },
    valueMicroLocationRating() {
      const array = []
      for (let value = 1; value < 5.5; value += 0.5) {
        array.push(value)
      }
      return array
    },
    valueBuildingStrategy() {
      return [
        'BUILDING_STRATEGY_TYPE_NO_RENOVATION',
        'BUILDING_STRATEGY_TYPE_MAINTAIN',
        'BUILDING_STRATEGY_TYPE_REPAIR',
        'BUILDING_STRATEGY_TYPE_REPLACEMENT',
        'BUILDING_STRATEGY_TYPE_SALE'
      ]
    },
    name: {
      get() {
        return this.building.name
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField', {
          field: 'name',
          value: value
        })
      }
    },
    egid: {
      get() {
        return this.building.egid
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField', {
          field: 'egid',
          value: value
        })
      }
    },
    constructionYear: {
      get() {
        return this.building.constructionYear !== -1 ? this.building.constructionYear : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          {
            field: 'constructionYear',
            value: value,
            dataType: 'number'
          })
      }
    },
    renovationYear: {
      get() {
        return this.building.renovationYear !== -1 ? this.building.renovationYear : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          {
            field: 'renovationYear',
            value: value,
            dataType: 'number'
          })
      }
    },
    buildingArea: {
      get() {
        return this.building.buildingArea !== -1 ? this.building.buildingArea : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          {
            field: 'buildingArea',
            value: value,
            dataType: 'number'
          })
      }
    },
    floors: {
      get() {
        return this.building.floors !== -1 ? this.building.floors : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          {
            field: 'floors',
            value: value,
            dataType: 'number'
          })
      }
    },
    volume: {
      get() {
        return this.building.volume !== -1 ? this.building.volume : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          {
            field: 'volume',
            value: value,
            dataType: 'number'
          })
      }
    },
    constructionTypes: {
      get() {
        return this.building.constructionTypes.filter(con => con !== 'UNDEFINED_CONSTRUCTION_TYPE').map(conTyp => {
          return {
            label: this.$t('message.onBoarding.buildings.constructionTypes.' + conTyp),
            value: conTyp
          }
        })
      },
      set(value) {
        const conTypValues = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateBuildingField', {
          field: 'constructionTypes',
          value: conTypValues
        })
      }
    },
    contractAmendments: {
      get() {
        return this.building.contractAmendments
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField', {
          field: 'contractAmendments',
          value: value
        })
      }
    },
    note: {
      get() {
        return this.building.note
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField', {
          field: 'note',
          value: value
        })
      }
    },
    acquisitionDate: {
      get() {
        if (this.building.acquisitionDate !== null) {
          return this.building.acquisitionDate.year + '-' + ('0' + this.building.acquisitionDate.month).slice(-2) + '-' + ('0' + this.building.acquisitionDate.day).slice(-2)
        }
        return null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField', {
          field: 'acquisitionDate',
          value: value
        })
      }
    },
    plotIds: {
      get() {
        return this.building.plotIds
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField', {
          field: 'plotIds',
          value: value
        })
      }
    },
    quotaReadOnly: {
      get() {
        return this.building.valueQuotaWithTotal ? this.formatValueQuota(this.building.valueQuotaWithTotal) + '/' + this.formatValueQuota(this.totalPropertyValueQuota) : null
      }
    },
    buildingStrategy: {
      get() {
        return this.building.buildingStrategy?.type ? this.building.buildingStrategy.type : 'BUILDING_STRATEGY_TYPE_UNDEFINED'
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingStrategy', {
          field: 'type',
          value: value
        })
        if (this.dates.buildingStrategyReviewAt === '' || this.dates.buildingStrategyReviewAt === null) {
          const currentDate = new Date()
          currentDate.setFullYear(currentDate.getFullYear() + 5)
          this.dates.buildingStrategyReviewAt = this.$options.filters.timestampToDateInput(currentDate.getTime() / 1000)
          this.dateToObject()
        }
        if (value === 'BUILDING_STRATEGY_TYPE_UNDEFINED') {
          this.buildingStrategyReviewAt = ''
        }
      }
    },
    buildingStrategyReviewAt: {
      get() {
        return this.building.buildingStrategy?.reviewAt
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingStrategy', {
          field: 'reviewAt',
          value: value
        })
      }
    },
    microLocationRatingProposal: {
      get() {
        return this.building.rating?.microLocationRatingProposal ? this.building.rating.microLocationRatingProposal : -1
      }
    },
    microLocationRating: {
      get() {
        return this.building.rating?.microLocationRating ? this.building.rating.microLocationRating : -1
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingRating', {
          field: 'microLocationRating',
          value: value
        })
      }
    },
    translatedConstructionTypes() {
      const array = []
      this.availableConstructionTypes.forEach(conTyp => {
        array.push({
          label: this.$t('message.onBoarding.buildings.constructionTypes.' + conTyp),
          value: conTyp
        })
      })
      return array
    },
    getConstructionTypes() {
      const array = []
      this.building.constructionTypes.forEach(conTyp => {
        array.push(this.$t('message.onBoarding.buildings.constructionTypes.' + conTyp))
      })
      return array.join(', ')
    },
    buildingMediaUrl() {
      for (const thumbnail of this.buildingImageMedia.media.thumbnails) {
        if (thumbnail.type === 1) {
          return thumbnail.url
        }
      }

      return this.buildingImageMedia.media.url
    },
    propertyType() {
      return this.properties.length > 0 ? this.properties[0].type : ''
    },
    totalPropertyValueQuota() {
      const listBuildings = this.$store.getters['onboarding/getPropertyBuildings']
      return listBuildings.reduce((accumulator, object) => {
        return accumulator + object.valueQuota
      }, 0)
    }
  },
  watch: {
    'building.address.street': function (newValue) {
      if (newValue !== null) {
        this.$store.dispatch('onboarding/updateBuildingField',
          {
            field: 'address',
            value: this.building.address
          })
      }
    },
    'building.address.streetNumber': function (newValue) {
      if (newValue !== null) {
        this.$store.dispatch('onboarding/updateBuildingField',
          {
            field: 'address',
            value: this.building.address
          })
      }
    },
    landRegisterNumberDummy: function (newValue) {
      this.plotIds = newValue.map(x => x.value.id)
      let plotArea = 0
      let constructionZone = ''
      newValue.forEach(element => {
        if (element && element.value) {
          if (element.value.plotArea && element.value.plotArea !== '') {
            plotArea = plotArea + element.value.plotArea
          }
          if (element.value.constructionZone && element.value.constructionZone !== '') {
            constructionZone = constructionZone + ', ' + element.value.constructionZone
          }
        }
      })
      this.plotArea = plotArea === 0 ? null : plotArea
      this.buildingZone = constructionZone.slice(1)
    },
    plots: function () {
      this.plotIds.forEach(element => {
        const searchedElement = this.plots.find(x => x.id === element)
        if (searchedElement) {
          this.landRegisterNumberDummy.push({
            label: searchedElement.landRegisterNumber,
            value: searchedElement
          })
        }
      })
    }
  },
  mounted() {
    document.body.onmousedown = function (e) {
      if (e.button === 1) {
        e.preventDefault()
        return false
      }
    }
    this.dates.buildingStrategyReviewAt = this.$options.filters.objectToDateInput(this.buildingStrategyReviewAt)
    this.plotIds.forEach(element => {
      const searchedElement = this.plots.find(x => x.id === element)
      if (searchedElement) {
        this.landRegisterNumberDummy.push({
          label: searchedElement.landRegisterNumber,
          value: searchedElement
        })
      }
    })
    this.$nextTick(function () {
      this.building.modified = false
      const objectsChanged = this.objects.filter(x => x.modified === true)
      const tenanciesChanged = this.tenancies.filter(x => x.modified === true)
      const buildingDevicesChanged = this.buildingDevices.filter(x => x.modified === true)
      const objectDevicesChanged = this.objectDevices.filter(x => x.modified === true)
      if (objectsChanged.length === 0 && tenanciesChanged.length === 0 && buildingDevicesChanged.length === 0 && objectDevicesChanged.length === 0) {
        this.resetChanged()
      }
    })
  },
  methods: {
    async addFile(event) {
      const files = await this.getFilesFromEvent(event)
      if (files.length > 0) {
        this.mediaUpload = files[0]
        if (['image/png', 'image/jpeg', 'image/jpg'].includes(this.mediaUpload.type)) {
          await this.sendFile()
        } else {
          this.mediaUpload = null
          Vue.toasted.show(this.$tc('message.savingErrors.fileNotSupported'), { type: 'error' })
        }
      }
    },
    async sendFile() {
      this.mediaUploadProcessing = true
      const selectedFile = this.mediaUpload ? [this.mediaUpload] : []
      const response = await this.uploadMedia(selectedFile)
      if (response.length > 0) {
        const media = response[0]
        // Update vuex store
        this.setBuildingImageMedia({ media: media })
        this.mediaUpload = null
      }
      this.$nextTick(() => {
        this.mediaUploadProcessing = false
      })
    },
    showImage() {
      if (this.readOnly && this.buildingMediaUrl) {
        this.galleryImages = [
          this.buildingMediaUrl
        ]
        this.$nextTick(function () {
          this.$viewerApi({
            images: this.galleryImages,
            options: this.viewerOptions
          })
        })
      }
    },
    dateToObject() {
      this.buildingStrategyReviewAt = this.toObject(this.dates.buildingStrategyReviewAt)
    },
    autocompletePlaceChanged(data) {
      this.updateWithAddressComponents(data.address_components)
    },
    getPlotsNumber(plotId) {
      const obj = this.plots.find(x => x.id === plotId)
      return obj ? obj.landRegisterNumber : ''
    },
    showDetailLandRegister(plotId) {
      const obj = this.plots.find(x => x.id === plotId)
      if (obj) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerPropertyDetailsView',
            params: { id: obj.propertyId },
            query: {
              view: 'landRegister',
              elementSelected: obj.id
            }
          }, () => {
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminPropertyDetailsView',
            params: { id: obj.propertyId },
            query: {
              view: 'landRegister',
              elementSelected: obj.id
            }
          }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetPropertyDetailsView',
            params: { id: obj.propertyId },
            query: {
              view: 'landRegister',
              elementSelected: obj.id
            }
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingPropertyDetailsView',
            params: { id: obj.propertyId },
            query: {
              view: 'landRegister',
              elementSelected: obj.id
            }
          }, () => {
          })
        }
      }
    },
    // openActivities() {
    //   if (this.isOwnerModule) {
    //     this.$router.push({ name: 'OwnerActivitiesTicketView', params: { id: this.building.id }, query: { view: 'tickets' } }, () => {})
    //   } else if (this.isAdminModule) {
    //     this.$router.push({ name: 'AdminActivitiesTicketView', params: { id: this.building.id }, query: { view: 'tickets' } }, () => {})
    //   } else if (this.isAssetModule) {
    //     this.$router.push({ name: 'AssetActivitiesTicketView', params: { id: this.building.id }, query: { view: 'tickets' } }, () => {})
    //   } else if (this.isAccountingModule) {
    //     this.$router.push({ name: 'AccountingActivitiesTicketView', params: { id: this.building.id }, query: { view: 'tickets' } }, () => {})
    //   } else {
    //     this.$router.push({ name: 'MarketingActivitiesTicketView', params: { id: this.building.id }, query: { view: 'tickets' } }, () => {})
    //   }
    // },
    // openActivitiesMiddle() {
    //   let routeData = ''
    //   if (this.isOwnerModule) {
    //     routeData = this.$router.resolve({ name: 'OwnerActivitiesTicketView', params: { id: this.building?.id }, query: { view: 'tickets' } }, () => {})
    //   } else if (this.isAdminModule) {
    //     routeData = this.$router.resolve({ name: 'AdminActivitiesTicketView', params: { id: this.building?.id }, query: { view: 'tickets' } }, () => {})
    //   } else if (this.isAssetModule) {
    //     routeData = this.$router.resolve({ name: 'AssetActivitiesTicketView', params: { id: this.building?.id }, query: { view: 'tickets' } }, () => {})
    //   } else if (this.isAccountingModule) {
    //     routeData = this.$router.resolve({ name: 'AccountingActivitiesTicketView', params: { id: this.building?.id }, query: { view: 'tickets' } }, () => {})
    //   } else {
    //     routeData = this.$router.resolve({ name: 'MarketingActivitiesTicketView', params: { id: this.building.id }, query: { view: 'tickets' } }, () => {})
    //   }
    //   window.open(routeData.href, '_blank')
    // },
    updateWithAddressComponents(addressComponents) {
      const streetNo = addressComponents.find((component) => component.types.find((type) => type === 'street_number'))
      const street = addressComponents.find((component) => component.types.find((type) => type === 'route'))
      const city = addressComponents.find((component) => component.types.find((type) => type === 'locality'))
      const zip = addressComponents.find((component) => component.types.find((type) => type === 'postal_code'))
      const state = addressComponents.find((component) => component.types.find((type) => type === 'administrative_area_level_1'))
      const country = addressComponents.find((component) => component.types.find((type) => type === 'country'))

      if (country && country.short_name === 'CH') {
        if (streetNo) {
          this.building.address.streetNo = streetNo.long_name
        }
        if (street) {
          this.building.address.street = street.long_name
        }
        if (city) {
          this.building.address.city = city.long_name
        }
        if (zip) {
          this.building.address.zip = zip.long_name
        }
        if (state) {
          this.building.address.state = state.short_name
        }
      } else {
        // TODO Alert
      }
    },
    uploadImageClicked() {
      if (!this.readOnly) {
        const btn = this.$refs.mediaUpload.$el.children
        btn[0].click()
      }
    },
    handleDragStart(itemId) {
      // Set the dragged item's ID in the data transfer object
      event.dataTransfer.setData('itemId', itemId)
    },
    deleteMedia() {
      this.setBuildingImageMedia(null)
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      return this.$v.$invalid
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    }
  },
  validations: {
    building: {
      address: {
        street: {
          required
        }
      }
    },
    egid: {
      minValue: minValue(0),
      numeric
    },
    name: {
      required
    },
    constructionYear: {
      minLength: minLength(4),
      maxLength: maxLength(4),
      numeric
    },
    renovationYear: {
      minLength: minLength(4),
      maxLength: maxLength(4),
      numeric
    },
    floors: {
      minValue: minValue(0)
    },
    buildingArea: {
      minValue: minValue(0)
    },
    volume: {
      minValue: minValue(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.uploaded-file {
  max-height: 355px;
}

.building-image {
  max-height: 355px;
  max-width: 100%;
}

.object-image-remove-container {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin: 4px 12px;
}

.object-image-remove {
  position: absolute;
  z-index: 3;
  width: 17px;
  height: 16px;
  color: red;

  &.bg {
    left: 0px;
    top: -3px;
    width: 14px;
    height: 22px;
    color: white;
  }
}
</style>
