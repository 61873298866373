<template>
  <svg
    :id="name"
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    style="enable-background:new 0 0 489.4 489.4;"
    :style="active ? 'filter: invert(30%) sepia(8%) saturate(171%) hue-rotate(161deg) brightness(92%) contrast(89%)' : 'filter: invert(70%) sepia(0%) saturate(50%) hue-rotate(102deg) brightness(100%) contrast(100%);'"
    viewBox="0 0 448 512">
    <path d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" />
  </svg>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

library.add(faArrowUp)

export default {
  name: 'CoozzyArrowRightIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .fa {
    color: $color-text-grey;
  }
</style>
