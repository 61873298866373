export default {
  namespaced: true,
  state: {
    medias: []
  },
  getters: {
    getMedias: (state) => {
      return state.medias
    },
    getMediaById: (state) => (id) => {
      return state.medias.filter(media => media.id === id)
    }
  },
  mutations: {
    setMedias: (state, value) => {
      if (Array.isArray(value)) {
        state.medias = value
      }
    },
    addMedia: (state, value) => {
      state.medias.push(value)
    }
  },
  actions: {
    clearMediaList: ({ commit }) => {
      commit('setMedias', [])
    },
    addMedia({ commit }, value) {
      commit('addMedia', value)
    }
  }
}
