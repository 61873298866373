import Vue from 'vue'
import {
  V2Request
} from '@/types/api'

interface State {
  marketplace: string | null;
  request: V2Request[];
}
export default {
  namespaced: true,
  state: {
    marketplace: null,
    request: []
  },
  getters: {
    getRequests: (state: State) => {
      return state.request
    }
  },
  mutations: {
    setMarketplace(state: State, name: string) {
      Vue.set(state, 'marketplace', name)
    },
    setRequests: (state: State, value: V2Request[]) => {
      state.request = value
    }
  },
  actions: {
    setRequests: ({ commit }: { commit: Function }, value: V2Request[]) => {
      commit('setRequests', value)
    }
  }
}
