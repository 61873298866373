<template>
  <div>
    <b-carousel
      v-bind="$attrs"
      v-on="mountEventListeners">
      <b-carousel-slide
        v-for="slide in slides"
        :key="slide.id"
        :caption="slide.caption"
        :text="slide.text"
        :img-src="slide.imageSource" />
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'CoozzyCarousel',
  inheritAttrs: false,
  props: {
    slides: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
