<template>
  <div class="container-fluid">
    <header class="row pl-3">
      <div class="col-2 pl-0 d-flex align-items-center">
        <img
          class="company-logo"
          src="/logo.png"
          alt="Coozzy">
      </div>
      <div class="col pr-0">
        <div class="row justify-content-end">
          <div
            class="d-none d-md-flex col-md-auto pr-0 align-items-center">
            <coozzy-spinner
              v-if="loading.registration"
              class="mx-4" />
            <coozzy-button
              v-else
              design="transparent"
              @click="register">
              <span class="text-white text-uppercase">
                {{ $t('message.header.register') }}
              </span>
            </coozzy-button>
          </div>
          <div
            class="col-auto pl-0 d-flex align-items-center">
            <coozzy-spinner
              v-if="loading.login"
              class="mx-4" />
            <coozzy-button
              v-else
              design="transparent"
              @click="login">
              <span class="text-white text-uppercase">
                {{ $t('message.header.login') }}
              </span>
            </coozzy-button>
          </div>
          <coozzy-vertical-ruler />

          <div class="col-auto pr-0">
            <coozzy-form-select
              class="language-selection"
              :value="getCurrentLanguage"
              @change="languageChanged">
              <option value="en">
                {{ $t('message.generic.english') }}
              </option>
              <option value="de">
                {{ $t('message.generic.german') }}
              </option>
              <option value="fr">
                {{ $t('message.generic.french') }}
              </option>
              <option value="it">
                {{ $t('message.generic.italian') }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'CoozzyHeaderTransparent',
  data() {
    return {
      loading: {
        login: false,
        registration: false
      }
    }
  },
  computed: {
    getCurrentLanguage() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    }
  },
  methods: {
    login() {
      this.loading.login = true
      this.$auth.login('', { language: this.$store.getters['internationalization/getCurrentLanguage'] })
    },
    register() {
      this.loading.registration = true
      this.$auth.login('signUp', { language: this.$store.getters['internationalization/getCurrentLanguage'] })
    },
    languageChanged(language) {
      this.$store.dispatch('internationalization/changeCurrentLanguage', language).then()
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    padding: 0.5rem 1.5rem;
    line-height: 41px;

    .language-selection {
      cursor: pointer;
      border: none;
      color: white;
      background: none;
    }

    .company-logo {
      width: 150px;
      height: 41px;

      @media screen and (max-width: 767px) {
        width: 100px;
        height: 27px;
      }
    }
  }
</style>
