<template>
  <b-dropdown
    :variant="design"
    v-bind="$attrs"
    v-on="mountEventListeners"
    @close="closeDropdown">
    <template slot="button-content">
      <slot name="custom-text" />
    </template>

    <slot />
  </b-dropdown>
</template>

<script>
export default {
  name: 'CoozzyDropdown',
  inheritAttrs: false,
  props: {
    design: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    mountEventListeners() {
      return this.$listeners
    }
  },
  methods: {
    closeDropdown(e) {
      console.log('closeDropdown')
      // Check if the click occurred outside the dropdown
      if (!this.$refs.dropdown.$el.contains(e.target)) {
        e.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.btn-default) {
    color: $color-bg-white;
    background-color: $color-bg-green-light;
    border-color: $color-bg-green-light;

    &:hover:not([disabled]) {
      color: $color-bg-white;
      background-color: $color-bg-green;
      border-color: $color-bg-green;
    }
  }

  :deep(.btn-green) {
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
  }
  :deep(.btn-green):hover {
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
  }

  :deep(ul.dropdown-menu) {
    box-shadow: 1px 1px 6px #ccc;
  }
</style>
