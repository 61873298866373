<template>
  <div class="w-100">
    <ul class="list">
      <slot />
    </ul>
  </div>
</template>

<script>
import { ContainerMixin } from 'vue-slicksort'
export default {
  name: 'CoozzySortableList',
  mixins: [ContainerMixin]
}
</script>

<style lang="scss" scoped>
  .list {
    margin: 0 auto;
    padding: 0;
    overflow: auto;
    background-color: #f3f3f3;
    border-radius: 3;
}
</style>
