<template>
  <section>
    <div
      v-b-toggle="'collapse-building-distances'"
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ $t('message.onBoarding.createBuilding.distances') }}
      </h5>
    </div>
    <b-collapse
      :id="'collapse-building-distances'"
      ref="collapse"
      :visible="expandByDefault || openedCollapses.includes('collapse-building-distances')"
      class="row">
      <div class="col">
        <div class="row details-row-bg-1 mt-3 py-2">
          <div class="col-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="kindergarten"
              type="number"
              min="0"
              placeholder="m"
              :is-read-only="readOnly"
              :is-integer="true"
              :filter="'formatMeter'"
              :name="$t('message.onBoarding.buildings.distances.kindergarten')"
              :check-valide="$v.kindergarten.$dirty && $v.kindergarten.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="primarySchool"
              type="number"
              min="0"
              placeholder="m"
              :is-read-only="readOnly"
              :is-integer="true"
              :filter="'formatMeter'"
              :name="$t('message.onBoarding.buildings.distances.primarySchool')"
              :check-valide="$v.primarySchool.$dirty && $v.primarySchool.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="upperSchool"
              type="number"
              min="0"
              placeholder="m"
              :is-read-only="readOnly"
              :is-integer="true"
              :filter="'formatMeter'"
              :name="$t('message.onBoarding.buildings.distances.upperSchool')"
              :check-valide="$v.upperSchool.$dirty && $v.upperSchool.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="shop"
              type="number"
              :is-read-only="readOnly"
              :is-integer="true"
              min="0"
              placeholder="m"
              :filter="'formatMeter'"
              :name="$t('message.onBoarding.buildings.distances.shop')"
              :check-valide="$v.shop.$dirty && $v.shop.$error ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="publicTransport"
              type="number"
              min="0"
              placeholder="m"
              :is-read-only="readOnly"
              :is-integer="true"
              :filter="'formatMeter'"
              :name="$t('message.onBoarding.buildings.distances.publicTransport')"
              :check-valide="$v.publicTransport.$dirty && $v.publicTransport.$error ? 'is-invalid' : ''" />
          </div>
          <div class="col-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="motorway"
              type="number"
              min="0"
              placeholder="m"
              :is-read-only="readOnly"
              :is-integer="true"
              :filter="'formatMeter'"
              :name="$t('message.onBoarding.buildings.distances.motorway')"
              :check-valide="$v.motorway.$dirty && $v.motorway.$error ? 'is-invalid' : ''" />
          </div>
        </div>
      </div>
    </b-collapse>
  </section>
</template>

<script>
import { minValue } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric'

export default {
  name: 'BuildingDistances',
  components: { CoozzyFormInputNumeric },
  mixins: [validation],
  props: {
    building: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    expandByDefault: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    openedCollapses() {
      return this.$store.getters['onboarding/getOpenedCollapses']
    },
    kindergarten: {
      get() {
        return this.building.distances.kindergarten !== -1 ? this.building.distances.kindergarten : null
      },
      set(value) {
        this.$emit('value-updated', { field: 'kindergarten', value: value })
      }
    },
    primarySchool: {
      get() {
        return this.building.distances.primarySchool !== -1 ? this.building.distances.primarySchool : null
      },
      set(value) {
        this.$emit('value-updated', { field: 'primarySchool', value: value })
      }
    },
    upperSchool: {
      get() {
        return this.building.distances.upperSchool !== -1 ? this.building.distances.upperSchool : null
      },
      set(value) {
        this.$emit('value-updated', { field: 'upperSchool', value: value })
      }
    },
    shop: {
      get() {
        return this.building.distances.shop !== -1 ? this.building.distances.shop : null
      },
      set(value) {
        this.$emit('value-updated', { field: 'shop', value: value })
      }
    },
    publicTransport: {
      get() {
        return this.building.distances.publicTransport !== -1 ? this.building.distances.publicTransport : null
      },
      set(value) {
        this.$emit('value-updated', { field: 'publicTransport', value: value })
      }
    },
    motorway: {
      get() {
        return this.building.distances.motorway !== -1 ? this.building.distances.motorway : null
      },
      set(value) {
        this.$emit('value-updated', { field: 'motorway', value: value })
      }
    }
  },
  methods: {
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      return this.$v.$invalid
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    }
  },
  validations: {
    kindergarten: {
      minValue: minValue(0)
    },
    primarySchool: {
      minValue: minValue(0)
    },
    upperSchool: {
      minValue: minValue(0)
    },
    shop: {
      minValue: minValue(0)
    },
    publicTransport: {
      minValue: minValue(0)
    },
    motorway: {
      minValue: minValue(0)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
