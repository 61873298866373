<template>
  <div class="d-flex justify-content-center">
    <b-img
      v-if="!videoUrl.endsWith('.mp4')"
      :id="id"
      v-bind="$attrs"
      :class="sizeRestriction ? 'thumbnail-size-restriction' : ''"
      class="thumbnail p-0 border-0 rounded-0"
      thumbnail
      fluid
      @click="imageClicked" />
    <div
      v-else
      class="uploaded-file w-100"
      style="background-repeat: no-repeat;background-size: cover;background-position: center center;"
      :style="{ 'background-image': 'url(' + videoSrc + ')' }"
      @click="imageClicked" />
  </div>
</template>

<script>
export default {
  name: 'CoozzyThumbnail',
  inheritAttrs: false,
  props: {
    sizeRestriction: {
      type: Boolean,
      default: true
    },
    videoUrl: {
      type: String,
      default: ''
    },
    videoSrc: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id: null
    }
  },
  mounted() {
    this.id = 'thumb_' + this._uid
  },
  methods: {
    imageClicked() {
      this.$emit('imageClicked')
    }
  }
}
</script>

<style lang="scss" scoped>
  .thumbnail-size-restriction {
    width: 100px;
    height: 60px;
    object-fit: cover;
  }

  .thumbnail {
    background-color: transparent;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
</style>
