<template>
  <section class="w-100">
    <div>
      <coozzy-detail-card
        :title="fromHandover === 'moveIn' ? $t('message.processHandover.movingInTenancy') : $t('message.processHandover.movingOutTenancy')">
        <div class="col p-0">
          <div
            v-if="loadingTenancies"
            class="text-center">
            <coozzy-spinner />
          </div>
          <b-table
            v-else
            ref="tenancyContactsTable"
            :fields="fieldsTenantsList"
            :items="listContactTenancies"
            bordered
            class="overflow-auto shadow"
            hover
            responsive="true"
            select-mode="single"
            selectable
            stacked="md"
            @row-clicked="onRowClicked">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <!-- Headings -->
            <template #head(name)>
              {{ $t('message.generic.name') }}
            </template>
            <template #head(contactPerson)>
              {{ $t('message.interestedParty.reference.landlord.contactPerson') }}
            </template>
            <template #head(communication)>
              {{ $t('message.generic.communication') }}
            </template>
            <template #head(startingFrom)>
              {{ $t('message.onBoarding.buildings.objects.tenancies.startingFrom') }}
            </template>
            <template #head(endingFrom)>
              {{ $t('message.onBoarding.buildings.objects.tenancies.endingFrom') }}
            </template>
            <template #head(action) />

            <!-- Body -->
            <template #cell(name)="data">
              {{
                data.item.name
              }}
            </template>
            <template #cell(startingFrom)="data">
              {{
                data.item.startingFrom
              }}
            </template>
            <template #cell(endingFrom)="data">
              {{
                data.item.endingFrom
              }}
            </template>
            <template #cell(contactPerson)="data">
              <coozzy-form-select
                v-if="data.item.contactPersons"
                id="contactPerson"
                :ref="'contactPerson_' + data.item.id"
                v-model="data.item.selectedContactPerson">
                <option :value="null">
                  -
                </option>
                <option
                  v-for="contact of data.item.contactPersons"
                  :key="contact.id"
                  :value="contact.id">
                  {{ contact.name }}
                </option>
              </coozzy-form-select>
              <span v-else>
                -
              </span>
            </template>
            <template #cell(communication)="data">
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-mobile-alt-icon />
                </div>
                <div class="col">
                  <a
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'MOBILE')"
                    class="link">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') }}
                  </a>
                </div>
              </div>
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'HOME') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-phone-icon />
                </div>
                <div class="col">
                  <a
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'HOME')"
                    class="link">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'HOME') }}
                  </a>
                </div>
              </div>
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'WORK') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-headset-icon />
                </div>
                <div class="col">
                  <a
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'WORK')"
                    class="link">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'WORK') }}
                  </a>
                </div>
              </div>
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'FAX') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-fax-icon />
                </div>
                <div class="col">
                  <a
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'FAX')"
                    class="link">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'FAX') }}
                  </a>
                </div>
              </div>
              <div
                v-if="data.item.email !== ''"
                class="row">
                <div class="col-1">
                  <img
                    alt="Placeholder"
                    class="email-icon-img"
                    src="@/assets/icon/arroba.svg">
                </div>
                <div class="col">
                  <template v-if="data.item.email !== ''">
                    <a
                      class="link"
                      tabindex="0">
                      {{ data.item.email }}
                    </a>
                  </template>
                  <template v-else>
                    {{ data.item.email | displayOptionalValue }}
                  </template>
                </div>
              </div>
              <div
                v-if="data.item.website !== ''"
                class="row">
                <div class="col-1">
                  <coozzy-globe-icon />
                </div>
                <div class="col">
                  <template v-if="data.item.website !== ''">
                    <a
                      :href="getUrlWebsite(data.item.website)"
                      class="link"
                      tabindex="0"
                      target="_blank">
                      {{ data.item.website }}
                    </a>
                  </template>
                  <template v-else>
                    {{ data.item.website | displayOptionalValue }}
                  </template>
                </div>
              </div>
            </template>
            <template #cell(action)="data">
              <coozzy-button
                size="small"
                class="float-right mb-0 address-button"
                design="green"
                @click="addAddress(data.item.tenancy)">
                {{ $t('message.contact.actions.addAddress') }}
              </coozzy-button>
            </template>
          </b-table>
        </div>
      </coozzy-detail-card>
      <add-address-modal
        v-if="contact"
        :contact="contact" />
      <create-contact-modal
        v-if="contact"
        :key="contact ? contact.id : null"
        :for-owner-id="null"
        :from-handover="fromHandover"
        :show-search="false"
        :show-request-sources="false"
        :set-interested-party-role="false"
        :show-add-another="true"
        :contact-to-edit="contact"
        @contact-updated="contactUpdated"
        @modal-hide="modalHide" />
    </div>
  </section>
</template>

<script>
import { tables } from '@/mixins/tables'
import { routeChecks } from '@/mixins/routeChecks'
import { user } from '@/mixins/user'
import { formatting } from '@/mixins/formatting'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import { referenceInterestRate } from '@/mixins/referenceInterestRate'
import AddAddressModal from '@/contact/components/AddAddressModal.vue'
import CreateContactModal from '@/contact/components/CreateContactModal.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon.vue'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon.vue'
import CoozzyHeadsetIcon from '@/framework/components/icons/CoozzyHeadsetIcon.vue'
import CoozzyGlobeIcon from '@/framework/components/icons/CoozzyGlobeIcon.vue'
import CoozzyFaxIcon from '@/framework/components/icons/CoozzyFaxIcon.vue'

export default {
  name: 'HandoverTenancyMove',
  components: {
    CoozzyFaxIcon,
    CoozzyGlobeIcon,
    CoozzyHeadsetIcon,
    CoozzyPhoneIcon,
    CoozzyMobileAltIcon,
    CoozzyFormSelect,
    CoozzyButton,
    CreateContactModal,
    AddAddressModal,
    CoozzySpinner,
    CoozzyDetailCard
  },
  mixins: [tables, routeChecks, user, formatting, referenceInterestRate],
  props: {
    availableContact: {
      type: Array,
      default() {
        return []
      }
    },
    fromHandover: {
      type: String,
      default: ''
    },
    moveTenancies: {
      type: Array,
      default() {
        return []
      }
    },
    objects: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      contact: null,
      fieldsTenantsList: [
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'startingFrom',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.startingFrom'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'endingFrom',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.endingFrom'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'communication',
          label: this.$t('message.generic.communication'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      listContactTenancies: [],
      filterTenants: [],
      listContacts: [],
      loadingTenancies: false
    }
  },
  watch: {
    availableContact: {
      handler() {
        this.listContacts = this.availableContact
        this.loadData()
      },
      deep: true
    }
  },
  mounted() {
    this.loadData()
    this.fieldsTenantsList = this.fieldsTenantsList.filter(field =>
      this.fromHandover === 'moveIn' ? (field.key !== 'action' && field.key !== 'endingFrom') : (field.key !== 'startingFrom')
    )
  },
  methods: {
    getPhoneNumbers(phoneNumbers, type) {
      if (phoneNumbers && phoneNumbers.length > 0) {
        if (phoneNumbers.find(element => element.type?.toLowerCase() === type?.toLowerCase())) {
          return phoneNumbers.find(element => element.type.toLowerCase() === type.toLowerCase()).number
        }
      }
      return '-'
    },
    loadData() {
      if (this.moveTenancies.length > 0 && this.availableContact.length > 0) {
        this.filterTenants = this.moveTenancies
        // contacts that match contactIds of tenant of every tenancy
        const list = []
        const tenancies = this.moveTenancies.filter(tenancy => this.objects.some(x => x.id === tenancy.objectId))
        this.listContactTenancies = tenancies.map(tenancy => {
          const contacts = this.availableContact.filter(contact => tenancy.tenant.contactIds.includes(contact.id))
          const object = this.objects.find(object => object.id === tenancy?.objectId) || null
          contacts.forEach(contact => {
            list.push({
              id: contact.id,
              name: contact.name,
              type: object ? object.type : '-',
              email: contact.email,
              phoneNumbers: contact.phoneNumbers,
              website: contact.website,
              tenancy: tenancy,
              startingFrom: this.$options.filters.objectToDate(tenancy.startingAt),
              endingFrom: this.$options.filters.objectToDate(tenancy.endingAt)
            })
          })
          return list
        }).flat().filter((v, i, a) => a.findIndex(t => (t === v)) === i)
        this.loadingTenancies = false
        // remove contact that is duplicated by id on listContactTenancies
        this.listContactTenancies = this.listContactTenancies.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
      }
    },
    onRowClicked(contact, index, event) {
      if (event.srcElement.classList.contains('address-button')) {
        this.addAddress(contact.tenancy)
      }
      this.editContactClicked(this.availableContact.find(c => c.id === contact.id))
    },
    contactUpdated(contact) {
      this.listContacts.find((element, index) => {
        if (element.id === contact.contact.id) {
          this.listContacts[index] = contact.contact
        }
      })
      this.contact = null
      this.$emit('contact-updated', contact)
    },
    editContactClicked(contact) {
      this.contact = contact
      this.$nextTick(function () {
        this.$bvModal.show('create-contact-modal')
      })
    },
    addAddress(tenancy) {
      this.availableContact.find(contact => {
        if (contact.id === tenancy.tenant.contactIds[0]) {
          this.contact = contact
        }
      })
      this.$nextTick(function () {
        this.$bvModal.show('add-address-modal')
      })
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    },
    modalHide() {
      this.contact = null
      this.$nextTick(function () {
        this.$bvModal.hide('create-contact-modal')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text-grey {
  color: $color-text-grey-light;
}
:deep(#tenantsTable) {
  overflow: unset !important;
}
:deep(.sticky-header) {
  background-color: #f8f8f8 !important;
}
:deep(.b-table-sticky-column) {
  background-image: none !important;
}
</style>
