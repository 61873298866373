// @ts-nocheck
import network from './network'

export default {
  getCommentByReferenceIds(referenceIds) {
    return network.post('web/common/note/listByReferenceIds', { referenceIds: referenceIds })
      .then(response => {
        return response.data
      })
  },
  newComment(ownerId, referenceIds, note, accountInternal, mediaIds, mentions = []) {
    return network.post('web/common/note/create', {
      referenceIds: referenceIds,
      ownerId: ownerId,
      note: note,
      mentions: mentions,
      accountInternal: accountInternal,
      mediaIds: mediaIds
    })
      .then(response => {
        return response.data
      })
  },
  newCommentUnregistered(email, name, referenceIds, note, mediaIds) {
    return network.post('web/common/note/createUnregistered', {
      name: name,
      email: email,
      referenceIds: referenceIds,
      note: note,
      mediaIds: mediaIds
    })
      .then(response => {
        return response.data
      })
  },
  updateComment(id, note, mediaIds = [], mentions = []) {
    return network.post(`web/common/note/${id}/update`, {
      mediaIds: mediaIds,
      mentions: mentions,
      note: note
    })
      .then(response => {
        return response.data
      })
  },
  deleteComment(id) {
    return network.delete(`/web/common/note/delete/${id}`).then(response => {
      return response.data
    })
  },
  hasCertificationEnabled(accountId) {
    return network.get(`/web/common/certifaction/isEnabled?accountId=${accountId}`)
      .then(response => {
        return response.data
      })
  },
  enableCertification(accountId, enable) {
    return network.post('/web/common/certifaction/enable', {
      accountId: accountId,
      enable: enable
    }).then(response => {
      return response.data
    })
  }
}
