<template>
  <section>
    <h1
      class="d-none d-md-block">
      <address-text
        :one-line="true"
        :address="item.address" />
    </h1>
    <h2 class="d-block d-md-none">
      <address-text
        :one-line="true"
        :address="item.address" />
    </h2>
    <h5>
      <span>{{ item.publicId }} | </span>
      <span v-if="getObjectRoom(item) > 0">{{ formatRooms(getObjectRoom(item)) }} </span>
      <span>{{ $t('message.onBoarding.buildings.objects.category.' + item.category.toLowerCase()) }} </span>
      <span v-if="getObjectFloor(item) > -10000">{{ formatFloor(getObjectFloor(item)) }}</span>
      <span v-if="showMarketingStatus">
        | {{ $t('message.marketingObjects.status.title') }}:
        <span :class="statusColor">{{ statusTitle }}</span>
        <span
          v-if="statusDate"
          :class="statusColor">
          {{ $t('message.generic.since') + ' ' + statusDate }}
        </span>
      </span>
      <coozzy-alert
        v-if="showMarketingInfoBox && item.marketingStatus && item.marketingStatus.marketingNote"
        class="my-2"
        variant="blue"
        size="sm">
        <div>
          <h6 class="d-inline-block alert-heading font-weight-bold mb-0">
            {{ $t('message.marketingObjects.startMarketing') }}:
          </h6>
          {{ item.marketingStatus.startMarketingAt | timestampToDate }}
        </div>
        <h6 class="alert-heading font-weight-bold mb-0 mt-2">
          {{ $t('message.marketingObjects.marketingNote') }}:
        </h6>
        <div
          v-if="item.marketingStatus.marketingNote.length < 60 ">
          {{ item.marketingStatus.marketingNote }}
        </div>
        <div
          v-else-if="!marketingNoteVisibility">
          {{ item.marketingStatus.marketingNote.substring(0, 20) }}...
          <b-link
            @click="showMoreShowLess">
            {{ $t('message.generic.showMore') }}
          </b-link>
        </div>
        <div
          v-else>
          <b-collapse
            id="collapse-1"
            v-model="marketingNoteVisibility">
            <p>{{ item.marketingStatus.marketingNote }}</p>
          </b-collapse>
          <b-link
            @click="showMoreShowLess">
            {{ $t('message.generic.showLess') }}
          </b-link>
        </div>
      </coozzy-alert>
    </h5>
  </section>
</template>
<script>
import AddressText from '../framework/components/misc/AddressText'
import { obj } from '@/mixins/object'
import { formatting } from '@/mixins/formatting'
import CoozzyAlert from '@/framework/components/alert/CoozzyAlert'

export default {
  name: 'TitleMarketingObjectView',
  components: {
    CoozzyAlert,
    AddressText
  },
  mixins: [formatting, obj],
  props: {
    item: {
      type: Object,
      default: null
    },
    showMarketingInfoBox: {
      type: Boolean,
      default: true
    },
    showMarketingStatus: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    statusTitle() {
      return this.calculateMarketingStatus(this.item.marketingStatus).label
    },
    statusColor() {
      return this.calculateMarketingStatus(this.item.marketingStatus).color
    },
    statusDate() {
      return this.calculateMarketingStatus(this.item.marketingStatus).date
    }
  },
  methods: {
    showMoreShowLess() {
      this.marketingNoteVisibility = !this.marketingNoteVisibility
    }
  }
}
</script>
